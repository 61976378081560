import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { groupBy as _groupBy, isEqual as _isEqual } from "lodash";
import { Observable } from "rxjs";
import { distinctUntilChanged, filter, first, map, take } from "rxjs/operators";
import { REPORT_FILTER_ID, RUNTIME_FILTER_ID } from "../../../core/helpers/filter/filter-id.helper";
import { doesFilterExpressionUseSourceFilter } from "../../../core/helpers/filter/filter-validation.helper";
import { FilterConfigurationDto } from "../../../core/models/filter/filter-configuration";
import {
  FilterViewModel,
  createFilterViewModel
} from "../../../core/models/filter/filter-view-model";
import { IFilterSelector } from "../../../core/services/filter/i-filter.selector";
import { EntityId } from "../../../meta/models/entity";
import { isEmptyOrNotDefined } from "../../../ts-utils/helpers/is-empty.helper";
import { isDefined, isNotDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Dictionary } from "../../../ts-utils/models/dictionary.type";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { ComponentStateDto } from "../../models/component-state";
import {
  selectDependentFilters,
  selectFilterById,
  selectFilterByIdOrDefault,
  selectFilterEntities,
  selectFiltersById
} from "../../store/filter/filter.selectors";

@Injectable()
export class FilterSelector extends IFilterSelector {
  constructor(private store$: Store<any>) {
    super();
  }

  selectById(filterId: EntityId): Observable<FilterConfigurationDto> {
    return this.store$.select(selectFilterById(filterId));
  }

  selectByIdOrDefault(filterId: EntityId): Observable<FilterConfigurationDto> {
    return this.store$.select(selectFilterByIdOrDefault(filterId));
  }

  selectManyById(filterIds: EntityId[]): Observable<FilterConfigurationDto[]> {
    return this.store$.select(selectFiltersById(filterIds));
  }

  selectAll(): Observable<Dictionary<Maybe<FilterConfigurationDto>>> {
    return this.store$.select(selectFilterEntities);
  }

  selectAllInLiveMode(): Observable<Dictionary<FilterConfigurationDto>> {
    return this.store$.select(selectFilterEntities).pipe(
      map((filterConfigs) => {
        return findFiltersInLiveMode(filterConfigs);
      }),
      distinctUntilChanged((previousFilters, currentFilters) =>
        _isEqual(Object.keys(previousFilters), Object.keys(currentFilters))
      )
    );
  }

  getById(id: EntityId): FilterConfigurationDto {
    let filterConfig: FilterConfigurationDto = null;
    this.selectById(id)
      .pipe(take(1), filter(isDefined))
      .subscribe((newFilterConfig) => (filterConfig = newFilterConfig));
    return filterConfig;
  }

  getByComponent(component: ComponentStateDto): FilterConfigurationDto {
    return isDefined(component.filterId) ? this.getById(component.filterId) : this.getGlobal();
  }

  getManyById(ids: EntityId[]): FilterConfigurationDto[] {
    let filterConfigs: FilterConfigurationDto[] = [];
    this.selectManyById(ids)
      .pipe(take(1))
      .subscribe((newFilterConfigs) => (filterConfigs = newFilterConfigs.filter(isDefined)));
    return filterConfigs;
  }

  getManyByComponents(components: ComponentStateDto[]): FilterConfigurationDto[] {
    const componentsByFilter = _groupBy(
      components,
      (componentState) => componentState.filterId ?? RUNTIME_FILTER_ID
    );
    const componentFilters = this.getManyById(Object.keys(componentsByFilter));
    return componentFilters;
  }

  getAll(): Dictionary<FilterConfigurationDto> {
    let filtersDict: Dictionary<FilterConfigurationDto> = {};
    this.selectAll()
      .pipe(take(1))
      .subscribe((filterConfigs) => (filtersDict = filterConfigs));
    return filtersDict;
  }

  getAllAsArray(): FilterConfigurationDto[] {
    return Object.values(this.getAll());
  }

  getGlobal(): FilterConfigurationDto {
    let globalFilter: FilterConfigurationDto;
    this.store$
      .select(selectFilterByIdOrDefault(REPORT_FILTER_ID))
      .pipe(first())
      .subscribe((filter: FilterConfigurationDto) => (globalFilter = filter));
    return globalFilter;
  }

  getRuntime(): FilterConfigurationDto {
    let runtimeGlobalFilter: FilterConfigurationDto;
    this.store$
      .select(selectFilterByIdOrDefault(RUNTIME_FILTER_ID))
      .pipe(first())
      .subscribe((filter: FilterConfigurationDto) => (runtimeGlobalFilter = filter));
    return runtimeGlobalFilter;
  }

  getByIdOrDefault(filterId: Maybe<EntityId>): FilterConfigurationDto {
    let requiredFilter: FilterConfigurationDto;
    this.store$
      .select(selectFilterByIdOrDefault(filterId))
      .pipe(first())
      .subscribe((filter: FilterConfigurationDto) => {
        requiredFilter = filter;
      });
    return requiredFilter;
  }

  getAllInLiveMode(): Dictionary<FilterConfigurationDto> {
    let allFilters: Dictionary<Maybe<FilterConfigurationDto>>;
    this.store$
      .select(selectFilterEntities)
      .pipe(first())
      .subscribe((filters: Dictionary<Maybe<FilterConfigurationDto>>) => (allFilters = filters));
    return findFiltersInLiveMode(allFilters);
  }

  getDependentFilters(sourceFilterId: EntityId): FilterConfigurationDto[] {
    let dependentFilters = [];
    this.store$
      .select(selectDependentFilters(sourceFilterId))
      .pipe(first())
      .subscribe((filters) => (dependentFilters = filters));
    return dependentFilters;
  }

  getRelativeFilters(sourceFilterId: EntityId): FilterConfigurationDto[] {
    return this.getDependentFilters(sourceFilterId).filter((filter) =>
      doesFilterExpressionUseSourceFilter(filter.timeRange)
    );
  }

  // NOTE: we are considering that depth of filter dependencies is 1
  getManyWithDependentFilters(filterIds: EntityId[]): FilterConfigurationDto[] {
    const allFiltersDict: Dictionary<FilterConfigurationDto> = filterIds.reduce(
      (acc: Dictionary<FilterConfigurationDto>, filterId) => {
        acc[filterId] = this.getById(filterId);
        const dependingFilters = this.getDependentFilters(filterId);
        dependingFilters.forEach((filter) => {
          if (!acc[filter.id]) {
            acc[filter.id] = filter;
          }
        });
        return acc;
      },
      {}
    );
    return Object.values(allFiltersDict);
  }
}

export function findFiltersInLiveMode(
  filtersDict: Dictionary<Maybe<FilterConfigurationDto>>
): Dictionary<FilterConfigurationDto> {
  return Object.keys(filtersDict).reduce((acc: Dictionary<FilterConfigurationDto>, filterId) => {
    const filterViewModel = getFilterViewModel(filtersDict[filterId], filtersDict);
    if (isFilterInLiveMode(filterViewModel)) {
      acc[filterId] = filtersDict[filterId];
    }
    return acc;
  }, {});
}

export function getFilterViewModel(
  filter: FilterConfigurationDto,
  filtersDict: Dictionary<FilterConfigurationDto>
): FilterViewModel {
  const sourceFilterViewConfig = filter.sourceFilterId
    ? getFilterViewModel(filtersDict[filter.sourceFilterId], filtersDict)
    : null;
  return createFilterViewModel(filter, sourceFilterViewConfig);
}

function isFilterInLiveMode(filter: FilterViewModel): boolean {
  if (isNotDefined(filter)) {
    return false;
  }
  if (isNotDefined(filter.sourceFilterConfig)) {
    return isEmptyOrNotDefined(filter.timeRange.toExpression);
  } else {
    const dependsOnSourceFilter =
      isEmptyOrNotDefined(filter.timeRange.toExpression) ||
      doesFilterExpressionUseSourceFilter(filter.timeRange);
    return dependsOnSourceFilter && isFilterInLiveMode(filter.sourceFilterConfig);
  }
}
