import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from 'rxjs/operators';
import { Dispatcher, ErrorCatchingActions } from "ui-core";
import { TelemetryService } from "./telemetry.service";
 
@Injectable()
export class HttpErrorHandlerInterceptor implements HttpInterceptor {    
  constructor(private telemetryService: TelemetryService, private dispatcher: Dispatcher) {}
 
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        this.telemetryService.logException(error);

        // Request too large error, show popup
        if (error.status === 413) {
          this.dispatcher.dispatch(
            ErrorCatchingActions.catchError({
              messageToDisplay: error.error,
              error: error,
              autoClose: true
            })
          );
        }

        return throwError(error);
      })
    )
  }
}