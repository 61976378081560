import { HttpHeaders, HttpParams } from "@angular/common/http";
import { Maybe } from "../../ts-utils";
import { StrictPartial } from "../../ts-utils/models/strict-partial.type";

const JSON_HTTP_HEADER: HttpHeaders = new HttpHeaders({ "Content-type": "application/json" });

export class HttpGetParams {
  url: string;
  options: any;
  failureDefault: any;
  queryParams: Maybe<HttpParams>;
  constructor(params: StrictPartial<HttpGetParams, "url">) {
    this.url = params.url;
    this.options = getHttpOptions(params.options);
    this.failureDefault = params.failureDefault;
    this.queryParams = params.queryParams;
  }
}

export class HttpPostParams extends HttpGetParams {
  body: any;
  constructor(params: StrictPartial<HttpPostParams, "url" | "body">) {
    super(params);
    this.body = params.body;
  }
}

function getHttpOptions(options: any) {
  let httpOptions = { headers: JSON_HTTP_HEADER };
  return { ...httpOptions, ...options };
}
