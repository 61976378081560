import {
  ChangeDetectorRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  Type,
  ViewContainerRef
} from "@angular/core";
import { DynamicTemplateDirective } from "../../dynamics/dynamic-template.directive";
import { TypeProvider } from "../../meta/services/type-provider";
import { CriticalError } from "../../ts-utils/models/critical-error";
import { BaseComponent } from "../components/base/base.component";
import { ComponentStateDto } from "../models/component-state";
import { IConnectable } from "../models/i-connectable";

@Directive({
  selector: "[dynamic-component-container]"
})
export class DynamicComponentsDirective extends DynamicTemplateDirective {
  @Input()
  public components: ComponentRef<BaseComponent>[] = [];

  constructor(
    public viewContainerRef: ViewContainerRef,
    protected componentFactoryResolver: ComponentFactoryResolver,
    private typeProvider: TypeProvider,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(viewContainerRef, componentFactoryResolver);
  }

  public loadStoreComponents(componentStates: ComponentStateDto[]): void {
    componentStates.forEach((componentState: ComponentStateDto) => {
      if (!componentState) {
        throw new CriticalError("Undefined component state");
      }
      this.attachComponent(componentState.type, componentState);
    });
    // We couldn't load components in ngOnInit (@ViewChild is not available at that time)
    // but on afterViewInit. Since that's after the change detection has finished, we need to run it manually
    this.changeDetectorRef.detectChanges();
  }

  private attachComponent(type: string, componentState: ComponentStateDto) {
    const componentType: Type<BaseComponent> = this.typeProvider.getType(type)
      .constructorFunction as Type<BaseComponent>;
    const newComponent: BaseComponent = this.appendComponent(componentType);
    (<IConnectable>newComponent).connectTo(componentState);
  }

  public appendComponent<T>(componentType: Type<T>): T {
    const newComponent: T = super.appendComponent(componentType);
    if (!(newComponent instanceof BaseComponent)) {
      throw new CriticalError(`Cannot append non-BaseComponent type ${componentType}.`);
    }
    return newComponent;
  }
}
