import { isDefined } from "../../ts-utils/helpers/predicates.helper";

const regex = RegExp("^(0*)(\\.(0*)(#*))?$");

export function isValidNumberFormatString(format: string): boolean {
  return regex.test(format);
}

export function formatNumberDefault(value: number): string {
  const absValue = Math.abs(value);
  if (absValue < 1e-12) {
    return "0";
  }
  if (absValue < 1e-6 || absValue > 1e6) {
    return value.toExponential(1);
  }
  if (absValue < 1e-4) {
    return formatNumberByFormatString(value, "0.######");
  }
  if (absValue < 1e-2) {
    return formatNumberByFormatString(value, "0.####");
  }
  return formatNumberByFormatString(value, "0.##");
}
function roundToPrec(value: number, fractionDigits: number): number {
  const scale = Math.pow(10, fractionDigits);
  return Math.round(scale * value) / scale;
}

export function formatNumberByFormatString(value: number, format: string): string {
  if (!Number.isFinite(value)) {
    return value.toString();
  }
  const match = regex.exec(format);
  if (isDefined(match)) {
    const minimumIntegerDigits = match[1]?.length ?? 1;
    const forcedMantissaLength = match[3]?.length ?? 0;
    const optionalMantissaLength = match[4]?.length ?? 0;

    const fractionalFormatted = formatFractionalDigits(
      value,
      forcedMantissaLength,
      optionalMantissaLength
    );
    return padLeft(fractionalFormatted, minimumIntegerDigits);
  }

  // invalid format. fallback to toString
  return value.toString();
}

function formatFractionalDigits(
  value: number,
  forcedMantissaLength: number,
  optionalMantissaLength: number
): string {
  // optional mantissa: remove least significant digit while number remains within 1e-12 of original
  const maximumFractionDigits = forcedMantissaLength + optionalMantissaLength;
  let tmp = value.toFixed(forcedMantissaLength + optionalMantissaLength);

  if (Math.abs(value - roundToPrec(value, maximumFractionDigits)) < 1e-12) {
    let remainingOptionalMantissa = optionalMantissaLength;
    while (remainingOptionalMantissa > 0 && tmp.endsWith("0")) {
      tmp = tmp.substring(0, tmp.length - 1);
      remainingOptionalMantissa--;
    }
    if (tmp.endsWith(".")) {
      tmp = tmp.substring(0, tmp.length - 1);
    }
  }
  return tmp;
}

function padLeft(decimalString: string, minimumIntegerDigits: number): string {
  if (minimumIntegerDigits > 1) {
    const startsWithSign = decimalString[0] === "-";
    const sign = startsWithSign ? decimalString[0] : "";
    const afterSign = startsWithSign ? decimalString.substring(1) : decimalString;
    const dotAt = afterSign.indexOf(".");
    const currentIntegerDigits = dotAt >= 0 ? dotAt : afterSign.length;
    if (currentIntegerDigits < minimumIntegerDigits) {
      decimalString =
        sign + "".padStart(minimumIntegerDigits - currentIntegerDigits, "0") + afterSign;
    }
  }
  return decimalString;
}
