<div class="app-footer">
  <span class="app-footer__copyright">&copy; Copyright 2018 - {{ currentYear }} ABB</span>
  <span class="app-footer__connection-status">
    <i
      [ngClass]="
        (isOnline$ | async)
          ? 'abb-icon Link'
          : 'connection-status-warn abb-icon Warning_triangle_2'
      "
    ></i>
    <span class="app-footer__connection-status-title">{{
      (isOnline$ | async) ? "Online" : "Offline"
    }}</span>
  </span>
</div>
