import { isNumber } from "lodash";
import { isValueHTML } from "../../../../../core/services/value-formatter.service";
import { DataColumnType, DataConnectorDto } from "../../../../../data-connectivity";
import { DataConnectorViewDto } from "../../../../../data-connectivity/models/data-connector-view";
import {
  DataPointDto,
  DataPointValue,
  TimeSeriesDataPointDto,
  getKey
} from "../../../../../data-connectivity/models/data-point";
import { Dispatcher } from "../../../../../dispatcher";
import { DateFormatterService } from "../../../../../environment/services/date-formatter.service";
import { EntityId } from "../../../../../meta/models/entity";
import { SimpleSingleValueViewConfig } from "../../../../../shared/components/simple-single-value/view-config";
import {
  DeepUpdate,
  Maybe,
  isDate,
  isDefined,
  isEmpty,
  isNotDefined,
  isString
} from "../../../../../ts-utils";
import { binarySearch } from "../../../../../ts-utils/helpers/binary-search";
import { getColumnWidthInPx, getTableWidth } from "../../../../helpers/column.helper";
import { getPseudoConnectorId, isPseudoConnector } from "../../../../helpers/connectors.helper";
import { buildLinkFromProperties } from "../../../../helpers/link-resolution.helper";
import { HorizontalAlignment } from "../../../../models/alignment/horizontal-alignment";
import { BLACK_COLOR_HEX } from "../../../../models/colors.constants";
import { LinkProperties } from "../../../../models/link-properties";
import { DataConnectorDescriptor } from "../../../../models/store/data-connector-descriptor";
import { RowValue, TableCellInfo } from "../../../../models/table/table-cell-info";
import { TableCellType } from "../../../../models/table/table-cell-type";
import { ColumnType, TableColumnConfig } from "../../../../models/table/table-column-config";
import { TableConnectorView } from "../../../../models/table/table-connector-view";
import { RowKeyType, TableRow } from "../../../../models/table/table-row";
import { LimitMarkerHelper } from "../../../../services/limit.helper";
import { DataConnectorViewActions } from "../../../../store/data-connector-view/data-connector-view.actions";
import { TimeSeriesViewConfig } from "../../../time-series/view-config";
import { ITableViewConfig } from "../i-table-for-connectors-view-config";
import { TableRenderingStrategy } from "./table-rendering-strategy";

export const X_COLUMN_ID = "xColumn";
export const LABEL_COLUMN = "labelColumn";

export class ConnectorPerColumnStrategy implements TableRenderingStrategy {
  protected get xColumnTitle(): string {
    return "Category";
  }

  constructor(private dateFormatter: DateFormatterService) {}

  public createCols(
    view: Maybe<TimeSeriesViewConfig>,
    dataConnectors: Maybe<DataConnectorDescriptor[]>,
    tableComponentId: Maybe<EntityId>,
    dispatcher: Dispatcher
  ): Maybe<TableColumnConfig[]> {
    if (isDefined(view) && isDefined(dataConnectors) && isDefined(tableComponentId)) {
      const tableWidth: number = getTableWidth(view.runtimeView.runtimeSize);
      const connectorViewUpdates: DeepUpdate<DataConnectorViewDto>[] = [];
      const columnsConfig: TableColumnConfig[] = dataConnectors.reduce(
        (acc: TableColumnConfig[], connectorDescriptor: DataConnectorDescriptor) => {
          const column: TableConnectorView = connectorDescriptor.connectorView.column;
          if (column.isHidden) {
            return acc;
          }
          const columnWidthInPx: Maybe<number> =
            column.width !== "" ? getColumnWidthInPx(column.width, tableWidth) : null;

          const horizontalAlignment = resolveHorizontalAlignment(
            column,
            connectorDescriptor?.connector
          );
          if (
            shouldUpdateColumnAlignment(
              column.horizontalAlignment,
              connectorDescriptor.connector.dataPoints,
              connectorDescriptor.connector.id
            )
          ) {
            connectorViewUpdates.push({
              id: connectorDescriptor.connectorView.id.toString(),
              changes: { column: { horizontalAlignment } }
            });
          }

          if (connectorDescriptor.connector.id === getPseudoConnectorId(tableComponentId)) {
            const timestampColumn: TableColumnConfig = new TableColumnConfig();
            timestampColumn.title = connectorDescriptor.connector.title;
            timestampColumn.cellConfig = {
              columnWidth: columnWidthInPx,
              alignment: horizontalAlignment,
              displayFormat: resolveDisplayFormat(column.displayFormat, view.dateFormat)
            };
            timestampColumn.id = X_COLUMN_ID;
            timestampColumn.columnType = ColumnType.Date;
            acc.push(timestampColumn);
          } else {
            const col: TableColumnConfig = new TableColumnConfig();
            const columnIsDate: boolean =
              connectorDescriptor?.connector?.properties?.type === DataColumnType.Date;
            const globalFormat: string = columnIsDate ? view.dateFormat : view.displayFormat;
            col.id = connectorDescriptor.connectorView.id.toString();
            col.title = connectorDescriptor.connector.title;
            col.cellConfig = {
              textColor: BLACK_COLOR_HEX,
              unit: getUnitFromConnector(connectorDescriptor?.connector),
              displayFormat: resolveDisplayFormat(column.displayFormat, globalFormat),
              alignment: horizontalAlignment,
              columnWidth: columnWidthInPx
            };
            col.columnType = this.resolveColumnType(columnIsDate, connectorDescriptor?.connector);
            acc.push(col);
          }
          return acc;
        },
        []
      );
      if (!isEmpty(connectorViewUpdates)) {
        dispatcher.dispatch(DataConnectorViewActions.updateMany({ connectorViewUpdates }));
      }
      return columnsConfig;
    }
  }

  public createRows(
    view: Maybe<ITableViewConfig>,
    connectors: Maybe<DataConnectorDescriptor[]>,
    tableComponentId: Maybe<EntityId>
  ): TableRow[] {
    let rows: TableRow[] = [];
    if (isDefined(connectors) && !isEmpty(connectors)) {
      const isTimeTable: boolean = areAllTimeSeries(connectors);
      let timestampConnectorDescriptor: Maybe<DataConnectorDescriptor>;
      if (!isTimeTable) {
        connectors = filterOutTimeSeries(connectors);
      } else {
        timestampConnectorDescriptor = connectors.find(
          (connectorDesc: DataConnectorDescriptor) =>
            connectorDesc.connector.id === getPseudoConnectorId(tableComponentId)
        );
      }
      connectors.forEach((connectorDesc) => {
        if (!connectorDesc.connector.dataPoints) {
          return;
        }

        const rowIsDate: boolean =
          connectorDesc?.connector?.properties?.type === DataColumnType.Date;

        if (isDefined(view)) {
          const limitHelper = new LimitMarkerHelper(view.limits);
          rows = connectorDesc.connector.dataPoints.reduce(
            (acc, dataPoint: DataPointDto, currentIndex: number) => {
              const connectorViewId = getIdFromConnectorView(connectorDesc.connectorView);
              const rowKey = this.getRowKey(dataPoint, currentIndex);
              let rowIndex = binarySearch(rows, (row: TableRow) =>
                compareRowKey(row.rowKey, rowKey)
              );

              if (rowIndex < 0) {
                const newRow: TableRow = this.createRow(rowKey, dataPoint, isTimeTable);
                rowIndex = ~rowIndex;
                acc.splice(rowIndex, 0, newRow);
              }

              const cell: TableCellInfo = {
                value: dataPoint.evaluatedValue ?? dataPoint.y,
                cellType: this.resolveCellType(dataPoint.y, rowIsDate)
              };
              const config: SimpleSingleValueViewConfig = {} as any;
              cell.rowValue = cell.cellType === TableCellType.HTML ? null : cell.value;
              acc[rowIndex].cells[connectorViewId] = cell;

              if (isDefined(dataPoint.properties)) {
                config.link = buildLinkFromProperties(dataPoint.properties as LinkProperties);
              }

              const limitColor = limitHelper.getPointsLimitColor(dataPoint);
              if (limitColor != null) {
                config.textColor = limitColor;
              }
              if (Object.keys(config).length > 0) {
                cell.config = config;
              }
              return acc;
            },
            rows
          );
        }
      });
    }
    return rows;
  }

  protected getRowKey(dataPoint: DataPointDto, pointsIndex: number): RowKeyType {
    return dataPoint.x != null ? dataPoint.x.toString() : pointsIndex + 1;
  }

  private createRow(
    rowKey: RowKeyType,
    dataPoint: DataPointDto,
    renderTimestamp: boolean
  ): TableRow {
    const row: TableRow = { rowKey: rowKey, cells: {} };
    if (renderTimestamp) {
      const sortingValue: Maybe<RowValue> = getKey(dataPoint);
      if (isDefined(sortingValue)) {
        row.cells[X_COLUMN_ID] = {
          value: (dataPoint as TimeSeriesDataPointDto).x ?? "",
          rowValue: sortingValue,
          cellType: TableCellType.SingleValue
        };
      }
    }
    return row;
  }

  private resolveCellType(value: DataPointValue, rowIsDate: boolean): TableCellType {
    if (isValueHTML(value)) {
      return TableCellType.HTML;
    } else if (rowIsDate) {
      return TableCellType.Date;
    } else {
      return TableCellType.SingleValue;
    }
  }

  private resolveColumnType(columnIsDate: boolean, connector: DataConnectorDto): Maybe<ColumnType> {
    if (columnIsDate || hasColumnDateValue(connector.dataPoints)) {
      return ColumnType.Date;
    } else if (
      (isDefined(connector?.properties?.type) &&
        connector?.properties?.type === DataColumnType.String) ||
      hasColumnTextValue(connector.dataPoints)
    ) {
      return ColumnType.Text;
    } else if (
      (isDefined(connector?.properties?.type) &&
        connector?.properties?.type === DataColumnType.Number) ||
      hasColumnNumberValue(connector.dataPoints)
    ) {
      return ColumnType.Number;
    }
  }
}

function getIdFromConnectorView(connectorView: DataConnectorViewDto): string {
  return connectorView.id?.toString() ?? "";
}

function getUnitFromConnector(connector: DataConnectorDto): string {
  return isDefined(connector.properties) ? `${connector.properties["Unit"] ?? ""}` : "";
}

export function areAllTimeSeries(tableConnectors: DataConnectorDescriptor[]): boolean {
  return tableConnectors.every(
    (descriptor: DataConnectorDescriptor) => descriptor.connector.isTimeSeries
  );
}

export function filterOutTimeSeries(
  tableConnectors: DataConnectorDescriptor[]
): DataConnectorDescriptor[] {
  return tableConnectors.filter(
    (descriptor: DataConnectorDescriptor) => !descriptor.connector.isTimeSeries
  );
}

function compareRowKey(a: RowKeyType, b: RowKeyType): number {
  if (typeof a === "number") {
    if (typeof b !== "number") {
      throw new Error("Table key type mismatch.");
    }
    return a - b;
  }
  if (typeof b !== "string") {
    throw new Error("Table key type mismatch.");
  }

  return a.localeCompare(b);
}

function resolveDisplayFormat(columnDisplayFormat: string, globalFormat: string): string {
  return columnDisplayFormat !== "" ? columnDisplayFormat : globalFormat;
}

function resolveHorizontalAlignment(
  column: TableConnectorView,
  connector: DataConnectorDto
): string {
  let horizontalAlignment: string;
  let columnIsText: boolean = false;
  if (isDefined(connector?.properties?.type)) {
    columnIsText = connector?.properties?.type === DataColumnType.String;
  } else if (isDefined(connector.dataPoints)) {
    columnIsText = hasColumnTextValue(connector.dataPoints);
  }
  horizontalAlignment = columnIsText ? HorizontalAlignment.Left : HorizontalAlignment.Right;
  if (isDefined(column.horizontalAlignment)) {
    horizontalAlignment = column.horizontalAlignment?.toLocaleLowerCase();
  }
  return horizontalAlignment;
}

function hasColumnTextValue(dataPoints: DataPointDto[]): boolean {
  return dataPoints?.some((point) => isString(point.y));
}

function hasColumnDateValue(dataPoints: DataPointDto[]): boolean {
  return dataPoints?.some((point) => isDate(point.y));
}

function hasColumnNumberValue(dataPoints: DataPointDto[]): boolean {
  return dataPoints?.some((point) => isNumber(point.y));
}

function shouldUpdateColumnAlignment(
  columnAlignment: Maybe<string>,
  dataPoints: Maybe<DataPointDto[]>,
  connectorId: EntityId
): boolean {
  return isNotDefined(columnAlignment) && (isDefined(dataPoints) || isPseudoConnector(connectorId));
}
