import { IconId } from "../../../browsing/models/icon-id";
import { StatusTitle } from "../../../shared/models/status-title";
import { IconStateDisplay } from "./icon-state-display";
import { IndicatorShape } from "./indicator-shape";
import { IndicatorState } from "./indicator-state";
import { ShapeStateDisplay } from "./shape-state-display";

export const NULL_VALUE = "NULL";
export const DEFAULT_STATUS_VALUE = "any";
export const NULL_VALUE_SMALL = "null";
export const MIN_TITLE_FONT_SIZE = 15;
export const TITLE_SCALE_FACTOR = 0.15;
export const ICON_SCALE_FACTOR_MAX = 0.8;
export const ICON_SCALE_FACTOR_MIN = 0.5;

export const DEFAULT_BINARY_STATE = new IndicatorState({
  title: "running",
  value: DEFAULT_STATUS_VALUE,
  displayType: new ShapeStateDisplay({
    color: "#0ca919",
    shape: IndicatorShape.FullCircle
  })
});

export const DEFAULT_MULTIVALUE_STATE = new IndicatorState({
  title: DEFAULT_STATUS_VALUE,
  value: DEFAULT_STATUS_VALUE,
  displayType: new IconStateDisplay({
    color: "#000",
    icon: {
      fontClass: "abb-icon",
      char: parseInt("e951", 16)
    } as IconId
  })
});

export const BINARY_STATES: IndicatorState[] = [
  new IndicatorState({
    title: StatusTitle.Undefined,
    value: NULL_VALUE,
    displayType: new ShapeStateDisplay({
      color: "#ccc",
      shape: IndicatorShape.FullCircle
    })
  }),
  new IndicatorState({
    title: "stopped",
    value: 0,
    displayType: new ShapeStateDisplay({
      color: "#f03040",
      shape: IndicatorShape.BorderCircle
    })
  }),
  DEFAULT_BINARY_STATE
];

export const MULTIVALUE_STATES: IndicatorState[] = [
  new IndicatorState({
    title: StatusTitle.Undefined,
    value: NULL_VALUE,
    displayType: new ShapeStateDisplay({
      color: "#ccc",
      shape: IndicatorShape.FullCircle
    })
  }),
  new IndicatorState({
    title: StatusTitle.Ok,
    value: 0,
    displayType: new ShapeStateDisplay({
      color: "#0ca919",
      shape: IndicatorShape.FullCircle
    })
  }),
  new IndicatorState({
    title: StatusTitle.Warn,
    value: 1,
    displayType: new ShapeStateDisplay({
      color: "#ffd800",
      shape: IndicatorShape.FullCircle
    })
  }),
  new IndicatorState({
    title: StatusTitle.Error,
    value: 2,
    displayType: new ShapeStateDisplay({
      color: "#f03040",
      shape: IndicatorShape.BorderCircle
    })
  }),
  new IndicatorState({
    title: StatusTitle.Bad,
    value: 3,
    displayType: new ShapeStateDisplay({
      color: "#f03040",
      shape: IndicatorShape.BorderCircle
    })
  }),
  new IndicatorState({
    title: StatusTitle.Off,
    value: 4,
    displayType: new ShapeStateDisplay({
      color: "#cb2bd5",
      shape: IndicatorShape.FullCircle
    })
  }),
  DEFAULT_MULTIVALUE_STATE
];
