import { ActionReducerMap } from "@ngrx/store/src/models";
import * as fromAppSettings from "./app-settings/app-settings.reducer";
import * as fromAppStatus from "./app-status/app-status.reducer";
import * as fromProjectEnvironemnt from "./project-environment/project-environment.reducer";
import { EnvironmentState } from "./state";
import * as fromTimeInfo from "./time-info/time-info.reducer";

export const reducers: ActionReducerMap<EnvironmentState> = {
  appSettings: fromAppSettings.reducer,
  projectEnvironment: fromProjectEnvironemnt.reducer,
  appStatus: fromAppStatus.reducer,
  timeInfo: fromTimeInfo.reducer
};
