import { construct } from "../../../core";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { Configurable, EditableType, Serializable } from "../../../meta/decorators";
import { EditorType } from "../../../meta/models/editor-type";
import { DeepPartial } from "../../../ts-utils";
import { BaseViewConfigDto } from "../../models/base-view-config";
import { ComponentCssSize } from "../../models/component-size";

const TYPE_NAME = "MapViewConfig";

// @dynamic
@EditableType({ fullName: TYPE_NAME })
export class MapViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.TextColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#004C97")
  textColor!: string;

  constructor(viewConfigDto: DeepPartial<MapViewConfig> = {}) {
    super();
    construct(this, viewConfigDto, TYPE_NAME, { size: getDefaultSize() });
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("750", "500");
}
