import { Action, createReducer, on } from "@ngrx/store";
import { Equipment } from "../../../core/models/equipment";
import { Maybe } from "../../../ts-utils";
import { EquipmentActions } from "./equipment.actions";

export interface State {
  root: Maybe<Equipment>;
  loading: boolean;
}

const initialState: State = {
  root: null,
  loading: false
};

export function reducer(state: State, action: Action) {
  return _reducer(state, action);
}

const _reducer = createReducer(
  initialState,
  on(EquipmentActions.loadEquipmentModel, (state) => onLoadEquipmentModel(state)),
  on(EquipmentActions.loadEquipmentModelSuccess, (state, { rootEquipment }) =>
    onLoadEquipmentModelSuccess(rootEquipment)
  )
);

function onLoadEquipmentModel(state: State) {
  return { ...state, loading: true };
}

function onLoadEquipmentModelSuccess(rootEquipment: Equipment) {
  return {
    root: rootEquipment,
    loading: false
  };
}
