import { Action, createReducer, on } from "@ngrx/store";
import { IName } from "../../../core";
import { ProjectEnvironment } from "../../models/project-environment";
import { ProjectEnvironmentActions } from "./project-environment.actions";

const initialState: ProjectEnvironment = {
  name: "",
  appTitle: {
    projectName: {
      fullName: "Web GUI",
      shortName: ""
    },
    productName: { fullName: "", shortName: "" }
  },
  standalone: true,
  isPushDataMode: false
};

export function reducer(state: ProjectEnvironment, action: Action) {
  return _reducer(state, action);
}

const _reducer = createReducer(
  initialState,
  on(ProjectEnvironmentActions.updateEnvironmentName, (state, { environmentName }) =>
    updateEnvironmentName(state, environmentName)
  ),
  on(ProjectEnvironmentActions.setProjectName, (state, projectName) =>
    setProjectName(state, projectName)
  ),
  on(ProjectEnvironmentActions.setProductName, (state, productName) =>
    setProductName(state, productName)
  ),
  on(ProjectEnvironmentActions.updateStandalone, (state, { standalone }) =>
    updateStandalone(state, standalone)
  ),
  on(ProjectEnvironmentActions.setPushDataMode, (state, { pushModeActive }) =>
    setPushDataMode(state, pushModeActive)
  )
);

function updateEnvironmentName(state: ProjectEnvironment, newName: string): ProjectEnvironment {
  return {
    ...state,
    name: newName
  };
}

function setProjectName(state: ProjectEnvironment, newProjectName: IName): ProjectEnvironment {
  return {
    ...state,
    appTitle: {
      ...state.appTitle,
      projectName: newProjectName
    }
  };
}

function setProductName(state: ProjectEnvironment, newProductName: IName): ProjectEnvironment {
  return {
    ...state,
    appTitle: {
      ...state.appTitle,
      productName: newProductName
    }
  };
}

function updateStandalone(
  state: ProjectEnvironment,
  standaloneUpdate: boolean
): ProjectEnvironment {
  return {
    ...state,
    standalone: standaloneUpdate
  };
}

function setPushDataMode(state: ProjectEnvironment, pushModeActive: boolean): ProjectEnvironment {
  return {
    ...state,
    isPushDataMode: pushModeActive
  };
}
