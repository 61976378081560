import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { EnvironmentSelector } from "../environment/services/environment.selector";
import { Dictionary, isDefined } from "../ts-utils";
import { DEFAULT_LANGUAGE, languagesFullNameDict, supportedLanguages } from "./languages";
import { ButtonsLocalizationDictionary } from "./models/buttons-localization-dictionary";
import { DialogsLocalizationDictionary } from "./models/dialogs-localization-dictionary";
import { FilterComponentLocalizationDictionary } from "./models/filter-component-localization-dictionary";
import { GeneralSettingsLocalizationDictionary } from "./models/general-settings-localization-dictionary";
import { LayoutEditorLocalizationDictionary } from "./models/layout-editor-localization-dictionary";
import { LocalizationDictionary } from "./models/localization-dictionary";
import { PropertySheetLocalizationDictionary } from "./models/property-sheet-localization-dictionary";
import { SidebarLocalizationDictionary } from "./models/sidebar-localization-dictionary";
import { SnackBarMessagesLocalizationDictionary } from "./models/snackbar-messages-localization-dictionary";
import { TranslationFallback } from "./translation-fallback";

@Injectable({
  providedIn: "root"
})
export class LocalizationService {
  localizationDictionary: LocalizationDictionary = new LocalizationDictionary();

  get propertySheet(): PropertySheetLocalizationDictionary {
    return this.localizationDictionary.propertySheet;
  }

  get generalSettings(): GeneralSettingsLocalizationDictionary {
    return this.localizationDictionary.generalSettings;
  }

  get buttons(): ButtonsLocalizationDictionary {
    return this.localizationDictionary.buttons;
  }

  get sidebar(): SidebarLocalizationDictionary {
    return this.localizationDictionary.sidebar;
  }

  get filterComponent(): FilterComponentLocalizationDictionary {
    return this.localizationDictionary.filterComponent;
  }

  get layoutEditor(): LayoutEditorLocalizationDictionary {
    return this.localizationDictionary.layoutEditor;
  }

  get snackBarMessages(): SnackBarMessagesLocalizationDictionary {
    return this.localizationDictionary.snackBarMessages;
  }

  get dialogs(): DialogsLocalizationDictionary {
    return this.localizationDictionary.dialogs;
  }

  constructor(
    public translateService: TranslateService,
    private environmentSelector: EnvironmentSelector
  ) {
    this.init();
  }

  init(): void {
    this.translateService.setDefaultLang(DEFAULT_LANGUAGE);
    // FIXME: remove when loading languages from backend is implemented
    this.setSupportedLanguages(supportedLanguages);

    this.environmentSelector.selectCurrentLanguage().subscribe((currentLanguage) => {
      if (isDefined(currentLanguage)) {
        const languagePart = currentLanguage.split("-")[0];
        // FIXME: use this instead of split once Intl.Locale is part of typescript standard
        // new Intl.Locale("currentLanguage").language;
        this.translateService.use(languagePart);
      }
    });
  }

  get(key: string, interpolateParams?: object): string {
    return this.translateService.instant(key, interpolateParams);
  }

  getWithFallback(key: string, fallback: string): string {
    const translationFallback: TranslationFallback = { fallback: fallback };
    return this.get(key, translationFallback);
  }

  getSupportedLanguages(): Dictionary<string> {
    return this.translateService.getLangs().reduce((acc: Dictionary<string>, languageCode) => {
      if (typeof languagesFullNameDict[languageCode] !== "undefined") {
        const languageFullName = languagesFullNameDict[languageCode];
        acc[languageFullName] = languageCode;
      }
      return acc;
    }, {});
  }

  fetchSupportedLanguages(): string[] {
    throw new Error("Not implemented");
  }

  setSupportedLanguages(languages: string[]): void {
    this.translateService.addLangs(Object.values(languages));
  }

  setTranslation(translationDict: Dictionary<Dictionary<string>>): void {
    this.translateService.setTranslation(this.translateService.currentLang, translationDict, true);
  }
}
