import { getConnectorGroupId } from "../../data-connectivity/helpers/data-connector-view.helper";
import { DATA_CONNECTOR_DTO } from "../../elements/models/entity-type.constants";
import { DataConnectorViewSelector } from "../../elements/services/entity-selectors/data-connector-view.selector";
import { isDefined } from "../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { Group, ItemType } from "../components/array-editor/models";

const GROUP_NUMBER = 2;

export function updateItemsGroupId(items: ItemType[], newGroupId: string): ItemType[] {
  return items.map((item: ItemType) => ({ ...item, groupId: newGroupId }));
}

export function resolveNewGroupId(groups: Group[], groupNamePrefix: string): string {
  const pattern = `(${groupNamePrefix})?\\((\\d+)\\)`;
  let maxNumber = 1;

  groups.forEach((group: Group) => {
    const result = group.id.match(pattern);

    if (isDefined(result)) {
      const numberOfGroup = Number(result[GROUP_NUMBER]);
      if (maxNumber <= numberOfGroup) {
        maxNumber = numberOfGroup;
        maxNumber++;
      }
    }
  });
  return `${groupNamePrefix}(${maxNumber})`;
}

export function addOrMoveItemToGroup(
  groups: Group[],
  groupId: string,
  itemToChange: Maybe<ItemType>,
  connectorViewSelector: DataConnectorViewSelector
): Group[] {
  const itemGroupId: string = getItemGroupId(itemToChange, connectorViewSelector);

  const oldGroupIndex: number = groups.findIndex((group: Group) => group.id === itemGroupId);
  const newGroupIndex: number = groups.findIndex((group: Group) => group.id === groupId);

  if (oldGroupIndex === newGroupIndex) {
    return groups;
  }
  if (oldGroupIndex > -1) {
    groups[oldGroupIndex] = removeItemFromGroup(groups[oldGroupIndex], itemToChange);
  }
  const newItem = { ...itemToChange, groupId };
  if (newGroupIndex > -1) {
    groups[newGroupIndex].items.push(newItem);
  }
  return groups;
}

export function getItemGroupId(
  item: Maybe<ItemType>,
  dataConnectorViewSelector: DataConnectorViewSelector
): string {
  return item.typeName === DATA_CONNECTOR_DTO
    ? getConnectorGroupId(item.id, dataConnectorViewSelector)
    : item.groupId;
}

export function removeItemFromGroup(group: Group, itemToRemove: ItemType): Group {
  const itemIndex = group.items.findIndex((item: ItemType) => item.id === itemToRemove.id);
  group.items.splice(itemIndex, 1);
  return group;
}

export function getDefaultGroupName(target: any): Maybe<string> {
  if (isDefined(target.view) && isDefined(target.view.groupName)) {
    return target.view.groupName;
  }
}

export function getItemById(group: Group, itemId: string): Maybe<ItemType> {
  return group.items.find((groupItem: ItemType) => groupItem.id === itemId);
}
