<div
  class="history-view__dialog"
  cdkDrag
  cdkDragBoundary=".history-view"
  [ngClass]="{ 'history-view__dialog--maximized': isMaximized }"
>
  <div class="history-view__dialog-header" cdkDragHandle>
    <i
      class="abb-icon abb-icon--medium Link dialog-header__button"
      (click)="copyConfigurationToClipboard()"
      title="{{ translationService.buttons.CopyHistoryViewToClipboardTooltip | translate }}"
    ></i>
    <i
      class="dialog-header__button"
      [ngClass]="
        trendShown
          ? 'abb-icon abb-icon--medium Left_arrow'
          : 'dashboard-icon dashboard-icon--medium icon-KM-Trend'
      "
      (click)="toggleTrendVisibility()"
    ></i>
    <i
      class="abb-icon abb-icon--medium dialog-header__button"
      [ngClass]="isMaximized ? 'Minimize' : 'Maximize'"
      (click)="toggleDialogMaximize()"
    ></i>
    <i
      class="abb-icon abb-icon--medium Close dialog-header__button"
      (click)="closeHistoryView()"
    ></i>
  </div>
  <div
    class="history-view__dialog-body"
    [style.width.px]="initialDialogSize.widthInPx"
    [style.height.px]="initialDialogSize.heightInPx"
    [ngClass]="{ 'history-view__dialog-body--maximized': isMaximized }"
    #dialogBody
  >
    <div class="history-view__cards-container" [@flip]="trendShown">
      <div
        class="history-view__expanded-card-container"
        [ngClass]="{ 'content-hidden': trendShown }"
      >
        <div class="history-view__expanded-card-player">
          <div class="player__wrapper">
            <c-time-scrubber
              class="player__scrubber"
              [dateTimeRange]="currentTimeRange"
              #timeScrubber
              (timestampChanged)="updateComponentCacheTimestamp($event)"
              (playbackEnded)="stopHistory()"
              [class.disabled]="trendShown"
            >
            </c-time-scrubber>
            <div class="history-view__player-button-wrapper">
              <div>
                <i
                  class="abb-icon Play abb-icon--medium history-view__player-button"
                  *ngIf="!playModeOn"
                  (click)="playHistory()"
                ></i>
                <i
                  class="abb-icon Pause abb-icon--medium history-view__player-button"
                  *ngIf="playModeOn"
                  (click)="stopHistory()"
                ></i>
              </div>
              <i
                #calendar
                class="abb-icon Time_and_date abb-icon--medium history-view__player-button"
                (click)="onTogglePickerVisibility($event)"
              ></i>
            </div>
          </div>
        </div>
        <div class="history-view__expanded-card">
          <div class="expanded-card__placeholder-wrapper">
            <c-hv-placeholder
              [displayedComponentId]="expandedCardId"
              [style.transform]="scalingTransformation"
            >
            </c-hv-placeholder>
          </div>
        </div>
      </div>
      <div class="history-view__trend-card" [ngClass]="{ 'content-hidden': !trendShown }">
        <c-hv-placeholder
          [displayedComponentId]="trendContainerId"
          class="history-view__trend-placeholder"
        ></c-hv-placeholder>
      </div>
    </div>
  </div>
</div>

<div
  class="history-view__time-picker-wrapper"
  [ngClass]="{ 'element--hidden': !isDateTimePickerOpened }"
  [style.left.px]="calendar.getBoundingClientRect().left - 400"
  [style.top.px]="calendar.getBoundingClientRect().top - 400"
>
  <date-time-range-picker
    class="date-time-range-picker"
    [isOpened]="isDateTimePickerOpened"
    [dateTimeRange]="currentTimeRange"
    (onChangeDisplay)="togglePickerVisibility()"
    (onDateTimeChange)="onTimeRangeChange($event)"
  >
  </date-time-range-picker>
</div>
