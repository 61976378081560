import { Component, ElementRef } from "@angular/core";
import { DraggedItemType } from "../../../core/models/drag/dragged-item-type";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { LayoutBuilder } from "../../../meta/decorators/layout-builder.decorator";
import { ComponentCategory } from "../../../meta/models/component-category";
import { MaxConnectors } from "../../decorators/max-connectors.decorator";
import { View } from "../../decorators/view.decorator";
import { isContainerWidget } from "../../models/component-type.helper";
import { BaseComponent } from "../base/base.component";
import { ComponentConstructorParams } from "../base/component-constructor-params";
import { ImageViewConfig } from "./view-config";

@Component({
  selector: "c-image",
  templateUrl: "./image.component.html",
  styleUrls: ["./image.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: ImageComponent }]
})
@LayoutBuilder(
  ComponentCategory.Glitter,
  "ImageComponent",
  "Image",
  "abb-icon",
  null,
  LOCALIZATION_DICTIONARY.layoutEditor.Image
)
@MaxConnectors(0)
@EditableWidget({ fullName: "ImageComponent", title: "image" })
export class ImageComponent extends BaseComponent {
  constructor(params: ComponentConstructorParams, hostElementRef: ElementRef) {
    super(params, hostElementRef);
  }

  @View(ImageViewConfig)
  public get view() {
    return this.currentState.view as ImageViewConfig;
  }

  public get imageUrl() {
    const imageIsDefined = this.view && this.view.image;
    return imageIsDefined ? this.view.image : "";
  }

  canAcceptDrop(): boolean {
    const target = this.draggedComponentService.target;
    return (
      target?.type === DraggedItemType.Component &&
      !isContainerWidget(target?.item?.descriptor?.name)
    );
  }
}
