import { Component, OnInit, Input } from "@angular/core";
import { LegendItemConfig } from "./legend-item-config";
import { BulletType } from "../../models";

@Component({
  selector: "km-legend-item",
  templateUrl: "./legend-item.component.html",
  styleUrls: ["./legend-item.component.scss"]
})
export class LegendItemComponent implements OnInit {
  BulletType = BulletType;
  @Input() legendItem: LegendItemConfig;
  constructor() {}

  ngOnInit() {}
}
