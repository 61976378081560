import { Injectable } from "@angular/core";
import { first, filter } from "rxjs/operators";
import { AppSettings } from "../models/app-settings";
import { EnvironmentSelector } from "./environment.selector";

@Injectable({ providedIn: "root" })
export class AppSettingsService {
  public Settings: AppSettings;

  constructor(private environmentSelector: EnvironmentSelector) {
    this.environmentSelector
      .selectAppSettings()
      .pipe(
        filter((settings) => settings != null),
        first()
      )
      .subscribe((settings: AppSettings) => {
        this.Settings = settings;
      });
  }
}
