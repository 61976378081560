import { Injectable } from "@angular/core";
import { EntityId } from "../../meta";
import { ParentEntityInfo } from "../../meta/models/parent-entity-info";
import { Maybe } from "../../ts-utils/models/maybe.type";

@Injectable()
export class IEntityUpdater {
  public update(
    entityId: EntityId,
    entityTypeName: string,
    changes: any,
    parentEntityInfo: Maybe<ParentEntityInfo>
  ): void {
    throw new Error("Not implemented");
  }

  public shallowUpdate(entityId: EntityId, entityTypeName: string, changes: any): void {
    throw new Error("Not implemented");
  }
}
