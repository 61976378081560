import { Injectable, OnDestroy } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Update } from "@ngrx/entity";
import { Action, Store } from "@ngrx/store";
import { cloneDeep as _cloneDeep } from "lodash";
import { Subject, combineLatest, fromEvent, of } from "rxjs";
import { concatMap, debounceTime, map, switchMap, takeUntil, withLatestFrom } from "rxjs/operators";
import { LinkDto } from "../../../core/models/link";
import { PositionDto } from "../../../core/models/position";
import { FilterFactory } from "../../../core/services/filter/filter-factory.service";
import { ErrorHandlingActions } from "../../../core/store/error-handling/error-handling.actions";
import {
  ApiDataSourceDto,
  ApiParameter,
  DataConnectorDto,
  DataService,
  KeyValuePair
} from "../../../data-connectivity";
import { getConnectorViewId } from "../../../data-connectivity/helpers/connector-view-id.helper";
import {
  isEmptySource,
  isEquipment,
  isEquipmentPartial
} from "../../../data-connectivity/helpers/data-source-type.helper";
import { DataAggregationConfigDto } from "../../../data-connectivity/models/data-aggregation-config";
import { DataConnectorViewDto } from "../../../data-connectivity/models/data-connector-view";
import { DataSourceDto } from "../../../data-connectivity/models/data-source/data-source";
import { SERIES_TYPE_DEFAULT } from "../../../data-connectivity/models/series-type.strategies";
import { ApiDataSourceDescriptorSelector } from "../../../data-connectivity/store/api-data-source-descriptor";
import { AppStatusActions } from "../../../environment/store/app-status/app-status.actions";
import { LocalizationService } from "../../../i18n/localization.service";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { CutOffStrategy } from "../../../meta/models/cut-off-strategy";
import { EntityId } from "../../../meta/models/entity";
import { PropertySheetService } from "../../../property-sheet/services/property-sheet.service";
import {
  DeepPartial,
  Dictionary,
  isDefined,
  isEmpty,
  isEmptyOrNotDefined,
  isNotDefined
} from "../../../ts-utils";
import { DeepUpdate } from "../../../ts-utils/models/deep-update.type";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { BasicCardViewConfig } from "../../components/basic-card/view-config";
import { ContainerComponentViewConfig } from "../../components/container/view-config";
import { NavigationBarViewConfig } from "../../components/navigation-bar/view-config";
import { StrategizedChartViewConfig } from "../../components/strategized-chart/view-config";
import { TimeSeriesViewConfig } from "../../components/time-series/view-config";
import {
  getComponentsWithForegroundColor,
  getNeutralColorForText
} from "../../helpers/color.helper";
import { getSizeUpdateAction } from "../../helpers/component-size.helper";
import {
  dynamicConnectorsNeedCleanup,
  equipmentDcqNeedsResolution,
  genericOrApiDcqNeedsUpdate
} from "../../helpers/connector-resolution.helper";
import { getPseudoConnectorId, isPseudoConnector } from "../../helpers/connectors.helper";
import { getFullRequestActions } from "../../helpers/full-range-request-actions.helper";
import {
  getAllRequestParams,
  getGenericRequestParams
} from "../../helpers/request-params-creation.helper";
import { getRuntimeViewPropsUpdate, getRuntimeViewUpdate } from "../../helpers/runtime-view.helper";
import { ComponentCssSize } from "../../models/component-size";
import { ComponentStateDto, hasChildren } from "../../models/component-state";
import { isTimeSeries } from "../../models/component-type.helper";
import { DataStatus } from "../../models/data-status";
import { FullComponentStateVM } from "../../models/full-component-state-vm";
import { MultiplePasteEntities } from "../../models/multiple-paste-entities";
import { NavLinkInfo } from "../../models/nav-link-info";
import { YAxisDescriptor } from "../../models/y-axis-descriptor";
import {
  ClipboardService,
  calculatePasteOffsetObject,
  calculateWidgetFitPosition,
  getCardPositioningUpdateActionOnPaste,
  getWidgetPositioningUpdateActionOnPaste,
  offsetPastedComponents,
  resolveConsecutivePasteCount
} from "../../services/clipboard.service";
import { reducePasteEntities } from "../../services/cloning.service";
import {
  ComponentSelectionService,
  findSelectableParent
} from "../../services/component-selection.service";
import { shouldUpdateConnectorsContext } from "../../services/connector-context.helper";
import { ComponentStateSelector } from "../../services/entity-selectors/component-state.selector";
import { DataConnectorViewSelector } from "../../services/entity-selectors/data-connector-view.selector";
import { DataConnectorSelector } from "../../services/entity-selectors/data-connector.selector";
import { RuntimeViewService } from "../../services/runtime-view.service";
import { CommonActions } from "../common/common.actions";
import { ComponentCounterActions } from "../component-counter/component-counter.actions";
import { DataConnectorViewActions } from "../data-connector-view/data-connector-view.actions";
import { DataConnectorActions } from "../data-connector/data-connector.actions";
import { RuntimeSettingsActions } from "../runtime-settings/runtime-settings.actions";
import { ComponentStateActions } from "./component-state.actions";
import { selectComponentStateEntities } from "./component-state.selectors";

@Injectable()
export class ComponentStateEffects implements OnDestroy {
  private unsubscribeSubject$ = new Subject();

  constructor(
    private actions$: Actions,
    private componentStateSelector: ComponentStateSelector,
    private dataConnectorSelector: DataConnectorSelector,
    private dataConnectorViewSelector: DataConnectorViewSelector,
    private apiDataSourceDescriptorSelector: ApiDataSourceDescriptorSelector,
    private filterFactory: FilterFactory,
    private translationService: LocalizationService,
    private componentSelectionService: ComponentSelectionService,
    private store$: Store<any>,
    private dataService: DataService,
    private runtimeViewService: RuntimeViewService,
    private clipboardService: ClipboardService,
    private propertySheetService: PropertySheetService
  ) {}

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  // #region RESPONSIVENESS
  calculateRuntimeView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentStateActions.calculateRuntimeViewProps),
      withLatestFrom(this.store$.select(selectComponentStateEntities)),
      concatMap(([_, componentStates]: [any, Dictionary<ComponentStateDto | undefined>]) => {
        const updates = getRuntimeViewUpdate(componentStates, this.runtimeViewService);
        const contextUpdateActions: Action[] = updates.reduce((acc: Action[], update) => {
          const component: ComponentStateDto = componentStates[update.componentId];

          if (shouldUpdateConnectorsContext(component, update)) {
            const connectors = this.dataConnectorSelector.getForComponent(component.id);
            if (isEmpty(connectors)) {
              return acc;
            }
            return acc.concat([
              DataConnectorActions.setContextMany({
                componentId: component.id,
                connectors
              })
            ]);
          }
          return acc;
        }, []);

        if (updates.length > 0) {
          return [
            ComponentStateActions.updateRuntimeViewProps({ updates }),
            ...contextUpdateActions
          ];
        } else {
          return [CommonActions.doNothing()];
        }
      })
    )
  );

  scaleComponentsOnWindowResize$ = createEffect(() =>
    fromEvent(window, "resize").pipe(
      debounceTime(300),
      map(() => ComponentStateActions.calculateRuntimeViewProps()),
      takeUntil(this.unsubscribeSubject$)
    )
  );

  calculateCardSize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        RuntimeSettingsActions.setPagePreviewWidth,
        ComponentStateActions.updateComponentSize,
        ComponentStateActions.updateExpanded
      ),
      map(() => ComponentStateActions.calculateRuntimeViewProps())
    )
  );

  calculateNewComponentSize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentStateActions.addOne),
      withLatestFrom(this.store$.select(selectComponentStateEntities)),
      switchMap(
        ([{ newComponent, parentId }, componentStates]: [
          { newComponent: ComponentStateDto; parentId: Maybe<EntityId> },
          Dictionary<ComponentStateDto | undefined>
        ]) => {
          if (isNotDefined(parentId)) {
            return [CommonActions.doNothing()];
          }
          const parent = componentStates[parentId];
          if (isNotDefined(parent)) {
            return [CommonActions.doNothing()];
          }
          const parentContentSize = this.runtimeViewService.calculateContentSize(parent.view);
          const updates = getRuntimeViewPropsUpdate(
            componentStates,
            newComponent.id,
            parentContentSize,
            this.runtimeViewService
          );
          let actions: Action[] = [ComponentStateActions.updateRuntimeViewProps({ updates })];
          if (!isEmptySource(newComponent.dataConnectorQuery)) {
            const componentUpdate: Update<ComponentStateDto> = {
              id: newComponent.id as string,
              changes: newComponent
            };
            const queryActions: Action[] = this.getDcqUpdateActions(componentUpdate, newComponent);
            actions = [...actions, ...queryActions];
          }
          return actions;
        }
      )
    )
  );

  // #endregion
  // #region PREVIEW MODE
  enterPreviewMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppStatusActions.enterPreviewMode),
      concatMap(() => {
        this.componentSelectionService.clearSelection();
        return [ComponentStateActions.calculateRuntimeViewProps()];
      })
    )
  );

  // #endregion
  // #region CLEAR SELECTION
  removeDeletedFromSelection$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ComponentStateActions.deleteMany),
        map(({ targetComponents }) => targetComponents.map((component) => component.id)),
        map((componentIds) => this.componentSelectionService.removeFromSelected(componentIds))
      ),
    { dispatch: false }
  );

  removeOneFromSelection$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ComponentStateActions.deleteOne),
        map(({ targetComponent }) => targetComponent.id),
        map((componentId) => this.componentSelectionService.removeFromSelected([componentId]))
      ),
    { dispatch: false }
  );
  //#endregion
  // #region UPDATE ONE
  updateOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentStateActions.updateOne),
      concatMap(({ componentUpdate }) => {
        const updatedComponent: Maybe<ComponentStateDto> = this.componentStateSelector.getById(
          componentUpdate.id
        );
        if (isNotDefined(updatedComponent)) {
          return [CommonActions.doNothing()];
        }

        const dcqUpdate: Maybe<DeepPartial<DataSourceDto>> = getDcqUpdate(componentUpdate);
        const sizeUpdate: Maybe<DeepPartial<ComponentCssSize>> = getSizeUpdate(componentUpdate);
        const linkUpdate: Maybe<DeepPartial<LinkDto>> = getLinkUpdate(componentUpdate);
        const navBarLinkUpdate: Maybe<DeepPartial<NavLinkInfo[]>> =
          getNavBarLinkUpdate(componentUpdate);
        const strategyUpdate: Maybe<string> = getDisplayTypeUpdate(componentUpdate);
        const backgroundImageUpdate: Maybe<string> = getBackgroundImageUpdate(componentUpdate);
        const cutOffStrategyUpdate: Maybe<CutOffStrategy> =
          getCutOffStrategyUpdate(componentUpdate);
        const backgroundColorUpdate: Maybe<string> = getBackgroundColorUpdate(componentUpdate);
        const yAxesUpdate: Maybe<YAxisDescriptor[]> = getYAxesUpdate(componentUpdate);

        if (isDefined(dcqUpdate)) {
          return this.getDcqUpdateActions(componentUpdate, updatedComponent);
        } else if (isDefined(sizeUpdate)) {
          return [getSizeUpdateAction(sizeUpdate, updatedComponent.id)];
        } else if (isDefined(linkUpdate)) {
          return [
            ComponentStateActions.updateLink({
              componentId: componentUpdate.id,
              link: linkUpdate
            })
          ];
        } else if (isDefined(navBarLinkUpdate)) {
          return [
            ComponentStateActions.updateNavBarLinks({
              componentId: componentUpdate.id,
              links: navBarLinkUpdate
            })
          ];
        } else if (isDefined(strategyUpdate)) {
          const actions: Action[] = [];
          let componentConnectors: DataConnectorDto[] = this.dataConnectorSelector.getForComponent(
            componentUpdate.id
          );
          const actionToDeletePseudoConn: Maybe<Action> = getActionToDeletePseudoConnector(
            componentConnectors,
            componentUpdate.id
          );
          if (isDefined(actionToDeletePseudoConn)) {
            componentConnectors = componentConnectors.filter(
              (connector: DataConnectorDto) => !isPseudoConnector(connector.id)
            );
            actions.push(actionToDeletePseudoConn);
          }
          actions.push(
            DataConnectorActions.setContextMany({
              componentId: componentUpdate.id,
              connectors: componentConnectors
            })
          );
          let connectorViewUpdates: DeepUpdate<DataConnectorViewDto>[] = [];

          if (isTimeSeries(updatedComponent.type)) {
            connectorViewUpdates = getUpdatedConnectorView(
              componentConnectors,
              this.dataConnectorViewSelector
            );

            if (!isEmpty(connectorViewUpdates)) {
              actions.push(DataConnectorViewActions.updateMany({ connectorViewUpdates }));
            }
          }
          return actions;
        } else if (shouldUpdateCardRuntimeView(componentUpdate)) {
          return [ComponentStateActions.calculateRuntimeViewProps()];
        } else if (isDefined(backgroundImageUpdate)) {
          return [
            ComponentStateActions.resolveBackgroundImage({
              componentId: componentUpdate.id,
              imageUrl: backgroundImageUpdate
            })
          ];
        } else if (isDefined(cutOffStrategyUpdate)) {
          return this.resolveRequestParams(updatedComponent);
        } else if (isDefined(backgroundColorUpdate)) {
          const foregroundColor: string = getForegroundColor(
            this.componentStateSelector,
            updatedComponent.id,
            backgroundColorUpdate
          );

          return !isEmpty(updatedComponent.childrenIds)
            ? [
                ComponentStateActions.updateMany({
                  componentUpdates: getComponentsWithForegroundColor(
                    updatedComponent,
                    this.componentStateSelector.getAllAsDict(),
                    foregroundColor
                  )
                })
              ]
            : [CommonActions.doNothing()];
        } else if (isDefined(yAxesUpdate)) {
          const validYAxes: string[] = yAxesUpdate.map((descriptor) => descriptor.id);
          return [DataConnectorViewActions.clearConnectorsFromDeletedYAxis({ validYAxes })];
        } else {
          return [CommonActions.doNothing()];
        }
      })
    )
  );

  private getDcqUpdateActions(
    componentUpdate: DeepUpdate<ComponentStateDto>,
    componentInStore: ComponentStateDto
  ): Action[] {
    const actions: Action[] = [];
    const dcqUpdate = getDcqUpdate(componentUpdate) as DeepPartial<DataSourceDto>;
    const updatedDcq: DataSourceDto = componentInStore.dataConnectorQuery;
    const aggregationConfigUpdate: Maybe<DeepPartial<DataAggregationConfigDto>> =
      getAggregationConfigUpdate(componentUpdate);
    const currentDynamicConnectors: DataConnectorDto[] = getDynamicConnectors(
      componentInStore,
      this.dataConnectorSelector
    );
    const apiUrlUpdate: Maybe<string> = getUpdatedUrl(componentUpdate);

    dcqUpdate.typeName = updatedDcq.typeName;

    if (isDefined(apiUrlUpdate)) {
      const currentParams = (componentInStore.dataConnectorQuery as ApiDataSourceDto).params;
      const paramsToUpdate: KeyValuePair[] = getParamsToUpdateOnUrlChange(
        componentUpdate,
        apiUrlUpdate,
        this.apiDataSourceDescriptorSelector,
        currentParams
      );
      actions.push(
        ComponentStateActions.updateApiQueryParams({
          componentId: componentUpdate.id,
          params: paramsToUpdate as DeepPartial<DataSourceDto>
        })
      );
      componentInStore = {
        ...componentInStore,
        dataConnectorQuery: {
          ...componentInStore.dataConnectorQuery,
          params: paramsToUpdate
        } as ApiDataSourceDto
      };
    }
    if (
      isEquipmentPartial(dcqUpdate) &&
      isEquipment(updatedDcq) &&
      equipmentDcqNeedsResolution(dcqUpdate, updatedDcq)
    ) {
      return [
        DataConnectorActions.resolveEquipmentQuery({
          component: componentInStore
        })
      ];
    } else if (genericOrApiDcqNeedsUpdate(dcqUpdate, updatedDcq)) {
      const requestParams = getGenericRequestParams([componentInStore], this.filterFactory);
      actions.push(...getFullRequestActions(requestParams));
      return actions;
    } else if (dynamicConnectorsNeedCleanup(currentDynamicConnectors)) {
      actions.push(
        DataConnectorActions.deleteMany({
          connectorsByComponent: {
            [componentUpdate.id]: currentDynamicConnectors
          }
        }),
        DataConnectorActions.updateDataStatusMany({
          dataConnectorQueryStatusDic: {
            [componentUpdate.id]: DataStatus.NoDataDefined
          },
          dataConnectorStatusDic: {}
        })
      );
      return actions;
    } else if (isDefined(aggregationConfigUpdate)) {
      actions.push(...this.resolveRequestParams(componentInStore));
      return actions;
    } else {
      // empty
      actions.push(
        DataConnectorActions.updateDataStatusMany({
          dataConnectorQueryStatusDic: {
            [componentUpdate.id]: DataStatus.NoDataDefined
          },
          dataConnectorStatusDic: {}
        })
      );
      return actions;
    }
  }

  private resolveRequestParams(updatedComponent: ComponentStateDto): Action[] {
    const requestParams = getAllRequestParams(
      [updatedComponent],
      this.dataConnectorSelector.getForComponent(updatedComponent.id),
      this.componentStateSelector,
      this.filterFactory
    );
    return getFullRequestActions(requestParams);
  }

  updateResolvedBackgroundImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentStateActions.resolveBackgroundImage),
      switchMap(({ componentId, imageUrl }) => {
        const imageData$ = this.dataService.getImage(imageUrl);
        return combineLatest([of(componentId), imageData$]);
      }),
      map(([componentId, imageData]) => {
        const backgroundImageDataUpdate: DeepUpdate<ComponentStateDto> = {
          id: componentId.toString(),
          changes: {
            view: {
              backgroundImageData: imageData as string
            }
          }
        };
        return ComponentStateActions.updateOne({
          componentUpdate: backgroundImageDataUpdate
        });
      })
    )
  );

  // #endregion
  onDeleteComponent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentStateActions.deleteOne),
      map(({ targetComponent }) => {
        if (targetComponent == null) {
          return CommonActions.doNothing();
        } else {
          const connectorsToDelete = this.dataConnectorSelector.getManyByIdAsArray(
            targetComponent.dataConnectorIds
          );
          return DataConnectorActions.deleteMany({
            connectorsByComponent: {
              [targetComponent.id]: connectorsToDelete
            }
          });
        }
      })
    )
  );

  onDeleteManyComponents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentStateActions.deleteMany),
      concatMap(({ targetComponents }) => {
        if (targetComponents == null) {
          return [CommonActions.doNothing()];
        } else {
          const actions: Action[] = [];
          const dataConnectorsByComponent: Dictionary<DataConnectorDto[]> = targetComponents.reduce(
            (acc: Dictionary<DataConnectorDto[]>, componentState) => {
              acc[componentState.id] = this.dataConnectorSelector.getManyByIdAsArray(
                componentState.dataConnectorIds
              );
              const actionToDeletePseudoConnView: Maybe<Action> = getActionToDeletePseudoConnView(
                this.dataConnectorViewSelector,
                componentState.id
              );
              if (isDefined(actionToDeletePseudoConnView)) {
                actions.push(actionToDeletePseudoConnView);
              }
              return acc;
            },
            {}
          );
          actions.push(
            DataConnectorActions.deleteMany({
              connectorsByComponent: dataConnectorsByComponent
            })
          );
          return actions;
        }
      })
    )
  );

  onDeleteManyWithChildren$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentStateActions.deleteManyWithChildren),
      map(({ targetComponents }) => this.getDeleteActionForComponentAndChildren(targetComponents))
    )
  );

  copyComponents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentStateActions.copyComponents),
      concatMap(({ componentIds }) => {
        this.clipboardService.clipboardData = this.clipboardService.initCopy(
          this.componentStateSelector.getManyById(componentIds)
        );
        return [
          ErrorHandlingActions.displayInfo({
            messageToDisplay: this.translationService.get(
              LOCALIZATION_DICTIONARY.snackBarMessages.Copy
            )
          })
        ];
      })
    )
  );

  cutComponent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentStateActions.cutComponents),
      concatMap(({ componentIds }) => {
        const components = this.componentStateSelector.getManyById(componentIds);
        this.clipboardService.clipboardData = this.clipboardService.initCut(components);
        let selectableParent;
        if (isDefined(components[0])) {
          selectableParent = findSelectableParent(components[0].id, this.componentStateSelector);
        }
        return [
          isDefined(selectableParent)
            ? ComponentStateActions.selectComponents({ componentIds: [selectableParent.id] })
            : CommonActions.doNothing(),
          this.getDeleteActionForComponentAndChildren(components),
          ErrorHandlingActions.displayInfo({
            messageToDisplay: this.translationService.get(
              LOCALIZATION_DICTIONARY.snackBarMessages.Cut
            )
          })
        ];
      })
    )
  );

  pasteComponent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentStateActions.pasteComponents),
      concatMap(({ pasteIntoId, pasteTargetId, areWidgetsPasted }) => {
        const clipboard = this.clipboardService.clipboardData;
        if (isNotDefined(clipboard)) {
          return [];
        }
        const pasteEntities = reducePasteEntities(this.clipboardService.initPaste(clipboard));
        const newPasteCount = resolveConsecutivePasteCount(clipboard.context, pasteIntoId);
        if (areWidgetsPasted) {
          pasteEntities.entities.componentStates = this.offsetWidgetsOnPaste(
            pasteEntities.entities.componentStates,
            true,
            pasteIntoId,
            calculatePasteOffsetObject(newPasteCount)
          );
        }
        clipboard.context.consecutivePastesDict[pasteIntoId] = newPasteCount;
        clipboard.context.isCut = false;
        this.clipboardService.clipboardData = clipboard;
        return this.getPasteActions(
          pasteEntities,
          pasteIntoId,
          pasteTargetId,
          areWidgetsPasted,
          false
        );
      })
    )
  );

  cloneComponent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentStateActions.cloneComponent),
      concatMap(({ component, cloneInto, orderSource, isWidget }) => {
        const clipboardData = this.clipboardService.initCopy([component]);
        const pasteEntities = reducePasteEntities(this.clipboardService.initPaste(clipboardData));
        if (isWidget) {
          pasteEntities.entities.componentStates = offsetPastedComponents(
            pasteEntities.entities.componentStates
          );
        }
        return this.getPasteActions(pasteEntities, cloneInto, orderSource, isWidget, false);
      })
    )
  );

  copyIntoCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentStateActions.copyComponentsIntoCard),
      concatMap(
        ({ componentsIds, copyIntoId, dropPositions, dropTargetId, isDroppedOnRightSide }) => {
          const components = this.componentStateSelector.getManyById(componentsIds);
          const clipboardData = this.clipboardService.initCopy(components);
          clipboardData.content = this.updateWidgetsPositionOnDrop(
            clipboardData.content,
            copyIntoId,
            dropPositions
          );

          return this.getPasteActions(
            reducePasteEntities(this.clipboardService.initPaste(clipboardData)),
            copyIntoId,
            dropTargetId,
            true,
            isDroppedOnRightSide
          );
        }
      )
    )
  );

  moveToContainer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentStateActions.moveToContainer),
      concatMap(
        ({
          componentsIds,
          destinationComponent,
          dropPositions,
          dropTargetId,
          isDroppedOnRightSide
        }) => {
          const components = this.componentStateSelector.getManyById(componentsIds);
          const clipboardData = this.clipboardService.initCut(components);
          const componentParent = this.componentStateSelector.getParent(components[0].id);
          if (isNotDefined(componentParent)) {
            return [];
          }
          clipboardData.content = this.updateWidgetsPositionOnDrop(
            clipboardData.content,
            destinationComponent,
            dropPositions
          );

          this.componentSelectionService.clearSelection();
          return [
            ComponentStateActions.removeFromChildren({
              childrenIds: components.map((component) => component.id),
              componentId: componentParent.id
            }),
            ...this.getPasteActions(
              reducePasteEntities(this.clipboardService.initPaste(clipboardData)),
              destinationComponent,
              dropTargetId,
              true,
              isDroppedOnRightSide
            )
          ];
        }
      )
    )
  );

  cloneCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentStateActions.cloneCard),
      concatMap(({ cardId, dropTargetId, isDroppedOnRightSide }) => {
        const card = this.componentStateSelector.getById(cardId);
        const root = this.componentStateSelector.getRoot();
        if (isDefined(card)) {
          const clipboardData = this.clipboardService.initCopy([card]);
          return this.getPasteActions(
            reducePasteEntities(this.clipboardService.initPaste(clipboardData)),
            root.id,
            dropTargetId,
            false,
            isDroppedOnRightSide
          );
        }
        return [CommonActions.doNothing()];
      })
    )
  );

  selectComponents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentStateActions.selectComponents),
      concatMap(({ componentIds }) => {
        this.componentSelectionService.selectedComponentsIds = componentIds;
        if (componentIds.length === 1) {
          const selectedComponent = this.componentStateSelector.getById(componentIds[0]);
          this.propertySheetService.openOrReplaceTarget(selectedComponent, null, true);
        }
        return [] as Action[];
      })
    )
  );

  mapChildIdsToComponentStates(componentState: ComponentStateDto): ComponentStateDto[] {
    if (!hasChildren(componentState)) {
      return [];
    }
    return componentState.childrenIds.reduce((acc: ComponentStateDto[], childComponentId) => {
      const childComponent = this.componentStateSelector.getById(childComponentId);
      if (childComponent != null) {
        acc.push(...this.mapChildIdsToComponentStates(childComponent));
        acc.push(childComponent);
      }
      return acc;
    }, []);
  }

  private getDeleteActionForComponentAndChildren(
    parentComponentStates: ComponentStateDto[]
  ): Action {
    const componentStates: ComponentStateDto[] = [...parentComponentStates];
    parentComponentStates.forEach((parentState) => {
      componentStates.push(...this.mapChildIdsToComponentStates(parentState));
    });
    if (isNotDefined(componentStates) || isEmpty(componentStates)) {
      return CommonActions.doNothing();
    }
    if (componentStates.length > 0) {
      return ComponentStateActions.deleteMany({ targetComponents: componentStates });
    }
  }

  private offsetWidgetsOnPaste<T extends ComponentStateDto | FullComponentStateVM>(
    widgets: T[],
    preventScrollbar: boolean,
    pasteIntoId: EntityId,
    offset: PositionDto
  ): T[] {
    return this.fitWidgetsToCardView(
      offsetPastedComponents(widgets, offset),
      pasteIntoId,
      preventScrollbar
    );
  }

  private updateWidgetsPositionOnDrop<T extends ComponentStateDto | FullComponentStateVM>(
    widgets: T[],
    pasteIntoId: EntityId,
    position: Dictionary<PositionDto>
  ): T[] {
    return this.fitWidgetsToCardView(
      widgets.map((component) => {
        component.view.css.top = position[component.id].top.toString() + "px";
        component.view.css.left = position[component.id].left.toString() + "px";
        return component;
      }),
      pasteIntoId,
      false
    );
  }

  private fitWidgetsToCardView<T extends ComponentStateDto | FullComponentStateVM>(
    widgets: T[],
    cardId: EntityId,
    preventScrollbar: boolean
  ): T[] {
    const cardComponent = this.componentStateSelector.getById(cardId);
    const toPx = (value: number) => `${value}px`;
    if (isDefined(cardComponent)) {
      const cardContentSize = this.runtimeViewService.calculateContentSize(cardComponent.view);
      return widgets.map((widget) => {
        const { newLeft, newTop } = calculateWidgetFitPosition(
          widget,
          preventScrollbar,
          cardContentSize
        );
        if (isDefined(newTop) && isDefined(newLeft)) {
          widget.view.css.top = toPx(newTop);
          widget.view.css.left = toPx(newLeft);
        }
        return widget;
      });
    }
    return [];
  }

  private getPasteActions(
    reportEntities: MultiplePasteEntities,
    pasteIntoId: EntityId,
    pasteTargetId: Maybe<EntityId>,
    areWidgetsPasted: boolean,
    isDroppedOnRightSide: boolean
  ): Action[] {
    return [
      CommonActions.upsertEntities({
        reportEntities: reportEntities.entities
      }),
      ComponentStateActions.updateChildren({
        parentId: pasteIntoId,
        childrenIdsToAdd: reportEntities.pastedEntitiesIds
      }),
      ComponentCounterActions.increment({
        incrementValue: reportEntities.entities.componentStates.length
      }),
      ...this.getPositioningUpdateActionsOnPaste(
        reportEntities,
        pasteIntoId,
        pasteTargetId,
        areWidgetsPasted,
        isDroppedOnRightSide
      ),
      this.getTextColorUpdateActionOnPaste(
        reportEntities.entities.componentStates,
        pasteIntoId,
        areWidgetsPasted
      ),
      ComponentStateActions.selectComponents({ componentIds: reportEntities.pastedEntitiesIds }),
      ComponentStateActions.calculateRuntimeViewProps()
    ];
  }

  private getPositioningUpdateActionsOnPaste(
    reportEntities: MultiplePasteEntities,
    pasteIntoId: EntityId,
    pasteTargetId: Maybe<EntityId>,
    areWidgetsPasted: boolean,
    isDroppedOnRightSide: boolean
  ): Action[] {
    if (areWidgetsPasted) {
      const pasteInto = this.componentStateSelector.getById(pasteIntoId);
      if (isDefined(pasteInto)) {
        const targetMode = (pasteInto.view as ContainerComponentViewConfig).positioningType;
        return getWidgetPositioningUpdateActionOnPaste(
          reportEntities,
          pasteTargetId,
          targetMode,
          this.componentStateSelector,
          isDroppedOnRightSide
        );
      }
      return [];
    } else {
      return [
        getCardPositioningUpdateActionOnPaste(reportEntities, pasteTargetId, isDroppedOnRightSide)
      ];
    }
  }

  private getTextColorUpdateActionOnPaste(
    pastedComponents: ComponentStateDto[],
    pasteIntoId: EntityId,
    areWidgetsPasted: boolean
  ): Action {
    if (areWidgetsPasted) {
      const pasteInto = this.componentStateSelector.getById(pasteIntoId);
      if (isDefined(pasteInto)) {
        const targetTextColor = pasteInto.view.foregroundColor;
        const pastedComponentsToUpdate = pastedComponents.reduce(
          (acc: Update<ComponentStateDto>[], component: ComponentStateDto) => {
            if (
              shouldUpdateTextColor(
                targetTextColor,
                component.view.foregroundColor,
                component.view.css.backgroundColor
              )
            ) {
              acc.push({
                id: component.id.toString(),
                changes: {
                  view: { foregroundColor: targetTextColor }
                } as Partial<ComponentStateDto>
              });
            }
            return acc;
          },
          []
        );
        if (!isEmpty(pastedComponentsToUpdate)) {
          return ComponentStateActions.updateMany({ componentUpdates: pastedComponentsToUpdate });
        }
      }
    }
    return CommonActions.doNothing();
  }
}

function shouldUpdateTextColor(
  targetTextColor: string,
  componentTextColor: string,
  componentBackgroundColor: Maybe<string>
): boolean {
  return targetTextColor !== componentTextColor && isEmptyOrNotDefined(componentBackgroundColor);
}

function getDynamicConnectors(
  componentState: ComponentStateDto,
  connectorSelector: DataConnectorSelector
): DataConnectorDto[] {
  return Object.values(connectorSelector.getManyById(componentState.dataConnectorIds)).filter(
    (connector) => connector.isDynamicallyCreated && !isPseudoConnector(connector.id)
  );
}

function getDcqUpdate(update: DeepUpdate<ComponentStateDto>): Maybe<DeepPartial<DataSourceDto>> {
  if (update != null && update.changes != null) {
    return _cloneDeep(update.changes.dataConnectorQuery);
  } else {
    return null;
  }
}

function getUpdatedUrl(update: DeepUpdate<ComponentStateDto>): Maybe<string> {
  if (
    isDefined(update?.changes?.dataConnectorQuery) &&
    isDefined((update?.changes?.dataConnectorQuery as ApiDataSourceDto).url)
  ) {
    return (update.changes.dataConnectorQuery as ApiDataSourceDto).url;
  } else {
    return null;
  }
}

function getSizeUpdate(
  update: DeepUpdate<ComponentStateDto>
): Maybe<DeepPartial<ComponentCssSize>> {
  if (update?.changes?.view != null) {
    return _cloneDeep(update.changes.view.size);
  } else {
    return null;
  }
}

function getNavBarLinkUpdate(
  update: DeepUpdate<ComponentStateDto>
): Maybe<DeepPartial<NavLinkInfo[]>> {
  if (update?.changes?.view != null) {
    return _cloneDeep((update.changes.view as NavigationBarViewConfig).links);
  } else {
    return null;
  }
}

function getLinkUpdate(update: DeepUpdate<ComponentStateDto>): Maybe<DeepPartial<LinkDto>> {
  if (isDefined(update?.changes?.view)) {
    return _cloneDeep(update.changes.view.link);
  } else {
    return null;
  }
}

function getDisplayTypeUpdate(update: DeepUpdate<ComponentStateDto>): Maybe<string> {
  if (isDefined(update?.changes?.view)) {
    return (update.changes.view as StrategizedChartViewConfig).displayStrategy;
  } else {
    return null;
  }
}

function getCutOffStrategyUpdate(update: DeepUpdate<ComponentStateDto>): Maybe<CutOffStrategy> {
  if (isDefined(update?.changes?.view)) {
    return update.changes.view.cutOffStrategy;
  } else {
    return null;
  }
}

function getBackgroundColorUpdate(update: DeepUpdate<ComponentStateDto>): Maybe<string> {
  if (isDefined(update?.changes?.view)) {
    return update.changes.view?.css?.backgroundColor;
  } else {
    return null;
  }
}

function getYAxesUpdate(update: DeepUpdate<ComponentStateDto>): Maybe<YAxisDescriptor[]> {
  return (update?.changes?.view as TimeSeriesViewConfig)?.yAxes;
}

function shouldUpdateCardRuntimeView(componentUpdate: DeepUpdate<ComponentStateDto>): boolean {
  const headerUpdate: Maybe<boolean> = getBasicCardHeaderUpdate(componentUpdate);
  const footerUpdate: Maybe<boolean> = getBasicCardFooterUpdate(componentUpdate);
  return headerUpdate != null || footerUpdate != null;
}

function getBasicCardHeaderUpdate(update: DeepUpdate<ComponentStateDto>): Maybe<boolean> {
  if (isDefined(update?.changes?.view)) {
    return (update.changes.view as BasicCardViewConfig).showHeader;
  } else {
    return null;
  }
}

function getBasicCardFooterUpdate(update: DeepUpdate<ComponentStateDto>): Maybe<boolean> {
  if (isDefined(update?.changes?.view)) {
    return (update.changes.view as BasicCardViewConfig).showFooter;
  } else {
    return null;
  }
}

function getBackgroundImageUpdate(update: DeepUpdate<ComponentStateDto>): Maybe<string> {
  return update?.changes?.view?.backgroundImage ?? null;
}

function getAggregationConfigUpdate(
  update: DeepUpdate<ComponentStateDto>
): Maybe<DeepPartial<DataAggregationConfigDto>> {
  if (isDefined(update?.changes)) {
    return update.changes.dataConnectorQuery?.aggregationConfig;
  } else {
    return null;
  }
}

function getParamsToUpdateOnUrlChange(
  componentUpdate: DeepUpdate<ComponentStateDto>,
  updatedUrl: string,
  apiDataSourceDescriptorSelector: ApiDataSourceDescriptorSelector,
  previousUrlParams: KeyValuePair[]
): KeyValuePair[] {
  const initiallyConfiguredParams = getUpdatedParams(componentUpdate);
  let paramsToUpdate: KeyValuePair[] = [];

  if (!isEmpty(updatedUrl)) {
    const supportedUrlParams = getApiParams(updatedUrl, apiDataSourceDescriptorSelector);
    paramsToUpdate = !isEmptyOrNotDefined(initiallyConfiguredParams)
      ? initiallyConfiguredParams.concat(supportedUrlParams)
      : supportedUrlParams.concat(previousUrlParams);
  }

  return paramsToUpdate;
}

function getUpdatedParams(update: DeepUpdate<ComponentStateDto>): Maybe<KeyValuePair[]> {
  if (
    isDefined(update?.changes?.dataConnectorQuery) &&
    isDefined((update?.changes?.dataConnectorQuery as ApiDataSourceDto).params)
  ) {
    return (update.changes.dataConnectorQuery as ApiDataSourceDto).params;
  } else {
    return null;
  }
}

function getApiParams(
  updatedApiUrl: string,
  apiDataSourceDescriptorSelector: ApiDataSourceDescriptorSelector
): KeyValuePair[] {
  const queryParams: Maybe<Dictionary<ApiParameter>> =
    apiDataSourceDescriptorSelector.getParametersByUrl(updatedApiUrl);
  if (isDefined(queryParams)) {
    return Object.keys(queryParams).reduce((acc: KeyValuePair[], paramKey: string) => {
      const queryValues: Maybe<string[]> = queryParams[paramKey].queryValues;
      if (isDefined(queryValues)) {
        if (isEmpty(queryValues)) {
          acc.push(new KeyValuePair({ key: paramKey }));
        } else {
          queryValues.forEach((value) => {
            acc.push(new KeyValuePair({ key: paramKey, value }));
          });
        }
      }
      return acc;
    }, []);
  }
  return [];
}

function getActionToDeletePseudoConnector(
  componentConnectors: DataConnectorDto[],
  componentId: EntityId
): Maybe<Action> {
  const pseudoConnector: Maybe<DataConnectorDto> = componentConnectors.find(
    (connector: DataConnectorDto) => isPseudoConnector(connector.id)
  );
  if (isDefined(pseudoConnector)) {
    return DataConnectorActions.deleteOne({
      componentId,
      connector: pseudoConnector
    });
  }
}

function getActionToDeletePseudoConnView(
  dataConnectorViewSelector: DataConnectorViewSelector,
  componentId: EntityId
): Maybe<Action> {
  const pseudoConnectorView: Maybe<DataConnectorViewDto> = dataConnectorViewSelector.getById(
    getConnectorViewId(getPseudoConnectorId(componentId))
  );
  if (isDefined(pseudoConnectorView)) {
    return DataConnectorViewActions.deletePseudoOne({
      pseudoConnectorViewId: getPseudoConnectorId(componentId)
    });
  }
}

function getUpdatedConnectorView(
  componentConnectors: DataConnectorDto[],
  dataConnectorViewSelector: DataConnectorViewSelector
): DeepUpdate<DataConnectorViewDto>[] {
  const dataConnectorViews: DataConnectorViewDto[] = getDataConnectorViews(
    componentConnectors,
    dataConnectorViewSelector
  );

  return dataConnectorViews.reduce(
    (acc: DeepUpdate<DataConnectorViewDto>[], dataConnectorView: DataConnectorViewDto) => {
      if (dataConnectorView.timeSeriesConfig.seriesType !== SERIES_TYPE_DEFAULT) {
        acc.push({
          id: dataConnectorView.id.toString(),
          changes: {
            timeSeriesConfig: {
              seriesType: SERIES_TYPE_DEFAULT
            }
          }
        });
      }
      return acc;
    },
    []
  );
}

function getDataConnectorViews(
  componentConnectors: DataConnectorDto[],
  dataConnectorViewSelector: DataConnectorViewSelector
): DataConnectorViewDto[] {
  const connectorViewIds: EntityId[] = componentConnectors.map((connector: DataConnectorDto) =>
    getConnectorViewId(connector.id)
  );
  const connectorViewDict: Dictionary<DataConnectorViewDto> =
    dataConnectorViewSelector.getManyById(connectorViewIds);

  return Object.values(connectorViewDict);
}

function getForegroundColor(
  componentStateSelector: ComponentStateSelector,
  id: EntityId,
  backgroundColor: string
): string {
  const parentBackgroundColor: Maybe<string> =
    componentStateSelector.getParent(id)?.view.css.backgroundColor;
  return getNeutralColorForText(backgroundColor, parentBackgroundColor);
}
