<div class="time-range-editor__switch">
  <toggle-switch
    [value]="isLiveMode"
    label="{{ localizer.filterComponent.LiveModeOn | translate }}"
    (changeValue)="onChangeLiveMode($event)"
    [distinctDefault]="!isLiveMode"
  ></toggle-switch>
</div>
<div class="time-range-editor__time-interval">
  <live-mode-filter
    *ngIf="isLiveMode; else liveModeOff"
    class="live-mode"
    [liveModeFilter]="liveModeFilter"
    (onChangeValue)="onChangeLiveModeFilter($event)"
    [distinctDefault]="true"
  ></live-mode-filter>
  <ng-template #liveModeOff>
    <historic-range
      [timeRange]="originalTimeRange"
      [horizontalLayout]="false"
      (onChangeTimeRange)="onChangeCustomTimeRange($event)"
    >
    </historic-range>
  </ng-template>
</div>
