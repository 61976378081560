<div class="create-report__wrapper">
  <div class="create-report__header">
    <div mat-dialog-title>
      {{ dialogTitle | translate }}
    </div>
    <span class="close-icon" (click)="cancel()">
      <i aria-hidden="true" class="abb-icon-default Close"></i>
    </span>
  </div>
  <form mat-dialog-content>
    <mat-form-field>
      <mat-label>{{ localizer.dialogs.ReportName | translate }}</mat-label>
      <input
        type="text"
        matInput
        [formControl]="reportNameControl"
        [errorStateMatcher]="matcher"
        autocomplete="off"
      />
      <mat-error *ngIf="reportNameControl.invalid">
        {{ getReportError() }}
      </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="supportsAliasName">
      <mat-label>{{ localizer.dialogs.AliasName | translate }}</mat-label>
      <textarea
        matInput
        maxlength="60"
        [value]="aliasName"
        (input)="onAliasNameChange($event.target.value)"
      ></textarea>
    </mat-form-field>
    <mat-form-field *ngIf="supportsDescription">
      <mat-label>{{ localizer.dialogs.Description | translate }}</mat-label>
      <textarea
        matInput
        maxlength="128"
        [value]="description"
        (input)="onDescriptionChange($event.target.value)"
      ></textarea>
    </mat-form-field>
  </form>
  <div mat-dialog-actions>
    <button
      class="c-button c-button--rounded c-button--primary"
      (click)="save(reportNameControl.value)"
      [disabled]="!reportNameControl.valid"
    >
      {{ localizer.dialogs.Save | translate }}
    </button>
    <button class="c-button c-button--rounded" (click)="cancel()">
      {{ localizer.dialogs.Cancel | translate }}
    </button>
  </div>
</div>
