import { EditableType, Serializable } from "../../meta";
import { ReportDescription } from "./report-description";
import { ReportId } from "./report-id";

@EditableType({
  fullName: "DecoratedReportDescriptionDto",
  title: "decorated-report-description-dto"
})
export class DecoratedReportDescriptionDto implements ReportDescription {
  @Serializable()
  reportId: ReportId;

  @Serializable()
  reportName: string;

  @Serializable()
  reportTemplateUrl?: string;

  constructor(reportId: ReportId, reportName: string, reportTemplateUrl?: string) {
    this.reportId = reportId;
    this.reportName = reportName;
    this.reportTemplateUrl = reportTemplateUrl;
  }
}
