import { EditableType, Serializable } from "ui-core";

@EditableType({ fullName: "PositionViewConfig" })
export class PositionViewConfig {
  @Serializable()
  id: string;

  @Serializable()
  x: number;

  @Serializable()
  y: number;

  @Serializable()
  value: number;
}
