export const PAGE_VIEW_CONFIG = "PageViewConfig";
export const BASIC_CARD_VIEW_CONFIG = "BasicCardViewConfig";
export const NAVIGATION_BAR_VIEW_CONFIG = "NavigationBarViewConfig";
export const CONTAINER_COMPONENT_VIEW_CONFIG = "ContainerComponentViewConfig";
export const TAB_GROUP_VIEW_CONFIG = "TabGroupCardViewConfig";
export const TIME_SERIES_VIEW_CONFIG = "TimeSeriesViewConfig";
export const SINGLE_VALUE_VIEW_CONFIG = "SingleValueViewConfig";
export const CATEGORY_VIEW_CONFIG = "CategoryViewConfig";
export const LABEL_VIEW_CONFIG = "LabelViewConfig";
export const STATUS_INDICATOR_VIEW_CONFIG = "StatusIndicatorViewConfig";
