<div class="basic-card__holder">
  <div class="basic-card__header-wrapper positioning-service__page-drag-handle">
    <div
      class="basic-card__header"
      *ngIf="interpolatedViewConfig.showHeader"
      [ngClass]="{ 'basic-card__header--draggable': isEditable }"
    >
      <div class="basic-card__header-title page-item__drag-handle" [style.color]="titleColor">
        <p>{{ interpolatedViewConfig.title }}</p>
      </div>
      <c-button-strip
        *ngIf="!isEditable"
        class="basic-card__button-strip"
        [buttons]="cardSpecificButtons"
      ></c-button-strip>
    </div>
  </div>
  <div class="basic-card__background-image" [ngStyle]="backgroundImageCSS"></div>
  <div
    #cardBody
    class="basic-card__body"
    [ngStyle]="bodyStyle"
    [widgetsContainer]="isEditable"
    [containerId]="id"
  >
    <ng-template dynamic-component-container></ng-template>
  </div>
  <div class="basic-card__footer" *ngIf="interpolatedViewConfig.showFooter">
    <div class="basic-card__footer-left-button-container" *ngIf="interpolatedViewConfig.expandable">
      <button class="c-button c-button--circular" (click)="toggleExpanded()">
        <i class="abb-icon" [ngClass]="interpolatedViewConfig.expanded ? 'Up' : 'Down'"></i>
      </button>
    </div>
    <div class="basic-card__footer-timestamp-display">
      <i class="dashboard-icon icon-Clock timeIcon"> </i>
      <span class="basic-card__footer-timestamp-display-time">
        {{ getFormattedLatestTimestamp() }}
      </span>
    </div>
  </div>
</div>
