import {
  historyViewHeaderHeight,
  historyViewPlayerHeight
} from "projects/ui-core/src/style-variables";
import { getConnectorDtoViewId } from "../../data-connectivity/helpers/data-connector-view.helper";
import { isSignalBased } from "../../data-connectivity/helpers/data-source-type.helper";
import { AggregationFunction } from "../../data-connectivity/models/data-aggregation-function";
import { DataConnectorDto } from "../../data-connectivity/models/data-connector";
import { DataConnectorViewDto } from "../../data-connectivity/models/data-connector-view";
import { DataSourceDto } from "../../data-connectivity/models/data-source/data-source";
import { ESCAPE_KEY, SPACE_KEY } from "../../keyboard.constants";
import { CutOffStrategy } from "../../meta/models/cut-off-strategy";
import { EntityId } from "../../meta/models/entity";
import { removeDuplicates } from "../../ts-utils/helpers/array.helper";
import { isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { DeepPartial } from "../../ts-utils/models/deep-partial.type";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { BasicCardViewConfig } from "../components/basic-card/view-config";
import { HISTORY_VIEW_CONSTANTS as CONSTANTS } from "../components/history-view/history-view.constants";
import { KmTrendViewConfig } from "../components/km-trend/view-config";
import { TabGroupCardViewConfig } from "../components/tab-group/view-config";
import { CacheOptionsDto } from "../models/cache-options";
import { ComponentCssSize } from "../models/component-size";
import { FULL_HEIGHT_CSS_SIZE, FULL_WIDTH_CSS_SIZE } from "../models/component-size.constants";
import { ComponentStateDto } from "../models/component-state";
import {
  isBasicCard,
  isSingleValue,
  isTabContent,
  isTabGroup
} from "../models/component-type.helper";
import { BASIC_CARD } from "../models/element-type.constants";
import { KmTheme } from "../models/km-themes";
import { ReportEntities } from "../models/report-entities";
import {
  CardRuntimeViewProperties,
  NEUTRAL_SCALING_FACTOR
} from "../models/runtime-view-properties";
import { DataConnectorDescriptor } from "../models/store/data-connector-descriptor";
import { CloningService } from "../services/cloning.service";
import { ComponentStateSelector } from "../services/entity-selectors/component-state.selector";

export function convertToLocalEntities(
  reportEntities: ReportEntities,
  cacheTimestamp: Date
): ReportEntities {
  return {
    ...reportEntities,
    componentStates: adaptComponentStates(reportEntities.componentStates, cacheTimestamp),
    dataConnectors: adaptDataConnectors(reportEntities.dataConnectors),
    dataConnectorViews: adaptDataConnectorViews(reportEntities.dataConnectorViews)
  };
}

function adaptComponentStates(
  componentStates: ComponentStateDto[],
  cacheTimestamp: Date
): ComponentStateDto[] {
  return componentStates.map((component) => {
    component = {
      ...component,
      id: getLocalId(component.id),
      childrenIds: component.childrenIds.map((childId: EntityId) => getLocalId(childId)),
      cache: new CacheOptionsDto({ enabled: true, currentTimestamp: cacheTimestamp }),
      dataConnectorIds: component.dataConnectorIds.map((id: EntityId) => getLocalId(id)),
      filterId: CONSTANTS.FILTER_ID
    };
    if (isBasicCard(component.type)) {
      (component.view as BasicCardViewConfig).historyViewEnabled = false;
      (component.view as BasicCardViewConfig).expandable = false;
    }

    return adaptViewConfigNestedIds(component);
  });
}

function adaptViewConfigNestedIds(component: ComponentStateDto): ComponentStateDto {
  if (isTabGroup(component.type)) {
    (component.view as TabGroupCardViewConfig).tabs = (
      component.view as TabGroupCardViewConfig
    ).tabs.map((tabConfig) => ({
      ...tabConfig,
      contentContainerId: getLocalId(tabConfig.contentContainerId)
    }));
  }
  return component;
}

function adaptDataConnectors(connectors: DataConnectorDto[]): DataConnectorDto[] {
  return connectors.map((connector) => {
    connector = {
      ...connector,
      id: getLocalId(connector.id),
      numberOfRequestedDataPoints: resolveConnectorNumberOfRequestedPoints(connector)
    };
    if (isSignalBased(connector.dataSource)) {
      connector.dataSource.aggregationConfig.timeAggregationFunction = AggregationFunction.None;
    }
    return connector;
  });
}

function adaptDataConnectorViews(connectorViews: DataConnectorViewDto[]): DataConnectorViewDto[] {
  return connectorViews.map((connectorView) => ({
    ...connectorView,
    id: getLocalId(connectorView.id)
  }));
}

function resolveTrendConnectors(
  entities: ReportEntities,
  cloningService: CloningService
): DataConnectorDescriptor[] {
  const uniqueConnectors = removeDuplicates(entities.dataConnectors, getUniqueConnectorIdentifier);
  return uniqueConnectors
    .map((originalConnector) => {
      const originalConnectorView = entities.dataConnectorViews.find(
        (view) => view.id === getConnectorDtoViewId(originalConnector)
      );
      if (isNotDefined(originalConnectorView)) {
        return;
      }
      let connectorClone = cloningService.createConnectorCloneWithRandomId(originalConnector);
      connectorClone = {
        ...connectorClone,
        id: getLocalId(connectorClone.id)
      };

      return {
        connector: connectorClone,
        connectorView: cloningService.createConnectorViewClone(
          originalConnectorView,
          getConnectorDtoViewId(connectorClone)
        )
      };
    })
    .filter(isDefined);
}

function getUniqueConnectorIdentifier(connector: DataConnectorDto): string {
  const dataSource: DataSourceDto = connector.dataSource;
  if (isSignalBased(dataSource)) {
    return dataSource.signal.id + ":" + dataSource.signal.name;
  } else {
    return connector.id.toString();
  }
}

export function getLocalId(id: EntityId): string {
  return CONSTANTS.ID_PREFIX + id.toString();
}

export function shouldDisableChartAnimations(
  id: EntityId,
  componentType: string,
  componentStateSelector: ComponentStateSelector
): boolean {
  if (!isInHistoryViewMode(id)) {
    return false;
  }
  if (isSingleValue(componentType)) {
    return true;
  }
  const parent = componentStateSelector.getParent(id);
  return isDefined(parent) && isTabContent(parent.type);
}

function isInHistoryViewMode(id: EntityId): boolean {
  return id.toString().startsWith(CONSTANTS.ID_PREFIX);
}

function createTrendComponentStates(): {
  trendCard: ComponentStateDto;
  trendComponent: ComponentStateDto;
} {
  return {
    trendCard: createTrendCard(),
    trendComponent: createTrendComponent()
  };
}

function createTrendCard(): ComponentStateDto {
  const trendCardSize = new ComponentCssSize(FULL_WIDTH_CSS_SIZE, FULL_HEIGHT_CSS_SIZE);
  return new ComponentStateDto({
    type: BASIC_CARD,
    id: CONSTANTS.TREND_CARD_ID,
    childrenIds: [CONSTANTS.TREND_ID],
    view: {
      size: trendCardSize,
      expandedSize: trendCardSize,
      canBeScaled: false,
      historyViewEnabled: false,
      title: "Plot"
    } as DeepPartial<BasicCardViewConfig>
  });
}

function createTrendComponent(): ComponentStateDto {
  const trendComponentSize = new ComponentCssSize(FULL_WIDTH_CSS_SIZE, FULL_HEIGHT_CSS_SIZE);
  return new ComponentStateDto({
    type: "KmTrendComponent",
    id: CONSTANTS.TREND_ID,
    filterId: CONSTANTS.FILTER_ID,
    view: {
      size: trendComponentSize,
      theme: KmTheme.White
    } as DeepPartial<KmTrendViewConfig>
  });
}

export function createTrendEntities(
  expandedEntities: ReportEntities,
  cloningService: CloningService
): ReportEntities {
  const { trendCard, trendComponent } = createTrendComponentStates();
  const trendConnectorDesciptors = resolveTrendConnectors(expandedEntities, cloningService);
  trendComponent.dataConnectorIds = trendConnectorDesciptors.map(
    (descriptor) => descriptor.connector.id
  );
  return {
    componentStates: [trendCard, trendComponent],
    dataConnectorViews: trendConnectorDesciptors.map((descriptor) => descriptor.connectorView),
    dataConnectors: trendConnectorDesciptors.map((descriptor) => descriptor.connector),
    filters: []
  };
}

export function updateCutoffStrategy(components: ComponentStateDto[]): ComponentStateDto[] {
  return components.map((componentState) => ({
    ...componentState,
    view: {
      ...componentState.view,
      cutOffStrategy: CutOffStrategy.Downsample
    }
  }));
}

export function fitExpandedCardToDialog(
  components: ComponentStateDto[],
  expandedCardId: Maybe<EntityId>
): ComponentStateDto[] {
  const expandedCardIndex = components.findIndex((component) => component.id === expandedCardId);
  if (expandedCardIndex < 0) {
    return components;
  }
  const expandedCard = components[expandedCardIndex];
  const cardWidth = expandedCard.view.runtimeView.runtimeSize.widthInPx;
  const cardHeight = Math.min(
    getMaxCardHeight(),
    expandedCard.view.runtimeView.runtimeSize.heightInPx
  );

  components[expandedCardIndex] = applyViewChanges(expandedCard, cardWidth, cardHeight);
  return components;
}

function getMaxCardHeight(): number {
  return (
    (document.documentElement.clientHeight * CONSTANTS.MAX_CARD_HEIGHT_IN_VH) / 100 -
    historyViewPlayerHeight -
    historyViewHeaderHeight
  );
}

function applyViewChanges(
  expandedCard: ComponentStateDto,
  width: number,
  height: number
): ComponentStateDto {
  expandedCard.view.size.width = width.toString();
  expandedCard.view.runtimeView.runtimeSize.widthInPx = width;
  expandedCard.view.size.height = height.toString();
  expandedCard.view.runtimeView.runtimeSize.heightInPx = height;

  return expandedCard;
}

function resolveConnectorNumberOfRequestedPoints(connector: DataConnectorDto): number {
  return Math.max(
    connector.numberOfRequestedDataPoints,
    CONSTANTS.TIME_SLIDER_STEPS / CONSTANTS.STEPS_PER_DATA_POINT
  );
}

export function resetViewModeScaling(components: ComponentStateDto[]): ComponentStateDto[] {
  return components.map((component) => {
    if (isBasicCard(component.type)) {
      (component.view.runtimeView as CardRuntimeViewProperties).scalingFactor =
        NEUTRAL_SCALING_FACTOR;
    }
    return component;
  });
}

export function isShortcutPressed(key: string): boolean {
  return key === SPACE_KEY || key === ESCAPE_KEY;
}
