import { InheritableEnum } from "./inheritable.enum";

export enum AggregationFunction {
  None = "None",
  Average = "Average",
  Maximum = "Maximum",
  Minimum = "Minimum",
  Sum = "Sum",
  First = "First",
  Latest = "Latest",
  Default = "Default"
}

export const InheritableAggregationFunction = {
  ...AggregationFunction,
  ...InheritableEnum
};

export type AggregationType = AggregationFunction | InheritableEnum;
