import { merge as _merge } from "lodash";
import { DeepPartial } from "../../ts-utils/models/deep-partial.type";
import { IconStateDisplay } from "../models/status-indicator/icon-state-display";
import { ImageStateDisplay } from "../models/status-indicator/image-state-display";
import { IndicatorStateDisplay } from "../models/status-indicator/indicator-state-display";
import { ShapeStateDisplay } from "../models/status-indicator/shape-state-display";
import { StateDisplayType } from "../models/status-indicator/state-display-type";

export function createStateDisplayType(
  type: StateDisplayType,
  partial: DeepPartial<IndicatorStateDisplay>
): IndicatorStateDisplay {
  const typedPartial = { ...partial };
  typedPartial.typeName = type;
  switch (partial.typeName) {
    case StateDisplayType.Shape:
      return createShapeStateDisplay(partial as ShapeStateDisplay);
    case StateDisplayType.Icon:
      return createIconStateDisplay(partial as IconStateDisplay);
    case StateDisplayType.Image:
      return createImageStateDisplay(partial as ImageStateDisplay);
    default:
      return createShapeStateDisplay(partial as ShapeStateDisplay);
  }
}

function createShapeStateDisplay(source: DeepPartial<ShapeStateDisplay> = {}): ShapeStateDisplay {
  const target = new ShapeStateDisplay();
  return _merge(target, source);
}

function createIconStateDisplay(source: DeepPartial<IconStateDisplay> = {}): IconStateDisplay {
  const target = new IconStateDisplay();
  return _merge(target, source);
}

function createImageStateDisplay(source: DeepPartial<ImageStateDisplay> = {}): ImageStateDisplay {
  const target = new ImageStateDisplay();
  return _merge(target, source);
}
