import { ChangeDetectionStrategy, Component } from "@angular/core";
import credits from "../../../../assets/ui-core/credits.json";
import { BaseDialogComponent } from "../base-dialog/base-dialog.component";

interface Credit {
  dep: string;
  license: string;
  homepage: string;
}

@Component({
  selector: "c-credits",
  templateUrl: "./credits.component.html",
  styleUrls: ["./credits.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreditsComponent extends BaseDialogComponent {
  public credits: Credit[] = [];
  public flags: boolean[] = [];
  constructor() {
    super();
    this.credits = credits.credits as Credit[];
    this.flags = new Array(this.credits.length).fill(false);
  }
}
