import { Maybe } from "../../ts-utils/models/maybe.type";

export const DEFAULT_ROOT_PATH = "/";

export interface EquipmentPath {
  steps: EquipmentPathStep[];
  overallIndex: Maybe<number>;
}

export interface EquipmentPathStep {
  axis: PathAxis;
  predicates: PathPredicate[];
}

export enum PathAxis {
  Child = "child",
  DescendantOrSelf = "descendant-or-self", // abbreviated //
  Parent = "parent"
}

export interface PathPredicate {
  type: PredicateType;
  expected: string | number;
}

export enum PredicateType {
  NodeName = "NodeName",
  EquipmentClass = "EquipmentClass",
  Index = "Index"
}
