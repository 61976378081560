import { ActionReducerMap } from "@ngrx/store/src/models";
import * as fromComponentsCounter from "./component-counter/component-counter.reducer";
import * as fromComponentStates from "./component-state/component-state.reducer";
import * as fromDataConnectorView from "./data-connector-view/data-connector-view.reducer";
import * as fromDataConnector from "./data-connector/data-connector.reducer";
import * as fromFilters from "./filter/filter.reducer";
import * as fromGeneralSettings from "./general-settings/general-settings.reducer";
import * as fromInfo from "./report-info/report-info.reducer";
import * as fromRuntimeSettings from "./runtime-settings/runtime-settings.reducer";
import { ReportState } from "./state";
import { versionReducer } from "./version/version.reducer";

export const reducers: ActionReducerMap<ReportState> = {
  componentStates: fromComponentStates.reducer,
  dataConnectors: fromDataConnector.reducer,
  filters: fromFilters.reducer,
  dataConnectorViews: fromDataConnectorView.reducer,
  componentsCounter: fromComponentsCounter.reducer,
  reportInfo: fromInfo.reducer,
  generalSettings: fromGeneralSettings.reducer,
  runtimeSettings: fromRuntimeSettings.reducer,
  version: versionReducer
};
