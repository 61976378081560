import { TIME_SERIES } from "../models/element-type.constants";

const COMPONENTS_WITH_DYNAMIC_DATA_POINTS: string[] = [TIME_SERIES, "TrendComponent"];
export function hasDynamicDataPoints(componentType: string): boolean {
  const dynamicComponentIndex = COMPONENTS_WITH_DYNAMIC_DATA_POINTS.findIndex(
    (dynamicType) => dynamicType === componentType
  );
  if (dynamicComponentIndex === -1) {
    return false;
  } else {
    return true;
  }
}
