import { Action, createReducer, on } from "@ngrx/store";
import { isDefined } from "../../../ts-utils";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { ApiDataSourceDescriptor } from "../../models";
import { ApiDataSourceDescriptorActions } from "./api-data-source-descriptor.actions";

const initialState: Maybe<ApiDataSourceDescriptor[]> = null;

export function reducer(state: Maybe<ApiDataSourceDescriptor[]>, action: Action) {
  return _reducer(state, action);
}

const _reducer = createReducer(
  initialState as Maybe<ApiDataSourceDescriptor[]>,
  on(ApiDataSourceDescriptorActions.loadSuccess, (state, { descriptors }) =>
    onLoadApiDataSourceDescriptorsSuccess(state ?? [], descriptors)
  )
);

function onLoadApiDataSourceDescriptorsSuccess(
  state: ApiDataSourceDescriptor[],
  descriptors: Maybe<ApiDataSourceDescriptor[]>
): Maybe<ApiDataSourceDescriptor[]> {
  return isDefined(descriptors) ? [...state, ...descriptors] : state;
}
