// from https://en.wikipedia.org/wiki/Binary_search_algorithm

// The index of the specified value in the specified array, if value is found; otherwise, a negative number.
// If value is not found and value is less than one or more elements in array, the negative number returned is the bitwise complement of the index of the first element that is larger than value.
// If value is not found and value is greater than all elements in array, the negative number returned is the bitwise complement of the index of the last element plus 1.
export function binarySearch<TElem>(A: TElem[], compare: (a: TElem) => number): number {
  const n = A.length;
  let left = 0;
  let right = n - 1;
  while (left <= right) {
    const mid = (left + right) >>> 1;
    const compareResult = compare(A[mid]);
    if (compareResult < 0) {
      left = mid + 1;
    } else if (compareResult > 0) {
      right = mid - 1;
    } else {
      return mid;
    }
  }
  return ~left;
}
