import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { ComponentMetadataService } from "./services/component-metadata.service";
import { ConnectivitySelector } from "./services/connectivity.selector";
import { DataConnectorFactory } from "./services/deserializers/data-connector-factory.service";
import { DataConnectorViewModelDeserializer } from "./services/deserializers/data-connector-vm.deserializer";
import { DataSourceDeserializer } from "./services/deserializers/data-source.deserializer";
import { DataSourceDescriptorEffects } from "./store/data-source-descriptor/data-source-descriptor.effects";
import { CONNECTIVITY_FEATURE } from "./store/feature.selector";
import { reducers } from "./store/index.reducer";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(CONNECTIVITY_FEATURE, reducers),
    EffectsModule.forFeature([DataSourceDescriptorEffects])
  ],
  declarations: []
})
export class DataConnectorModule {
  static forRoot(): ModuleWithProviders<DataConnectorModule> {
    return {
      ngModule: DataConnectorModule,
      providers: [
        ComponentMetadataService,
        DataConnectorViewModelDeserializer,
        DataSourceDeserializer,
        DataConnectorFactory,
        ConnectivitySelector
      ]
    };
  }
}
