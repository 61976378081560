<div
  class="dashboard"
  [ngClass]="{
    'drop-above': style === 'drop-above',
    'drop-below': style === 'drop-below',
    'drop-center': style === 'drop-center',
    'dashboard--active': isActive,
    'dashboard--delete-mode': isDeleteModeOn,
    'dashboard--rename-mode': isRenameModeOn,
    'dashboard--drag-mode draggable-item': isDragModeOn
  }"
  #element
>
  <div class="dashboard__content">
    <span
      class="dashboard__title"
      (click)="changeDashboard()"
      [ngStyle]="{ 'margin-left.px': margin }"
      ><span [innerHTML]="getStyledNodeName(dashboard)"></span></span
    >
  </div>

  <div class="dashboard__icons">
    <em
      *ngIf="isDeleteModeOn"
      class="icon icon--red abb-icon Trash"
      (click)="toggleTooltip()"
    ></em>
    <em
      *ngIf="isRenameModeOn"
      class="icon icon--blue abb-icon Edit"
      (click)="toggleTooltip()"
    ></em>
    <em
      (click)="toggleMain()"
      class="icon icon-flag abb-md-other-icons"
      [ngClass]="{
        'icon--active': dashboard.isMain,
        'icon--inactive': !dashboard.isMain,
        'icon--visible': isEditModeOn
      }"
    ></em>
  </div>
</div>
