import { createAction, props } from "@ngrx/store";
import { ColorPickerDialogInfo } from "../../models/color-picker-dialog-info";

export namespace ColorPickerDialogActions {
  export const openColorPickerDialog = createAction(
    "[Dialog] Open Color Picker Dialog",
    props<{ colorPickerDialogInfo: ColorPickerDialogInfo }>()
  );

  export const onColorPickerDialogClosed = createAction(
    "[Dialog] On Color Picker Dialog Closed",
    props<{ selectedColorInfo: string }>()
  );
}
