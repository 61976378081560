import { Component, OnInit } from "@angular/core";
import { selectConnectionStatus } from "../../../environment/store/feature.selector";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

@Component({
  selector: "c-app-footer",
  templateUrl: "./app-footer.component.html",
  styleUrls: ["./app-footer.component.scss"]
})
export class AppFooterComponent implements OnInit {
  public currentYear: number;
  public isOnline$: Observable<boolean>;

  constructor(private store$: Store<any>) {}

  ngOnInit() {
    const currentDate = new Date();
    this.currentYear = currentDate.getFullYear();
    this.isOnline$ = this.store$.select(selectConnectionStatus);
  }
}
