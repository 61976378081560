<div class="card-item__drag-handle airgap-profile-chart__wrapper">
  <highcharts-chart-extended
    class="chart-container"
    [Highcharts]="Highcharts"
    [options]="chartOptions"
  >
  </highcharts-chart-extended>
  <div class="airgap-profile-chart__legend-container">
    <km-legend [itemList]="[]"></km-legend>
  </div>
</div>
