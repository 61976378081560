import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { isUndefined } from "lodash";
import { iif, Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { EnvironmentSelector, WebServicesConfiguration } from "ui-core";
import { CoreComponentCategoryProvider } from "../../../../../ui-core/src/lib/core/services/core-component-category-provider";
import {
  SidemenuComponents,
  ToolbarItem
} from "../../../../../ui-core/src/lib/shared/models/tool-bar-item";
import { ToolbarItemsGenerator } from "../../../../../ui-core/src/lib/shared/services/toolbar-items-generator.service";
import { FLEET_VIEW_REPORT_ID } from "../../models/constants/predefined-reports";

@Injectable()
export class RdsToolbarItemsGenerator extends ToolbarItemsGenerator {
  constructor(
    protected componentCategoryInfoProvider: CoreComponentCategoryProvider,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private apiConfig: WebServicesConfiguration,
    protected environmentSelector: EnvironmentSelector
  ) {
    super(componentCategoryInfoProvider, environmentSelector);
  }

  private readonly commonToolbarItems: ToolbarItem[] = [
    new ToolbarItem("abb-icon Map", SidemenuComponents.MapExplorer, "", () =>
      this.router.navigateByUrl(`/report/${FLEET_VIEW_REPORT_ID}`)
    ),
    new ToolbarItem("abb-icon Tree_view", SidemenuComponents.TreeExplorer, "")
  ];

  private get extraExplorerToolbarItems(): ToolbarItem[] {
    return this.commonToolbarItems;
  }

  private getExclusiveToolbarItems$(items: ToolbarItem[]): Observable<ToolbarItem[]> {
    const customer = this.route.snapshot.queryParamMap.get("rootPath")?.split("/")[0];

    const isCustomerSelected = (): boolean => !isUndefined(customer);
    const $items = of(items);
    const $copilot = this.http
      .get<boolean>(`${this.apiConfig.itemsServiceUrl}/access-management/copilot/${customer}`)
      .pipe(
        map((hasAccess: boolean) =>
          hasAccess
            ? items.concat(
              new ToolbarItem(
                "abb-icon Copilot",
                SidemenuComponents.Copilot,
                "",
                () => window.open(`${window.location.origin}/copilot/customer/${customer}`, "_blank")
              )
            )
            : items
        )
      );

    return iif(isCustomerSelected, $copilot, $items);
  }

  generateExplorerToolbarItems$(): Observable<ToolbarItem[]> {
    const coreExplorerItems$: Observable<ToolbarItem[]> = super.generateExplorerToolbarItems$();
    return coreExplorerItems$.pipe(
      map((coreItems) => this.extraExplorerToolbarItems.concat(coreItems)),
      switchMap((basicItems) => this.getExclusiveToolbarItems$(basicItems))
    );
  }
}

