import "reflect-metadata";
import { ConstructorFunction } from "../../ts-utils/models/constructor-function";
import { PropertyChangeHandler } from "../models/property-change-handler";
import { TypeProvider } from "../services/type-provider";

const TYPE_PROVIDER = TypeProvider.getInstance();

//Allows introducing additional changes to modified object.
//NOTE: Only one property change will be processed by updateOne effect, depending of the order of processing.
export function OnPropertyChange<ContextType, OwnerType, PropertyType>(
  handler: PropertyChangeHandler<ContextType, OwnerType, PropertyType>
): any {
  return function (target: Object, key: string) {
    const constructorFunction = target.constructor as ConstructorFunction<ContextType>;
    const typeDescriptor = TYPE_PROVIDER.addType({ constructorFunction });
    typeDescriptor.setPropertyObserver(key, handler);
  };
}
