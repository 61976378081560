import { Injectable } from "@angular/core";

@Injectable()
export class IPTypeCheckService {
  public isPri(periodType: string): boolean {
    throw new Error("Not implemented");
  }

  public isMinute(periodType: string): boolean {
    throw new Error("Not implemented");
  }

  public is15Minute(periodType: string): boolean {
    throw new Error("Not implemented");
  }

  public is30Minute(periodType: string): boolean {
    throw new Error("Not implemented");
  }

  public isHour(periodType: string): boolean {
    throw new Error("Not implemented");
  }

  public isShift(periodType: string): boolean {
    throw new Error("Not implemented");
  }

  public isDay(periodType: string): boolean {
    throw new Error("Not implemented");
  }

  public isWeek(periodType: string): boolean {
    throw new Error("Not implemented");
  }

  public isMonth(periodType: string): boolean {
    throw new Error("Not implemented");
  }

  public isYear(periodType: string): boolean {
    throw new Error("Not implemented");
  }
}
