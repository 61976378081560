import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { tap } from "rxjs/operators";
import { LocalizationService } from "../../../i18n/localization.service";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { isDefined } from "../../../ts-utils";
import { ErrorLogger } from "../../services/error-logger";
import { ErrorHandlingActions } from "./error-handling.actions";

const durationInMs = 5000;

const DEFAULT_ERROR_CONFIG: MatSnackBarConfig = {
  duration: durationInMs,
  horizontalPosition: "right",
  verticalPosition: "bottom",
  panelClass: ["snack-bar-notification", "snack-bar-notification--error"]
};

const DEFAULT_WARNING_CONFIG: MatSnackBarConfig = {
  duration: durationInMs,
  horizontalPosition: "right",
  verticalPosition: "bottom",
  panelClass: ["snack-bar-notification", "snack-bar-notification--warning"]
};

const DEFAULT_INFO_CONFIG: MatSnackBarConfig = {
  duration: durationInMs,
  horizontalPosition: "right",
  verticalPosition: "bottom",
  panelClass: ["snack-bar-notification", "snack-bar-notification--info"]
};

const DEFAULT_INSTRUCTION_CONFIG: MatSnackBarConfig = {
  horizontalPosition: "center",
  verticalPosition: "bottom",
  panelClass: ["snack-bar-notification"]
};

@Injectable()
export class ErrorHandlingEffects {
  constructor(
    private actions$: Actions,
    private snackBar: MatSnackBar,
    private translationService: LocalizationService,
    private errorLogger: ErrorLogger
  ) {}

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ErrorHandlingActions.handleError),
        tap(({ messageToDisplay, error, autoClose }) => {
          if (isDefined(error)) {
            console.log(error);
          }
          if (messageToDisplay != null) {
            const config = Object.assign({}, DEFAULT_ERROR_CONFIG);
            if (autoClose === false) {
              config.duration = 1e7;
            }
            this.displayMessage(messageToDisplay, config);
          }
          this.errorLogger.add(messageToDisplay, error);
        })
      ),
    { dispatch: false }
  );

  displayWarning$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ErrorHandlingActions.handleWarning),
        tap(({ messageToDisplay }) => {
          this.displayMessage(messageToDisplay, DEFAULT_WARNING_CONFIG);
        })
      ),
    { dispatch: false }
  );

  displayInfo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ErrorHandlingActions.displayInfo),
        tap(({ messageToDisplay }) => {
          this.displayMessage(messageToDisplay, DEFAULT_INFO_CONFIG);
        })
      ),
    { dispatch: false }
  );

  private displayMessage(message: string, config: MatSnackBarConfig) {
    const actionButtonText = this.translationService.get(
      LOCALIZATION_DICTIONARY.snackBarMessages.Close
    );
    this.snackBar.open(message, actionButtonText, config);
  }

  displayInstruction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ErrorHandlingActions.displayInstruction),
        tap(({ instruction }) => {
          this.snackBar.open(instruction, "x", DEFAULT_INSTRUCTION_CONFIG);
        })
      ),
    { dispatch: false }
  );

  closeSnackBar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ErrorHandlingActions.closeSnackBar),
        tap(() => this.snackBar.dismiss())
      ),
    { dispatch: false }
  );
}
