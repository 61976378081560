import { construct } from "../../core";
import { DataTransferObject } from "../../core/models/data-transfer-object";
import { EditableType, Serializable } from "../../meta/decorators";
import { DeepPartial, Maybe } from "../../ts-utils";

const TYPE_NAME = "CacheOptionsDto";

// @dynamic
@EditableType({ fullName: TYPE_NAME })
export class CacheOptionsDto implements DataTransferObject {
  typeName = TYPE_NAME;

  @Serializable(false)
  enabled!: boolean;

  @Serializable(null)
  currentTimestamp: Maybe<Date>;

  constructor(partialCache: DeepPartial<CacheOptionsDto> = {}) {
    construct(this, partialCache, TYPE_NAME);
  }
}
