import { CustomFilterDescriptorDto } from "../../core/models/filter/custom-filter-descriptor";
import { isDefined, isEmpty } from "../../ts-utils";
import { DeepPartial } from "../../ts-utils/models/deep-partial.type";
import { Maybe } from "../../ts-utils/models/maybe.type";

export function resolveCustomFilterKey(
  customFilter: DeepPartial<CustomFilterDescriptorDto>
): Maybe<string> {
  if (isDefined(customFilter.key) && !isEmpty(customFilter.key)) {
    return customFilter.key;
  }
  if (isDefined(customFilter.label) && !isEmpty(customFilter.label)) {
    return customFilter.label;
  }
  return null;
}
