import { AfterViewInit, Component, Inject, OnDestroy, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatListOption } from "@angular/material/list";
import { MatSelect } from "@angular/material/select";
import { from, Subject } from "rxjs";
import { debounceTime, map, takeUntil } from "rxjs/operators";
import { SignalDataSourceDto } from "../../../../../data-connectivity";
import { DataService } from "../../../../../data-connectivity/services/data.service";
import { BaseDialogComponent } from "../../../../../shared/components/base-dialog/base-dialog.component";
import { SignalData } from "../../../../models/dialogs/signal-data.model";

@Component({
  selector: "c-add-signal-dialog",
  templateUrl: "./add-signal-dialog.component.html",
  styleUrls: ["./add-signal-dialog.component.scss"]
})
export class AddSignalDialogComponent
  extends BaseDialogComponent
  implements AfterViewInit, OnDestroy
{
  public axisArray: any[] = [];
  public signalArray: string[] = [];
  public selectedAxis: string;
  public noValuesSelected: boolean;
  private noSignalsSelected: boolean;
  private noAxisSelected: boolean;
  private selectedSignals: string[];

  @ViewChild("axisList", { static: true }) axisList: MatSelect;

  unsubscribeSubject$: Subject<any> = new Subject<any>();

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<AddSignalDialogComponent>,
    private dataService: DataService
  ) {
    super();
    this.noValuesSelected = true;
    this.noSignalsSelected = true;
    this.noAxisSelected = true;

    this.axisArray = data.axisArray;
  }

  ngAfterViewInit(): void {
    const DEBOUNCE_TIME = 50;
    from(this.axisList.selectionChange)
      .pipe(
        debounceTime(DEBOUNCE_TIME),
        map(() => {
          return true;
        }),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe((axisSelected: boolean) => {
        this.noAxisSelected = !axisSelected;

        if (this.noSignalsSelected) {
          this.noValuesSelected = true;
          return;
        }

        this.noValuesSelected = this.noAxisSelected;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  onDialogClosed(): void {
    const signalData: SignalData = {
      // FIXME 7 rename class to AxisData, give variable better name
      axisId: this.selectedAxis,
      signalIds: this.selectedSignals
    };
    this.dialogRef.close(signalData);
  }

  onChanged(signals: SignalDataSourceDto[]) {
    this.selectedSignals = signals.map((dataSource) => dataSource.signal.name); // FIXME 8 should not use only string[]
  }

  public setAxis(axisSelected: string) {
    this.selectedAxis = axisSelected;
  }

  public getValuesFromListOptions(listOptions: MatListOption[]) {
    return listOptions.map((listOption) => listOption.value);
  }
}
