import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, tap } from "rxjs/operators";
import { CommonActions } from "../../../elements/store/common/common.actions";
import { AboutComponent } from "../../components/about/about.component";
import { CreditsComponent } from "../../components/credits/credits.component";
import { HelpDialogActions } from "../actions/help-actions";
import { AboutDialogStrategy, CreditsDialogStrategy } from "../dialog-config.strategies";
import { OpenDialogEffect } from "./dialog.effects";

@Injectable()
export class HelpDialogEffects extends OpenDialogEffect {
  constructor(private actions$: Actions, dialog: MatDialog) {
    super(dialog);
  }

  openAboutStrategy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HelpDialogActions.openAboutDialog),
      tap(() => {
        this.dialogConfigStrategy = new AboutDialogStrategy();
        this.dialogConfig = this.dialogConfigStrategy.createDialogConfig();
        this.dialogRef = this.dialog.open(AboutComponent, this.dialogConfig);
      }),
      map(() => {
        return CommonActions.doNothing();
      })
    )
  );

  openCreditsStrategy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HelpDialogActions.openCreditsDialog),
      tap(() => {
        this.dialogConfigStrategy = new CreditsDialogStrategy();
        this.dialogConfig = this.dialogConfigStrategy.createDialogConfig();
        this.dialogRef = this.dialog.open(CreditsComponent, this.dialogConfig);
      }),
      map(() => {
        return CommonActions.doNothing();
      })
    )
  );
}
