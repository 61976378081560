import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import { LinkResolver, ReportId, ReportLinkDto } from "ui-core";
import { Dashboard } from "../../../models/side-nav/sidebar/dashboard";
import { DashboardChange } from "../../../models/side-nav/sidebar/dashboard-change";
import { DashboardChangeType } from "../../../models/side-nav/sidebar/dashboard-change-type";
import { DashboardType } from "../../../models/side-nav/sidebar/dashboard-type";
import { DashboardNavigationService } from "../../../services/sidebar/dashboard-navigation.service";
import { FleetViewDataService } from "../../../services/sidebar/fleet-view.service";
import { DialogComponent } from "../dialog/dialog.component";

export enum DialogType {
  Delete = "Delete",
  Rename = "Rename"
}

@Component({
  selector: "report-element",
  templateUrl: "report-element.component.html",
  styleUrls: ["./report-element.component.scss"]
})
export class ReportElementComponent implements OnInit, OnDestroy {
  /**
   * Input element for title edit.
   */
  @ViewChild("editInput") input?: ElementRef;
  /**
   * Whether the dashboard currently open is this one.
   */
  @Input() isActive: boolean = false;
  /**
   * Whether to enable different styling for drag mode.
   */
  @Input() isDragModeOn: boolean = false;
  /**
   * All current load dashboards
   */
  @Input() dashboards?: Dashboard[] = [];
  /**
   * Dashboard configuration.
   */
  @Input() dashboard: Dashboard = {} as Dashboard;
  /**
   * String representation of `DragPosition` sent from the `SidebarNavigationComponent`.
   */
  @Input() style: string = "";
  @Input() filterText: string = "";
  /**
   * Whether to enable the trash icon and disables the pencil icon. Hides the tooltip on set.
   */
  @Input()
  set isDeleteModeOn(value: boolean) {
    this._isDeleteModeOn = value;
    this.isTooltipEnabled = false;
  }
  get isDeleteModeOn(): boolean {
    return this._isDeleteModeOn;
  }
  /**
   * Whether to show tool-top element.
   */
  public isTooltipEnabled: boolean = false;
  /**
   * Whether to change span into a input element.
   */
  public isEditModeOn: boolean = false;
  /**
   * Whether the renaming mode is currently on.
   */
  public isRenameModeOn: boolean = false;
  /**
   * Information for the user about current state.
   */
  public tooltipMessage: string = "";
  /**
   * Whether the new title user wrote is valid.
   */
  public isTitleValid: boolean = true;
  /**
   * Error message displayed inside of the tool-tip element.
   */
  public errorMessage: string | null = "";
  /**
   * Margin of the element provided by the parent component.
   */
  public margin: number = 0;
  /**
   * Whether to enable the trash icon and disables the pencil icon. Hides the tooltip on set.
   */
  private _isDeleteModeOn: boolean = false;

  public isEditor = false;

  unsubscribeSubject$ = new Subject();

  constructor(
    private dashboardsService: DashboardNavigationService,
    private linkResolver: LinkResolver,
    public dialog: MatDialog,
    private fleetViewDataService: FleetViewDataService
  ) {}

  ngOnInit(): void {
    this.fleetViewDataService.isEditor
      .pipe(
        tap((res: boolean) => (this.isEditor = res)),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe();

    this.dashboardsService.onTooltipClose
      .pipe(
        tap(() => {
          this.isTooltipEnabled = false;
          this.isEditModeOn = false;
        }),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe();

    this.dashboardsService.isCustomizeMode
      .pipe(
        tap((res: boolean) => {
          this.isRenameModeOn = res;
        }),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe();

    this.margin = this.getDashboardElementMargin(this.dashboard.type);
  }

  public getStyledNodeName(node: Dashboard): string {
    const index = node.title.toLowerCase().indexOf(this.filterText.toLowerCase());
    if (index >= 0 && this.filterText) {
      const beforeMatch = node.title.slice(0, index);
      const match = node.title.slice(index, index + this.filterText.length);
      const afterMatch = node.title.slice(index + this.filterText.length);
      return `${beforeMatch}<span class="filter-match">${match}</span>${afterMatch}`;
    } else {
      return node.title; // No filter, or no match; return the unmodified name
    }
  }

  /**
   * Change the current dashboard using link as URL.
   */
  public changeDashboard(): void {
    if (!this.isDragModeOn && !this.isDeleteModeOn) {
      this.linkResolver.resolveLink(
        new ReportLinkDto({
          info: { reportId: this.dashboard.link as ReportId, reportName: this.dashboard.title }
        })
      );
    }
  }

  /**
   * Toggle off all the other tooltips and display a message with options if on.
   * Save current tooltip status before emitting a close trigger.
   */
  public toggleTooltip(): void {
    if (this.isDeleteModeOn) {
      this.openDialog("delete");
    } else {
      this.openDialog("rename");
      this.tooltipMessage = "Save report name";
    }

    this.errorMessage = null;
    this.isTitleValid = true;

    const tooltipStatus = this.isTooltipEnabled;
    this.dashboardsService.closeTooltips();
    this.isTooltipEnabled = !tooltipStatus;

    if (!this.isDeleteModeOn) {
      this.isEditModeOn = !tooltipStatus;
    }
  }

  /**
   * Open dialog window for deleting or renaming
   * @param type Corresponds to the action that will be triggered for the report according to type.
   */
  openDialog(type: string): void {
    const dialogTitle = type === "delete" ? this.getDeleteTooltip() : DialogType.Rename;
    const showInput = type === "delete" ? false : true;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "auto",
      data: { name: this.dashboard.title, dialogTitle, showInput, type }
    });

    dialogRef
      .afterClosed()
      .pipe(
        tap((result) => {
          if (result) {
            const action = result.action === "delete" ? "delete" : "rename";
            if (action === "delete") {
              this.deleteDashboard();
            } else if (action === "rename" && result.reportName.length > 0) {
              this.renameDashboard(result.reportName);
            }
          }
        }),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe();
  }

  /**
   * Show alert message for deleting a few report
   */
  private getDeleteTooltip(): string {
    const dashboardChildren =
      this.dashboard && this.dashboard.children ? this.dashboard.children : [];
    return dashboardChildren.length > 0 ? "Delete all children for this" : DialogType.Delete;
  }

  /**
   * Go to a method based on the current mode.
   */
  public handleButtonClick(): void {
    this.isDeleteModeOn ? this.deleteDashboard() : this.validateRenaming();
  }

  /**
   * Close the tooltip if user tried to save Edit Mode with the same title.
   */
  public validateRenaming(): void {
    this.dashboard.title === this.input?.nativeElement.value
      ? this.toggleTooltip()
      : this.renameDashboard(this.input?.nativeElement.value);
  }

  /**
   * Remove the dashboard and send the change to `DashboardService`.
   */
  public deleteDashboard(): void {
    const update: DashboardChange = {
      current: this.dashboard,
      type: DashboardChangeType.Deleted,
      dashboards: this.dashboards as Dashboard[]
    };

    this.dashboardsService.updateDashboard(update);
  }

  /**
   * Toggle the favorite status and send the change to `DashboardService`.
   */
  public toggleMain(): void {
    const changes: DashboardChange = {
      current: this.dashboard,
      type: DashboardChangeType.SelectedMain,
      dashboards: this.dashboards as Dashboard[]
    };

    this.dashboardsService.updateDashboard(changes);
  }

  /**
   * Save made changes and send them to `DashboardService`.
   */
  private renameDashboard(value: string): void {
    const update = { ...this.dashboard, title: value };

    const changes: DashboardChange = {
      previous: this.dashboard,
      current: update,
      type: DashboardChangeType.Renamed,
      dashboards: this.dashboards as Dashboard[]
    };
    this.dashboardsService.updateDashboard(changes);

    this.isTooltipEnabled = false;
    this.isTitleValid = true;
  }

  /**
   * Return appropriate margin for the element based on its type.
   * @param type Corresponds to the Dashboard level in Mat Tree.
   */
  private getDashboardElementMargin(type: DashboardType): number {
    const NO_MARGIN: number = 0;
    const CHILD_MARGIN: number = 20;
    const GRANDCHILD_MARGIN: number = 30;

    switch (type) {
      case DashboardType.Parent: {
        return NO_MARGIN;
      }
      case DashboardType.Child: {
        return CHILD_MARGIN;
      }
      case DashboardType.Grandchild: {
        return GRANDCHILD_MARGIN;
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.complete();
  }
}
