<div class="pole-status__indicator-container">
  <div *ngFor="let pole of poles; index as i" class="pole-status__indicator-wrapper">
    <c-simple-status-indicator
      [status]="getStatus(pole)"
      style="width: 9px; height: 9px"
    ></c-simple-status-indicator>
    <b class="pole-status__indicator-enumerator">{{ i + 1 }}</b>
  </div>
</div>
<div class="pole-status__legend-container">
  <km-legend
    [itemList]="[
      { name: 'Alert', color: '#FF0000' },
      { name: 'Warning', color: '#FFBB00' },
      { name: 'OK', color: '#005c27' }
    ]"
  ></km-legend>
</div>
