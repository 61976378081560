import { DataPointValue } from "../models/data-point";

export type TransformationFunction = (points: DataPointValue[]) => DataPointValue;

export class TransformationFunctionFactory {
  static get(funcName: string): TransformationFunction {
    switch (funcName.toLowerCase()) {
      case "sum": {
        return (points: number[]): number => {
          return points.reduce((acc, point) => (acc += point), 0);
        };
      }
      case "sub": {
        return (points: number[]): number => {
          return points.reduce((acc, point, index) => {
            if (index === 0) {
              acc += point;
            } else {
              acc -= point;
            }
            return acc;
          }, 0);
        };
      }
      case "max": {
        return (points: number[]): number => {
          return Math.max(...points);
        };
      }
      case "min": {
        return (points: number[]): number => {
          return Math.min(...points);
        };
      }
      case "avg": {
        return (points: number[]): number => {
          if (!Array.isArray(points) || points.length === 0) {
            return null;
          }
          const sum = points.reduce((acc, point) => (acc += point), 0);
          return sum / points.length;
        };
      }
    }
  }
}
