import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { Actions, ofType } from "@ngrx/effects";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, take } from "rxjs/operators";
import { LIMITS_DTO } from "../../../core/models/dto-type.constants";
import { Dispatcher } from "../../../dispatcher";
import {
  COLOR_PICKER_DIALOG_HEIGHT,
  COLOR_PICKER_DIALOG_WIDTH
} from "../../../elements/helpers/color.helper";
import { LIMIT_COLOR_SUFIX } from "../../../elements/models/limit-color-config";
import { LocalizationService } from "../../../i18n/localization.service";
import { PropertyDescriptor } from "../../../meta/models/property-descriptor";
import { TypeProvider } from "../../../meta/services/type-provider";
import { ColorPickerDialogActions } from "../../../shared/dialogs/actions/color-picker-dialog.actions";
import { ColorPickerDialogInfo } from "../../../shared/models/color-picker-dialog-info";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { focusItemInput } from "../../helpers/input-editor-helper";
import { LimitEditorVisualization } from "../../models/limit-editor-visualization";

@Component({
  selector: "limit-value-color-editor",
  templateUrl: "limit-value-color-editor.component.html",
  styleUrls: ["limit-value-color-editor.component.scss"]
})
export class LimitValueColorEditorComponent implements OnInit, OnChanges {
  @Input() editorVisualization: LimitEditorVisualization;
  @Input() key: string = "";
  @Input() color: string = "";
  @Input() value: Maybe<number>;
  public defaultColor: string = "";
  public defaultValue: Maybe<number>;
  valueUpdate: Subject<string> = new Subject<string>();
  colorUpdate: Subject<string> = new Subject<string>();
  @Output() onChangeColor: EventEmitter<string> = new EventEmitter<string>();
  @Output() onChangeValue: EventEmitter<any> = new EventEmitter();
  @ViewChild("limitInput") limitInput?: ElementRef;

  constructor(
    public localizer: LocalizationService,
    public dispatcher: Dispatcher,
    public typeProvider: TypeProvider,
    private actions$: Actions
  ) {}

  ngOnInit(): void {
    this.initDefaults();
    this.valueUpdate.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((value: string) => {
      this.onChangeValue.emit();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isDefined(changes["value"]?.currentValue)) {
      focusItemInput(this.limitInput?.nativeElement);
    }
  }

  initDefaults(): void {
    const typeDescriptor = this.typeProvider.getType(LIMITS_DTO);
    const valuePropertyDescriptor: Maybe<PropertyDescriptor> = typeDescriptor.getPropertyByName(
      this.key
    );
    const colorPropertyDescriptor: Maybe<PropertyDescriptor> = typeDescriptor.getPropertyByName(
      `${this.key}${LIMIT_COLOR_SUFIX}`
    );
    if (isDefined(valuePropertyDescriptor)) {
      this.defaultValue = valuePropertyDescriptor.defaultValue;
    }
    if (isDefined(colorPropertyDescriptor)) {
      this.defaultColor = colorPropertyDescriptor.defaultValue;
    }
  }

  openColorPicker(event: MouseEvent): void {
    const top: number =
      window.innerHeight - event.pageY < COLOR_PICKER_DIALOG_HEIGHT
        ? event.pageY - COLOR_PICKER_DIALOG_HEIGHT
        : event.pageY;
    const left: number =
      window.innerWidth - event.pageX < COLOR_PICKER_DIALOG_WIDTH
        ? event.pageX - COLOR_PICKER_DIALOG_WIDTH
        : event.pageX;

    this.openColorPickerDialog(left, top);
  }

  openColorPickerDialog(left: number, top: number): void {
    const dialogConfig: ColorPickerDialogInfo = {
      top: top.toString() + "px",
      left: left.toString() + "px",
      color: this.color,
      defaultColor: this.defaultColor
    };
    this.dispatcher.dispatch(
      ColorPickerDialogActions.openColorPickerDialog({ colorPickerDialogInfo: dialogConfig })
    );
    this.subscribeToDialogClose();
  }

  private subscribeToDialogClose(): void {
    this.actions$
      .pipe(ofType(ColorPickerDialogActions.onColorPickerDialogClosed), take(1))
      .subscribe(({ selectedColorInfo }) => {
        this.color = selectedColorInfo;
        this.onChangeColor.emit(this.color);
      });
  }

  getLimitInput(): Maybe<ElementRef> {
    return this.limitInput;
  }
}
