import { Component } from "@angular/core";
import { BaseComponent, ConnectorRoles, EditableType, View } from "ui-core";
import { DowntimeAnalysisViewConfig } from "./view-config";

@Component({
  selector: "rds-downtime-analysis",
  templateUrl: "./downtime-analysis.component.html",
  styleUrls: ["./downtime-analysis.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: DowntimeAnalysisComponent }]
})
// @LayoutBuilder(ComponentCategory.Premade, "DowntimeAnalysisComponent", "Dashboard_2")
@ConnectorRoles()
@EditableType({ fullName: "DowntimeAnalysisComponent", title: "downtime-analysis" })
export class DowntimeAnalysisComponent extends BaseComponent {
  @View(DowntimeAnalysisViewConfig)
  public get view() {
    return this.currentState.view as DowntimeAnalysisViewConfig;
  }
}
