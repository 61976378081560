import { Component, Input } from "@angular/core";
import { iconDefaultSize } from "../../../../style-variables";
import { isDefined } from "../../../ts-utils/helpers";
import { Maybe } from "../../../ts-utils/models";
import { IconId } from "../../models/icon-id";

@Component({
  selector: "icon",
  templateUrl: "icon.component.html"
})
export class IconComponent {
  private _iconSize: number = iconDefaultSize;
  @Input() public icon!: Maybe<IconId>;
  @Input() public color!: string;

  @Input()
  public set iconSize(value: Maybe<number>) {
    this._iconSize = isDefined(value) ? value : iconDefaultSize;
  }

  public get iconSize(): number {
    return this._iconSize;
  }

  public get iconHtml(): string {
    const res = "&#x" + this.icon?.char.toString(16) + ";";
    return res;
  }
}
