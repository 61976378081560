export interface FeedbackFormData {
  title: string;
  description: string;
  type: FeedbackType;
  attachments: File[];
}

export enum FeedbackType {
  Bug = "Bug",
  FeatureRequest = "Feature Request",
  Question = "Question",
  Other = "Other"
}
