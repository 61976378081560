import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Observable, of as observableOf, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { ReportDescription } from "../../../core";
import { LocalizationService } from "../../../i18n/localization.service";
import { ReportBrowserNode } from "../../models/report-browser-node";
import { ReportBrowserSelector } from "../../services/report-browser.selector";
import { BrowsingState } from "../../store/browsing.state";
import { State } from "../../store/cached-reports";

@Component({
  selector: "report-browser",
  templateUrl: "report-browser.component.html",
  styleUrls: ["report-browser.component.scss"]
})
export class ReportBrowserComponent implements OnInit, OnDestroy {
  private unsubscribeSubject$: Subject<any> = new Subject();
  private searchText: string = "";
  public allReportDescriptions$: Observable<ReportDescription[]>;
  public filteredReportDescriptions$: Observable<ReportDescription[]>;
  public nodes: ReportBrowserNode[];
  public searchInputChanged$: Subject<string> = new Subject<string>();
  public showProgressBar = false;
  @Input() public refreshReports$: Subject<boolean>;
  @Input() public shouldShowReportDescription: boolean = true;
  @Output() public clickedReport: EventEmitter<ReportDescription> =
    new EventEmitter<ReportDescription>();
  public currentReportSelected: ReportDescription;

  constructor(
    private reportBrowserSelector: ReportBrowserSelector,
    public localizer: LocalizationService
  ) {}

  ngOnInit() {
    this.currentReportSelected = null;
    this.allReportDescriptions$ = new Observable<ReportDescription[]>();
    this.filteredReportDescriptions$ = new Observable<ReportDescription[]>();
    this.reportBrowserSelector.checkIfIdsCached();

    this.refreshReports$
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((refreshReports: boolean) => {
        if (refreshReports) {
          this.reloadReportIds();
        }
      });

    this.reportBrowserSelector.subscribe(
      (reportDesc: ReportDescription[]) => {
        this.allReportDescriptions$ = observableOf(reportDesc);
        this.filterReports(this.searchText);
      },
      (state: BrowsingState) => {
        return state.allReportDescriptions;
      },
      this.unsubscribeSubject$
    );

    this.reportBrowserSelector.subscribe(
      (openedReports: State) => {
        if (openedReports.ids.length === 0) {
          return;
        }
        const currentReportTreeExpanded: ReportBrowserNode =
          openedReports.entities[this.currentReportSelected.reportId];
        if (!!currentReportTreeExpanded) {
          this.nodes = [...currentReportTreeExpanded.children];
        }
      },
      (state: BrowsingState) => {
        return state.cachedReports;
      },
      this.unsubscribeSubject$
    );
  }

  ngOnDestroy() {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  searchInputChanged(event: KeyboardEvent): void {
    this.filterReports((<HTMLInputElement>event.target).value);
  }

  filterReports(searchText: string): void {
    this.searchText = searchText;
    if (searchText === "") {
      this.filteredReportDescriptions$ = this.allReportDescriptions$;
    }
    this.filteredReportDescriptions$ = this.allReportDescriptions$.pipe(
      map((rep: ReportDescription[]) => {
        return rep.filter((report) =>
          report.reportName.toLowerCase().includes(searchText.toLowerCase())
        );
      })
    );
  }

  reloadReportIds(): void {
    this.reportBrowserSelector.refreshReportIds();
    this.reportBrowserSelector.deleteCachedReports();
  }

  createReportLink(reportDescription: ReportDescription): void {
    this.currentReportSelected = reportDescription;
    this.clickedReport.emit(reportDescription);
  }
}
