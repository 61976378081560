import { Injectable, Type } from "@angular/core";
import { TypeDescriptor } from "../../meta/models/type-descriptor";
import { TypeProvider } from "../../meta/services/type-provider";
import { BaseViewConfigDto } from "../models/base-view-config";

export interface ViewConfigMapping {
  viewConfigNativeType: Type<BaseViewConfigDto>;
  viewConfigType: TypeDescriptor;
  componentType: TypeDescriptor;
}
const TYPE_PROVIDER = TypeProvider.getInstance();

@Injectable()
export class ViewConfigProvider {
  private _mappings: ViewConfigMapping[] = [];
  static _instance: ViewConfigProvider;

  getNativeType(componentTypeDescriptor: TypeDescriptor): Type<BaseViewConfigDto> {
    const matches = findMatchingMappings(this._mappings, componentTypeDescriptor);
    if (matches.length === 0) {
      // fallback to default as not all components have specific view config class
      return BaseViewConfigDto;
    }
    return matches[0].viewConfigNativeType;
  }

  getType(componentTypeDescriptor: TypeDescriptor): TypeDescriptor {
    const matches = findMatchingMappings(this._mappings, componentTypeDescriptor);
    if (matches.length === 0) {
      return TYPE_PROVIDER.getTypeByConstructor(BaseViewConfigDto);
    }
    return matches[0].viewConfigType;
  }

  get<T extends BaseViewConfigDto>(viewConfigType: Type<T>) {
    const matches = this._mappings.filter(
      (mapping: ViewConfigMapping) => mapping.viewConfigNativeType === viewConfigType
    );
    return matches[0];
  }

  add(mapping: ViewConfigMapping) {
    this._mappings.push(mapping);
  }

  static getInstance(): ViewConfigProvider {
    return (
      ViewConfigProvider._instance ?? (ViewConfigProvider._instance = new ViewConfigProvider())
    );
  }
}

export const viewConfigProviderFactory = () => {
  return ViewConfigProvider.getInstance();
};

function findMatchingMappings(
  mappings: ViewConfigMapping[],
  componentTypeDescriptor: TypeDescriptor
): ViewConfigMapping[] {
  const matches = mappings.filter((mapping) => mapping.componentType === componentTypeDescriptor);
  if (matches.length > 1) {
    console.error(`Multiple view configs found for ${componentTypeDescriptor.name}`, matches);
  }
  return matches;
}
