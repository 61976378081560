import { EMPTY_DATA_SOURCE } from "../../data-connectivity";
import { DataConnectorDto } from "../../data-connectivity/models/data-connector";
import { DataSourceDto } from "../../data-connectivity/models/data-source/data-source";
import { Dictionary } from "../../ts-utils/models/dictionary.type";
import { ComponentStateDto } from "../models/component-state";
import { DataStatus } from "../models/data-status";
import { ComponentStateSelector } from "../services/entity-selectors/component-state.selector";
import { DataConnectorSelector } from "../services/entity-selectors/data-connector.selector";
import { isDataSourceNotEmpty } from "./connectors.helper";

export function ensureDataStatusOnLoad(
  component: ComponentStateDto,
  componentConnectors: DataConnectorDto[]
): ComponentStateDto {
  let componentDataStatus = aggregateComponentStatus(
    component.dataConnectorQueryDataStatus,
    componentConnectors
  );

  if (hasRequestOnLoadFailedForDCQ(component.dataConnectorQuery, componentDataStatus)) {
    componentDataStatus = DataStatus.RequestFailed;
    component = { ...component, dataConnectorQueryDataStatus: DataStatus.RequestFailed };
  }

  return { ...component, componentDataStatus };
}

function hasRequestOnLoadFailedForDCQ(
  dataConnectorQuery: DataSourceDto,
  calculatedComponentStatus: DataStatus
): boolean {
  return (
    dataConnectorQuery.typeName !== EMPTY_DATA_SOURCE &&
    calculatedComponentStatus === DataStatus.NoDataDefined &&
    isDataSourceNotEmpty(dataConnectorQuery)
  );
}

export function resolveComponentsToUpdate2(
  componentStates: ComponentStateDto[],
  dataConnectorSelector: DataConnectorSelector
): Dictionary<DataStatus> {
  return componentStates.reduce((acc: Dictionary<DataStatus>, component: ComponentStateDto) => {
    const componentConnectors = dataConnectorSelector.getForComponent(component.id);
    const newComponentStatus: DataStatus = aggregateComponentStatus(
      component.dataConnectorQueryDataStatus,
      componentConnectors
    );
    if (newComponentStatus !== component.componentDataStatus) {
      acc[component.id] = newComponentStatus;
    }
    return acc;
  }, {});
}

export function aggregateComponentStatus(
  dataConnectorQueryDataStatus: DataStatus,
  componentConnectors: DataConnectorDto[]
): DataStatus {
  const dcStates = componentConnectors.map((conn) => conn.dataStatus);

  switch (dataConnectorQueryDataStatus) {
    case DataStatus.RequestFailed:
      return DataStatus.RequestFailed;
    case DataStatus.WaitingForData:
      return DataStatus.WaitingForData;
    case DataStatus.WaitingForMorePreciseData:
      return DataStatus.WaitingForMorePreciseData;
    case DataStatus.NoDataReceived:
      return aggregateDataStatesSimple(dcStates, DataStatus.NoDataReceived);
    case DataStatus.DataReceived:
      return aggregateDataStatesSimple(dcStates, DataStatus.NoDataReceived);
    case DataStatus.NoDataDefined:
      return aggregateDataStatesSimple(dcStates, DataStatus.NoDataDefined);
    case undefined:
      return DataStatus.NoDataDefined;
    default:
      const exhaustiveCheck: never = dataConnectorQueryDataStatus;
      throw new Error(exhaustiveCheck);
      break;
  }
}

function aggregateDataStatesSimple(
  states: DataStatus[],
  resultIfNoDataDefined: DataStatus
): DataStatus {
  if (states.includes(DataStatus.WaitingForData)) {
    return DataStatus.WaitingForData;
  } else if (states.includes(DataStatus.DataReceived)) {
    return DataStatus.DataReceived;
  } else if (states.includes(DataStatus.RequestFailed)) {
    return DataStatus.RequestFailed;
  } else if (states.includes(DataStatus.WaitingForMorePreciseData)) {
    return DataStatus.WaitingForMorePreciseData;
  } else if (states.includes(DataStatus.NoDataReceived)) {
    return DataStatus.NoDataReceived;
  } else {
    return resultIfNoDataDefined;
  }
}

export function getComponentStatusUpdatesIfNeeded(
  componentStateSelector: ComponentStateSelector,
  dataConnectorSelector: DataConnectorSelector
): Dictionary<DataStatus> {
  const allComponents = Object.values(componentStateSelector.getAllAsDict());
  return resolveComponentsToUpdate2(allComponents, dataConnectorSelector);
}
