import { Component } from '@angular/core';
import { AxisLabelsFormatterContextObject } from "highcharts";
import { RDS_LOCALIZATION_DICTIONARY } from "projects/rds/src/assets/i18n/models/rds-localization-dictionary";
import { BaseComponent, ChartComponent, ConnectorRoles, DataConnectorDto, EditableType, LayoutBuilder, View } from "ui-core";
import { RdsComponentCategory } from "../rds-component-category";
import { Roles } from "./roles";
import { PolarChartViewConfig } from "./view-config";

@Component({
  selector: 'rds-polar-chart',
  templateUrl: './polar-chart.component.html',
  styleUrls: ['./polar-chart.component.scss'],
  providers: [
    {
      provide: BaseComponent,
      useExisting: PolarChartComponent
    }
  ]
})
@LayoutBuilder(
  RdsComponentCategory.RDS,
  "PolarChartComponent",
  "Plugin",
  "abb-icon",
  undefined,
  RDS_LOCALIZATION_DICTIONARY.layoutEditor.PolarChart
)
@ConnectorRoles(Roles)
@EditableType({ fullName: "PolarChartComponent", title: "polar-chart" })
export class PolarChartComponent extends ChartComponent {
  private viewConfig!: PolarChartViewConfig;
  private mainConnector: DataConnectorDto = {} as DataConnectorDto;
  private mainValues: number[] = [];
  private numberOfPoles: number = 0;
  private lowestValue: number = 0;
  private highestValue: number = 0;
  private highestIndex: number = 0;
  private lowestIndex: number = 0;

  @View(PolarChartViewConfig)
  public get view() {
    return this.currentState.view as PolarChartViewConfig;
  }

  protected updateChartData(): void {
    this.interpolateProperties();

    const dataConnectors = this.dataAccessor.getAllConnectors();
    this.mainConnector = dataConnectors[0];

    if (this.mainConnector.dataPoints && this.mainConnector.dataPoints.length > 0) {
      this.mainValues = this.mainConnector.dataPoints[0].y as number[];
      this.numberOfPoles = this.mainValues.length;
      this.lowestValue = Math.min(...this.mainValues);
      this.highestValue = Math.max(...this.mainValues);
      this.lowestIndex = this.mainValues.indexOf(this.lowestValue);
      this.highestIndex = this.mainValues.indexOf(this.highestValue);
    }
  }

  protected setChartOptions(): void {
    (this.chartOptions as Highcharts.Options) = {
      chart: {
        polar: true
      },

      title: {
        text: this.viewConfig.title ?? undefined
      },

      pane: {
        startAngle: 0,
        endAngle: 360
      },

      xAxis: {
        tickInterval: 1,
        min: 0,
        max: this.numberOfPoles,
        gridLineColor: "transparent",
        labels: {
          formatter: (context: AxisLabelsFormatterContextObject) => {
            return (Number(context.value) + 1).toString();
          }
        }
      },

      yAxis: {
        min: 0,
        gridLineColor: "transparent"
      },

      plotOptions: {
        series: {
          pointStart: 0,
          pointInterval: 1
        },
        column: {
          pointPadding: 0,
          groupPadding: 0
        }
      },

      legend: {
        enabled: true
      },

      credits: {
        enabled: false
      },

      series: [
        {
          type: 'column',
          name: this.mainConnector.title,
          color: this.viewConfig.basicColor,
          pointPadding: 0.15,
          data: this.mainValues.map((value: number, index: number) => {
            return index === this.highestIndex
              ? { y: value, color: this.viewConfig.highestColor }
              : index === this.lowestIndex
              ? { y: value, color: this.viewConfig.lowestColor }
              : value;
          })
        },
        {
          type: "line",
          name: `Lowest ${this.viewConfig.variable || 'value'}`,
          dashStyle: 'LongDash',
          color: this.viewConfig.lowestColor,
          enableMouseTracking: false,
          lineWidth: 2,
          marker: {
            enabled: false
          },
          visible: this.viewConfig.showExtremes,
          data: Array(this.numberOfPoles).fill(this.lowestValue),
        },
        {
          type: "line",
          name: `Highest ${this.viewConfig.variable || 'value'}`,
          dashStyle: 'LongDash',
          color: this.viewConfig.highestColor,
          enableMouseTracking: false,
          lineWidth: 2,
          marker: {
            enabled: false
          },
          visible: this.viewConfig.showExtremes,
          data: Array(this.numberOfPoles).fill(this.highestValue),
        },
      ]
    };
  }
  
  private interpolateProperties(): void {
    const interpolatedProperties =
      this.propertyInterpolationService.prepareAndInterpolateProperties<PolarChartViewConfig>(
        this.currentState,
        this.dataAccessor.getAllConnectors()
      );
    this.viewConfig = interpolatedProperties.viewConfig;
  }
}
