import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Maybe } from "../../ts-utils";
import { FeedbackFormData } from "../models/feedback-form-data";
import { StoreState } from "../models/store-state";
import { ErrorInfo } from "./error-logger";

@Injectable()
export class IFeedbackService {
  public shouldShowFeedbackOption = false;

  public sendFeedback(
    feedbackFormData: FeedbackFormData,
    fullStoreState: Maybe<StoreState>,
    errors: ErrorInfo[]
  ): Observable<boolean> {
    throw new Error("Not implemented!");
  }

  public getStoreState(): StoreState {
    throw new Error("Not implemented!");
  }
}
