import { Injectable } from "@angular/core";
import { EntityState } from "@ngrx/entity";
import {
  RUNTIME_FILTER_ID,
  getDefaultFilterIdForEntity
} from "../../core/helpers/filter/filter-id.helper";
import { FilterConfigurationDto } from "../../core/models/filter/filter-configuration";
import { TimeRangeConfigurationDto } from "../../core/models/time-range-configuration";
import { ComponentStateDto } from "../../elements/models/component-state";
import { EntityId } from "../../meta/models/entity";
import { isDefined, isEmptyOrNotDefined2, isNotDefined } from "../../ts-utils";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class AddFilterEntityToConnector implements UpgradeStep {
  name = "AddFilterEntityToConnector";
  fromVersion = new Version(4, 0, 6);

  perform(oldConfig: any): UpgradeStepResult {
    const connectorEntities = oldConfig["dataConnectors"]?.["entities"];
    const componentEntities = oldConfig["componentStates"]?.["entities"];
    const filterState = oldConfig["filters"];
    let modified = false;
    modified = this.relocateTimeRangeAndStandardFilters(
      connectorEntities,
      filterState,
      componentEntities
    );
    return {
      result: oldConfig,
      modified: modified,
      warning: null
    };
  }

  private relocateTimeRangeAndStandardFilters(
    dataConnectorEntities: any,
    filterState: any,
    componentEntities: any
  ): boolean {
    if (
      isNotDefined(dataConnectorEntities) ||
      isNotDefined(filterState) ||
      isNotDefined(componentEntities)
    ) {
      return false;
    }
    let modified = false;
    Object.values(dataConnectorEntities).forEach((connector: any) => {
      if (hasFilterProperties(connector)) {
        const filterId = getDefaultFilterIdForEntity(connector.id);
        const sourceFilterId = resolveSourceFilterId(connector.id, componentEntities);
        const filterEntity = getOrCreateEntity<FilterConfigurationDto>(
          filterState,
          filterId.toString(),
          () =>
            new FilterConfigurationDto({
              id: filterId,
              timeRange: new TimeRangeConfigurationDto(connector["timeRange"]),
              sourceFilterId,
              customFilters: connector["standardFilters"]
            })
        );
        connector["filterId"] = filterEntity.id;
        delete connector["timeRange"];
        delete connector["standardFilters"];
        modified = true;
      }
    });
    return modified;
  }
}

function resolveSourceFilterId(connectorId: EntityId, components: ComponentStateDto[]): EntityId {
  const component = Object.values(components).find(
    (component: ComponentStateDto) =>
      isDefined(component.dataConnectorIds) &&
      component.dataConnectorIds.find((id: EntityId) => connectorId === id)
  );
  if (isDefined(component) && !isEmptyOrNotDefined2(component.filterId)) {
    return component.filterId as EntityId;
  }
  return RUNTIME_FILTER_ID;
}

function hasFilterProperties(connector: any): boolean {
  return isDefined(connector["standardFilters"]) || isDefined(connector["timeRange"]);
}

function getOrCreateEntity<T>(state: EntityState<T>, id: string, creator: () => T): T {
  const existing = state.entities[id];
  if (isDefined(existing)) {
    return existing;
  }
  const newEntity = creator();
  (state.ids as string[]).push(id);
  state.entities[id] = newEntity;
  return newEntity;
}
