import { InheritableEnum } from "../../data-connectivity/models/inheritable.enum";

export enum CutOffStrategy {
  End = "End",
  Start = "Start",
  Downsample = "Downsample"
}

export const InheritableCutOffStrategy = { ...CutOffStrategy, ...InheritableEnum };
export type CutOffStrategyType = CutOffStrategy | InheritableEnum;
