import { createSelector } from "@ngrx/store";
import { getReportFeature } from "../feature.selector";
import { ReportState } from "../state";
import { ReportInfoState } from "./report-info.reducer";

export const selectReportInfo = createSelector(
  getReportFeature,
  (state: ReportState) => state.reportInfo
);

export const selectReportId = createSelector(
  selectReportInfo,
  (reportInfo: ReportInfoState) => reportInfo.id
);
