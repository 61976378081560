export type SeriesType = SeriesTypeForTimeSeries | SeriesTypeForScatter;

export const SERIES_TYPE_DEFAULT = "Default";
export const SERIES_TYPE_LINE = "Line";
export const SERIES_TYPE_SCATTER = "Scatter";
export const SERIES_TYPE_BAND = "Band";
export const SERIES_TYPE_X_PLOT_LINE = "XPlotLine";
export const SERIES_TYPE_Y_PLOT_LINE = "YPlotLine";
export const SERIES_TYPE_COLUMN = "Column";
export const SERIES_TYPE_COLUMN_PYRAMID = "ColumnPyramid";
export const SERIES_TYPE_SHAPE = "Shape";

export const SeriesTypeForTimeSeriesArray = [
  SERIES_TYPE_DEFAULT,
  SERIES_TYPE_LINE,
  SERIES_TYPE_SCATTER,
  SERIES_TYPE_BAND,
  SERIES_TYPE_X_PLOT_LINE,
  SERIES_TYPE_Y_PLOT_LINE,
  SERIES_TYPE_COLUMN,
  SERIES_TYPE_COLUMN_PYRAMID
] as const;

export type SeriesTypeForTimeSeries = (typeof SeriesTypeForTimeSeriesArray)[number];

export const SeriesTypeForScatterArray = [
  SERIES_TYPE_DEFAULT,
  SERIES_TYPE_SCATTER,
  SERIES_TYPE_X_PLOT_LINE,
  SERIES_TYPE_Y_PLOT_LINE,
  SERIES_TYPE_SHAPE
] as const;

export type SeriesTypeForScatter = (typeof SeriesTypeForScatterArray)[number];
