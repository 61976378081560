import { Maybe } from "projects/ui-core/src/lib/ts-utils/models/maybe.type";
import { Dispatcher } from "../../../../../dispatcher";
import { EntityId } from "../../../../../meta/models/entity";
import { DataConnectorDescriptor } from "../../../../models/store/data-connector-descriptor";
import { TableColumnConfig } from "../../../../models/table/table-column-config";
import { TableRow } from "../../../../models/table/table-row";
import { TimeSeriesViewConfig } from "../../../time-series/view-config";
import { ITableViewConfig } from "../i-table-for-connectors-view-config";
import { TableRenderingStrategy } from "./table-rendering-strategy";

export class NullStrategy implements TableRenderingStrategy {
  createCols(
    _view: Maybe<TimeSeriesViewConfig>,
    _dataConnectors: Maybe<DataConnectorDescriptor[]>,
    _tableComponentId: Maybe<EntityId>,
    _dispatcher: Dispatcher
  ): Maybe<TableColumnConfig[]> {
    return [];
  }
  createRows(
    _view: ITableViewConfig,
    _dataConnectors: DataConnectorDescriptor[],
    _tableComponentId: Maybe<EntityId>
  ): TableRow[] {
    return [];
  }
}
