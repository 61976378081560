import {
  AfterViewInit,
  Component,
  ComponentRef,
  ElementRef,
  OnDestroy,
  Type,
  ViewChild
} from "@angular/core";
import Draggabilly from "draggabilly";
import { takeUntil } from "rxjs/operators";
import { DraggedItemType } from "../../../core";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { createUpdatedComponentsInfo } from "../../../meta/helpers/updated-entities-info.helper";
import { EntityId } from "../../../meta/models/entity";
import { TypeDescriptorAlias } from "../../../meta/models/type-descriptor";
import { isDefined, isNotDefined, Maybe } from "../../../ts-utils";
import { DynamicComponentsDirective } from "../../directives/dynamic-components.directive";
import { RESIZE_ELEMENT_WIDTH } from "../../directives/resizable-component.directive";
import {
  isAbsolutePositioningType,
  isRelativePositioningType
} from "../../helpers/positioning-type.helper";
import {
  calculateWidgetsDropPosition,
  canDirectlyAcceptDrop,
  findParentContainer,
  getComponentFromPoint,
  getDraggedWidgetsIds,
  getDropAction,
  getSiblingIdUnderPoint,
  initializeDraggabillyContextOnDragStart,
  initializeDraggingIndicator,
  isDroppedAfter,
  isMovingOverContainer,
  limitDragMovement,
  onDropCleanup,
  resolveActionToUpdateOrder,
  resolveCursorOnDragMove,
  resolveDropRect,
  updateDraggedElementsTransparency,
  updateDraggingIndicatorOnMove,
  updateSiblingsOnDragEnd,
  updateSiblingsOnDragMove,
  updateZIndexesOnDragEnd,
  updateZIndexesOnDragStart
} from "../../helpers/widget-dragging.helper";
import { Orientation } from "../../models";
import { ComponentTypeInfo } from "../../models/component-type-info";
import { DraggabillyEventContext } from "../../models/draggabilly-event-context";
import { PositioningType } from "../../models/positioning-type";
import { ActiveContainersService } from "../../services/active-containers.service";
import {
  CARD_ITEM_DRAG_HANDLE,
  ComponentPositioningService
} from "../../services/component-positioning.service";
import { ContainerTimestampService } from "../../services/container-timestamp.service";
import { DomMapper } from "../../services/dom-mapper.service";
import { ComponentStateActions } from "../../store/component-state/component-state.actions";
import { BaseComponent } from "../base/base.component";
import { ComponentConstructorParams } from "../base/component-constructor-params";
import { DraggabillyConfig } from "../page/draggabilly-config";
import { ContainerComponentViewConfig } from "./view-config";

@Component({
  selector: "c-container",
  template: ""
})
@EditableWidget({ fullName: "ContainerComponent", virtual: true })
export class ContainerComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  @ViewChild(DynamicComponentsDirective)
  public dynamicChildren: DynamicComponentsDirective;
  public draggables: Draggabilly[] = [];
  protected draggabillyConfig: DraggabillyConfig = {
    handle: "." + CARD_ITEM_DRAG_HANDLE
  };
  private timestampService = new ContainerTimestampService();
  protected disableDrag = false;

  constructor(
    params: ComponentConstructorParams,
    hostElementRef: ElementRef,
    private activeContainersService: ActiveContainersService = new ActiveContainersService(),
    protected componentPositioningService: ComponentPositioningService
  ) {
    super(params, hostElementRef);
    this.activeContainersService.addContainerComponent(this);
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.componentStatePropertySelector.subscribeOnSliceWithPreviousValue(
      (state) => state.childrenIds,
      ([previousChildIds, currentChildIds]: [EntityId[], EntityId[]]) => {
        this.onChildrenChange(previousChildIds, currentChildIds);
      }
    );
  }

  protected onChildrenChange(previousChildIds: EntityId[], currentChildIds: EntityId[]): void {
    const childIdsForRemoval: EntityId[] = previousChildIds.filter(
      (id: EntityId) => currentChildIds.indexOf(id) < 0
    );
    childIdsForRemoval.forEach((id: EntityId) => {
      const childComponent: BaseComponent = this.getChildComponentById(id);
      this.removeChild(childComponent);
    });

    const addedIds: EntityId[] = currentChildIds.filter((id) => previousChildIds.indexOf(id) < 0);
    if (addedIds.length > 0) {
      this.initializeDynamicChildren(addedIds);
      this.refreshLayout(addedIds);
      this.subscribeToChildTimestampChanges(addedIds);
    }
  }

  protected getChildComponentById(
    childId: string | number,
    childrenComponents: BaseComponent[] = this.childComponents
  ): Maybe<BaseComponent> {
    let foundChild: Maybe<BaseComponent> = childrenComponents.find(
      (childComponent: BaseComponent) => childComponent.id === childId
    );
    if (foundChild) {
      return foundChild;
    }
    const containers: BaseComponent[] = childrenComponents.filter(
      (childComponent: BaseComponent) => childComponent instanceof ContainerComponent
    );
    while (containers.length !== 0 && !foundChild) {
      const container = containers.pop();
      foundChild = this.getChildComponentById(
        childId,
        (container as ContainerComponent).childComponents
      );
    }
    return foundChild ? foundChild : null;
  }

  protected initializeDynamicChildren(idsToAdd: EntityId[]): void {
    if (!!this.dynamicChildren) {
      this.hostElement.id = DomMapper.getHostId(this.id); // Give DOM element unique id for saving drag positions
      const addedChildren = this.children.filter((child) => idsToAdd.includes(child.id));
      this.dynamicChildren.loadStoreComponents(addedChildren);
    }
  }

  protected getChildrenDeep(): BaseComponent[] {
    let allChildComponents: BaseComponent[] = this.childComponents;
    if (allChildComponents == null) {
      return [];
    }
    allChildComponents = this.childComponents.reduce((acc, childComponent) => {
      if (childComponent instanceof ContainerComponent) {
        return acc.concat(childComponent.getChildrenDeep());
      } else {
        return !!childComponent.staticViewChildren
          ? acc.concat(childComponent.staticViewChildren.toArray())
          : acc;
      }
    }, allChildComponents);
    return allChildComponents;
  }

  updateLatestTimestamp(): void {
    // NOTE container components have different way to update timestamp
  }

  protected subscribeToChildTimestampChanges(addedIds: EntityId[]): void {
    const addedChildComponents = this.getChildrenDeep().filter(({ id }: BaseComponent) =>
      addedIds.includes(id)
    );
    this.takeCurrentLatestTimestampFromChildren(addedChildComponents);
    addedChildComponents.forEach((childComponent: BaseComponent) => {
      childComponent.timestampUpdated
        .pipe(takeUntil(this.unsubscribeSubject$))
        .subscribe((lastChildTimestamp: Date) => {
          this.timestampService.setTimestampForComponent(childComponent.id, lastChildTimestamp);
          this.latestTimestamp = this.timestampService.getLatestTimestamp();
          this.timestampUpdated.emit(this.latestTimestamp);
        });
    });
  }

  private takeCurrentLatestTimestampFromChildren(addedChildComponents: BaseComponent[]): void {
    // FIXME: needed because in some cases child components emit timestamp before subscribing to those changes
    if (addedChildComponents == null) {
      return;
    }
    addedChildComponents.forEach((childComponent: BaseComponent) => {
      this.timestampService.setTimestampForComponent(
        childComponent.id,
        childComponent.latestTimestamp
      );
    });
    this.latestTimestamp = this.timestampService.getLatestTimestamp();
    this.timestampUpdated.emit(this.latestTimestamp);
  }

  protected initializeDraggables(idsToAdd: EntityId[]): void {
    if (!this.dynamicChildren) {
      return;
    }
    const addedComponents: ComponentRef<BaseComponent>[] = this.dynamicChildren.components.filter(
      (component: ComponentRef<BaseComponent>) => idsToAdd.indexOf(component.instance.id) >= 0
    );

    this.addDraggables(addedComponents);
  }

  protected addDraggables(elements: ComponentRef<BaseComponent>[]): void {
    elements.forEach((holder) => {
      const isDraggable = holder.instance.currentState.view.selectable;
      if (!isDraggable) {
        return;
      }
      const hostElement = holder.instance.hostElement;
      const shouldCreateDragOverlay = holder.instance.currentState.view.useDragOverlay;

      if (shouldCreateDragOverlay) {
        this.createDragOverlayWithCorrectDragHandle(hostElement);
      }

      const draggable = new Draggabilly(hostElement, this.draggabillyConfig);
      (hostElement as any).draggableInstance = draggable;
      this.addDraggabillyHandlers(holder.instance, draggable);
      if (this.isEditable) {
        draggable.enable();
      } else {
        draggable.disable();
      }
      this.draggables.push(draggable);
    });
  }

  protected createDragOverlayWithCorrectDragHandle(
    hostElement: HTMLElement,
    parentIsPage = false
  ): void {
    this.componentPositioningService.createDragOverlay(hostElement, parentIsPage);
    if (this.isEditable) {
      this.componentPositioningService.showDraggableOverlay(hostElement);
    } else {
      this.componentPositioningService.hideDraggableOverlay(hostElement);
    }
  }

  protected addDraggabillyHandlers(component: BaseComponent, draggable: Draggabilly): void {
    if (isNotDefined(draggable)) {
      return;
    }
    const draggabillyEventContext = new DraggabillyEventContext(
      component.id,
      this,
      draggable,
      document.getElementById("dragging_indicator_wrapper") as HTMLElement
    );
    draggable.on("pointerDown", (event: Event, dropPoint: MouseEvent | Touch) => {
      this.onPointerDown(event, dropPoint, component, draggabillyEventContext);
    });
    draggable.on("dragStart", (event: Event, dropPoint: MouseEvent | Touch) => {
      this.onDragStart(event, dropPoint, draggabillyEventContext, component);
    });
    draggable.on("pointerMove", (event: Event, dropPoint: MouseEvent | Touch) => {
      this.onDragMove(dropPoint, draggabillyEventContext);
    });
    draggable.on("dragEnd", (event: Event, dropPoint: MouseEvent | Touch) => {
      this.undoRedoService.createSnapshot({
        updatedEntitiesInfo: createUpdatedComponentsInfo([component.id])
      });
      this.onDragEnd(event, dropPoint, draggabillyEventContext);
    });
    draggable.on("staticClick", (event: Event, dropPoint: MouseEvent | Touch) => {
      this.onStaticClick(event, dropPoint, draggabillyEventContext, component);
    });
  }

  protected onPointerDown(
    _event: Event,
    _dropPoint: MouseEvent | Touch,
    draggedComponent: BaseComponent,
    _draggabillyContext: DraggabillyEventContext
  ): void {
    this.handleChildSelection(_dropPoint as PointerEvent, draggedComponent, _draggabillyContext);
  }

  protected handleChildSelection(
    event: PointerEvent,
    child: BaseComponent,
    context: DraggabillyEventContext
  ) {
    event.stopPropagation();
    context.isSelectionHandled = !child.isSelected;

    if (child.isSelected) {
      return;
    }

    child.selectComponent(event.ctrlKey);
  }

  protected onStaticClick(
    _event: Event,
    _dropPoint: MouseEvent | Touch,
    context: DraggabillyEventContext,
    draggedComponent: BaseComponent
  ): void {
    if (!context.isSelectionHandled) {
      draggedComponent.selectComponent((_dropPoint as PointerEvent).ctrlKey);
    }
  }

  protected onDragStart(
    _event: Event,
    _dropPoint: MouseEvent | Touch,
    _draggabillyContext: DraggabillyEventContext,
    draggedComponent: BaseComponent
  ): void {
    this.draggedComponentService.setDragTarget({
      type: DraggedItemType.Component,
      item: new ComponentTypeInfo(draggedComponent.typeDescriptor, {} as TypeDescriptorAlias)
    });
    const selectedSiblings = this.getSelectedChildrenHosts().filter(
      (host) => host !== draggedComponent.hostElement
    );
    const draggedElement: HTMLElement = _draggabillyContext?.draggable?.element;
    const positioningType: PositioningType = (
      this.currentState.view as ContainerComponentViewConfig
    ).positioningType;
    limitDragMovement(_draggabillyContext, selectedSiblings);
    initializeDraggabillyContextOnDragStart(
      _draggabillyContext,
      _dropPoint,
      selectedSiblings,
      draggedComponent.currentState
    );
    initializeDraggingIndicator(_draggabillyContext);
    updateZIndexesOnDragStart(positioningType, draggedElement, selectedSiblings);
  }

  protected onDragMove(
    _dropPoint: MouseEvent | Touch,
    _draggabillyContext: DraggabillyEventContext
  ): void {
    if (!_draggabillyContext.isDraggingStarted) {
      return;
    }
    requestAnimationFrame(() => {
      updateSiblingsOnDragMove(_draggabillyContext);
    });
    const componentAtPoint = getComponentFromPoint(_dropPoint.pageX, _dropPoint.pageY);
    const containerAtPoint = findParentContainer(
      componentAtPoint?.currentState,
      this.componentStateSelector
    );
    const isDraggedOverSibling = containerAtPoint?.id === _draggabillyContext.container.id;
    const canDrop = canDirectlyAcceptDrop(componentAtPoint);
    const isMovedOverContainer = isMovingOverContainer(
      _draggabillyContext,
      _dropPoint,
      componentAtPoint
    );
    const isDraggedOverItself =
      _draggabillyContext.draggable.element.angularComponentRef === componentAtPoint;
    const isNotMovedOverParent: boolean = canDrop && !isMovedOverContainer && !isDraggedOverItself;
    const cursor = resolveCursorOnDragMove(
      isNotMovedOverParent,
      isMovedOverContainer,
      _dropPoint,
      isDraggedOverSibling
    );
    document.body.style.cursor = cursor;
    const shouldHaveIndicator = (_dropPoint as PointerEvent).ctrlKey
      ? isNotMovedOverParent
      : isNotMovedOverParent && !isDraggedOverSibling;
    updateDraggingIndicatorOnMove(shouldHaveIndicator, _draggabillyContext, _dropPoint);
    updateDraggedElementsTransparency(_draggabillyContext, cursor === "move");
  }

  protected onDragEnd(
    _event: Event,
    _dropPoint: MouseEvent | Touch,
    draggabillyContext: DraggabillyEventContext
  ): void {
    const positioningType = (this.currentState.view as ContainerComponentViewConfig)
      .positioningType;
    const isDragDropped =
      document.body.style.cursor === "copy" || document.body.style.cursor === "move";

    if (isAbsolutePositioningType(positioningType)) {
      this.updateAbsolutePositionsOnDragEnd(draggabillyContext);
    }
    if (isDragDropped) {
      this.onWidgetsDragDrop(_dropPoint, draggabillyContext, positioningType);
    } else if (isRelativePositioningType(positioningType)) {
      this.updateOrdersOnDragEnd(_dropPoint, draggabillyContext);
      resetDraggedElementsPositioningCss(draggabillyContext);
    }

    onDropCleanup(draggabillyContext);
    this.draggedComponentService.clear();
    updateZIndexesOnDragEnd(positioningType, this.getSelectedChildrenHosts(), _event);
  }

  canAcceptDrop(): boolean {
    const targetFromService = this.draggedComponentService.target;
    return (
      isDefined(targetFromService) &&
      targetFromService.type === DraggedItemType.Component &&
      this.canContain((targetFromService.item as ComponentTypeInfo).descriptor.constructorFunction)
    );
  }

  protected canContain(componentForAttaching: Type<BaseComponent>): boolean {
    const isInstanceOfBaseComponent: boolean =
      componentForAttaching.prototype instanceof BaseComponent;
    return isInstanceOfBaseComponent;
  }

  public get childComponents(): BaseComponent[] {
    if (this.dynamicChildren == null) {
      return [];
    }
    return this.dynamicChildren.components.map((componentRef) => componentRef.instance);
  }

  public removeChild(childControl: BaseComponent): void {
    this.dynamicChildren.removeComponent(childControl);
    const draggable = this.draggables.find(
      (draggable) => draggable.element === childControl.hostElement
    );
    if (draggable != null) {
      draggable.destroy();
      this.draggables = this.draggables.filter(
        (draggable) => draggable.element !== childControl.hostElement
      );
    }
    this.timestampService.removeTimestampForComponent(childControl.id);
    this.latestTimestamp = this.timestampService.getLatestTimestamp();
    this.timestampUpdated.emit(this.latestTimestamp);
  }

  public refreshLayout(idsToAdd: EntityId[]): void {
    this.initializeDraggables(idsToAdd);
  }

  public enableDragging(): void {
    this.showDraggableOverlay();
    this.enableDraggables();
  }

  private showDraggableOverlay(): void {
    this.componentPositioningService.showDraggableOverlay(this.hostElement);
    this.childComponents.forEach((childComponent: BaseComponent) => {
      this.componentPositioningService.showDraggableOverlay(childComponent.hostElement);
    });
  }

  private enableDraggables(): void {
    this.draggables.forEach((draggable) => {
      draggable.enable();
    });
  }

  public disableDragging(): void {
    this.hideDraggableOverlay();
    this.disableDraggables();
  }

  private hideDraggableOverlay(): void {
    this.componentPositioningService.hideDraggableOverlay(this.hostElement);
    this.childComponents.forEach((childComponent: BaseComponent) => {
      this.componentPositioningService.hideDraggableOverlay(childComponent.hostElement);
    });
  }

  private disableDraggables(): void {
    this.draggables.forEach((draggable) => {
      draggable.disable();
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.activeContainersService.removeContainerComponent(this);
  }

  // NOTE: resize elements cause scrollbar when it shouldn't be shown (when widget is very close to border)
  protected shouldEnableScrollbar(orientation: Orientation, holderElement: ElementRef): boolean {
    if (holderElement == null) {
      return false;
    }
    const holderHtmlElement: HTMLElement = holderElement.nativeElement;
    if (orientation === Orientation.Horizontal) {
      const minWidthForScroll = Math.ceil(holderHtmlElement.clientWidth + RESIZE_ELEMENT_WIDTH / 2);
      return holderHtmlElement.scrollWidth > minWidthForScroll;
    } else {
      const minHeightForScroll = Math.ceil(
        holderHtmlElement.clientHeight + RESIZE_ELEMENT_WIDTH / 2
      );
      return holderHtmlElement.scrollHeight > minHeightForScroll;
    }
  }

  private getSelectedChildrenHosts(): HTMLElement[] {
    return this.componentSelectionService.selectedComponentsIds
      .map((id) => this.getChildComponentById(id))
      .filter(isDefined)
      .map((el) => el.hostElement);
  }

  protected pointerDownHandler(event: PointerEvent): void {
    event.stopPropagation();
    this.selectComponent(false);
  }

  private onWidgetsDragDrop(
    dropPoint: MouseEvent | Touch,
    draggabillyContext: DraggabillyEventContext,
    positioningType: PositioningType
  ) {
    const dropDestination: Maybe<BaseComponent> = getComponentFromPoint(
      dropPoint.pageX,
      dropPoint.pageY
    );
    const parentContainer = findParentContainer(
      dropDestination?.currentState,
      this.componentStateSelector
    );
    if (isDefined(parentContainer) && isDefined(dropDestination)) {
      const dropRect = resolveDropRect(dropPoint);
      const dropPositions = calculateWidgetsDropPosition(dropPoint, draggabillyContext, dropRect);
      if (isRelativePositioningType(positioningType)) {
        resetDraggedElementsPositioningCss(draggabillyContext);
      }
      this.dispatch(
        getDropAction(
          parentContainer.id,
          dropPositions,
          getDraggedWidgetsIds(draggabillyContext),
          dropDestination.id,
          isDroppedAfter(dropPoint, dropDestination.hostElement.getBoundingClientRect())
        )
      );
    }
  }

  private updateAbsolutePositionsOnDragEnd(draggabillyContext: DraggabillyEventContext) {
    this.componentPositioningService.updateMovedComponentPosition(
      draggabillyContext.draggableComponentId.toString(),
      draggabillyContext.draggable
    );
    this.dispatch(
      ComponentStateActions.updateChildrenPositions({
        parentId: this.id,
        updateDict: updateSiblingsOnDragEnd(draggabillyContext)
      })
    );
  }

  private updateOrdersOnDragEnd(
    dropPoint: MouseEvent | Touch,
    draggabillyContext: DraggabillyEventContext
  ) {
    const siblingUnderPoint = getSiblingIdUnderPoint(dropPoint, draggabillyContext);
    if (isNotDefined(siblingUnderPoint)) {
      return;
    }
    const siblingUnderPointId = DomMapper.getComponentId(siblingUnderPoint.id);
    getDraggedWidgetsIds(draggabillyContext).map((widgetId) => {
      if (siblingUnderPointId === this.id) {
        this.dispatch(
          ComponentStateActions.updateOrderSetAtEnd({
            componentId: widgetId
          })
        );
      } else if (this.currentState.childrenIds.includes(siblingUnderPointId)) {
        const siblingUnderPointRect = (siblingUnderPoint as HTMLElement).getBoundingClientRect();
        this.dispatch(
          resolveActionToUpdateOrder(
            isDroppedAfter(dropPoint, siblingUnderPointRect),
            siblingUnderPointId,
            widgetId
          )
        );
      }
    });
  }
}

function resetDraggedElementsPositioningCss(draggabillyContext: DraggabillyEventContext): void {
  const draggedHtmlElement = draggabillyContext.draggable.element as HTMLElement;
  draggedHtmlElement.style.top = draggabillyContext.initialTopOffset;
  draggedHtmlElement.style.left = draggabillyContext.initialLeftOffset;
  draggabillyContext.selectedSiblings.map((sibling) => (sibling.style.transform = ""));
}
