import { Dictionary } from "../../ts-utils/models/dictionary.type";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { StrictPartial } from "../../ts-utils/models/strict-partial.type";
import { NumberOfDataPointsToRequestFunction } from "./number-of-data-points-to-request-function";
import { PropertyChangeHandler } from "./property-change-handler";
import { PropertyDescriptor } from "./property-descriptor";

export class TypeDescriptorAlias {
  public name: string;
  public displayName: string;
  public icon: string;
  public iconGroup: string;
  public category: string;
}

export class TypeDescriptor {
  private _name: string;
  private _titleProperty: string;
  private _propertyObservers: Dictionary<PropertyChangeHandler<unknown, unknown, unknown>> = {};
  private _properties: PropertyDescriptor[] = [];
  private _initialConfig: object = {};
  public readonly constructorFunction: new () => any; // ObjectConstructor
  public readonly isPrimitive: boolean;
  public isVirtual: boolean;
  public overridePropertyValues: boolean = false;

  public aliases: TypeDescriptorAlias[] = [];

  public numberOfDataPointsToRequest: Maybe<NumberOfDataPointsToRequestFunction>;

  constructor(params: StrictPartial<TypeDescriptor, "constructorFunction">) {
    this.constructorFunction = params.constructorFunction;
    this._name = params.name ?? "";
    this.initialConfig = params.initialConfig ?? {};
    this.isPrimitive = params.isPrimitive ?? false;
    this.isVirtual = params.isVirtual ?? false;
    this.overridePropertyValues = params.overridePropertyValues ?? false;
    if (this.isVirtual) {
      this.isPrimitive = false;
    }
  }

  upsertProperty(
    name: string,
    propConstructor: Function,
    propPartial: Partial<PropertyDescriptor>
  ): void {
    if (this.getPropertyByName(name)) {
      this.updateProperty(name, propPartial);
    } else {
      this.addProperty(PropertyDescriptor.create(name, propConstructor, propPartial));
    }
  }

  addProperty(prop: PropertyDescriptor): void {
    this._properties.push(prop);
  }

  updateProperty(name: string, change: Partial<PropertyDescriptor>): void {
    this._properties = this._properties.map((property) => {
      return property.name === name ? PropertyDescriptor.createFrom(property, change) : property;
    });
  }

  get titleProperty(): string {
    return this._titleProperty;
  }

  set titleProperty(member: string) {
    this._titleProperty = member;
  }

  get properties(): PropertyDescriptor[] {
    return this._properties;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get initialConfig(): object {
    return this._initialConfig;
  }

  set initialConfig(config: object) {
    this._initialConfig = config;
  }

  public isInheritedFrom(base: TypeDescriptor): boolean {
    let constructorFunction = this.constructorFunction;
    while (constructorFunction && constructorFunction !== Object.prototype) {
      constructorFunction = Object.getPrototypeOf(constructorFunction);
      if (constructorFunction === base.constructorFunction) {
        return true;
      }
    }
    return false;
  }

  public get isCompositeType(): boolean {
    return this.properties.length > 0 || this.isVirtual;
  }

  public getPropertyObserver(
    fullPropertyName: string
  ): PropertyChangeHandler<unknown, unknown, unknown> {
    return this._propertyObservers[fullPropertyName];
  }

  public setPropertyObserver(
    fullPropertyName: string,
    handlerName: PropertyChangeHandler<any, any, any>
  ): void {
    this._propertyObservers[fullPropertyName] = handlerName;
  }

  public getPropertyByName(name: string): Maybe<PropertyDescriptor> {
    return this.properties.find((childDescriptor) => childDescriptor.name === name);
  }
}
