<div class="equipment-model-editor__wrapper">
  <input
    #editorInput
    type="text"
    *ngIf="!isReadOnly"
    [value]="selectedEquipment"
    class="equipment-model-editor__input ng-star-inserted"
    [class.editor--value-configured]="value !== propertyInfo.descriptor.defaultValue"
    (input)="onInputChange($event.target.value)"
    readonly
  />
  <button
    (click)="browse()"
    *ngIf="!isReadOnly"
    class="c-button c-button--rounded equipment-model-editor__browse-button"
  >
    ...
  </button>
  <div
    [ngClass]="{
      'floating-x-hidden': selectedEquipment === '',
      'floating-x-show': selectedEquipment !== ''
    }"
    (click)="onXClick()"
    class="abb-icon Close c-button c-button--rounded equipment-model-editor__clear-input"
  ></div>
</div>
