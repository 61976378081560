<div>
  <nav mat-tab-nav-bar class="navigation-bar__nav" [disableRipple]="true" [tabPanel]="tabPanel" >
    <ul class="navigation-bar__list">
      <li *ngFor="let linkInfo of links">
        <a
          class="navigation-bar__link-title"
          mat-tab-link
          label="linkInfo.title"
          [class.navigation-bar__link--active]="linkInfo.isActive"
          [class.navigation-bar__link--selectable]="!linkInfo.disabled && !isEditable"
          [disabled]="linkInfo.disabled"
          [style.color]="resolveLinkColor(linkInfo.color)"
          (click)="onLinkClick($event, linkInfo.link)"
        >
          <div class="nav-bar__icon" *ngIf="view.displayStatusIcons">
            <icon
              *ngIf="linkInfo.icon; else showCircle"
              [icon]="linkInfo.icon"
              [iconSize]="17"
              [color]="resolveLinkColor(linkInfo.color)"
            ></icon>
            <ng-template #showCircle
              ><i class="status-indicator--circle nav-bar__default-icon"></i
            ></ng-template>
          </div>
          &nbsp;
          <span [style.color]="resolveLinkColor(linkInfo.color)">{{ linkInfo.title }}</span>
        </a>
      </li>
    </ul>
  </nav>
</div>

<mat-tab-nav-panel #tabPanel> </mat-tab-nav-panel>
