import { Injectable } from "@angular/core";
import { TypeDescriptor } from "../../meta/models/type-descriptor";
import { Maybe } from "../../ts-utils";
import { DataSourceDto } from "../models";

export type DataSourceModifierFunction = (dataSource: DataSourceDto) => DataSourceDto;

// TODO rename this class
@Injectable()
export class DataSourceModifiers {
  private static _instance: DataSourceModifiers;
  private _mappings: Map<TypeDescriptor, DataSourceModifierFunction> = new Map<
    TypeDescriptor,
    DataSourceModifierFunction
  >();

  getModifier(componentType: TypeDescriptor): Maybe<DataSourceModifierFunction> {
    return this._mappings.get(componentType);
  }

  add(componentType: TypeDescriptor, modifierFunction: DataSourceModifierFunction): void {
    this._mappings.set(componentType, modifierFunction);
  }

  static getInstance(): DataSourceModifiers {
    if (!DataSourceModifiers._instance) {
      DataSourceModifiers._instance = new DataSourceModifiers();
    }
    return DataSourceModifiers._instance;
  }
}

export const dataSourceModifiersFactory = (): DataSourceModifiers => {
  return DataSourceModifiers.getInstance();
};
