import { inRange as _inRange, min as _min } from "lodash";
import { PositionDto } from "../../core";
import { EntityId } from "../../meta";
import { CSS_COMPONENT_SELECTED } from "../components/base/base.component";
import { RectangleDto } from "../models/resize/rectangle";
import { DomMapper } from "../services/dom-mapper.service";

export function getSelectedChildrenIds(
  containerElement: HTMLElement,
  removeSelectedClass: boolean
): EntityId[] {
  const ids: string[] = [];
  containerElement.querySelectorAll("." + CSS_COMPONENT_SELECTED).forEach((element) => {
    if (removeSelectedClass) {
      element.classList.remove(CSS_COMPONENT_SELECTED);
    }
    ids.push(DomMapper.getComponentId(element.id));
  });
  return ids;
}

export function calculateScrollOffsetX(
  movePoint: PositionDto,
  containerElement: HTMLElement
): number {
  const containerRect = containerElement.getBoundingClientRect();
  if (movePoint.left < containerElement.scrollLeft) {
    return movePoint.left - containerElement.scrollLeft;
  } else if (movePoint.left > containerElement.scrollLeft + containerRect.width) {
    return movePoint.left - containerElement.scrollLeft - containerRect.width;
  } else {
    return 0;
  }
}

export function calculateScrollOffsetY(
  movePoint: PositionDto,
  containerElement: HTMLElement
): number {
  const containerRect = containerElement.getBoundingClientRect();

  if (movePoint.top < containerElement.scrollTop) {
    return movePoint.top - containerElement.scrollTop;
  } else if (movePoint.top > containerElement.scrollTop + containerRect.height) {
    return movePoint.top - containerElement.scrollTop - containerRect.height;
  } else {
    return 0;
  }
}

export function setSelectionBoxStyle(selectionBoxElement: HTMLElement, rectInfo: RectangleDto) {
  selectionBoxElement.style.width = toPx(rectInfo.width);
  selectionBoxElement.style.height = toPx(rectInfo.height);
  selectionBoxElement.style.left = toPx(rectInfo.left);
  selectionBoxElement.style.top = toPx(rectInfo.top);
}

export function getSelectionBoxRectangle(point1: PositionDto, point2: PositionDto): RectangleDto {
  return {
    left: _min([point1.left, point2.left]) ?? 0,
    top: _min([point1.top, point2.top]) ?? 0,
    width: Math.abs(point1.left - point2.left),
    height: Math.abs(point1.top - point2.top)
  };
}

export function isWidgetInsideSelection(
  widgetElement: HTMLElement,
  selectionElement: HTMLElement
): boolean {
  return (
    hasCornerInContainer(widgetElement, selectionElement) ||
    hasCornerInContainer(selectionElement, widgetElement) ||
    isElementBetweenRectangleCorners(selectionElement, widgetElement.getBoundingClientRect())
  );
}

function isElementBetweenRectangleCorners(element: HTMLElement, rect: RectangleDto): boolean {
  const elementRect = element.getBoundingClientRect();
  return (
    (_inRange(rect.left, elementRect.left, elementRect.left + elementRect.width) &&
      _inRange(rect.left + rect.width, elementRect.left, elementRect.left + elementRect.width) &&
      _inRange(elementRect.top, rect.top, rect.top + rect.height)) ||
    (_inRange(rect.top, elementRect.top, elementRect.top + elementRect.height) &&
      _inRange(rect.top + rect.height, elementRect.top, elementRect.top + elementRect.height) &&
      _inRange(elementRect.left, rect.left, rect.left + rect.width))
  );
}

function hasCornerInContainer(element: HTMLElement, containerElement: HTMLElement): boolean {
  const elementRect = element.getBoundingClientRect();
  const containerRect = containerElement.getBoundingClientRect();

  return (
    isTopLeftCornerInsideContainerRect(elementRect, containerRect) ||
    isTopRightCornerInsideContainerRect(elementRect, containerRect) ||
    isBottomLeftCornerInsideContainerRect(elementRect, containerRect) ||
    isBottomRightCornerInsideContainerRect(elementRect, containerRect)
  );
}

function isTopLeftCornerInsideContainerRect(
  cornerRect: RectangleDto,
  containerRect: DOMRect
): boolean {
  return isPointInsideElement(
    {
      left: cornerRect.left,
      top: cornerRect.top
    },
    containerRect
  );
}

function isTopRightCornerInsideContainerRect(
  cornerRect: RectangleDto,
  containerRect: DOMRect
): boolean {
  return isPointInsideElement(
    {
      left: cornerRect.left + cornerRect.width,
      top: cornerRect.top
    },
    containerRect
  );
}

function isBottomLeftCornerInsideContainerRect(
  cornerRect: RectangleDto,
  containerRect: DOMRect
): boolean {
  return isPointInsideElement(
    {
      left: cornerRect.left,
      top: cornerRect.top + cornerRect.height
    },
    containerRect
  );
}

function isBottomRightCornerInsideContainerRect(
  cornerRect: RectangleDto,
  containerRect: DOMRect
): boolean {
  return isPointInsideElement(
    {
      left: cornerRect.left + cornerRect.width,
      top: cornerRect.top + cornerRect.height
    },
    containerRect
  );
}

function isPointInsideElement(point: PositionDto, elementRect: DOMRect): boolean {
  return (
    _inRange(point.left, elementRect.left, elementRect.left + elementRect.width) &&
    _inRange(point.top, elementRect.top, elementRect.top + elementRect.height)
  );
}

function toPx(number: number): string {
  return number + "px";
}
