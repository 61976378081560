import { cloneDeep as _cloneDeep } from "lodash";
import { forkJoin, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { DataService } from "../../data-connectivity";
import { Dictionary, isDefined } from "../../ts-utils";
import { ComponentStateDto, ReportEntities } from "../models";

export function getImageDataByComponent(
  componentStates: ComponentStateDto[],
  dataService: DataService
): Observable<Dictionary<string>> {
  const componentsWithImage = getComponentsWithBackgroundImage(componentStates);
  if (componentsWithImage.length < 1) {
    return of({});
  }

  const imagesByComponents: Observable<Dictionary<string>> = forkJoin(
    componentsWithImage.map((component) => dataService.getImage(component.view.backgroundImage))
  ).pipe(map((images) => groupImageDataByComponent(componentsWithImage, images)));
  return imagesByComponents;
}

function groupImageDataByComponent(
  componentStates: ComponentStateDto[],
  imagesData: string[]
): Dictionary<string> {
  return componentStates.reduce((acc: Dictionary<string>, componentState, index) => {
    acc[componentState.id] = imagesData[index];
    return acc;
  }, {});
}

export function addImageDataToComponents(
  reportEntities: ReportEntities,
  imagesByComponents: Dictionary<string>
): ReportEntities {
  const clonedReportEntites = _cloneDeep(reportEntities);
  Object.keys(imagesByComponents).forEach((componentId) => {
    const componentIndex: number = clonedReportEntites.componentStates.findIndex(
      (component) => component.id === componentId
    );
    const component = clonedReportEntites.componentStates[componentIndex];
    clonedReportEntites.componentStates[componentIndex] = {
      ...component,
      view: {
        ...component.view,
        backgroundImageData: imagesByComponents[componentId]
      }
    };
  });
  return clonedReportEntites;
}

function getComponentsWithBackgroundImage(
  componentStates: ComponentStateDto[]
): ComponentStateDto[] {
  return componentStates.filter(
    (component) =>
      isDefined(component.view.backgroundImage) && component.view.backgroundImage !== ""
  );
}
