import {
  BaseViewConfigDto,
  ComponentCssSize,
  Configurable,
  construct,
  DeepPartial,
  EditableType,
  EditorType,
  Serializable
} from "ui-core";

const TYPE_NAME = "EventFrequencyChartViewConfig";

@EditableType({ fullName: TYPE_NAME })
export class EventFrequencyChartViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  @Configurable({ displayName: "Bar Color", editorType: EditorType.ColorPicker })
  @Serializable("#004C97")
  barColor!: string;

  @Configurable({ displayName: "Tooltip title", editorType: EditorType.TextBox })
  @Serializable("Occurrence")
  tooltipTitle!: string;

  categories: string[] = [];
  eventFrequencies: number[] = [];

  constructor(viewConfigDto: DeepPartial<EventFrequencyChartViewConfig> = {}) {
    super();
    construct(this, viewConfigDto, TYPE_NAME, { size: new ComponentCssSize() });
  }
}
