import { BaseViewConfigDto, ComponentCssSize, construct, DeepPartial, EditableType } from "ui-core";

const TYPE_NAME = "PoleStatusViewConfig";

@EditableType({ fullName: TYPE_NAME })
export class PoleStatusViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  constructor(viewConfigDto: DeepPartial<PoleStatusViewConfig> = {}) {
    super();
    construct(this, viewConfigDto, TYPE_NAME, { size: getDefaultSize() });
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("1000", "100");
}
