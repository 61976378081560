import { createFeatureSelector, createSelector } from "@ngrx/store";
import { IName } from "../../core";
import { AppTitleState } from "../models/app-title-state";
import { EnvironmentState } from "./state";

//FIXME: split up selectors

export const ENVIRONMENT_FEATURE = "environment";

export const getEnvironmentFeature = createFeatureSelector<EnvironmentState>(ENVIRONMENT_FEATURE);

export const selectTimeInfo = createSelector(getEnvironmentFeature, (state) => state.timeInfo);

export const selectServerOffset = createSelector(selectTimeInfo, (state) => state.serverOffset);

export const selectClientNow = createSelector(selectTimeInfo, (state) => state.clientNow);

export const selectAppSettings = createSelector(
  getEnvironmentFeature,
  (state) => state.appSettings
);
export const selectSidebarVisibilityMode = createSelector(
  getEnvironmentFeature,
  (state) => state.appStatus.sidebarOpened
);

export const selectFilterToolbarVisibilityMode = createSelector(
  getEnvironmentFeature,
  (state) => state.appStatus.filterToolbarOpened
);

export const selectDisplayMode = createSelector(
  getEnvironmentFeature,
  (state) => state.appStatus.displayMode
);

export const selectAdvancedMode = createSelector(
  getEnvironmentFeature,
  (state) => state.appStatus.advancedMode
);

export const selectPropertySheetVisibilityMode = createSelector(
  getEnvironmentFeature,
  (state) => state.appStatus.propertySheetOpened
);

export const selectConnectionStatus = createSelector(
  getEnvironmentFeature,
  (state) => state.appStatus.connectionStatus
);

export const selectHistoryViewVisibilityMode = createSelector(
  getEnvironmentFeature,
  (state) => state.appStatus.historyViewOpened
);

export const selectCurrentLanguage = createSelector(
  getEnvironmentFeature,
  (state) => state.appSettings.currentLanguage
);

export const selectDateFormat = createSelector(
  getEnvironmentFeature,
  (state) => state.appSettings.dateFormat
);

export const selectUserName = createSelector(
  getEnvironmentFeature,
  (state) => state.appSettings.userName
);

export const selectTheme = createSelector(
  getEnvironmentFeature,
  (state) => state.appSettings.theme
);

export const selectViewMode = createSelector(
  getEnvironmentFeature,
  (state) => state.appStatus.viewMode
);

export const selectReportSavingState = createSelector(
  getEnvironmentFeature,
  (state) => state.appStatus.isReportSaving
);

export const selectReportLoadingState = createSelector(
  getEnvironmentFeature,
  (state) => state.appStatus.isReportLoading
);

export const selectEnvironmentName = createSelector(
  getEnvironmentFeature,
  (state) => state.projectEnvironment.name
);

export const selectStandalone = createSelector(
  getEnvironmentFeature,
  (state) => state.projectEnvironment.standalone
);
export const selectPushDataModeState = createSelector(
  getEnvironmentFeature,
  (state) => state.projectEnvironment.isPushDataMode
);

export const selectAppliedAppTitle = createSelector(getEnvironmentFeature, (state) =>
  getAppTitle(state.projectEnvironment.appTitle)
);

export const selectPropertySheetPinMode = createSelector(
  getEnvironmentFeature,
  (state) => state.appStatus.propertySheetPinned
);

export const selectFilterToolbarPinMode = createSelector(
  getEnvironmentFeature,
  (state) => state.appStatus.filterToolbarPinned
);

export const selectSidebarPinMode = createSelector(
  getEnvironmentFeature,
  (state) => state.appStatus.sidebarPinned
);

export const selectSidebarExpandedMode = createSelector(
  getEnvironmentFeature,
  (state) => state.appStatus.sidebarExpanded
);

export const selectAliasMode = createSelector(
  getEnvironmentFeature,
  (state) => state.appSettings.aliasMode
);

export const selectTemplateBuilderMode = createSelector(
  getEnvironmentFeature,
  (state) => state.appStatus.templateBuilderMode
);

function getAppTitle(state: AppTitleState): IName {
  const { productName, projectName } = state;
  const res: IName = { fullName: "", shortName: "" };

  if (productName.fullName !== "") {
    res.fullName = productName.fullName;
  } else if (projectName.fullName !== "") {
    res.fullName = projectName.fullName;
  }

  if (productName.shortName !== "") {
    res.shortName = productName.shortName;
  } else if (projectName.shortName !== "") {
    res.shortName = projectName.shortName;
  }
  return res;
}
