import "reflect-metadata";
import { PropertyDescriptor } from "../models/property-descriptor";
import { TypeProvider } from "../services/type-provider";

const TYPE_PROVIDER = TypeProvider.getInstance();

export interface PlaceholderConfig {
  text: string;
  combineWithRuntimeValue: boolean;
}

export function Placeholder(placeholderConfig: PlaceholderConfig) {
  return function (proto: Object, key: string) {
    const typeDescriptor = TYPE_PROVIDER.addType({
      constructorFunction: proto.constructor as new () => any
    });
    const typeConstructorFunction = Reflect.getMetadata("design:type", proto, key);
    if (typeDescriptor.getPropertyByName(key)) {
      typeDescriptor.updateProperty(key, {
        placeholderConfig
      });
    } else {
      typeDescriptor.addProperty(
        PropertyDescriptor.create(key, typeConstructorFunction, {
          placeholderConfig
        })
      );
    }
  };
}
