import { isEqual as _isEqual } from "lodash";
import { DataTransferObject } from "../../core/models/data-transfer-object";
import { PropertyDescriptor, TypeDescriptor } from "../../meta/models";
import { TypeProvider } from "../../meta/services/type-provider";
import { isDefined } from "../../ts-utils/helpers/predicates.helper";

// IP should this be part of the meta?
export function removeDefaultValues<T>(
  value: T,
  typeDescriptor: TypeDescriptor,
  typeProvider: TypeProvider
): Partial<T> {
  const properties: PropertyDescriptor[] = typeProvider.getAllProperties(typeDescriptor);
  const trimmedValue: Partial<T> = properties.reduce(
    (acc: Partial<T>, prop: PropertyDescriptor) => {
      const currentPropValue = value[prop.name];
      const minimalValue = trimPropValue(currentPropValue, prop, typeProvider);
      if (minimalValue !== undefined) {
        acc[prop.name] = minimalValue;
      }
      return acc;
    },
    {} as Partial<T>
  );
  return trimmedValue;
}

function trimPropValue(
  propValue: any,
  propertyDesc: PropertyDescriptor | TypeDescriptor,
  typeProvider: TypeProvider
): any {
  if (shouldDiscardProperty(propertyDesc, propValue)) {
    return undefined;
  }
  // null is a valid value and should be persisted if it's not a default value
  if (propValue === null) {
    return null;
  }
  let propertyType: TypeDescriptor = getPropertyType(propValue, propertyDesc, typeProvider);

  if (!propertyType) {
    return propValue;
  }
  const isTerminalProp = propertyType.isPrimitive;
  if (isTerminalProp) {
    return propValue;
  } else {
    const propertyIsArray = Array.isArray(propValue);
    const minimalProps = propertyIsArray
      ? trimArrayElements(propValue, typeProvider, propertyType)
      : trimObjectProperties(propValue, typeProvider, propertyType);
    return shouldPropValueBeDiscarded(minimalProps) ? undefined : minimalProps;
  }
}

function trimObjectProperties(
  propValue: any,
  typeProvider: TypeProvider,
  propertyType: TypeDescriptor
): any {
  const childProps: PropertyDescriptor[] = typeProvider.getAllProperties(propertyType);
  const minimalProps = childProps.reduce((acc, childPropDesc: PropertyDescriptor) => {
    const minimalPropValue = trimPropValue(
      propValue[childPropDesc.name],
      childPropDesc,
      typeProvider
    );
    const shouldPersistProp: boolean = !shouldPropValueBeDiscarded(minimalPropValue);
    if (shouldPersistProp) {
      acc[childPropDesc.name] = minimalPropValue;
    }
    return acc;
  }, {});
  return minimalProps;
}

function trimArrayElements(
  propValue: any,
  typeProvider: TypeProvider,
  propertyType: TypeDescriptor
): any {
  const minimalProps = propValue.reduce((acc, arrayElement, currentIndex) => {
    const minimalPropValue = trimPropValue(arrayElement, propertyType, typeProvider);
    const shouldPersistProp: boolean = !shouldPropValueBeDiscarded(minimalPropValue);
    if (shouldPersistProp) {
      acc[currentIndex] = minimalPropValue;
    }
    return acc;
  }, []);
  return minimalProps;
}

export function shouldDiscardProperty(
  propertyDesc: PropertyDescriptor | TypeDescriptor,
  propValue: any
): boolean {
  return (
    propertyDesc instanceof PropertyDescriptor &&
    (shouldPropValueBeDiscarded(propValue, propertyDesc.defaultValue) ||
      !propertyDesc.isSerializable)
  );
}

function shouldPropValueBeDiscarded(propValue: any, defaultPropValue?: any): boolean {
  const emptyObj = {};
  if (isNonEmptyObject(propValue)) {
    propValue = JSON.parse(JSON.stringify(propValue));
  }
  if (isNonEmptyObject(defaultPropValue)) {
    defaultPropValue = JSON.parse(JSON.stringify(defaultPropValue));
  }
  const shouldPropBeDiscarded: boolean =
    _isEqual(propValue, defaultPropValue) ||
    _isEqual(propValue, emptyObj) ||
    propValue === undefined;
  return shouldPropBeDiscarded;
}

function isNonEmptyObject(value: any): boolean {
  return (
    typeof value === "object" &&
    isDefined(value) &&
    !Array.isArray(value) &&
    Object.keys(value).length > 0
  );
}

function getPropertyType(
  propValue: any,
  propertyDesc: PropertyDescriptor | TypeDescriptor,
  typeProvider: TypeProvider
): TypeDescriptor {
  let propertyType: TypeDescriptor;
  if (propertyDesc instanceof PropertyDescriptor) {
    propertyType = propertyDesc.isVirtual
      ? typeProvider.getType((propValue as DataTransferObject).typeName)
      : typeProvider.getTypeByConstructor(propertyDesc.constructorFunction);
  } else {
    propertyType = propertyDesc;
  }
  return propertyType;
}
