import { Component, ElementRef, OnInit, Type } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { EntityId } from "../../../meta/models/entity";
import { ConnectorRoles } from "../../decorators/connector-roles.decorator";
import { View } from "../../decorators/view.decorator";
import { ReportConfiguration } from "../../models/report-configuration";
import { ActiveContainersService } from "../../services/active-containers.service";
import { ComponentPositioningService } from "../../services/component-positioning.service";
import { DomMapper } from "../../services/dom-mapper.service";
import { ReportsService } from "../../services/reports.service";
import { BaseComponent } from "../base/base.component";
import { ComponentConstructorParams } from "../base/component-constructor-params";
import { ContainerComponent } from "../container/container.component";
import { SingleValueComponent } from "../single-value/single-value.component";
import { ReportHeaderViewConfig } from "./view-config";

@Component({
  selector: "c-report-header",
  templateUrl: "./report-header.component.html",
  styleUrls: ["./report-header.component.scss"],
  providers: [{ provide: ContainerComponent, useExisting: ReportHeaderComponent }]
})
// @LayoutBuilder(
//   ComponentCategory.Container,
//   "ReportHeaderComponent",
//   "icon-Basic-Card",
//   "dashboard-icon"
// )
@ConnectorRoles()
@EditableWidget({ fullName: "ReportHeaderComponent", title: "report-header" })
export class ReportHeaderComponent extends ContainerComponent implements OnInit {
  public ancestors: ReportConfiguration[] = [];

  constructor(
    params: ComponentConstructorParams,
    hostElementRef: ElementRef,
    activeContainersService: ActiveContainersService = new ActiveContainersService(),
    componentPositioningService: ComponentPositioningService,
    private reportsService: ReportsService
  ) {
    super(params, hostElementRef, activeContainersService, componentPositioningService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reportsService.current$
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((report) => (this.ancestors = report.ancestors));
  }

  protected initializeDynamicChildren(addedIds: EntityId[]): void {
    if (this.dynamicChildren) {
      this.hostElement.id = DomMapper.getHostId(this.id); // Give DOM element unique id for saving drag positions
      const addedChildren = this.children.filter((child) => addedIds.indexOf(child.id) >= 0);
      this.dynamicChildren.loadStoreComponents(addedChildren);
    }
  }

  @View(ReportHeaderViewConfig)
  public get view() {
    return this.currentState.view as ReportHeaderViewConfig;
  }

  canContain(componentForAttaching: Type<BaseComponent>): boolean {
    const isInstanceOfSingleValue: boolean = this.typeProvider.areEqualTypes(
      componentForAttaching,
      SingleValueComponent
    );
    return isInstanceOfSingleValue;
  }

  onAlarmsClick() {}
}
