import { Maybe } from "../../ts-utils/models/maybe.type";
import { PropertyInfo } from "../models/property-info";

export function isTitle(x: Maybe<PropertyInfo<unknown>>): x is PropertyInfo<string> {
  return x != null && x.descriptor.isTitle;
}

export function isKey(x: Maybe<PropertyInfo<unknown>>): x is PropertyInfo<string> {
  return x != null && x.descriptor.isKey;
}
