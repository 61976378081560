import { Component, Inject, OnDestroy } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { ReportId } from "../../../core/models/report-id";
import { ReportInfoSelector } from "../../../elements/services/entity-selectors/report-info.selector";
import { ReportInfoState } from "../../../elements/store/report-info";
import { FeatureAvailabilityService } from "../../../environment/services/feature-availability.service";
import { LocalizationService } from "../../../i18n/localization.service";
import { CreateReportDialogInfo } from "../../models/create-report-dialog-info";
import { ReportCreationParams } from "../../models/report-creation-params";
import { ReportCreationType } from "../../models/report-creation-type";
import {
  ReportStateMatcher,
  reportExistsValidator,
  reportNameValidator
} from "../../services/report-creation.helper";
import { BaseDialogComponent } from "../base-dialog/base-dialog.component";

@Component({
  selector: "c-create-report",
  templateUrl: "./create-report.component.html",
  styleUrls: ["./create-report.component.scss"]
})
export class CreateReportComponent extends BaseDialogComponent implements OnDestroy {
  public reportNameControl: FormControl<string> = new FormControl<string>("");
  public dialogTitle: string = "";
  private existingReportNames: string[] = [];
  public aliasName: string = "";
  public description: string = "";
  public matcher = new ReportStateMatcher();
  private unsubscribeSubject$: Subject<never> = new Subject<never>();

  constructor(
    public dialogRef: MatDialogRef<CreateReportComponent>,
    @Inject(MAT_DIALOG_DATA) public reportCreationInfo: CreateReportDialogInfo,
    public localizer: LocalizationService,
    private featureAvailability: FeatureAvailabilityService,
    private reportInfoSelector: ReportInfoSelector
  ) {
    super();
    let originalReportName = "";
    if (this.reportCreationInfo.creationType === ReportCreationType.Duplicate) {
      this.dialogTitle = this.localizer.buttons.SaveAs;
      originalReportName = this.setExistingReportInfo();
    } else {
      this.dialogTitle = this.localizer.sidebar.CreateNewReport;
    }
    this.reportNameControl.updateValueAndValidity();
    this.existingReportNames = this.reportCreationInfo.existingReportNames ?? [];
    this.reportNameControl.setValidators([
      Validators.required,
      reportNameValidator,
      reportExistsValidator(this.existingReportNames, originalReportName)
    ]);
  }

  get supportsAliasName(): boolean {
    return this.featureAvailability.description;
  }

  get supportsDescription(): boolean {
    return this.featureAvailability.description;
  }

  public setExistingReportInfo(): string {
    const existingReportInfo: ReportInfoState = this.reportInfoSelector.getReportInfo();
    const originalReportName = existingReportInfo.name ?? "";
    this.aliasName = existingReportInfo.aliasName ?? "";
    this.description = existingReportInfo.description ?? "";
    this.reportNameControl = new FormControl<string>(originalReportName);
    return originalReportName;
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  onAliasNameChange(aliasName: string): void {
    this.aliasName = aliasName;
  }

  onDescriptionChange(description: string): void {
    this.description = description;
  }

  save(reportName: string): void {
    const reportCreationParams: ReportCreationParams = {
      id: reportName as ReportId,
      name: reportName,
      aliasName: this.aliasName,
      description: this.description,
      creationType: this.reportCreationInfo.creationType
    };
    this.dialogRef.close(reportCreationParams);
  }

  getReportError(): string {
    return this.reportNameControl.hasError("required")
      ? "Name is required."
      : this.reportNameControl.hasError("reportNameError")
      ? "Only name from 2 to 30 characters."
      : this.reportNameControl.hasError("reportExistsError")
      ? "Provided name already exists."
      : "";
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
