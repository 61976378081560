import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap, tap } from "rxjs/operators";
import { IconBrowserComponent } from "../../../browsing/components/icon-browser/icon-browser.component";
import {
  OPEN_ICON_PICKER_DIALOG,
  OnIconPickerDialogClosed
} from "../actions/icon-picker-dialog.actions";
import { IconPickerDialogStrategy } from "../dialog-config.strategies";
import { OpenDialogEffect } from "./dialog.effects";

@Injectable()
export class IconPickerDialogEffects extends OpenDialogEffect {
  constructor(private actions$: Actions, dialog: MatDialog) {
    super(dialog);
  }

  openIconPickerStrategy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OPEN_ICON_PICKER_DIALOG),
      tap(() => {
        this.dialogConfigStrategy = new IconPickerDialogStrategy();
        this.dialogConfig = this.dialogConfigStrategy.createDialogConfig();
        this.dialogRef = this.dialog.open(IconBrowserComponent, this.dialogConfig);
      }),
      switchMap(() => {
        return this.dialogRef.afterClosed().pipe(
          map((result) => {
            return new OnIconPickerDialogClosed(result);
          })
        );
      })
    )
  );
}
