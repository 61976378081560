import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Type,
  ViewChild
} from "@angular/core";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { IEntityUpdater } from "../../../core/services/i-entity-updater";
import { DataSourceDeserializer } from "../../../data-connectivity/services/deserializers/data-source.deserializer";
import { StatusDisplayTypeDeserializer } from "../../../data-connectivity/services/deserializers/status-display-type.deserializer";
import { DynamicTemplateDirective } from "../../../dynamics/dynamic-template.directive";
import { LocalizationService } from "../../../i18n/localization.service";
import { EditorType, ObjectDescriptor, PropertyInfo, TypeDescriptor } from "../../../meta/models";
import { TypeProvider } from "../../../meta/services/type-provider";
import { UndoRedoService } from "../../../shared/services/undo-redo.service";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { IEditorProvider } from "../../services/editor-provider.service";
import { BaseEditorComponent } from "../base-editor.component";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "editor-wrapper",
  templateUrl: "editor-wrapper.component.html",
  styleUrls: ["editor-wrapper.component.scss"]
})
export class EditorWrapperComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DynamicTemplateDirective) dynamicChildren: DynamicTemplateDirective;
  @Input() targetInfo: ObjectDescriptor<unknown>;
  @Input() propertyConfig: PropertyInfo<unknown>;
  @Input() parentInfo: any;
  public editorComponent: BaseEditorComponent;
  public validationResult: boolean = true;
  public EditorType = EditorType;
  protected unsubscribeSubject$: Subject<any> = new Subject();

  constructor(
    protected editorProvider: IEditorProvider,
    protected typeProvider: TypeProvider,
    protected changeDetectorRef: ChangeDetectorRef,
    protected entityUpdater: IEntityUpdater,
    protected translationService: LocalizationService,
    protected dataSourceDeserializer: DataSourceDeserializer,
    protected statusDisplayTypeDeserializer: StatusDisplayTypeDeserializer,
    protected undoRedoService: UndoRedoService,
    protected hostElementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.subscribeToUndoRedoChanges();
  }

  private subscribeToUndoRedoChanges(): void {
    this.undoRedoService.editorPathChanged$
      .pipe(
        filter((propertyPath) => this.shouldFocusEditor(propertyPath)),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe(() => {
        this.hostElementRef.nativeElement.scrollIntoView({
          block: "nearest",
          behavior: "smooth"
        });
        this.editorComponent.focus();
      });
  }

  private shouldFocusEditor(propertyPath: string[]): boolean {
    return propertyPath.every((part, i) => part === this.propertyConfig.originalPath[i]);
  }

  ngOnChanges(): void {
    if (isDefined(this.editorComponent)) {
      this.editorComponent.itemOwner = this.targetInfo.value;
      this.editorComponent.parentInfo = this.parentInfo;
      this.editorComponent.refreshValue(this.propertyConfig.value);
      this.editorComponent.propertyInfo = this.propertyConfig;
    }
  }

  ngAfterViewInit(): void {
    this.insertEditor(this.propertyConfig, this.targetInfo.value, this.parentInfo);
    // NOTE: An error occurs with the color picker editor unless changes are detected
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  get target(): any {
    return this.targetInfo.value;
  }

  get targetType(): TypeDescriptor {
    return this.targetInfo.type;
  }

  insertEditor(itemConfig: PropertyInfo<unknown>, itemOwner: unknown, parentInfo: any): void {
    if (!itemConfig) {
      return;
    }
    const editorType = itemConfig.descriptor.editorType;
    const editor: Type<BaseEditorComponent> = this.editorProvider.getEditor(editorType);
    this.editorComponent = this.dynamicChildren.loadComponent<BaseEditorComponent>(editor);
    this.editorComponent.propertyInfo = itemConfig;
    this.editorComponent.itemOwner = itemOwner;
    this.editorComponent.parentInfo = parentInfo;

    this.editorComponent.validateInput
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((validationResult) => {
        this.validationResult = validationResult;
      });

    this.editorComponent.propertyValueChanged
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((newValue) => this.updateTarget(newValue));
  }

  updateTarget(newValue: any) {}

  shouldPlaceLabelAtTop(): boolean {
    return (
      this.propertyConfig.descriptor.inlineAlignment &&
      this.propertyConfig.descriptor.editorType === EditorType.ImageSelection
    );
  }
}
