import { Component, Input } from "@angular/core";
import { FilterToolbarElementConfig } from "../../models/button/filter-toolbar-element.config";

@Component({
  selector: "filter-toolbar-element",
  templateUrl: "filter-toolbar-element.component.html",
  styleUrls: ["./filter-toolbar-element.component.scss"]
})
export class FilterToolbarElementComponent {
  @Input() buttonConfig: FilterToolbarElementConfig = new FilterToolbarElementConfig();
  @Input() isDropdown: boolean = false;
  @Input() isOpened: boolean = false;
  @Input() selectedValueLabel: string = "";
}
