<div class="core__wrapper">
  <c-history-view *ngIf="isHistoryViewOpened$ | async" class="history-view"></c-history-view>
  <c-app-header [hasAccessToEdit]="hasAccessToEdit" *ngIf="!hideHeader"></c-app-header>
  <c-app-body [hideFooter]="hideHeader" (toggleReportBrowser)="toggleReportBrowserSidebar()">
    <ng-content></ng-content>
  </c-app-body>
  <c-app-footer
    *ngIf="displayMode === DisplayMode.Desktop && !(isHistoryViewOpened$ | async) && !hideHeader"
  ></c-app-footer>
</div>
<div id="dragging_indicator_wrapper" class="element--hidden"></div>
