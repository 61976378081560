import { Component, HostBinding, ViewChild } from "@angular/core";
import { OfType } from "../../../meta/decorators/of-type.decorator";
import { EditorType } from "../../../meta/models/editor-type";
import { NgxQuillEditorWrapperComponent } from "../../../shared/components/ngx-quill-editor-wrapper/ngx-quill-editor-wrapper.component";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { BaseEditorComponent } from "../base-editor.component";
@Component({
  selector: "text-area-editor",
  templateUrl: "text-area-editor.component.html",
  styleUrls: ["text-area-editor.component.scss"]
})
@OfType(EditorType.TextArea)
export class TextAreaEditorComponent extends BaseEditorComponent {
  @ViewChild("ngxQuillEditor") editorComponent?: NgxQuillEditorWrapperComponent;

  @HostBinding("attr.title")
  public get tooltipText(): string {
    return this.tooltip;
  }

  focus(): void {
    if (isDefined(this.editorComponent)) {
      this.editorComponent.setFocus();
      this.editorComponent.preserveCursorPosition();
    }
  }
}
