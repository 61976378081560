<div
  *ngIf="isOpened"
  [@enterAnimation]
  class="date-time-range-picker__wrapper"
  (clickOutside)="closeDateTimePicker()"
>
  <div class="date-time-range-picker__items">
    <div class="date-time-range-picker__item-wrapper">
      <date-time-picker
        class="date-time-picker__wrapper"
        [date]="dateTimeRange.from"
        [dateTimeFormat]="dateTimeFormat"
        (onChangeDisplay)="closeDateTimePicker()"
        (onDateTimeChange)="updateStartDateTime($event)"
      ></date-time-picker>
    </div>
    <div class="date-time-range-picker__item-wrapper">
      <date-time-picker
        class="date-time-picker__wrapper"
        [date]="dateTimeRange.to"
        [dateTimeFormat]="dateTimeFormat"
        (onChangeDisplay)="closeDateTimePicker()"
        (onDateTimeChange)="updateEndDateTime($event)"
      ></date-time-picker>
    </div>
  </div>
</div>
