import { Pipe, PipeTransform } from "@angular/core";
import {
  DEFAULT_STATUS_VALUE,
  NULL_VALUE
} from "projects/ui-core/src/lib/elements/models/status-indicator/status-indicator-states.constants";
import { Maybe, StatusValue } from "ui-core";
import { MotorStatus } from "../models/enums/motor-status.enum";

@Pipe({ name: "statusTransformer" })
export class StatusTransformer implements PipeTransform {
  statusMap: Map<Maybe<string>, Maybe<StatusValue>> = new Map<Maybe<string>, Maybe<StatusValue>>([
    [MotorStatus.Running, DEFAULT_STATUS_VALUE],
    [MotorStatus.RunningAlarms, 1],
    [MotorStatus.StoppedNormal, 4],
    [MotorStatus.Trip, 2],
    [MotorStatus.NoDataConnection, NULL_VALUE]
  ]);

  transform(status: string): Maybe<StatusValue> {
    const statusColor = status
      ? this.statusMap.get(status)
      : this.statusMap.get("No Data Connection");
    return statusColor;
  }
}
