import { Component, ElementRef, OnInit, QueryList, ViewChildren } from "@angular/core";
import { cloneDeep as _cloneDeep } from "lodash";
import { BorderSide } from "../../../elements/models/border-style";
import { EditorType, OfType } from "../../../meta";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { BaseEditorComponent } from "../base-editor.component";

const CSS_BORDER_SIDE_FOCUS = "border-side--focus";

@Component({
  selector: "border-side-selector-editor",
  templateUrl: "border-side-selector-editor.component.html",
  styleUrls: ["border-side-selector-editor.component.scss"]
})
@OfType(EditorType.BorderSideSelector)
export class BorderSideSelectorEditorComponent extends BaseEditorComponent implements OnInit {
  selectedSides: string[] = [];
  sides: string[] = [];
  @ViewChildren("borderSideElement") borderSideElements!: QueryList<ElementRef>;

  ngOnInit(): void {
    super.ngOnInit();
    this.selectedSides = _cloneDeep(this.value);
    this.sides = Object.keys(BorderSide);
  }

  selectSide(borderSide: BorderSide): void {
    const sideIndex: number = this.selectedSides.findIndex((side) => side === borderSide);
    sideIndex > -1 ? this.selectedSides.splice(sideIndex, 1) : this.selectedSides.push(borderSide);
    this.onValueChanged([...this.selectedSides]);
  }

  shouldFocusSide(borderSide: BorderSide): boolean {
    return this.selectedSides.includes(borderSide);
  }

  focus(): void {
    this.selectedSides = _cloneDeep(this.value);

    this.sides.forEach((borderSide: string) => {
      const borderSideElement: Maybe<ElementRef> = this.borderSideElements.find(
        (borderEl: ElementRef) =>
          borderEl.nativeElement.classList.contains(borderSide.toLowerCase())
      );
      this.selectedSides.includes(borderSide)
        ? this.trySelectBorderSideElement(borderSideElement)
        : this.tryUnselectBorderSideElement(borderSideElement);
    });
  }

  private trySelectBorderSideElement(borderSideElement: ElementRef): void {
    if (!borderSideElement.nativeElement.classList.contains(CSS_BORDER_SIDE_FOCUS)) {
      borderSideElement.nativeElement.classList.add(CSS_BORDER_SIDE_FOCUS);
    }
  }

  private tryUnselectBorderSideElement(borderSideElement: ElementRef): void {
    if (borderSideElement.nativeElement.classList.contains(CSS_BORDER_SIDE_FOCUS)) {
      borderSideElement.nativeElement.classList.remove(CSS_BORDER_SIDE_FOCUS);
    }
  }
}
