import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { distinctUntilChanged, first } from "rxjs/operators";
import { DataConnectorDto } from "../../../data-connectivity";
import { DataConnectorViewDto } from "../../../data-connectivity/models/data-connector-view";
import { IDataConnectorViewSelector } from "../../../data-connectivity/services/i-data-connector-view.selector";
import { EntityId } from "../../../meta/models/entity";
import { areArraysEqualByElementsReference } from "../../../ts-utils/helpers/array.helper";
import { Dictionary } from "../../../ts-utils/models/dictionary.type";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import {
  selectComponentConnectorViewsById,
  selectConnectorViewDictByConnectors,
  selectDataConnectorViewById,
  selectDataConnectorViewEntities,
  selectDataConnectorViewIds,
  selectReorderedViewsByConnectors
} from "../../store/data-connector-view/data-connector-view.selectors";

@Injectable({ providedIn: "root" })
export class DataConnectorViewSelector extends IDataConnectorViewSelector {
  constructor(private store$: Store<any>) {
    super();
  }

  selectAll(): Observable<Dictionary<DataConnectorViewDto>> {
    return this.store$.select(selectDataConnectorViewEntities);
  }

  selectById(connectorViewId: EntityId): Observable<Maybe<DataConnectorViewDto>> {
    return this.store$.select(selectDataConnectorViewById(connectorViewId));
  }

  selectForComponent(componentId: EntityId): Observable<DataConnectorViewDto[]> {
    return this.store$
      .select(selectComponentConnectorViewsById(componentId))
      .pipe(distinctUntilChanged(areArraysEqualByElementsReference));
  }

  selectConnectorViewDictByConnectors(
    connectors: DataConnectorDto[]
  ): Observable<Dictionary<DataConnectorViewDto>> {
    return this.store$.select(selectConnectorViewDictByConnectors(connectors));
  }

  selectReorderedViewsByConnectors(
    connectors: DataConnectorDto[]
  ): Observable<Maybe<DataConnectorViewDto[]>> {
    return this.store$.select(selectReorderedViewsByConnectors(connectors));
  }

  getAll(): Dictionary<DataConnectorViewDto> {
    let connectorViews: Dictionary<DataConnectorViewDto>;
    this.selectAll()
      .pipe(first())
      .subscribe((connectorViewEntities) => {
        connectorViews = connectorViewEntities;
      });
    return connectorViews;
  }

  getAllAsArray(): DataConnectorViewDto[] {
    const allConnectorViewDict = this.getAll();
    return Object.keys(allConnectorViewDict)
      .map((key) => allConnectorViewDict[key] as DataConnectorViewDto)
      .reduce((acc: DataConnectorViewDto[], connectorView: DataConnectorViewDto) => {
        acc.push(connectorView);
        return acc;
      }, []);
  }

  getById(connectorViewId: EntityId): Maybe<DataConnectorViewDto> {
    let result: Maybe<DataConnectorViewDto> = null;
    this.store$
      .select(selectDataConnectorViewById(connectorViewId))
      .pipe(first())
      .subscribe((connectorView) => (result = connectorView));
    return result;
  }

  getManyById(connectorViewIds: EntityId[]): Dictionary<DataConnectorViewDto> {
    const connectors: Dictionary<DataConnectorViewDto> = this.getAll();
    if (!Array.isArray(connectorViewIds)) {
      return {};
    }
    return connectorViewIds.reduce((acc: Dictionary<DataConnectorViewDto>, connectorViewId) => {
      if (connectors[connectorViewId]) {
        acc[connectorViewId] = connectors[connectorViewId];
      }
      return acc;
    }, {});
  }

  getManyByIdAsArray(connectorViewIds: EntityId[]): DataConnectorViewDto[] {
    if (!Array.isArray(connectorViewIds)) {
      return [];
    }
    const connectorViews: DataConnectorViewDto[] = this.getAllAsArray();
    return connectorViews.filter((connectorView) => connectorViewIds.includes(connectorView.id));
  }

  getDataConnectorViewIds(): Maybe<EntityId[]> {
    let connectroViewIds: Maybe<EntityId[]> = null;
    this.store$
      .select(selectDataConnectorViewIds)
      .pipe(first())
      .subscribe((ids) => {
        connectroViewIds = ids;
      });
    return connectroViewIds;
  }

  getForComponent(componentId: EntityId): DataConnectorViewDto[] {
    let componentConnectorViews: DataConnectorViewDto[] = [];
    this.selectForComponent(componentId)
      .pipe(first())
      .subscribe((connectorViews) => (componentConnectorViews = connectorViews));
    return componentConnectorViews;
  }
}
