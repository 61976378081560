export const HIDDEN_ELEMENT_CLASS = "element--hidden";
export const UNSET_Z_INDEX_CLASS = "unset-z-index";

export function hideElement(element: HTMLElement): void {
  element.classList.add(HIDDEN_ELEMENT_CLASS);
}

export function unhideElement(element: HTMLElement): void {
  element.classList.remove(HIDDEN_ELEMENT_CLASS);
}

export function toggleElement(element: HTMLElement): void {
  element.classList.toggle(HIDDEN_ELEMENT_CLASS);
}

export function unsetZIndex(element: HTMLElement): void {
  element.classList.add(UNSET_Z_INDEX_CLASS);
}

export function revertZIndex(element: HTMLElement): void {
  element.classList.remove(UNSET_Z_INDEX_CLASS);
}
