import { Injectable } from "@angular/core";
import { EntityId } from "../../meta/models/entity";
import { NavigationNodeInfo } from "../../shared/models/navigation-node-info";
import { isEmpty } from "../../ts-utils";
import { SidebarNavigationNode } from "../models/sidebar-nav-composite-model";

const REPORT_MODEL = {
  "GMD Systems": {
    link: {
      reportId: "gmd-systems"
    },

    Grid: {
      link: {
        reportId: ""
      }
    },
    "MV Switchgear": {
      link: {
        reportId: ""
      }
    },
    Transformers: {
      link: {
        reportId: "transformers"
      }
    },
    "E-House": {
      link: {
        reportId: "e-house"
      }
    },
    "Ring Motor": {
      link: {
        reportId: "ring-motor"
      },
      Rotor: {
        link: {
          reportId: "ring-motor"
        }
      },
      Stator: {
        link: {
          reportId: "ring-motor-stator"
        }
      },
      Cooling: {
        link: {
          reportId: "ring-motor-cooling"
        }
      }
    },
    Plotting: {
      link: {
        reportId: "plotting"
      }
    },
    "Downtime Analysis": {
      link: {
        reportId: "downtime-analysis"
      }
    },
    "Downtime Analysis Utilization": {
      link: {
        reportId: "downtime-analysis-utilization"
      }
    },
    "Alarms and Events": {
      link: {
        reportId: "alarms-events"
      }
    },
    "Dummy Report": {
      link: {
        reportId: "dummy-report"
      }
    }
  }
};
const GRINDING_AREA_REPORT_LINK = "grinding";
@Injectable()
export class MockSidenavService {
  mockReportModel = new SidebarNavigationNode<NavigationNodeInfo>(
    "ABB User",
    new NavigationNodeInfo(null, "root")
  );
  mockEquipmentModel = new SidebarNavigationNode<NavigationNodeInfo>(
    "ABB User",
    new NavigationNodeInfo(null, "root")
  );
  reportModelLinks: string[] = [];

  constructor() {}

  getMockReportTree(): SidebarNavigationNode<NavigationNodeInfo> {
    this.getLinksOfReportModel(this.reportModelLinks, REPORT_MODEL);
    this.reportModelLinks.push(GRINDING_AREA_REPORT_LINK);
    this.createComposite(this.mockReportModel, REPORT_MODEL);
    return this.mockReportModel;
  }
  private getLinksOfReportModel(reportModelLinks: string[], reportModel: any): void {
    Object.keys(reportModel).forEach((key: string) => {
      if (key === "link") {
        const linkToReport: { reportId: EntityId } = reportModel[key];
        if (!!linkToReport.reportId) {
          reportModelLinks.push(linkToReport.reportId.toString());
        }
        return;
      }
      const childObject: any = reportModel[key];
      const childIsLeaf: boolean = isEmpty(childObject);
      if (childIsLeaf) {
        return;
      }
      this.getLinksOfReportModel(reportModelLinks, childObject);
    });
  }

  // NOTE: impure func to make the composite structure
  private createComposite(
    composite: SidebarNavigationNode<NavigationNodeInfo>,
    compositeModel: any
  ) {
    const keys: string[] = Object.keys(compositeModel);
    keys.forEach((key: string) => {
      if (key === "link") {
        const LINK_TO_REPORT = compositeModel[key];
        composite.nodeInfo = new NavigationNodeInfo(LINK_TO_REPORT, null);
        return;
      }

      const refToChildComposite = composite.addChild(key, new NavigationNodeInfo(null, key));
      const childObject: any = compositeModel[key];
      const childIsLeaf: boolean = isEmpty(childObject);
      if (childIsLeaf) {
        return;
      }
      this.createComposite(refToChildComposite, childObject);
    });
  }
}
