import { Injectable } from "@angular/core";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { environment } from "projects/rds/src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class TelemetryService {
  appInsights!: ApplicationInsights;
  constructor() {
    if (environment.telemetryInstrumentationKey) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: environment.telemetryInstrumentationKey,
          enableDebug: !environment.production,
          enableDebugExceptions: !environment.production
        }
      });

      if (!environment.production) {
        console.log(`Telemetry for exceptions enabled ${environment.telemetryInstrumentationKey}`);
      }

      this.appInsights.loadAppInsights();
    }
  }

  private isApplicationInsightsEnabled(): boolean | undefined {
    return this.appInsights?.core?.isInitialized && this.appInsights.core?.isInitialized();
  }

  logPageView(name?: string, url?: string) {
    if (this.isApplicationInsightsEnabled()) {
      this.appInsights.trackPageView({
        name: name,
        uri: url
      });
    }
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    if (this.isApplicationInsightsEnabled()) {
      this.appInsights.trackEvent({ name: name }, properties);
    }
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    if (this.isApplicationInsightsEnabled()) {
      this.appInsights.trackMetric({ name: name, average: average }, properties);
    }
  }

  logException(exception: Error, severityLevel?: number) {
    if (this.isApplicationInsightsEnabled()) {
      this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    }
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    if (this.isApplicationInsightsEnabled()) {
      this.appInsights.trackTrace({ message: message }, properties);
    }
  }
}
