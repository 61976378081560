import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { concatMap, map } from "rxjs/operators";
import { CustomFilterValue } from "../../../core/models/filter/filter-type-descriptor";
import { EnvironmentSelector } from "../../../environment/services/environment.selector";
import { getInitialValueOrDefault } from "../../../shared/models/custom-filter-value-type";
import { Dictionary, Maybe, isDefined, isNotDefined } from "../../../ts-utils";
import { resolveCustomFilterKey } from "../../services/custom-filter.helper";
import { FilterActions } from "../filter/filter.actions";
import { RuntimeSettingsActions } from "../runtime-settings";
import { GeneralSettingsActions } from "./general-settings.actions";

@Injectable()
export class GeneralSettingsEffects {
  constructor(private actions$: Actions, private environmentSelector: EnvironmentSelector) {}

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralSettingsActions.update),
      concatMap(({ changes }) => {
        const actions: Action[] = [];
        if (isDefined(changes.useServerTime)) {
          const offset =
            (changes.useServerTime ? 1 : 0) * this.environmentSelector.getServerOffset();
          actions.push(
            GeneralSettingsActions.toggleUseServerTime({
              useServerTime: changes.useServerTime,
              filtersUpdateOffset: offset
            })
          );
        }
        if (isDefined(changes.periodType)) {
          actions.push(
            RuntimeSettingsActions.setPeriodType({
              pType: changes.periodType
            })
          );
        }
        if (isDefined(changes.customFilterDeclarations)) {
          const customFilterValues = changes.customFilterDeclarations
            .filter(isDefined)
            .reduce((acc: Dictionary<CustomFilterValue>, customFilter) => {
              const customFilterKey: Maybe<string> = resolveCustomFilterKey(customFilter);
              if (isNotDefined(customFilterKey)) {
                return acc;
              }
              acc[customFilterKey] = getInitialValueOrDefault(customFilter);
              return acc;
            }, {});

          actions.push(
            FilterActions.resolveUpdatedCustomFilters({
              customFilters: customFilterValues
            })
          );
        }
        return actions;
      })
    )
  );

  useServerTime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralSettingsActions.toggleUseServerTime),
      map(({ filtersUpdateOffset }) =>
        FilterActions.updateManyWithTimeOffset({ offset: filtersUpdateOffset })
      )
    )
  );
}
