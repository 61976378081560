import { SERIES_TYPE_BAND } from "../../../data-connectivity/models/series-type.strategies";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";

export class PlotBandGenerator {
  public isBand(dataConnectorDescriptor: DataConnectorDescriptor): boolean {
    return dataConnectorDescriptor.connectorView?.timeSeriesConfig?.seriesType === SERIES_TYPE_BAND;
  }

  public addPlotBands(
    opt: Highcharts.Options,
    dataConnectorDescriptors: DataConnectorDescriptor[]
  ): void {
    const bands: Highcharts.AxisPlotBandsOptions[] = dataConnectorDescriptors
      .filter((dcd) => this.isBand(dcd))
      .reduce((acc, connectorDesc): Highcharts.XAxisPlotBandsOptions[] => {
        const tmpBands = this.createBandsForDataConnector(connectorDesc);

        return [...acc, ...tmpBands];
      }, [] as Highcharts.XAxisPlotBandsOptions[]);

    (opt.xAxis as Highcharts.XAxisOptions[])[0].plotBands = bands;
  }

  private createBandsForDataConnector(
    connectorDesc: DataConnectorDescriptor
  ): Highcharts.XAxisPlotBandsOptions[] {
    return (
      connectorDesc.connector?.dataPoints?.reduce((bands, point, index, array) => {
        if (point.y === 1 && (index === 0 || array[index - 1].y === 0)) {
          bands.push({
            from: point.x as number,
            to: point.x as number,
            color: connectorDesc.connectorView?.color
          } as Highcharts.XAxisPlotBandsOptions);
        }

        if (point.y === 1) {
          bands[bands.length - 1].to = point.x as number;
        }

        return bands;
      }, [] as Highcharts.XAxisPlotBandsOptions[]) || []
    );
  }
}
