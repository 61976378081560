import { Component } from "@angular/core";
import { Chart, SVGElement } from "highcharts";
import { RDS_LOCALIZATION_DICTIONARY } from "projects/rds/src/assets/i18n/models/rds-localization-dictionary";
import {
  BaseComponent,
  BulletType,
  ChartComponent,
  ConnectorRoles,
  Dictionary,
  EditableType,
  formatValueByStringFormat,
  HorizontalAlignment,
  isEmptyOrNotDefined,
  LayoutBuilder,
  Orientation,
  View
} from "ui-core";
import { RdsComponentCategory } from "../../rds-component-category";
import { Roles } from "./roles";
import { StaticEccentricityArrowOptions } from "./static-eccentricity-arrow-options";
import { StaticEccentricityViewConfig } from "./view-config";
import { RotorMillCenter } from "./view-model.model";

const DEGREES_PER_RAD = 180 / Math.PI;
const RADS_PER_DEGREE = 1 / DEGREES_PER_RAD;

// NOTE constants added for highcharts drawing not to be connected to math logic
// notes: in highchart, the x axis starts from left to right and y axis from top to bottom
// the 0 degree is at 3 o'clock and it increases clockwise.
// Quadrant 1 is top right and increases counter clockwise
const highchartsQuadrantAngles: number[] = [+360, +180, +180, 0];

// from below variable only first item is used. the rest might be wrong.
const highchartsQuadrantStartAngles: number[] = [0, Math.PI, Math.PI, 0];
@Component({
  selector: "rds-static-eccentricity-chart",
  templateUrl: "./static-eccentricity-chart.component.html",
  styleUrls: ["./static-eccentricity-chart.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: StaticEccentricityChartComponent }]
})
@LayoutBuilder(RdsComponentCategory.RDS, "StaticEccentricityChartComponent",
  "Plugin", "abb-icon", undefined, RDS_LOCALIZATION_DICTIONARY.layoutEditor.StaticEccentricityChart)    
@ConnectorRoles(Roles)
@EditableType({ fullName: "StaticEccentricityChartComponent", title: "static-eccentricity-chart" })
export class StaticEccentricityChartComponent extends ChartComponent {
  private renderedShapes: (SVGElement | void)[] = [];
  HorizontalAlignment = HorizontalAlignment;
  Orientation = Orientation;
  BulletType = BulletType;
  updateChartFlag = false;
  updateOneToOneFlag = false;
  rotorMillCenter: RotorMillCenter;

  @View(StaticEccentricityViewConfig)
  public get view() {
    return this.currentState.view as StaticEccentricityViewConfig;
  }

  protected updateDisplay(): void {
    super.updateDisplay();
    if (!!this.chartObject) {
      this.clear();
      this.render(this.chartObject, this.rotorMillCenter);
    }
  }

  private clear() {
    this.renderedShapes.reduce((acc, shape) => {
      if (!this.isEmptyShape(shape)) {
        (shape as SVGElement).destroy();
      }
      return acc;
    }, []);
  }

  private isEmptyShape(shape: SVGElement | void): boolean {
    if (!shape) {
      return false;
    }

    return Object.keys(shape).length === 0;
  }

  private render(chart: Chart, millRotorCenter: RotorMillCenter) {
    if (!millRotorCenter || !millRotorCenter.x || !millRotorCenter.y) {
      return;
    }

    const center = {
      x: chart.plotWidth / 2,
      y: chart.plotHeight / 2
    };

    const chartCenter: { x: number; y: number } = {
      x: center.x + chart.plotLeft,
      y: center.y + chart.plotTop
    };
    const rotorCenterDrawingValues: {
      x: number;
      y: number;
    } = this.calculateRotorCenterDrawingValues(millRotorCenter);

    // FIXME 910 handle zero cases y=0 x=0...
    const angle = Math.atan(millRotorCenter.y / millRotorCenter.x) * DEGREES_PER_RAD;

    const degreesForDrawing = this.getHighchartsQuadrantAngleSummand(millRotorCenter) - angle; //+ ;
    const degreeArcSvgElement = this.drawDegreeArc(
      chart,
      center,
      degreesForDrawing,
      this.getQuadrant(millRotorCenter),
      rotorCenterDrawingValues
    );
    if (!degreeArcSvgElement) {
      console.warn("No svg degree element!");
      return;
    }
    const drawingValuesOfArcDegree = (degreeArcSvgElement.element.attributes as any).d.value.split(
      " "
    );

    this.renderedShapes.push(degreeArcSvgElement);
    const arcDegreeDrawing = {
      startOfArc: {
        x: +drawingValuesOfArcDegree[1],
        y: +drawingValuesOfArcDegree[2]
      },
      arcValues: {
        rx: +drawingValuesOfArcDegree[4],
        ry: +drawingValuesOfArcDegree[5],
        xAxisRotation: +drawingValuesOfArcDegree[6],
        largeArcFlag: +drawingValuesOfArcDegree[7],
        sweepFlag: +drawingValuesOfArcDegree[8]
      },
      endOfArc: {
        x: +drawingValuesOfArcDegree[9],
        y: +drawingValuesOfArcDegree[10]
      }
    };

    const arrowXCoord = arcDegreeDrawing.endOfArc.x;
    const arrowYCoord = arcDegreeDrawing.endOfArc.y;
    // NOTE arctg gets back negative values but we need positive ones to calculate
    // mathematically correct angle
    const getPositiveAngleOffset = (startAngle: number) => {
      if (startAngle < 0) {
        return 90 - -startAngle;
      } else {
        return startAngle;
      }
    };
    const mathCorrectAngle: number = angle;
    const drawAngleArrow: StaticEccentricityArrowOptions = {
      chart,
      arrowXCoord,
      arrowYCoord,
      angle: degreesForDrawing,
      strokeColor: "#2ecafa",
      strokeWidth: 1
    };
    this.renderedShapes.push(this.drawArrow(drawAngleArrow));
    const xOffset = +90;
    const yOffset = -30;

    const xPositionDegreeLabel = chartCenter.x + xOffset;
    const yPositionDegreeLabel = chartCenter.y + yOffset;

    if (isNaN(xPositionDegreeLabel) || isNaN(yPositionDegreeLabel) || isNaN(mathCorrectAngle)) {
      console.warn("Values for svg renderer not provided!");
      return;
    }

    this.renderedShapes.push(
      chart.renderer
        .text(
          "Degrees: " + Math.round(degreesForDrawing) + "°",
          xPositionDegreeLabel,
          yPositionDegreeLabel
        )
        .css({ fontSize: "13px", color: "black" })
        .add()
    );

    this.drawCoordinateSystemAxes(chartCenter.x, chartCenter.y, chart);
    const statorCenterThickness = 3;
    const statorCenter = chart.renderer
      .circle(chartCenter.x, chartCenter.y, statorCenterThickness)
      .attr({ fill: "black", stroke: "black", "stroke-width": 1 })
      .add();
    this.renderedShapes.push(statorCenter);

    const yAxis: Highcharts.Axis = <Highcharts.Axis>chart.get("yAxis");
    const plotlineStart = (<any>yAxis).len / 4;
    const plotlineSpacing = (<any>yAxis).len / 8;
    this.drawPlotline(chartCenter, plotlineStart + plotlineSpacing, chart);
    this.drawPlotline(chartCenter, plotlineStart + 2 * plotlineSpacing, chart);
    this.drawPlotline(chartCenter, plotlineStart + 3 * plotlineSpacing, chart);
    this.drawPlotline(chartCenter, plotlineStart + 4 * plotlineSpacing, chart);
    this.drawPlotline(chartCenter, plotlineStart + 5 * plotlineSpacing, chart);
    this.drawRotorMill(
      chartCenter,
      chart,
      rotorCenterDrawingValues,
      millRotorCenter,
      getPositiveAngleOffset(angle)
    );
  }

  private drawDegreeArc(
    chart: Chart,
    center: { x: number; y: number },
    angle: number,
    quadrant: number,
    drawingValues: { x: number; y: number }
  ): SVGElement | void {
    const CENTER_X_OF_CHART = center.x + chart.plotLeft;
    const CENTER_Y_OF_CHART = center.y + chart.plotTop;
    const yAxis: Highcharts.Axis = <Highcharts.Axis>chart.get("yAxis");
    const plotlineStart = (<any>yAxis).len / 5;
    if (
      isNaN(CENTER_X_OF_CHART) ||
      isNaN(CENTER_Y_OF_CHART) ||
      isNaN(drawingValues.x) ||
      isNaN(angle)
    ) {
      return;
    }

    return chart.renderer
      .arc(
        CENTER_X_OF_CHART,
        CENTER_Y_OF_CHART,
        Math.min(drawingValues.x, plotlineStart),
        Math.min(drawingValues.x, plotlineStart),
        highchartsQuadrantStartAngles[quadrant - 1],
        highchartsQuadrantStartAngles[quadrant - 1] + (angle - 2) * RADS_PER_DEGREE
      )
      .attr({
        fill: "#FCFFC5",
        stroke: "#2ecafa",
        "stroke-width": 1,
        "stroke-dasharray": "4 6"
      })
      .add();
  }

  private drawArrow(options: StaticEccentricityArrowOptions): SVGElement | void {
    const { chart, arrowXCoord, arrowYCoord, angle, strokeColor, strokeWidth } = options;
    if (isNaN(arrowXCoord) || isNaN(arrowYCoord) || isNaN(strokeWidth) || isNaN(angle)) {
      return;
    }

    return (
      chart.renderer
        .path([
          ["M", arrowXCoord, arrowYCoord],
          ["L", arrowXCoord - 6, arrowYCoord - 6],
          ["M", arrowXCoord, arrowYCoord],
          ["L", arrowXCoord + 6, arrowYCoord - 6],
          ["Z"]
        ])
        .attr({
          transform: "rotate(" + angle + ")",
          "stroke-width": strokeWidth,
          stroke: strokeColor
        }) as SVGElement
    )
      .css({ "transform-origin": arrowXCoord + "px" + " " + arrowYCoord + "px" })
      .add();
  }

  private getQuadrant(millRotorCenter: { x: number; y: number }): number {
    const xValueIsPositive: boolean = millRotorCenter.x > 0;
    const yValueIsPositive: boolean = millRotorCenter.y > 0;

    const quadrantPosition: boolean[] = [
      xValueIsPositive && yValueIsPositive,
      !xValueIsPositive && yValueIsPositive,
      !xValueIsPositive && !yValueIsPositive,
      xValueIsPositive && !yValueIsPositive
    ];

    return quadrantPosition.findIndex((q) => q) + 1;
  }

  private getHighchartsQuadrantAngleSummand(millRotorCenter: { x: number; y: number }) {
    const xValueIsPositive: boolean = millRotorCenter.x > 0;
    const yValueIsPositive: boolean = millRotorCenter.y > 0;

    const isInQuadrant: Dictionary<boolean> = {
      1: xValueIsPositive && yValueIsPositive,
      2: !xValueIsPositive && yValueIsPositive,
      3: !xValueIsPositive && !yValueIsPositive,
      4: xValueIsPositive && !yValueIsPositive
    };
    return highchartsQuadrantAngles.reduce((prev: number, current: number, index: number) => {
      if (isInQuadrant[index + 1]) {
        return current;
      }
      return prev;
    });
  }

  private calculateRotorCenterDrawingValues(millRotorCenter: RotorMillCenter): RotorMillCenter {
    if (isEmptyOrNotDefined(millRotorCenter)) {
      console.warn("No rotor mill center!");
      return millRotorCenter;
    }

    const scalingConstant =
      100 / Math.max(Math.abs(millRotorCenter.x), Math.abs(millRotorCenter.y));
    return { x: millRotorCenter.x * scalingConstant, y: millRotorCenter.y * scalingConstant };
  }

  private drawPlotline(
    chartCenter: { x: number; y: number },
    radius: number,
    chart: Highcharts.Chart
  ) {
    if (isNaN(radius)) {
      console.warn("No values provided for svg renderer!");
      return;
    }

    this.renderedShapes.push(
      (
        chart.renderer.circle(chartCenter.x, chartCenter.y, radius).attr({
          "stroke-dasharray": "4 5",
          fill: "transparent",
          stroke: "#e6e6e6",
          "stroke-width": 1
        }) as SVGElement
      ).add()
    );
  }

  private drawRotorMill(
    chartCenter: { x: number; y: number },
    chart: Highcharts.Chart,
    drawingRotorCenter: { x: number; y: number },
    millRotorCenter: { x: number; y: number },
    angleCorrection: number
  ) {
    if (
      isNaN(drawingRotorCenter.x) ||
      isNaN(drawingRotorCenter.y) ||
      isNaN(angleCorrection) ||
      isNaN(millRotorCenter.y) ||
      isNaN(millRotorCenter.x)
    ) {
      console.warn("No values provided for svg renderer!");
      return;
    }

    const millLength: Highcharts.SVGElement = (
      chart.renderer
        .path([
          ["M", chartCenter.x, chartCenter.y],
          ["L", chartCenter.x + drawingRotorCenter.x, chartCenter.y - drawingRotorCenter.y],
          ["Z"]
        ])
        .attr({
          //transform: this.getAngleRotationFix(chartCenter, millRotorCenter, angleCorrection),
          "stroke-width": 1,
          stroke: "red",
          "z-index": 1,
          "stroke-dasharray": "4 6"
        }) as SVGElement
    ).add();
    this.renderedShapes.push(millLength);

    const millLengthDrawingValues: string[] = (<any>millLength).element.attributes.d.value.split(
      " "
    );
    const millLengthDrawing = {
      centerOfChart: {
        x: millLengthDrawingValues[1],
        y: millLengthDrawingValues[2]
      },
      endOfPath: {
        x: millLengthDrawingValues[4],
        y: millLengthDrawingValues[5]
      }
    };

    const xCoordinateMillLength: number = Number(millLengthDrawing.endOfPath.x);
    const yCoordinateMillLength: number = Number(millLengthDrawing.endOfPath.y);

    const rotorMillCenterThickness = 3;
    const rotorMillCenter: Highcharts.SVGElement = (
      chart.renderer
        .circle(xCoordinateMillLength, yCoordinateMillLength, rotorMillCenterThickness)
        .attr({
          //transform: this.getAngleRotationFix(chartCenter, millRotorCenter, angleCorrection),
          fill: "red",
          stroke: "red",
          "stroke-width": 1
        }) as SVGElement
    ).add();

    this.renderedShapes.push(rotorMillCenter);
    this.renderedShapes.push(
      chart.renderer
        .text(
          "Length: " +
            formatValueByStringFormat(
              this.calculateEccLength(this.rotorMillCenter),
              this.view.displayFormat
            ) +
            "mm",
          chartCenter.x + 90,
          chartCenter.y - 18
        )
        .css({ fontSize: "13px", color: "black" })
        .add()
    );
  }

  private drawCoordinateSystemAxes(centerX: number, centerY: number, chart: Highcharts.Chart) {
    const yAxis: Highcharts.Axis = <Highcharts.Axis>chart.get("yAxis");
    const xAxisLine: Highcharts.SVGElement = (
      chart.renderer
        .path([
          "M",
          centerX - (<any>yAxis).len,
          centerY,
          "L",
          centerX + (<any>yAxis).len,
          centerY,
          "Z"
        ])
        .attr({
          "stroke-width": 1,
          stroke: "black",
          "z-index": 5
        }) as SVGElement
    ).add();

    this.renderedShapes.push(xAxisLine);
    const xAxisLabelOffset = 10;
    const xLabel = chart.renderer
      .text(
        "X - Axis",
        xAxisLine.getBBox().x + xAxisLine.getBBox().width + xAxisLabelOffset,
        xAxisLine.getBBox().y + xAxisLabelOffset
      )
      .css({ fontSize: "15px", color: "black" })
      .add();
    this.renderedShapes.push(xLabel);

    const yAxisLine: Highcharts.SVGElement = (
      chart.renderer
        .path([
          "M",
          centerX,
          centerY - (<any>yAxis).len,
          "L",
          centerX,
          centerY + (<any>yAxis).len,
          "Z"
        ])
        .attr({
          "stroke-width": 1,
          stroke: "black",
          "z-index": 5
        }) as SVGElement
    ).add();
    this.renderedShapes.push(yAxisLine);
    const yAxisLabelXOffset = 30;
    const yAxisLabelYOffset = 20;
    const yLabel = chart.renderer
      .text(
        "Y - Axis",
        yAxisLine.getBBox().x + yAxisLabelYOffset, // + yAxisLine.getBBox().width - yAxisLabelXOffset,
        yAxisLine.getBBox().y
      )
      .css({ fontSize: "15px", color: "black" })
      .add();
    this.renderedShapes.push(yLabel);
  }

  private calculateEccLength(millRotorCenter: { x: number; y: number }): number {
    return Math.sqrt(millRotorCenter.x * millRotorCenter.x + millRotorCenter.y * millRotorCenter.y);
  }

  private getAngleRotationFix(
    chartCenter: { x: number; y: number },
    millRotorCenter: { x: number; y: number },
    angleCorrection: number
  ): string {
    const inSecondOrFourthQuadrant: boolean = millRotorCenter.x * millRotorCenter.y < 0;
    if (inSecondOrFourthQuadrant) {
      return `rotate(${2 * angleCorrection},${chartCenter.x},${chartCenter.y})`;
    }
    return `rotate(${-2 * (90 - angleCorrection)},${chartCenter.x},${chartCenter.y})`;
  }

  protected updateChartData(): void {
    const rotorMill: number[] = this.dataAccessor.getValueForRole(Roles.RotorMillCenter.name);

    if (!rotorMill) {
      return;
    }

    this.rotorMillCenter = {
      x: rotorMill[0],
      y: rotorMill[1]
    };
  }

  // NOTE the actual drawing of axes is done with svg renderer
  protected setChartOptions(): void {
    const component = this;

    this.updateChartFlag = false;
    this.updateOneToOneFlag = false;
    this.chartOptions = {
      lang: {
        noData: ""
      },
      chart: {
        polar: true,
        events: {
          load: function () {
            component.chartObject = this; // expose chart object to component in order to properly call renderer
            component.render(component.chartObject as Chart, component.rotorMillCenter);
          }
        }
      },
      title: { text: null },
      credits: { enabled: false },
      legend: { enabled: true },
      pane: { startAngle: 0, endAngle: 360 },
      xAxis: {
        id: "xAxis",
        lineWidth: 0,
        gridLineWidth: 0,
        lineColor: "transparent",
        minorGridLineWidth: 0,
        labels: { enabled: true },
        tickInterval: 90,
        min: 0,
        max: 360
      },
      yAxis: {
        id: "yAxis",
        showFirstLabel: false,
        min: 0,
        tickAmount: 2,
        gridLineWidth: 0,
        floor: 0,
        ceiling: 10,
        max: 10,
        plotLines: []
      },
      plotOptions: {
        series: { pointStart: 0, pointInterval: 0 },
        column: { pointPadding: 0, groupPadding: 0 }
      },
      series: []
    };
  }
}
