import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntityId } from "../../meta";
import { Dictionary, Maybe } from "../../ts-utils";
import { DataConnectorViewDto } from "../models/data-connector-view";

@Injectable()
export class IDataConnectorViewSelector {
  selectAll(): Observable<Dictionary<DataConnectorViewDto>> {
    throw new Error("Not implemented");
  }

  selectById(Id: EntityId): Observable<Maybe<DataConnectorViewDto>> {
    throw new Error("Not implemented");
  }

  getById(id: EntityId): Maybe<DataConnectorViewDto> {
    throw new Error("Not implemented");
  }

  getManyById(connectorViewIds: EntityId[]): Dictionary<DataConnectorViewDto> {
    throw new Error("Not implemented");
  }

  getAll(): Dictionary<DataConnectorViewDto> {
    throw new Error("Not implemented");
  }

  getDataConnectorViewIds(): Maybe<EntityId[]> {
    throw new Error("Not implemented");
  }

  selectForComponent(componentId: EntityId): Observable<DataConnectorViewDto[]> {
    throw new Error("Not implemented");
  }
}
