<div class="color-picker__wrapper">
  <div class="color-picker__indicator" #colorIndicator>
    <div class="color-picker--selected-background"></div>
    <div
      class="color-picker--selected"
      [style.background-color]="color"
      (click)="openColorPicker($event)"
    ></div>
  </div>
  <input
    #editorInput
    class="editor--discreet-input color-picker__hex-value"
    [ngClass]="{
      'invalid-input': !isColorFormatValid,
      'editor--value-configured': value !== propertyInfo.descriptor.defaultValue
    }"
    [value]="color ? color : 'rgba(255,255,255,0)'"
    (input)="onInputChange($event)"
  />
</div>
<div *ngIf="!isColorFormatValid" class="editor-item__message error">
  Value is invalid, it will not be saved.
</div>
