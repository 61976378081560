import { createAction, props } from "@ngrx/store";

export namespace ErrorCatchingActions {
  export const catchError = createAction(
    "[Global] Catch Error",
    props<{ messageToDisplay: string; error?: any; autoClose: boolean }>()
  );

  export const catchWarning = createAction(
    "[Global] Catch Warning",
    props<{ messageToDisplay: string }>()
  );

  export const provideInfo = createAction(
    "[Global] Provide Info",
    props<{ messageToDisplay: string }>()
  );
}
