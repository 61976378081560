import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { AccountInfo } from "@azure/msal-common";
import { Observable } from "rxjs";
import { HttpService, WebServicesConfiguration } from "ui-core";
import { Customer } from "../../models/api/auth-customer.interface";

@Injectable()
export class AccountService {
  constructor(
    private httpService: HttpService,
    private apiConfig: WebServicesConfiguration,
    private msalSvc: MsalService
  ) {}

  getUserData(): AccountInfo | null {
    return this.msalSvc.instance.getActiveAccount();
  }

  getCustomersForCurrentUser(): Observable<Customer[]> {
    const params = new HttpParams().append(
      "userId",
      this.msalSvc.instance.getActiveAccount()?.localAccountId as string
    );

    return this.httpService.get({
      url: `${this.apiConfig.itemsServiceUrl}/access-management/organization`,
      options: { params }
    });
  }
}
