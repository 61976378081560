<mat-form-field class="mat-date-picker--narrow basic-time-selector__picker">
  <input
    matInput
    [matDatepicker]="fromDatePicker"
    placeholder="From"
    (dateChange)="onChangeFromDate($event.target.value)"
    [value]="timeRange.from"
  />
  <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
  <mat-datepicker
    #fromDatePicker
    [startView]="monthly ? 'year' : 'month'"
    (monthSelected)="monthly ? onChangeFromDate($event, fromDatePicker) : null"
  ></mat-datepicker>
</mat-form-field>
<mat-form-field class="mat-date-picker--narrow basic-time-selector__picker">
  <input
    matInput
    [matDatepicker]="toDatePicker"
    placeholder="To"
    (dateChange)="onChangeToDate($event.target.value)"
    [value]="timeRange.to"
  />
  <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
  <mat-datepicker
    #toDatePicker
    [startView]="monthly ? 'year' : 'month'"
    (monthSelected)="monthly ? onChangeToDate($event, toDatePicker) : null"
  ></mat-datepicker>
</mat-form-field>
