import { createSelector } from "@ngrx/store";
import { BrowsingState } from "../browsing.state";
import { getCachedFeature } from "../feature.selector";

export const selectEquipmentModel = createSelector(
  getCachedFeature,
  (state: BrowsingState) => state.equipment
);

export const selectLoadingStatus = createSelector(
  selectEquipmentModel,
  (equipmentModel) => equipmentModel.loading
);

export const selectEquipmentTree = createSelector(
  selectEquipmentModel,
  (equipmentModel) => equipmentModel.root
);
