import { AnyFunction } from "../models/function.type";
import { Maybe } from "../models/maybe.type";

export function isDefined<T>(x: Maybe<T>): x is T {
  return x != null;
}

export function assertIsDefined<T>(x: Maybe<T>): asserts x is T {
  if (x == null) {
    throw new Error("null");
  }
}

export function isNotDefined<T>(x: Maybe<T>): x is null | undefined {
  return x == null;
}

export function isArray(x: any): x is any[] {
  return Array.isArray(x);
}

export function isUndefined<T>(x: Maybe<T>): x is T | null {
  return x === undefined;
}

export function isFunction(x: any): x is AnyFunction {
  return typeof x === "function";
}

export function isNumber(x: any): x is number {
  return typeof x === "number";
}

export function isString(x: any): x is string {
  return typeof x === "string";
}

export function isDate(x: any): x is Date {
  return x instanceof Date;
}
