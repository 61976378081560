import "reflect-metadata";
import { PropertyCategory } from "../models/property-category";
import { DEFAULT_PROPERTY_ORDER_INDEX, PropertyDescriptor } from "../models/property-descriptor";
import { TypeProvider } from "../services/type-provider";

const TYPE_PROVIDER = TypeProvider.getInstance();

export function ConfigurationCategory(
  category: PropertyCategory,
  subCategory: string = "General",
  orderIndex: number = DEFAULT_PROPERTY_ORDER_INDEX
) {
  return function (proto: Object, key: string) {
    const partialPropertyDescriptor: Partial<PropertyDescriptor> = {
      category,
      subCategory,
      order: orderIndex
    };

    TYPE_PROVIDER.upsertProperty(proto, key, partialPropertyDescriptor);
  };
}
