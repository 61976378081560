import { MsalGuardConfiguration, MsalInterceptorConfiguration } from "@azure/msal-angular";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { environment } from "../environments/environment";

/**
 * Factory for MSAL Public Application Configuration Factory
 * @returns
 */
export function MSALInstanceFactory(): PublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.AADAppClientId,
      authority: "https://login.microsoftonline.com/372ee9e0-9ce0-4033-a64a-c07073a91ecd",
      redirectUri: environment.authUri
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false
    }
  });
}

export function MSALGuardConfigurationFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ["openid"]
    }
  };
}

/**
 * Factory for MSAL Interceptor Configuration Factory
 * @returns
 */
export function MSALInterceptorConfigurationFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: new Map([
      ["./assets/ui-core/appSettings.json", null],
      ["https://dc.services.visualstudio.com/*", null],
      ["**", msalScopes]
    ])
  };
}

export const msalScopes = [`api://${environment.AADAppClientId}/serving-api`];
