import { ActionReducerMap } from "@ngrx/store/src/models";
import { reducer as apiDataSourceReducer } from "./api-data-source-descriptor/api-data-source-descriptor.reducer";
import { reducer } from "./generic-data-source-descriptor/generic-data-source-descriptor.reducer";
import { reducer as periodTypesReducer } from "./period-type/period-type.reducer";
import { ConnectivityState } from "./state";

export const reducers: ActionReducerMap<ConnectivityState> = {
  allApiDataSourceDescriptors: apiDataSourceReducer,
  allGenericDatasourceDescriptors: reducer,
  periodTypes: periodTypesReducer
};
