import { construct } from "../../core/services/construct.helper";
import { EditableType, Serializable } from "../../meta/decorators";
import { KmLogConfig, KmLogConfigDto } from "../models/km-log-config";

const TYPE_NAME = "KmLogArray";

export interface KmLogArrayDto {
  logs: KmLogConfigDto[];
}

@EditableType({ fullName: TYPE_NAME, title: "km-log-array" })
export class KmLogArray implements KmLogArrayDto {
  // @ConfigurableArray({ displayName: "Logs", typeConstructor: KmLogConfig })
  @Serializable([], KmLogConfig)
  logs!: KmLogConfig[];

  constructor() {
    construct(this, {}, TYPE_NAME);
  }
}
