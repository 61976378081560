import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { ComponentCategoryInfo, CoreComponentCategoryProvider, WebServicesConfiguration } from "ui-core";
import { RdsComponentCategory } from "../../components/rds-component-category";

@Injectable({
  providedIn: "root"
})
export class RdsComponentCategoryProvider extends CoreComponentCategoryProvider {
  private readonly common: ComponentCategoryInfo[] = [
    new ComponentCategoryInfo(RdsComponentCategory.RDS, "abb-icon Powertrain", 600)
  ];

  private readonly abb: ComponentCategoryInfo[] = [
    new ComponentCategoryInfo(RdsComponentCategory.ABB, "abb-icon Jog_planar", 700)
  ];

  constructor(
    private http: HttpClient,
    private apiConfig: WebServicesConfiguration
  ) {
    super();
  }
  
  private rdsCategories$(): Observable<ComponentCategoryInfo[]> {
    return this.http.get<boolean>(`${this.apiConfig.itemsServiceUrl}/access-management/me/abb`).pipe(
      map((isABB: boolean) => isABB
        ? this.common.concat(this.abb)
        : this.common
      )
    );
  }

  public selectAllCategories(): Observable<ComponentCategoryInfo[]> {
    return forkJoin([
      super.selectAllCategories(),
      this.rdsCategories$()
    ]).pipe(
      map(([coreComponents, rdsComponents]) => coreComponents.concat(rdsComponents))
    )
  }
}
