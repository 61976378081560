import { ChangeDetectorRef, Component, ElementRef, HostBinding, OnDestroy } from "@angular/core";
import { Actions } from "@ngrx/effects";
import { fromEvent, Subject, Subscription } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { IconId } from "../../../browsing/models/icon-id";
import { Dispatcher } from "../../../dispatcher";
import { LocalizationService } from "../../../i18n/localization.service";
import { SPACE_KEY } from "../../../keyboard.constants";
import { TypeProvider } from "../../../meta";
import { OfType } from "../../../meta/decorators";
import { EditorType } from "../../../meta/models";
import {
  ON_ICON_PICKER_DIALOG_CLOSED,
  OpenIconPickerDialog
} from "../../../shared/dialogs/actions/icon-picker-dialog.actions";
import { BaseEditorComponent } from "../base-editor.component";

@Component({
  selector: "icon-picker-editor",
  templateUrl: "icon-picker-editor.component.html",
  styleUrls: ["icon-picker-editor.component.scss"]
})
@OfType(EditorType.IconPicker)
export class IconPickerEditorComponent extends BaseEditorComponent implements OnDestroy {
  private subscription: Subscription;
  private unsubscribeSubject$: Subject<any> = new Subject<any>();

  constructor(
    protected cdr: ChangeDetectorRef,
    private dispatcher: Dispatcher,
    private actions$: Actions,
    protected typeProvider: TypeProvider,
    protected translationService: LocalizationService,
    private hostElementRef: ElementRef
  ) {
    super(cdr, typeProvider, translationService);
  }

  public get selectedIcon(): IconId {
    return this.value as IconId;
  }

  @HostBinding("attr.title")
  public get tooltipText(): string {
    return this.tooltip;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscribeToKeyboardEvent();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  private subscribeToKeyboardEvent(): void {
    fromEvent<KeyboardEvent>(this.hostElementRef.nativeElement, "keydown")
      .pipe(
        filter((event) => event.key === SPACE_KEY),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe(() => this.open());
  }

  // open dialog via effects
  open() {
    this.subscription = this.actions$.subscribe((action: any) => {
      if (action.type !== ON_ICON_PICKER_DIALOG_CLOSED) {
        return;
      }

      if (action.result !== undefined) {
        this.value = action.result;
        super.onValueChanged(action.result);
      }
      this.subscription.unsubscribe();
    });
    this.dispatcher.dispatch(new OpenIconPickerDialog());
  }

  public clear() {
    if (this.value != null) {
      this.value = null;
      super.onValueChanged(null);
    }
  }
}
