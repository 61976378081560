export class ToolbarItem {
  icon: string;
  componentToShow: SidemenuComponents;
  componentCategory: string;
  singleAction?: () => void;

  constructor(
    icon: string,
    component: SidemenuComponents,
    componentCategory: string,
    singleAction?: () => void
  ) {
    this.icon = icon;
    this.componentToShow = component;
    this.componentCategory = componentCategory;
    this.singleAction = singleAction;
  }
}

export enum SidemenuComponents {
  GeneralSettings = "GeneralSettings",
  DataExplorer = "DataExplorer",
  TreeExplorer = "TreeExplorer",
  MapExplorer = "MapExplorer",
  Copilot = "Copilot",
  LayoutBuilderTabContent = "LayoutEditor"
}
