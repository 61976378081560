import { PropertyInfo } from "../../meta/models/property-info";
import { TypeProvider } from "../../meta/services/type-provider";
import { Dictionary, Maybe } from "../models";
import { mergeDeep } from "./assignment.helper";
import { isEmpty, isEmptyOrNotDefined } from "./is-empty.helper";
import { isNotDefined } from "./predicates.helper";

const TYPE_PROVIDER = TypeProvider.getInstance();

export function first<T>(array: Maybe<T[]>): Maybe<T> {
  if (isNotDefined(array) || isEmpty(array)) {
    return null;
  } else {
    return array[0];
  }
}

export function last<T>(array: Maybe<T[]>): Maybe<T> {
  if (isNotDefined(array) || isEmpty(array)) {
    return null;
  } else {
    return array[array.length - 1];
  }
}

export function removeDuplicates<T>(array: T[], objectKeyGetter?: (element: T) => string): T[] {
  if (isNotDefined(objectKeyGetter)) {
    return [...new Set(array)];
  } else {
    const uniqueKeys: string[] = array.map(objectKeyGetter);
    return array.filter(
      (element: T, index: number) => uniqueKeys.indexOf(objectKeyGetter(element)) === index
    );
  }
}

export function areArraysEqualByElementsReference<T>(first: T[], second: T[]): boolean {
  if (first.length !== second.length) {
    return false;
  }
  let areArraysTheSame = true;
  first.forEach((elementInFirstArray: T) => {
    if (!second.includes(elementInFirstArray)) {
      areArraysTheSame = false;
    }
  });
  if (!areArraysTheSame) {
    return false;
  }
  // if first array has 2 elements that are the same, we need to check in the other way too...
  second.forEach((elementInFirstArray: T) => {
    if (!first.includes(elementInFirstArray)) {
      areArraysTheSame = false;
    }
  });
  return areArraysTheSame;
}

export function groupBy<T, K extends keyof T>(array: T[], key: K): Dictionary<T[]> {
  return array.reduce<Dictionary<T[]>>((acc, elem) => {
    const elemKey = elem[key] as unknown as string;
    const ofKey = acc[elemKey];
    if (ofKey === undefined) {
      acc[elemKey] = [elem];
    } else {
      ofKey.push(elem);
    }
    return acc;
  }, {});
}

export function groupByNestedKey<T>(array: T[], key: (item: T) => any): Dictionary<T[]> {
  return array.reduce<Dictionary<T[]>>((acc, elem) => {
    const groupKey = key(elem);
    if (isNotDefined(acc[groupKey])) {
      acc[groupKey] = [];
    }
    acc[groupKey].push(elem);
    return acc;
  }, {});
}

export function mergeByKey<T>(array1: T[], array2: T[]): any[] {
  const unifiedArrays: T[] = [...array1, ...array2];
  if (isEmptyOrNotDefined(unifiedArrays)) {
    return [];
  }
  const element = unifiedArrays[0];
  if (element instanceof Object) {
    return mergeComplexElements(unifiedArrays);
  } else {
    return mergePrimitiveElements(unifiedArrays);
  }
}

export function mergeComplexElements<T>(array: T[]): T[] {
  const combinedMap = new Map<any, T>();
  const instanceType = TYPE_PROVIDER.getTypeByConstructor(array[0].constructor);
  const keyProp: Maybe<PropertyInfo<any>> = TYPE_PROVIDER.getKeyPropertyItemDeep(
    instanceType,
    array[0]
  );
  if (isNotDefined(keyProp)) {
    return array;
  }

  const key = keyProp.descriptor.name;
  array.forEach((el) => {
    if (!combinedMap.has(el[key])) {
      combinedMap.set(el[key], el);
    } else {
      combinedMap.set(el[key], mergeDeep(combinedMap.get(el[key]) as T, el));
    }
  });
  return Array.from(combinedMap.values());
}

export function mergePrimitiveElements<T>(array: T[]): T[] {
  const combinedMap = new Map<any, T>();
  array.forEach((el) => {
    combinedMap.set(el, el);
  });
  return Array.from(combinedMap.values());
}
