import { createAction, props } from "@ngrx/store";
import { DialogData } from "../../../models/dialogs/dialog-data.model";

export namespace PlotlineDialogActions {
  export const addPlotlineDialog = createAction(
    "[Dialog] Add Plotline Dialog",
    props<{ chartYAxes: any[] }>()
  );

  export const addPlotlineDialogSuccess = createAction(
    "[Dialog] Add Plotline Dialog Success",
    props<{ result: DialogData }>() // FIXME Rename param and class, both names are too generic
  );

  export const removePlotlineDialog = createAction(
    "[Dialog] Remove Plotline Dialog",
    props<{ chartYAxes: any[] }>()
  );

  export const removePlotlineDialogSuccess = createAction(
    "[Dialog] Remove Plotline Dialog Success",
    props<{ plotlinesToRemove: DialogData }>()
  );
}
