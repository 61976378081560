import { isDefined } from "../../ts-utils/helpers/predicates.helper";

export class ButtonsLocalizationDictionary {
  Save: string = "Save";
  Layout: string = "Layout";
  DataExplorer: string = "DataExplorer";
  LayoutEditor: string = "LayoutEditor";
  Filters: string = "Filters";
  UserMenu: string = "UserMenu";
  ReportBrowser: string = "ReportBrowser";
  SaveAs: string = "SaveAs";
  More: string = "More";
  Delete: string = "Delete";
  Edit: string = "Edit";
  SnapToGrid: string = "SnapToGrid";
  AutoPosition: string = "AutoPosition";
  Link: string = "Link";
  Export: string = "Export";
  Expand: string = "Expand";
  Collapse: string = "Collapse";
  Preview: string = "Preview";
  Undo: string = "Undo";
  Redo: string = "Redo";
  UndoRedo: string = "Undo/Redo";
  Autosave: string = "Autosave";
  LogOut: string = "Log out";
  LiveMode: string = "LiveMode";
  Cancel: string = "Cancel";
  BringToFront: string = "Bring To Front";
  SendToBack: string = "Send To Back";
  Pin: string = "Pin";
  Unpin: string = "Unpin";
  Clone: string = "Clone";
  Copy: string = "Copy";
  Paste: string = "Paste";
  Cut: string = "Cut";
  Close: string = "Close";
  Clipboard: string = "Clipboard";
  Miscellaneous: string = "Miscellaneous";
  About: string = "About";
  Help: string = "Help";
  ReleaseNotes: string = "ReleaseNotes";
  ResetTimeTooltip: string = "ResetTimeTooltip";
  AlignAndDistribute: string = "Align And Distribute";
  LeftAlign: string = "Left Align";
  Center: string = "Center";
  RightAlign: string = "Right Align";
  TopAlign: string = "Top Align";
  Middle: string = "Middle";
  BottomAlign: string = "Bottom Align";
  HorizontalDistribute: string = "Horizontal Distribute";
  VerticalDistribute: string = "Vertical Distribute";
  CopyRuntimeToClipboardTooltip: string = "CopyRuntimeToClipboardTooltip";
  CopyHistoryViewToClipboardTooltip: string = "CopyHistoryViewToClipboardTooltip";
  Hide: string = "Hide";
  Show: string = "Show";
  InlineEdit: string = "InlineEdit";
  Credits: string = "Credits";

  constructor() {
    Object.keys(this).forEach((key) => {
      const thisKey = key as keyof ButtonsLocalizationDictionary;
      this[thisKey] = isDefined(thisKey) ? `Buttons.${thisKey}` : "";
    });
  }
}
