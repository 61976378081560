import { Maybe, isDefined } from "../../../ts-utils";

export function makeSeriesInvisible(
  allSeries: Highcharts.SeriesOptionsType[],
  invisibleSeriesIds: Set<string>
): void {
  invisibleSeriesIds.forEach((seriesId: string) => {
    const seriesToHide: Maybe<Highcharts.SeriesOptionsType> = allSeries.find(
      (series) => series.id === seriesId
    );
    if (isDefined(seriesToHide)) {
      seriesToHide.visible = false;
    }
  });
}

export function hideSeries(seriesId: Maybe<string>, invisibleSeriesIds: Set<string>): void {
  if (isDefined(seriesId)) {
    invisibleSeriesIds.add(seriesId);
  }
}

export function showSeries(seriesId: Maybe<string>, invisibleSeriesIds: Set<string>): void {
  if (isDefined(seriesId)) {
    invisibleSeriesIds.delete(seriesId);
  }
}
