import { isDefined, isNotDefined } from "./predicates.helper";

export function isWhiteSpace(s: string): boolean {
  return s.trim() === "";
}

export function isWhiteSpaceOrNotDefined(s: string | null | undefined): boolean {
  return isNotDefined(s) || isWhiteSpace(s);
}

export function convertDashCaseToTitleText(kebabString: string): string {
  let wordArray = kebabString.split("-");
  wordArray = wordArray.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return wordArray.join(" ");
}

export function plainTextToDashCase(text: string): string {
  return text.trim().toLowerCase().replace(/\s+/g, "-");
}

export function toStringOrUndefined(obj: unknown): string | undefined {
  return isDefined(obj) ? String(obj) : undefined;
}

export function capitalizeAfterSpaceRemoval(text: string): string {
  let convertedText: string = "";
  text.split(" ").forEach((word) => {
    convertedText += word.charAt(0).toUpperCase() + word.slice(1);
  });
  return convertedText;
}
