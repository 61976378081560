import { createAction, props } from "@ngrx/store";
import { EntityId } from "../../../meta/models/entity";
import { ReportBrowserNode } from "../../models/report-browser-node";

export namespace CachedReportsActions {
  export const addReport = createAction(
    "[Cached Reports] Add Report",
    props<{ newReportNode: ReportBrowserNode }>()
  );

  export const fetchReport = createAction(
    "[Cached Reports] Fetch Report",
    props<{ reportId: EntityId }>()
  );

  export const fetchReportSuccess = createAction(
    "[Cached Reports] Fetch Report Success",
    props<{ cachedReportNode: ReportBrowserNode }>()
  );

  export const fetchCachedReportComponentState = createAction(
    "[Cached Reports] Fetch Cached Report Component State"
  );

  export const deleteCachedReports = createAction("[Cached Reports] Delete Cached Reports");
}
