import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { DecoratorDelegateContext } from "../../core/models/decorator-delegate-context";
import { getConnectorIdByViewId } from "../../data-connectivity/helpers/connector-view-id.helper";
import { SelectionOption } from "../../meta/models/selection";
import { isEmpty } from "../../ts-utils/helpers/is-empty.helper";
import { isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { CategoryBarDisplayStrategies } from "../models/display-strategies/category-display-strategies";
import { DisplayStrategies as TimeSeriesDisplayStrategies } from "../models/display-strategies/time-series-display-strategies";
import { DEFAULT_UNIT_INTERPOLATION } from "../models/property-interpolation.constants";
import { filterOutEnumValues } from "./dynamically-visible-properties.helper";

export function getYAxesDisplayStrategies(): any[] {
  return [
    ...CategoryBarDisplayStrategies,
    ...filterOutEnumValues(TimeSeriesDisplayStrategies, [TimeSeriesDisplayStrategies.Table])
  ];
}

export function getYAxisNames(context: DecoratorDelegateContext): Observable<SelectionOption[]> {
  const connectorId = getConnectorIdByViewId(context.ownerInstance.id);
  const component = context.services.componentStateSelector.getComponentByConnectorId(connectorId);
  if (isNotDefined(component)) {
    return of([]);
  }
  return context.services.componentStateSelector.selectYAxes(component.id).pipe(
    map((yAxes) =>
      (yAxes ?? []).map((yAxis, index) => ({
        key: yAxis.id,
        title: getAxisTitle(yAxis.axisTitle, index)
      }))
    )
  );
}

function getAxisTitle(axisTitle: string, index: number): string {
  if (isEmpty(axisTitle)) {
    return `Axis no. ${index + 1}`;
  }
  return axisTitle === DEFAULT_UNIT_INTERPOLATION ? `${axisTitle} [${index + 1}]` : axisTitle;
}
