<equipment-path-browser
  *ngIf="usePathBrowser"
  [activePath]="selectedPath"
  (selectedEquipmentPathChanged)="equipmentPathSelectionChanged($event)"
  [openFullTree]="openFullTree"
>
</equipment-path-browser>
<equipment-browser
  *ngIf="!usePathBrowser"
  [rootPath]="equipmentDialogInfo.currentPath"
  (selectedEquipmentChanged)="equipmentSelectionChanged($event)"
  [showInDialog]="true"
  [rootClass]="rootClass"
  [openFullTree]="openFullTree"
></equipment-browser>

<mat-dialog-actions>
  <button (click)="confirm()" class="c-button c-button--rounded c-button--primary">OK</button>
  <button (click)="close()" class="c-button c-button--rounded">Cancel</button>
</mat-dialog-actions>
