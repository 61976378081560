import * as unitsCss from "units-css";
import { pageVirtualGridGap } from "../../../style-variables";
import { SizeMeasurement } from "../../core/models/size-measurement";

export const CSS_SIZE = "ComponentCssSize";

export const FULL_WIDTH_CSS_SIZE: string = "100.00%";
export const FULL_HEIGHT_CSS_SIZE: string = "100.00%";

export const PAGE_VIRTUAL_GRID_GAP = pageVirtualGridGap;
export const PAGE_VIRTUAL_GRID_SIDE = 75;

export const SUPPORTED_CSS_SIZE_UNITS = "px|cm|mm|in|pt|pc|rem|vw|vh|vmin|vmax|%";
export const CSS_SIZE_REGEX = new RegExp(`^([0-9]+)((.[0-9]+)?)(${SUPPORTED_CSS_SIZE_UNITS})?$`);
export const PX_AUTOFILL_KEY = "width";

export const cssSizeParser: (size: string) => SizeMeasurement = (size: string) => {
  return unitsCss.parse(size, PX_AUTOFILL_KEY) as SizeMeasurement;
};
