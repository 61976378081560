import { createSelector } from "@ngrx/store";
import { EntityId } from "../../../meta/models/entity";
import { Dictionary } from "../../../ts-utils";
import { ReportBrowserNode } from "../../models/report-browser-node";
import { BrowsingState } from "../browsing.state";
import { getCachedFeature } from "../feature.selector";
import { selectEntities, selectIds } from "./cached-reports.reducer";

export const selectOpenedReport = (id: EntityId) =>
  createSelector(getCachedFeature, (state: BrowsingState) => {
    const cachedReports: Dictionary<ReportBrowserNode> = selectEntities(state.cachedReports);
    return cachedReports[id];
  });

export const isOpenedReportInState = (id: EntityId) =>
  createSelector(getCachedFeature, (state: BrowsingState) => {
    const idsOfCachedReports: EntityId[] = selectIds(state.cachedReports);

    const neededReportTree = idsOfCachedReports.find((idOfCachedReport: EntityId) => {
      return idOfCachedReport === String(id);
    });
    const isReportTreeCached: boolean = !!neededReportTree;

    return isReportTreeCached;
  });
