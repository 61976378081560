import { Injectable } from "@angular/core";
import { DataConnectorDto } from "../../../data-connectivity";
import { IEntityConverter } from "../../../meta";
import { ComponentStateDto } from "../../models/component-state";
import { ComponentStateViewModel } from "../../models/component-state.vm";
import { COMPONENT_STATE_DTO } from "../../models/entity-type.constants";

@Injectable()
export class ComponentStateViewModelSerializer
  implements IEntityConverter<ComponentStateViewModel, ComponentStateDto>
{
  constructor() {}

  convert(componentStateVM: ComponentStateViewModel): ComponentStateDto {
    const childrenIds = componentStateVM.children.map((child) => child.id);
    const dataConnectorIds = componentStateVM.dataConnectors.map(
      (dataConnector: DataConnectorDto) => dataConnector.id
    );
    const filterId = componentStateVM.filterConfig?.id;
    return new ComponentStateDto({
      id: componentStateVM.id,
      view: componentStateVM.view,
      type: componentStateVM.type,
      typeName: COMPONENT_STATE_DTO,
      dataConnectorIds,
      childrenIds,
      filterId,
      dataConnectorQuery: {
        ...componentStateVM.dataConnectorQuery,
        aggregationConfig: { ...componentStateVM.dataConnectorQuery.aggregationConfig }
      }
    });
  }
}
