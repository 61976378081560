import { IActionMapping, TREE_ACTIONS } from "@circlon/angular-tree-component";
import { ITreeNode } from "@circlon/angular-tree-component/lib/defs/api";
import { NavigationNodeInfo, SidebarNavigationNode } from "ui-core";

export class NgTreeControl {
  disabledNodeClass = "sidebar-navigation__navigation-node--disabled";
  activeNodeClass = "sidebar-navigation__navigation-node--active";

  actionMapping: IActionMapping = {
    mouse: {
      click: (tree, node, $event) => {
        if (!this.nodeHasLink(node)) {
          return TREE_ACTIONS.DEACTIVATE(tree, node, $event);
        }
        return TREE_ACTIONS.TOGGLE_ACTIVE(tree, node, $event);
      }
    }
  };

  nodeHasLink(node: ITreeNode): boolean {
    const sidebarNavigationNode: SidebarNavigationNode<NavigationNodeInfo> = node.data;
    const hasReportLink: boolean = !!sidebarNavigationNode.nodeInfo.link?.reportId;

    return hasReportLink;
  }
}
