import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { ReportId } from "../../../core";
import { IReportInfoSelector } from "../../../core/services/i-report-info.selector";
import { Maybe } from "../../../ts-utils";
import { ReportInfoState } from "../../store/report-info";
import { selectReportId, selectReportInfo } from "../../store/report-info/report-info.selectors";

@Injectable()
export class ReportInfoSelector implements IReportInfoSelector {
  constructor(private store$: Store<any>) {}

  getReportInfo(): ReportInfoState {
    let reportInfo: ReportInfoState;
    this.store$
      .select(selectReportInfo)
      .pipe(take(1))
      .subscribe((reportStateInfo) => (reportInfo = reportStateInfo));
    return reportInfo;
  }

  selectReportId(): Observable<Maybe<ReportId>> {
    return this.store$.select(selectReportId);
  }

  getReportId(): ReportId {
    let reportId: ReportId;
    this.store$
      .select(selectReportId)
      .pipe(take(1))
      .subscribe((id) => (reportId = id));
    return reportId;
  }
}
