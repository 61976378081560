import { createSelector } from "@ngrx/store";
import { DataConnectorDto } from "../../../data-connectivity";
import { getConnectorViewId } from "../../../data-connectivity/helpers/connector-view-id.helper";
import { DataConnectorViewDto } from "../../../data-connectivity/models/data-connector-view";
import { EntityId } from "../../../meta/models/entity";
import { Dictionary, isDefined } from "../../../ts-utils";
import { selectComponentDataConnectorIds } from "../component-state/component-state.selectors";
import { getReportFeature } from "../feature.selector";
import { ReportContents } from "../state";

export const selectDataConnectorViews = createSelector(
  getReportFeature,
  (state: ReportContents) => state.dataConnectorViews
);

export const selectDataConnectorViewEntities = createSelector(
  selectDataConnectorViews,
  (state) => state.entities
);

export const selectDataConnectorViewIds = createSelector(
  selectDataConnectorViews,
  (state) => state.ids
);

export const selectDataConnectorViewById = (id: EntityId) =>
  createSelector(selectDataConnectorViewEntities, (entities) => entities[id]);

export const selectComponentConnectorViewsById = (componentId: EntityId) =>
  createSelector(
    selectComponentDataConnectorIds(componentId),
    selectDataConnectorViewEntities,
    (dataConnectorIds: EntityId[], allConnectorViews: Dictionary<DataConnectorViewDto>) => {
      const connectorViewIds = dataConnectorIds.map((connectorId) =>
        getConnectorViewId(connectorId)
      );
      return connectorViewIds.map((viewId) => allConnectorViews[viewId]).filter(isDefined);
    }
  );

export const selectConnectorViewDictByConnectors = (connectors: DataConnectorDto[]) =>
  createSelector(selectDataConnectorViewEntities, (entities: Dictionary<DataConnectorViewDto>) => {
    return connectors.reduce(
      (acc: Dictionary<DataConnectorViewDto>, connector: DataConnectorDto) => {
        const viewId = getConnectorViewId(connector?.id);
        acc[viewId] = entities[viewId];
        return acc;
      },
      {}
    );
  });

export const selectReorderedViewsByConnectors = (connectors: DataConnectorDto[]) =>
  createSelector(
    selectConnectorViewDictByConnectors(connectors),
    (entities: Dictionary<DataConnectorViewDto>) => {
      return Object.keys(entities).reduce(
        (acc: DataConnectorViewDto[], connectorViewId: EntityId) => {
          if (isDefined(entities[connectorViewId]) && isDefined(entities[connectorViewId].order)) {
            acc.push(entities[connectorViewId]);
          }
          return acc;
        },
        []
      );
    }
  );
