export const DEFAULT_TITLE_INTERPOLATION = "${connector.title}";
export const DEFAULT_UNIT_INTERPOLATION = "${connector.unit}";
export const DEFAULT_Y_AXIS_INTERPOLATED_TITLE = "${connector.unit}";

export const WIDGET_SOURCE = "widget";
export const CONNECTOR_SOURCE = "connector";
export const CONNECTOR_VIEW_SOURCE = "connectorView";
export const FILTER_SOURCE = "filter";
export const DATA_POINT_SOURCE = "dataPoint";

export const CONNECTOR_GROUP_INPUT = `(\\[[-a-zA-Z0-9@%:,_+~#=\\.'\\(\\)\\s]+(\\([1-9]d*\\))?\\[[1-9]d*\\]\\])`;
export const STRING_INTERPOLATION_INPUT = `\\$\\{(${WIDGET_SOURCE}|${FILTER_SOURCE}|${DATA_POINT_SOURCE}|${CONNECTOR_SOURCE}((\\[[1-9]\\d*\\])|${CONNECTOR_GROUP_INPUT})?|${CONNECTOR_VIEW_SOURCE}((\\[[1-9]\\d*\\])|${CONNECTOR_GROUP_INPUT})?)(\\.[a-zA-Z]+)\\}`;

export const STRING_INTERPOLATION_REGEX = new RegExp(STRING_INTERPOLATION_INPUT, "g");

export const STRING_INTERPOLATION_SEPARATORS = new RegExp(`[\\$\\{\\.\\}]+`);
export const SOURCE_INDEX_SEPARATORS = new RegExp(`[\\[\\]]+`, "g");

export const WIDGET_QUERY_TYPENAME = "WidgetQueryFilter";

export const SOURCE_AUTOCOMPLETE_INDICATOR = new RegExp(`(\\$\\{)`);
export const PROPERTY_AUTOCOMPLETE_INDICATOR = new RegExp(
  `((\\[\\d+\\])|${CONNECTOR_GROUP_INPUT})?\\.`
);
export const SOURCES_REGEX = new RegExp(
  `(${WIDGET_SOURCE}|${FILTER_SOURCE}|${CONNECTOR_SOURCE}|${CONNECTOR_VIEW_SOURCE}|${DATA_POINT_SOURCE})`
);
export const END_INTERPOLATION_INDICATOR = "}";
