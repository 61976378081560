<div (clickOutside)="closeMenu()">
  <header-button
    data-test="save-settings-dropdown-button"
    [buttonConfig]="dropDownButton"
    [isSelected]="isMenuOpened"
    icon="Save"
  ></header-button>
  <ul
    *ngIf="isMenuOpened"
    class="save-settings__menu"
    [ngClass]="{ 'save-settings__menu--mobile': displayMode === DisplayMode.Mobile }"
  >
    <li class="save-settings__menu-item">
      <div class="save-settings__menu-item-button">
        <header-button
          data-test="save-button"
          [verticallyOpened]="true"
          [buttonConfig]="saveButtonConfig"
          [showLabel]="isMenuOpened"
          [hasProgressBar]="savingInProgress"
          icon="Save"
        ></header-button>
      </div>
    </li>
    <li class="save-settings__menu-item">
      <div class="save-settings__menu-item-button">
        <header-button
          [verticallyOpened]="true"
          [buttonConfig]="saveAsButtonConfig"
          [showLabel]="isMenuOpened"
          icon="Save_as"
        ></header-button>
      </div>
    </li>
  </ul>
</div>
