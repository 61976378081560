import { FormControl } from "@angular/forms";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { isDefined } from "../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";

export function forceOpenAutocomplete(
  filterInput: FormControl<string>,
  value: Maybe<string>,
  autocompleteTrigger: Maybe<MatAutocompleteTrigger>
): void {
  if (isDefined(autocompleteTrigger)) {
    if (isDefined(value)) {
      filterInput.setValue(value);
    } else {
      filterInput.setValue("");
    }
    autocompleteTrigger.openPanel();
  }
}
