<div
  #sidebar
  class="sidebar__container"
  [ngClass]="{ 'sidebar__container--hidden': !isSidebarOpened }"
  (clickOutside)="closeIfNotPinned()"
>
  <div class="sidebar__items">
    <div class="sidebar__items-wrapper">
      <ul>
        <li *ngFor="let item of explorerToolbarItems$ | async">
          <button
            title="{{ localizer.sidebar[item.componentToShow] | translate }}"
            class="sidebar__button"
            [ngClass]="{
              'sidebar__button--active': item == selectedToolbarItem
            }"
            (click)="selectedTabChanged(item)"
          >
            <i [class]="'abb-icon abb-icon--medium ' + item.icon"></i>
          </button>
        </li>
      </ul>
    </div>
    <ul *ngIf="layoutToolbarItems && !previewMode">
      <li *ngFor="let item of layoutToolbarItems">
        <button
          title="{{ localizer.sidebar[item.componentCategory] | translate }}"
          class="sidebar__button"
          [ngClass]="{
            'sidebar__button--active': item == selectedToolbarItem
          }"
          (click)="selectedTabChanged(item)"
        >
          <i [class]="'abb-icon abb-icon--medium ' + item.icon"></i>
        </button>
      </li>
    </ul>
  </div>
  <div
    class="sidebar__panels"
    [ngClass]="{
      'sidebar__panels--pinned': isPinned,
      'sidebar__panels--unpinned': !isPinned,
      'sidebar__panels--responsive': displayMode === DisplayMode.Mobile
    }"
  >
    <div class="sidebar__content-wrapper" [@enterAnimation] *ngIf="selectedToolbarItem">
      <div class="sidebar__header">
        <div class="sidebar__title-wrapper">
          <span class="sidebar__title">
            {{ localizer.sidebar[selectedToolbarItem.componentToShow] | translate }}
          </span>
          <i
            *ngIf="displayMode === DisplayMode.Desktop"
            class="sidebar__header-button sidebar__pin-button abb-icon abb-icon--small"
            [ngClass]="isPinned ? 'Pin' : 'Unpin'"
            [class.sidebar__header-button--pinned]="isPinned"
            (click)="onChangePinMode()"
            title="{{
              isPinned ? (localizer.buttons.Unpin | translate) : (localizer.buttons.Pin | translate)
            }}"
            tabIndex="0"
          ></i>
          <i
            class="sidebar__header-button abb-icon abb-icon--small Close"
            (click)="collapseSidebar()"
            tabIndex="0"
          ></i>
        </div>
        <div class="sidebar__category--active" *ngIf="selectedToolbarItem.componentCategory">
          <span>{{ localizer.sidebar[selectedToolbarItem.componentCategory] | translate }}</span>
        </div>
      </div>
      <div [ngSwitch]="selectedToolbarItem.componentToShow" class="sidebar__content">
        <layout-builder-tab
          *ngSwitchCase="SidemenuComponents.LayoutBuilderTabContent"
          [componentCategory]="selectedToolbarItem.componentCategory"
          (collapseSidebar)="checkIfSidebarShouldCollapse()"
        ></layout-builder-tab>
        <div class="sidebar__content-item" *ngSwitchCase="SidemenuComponents.DataExplorer">
          <data-explorer (collapseSidebar)="checkIfSidebarShouldCollapse()"></data-explorer>
        </div>
        <div *ngSwitchCase="SidemenuComponents.TreeExplorer">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
    <equipment-property-browser
      *ngIf="isPropertyBrowserExpanded"
      [ngClass]="{ 'element--hidden': isSignalTabSelected }"
      (collapseSidebar)="checkIfSidebarShouldCollapse()"
      (collapse)="collapseEquipmentPropertyBrowser()"
    ></equipment-property-browser>
  </div>
</div>
