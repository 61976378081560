import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { DEFAULT_LANGUAGE, IUserPreferencesService } from "ui-core";

@Injectable()
export class RdsUserPreferencesService extends IUserPreferencesService {
  constructor(private msalSvc: MsalService) {
    super();
  }

  getLanguage(): string {
    throw DEFAULT_LANGUAGE;
  }

  setLanguage(language: string) {
    throw new Error("Not implemented");
  }

  getDateTimeFormat(): string {
    return "";
  }

  logOut() {
    this.msalSvc.logout();
  }

  getAliasMode(): boolean {
    return false;
  }
}
