import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { Injectable } from "@angular/core";
import { ParamMap } from "@angular/router";
import { Dictionary } from "@ngrx/entity";
import { isEmpty } from "lodash";
import { ComponentCssSize } from "../../../elements/models/component-size";
import { ComponentStateDto } from "../../../elements/models/component-state";
import { ReportConfiguration } from "../../../elements/models/report-configuration";

import { CURRENT_VERSION } from "../../../upgrade/service/report-upgrade.service";
import { RUNTIME_FILTER_ID } from "../../helpers/filter/filter-id.helper";
import { IQueryReportConfig } from "../../interfaces";
import { IDynamicReportService } from "../../interfaces/dynamic-report-service.interface";
import { DYNAMIC_REPORT } from "../../models/dynamic-report/dynamic-report.constants";
import {
  ExtendedBaseViewConfigDto,
  ExtendedDataConnector,
  ExtendedDataSourceDto
} from "../../models/extended-report-configuration";
import { FilterConfigurationDto } from "../../models/filter/filter-configuration";
import { GeneralSettingsDto } from "../../models/general-settings";
import { QueryReportKeys } from "../../models/query-report.enum";
import { ReportId } from "../../models/report-id";
import { RequiredProperty } from "../../models/required-property";
import { TimeRangeConfigurationDto } from "../../models/time-range-configuration";

const EXPANDED_CARD_WIDTH = "1435";

@Injectable({
  providedIn: "root"
})
export class DynamicReportLineChartService implements IDynamicReportService {
  reportName: string = DYNAMIC_REPORT;
  /**
   * Configures dynamic report based on passed parameters in query.
   * @param params All parameters provided in URL.
   * @returns Modified report if all parameters are provided otherwise default template.
   */
  generateReport(params: ParamMap): ReportConfiguration {
    const config: IQueryReportConfig = {
      name: params.get("name") || "",
      tag: params.get("tag") || "",
      from: params.get("from") || "",
      to: params.get("to") || "",
      forecast: coerceBooleanProperty(params.get("forecast")),
      anomalies: coerceBooleanProperty(params.get("anomalies")),
      analytics: coerceBooleanProperty(params.get("analytics"))
    };

    const isValid = Object.keys(config)
      .filter((k) => k !== "from" && k !== "to")
      .every((v: string) => !isEmpty(v));

    return isValid ? this.modifyReportTemplate(config) : this.getQueryReportTemplate();
  }

  /**
   * Modifies report template according to provided configuration
   * @param config Config object `IQueryReportConfig`
   * @returns Modified report
   */
  private modifyReportTemplate(config: IQueryReportConfig): ReportConfiguration {
    const template = this.getQueryReportTemplate() as RequiredProperty<
      ReportConfiguration,
      "content"
    >;

    const key = Object.keys(template.content.dataConnectors.entities)[0];
    const connector = template.content.dataConnectors.entities[key];
    const timeSeries = template.content.componentStates.entities[QueryReportKeys.TAG_TIME_SERIES];
    const globalFilter = template.content.filters.entities[QueryReportKeys.GLOBAL];

    if (timeSeries) {
      timeSeries.view.title = config.name;
    }

    if (connector) {
      connector.title = config.tag;

      (connector.dataSource as ExtendedDataSourceDto).forecast = config.forecast;
      (connector.dataSource as ExtendedDataSourceDto).anomalies = config.anomalies;

      if (config.analytics) {
        (connector.dataSource as ExtendedDataSourceDto).forecast = true;
        (connector.dataSource as ExtendedDataSourceDto).anomalies = true;
      }

      const data = connector.dataSource as unknown as ExtendedDataConnector;

      data.signal.name = config.tag;
      data.signal.id = `Signals.${config.tag}`;
    }

    if (globalFilter && config.from && config.to) {
      globalFilter.timeRange = {
        typeName: "TimeRangeConfigurationDto",
        fromExpression: this.getFormattedDate(new Date(config.from)),
        toExpression: this.getFormattedDate(new Date(config.to))
      } as TimeRangeConfigurationDto;
    }

    return template as ReportConfiguration;
  }

  private getFormattedDate(date: Date): string {
    return `Date(${date.getFullYear()},${(
      date.getMonth() + 1
    ).toString()},${date.getDate()},${date.getHours()},${date.getMinutes()})`;
  }

  private getQueryReportTemplate(): ReportConfiguration {
    return {
      content: {
        componentStates: {
          ids: [QueryReportKeys.ROOT, QueryReportKeys.TAG_CARD, QueryReportKeys.TAG_TIME_SERIES],
          entities: {
            Root: {
              id: QueryReportKeys.ROOT,
              view: {
                size: {
                  width: "100%",
                  height: "100%"
                } as Partial<ComponentCssSize>
              },
              type: "PageComponent",
              childrenIds: [QueryReportKeys.TAG_CARD],
              filterId: RUNTIME_FILTER_ID
            } as ComponentStateDto,
            "tag-card": {
              id: QueryReportKeys.TAG_CARD,
              view: {
                title: "",
                size: {
                  width: "100%",
                  height: "100%"
                },
                expandedSize: {
                  width: EXPANDED_CARD_WIDTH,
                  height: "100%"
                },
                css: {
                  order: "0",
                  zIndex: "1"
                },
                showHeader: false,
                showFooter: false
              } as unknown as Partial<ExtendedBaseViewConfigDto>,
              type: "BasicCardComponent",
              childrenIds: [QueryReportKeys.TAG_TIME_SERIES]
            } as ComponentStateDto,
            "tag-time-series": {
              id: QueryReportKeys.TAG_TIME_SERIES,
              view: {
                title: "",
                showLegend: false,
                size: {
                  width: "100%",
                  height: "100%"
                } as Partial<ComponentCssSize>,
                exporting: false
              },
              dataConnectorIds: ["c5d4c06e-c813-49c6-b75c-b31edf7c267f"],
              type: "TimeSeriesComponent"
            } as unknown as ComponentStateDto
          }
        },
        dataConnectors: {
          ids: ["c5d4c06e-c813-49c6-b75c-b31edf7c267f"],
          entities: {
            "c5d4c06e-c813-49c6-b75c-b31edf7c267f": {
              id: "c5d4c06e-c813-49c6-b75c-b31edf7c267f",
              role: "Value",
              title: "",
              dataSource: {
                typeName: "SignalDataSourceDto",
                signal: {
                  name: "",
                  id: ""
                },
                forecast: false,
                anomalies: false
              }
            }
          } as unknown as Dictionary<ExtendedDataConnector>
        },
        dataConnectorViews: {
          ids: [],
          entities: {}
        },
        filters: {
          ids: [QueryReportKeys.GLOBAL],
          entities: {
            Global: {
              id: QueryReportKeys.GLOBAL
            } as FilterConfigurationDto
          }
        },
        componentsCounter: 4,
        generalSettings: {
          useServerTime: false
        } as GeneralSettingsDto,
        version: CURRENT_VERSION.asArray
      },
      readOnly: true,
      ancestors: [],
      id: "dynamic-report" as ReportId,
      name: "dynamic-report"
    };
  }
}
