import {
  SERIES_TYPE_DEFAULT,
  SERIES_TYPE_SHAPE,
  SeriesTypeForScatter,
  SeriesTypeForScatterArray
} from "../../data-connectivity/models/series-type.strategies";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import { ConfigurableEnum } from "../../meta/decorators/configurable-enum.decorator";
import { Configurable } from "../../meta/decorators/configurable.decorator";
import { ConfigurationCategory } from "../../meta/decorators/configuration-category.decorator";
import { DynamicallyVisible } from "../../meta/decorators/dynamically-visible.decorator";
import { EditableType } from "../../meta/decorators/editable-type.decorator";
import { Serializable } from "../../meta/decorators/serializable.decorator";
import { EditorType } from "../../meta/models/editor-type";
import { PropertyCategory } from "../../meta/models/property-category";
import { selectScatterSeriesTypeByConnectorViewId } from "../helpers/dynamically-visible-properties.helper";
import { ISeriesBaseConfig } from "./series-base-config";

const TYPE_NAME = "ScatterConnectorView";

@EditableType({ fullName: TYPE_NAME })
export class ScatterConnectorView implements ISeriesBaseConfig {
  typeName = TYPE_NAME;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.layoutEditor.ScatterChart
  )
  @ConfigurableEnum({
    enumSource: SeriesTypeForScatterArray,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.SeriesType
  })
  @Serializable(SERIES_TYPE_DEFAULT)
  seriesType!: SeriesTypeForScatter;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.layoutEditor.ScatterChart
  )
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.LineWidth,
    editorType: EditorType.Number
  })
  @Serializable(2)
  lineWidth!: number;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.layoutEditor.ScatterChart
  )
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.BackgroundColor,
    editorType: EditorType.ColorPicker
  })
  @DynamicallyVisible(selectScatterSeriesTypeByConnectorViewId, [SERIES_TYPE_SHAPE])
  @Serializable("")
  backgroundColor: string = "";
}
