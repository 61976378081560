import { TimeSeriesDataPointDto } from "../../data-connectivity/models/data-point";

export function padGaps(
  data: TimeSeriesDataPointDto[],
  useStartTime: boolean
): TimeSeriesDataPointDto[] {
  if (data == null || data.length === 0 || data[0].startTime == null) {
    return data;
  }
  return useStartTime ? insertPointBeforeGap(data) : insertPointAfterGap(data);
}

export function insertPointAfterGap(data: TimeSeriesDataPointDto[]): TimeSeriesDataPointDto[] {
  let isInGap = true;
  for (let index = 0; index < data.length; index++) {
    const element = data[index];
    if (element.y == null) {
      isInGap = true;
    } else {
      if (isInGap) {
        const endOfGapPoint: TimeSeriesDataPointDto = { ...data[index] };
        if (index === 0) {
          endOfGapPoint.x = data[index].startTime;
          data = [endOfGapPoint, ...data];
        } else {
          endOfGapPoint.x = new Date(data[index].startTime.getTime() + 1);
          data = [...data.slice(0, index), endOfGapPoint, ...data.slice(index)];
        }
        isInGap = false;
      }
    }
  }
  // console.debug(
  //   "padded data: ",
  //   data.map((p) => {
  //     return { x: moment(p.x).format("HH:mm:ss"), y: p.y };
  //   })
  // );
  return data;
}

export function insertPointBeforeGap(data: TimeSeriesDataPointDto[]): TimeSeriesDataPointDto[] {
  let isInGap = true;
  for (let index = data.length - 1; index >= 0; index--) {
    const element = data[index];
    if (element.y == null) {
      isInGap = true;
    } else {
      if (isInGap) {
        const endOfGapPoint: TimeSeriesDataPointDto = { ...data[index] };
        if (index === data.length - 1) {
          endOfGapPoint.x = data[index].endTime;
          data = [...data, endOfGapPoint];
        } else {
          endOfGapPoint.x = new Date(data[index].endTime.getTime() - 1);
          data = [...data.slice(0, index + 1), endOfGapPoint, ...data.slice(index + 1)];
        }
        isInGap = false;
      }
    }
  }
  // console.debug(
  //   "padded data: ",
  //   data.map((p) => {
  //     return { x: moment(p.x).format("HH:mm:ss"), y: p.y };
  //   })
  // );
  return data;
}
