<div class="single-value__wrapper card-item__drag-handle" [ngSwitch]="view.displayStrategy">
  <gauge
    *ngSwitchCase="strategyEnum.VerticalGauge"
    [viewConfig]="simpleView"
    [dataConnectorDescriptors]="dataConnectorDescriptors"
    [gaugeType]="view.displayStrategy"
    [dataStatus]="currentState.componentDataStatus"
    [disableAnimations]="disableChartAnimations"
  ></gauge>
  <gauge
    *ngSwitchCase="strategyEnum.HorizontalGauge"
    [viewConfig]="simpleView"
    [dataConnectorDescriptors]="dataConnectorDescriptors"
    [gaugeType]="view.displayStrategy"
    [dataStatus]="currentState.componentDataStatus"
    [disableAnimations]="disableChartAnimations"
  ></gauge>
  <gauge
    *ngSwitchCase="strategyEnum.BulletChart"
    [viewConfig]="simpleView"
    [dataConnectorDescriptors]="dataConnectorDescriptors"
    [gaugeType]="view.displayStrategy"
    [dataStatus]="currentState.componentDataStatus"
    [disableAnimations]="disableChartAnimations"
  ></gauge>
  <gauge
    *ngSwitchCase="strategyEnum.DialGauge"
    [viewConfig]="simpleView"
    [dataConnectorDescriptors]="dataConnectorDescriptors"
    [gaugeType]="view.displayStrategy"
    [dataStatus]="currentState.componentDataStatus"
    [disableAnimations]="disableChartAnimations"
  ></gauge>
  <gauge
    *ngSwitchCase="strategyEnum.MultiDialGauge"
    [viewConfig]="simpleView"
    [dataConnectorDescriptors]="dataConnectorDescriptors"
    [gaugeType]="view.displayStrategy"
    [dataStatus]="currentState.componentDataStatus"
    [disableAnimations]="disableChartAnimations"
  ></gauge>
  <gauge
    *ngSwitchCase="strategyEnum.SolidGauge"
    [viewConfig]="simpleView"
    [dataConnectorDescriptors]="dataConnectorDescriptors"
    [gaugeType]="view.displayStrategy"
    [dataStatus]="currentState.componentDataStatus"
    [disableAnimations]="disableChartAnimations"
  ></gauge>

  <textual-value
    *ngSwitchCase="strategyEnum.Value"
    [viewConfig]="simpleView"
    [value]="valueDC"
    [withTrend]="false"
    [dataStatus]="currentState.componentDataStatus"
    [hasLink]="hasLink"
    (openLink)="resolveLink()"
  ></textual-value>
  <textual-value
    *ngSwitchCase="strategyEnum.CircleValue"
    [viewConfig]="simpleView"
    [value]="valueDC"
    [withTrend]="false"
    [withCircle]="true"
    [dataStatus]="currentState.componentDataStatus"
    [hasLink]="hasLink"
    (openLink)="resolveLink()"
  ></textual-value>
  <textual-value
    *ngSwitchCase="strategyEnum.TrendValue"
    [viewConfig]="simpleView"
    [value]="valueDC"
    [withTrend]="true"
    [dataStatus]="currentState.componentDataStatus"
    [hasLink]="hasLink"
    (openLink)="resolveLink()"
  ></textual-value>

  <div *ngSwitchDefault>Unknown strategy {{ view.displayStrategy }}</div>
</div>
