import { DataTransferObject } from "../../core/models/data-transfer-object";
import { construct } from "../../core/services/construct.helper";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import {
  Configurable,
  ConfigurationCategory,
  EditableType,
  Serializable
} from "../../meta/decorators";
import { EditorType } from "../../meta/models/editor-type";
import { PropertyCategory } from "../../meta/models/property-category";
import { Dictionary } from "../../ts-utils";
import { BorderStyleDto } from "./border-style";

export interface CssSize {
  width: string;
  height: string;
  minHeight?: string;
}

const TYPE_NAME = "ComponentStyleDto";
@EditableType({ fullName: TYPE_NAME, title: "component-style-dto" })
export class ComponentStyleDto implements CssSize, DataTransferObject {
  typeName = TYPE_NAME;

  @Serializable(new BorderStyleDto())
  border!: BorderStyleDto;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Style)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Padding,
    editorType: EditorType.TextBox,
    advancedMode: true,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.PaddingTooltip,
    inlineAlignment: true
  })
  @Serializable("")
  padding!: string;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.Background,
    1
  )
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Color,
    editorType: EditorType.ColorPicker,
    advancedMode: true,
    inlineAlignment: true
  })
  @Serializable("")
  backgroundColor!: string;

  @Serializable("relative")
  position!: string;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.SizeAndPosition
  )
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Left,
    editorType: EditorType.TextBox,
    advancedMode: true,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.LeftTopTooltip,
    inlineAlignment: true
  })
  @Serializable("")
  left!: string;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.SizeAndPosition,
    3
  )
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Top,
    editorType: EditorType.TextBox,
    advancedMode: true,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.LeftTopTooltip,
    inlineAlignment: true
  })
  @Serializable("")
  top!: string;

  @Serializable("")
  order!: string;

  @Serializable("")
  zIndex!: string;

  backgroundRepeat: string = "no-repeat";
  backgroundSize: string = "100% 100%";

  width: string = "";
  height: string = "";

  marginRight: string = "0px";
  marginLeft: string = "0px";

  constructor(bgColor: string = "") {
    construct(
      this,
      {
        backgroundColor: bgColor
      },
      TYPE_NAME
    );
  }
}

export function convertToCssProperties(
  style: Dictionary<string | number> | ComponentStyleDto
): Dictionary<string | number> {
  const cssProps = Object.keys(style).reduce((acc, key) => {
    const newKey = camelToDashCase(key);
    const value = style[key];
    if (typeof value !== "undefined") {
      acc[newKey] = value;
    }
    return acc;
  }, {});
  return cssProps;
}

export function camelToDashCase(propertyName: string): string {
  return propertyName.replace(/([A-Z])/g, function ($1) {
    return "-" + $1.toLowerCase();
  });
}

export function convertToString(style: ComponentStyleDto): string {
  const cssProps = convertToCssProperties(style);
  const cssText = Object.keys(cssProps).reduce((acc, key) => {
    return acc + key + ":" + cssProps[key] + ";";
  }, "");
  return cssText;
}
