<button
  class="header-button"
  [ngClass]="{
    'header-button--selected': isSelected && !buttonConfig.hasDropdown,
    'header-dropdown-button--selected': isSelected && buttonConfig.hasDropdown,
    'no-border': hasProgressBar,
    'header-button--mobile-mode': verticallyOpened
  }"
  (click)="onClick($event)"
  title="{{ buttonConfig.title | translate }}"
  [disabled]="buttonConfig.isDisabled"
>
  <div class="header-button__container">
    <i
      [class]="'header-button__single-icon ' + icon"
      [ngClass]="{
        'abb-icon abb-icon--medium': buttonConfig.iconSource === 'Abb',
        'dashboard-icon dashboard-icon--medium': buttonConfig.iconSource === 'Dashboard',
        'header-button__icon': buttonConfig.hasDropdown
      }"
    ></i>
    <i
      *ngIf="buttonConfig.hasDropdown"
      class="abb-icon abb-icon--small"
      [ngClass]="isSelected && buttonConfig.hasDropdown ? 'Up' : 'Down'"
    ></i>
    <span class="header-button__label" *ngIf="showLabel">{{ buttonConfig.title | translate }}</span>
  </div>
  <mat-progress-bar
    *ngIf="hasProgressBar"
    class="header-button__progress-bar"
    mode="indeterminate"
  ></mat-progress-bar>
</button>
