import moment from "moment";
import { isDefined, isEmpty, isEmptyOrNotDefined, isNotDefined, Maybe } from "../../../ts-utils";
import { TimeRange } from "../../models";
import {
  DATE_AND_ZONE_OFFSET_SEPARATOR,
  HOUR_TO_MS,
  ISO_DATE_TIME_REGEX,
  ISO_TIME_SEPARATOR,
  MILLISECONDS_SEPARATOR,
  MIN_TO_MS,
  TIME_PORTIONS_SEPARATOR,
  UTC_POSITIVE_OFFSET_SEPARATOR,
  UTC_TIMEZONE_OFFSET
} from "../../models/time.constants";
import { isCorrectTimeRangeInterval } from "./filter-validation.helper";

export function createDateByIsoDateTimeStrings(isoDateTime: Maybe<string>): Maybe<Date> {
  if (isEmptyOrNotDefined(isoDateTime)) {
    return null;
  }
  if (!ISO_DATE_TIME_REGEX.test(isoDateTime)) {
    return null;
  }

  const localDate = createLocalDate(isoDateTime);
  return isNotDefined(localDate) || isNaN(localDate.getTime()) ? null : localDate;
}

function createLocalDate(isoDateTime: string): Maybe<Date> {
  const dateAndTimeIsoParts = isoDateTime.split(ISO_TIME_SEPARATOR);
  const isoDate = dateAndTimeIsoParts[0];
  const isoTime = dateAndTimeIsoParts[1];
  const dateParams = isoDate.split(DATE_AND_ZONE_OFFSET_SEPARATOR);

  if (yearStartsWithZero(dateParams[0])) {
    return null;
  }

  const year = Number(dateParams[0]);
  const month = Number(dateParams[1]) - 1;
  const day = Number(dateParams[2]);
  if (isDefined(isoTime)) {
    return generateDateTime(isoDateTime, isoTime, year, month, day);
  } else {
    return new Date(year, month, day);
  }
}

function yearStartsWithZero(yearString: string): boolean {
  return yearString.match(/^0+[1-9]/) ? true : false;
}

function generateDateTime(
  isoDateTime: string,
  isoTime: string,
  year: number,
  month: number,
  day: number
): Date {
  let splittedTimeParams = isoTime.split(UTC_TIMEZONE_OFFSET);
  const timeWithoutZoneOffset: string = splittedTimeParams[0];
  const timeZoneOffset = splittedTimeParams[1];
  splittedTimeParams = timeWithoutZoneOffset.split(MILLISECONDS_SEPARATOR);
  const milliseconds = Number(splittedTimeParams[1] ?? "");

  const timePortionsFromIsoTime = splittedTimeParams[0].split(TIME_PORTIONS_SEPARATOR);
  const hours = Number(timePortionsFromIsoTime[0]);
  const minutes = Number(timePortionsFromIsoTime[1]);
  const seconds = Number(timePortionsFromIsoTime[2] ?? "");
  const date = new Date(year, month, day, hours, minutes, seconds, milliseconds);
  return moment(date)
    .add(getTimezoneOffsetInMilliseconds(timeZoneOffset, isoDateTime), "ms")
    .toDate();
}

function getTimezoneOffsetInMilliseconds(
  utcTimeZoneOffset: Maybe<string>,
  isoDateTime: string
): number {
  let offset = 0;
  if (isDefined(utcTimeZoneOffset)) {
    offset = isEmpty(utcTimeZoneOffset)
      ? getLocalOffset()
      : calculateOffset(utcTimeZoneOffset, isoDateTime);
  }

  return offset;
}

function getLocalOffset(): number {
  return -1 * new Date().getTimezoneOffset() * MIN_TO_MS;
}

function calculateOffset(utcTimeZoneOffset: string, isoDateTime: string): number {
  const timePortionsFromOffset = utcTimeZoneOffset.split(TIME_PORTIONS_SEPARATOR);
  let offsetInMs =
    Number(timePortionsFromOffset[0]) * HOUR_TO_MS + Number(timePortionsFromOffset[1]) * MIN_TO_MS;
  if (!isoDateTime.includes(UTC_POSITIVE_OFFSET_SEPARATOR)) {
    offsetInMs = -1 * offsetInMs;
  }
  return offsetInMs;
}

export function createValidTimeRange(from: Maybe<Date>, to: Maybe<Date>): Maybe<TimeRange> {
  if (isNotDefined(from) || isNotDefined(to)) {
    return null;
  }
  return isCorrectTimeRangeInterval(from, to) ? new TimeRange(from, to) : null;
}
