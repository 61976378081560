import { CustomFilterDescriptorDto } from "../../core/models/filter/custom-filter-descriptor";
import { CustomFilterNumberValueDescriptor } from "../../core/models/filter/custom-filter-number-value-descriptor";
import { CustomFilterTextValueDescriptor } from "../../core/models/filter/custom-filter-text-value-descriptor";
import { CustomFilterValue } from "../../core/models/filter/filter-type-descriptor";
import { DeepPartial } from "../../ts-utils/models/deep-partial.type";

export const DEFAULT_TEXT_VALUE = "";
export const DEFAULT_NUMBER_VALUE = null;

export enum CustomFilterValueType {
  Text = "Text_Value",
  Number = "Number_Value"
}

export function getInitialValueOrDefault(
  filter: DeepPartial<CustomFilterDescriptorDto>
): CustomFilterValue {
  switch (filter.valueDescriptor?.typeName) {
    case CustomFilterValueType.Text:
      return (
        (filter.valueDescriptor as CustomFilterTextValueDescriptor).initialValue ??
        DEFAULT_TEXT_VALUE
      );
    case CustomFilterValueType.Number:
      return (
        (filter.valueDescriptor as CustomFilterNumberValueDescriptor).initialValue ??
        DEFAULT_NUMBER_VALUE
      );
    default:
      return DEFAULT_TEXT_VALUE;
  }
}
