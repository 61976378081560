import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { first } from "rxjs/operators";
import { Dictionary } from "../../../ts-utils/models/dictionary.type";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { ApiParameter } from "../../models/data-source/data-source-descriptors";
import { selectUrlParameters, selectUrls } from "./api-data-source-descriptor.selectors";

@Injectable()
export class ApiDataSourceDescriptorSelector {
  constructor(private store$: Store<any>) {}

  selectUrls(): Observable<Maybe<string[]>> {
    return this.store$.select(selectUrls);
  }

  selectUrlParameters(url: string): Observable<Maybe<Dictionary<ApiParameter>>> {
    return this.store$.select(selectUrlParameters(url));
  }

  getUrls(): Maybe<string[]> {
    let urls: Maybe<string[]> = null;
    this.selectUrls()
      .pipe(first())
      .subscribe((allUrl) => (urls = allUrl));
    return urls;
  }

  getParametersByUrl(url: string): Maybe<Dictionary<ApiParameter>> {
    let paramDict: Maybe<Dictionary<ApiParameter>> = null;
    this.selectUrlParameters(url)
      .pipe(first())
      .subscribe((parametersDict) => (paramDict = parametersDict));
    return paramDict;
  }
}
