<div class="tab-group-wrapper">
  <mat-tab-group
    animationDuration="0ms"
    [disableRipple]="true"
    (selectedTabChange)="selectedTabChange($event)"
    [mat-stretch-tabs]="false"
  >
    <mat-tab *ngFor="let tab of tabsWithDefinedContentContainer">
      <ng-template mat-tab-label>
        <span
          [style.color]="getTabTitleColor(tab.titleColor)"
          [style.font-weight]="tab.boldTitle ? 'bold' : 'normal'"
          [style.font-size.px]="tab.fontSize"
          >{{ tab.title }}</span
        >
      </ng-template>
      <ng-template matTabContent>
        <c-tab-content
          [id]="tab.contentContainerId"
          (timestampUpdated)="tabContentTimestampUpdated(tab.contentContainerId, $event)"
          [backgroundImage]="tab.backgroundImage"
        ></c-tab-content>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
