import { createAction, props } from "@ngrx/store";
import { DialogData } from "../../../models/dialogs/dialog-data.model";

export namespace SignalDialogActions {
  export const addSignalDialog = createAction(
    "[Dialog] Add Signal Dialog",
    props<{ chartYAxes: any[] }>()
  );

  export const addSignalDialogSuccess = createAction(
    "[Dialog] Add Signal Dialog Success",
    props<{ result: DialogData }>() // FIXME Rename param and class, both names are too generic
  );

  export const removeSignalDialog = createAction(
    "[Dialog] Remove Signal Dialog",
    props<{ chartYAxes: any[]; chartSeries: any[] }>()
  );

  export const removeSignalDialogSuccess = createAction(
    "[Dialog] Remove Signal Dialog Success",
    props<{ signalsToRemove: DialogData }>()
  );
}
