import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import {
  ConfigurableEnum,
  ConfigurationCategory,
  EditableType,
  Serializable
} from "../../meta/decorators";
import { PropertyCategory } from "../../meta/models/property-category";
import { Maybe } from "../../ts-utils";
import { getColumns } from "../helpers/generic-data-source-entities.helper";

export enum OrderDirection {
  Ascending = "Ascending",
  Descending = "Descending"
}

@EditableType({ fullName: "OrderBy", title: "order-by" })
export class OrderBy {
  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.DataSource)
  @ConfigurableEnum({
    enumSource: getColumns,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.OrderColumn
  })
  @Serializable()
  columnName: Maybe<string> = null;

  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.DataSource)
  @ConfigurableEnum({
    enumSource: OrderDirection,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.OrderDirection
  })
  @Serializable(OrderDirection.Descending)
  direction: OrderDirection = OrderDirection.Descending;
}
