import { Injectable } from "@angular/core";
import { ParamMap } from "@angular/router";
import { isEmpty } from "lodash";
import { ApiDataSourceDto } from "../../../data-connectivity";
import { ComponentCssSize, ComponentStateDto, ComponentStyleDto } from "../../../elements/models";
import { ReportConfiguration } from "../../../elements/models/report-configuration";
import { CutOffStrategy } from "../../../meta";
import { IDynamicReportService } from "../../interfaces/dynamic-report-service.interface";
import { FilterConfigurationDto, GeneralSettingsDto, ReportId } from "../../models";
import { DynamicReportTableConfig } from "../../models/dynamic-report/dynamic-report-table.config";
import { DYNAMIC_REPORT_TABLE } from "../../models/dynamic-report/dynamic-report.constants";
import { ExtendedBaseViewConfigDto } from "../../models/extended-report-configuration";
import { RequiredProperty } from "../../models/required-property";

@Injectable({
  providedIn: "root"
})
export class DynamicReportTableService implements IDynamicReportService {
  reportName: string = DYNAMIC_REPORT_TABLE;

  private TIMESERIES_TABLE: string = "time-series-table";
  /**
   * Configures dynamic report based on passed parameters in query.
   * @param params All parameters provided in URL.
   * @returns Modified report if all parameters are provided otherwise default template.
   */
  generateReport(params: ParamMap): ReportConfiguration {
    const apiUrl = params.get("externalApiSourceUrl") || "";
    const decodedApiUrl = decodeURIComponent(apiUrl);

    const config: DynamicReportTableConfig = {
      externalApiSourceUrl: decodedApiUrl
    };
    const isValid = Object.keys(config).every((v: string) => !isEmpty(v));
    return isValid ? this.modifyReportTemplate(config) : this.getQueryReportTemplate();
  }

  /**
   * Modifies report template according to provided configuration
   * @param config Config object `IQueryReportConfig`
   * @returns Modified report
   */
  private modifyReportTemplate(config: DynamicReportTableConfig): ReportConfiguration {
    const template = this.getQueryReportTemplate() as RequiredProperty<
      ReportConfiguration,
      "content"
    >;
    const url = new URL(config.externalApiSourceUrl);
    const baseURL = `${url.origin}${url.pathname}`;
    const urlSearchParams = new URLSearchParams(url.search);

    const timeSeries = template.content.componentStates.entities[this.TIMESERIES_TABLE];
    if (timeSeries) {
      const dataSource = timeSeries.dataConnectorQuery as ApiDataSourceDto;
      dataSource.url = baseURL;
      urlSearchParams.forEach((value, key) => {
        dataSource.params.push({ key: key, value: value });
      });
    }

    return template as ReportConfiguration;
  }

  public getQueryReportTemplate(): ReportConfiguration {
    return {
      id: "dynamictest" as ReportId,
      content: {
        componentStates: {
          ids: ["Root", "basic-card-1", this.TIMESERIES_TABLE],
          entities: {
            Root: {
              id: "Root",
              view: {
                title: "",
                size: {
                  columns: -1,
                  rows: -1,
                  gapSize: 10
                } as Partial<ComponentCssSize>
              },
              type: "PageComponent",
              childrenIds: ["basic-card-1"],
              filterId: "Global"
            } as ComponentStateDto,
            "basic-card-1": {
              id: "basic-card-1",
              view: {
                title: "",
                size: {
                  columns: -1,
                  rows: -1,
                  gapSize: 10
                },
                expandedSize: {
                  columns: -1,
                  rows: -1,
                  gapSize: 10
                },
                showHeader: false,
                css: {
                  order: "0",
                  zIndex: "1"
                }
              } as unknown as Partial<ExtendedBaseViewConfigDto>,
              type: "BasicCardComponent",
              childrenIds: [this.TIMESERIES_TABLE]
            } as ComponentStateDto,
            "time-series-table": {
              id: this.TIMESERIES_TABLE,
              view: {
                css: {
                  position: "absolute",
                  left: "0px",
                  top: "0px",
                  order: "0",
                  zIndex: "1"
                } as ComponentStyleDto,
                size: {
                  width: "100.00%",
                  height: "100.0%"
                } as Partial<ComponentCssSize>,
                displayStrategy: "Table",
                yAxes: [
                  {
                    id: "6f6e8444-a99c-48d0-94d3-2059d70e4642"
                  }
                ],
                cutOffStrategy: CutOffStrategy.Start
              },
              type: "TimeSeriesComponent",
              dataConnectorQuery: {
                typeName: "ApiDataSourceDto",
                params: [
                  {
                    key: "verb",
                    value: "get"
                  }
                ]
              } as ApiDataSourceDto
            } as unknown as ComponentStateDto
          }
        },
        dataConnectors: {
          ids: [],
          entities: {}
        },
        filters: {
          ids: ["Global"],
          entities: {
            Global: {
              id: "Global"
            } as FilterConfigurationDto
          }
        },
        dataConnectorViews: {
          ids: [],
          entities: {}
        },
        componentsCounter: 2,
        generalSettings: {} as GeneralSettingsDto,
        version: [4, 0, 5]
      }
    };
  }
}
