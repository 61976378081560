import { createAction, props } from "@ngrx/store";

export namespace ErrorHandlingActions {
  export const handleError = createAction(
    "[Global] Handle Error",
    props<{ messageToDisplay: string; error?: any; autoClose: boolean }>()
  );

  export const handleWarning = createAction(
    "[Global] Handle Warning",
    props<{ messageToDisplay: string }>()
  );

  export const displayInfo = createAction(
    "[Global] Display Info",
    props<{ messageToDisplay: string }>()
  );

  export const displayInstruction = createAction(
    "[Global] Display Instruction",
    props<{ instruction: string }>()
  );

  export const closeSnackBar = createAction("[Global] Close SnackBar;");
}
