import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { concatMap, map } from "rxjs/operators";
import { REPORT_FILTER_ID, RUNTIME_FILTER_ID } from "../../../core/helpers/filter/filter-id.helper";
import { isEmptyFilterConfig } from "../../../core/helpers/filter/filter-validation.helper";
import { FilterConfigurationDto } from "../../../core/models/filter/filter-configuration";
import { FilterFactory } from "../../../core/services/filter/filter-factory.service";
import { IFilterSelector } from "../../../core/services/filter/i-filter.selector";
import { DataConnectorDto } from "../../../data-connectivity/models/data-connector";
import { EntityId } from "../../../meta";
import { ParentEntityInfo } from "../../../meta/models/parent-entity-info";
import { Maybe, isDefined, isNotDefined } from "../../../ts-utils";
import { HISTORY_VIEW_CONSTANTS } from "../../components/history-view/history-view.constants";
import { getFullRequestActions } from "../../helpers/full-range-request-actions.helper";
import {
  getAllRequestParams,
  getAllRequestParamsByFilters
} from "../../helpers/request-params-creation.helper";
import { ComponentStateDto } from "../../models/component-state";
import { DataRequestParams } from "../../models/data-request-params";
import {
  COMPONENT_STATE_DTO,
  COMPONENT_STATE_VIEW_MODEL,
  DATA_CONNECTOR_VIEW_MODEL
} from "../../models/entity-type.constants";
import { ComponentStateSelector } from "../../services/entity-selectors/component-state.selector";
import { DataConnectorSelector } from "../../services/entity-selectors/data-connector.selector";
import { ComponentStateActions } from "../component-state/component-state.actions";
import { DataConnectorActions } from "../data-connector";
import { FilterActions } from "./filter.actions";

@Injectable()
export class FilterEffects {
  constructor(
    private actions$: Actions,
    private filterFactory: FilterFactory,
    private filterSelector: IFilterSelector,
    private componentStateSelector: ComponentStateSelector,
    private dataConnectorSelector: DataConnectorSelector
  ) {}

  updateMany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.updateMany),
      map(({ filterUpdates }) => filterUpdates.map((filterUpdate) => filterUpdate.id)),
      concatMap((filterIds: EntityId[]) => [FilterActions.queryFullDataForFilters({ filterIds })])
    )
  );

  updateManyWithTimeOffset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.updateManyWithTimeOffset),
      map(() => this.filterSelector.getAllAsArray()),
      concatMap((filters: FilterConfigurationDto[]) => {
        const requestParams: DataRequestParams[] = getAllRequestParamsByFilters(
          filters,
          this.filterFactory,
          this.componentStateSelector,
          this.dataConnectorSelector
        );
        return getFullRequestActions(requestParams);
      })
    )
  );

  upsertOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.upsertOne),
      concatMap(({ filterUpdate, parentEntityInfo }) => {
        const filterId = filterUpdate.id;
        const modifiedFilter = this.filterSelector.getById(filterId);
        if (isEmptyFilterConfig(modifiedFilter)) {
          return [
            FilterActions.deleteOne({
              filterId: filterId.toString(),
              parentEntityInfo
            })
          ];
        } else {
          if (isNewFilterCreated(modifiedFilter)) {
            return [...this.onCreateNewFilter(filterId, parentEntityInfo)];
          } else {
            return [FilterActions.queryFullDataForFilters({ filterIds: [filterId] })];
          }
        }
      })
    )
  );

  reactToSelectTimeRange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.reactToSelectTimeRange),
      concatMap(({ filterUpdate, componentId }) => {
        const filterId = filterUpdate.id;
        const modifiedFilter = this.filterSelector.getById(filterId);
        if (isEmptyFilterConfig(modifiedFilter)) {
          return [
            FilterActions.deleteOne({
              filterId: filterId.toString(),
              parentEntityInfo: {
                parentId: componentId,
                entityType: COMPONENT_STATE_DTO
              }
            })
          ];
        } else {
          const filterUpdates: Update<FilterConfigurationDto>[] = this.updateConnectorsFilters(
            componentId,
            filterUpdate.id
          );
          return [FilterActions.updateMany({ filterUpdates })];
        }
      })
    )
  );

  private onCreateNewFilter(
    newFilterId: EntityId,
    parentEntityInfo: Maybe<ParentEntityInfo>
  ): Action[] {
    const actions = [];
    actions.push(
      FilterActions.setSourceFilter({
        filterId: newFilterId,
        sourceFilterId: this.resolveSourceFilter(parentEntityInfo)
      })
    );
    if (isDefined(parentEntityInfo)) {
      if (parentEntityInfo?.entityType === COMPONENT_STATE_VIEW_MODEL) {
        actions.push(...this.onCreateComponentFilter(newFilterId, parentEntityInfo));
      } else if (parentEntityInfo?.entityType === DATA_CONNECTOR_VIEW_MODEL) {
        actions.push(
          DataConnectorActions.updateOne({
            connectorUpdate: {
              id: parentEntityInfo.parentId,
              changes: {
                filterId: newFilterId
              }
            } as Update<DataConnectorDto>
          })
        );
      }
    }
    return actions;
  }

  private onCreateComponentFilter(
    newFilter: EntityId,
    parentEntityInfo: ParentEntityInfo
  ): Action[] {
    const actions = [];
    actions.push(
      ComponentStateActions.updateOne({
        componentUpdate: {
          id: parentEntityInfo.parentId,
          changes: {
            filterId: newFilter
          }
        } as Update<ComponentStateDto>
      })
    );
    const filterUpdates = this.updateConnectorsFilters(parentEntityInfo.parentId, newFilter);
    actions.push(FilterActions.updateMany({ filterUpdates }));
    return actions;
  }

  private updateConnectorsFilters(
    componentId: EntityId,
    newFilterId: EntityId
  ): Update<FilterConfigurationDto>[] {
    const componentConnectors = this.dataConnectorSelector.getForComponent(componentId);
    return componentConnectors.reduce(
      (acc: Update<FilterConfigurationDto>[], conn: DataConnectorDto) => {
        if (isDefined(conn.filterId)) {
          const filterUpdate = {
            id: conn.filterId,
            changes: {
              sourceFilterId: newFilterId
            }
          } as Update<FilterConfigurationDto>;
          acc.push(filterUpdate);
          return acc;
        }
        return acc;
      },
      [
        {
          id: newFilterId,
          changes: {}
        } as Update<FilterConfigurationDto>
      ]
    );
  }

  resolveSourceFilter(parentEntityInfo: Maybe<ParentEntityInfo>): EntityId {
    if (parentEntityInfo?.entityType === COMPONENT_STATE_VIEW_MODEL) {
      return RUNTIME_FILTER_ID;
    } else if (parentEntityInfo?.entityType === DATA_CONNECTOR_VIEW_MODEL) {
      const componentWithConnector = this.componentStateSelector.getComponentByConnectorId(
        parentEntityInfo.parentId
      );
      return componentWithConnector?.filterId ?? RUNTIME_FILTER_ID;
    }
    return "";
  }

  updateGlobalFilter$ = createEffect(() =>
    this.actions$.pipe(
      // TODO should check the case if the runtime custom filters are the same e.g. if we add new custom filter descriptor, it will query full data for filters, but it should not (just if the initial value is set/unset)
      ofType(FilterActions.updateGlobalFilter, FilterActions.resolveUpdatedCustomFilters),
      concatMap(() => {
        const reportFilter: FilterConfigurationDto = this.filterSelector.getById(REPORT_FILTER_ID);
        const runtimeFilter: FilterConfigurationDto =
          this.filterSelector.getById(RUNTIME_FILTER_ID);
        return [
          FilterActions.queryFullDataForFilters({ filterIds: [runtimeFilter.id, reportFilter.id] })
        ];
      })
    )
  );

  queryFullDataForFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.queryFullDataForFilters),
      concatMap(({ filterIds }) => {
        const filters = this.filterSelector.getManyWithDependentFilters(filterIds);
        const requestParams: DataRequestParams[] = getAllRequestParamsByFilters(
          filters,
          this.filterFactory,
          this.componentStateSelector,
          this.dataConnectorSelector
        );
        return getFullRequestActions(requestParams);
      })
    )
  );

  deleteOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.deleteOne),
      concatMap(({ filterId, parentEntityInfo }) => {
        if (isNotDefined(parentEntityInfo)) {
          return [];
        }
        const requestParams: DataRequestParams[] = this.onFilterDelete(parentEntityInfo);
        return getFullRequestActions(requestParams);
      })
    )
  );

  private onFilterDelete(parentEntityInfo: ParentEntityInfo): DataRequestParams[] {
    if (parentEntityInfo.entityType === COMPONENT_STATE_VIEW_MODEL) {
      const componentState = this.componentStateSelector.getById(parentEntityInfo.parentId);
      if (isDefined(componentState)) {
        const connectors = this.dataConnectorSelector.getForComponent(componentState.id);
        return getAllRequestParams(
          [componentState],
          connectors,
          this.componentStateSelector,
          this.filterFactory
        );
      }
    } else if ((parentEntityInfo.entityType as string) === DATA_CONNECTOR_VIEW_MODEL) {
      const dataConnector = this.dataConnectorSelector.getById(parentEntityInfo.parentId);
      if (isDefined(dataConnector)) {
        return getAllRequestParams(
          [],
          [dataConnector],
          this.componentStateSelector,
          this.filterFactory
        );
      }
    }
    return [];
  }
}

function isNewFilterCreated(filter: FilterConfigurationDto): boolean {
  return (
    filter.id !== REPORT_FILTER_ID &&
    filter.id !== RUNTIME_FILTER_ID &&
    filter.id !== HISTORY_VIEW_CONSTANTS.FILTER_ID &&
    isNotDefined(filter.sourceFilterId)
  );
}
