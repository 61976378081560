import { construct } from "../../../core";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import {
  Configurable,
  EditableType,
  NotSerializable,
  Serializable
} from "../../../meta/decorators";
import { EditorType } from "../../../meta/models/editor-type";
import { DeepPartial } from "../../../ts-utils";
import {
  ComponentCssSize,
  FULL_HEIGHT_CSS_SIZE,
  FULL_WIDTH_CSS_SIZE,
  PAGE_VIEW_CONFIG
} from "../../models";
import { BaseViewConfigDto } from "../../models/base-view-config";

// @dynamic
@EditableType({ fullName: PAGE_VIEW_CONFIG })
export class PageViewConfig extends BaseViewConfigDto {
  typeName = PAGE_VIEW_CONFIG;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ShowBreadcrumb,
    editorType: EditorType.CheckBox
  })
  @Serializable(true)
  showBreadcrumb!: boolean;

  @NotSerializable()
  snapToGrid: boolean = true;

  hideBackground: boolean = false;

  constructor(viewConfigDto: DeepPartial<PageViewConfig> = {}) {
    super();
    const defaultOverrides: Partial<PageViewConfig> = {
      selectable: false,
      hasRuntimeData: false,
      size: getDefaultSize()
    };
    construct(this, viewConfigDto, PAGE_VIEW_CONFIG, defaultOverrides);
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize(FULL_WIDTH_CSS_SIZE, FULL_HEIGHT_CSS_SIZE);
}
