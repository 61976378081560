import { Injectable } from "@angular/core";
import { LocalizationService } from "ui-core";

@Injectable({
  providedIn: "root"
})
export class RdsLocalizationService extends LocalizationService {
  fetchSupportedLanguages(): string[] {
    return ["en"];
  }
}
