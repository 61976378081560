<div
  class="editor-item__body"
  [style.flex-direction]="propertyConfig.descriptor.inlineAlignment ? 'row' : 'column'"
>
  <div
    class="editor-item__label"
    [ngClass]="shouldPlaceLabelAtTop() ? 'editor-item__label--top' : 'editor-item__label--middle'"
    *ngIf="propertyConfig.descriptor.showLabel"
  >
    {{ propertyConfig.descriptor.displayName | translate }}
  </div>
  <ng-template dynamic-template></ng-template>
</div>
<div *ngIf="!validationResult" class="editor-item__message">
  Value is invalid, it will not be saved.
</div>
