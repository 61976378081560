import { Action, createReducer, on } from "@ngrx/store";
import { Version } from "../../../upgrade/models/version";
import { CommonActions } from "../common/common.actions";

export function versionReducer(state: number[], action: Action) {
  return _versionReducer(state, action);
}

const _versionReducer = createReducer(
  null
  // on(CommonActions.upsertEntities, (state, { reportEntities }) =>
  //   reportEntities.
  // )
);
