export function createChangeObject(originalPath: string[], changedValue: any): any {
  const changeObject = {};

  const localPath = [...originalPath];
  const pathFragment: string = localPath.shift();

  if (localPath.length > 0) {
    changeObject[pathFragment] = createChangeObject(localPath, changedValue);
  } else {
    changeObject[pathFragment] = changedValue;
  }

  return changeObject;
}
