<div
  #contentBody
  class="tab-content__body"
  [ngStyle]="bodyStyle"
  [widgetsContainer]="isEditable"
  [containerId]="id"
  [isSelectable]="view.selectable"
>
  <ng-template dynamic-component-container></ng-template>
</div>
