import { Injectable } from "@angular/core";
import { isWidget } from "../../elements/models/component-type.helper";
import { isDefined, isNumber } from "../../ts-utils/helpers/predicates.helper";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class UpgradeMinMaxValues implements UpgradeStep {
  name = "UpgradeMinMaxValues";
  fromVersion = new Version(4, 0, 12);

  perform(oldConfig: any): UpgradeStepResult {
    const widgets = getWidgets(oldConfig);
    let changed = false;
    widgets.forEach((widget) => {
      const view = widget["view"];
      const { min, max, xAxisMin, xAxisMax, xMin, xMax, yMin, yMax, maxChartItems, yAxes } = view;
      if (isDefined(min) && isNumber(min)) {
        view["min"] = min.toString();
        changed = true;
      }
      if (isDefined(max) && isNumber(max)) {
        view["max"] = max.toString();
        changed = true;
      }

      if (isDefined(xAxisMin) && isNumber(xAxisMin)) {
        view["xAxisMin"] = xAxisMin.toString();
        changed = true;
      }
      if (isDefined(xAxisMax) && isNumber(xAxisMax)) {
        view["xAxisMax"] = xAxisMax.toString();
        changed = true;
      }

      if (isDefined(xMin) && isNumber(xMin)) {
        view["xMin"] = xMin.toString();
        changed = true;
      }
      if (isDefined(xMax) && isNumber(xMax)) {
        view["xMax"] = xMax.toString();
        changed = true;
      }
      if (isDefined(yMin) && isNumber(yMin)) {
        view["yMin"] = yMin.toString();
        changed = true;
      }
      if (isDefined(yMax) && isNumber(yMax)) {
        view["yMax"] = yMax.toString();
        changed = true;
      }

      if (isDefined(maxChartItems) && isNumber(maxChartItems)) {
        view["maxChartItems"] = maxChartItems.toString();
        changed = true;
      }

      changed = this.upgradeYAxes(yAxes, view) || changed;
    });
    return {
      result: oldConfig,
      modified: changed,
      warning: null
    };
  }

  private upgradeYAxes(yAxes, view): boolean {
    let changed = false;
    if (isDefined(yAxes)) {
      yAxes.forEach((axis, index) => {
        const { min: axisMin, max: axisMax } = axis;
        if (isDefined(axisMin) && isNumber(axisMin)) {
          view["yAxes"][index]["min"] = axisMin.toString();
          changed = true;
        }
        if (isDefined(axisMax) && isNumber(axisMax)) {
          view["yAxes"][index]["max"] = axisMax.toString();
          changed = true;
        }
      });
    }
    return changed;
  }
}

function getWidgets(oldConfig: any): any[] {
  const componentStates: any = oldConfig["componentStates"]["entities"];
  return Object.values(componentStates).filter((componentState) =>
    isWidget(componentState["type"])
  );
}
