import { TranslateLoader } from "@ngx-translate/core";
import { merge as _merge } from "lodash";
import { Observable, of } from "rxjs";

export class CoreTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return of(null); // when building for release, this absolute path breaks the build for project using core.
    // return from(import(`projects/ui-core/src/assets/ui-core/i18n/${lang}.json`));
  }

  protected mergeTranslations(coreTranslations: Object, adapterTranslations: Object) {
    const prunedCoreTranslations = this.removeEmptyTranslations(coreTranslations);
    const prunedAdapterTranslations = this.removeEmptyTranslations(adapterTranslations);
    const mergedTranslations = _merge(prunedCoreTranslations, prunedAdapterTranslations);
    return mergedTranslations;
  }

  protected removeEmptyTranslations(translations: Object): Object {
    // translations can be object with strings and nested objects with strings
    return Object.entries(translations).reduce((prunedTranslations, [key, value]) => {
      if (typeof value === "string" && value !== "") {
        prunedTranslations[key] = value;
      } else if (value instanceof Object) {
        prunedTranslations[key] = this.removeEmptyTranslations(value);
      }
      return prunedTranslations;
    }, {});
  }
}
