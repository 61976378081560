import { ButtonConfig } from "../../shared/models/button/button-config";
import { ButtonStyle } from "../../shared/models/button/button-style";
import { FunctionWithParams } from "../../shared/models/function-with-params";
import { CardButtonParams } from "../components/basic-card/card-button-params";
import { BasicCardViewConfig } from "../components/basic-card/view-config";
import { BASIC_CARD } from "../models/element-type.constants";
import { ExportFormat } from "../models/export-format";
import { HistoryViewDialogActions } from "../store/dialogs/actions/history-view-dialog.actions";
import { exportData, ExportParams } from "./export-data.helper";
import { getLinkButton } from "./get-dynamic-buttons.helper";

/**
 * Returns array of params for header buttons of card in regular (left-to-right) order.
 * @param cardComponent BasicCardComponent instance.
 */
export function getCardRuntimeButtons(cardComponent: CardButtonParams): ButtonConfig[] {
  if (cardComponent.currentState.type !== BASIC_CARD) {
    throw new Error("Can only get card buttons for card components.");
  }
  return [
    getLinkButton(cardComponent, cardComponent.localizer.buttons.Link),
    getExportButton(cardComponent, cardComponent.localizer.buttons.Export),
    getExpandButton(cardComponent, cardComponent.localizer.buttons.Expand)
  ];
}

function getExportButton(cardComponent: CardButtonParams, titleKey: string): ButtonConfig {
  return new ButtonConfig({
    shouldShow: () => (cardComponent.currentState.view as BasicCardViewConfig).exportEnabled,
    style: new ButtonStyle({
      title: titleKey,
      iconClass: "abb-icon",
      icon: "Export"
    }),
    dropdownOptions: getExportOptions(cardComponent)
  });
}

function getExportOptions(cardComponent: CardButtonParams): ButtonConfig[] {
  return [
    new ButtonConfig({
      style: new ButtonStyle({ title: "CSV" }),
      clickFunction: new FunctionWithParams<ExportParams>(exportData, {
        cardComponent: cardComponent,
        outputFormat: ExportFormat.CSV
      })
    }),
    new ButtonConfig({
      style: new ButtonStyle({ title: "JSON" }),
      clickFunction: new FunctionWithParams<ExportParams>(exportData, {
        cardComponent: cardComponent,
        outputFormat: ExportFormat.JSON
      })
    })
  ];
}

function getExpandButton(cardComponent: CardButtonParams, titleKey: string): ButtonConfig {
  return new ButtonConfig({
    clickFunction: new FunctionWithParams<CardButtonParams>(openHistoryViewDialog, cardComponent),
    shouldShow: () => (cardComponent.currentState.view as BasicCardViewConfig).historyViewEnabled,
    style: new ButtonStyle({
      title: titleKey,
      iconClass: "abb-icon",
      icon: "Fullscreen"
    })
  });
}

function openHistoryViewDialog(cardComponent: CardButtonParams): void {
  cardComponent.dispatch(
    HistoryViewDialogActions.openHistoryViewDialog({ component: cardComponent.currentState })
  );
}
