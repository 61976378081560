import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as HighCharts from "highcharts";
import HC_exporting from "highcharts/modules/exporting";
import { Nominal } from "projects/ui-core/src/lib/ts-utils/models/nominal.type";
import { Observable, Subject } from "rxjs";
import { WebServicesConfiguration } from "ui-core";
import { baseChartOptions, countryCodes } from "../../components/fleet-view/fleet-view.config";
import { IGroupMarker, IPlantData, IPlantMarker } from "../../models";
import { ApiResponse } from "../../models/api/api-response";
import { Customer } from "../../models/api/auth-customer.interface";
import { IClientData } from "../../models/api/client-data.interface";
import { Continent, Project } from "../../models/api/hierarchy";
import { MotorStatus } from "../../models/enums/motor-status.enum";
import { IProjectHierarchyWithStatus } from "../../models/motor-response.interface";
import { IMotorStatus } from "../../models/motor-status.interface";
import { MotorMarker } from "../../models/side-nav/motor-marker";
import { MotorModel } from "../../models/side-nav/motor.model";

HC_exporting(HighCharts);

@Injectable()
export class FleetViewDataService {
  private countriesGroups: Map<string, string[]> = new Map<string, string[]>();
  private equipmentStatusUrl = "ServingFunction/Status";
  private urlParameter = "details";
  motorsResponse?: IProjectHierarchyWithStatus;

  customersDataMap = new Map<string, Customer>();

  isEditor = new Subject<boolean>();

  unsubscribeHierarchy$ = new Subject();
  continentsCollection$ = new Subject<Continent[]>();

  constructor(private httpService: HttpClient, private apiConfig: WebServicesConfiguration) {}

  colorStatusMap: Map<number, string> = new Map<number, string>([
    [0, "circle-green"],
    [1, "circle-yellow"],
    [2, "circle-violet"],
    [3, "circle-red"],
    [4, "circle-grey"]
  ]);

  motorsMarkersCollection: Map<string, Customer> = new Map<string, Customer>();
  motors: MotorModel[] = [];
  motorsObservable$?: Observable<MotorModel>;
  motorStatusCollection: ApiResponse[] = [];
  statusLabels = [
    MotorStatus.Running,
    MotorStatus.RunningAlarms,
    MotorStatus.StoppedNormal,
    MotorStatus.Trip,
    MotorStatus.NoDataConnection
  ];

  getClientData(statusRequestBody: IMotorStatus | undefined): Observable<ApiResponse[]> {
    if (statusRequestBody) {
      return this.httpService.post<ApiResponse[]>(
        `${this.apiConfig.logDataServiceUrl}/${this.equipmentStatusUrl}`,
        statusRequestBody
      );
    } else {
      throw new Error("Status Request Body is empty");
    }
  }

  getReportTree(client: string): Observable<IClientData> {
    return this.httpService.get<IClientData>(
      `${this.apiConfig.reportsUrl}/${client}/reports/${this.urlParameter}`
    );
  }

  private calculateDistance(lat1: number, lng1: number, lat2: number, lng2: number): number {
    const lat = [lat1, lat2];
    const lng = [lng1, lng2];
    const earthEllipsoidLength = 6_378_137;
    const dLat = ((lat[1] - lat[0]) * Math.PI) / 180;
    const dLng = ((lng[1] - lng[0]) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat[0] * Math.PI) / 180) *
        Math.cos((lat[1] * Math.PI) / 180) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = earthEllipsoidLength * c;
    return Math.round(d) / 1000;
  }

  createNewMarker(
    motor: MotorModel,
    additionalInfo: Customer | undefined,
    description: string
  ): MotorMarker {
    const newMarker = new MotorMarker();
    newMarker.motors = [];
    newMarker.motorsByCustomer = new Map<string, MotorModel[]>();

    newMarker.description = description;
    newMarker.latitude = motor.latitude;
    newMarker.longitude = motor.longitude;
    newMarker.totalMotorsCount = 1;
    newMarker.motors.push(motor);
    newMarker.motorsByCustomer.set(motor.projectName, [motor]);
    if (additionalInfo && motor.projectName === additionalInfo.OrgShortName) {
      newMarker.productionArea = additionalInfo.ProductionArea;
      newMarker.systemOverviewReport = additionalInfo.SystemOverviewReport as Nominal<
        string | number,
        "ReportId"
      >;
      newMarker.projectOverviewReport = additionalInfo.ProjectOverviewReport;
    }

    return newMarker;
  }

  private createPlantData(motor: MotorModel): IPlantData {
    return {
      countryCode: countryCodes.find((cd) => cd.name === motor.country)?.code ?? "",
      projectShortName: motor.projectShortName,
      projectName: motor.projectName,
      productionArea: motor.productionArea,
      systemOverviewReport: motor.defaultSystemReport as Nominal<string | number, "ReportId">
    };
  }

  groupMotors(motors: MotorModel[]): IGroupMarker[] {
    const groupedByProject = new Map<string, MotorModel[]>();

    motors.forEach((motor) => {
      if (!groupedByProject.has(motor.projectShortName)) {
        groupedByProject.set(motor.projectShortName, []);
      }
      groupedByProject.get(motor.projectShortName)?.push(motor);
    });

    const groupMarkers: IGroupMarker[] = [];
    const processedProjects = new Set<string>();

    groupedByProject.forEach((motors, projectShortName) => {
      if (processedProjects.has(projectShortName)) {
        return;
      }

      const plantMarkers: IPlantMarker[] = [];
      const currentProjectMotors = groupedByProject.get(projectShortName);

      if (currentProjectMotors) {
        plantMarkers.push({
          plantData: this.createPlantData(currentProjectMotors[0]),
          motors: currentProjectMotors
        });
        processedProjects.add(projectShortName);

        groupedByProject.forEach((otherMotors, otherProjectShortName) => {
          if (
            projectShortName !== otherProjectShortName &&
            !processedProjects.has(otherProjectShortName)
          ) {
            const areCloseEnough = currentProjectMotors.some((motor) =>
              otherMotors.some(
                (otherMotor) =>
                  this.calculateDistance(
                    motor.latitude,
                    motor.longitude,
                    otherMotor.latitude,
                    otherMotor.longitude
                  ) <= 100
              )
            );

            if (areCloseEnough) {
              plantMarkers.push({
                plantData: this.createPlantData(otherMotors[0]),
                motors: otherMotors
              });
              processedProjects.add(otherProjectShortName);
            }
          }
        });
      }

      if (motors.length > 0) {
        const firstMotor = motors[0];
        groupMarkers.push({
          plantsMarker: plantMarkers,
          markerOptions: {
            position: new google.maps.LatLng({
              lat: firstMotor.latitude,
              lng: firstMotor.longitude
            })
          }
        });
      }
    });

    groupMarkers.forEach((gm) => this.setMarkerIcon(gm));

    return groupMarkers;
  }

  /**
   * Converts svg format to image type
   * @param svg Full svg path as string e.g. <svg>...</svg>
   * @returns Formatted string that represents image url
   */
  private svgToImg(svg: string): string {
    const svgPath = btoa(svg);
    const headerPath = "data:image/svg+xml;base64,";
    const imagePath = headerPath + svgPath;

    return imagePath;
  }

  private setMarkerIcon(groupMarker: IGroupMarker): void {
    const series = this.getStatusSeries(groupMarker.plantsMarker.flatMap((p) => p.motors));
    const { chart } = baseChartOptions;

    const markerChart = HighCharts.chart({
      ...baseChartOptions,
      chart: {
        ...chart,
        renderTo: "pie-container",
        width: 45,
        height: 45
      },
      series: series
    });

    groupMarker.markerOptions.icon = this.svgToImg(markerChart.getSVG());
    groupMarker.markerOptions.label = {
      text: groupMarker.plantsMarker.flatMap((p) => p.motors).length.toString()
    };
  }

  private countryInPredefinedRegion(motor: MotorModel): null | string {
    let region = null;
    this.countriesGroups.forEach((value: string[], key: string) => {
      if (motor.countries.find((country) => value.includes(country.name))) {
        region = key;
      }
    });
    return region;
  }

  flatMotors(continents: Continent[], response?: ApiResponse[]): MotorModel[] {
    const flatedMotors: MotorModel[] = [];
    continents.forEach((motor) => {
      const motorModel: MotorModel = {} as MotorModel;
      motorModel.continent = motor.name;

      motor.countries.forEach((country) => {
        motorModel.country = country.name;

        country.projects.forEach((project) => {
          const status = 0;
          this.mapProjectData(motorModel, project);

          project.systems.forEach((system) => {
            if (response) {
              this.mapMotorModelData(response, motorModel, status, system);
            }
            motorModel.systemName = `${system.name}`;
            motorModel.systemDescription = system.description;
            flatedMotors.push({ ...motorModel });
          });
        });
      });
    });
    return flatedMotors;
  }

  mapProjectData(motorModel: MotorModel, project: Project): void {
    motorModel.projectName = project.orgFullName;
    motorModel.projectShortName = project.name;
    motorModel.tzName = project.tzName;
    motorModel.latitude = project.latitude;
    motorModel.longitude = project.longitude;
    motorModel.altitude = project.altitude;
    motorModel.orYear = project.orYear;
    motorModel.icYear = project.icYear;
    motorModel.mineral = project.mineral;
    motorModel.millSupplier = project.millSupplier;
    motorModel.productionArea = project.productionArea;
    motorModel.defaultSystemReport = project.defaultReport;
  }

  mapMotorModelData(
    response: ApiResponse[],
    motorModel: MotorModel,
    globalStatus: number,
    system: MotorModel
  ): void {
    const motorStatus = response.find(
      (item: ApiResponse) => item.ClientId === `${motorModel.projectShortName}.${system.name}`
    );

    if (!motorStatus || motorStatus.DataPoints.length === 0) {
      motorModel.systemStatus = this.getStatusDescription(globalStatus);
      motorModel.markerColor = `${this.getColorByStatus(globalStatus)}`;
      return;
    }

    motorModel.rootPath = system.rootPath;
    motorModel.defaultReport = system.defaultReport;
    motorModel.projectStatus = motorStatus.DataPoints[0].value;
    motorModel.lastUpdateTime = motorStatus.DataPoints[0].timeStamp;
    motorModel.systemStatus = this.getStatusDescription(motorModel.projectStatus);
    motorModel.markerColor = `${this.getColorByStatus(motorModel.projectStatus)}`;
  }

  getColorByStatus(status: number): string | undefined {
    return this.colorStatusMap.get(status);
  }

  getStatuses(): number[] {
    return Array.from(this.colorStatusMap.keys());
  }

  getStatusDescription(status: number): string {
    return this.statusLabels[status];
  }

  /**
   * Evaluates series based on provided motors data.
   * @param motors Motors for data calculation.
   * @returns Series for pie chart type.
   */
  getStatusSeries(motors: MotorModel[]): HighCharts.SeriesOptionsType[] {
    const statusMap = this.getStatusMap(motors);

    const series: HighCharts.SeriesOptionsType[] = [
      {
        type: "pie",
        data: [
          {
            name: MotorStatus.Running,
            y: statusMap.get(MotorStatus.Running),
            color: "#0CA919"
          },
          {
            name: MotorStatus.RunningAlarms,
            y: statusMap.get(MotorStatus.RunningAlarms),
            color: "#FFD800"
          },
          {
            name: MotorStatus.Trip,
            y: statusMap.get(MotorStatus.Trip),
            color: "#F03040"
          },
          {
            name: MotorStatus.StoppedNormal,
            y: statusMap.get(MotorStatus.StoppedNormal),
            color: "#CB2BD5"
          },
          {
            name: MotorStatus.NoDataConnection,
            y: statusMap.get(MotorStatus.NoDataConnection),
            color: "#A9A9A9"
          }
        ]
      }
    ];

    return series;
  }

  /**
   * Evaluates statuses based on provided motors.
   * @param motors Motor for calculation.
   * @returns Statuses with total motors.
   */
  getStatusMap(motors: MotorModel[]): Map<MotorStatus, number> {
    const statusMap = new Map<MotorStatus, number>([
      [MotorStatus.Running, 0],
      [MotorStatus.RunningAlarms, 0],
      [MotorStatus.Trip, 0],
      [MotorStatus.StoppedNormal, 0],
      [MotorStatus.NoDataConnection, 0]
    ]);

    motors.forEach((motor: MotorModel) => {
      const status = motor.systemStatus as MotorStatus;
      statusMap.set(status, statusMap.get(status)! + 1);
    });

    return statusMap;
  }
}
