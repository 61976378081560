import { DataPoint, DataPointValue } from "../../data-connectivity/models";
import { isDefined, isNotDefined, isNumber } from "../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";

export function getPointValue(lastDataPoint: Maybe<DataPoint>): Maybe<DataPointValue> {
  if (isNotDefined(lastDataPoint)) {
    return null;
  }

  if (isDefined(lastDataPoint.evaluatedValue) && isNumber(lastDataPoint.evaluatedValue)) {
    return lastDataPoint.evaluatedValue;
  }
  if (isNumber(lastDataPoint.y)) {
    return lastDataPoint.y;
  }
}
