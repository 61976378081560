import { Action } from "@ngrx/store";
import { isEmpty as _isEmpty, maxBy as _maxBy, minBy as _minBy } from "lodash";
import { Subscription, fromEvent } from "rxjs";
import { filter, take, withLatestFrom } from "rxjs/operators";
import { PositionDto } from "../../core/models/position";
import { EntityId } from "../../meta";
import { first } from "../../ts-utils/helpers/array.helper";
import { isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { Dictionary } from "../../ts-utils/models/dictionary.type";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { BaseComponent } from "../components/base/base.component";
import { BasicCardComponent } from "../components/basic-card/basic-card.component";
import { ContainerComponent } from "../components/container/container.component";
import { TabContentComponent } from "../components/tab-content/tab-content.component";
import { TabGroupComponent } from "../components/tab-group/tab-group.component";
import { ComponentStateDto } from "../models/component-state";
import { canBeParent } from "../models/component-type.helper";
import { DraggabillyEventContext } from "../models/draggabilly-event-context";
import { PAGE } from "../models/element-type.constants";
import { PositioningType } from "../models/positioning-type";
import { COMPONENT_HOST_ID_PREFIX, DomMapper } from "../services/dom-mapper.service";
import { ComponentStateSelector } from "../services/entity-selectors/component-state.selector";
import { CommonActions } from "../store/common/common.actions";
import { ComponentStateActions } from "../store/component-state/component-state.actions";
import { HIDDEN_ELEMENT_CLASS } from "./dom-element-visibility.helper";
import { isRelativePositioningType } from "./positioning-type.helper";

export const CLOSEST_COMPONENT_SELECTOR = `[id^=${COMPONENT_HOST_ID_PREFIX}]`;
export const BASIC_CARD_SELECTOR = `[id^=${COMPONENT_HOST_ID_PREFIX}basic-card-]`;
export const TAB_CONTENT_SELECTOR = `[id^=${COMPONENT_HOST_ID_PREFIX}tab-content-]`;
export const TAB_GROUP_SELECTOR = `[id^=${COMPONENT_HOST_ID_PREFIX}tab-group-]`;
const DRAGGING_INDICATORS_CLASS = "edit-mode--active dragging-indicator";
export const DRAGGED_ELEMENT_CSS_CLASS = "dragged-element-z-index";

export function initializeDraggabillyContextOnDragStart(
  context: DraggabillyEventContext,
  dropPoint: MouseEvent | Touch,
  selectedSiblings: HTMLElement[],
  draggedComponent: ComponentStateDto
): void {
  context.cursorOffset = calculateCursorOffset(context);
  context.selectedSiblings = selectedSiblings;
  context.isDraggingStarted = true;
  context.initialTopOffset = draggedComponent.view.css.top;
  context.initialLeftOffset = draggedComponent.view.css.left;
  initializeDraggedElementsOffsets(context);
  initializeCtrlEvents(context, dropPoint as MouseEvent);
}

function calculateCursorOffset(context: DraggabillyEventContext): PositionDto {
  const draggedElementRect = (context.draggable.element as HTMLElement).getBoundingClientRect();
  return {
    left: context.draggable.pointerDownPointer.pageX - draggedElementRect.left,
    top: context.draggable.pointerDownPointer.pageY - draggedElementRect.top
  };
}

function initializeCtrlEvents(context: DraggabillyEventContext, mouseEvent: MouseEvent) {
  context.ctrlUpSubscription = subscribeToCtrlKeyup(context);
  if (mouseEvent.ctrlKey) {
    ctrlOn(context, mouseEvent);
  } else {
    context.ctrlDownSubscription = subscribeToSingleCtrlKeydown(context);
  }
}

function subscribeToSingleCtrlKeydown(context: DraggabillyEventContext): Subscription {
  return fromEvent<KeyboardEvent>(document, "keydown")
    .pipe(
      filter((event) => event.key === "Control"),
      take(1),
      withLatestFrom(fromEvent<MouseEvent>(document, "pointermove"))
    )
    .subscribe(([_, mouseEvent]) => {
      ctrlOn(context, mouseEvent);
    });
}

function subscribeToCtrlKeyup(context: DraggabillyEventContext): Subscription {
  return fromEvent<KeyboardEvent>(document, "keyup")
    .pipe(
      filter((event) => event.key === "Control"),
      withLatestFrom(fromEvent<MouseEvent>(document, "pointermove"))
    )
    .subscribe(([_, mouseEvent]) => {
      ctrlOff(context, mouseEvent);
    });
}

function ctrlOn(context: DraggabillyEventContext, latestMouseMove: MouseEvent) {
  if (document.body.style.cursor === "grab") {
    updateDraggingIndicatorOnMove(true, context, latestMouseMove);
  }
  if (document.body.style.cursor === "move") {
    updateDraggedElementsTransparency(context, false);
  }
  context.cachedContainSize = context.draggable.containSize;
  context.cachedRelativeStartPosition = context.draggable.relativeStartPosition;
  disableMoving(context);
  if (document.body.style.cursor !== "no-drop") {
    document.body.style.cursor = "copy";
  }
}

function disableMoving(context: DraggabillyEventContext) {
  context.draggable.containSize = {
    width: context.draggable.dragPoint.x,
    height: context.draggable.dragPoint.y
  };
  context.draggable.relativeStartPosition = {
    x: -context.draggable.dragPoint.x,
    y: -context.draggable.dragPoint.y
  };
}

function ctrlOff(context: DraggabillyEventContext, latestMouseMove: MouseEvent) {
  restoreDraggingFlow(context, latestMouseMove);

  const componentAtPoint = getComponentFromPoint(latestMouseMove.pageX, latestMouseMove.pageY);
  const shouldHideIndicator = shouldHideIndicatorOnCtrlOff(context, componentAtPoint);
  if (shouldHideIndicator) {
    updateDraggingIndicatorOnMove(false, context, latestMouseMove);
    document.body.style.cursor = "grab";
  }

  const shouldAddTransparency = canDirectlyAcceptDrop(componentAtPoint) && !shouldHideIndicator;
  if (shouldAddTransparency) {
    updateDraggedElementsTransparency(context, true);
    document.body.style.cursor = "move";
  }

  context.ctrlDownSubscription = subscribeToSingleCtrlKeydown(context);
}

function shouldHideIndicatorOnCtrlOff(
  context: DraggabillyEventContext,
  componentAtPoint: Maybe<BaseComponent>
): boolean {
  return (
    isDefined(componentAtPoint) &&
    (componentAtPoint === context.container ||
      isDefined(context.container.children.find((child) => child.id === componentAtPoint.id)))
  );
}

function restoreDraggingFlow(context: DraggabillyEventContext, latestMouseMove: MouseEvent) {
  context.draggable.containSize = context.cachedContainSize;
  context.draggable.relativeStartPosition = context.cachedRelativeStartPosition;
  let moveVector = {
    x: latestMouseMove.pageX - context.draggable.pointerDownPointer.pageX,
    y: latestMouseMove.pageY - context.draggable.pointerDownPointer.pageY
  };
  context.draggable.dragMove(latestMouseMove, latestMouseMove, moveVector);
  context.selectedSiblings.map((sibling) => {
    sibling.style.transform = getTranslationString(
      context.draggable.dragPoint.x,
      context.draggable.dragPoint.y
    );
  });
}

function initializeDraggedElementsOffsets(context: DraggabillyEventContext): void {
  const mainDraggedElementRect = (context.draggable.element as HTMLElement).getBoundingClientRect();
  const draggedElements = context.selectedSiblings.concat([context.draggable.element]);
  draggedElements.map((sibling) => {
    const siblingRect = sibling.getBoundingClientRect();
    context.draggedElementsOffsets[DomMapper.getComponentId(sibling.id)] = {
      left: siblingRect.left - mainDraggedElementRect.left,
      top: siblingRect.top - mainDraggedElementRect.top
    };
  });
}

export function initializeDraggingIndicator(context: DraggabillyEventContext) {
  context.draggingIndicator.innerHTML = "";
  const draggedElements = context.selectedSiblings.concat([context.draggable.element]);
  draggedElements.map((element) => {
    const elementIndicator = document.createElement("div");
    elementIndicator.style.width = element.clientWidth + "px";
    elementIndicator.style.height = element.clientHeight + "px";
    elementIndicator.className = DRAGGING_INDICATORS_CLASS;
    context.draggingIndicator.appendChild(elementIndicator);
    const elementIndicatorOffset =
      context.draggedElementsOffsets[DomMapper.getComponentId(element.id)];
    elementIndicator.style.transform = getTranslationString(
      elementIndicatorOffset.left,
      elementIndicatorOffset.top
    );
  });
}

function getTranslationString(left: number, top: number): string {
  return `translate3d( ${left}px, ${top}px, 0px )`;
}

export function limitDragMovement(
  context: DraggabillyEventContext,
  siblingElements: HTMLElement[]
) {
  if (_isEmpty(siblingElements)) {
    return;
  }
  const allSelectedElements = siblingElements.concat([context.draggable.element]);

  const { rightLimiter, bottomLimiter, topLimiter, leftLimiter } =
    getLimitingElements(allSelectedElements);

  context.draggable.containSize = getContainSizeForMultipleSelection(
    context,
    rightLimiter,
    bottomLimiter
  );
  context.draggable.relativeStartPosition = getRelativeStartPositionForMultipleSelection(
    context,
    leftLimiter,
    topLimiter
  );
}

function getLimitingElements(elements: HTMLElement[]): {
  rightLimiter: HTMLElement;
  leftLimiter: HTMLElement;
  topLimiter: HTMLElement;
  bottomLimiter: HTMLElement;
} {
  return {
    topLimiter: _minBy(elements, (element) => element.getBoundingClientRect().top),
    bottomLimiter: _maxBy(elements, (element) => element.getBoundingClientRect().bottom),
    leftLimiter: _minBy(elements, (element) => element.getBoundingClientRect().left),
    rightLimiter: _maxBy(elements, (element) => element.getBoundingClientRect().right)
  };
}

function getContainSizeForMultipleSelection(
  context: DraggabillyEventContext,
  rightLimiter: HTMLElement,
  bottomLimiter: HTMLElement
) {
  return {
    width:
      context.draggable.containSize.width -
      (rightLimiter.getBoundingClientRect().right -
        (context.draggable.element as HTMLElement).getBoundingClientRect().right),
    height:
      context.draggable.containSize.height -
      (bottomLimiter.getBoundingClientRect().bottom -
        (context.draggable.element as HTMLElement).getBoundingClientRect().bottom)
  };
}

function getRelativeStartPositionForMultipleSelection(
  context: DraggabillyEventContext,
  leftLimiter: HTMLElement,
  topLimiter: HTMLElement
) {
  return {
    x:
      context.draggable.relativeStartPosition.x -
      ((context.draggable.element as HTMLElement).getBoundingClientRect().left -
        leftLimiter.getBoundingClientRect().left),
    y:
      context.draggable.relativeStartPosition.y -
      ((context.draggable.element as HTMLElement).getBoundingClientRect().top -
        topLimiter.getBoundingClientRect().top)
  };
}

export function getComponentFromPoint(x: number, y: number): Maybe<BaseComponent> {
  const elementsAtPoint = document.elementsFromPoint(x, y);
  const closestComponentHostAncestor = elementsAtPoint
    .find((element) => element.className !== DRAGGING_INDICATORS_CLASS)
    ?.closest(CLOSEST_COMPONENT_SELECTOR);
  return (closestComponentHostAncestor as any)?.angularComponentRef as BaseComponent;
}

function moveDraggingIndicator(
  context: DraggabillyEventContext,
  dropPoint: MouseEvent | Touch
): void {
  context.draggingIndicator.style.left =
    (dropPoint.pageX - context.cursorOffset.left).toString() + "px";
  context.draggingIndicator.style.top =
    (dropPoint.pageY - context.cursorOffset.top).toString() + "px";
}

export function canDirectlyAcceptDrop(component: Maybe<BaseComponent>): boolean {
  return isDefined(component) && component.canAcceptDrop() && component.currentState.type !== PAGE;
}

export function isMovingOverContainer(
  context: DraggabillyEventContext,
  dragPoint: MouseEvent | Touch,
  componentAtPoint: Maybe<BaseComponent>
): boolean {
  return !(dragPoint as PointerEvent).ctrlKey && componentAtPoint === context.container;
}

export function resolveCursorOnDragMove(
  showIndicator: boolean,
  isMovedOverContainer: boolean,
  dragPoint: MouseEvent | Touch,
  isDraggedOverSibling: boolean
): string {
  if (showIndicator) {
    return (dragPoint as MouseEvent).ctrlKey
      ? "copy"
      : resolveDraggingOverSibling(isDraggedOverSibling);
  }
  if (isMovedOverContainer) {
    return "grab";
  }
  return "no-drop";
}

export function resolveCardDraggingCursor(
  isOverSelf: boolean,
  isOverPage: boolean,
  dragPoint: MouseEvent | Touch
) {
  if (isOverPage && !isOverSelf && (dragPoint as MouseEvent).ctrlKey) {
    return "copy";
  }
  if (!isOverPage) {
    return "no-drop";
  }
  return "grab";
}

function resolveDraggingOverSibling(isDraggedOverSibling: boolean): string {
  return isDraggedOverSibling ? "grab" : "move";
}

export function updateDraggedElementsTransparency(
  context: DraggabillyEventContext,
  setTransparency: boolean
): void {
  if (setTransparency) {
    context.draggable.element.classList.add("element--half_transparent");
    context.selectedSiblings.map((sibling) => sibling.classList.add("element--half_transparent"));
  } else {
    context.draggable.element.classList.remove("element--half_transparent");
    context.selectedSiblings.map((sibling) =>
      sibling.classList.remove("element--half_transparent")
    );
  }
}

export function updateDraggingIndicatorOnMove(
  showIndicator: boolean,
  context: DraggabillyEventContext,
  dragPoint: MouseEvent | Touch
): void {
  if (showIndicator) {
    context.draggingIndicator.classList.remove(HIDDEN_ELEMENT_CLASS);
    moveDraggingIndicator(context, dragPoint);
  } else {
    context.draggingIndicator.classList.add(HIDDEN_ELEMENT_CLASS);
  }
}

export function updateSiblingsOnDragMove(context: DraggabillyEventContext) {
  context.selectedSiblings.map((sibling) => {
    sibling.style.transform = context.draggable.element.style.transform;
  });
}

export function updateSiblingsOnDragEnd(context: DraggabillyEventContext): Dictionary<PositionDto> {
  return context.selectedSiblings.reduce((acc: Dictionary<PositionDto>, sibling) => {
    sibling.style.transform = "";
    const siblingOffset = context.draggedElementsOffsets[DomMapper.getComponentId(sibling.id)];
    if (isDefined(siblingOffset)) {
      const siblingPositionLeft = context.draggable.position.x + siblingOffset.left;
      const siblingPositionTop = context.draggable.position.y + siblingOffset.top;

      (sibling as any).draggableInstance.setPosition(siblingPositionLeft, siblingPositionTop);

      acc[DomMapper.getComponentId(sibling.id)] = {
        left: siblingPositionLeft,
        top: siblingPositionTop
      };
    }
    return acc;
  }, {});
}

export function calculateWidgetsDropPosition(
  event: MouseEvent | Touch,
  draggabillyContext: DraggabillyEventContext,
  dropRect: DOMRect
): Dictionary<PositionDto> {
  const baseOffset = {
    top: event.pageY - dropRect.top - draggabillyContext.cursorOffset.top,
    left: event.pageX - dropRect.left - draggabillyContext.cursorOffset.left
  };
  const positionsDict: Dictionary<PositionDto> = {};
  Object.keys(draggabillyContext.draggedElementsOffsets).map((draggedElementId) => {
    positionsDict[draggedElementId] = {
      left: baseOffset.left + draggabillyContext.draggedElementsOffsets[draggedElementId].left,
      top: baseOffset.top + draggabillyContext.draggedElementsOffsets[draggedElementId].top
    };
  });
  return positionsDict;
}

export function resolveDropRect(_dropPoint: MouseEvent | Touch): DOMRect {
  const closestContainer = findClosestContainerComponent(_dropPoint as MouseEvent | TouchEvent);
  const dropDestinationBody = getParentContainerHTML(closestContainer);
  return dropDestinationBody.getBoundingClientRect();
}

export function findClosestContainerComponent(
  _dropPoint: MouseEvent | TouchEvent
): ContainerComponent {
  const elementsAtPoint =
    _dropPoint instanceof MouseEvent
      ? document.elementsFromPoint(_dropPoint?.pageX, _dropPoint?.pageY)
      : document.elementsFromPoint(
          _dropPoint?.changedTouches[0]?.pageX,
          _dropPoint?.changedTouches[0]?.pageY
        );
  const tabContent = findClosestTabContent(elementsAtPoint);
  const basicCard = findClosestBasicCard(elementsAtPoint);
  return tabContent ?? basicCard;
}

function findClosestTabContent(elementsAtPoint: Element[]): TabContentComponent {
  const closestTabContent = elementsAtPoint
    .find((element) => element.className !== DRAGGING_INDICATORS_CLASS)
    ?.closest(TAB_CONTENT_SELECTOR);
  return (closestTabContent as any)?.angularComponentRef as TabContentComponent;
}

export function findClosestBasicCard(elementsAtPoint: Element[]): BasicCardComponent {
  const closestBasicCard = elementsAtPoint
    .find((element) => element.className !== DRAGGING_INDICATORS_CLASS)
    ?.closest(BASIC_CARD_SELECTOR);
  return (closestBasicCard as any)?.angularComponentRef as BasicCardComponent;
}

export function getParentContainerHTML(containerComponent: ContainerComponent): HTMLElement {
  return isDefined((containerComponent as BasicCardComponent)?.cardBody)
    ? (containerComponent as BasicCardComponent)?.cardBody?.nativeElement
    : containerComponent?.hostElement;
}

export function findClosestTabGroup(elementsAtPoint: Element[]): TabGroupComponent {
  const closestTabGroup = first(elementsAtPoint)?.closest(TAB_GROUP_SELECTOR);
  return (closestTabGroup as any)?.angularComponentRef as TabGroupComponent;
}

export function onDropCleanup(draggabillyContext: DraggabillyEventContext) {
  document.body.style.cursor = "default";

  draggabillyContext.ctrlDownSubscription?.unsubscribe();
  draggabillyContext.ctrlUpSubscription?.unsubscribe();
  draggabillyContext.selectedSiblings = [];
  draggabillyContext.draggedElementsOffsets =
    draggabillyContext.cachedContainSize =
    draggabillyContext.cachedRelativeStartPosition =
      {};
  draggabillyContext.draggable.element.classList.remove("element--half_transparent");
  draggabillyContext.isDraggingStarted = false;
  const indicatorElement = draggabillyContext.draggingIndicator;
  indicatorElement.classList.add(HIDDEN_ELEMENT_CLASS);
}

export function getDropAction(
  containerId: EntityId,
  dropPositions: Dictionary<PositionDto>,
  draggedComponentsIds: string[],
  dropDestination: EntityId,
  isDroppedOnRightSide: boolean
): Action {
  switch (document.body.style.cursor) {
    case "copy":
      return ComponentStateActions.copyComponentsIntoCard({
        copyIntoId: containerId,
        dropPositions,
        componentsIds: draggedComponentsIds,
        dropTargetId: dropDestination,
        isDroppedOnRightSide
      });
    case "move":
      return ComponentStateActions.moveToContainer({
        componentsIds: draggedComponentsIds,
        destinationComponent: containerId,
        dropPositions,
        dropTargetId: dropDestination,
        isDroppedOnRightSide
      });
    default:
      return CommonActions.doNothing();
  }
}

export function getDraggedWidgetsIds(context: DraggabillyEventContext): string[] {
  return context.selectedSiblings
    .map((sibling) => DomMapper.getComponentId(sibling.id))
    .concat([context.draggableComponentId.toString()]);
}

export function findParentContainer(
  componentState: Maybe<ComponentStateDto>,
  componentStateSelector: ComponentStateSelector
): Maybe<ComponentStateDto> {
  if (isNotDefined(componentState)) {
    return null;
  }
  if (canBeParent(componentState.type)) {
    return componentState;
  }
  const parent = componentStateSelector.getParent(componentState.id);
  return findParentContainer(parent, componentStateSelector);
}

export function findMaxComponentOrder(
  components: ComponentStateDto[],
  minAllowedOrder: number
): number {
  const maxOrder = components
    .map((component) => component?.view.css.order)
    .filter(isDefined)
    .filter((x) => !Number.isNaN(x))
    .map((x) => parseInt(x))
    .reduce((acc, curr) => (curr > acc ? curr : acc), minAllowedOrder);
  return maxOrder;
}

export function getSiblingIdUnderPoint(
  dropPoint: MouseEvent | Touch,
  draggabillyContext: DraggabillyEventContext
): Maybe<Element> {
  return document
    .elementsFromPoint(dropPoint.pageX, dropPoint.pageY)
    .filter(
      (element) =>
        element.id.startsWith(COMPONENT_HOST_ID_PREFIX) &&
        element.id !== DomMapper.getHostId(draggabillyContext.draggableComponentId)
    )[0];
}

export function resolveActionToUpdateOrder(
  setAfter: boolean,
  dropTargetId: EntityId,
  droppedComponentId: EntityId
): Action {
  return setAfter
    ? ComponentStateActions.updateOrderSetAfter({
        targetSiblingId: dropTargetId,
        componentId: droppedComponentId
      })
    : ComponentStateActions.updateOrderSetBefore({
        targetSiblingId: dropTargetId,
        componentId: droppedComponentId
      });
}

export function isDroppedAfter(dropPoint: MouseEvent | Touch, dropTargetRect: DOMRect): boolean {
  return (
    (dropPoint.clientX - dropTargetRect.left) / (dropTargetRect.right - dropTargetRect.left) > 0.5
  );
}

export function updateZIndexesOnDragStart(
  positioningType: PositioningType,
  draggedElement: HTMLElement,
  draggedSiblings: HTMLElement[]
): void {
  (draggedElement as any)?.angularComponentRef.revertOverlappingWidgetsState();
  if (isRelativePositioningType(positioningType)) {
    showComponentsInFront(draggedElement, draggedSiblings);
  }
}

function showComponentsInFront(draggedElement: HTMLElement, draggedSiblings: HTMLElement[]): void {
  draggedElement?.classList.add(DRAGGED_ELEMENT_CSS_CLASS);
  draggedSiblings.map((draggedSibling) => draggedSibling.classList.add(DRAGGED_ELEMENT_CSS_CLASS));
}

export function updateZIndexesOnDragEnd(
  positioningType: PositioningType,
  draggedElements: HTMLElement[],
  event: Event
): void {
  if (isRelativePositioningType(positioningType)) {
    draggedElements.map((element) => element.classList.remove(DRAGGED_ELEMENT_CSS_CLASS));
  }
  if (draggedElements.length === 1) {
    (draggedElements[0] as any)?.angularComponentRef.makeConfigButtonsFullyVisible(event);
  }
}
