<mat-dialog-content>
  <div mat-dialog-title>Add signals</div>
  <div class="add-signal-dialog__wrapper">
    <div>Assign to axis:</div>
    <mat-select placeholder="Y Axis" #axisList (selectionChange)="setAxis($event.value)">
      <mat-option *ngFor="let axis of axisArray" value="{{ axis.id }}">
        <div [innerHTML]="axis.title.text | oneline"></div>
      </mat-option>
    </mat-select>
  </div>
  <div class="add-signal-dialog__wrapper">
    <div>Select signals:</div>
    <div class="add-signal-dialog__signal-list-wrapper">
      <signal-browser
        (signalsChanged)="onChanged($event)"
        [openInDialog]="true"
      ></signal-browser>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="add-signal-dialog__button-container">
  <button
    class="c-button c-button--rounded rend__button--rounded c-button--primary"
    (click)="onDialogClosed()"
  >
    Add
  </button>
  <button class="c-button c-button--rounded trend__button--rounded" mat-dialog-close>Cancel</button>
</mat-dialog-actions>
