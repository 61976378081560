<span class="confirmation-dialog__text">{{ text }}</span>
<div mat-dialog-actions class="confirmation-dialog__button-group">
  <button class="c-button c-button--rounded c-button--primary" (click)="onSave()">
    {{ localizer.dialogs.Save | translate }}
  </button>
  <button class="c-button c-button--rounded" (click)="onDiscard()">
    {{ localizer.dialogs.Discard | translate }}
  </button>
  <button class="c-button c-button--rounded" (click)="onCancel()">
    {{ localizer.dialogs.Cancel | translate }}
  </button>
</div>
