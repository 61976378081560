import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef } from "@angular/core";
import { TimeRange } from "../../../core";
import { TimeRangeEvent, WidgetEventType } from "../../../core/models/widget-eventing/widget-event";
import { FilterFactory } from "../../../core/services/filter/filter-factory.service";
import { TimeSeriesDataPointDto } from "../../../data-connectivity/models/data-point";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { LayoutBuilder } from "../../../meta/decorators";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { NumberOfDataPointsToRequest } from "../../../meta/decorators/number-of-data-points-to-request.decorator";
import { ComponentCategory } from "../../../meta/models/component-category";
import { first, last } from "../../../ts-utils/helpers/array.helper";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { ConnectorRoles } from "../../decorators/connector-roles.decorator";
import { MaxConnectors } from "../../decorators/max-connectors.decorator";
import { View } from "../../decorators/view.decorator";
import { calculateNumberOfPointsByLengthInPix } from "../../helpers/component-size.helper";
import { isPseudoConnector } from "../../helpers/connectors.helper";
import { hideElement, unhideElement } from "../../helpers/dom-element-visibility.helper";
import { CSS_INLINE_EDIT_BUTTON } from "../../helpers/get-dynamic-buttons.helper";
import { DataStatus } from "../../models/data-status";
import { isTable } from "../../models/display-strategies/display-strategy-type.helper";
import { DisplayStrategies } from "../../models/display-strategies/time-series-display-strategies";
import { TIME_SERIES } from "../../models/element-type.constants";
import { SizeInPx } from "../../models/size-in-px";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import { CommonActions, ComponentStateActions } from "../../store";
import { BaseComponent } from "../base/base.component";
import { ComponentConstructorParams } from "../base/component-constructor-params";
import { calculateNumberOfPointsForTable } from "../simple-components/table-for-connectors/table-for-connectors.component";
import { Roles } from "./roles";
import { TimeSeriesViewConfig } from "./view-config";

@Component({
  selector: "c-time-series",
  templateUrl: "./time-series.component.html",
  styleUrls: ["./time-series.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: TimeSeriesComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@LayoutBuilder(
  ComponentCategory.TimeSeries,
  TIME_SERIES,
  "Trend_1",
  "abb-icon",
  DisplayStrategies.Line,
  LOCALIZATION_DICTIONARY.propertySheet.Display_Line
)
@LayoutBuilder(
  ComponentCategory.TimeSeries,
  TIME_SERIES,
  "icon-Other-Charts",
  "dashboard-icon",
  DisplayStrategies.Area,
  LOCALIZATION_DICTIONARY.propertySheet.Display_Area
)
@LayoutBuilder(
  ComponentCategory.TimeSeries,
  TIME_SERIES,
  "Bar_chart",
  "abb-icon",
  DisplayStrategies.Column,
  LOCALIZATION_DICTIONARY.propertySheet.Display_Column
)
@LayoutBuilder(
  ComponentCategory.TimeSeries,
  TIME_SERIES,
  "icon-Horizontal-bar-chart",
  "dashboard-icon",
  DisplayStrategies.Bar,
  LOCALIZATION_DICTIONARY.propertySheet.Display_Bar
)
@LayoutBuilder(
  ComponentCategory.TimeSeries,
  TIME_SERIES,
  "Bar_chart",
  "abb-icon",
  DisplayStrategies.Histogram,
  LOCALIZATION_DICTIONARY.propertySheet.Display_Histogram
)
@LayoutBuilder(
  ComponentCategory.TimeSeries,
  TIME_SERIES,
  "icon-Table",
  "dashboard-icon",
  DisplayStrategies.Table,
  LOCALIZATION_DICTIONARY.propertySheet.Display_Table
)
@NumberOfDataPointsToRequest(calculateNumberOfDataPointsToRequest)
@ConnectorRoles(Roles)
@MaxConnectors(20)
@EditableWidget({ fullName: TIME_SERIES, title: "time-series" })
export class TimeSeriesComponent extends BaseComponent {
  @View(TimeSeriesViewConfig)
  public get view(): TimeSeriesViewConfig {
    return this.currentState.view as TimeSeriesViewConfig;
  }

  public strategyEnum = DisplayStrategies;
  public simpleView: TimeSeriesViewConfig;
  public valueConnectorDescriptors: DataConnectorDescriptor[];
  public filterRange: Partial<TimeRange> = {};

  constructor(
    params: ComponentConstructorParams,
    hostElementRef: ElementRef<any>,
    private cdr: ChangeDetectorRef,
    private filterFactory: FilterFactory
  ) {
    super(params, hostElementRef);
  }

  // TODO: fullRedraw onDataConnectorsChange?

  protected updateDisplay(callerInfo?: string): void {
    super.updateDisplay(callerInfo);
    const evaluated = this.dynamicDefaultsEvaluator.collectAndEvaluate(
      this.view,
      this.dataAccessor
    );

    // TODO remove table strategy filter condition once category table is separated out
    this.valueConnectorDescriptors = evaluated.connectorDescriptors.filter(
      (connectorDesc) =>
        (connectorDesc.connector.role === Roles.Value.name &&
          connectorDesc.connector.isTimeSeries) ||
        this.view.displayStrategy === DisplayStrategies.Table
    );

    const interpolatedProperties =
      this.propertyInterpolationService.collectInterpolatedProperties<TimeSeriesViewConfig>(
        { ...this.currentState, view: evaluated.viewConfig },
        this.valueConnectorDescriptors
      );
    this.simpleView = interpolatedProperties.viewConfig;
    this.valueConnectorDescriptors = interpolatedProperties.connectorDescriptors;

    if (isDefined(this.filter)) {
      const componentFilter = this.filterFactory.createFilterFromConfiguration(this.filter);
      this.filterRange = componentFilter?.timeRange ?? {};
    } else {
      this.filterRange = {};
    }

    this.cdr.markForCheck();
  }

  onUserZoom(event: TimeRangeEvent): void {
    const { type } = event;
    if (type === WidgetEventType.SelectTimeRangeEvent) {
      this.dispatch(
        CommonActions.raiseWidgetSelectTimeRangeEvent({
          source: this.id,
          raisedEvent: event
        })
      );
    } else if (type === WidgetEventType.ResetTimeRangeEvent) {
      this.dispatch(
        CommonActions.raiseWidgetResetTimeRangeEvent({
          source: this.id,
          raisedEvent: event
        })
      );
    }
  }

  protected onDataStatusChange(dataStatus: DataStatus): void {
    super.onDataStatusChange(dataStatus);
    this.cdr.markForCheck();
  }

  updatePageSize(pageSize: number): void {
    const didPageSizeChange = this.view.tablePageSize !== pageSize;
    if (didPageSizeChange) {
      this.dispatch(
        ComponentStateActions.updateOne({
          componentUpdate: {
            id: this.id.toString(),
            changes: {
              view: { ...this.view, tablePageSize: pageSize } as TimeSeriesViewConfig
            }
          }
        })
      );
    }
  }

  loadMoreData(): void {
    const connectors = this.dataConnectors.filter((connector) => !isPseudoConnector(connector.id));
    const dataPoints = first(connectors)?.dataPoints;
    const { startTime } = last(dataPoints) as TimeSeriesDataPointDto;
    this.dispatch(
      CommonActions.getFullRangeSignalData({
        connectors,
        timeRange: { from: startTime }
      })
    );
  }

  protected showOrHideInlineButton(): void {
    const inlineButton = this.configButtonsContainer?.querySelector("." + CSS_INLINE_EDIT_BUTTON);
    if (isDefined(inlineButton)) {
      isTable(this.simpleView.displayStrategy)
        ? unhideElement(inlineButton as HTMLElement)
        : hideElement(inlineButton as HTMLElement);
    }
  }
}

export function calculateNumberOfDataPointsToRequest(
  strategy: string,
  componentSize: SizeInPx
): number {
  switch (strategy) {
    case DisplayStrategies.Line:
    case DisplayStrategies.Area:
      return calculateNumberOfPointsByLengthInPix(componentSize.widthInPx, false);
    case DisplayStrategies.Column:
      return calculateNumberOfPointsByLengthInPix(componentSize.widthInPx, true);
    case DisplayStrategies.Bar:
      return calculateNumberOfPointsByLengthInPix(componentSize.heightInPx, true);
    case DisplayStrategies.Table:
      return calculateNumberOfPointsForTable(componentSize.widthInPx);
    case DisplayStrategies.Histogram:
      return 10000;
    default:
      return calculateNumberOfPointsByLengthInPix(
        Math.max(componentSize.widthInPx, componentSize.heightInPx),
        false
      );
  }
}
