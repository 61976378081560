import { AggregationFunction, AggregationType } from "../../data-connectivity/models";
import { ComponentStateDto } from "../models";
import { isSingleValue } from "../models/component-type.helper";

export function resolveTimeAggregationOverride(newComponent: ComponentStateDto): ComponentStateDto {
  const defaultTimeAggregation: AggregationType =
    newComponent.dataConnectorQuery.aggregationConfig.timeAggregationFunction;
  newComponent.dataConnectorQuery.aggregationConfig.timeAggregationFunction =
    defaultTimeAggregation === AggregationFunction.None && isSingleValue(newComponent.type)
      ? AggregationFunction.Latest
      : defaultTimeAggregation;
  return newComponent;
}
