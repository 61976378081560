<div class="user-menu__container" *ngIf="shouldShowUserMenu" (clickOutside)="closeDropdown()">
  <header-button
    [buttonConfig]="userMenuButton"
    [isSelected]="userMenuOpened"
    icon="abb-icon--large User_in_circle"
  ></header-button>
  <div
    class="user-menu__dropdown"
    [ngClass]="{ 'user-menu__dropdown--mobile': displayMode === DisplayMode.Mobile }"
    #userMenu
  >
    <header-button
      [verticallyOpened]="true"
      [buttonConfig]="userNameButton"
      [showLabel]="true"
      icon="User"
    ></header-button>
    <header-button
      [verticallyOpened]="true"
      [buttonConfig]="logOutButton"
      [showLabel]="true"
      icon="Log_out"
    ></header-button>
    <header-button
      *ngIf="feedbackService.shouldShowFeedbackOption"
      [verticallyOpened]="true"
      [buttonConfig]="feedbackButton"
      [showLabel]="true"
      icon="Comment"
    ></header-button>
  </div>
</div>
