import { Injectable, Type } from "@angular/core";
import { EditorType } from "../../meta/models";
import { CriticalError } from "../../ts-utils/models/critical-error";
import { BaseEditorComponent } from "../components/base-editor.component";
import { EditorMapping } from "../models/editor-mapping";

export abstract class IEditorProvider {
  getEditor: (editorType: EditorType) => Type<BaseEditorComponent>;
}

@Injectable()
export class EditorProvider implements IEditorProvider {
  private _editorsList: EditorMapping[];

  constructor() {
    this._editorsList = (<any>window).__editors;
    if (typeof this._editorsList === "undefined") {
      (<any>window).__editors = [];
    }
  }

  public getEditor(editorType: EditorType): Type<BaseEditorComponent> {
    const editor = BaseEditorComponent;

    //TODO: replace filter with find
    const editors = this._editorsList
      .filter((mapping) => mapping.key === editorType)
      .map((mapping) => mapping.editor);

    if (editors.length === 0) {
      throw new CriticalError(`Editor for ${editorType} not found`);
    } else {
      return editors[0];
    }
  }
}
