import { Injectable } from "@angular/core";

@Injectable()
export class HelpService {
  protected _hasHelpDocument = false;

  public get hasHelpDocument(): boolean {
    return this._hasHelpDocument;
  }

  public open(): void {}
}
