import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import { Serializable, Title } from "../../meta";
import { Configurable } from "../../meta/decorators/configurable.decorator";
import { EditableType } from "../../meta/decorators/editable-type.decorator";
import { Key } from "../../meta/decorators/key.decorator";
import { EditorType } from "../../meta/models/editor-type";

export interface KeyValuePairDto {
  key: string;
  value?: string;
}

@EditableType({ fullName: "KeyValuePair" })
export class KeyValuePair implements KeyValuePairDto {
  @Key()
  @Title()
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Filter_Key,
    editorType: EditorType.TextBox
  })
  @Serializable("")
  public key: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Value,
    editorType: EditorType.TextBox
  })
  public value?: string;

  constructor(keyValuePairDto: any = {}) {
    this.key = keyValuePairDto.key;
    this.value = keyValuePairDto.value;
  }
}
