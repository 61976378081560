import { createAction, props } from "@ngrx/store";
import { ReportDescription } from "../../../core/models/report-description";
import { Maybe } from "../../../ts-utils/models/maybe.type";

export namespace ReportBrowserDialogActions {
  export const openReportBrowserDialog = createAction("[Dialog] Open Report Browser Dialog");

  export const onReportBrowserDialogClosed = createAction(
    "[Dialog] On Report Browser Dialog Closed",
    props<{ result: Maybe<ReportDescription> }>()
  );
}
