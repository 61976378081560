import { combineLatest, Observable, of } from "rxjs";
import { catchError, filter, map } from "rxjs/operators";
import { QueryFilter } from "../../core/models/filter/query-filter";
import { WebServicesConfiguration } from "../../core/services/api.config";
import { HttpService } from "../../core/services/http.service";
import { TimeService } from "../../core/services/time.service";
import { ErrorCatchingActions } from "../../core/store/error-catching/error-catching.actions";
import { ConnectorsDictionaryIndexedById } from "../../data-connectivity/models/connectors-dictionary-indexed-by-id";
import { DataConnectorDto } from "../../data-connectivity/models/data-connector";
import { DataSourceDto } from "../../data-connectivity/models/data-source/data-source";
import { Dispatcher } from "../../dispatcher";
import { createConnectorsDictionaryWithEmptyDataPoints } from "../../elements/helpers/connectors.helper";
import { DataStatus } from "../../elements/models/data-status";
import { RequestScope } from "../../elements/models/request-scope";
import { LocalizationService } from "../../i18n/localization.service";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import { EntityId } from "../../meta";
import { getEntityTitle } from "../../meta/helpers/get-title.helper";
import { Dictionary, isDefined, isEmpty, Maybe } from "../../ts-utils";
import {
  filterDictionary,
  isEmptyDict,
  mapDictionary
} from "../../ts-utils/helpers/dictionary.helper";

export const API_QUERY = "API query";

export interface IConnectorDataGetter {
  getData(
    singleFilter: QueryFilter,
    connectors: Dictionary<DataConnectorDto[]>,
    requestScope: RequestScope
  ): Observable<ConnectorsDictionaryIndexedById>;
}

export interface IConnectorsGetter {
  getConnectors(
    queries: Dictionary<DataSourceDto>,
    filters: Dictionary<QueryFilter>
  ): Observable<Dictionary<DataConnectorDto[]>>;
}

export abstract class DataGetter<TResponse> {
  constructor(
    protected apiConfig: WebServicesConfiguration,
    protected httpService: HttpService,
    protected timeService: TimeService,
    private localizationService: LocalizationService,
    private dispatcher: Dispatcher
  ) {}

  protected abstract get supportedDatasourceTypes(): string[];

  protected isDataSourceValid(dataSource: DataSourceDto): boolean {
    return true;
  }

  protected isDataSourceSupportedAndValid(dataSource: DataSourceDto): boolean {
    return (
      this.supportedDatasourceTypes.includes(dataSource.typeName) &&
      this.isDataSourceValid(dataSource)
    );
  }

  protected reportInvalidQuery(querySource: string, errorMessage: string): void {
    const localized = this.localizationService.get(
      LOCALIZATION_DICTIONARY.snackBarMessages.InvalidDataQuery,
      { query: querySource, message: errorMessage }
    );

    const action = ErrorCatchingActions.catchWarning({
      messageToDisplay: localized
    });
    this.dispatcher.dispatch(action);
  }

  protected raiseError(key: string, error: any): void {
    const translated = this.localizationService.get(key);
    const action = ErrorCatchingActions.catchError({
      messageToDisplay: translated,
      error: error,
      autoClose: true
    });
    this.dispatcher.dispatch(action);
  }
}

export abstract class ConnectorsGetter<TQuery, TResponse>
  extends DataGetter<TResponse>
  implements IConnectorsGetter
{
  constructor(
    apiConfig: WebServicesConfiguration,
    httpService: HttpService,
    timeService: TimeService,
    localizationService: LocalizationService,
    dispatcher: Dispatcher
  ) {
    super(apiConfig, httpService, timeService, localizationService, dispatcher);
  }

  protected abstract convertResponse(
    response: TResponse,
    queriedDatasource: DataSourceDto,
    ownerId: EntityId
  ): DataConnectorDto[];

  private convertResponses(
    responses: Dictionary<TResponse>,
    queries: Dictionary<DataSourceDto>
  ): Dictionary<DataConnectorDto[]> {
    const res: Dictionary<DataConnectorDto[]> = Object.keys(responses).reduce(
      (acc: Dictionary<DataConnectorDto[]>, componentId: string) => {
        const response = responses[componentId];
        const query = queries[componentId];
        if (!isEmptyDict(response)) {
          acc[componentId] = this.convertResponse(response, query, componentId);
        }
        return acc;
      },
      {}
    );
    return res;
  }

  protected abstract buildQueryDto(dataSource: DataSourceDto, filter: QueryFilter): TQuery;

  protected abstract callServer(
    queryDtos: Dictionary<TQuery>,
    queries: Dictionary<DataSourceDto>
  ): Observable<Dictionary<TResponse>>;

  public getConnectors(
    queries: Dictionary<DataSourceDto>,
    filters: Dictionary<QueryFilter>
  ): Observable<Dictionary<DataConnectorDto[]>> {
    const supportedQueries = filterDictionary(queries, (query) =>
      this.isDataSourceSupportedAndValid(query)
    );
    if (isEmptyDict(supportedQueries)) {
      return of({});
    } else {
      const queryDtos = mapDictionary(supportedQueries, (query, key) => {
        const filterUsed = filters[key];
        const queryDto = this.buildQueryDto(query, filterUsed);
        return queryDto;
      });

      const responses$ = this.callServer(queryDtos, supportedQueries);

      return responses$.pipe(
        map((responses) => this.convertResponses(responses, supportedQueries)),
        catchError((error) => {
          this.raiseError(LOCALIZATION_DICTIONARY.snackBarMessages.SendingLogsRequestsError, error);
          return of({});
        })
      );
    }
  }
}

export abstract class ConnectorDataGetter<TResponse>
  extends DataGetter<TResponse>
  implements IConnectorDataGetter
{
  constructor(
    apiConfig: WebServicesConfiguration,
    httpService: HttpService,
    timeService: TimeService,
    translationService: LocalizationService,
    dispatcher: Dispatcher
  ) {
    super(apiConfig, httpService, timeService, translationService, dispatcher);
  }

  protected abstract sendRequest(
    singleFilter: QueryFilter,
    connectors: Dictionary<DataConnectorDto[]>,
    requestScope: RequestScope
  ): Observable<TResponse>;

  protected abstract convertResponse(
    response: TResponse,
    queriedConnectors: DataConnectorDto[]
  ): ConnectorsDictionaryIndexedById;

  public getData(
    singleFilter: QueryFilter,
    connectors: Dictionary<DataConnectorDto[]>,
    requestScope: RequestScope
  ): Observable<ConnectorsDictionaryIndexedById> {
    const supportedConnectors = this.filterSupportedConnectors(connectors);
    if (isEmpty(supportedConnectors)) {
      const connectorsWithData: ConnectorsDictionaryIndexedById =
        createConnectorsDictionaryWithEmptyDataPoints(
          supportedConnectors,
          DataStatus.NoDataReceived
        );
      return of(connectorsWithData);
    }

    const response$ = this.sendRequest(singleFilter, supportedConnectors, requestScope);

    return response$.pipe(
      filter((response) => response != null),
      map((response) => this.convertResponse(response, Object.values(supportedConnectors).flat())),
      catchError((error) => {
        this.raiseError(LOCALIZATION_DICTIONARY.snackBarMessages.SendingLogsRequestsError, error);
        const connectorsWithData: ConnectorsDictionaryIndexedById =
          createConnectorsDictionaryWithEmptyDataPoints(
            supportedConnectors,
            DataStatus.RequestFailed
          );
        return of(connectorsWithData);
      })
    );
  }

  private filterSupportedConnectors(
    connectors: Dictionary<DataConnectorDto[]>
  ): Dictionary<DataConnectorDto[]> {
    return Object.keys(connectors).reduce((acc: Dictionary<DataConnectorDto[]>, componentId) => {
      const filteredConnectors = connectors[componentId].filter((conn) =>
        this.isDataSourceSupportedAndValid(conn.dataSource)
      );
      if (filteredConnectors.length > 0) {
        acc[componentId] = filteredConnectors;
      }
      return acc;
    }, {});
  }

  protected reportInvalidConnectorQuery(
    queriedConnector: DataConnectorDto,
    errorMessage: string
  ): void {
    this.reportInvalidQuery(getEntityTitle(queriedConnector), errorMessage);
  }
}

export abstract class MultiCallConnectorDataGetter<TResponse> extends ConnectorDataGetter<
  TResponse[]
> {
  protected abstract sendOneRequest(
    filter: QueryFilter,
    connector: DataConnectorDto,
    parentComponentStateId: EntityId
  ): Observable<TResponse>;

  protected abstract convertOneResponse(
    response: TResponse,
    queriedConnector: DataConnectorDto
  ): Maybe<DataConnectorDto>;

  protected sendRequest(
    singleFilter: QueryFilter,
    connectors: Dictionary<DataConnectorDto[]>
  ): Observable<TResponse[]> {
    const responseObservables = Object.keys(connectors).reduce(
      (acc: Observable<TResponse>[], componentId) => {
        const connectorResponses = connectors[componentId].map((connector) =>
          this.sendOneRequest(singleFilter, connector, componentId)
        );
        acc = acc.concat(connectorResponses);
        return acc;
      },
      []
    );
    return combineLatest(responseObservables);
  }

  protected convertResponse(
    response: TResponse[],
    queriedConnectors: DataConnectorDto[]
  ): ConnectorsDictionaryIndexedById {
    const convertedConnectors: DataConnectorDto[] = response.reduce(
      (acc: DataConnectorDto[], oneResponse, index) => {
        const convertedConnector = this.convertOneResponse(oneResponse, queriedConnectors[index]);
        if (isDefined(convertedConnector)) {
          acc.push(convertedConnector);
        }
        return acc;
      },
      []
    );

    const connectorDict = convertedConnectors.reduce(
      (acc: ConnectorsDictionaryIndexedById, convertedConnector) => {
        if (convertedConnector != null) {
          acc[convertedConnector.id] = convertedConnector;
        }
        return acc;
      },
      {}
    );
    return connectorDict;
  }
}
