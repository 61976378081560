import { v4 as uuid } from "uuid";
import { construct } from "../../core";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import {
  Configurable,
  ConfigurableEnum,
  EditableType,
  EditorType,
  Serializable,
  Title
} from "../../meta";
import { Key } from "../../meta/decorators/key.decorator";
import { DeepPartial } from "../../ts-utils";

const TYPE_NAME = "SpiderSeriesType";
export enum SeriesType {
  Line = "Line",
  Area = "Area",
  Column = "Column"
}
// @dynamic
@EditableType({ fullName: TYPE_NAME })
export class SpiderSeriesType {
  @Key()
  @Serializable()
  id: string;

  @Title()
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Title,
    editorType: EditorType.TextBox
  })
  @Serializable("")
  title!: string;
  @ConfigurableEnum({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Type,
    enumSource: SeriesType
  })
  @Serializable(SeriesType.Line)
  type!: string;

  constructor(seriesConfigDto: DeepPartial<SpiderSeriesType> = {}) {
    construct(this, seriesConfigDto, TYPE_NAME, { id: uuid() });
  }
}
