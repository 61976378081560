import { isBasicCard } from "../../elements/models/component-type.helper";
import { isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";

export function getBasicCards(oldConfig: any): any[] {
  const componentStates: any = oldConfig["componentStates"]["entities"];
  const cards: any[] = Object.values(componentStates).filter((cs) => isBasicCard(cs["type"]));
  return cards;
}

export function getDataSourceAggregationConfig(oldConfig: any): any[] {
  const dataConnectors: any = oldConfig["dataConnectors"]?.["entities"];
  if (isNotDefined(dataConnectors)) {
    return [];
  }
  const dataSources: any[] = Object.values(dataConnectors).map((dc) => dc["dataSource"]);

  return dataSources
    .filter(isDefined)
    .map((ds) => ds["aggregationConfig"])
    .filter(isDefined);
}

export function getComponentStateAggregationConfig(oldConfig: any): any[] {
  const componentStates: any = oldConfig["componentStates"]?.["entities"];
  if (isNotDefined(componentStates)) {
    return [];
  }
  const dataConnectorQueries: any[] = Object.values(componentStates).map(
    (cs) => cs["dataConnectorQuery"]
  );

  return dataConnectorQueries
    .filter(isDefined)
    .map((dcq) => dcq["aggregationConfig"])
    .filter(isDefined);
}
