export class SidebarNavigationNode<T> {
  children: SidebarNavigationNode<T>[];
  constructor(public name: string, public nodeInfo: T) {
    this.children = [];
  }

  addChild(childName: string, child: T): SidebarNavigationNode<T> {
    const sidebarNavigationNode: SidebarNavigationNode<T> = new SidebarNavigationNode<T>(
      childName,
      child
    );
    this.children.push(sidebarNavigationNode);
    return sidebarNavigationNode;
  }
}
