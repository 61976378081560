import { LimitEditorPosition } from "./limit-editor-position";

export class LimitEditorVisualization {
  public absolutePositionX: number;
  public absolutePositionY: number;
  public point: LimitEditorPosition;
  public isInvalidValue: boolean;

  constructor(params: Partial<LimitEditorVisualization> = {}) {
    this.absolutePositionX = params.absolutePositionX ?? 0;
    this.absolutePositionY = params.absolutePositionY ?? 0;
    this.point = params.point ?? new LimitEditorPosition();
    this.isInvalidValue = params.isInvalidValue ?? false;
  }
}
