export enum FooterFunctions {
  None = "None",
  Min = "Min",
  Max = "Max",
  Cnt = "Cnt",
  Avg = "Avg",
  StDev = "StDev",
  Var = "Var",
  CoefficientOfVariation = "CoefficientOfVariation"
}
