<div class="card-item__drag-handle">
  <highcharts-chart-extended
    class="chart-container"
    [Highcharts]="Highcharts"
    [options]="chartOptions"
  >
  </highcharts-chart-extended>
  <div class="water-conductivity-chart__legend-container">
    <km-legend
      [itemList]="[
        {
          name: 'Current Value',
          color: view.currentColor,
          value: (currentValue || EMPTY_VALUE) + ' ' + view.unit
        },
        { name: 'Alarm Limit', color: view.alarmColor },
        { name: 'Trip Limit', color: view.tripColor }
      ]"
    ></km-legend>
  </div>
</div>
