import { merge as _merge } from "lodash";
import { CustomFilterValueType } from "../../../shared/models/custom-filter-value-type";
import { DeepPartial } from "../../../ts-utils/models/deep-partial.type";
import { CustomFilterNumberValueDescriptor } from "../../models/filter/custom-filter-number-value-descriptor";
import { CustomFilterTextValueDescriptor } from "../../models/filter/custom-filter-text-value-descriptor";
import { CustomFilterValueDescriptor } from "../../models/filter/custom-filter-value-descriptor";

export function createCustomFilterValueDescriptor(
  type: CustomFilterValueType,
  partial: DeepPartial<CustomFilterValueDescriptor>
): CustomFilterValueDescriptor {
  const typedPartial = { ...partial, typeName: type };
  switch (typedPartial.typeName) {
    case CustomFilterValueType.Text:
      return createCustomFilterTextValueDescriptor(
        partial as DeepPartial<CustomFilterTextValueDescriptor>
      );
    case CustomFilterValueType.Number:
      return createCustomFilterNumberValueDescriptor(
        partial as DeepPartial<CustomFilterNumberValueDescriptor>
      );
    default:
      return createCustomFilterTextValueDescriptor(
        partial as DeepPartial<CustomFilterTextValueDescriptor>
      );
  }
}

function createCustomFilterTextValueDescriptor(
  source: DeepPartial<CustomFilterTextValueDescriptor> = {}
): CustomFilterTextValueDescriptor {
  const target = new CustomFilterTextValueDescriptor();
  return _merge(target, source);
}

function createCustomFilterNumberValueDescriptor(
  source: DeepPartial<CustomFilterNumberValueDescriptor> = {}
): CustomFilterNumberValueDescriptor {
  const target = new CustomFilterNumberValueDescriptor();
  return _merge(target, source);
}
