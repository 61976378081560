export enum DisplayStrategies {
  Pie = "Pie",
  Donut = "Donut",
  CategoryVerticalBar = "CategoryVerticalBar",
  CategoryHorizontalBar = "CategoryHorizontalBar"
}

export const CategoryBarDisplayStrategies = [
  DisplayStrategies.CategoryHorizontalBar,
  DisplayStrategies.CategoryVerticalBar
];

export const CategoryPieDisplayStrategies = [DisplayStrategies.Pie, DisplayStrategies.Donut];
