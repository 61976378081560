import { Component, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { SignalBrowserComponent } from "../../../browsing/components/signal-browser/signal-browser.component";
import { EnvironmentSelector } from "../../../environment/services/environment.selector";
import { BaseDialogComponent } from "../../../shared/components/base-dialog/base-dialog.component";

@Component({
  selector: "signal-browser-dialog",
  templateUrl: "./signal-browser-dialog.component.html",
  styleUrls: ["./signal-browser-dialog.component.scss"]
})
export class SignalBrowserDialogComponent extends BaseDialogComponent {
  @ViewChild("signalBrowser", { static: true }) signalBrowser: SignalBrowserComponent;

  constructor(
    public dialogRef: MatDialogRef<SignalBrowserDialogComponent>,
    protected store$: Store<any>,
    protected environmentSelector: EnvironmentSelector
  ) {
    super();
  }

  close() {
    this.dialogRef.close();
  }

  confirm() {
    this.dialogRef.close(this.signalBrowser.selectedItem);
  }
}
