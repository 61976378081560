import { Injectable } from "@angular/core";
import { StatusConverter, StatusTitle } from "ui-core";

@Injectable()
export class AzureStatusConverter implements StatusConverter {
  public convert(status: number): StatusTitle {
    switch (status) {
      case 0:
        return StatusTitle.Ok;
      case 1:
        return StatusTitle.Warn;
      case 2:
        return StatusTitle.Off;
      case 3:
        return StatusTitle.Error;
      default:
        return StatusTitle.Undefined;
    }
  }
}
