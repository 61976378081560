import "reflect-metadata";

import { PropertyDescriptor } from "../../meta/models/property-descriptor";
import { typeProviderFactory } from "../../meta/services/type-provider";

const typeProvider = typeProviderFactory();

export function DefaultValue(defaultValue: any) {
  return function (proto: Object, key: string) {
    const typeDescriptor = typeProvider.addType({
      constructorFunction: proto.constructor as new () => any
    });
    const typeConstructorFunction = Reflect.getMetadata("design:type", proto, key);
    const partialPropertyDescriptor: Partial<PropertyDescriptor> = {
      defaultValue: defaultValue,
      constructorFunction: typeConstructorFunction
    };

    typeDescriptor.upsertProperty(key, typeConstructorFunction, partialPropertyDescriptor);
  };
}
