import { mobileBreakpoint, tabletBreakpoint } from "../../../style-variables";

export enum DisplayMode {
  Mobile = "Mobile",
  Tablet = "Tablet",
  Desktop = "Desktop"
}

export function getDisplayMode(): DisplayMode {
  if (window.innerWidth <= mobileBreakpoint) {
    return DisplayMode.Mobile;
  }
  if (window.innerWidth <= tabletBreakpoint) {
    return DisplayMode.Tablet;
  }
  return DisplayMode.Desktop;
}
