<div class="connector-item__wrapper">
  <span class="connector-item__name">{{ dataConnector.title }}</span>
  <div class="connector-item__button-wrapper">
    <i
      class="connector-item__button edit-button abb-icon Settings"
      title="{{ translationService.buttons.Edit | translate }}"
      (click)="edit($event)"
    ></i>
    <i
      *ngIf="!dataConnector.isDynamicallyCreated"
      class="connector-item__button delete-button abb-icon Trash"
      (click)="remove($event)"
    ></i>
  </div>
</div>
