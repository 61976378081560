import { v4 as uuid } from "uuid";
import { DataTransferObject } from "../../../core/models/data-transfer-object";
import { construct } from "../../../core/services/construct.helper";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { ConfigurableEnum } from "../../../meta/decorators/configurable-enum.decorator";
import { Configurable } from "../../../meta/decorators/configurable.decorator";
import { EditableType } from "../../../meta/decorators/editable-type.decorator";
import { Key } from "../../../meta/decorators/key.decorator";
import { OnPropertyChange } from "../../../meta/decorators/on-property-change.decorator";
import { Serializable } from "../../../meta/decorators/serializable.decorator";
import { Title } from "../../../meta/decorators/title.decorator";
import { EditorType } from "../../../meta/models/editor-type";
import { PropertyInfo } from "../../../meta/models/property-info";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { DeepPartial } from "../../../ts-utils/models/deep-partial.type";
import { FooterFunctions } from "./table-footer-functions";

const TYPE_NAME = "TableFooterDescriptor";

@EditableType({ fullName: TYPE_NAME, title: "table-footer-descriptor" })
export class TableFooterDescriptor implements DataTransferObject {
  typeName: string = TYPE_NAME;

  @Key()
  @Serializable()
  id!: string;

  @Title()
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Title,
    editorType: EditorType.TextBox
  })
  @Serializable(FooterFunctions.None)
  label!: string;

  @OnPropertyChange<any, Partial<TableFooterDescriptor>, FooterFunctions>(onFunctionChange)
  @ConfigurableEnum({
    enumSource: FooterFunctions,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.TableFooterFunction
  })
  @Serializable(FooterFunctions.None)
  function!: FooterFunctions;

  constructor(footerDescriptor: DeepPartial<TableFooterDescriptor> = {}) {
    construct(this, footerDescriptor, TYPE_NAME, { id: uuid() });
  }
}

function onFunctionChange(
  _context: any,
  owner: Partial<TableFooterDescriptor>,
  propertyChange: PropertyInfo<FooterFunctions>
): Partial<TableFooterDescriptor> {
  if (isDefined(propertyChange.value)) {
    return {
      label: propertyChange.value,
      function: propertyChange.value
    };
  }
  return owner;
}
