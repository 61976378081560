<div [ngClass]="horizontalLayout ? 'historic-range__horizontal' : 'historic-range__vertical'">
  <div class="historic-range__item-wrapper">
    <div class="historic-range__item">
      <span class="date-label">{{ localizer.filterComponent.From | translate }} </span>
      <div
        #startDateTime
        class="historic-range__date-time-input-wrapper"
        [ngClass]="{ 'time--error': !isStartDateValid }"
      >
        <input
          #startDateTimeInput
          focusVisualization
          [focusedElement]="startDateTime"
          class="filter__date-time date-time"
          [value]="timeRange.from | moment : dateTimeFormat"
          (keyup)="startTimeRangeUpdate$.next($event.target.value)"
          title="{{ dateTimeFormat }}"
        />
        <i
          class="abb-icon abb-icon--small Time_and_date app-filter-button date-time-picker__button"
          [class.date-time-picker__button--selected]="isStartDateTimePickerOpened"
          (click)="showOrHideDateTimePicker(!isStartDateTimePickerOpened, false, $event)"
          title="{{ localizer.filterComponent.DateTimePicker | translate }}"
        ></i>
      </div>
    </div>
  </div>
  <div class="historic-range__item-wrapper">
    <div class="historic-range__item">
      <span class="date-label">{{ localizer.filterComponent.To | translate }}</span>
      <div
        #endDateTime
        class="historic-range__date-time-input-wrapper"
        [ngClass]="{ 'time--error': !isEndDateValid }"
      >
        <input
          #endDateTimeInput
          focusVisualization
          [focusedElement]="endDateTime"
          class="filter__date-time date-time"
          [value]="timeRange.to | moment : dateTimeFormat"
          (keyup)="endTimeRangeUpdate$.next($event.target.value)"
          title="{{ dateTimeFormat }}"
        />
        <i
          class="abb-icon abb-icon--small Time_and_date app-filter-button date-time-picker__button"
          [class.date-time-picker__button--selected]="isEndDateTimePickerOpened"
          (click)="showOrHideDateTimePicker(false, !isEndDateTimePickerOpened, $event)"
          title="{{ localizer.filterComponent.DateTimePicker | translate }}"
        ></i>
      </div>
    </div>
  </div>
</div>
