import { Injectable } from "@angular/core";
import { DataConnectorViewDto } from "../../data-connectivity/models/data-connector-view";
import { PropertyInfo } from "../../meta";
import { tryGetPropertyByPath } from "../../meta/helpers/property-extraction.helper";
import { DynamicDefaultDescriptor } from "../../meta/models/dynamic-default/dynamic-default-descriptor";
import {
  DynamicDefaultType,
  isConnectorDefault
} from "../../meta/models/dynamic-default/dynamic-default-type";
import { IndexedDynamicDefault } from "../../meta/models/dynamic-default/indexed-dynamic-default";
import { TypeProvider } from "../../meta/services/type-provider";
import { overridePropertyByPathCreatingIntermediates } from "../../property-sheet/helpers/override-property-by-path.helper";
import {
  first,
  isDefined,
  isEmptyOrNotDefined,
  isEmptyOrNotDefined2,
  isNotDefined,
  last,
  Maybe,
  mergeDeep
} from "../../ts-utils";
import { BaseViewConfigDto } from "../models";
import { EvaluationTargetType } from "../models/dynamic-defaults-evaluator-models";
import { DataConnectorDescriptor } from "../models/store/data-connector-descriptor";
import { DynamicDefaultsEvaluatorLogger } from "./dynamic-defaults-evaluator-logger";

@Injectable()
export class DynamicDefaultsEvaluatorCore {
  private logger: Maybe<DynamicDefaultsEvaluatorLogger> = null;
  // to debug unit property, use new DynamicDefaultsEvaluatorLogger(["unit"]);

  constructor(private typeProvider: TypeProvider) {}

  public evaluate<T extends BaseViewConfigDto>(
    target: EvaluationTargetType<T>,
    connectorDescriptors: DataConnectorDescriptor[]
  ): EvaluationTargetType<T> {
    const typeDescriptor = this.typeProvider.getType(target.typeName);
    const propsToEvaluate = this.typeProvider
      .getAllPropertyItemsDeep(typeDescriptor, target)
      .filter((prop) => !isEmptyOrNotDefined(prop.descriptor.dynamicDefaults));

    const userConfiguredValues: IndexedDynamicDefault[] = propsToEvaluate
      .map((prop: PropertyInfo<unknown>) => ({
        propPath: prop.localPath,
        descriptor: {
          type: DynamicDefaultType.Value,
          priority: 0,
          constant: prop.value
        }
      }))
      .filter((indexedDefault: IndexedDynamicDefault) =>
        isDefined(indexedDefault.descriptor.constant)
      );

    const dynamicDefaults: IndexedDynamicDefault[] = propsToEvaluate
      .map((propInfo) =>
        propInfo.descriptor.dynamicDefaults.map((dynamicDefault: DynamicDefaultDescriptor) => {
          return {
            propPath: propInfo.localPath,
            descriptor: dynamicDefault
          };
        })
      )
      .flat();
    const itemsToEvaluate = userConfiguredValues.concat(dynamicDefaults);
    itemsToEvaluate.sort((a, b) => b.descriptor.priority - a.descriptor.priority);
    const evaluatedProps: Partial<EvaluationTargetType<T>> = itemsToEvaluate.reduce(
      (acc: Partial<EvaluationTargetType<T>>, indexedDefault: IndexedDynamicDefault) =>
        this.evaluateDefault(acc, indexedDefault, connectorDescriptors, target),
      {}
    );
    const evaluatedTarget: EvaluationTargetType<T> = mergeDeep(target, evaluatedProps);
    return evaluatedTarget;
  }

  private evaluateDefault<T extends BaseViewConfigDto>(
    evaluatedProps: Partial<EvaluationTargetType<T>>,
    toEvaluate: IndexedDynamicDefault,
    connectorDescriptors: DataConnectorDescriptor[],
    target: EvaluationTargetType<T>
  ): Partial<EvaluationTargetType<T>> {
    const prevValue = tryGetPropertyByPath(evaluatedProps, toEvaluate.propPath) as unknown;
    if (isEmptyOrNotDefined2(prevValue)) {
      const descriptor: DynamicDefaultDescriptor = toEvaluate.descriptor;
      const evaluatedDefault = isConnectorDefault(descriptor.type)
        ? this.evaluateConnectorsDefault(descriptor, connectorDescriptors)
        : this.evaluateSimpleDefault(descriptor, target, evaluatedProps);
      this.logger?.log(
        toEvaluate.propPath,
        descriptor,
        "evaluated to " + this.logger.valueToString(evaluatedDefault)
      );
      if (evaluatedDefault !== undefined) {
        overridePropertyByPathCreatingIntermediates(
          toEvaluate.propPath,
          evaluatedProps,
          evaluatedDefault
        );
      }
    } else {
      this.logger?.log(
        toEvaluate.propPath,
        toEvaluate.descriptor,
        "already has value " + this.logger.valueToString(prevValue)
      );
    }
    return evaluatedProps;
  }

  private evaluateConnectorsDefault(
    descriptor: DynamicDefaultDescriptor,
    connectorDescriptors: DataConnectorDescriptor[]
  ): unknown {
    const matchingConnector = this.findMatchingConnector(descriptor.role, connectorDescriptors);
    if (isDefined(matchingConnector)) {
      return this.evaluateConnectorDefault(descriptor, matchingConnector);
    }

    return undefined;
  }

  private findMatchingConnector(
    descriptorRole: Maybe<string>,
    connectorDescriptors: DataConnectorDescriptor[]
  ): Maybe<DataConnectorDescriptor> {
    const matchingConnectors = isDefined(descriptorRole)
      ? connectorDescriptors.filter((c) => c.connector.role === descriptorRole)
      : connectorDescriptors;
    return first(matchingConnectors);
  }

  private evaluateConnectorDefault(
    descriptor: DynamicDefaultDescriptor,
    connectorDesc: DataConnectorDescriptor
  ): unknown {
    if (descriptor.type === DynamicDefaultType.DataLastValue) {
      const lastPoint = last(connectorDesc.connector.dataPoints);
      return lastPoint?.y;
    } else {
      return this.evaluateConnectorPropertyDefault(descriptor, connectorDesc);
    }
  }

  private evaluateConnectorPropertyDefault(
    descriptor: DynamicDefaultDescriptor,
    connectorDesc: DataConnectorDescriptor
  ): any {
    if (isNotDefined(descriptor.property)) {
      return undefined;
    }
    const connector = connectorDesc.connector;
    switch (descriptor.type) {
      case DynamicDefaultType.ConnectorMetadataProperty:
        return connector.properties?.[descriptor.property];
      case DynamicDefaultType.LastDataPointProperty: {
        const lastPoint = last(connector.dataPoints);
        return lastPoint?.properties?.[descriptor.property];
      }
      case DynamicDefaultType.ConnectorViewProperty:
        const connectorView = connectorDesc.connectorView;
        return connectorView?.[descriptor.property as keyof DataConnectorViewDto];
      default:
        throw new Error(`Unknown connector-based dynamic default type: ${descriptor.type}`);
    }
  }

  private evaluateSimpleDefault<T extends BaseViewConfigDto>(
    descriptor: DynamicDefaultDescriptor,
    target: EvaluationTargetType<T>,
    evaluatedProps: Partial<EvaluationTargetType<T>>
  ): any {
    switch (descriptor.type) {
      case DynamicDefaultType.Value:
        return descriptor.constant;
      case DynamicDefaultType.Function:
        if (isNotDefined(descriptor.function)) {
          throw new Error("Function expected.");
        }
        return descriptor.function(mergeDeep(target, evaluatedProps));
      default:
        throw new Error(`Unknown simple dynamic default type: ${descriptor.type}`);
    }
  }
}
