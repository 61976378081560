<div
  class="runtime-period-type__container"
  [ngClass]="{ 'runtime-period-type__container--vertical': !canExpandHorizontally }"
  (clickOutside)="closeIfDropdown()"
>
  <div [ngClass]="{ 'element--hidden': isFilterBarSelected && canExpandHorizontally }">
    <filter-toolbar-element
      [buttonConfig]="toggleButton"
      [selectedValueLabel]="runtimePeriodType"
      [isDropdown]="!canExpandHorizontally"
      [isOpened]="isFilterBarSelected"
    ></filter-toolbar-element>
  </div>
  <div class="runtime-period-type__filters" *ngIf="isFilterBarSelected">
    <div
      class="runtime-period-type__icon-item"
      [ngClass]="{ 'element--hidden': !canExpandHorizontally }"
    >
      <i [class]="'abb-icon abb-icon--medium ' + icon"></i>
    </div>
    <div
      class="runtime-period-type__list"
      [ngClass]="{ 'runtime-period-type__list--vertical': !canExpandHorizontally }"
      *ngIf="periodTypes"
    >
      <ul class="list__wrapper" [ngClass]="{ 'list__wrapper--vertical': !canExpandHorizontally }">
        <li
          *ngFor="let pType of periodTypes"
          [ngClass]="{
            'list__period-type-item': canExpandHorizontally,
            'list__period-type-item--vertical': !canExpandHorizontally,
            'list__period-type-item--selected': pType === runtimePeriodType && canExpandHorizontally,
            'list__period-type-item--vertical-selected':
              pType === runtimePeriodType && !canExpandHorizontally
          }"
          (click)="onChangePeriodType(pType, $event)"
        >
          {{ pType }}
        </li>
      </ul>
    </div>
    <button
      class="app-filter-button app-filter-button__collapse"
      tabindex="-1"
      [ngClass]="{ 'element--hidden': !canExpandHorizontally, selected: isFilterBarSelected }"
      (click)="closeBar($event)"
    >
      <i class="abb-icon abb-icon--medium Left"></i>
    </button>
  </div>
</div>
