import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { ErrorInfo, FeatureSelector, FeedbackFormData, FeedbackService, Maybe } from "ui-core";
import { RdsFullStoreState } from "../../models/rds-store-state";
import { HierarchyService } from "../hierarchy.service";

@Injectable()
export class RdsFeedbackService extends FeedbackService {
  public shouldShowFeedbackOption = true;

  constructor(
    protected hierarchyService: HierarchyService,
    protected featureSelector: FeatureSelector
  ) {
    super(featureSelector);
  }

  public sendFeedback(
    feedbackFormData: FeedbackFormData,
    fullStoreState: Maybe<RdsFullStoreState> = null,
    errors: ErrorInfo[] = []
  ): Observable<boolean> {
    return of(true);
  }

  public getStoreState(): RdsFullStoreState {
    const storeState = super.getStoreState();
    return {
      ...storeState,
      hierarchyState: this.hierarchyService.getHierarchyState(),
      clientDataState: this.hierarchyService.getclientDataState()
    } as RdsFullStoreState;
  }
}
