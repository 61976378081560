import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { PathPredicate, PredicateType } from "../../../core/models/equipment-path";
import { isDefined } from "../../../ts-utils";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { EquipmentNode, NodeSelectionInfo } from "../../models/equipment-node";

@Component({
  selector: "equipment-path-node",
  templateUrl: "equipment-path-node.component.html",
  styleUrls: ["equipment-path-node.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EquipmentPathNodeComponent {
  @Input()
  equipment: Maybe<EquipmentNode>;

  @Input()
  selectionInfo: Maybe<NodeSelectionInfo>;

  @Output() selected: EventEmitter<PathPredicate> = new EventEmitter<PathPredicate>();

  public applyLightTheme: boolean = true;

  private predicates(): PathPredicate[] {
    return this.selectionInfo?.lastStepToThisNode?.predicates ?? [];
  }

  public isNodeNameFiltering(): boolean {
    if (isDefined(this.selectionInfo) && this.selectionInfo.isInSelectedPath) {
      const predicates = this.predicates();
      return (
        predicates.length === 0 ||
        predicates.find((predicate) => predicate.type === PredicateType.NodeName) !== undefined
      );
    }
    return false;
  }

  public isClassFiltering(className: string): boolean {
    if (isDefined(this.selectionInfo) && this.selectionInfo.isInSelectedPath) {
      return (
        this.predicates().find(
          (predicate) =>
            predicate.type === PredicateType.EquipmentClass && predicate.expected === className
        ) !== undefined
      );
    }
    return false;
  }

  public nameClicked(): void {
    const name = this.equipment?.name;
    if (isDefined(name)) {
      const selectedWith: PathPredicate = {
        type: PredicateType.NodeName,
        expected: name
      };
      this.selected.emit(selectedWith);
    }
  }

  public classClicked(cls: string): void {
    const selectedWith: PathPredicate = {
      type: PredicateType.EquipmentClass,
      expected: cls
    };
    this.selected.emit(selectedWith);
  }
}
