import { Injectable } from "@angular/core";
import { FilterConfigurationDto } from "../../../core/models/filter/filter-configuration";
import { GeneralSettingsSelector } from "../../../elements/services/entity-selectors/general-settings.selector";
import { IEntityConverter } from "../../../meta/services/entity-converter";
import { REPORT_FILTER_ID } from "../../models";
import { GlobalFilterViewModel } from "../../models/filter/global-filter-view-model";

@Injectable()
export class GlobalFilterViewModelDeserializer
  implements IEntityConverter<FilterConfigurationDto, GlobalFilterViewModel>
{
  constructor(private generalSettingsSelector: GeneralSettingsSelector) {}

  convert(globalFilter: FilterConfigurationDto): GlobalFilterViewModel {
    return new GlobalFilterViewModel({
      id: REPORT_FILTER_ID,
      timeRange: globalFilter.timeRange,
      generalSettings: this.generalSettingsSelector.getGeneralSettings()
    });
  }
}
