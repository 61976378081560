import { Component } from "@angular/core";

@Component({
  selector: "c-spinner",
  template: `
    <div class="spinner">
      <svg class="spinner__svg" viewBox="25 25 50 50">
        <circle
          class="path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke-width="2"
          stroke-miterlimit="10"
        />
      </svg>
    </div>
  `,
  styleUrls: ["./spinner.component.scss"]
})
export class SpinnerComponent {}
