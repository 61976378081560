import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef } from "@angular/core";
import { Actions } from "@ngrx/effects";
import { Update } from "@ngrx/entity";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { DraggedItem } from "../../../core/models/drag/dragged-item";
import { DraggedItemType } from "../../../core/models/drag/dragged-item-type";
import { isGroupable } from "../../../core/models/groupable";
import { IDragDropService } from "../../../core/services/i-drag-drop.service";
import { DataConnectorDto } from "../../../data-connectivity";
import { getConnectorViewId } from "../../../data-connectivity/helpers/connector-view-id.helper";
import { switchDataSource } from "../../../data-connectivity/helpers/data-source-type.helper";
import { ComponentMetadataService } from "../../../data-connectivity/services/component-metadata.service";
import { DataConnectorFactory } from "../../../data-connectivity/services/deserializers/data-connector-factory.service";
import { Dispatcher } from "../../../dispatcher";
import { addConnectorToStore, sortConnectors } from "../../../elements/helpers/connectors.helper";
import { ComponentStateDto } from "../../../elements/models/component-state";
import { isWaterfallWidget } from "../../../elements/models/component-type.helper";
import {
  COMPONENT_STATE_DTO,
  DATA_CONNECTOR_DTO
} from "../../../elements/models/entity-type.constants";
import { DropHelper } from "../../../elements/services/drop.helper";
import { ComponentStateSelector } from "../../../elements/services/entity-selectors/component-state.selector";
import { DataConnectorViewSelector } from "../../../elements/services/entity-selectors/data-connector-view.selector";
import { DataConnectorSelector } from "../../../elements/services/entity-selectors/data-connector.selector";
import { DataConnectorViewActions } from "../../../elements/store/data-connector-view/data-connector-view.actions";
import { DataConnectorActions } from "../../../elements/store/data-connector/data-connector.actions";
import { LocalizationService } from "../../../i18n/localization.service";
import { OfType } from "../../../meta/decorators";
import { createUpdatedComponentsInfo } from "../../../meta/helpers/updated-entities-info.helper";
import { EditorType, Entity, EntityId, TypeDescriptor } from "../../../meta/models";
import { isEntity } from "../../../meta/models/entity";
import { TypeProvider } from "../../../meta/services/type-provider";
import { UndoRedoService } from "../../../shared/services/undo-redo.service";
import { Maybe, isDefined, isEmptyOrNotDefined, isNotDefined } from "../../../ts-utils";
import { CriticalError } from "../../../ts-utils/models/critical-error";
import { addOrMoveItemToGroup, getItemGroupId } from "../../helpers/groups.helper";
import { PropertySheetService } from "../../services/property-sheet.service";
import { ArrayEditorComponent, swapArrayItems } from "../array-editor/array-editor.component";
import { Group, GroupUpdate, ItemType } from "../array-editor/models";

@Component({
  selector: "entity-array-editor",
  templateUrl: "../array-editor/array-editor.component.html",
  styleUrls: ["../array-editor/array-editor.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@OfType(EditorType.EntityArray)
export class EntityArrayEditorComponent extends ArrayEditorComponent {
  protected parentEntityId: EntityId = "";

  constructor(
    dispatcher: Dispatcher,
    private propertySheetService: PropertySheetService,
    typeProvider: TypeProvider,
    componentSelector: ComponentStateSelector,
    metadataService: ComponentMetadataService,
    elementRef: ElementRef,
    actions$: Actions,
    cdr: ChangeDetectorRef,
    connectorViewSelector: DataConnectorViewSelector,
    private connectorFactory: DataConnectorFactory,
    private connectorSelector: DataConnectorSelector,
    public translationService: LocalizationService,
    protected dragDropService: IDragDropService,
    protected dropHelper: DropHelper,
    private undoRedoService: UndoRedoService
  ) {
    super(
      dispatcher,
      typeProvider,
      componentSelector,
      metadataService,
      elementRef,
      actions$,
      translationService,
      cdr,
      dragDropService,
      dropHelper,
      connectorViewSelector
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (isDefined(this.itemOwner)) {
      this.setParentEntity(this.itemOwner.id);
    } else {
      throw new CriticalError("Undefined owner entity.");
    }
    this.subscribeToGroupIdChanges();
    this.createOrRefreshArrayItemsSub();
    this.subscribeToItemOrderChanges();
  }

  subscribeToGroupIdChanges(): void {
    this.groupNameObserver$
      .pipe(debounceTime(1000), distinctUntilChanged(), takeUntil(this.unsubscribeSubject$))
      .subscribe((groupToUpdate: GroupUpdate) => {
        const target = groupToUpdate.event.target as HTMLTextAreaElement;
        const updatedGroupIndex: number = groupToUpdate.index;
        const newGroupId: string = target.value;
        this.validateNewGroupName(target.value, updatedGroupIndex);
        if (
          this.invalidGroupIndex === -1 &&
          this.doesGroupIdDistinctFromStore(updatedGroupIndex, newGroupId)
        ) {
          this.updateEntityGroupId(updatedGroupIndex, newGroupId);
        }
      });
  }

  createOrRefreshArrayItemsSub(): void {
    this.connectorSelector
      .selectForComponent(this.parentEntityId)
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((entities) => {
        this.value = [...entities];
        this.onValueChanged(this.value);
        this.recreateGroups(entities);
        this.invalidGroupIndex = -1;
        this.cdr.markForCheck();
      });
  }

  private validateNewGroupName(newGroupId: string, newGroupIdIndex: number): void {
    if (isEmptyOrNotDefined(newGroupId)) {
      this.invalidGroupIndex = newGroupIdIndex;
    } else {
      this.invalidGroupIndex = this.groups.some(
        (group: Group, index: number) => group.id === newGroupId && index !== newGroupIdIndex
      )
        ? newGroupIdIndex
        : -1;
    }
    this.cdr.markForCheck();
  }

  private doesGroupIdDistinctFromStore(groupIndex: number, groupId: string): boolean {
    return this.groups[groupIndex].id !== groupId;
  }

  private subscribeToItemOrderChanges(): void {
    this.connectorViewSelector
      .selectReorderedViewsByConnectors(this.value)
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((itemViews) => {
        if (!isEmptyOrNotDefined(itemViews)) {
          this.recreateGroups(this.value);
          this.cdr.markForCheck();
        }
      });
  }

  recreateGroups(items: ItemType[]): void {
    sortConnectors(items, this.connectorViewSelector);
    if (this.isGroupable) {
      this.groups = items.reduce((acc: Group[], item: ItemType) => {
        const itemGroupId: string = getItemGroupId(item, this.connectorViewSelector);
        const isGroupExpanded: Maybe<boolean> = this.groups.find(
          (group: Group) => group.id === itemGroupId
        )?.isExpanded;
        const existingGroup: Maybe<Group> = acc.find((group: Group) => group.id === itemGroupId);
        if (isDefined(existingGroup)) {
          existingGroup.items.push(item);
        } else {
          acc.push({
            id: itemGroupId,
            isExpanded: isGroupExpanded ?? false,
            items: [item]
          });
        }
        return acc;
      }, []);
    }
  }

  initDraggedTargetsArray(): void {
    this.groups.forEach(() => {
      this.draggedOverTargets.push(false);
    });
  }

  setDraggedOverTarget(index: number): void {
    this.draggedOverTargets[index] = true;
  }

  updateEntityGroupId(index: number, newGroupId: string): void {
    if (this.isGroupable) {
      const itemsInGroup: Maybe<ItemType[]> = this.updateGroupId(index, newGroupId);
      if (isDefined(itemsInGroup) && this.getArrayItemType().name === DATA_CONNECTOR_DTO) {
        this.updateGroupItemsInStore(itemsInGroup, newGroupId);
      }
    }
  }

  addArrayItem(event: Event): void {
    if (!this.typeDescriptor) {
      throw new CriticalError("Undefined type descriptor");
    }
    this.createNewEntity(this.typeDescriptor);
    event.stopPropagation();
  }

  addCustomArrayItem(event: Event): void {
    if (isNotDefined(this.typeDescriptor)) {
      throw new CriticalError("Undefined type descriptor");
    }
    this.createNewEntity(this.typeDescriptor, true);
    event.stopPropagation();
  }

  createNewEntity(entityType: TypeDescriptor, isCustom: boolean = false): Entity {
    //NOTE: currently paramterless constructor will work only for DC
    const basicEntity: Entity = new entityType.constructorFunction();
    const arrayItemTypeName: string = this.getArrayItemType().name;
    switch (arrayItemTypeName) {
      case DATA_CONNECTOR_DTO:
        const parentComponent: Maybe<ComponentStateDto> = this.findParentComponent();
        if (isDefined(parentComponent)) {
          const dataConnectorEntity: DataConnectorDto = !isCustom
            ? this.connectorFactory.createForComponentType(
                parentComponent.type,
                (basicEntity as DataConnectorDto).dataSource
              )
            : this.connectorFactory.createSumConnectorForComponentType(
                parentComponent.type,
                (basicEntity as DataConnectorDto).dataSource
              );
          addConnectorToStore(dataConnectorEntity, this.dispatcher, this.parentEntityId);
          this.propertySheetService.openOrReplaceTarget(dataConnectorEntity, parentComponent.id);
        } else {
          throw new Error("Parent component not found, " + this.parentEntityId);
        }

      default:
        return basicEntity;
    }
  }

  editArrayItem(entityToEdit: Entity, index?: number): void {
    const parentComponent: Maybe<ComponentStateDto> = this.findParentComponent();
    if (isDefined(parentComponent)) {
      this.propertySheetService.openOrReplaceTarget(entityToEdit, parentComponent.id);
    }
  }

  findParentComponent(): Maybe<ComponentStateDto> {
    return this.componentSelector.getById(this.parentEntityId);
  }

  getIconBasedOnDataSourceType(arrayItem: ItemType): string {
    switch (this.getArrayItemType().name) {
      case DATA_CONNECTOR_DTO:
        return switchDataSource<string>((arrayItem as DataConnectorDto).dataSource, {
          ApiDataSourceDto: () => "abb-icon abb-icon--small Database_new",
          EmptyDataSourceDto: () => "",
          EquipmentDataSourceDto: () => "dashboard-icon icon-Equipment",
          GroupedDataSourceDto: () => "",
          SignalDataSourceDto: () => "dashboard-icon icon-Trend",
          TabularDataSourceDto: () => "",
          ValueDataSourceDto: () => "abb-icon abb-icon--small Numericals"
        });
      default:
        return "";
    }
  }

  getItemDisplayRole(arrayItem: ItemType): string {
    return this.getArrayItemType().name === DATA_CONNECTOR_DTO
      ? (arrayItem as DataConnectorDto).role
      : "";
  }

  setParentEntity(id: EntityId): void {
    this.parentEntityId = id;
  }

  remove(entityToRemove: Entity, indexOfItemToRemove: number): void {
    super.remove(entityToRemove, indexOfItemToRemove);
    this.removeEntityFromStore(entityToRemove);
  }

  removeEntityFromStore(entityToRemove: Entity): void {
    this.dispatcher.dispatch(
      DataConnectorActions.deleteOne({
        componentId: this.parentEntityId,
        connector: entityToRemove as DataConnectorDto
      }),
      {
        withSnapshot: true,
        updatedEntitiesInfo: createUpdatedComponentsInfo([this.parentEntityId])
      }
    );
  }

  areEntitiesGroupable(items: Maybe<any[]>): boolean {
    return isDefined(items) && isEntity(items[0]) && isGroupable(items[0]);
  }

  updateGroupItemsInStore(newArrayItems: ItemType[], newGroupId: string): void {
    const itemUpdates: Update<ItemType>[] = newArrayItems.map((item: ItemType) => ({
      id: getConnectorViewId(item.id),
      changes: { groupId: newGroupId }
    }));
    this.dispatcher.dispatch(
      DataConnectorViewActions.updateMany({ connectorViewUpdates: itemUpdates }),
      {
        withSnapshot: true,
        updatedEntitiesInfo: createUpdatedComponentsInfo([this.parentEntityId])
      }
    );
  }

  updateConnectorGroupId(item: Entity, groupId: string): void {
    this.dispatcher.dispatch(
      DataConnectorViewActions.updateOne({
        connectorViewUpdate: {
          id: getConnectorViewId(item.id).toString(),
          changes: {
            groupId
          }
        }
      })
    );
  }

  canAcceptDrop(): boolean {
    if (isNotDefined(this.dragDropService.target)) {
      return false;
    }
    switch (this.dragDropService.target.type) {
      case DraggedItemType.Component:
        return false;
      case DraggedItemType.Signal:
      case DraggedItemType.DataConnector:
      default:
        return true;
    }
  }

  drop(event: DragEvent | TouchEvent, groupId: string, index?: number): void {
    this.undoRedoService.createSnapshot({
      updatedEntitiesInfo: createUpdatedComponentsInfo([this.parentEntityId])
    });
    this.resetDraggedOverTarget();
    const target = this.dragDropService.target;
    const groupItems = this.getGroupItemsByGroupId(groupId);
    if (isDefined(groupItems)) {
      const oldIndex: number = groupItems.findIndex((item) => item.id === target?.item.id);
      if (
        this.canSwapItems(oldIndex, index) &&
        this.getArrayItemType().name === DATA_CONNECTOR_DTO
      ) {
        swapArrayItems(groupItems, oldIndex, index);
        this.updateConnectorOrder(groupItems);
      }
    }
    if (target?.type === DraggedItemType.DataConnector) {
      this.addEntityToGroup(groupId, target.item);
    } else if (isDraggedFromSidebar(target)) {
      this.dropHelper.dropConnectorOnComponent(target, this.itemOwner, groupId);
    }

    event.stopPropagation();
    this.dragDropService.clear();
  }

  canSwapItems(oldIndex: number, newIndex: Maybe<number>): boolean {
    return isDefined(newIndex) && oldIndex !== -1;
  }

  addEntityToGroup(groupId: string, entity: DataConnectorDto): void {
    if (isDefined(entity)) {
      if (!isEntity(entity)) {
        throw new CriticalError("Item is not entity!");
      }

      this.addItemToGroup(groupId, entity);
      if (this.getArrayItemType().name === DATA_CONNECTOR_DTO) {
        this.updateConnectorGroupId(entity, groupId);
      }
    }
  }

  addItemToGroup(groupId: string, entity: DataConnectorDto): void {
    this.groups = addOrMoveItemToGroup(this.groups, groupId, entity, this.connectorViewSelector);
  }

  updateConnectorOrder(groupItems: ItemType[]): void {
    const connectorViewUpdates: Update<ItemType>[] = groupItems.map(
      (item: ItemType, index: number) => {
        return {
          id: getConnectorViewId(item.id),
          changes: { order: index }
        };
      }
    );
    this.dispatcher.dispatch(DataConnectorViewActions.updateMany({ connectorViewUpdates }));
  }

  dragStart(event: Event, item: ItemType, index?: number): void {
    const dragTarget: Maybe<DraggedItem> = this.getDragTarget(item);
    if (isDefined(dragTarget)) {
      this.dragDropService.enableDrag = true;
      this.dragDropService.setDragTarget(dragTarget, "");
    } else {
      this.dragDropService.enableDrag = false;
    }
  }

  protected getDragTarget(item: ItemType): DraggedItem {
    switch (this.getArrayItemType().name) {
      case COMPONENT_STATE_DTO: {
        return {
          type: DraggedItemType.Component,
          item: item
        };
      }
      case DATA_CONNECTOR_DTO: {
        return {
          type: DraggedItemType.DataConnector,
          item: item
        };
      }
      default:
        return {
          type: DraggedItemType.Signal,
          item: item
        };
    }
  }

  refreshValue(value: any): void {}

  resolveAddingCustomItem(): void {
    this.canAddCustomItem = isWaterfallWidget(this.itemOwner.type);
  }
}

function isDraggedFromSidebar(target: Maybe<DraggedItem>): boolean {
  return target?.type === DraggedItemType.Signal || target?.type === DraggedItemType.Equipment;
}
