import { Injectable } from "@angular/core";
import { WebServicesConfiguration } from "../../core/services/api.config";
import { IFilterSelector } from "../../core/services/filter/i-filter.selector";
import { HttpService } from "../../core/services/http.service";
import { QueryParamKeyConverter } from "../../core/services/query-param-key.converter";
import { QueryStringService } from "../../core/services/query-string.service";
import { TimeService } from "../../core/services/time.service";
import { DataConnectorFactory } from "../../data-connectivity/services/deserializers/data-connector-factory.service";
import { EquipmentToDataConnectorsConverter } from "../../data-connectivity/services/equipment-to-data-connectors.converter";
import { Dispatcher } from "../../dispatcher";
import { LocalizationService } from "../../i18n/localization.service";
import { DataConnectorSelector } from "../services/entity-selectors/data-connector.selector";
import { RuntimeSettingsSelector } from "../services/entity-selectors/runtime-settings.selector";
import { QueryParamsResolverService } from "../services/query-params-resolver.service";

@Injectable({ providedIn: "root" })
export class DataServiceParams {
  constructor(
    public connectorSelector: DataConnectorSelector,
    public apiConfig: WebServicesConfiguration,
    public httpService: HttpService,
    public equipmentToDataConnectorsConverter: EquipmentToDataConnectorsConverter,
    public queryStringService: QueryStringService,
    public dispatcher: Dispatcher,
    public timeService: TimeService,
    public dataConnectorFactory: DataConnectorFactory,
    public queryParamsResolver: QueryParamsResolverService,
    public localizationService: LocalizationService,
    public runtimeSettingsSelector: RuntimeSettingsSelector,
    public filterSelector: IFilterSelector,
    public queryParamKeyConverter: QueryParamKeyConverter
  ) {}
}
