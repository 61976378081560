import { ChangeDetectorRef, Component, HostBinding, OnInit } from "@angular/core";
import { Actions, ofType } from "@ngrx/effects";
import { take } from "rxjs/operators";
import { EquipmentPath } from "../../../core/models/equipment-path";
import { ePathToString } from "../../../core/services/equipment-path-to-string.helper";
import { Dispatcher } from "../../../dispatcher";
import { LocalizationService } from "../../../i18n/localization.service";
import { TypeProvider } from "../../../meta";
import { OfType } from "../../../meta/decorators/of-type.decorator";
import { EditorType } from "../../../meta/models/editor-type";
import { BaseEditorComponent } from "../../../property-sheet/components/base-editor.component";
import { EquipmentBrowserDialogActions } from "../../../shared/dialogs/actions";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";

@Component({
  selector: "equipment-model-editor",
  templateUrl: "equipment-model-editor.component.html",
  styleUrls: ["equipment-model-editor.component.scss"]
})
@OfType(EditorType.EquipmentBrowser)
@OfType(EditorType.EquipmentPathBrowser)
export class EquipmentModelEditorComponent extends BaseEditorComponent implements OnInit {
  public selectedEquipment: string = "";
  selectedPath: Maybe<EquipmentPath>;

  @HostBinding("attr.title")
  public get tooltipText(): string {
    return this.tooltip;
  }

  public usePathBrowser = false;

  constructor(
    private dispatcher: Dispatcher,
    protected cdr: ChangeDetectorRef,
    private actions$: Actions,
    protected typeProvider: TypeProvider,
    protected translationService: LocalizationService
  ) {
    super(cdr, typeProvider, translationService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.usePathBrowser =
      this.propertyInfo.descriptor.editorType === EditorType.EquipmentPathBrowser;
    this.resolveSelectedPathAndEquipment(this.value);
  }

  refreshValue(value): void {
    super.refreshValue(value);
    this.resolveSelectedPathAndEquipment(value);
  }

  private resolveSelectedPathAndEquipment(value): void {
    if (this.usePathBrowser) {
      this.selectedPath = value;
      this.selectedEquipment = isDefined(value) ? ePathToString(value) : "";
    } else {
      this.selectedEquipment = value ?? "";
    }
  }

  onInputChange(value: string) {
    if (this.usePathBrowser) {
      throw new Error("not supported");
    }
    this.selectedEquipment = value;
    this.onValueChanged(this.selectedEquipment);
  }

  // TODO this common logic can be moved into a superclass
  browse() {
    this.subscribeToDialogClose();
    this.openDialog();
  }

  private openDialog() {
    this.dispatcher.dispatch(
      EquipmentBrowserDialogActions.openEquipmentBrowserDialog({
        equipmentDialogInfo: {
          currentPath: this.usePathBrowser ? this.selectedPath : this.selectedEquipment,
          usePathBrowser: this.usePathBrowser,
          rootClass: "",
          openFullTree: false
        }
      })
    );
  }

  private subscribeToDialogClose() {
    this.actions$
      .pipe(ofType(EquipmentBrowserDialogActions.onEquipmentBrowserDialogClosed), take(1))
      .subscribe(({ result }) => {
        if (result != null) {
          if (this.usePathBrowser) {
            this.selectedPath = result as EquipmentPath;
            this.selectedEquipment = ePathToString(this.selectedPath);
            this.onValueChanged(this.selectedPath);
          } else {
            const isRootPath: boolean = result === "";
            this.selectedEquipment = isRootPath ? "/" : result;
            this.onValueChanged(this.selectedEquipment);
          }
        }
      });
  }

  onXClick = () => {
    this.selectedEquipment = "";
    this.selectedPath = null;
    this.onValueChanged(this.usePathBrowser ? this.selectedPath : this.selectedEquipment);
  };
}
