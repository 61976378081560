import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { ErrorCatchingActions, ReportDescription } from "../../core";
import { WebServicesConfiguration } from "../../core/services/api.config";
import { Dispatcher } from "../../dispatcher";
import { LocalizationService } from "../../i18n/localization.service";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import { IReportNameValidator } from "../../shared/services/i-report-name-validator";
import { Dictionary } from "../../ts-utils";
import { ReportConfiguration } from "../models/report-configuration";

// WILL BE OBSOLETE

@Injectable()
export class ReportsService extends IReportNameValidator {
  protected _currentReport$: Subject<ReportConfiguration> = new Subject<ReportConfiguration>();

  constructor(
    protected http: HttpClient,
    protected api: WebServicesConfiguration,
    protected dispatcher: Dispatcher,
    private translationService: LocalizationService
  ) {
    super();
  }

  get current$(): Subject<ReportConfiguration> {
    return this._currentReport$;
  }

  set current(value: ReportConfiguration) {
    this._currentReport$.next(value);
  }

  // TODO should be an redux action
  setAncestorsForReport(report: ReportConfiguration): Observable<ReportConfiguration> {
    const errorMessage = this.translationService.get(
      LOCALIZATION_DICTIONARY.snackBarMessages.AncestorsError
    );

    return this.http.get(this.api.reportsUrl).pipe(
      switchMap((reports: ReportConfiguration[]) => {
        report.ancestors = this.getAncestorsForReport(report.id, reports);
        return of(report);
      }),
      catchError((error) => {
        this.dispatcher.dispatch(
          ErrorCatchingActions.catchError({
            messageToDisplay: errorMessage,
            error: error,
            autoClose: true
          })
        );
        return of(null);
      })
    );
  }

  private getAncestorsForReport(reportId: string | number, reports: ReportConfiguration[]) {
    const dict: Dictionary<ReportConfiguration> = reports.reduce(
      (acc: Dictionary<ReportConfiguration>, report: ReportConfiguration) => {
        acc[report.id] = report;
        return acc;
      },
      {}
    );
    let targetId = reportId;
    const ancestors: ReportConfiguration[] = [];
    while (targetId) {
      const report = dict[targetId];
      if (report.id !== reportId) {
        ancestors.push(report);
      }
      targetId = report.parentId;
    }
    return ancestors;
  }

  getAllReports(): Observable<ReportDescription[]> {
    return of([]);
  }

  getUniqueIdentifier(reportName: string): string {
    return reportName;
  }
}
