import {
  AbstractControl,
  FormControl,
  FormGroupDirective,
  NgForm,
  ValidationErrors,
  ValidatorFn
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { isEmpty } from "../../ts-utils/helpers/is-empty.helper";

export const REPORT_NAME_REGEX = new RegExp(`^[a-zA-Z0-9$-_#%& ]{2,30}$`);
export class ReportStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export const reportNameValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const value = control?.value?.trim() || "";

  if (!REPORT_NAME_REGEX.test(value)) {
    return { reportNameError: true };
  } else {
    return null;
  }
};

export const reportExistsValidator =
  (reports: string[], originalReportName: string): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    const providedName = control.value ?? "";
    const exists = reports.some((r) => r.toLowerCase() === providedName.toLowerCase());

    if (exists || (!isEmpty(originalReportName) && providedName === originalReportName)) {
      return { reportExistsError: "Provided name already exists." };
    } else {
      return null;
    }
  };
