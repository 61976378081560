import "reflect-metadata";
import { DynamicDefaultDescriptor } from "../models/dynamic-default/dynamic-default-descriptor";
import { DynamicDefaultType } from "../models/dynamic-default/dynamic-default-type";
import { PropertyDescriptor } from "../models/property-descriptor";
import { TypeProvider } from "../services/type-provider";

const TYPE_PROVIDER = TypeProvider.getInstance();

export function DynamicDefaultFromData(connectorRole: string, priority: number) {
  return function (proto: Object, key: string) {
    const partialPropertyDescriptor: Partial<PropertyDescriptor> = {
      dynamicDefaults: [
        {
          type: DynamicDefaultType.DataLastValue,
          role: connectorRole,
          priority: priority
        }
      ]
    };
    TYPE_PROVIDER.upsertProperty(proto, key, partialPropertyDescriptor);
  };
}

export function DynamicDefaultFromMetadata(
  connectorRole: string,
  metadataProp: string,
  priority: number,
  lookInConnectorProperty = true,
  lookInLastDataPointProperty = true
) {
  return function (proto: Object, key: string) {
    const dynamicDefaults: DynamicDefaultDescriptor[] = [];
    if (lookInConnectorProperty) {
      dynamicDefaults.push({
        type: DynamicDefaultType.ConnectorMetadataProperty,
        role: connectorRole,
        priority: priority,
        property: metadataProp
      });
    }
    if (lookInLastDataPointProperty) {
      dynamicDefaults.push({
        type: DynamicDefaultType.LastDataPointProperty,
        role: connectorRole,
        priority: lookInConnectorProperty ? priority - 0.5 : priority,
        property: metadataProp
      });
    }
    const partialPropertyDescriptor: Partial<PropertyDescriptor> = {
      dynamicDefaults: dynamicDefaults
    };
    TYPE_PROVIDER.upsertProperty(proto, key, partialPropertyDescriptor);
  };
}

export function DynamicDefaultFromConnectorView(
  connectorRole: string,
  viewProp: string,
  priority: number
) {
  return function (proto: Object, key: string) {
    const partialPropertyDescriptor: Partial<PropertyDescriptor> = {
      dynamicDefaults: [
        {
          type: DynamicDefaultType.ConnectorViewProperty,
          role: connectorRole,
          property: viewProp,
          priority: priority
        }
      ]
    };
    TYPE_PROVIDER.upsertProperty(proto, key, partialPropertyDescriptor);
  };
}

export function DynamicDefaultFromValue(value: any, priority: number) {
  return function (proto: Object, key: string) {
    const partialPropertyDescriptor: Partial<PropertyDescriptor> = {
      dynamicDefaults: [
        {
          type: DynamicDefaultType.Value,
          constant: value,
          priority: priority
        }
      ]
    };
    TYPE_PROVIDER.upsertProperty(proto, key, partialPropertyDescriptor);
  };
}

export function DynamicDefaultFromFunction(fun: (viewConfig: any) => any, priority: number) {
  return function (proto: Object, key: string) {
    const partialPropertyDescriptor: Partial<PropertyDescriptor> = {
      dynamicDefaults: [
        {
          type: DynamicDefaultType.Function,
          function: fun,
          priority: priority
        }
      ]
    };
    TYPE_PROVIDER.upsertProperty(proto, key, partialPropertyDescriptor);
  };
}
