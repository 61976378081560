import { v4 as uuid } from "uuid";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import {
  Access,
  Configurable,
  EditableType,
  EditorType,
  PropertyCategory,
  PropertyDescriptor,
  Serializable,
  Title,
  Virtual
} from "../../../meta";
import { Key } from "../../../meta/decorators/key.decorator";
import { ValidationContext } from "../../../meta/models/validation-context";
import { CustomFilterValueType } from "../../../shared/models/custom-filter-value-type";
import { isNotDefined } from "../../../ts-utils/helpers/predicates.helper";
import { CriticalError } from "../../../ts-utils/models/critical-error";
import { DeepPartial } from "../../../ts-utils/models/deep-partial.type";
import { createCustomFilterValueDescriptor } from "../../helpers/filter/custom-filter-value-descriptor-creation.helper";
import { construct } from "../../services/construct.helper";
import { DataTransferObject } from "../data-transfer-object";
import { CustomFilterValueDescriptor } from "./custom-filter-value-descriptor";
import { CUSTOM_FILTER_TYPES } from "./filter-type-descriptor";

const TIME_RANGE_FROM: string = "From";
const TIME_RANGE_TO: string = "To";

const TYPE_NAME = "CustomFilterDescriptorDto";

// @dynamic
@EditableType({ fullName: TYPE_NAME })
export class CustomFilterDescriptorDto implements DataTransferObject {
  typeName = TYPE_NAME;

  id: string;

  @Key()
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Filter_Key,
    editorType: EditorType.TextBox,
    validationFunction: isKeyUnique
  })
  @Serializable("")
  public key!: string;

  @Title()
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Filter_Label,
    editorType: EditorType.TextBox,
    validationFunction: isLabelUnique
  })
  @Serializable("")
  public label!: string;

  @Serializable()
  @Virtual()
  public valueDescriptor!: CustomFilterValueDescriptor;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Filter_ReadOnly,
    editorType: EditorType.CheckBox
  })
  @Serializable(false)
  isReadOnly: boolean;

  @Serializable(false)
  isHidden: boolean;

  constructor(customFilter: DeepPartial<CustomFilterDescriptorDto> = {}) {
    customFilter = {
      ...customFilter,
      valueDescriptor: createCustomFilterValueDescriptor(
        customFilter.valueDescriptor?.typeName ?? CustomFilterValueType.Text,
        customFilter.valueDescriptor ?? {}
      )
    };
    construct(this, customFilter, TYPE_NAME, { id: uuid() });
  }
}

export function createPropertyDescriptor(
  customFilterProp: CustomFilterDescriptorDto,
  dynamicallyCreatedFrom: string
): PropertyDescriptor {
  const typeDesc = CUSTOM_FILTER_TYPES.find(
    (t) => t.type === customFilterProp.valueDescriptor.typeName
  );
  if (typeDesc == null) {
    throw new CriticalError(`Unknown filter type: ${customFilterProp.valueDescriptor.typeName}`);
  }
  const propDesc = PropertyDescriptor.create(
    customFilterProp.key,
    typeDesc.typeConstructorFunction,
    {
      displayName: customFilterProp.label,
      editorType: typeDesc.editor,
      category: PropertyCategory.Data,
      subCategory: LOCALIZATION_DICTIONARY.propertySheet.Filter,
      isHidden: customFilterProp.isHidden,
      access: customFilterProp.isReadOnly ? Access.ReadOnly : Access.Write,
      dynamicallyCreatedFrom,
      placeholderConfig: {
        text: LOCALIZATION_DICTIONARY.propertySheet.Inherited,
        combineWithRuntimeValue: false
      }
    }
  );
  return propDesc;
}

function isKeyUnique(value: string, _validationContext?: ValidationContext): boolean {
  return isPropertyUnique("key", value, _validationContext);
}

function isLabelUnique(value: string, _validationContext?: ValidationContext): boolean {
  return isPropertyUnique("label", value, _validationContext);
}

function isPropertyUnique(
  propertyName: string,
  value: string,
  _validationContext?: ValidationContext
): boolean {
  if (isTimeRangeValue(value)) {
    return false;
  }
  if (isNotDefined(_validationContext)) {
    return true;
  }
  const { itemOwner, parentInfo } = _validationContext;
  const customFilters = parentInfo.generalSettings.customFilterDeclarations;
  return !customFilters.some(
    (filter) => filter[propertyName] === value && filter.id !== itemOwner.id
  );
}

function isTimeRangeValue(value: string): boolean {
  return value === TIME_RANGE_FROM || value === TIME_RANGE_TO;
}
