import "reflect-metadata";
import { PropertyDescriptor } from "../models/property-descriptor";
import { typeProviderFactory } from "../services/type-provider";

const typeProvider = typeProviderFactory();

export function Hidden() {
  return function (proto: Object, key: string) {
    const partialPropertyDescriptor: Partial<PropertyDescriptor> = {
      isHidden: true
    };

    typeProvider.upsertProperty(proto, key, partialPropertyDescriptor);
  };
}
