import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap, tap } from "rxjs/operators";
import { FeedbackComponent } from "../../components/feedback/feedback.component";
import { FeedbackDialogActions } from "../actions/feedback-dialog.actions";
import { OpenDialogEffect } from "./dialog.effects";

@Injectable()
export class FeedbackDialogEffects extends OpenDialogEffect {
  constructor(private actions$: Actions, dialog: MatDialog) {
    super(dialog);
  }

  openFeedbackDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedbackDialogActions.openFeedbackDialog),
      tap(({}) => {
        this.dialogRef = this.dialog.open(FeedbackComponent);
      }),
      switchMap(() => {
        return this.dialogRef.afterClosed().pipe(
          map(() => {
            return FeedbackDialogActions.onFeedbackDialogClosed();
          })
        );
      })
    )
  );
}
