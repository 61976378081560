import { Injectable } from "@angular/core";
import { SUM_ROLE } from "../../../elements/components/waterfall-chart/roles";
import { EntityId } from "../../../meta";
import { TypeProvider } from "../../../meta/services/type-provider";
import { DataConnectorDto, DataSourceDto } from "../../models";
import { ComponentMetadataService } from "../component-metadata.service";
import { DataSourceModifiers } from "../data-source-modifiers";

@Injectable()
export class DataConnectorFactory {
  constructor(
    private typeProvider: TypeProvider,
    private metadataProvider: ComponentMetadataService,
    private dataSourceModifiers: DataSourceModifiers
  ) {}

  create(dto: Partial<DataConnectorDto>): DataConnectorDto {
    const result: DataConnectorDto = Object.keys(dto)
      .filter((key) => key !== "id" && key !== "dataSource")
      .reduce((acc, propertyName) => {
        acc[propertyName] = dto[propertyName];
        return acc;
      }, this.createWithId(dto.id));
    // do not overrite default data source
    if (dto.dataSource) {
      // IP should probably use DataSourceFactory
      result.dataSource = dto.dataSource as DataSourceDto;
    }
    return result;
  }

  createWithId(connectorId: EntityId): DataConnectorDto {
    const connector = new DataConnectorDto({ id: connectorId });
    return connector;
  }

  createForComponentType(componentType: string, dataSource: DataSourceDto): DataConnectorDto {
    const dataConnector = new DataConnectorDto({
      dataSource: this.configureNewDataSource(dataSource, componentType),
      role: this.metadataProvider.getDefaultRole(componentType).name
    });
    return dataConnector;
  }

  createSumConnectorForComponentType(
    componentType: string,
    dataSource: DataSourceDto
  ): DataConnectorDto {
    const dataConnector = new DataConnectorDto({
      dataSource: this.configureNewDataSource(dataSource, componentType),
      role: SUM_ROLE,
      isComputing: true,
      title: SUM_ROLE
    });
    return dataConnector;
  }

  configureNewDataSource(dataSource: DataSourceDto, componentType: string): DataSourceDto {
    const componentTypeDescriptor = this.typeProvider.getType(componentType);
    const modifier = this.dataSourceModifiers.getModifier(componentTypeDescriptor);
    return modifier ? modifier(dataSource) : dataSource;
  }
}
