import { EntityId } from "../../../meta/models/entity";
import { Dictionary, isDefined, isNotDefined } from "../../../ts-utils";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { FilterConfigurationDto } from "../../models/filter/filter-configuration";
import { IFilterSelector } from "../../services/filter/i-filter.selector";

export const RUNTIME_FILTER_ID: string = "Runtime";
export const REPORT_FILTER_ID: string = "Global";
export const FILTER_ID_SUFFIX = "-filter";
export const EVENT_RUNTIME_FILTER_SUFFIX = "_runtime";

export function isEventRuntimeFilter(filterId: Maybe<EntityId>): boolean {
  return filterId?.toString().includes(EVENT_RUNTIME_FILTER_SUFFIX) ?? false;
}

export function getDefaultFilterIdForEntity(
  entityId: EntityId,
  hasEventOrigin: boolean = false
): EntityId {
  if (entityId === RUNTIME_FILTER_ID) {
    return entityId;
  }
  return entityId.toString() + (hasEventOrigin ? EVENT_RUNTIME_FILTER_SUFFIX : FILTER_ID_SUFFIX);
}

export function getOrCreateEntityFilter(
  entityId: EntityId,
  filterSelector: IFilterSelector
): FilterConfigurationDto {
  const filterId = getDefaultFilterIdForEntity(entityId);
  const targetFilter = filterSelector.getById(filterId);
  return (
    targetFilter ??
    new FilterConfigurationDto({
      id: filterId,
      sourceFilterId: RUNTIME_FILTER_ID
    })
  );
}

export function alreadyHasEventRuntimeFilter(
  componentId: EntityId,
  componentFilterId: Maybe<EntityId>
): boolean {
  if (isNotDefined(componentFilterId)) {
    return false;
  }
  const runtimeFilterId = getDefaultFilterIdForEntity(componentId, true);
  return componentFilterId === runtimeFilterId;
}

export function determineSourceFilterId(
  filters: Dictionary<FilterConfigurationDto>,
  previousSourceFilter: Maybe<EntityId>
): string {
  const previousSourceFilterId: string = previousSourceFilter?.toString() ?? "";
  if (previousSourceFilterId.includes(EVENT_RUNTIME_FILTER_SUFFIX)) {
    const newSourceFilterId = previousSourceFilterId.replace(
      EVENT_RUNTIME_FILTER_SUFFIX,
      FILTER_ID_SUFFIX
    );
    return isDefined(filters[newSourceFilterId]) ? newSourceFilterId : RUNTIME_FILTER_ID;
  }
  return RUNTIME_FILTER_ID;
}
