import { ElementRef } from "@angular/core";
import { LimitValueConfigDto } from "../../elements/models/limit-value-config";
import { LimitsDto } from "../../elements/models/limits";
import { Dictionary, isDefined, isEmptyOrNotDefined, isNotDefined, Maybe } from "../../ts-utils";
import { LimitEditorVisualization } from "../models/limit-editor-visualization";
import { LimitProperties } from "../models/limit-properties";

export function getLimitValuesFromInputs(elementRef: ElementRef): LimitValueConfigDto {
  const inputs: HTMLInputElement[] = elementRef.nativeElement.querySelectorAll(
    ".limits-editor-tooltip__value-input"
  );
  return Array.from(inputs).reduce((acc: LimitValueConfigDto, input: HTMLInputElement) => {
    const limitName = input.getAttribute("name");
    if (isDefined(limitName)) {
      acc[limitName] = isEmptyOrNotDefined(input.value) ? null : Number(input.value);
    }
    return acc;
  }, {} as LimitValueConfigDto);
}

export function validateLimits(
  limitsToSave: LimitsDto,
  updatedLimitProperty: string,
  userValues: LimitValueConfigDto,
  editorsInfo: Dictionary<LimitEditorVisualization>
): LimitsDto {
  const evaluatedLimits = { ...limitsToSave };
  const limits: LimitProperties[] = Object.values(LimitProperties);
  limits.forEach((limitName, i) => {
    const userInputValue: Maybe<number> = userValues[limitName];
    if (isLimitValueValid(i, limits, userValues)) {
      editorsInfo[limitName].isInvalidValue = false;
      evaluatedLimits[limitName] = userInputValue;
    } else {
      if (limitName === updatedLimitProperty) {
        editorsInfo[limitName].isInvalidValue = true;
      }
    }
  });
  return evaluatedLimits;
}

export function isLimitValueValid(
  i: number,
  limits: LimitProperties[],
  userValues: LimitValueConfigDto
): boolean {
  const currentProcessedLimit = limits[i];
  if (isNotDefined(userValues[currentProcessedLimit])) {
    return true;
  }
  return (
    compareToFirstHigherLimit(i, limits, userValues) &&
    compareToFirstLowerLimit(i, limits, userValues)
  );
}

export function compareToFirstHigherLimit(
  i: number,
  limits: LimitProperties[],
  visibleValues: LimitValueConfigDto
): boolean {
  const currentProcessedLimit = limits[i];
  const firstHigherLimit = findFirstHigherLimit(i, limits, visibleValues);
  if (isNotDefined(firstHigherLimit)) {
    return true;
  }
  return firstHigherLimit >= visibleValues[currentProcessedLimit];
}

export function findFirstHigherLimit(
  i: number,
  limits: LimitProperties[],
  visibleValues: LimitValueConfigDto
): Maybe<number> {
  if (i === 0) {
    return null;
  }
  for (let j = i - 1; j >= 0; j--) {
    const limitName = limits[j];
    if (isDefined(visibleValues[limitName])) {
      return visibleValues[limitName];
    }
  }
  return null;
}

export function compareToFirstLowerLimit(
  i: number,
  limits: LimitProperties[],
  visibleValues: LimitValueConfigDto
): boolean {
  const currentProcessedLimit = limits[i];
  const firstLowerLimit = findFirstLowerLimit(i, limits, visibleValues);
  if (isNotDefined(firstLowerLimit)) {
    return true;
  }
  return firstLowerLimit <= visibleValues[currentProcessedLimit];
}

export function findFirstLowerLimit(
  i: number,
  limits: LimitProperties[],
  visibleValues: LimitValueConfigDto
): Maybe<number> {
  if (i === limits.length - 1) {
    return null;
  }
  for (let j = i + 1; j < limits.length; j++) {
    const limitName = limits[j];
    if (isDefined(visibleValues[limitName])) {
      return visibleValues[limitName];
    }
  }
  return null;
}
