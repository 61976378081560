import { ChangeDetectorRef, Component, ElementRef } from "@angular/core";
import Highcharts, { PointOptionsObject } from "highcharts";
import { last } from "lodash";
import { DataConnectorDto, DataPointDto } from "../../../data-connectivity";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { ComponentCategory, EditableWidget, LayoutBuilder } from "../../../meta";
import { Maybe, isDefined, isNotDefined, isNumber } from "../../../ts-utils";
import { ConnectorRoles } from "../../decorators/connector-roles.decorator";
import { MaxConnectors } from "../../decorators/max-connectors.decorator";
import { View } from "../../decorators/view.decorator";
import { WATERFALL_CHART } from "../../models/element-type.constants";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import {
  PRIMARY_X_AXIS_ID,
  PRIMARY_Y_AXIS_ID
} from "../../services/highcharts/base-highcharts-options.helper";
import { ComponentConstructorParams } from "../base/component-constructor-params";
import { ChartComponent } from "../chart/chart.component";
import { Roles, SUM_ROLE } from "./roles";
import { WaterfallChartViewConfig } from "./view-config";

@Component({
  selector: "waterfall-chart",
  templateUrl: "./waterfall-chart.component.html",
  styleUrls: ["./waterfall-chart.component.scss"]
})
@LayoutBuilder(
  ComponentCategory.Category,
  WATERFALL_CHART,
  "icon-Plain-Single-value",
  "dashboard-icon",
  LOCALIZATION_DICTIONARY.layoutEditor.WaterfallChart
)
@MaxConnectors(20)
@ConnectorRoles(Roles)
@EditableWidget({
  fullName: WATERFALL_CHART,
  title: "waterfall-chart"
})
export class WaterfallChartComponent extends ChartComponent {
  public Highcharts = Highcharts;
  interpolatedViewConfig!: WaterfallChartViewConfig;

  @View(WaterfallChartViewConfig)
  public get view(): WaterfallChartViewConfig {
    return this.currentState.view as WaterfallChartViewConfig;
  }

  constructor(
    params: ComponentConstructorParams,
    hostElementRef: ElementRef,
    cdr: ChangeDetectorRef
  ) {
    super(params, hostElementRef, cdr);
  }

  protected updateChartData(): void {
    const interpolatedProperties =
      this.propertyInterpolationService.prepareAndInterpolateProperties<WaterfallChartViewConfig>(
        this.currentState,
        this.dataAccessor.getAllConnectors()
      );
    this.interpolatedViewConfig = interpolatedProperties.viewConfig;
    this.resetChartData();
    this.createHighchartsSeries(interpolatedProperties.connectorDescriptors);
  }

  protected setChartOptions(): void {
    const component = this;
    const options: Highcharts.Options = {
      chart: {
        type: "waterfall"
      },
      title: {
        text: this.interpolatedViewConfig.title ?? ""
      },
      legend: {
        enabled: this.interpolatedViewConfig.showLegend
      },
      xAxis: [
        {
          id: PRIMARY_X_AXIS_ID,
          type: "category",
          labels: {
            style: { fontWeight: "bold" },
            formatter: function () {
              return component.formatXAxisTicks(String(this.value));
            }
          }
        }
      ],
      yAxis: [
        {
          id: PRIMARY_Y_AXIS_ID,
          labels: {
            formatter: function () {
              return Highcharts.numberFormat(Number(this.value), 0);
            }
          }
        }
      ]
    };
    this.mergeChartOptions(options);
  }

  private resetChartData(): void {
    if (isDefined(this.chartObject)) {
      this.chartObject.update(
        {
          series: []
        },
        true,
        true,
        false
      );
    }
  }

  private createHighchartsSeries(connectorDescriptors: DataConnectorDescriptor[]): void {
    const component = this;
    const data: PointOptionsObject[] = [];
    connectorDescriptors.forEach((descriptor: DataConnectorDescriptor, index: number) => {
      const { connector, connectorView } = descriptor;
      if (isNotDefined(connector) && isNotDefined(connectorView)) {
        return;
      }
      const dcValue: PointOptionsObject = {
        id: connector.id.toString(),
        name: this.createNameForXAxisTicks(connector.title, connector.id?.toString()),
        y: this.resolvePointValue(connector?.dataPoints),
        color: connectorView.color
          ? connectorView.color
          : this.colorService.getSeriesColorAtIndex(index),
        isIntermediateSum: connector.role === SUM_ROLE ? true : false,
        isSum: false,
        selected: false,
        dataLabels: {
          overflow: "allow",
          crop: false,
          inside: false,
          y: -20,
          verticalAlign: "top",
          color: this.interpolatedViewConfig.foregroundColor,
          style: {
            fontWeight: "normal",
            textOutline: "none"
          }
        },
        borderColor: this.interpolatedViewConfig.foregroundColor
      };
      data.push(dcValue);
    });
    this.chartObject?.addSeries(
      {
        name: "Data Connectors",
        type: "waterfall",
        data: data,
        dataLabels: {
          enabled: true,
          verticalAlign: "top",
          inside: false,
          formatter: function () {
            return this.y + " " + component.getUnit();
          }
        },
        lineColor: this.interpolatedViewConfig.foregroundColor
      },
      true,
      false
    );
  }

  private resolvePointValue(dataPoints: Maybe<DataPointDto[]>): number {
    const lastDataPoint = last(dataPoints);
    if (isNumber(lastDataPoint?.y)) {
      const dataPointValue = Number(lastDataPoint.y.toFixed(2));
      if (isDefined(dataPointValue)) {
        return dataPointValue;
      }
    }
    return 0;
  }

  public getUnit(): string {
    const dataConnectors = this.dataAccessor.getAllConnectors();
    let unit;
    dataConnectors.forEach((connector: DataConnectorDto) => {
      if (isDefined(connector?.properties?.unit)) {
        unit = connector?.properties.unit;
      }
    });
    if (isNotDefined(unit)) {
      unit = this.interpolatedViewConfig.unit;
    }
    return unit;
  }

  private formatXAxisTicks(value: string): string {
    return value.split("(")[0].trim();
  }

  private createNameForXAxisTicks(title: Maybe<string>, id: Maybe<string>): string {
    if (isDefined(title) && isDefined(id)) {
      return title + "(" + id + ")";
    }
    return "";
  }
}
