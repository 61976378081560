import { Pipe, PipeTransform } from "@angular/core";
import { isNotDefined } from "../../ts-utils";
import { SubcategoryKeyValue } from "../models/property-sheet-config";
import { PropertySubcategory, PROPERTY_SUBCATEGORY_PREFIX } from "../models/property-subcategory";

@Pipe({ name: "sortSubcategory" })
export class SortSubcategoryPipe implements PipeTransform {
  public transform(subCategories: SubcategoryKeyValue[]) {
    return subCategories.sort((subCat1: SubcategoryKeyValue, subCat2: SubcategoryKeyValue) => {
      const subCatName1 = subCat1.key.replace(PROPERTY_SUBCATEGORY_PREFIX, "");
      const subCatName2 = subCat2.key.replace(PROPERTY_SUBCATEGORY_PREFIX, "");
      if (isNotDefined(PropertySubcategory[subCatName1 as keyof typeof PropertySubcategory])) {
        return 1;
      }
      if (isNotDefined(PropertySubcategory[subCatName2 as keyof typeof PropertySubcategory])) {
        return -1;
      }
      return (
        PropertySubcategory[subCatName1 as keyof typeof PropertySubcategory] -
        PropertySubcategory[subCatName2 as keyof typeof PropertySubcategory]
      );
    });
  }
}
