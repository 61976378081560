<ng-container>
  <div *ngIf="!legendItem.hidden" class="legend__item-container">
    <div class="legend__item-tag">
      <!-- NOTE: both colors are needed, one for line, and other for circle case -->
      <div
        [ngClass]="{
          'legend__item-bullet--line':
            !legendItem.bulletType || legendItem.bulletType === BulletType.Line,
          'legend__item-bullet--circle': legendItem.bulletType === BulletType.Circle
        }"
        [style.color]="legendItem.color"
        [style.background-color]="legendItem.color"
      ></div>
      <div class="legend__item-name">
        {{ legendItem.name }}
        <span *ngIf="legendItem.unit">({{ legendItem.unit }})</span>
      </div>
    </div>
    <div *ngIf="legendItem.value" class="legend__item-value" [style.color]="legendItem.color">
      {{ legendItem.value }}
    </div>
  </div>
</ng-container>
