<div *ngIf="propertyInfo.descriptor.allowMultipleSelection; else singleSelection">
  <div class="multiple-selection-input__wrapper">
    <input
      #editorInput
      [formControl]="inputControl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (click)="forceOpenAutocomplete()"
      class="multiple-selection-text-input"
    />
    <i
      *ngIf="!isReadOnly"
      class="abb-icon abb-icon--tiny List c-button c-button--rounded select-all__button"
      (click)="selectAll()"
    ></i>
    <i
      *ngIf="!isReadOnly"
      class="abb-icon abb-icon--tiny Close c-button c-button--rounded select-none__button"
      (click)="clearAllSelections()"
    ></i>
  </div>
  <mat-chip-grid #chipList>
    <mat-chip-row
      *ngFor="let item of multiSelectionResult"
      disableRipple
      [removable]="true"
      (removed)="removeSelectedItem(item.title)"
    >
      {{ item.title }}
      <button matChipRemove>
        <mat-icon class="abb-icon Close"></mat-icon>
      </button>
    </mat-chip-row>
  </mat-chip-grid>
</div>
<ng-template #singleSelection>
  <input
    #editorInput
    type="text"
    ([ngModel])="(value)"
    (ngModelChange)="inputChanges$.next($event)"
    (focusout)="onCustomInputFocusout(inputControl.value)"
    aria-label="Number"
    (click)="forceOpenAutocomplete()"
    matInput
    [formControl]="inputControl"
    [matAutocomplete]="auto"
    class="single-selection-text-input editor--discreet-input"
    [class.editor--value-configured]="value !== propertyInfo.descriptor.defaultValue"
  />
</ng-template>
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="updateOnSelect($event)">
  <mat-option
    *ngFor="let itemName of selectableItemNames$ | async"
    [value]="itemName"
    title="{{ itemName }}"
    (click)="preventOtherEvents($event)"
  >
    {{ itemName }}
  </mat-option>
</mat-autocomplete>
