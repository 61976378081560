import { Action } from "@ngrx/store";

export const OPEN_ICON_PICKER_DIALOG = "[Dialog] Open Icon Picker Dialog";
export const ON_ICON_PICKER_DIALOG_CLOSED = "[Dialog] On Icon Picker Dialog Closed";

export class OpenIconPickerDialog implements Action {
  readonly type: string = OPEN_ICON_PICKER_DIALOG;
  constructor() {}
}

export class OnIconPickerDialogClosed implements Action {
  readonly type: string = ON_ICON_PICKER_DIALOG_CLOSED;
  constructor(public result: any) {}
}
