import { Update as update } from "@ngrx/entity";
import { cloneDeep as _cloneDeep, merge as _merge, sortBy as _sortBy } from "lodash";
import { EntityId } from "../../meta";
import { Dictionary, first, isDefined, isNotDefined, last } from "../../ts-utils";
import { ComponentStateDto } from "../models";

export enum LayerChange {
  Front,
  Back
}

export function getComponentZIndex(component: ComponentStateDto): number {
  if (component && component.view && component.view.css) {
    const parsedZIndex = Number(component.view.css.zIndex);
    const zIndexIsDefined = component.view.css.zIndex !== "" && !isNaN(parsedZIndex);
    return zIndexIsDefined ? parsedZIndex : undefined;
  }
  return undefined;
}

//#region SendToBack/BringToFront
export function getUpdatesForMoveToEdgeLayerAction(
  movedComponentId: EntityId,
  layerChange: LayerChange,
  componentsDict: Dictionary<ComponentStateDto>,
  incrementalZIndexSiblingsIds: EntityId[] = []
): update<ComponentStateDto>[] {
  const filteredSiblings = getComponentWithSibilings(movedComponentId, componentsDict).filter(
    (sibling) => isNotDefined(incrementalZIndexSiblingsIds.find((id) => id === sibling.id))
  );
  const componentsOrderedByZIndex = orderComponentsByZIndex(filteredSiblings);

  const componentNewZindex = getNewZIndexForMovedComponent(layerChange, componentsOrderedByZIndex);
  const movedComponentUpdate = createZIndexUpdateObject(
    componentsDict[movedComponentId],
    componentNewZindex.toString()
  );
  const incrementalComponents = incrementalZIndexSiblingsIds
    .map((id) => componentsDict[id])
    .filter(isDefined);
  const updates = [
    movedComponentUpdate,
    ...getFilteredSiblingsUpdate(movedComponentId, layerChange, componentsOrderedByZIndex),
    ...getIncrementalComponentsUpdate(incrementalComponents, componentNewZindex, layerChange)
  ];
  return updates;
}

function getFilteredSiblingsUpdate(
  movedComponentId: EntityId,
  layerChange: LayerChange,
  componentsOrderedByZIndex: ComponentStateDto[]
): update<ComponentStateDto>[] {
  return findSibilingsForUpdate(movedComponentId, componentsOrderedByZIndex, layerChange).map(
    (sibilingForUpdate) => {
      const newZIndex: string = calculateSibilingZIndex(sibilingForUpdate, layerChange);
      return createZIndexUpdateObject(sibilingForUpdate, newZIndex);
    }
  );
}

function getIncrementalComponentsUpdate(
  incrementalComponents: ComponentStateDto[],
  startZIndex: number,
  layerChange: LayerChange
): update<ComponentStateDto>[] {
  return incrementalComponents.map((component, index) =>
    createZIndexUpdateObject(
      component,
      (layerChange === LayerChange.Front
        ? startZIndex + 1 + index
        : startZIndex - 1 - index
      ).toString()
    )
  );
}

function getNewZIndexForMovedComponent(
  orderChange: LayerChange,
  componentsOrderedByZIndex: ComponentStateDto[]
): number {
  const sourceComponent =
    orderChange === LayerChange.Front
      ? last(componentsOrderedByZIndex)
      : first(componentsOrderedByZIndex);
  return getComponentZIndex(sourceComponent);
}

function findSibilingsForUpdate(
  componentId: EntityId,
  componentsOrderedByZIndex: ComponentStateDto[],
  orderChange: LayerChange
) {
  const componentIndex = componentsOrderedByZIndex.findIndex(
    (componentState) => componentState.id === componentId
  );
  let startIndex;
  let endIndex;
  if (orderChange === LayerChange.Front) {
    startIndex = componentIndex + 1;
    endIndex = componentsOrderedByZIndex.length;
  } else {
    startIndex = 0;
    endIndex = componentIndex;
  }
  return componentsOrderedByZIndex.slice(startIndex, endIndex);
}

function calculateSibilingZIndex(sibling: ComponentStateDto, orderChange: LayerChange): string {
  const oldZIndex = getComponentZIndex(sibling);
  if (oldZIndex == null) {
    return "";
  }
  const newZIndex = orderChange === LayerChange.Front ? oldZIndex - 1 : oldZIndex + 1;
  return newZIndex.toString();
}
//#endregion

//#region SendBackward/BringForward
export function getUpdatesForMoveToNextLayerAction(
  componentId: EntityId,
  layerChange: LayerChange,
  componentsDict: Dictionary<ComponentStateDto>
): update<ComponentStateDto>[] {
  const componentsOrderedByZIndex = orderComponentsByZIndex(
    getComponentWithSibilings(componentId, componentsDict)
  );
  const componentForOrderSwitch = findComponentToSwitchOrderWith(
    componentId,
    componentsOrderedByZIndex,
    layerChange
  );
  const component = componentsDict[componentId];
  return createUpdatesForMoveToNextLayerAction(component, componentForOrderSwitch);
}

function findComponentToSwitchOrderWith(
  componentId: EntityId,
  componentsOrderedByZIndex: ComponentStateDto[],

  orderChange: LayerChange
): ComponentStateDto {
  const componentIndex = componentsOrderedByZIndex.findIndex(
    (componentState) => componentState.id === componentId
  );
  const indexOfComponentForOrderSwitch =
    orderChange === LayerChange.Front ? componentIndex + 1 : componentIndex - 1;
  return componentsOrderedByZIndex[indexOfComponentForOrderSwitch];
}

function createUpdatesForMoveToNextLayerAction(
  movedComponent: ComponentStateDto,
  componentForOrderSwitch: ComponentStateDto
): update<ComponentStateDto>[] {
  if (movedComponent == null || componentForOrderSwitch == null) {
    return [];
  }
  const componentUpdates = [];
  if (componentForOrderSwitch != null) {
    componentUpdates.push(
      createZIndexUpdateObject(movedComponent, componentForOrderSwitch.view.css.zIndex)
    );

    componentUpdates.push(
      createZIndexUpdateObject(componentForOrderSwitch, movedComponent.view.css.zIndex)
    );
  }
  return componentUpdates;
}
//#endregion

//#region UpdateZIndexesOnReportLoad
export function updateComponentZIndexesOnReportLoad(
  deserializedComponentStates: Dictionary<ComponentStateDto>
): Dictionary<ComponentStateDto> {
  const updatedComponents = Object.values(deserializedComponentStates).reduce(
    (acc, componentState: ComponentStateDto) => {
      const childComponents = componentState.childrenIds.map(
        (childId) => deserializedComponentStates[childId]
      );
      acc = _merge(
        acc,
        updateComponentsWithUndefinedZIndex(childComponents),
        updateComponentsWithDefinedZIndex(childComponents)
      );
      return acc;
    },
    {}
  );
  const allComponents = _merge({}, deserializedComponentStates, updatedComponents);
  return allComponents;
}

function updateComponentsWithUndefinedZIndex(
  componentStates: ComponentStateDto[]
): Dictionary<ComponentStateDto> {
  const componentsWithUndefinedZIndex = getComponentsWithUndefinedZIndex(componentStates);
  return componentsWithUndefinedZIndex.reduce((acc, component, index) => {
    const zIndex = index + 1;
    const updatedComponent = _cloneDeep(component);
    updatedComponent.view.css.zIndex = zIndex.toString();
    acc[component.id] = updatedComponent;
    return acc;
  }, {});
}

function updateComponentsWithDefinedZIndex(
  componentStates: ComponentStateDto[]
): Dictionary<ComponentStateDto> {
  const deltaZIndex = getComponentsWithUndefinedZIndex(componentStates).length;
  if (deltaZIndex === 0) {
    return {};
  }
  const componentsWithDefinedZIndex = getComponentsWithDefinedZIndex(componentStates);
  return componentsWithDefinedZIndex.reduce((acc, component) => {
    const zIndex = getComponentZIndex(component) + deltaZIndex;
    const updatedComponent = _cloneDeep(component);
    updatedComponent.view.css.zIndex = zIndex.toString();
    acc[component.id] = updatedComponent;
    return acc;
  }, {});
}

function getComponentsWithUndefinedZIndex(
  componentStates: ComponentStateDto[]
): ComponentStateDto[] {
  return componentStates.filter((component) => getComponentZIndex(component) == null);
}

function getComponentsWithDefinedZIndex(componentStates: ComponentStateDto[]): ComponentStateDto[] {
  return componentStates.filter((component) => getComponentZIndex(component) != null);
}
//#endregion

export function getSiblingZIndexUpdatesOnComponentDelete(
  deletedComponent: ComponentStateDto,
  componentsDict: Dictionary<ComponentStateDto>
): update<ComponentStateDto>[] {
  const componentZIndex = getComponentZIndex(deletedComponent);
  if (componentZIndex == null) {
    return;
  }
  const sibilingsToUpdate = getComponentSibilings(deletedComponent.id, componentsDict).filter(
    (sibiling) => getComponentZIndex(sibiling) > componentZIndex
  );

  const updates: update<ComponentStateDto>[] = sibilingsToUpdate.reduce((acc, sibiling) => {
    const sibilingZIndex = getComponentZIndex(sibiling);
    if (sibilingZIndex != null) {
      const newZIndex = sibilingZIndex - 1;
      acc.push(createZIndexUpdateObject(sibiling, newZIndex.toString()));
    }
    return acc;
  }, []);
  return updates;
}

function getComponentWithSibilings(
  componentId: EntityId,
  componentsDict: Dictionary<ComponentStateDto>
): ComponentStateDto[] {
  const allComponentStates = Object.values(componentsDict);
  const parentComponent = getParentComponentState(componentId, allComponentStates);
  return parentComponent.childrenIds.map((childId) => componentsDict[childId]);
}

function getComponentSibilings(
  componentId: EntityId,
  componentsDict: Dictionary<ComponentStateDto>
): ComponentStateDto[] {
  const allComponentStates = Object.values(componentsDict);
  const parentComponent = getParentComponentState(componentId, allComponentStates);
  return parentComponent.childrenIds
    .filter((childId) => childId !== componentId)
    .map((childId) => componentsDict[childId]);
}

function orderComponentsByZIndex(components: ComponentStateDto[]): ComponentStateDto[] {
  if (components == null) {
    return;
  }
  return _sortBy(components, (component) => Number(component.view.css.zIndex));
}

function createZIndexUpdateObject(
  componentState: ComponentStateDto,
  newZIndex: string
): update<ComponentStateDto> {
  return {
    id: componentState.id,
    changes: {
      view: {
        ...componentState.view,
        css: {
          ...componentState.view.css,
          zIndex: newZIndex
        }
      }
    }
  } as update<ComponentStateDto>;
}

// FIXME: same function exists in component selector
export function getParentComponentState(
  childId: EntityId,
  allComponentStates: ComponentStateDto[]
): ComponentStateDto {
  const parentComponentState: ComponentStateDto = allComponentStates.find(
    (componentState: ComponentStateDto) => componentState.childrenIds.includes(childId)
  );
  return parentComponentState;
}
