import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap } from "rxjs/operators";
import { Equipment } from "../../../core/models/equipment";
import { DataService } from "../../../data-connectivity/services/data.service";
import { EquipmentActions } from "./equipment.actions";

@Injectable()
export class EquipmentModelEffects {
  constructor(private actions$: Actions, private dataService: DataService) {}

  fetch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EquipmentActions.loadEquipmentModel),
      switchMap(() => {
        return this.dataService
          .getFullEquipmentTree()
          .pipe(
            map((rootEquipment: Equipment) =>
              EquipmentActions.loadEquipmentModelSuccess({ rootEquipment })
            )
          );
      })
    )
  );
}
