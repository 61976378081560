import { select } from "@ngrx/store";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { getStoreFromContext } from "../../data-connectivity/helpers/generic-data-source-entities.helper";
import { selectUrls } from "../../data-connectivity/store/api-data-source-descriptor/api-data-source-descriptor.selectors";
import { SelectionOption } from "../../meta/models/selection";
import { isEmptyOrNotDefined } from "../../ts-utils/helpers/is-empty.helper";
import { DecoratorDelegateContext } from "../models/decorator-delegate-context";

export function getSupportedApiUrls(
  context: DecoratorDelegateContext
): Observable<SelectionOption[]> {
  const store = getStoreFromContext(context);
  return store.pipe(
    select(selectUrls),
    map((urls) => {
      let options: SelectionOption[] = [];
      if (!isEmptyOrNotDefined(urls)) {
        options = urls.map((url) => {
          return {
            key: url,
            title: url
          };
        });
      }
      return options;
    }),
    take(1)
  );
}
