import { Action, createReducer, on } from "@ngrx/store";
import { ApiResponse } from "../../models/api/api-response";
import { ClientDataActions } from "../actions/client-data.actions";

export const initialState: ApiResponse[] = [{
  ClientId: '',
  RiCode: '',
  DataPoints: [],
  Events: [],
  Notifications: [{
    Message: '',
    Description: '',
    Recommendations: '',
    TimeStamp: new Date('14 Oct 1995'),
    AlarmId: '',
    EquipmentPath: '',
    Signal: '',
    Type: 0,
    Acknowledged: false
  }],
  Properties: [{
    StartDate: new Date('14 Oct 1995'),
    EndDate: new Date('14 Oct 1995'),
    Frequency: '',
  }],
}]

export function clientDataReducer(state: ApiResponse[], action: Action) {
  return _reducer(state, action);
}

const _reducer = createReducer(
  initialState,
  on(ClientDataActions.getClientDataAction, (state) =>
    getClientData(state)
  ),
  on(ClientDataActions.setClientData, (state, { clientData }) =>
    setClientData(state, clientData)
  )
);

function setClientData(state: ApiResponse[], clientData: ApiResponse[]) {
  if (!clientData) {
    return state;
  } else {
    return [
      ...state,
      ...clientData
    ];
  }
}

function getClientData(state: ApiResponse[]) {
  return state;
}