import { Action, createReducer, on } from "@ngrx/store";
import { Maybe } from "../../../ts-utils";
import { GenericDataSourceDescriptor } from "../../models/data-source/data-source-descriptors";
import { GenericDataSourceDescriptorActions } from "./generic-data-source-descriptor.actions";

const initialState: Maybe<GenericDataSourceDescriptor[]> = null;

export function reducer(state: Maybe<GenericDataSourceDescriptor[]>, action: Action) {
  return _reducer(state, action);
}

const _reducer = createReducer(
  initialState as Maybe<GenericDataSourceDescriptor[]>,
  on(GenericDataSourceDescriptorActions.loadSuccess, (state, { descriptors }) =>
    onLoadGenericDataSourceDescriptorsSuccess(descriptors)
  )
);

function onLoadGenericDataSourceDescriptorsSuccess(
  descriptors: Maybe<GenericDataSourceDescriptor[]>
): Maybe<GenericDataSourceDescriptor[]> {
  return descriptors;
}
