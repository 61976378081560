import { Component, OnInit } from "@angular/core";
import {
  BaseComponent,
  ConnectorRoles,
  EditableType,
  getIndicatorStatus,
  IndicatorState,
  IndicatorType,
  View
} from "ui-core";
import { Roles } from "./roles";
import { PoleStatusViewConfig } from "./view-config";

@Component({
  selector: "rds-pole-status",
  templateUrl: "./pole-status.component.html",
  styleUrls: ["./pole-status.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: PoleStatusComponent }]
})
// @LayoutBuilder(ComponentCategory.Premade, "PoleStatusComponent", "Dashboard_2")
@ConnectorRoles(Roles)
@EditableType({ fullName: "PoleStatusComponent", title: "pole-status" })
export class PoleStatusComponent extends BaseComponent implements OnInit {
  public poles: number[] = [];

  ngOnInit() {
    super.ngOnInit();

    // TODO remove this when we have real data for this component
    this.componentStatePropertySelector.subscribeOnDataConnectors({
      componentId: this.id,
      selector: (connector) => connector.role.indexOf(Roles.PolePrefix.name) === 0,
      delegate: (dataConnectors) => this.updateData()
    });

    this.updateData(); // Initialize mock data
  }

  @View(PoleStatusViewConfig)
  public get view() {
    return this.currentState.view as PoleStatusViewConfig;
  }

  private updateData() {
    this.poles = [];
    for (let i = 0; i < 80; i++) {
      this.poles.push(Math.floor(Math.random() * 4));
    }
  }

  getStatus(statusValue: string): IndicatorState {
    return getIndicatorStatus(statusValue, IndicatorType.Multivalue, this.view.typeName);
  }
}
