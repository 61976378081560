import { createSelector } from "@ngrx/store";
import { DataConnectorDto, EquipmentDataSourceDto } from "../../../data-connectivity";
import { getConnectorViewId } from "../../../data-connectivity/helpers/connector-view-id.helper";
import { DataConnectorViewDto } from "../../../data-connectivity/models/data-connector-view";
import { EntityId } from "../../../meta/models/entity";
import { Dictionary, Maybe, isDefined, isNotDefined } from "../../../ts-utils";
import { selectComponentDataConnectorIds } from "../component-state/component-state.selectors";
import {
  selectConnectorViewDictByConnectors,
  selectDataConnectorViewById
} from "../data-connector-view";
import { getReportFeature } from "../feature.selector";
import { ReportContents } from "../state";
import { DataConnectorState } from "./data-connector.state";

export const selectDataConnectors = createSelector(
  getReportFeature,
  (state: ReportContents) => state.dataConnectors
);

export const selectDataConnectorEntities = createSelector(
  selectDataConnectors,
  (state) => state.entities
);

export const selectDataConnectorIds = createSelector(selectDataConnectors, (state) => state.ids);

export const selectDataConnectorById = (connectorId: EntityId) =>
  createSelector(selectDataConnectors, (state) => state.entities[connectorId]);

export const selectDataConnectorsById = (connectorIds: EntityId[]) =>
  createSelector(selectDataConnectors, (state) =>
    connectorIds
      .map((connectorId) => state.entities[connectorId])
      .filter((connector) => isDefined(connector))
  );

export const selectComponentConnectorsById = (componentId: EntityId) =>
  createSelector(
    selectComponentDataConnectorIds(componentId),
    selectDataConnectorEntities,
    (dataConnectorIds: EntityId[], allDataConnectors: Dictionary<Maybe<DataConnectorDto>>) => {
      return dataConnectorIds
        .map((id) => allDataConnectors[id])
        .filter((connector) => isDefined(connector));
    }
  );

export const selectMultipleConnectorsById = (ids: EntityId[]) =>
  createSelector(selectDataConnectors, (entities) => Object.keys(entities).filter(ids.includes));

export function filterDataControllersByDataSource(
  controllers: DataConnectorDto[],
  dataSourceType: string
): DataConnectorDto[] {
  if (!Array.isArray(controllers)) {
    return [];
  }
  return controllers.filter(
    (controller) => controller.dataSource && controller.dataSource.typeName === dataSourceType
  );
}

export function getAllDynamicConnectors(dataConnectors: DataConnectorState): DataConnectorDto[] {
  if (!dataConnectors || !dataConnectors.entities) {
    return [];
  }
  return Object.keys(dataConnectors.entities)
    .map((id) => dataConnectors.entities[id])
    .filter((dataConnector: DataConnectorDto) => dataConnector.isDynamicallyCreated);
}

export function getAllDynamicConnectorsAsDictionary(
  dataConnectors: DataConnectorState
): Dictionary<DataConnectorDto> {
  return getAllDynamicConnectors(dataConnectors).reduce((acc, connector: DataConnectorDto) => {
    acc[connector.id] = connector;
    return acc;
  }, {});
}

export const selectDataConnectorDescriptor = (connectorId: EntityId) => {
  const viewId = getConnectorViewId(connectorId);
  return createSelector(
    selectDataConnectorById(connectorId),
    selectDataConnectorViewById(viewId),
    (connector: Maybe<DataConnectorDto>, connectorView: Maybe<DataConnectorViewDto>) => {
      return {
        connector,
        connectorView
      };
    }
  );
};

export const selectDataConnectorDescriptors = (connectors: DataConnectorDto[]) =>
  createSelector(
    selectConnectorViewDictByConnectors(connectors),
    (connectorViewDict: Dictionary<DataConnectorViewDto>) => {
      return connectors.map((connector: DataConnectorDto) => {
        const connectorView = connectorViewDict[getConnectorViewId(connector?.id)];
        return {
          connector,
          connectorView
        };
      });
    }
  );

export const selectConnectorByStandaloneFilterId = (id: EntityId) =>
  createSelector(selectDataConnectorEntities, (entities) =>
    Object.values(entities).find((connector) => connector?.filterId === id)
  );

export const selectEquipmentPathByConnector = (id: EntityId) =>
  createSelector(selectDataConnectorById(id), (connector) => {
    if (isNotDefined(connector) || isNotDefined(connector.dataSource)) {
      return "";
    }
    const rootPath = (connector.dataSource as EquipmentDataSourceDto).path;
    return rootPath ?? "";
  });
