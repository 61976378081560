import { OnDestroy, Pipe, PipeTransform } from "@angular/core";
import moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { EnvironmentSelector } from "../../../environment/services/environment.selector";

@Pipe({
  name: "moment"
})
export class MomentPipe implements PipeTransform, OnDestroy {
  private language: string = "";
  private unsubscribeSubject$ = new Subject();

  constructor(private environmentSelector: EnvironmentSelector) {
    this.environmentSelector
      .selectCurrentLanguage()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((language) => {
        this.language = language;
      });
  }

  transform(value: Date, format: string): string {
    return moment(value).locale(this.language).format(format);
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }
}
