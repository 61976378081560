import { MatDialogConfig } from "@angular/material/dialog";
import { EquipmentDialogInfo } from "../../property-sheet/models/equipment-dialog-info";
import { ColorPickerDialogInfo } from "../models/color-picker-dialog-info";
import { CreateReportDialogInfo } from "../models/create-report-dialog-info";

export interface DialogConfigStrategy {
  createDialogConfig(data?: any): MatDialogConfig;
}

export class BasicConfig implements DialogConfigStrategy {
  createDialogConfig(data?: any): MatDialogConfig<any> {
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.width = "fit-content";
    dialogConfig.height = "fit-content(80vh)";
    dialogConfig.backdropClass = "trend__dialog-backdrop--none";
    dialogConfig.autoFocus = true;
    if (data != null) {
      dialogConfig.data = data;
    }
    return dialogConfig;
  }
}

export class OpenSignalEditorStrategy extends BasicConfig {
  createDialogConfig(): MatDialogConfig<any> {
    const dialogConfig = super.createDialogConfig();
    dialogConfig.width = "30vw";
    return dialogConfig;
  }
}

export class OpenEquipmentEditorStrategy extends BasicConfig {
  createDialogConfig(data: EquipmentDialogInfo): MatDialogConfig<any> {
    const dialogConfig = super.createDialogConfig(data);
    dialogConfig.width = "30vw";
    return dialogConfig;
  }
}

export class CreateReportConfigStrategy extends BasicConfig {
  createDialogConfig(data: CreateReportDialogInfo): MatDialogConfig<any> {
    const dialogConfig = super.createDialogConfig(data);
    dialogConfig.width = "30vw";
    return dialogConfig;
  }
}

export class IconPickerDialogStrategy extends BasicConfig {
  createDialogConfig(data: any): MatDialogConfig<any> {
    const dialogConfig = super.createDialogConfig(data);
    // two scrollbars appear when height is fixed: https://github.com/angular/components/issues/6584
    dialogConfig.width = "40vw";
    return dialogConfig;
  }
}

export class AboutDialogStrategy extends BasicConfig {
  createDialogConfig(data: any): MatDialogConfig<any> {
    const dialogConfig = super.createDialogConfig(data);
    return dialogConfig;
  }
}

export class CreditsDialogStrategy extends BasicConfig {
  createDialogConfig(data: any): MatDialogConfig<any> {
    const dialogConfig = super.createDialogConfig(data);
    dialogConfig.height = "80vh";
    dialogConfig.width = "70vw";
    return dialogConfig;
  }
}

export class ReportBrowserDialogStrategy extends BasicConfig {
  createDialogConfig(): MatDialogConfig<any> {
    const dialogConfig = super.createDialogConfig();
    dialogConfig.width = "35vmin";
    return dialogConfig;
  }
}

export class ViewReportConfigStrategy extends BasicConfig {
  createDialogConfig(data: string): MatDialogConfig<any> {
    const dialogConfig = super.createDialogConfig(data);
    dialogConfig.maxWidth = "100vw";
    dialogConfig.maxHeight = "100vh";
    dialogConfig.height = "70vh";
    dialogConfig.width = "70vw";

    if (data != null) {
      dialogConfig.data = data;
    }
    dialogConfig.backdropClass = "blur-background";
    dialogConfig.panelClass = "custom-mat-dialog-panel";
    return dialogConfig;
  }
}

export class PropertySheetEditorDialogStrategy extends BasicConfig {
  createDialogConfig(config: ColorPickerDialogInfo): MatDialogConfig<any> {
    const dialogConfig = super.createDialogConfig(config);
    dialogConfig.position = {
      top: config.top,
      left: config.left
    };
    dialogConfig.panelClass = "editor-overlay-dialog-panel";

    return dialogConfig;
  }
}
