import { EditableType } from "../../../meta/decorators/editable-type.decorator";
import { Serializable } from "../../../meta/decorators/serializable.decorator";
import { construct } from "../../services/construct.helper";
import { DataTransferObject } from "../data-transfer-object";

const TYPE_NAME = "GlobalTimeRangeConfigurationDto";

// @dynamic
@EditableType({ fullName: TYPE_NAME })
export class GlobalTimeRangeConfigurationDto implements DataTransferObject {
  typeName = TYPE_NAME;

  @Serializable("")
  fromExpression?: string;

  @Serializable("")
  toExpression?: string;

  constructor(timeRange: Partial<GlobalTimeRangeConfigurationDto> = {}) {
    construct(this, timeRange, TYPE_NAME);
  }
}
