import { SizeInPx } from "./size-in-px";

export interface RuntimeViewProperties {
  runtimeSize: SizeInPx;
}

export interface CardRuntimeViewProperties extends RuntimeViewProperties {
  scalingFactor: number;
}

export interface PageRuntimeViewProperties extends RuntimeViewProperties {
  paddingTop: number;
  paddingLeft: number;
}

export const NEUTRAL_SCALING_FACTOR = 1;
