<!-- <chart type="Map" [options]="options"></chart> -->
<div
  class="linear-gauge__wrapper card-item__drag-handle"
  class="linear-gauge__wrapper card-item__drag-handle"
>
  <highcharts-chart-extended
    class="c-map__container"
    [Highcharts]="Highcharts"
    [constructorType]="chartConstructor"
    [options]="mapOptions"
    [callbackFunction]="onMapRendered"
  >
  </highcharts-chart-extended>
</div>
