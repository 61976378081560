import { isDevMode } from "@angular/core";
import { MissingTranslationHandler, MissingTranslationHandlerParams } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { bufferTime, filter, map } from "rxjs/operators";
import { isDefined } from "../ts-utils";
import { TranslationFallback } from "./translation-fallback";

export class MissingTranslationReporter implements MissingTranslationHandler {
  subject: Subject<string>;

  constructor() {
    this.subject = new Subject<string>();
    if (isDevMode()) {
      this.subject
        .pipe(
          bufferTime(1000),
          filter((items: string[]) => items.length > 0),
          map((items: string[]) => new Set(items))
        )
        .subscribe((items: Set<string>) => {
          const sorted = [...items].sort((a, b) => {
            const aHasDot = a.includes(".");
            const bHasDot = b.includes(".");
            if (aHasDot && !bHasDot) {
              return -1;
            }
            if (bHasDot && !aHasDot) {
              return 1;
            }
            return a.localeCompare(b);
          });
          console.log("Missing translation", sorted);
        });
    }
  }

  handle(params: MissingTranslationHandlerParams): string {
    const asFallback = params.interpolateParams as TranslationFallback;
    if (isDefined(asFallback?.fallback)) {
      this.subject.next(params.key + "*");
      return asFallback.fallback;
    }
    this.subject.next(params.key);
    return params.key;
  }
}
