<date-time-picker
  class="date-time-picker"
  [date]="date"
  [dateTimeFormat]="dateTimeFormat"
  (onDateTimeChange)="onSelectDateTimePicker(true, $event)"
></date-time-picker>
<div class="dialog__custom-buttons">
  <button class="c-button c-button--primary" (click)="onSave()">
    <span>{{ localizer.propertySheet.Ok | translate }}</span>
  </button>
  <button class="c-button c-button--primary" (click)="onReset()">
    <span class="cancel-btn">{{ localizer.propertySheet.Reset | translate }}</span>
  </button>
  <button class="c-button" (click)="onCancel()">
    <span class="cancel-btn">{{ localizer.dialogs.Cancel | translate }}</span>
  </button>
</div>
