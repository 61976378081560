import { Injectable } from "@angular/core";
import { FilterConfigurationDto } from "../../core/models/filter/filter-configuration";
import { GlobalFilterViewModel } from "../../core/models/filter/global-filter-view-model";
import { GlobalFilterViewModelDeserializer } from "../../core/services/filter/global-filter-vm-deserializer";
import { DataConnectorDto } from "../../data-connectivity";
import { DataConnectorViewModel } from "../../data-connectivity/models/data-connector.vm";
import { DataConnectorViewModelDeserializer } from "../../data-connectivity/services/deserializers/data-connector-vm.deserializer";
import { Entity, EntityId } from "../../meta/models/entity";
import { TypeProvider } from "../../meta/services/type-provider";
import { ModifiedObject } from "../../property-sheet/models";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { ComponentStateDto } from "../models/component-state";
import { ComponentStateViewModel } from "../models/component-state.vm";
import {
  COMPONENT_STATE_DTO,
  DATA_CONNECTOR_DTO,
  FILTER_CONFIGURATION_DTO
} from "../models/entity-type.constants";
import { ComponentStateViewModelDeserializer } from "./deserializers/component-state-vm.deserializer";

@Injectable()
export class EntityInfoService {
  constructor(
    private typeProvider: TypeProvider,
    private componentStateViewModelDeserializer: ComponentStateViewModelDeserializer,
    private dataConnectorViewModelDeserializer: DataConnectorViewModelDeserializer,
    private filterViewModelDeserializer: GlobalFilterViewModelDeserializer
  ) {}

  //TODO: add type property for all Entities to avoid this way for checking entity types
  getEntityInfo(entity: Entity, parentEntityId: Maybe<EntityId> = null): ModifiedObject<Entity> {
    switch (entity.typeName) {
      case COMPONENT_STATE_DTO: {
        return {
          value: this.componentStateViewModelDeserializer.convert(entity as ComponentStateDto),
          type: this.typeProvider.getTypeByConstructor(ComponentStateViewModel),
          parentEntityId
        };
      }
      case DATA_CONNECTOR_DTO: {
        return {
          value: this.dataConnectorViewModelDeserializer.convert(entity as DataConnectorDto),
          type: this.typeProvider.getTypeByConstructor(DataConnectorViewModel),
          parentEntityId
        };
      }
      case FILTER_CONFIGURATION_DTO: {
        return {
          value: this.filterViewModelDeserializer.convert(entity as FilterConfigurationDto),
          type: this.typeProvider.getTypeByConstructor(GlobalFilterViewModel),
          parentEntityId
        };
      }
      default: {
        return {
          value: entity,
          type: this.typeProvider.getType(entity.typeName),
          parentEntityId
        };
      }
    }
  }
}
