export class PropertySheetLocalizationDictionary {
  General: string = "General";
  Display: string = "Display";

  Data: string = "Data";
  AdvancedMode: string = "AdvancedMode";
  Title: string = "Title";

  TitleTooltip: string = "TitleTooltip";
  Hidable: string = "Hidable";
  DisplayFormat: string = "DisplayFormat";
  TitleFormat: string = "TitleFormat";
  TitleFormat_Truncate: string = "TitleFormat_Truncate";
  TitleFormat_Wrap: string = "TitleFormat_Wrap";
  TitleFormat_ScaleDown: string = "TitleFormat_ScaleDown";
  Interaction: string = "Interaction";
  Tabs: string = "Tabs";
  TabsTooltip: string = "TabsTooltip";
  DisplayFormatTooltip: string = "DisplayFormatTooltip";
  DisplayOrDateFormatTooltip: string = "DisplayOrDateFormatTooltip";
  Where = "Where";
  WhereTooltip = "WhereTooltip";
  FilterConfigTooltip = "FilterConfigTooltip";
  ColumnWidthConfigTooltip = "ColumnWidthConfigTooltip";
  ApiUrlTooltip = "ApiUrlTooltip";
  EquipmentPropertyTooltip = "EquipmentPropertyTooltip";
  Marker_Style: string = "Marker_Style";
  Ok: string = "Ok";
  Reset: string = "Reset";
  CssWidthTooltip: string = "CssWidthTooltip";
  CssHeightTooltip: string = "CssHeightTooltip";
  PropertyInterpolationTooltip: string = "PropertyInterpolationTooltip";

  Display_Line = "Line";
  Display_Area: string = "Display_Area";
  Display_Column: string = "Display_Column";
  Display_Bar: string = "Display_Bar";
  Display_CategoryVerticalBar: string = "Display_CategoryVerticalBar";
  Display_CategoryHorizontalBar: string = "Display_CategoryHorizontalBar";
  Display_Histogram: string = "Display_Histogram";
  Display_Table: string = "Display_Table";
  Display_Pie: string = "Display_Pie";
  Display_Donut: string = "Display_Donut";
  Display_StackedColumn: string = "Display_StackedColumn";
  Display_Value: string = "Display_Value";
  Display_CircleValue: string = "Display_CircleValue";
  Display_TrendValue: string = "Display_TrendValue";
  Display_DialGauge: string = "Display_DialGauge";
  Display_MultiDialGauge: string = "Display_MultiDialGauge";
  Display_SolidGauge: string = "Display_SolidGauge";
  Display_HorizontalGauge: string = "Display_HorizontalGauge";
  Display_VerticalGauge: string = "Display_VerticalGauge";
  Display_BulletChart: string = "Display_BulletChart";
  Display_Binary: string = "Display_Binary";
  Display_Multivalue: string = "Display_Multivalue";
  Display_Label: string = "Display_Label";
  Display_MultilineLabel: string = "Display_MultilineLabel";

  Display_IFrame: string = "Display_IFrame";

  Stack: string = "Stack";
  NumberOfBins: string = "NumberOfBins";
  BinsTooltip: string = "BinsTooltip";
  SharedBins: string = "SharedBins";
  SharedBinsTooltip: string = "SharedBinsTooltip";
  XaxisTitle: string = "XaxisTitle";
  XaxisTitleTooltip: string = "XaxisTitleTooltip";
  YAxes: string = "YAxes";
  YaxisTitle: string = "YaxisTitle";
  YaxisTitleTooltip: string = "YaxisTitleTooltip";
  ShowRegression: string = "ShowRegression";
  ShowRegressionTooltip: string = "ShowRegressionTooltip";

  YAxesTooltip: string = "YAxesTooltip";
  ShowGrid: string = "ShowGrid";

  Theme: string = "Theme";

  Theme_Default: string = "Theme_Default";
  Theme_Dark: string = "Theme_Dark";
  Theme_Light: string = "Theme_Light";
  Theme_White: string = "Theme_White";
  Theme_Sky: string = "Theme_Sky";
  Theme_Japan: string = "Theme_Japan";

  GridLineWidth: string = "GridLineWidth";
  GridLineColor: string = "GridLineColor";
  GridLineStyle: string = "GridLineStyle";
  GridLineStyle_Solid: string = "GridLineStyle_Solid";
  GridLineStyle_Step: string = "GridLineStyle_Step";
  GridLineStyle_Dash: string = "GridLineStyle_Dash";
  GridLineStyle_Dot: string = "GridLineStyle_Dot";

  Values: string = "Values";
  Limits: string = "Limits";
  Description: string = "Description";
  Extendable: string = "Extendable";
  EditableLayout: string = "EditableLayout";
  Color: string = "Color";
  ShowHeader: string = "ShowHeader";
  ShowFooter: string = "ShowFooter";
  ExpandEnabled: string = "ExpandEnabled";
  ExpandEnabledTooltip: string = "ExpandEnabledTooltip";
  ExportEnabled: string = "ExportEnabled";
  ExportEnabledTooltip: string = "ExportEnabledTooltip";
  Unit: string = "Unit";
  UnitTooltip: string = "UnitTooltip";
  MinimumValue: string = "MinimumValue";
  MaximumValue: string = "MaximumValue";
  XAxisMinimumValue: string = "XAxisMinimumValue";
  XAxisMaximumValue: string = "XAxisMaximumValue";
  YAxisMinimumValue: string = "YAxisMinimumValue";
  YAxisMaximumValue: string = "YAxisMaximumValue";
  PrimaryColor: string = "PrimaryColor";
  TextColorTooltip: string = "TextColorTooltip";
  FontColor: string = "FontColor";
  ShowDataLabel: string = "ShowDataLabel";
  ShowLimitValues: string = "ShowLimitValues";
  ShowLimitsAsBars: string = "ShowLimitsAsBars";
  FontSize: string = "FontSize";
  Bold: string = "Bold";
  UnknownColor: string = "UnknownColor";
  FaultyColor: string = "FaultyColor";
  ForcedColor: string = "ForcedColor";
  HealthyColor: string = "HealthyColor";
  NoisyColor: string = "NoisyColor";
  NominalColor: string = "NominalColor";
  TextColor: string = "TextColor";
  TitleColor: string = "TitleColor";
  Navigation: string = "Navigation";
  LinkedWidgets: string = "LinkedWidgets";
  LinkButtonTooltip: string = "LinkButtonTooltip";
  UnlinkButtonTooltip: string = "UnlinkButtonTooltip";
  Events: string = "Events";
  SourceWidget: string = "SourceWidget";
  SelectTimeRangeEvent = "SelectTimeRangeEvent";
  SelectEquipmentPathEvent = "SelectEquipmentPathEvent";
  Link: string = "Link";
  NavLinksTooltip: string = "NavLinksTooltip";
  Other: string = "Other";
  NeutralColor: string = "NeutralColor";
  TargetColor: string = "TargetColor";
  VeryLowColor: string = "VeryLowColor";
  ExtremeLowColor: string = "ExtremeLowColor";
  LowColor: string = "LowColor";
  HighColor: string = "HighColor";
  VeryHighColor: string = "VeryHighColor";
  VeryLowValue: string = "VeryLowValue";
  ExtremeHighColor: string = "ExtremeHighColor";
  ExtremeLowValue: string = "ExtremeLowValue";
  LowValue: string = "LowValue";
  HighValue: string = "HighValue";
  VeryHighValue: string = "VeryHighValue";
  ExtremeHighValue: string = "ExtremeHighValue";
  Target: string = "Target";
  DataSource: string = "DataSource";
  DataAggregation: string = "DataAggregation";
  PeriodType: string = "PeriodType";
  Forecast: string = "Forecast";
  ForecastTooltip: string = "ForecastTooltip";
  Anomalies: string = "Anomalies";
  AnomaliesTooltip: string = "AnomaliesTooltip";
  Type: string = "Type";
  Path: string = "Path";
  Class: string = "Class";
  PropertyName: string = "PropertyName";
  SearchDepth: string = "SearchDepth";
  Filter: string = "Filter";
  From: string = "From";
  To: string = "To";
  pType: string = "pType";
  DataConnectors: string = "DataConnectors";
  DataConnectorsTooltip: string = "DataConnectorsTooltip";
  AddItem: string = "AddItem";
  Background: string = "Background";
  BackgroundColor: string = "BackgroundColor";
  BackgroundImage: string = "BackgroundImage";
  Image: string = "Image";
  Exporting: string = "Exporting";
  SizeAndPosition: string = "SizeAndPosition";
  Width: string = "Width";
  Height: string = "Height";
  CSSOverride: string = "CSSOverride";
  Style: string = "Style";
  LeftTopTooltip: string = "LeftTopTooltip";
  Padding: string = "Padding";
  PaddingTooltip: string = "PaddingTooltip";
  Position: string = "Position";
  Left: string = "Left";
  Top: string = "Top";
  Order: string = "Order";
  OrderTooltip: string = "OrderTooltip";
  ZIndex: string = "ZIndex";
  PlotColor: string = "PlotColor";
  Url: string = "Url";
  UrlTooltip: string = "UrlTooltip";
  Params: string = "Params";
  Hidden: string = "Hidden";
  DateFormat: string = "DateFormat";
  DateFormatTooltip: string = "DateFormatTooltip";

  materialAggregationFunction: string = "materialAggregationFunction";
  locationAggregationFunction: string = "locationAggregationFunction";
  arrayAggregationFunction: string = "arrayAggregationFunction";
  timeAggregationFunction: string = "timeAggregationFunction";
  AggregateFunction: string = "AggregateFunction";

  AggregateFunction_None: string = "AggregateFunction_None";
  AggregateFunction_Average: string = "AggregateFunction_Average";
  AggregateFunction_Maximum: string = "AggregateFunction_Maximum";
  AggregateFunction_Minimum: string = "AggregateFunction_Minimum";
  AggregateFunction_Sum: string = "AggregateFunction_Sum";
  AggregateFunction_First: string = "AggregateFunction_First";
  AggregateFunction_Latest: string = "AggregateFunction_Latest";
  AggregateFunction_Last: string = "AggregateFunction_Last";
  AggregateFunction_Default: string = "AggregateFunction_Default";

  Role: string = "Role";
  None: string = "None";
  RoleTooltip: string = "RoleTooltip";
  DisplayLegend: string = "DisplayLegend";
  DisplayFilter: string = "DisplayFilter";
  ShowValue: string = "ShowValue";
  Show80Line: string = "Show80Line";
  ShowCumulativeLine: string = "ShowCumulativeLine";
  ShowValueTooltip: string = "ShowValueTooltip";
  ShowPercentage: string = "ShowPercentage";
  ShowPercentageTooltip: string = "ShowPercentageTooltip";
  ShowSum: string = "ShowSum";
  ShowSumTooltip: string = "ShowSumTooltip";
  ShowYAxisLabels: string = "ShowYAxisLabels";
  CenterLabelText: string = "CenterLabelText";
  CenterLabelTextTooltip: string = "CenterLabelTextTooltip";
  CurrentColor: string = "CurrentColor";
  Columns: string = "Columns";
  Logs: string = "Logs";
  ShowBreadcrumb: string = "ShowBreadcrumb";
  TabLinks: string = "TabLinks";
  Centered: string = "Centered";
  ShowTimestamp: string = "ShowTimestamp";
  ShowCircle: string = "ShowCircle";
  ShowGrowth: string = "ShowGrowth";
  DetailsLink: string = "DetailsLink";
  ImageURL: string = "ImageURL";
  DisplayStatusIcons: string = "DisplayStatusIcons";
  DestinationFilter: string = "DestinationFilter";
  MaximumYAxes: string = "MaximumYAxes";
  View: string = "View";
  DataConnectorQuery: string = "DataConnectorQuery";
  Empty: string = "Empty";
  Signal: string = "Signal";
  Equipment: string = "Equipment";
  Api: string = "Api";
  Grouped: string = "Grouped";
  Tabular: string = "Tabular";
  Value: string = "Value";
  TimeOffset: string = "TimeOffset";
  SeriesType: string = "SeriesType";
  LineColor: string = "LineColor";
  LineType: string = "LineType";
  LineWidth: string = "LineWidth";
  MarkerRadius: string = "MarkerRadius";
  ShowMarker: string = "ShowMarker";
  YAxisLabelPosition: string = "YAxisLabelPosition";
  ValueFormat: string = "ValueFormat";
  CellConfiguration: string = "CellConfiguration";
  TrueColor: string = "TrueColor";
  FalseColor: string = "FalseColor";
  Name: string = "Name";
  AggregationType: string = "AggregationType";
  AggregationOf: string = "AggregationOf";
  Aggregation: string = "Aggregation";
  Aggregation_Count: string = "Aggregation_Count";
  Aggregation_Sum: string = "Aggregation_Sum";
  DisplayColumnDataLabels: string = "DisplayColumnDataLabels";

  MaxRecords: string = "Max Records";
  Bind: string = "Bind";
  Comparer: string = "Comparer";
  TableView: string = "Table/View";
  GroupBy: string = "GroupBy";
  SecondaryGroupBy: string = "SecondaryGroupBy";
  GroupTooltip: string = "Group";
  GroupNames: string = "GroupNames";
  GroupNamesTooltip: string = "GroupNamesTooltip";
  CreateGroupTooltip: string = "CreateGroupTooltip";

  SourceFilter: string = "SourceFilter";
  OrderColumn: string = "Order by";
  OrderDirection: string = "OrderDirection";
  OrderDirection_Ascending: string = "OrderDirection_Ascending";
  OrderDirection_Descending: string = "OrderDirection_Descending";

  SelectAReport: string = "SelectAReport";
  EnterURL: string = "EnterURL";
  CreateLink: string = "CreateLink";
  ExternalLinkDto: string = "ExternalLink";
  Search: string = "Search";

  Icon: string = "Icon";
  HorizontalAlignment: string = "HorizontalAlignment";
  HorizontalAlignment_Left: string = "HorizontalAlignment_Left";
  HorizontalAlignment_Center: string = "HorizontalAlignment_Center";
  HorizontalAlignment_Right: string = "HorizontalAlignment_Right";
  VerticalAlignment: string = "VerticalAlignment";
  VerticalAlignment_Top: string = "VerticalAlignment_Top";
  VerticalAlignment_Middle: string = "VerticalAlignment_Middle";
  VerticalAlignment_Bottom: string = "VerticalAlignment_Bottom";

  Filter_Key: string = "Filter_Key";
  Filter_Label: string = "Filter_Label";
  Filter_ValueType: string = "Filter_ValueType";
  Filter_ReadOnly: string = "Filter_ReadOnly";
  Filter_InitialValue: string = "Filter_InitialValue";
  FilterType_Text: string = "FilterType_Text";
  FilterType_Number: string = "FilterType_Number";
  FilterType_Boolean: string = "FilterType_Boolean";
  FilterType_Date: string = "FilterType_Date";
  FilterType_Enum: string = "FilterType_Enum";

  IndicatorType: string = "IndicatorType";
  IndicatorType_Binary: string = "IndicatorType_Binary";
  IndicatorType_Multivalue: string = "IndicatorType_Multivalue";
  IndicatorStates: string = "IndicatorStates";
  StatesTooltip: string = "StatesTooltip";
  IndicatorIcon: string = "IndicatorIcon";
  IndicatorImage: string = "IndicatorImage";
  IndicatorShape: string = "IndicatorShape";
  IndicatorShape_None: string = "IndicatorShape_None";
  IndicatorShape_FullCircle: string = "IndicatorShape_FullCircle";
  IndicatorShape_BorderCircle: string = "IndicatorShape_BorderCircle";
  IndicatorShape_Rectangle: string = "IndicatorShape_Rectangle";
  IndicatorShape_Square: string = "IndicatorShape_Square";
  IndicatorShape_Oval: string = "IndicatorShape_Oval";
  IndicatorShape_Rhombus: string = "IndicatorShape_Rhombus";
  IndicatorState_ValueTooltip: string = "IndicatorState_ValueTooltip";
  StateDisplayType: string = "StateDisplayType";
  StateDisplayType_Shape: string = "StateDisplayType_Shape";
  StateDisplayType_Icon: string = "StateDisplayType_Icon";
  StateDisplayType_Image: string = "StateDisplayType_Image";

  EnableScaling: string = "EnableScaling";
  ShowCategoryInLabel: string = "ShowCategoryInLabel";

  RootPath: string = "RootPath";
  EmptyLinkDto: string = "EmptyLinkDto";
  ReportLinkDto: string = "ReportLinkDto";

  Array: string = "Array";
  Location: string = "Location";
  Material: string = "Material";
  ArrayTooltip: string = "ArrayTooltip";
  LocationTooltip: string = "LocationTooltip";
  MaterialTooltip: string = "MaterialTooltip";

  CutOffStrategy: string = "CutOffStrategy";
  CutOffStrategyTooltip: string = "CutOffStrategyTooltip";
  End: string = "End";
  Start: string = "Start";
  Downsample: string = "Downsample";
  RobustColor: string = "RobustColor";
  StableColor: string = "StableColor";
  ReactiveColor: string = "ReactiveColor";
  OverLoadedColor: string = "OverLoadedColor";
  UpsetStateColor: string = "UpsetStateColor";
  SteadyStateColor: string = "SteadyStateColor";

  AxisNames: string = "AxisNames";
  AxisName: string = "AxisName";
  SpiderSeriesTypeTooltip: string = "SpiderSeriesTypeTooltip";
  AxisTooltip: string = "AxisTooltip";
  APIDataSourceTooltip: string = "APIDataSourceTooltip";

  LinkOpenMode: string = "LinkOpenMode";
  LinkOpenMode_SameWindow: string = "LinkOpenMode_SameWindow";
  LinkOpenMode_NewTab: string = "LinkOpenMode_NewTab";
  LinkOpenMode_NewKmTab: string = "LinkOpenMode_NewKmTab";
  LinkOpenMode_NewWindow: string = "LinkOpenMode_NewWindow";
  LinkOpenMode_PopUp: string = "LinkOpenMode_PopUp";

  HideColumn: string = "HideColumn";
  TableColumn: string = "TableColumn";

  SeriesConfiguration: string = "SeriesConfiguration";

  YAxisLabelsAlignment: string = "YAxisLabelsAlignment";
  YAxisLabelsAlignment_Left: string = "YAxisLabelsAlignment_Left";
  YAxisLabelsAlignment_Right: string = "YAxisLabelsAlignment_Right";

  ValueDataSourceTooltip: string = "ValueDataSourceTooltip";

  MultilineText: string = "MultilineText";
  MultilineLabelTooltip: string = "MultilineLabelTooltip";

  InvalidPropertySource: string = "InvalidPropertySource";

  CustomFilters: string = "CustomFilters";
  CustomFiltersTooltip: string = "CustomFiltersTooltip";
  RuntimeFilters: string = "RuntimeFilters";
  UseServerTime: string = "UseServerTime";
  FilterTimeRange: string = "Time Range";

  Border: string = "Border";
  BorderRadius: string = "BorderRadius";

  AlternateRowColors: string = "AlternateRowColors";

  SwapXY: string = "SwapXY";
  DragToReorder: string = "DragToReorder";
  YAxisLabelTooltip: string = "YAxisLabelTooltip";

  DynamicDefaultsPlaceholder: string = "DynamicDefaultsPlaceholder";
  Inherited: string = "Inherited";
  NonePeriodType: string = "NonePeriodType";
  MaxChartItems: string = "MaxChartItems";
  MaxChartItemsTooltip: string = "MaxChartItemsTooltip";

  DataValue: string = "DataValue";
  DataValueTooltip: string = "DataValueTooltip";
  FontSizeTooltip: string = "FontSizeTooltip";

  AddSum: string = "AddSum";

  TableFooterFunction: string = "TableFooterFunction";
  TableFooterFunction_None: string = "TableFooterFunction_None";
  TableFooterFunction_Min: string = "TableFooterFunction_Min";
  TableFooterFunction_Max: string = "TableFooterFunction_Max";
  TableFooterFunction_Cnt: string = "TableFooterFunction_Cnt";
  TableFooterFunction_Avg: string = "TableFooterFunction_Avg";
  TableFooterFunction_StDev: string = "TableFooterFunction_StDev";
  TableFooterFunction_Var: string = "TableFooterFunction_Var";
  TableFooterFunction_CoefficientOfVariation: string = "TableFooterFunction_CoefficientOfVariation";
  ShowFooterLabels: string = "ShowFooterLabels";

  constructor() {
    Object.keys(this).forEach((key) => {
      const thisKey = key as keyof PropertySheetLocalizationDictionary;
      this[thisKey] = `PropertySheet.${thisKey}`;
    });
  }
}
