import { Pipe, PipeTransform } from "@angular/core";
import { groupBy as _groupBy, partition as _partition } from "lodash";
import { Dictionary, isDefined, isEmpty } from "../../ts-utils";
import { ItemInfo } from "../models/property-sheet-config";

@Pipe({ name: "categorizeProperties" })
export class CategorizePropertiesPipe implements PipeTransform {
  public transform(properties: ItemInfo[]): Dictionary<ItemInfo[]> {
    const propsDict: Dictionary<ItemInfo[]> = {};
    const [groupedProperties, individualProperties] = _partition(properties, (property) =>
      isDefined(property.propertyInfo.descriptor.groupInfo)
    );

    if (!isEmpty(individualProperties)) {
      propsDict[""] = individualProperties;
    }
    if (!isEmpty(groupedProperties)) {
      const groups: Dictionary<ItemInfo[]> = _groupBy(
        groupedProperties,
        (prop) => prop.propertyInfo.descriptor.groupInfo.groupId
      );
      Object.entries(groups).forEach(([id, props]) => {
        propsDict[id] = props;
      });
    }

    return propsDict;
  }
}
