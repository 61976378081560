import { Injectable } from "@angular/core";
import { QueryParamsResolverService } from "projects/ui-core/src/lib/elements/services/query-params-resolver.service";
import {
  DataConnectorFactory,
  DataConnectorSelector,
  DataServiceParams,
  Dispatcher,
  EquipmentToDataConnectorsConverter,
  HttpService,
  IFilterSelector,
  LocalizationService,
  QueryParamKeyConverter,
  RuntimeSettingsSelector,
  TimeService,
  WebServicesConfiguration
} from "ui-core";
import { AzureEquipmentConverter } from "../services/api/azure-equipment.converter";
import { AzureQueryStringService } from "../services/api/azure-query-string.service";

@Injectable({
  providedIn: "root"
})
export class AzureServiceParams extends DataServiceParams {
  constructor(
    connectorSelector: DataConnectorSelector,
    apiConfig: WebServicesConfiguration,
    httpService: HttpService,
    equipmentConverter: EquipmentToDataConnectorsConverter,
    queryStringService: AzureQueryStringService,
    dispatcher: Dispatcher,
    dataConnectorFactory: DataConnectorFactory,
    timeService: TimeService,
    public azureEquipmentConverter: AzureEquipmentConverter,
    queryParamsResolver: QueryParamsResolverService,
    localizationService: LocalizationService,
    runtimeSettingsSelector: RuntimeSettingsSelector,
    public filterSelector: IFilterSelector,
    queryParamKeyConverter: QueryParamKeyConverter
  ) {
    super(
      connectorSelector,
      apiConfig,
      httpService,
      equipmentConverter,
      queryStringService,
      dispatcher,
      timeService,
      dataConnectorFactory,
      queryParamsResolver,
      localizationService,
      runtimeSettingsSelector,
      filterSelector,
      queryParamKeyConverter
    );
  }
}
