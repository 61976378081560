export class DialogsLocalizationDictionary {
  Save: string = "Save";
  Discard: string = "Discard";
  Confirm: string = "Confirm";
  Cancel: string = "Cancel";
  Yes: string = "Yes";
  No: string = "No";
  Ok: string = "Ok";
  ReportName: string = "ReportName";
  ReportNameTooltip: string = "ReportNameTooltip";
  UnsavedChangesMessage: string = "UnsavedChangesMessage";
  Send: string = "Send";
  Bug: string = "Bug";
  FeatureRequest: string = "FeatureRequest";
  Question: string = "Question";
  Other: string = "Other";
  AllowDebugInfo: string = "AllowDebugInfo";
  Attachments: string = "Attachments";
  Type: string = "Type";
  Title: string = "Title";
  Description: string = "Description";
  AliasName: string = "AliasName";

  constructor() {
    Object.keys(this).forEach((key) => {
      const thisKey = key as keyof DialogsLocalizationDictionary;
      this[thisKey] = `Dialogs.${thisKey}`;
    });
  }
}
