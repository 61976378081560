export const ISO_DATE_TIME_REGEX = new RegExp(
  "^\\d{4}-([0][1-9]|1[0-2])-([0-2][1-9]|[1-3]0|3[01])(T(([01]\\d|2[0-3]):[0-5]\\d)(:[0-5]\\d)?(\\.\\d{3})?)?(Z|([+|\\-|\\s](\\d{1,2}\\:\\d{1,2}))?)?$"
);
export const ISO_TIME_SEPARATOR = "T";
export const DATE_AND_ZONE_OFFSET_SEPARATOR = "-";
export const ZERO_TIMEZONE_UTC_SEPARATOR = "Z";
export const UTC_POSITIVE_OFFSET_SEPARATOR = " ";
export const MILLISECONDS_SEPARATOR = ".";
export const TIME_PORTIONS_SEPARATOR = ":";
export const HOUR_TO_MS = 3600000;
export const MIN_TO_MS = 60000;
export const UTC_TIMEZONE_OFFSET = new RegExp(
  `${ZERO_TIMEZONE_UTC_SEPARATOR}|${DATE_AND_ZONE_OFFSET_SEPARATOR}|${UTC_POSITIVE_OFFSET_SEPARATOR}`
);
