export const SERIES_COLORS: string[] = Object.values({
  blue70: "#005ca3",
  green50: "#51a34b",
  magenta70: "#a8135d",
  gold50: "#c27121",
  red70: "#cc0815",
  teal80: "#16453d",
  purple40: "#e670e6",
  orange50: "#ed5739",
  blue50: "#00acec",
  gold70: "#753b00",
  teal40: "#5abfae",
  yellow40: "#85aa0d",
  purple90: "#4a014a",
  red50: "#ff4754",
  teal60: "#3b786e",
  red40: "#ff757e",
  blue90: "#00284a",
  orange90: "#4d0d00",
  orange70: "a82005",
  yellow70: "#524c00"
});
