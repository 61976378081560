export namespace HISTORY_VIEW_CONSTANTS {
  export const ID_PREFIX = "history-view-";
  export const FILTER_ID = ID_PREFIX + "filter";
  export const TIME_SLIDER_STEPS = 240;
  export const STEPS_PER_DATA_POINT = 2;
  export const TREND_ID = ID_PREFIX + "trend";
  export const TREND_CARD_ID = TREND_ID + "-card";
  export const MAX_CARD_HEIGHT_IN_VH = 99;
  const HOUR_IN_MS = 60 * 60 * 1000;
  const DAY_IN_MS = 24 * HOUR_IN_MS;
  export const TIME_RANGE_DISPLAY_FORMAT_THRESHOLD_LOW = 8 * HOUR_IN_MS;
  export const TIME_RANGE_DISPLAY_FORMAT_THRESHOLD_HIGH = 31 * DAY_IN_MS;
  export const CURRENT_TIME_DISPLAY_FORMAT_TRESHOLD_LOW = 8 * HOUR_IN_MS;
  export const CURRENT_TIME_DISPLAY_FORMAT_TRESHOLD_MID = 7 * DAY_IN_MS;
  export const CURRENT_TIME_DISPLAY_FORMAT_TRESHOLD_HIGH = 365 * DAY_IN_MS;
}
