import { Action, createReducer, on } from "@ngrx/store";
import { Theme } from "../../../theme";
import { isEmptyOrNotDefined } from "../../../ts-utils";
import { DEFAULT_DATE_FORMAT } from "../../helpers/date-formatter.helper";
import { AppSettings } from "../../models/app-settings";
import { AppSettingsActions } from "./app-settings.actions";

const initialState: AppSettings = {
  useStartTime: true,
  currentLanguage: "en",
  dateFormat: DEFAULT_DATE_FORMAT,
  userName: "",
  theme: Theme.Dark,
  aliasMode: false
};

export function reducer(state: AppSettings, action: Action): AppSettings {
  return _reducer(state, action);
}

const _reducer = createReducer(
  initialState,
  on(AppSettingsActions.updateCurrentLanguage, (state, { language }) =>
    updateCurrentLanguage(state, language)
  ),
  on(AppSettingsActions.updateDateFormat, (state, { dateFormat }) =>
    updateDateFormat(state, dateFormat)
  ),
  on(AppSettingsActions.updateUserName, (state, { userName }) => updateUserName(state, userName)),
  on(AppSettingsActions.updateTheme, (state, { theme }) => updateTheme(state, theme)),
  on(AppSettingsActions.changeAliasMode, (state, { aliasMode }) =>
    updateAliasMode(state, aliasMode)
  )
);

function updateCurrentLanguage(state: AppSettings, language: string): AppSettings {
  return {
    ...state,
    currentLanguage: language
  };
}

function updateDateFormat(state: AppSettings, newDateFormat: string): AppSettings {
  if (isEmptyOrNotDefined(newDateFormat)) {
    return state;
  }
  return {
    ...state,
    dateFormat: newDateFormat
  };
}

function updateUserName(state: AppSettings, newUserName: string): AppSettings {
  return {
    ...state,
    userName: newUserName
  };
}

function updateTheme(state: AppSettings, theme: Theme): AppSettings {
  return {
    ...state,
    theme: theme
  };
}

function updateAliasMode(state: AppSettings, aliasMode: boolean): AppSettings {
  return {
    ...state,
    aliasMode
  };
}
