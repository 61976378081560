import { createAction, props } from "@ngrx/store";
import { CreateReportDialogInfo } from "../../models/create-report-dialog-info";
import { ReportCreationParams } from "../../models/report-creation-params";

export namespace CreateReportDialogActions {
  export const openCreateReportDialog = createAction(
    "[Dialog] Open Create Report Dialog",
    props<{ reportDialogInfo: CreateReportDialogInfo }>()
  );

  export const onCreateReportDialogClosed = createAction(
    "[Dialog] On Create Report Dialog Closed",
    props<{ reportCreationInfo: ReportCreationParams }>()
  );
}
