import { isLabel } from "../../elements/models/component-type.helper";
import { isNotDefined } from "../../ts-utils/helpers";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

export class UpdateDefaultLabelFontSize implements UpgradeStep {
  name: string = "UpdateDefaultLabelFontSize";
  fromVersion: Version = new Version(1, 0, 2);

  perform(oldConfig: any): UpgradeStepResult {
    const componentStates: any = oldConfig["componentStates"]["entities"];
    let changed = false;
    Object.values(componentStates).forEach((component: any) => {
      const view = component["view"];
      if (isLabel(component["type"]) && isNotDefined(view["fontSize"])) {
        const oldDefaultFontSizePx = 14;
        view["fontSize"] = oldDefaultFontSizePx;
        changed = true;
      }
    });
    return {
      result: oldConfig,
      modified: changed,
      warning: null
    };
  }
}
