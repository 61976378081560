import { createAction, props } from "@ngrx/store";
import { Equipment } from "../../../core/models/equipment";

export namespace EquipmentActions {
  export const loadEquipmentModel = createAction(
    "[Equipment] Load Equipment Model",
    props<{
      newRootPath: string;
    }>()
  );

  export const loadEquipmentModelSuccess = createAction(
    "[Equipment] Load Equipment Model Success",
    props<{ rootEquipment: Equipment }>()
  );
}
