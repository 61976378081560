import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap, tap } from "rxjs/operators";
import { ColorPickerDialogComponent } from "../../../property-sheet/components";
import { ColorPickerDialogActions } from "../actions/color-picker-dialog.actions";
import { PropertySheetEditorDialogStrategy } from "../dialog-config.strategies";
import { OpenDialogEffect } from "./dialog.effects";

@Injectable()
export class ColorPickerDialogEffects extends OpenDialogEffect {
  constructor(private actions$: Actions, dialog: MatDialog) {
    super(dialog);
  }

  openColorPickerDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ColorPickerDialogActions.openColorPickerDialog),
      tap(({ colorPickerDialogInfo }) => {
        this.dialogConfigStrategy = new PropertySheetEditorDialogStrategy();
        this.dialogConfig = this.dialogConfigStrategy.createDialogConfig(colorPickerDialogInfo);
        this.dialogRef = this.dialog.open(ColorPickerDialogComponent, this.dialogConfig);
      }),
      switchMap(() => {
        return this.dialogRef.afterClosed().pipe(
          map((result: string) => {
            return ColorPickerDialogActions.onColorPickerDialogClosed({
              selectedColorInfo: result ?? this.dialogRef.componentInstance.color
            });
          })
        );
      })
    )
  );
}
