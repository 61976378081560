<div class="card-item__drag-handle pole-alignment-chart__wrapper">
  <highcharts-chart-extended
    class="chart-container pole-alignment-chart__chart-container"
    [Highcharts]="Highcharts"
    [options]="chartOptions"
  >
  </highcharts-chart-extended>
  <div class="pole-alignment-chart__legend-container">
    <div class="pole-alignment__column-title">LEGEND</div>
    <km-legend
      [itemList]="[
        { name: 'Smallest Airgap', color: view.largestAirgapColor },
        { name: 'Average Airgap', color: view.averageAirgapColor },
        { name: 'Largest Airgap', color: view.smallestAirgapColor },
        { name: 'Poles', color: view.polesColor },
        { name: 'Scaled down mill shell', color: '#000000' }
      ]"
    >
    </km-legend>
  </div>
</div>
