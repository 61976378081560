<div class="layout-builder-tab__container">
  <div class="layout-builder-tab__items">
    <div
      class="layout-builder-tab__item"
      *ngFor="let componentAndAlias of componentsTypeDescriptors"
      draggable="true"
      (drag)="dragStart($event, componentAndAlias)"
      (touchstart)="dragStart($event, componentAndAlias)"
      (touchmove)="touchMove($event)"
      (touchend)="dropOnTouchDevices($event)"
      (mousedown)="setSelectedItem(componentAndAlias)"
    >
      <i class="item-icon" [ngClass]="getIconFromComponentMetadata(componentAndAlias)"></i>
      <div class="item-name">
        {{ getComponentClassName(componentAndAlias) | translate }}
      </div>
    </div>
  </div>
</div>
