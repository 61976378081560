import { Injectable } from "@angular/core";
import { Equipment } from "../../core/models/equipment";
import { DataConnectorDto } from "../../data-connectivity/models/data-connector";
import { ComponentStateDto } from "../../elements/models/component-state";
import { EntityId } from "../../meta/models/entity";
import { EquipmentDataSourceDto } from "../models/data-source/equipment-data-source";

@Injectable()
export class EquipmentToDataConnectorsConverter {
  public convert(
    equipmentLists: Equipment[][],
    equipmentQueryDict: EquipmentDataSourceDto[]
  ): DataConnectorDto[][] {
    throw new Error("Not implemented");
  }

  public setDefaultRole(
    dataConnectorMatrix: DataConnectorDto[][],
    originatorIds: EntityId[],
    componentStates: ComponentStateDto[]
  ): void {
    throw new Error("Not implemented");
  }

  public setConnectorIds(
    dataConnectorMatrix: DataConnectorDto[][],
    originatorIds: EntityId[]
  ): void {
    throw new Error("Not implemented");
  }
}
