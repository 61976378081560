<div class="card-item__drag-handle">
  <div class="classified-heatmap-chart__chart-wrapper">
    <highcharts-chart-extended
      class="classified-heatmap-chart__chart"
      [Highcharts]="Highcharts"
      [options]="chartOptions"
    >
    </highcharts-chart-extended>
    <div class="classified-heatmap-chart__zoom-buttons-wrapper">
      <div class="classified-heatmap-chart__zoom-button-container">
        <i class="abb-icon abb-icon--large Zoom_in" (click)="zoomIn()"></i>
      </div>
      <div class="classified-heatmap-chart__zoom-button-container">
        <i class="abb-icon abb-icon--large Zoom_out" (click)="zoomOut()"></i>
      </div>
      <div class="classified-heatmap-chart__zoom-button-container">
        <i class="abb-icon abb-icon--large Search" (click)="zoomReset()"></i>
      </div>
    </div>
  </div>
  <div class="classified-heatmap-chart__bottom-container">
    <div class="classified-heatmap-chart__legend-container">
      <km-legend
        [itemList]="[
          { name: 'Faulty', color: view.faultyColor },
          { name: 'Forced', color: view.forcedColor },
          { name: 'Healthy', color: view.healthyColor },
          { name: 'Noisy', color: view.noisyColor },
          { name: 'Unknown', color: view.unknownColor }
        ]"
      ></km-legend>
    </div>
  </div>
</div>
