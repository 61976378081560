<div class="card-item__drag-handle">
  <div class="trend__control-container">
    <div class="trend__control-group-container">
      <button class="c-button c-button--light c-button--rounded" (click)="openAddAxisDialog()">
        Add Y axis
      </button>
      <button class="c-button c-button--light c-button--rounded" (click)="openRemoveAxisDialog()">
        Remove Y axes
      </button>
    </div>
    <div class="trend__control-group-container">
      <button class="c-button c-button--light c-button--rounded" (click)="openAddSignalDialog()">
        Add Signals
      </button>
      <button class="c-button c-button--light c-button--rounded" (click)="openRemoveSignalDialog()">
        Remove Signals
      </button>
    </div>
    <div class="trend__control-group-container">
      <button class="c-button c-button--light c-button--rounded" (click)="openAddPlotlineDialog()">
        Add Plotline
      </button>
      <button
        class="c-button c-button--light c-button--rounded"
        (click)="openRemovePlotlineDialog()"
      >
        Remove Plotlines
      </button>
    </div>
    <div class="trend__control-group-container">
      <historic-range
        [timeRange]="timeRange"
        (timeRangeChanged)="onTimeRangeChanged($event)"
      ></historic-range>
    </div>
  </div>
  <highcharts-chart-extended
    [Highcharts]="Highcharts"
    [options]="chartOptions"
    [constructorType]="constructorType"
  >
  </highcharts-chart-extended>
</div>
