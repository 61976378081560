import "reflect-metadata";
import { v4 as uuid } from "uuid";
import {
  DEFAULT_WIDTH_RATIO,
  GroupablePropertiesParams,
  GroupPropertiesInfo
} from "../models/groupable-properties";
import { PropertyDescriptor } from "../models/property-descriptor";
import { TypeProvider } from "../services/type-provider";

//adds the properties from the "layout" param to the proper subgroup

const TYPE_PROVIDER = TypeProvider.getInstance();

export function GroupNestedProperties(params: GroupablePropertiesParams) {
  return function (typeConstructor: Function) {
    const groupId: string = uuid();
    for (let i = 0; i < params.layout.length; i++) {
      for (let j = 0; j < params.layout[i].length; j++) {
        const key = params.layout[i][j];
        const typeDescriptor = TYPE_PROVIDER.addType({
          constructorFunction: typeConstructor as new () => any
        });
        const groupInfo: GroupPropertiesInfo = {
          groupId,
          subgroupIndex: i,
          subgroupWidthRatio: params.subgroupsWidthRatio[i] ?? DEFAULT_WIDTH_RATIO
        };
        if (typeDescriptor.getPropertyByName(key)) {
          typeDescriptor.updateProperty(key, {
            groupInfo
          });
        } else {
          typeDescriptor.addProperty(
            PropertyDescriptor.create(key, typeConstructor, {
              groupInfo
            })
          );
        }
      }
    }
  };
}
