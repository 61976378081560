import { AfterViewInit, Component } from "@angular/core";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { ConnectorRoles } from "../../decorators/connector-roles.decorator";
import { View } from "../../decorators/view.decorator";
import { BaseComponent } from "../base/base.component";
import { ChartComponent } from "../chart/chart.component";
import { Roles } from "./roles";
import { ClassifiedHeatmapChartViewConfig } from "./view-config";

@Component({
  selector: "rds-classified-heatmap-chart",
  templateUrl: "./classified-heatmap-chart.component.html",
  styleUrls: ["./classified-heatmap-chart.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: ClassifiedHeatmapChartComponent }]
})
// @LayoutBuilder(
//   ComponentCategory.OtherComponents,
//   "ClassifiedHeatmapChartComponent",
//   "icon-Classified-Heatmap-Chart",
//   "dashboard-icon"
// )
@ConnectorRoles(Roles)
@EditableWidget({ fullName: "ClassifiedHeatmapChartComponent", title: "classified-heatmap-chart" })
export class ClassifiedHeatmapChartComponent extends ChartComponent implements AfterViewInit {
  private originalXMin: number;
  private originalXMax: number;
  private currentXMin: number;
  private currentXMax: number;
  private zoomFactor: number;

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.originalXMin = this.chartObject.xAxis[0].getExtremes().min;
    this.originalXMax = this.chartObject.xAxis[0].getExtremes().max;
    this.currentXMin = this.originalXMin;
    this.currentXMax = this.originalXMax;
    this.zoomFactor = Math.abs(this.originalXMax - this.originalXMin) / 10;
  }

  @View(ClassifiedHeatmapChartViewConfig)
  public get view() {
    return this.currentState.view as ClassifiedHeatmapChartViewConfig;
  }

  protected updateChartData() {}

  protected setChartOptions(): void {
    const component = this;
    this.chartOptions = {
      lang: {
        noData: "No Data Available"
      },
      chart: {
        type: "heatmap",
        panning: true,
        events: {
          load: function () {
            component.chartObject = this; // expose chart object to component in order to properly add and remove axes
          }
        }
      },
      title: { text: null },
      credits: { enabled: false },
      legend: { enabled: false },
      colorAxis: {
        dataClassColor: "category",
        dataClasses: [
          {
            name: "Unknown",
            color: this.view.unknownColor,
            from: 0
          },
          {
            name: "Faulty",
            color: this.view.faultyColor,
            from: 1
          },
          {
            name: "Forced",
            color: this.view.forcedColor,
            from: 2
          },
          {
            name: "Healthy",
            color: this.view.healthyColor,
            from: 3
          },
          {
            name: "Noisy",
            color: this.view.noisyColor,
            from: 4
          }
        ]
      },
      xAxis: {
        type: "category",
        title: { text: null },
        categories: [],
        tickmarkPlacement: "on"
      },
      yAxis: {
        type: "category",
        title: { text: null },
        categories: [
          ...new Array(15).fill(0).map((signal, signalIndex) => `AGS${signalIndex + 1}`)
        ].reverse(),
        gridLineWidth: 0,
        tickLength: 0,
        lineWidth: 1,
        offset: 5
      },
      plotOptions: {
        series: {
          rowsize: 0.9,
          colsize: 1
        }
      },
      tooltip: {
        useHTML: true,
        headerFormat: "<small>{series.name}</small><table>",
        pointFormat:
          "<tr><td>{point.x}:</td>" +
          '<td style="text-align: right"><b>{point.value}</b></td></tr>',
        footerFormat: "</table>",
        borderWidth: 3,
        valueDecimals: 0,
        backgroundColor: "#ffffff"
      },
      series: [
        ...new Array(15).fill(0).map((signal, signalIndex, array) => {
          return {
            name: `AGS${array.length - signalIndex}`,
            data: new Array(90).fill(0).map((day, dayIndex) => {
              return [dayIndex, signalIndex, Math.floor(Math.random() * (4 - 0 + 1)) + 0];
            })
          };
        })
      ]
    };
  }

  zoomIn() {
    this.currentXMin = this.chartObject.xAxis[0].getExtremes().min;
    this.currentXMax = this.chartObject.xAxis[0].getExtremes().max;
    this.currentXMin += this.zoomFactor;
    this.currentXMax -= this.zoomFactor;
    this.chartObject.xAxis[0].setExtremes(this.currentXMin, this.currentXMax);
  }
  zoomOut() {
    const isOutOfLeftBound = this.currentXMin - this.zoomFactor < this.originalXMin;
    const isOutOfRightBound = this.currentXMax + this.zoomFactor > this.originalXMax;
    if (isOutOfLeftBound || isOutOfRightBound) {
      this.zoomReset();
    } else {
      this.currentXMin -= this.zoomFactor;
      this.currentXMax += this.zoomFactor;
      this.chartObject.xAxis[0].setExtremes(this.currentXMin, this.currentXMax);
    }
  }
  zoomReset() {
    this.currentXMin = this.originalXMin;
    this.currentXMax = this.originalXMax;
    this.chartObject.xAxis[0].setExtremes(this.currentXMin, this.currentXMax);
  }
}
