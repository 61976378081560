import { Component } from "@angular/core";
import { LOCALIZATION_DICTIONARY } from "../../../../i18n/models/localization-dictionary";
import { LayoutBuilder } from "../../../../meta/decorators";
import { EditableWidget } from "../../../../meta/decorators/editable-widget.decorator";
import { ComponentCategory } from "../../../../meta/models/component-category";
import { ConnectorRoles } from "../../../decorators/connector-roles.decorator";
import { View } from "../../../decorators/view.decorator";
import { BaseComponent } from "../../base/base.component";
import { HorizontalSeparatorViewConfig } from "./view-config";

@Component({
  selector: "c-horizontal-separator",
  templateUrl: "../separator.component.html",
  styleUrls: ["./horizontal-separator.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: HorizontalSeparatorComponent }]
})
@LayoutBuilder(
  ComponentCategory.Glitter,
  "HorizontalSeparatorComponent",
  "More",
  "abb-icon ",
  null,
  LOCALIZATION_DICTIONARY.layoutEditor.HorizontalSeparator
)
@ConnectorRoles()
@EditableWidget({ fullName: "HorizontalSeparatorComponent", title: "horizontal-separator" })
export class HorizontalSeparatorComponent extends BaseComponent {
  @View(HorizontalSeparatorViewConfig)
  public get view(): HorizontalSeparatorViewConfig {
    return this.currentState.view as HorizontalSeparatorViewConfig;
  }

  get lineStyle(): Partial<CSSStyleDeclaration> {
    const style: Partial<CSSStyleDeclaration> = {};
    style["backgroundColor"] = this.view.color ?? this.view.foregroundColor;
    return style;
  }
}
