<div class="meta-data-inspector__wrapper card-item__drag-handle">
  <mat-tab-group *ngIf="connectorInfos != null" animationDuration="0ms">
    <mat-tab *ngFor="let connectorInfo of connectorInfos" label="{{ connectorInfo.title }}">
      <dl>
        <ng-template ngFor let-kvp [ngForOf]="connectorInfo.connectorProperties">
          <dt>{{ kvp[0] }}</dt>
          <dd>{{ kvp[1] }}</dd>
        </ng-template>
      </dl>

      <ng-template [ngIf]="connectorInfo.dataPointProperties != null" [ngIfElse]="noData">
        <h4>Points</h4>
        <mat-tab-group
          *ngIf="connectorInfo.dataPointProperties != null"
          animationDuration="0ms"
          class="small-mat-tab"
        >
          <mat-tab
            *ngFor="let pointProps of connectorInfo.dataPointProperties; let i = index"
            label="{{ i }}"
          >
            <dl>
              <ng-template ngFor let-kvp [ngForOf]="pointProps">
                <dt>{{ kvp[0] }}</dt>
                <dd>{{ kvp[1] }}</dd>
              </ng-template>
            </dl>
          </mat-tab>
        </mat-tab-group>
      </ng-template>

      <ng-template #noData>
        <h4>no Data</h4>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
