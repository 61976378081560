import {
  BaseViewConfigDto,
  ComponentCssSize,
  construct,
  DeepPartial,
  EditableType,
  FULL_WIDTH_CSS_SIZE
} from "ui-core";

const TYPE_NAME = "FleetListViewConfig";

@EditableType({ fullName: TYPE_NAME })
export class FleetListViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  constructor(viewConfigDto: DeepPartial<FleetListViewConfig> = {}) {
    super();
    construct(this, viewConfigDto, TYPE_NAME, { size: getDefaultSize() });
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize(FULL_WIDTH_CSS_SIZE, "625");
}
