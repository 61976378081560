import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { PageWidth } from "../../../core/models/page-layout";
import { RuntimeSettings } from "../../../core/models/runtime-settings";
import { IRuntimeSettingsSelector } from "../../../core/services/i-runtime-settings.selector";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import {
  selectCurrentRootPath,
  selectPagePreviewWidth,
  selectRuntimePeriodType,
  selectRuntimeSettings
} from "../../store/runtime-settings/runtime-settings.selectors";

@Injectable()
export class RuntimeSettingsSelector implements IRuntimeSettingsSelector {
  constructor(private store$: Store<any>) {}

  select(): Observable<RuntimeSettings> {
    return this.store$.select(selectRuntimeSettings);
  }

  selectPagePreviewWidth(): Observable<PageWidth> {
    return this.store$.select(selectPagePreviewWidth);
  }

  selectCurrentRootPath(): Observable<string> {
    return this.store$.select(selectCurrentRootPath);
  }

  selectPeriodType(): Observable<string> {
    return this.store$.select(selectRuntimePeriodType);
  }

  getRuntimeSettings(): RuntimeSettings {
    let runtimeSettings: RuntimeSettings;
    this.select()
      .pipe(take(1))
      .subscribe((runSettings) => (runtimeSettings = runSettings));
    return runtimeSettings;
  }

  getCurrentRootPath(): string {
    let rootPath: Maybe<string>;
    this.store$
      .select(selectCurrentRootPath)
      .pipe(take(1))
      .subscribe((currentRootPath) => (rootPath = currentRootPath));
    return rootPath ?? "";
  }

  getPeriodType(): string {
    let periodType: Maybe<string>;
    this.selectPeriodType()
      .pipe(take(1))
      .subscribe((pType) => (periodType = pType));
    return periodType ?? "";
  }

  getPagePreviewWidth(): PageWidth {
    let pageWidth: Maybe<PageWidth>;
    this.selectPagePreviewWidth()
      .pipe(take(1))
      .subscribe((pagePreviewWidth) => (pageWidth = pagePreviewWidth));
    return pageWidth ?? "";
  }
}
