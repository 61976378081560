import { Injectable } from "@angular/core";
import { Dictionary } from "../../ts-utils/models/dictionary.type";
import { BaseViewConfigDto } from "../models/base-view-config";

@Injectable({ providedIn: "root" })
export class StrategyDefaultsOverrideService {
  strategyDefaultsOverrideDict: Dictionary<Partial<BaseViewConfigDto>> = {};

  setStrategyOverrides(strategyOverrides: Dictionary<Partial<BaseViewConfigDto>>): void {
    Object.assign(this.strategyDefaultsOverrideDict, strategyOverrides);
  }

  getStrategyOverrides(strategy: string): Partial<BaseViewConfigDto> {
    return this.strategyDefaultsOverrideDict[strategy] ?? {};
  }
}
