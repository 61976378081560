import { Options, YAxisLabelsOptions, YAxisOptions, YAxisPlotBandsOptions } from "highcharts";
import { formatValueByStringFormat } from "../../../core/services/value-formatter.service";
import { isDefined, Maybe, tryConvertToNumber } from "../../../ts-utils";
import {
  getConfiguredOrForegroundColor,
  getTextColorForNoDataStatus,
  getTextColorForSingleValue
} from "../../helpers/color.helper";
import { DEFAULT_CHART_TEXT_COLOR_HEX } from "../../models/colors.constants";
import { DataStatus } from "../../models/data-status";
import { IBaseDisplayConfig } from "../../models/i-view-config/i-base-display-config";
import { IGaugeDisplayConfig } from "../../models/i-view-config/i-gauge-display-config";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import { LimitMarkerHelper } from "../limit.helper";
import { mergeChartOptions, PRIMARY_Y_AXIS_ID } from "./base-highcharts-options.helper";

export abstract class GaugeDisplayService {
  protected chartType: string;
  protected isTargetAsAxis: boolean; // FIXME: proper naming

  shouldRedraw(
    previousProcessedViewConfig: IBaseDisplayConfig,
    currentProcessedViewConfig: IBaseDisplayConfig,
    previousChartOptions: Maybe<Options>,
    currentChartOptions: Maybe<Options>
  ): boolean {
    return this.requiresFullRedraw(
      previousProcessedViewConfig,
      currentProcessedViewConfig,
      previousChartOptions,
      currentChartOptions
    );
  }

  protected requiresFullRedraw(
    previousProcessedViewConfig: IBaseDisplayConfig,
    currentProcessedViewConfig: IBaseDisplayConfig,
    previousChartOptions: Maybe<Options>,
    currentChartOptions: Maybe<Options>
  ): boolean {
    return false;
  }

  getChartOptions(
    viewConfig: IGaugeDisplayConfig,
    dataConnectorDescriptors: Maybe<DataConnectorDescriptor>[],
    dataStatus: DataStatus
  ): Options {
    const specificOptions = this.getSpecificGaugeOptions(
      viewConfig,
      dataConnectorDescriptors,
      dataStatus
    );
    const commonOptions = this.getCommonGaugeOptions(
      viewConfig,
      this.chartType,
      this.isTargetAsAxis,
      dataStatus
    );
    const combined = mergeChartOptions(commonOptions, specificOptions);
    return combined;
  }

  protected abstract getSpecificGaugeOptions(
    viewConfig: IGaugeDisplayConfig,
    dataConnectorDescriptors: Maybe<DataConnectorDescriptor>[],
    dataStatus: DataStatus
  ): Options;

  public getCommonGaugeOptions(
    viewConfig: IGaugeDisplayConfig,
    type: string,
    targetAsAxis: boolean,
    dataStatus: DataStatus
  ): Options {
    const component = this;
    const chartOptions: Options = {
      chart: {
        type: type,
        style: {},
        showAxes: true
      },
      noData: {
        style: {
          color: getTextColorForNoDataStatus(dataStatus, DEFAULT_CHART_TEXT_COLOR_HEX)
        }
      },
      yAxis: [
        {
          id: PRIMARY_Y_AXIS_ID,
          title: null,
          min: tryConvertToNumber(viewConfig.min),
          max: tryConvertToNumber(viewConfig.max),
          labels: {
            style: { color: viewConfig.foregroundColor }
          }
        }
      ],
      plotOptions: {
        series: {
          dataLabels: {
            enabled: viewConfig.shouldShowDataLabel,
            style: {
              fontFamily: "ABB Font",
              fontWeight: "normal"
            },
            useHTML: true,
            formatter: function () {
              return component.getDataLabelFormat(this.y, viewConfig);
            }
          }
        }
      },
      tooltip: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      lang: {
        noData: dataStatus
      }
    };

    if (targetAsAxis && viewConfig.limits.target != null) {
      (chartOptions.yAxis as YAxisOptions[]).push({
        id: "targetAxis",
        linkedTo: 0,
        lineWidth: 0,
        minorTickLength: 0,
        tickPositions: [viewConfig.limits.target],
        tickLength: 18,
        tickColor: viewConfig.limits.targetColor,
        tickWidth: 2,
        zIndex: 6,
        labels: {
          distance: 13,
          style: {
            color: getConfiguredOrForegroundColor(
              viewConfig.limits.targetColor,
              viewConfig.foregroundColor
            )
          },
          formatter: () => {
            return formatValueByStringFormat(viewConfig.limits.target, viewConfig.displayFormat);
          }
        }
      } as any);
    }

    return chartOptions;
  }

  protected getDataLabelFormat(value: Maybe<number>, viewConfig: IGaugeDisplayConfig): string {
    const color = getTextColorForSingleValue(value, viewConfig);
    return `
      <div
        style="
          font-size: ${viewConfig.fontSize}px;
          text-align: center;
          color: ${color};
          font-weight: bold;
        "
      >
      ${isDefined(value) ? formatValueByStringFormat(value, viewConfig.displayFormat) : ""}
      <br />
      ${viewConfig.unit || ""}
      </div>`.trim();
  }

  protected getYAxisLabelOptions(viewConfig: IGaugeDisplayConfig): YAxisLabelsOptions {
    return {};
  }

  protected getYAxisPlotBands(viewConfig: IGaugeDisplayConfig): YAxisPlotBandsOptions[] {
    const bands = new LimitMarkerHelper(viewConfig.limits).getBands(
      tryConvertToNumber(viewConfig.min),
      tryConvertToNumber(viewConfig.max),
      viewConfig.neutralColor
    );
    return bands;
  }
}
