import { TypeProvider } from "../../meta/services/type-provider";
import { mergeDeep } from "../../ts-utils/helpers/assignment.helper";
import { isEmpty } from "../../ts-utils/helpers/is-empty.helper";
import { isDefined } from "../../ts-utils/helpers/predicates.helper";
import { DeepPartial } from "../../ts-utils/models/deep-partial.type";

const TYPE_PROVIDER = TypeProvider.getInstance();

export function construct<T>(
  object: T,
  params: DeepPartial<T>,
  typeName: string,
  defaultOverrides: Partial<T> = {}
): void {
  const defaults = isDefined(typeName) ? TYPE_PROVIDER.getDefaultsByName<T>(typeName) : {};
  const allDefaults = { ...defaults, ...defaultOverrides, typeName };
  const newThis = isEmpty(params) ? allDefaults : mergeDeep(allDefaults, params, false);
  Object.assign(object, newThis);
}
