import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { EditorType } from "../../../meta/models/editor-type";
import { CustomFilterValueType } from "../../../shared/models/custom-filter-value-type";
import { Maybe } from "../../../ts-utils/models/maybe.type";

export class FilterTypeDescriptor {
  constructor(
    public type: CustomFilterValueType,
    public title: string,
    public typeConstructorFunction: Function,
    public editor: EditorType
  ) {}
}

export const CUSTOM_FILTER_TYPES: FilterTypeDescriptor[] = [
  new FilterTypeDescriptor(
    CustomFilterValueType.Text,
    LOCALIZATION_DICTIONARY.propertySheet.FilterType_Text,
    String,
    EditorType.TextBox
  ),
  new FilterTypeDescriptor(
    CustomFilterValueType.Number,
    LOCALIZATION_DICTIONARY.propertySheet.FilterType_Number,
    Number,
    EditorType.Number
  )
];

export type CustomFilterValue = string | Maybe<number>;
