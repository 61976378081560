<div class="file-selection-editor__input-and-x-wrapper">
  <input
    #editorInput
    type="text"
    class="editor--discreet-input file-selection-editor__input"
    [class.editor--value-configured]="value !== propertyInfo.descriptor.defaultValue"
    [formControl]="displayValue"
    *ngIf="!isReadOnly"
  />
  <div
    [className]="value === '' ? 'floating-x-hidden' : 'floating-x-show'"
    (click)="onXClick()"
  >
    &times;
  </div>
  <label
    #fileSearch
    *ngIf="!isReadOnly"
    class="c-button c-button--rounded file-selection-editor__select-option"
  >
    ...
    <input
      #fileInput
      focusVisualization
      [focusedElement]="fileSearch"
      type="file"
      class="file-selection-editor__file-input"
      (change)="onFileInput($event)"
    />
  </label>
</div>
<div *ngIf="value != null && value !== ''" class="image-box">
  <img [src]="value | explicite | async" />
</div>
