import { Dictionary } from "../../ts-utils";
import { DroppableElement } from "./droppable-element";
import { EquipmentPropertyDataType } from "./equipment-property-data-type";

// TODO Should most things except name and value go into "properties"?
export class EquipmentProperty implements DroppableElement {
  public name: string;
  public logId?: number | string;
  public unit?: string;
  public className?: string;
  public dataType?: EquipmentPropertyDataType;
  // property can be attached either to the log or to the constant value
  public value?: any;
  public description?: string;
  public aliasName?: string;
  public properties?: Dictionary<any>;
  public aliasClassName?: string;
}
