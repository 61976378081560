import { TimeUnit } from "./time-unit";

export class TimeOffset {
  value: number;
  type: TimeUnit;

  constructor(value: number, type: TimeUnit) {
    this.value = value;
    this.type = type;
  }
}
