import {
  BaseViewConfigDto,
  ComponentCssSize,
  Configurable,
  construct,
  DeepPartial,
  EditableType,
  EditorType,
  Serializable
} from "ui-core";

const TYPE_NAME = "PoleFluxMonitoringChartViewConfig";

@EditableType({ fullName: TYPE_NAME })
export class PoleFluxMonitoringChartViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  @Configurable({ displayName: "Flux Density Color", editorType: EditorType.ColorPicker })
  @Serializable("#FF0000")
  fluxDensityColor!: string;

  @Configurable({ displayName: "Min. Flux Density Color", editorType: EditorType.ColorPicker })
  @Serializable("#eef442")
  minFluxDensityColor!: string;

  @Configurable({ displayName: "Max. Flux Density Color", editorType: EditorType.ColorPicker })
  @Serializable("#4db82c")
  maxFluxDensityColor!: string;

  @Configurable({ displayName: "Airgap Color", editorType: EditorType.ColorPicker })
  @Serializable("#0974ff")
  airgapColor!: string;

  @Configurable({ displayName: "Min. Airgap Color", editorType: EditorType.ColorPicker })
  @Serializable("#3f8efc")
  minAirgapColor!: string;

  @Configurable({ displayName: "Max. Airgap Color", editorType: EditorType.ColorPicker })
  @Serializable("#ffb73a")
  maxAirgapColor!: string;

  currentPoleAirgapValues: number[] = [];
  smallestAirgapValues: number[] = [];
  largestAirgapValues: number[] = [];
  currentPoleDensityValues: number[] = [];
  smallestDensityValues: number[] = [];
  largestDensityValues: number[] = [];

  constructor(viewConfigDto: DeepPartial<PoleFluxMonitoringChartViewConfig> = {}) {
    super();
    construct(this, viewConfigDto, TYPE_NAME, { size: getDefaultSize() });
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("700", "350");
}
