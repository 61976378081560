import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { switchMap } from "rxjs/operators";
import { DYNAMIC_REPORT_CANVAS } from "../../../core/models/dynamic-report/dynamic-report.constants";
import { Dispatcher } from "../../../dispatcher";
import { AppSettingsActions } from "../../../environment/store/app-settings/app-settings.actions";
import { AppStatusActions } from "../../../environment/store/app-status/app-status.actions";
import { Theme } from "../../../theme";
import {
  convertReportToTemplate,
  refreshReportFromTemplate
} from "../../helpers/template-builder.helper";
import { Request, TemplateExportMessage } from "../../models/template-builder-models";
import BetterPostMessage from "../../services/better-postmessage.service";
import { ComponentStateSelector } from "../../services/entity-selectors/component-state.selector";
import { ReportInfoSelector } from "../../services/entity-selectors/report-info.selector";
import { TemplateBuilderElementsService } from "../../services/template-builder-elements.service";

@Injectable()
export class TemplateBuilderEffects {
  private messageReciever: BetterPostMessage<Request, TemplateExportMessage | void>;
  constructor(
    private actions$: Actions,
    private reportInfoSelector: ReportInfoSelector,
    private componentSelector: ComponentStateSelector,
    private dispatcher: Dispatcher,
    private templateBuilderElementsService: TemplateBuilderElementsService
  ) {
    this.messageReciever = new BetterPostMessage<Request, TemplateExportMessage | void>(window);

    this.messageReciever.onReceive((message) => {
      switch (message?.type) {
        case "export":
          return { html: convertReportToTemplate(this.componentSelector) };
        case "refresh":
          refreshReportFromTemplate(message.html, this.componentSelector, this.dispatcher);
          break;
        case "loadElements":
          this.templateBuilderElementsService.templateElements = message.templateElements;
          break;
        default:
          throw "unknown message type recieved:" + message;
      }
    });
  }

  finishLoadingReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppStatusActions.finishLoadingReport),
      switchMap(() => {
        if (this.reportInfoSelector.getReportId() === DYNAMIC_REPORT_CANVAS) {
          return [
            AppStatusActions.enterTemplateBuilderMode(),
            AppStatusActions.exitPreviewMode(),
            AppSettingsActions.updateTheme({ theme: Theme.Light })
          ];
        }
        return [];
      })
    )
  );
}
