import { CommonModule, HashLocationStrategy, LocationStrategy } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { ModuleWithProviders, NgModule, Type } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { EffectsModule } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { LinkResolver } from "./browsing";
import { BrowsingModule } from "./browsing/browsing.module";
import { IPTypeCheckService, QueryParamKeyConverter, QueryStringService } from "./core";
import { CoreModule } from "./core/core.module";
import { ColorList } from "./core/models/color-list";
import { KmTrendConfig, KmTrendConfigToken } from "./core/models/km-trend-config";
import { WebServicesConfiguration } from "./core/services/api.config";
import { CoreComponentCategoryProvider } from "./core/services/core-component-category-provider";
import { HelpService } from "./core/services/help.service";
import { IFeedbackService } from "./core/services/i-feedback.service";
import { IUserPreferencesService } from "./core/services/i-user-preferences.service";
import { DataService } from "./data-connectivity/services/data.service";
import { ApiDataSourceDescriptorSelector } from "./data-connectivity/store/api-data-source-descriptor/api-data-source-descriptor.selector";
import { DataSourceDescriptorEffects } from "./data-connectivity/store/data-source-descriptor/data-source-descriptor.effects";
import { PeriodTypeEffects } from "./data-connectivity/store/period-type/period-type.effects";
import { Dispatcher } from "./dispatcher";
import { ElementsModule } from "./elements/elements.module";
import { ConfigurationService } from "./elements/services/configuration.service";
import { EntityUpdater } from "./elements/services/entity-updater";
import { FeedbackService } from "./elements/services/feedback.service";
import { ProjectDefaults } from "./elements/services/project-defaults";
import { ReportsService } from "./elements/services/reports.service";
import { CommonEffects } from "./elements/store/common/common.effects";
import { ComponentStateEffects } from "./elements/store/component-state/component-state.effects";
import { DataConnectorEffects } from "./elements/store/data-connector/data-connector.effects";
import { FilterEffects } from "./elements/store/filter/filter.effects";
import { GeneralSettingsEffects } from "./elements/store/general-settings/general-settings.effects";
import { TemplateBuilderEffects } from "./elements/store/template-builder/template-builder.effects";
import { EnvironmentModule } from "./environment/environment.module";
import { I18NModule } from "./i18n/i18n.module";
import { LocalizationService } from "./i18n/localization.service";
import { MissingTranslationReporter } from "./i18n/missing-translation-reporter.service";
import { CoreTranslateLoader } from "./i18n/models/core-translate-loader";
import { MaterialModule } from "./material/material.module";
import { MetaModule } from "./meta/meta.module";
import { PropertySheetModule } from "./property-sheet/property-sheet.module";
import { PropertySheetService } from "./property-sheet/services/property-sheet.service";
import { routes } from "./routes";
import { UndoRedo } from "./shared/models/undo-redo";
import { IReportNameValidator } from "./shared/services/i-report-name-validator";
import { StatusConverter } from "./shared/services/status.converter";
import { ToolbarItemsGenerator } from "./shared/services/toolbar-items-generator.service";
import { UndoRedoService } from "./shared/services/undo-redo.service";
import { SharedModule } from "./shared/shared.module";
import { TsUtilsModule } from "./ts-utils/ts-utils.module";
import { UiCoreComponent } from "./ui-core.component";
import { allUpgradeStepProviders } from "./upgrade/steps/all-steps";

export interface LibConfig {
  kmTrendConfig?: KmTrendConfig;
  dataService: Type<DataService>;
  webServicesConfiguration?: Type<WebServicesConfiguration>;
  statusConverter?: Type<StatusConverter>;
  projectDefaults?: Type<ProjectDefaults>;
  localizationLoader?: Type<TranslateLoader>;
  userPreferencesService?: Type<IUserPreferencesService>;
  componentCategoryProvider?: Type<CoreComponentCategoryProvider>;
  localizationService?: Type<LocalizationService>;
  reportsService?: Type<ReportsService>;
  configurationService?: Type<ConfigurationService>;
  linkResolver?: Type<LinkResolver>;
  entityUpdater?: Type<EntityUpdater>;
  helpService?: Type<HelpService>;
  feedbackService?: Type<IFeedbackService>;
  toolbarItemsGenerator?: Type<ToolbarItemsGenerator>;
  colorList?: string[];
  queryStringService?: Type<QueryStringService>;
  pTypeCheckService?: Type<IPTypeCheckService>;
  queryParamKeyConverter?: Type<QueryParamKeyConverter>;
}

// @dynamic - this flag is for prod build, lambda not supported
@NgModule({
  declarations: [UiCoreComponent],
  exports: [
    UiCoreComponent,
    ElementsModule,
    MaterialModule,
    I18NModule,
    SharedModule,
    TranslateModule
  ],
  imports: [
    TsUtilsModule,
    BrowsingModule,
    MaterialModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingTranslationReporter
      }
    }),
    RouterModule.forRoot(routes),
    CoreModule.forRoot(),
    ElementsModule.forRoot(),
    SharedModule.forRoot(),
    EnvironmentModule.forRoot(),
    EffectsModule.forRoot([
      FilterEffects,
      CommonEffects,
      DataConnectorEffects,
      ComponentStateEffects,
      DataSourceDescriptorEffects,
      PeriodTypeEffects,
      GeneralSettingsEffects,
      TemplateBuilderEffects
    ]),
    MetaModule.forRoot(),
    PropertySheetModule.forRoot()
  ],
  bootstrap: [UiCoreComponent]
})
export class UiCoreModule {
  static forRoot(libConfig: LibConfig): ModuleWithProviders<UiCoreModule> {
    return {
      ngModule: UiCoreModule,
      providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: DataService, useClass: libConfig.dataService },
        { provide: WebServicesConfiguration, useClass: libConfig.webServicesConfiguration },
        { provide: StatusConverter, useClass: libConfig.statusConverter ?? StatusConverter },
        { provide: ProjectDefaults, useClass: libConfig.projectDefaults },
        { provide: TranslateLoader, useClass: libConfig.localizationLoader ?? CoreTranslateLoader },
        { provide: LocalizationService, useClass: libConfig.localizationService },
        { provide: KmTrendConfigToken, useValue: libConfig.kmTrendConfig },
        { provide: ColorList, useValue: libConfig.colorList },
        { provide: UndoRedo, useExisting: UndoRedoService },
        { provide: IUserPreferencesService, useClass: libConfig.userPreferencesService },
        { provide: CoreComponentCategoryProvider, useClass: libConfig.componentCategoryProvider },
        { provide: ReportsService, useClass: libConfig.reportsService },
        { provide: IReportNameValidator, useClass: libConfig.reportsService },
        {
          provide: ConfigurationService,
          useClass: libConfig.configurationService ?? ConfigurationService
        },
        { provide: IPTypeCheckService, useClass: libConfig.pTypeCheckService },
        { provide: LinkResolver, useClass: libConfig.linkResolver ?? LinkResolver },
        { provide: EntityUpdater, useClass: libConfig.entityUpdater ?? EntityUpdater },
        { provide: HelpService, useClass: libConfig.helpService ?? HelpService },
        { provide: IFeedbackService, useClass: libConfig.feedbackService ?? FeedbackService },
        { provide: FeedbackService, useClass: libConfig.feedbackService ?? FeedbackService },
        {
          provide: ToolbarItemsGenerator,
          useClass: libConfig.toolbarItemsGenerator ?? ToolbarItemsGenerator
        },
        {
          provide: QueryStringService,
          useClass: libConfig.queryStringService ?? QueryStringService
        },
        {
          provide: QueryParamKeyConverter,
          useClass: libConfig.queryParamKeyConverter ?? QueryParamKeyConverter
        },
        PropertySheetService,
        Store,
        Dispatcher,
        ...allUpgradeStepProviders,
        ApiDataSourceDescriptorSelector
      ]
    };
  }
}
