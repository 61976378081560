import { Injectable } from "@angular/core";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { ComponentStateDto } from "../models";

@Injectable()
export class HistoryViewService {
  constructor() {}
  private _expandedComponent: Maybe<ComponentStateDto> = null;

  public get expandedComponent(): Maybe<ComponentStateDto> {
    return this._expandedComponent;
  }

  public set expandedComponent(component: Maybe<ComponentStateDto>) {
    this._expandedComponent = component;
  }

  public clearExpandedComponent(): void {
    this.expandedComponent = null;
  }
}
