import { Injectable } from "@angular/core";
import { DataSourceDragInfo } from "../../core/models/drag/data-source-drag-info";
import { DraggedItem } from "../../core/models/drag/dragged-item";
import { DraggedItemType } from "../../core/models/drag/dragged-item-type";
import { EquipmentDragInfo } from "../../core/models/drag/equipment-drag-info";
import { DataConnectorDto } from "../../data-connectivity";
import { ComponentMetadataService } from "../../data-connectivity/services/component-metadata.service";
import { DataConnectorFactory } from "../../data-connectivity/services/deserializers/data-connector-factory.service";
import { Dispatcher } from "../../dispatcher";
import { getDataConnectorTitle } from "../../meta/helpers/get-title.helper";
import { DeepPartial, isEmptyOrNotDefined } from "../../ts-utils";
import { Maybe } from "../../ts-utils/models/maybe.type";

import { ComponentStateDto } from "../models/component-state";
import { DropOffset } from "../models/drop-offset";
import { DroppedConnectorActionParams } from "../models/dropped-connector-action-params";
import { SizeInPx } from "../models/size-in-px";
import { ComponentStateActions } from "../store/component-state/component-state.actions";
import { DataConnectorActions } from "../store/data-connector/data-connector.actions";

const SMALL_WIDGET_MAX_HEIGHT = 75;
const SMALL_WIDGET_MAX_WIDTH = 225;

@Injectable()
export class DropHelper {
  constructor(
    private dispatcher: Dispatcher,
    private metadataProvider: ComponentMetadataService,
    private connectorFactory: DataConnectorFactory
  ) {}

  public dropConnectorOnComponent(
    target: Maybe<DraggedItem>,
    componentState: ComponentStateDto,
    groupId?: string
  ): boolean {
    if (target == null) {
      return false;
    }
    switch (target.type) {
      case DraggedItemType.Signal: {
        this.addSignalConnector(target.item as DataSourceDragInfo, componentState, groupId);
        return true;
      }
      case DraggedItemType.Equipment: {
        const droppedEquipmentInfo = target.item as EquipmentDragInfo;
        if (droppedEquipmentInfo.property != null) {
          this.addEquipmentConnector(droppedEquipmentInfo, componentState, groupId);
        } else {
          this.updateComponentWithDraggedEquipment(droppedEquipmentInfo, componentState, groupId);
        }
        return true;
      }
      default: {
        return false;
      }
    }
  }

  private addSignalConnector(
    draggedDataSource: DataSourceDragInfo,
    componentState: ComponentStateDto,
    groupId?: string
  ): void {
    const params: DroppedConnectorActionParams = this.getActionParams(
      draggedDataSource,
      componentState,
      groupId
    );
    this.dispatcher.dispatch(DataConnectorActions.setContext(params));
  }

  private addEquipmentConnector(
    draggedDataSource: DataSourceDragInfo,
    componentState: ComponentStateDto,
    groupId?: string
  ): void {
    const params: DroppedConnectorActionParams = this.getActionParams(
      draggedDataSource,
      componentState,
      groupId
    );
    this.dispatcher.dispatch(DataConnectorActions.resolveEquipmentConnector(params));
  }

  private getActionParams(
    draggedDataSource: DataSourceDragInfo,
    componentState: ComponentStateDto,
    groupId?: string
  ): DroppedConnectorActionParams {
    const actionParams: DroppedConnectorActionParams = {
      componentId: componentState.id,
      connector: this.createConnectorFromDraggedItem(draggedDataSource, componentState),
      replace: this.shouldReplaceConnector(componentState),
      groupId: groupId
    };
    return actionParams;
  }

  private createConnectorFromDraggedItem(
    draggedDataSource: DataSourceDragInfo,
    componentState: ComponentStateDto
  ): DataConnectorDto {
    const dataConnector = this.connectorFactory.createForComponentType(
      componentState.type,
      draggedDataSource.getDataSource()
    );
    dataConnector.title = getDataConnectorTitle(draggedDataSource.getDataSource());
    dataConnector.properties = draggedDataSource.properties || {};
    return dataConnector;
  }

  private shouldReplaceConnector(componentState: ComponentStateDto): boolean {
    return (
      this.metadataProvider.getMaxConnectors(componentState.type) === 1 &&
      componentState.dataConnectorIds.length > 0
    );
  }

  private updateComponentWithDraggedEquipment(
    targetItem: EquipmentDragInfo,
    componentState: ComponentStateDto,
    groupId?: string
  ): void {
    const dataSource = this.connectorFactory.configureNewDataSource(
      targetItem.getDataSource(),
      componentState.type
    );

    const componentStateChange: DeepPartial<ComponentStateDto> = {
      dataConnectorQuery: {
        ...dataSource,
        aggregationConfig: componentState.dataConnectorQuery.aggregationConfig
      }
    };

    const componentUpdate = {
      id: componentState.id.toString(),
      changes: componentStateChange
    };

    const action = isEmptyOrNotDefined(groupId)
      ? ComponentStateActions.updateOne({
          componentUpdate
        })
      : DataConnectorActions.resolveDraggedEquipmentToGroup({
          componentUpdate,
          groupId
        });

    this.dispatcher.dispatch(action);
  }
}

export function getDropOffsetInsideContainer(
  event: DragEvent | TouchEvent,
  componentState: ComponentStateDto,
  container: HTMLElement
): DropOffset {
  const dropOffset: DropOffset = { top: 0, left: 0 };
  if (event instanceof DragEvent) {
    dropOffset.top = event.offsetY + fromPx(componentState.view.css.top);
    dropOffset.left = event.offsetX + fromPx(componentState.view.css.left);
  } else if (
    event instanceof TouchEvent &&
    event.changedTouches &&
    event.changedTouches.length === 1
  ) {
    // FIXME this calculation is not the same as the one from drag event.
    // Drag event gives local coordinates inside of container, even if container was transformed.
    const containerPosition = container.getBoundingClientRect();
    dropOffset.top = event.changedTouches[0].pageY - containerPosition.top;
    dropOffset.left = event.changedTouches[0].pageX - containerPosition.left;
  }
  return dropOffset;
}

export function fromPx(prop: string): number {
  return Number(prop.replace(/[^-\d.]/g, ""));
}

export function isSmallWidget(size: SizeInPx): boolean {
  return size.heightInPx < SMALL_WIDGET_MAX_HEIGHT && size.widthInPx < SMALL_WIDGET_MAX_WIDTH;
}
