import { Action } from "@ngrx/store";
import { DataConnectorDto } from "../../data-connectivity/models/data-connector";
import { Dictionary, groupByNestedKey, isDefined, isEmpty, isEmptyDict } from "../../ts-utils";
import { ComponentStateDto } from "../models/component-state";
import {
  ConnectorRequestParams,
  DataRequestParams,
  GenericRequestParams,
  isGenericRequest,
  isSignalRequest
} from "../models/data-request-params";
import { CommonActions } from "../store/common/common.actions";
import { DataConnectorActions } from "../store/data-connector/data-connector.actions";

export function getFullRequestActions(
  requestParams: DataRequestParams[],
  isAdditionalDataRequest?: boolean
): Action[] {
  const requestsGroupedByFilter: Dictionary<DataRequestParams[]> = groupByNestedKey(
    requestParams,
    (params) => params.queryFilter.id
  );
  const allActions: Action[] = Object.values(requestsGroupedByFilter)
    .reduce((acc: Action[], params: DataRequestParams[]) => {
      acc.push(...getActionsForQueryFilter(params, isAdditionalDataRequest));
      return acc;
    }, [])
    .filter(isDefined);

  return !isEmpty(allActions) ? allActions : [CommonActions.doNothing()];
}

function getActionsForQueryFilter(
  params: DataRequestParams[],
  isAdditionalDataRequest?: boolean
): Action[] {
  const actions: Action[] = [];
  const connectorsByComponent: Dictionary<DataConnectorDto[]> =
    prepareSignalBasedConnectors(params);
  if (!isEmptyDict(connectorsByComponent)) {
    actions.push(
      CommonActions.getFullRangeFilterData({
        connectorsByComponent,
        queryFilter: params[0].queryFilter,
        isAdditionalDataRequest
      })
    );
  }

  const components: ComponentStateDto[] = getComponentsWithQuery(params);
  if (!isEmpty(components)) {
    actions.push(
      DataConnectorActions.resolveFullGenericQueries({
        queryFilter: params[0].queryFilter,
        componentsWithQuery: components
      })
    );
  }
  return actions;
}

function prepareSignalBasedConnectors(params: DataRequestParams[]): Dictionary<DataConnectorDto[]> {
  return params
    .filter((param) => isSignalRequest(param))
    .reduce((acc: Dictionary<DataConnectorDto[]>, param: DataRequestParams) => {
      const signalBasedConnectors = (param as ConnectorRequestParams).connectorsByComponent;
      Object.keys(signalBasedConnectors).map(
        (component: string) => (acc[component] = signalBasedConnectors[component])
      );
      return acc;
    }, {});
}

function getComponentsWithQuery(params: DataRequestParams[]): ComponentStateDto[] {
  return params
    .filter((param) => isGenericRequest(param))
    .flatMap((params) => (params as GenericRequestParams).componentsWithQuery);
}
