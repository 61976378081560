import { Component, ElementRef, HostBinding, Input } from "@angular/core";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { ComponentCategory, LayoutBuilder } from "../../../meta";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { Maybe, first, isDefined } from "../../../ts-utils";
import { ConnectorRoles } from "../../decorators/connector-roles.decorator";
import { View } from "../../decorators/view.decorator";
import {
  getIconSize,
  resolveIndicatorState,
  shouldHaveSameHeightAndWidth
} from "../../helpers/status-indicator.helper";
import { IndicatorState, IndicatorType } from "../../models";
import { StatusValue } from "../../models/status-indicator/status-value";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import { isWrap } from "../../services/scalable-font-size.helper";
import { getSingleValueTooltip } from "../../services/tooltip.helper";
import { BaseComponent } from "../base/base.component";
import { ComponentConstructorParams } from "../base/component-constructor-params";
import { Roles } from "./roles";
import { StatusIndicatorViewConfig } from "./view-config";

@LayoutBuilder(
  ComponentCategory.SingleValue,
  "StatusIndicatorComponent",
  "Information_circle_2",
  "abb-icon ",
  IndicatorType.Binary,
  LOCALIZATION_DICTIONARY.layoutEditor.BinaryStatusIndicator
)
@LayoutBuilder(
  ComponentCategory.SingleValue,
  "StatusIndicatorComponent",
  "Information_circle_1",
  "abb-icon ",
  IndicatorType.Multivalue,
  LOCALIZATION_DICTIONARY.layoutEditor.MultivalueStatusIndicator
)
@Component({
  selector: "c-status-indicator",
  templateUrl: "./status-indicator.component.html",
  styleUrls: ["./status-indicator.component.scss"]
})
@ConnectorRoles(Roles)
@EditableWidget({ fullName: "StatusIndicatorComponent", title: "status-indicator" })
export class StatusIndicatorComponent extends BaseComponent {
  @HostBinding("attr.title") public tooltipText: string = "";
  @Input() public statusValue: Maybe<StatusValue>;
  public status: IndicatorState;
  public additionalStyle: Partial<CSSStyleDeclaration> = {};
  public iconSize: Maybe<number> = 0;
  public viewConfig!: StatusIndicatorViewConfig;

  constructor(params: ComponentConstructorParams, hostElementRef: ElementRef) {
    super(params, hostElementRef);
  }

  @View(StatusIndicatorViewConfig)
  public get view(): StatusIndicatorViewConfig {
    return this.currentState.view as StatusIndicatorViewConfig;
  }

  protected updateDisplay(): void {
    const dataConnector = this.dataAccessor.getConnectorByRole(Roles.Status.name);
    const dataPoint = this.dataAccessor.getDataPointForConnector(dataConnector);
    const value: StatusValue = this.statusValue ?? dataPoint?.y;

    const interpolatedProperties =
      this.propertyInterpolationService.prepareAndInterpolateProperties<StatusIndicatorViewConfig>(
        this.currentState,
        isDefined(dataConnector) ? [dataConnector] : []
      );
    this.viewConfig = interpolatedProperties.viewConfig;
    const dataConnectorDescriptors: DataConnectorDescriptor[] =
      interpolatedProperties.connectorDescriptors;

    this.updateStatus(value);
    this.updateStatusIndicatorStyle();
    this.tooltipText = getSingleValueTooltip(
      first(dataConnectorDescriptors)?.connector,
      dataPoint,
      { displayFormat: this.viewConfig.displayFormat },
      this.dateFormatter,
      `Status: ${this.status.title}`
    );
  }

  private updateStatus(value: StatusValue): void {
    this.status = resolveIndicatorState(
      value,
      this.viewConfig.states,
      this.viewConfig.displayStrategy
    );
  }

  private updateStatusIndicatorStyle(): void {
    const {
      runtimeView: { runtimeSize },
      title = ""
    } = this.viewConfig;

    const { widthInPx, heightInPx } = runtimeSize;

    this.iconSize = getIconSize(heightInPx, title);
    this.updateIndicatorStyle(widthInPx, heightInPx);
  }

  private updateIndicatorStyle(width: number, height: number): void {
    let newHeight = 0;
    let newWidth = 0;
    // NOTE for min component size indicator should be smaller than component host (not touch borders)
    const reduceSizeFactor = 30;
    if (isDefined(this.status) && shouldHaveSameHeightAndWidth(this.status?.displayType)) {
      newWidth = newHeight = Math.min(width, height) - reduceSizeFactor;
    } else {
      newWidth = width - reduceSizeFactor;
      newHeight = height - reduceSizeFactor;
    }
    this.additionalStyle["width"] = newWidth + "px";
    this.additionalStyle["height"] = newHeight + "px";
  }

  public isTitleFormatWrap(): boolean {
    return isDefined(this.viewConfig) && isWrap(this.viewConfig.titleFormat);
  }
}
