<div class="time-scrubber__start-date">{{ startDate | moment: startEndDateFormat }}</div>
<div class="time-scrubber__slider-wrapper">
  <div class="slider__step-backwards" (click)="stepBackwards()" #back>
    <i class="abb-icon Left"></i>
  </div>
  <div class="slider__input-wrapper">
    <div class="slider__input-box" [style.height.px]="back.clientHeight"></div>
    <input
      type="range"
      min="0"
      [max]="stepsCount - 1"
      value="0"
      class="slider__input"
      #sliderInput
    />
  </div>
  <div class="slider__step-foward" (click)="stepFoward()"><i class="abb-icon Right"></i></div>
</div>
<div class="time-scrubber__end-date">{{ endDate | moment: startEndDateFormat }}</div>
<output class="time-scrubber__current-date-bubble" #bubble [style.left.px]="bubbleLeftOffset">
  {{ currentDate | moment: currentDateFormat }}</output
>
