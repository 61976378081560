import { DataConnectorRoles } from "../../../data-connectivity/models/data-connector-role";
import { Cardinality } from "../../../core/models/cardinality";

export const Roles: DataConnectorRoles = {
  Status: {
    name: "Status",
    cardinality: Cardinality.One,
    isDefaultRole: true
  }
};
