import { Options, Point, SeriesOptionsType } from "highcharts";
import { DataConnectorDto, ICommonDataProperties } from "../../../data-connectivity/models";
import { DateFormatterService } from "../../../environment/services/date-formatter.service";
import { getEntityTitle } from "../../../meta/helpers/get-title.helper";
import { isNotDefined, toStringOrUndefined, tryConvertToNumber } from "../../../ts-utils/helpers";
import { Maybe } from "../../../ts-utils/models";
import { ICategoryBarDisplayConfig } from "../../models/i-view-config/i-base-display-config";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import { PivotTableHelper } from "../pivot-table-helper";
import { TooltipData, getTooltipText } from "../tooltip.helper";
import {
  PRIMARY_X_AXIS_ID,
  PRIMARY_Y_AXIS_ID,
  Y_AXIS_PREFIX,
  getSeriesAxisOptions
} from "./base-highcharts-options.helper";

export class CategoryBarDisplayService {
  constructor(private dateFormatter: DateFormatterService, private chartType: string) {}

  getChartOptions(
    viewConfig: ICategoryBarDisplayConfig,
    dataConnectorDescriptors: DataConnectorDescriptor[]
  ): Options {
    const pivotTable = new PivotTableHelper(
      dataConnectorDescriptors.map((x) => x.connector),
      this.dateFormatter
    );
    const allAxisCategories = pivotTable.categories;
    const series: SeriesOptionsType[] = dataConnectorDescriptors.map((connDescriptor, index) => {
      return {
        color: connDescriptor.connectorView.color,
        name: getEntityTitle(connDescriptor.connector),
        type: undefined,
        data: pivotTable.getVector(connDescriptor.connector),
        ...getSeriesAxisOptions(connDescriptor, viewConfig)
      };
    });
    const chartOptions = this.buildOptions(
      allAxisCategories,
      viewConfig,
      this.chartType,
      dataConnectorDescriptors
    );
    chartOptions.series = series;
    return chartOptions;
  }

  private buildOptions(
    categories0: string[],
    viewConfig: ICategoryBarDisplayConfig,
    chartType: string,
    dataConnectorDescriptors: DataConnectorDescriptor[]
  ): Options {
    const opt: Options = {
      chart: {
        type: chartType
      },
      xAxis: [
        {
          id: PRIMARY_X_AXIS_ID,
          categories: categories0,
          type: "category",
          title: {
            text: viewConfig.xAxisTitle
          }
        }
      ],
      yAxis: getYAxes(viewConfig),
      legend: {
        enabled: viewConfig.showLegend,
        reversed: viewConfig.stacked
      },
      tooltip: {
        backgroundColor: "rgb(255, 255, 255, 1)"
      },

      plotOptions: {
        series: {
          stacking: viewConfig.stacked ? "normal" : (false as any),
          animation: false,
          tooltip: {
            pointFormatter: function () {
              return getTooltip(dataConnectorDescriptors, viewConfig.displayFormat, this);
            }
          }
        },
        bar: {
          groupPadding: 0,
          pointPadding: 0.1
        },
        column: {
          groupPadding: 0,
          pointPadding: 0.1
        }
      }
    };

    return opt;
  }
}

function getYAxes(displayConfig: ICategoryBarDisplayConfig): Highcharts.YAxisOptions[] {
  return displayConfig.yAxes.map((axisConfig, index) => ({
    id: index === 0 ? PRIMARY_Y_AXIS_ID : Y_AXIS_PREFIX + index.toString(),
    title: {
      text: axisConfig.axisTitle,
      style: { color: axisConfig.color }
    },
    labels: {
      style: { color: axisConfig.color }
    },
    min: tryConvertToNumber(axisConfig.min),
    max: tryConvertToNumber(axisConfig.max),
    opposite: index % 2 === 1,
    visible: !axisConfig.isHidden
  }));
}

function getTooltip(
  connectorDescriptors: DataConnectorDescriptor[],
  displayFormat: string,
  tooltipPoint: Point
): string {
  const seriesName: string = tooltipPoint.name;
  const dataConnector: Maybe<DataConnectorDto> = connectorDescriptors.find(
    (descriptor) => getEntityTitle(descriptor.connector) === seriesName
  )?.connector;
  if (isNotDefined(dataConnector)) {
    return "";
  }
  const props = dataConnector.properties as ICommonDataProperties;
  const tooltipData: TooltipData = {
    title: seriesName,
    description: props.description,
    additionalInfo: `${tooltipPoint.x}`,
    value: `${tooltipPoint.y}`,
    unit: dataConnector ? toStringOrUndefined(dataConnector.properties.unit) : ""
  };
  return getTooltipText(tooltipData, displayFormat);
}
