import { ChangeDetectionStrategy, Component, ElementRef } from "@angular/core";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { LayoutBuilder } from "../../../meta/decorators/layout-builder.decorator";
import { ComponentCategory } from "../../../meta/models/component-category";
import { View } from "../../decorators/view.decorator";
import { GENERIC_IFRAME } from "../../models/element-type.constants";
import { BaseComponent } from "../base/base.component";
import { ComponentConstructorParams } from "../base/component-constructor-params";
import { IframeViewConfig } from "./view-config";

@LayoutBuilder(
  ComponentCategory.Glitter,
  GENERIC_IFRAME,
  "Dashboard_1",
  "abb-icon-default",
  "Generic",
  LOCALIZATION_DICTIONARY.propertySheet.Display_IFrame
)
@Component({
  selector: "c-generic-iframe",
  templateUrl: "./generic-iframe.component.html",
  styleUrls: ["./generic-iframe.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: GenericIframe }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@EditableWidget({ fullName: GENERIC_IFRAME, title: GENERIC_IFRAME })
export class GenericIframe extends BaseComponent {
  @View(IframeViewConfig)
  public get view(): IframeViewConfig {
    return this.currentState.view as IframeViewConfig;
  }
  interpolatedViewConfig!: IframeViewConfig;

  constructor(params: ComponentConstructorParams, hostElementRef: ElementRef) {
    super(params, hostElementRef);
  }
}
