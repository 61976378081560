import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ApiResponse } from "../../models/api/api-response";
import { IProjectHierarchyWithStatus } from "../../models/motor-response.interface";

export const HIERARCHY_COLLECTION = "hierarchySelector";
export const CLIENT_DATA = "clientDataSelector";

export const featureSelector =
  createFeatureSelector<IProjectHierarchyWithStatus>(HIERARCHY_COLLECTION);

export const clientDataFeature = createFeatureSelector<ApiResponse[]>(CLIENT_DATA);

export const hierarchySelector = createSelector(featureSelector, (state) => state);

export const clientDataSelector = createSelector(clientDataFeature, (state) => state);
