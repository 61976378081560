import { HISTORY_VIEW_CONSTANTS as CONSTANTS } from "../../elements/components/history-view/history-view.constants";
import { DateFormatterService } from "../../environment/services/date-formatter.service";

export function visualizeSliderProgress(sliderInput: HTMLInputElement): void {
  const value =
    ((parseInt(sliderInput.value) - parseInt(sliderInput.min)) /
      (parseInt(sliderInput.max) - parseInt(sliderInput.min))) *
    100;
  sliderInput.style.background =
    "linear-gradient(to right, rgb(0, 92, 163) 0%, rgb(0, 92, 163) " +
    value +
    "%, var(--input-background-primary) " +
    value +
    "%, var(--input-background-primary) 100%)";
}

export function calculateCurrentDateFormat(
  startDate: Date,
  endDate: Date,
  dateFormatterService: DateFormatterService
): string {
  const timeRangeDiffInMs = endDate.valueOf() - startDate.valueOf();

  if (timeRangeDiffInMs < CONSTANTS.CURRENT_TIME_DISPLAY_FORMAT_TRESHOLD_LOW) {
    return dateFormatterService.getTimePartOfDateFormat();
  } else if (timeRangeDiffInMs < CONSTANTS.CURRENT_TIME_DISPLAY_FORMAT_TRESHOLD_MID) {
    return (
      dateFormatterService.getDatePartOfDateFormat() +
      dateFormatterService.getTimePartOfDateFormat()
    );
  } else if (timeRangeDiffInMs < CONSTANTS.CURRENT_TIME_DISPLAY_FORMAT_TRESHOLD_HIGH) {
    return (
      dateFormatterService.getDatePartOfDateFormat() +
      dateFormatterService.getTimePartOfDateFormatWithoutSeconds()
    );
  } else {
    return dateFormatterService.getDatePartOfDateFormat();
  }
}

export function calculateStartEndDateFormat(
  startDate: Date,
  endDate: Date,
  dateFormatterService: DateFormatterService
): string {
  const timeRangeDiffInMs = endDate.valueOf() - startDate.valueOf();
  if (timeRangeDiffInMs < CONSTANTS.TIME_RANGE_DISPLAY_FORMAT_THRESHOLD_LOW) {
    return dateFormatterService.getTimePartOfDateFormatWithoutSeconds();
  } else if (timeRangeDiffInMs < CONSTANTS.TIME_RANGE_DISPLAY_FORMAT_THRESHOLD_HIGH) {
    return (
      dateFormatterService.getDatePartOfDateFormat() +
      dateFormatterService.getTimePartOfDateFormatWithoutSeconds()
    );
  } else {
    return dateFormatterService.getDatePartOfDateFormat();
  }
}
