<div
  class="grouped-property-sheet-editors__wrapper"
  *ngFor="let index of subgroupsIndex"
  [style.width.%]="getSubgroupWidth(index)"
>
  <property-sheet-editor-wrapper
    *ngFor="let property of getSubgroupProperties(index); trackBy: sectionPropNames"
    [targetInfo]="getTargetInfo(property.propertyInfo)"
    [propertyConfig]="property.propertyInfo"
    [parentInfo]="property.parentInfo"
  >
  </property-sheet-editor-wrapper>
</div>
