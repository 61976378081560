import { ButtonsLocalizationDictionary } from "./buttons-localization-dictionary";
import { DialogsLocalizationDictionary } from "./dialogs-localization-dictionary";
import { FilterComponentLocalizationDictionary } from "./filter-component-localization-dictionary";
import { GeneralSettingsLocalizationDictionary } from "./general-settings-localization-dictionary";
import { LayoutEditorLocalizationDictionary } from "./layout-editor-localization-dictionary";
import { PropertySheetLocalizationDictionary } from "./property-sheet-localization-dictionary";
import { SidebarLocalizationDictionary } from "./sidebar-localization-dictionary";
import { SnackBarMessagesLocalizationDictionary } from "./snackbar-messages-localization-dictionary";

export class LocalizationDictionary {
  protected _filterComponent: FilterComponentLocalizationDictionary =
    new FilterComponentLocalizationDictionary();
  protected _sidebar: SidebarLocalizationDictionary = new SidebarLocalizationDictionary();
  protected _layoutEditor: LayoutEditorLocalizationDictionary =
    new LayoutEditorLocalizationDictionary();
  protected _snackBarMessages: SnackBarMessagesLocalizationDictionary =
    new SnackBarMessagesLocalizationDictionary();
  protected _propertySheet: PropertySheetLocalizationDictionary =
    new PropertySheetLocalizationDictionary();
  protected _dialogs: DialogsLocalizationDictionary = new DialogsLocalizationDictionary();
  protected _generalSettings: GeneralSettingsLocalizationDictionary =
    new GeneralSettingsLocalizationDictionary();
  protected _buttons: ButtonsLocalizationDictionary = new ButtonsLocalizationDictionary();

  get filterComponent(): FilterComponentLocalizationDictionary {
    return this._filterComponent;
  }

  get sidebar(): SidebarLocalizationDictionary {
    return this._sidebar;
  }

  get layoutEditor(): LayoutEditorLocalizationDictionary {
    return this._layoutEditor;
  }

  get snackBarMessages(): SnackBarMessagesLocalizationDictionary {
    return this._snackBarMessages;
  }

  get propertySheet(): PropertySheetLocalizationDictionary {
    return this._propertySheet;
  }

  get dialogs(): DialogsLocalizationDictionary {
    return this._dialogs;
  }

  get generalSettings(): GeneralSettingsLocalizationDictionary {
    return this._generalSettings;
  }

  get buttons(): ButtonsLocalizationDictionary {
    return this._buttons;
  }

  constructor() {}
}

export const LOCALIZATION_DICTIONARY = new LocalizationDictionary();

export function combineLocalizationKey(group: string, key: string): string {
  return group + "_" + key;
}

export function tableColumnLocalizationKey(table: string, column: string): string {
  return `DataSource.${table}_${column}`;
}
