import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap } from "rxjs/operators";
import { ReportDescription } from "../../../core";
import { ReportsService } from "../../../elements/services/reports.service";
import { ReportIdsActions } from "./report-ids.actions";

@Injectable()
export class ReportIdsEffects {
  constructor(private actions$: Actions, private allReportsService: ReportsService) {}

  fetchAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportIdsActions.fetchAll),
      switchMap(() => {
        return this.allReportsService
          .getAllReports()
          .pipe(
            map((reportIds: ReportDescription[]) => ReportIdsActions.fetchAllSuccess({ reportIds }))
          );
      })
    )
  );
}
