import { Action, createReducer, on } from "@ngrx/store";
import {
  CreateReportDialogActions,
  ReportCreationParams,
  ReportCreationType,
  ReportId
} from "ui-core";

export interface ReportNameState {
  reportId: ReportId;
  reportName: string;
  reportCreationType: ReportCreationType;
}

const initialState: ReportNameState = {
  reportId: "" as ReportId,
  reportName: "",
  reportCreationType: ReportCreationType.New
};

export function reducer(state: ReportNameState, action: Action) {
  return _reducer(state, action);
}

const _reducer = createReducer(
  initialState,
  on(CreateReportDialogActions.onCreateReportDialogClosed, (state, { reportCreationInfo }) =>
    addName(state, reportCreationInfo)
  )
);

// FIXME: change state type
function addName(state: ReportNameState, reportCreationInfo: ReportCreationParams): ReportNameState {
  if (reportCreationInfo == null) {
    return state;
  } else {
    const reportId = reportCreationInfo.id ?? state.reportId;
    const reportName = reportCreationInfo.name ?? state.reportName;
    const reportCreationType = reportCreationInfo.creationType ?? state.reportCreationType;
    return { ...state, reportId, reportName, reportCreationType };
  }
}
