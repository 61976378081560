import { animate, style, transition, trigger } from "@angular/animations";
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatTabChangeEvent, MatTabGroup } from "@angular/material/tabs";
import { Actions, ofType } from "@ngrx/effects";
import { cloneDeep as _cloneDeep } from "lodash";
import { Subject, Subscription, combineLatest, of } from "rxjs";
import { catchError, debounceTime, takeUntil, tap } from "rxjs/operators";
import { FilterConfigurationDto, REPORT_FILTER_ID, ViewMode } from "../../../core/models";
import { DisplayMode } from "../../../core/models/display-mode";
import { GlobalFilterViewModelDeserializer } from "../../../core/services/filter/global-filter-vm-deserializer";
import { IFilterSelector } from "../../../core/services/filter/i-filter.selector";
import { DataConnectorDto, DataSourceDto } from "../../../data-connectivity";
import { DataConnectorViewModel } from "../../../data-connectivity/models/data-connector.vm";
import { DataConnectorViewModelDeserializer } from "../../../data-connectivity/services/deserializers/data-connector-vm.deserializer";
import { Dispatcher } from "../../../dispatcher";
import { BaseViewConfigDto } from "../../../elements/models/base-view-config";
import { ComponentStateDto } from "../../../elements/models/component-state";
import { ComponentStateViewModel } from "../../../elements/models/component-state.vm";
import {
  COMPONENT_STATE_VIEW_MODEL,
  DATA_CONNECTOR_VIEW_MODEL,
  GLOBAL_FILTER_VIEW_MODEL
} from "../../../elements/models/entity-type.constants";
import { FullComponentStoreInfo } from "../../../elements/models/full-component-store-info";
import { InlineComponentParams } from "../../../elements/models/inline-mode-params";
import { InterpolationSourceOptions } from "../../../elements/models/interpolation-source-options";
import { DataConnectorDescriptor } from "../../../elements/models/store/data-connector-descriptor";
import { ComponentSelectionService } from "../../../elements/services/component-selection.service";
import { ComponentStateViewModelDeserializer } from "../../../elements/services/deserializers/component-state-vm.deserializer";
import { DynamicDefaultsEvaluator } from "../../../elements/services/dynamic-defaults-evaluator";
import { ComponentStateSelector } from "../../../elements/services/entity-selectors/component-state.selector";
import { DataConnectorSelector } from "../../../elements/services/entity-selectors/data-connector.selector";
import { GeneralSettingsSelector } from "../../../elements/services/entity-selectors/general-settings.selector";
import { InlineEditService } from "../../../elements/services/inline-edit.service";
import { PropertyInterpolationService } from "../../../elements/services/property-interpolation.service";
import { EnvironmentSelector } from "../../../environment/services/environment.selector";
import { AppStatusActions } from "../../../environment/store/app-status/app-status.actions";
import { LocalizationService } from "../../../i18n/localization.service";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import {
  CategoryConfig,
  CategoryKeyValue,
  DATA_CONNECTOR_SUBCATEGORY,
  Entity,
  ItemInfo,
  PROPERTY_SUBCATEGORY_PREFIX,
  PropertyCategory,
  TypeDescriptor,
  resolveTabIndex
} from "../../../meta/models";
import { TypeProvider } from "../../../meta/services/type-provider";
import { UndoRedoService } from "../../../shared/services/undo-redo.service";
import { Maybe, first, isDefined, isNotDefined } from "../../../ts-utils";
import {
  createTabDict,
  getActiveCategory,
  getCategoriesTitle,
  getOriginalTarget,
  getTargetSubcategoryProperties,
  getTargetType,
  hasDataSourceChanged,
  limitWidthAfterResizing,
  populateTabDict,
  resolveActiveTabIndex,
  resolveTargetSubtitle,
  resolveTargetTitle,
  shouldResetActiveTab,
  shouldUpdateProperty,
  shouldUpdateTitle
} from "../../helpers/property-sheet.helper";
import {
  filterByAdvancedMode,
  searchPropertyByPattern
} from "../../helpers/search-property.helper";
import { ModifiedObject } from "../../models/modified-object";
import { PropertySheetService } from "../../services/property-sheet.service";

@Component({
  selector: "property-sheet",
  templateUrl: "property-sheet.component.html",
  styleUrls: ["./property-sheet.component.scss"],
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [
        style({ transform: "translateX(100%)" }),
        animate("200ms", style({ transform: "translateX(0%)" }))
      ]),
      transition(":leave", [
        style({ transform: "translateX(0%)" }),
        animate("150ms", style({ transform: "translateX(100%)" }))
      ])
    ])
  ]
})
export class PropertySheetComponent implements OnInit, OnDestroy {
  private _activeTabIndex: number = 0;
  public displayMode: string;
  public DisplayMode = DisplayMode;
  public isPinned: boolean = false;
  public isFirstTimeOpened: boolean = true;
  isToolbarOpened!: boolean;
  public isToolbarPinned: boolean = true;
  public isOpened: boolean = false;
  public width: number = 0;
  public isDisabled: boolean = false;
  private oldXResizable: number = 0;
  private resizeInProgress: boolean = false;
  private originalTarget: Entity;
  public previousTarget: Entity = new Entity();
  public newTarget: Entity;
  public targetTitle: string;
  public subtitle: string = "";
  public properties: CategoryConfig;
  public targetType: TypeDescriptor;
  public isSearchingInProgress: boolean = false;
  public searchPattern: string = "";
  public searchPatternChanged$: Subject<string> = new Subject<string>();
  public propertiesMatchPattern: CategoryConfig;
  public propertiesToDisplay: CategoryConfig;
  public advancedMode: boolean = false;
  public shouldHideTabHeader: boolean = false;
  private targetSubscription: Subscription;
  private unsubscribeSubject$: Subject<any> = new Subject<any>();
  evaluatedTarget: Entity;

  @ViewChild(MatTabGroup) private matTabGroup!: MatTabGroup;

  constructor(
    private dispatcher: Dispatcher,
    private typeProvider: TypeProvider,
    public localizer: LocalizationService,
    private connectorSelector: DataConnectorSelector,
    private environmentSelector: EnvironmentSelector,
    private componentSelector: ComponentStateSelector,
    private propertySheetService: PropertySheetService,
    public dataConnectorViewModelDeserializer: DataConnectorViewModelDeserializer,
    public componentStateViewModelDeserializer: ComponentStateViewModelDeserializer,
    public filterViewModelDeserializer: GlobalFilterViewModelDeserializer,
    private componentSelectionService: ComponentSelectionService,
    private cdr: ChangeDetectorRef,
    private filterSelector: IFilterSelector,
    private generalSettingsSelector: GeneralSettingsSelector,
    private actions$: Actions,
    private propertyInterpolationService: PropertyInterpolationService,
    private undoRedoService: UndoRedoService,
    private inlineEditService: InlineEditService,
    private dynamicDefaultsEvaluator: DynamicDefaultsEvaluator
  ) {}

  ngOnInit(): void {
    this.subscribeToAdvancedMode();
    this.subscribeToTargetReplace();
    const target = _cloneDeep(this.propertySheetService.currentTarget);
    this.prepareTarget(target);
    this.subscribeToDisplayMode();
    this.subscribeToSearchProperties();
    this.subscribeToPinMode();
    this.subscribeToViewMode();
    this.subscribeToSelectedComponentsChange();
    this.subscribeToWidth();
    this.subscribeToFilterToolbar();
    this.subscribeToUndoRedoChanges();
    this.subscribeToComponentInInlineMode();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
    this.targetSubscription.unsubscribe();
  }

  public get activeTabIndex(): number {
    return this._activeTabIndex;
  }

  public set activeTabIndex(value: number) {
    this._activeTabIndex = value;
    this.propertySheetService.activeTabIndex = value;
  }

  get shouldDisableAnimation(): boolean {
    return this.undoRedoService.isLocked;
  }

  private subscribeToTargetReplace(): void {
    this.actions$
      .pipe(ofType(AppStatusActions.openPropertySheet), takeUntil(this.unsubscribeSubject$))
      .subscribe(({ targetInfo }) => {
        if (isDefined(targetInfo)) {
          this.prepareTarget(targetInfo);
          this.cdr.detectChanges();
        }
      });
  }

  prepareTarget(targetInfo: ModifiedObject<Entity>): void {
    this.originalTarget = _cloneDeep(getOriginalTarget(targetInfo));
    this.newTarget = _cloneDeep(this.originalTarget);
    this.targetType = getTargetType(targetInfo);

    this.targetTitle = this.getTargetTitleAsPath();
    this.subtitle = resolveTargetSubtitle(
      this.typeProvider,
      this.targetType,
      this.originalTarget,
      this.localizer,
      this.environmentSelector
    );
    this.isFirstTimeOpened = true;
    this.resetTabs();
    this.initializeTabs();
    this.resolveNewActiveTab();
    this.isSearchingInProgress = false;
    this.shouldHideTabHeader = false;
    if (isDefined(this.targetSubscription)) {
      this.targetSubscription.unsubscribe();
    }
    this.subscribeToTargetInStore();
    this.matTabGroup?.realignInkBar();
  }

  private resetTabs(): void {
    //FIXME: find better way to refresh material tab content when changing target and try to remove cdr
    if (isDefined(this.propertiesToDisplay) && this.propertiesToDisplay.size > 0) {
      this.propertiesToDisplay.clear();
      this.cdr.detectChanges();
    }
  }

  private getTargetTitleAsPath(): string {
    const evaluatedTitle: Maybe<string> =
      this.propertyInterpolationService.processInterpolationTitleWithinPlaceholder(this.newTarget);
    return (
      this.propertySheetService.getParentIndicatorPath() +
      resolveTargetTitle(this.newTarget, this.targetType, evaluatedTitle)
    );
  }

  private resolveNewActiveTab(): void {
    this.activeTabIndex = resolveActiveTabIndex(this.originalTarget, this.targetType);
    if (shouldResetActiveTab(this.propertiesToDisplay, this.activeTabIndex)) {
      this.setActiveTab();
    }
  }

  private subscribeToDisplayMode(): void {
    this.environmentSelector
      .selectDisplayMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((displayMode: string) => {
        this.displayMode = displayMode;
      });
  }

  private subscribeToAdvancedMode(): void {
    this.environmentSelector
      .selectAdvancedMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((advancedMode: boolean) => {
        this.advancedMode = advancedMode;
      });
  }

  private subscribeToPinMode(): void {
    this.environmentSelector
      .selectPropertySheetPinMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((propertySheetPinMode) => {
        this.isPinned = propertySheetPinMode;
      });
  }

  private subscribeToViewMode(): void {
    this.environmentSelector
      .selectViewMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((viewMode: ViewMode) => {
        if (viewMode === ViewMode.PreviewMode) {
          this.propertySheetService.closeOrRemoveCurrentTarget();
        }
      });
  }

  private subscribeToSelectedComponentsChange(): void {
    this.componentSelectionService.selectedComponentsChanged
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe(() => {
        this.isDisabled = this.componentSelectionService.isMultipleSelectionActive;
      });
  }

  private subscribeToWidth(): void {
    this.propertySheetService.onWidthChange$
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((width) => {
        this.width = width;
        this.matTabGroup?.realignInkBar();
      });
  }

  private subscribeToSearchProperties(): void {
    this.searchPatternChanged$
      .pipe(
        debounceTime(200),
        tap((searchPattern: string) => {
          this.searchPattern = searchPattern;
          this.propertiesMatchPattern = searchPropertyByPattern(
            this.properties,
            this.searchPattern,
            this.localizer
          );
        }),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe();
  }

  private subscribeToFilterToolbar(): void {
    combineLatest([
      this.environmentSelector.selectFilterToolbarVisibilityMode(),
      this.environmentSelector.selectFilterToolbarPinMode()
    ])
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe(([isOpened, isPinned]) => {
        this.isToolbarOpened = isOpened;
        this.isToolbarPinned = isPinned;
      });
  }

  private subscribeToUndoRedoChanges(): void {
    this.undoRedoService.propertySheetSnapshotStateChanged$
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((snapshotState) => {
        this.activeTabIndex = resolveTabIndex(snapshotState.category);
        if (snapshotState.advancedMode && !this.advancedMode) {
          this.toggleAdvancedMode();
        }
        this.refreshPropertyValues(
          this.activeTabIndex,
          this.previousTarget,
          this.newTarget,
          this.evaluatedTarget
        );
      });
  }

  private subscribeToComponentInInlineMode(): void {
    this.inlineEditService.onInlineComponentUpdate
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((inlineComponentParams: Maybe<InlineComponentParams>) => {
        this.isDisabled = isDefined(inlineComponentParams);
      });
  }

  onResizeStart(event): void {
    this.resizeInProgress = !this.resizeInProgress;
    this.oldXResizable = event.clientX;
    window.addEventListener("mousemove", this.mouseMove);
    window.addEventListener("mouseup", this.mouseUp);
  }

  mouseMove = (event) => {
    this.resizer(event.clientX - this.oldXResizable);
    this.oldXResizable = event.clientX;
  };

  mouseUp = (event) => {
    if (this.resizeInProgress) {
      this.resizeInProgress = false;
      this.oldXResizable = event.clientX;
      window.removeEventListener("mousemove", this.mouseMove);
      const newWidth = limitWidthAfterResizing(this.width);
      this.propertySheetService.onWidthChange$.next(newWidth);
    }
  };

  resizer(offsetX: number) {
    this.width -= offsetX;
  }

  private initializeTabs(): void {
    const tabDictionary: CategoryConfig = createTabDict();
    this.evaluateAndAdjustTargetProperties();
    this.properties = populateTabDict(
      tabDictionary,
      this.typeProvider,
      this.targetType,
      this.newTarget,
      this.evaluatedTarget
    );
    this.propertiesToDisplay = filterByAdvancedMode(this.properties, this.advancedMode);
    this.propertiesMatchPattern = this.properties;
  }

  private setActiveTab(): void {
    const tabNames = getCategoriesTitle(this.propertiesToDisplay);
    this.activeTabIndex = tabNames.findIndex((tabName: PropertyCategory) =>
      this.tabHasProperties(tabName, this.propertiesToDisplay)
    );
  }

  toggleAdvancedMode(): void {
    this.updateOnAdvancedModeChange(!this.advancedMode);
    this.dispatcher.dispatch(AppStatusActions.toggleAdvancedMode());
  }

  private subscribeToTargetInStore(): void {
    switch (this.targetType.name) {
      case COMPONENT_STATE_VIEW_MODEL: {
        this.targetSubscription = this.componentSelector
          .selectComponentWithFilter(this.originalTarget.id)
          .pipe(
            catchError(() => {
              return of(null);
            })
          )
          .subscribe((componentStoreInfo: Maybe<FullComponentStoreInfo>) => {
            if (
              isNotDefined(componentStoreInfo) ||
              isNotDefined(componentStoreInfo.componentState)
            ) {
              this.propertySheetService.closeOrRemoveCurrentTarget();
              this.resetTabs();
            } else {
              this.onComponentStateTargetChange(componentStoreInfo.componentState);
            }
          });
        break;
      }
      case DATA_CONNECTOR_VIEW_MODEL: {
        this.isFirstTimeOpened = false;
        this.targetSubscription = this.connectorSelector
          .selectDataConnectorDescriptor(this.originalTarget.id)
          .pipe(
            catchError(() => {
              return of(null);
            })
          )
          .subscribe((connectorDescriptor: Maybe<DataConnectorDescriptor>) => {
            if (isNotDefined(connectorDescriptor) || isNotDefined(connectorDescriptor.connector)) {
              this.propertySheetService.closeOrRemoveCurrentTarget();
              this.resetTabs();
            } else {
              this.onDataConnectorTargetChange(connectorDescriptor.connector);
            }
          });
        break;
      }
      case GLOBAL_FILTER_VIEW_MODEL: {
        this.targetSubscription = combineLatest([
          this.filterSelector.selectById(REPORT_FILTER_ID),
          this.generalSettingsSelector.select()
        ]).subscribe(([globalFilter, generalSettings]) => {
          this.onGlobalFilterChange(globalFilter);
          this.shouldHideTabHeader = true;
        });
        break;
      }
      default:
        break;
    }
  }

  private onComponentStateTargetChange(storeComponentState: ComponentStateDto): void {
    // TODO Separate into functions
    this.previousTarget = _cloneDeep(this.newTarget);
    this.newTarget = this.componentStateViewModelDeserializer.convert(storeComponentState);
    const oldDataSource = (this.previousTarget as ComponentStateViewModel).dataConnectorQuery;
    const newDataSource = (this.newTarget as ComponentStateViewModel).dataConnectorQuery;
    this.removeOldDataSource(oldDataSource, newDataSource);
    this.propertySheetService.updateCurrentEntity(this.newTarget);
    if (shouldUpdateTitle(this.previousTarget, this.newTarget)) {
      const evaluatedTitle: Maybe<string> =
        this.propertyInterpolationService.processInterpolationTitleWithinPlaceholder(
          this.newTarget
        );
      this.targetTitle = resolveTargetTitle(this.newTarget, this.targetType, evaluatedTitle);
    }
    this.subtitle = resolveTargetSubtitle(
      this.typeProvider,
      this.targetType,
      this.newTarget,
      this.localizer,
      this.environmentSelector
    );
    this.refreshPropertyValues(
      this.activeTabIndex,
      this.previousTarget,
      this.newTarget,
      this.evaluatedTarget
    );
  }

  private onDataConnectorTargetChange(storeConnector: DataConnectorDto): void {
    this.previousTarget = _cloneDeep(this.newTarget);
    this.newTarget = this.dataConnectorViewModelDeserializer.convert(storeConnector);
    this.propertySheetService.updateCurrentEntity(this.newTarget);
    const oldDataSource: DataSourceDto = (this.previousTarget as DataConnectorViewModel).dataSource;
    const newDataSource: DataSourceDto = (this.newTarget as DataConnectorViewModel).dataSource;
    this.removeOldDataSource(oldDataSource, newDataSource);
    this.refreshPropertyValues(
      this.activeTabIndex,
      this.previousTarget,
      this.newTarget,
      this.evaluatedTarget
    );
  }

  private onGlobalFilterChange(globalFilterFromStore: FilterConfigurationDto): void {
    this.previousTarget = _cloneDeep(this.newTarget);
    this.newTarget = this.filterViewModelDeserializer.convert(globalFilterFromStore);
    this.propertySheetService.updateCurrentEntity(this.newTarget);
    if (shouldUpdateTitle(this.previousTarget, this.newTarget)) {
      this.targetTitle = this.getTargetTitleAsPath();
    }
    this.refreshPropertyValues(
      this.activeTabIndex,
      this.previousTarget,
      this.newTarget,
      this.evaluatedTarget
    );
  }

  private updateOnAdvancedModeChange(newAdvancedMode: boolean): void {
    this.advancedMode = newAdvancedMode;
    this.propertiesToDisplay = filterByAdvancedMode(this.properties, this.advancedMode);
    this.matTabGroup?.realignInkBar();
    if (shouldResetActiveTab(this.propertiesToDisplay, this.activeTabIndex)) {
      this.setActiveTab();
    }
  }

  private removeOldDataSource(oldDataSource: DataSourceDto, newDataSource: DataSourceDto): void {
    if (isNotDefined(oldDataSource) || isNotDefined(newDataSource)) {
      return;
    }

    if (hasDataSourceChanged(oldDataSource, newDataSource)) {
      this.propertiesToDisplay
        .get(PropertyCategory.Data)
        ?.delete(LOCALIZATION_DICTIONARY.propertySheet.DataSource);
      this.cdr.detectChanges();

      this.updateSubcategory(
        PropertyCategory.Data,
        LOCALIZATION_DICTIONARY.propertySheet.DataSource
      );
    }
  }

  selectedTabChange(_event: MatTabChangeEvent): void {
    this.refreshPropertyValues(
      this.activeTabIndex,
      this.previousTarget,
      this.newTarget,
      this.evaluatedTarget,
      false
    );
  }

  private refreshPropertyValues(
    activeTabIndex: number,
    previousTarget: Entity,
    newTarget: Entity,
    previousEvaluatedTarget: Entity,
    tabChanged: boolean = true
  ): void {
    const activeCategory = getActiveCategory(this.properties, activeTabIndex);
    this.evaluateAndAdjustTargetProperties();

    const subCategoriesMap = this.properties.get(activeCategory);
    if (isNotDefined(subCategoriesMap)) {
      return;
    }
    const subcategories = Array.from(subCategoriesMap.keys());
    subcategories.forEach((subcategory: string) => {
      if (subcategory.replace(PROPERTY_SUBCATEGORY_PREFIX, "") === DATA_CONNECTOR_SUBCATEGORY) {
        return;
      }

      tabChanged
        ? this.shouldUpdateSubcategory(
            activeCategory,
            subcategory,
            previousTarget,
            newTarget,
            previousEvaluatedTarget
          )
        : this.updateSubcategory(activeCategory, subcategory);
    });
  }

  private evaluateAndAdjustTargetProperties(): void {
    if (this.targetType.name === COMPONENT_STATE_VIEW_MODEL) {
      this.evaluatePropertiesForComponent();
    }

    if (this.targetType.name === DATA_CONNECTOR_VIEW_MODEL) {
      this.evaluatePropertiesForConnector();
    }
  }

  private evaluatePropertiesForComponent(): void {
    const componentVM: ComponentStateViewModel = this.newTarget as ComponentStateViewModel;
    const connectorDescriptors: DataConnectorDescriptor[] =
      this.connectorSelector.getDataConnectorDescriptorsByConnectors(componentVM.dataConnectors);

    let evaluatedComponentView: BaseViewConfigDto =
      this.dynamicDefaultsEvaluator.evaluateViewConfig(componentVM.view, connectorDescriptors);

    const interpolationSourceOptions: InterpolationSourceOptions = {
      componentViewModel: componentVM,
      connectorDescriptors
    };
    evaluatedComponentView = this.propertyInterpolationService.interpolatePropertiesForTarget(
      evaluatedComponentView,
      interpolationSourceOptions
    ) as BaseViewConfigDto;

    this.evaluatedTarget = {
      ...this.newTarget,
      view: evaluatedComponentView
    } as ComponentStateViewModel;
  }

  private evaluatePropertiesForConnector(): void {
    const connectorVM: DataConnectorViewModel = this.newTarget as DataConnectorViewModel;
    const connector: DataConnectorDto = this.connectorSelector.getById(connectorVM.id);
    const evaluatedConnectorDescriptor =
      this.dynamicDefaultsEvaluator.evaluateDataConnectorDescriptor({
        connector,
        connectorView: connectorVM.view
      });

    const component: ComponentStateDto = this.componentSelector.getComponentByConnectorId(
      connectorVM.id
    );
    const componentVM: ComponentStateViewModel =
      this.componentStateViewModelDeserializer.convert(component);

    const interpolatedConnectorDescriptor = first(
      this.propertyInterpolationService.interpolateConnectorsProperties(componentVM, [
        {
          connector: evaluatedConnectorDescriptor.connector,
          connectorView: evaluatedConnectorDescriptor.connectorView
        }
      ])
    );
    if (
      isDefined(interpolatedConnectorDescriptor) &&
      isDefined(interpolatedConnectorDescriptor.connector)
    ) {
      const interpolatedConnectorVM = this.dataConnectorViewModelDeserializer.convert(
        interpolatedConnectorDescriptor.connector
      );
      this.evaluatedTarget = {
        ...interpolatedConnectorVM,
        view: interpolatedConnectorDescriptor.connectorView
      } as DataConnectorViewModel;
    }
  }

  private shouldUpdateSubcategory(
    category: PropertyCategory,
    subcategory: string,
    previousTarget: Entity,
    newTarget: Entity,
    previousEvaluatedTarget: Entity
  ): void {
    const subcategoryProps: Maybe<ItemInfo[]> = this.properties.get(category)?.get(subcategory);
    if (isNotDefined(subcategoryProps)) {
      return;
    }

    const updateSubcategory: boolean = subcategoryProps.some(
      (itemInfo: ItemInfo) =>
        shouldUpdateProperty(previousTarget, newTarget, itemInfo.propertyInfo) ||
        shouldUpdateProperty(previousEvaluatedTarget, this.evaluatedTarget, itemInfo.propertyInfo)
    );
    if (updateSubcategory) {
      this.updateSubcategory(category, subcategory);
    }
  }

  private updateSubcategory(propertyCategory: PropertyCategory, propertySubcategory: string): void {
    const currentProperties = this.properties.get(propertyCategory)?.get(propertySubcategory);
    const newProperties: ItemInfo[] = getTargetSubcategoryProperties(
      this.targetType,
      this.newTarget,
      this.typeProvider,
      propertyCategory,
      propertySubcategory,
      this.evaluatedTarget
    );
    const firstProp = first(newProperties);
    if (isDefined(firstProp)) {
      this.properties.get(propertyCategory)?.set(propertySubcategory, newProperties);
    } else {
      if (isNotDefined(currentProperties)) {
        throw Error("Property is not defined in any column");
      }
      this.properties.get(propertyCategory)?.delete(propertySubcategory);
    }
    this.propertiesToDisplay = filterByAdvancedMode(this.properties, this.advancedMode);
  }

  public clickOutside(): void {
    if (!this.isPinned && !this.isFirstTimeOpened) {
      const currentOpenedTarget = this.propertySheetService.currentTarget?.value.id;
      if (currentOpenedTarget === this.originalTarget.id) {
        this.propertySheetService.closeOrRemoveCurrentTarget();
      }
    }
    if (this.isFirstTimeOpened) {
      this.isFirstTimeOpened = false;
    }
  }

  public onClose(): void {
    this.targetSubscription.unsubscribe();
    this.propertySheetService.closeOrRemoveCurrentTarget(true);
  }

  public originalOrder = (cat1: CategoryKeyValue, cat2: CategoryKeyValue): number => {
    return 0;
  };

  public sectionTabNames(index: number, cat1: CategoryKeyValue): string {
    return cat1.key;
  }

  public shouldHideTab(category: PropertyCategory, tabConfig: CategoryConfig): boolean {
    return this.shouldHideTabHeader || !this.tabHasProperties(category, tabConfig);
  }

  public tabHasProperties(category: PropertyCategory, tabConfig: CategoryConfig): boolean {
    const subCategories = tabConfig.get(category as PropertyCategory);
    return isDefined(subCategories) && subCategories.size > 0;
  }

  onChangeSearchMode(): void {
    this.isSearchingInProgress = !this.isSearchingInProgress;
    if (this.isSearchingInProgress) {
      this.clearSearchInput();
    }
  }

  clearSearchInput(): void {
    this.searchPattern = "";
    this.propertiesMatchPattern = this.properties;
  }

  onChangePinMode(): void {
    this.isPinned = !this.isPinned;
    this.dispatcher.dispatch(
      AppStatusActions.changePropertySheetPinMode({ pinMode: this.isPinned })
    );
  }
}
