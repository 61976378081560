import { FilterType } from "../../shared/models/filter-type";
import { first } from "../../ts-utils/helpers/array.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { ActionType } from "../models/action-type";

export function resolveSnapshotAction(path: string[]): Maybe<ActionType> {
  if (isPeriodTypeChanged(path)) {
    return ActionType.PeriodTypeChanged;
  } else if (isGlobalFilterChanged(path)) {
    return ActionType.GlobalFilterChanged;
  } else if (isWidgetFilterChanged(path)) {
    return ActionType.WidgetFilterChanged;
  }
}

function isPeriodTypeChanged(path: string[]): boolean {
  return path.includes(FilterType.PeriodType);
}

function isGlobalFilterChanged(path: string[]): boolean {
  return first(path) === FilterType.TimeRange || path.includes("customFilterDeclarations");
}

function isWidgetFilterChanged(path: string[]): boolean {
  return first(path) === "filterConfig";
}
