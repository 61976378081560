import { ParamMap } from "@angular/router";
import { createAction, props } from "@ngrx/store";
import { ReportTag } from "../../../core/models";
import { DisplayMode } from "../../../core/models/display-mode";
import { ReportEntities } from "../../../elements/models/report-entities";
import { Entity } from "../../../meta/models/entity";
import { ModifiedObject } from "../../../property-sheet/models/modified-object";

export namespace AppStatusActions {
  export const enterPreviewMode = createAction("[Environment] Enter Preview Mode");

  export const exitPreviewMode = createAction("[Environment] Exit Preview Mode");

  export const startLoadingReport = createAction(
    "[Environment] Report Loading Started",
    props<{
      reportTag: ReportTag;
      queryParams?: ParamMap;
    }>()
  );
  export const finishLoadingReport = createAction(
    "[Environment] Report Loading Finished",
    props<{ reportEntities: ReportEntities }>()
  );

  export const updateConnectionStatus = createAction(
    "[Environment] Update Connection Status",
    props<{ isOnline: boolean }>()
  );

  export const openSidebar = createAction("[Environment] Open Sidebar");

  export const closeSidebar = createAction("[Environment] Close Sidebar");

  export const changeSidebarPinMode = createAction(
    "[Environment] Change Sidebar Pin Mode",
    props<{ pinMode: boolean }>()
  );

  export const toggleHistoryViewMode = createAction("[Environment] Toggle History View Mode");

  export const toggleAdvancedMode = createAction("[Environment] Toggle Advanced Mode");

  export const expandSidebar = createAction("[Environment] Expand Sidebar");

  export const collapseSidebar = createAction("[Environment] Collapse Sidebar");

  export const openFilterToolbar = createAction("[Environment] Open Filter Toolbar");

  export const closeFilterToolbar = createAction("[Environment] Close Filter Toolbar");

  export const changeFilterToolbarPinMode = createAction(
    "[Environment] Change Filter Toolbar Pin Mode",
    props<{ pinMode: boolean }>()
  );

  export const openPropertySheet = createAction(
    "[Environment] Open Property Sheet",
    props<{ targetInfo: ModifiedObject<Entity> }>()
  );

  export const closePropertySheet = createAction("[Environment] Close Property Sheet");

  export const changePropertySheetPinMode = createAction(
    "[Environment] Change Property Sheet Pin Mode",
    props<{ pinMode: boolean }>()
  );

  export const changeDisplayMode = createAction(
    "[Environment] Change Display Mode",
    props<{ displayMode: DisplayMode }>()
  );

  export const enterTemplateBuilderMode = createAction("[Environment] Enter Template Builder Mode");
}
