<div
  *ngIf="filterItems"
  #filterToolbar
  class="runtime-filter-toolbar"
  [ngClass]="{ 'runtime-filter-toolbar--pinned': toolbarPinned }"
  (clickOutside)="clickOutside()"
>
  <button
    class="app-filter-button app-filter-button__clipboard-copy"
    (click)="copyRuntimeConfigurationToClipboard()"
    title="{{ localizer.buttons.CopyRuntimeToClipboardTooltip | translate }}"
  >
    <i class="abb-icon abb-icon--medium Link"></i>
  </button>
  <button
    *ngIf="displayMode === DisplayMode.Desktop"
    class="app-filter-button pin-btn"
    [ngClass]="{ 'pin-btn--pinned': toolbarPinned }"
    (click)="onChangePinMode()"
    title="{{
      toolbarPinned ? (localizer.buttons.Unpin | translate) : (localizer.buttons.Pin | translate)
    }}"
  >
    <i class="abb-icon abb-icon--medium" [ngClass]="toolbarPinned ? 'Pin' : 'Unpin'"></i>
  </button>
  <div class="runtime-filter-toolbar__content">
    <root-path
      [isFilterBarSelected]="filterItems['rootPath'].isExpanded"
      [canExpandHorizontally]="filterItems['rootPath'].canExpandHorizontally"
      (showFilterBar)="onChangeFilterItemMode($event)"
    ></root-path>
    <runtime-custom-filters
      [isFilterBarSelected]="filterItems['customFilters'].isExpanded"
      [canExpandHorizontally]="filterItems['customFilters'].canExpandHorizontally"
      (showFilterBar)="onChangeFilterItemMode($event)"
    ></runtime-custom-filters>
    <runtime-period-type
      [isFilterBarSelected]="filterItems['periodType'].isExpanded"
      [canExpandHorizontally]="filterItems['periodType'].canExpandHorizontally"
      (showFilterBar)="onChangeFilterItemMode($event)"
    ></runtime-period-type>
    <runtime-date-time-range
      [isFilterBarSelected]="filterItems['timeRange'].isExpanded"
      [canExpandHorizontally]="filterItems['timeRange'].canExpandHorizontally"
      (showFilterBar)="onChangeFilterItemMode($event)"
    ></runtime-date-time-range>
  </div>
</div>
