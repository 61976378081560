import { EntityId } from "../../meta/models/entity";

export const CONNECTOR_VIEW_ID_SUFIX = "-view";

export function getConnectorViewId(connectorId: EntityId): string {
  return connectorId.toString() + CONNECTOR_VIEW_ID_SUFIX;
}

export function getConnectorIdByViewId(connectorViewId: EntityId): string {
  return connectorViewId.toString().replace(CONNECTOR_VIEW_ID_SUFIX, "");
}
