import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { ofType, Actions, createEffect } from "@ngrx/effects";

import { ErrorCatchingActions } from "./error-catching.actions";
import { ErrorHandlingActions } from "../error-handling/error-handling.actions";

@Injectable()
export class ErrorCatchingEffects {
  constructor(private actions$: Actions, private store$: Store<any>) {}

  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ErrorCatchingActions.catchError),
      map(({ messageToDisplay, error, autoClose }) => {
        return ErrorHandlingActions.handleError({ messageToDisplay, error, autoClose });
        // TODO: Handle error if not in standalone mode
      })
    )
  );

  warning$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ErrorCatchingActions.catchWarning),
      map(({ messageToDisplay }) => {
        return ErrorHandlingActions.handleWarning({ messageToDisplay });
        // TODO: Handle warning if not in standalone mode
      })
    )
  );

  info$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ErrorCatchingActions.provideInfo),
      map(({ messageToDisplay }) => {
        return ErrorHandlingActions.displayInfo({ messageToDisplay });
      })
    )
  );
}
