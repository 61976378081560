import { isDefined } from "../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { isLabel } from "../models/component-type.helper";
import { isTable } from "../models/display-strategies/display-strategy-type.helper";

const UNDO_ELEMENT = "Undo";
const REDO_ELEMENT = "Redo";

export function isElementInHost(hostElement: Maybe<HTMLElement>, element: HTMLElement): boolean {
  return isDefined(hostElement) && hostElement.contains(element);
}

export function isInlineModeAllowed(typeName: string, displayStrategy: string): boolean {
  return isLabel(typeName) || isTable(displayStrategy);
}

export function keepInputUpdated(hostElement: HTMLElement, newValue: string): void {
  const inputElement = hostElement.querySelector("input");
  if (isDefined(inputElement)) {
    inputElement.value = newValue;
  }
}

export function isClickedOnUndoRedo(target: Maybe<HTMLElement>): boolean {
  return (
    isDefined(target) &&
    (isDefined(target.querySelector("." + UNDO_ELEMENT)) ||
      isDefined(target.querySelector("." + REDO_ELEMENT)) ||
      target.classList.contains(UNDO_ELEMENT) ||
      target.classList.contains(REDO_ELEMENT))
  );
}
