import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgZone,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { ColorListService } from "../../../../environment/services/color-list.service";
import { DateFormatterService } from "../../../../environment/services/date-formatter.service";
import { isDefined } from "../../../../ts-utils";
import { Maybe } from "../../../../ts-utils/models/maybe.type";
import { DataStatus } from "../../../models/data-status";
import { DataConnectorDescriptor } from "../../../models/store/data-connector-descriptor";
import { RuntimeSettingsSelector } from "../../../services/entity-selectors/runtime-settings.selector";
import { HistogramDisplayService } from "../../../services/highcharts/histogram-display.service";
import { areConnectorsReordered } from "../../../services/highcharts/requires-full-redraw.helper";
import { SimpleChartComponent } from "../simple-chart/simple-chart.component";
import { IHistogramViewConfig } from "./i-histogram-view-config";

@Component({
  selector: "histogram",
  templateUrl: "./histogram.component.html",
  styleUrls: ["./histogram.component.scss"],
  host: { class: "simple-component" },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistogramComponent extends SimpleChartComponent implements OnChanges {
  @Input() value: Maybe<DataConnectorDescriptor[]> = null;
  @Input() viewConfig: Maybe<IHistogramViewConfig> = null;
  @Input() dataStatus: DataStatus = DataStatus.NoDataDefined;

  displayService!: HistogramDisplayService;

  constructor(
    ngZone: NgZone,
    protected dateFormatter: DateFormatterService,
    protected colorService: ColorListService,
    runtimeSettingsService: RuntimeSettingsSelector
  ) {
    super(ngZone, colorService, runtimeSettingsService);
    this.displayService = new HistogramDisplayService();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (isDefined(this.viewConfig) && isDefined(this.displayService)) {
      const options = this.displayService.getChartOptions(
        this.viewConfig,
        this.value,
        this.colorService
      );

      const fullRedraw: boolean = areConnectorsReordered(
        changes["value"].previousValue,
        changes["value"].currentValue
      );
      this.mergeChartOptions(options, fullRedraw, this.dataStatus);
    }
  }
}
