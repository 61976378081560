import { Injectable } from "@angular/core";
import { EquipmentDataSourceDto, EQUIPMENT_DATA_SOURCE } from "../../data-connectivity";
import { isGenericOrApiPartial } from "../../data-connectivity/helpers/data-source-type.helper";
import { DataSourceDto } from "../../data-connectivity/models/data-source/data-source";
import { Dictionary } from "../../ts-utils";
import { isDataSourceNotEmpty } from "../helpers/connectors.helper";
import { ComponentStateDto } from "../models";
import { ComponentStateSelector } from "./entity-selectors/component-state.selector";

@Injectable()
export class DataConnectorQueryService {
  constructor(private componentStateSelector: ComponentStateSelector) {}

  getEquipmentQueryDict(componentStates: ComponentStateDto[]): Dictionary<EquipmentDataSourceDto> {
    const componentsWithQuery = this.filterComponentsWithDCQ(componentStates);
    const equipmentQueryDict: Dictionary<EquipmentDataSourceDto> = componentsWithQuery
      .filter(
        (componentState) => componentState.dataConnectorQuery.typeName === EQUIPMENT_DATA_SOURCE
      )
      .reduce((acc: Dictionary<EquipmentDataSourceDto>, component) => {
        acc[component.id] = component.dataConnectorQuery as EquipmentDataSourceDto;
        return acc;
      }, {});
    return equipmentQueryDict;
  }

  getGenericQueryDict(componentStates: ComponentStateDto[]): Dictionary<DataSourceDto> {
    const componentsWithQuery = this.filterComponentsWithDCQ(componentStates);
    const genericQueryDict: Dictionary<DataSourceDto> = componentsWithQuery
      .filter((componentState) => isGenericOrApiPartial(componentState.dataConnectorQuery))
      .reduce((acc: Dictionary<DataSourceDto>, component) => {
        acc[component.id] = component.dataConnectorQuery;
        return acc;
      }, {});
    return genericQueryDict;
  }

  public getComponentsWithEquipmentQuery(): ComponentStateDto[] {
    const componentsWithQuery: ComponentStateDto[] = this.filterComponentsWithDCQ(
      this.componentStateSelector.getAllAsArray()
    );
    const equipmentQueryComponents: ComponentStateDto[] = componentsWithQuery
      .filter(
        (componentState) => componentState.dataConnectorQuery.typeName === EQUIPMENT_DATA_SOURCE
      )
      .reduce((acc: ComponentStateDto[], cmp) => {
        acc.push(cmp);
        return acc;
      }, []);
    return equipmentQueryComponents;
  }

  getComponentsWithGenericQuery(componentStates: ComponentStateDto[]): ComponentStateDto[] {
    return this.filterComponentsWithDCQ(componentStates).filter(
      (componentState) =>
        componentState.dataConnectorQuery != null &&
        isGenericOrApiPartial(componentState.dataConnectorQuery)
    );
  }

  filterComponentsWithDCQ(componentStates: ComponentStateDto[]): ComponentStateDto[] {
    if (componentStates == null) {
      componentStates = [];
    }
    return componentStates.filter((componentState: ComponentStateDto) =>
      isDataSourceNotEmpty(componentState.dataConnectorQuery)
    );
  }
}
