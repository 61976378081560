import { createAction, props } from "@ngrx/store";

export namespace SignalBrowserDialogActions {
  export const openSignalBrowserDialog = createAction("[Dialog] Open Signal Browser Dialog");

  export const onSignalBrowserDialogClosed = createAction(
    "[Dialog] On Signal Browser Dialog Closed",
    props<{ result: any }>()
  );
}
