import { Component, OnInit } from "@angular/core";
import * as Highcharts from "highcharts/highmaps";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { ConnectorRoles } from "../../decorators/connector-roles.decorator";
import { View } from "../../decorators/view.decorator";
import { ChartComponent } from "../chart/chart.component";
import exampleData from "./location-data";
import { MapViewConfig } from "./view-config";
import "./world-eckert3-highres";

@Component({
  selector: "c-map",
  templateUrl: "./map-card.component.html",
  styleUrls: ["./map-card.component.scss"]
})
// @LayoutBuilder(ComponentCategory.OtherComponents, "MapCardComponent", "Map")
@ConnectorRoles()
@EditableWidget({ fullName: "MapCardComponent", title: "map-card" })
export class MapCardComponent extends ChartComponent implements OnInit {
  public Highcharts = Highcharts;
  public chartConstructor = "mapChart";
  public mapOptions: any; // MapOptions;

  ngOnInit() {
    super.ngOnInit();

    const data = exampleData.map((point) => [point[0] as string, point[1] as number]);

    const mapSeries: any = {
      data: <any>data,
      mapData: (Highcharts as any).maps["custom/world-eckert3-highres"],
      joinBy: ["hc-key"],
      name: "World map",
      dataLabels: {
        enabled: true,
        formatter: function () {
          // Access the hc-key property of this point
          return this.point.properties["hc-key"];
        }
      }
    };

    this.mapOptions = {
      title: {
        text: this.view.title
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      colorAxis: {
        min: 0 // enable colorAxis
      },
      mapNavigation: {
        enableDoubleClickZoomTo: true,
        enableButtons: true
      },
      series: [mapSeries]
    };
  }

  @View(MapViewConfig)
  public get view() {
    return this.currentState.view as MapViewConfig;
  }

  protected updateChartData(): void {
    return null;
  }
  protected setChartOptions(): void {
    return null;
  }

  onMapRendered(chart: Highcharts.Chart) {
    // console.log("rendered", chart);
    // chart.reflow();
  }
}
