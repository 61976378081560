import { SimpleBoolIndicatorComponent } from "../../../shared/components/simple-bool-indicator/simple-bool-indicator.component";
import { SimpleSingleValueComponent } from "../../../shared/components/simple-single-value/simple-single-value.component";
import { SimpleTrendComponent } from "../../../shared/components/simple-trend/simple-trend.component";

export enum TableCellType {
  BoolIndicator = "BoolIndicator",
  SimpleTrend = "Trend",
  SingleValue = "SingleValue",
  Date = "Date",
  HTML = "HTML"
}

export const TableCellComponents = {
  BoolIndicator: SimpleBoolIndicatorComponent,
  SimpleTrend: SimpleTrendComponent,
  SingleValue: SimpleSingleValueComponent,
  Date: SimpleSingleValueComponent
};
