import { construct } from "../../../core";
import { CommonDataPropertyNames } from "../../../data-connectivity/models";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import {
  AllowInterpolation,
  Configurable,
  ConfigurableArray,
  ConfigurableEnum,
  ConfigurationCategory,
  DynamicDefaultFromMetadata,
  EditableType,
  Serializable
} from "../../../meta/decorators";
import { DynamicallyVisible } from "../../../meta/decorators/dynamically-visible.decorator";
import { EditorSize } from "../../../meta/models/editor-size";
import { EditorType } from "../../../meta/models/editor-type";
import { PropertyCategory } from "../../../meta/models/property-category";
import { validateNumberOrInterpolatedString } from "../../../property-sheet/helpers/number-validation.helper";
import { DeepPartial, isDefined, Maybe } from "../../../ts-utils";
import {
  filterOutEnumValues,
  selectDisplayStrategyByComponentId
} from "../../helpers/dynamically-visible-properties.helper";
import { ComponentCssSize } from "../../models/component-size";
import {
  CategoryBarDisplayStrategies,
  CategoryPieDisplayStrategies,
  DisplayStrategies
} from "../../models/display-strategies/category-display-strategies";
import {
  ICategoryBarDisplayConfig,
  IPieDisplayConfig
} from "../../models/i-view-config/i-base-display-config";
import { CATEGORY_VIEW_CONFIG } from "../../models/view-config-type.constants";
import { YAxisDescriptor } from "../../models/y-axis-descriptor";
import { StrategizedChartViewConfig } from "../strategized-chart/view-config";
import { Roles } from "./roles";

// @dynamic
@EditableType({ fullName: CATEGORY_VIEW_CONFIG })
export class CategoryViewConfig
  extends StrategizedChartViewConfig
  implements IPieDisplayConfig, ICategoryBarDisplayConfig
{
  typeName = CATEGORY_VIEW_CONFIG;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General, 1)
  @ConfigurableEnum({
    enumSource: DisplayStrategies,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Display
  })
  @Serializable(DisplayStrategies.Pie)
  displayStrategy!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Stack,
    editorType: EditorType.CheckBox
  })
  @DynamicallyVisible(
    selectDisplayStrategyByComponentId,
    filterOutEnumValues(DisplayStrategies, [DisplayStrategies.Donut, DisplayStrategies.Pie])
  )
  @Serializable(false)
  stacked!: boolean;

  @ConfigurableArray({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.YAxes,
    editorType: EditorType.Array,
    typeConstructor: YAxisDescriptor,
    arrayItemEditorType: EditorType.NestedObjectEditor,
    arrayEditorSize: EditorSize.Medium,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.YAxesTooltip,
    canBeHidden: true
  })
  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.YAxes, 12)
  @Serializable([new YAxisDescriptor()], YAxisDescriptor)
  @DynamicallyVisible(selectDisplayStrategyByComponentId, CategoryBarDisplayStrategies)
  @AllowInterpolation()
  yAxes!: YAxisDescriptor[];

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.XaxisTitle,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.XaxisTitleTooltip,
    editorType: EditorType.TextBox,
    advancedMode: true
  })
  @Serializable("")
  xAxisTitle!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.MaxChartItems,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.MaxChartItemsTooltip,
    editorType: EditorType.TextBox,
    advancedMode: true,
    validationFunction: validateNumberOrInterpolatedString
  })
  @AllowInterpolation()
  maxChartItems!: Maybe<string>;

  @DynamicDefaultFromMetadata(Roles.Value.name, CommonDataPropertyNames.unit, -1, true, false)
  yAxisTitle!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.DisplayLegend,
    editorType: EditorType.CheckBox
  })
  @Serializable(true)
  showLegend!: boolean;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ShowValue,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.ShowValueTooltip,
    editorType: EditorType.CheckBox
  })
  @Serializable(false)
  @DynamicallyVisible(selectDisplayStrategyByComponentId, CategoryPieDisplayStrategies)
  showValue!: boolean;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ShowPercentage,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.ShowPercentageTooltip,
    editorType: EditorType.CheckBox
  })
  @Serializable(true)
  @DynamicallyVisible(selectDisplayStrategyByComponentId, CategoryPieDisplayStrategies)
  showPercentage!: boolean;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ShowCategoryInLabel,
    editorType: EditorType.CheckBox
  })
  @Serializable(false)
  @DynamicallyVisible(selectDisplayStrategyByComponentId, CategoryPieDisplayStrategies)
  showCategoryInLabel!: boolean;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ShowSum,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.ShowSumTooltip,
    editorType: EditorType.CheckBox
  })
  @Serializable(false)
  @DynamicallyVisible(selectDisplayStrategyByComponentId, [DisplayStrategies.Donut])
  showSum!: boolean;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.CenterLabelText,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.CenterLabelTextTooltip,
    editorType: EditorType.TextBox
  })
  @Serializable("")
  @DynamicallyVisible(selectDisplayStrategyByComponentId, [DisplayStrategies.Donut])
  centerLabelText!: string;

  constructor(viewConfigDto: DeepPartial<CategoryViewConfig>) {
    super();

    viewConfigDto = {
      ...viewConfigDto,
      yAxes: isDefined(viewConfigDto.yAxes)
        ? viewConfigDto.yAxes.map((yAxis) => new YAxisDescriptor(yAxis))
        : [new YAxisDescriptor()]
    };
    construct(this, viewConfigDto, CATEGORY_VIEW_CONFIG, {
      size: getDefaultSize()
    });
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("250", "250");
}
