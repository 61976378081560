import {
  AfterViewInit,
  Component,
  ComponentRef,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  Type,
  ViewChild
} from "@angular/core";
import { take, takeUntil } from "rxjs/operators";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { isDefined, isEmptyOrNotDefined, isNotDefined, Maybe } from "../../../ts-utils";
import { ConnectorRoles } from "../../decorators/connector-roles.decorator";
import { View } from "../../decorators/view.decorator";
import { EditableLayoutDirective } from "../../directives/editable-layout.directive";
import {
  isAbsolutePositioningType,
  switchPositioningType
} from "../../helpers/positioning-type.helper";
import { ComponentStateDto, Orientation } from "../../models";
import { TAB_CONTENT } from "../../models/element-type.constants";
import { PositioningType } from "../../models/positioning-type";
import { ComponentPositionUpdate } from "../../models/resize/component-position-update";
import { NEUTRAL_SCALING_FACTOR } from "../../models/runtime-view-properties";
import { findSelectableParent } from "../../services/component-selection.service";
import { ComponentStateActions } from "../../store/component-state/component-state.actions";
import { BaseComponent } from "../base/base.component";
import { ContainerComponent } from "../container/container.component";
import { TabGroupComponent } from "../tab-group/tab-group.component";
import { TabContentViewConfig } from "./view-config";

@Component({
  selector: "c-tab-content",
  templateUrl: "./tab-content.component.html",
  styleUrls: ["./tab-content.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: TabContentComponent }]
})
@ConnectorRoles()
@EditableWidget({ fullName: TAB_CONTENT, title: "tab-content" })
export class TabContentComponent extends ContainerComponent implements OnChanges, AfterViewInit {
  @ViewChild("contentBody", { static: true }) contentBody!: ElementRef;
  @HostBinding("attr.EditableLayoutDirective") layoutEditor!: EditableLayoutDirective;
  @Input() backgroundImage!: string;
  parentCardScalingFactor: number = NEUTRAL_SCALING_FACTOR;

  ngOnInit(): void {
    super.ngOnInit();
    this.draggabillyConfig = Object.assign(this.draggabillyConfig, {
      containment: "#" + this.hostElement.id + " .tab-content__body"
    });

    this.componentStateSelector
      .selectComponentView(this.id)
      .pipe(take(1))
      .subscribe(() => {
        this.createEditableLayout();
      });

    this.componentStateSelector
      .selectClosestParentBackgroundImageData(this.id)
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((parentImageData: string) => {
        if (isEmptyOrNotDefined(this.view.backgroundImageData)) {
          this.applyBackgroundImage(parentImageData);
        }
      });
    this.subscribeToCardScalingFactor();
  }

  ngOnChanges(): void {
    this.dispatch(
      ComponentStateActions.updateOne({
        componentUpdate: {
          id: this.id.toString(),
          changes: {
            view: {
              backgroundImage: this.backgroundImage
            }
          }
        }
      })
    );
  }

  private subscribeToCardScalingFactor(): void {
    const parentCard = findSelectableParent(this.id, this.componentStateSelector);
    if (isDefined(parentCard)) {
      this.componentStateSelector
        .selectScalingFactor(parentCard.id)
        .pipe(takeUntil(this.unsubscribeSubject$))
        .subscribe((scalingFactor: number) => (this.parentCardScalingFactor = scalingFactor));
    }
  }

  updatePropertySheetTargetOnSelection(componentState: ComponentStateDto): void {
    const parent = this.componentStateSelector.getParent(this.id);
    if (isNotDefined(parent)) {
      return;
    }
    super.updatePropertySheetTargetOnSelection(parent);
  }

  protected shouldUpdateDroppedComponentPosition(
    componentState: Maybe<ComponentStateDto>
  ): boolean {
    return (
      isAbsolutePositioningType(this.view.positioningType) &&
      super.shouldUpdateDroppedComponentPosition(componentState)
    );
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.subscribeToPositioningTypeChange();
  }

  private subscribeToPositioningTypeChange(): void {
    this.componentStateSelector
      .selectPositioningType(this.id)
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((positioningType: PositioningType) =>
        this.onPositioningTypeChange(positioningType)
      );
  }

  private onPositioningTypeChange(positioningType: PositioningType): void {
    if (isNotDefined(this.dynamicChildren) || this.undoRedoService.isLocked) {
      return;
    }
    switchPositioningType(positioningType, {
      Absolute: () => {
        this.dispatch(
          ComponentStateActions.updateAbsolutePositions({
            containerId: this.id,
            updates: this.getChildrenPositionUpdates()
          })
        );
      },
      Relative: () => {
        this.dispatch(ComponentStateActions.updateRelativePositions({ containerId: this.id }));
      }
    });
  }

  private getChildrenPositionUpdates(): ComponentPositionUpdate[] {
    const { top: parentTop, left: parentLeft } = this.hostElement.getBoundingClientRect();
    const inverseCardScalingFactor = 1 / this.parentCardScalingFactor;
    const scrollTop = this.contentBody.nativeElement.scrollTop;
    const scrollLeft = this.contentBody.nativeElement.scrollLeft;
    return this.dynamicChildren.components.map((component: ComponentRef<BaseComponent>) => {
      const boundingRect = (
        component.location.nativeElement as HTMLElement
      ).getBoundingClientRect();
      return {
        componentId: component.instance.id,
        offsetLeft: (boundingRect.left - parentLeft + scrollLeft) * inverseCardScalingFactor,
        offsetTop: (boundingRect.top - parentTop + scrollTop) * inverseCardScalingFactor
      } as ComponentPositionUpdate;
    });
  }

  createEditableLayout(): void {
    this.layoutEditor = new EditableLayoutDirective(this, this.environmentSelector);
    this.layoutEditor.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.layoutEditor.ngOnDestroy();
  }

  @View(TabContentViewConfig)
  public get view(): TabContentViewConfig {
    return this.currentState.view as TabContentViewConfig;
  }

  canBeSelected(): boolean {
    return false;
  }

  protected canContain(componentForAttaching: Type<BaseComponent>): boolean {
    const isBaseComponent: boolean = componentForAttaching.prototype instanceof BaseComponent;
    const isContainer: boolean = componentForAttaching.prototype instanceof ContainerComponent;
    const isTabGroup: boolean = this.typeProvider.areEqualTypes(
      componentForAttaching,
      TabGroupComponent
    );
    return isBaseComponent && !isContainer && !isTabGroup;
  }

  public get bodyStyle(): unknown {
    const style = {};
    style["overflow-x"] = this.shouldEnableScrollbar(Orientation.Horizontal, this.contentBody)
      ? "auto"
      : "hidden";

    style["overflow-y"] = this.shouldEnableScrollbar(Orientation.Vertical, this.contentBody)
      ? "auto"
      : "hidden";

    return style;
  }

  protected get isResizable(): boolean {
    return false;
  }

  protected initDynamicButtons(): void {
    // overridden not to set edit and delete buttons
  }

  protected makeConfigButtonsFullyVisible(): void {}

  protected hideParentsConfigButtons(event: MouseEvent): void {}

  protected revertOverlappingWidgetsState(): void {}

  protected unhideParentsConfigButtons(event: MouseEvent): void {}
}
