import { animate, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";
import { TimeRange } from "../../../core/models/time-range";
import { DateFormatterService } from "../../../environment/services/date-formatter.service";

@Component({
  selector: "date-time-range-picker",
  templateUrl: "date-time-range-picker.component.html",
  styleUrls: ["./date-time-range-picker.component.scss"],
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [style({ opacity: 0 }), animate("300ms", style({ opacity: 1 }))]),
      transition(":leave", [style({ opacity: 1 }), animate("300ms", style({ opacity: 0 }))])
    ])
  ]
})
export class DateTimeRangePickerComponent implements OnInit {
  @Input() isOpened: boolean = false;
  @Input() set dateTimeRange(value: TimeRange) {
    this._dateTimeRange = value;
  }
  get dateTimeRange(): TimeRange {
    return this._dateTimeRange;
  }
  @Output() onChangeDisplay: EventEmitter<Event> = new EventEmitter();
  @Output() onDateTimeChange: EventEmitter<any> = new EventEmitter();

  private _dateTimeRange!: TimeRange;
  dateTimeFormat: string = "";

  constructor(private dateFormatter: DateFormatterService) {}

  @HostListener("click", ["$event"])
  onClick(event): void {
    event.stopPropagation();
  }

  ngOnInit(): void {
    this.dateTimeFormat = this.dateFormatter.getDateFormatString();
  }

  updateStartDateTime(startDateTime: Date): void {
    this.onDateTimeChange.emit(new TimeRange(startDateTime, this.dateTimeRange.to));
  }

  updateEndDateTime(endDateTime: Date): void {
    this.onDateTimeChange.emit(new TimeRange(this.dateTimeRange.from, endDateTime));
  }

  public closeDateTimePicker(): void {
    this.isOpened = false;
    this.onChangeDisplay.emit();
  }
}
