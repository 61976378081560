import { combineLatest, Observable } from "rxjs";
import { distinctUntilChanged, map, switchMap } from "rxjs/operators";
import { COMPONENT_STATE_VIEW_MODEL } from "../../elements/models/entity-type.constants";
import { SelectionOption } from "../../meta/models/selection";
import { isDefined, isEmpty, isNotDefined, Maybe } from "../../ts-utils";
import { Equipment, EquipmentClassNames, EquipmentProperty } from "../models";
import { DecoratorDelegateContext } from "../models/decorator-delegate-context";
import { DEFAULT_ROOT_PATH } from "../models/equipment-path";

export function getEquipmentWithPath(rootNode: Equipment, rootPath: string): Maybe<Equipment> {
  if (isNotDefined(rootNode)) {
    return null;
  }

  if (isDefined(rootNode) && isDefined(rootNode.path) && rootNode.path.includes(rootPath)) {
    return rootNode;
  }

  const children = rootNode.children ?? [];

  for (const equipmentChild of children) {
    const equipmentWithPath = getEquipmentWithPath(equipmentChild, rootPath);
    if (isDefined(equipmentWithPath)) {
      return equipmentWithPath;
    }
  }
  return null;
}

export function getEquipmentProperties(
  context: DecoratorDelegateContext
): Observable<SelectionOption[]> {
  const dataService = context.services.dataService;
  const environmentSelector = context.services.environmentSelector;
  const aliasMode: boolean = environmentSelector.getAliasMode();

  const relativeInstanceEquipmentPath$ =
    context.ownerInstance.typeName === COMPONENT_STATE_VIEW_MODEL
      ? context.services.componentStateSelector.selectEquipmentPathByComponent(
          context.ownerInstance.id
        )
      : context.services.dataConnectorSelector.selectEquipmentPathByConnector(
          context.ownerInstance.id
        );
  return combineLatest([
    context.services.generalSettingsSelector.selectRootPath(),
    relativeInstanceEquipmentPath$
  ]).pipe(
    distinctUntilChanged(),
    switchMap(([reportRootPath, relativeEquipmentPath]: [Maybe<string>, string]) => {
      const rootPath = getFullEquipmentPath(reportRootPath, relativeEquipmentPath);
      return dataService.getEquipmentProperties(rootPath).pipe(
        map((properties: EquipmentProperty[]) =>
          (properties ?? []).map(
            (property: EquipmentProperty) =>
              ({
                key: property.name,
                title:
                  aliasMode && isDefined(property.aliasName) ? property.aliasName : property.name
              } as SelectionOption)
          )
        )
      );
    })
  );
}

export function getFullEquipmentPath(
  reportRootPath: string,
  relativeEquipmentPath: string
): string {
  let rootPath = reportRootPath + DEFAULT_ROOT_PATH + relativeEquipmentPath;
  if (reportRootPath === DEFAULT_ROOT_PATH) {
    rootPath = relativeEquipmentPath;
  }
  if (relativeEquipmentPath === DEFAULT_ROOT_PATH || isEmpty(relativeEquipmentPath)) {
    rootPath = reportRootPath;
  }

  return rootPath;
}

export function getEquipmentClasses(
  context: DecoratorDelegateContext
): Observable<SelectionOption[]> {
  const dataService = context.services.dataService;
  const environmentSelector = context.services.environmentSelector;
  const aliasMode: boolean = environmentSelector.getAliasMode();
  return dataService.getAllEquipmentClasses().pipe(
    map((equipmentClasses: EquipmentClassNames[]) =>
      equipmentClasses.map(
        (equipmentClass): SelectionOption => ({
          key: equipmentClass.className,
          title:
            aliasMode && isDefined(equipmentClass.aliasClassName)
              ? equipmentClass.aliasClassName
              : equipmentClass.className
        })
      )
    )
  );
}
