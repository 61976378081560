import { construct } from "../../../core/services/construct.helper";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import {
  Configurable,
  EditableType,
  EditorType,
  Serializable,
  Title,
  Virtual
} from "../../../meta";
import { DeepPartial } from "../../../ts-utils/models/deep-partial.type";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { createStateDisplayType } from "../../helpers/state-display-type-creation.helper";
import { DEFAULT_TITLE } from "../default-property-value.constants";
import { IndicatorStateDisplay } from "./indicator-state-display";
import { StateDisplayType } from "./state-display-type";
import { StatusValue } from "./status-value";

const TYPE_NAME = "IndicatorState";

@EditableType({ fullName: TYPE_NAME })
export class IndicatorState {
  @Title()
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Title,
    editorType: EditorType.TextBox
  })
  @Serializable(DEFAULT_TITLE)
  title!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Value,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.IndicatorState_ValueTooltip,
    editorType: EditorType.TextBox
  })
  @Serializable(null)
  value!: Maybe<StatusValue>;

  @Serializable()
  @Virtual()
  displayType!: IndicatorStateDisplay;

  constructor(indicatorState: DeepPartial<IndicatorState> = {}) {
    indicatorState = {
      ...indicatorState,
      displayType: createStateDisplayType(
        indicatorState.displayType?.typeName ?? StateDisplayType.Shape,
        indicatorState.displayType ?? {}
      )
    };
    construct(this, indicatorState, TYPE_NAME);
  }
}
