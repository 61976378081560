import { RDS_LOCALIZATION_DICTIONARY } from "projects/rds/src/assets/i18n/models/rds-localization-dictionary";
import {
  BaseViewConfigDto,
  CommonDataPropertyNames,
  ComponentCssSize,
  Configurable,
  ConfigurableEnum,
  ConfigurationCategory,
  construct,
  DeepPartial,
  DynamicDefaultFromMetadata,
  EditableType,
  EditorType,
  LOCALIZATION_DICTIONARY,
  PropertyCategory,
  Serializable,
  SingleValueDisplayStrategy
} from "ui-core";
import { PositionViewConfig } from "./position-view-config";
import { Roles } from "./roles";

const TYPE_NAME = "StatusHeatmapViewConfig";

@EditableType({ fullName: TYPE_NAME })
export class StatusHeatmapViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Other)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.NeutralColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("")
  neutralColor!: string;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General, 1)
  @ConfigurableEnum({
    enumSource: SingleValueDisplayStrategy,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Display
  })
  @Serializable(SingleValueDisplayStrategy.BulletChart)
  displayStrategy!: string;

  @Serializable([], PositionViewConfig)
  positions!: PositionViewConfig[];

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.PrimaryColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#0000D3")
  primaryColor!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ShowDataLabel,
    editorType: EditorType.CheckBox
  })
  @Serializable()
  shouldShowDataLabel: boolean;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.FontSize,
    editorType: EditorType.Number
  })
  @Serializable()
  fontSize?: number;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General, 5)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Unit,
    editorType: EditorType.TextBox
  })
  @Serializable("")
  @DynamicDefaultFromMetadata(Roles.Value.name, CommonDataPropertyNames.unit, -1, true, false)
  unit!: string;

  @ConfigurationCategory(
    PropertyCategory.Display,
    RDS_LOCALIZATION_DICTIONARY.propertySheet.General
  )
  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.ColorAxisPosition,
    editorType: EditorType.Number,
    allowEmpty: false
  })
  @Serializable(20)
  colorAxisPosition!: number;

  @ConfigurationCategory(
    PropertyCategory.Display,
    RDS_LOCALIZATION_DICTIONARY.propertySheet.General
  )
  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.ColorAxisWidth,
    editorType: EditorType.Number,
    allowEmpty: false
  })
  @Serializable(500)
  colorAxisWidth!: number;

  @ConfigurationCategory(
    PropertyCategory.Display,
    RDS_LOCALIZATION_DICTIONARY.propertySheet.General
  )
  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.ColorAxisHeight,
    editorType: EditorType.Number,
    allowEmpty: false
  })
  @Serializable(20)
  colorAxisHeight!: number;

  @ConfigurationCategory(
    PropertyCategory.Display,
    RDS_LOCALIZATION_DICTIONARY.propertySheet.General
  )
  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.DataPointHeight,
    editorType: EditorType.Number,
    allowEmpty: false
  })
  @Serializable(4)
  dataPointHeight!: number;

  @ConfigurationCategory(
    PropertyCategory.Display,
    RDS_LOCALIZATION_DICTIONARY.propertySheet.General
  )
  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.DataPointWidth,
    editorType: EditorType.Number,
    allowEmpty: false
  })
  @Serializable(1)
  dataPointWidth!: number;

  @ConfigurationCategory(PropertyCategory.Display, RDS_LOCALIZATION_DICTIONARY.propertySheet.Other)
  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.ExtremeLowValue,
    editorType: EditorType.TextBox
  })
  @Serializable("")
  extremeLowValue!: string;

  @ConfigurationCategory(PropertyCategory.Display, RDS_LOCALIZATION_DICTIONARY.propertySheet.Other)
  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.VeryLowValue,
    editorType: EditorType.TextBox
  })
  @Serializable("")
  veryLowValue!: string;

  @ConfigurationCategory(PropertyCategory.Display, RDS_LOCALIZATION_DICTIONARY.propertySheet.Other)
  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.LowValue,
    editorType: EditorType.TextBox
  })
  @Serializable("")
  lowValue!: string;

  @ConfigurationCategory(PropertyCategory.Display, RDS_LOCALIZATION_DICTIONARY.propertySheet.Other)
  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.HighValue,
    editorType: EditorType.TextBox
  })
  @Serializable("")
  highValue!: string;

  @ConfigurationCategory(PropertyCategory.Display, RDS_LOCALIZATION_DICTIONARY.propertySheet.Other)
  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.VeryHighValue,
    editorType: EditorType.TextBox
  })
  @Serializable("")
  veryHighValue!: string;

  @ConfigurationCategory(PropertyCategory.Display, RDS_LOCALIZATION_DICTIONARY.propertySheet.Other)
  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.ExtremeHighValue,
    editorType: EditorType.TextBox
  })
  @Serializable("")
  extremeHighValue!: string;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Other)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ExtremeLowColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("")
  extremeLowColor!: string;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Other)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.VeryLowColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#41BEE1")
  veryLowColor!: string;

  @ConfigurationCategory(PropertyCategory.Display, RDS_LOCALIZATION_DICTIONARY.propertySheet.Other)
  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.LowColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#00FF00")
  lowColor!: string;

  @ConfigurationCategory(PropertyCategory.Display, RDS_LOCALIZATION_DICTIONARY.propertySheet.Other)
  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.HighColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#FFDB00")
  highColor!: string;

  @ConfigurationCategory(PropertyCategory.Display, RDS_LOCALIZATION_DICTIONARY.propertySheet.Other)
  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.VeryHighColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("")
  veryHighColor!: string;

  @ConfigurationCategory(PropertyCategory.Display, RDS_LOCALIZATION_DICTIONARY.propertySheet.Other)
  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.ExtremeHighColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("")
  extremeHighColor!: string;

  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.MinimumValue,
    editorType: EditorType.TextBox
  })
  @Serializable(0)
  min!: number;

  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.MaximumValue,
    editorType: EditorType.TextBox
  })
  @Serializable(100)
  max!: number;

  constructor(viewConfigDto: DeepPartial<StatusHeatmapViewConfig> = {}) {
    super();
    const defaultOverrides: Partial<StatusHeatmapViewConfig> = {
      size: getDefaultSize()
    };
    construct(this, viewConfigDto, TYPE_NAME, defaultOverrides);
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("250", "250");
}
