export class LayoutEditorLocalizationDictionary {
  Button: string = "Button";
  BinaryStatusIndicator: string = "BinaryStatusIndicator";
  MultivalueStatusIndicator: string = "MultivalueStatusIndicator";
  VerticalSeparator: string = "VerticalSeparator";
  HorizontalSeparator: string = "HorizontalSeparator";
  KmTrend: string = "KmTrend";
  BasicCard: string = "BasicCard";
  NavigationBar: string = "NavigationBar";
  TabGroup: string = "TabGroup";
  Image: string = "Image";
  SpiderChart: string = "SpiderChart";
  ScatterChart: string = "ScatterChart";
  ClusterChart: string = "ClusterChart";
  HeatmapChart: string = "HeatmapChart";
  WaterfallChart: string = "WaterfallChart";

  constructor() {
    Object.keys(this).forEach((key) => {
      const thisKey = key as keyof LayoutEditorLocalizationDictionary;
      this[thisKey] = `LayoutEditor.${thisKey}`;
    });
  }
}
