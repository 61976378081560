import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ErrorHandlingActions } from "../../core/store/error-handling/error-handling.actions";
import { Dispatcher } from "../../dispatcher";
import { LocalizationService } from "../../i18n/localization.service";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import { EntityId } from "../../meta/models/entity";
import { HighlightingWidgetInfo } from "../models/highlighting-widget-info";

@Injectable({ providedIn: "root" })
export class LinkingWidgetService {
  private _isLinkingModeActive: boolean = false;
  widgetLinked$: Subject<EntityId> = new Subject<EntityId>();
  linkingModeChanged$: Subject<boolean> = new Subject<boolean>();
  highlightingWidgetChanged$: Subject<HighlightingWidgetInfo> =
    new Subject<HighlightingWidgetInfo>();

  constructor(private dispatcher: Dispatcher, private localizationService: LocalizationService) {}

  isLinkingModeActive(): boolean {
    return this._isLinkingModeActive;
  }

  activateLinkingMode(): void {
    this._isLinkingModeActive = true;
    this.linkingModeChanged$.next(true);
    this.dispatcher.dispatch(
      ErrorHandlingActions.displayInstruction({
        instruction: this.localizationService.get(
          LOCALIZATION_DICTIONARY.snackBarMessages.LinkingWidgetInstruction
        )
      })
    );
  }

  linkWidget(widgetId: EntityId): void {
    this.widgetLinked$.next(widgetId);
    this.deactivateLinkingMode();
  }

  deactivateLinkingMode(): void {
    if (this._isLinkingModeActive) {
      this._isLinkingModeActive = false;
      this.linkingModeChanged$.next(false);
      this.dispatcher.dispatch(ErrorHandlingActions.closeSnackBar());
    }
  }

  highlightSourceWidget(widgetId: EntityId): void {
    this.highlightingWidgetChanged$.next({ widgetId, highlight: true });
  }

  unhighlightSourceWidget(widgetId: EntityId): void {
    this.highlightingWidgetChanged$.next({ widgetId, highlight: false });
  }
}
