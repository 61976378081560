import { Dictionary } from "../models";

export function fromArrayToDictionary(names: string[]): Dictionary<string> {
  if (!Array.isArray(names)) {
    throw new Error("Undefined string array");
  }
  return names.reduce((dic, name) => {
    dic[name] = name;
    return dic;
  }, {});
}
