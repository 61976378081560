import { Injectable } from "@angular/core";
import { IFilterSelector } from "../../core/services/filter/i-filter.selector";
import { getConnectorViewId } from "../../data-connectivity/helpers/connector-view-id.helper";
import { DataConnectorDto } from "../../data-connectivity/models/data-connector";
import { IDataConnectorViewSelector } from "../../data-connectivity/services/i-data-connector-view.selector";
import { ComponentStateDto } from "../../elements/models/component-state";
import {
  COMPONENT_STATE_VIEW_MODEL,
  DATA_CONNECTOR_VIEW_MODEL,
  GLOBAL_FILTER_VIEW_MODEL
} from "../../elements/models/entity-type.constants";
import { ReportEntities } from "../../elements/models/report-entities";
import { ComponentStateSelector } from "../../elements/services/entity-selectors/component-state.selector";
import { DataConnectorSelector } from "../../elements/services/entity-selectors/data-connector.selector";
import { GeneralSettingsSelector } from "../../elements/services/entity-selectors/general-settings.selector";
import { EntityId } from "../../meta/models/entity";
import { TypeDescriptor } from "../../meta/models/type-descriptor";
import { isDefined } from "../../ts-utils";

@Injectable()
export class PropertySheetDependencyService {
  constructor(
    protected componentStateSelector: ComponentStateSelector,
    protected dataConnectorSelector: DataConnectorSelector,
    protected filterSelector: IFilterSelector,
    protected dataConnectorViewSelector: IDataConnectorViewSelector,
    private generalSettingsSelector: GeneralSettingsSelector
  ) {}

  getTargetDependentEntities(targetId: EntityId, targetType: TypeDescriptor): ReportEntities {
    switch (targetType.name) {
      case COMPONENT_STATE_VIEW_MODEL: {
        const componentState = this.componentStateSelector.getById(targetId);
        if (isDefined(componentState)) {
          return this.getComponentDependentEntites(componentState);
        }
      }
      case DATA_CONNECTOR_VIEW_MODEL: {
        const dataConnector = this.dataConnectorSelector.getById(targetId);
        if (isDefined(dataConnector)) {
          return this.getConnectorDependentEntities(dataConnector);
        }
      }
      case GLOBAL_FILTER_VIEW_MODEL: {
        return {
          componentStates: [],
          dataConnectors: [],
          filters: [this.filterSelector.getGlobal()],
          dataConnectorViews: [],
          generalSettings: this.generalSettingsSelector.getGeneralSettings()
        };
      }
      default:
        return {
          componentStates: [],
          dataConnectors: [],
          filters: [],
          dataConnectorViews: []
        };
    }
  }

  getComponentDependentEntites(componentState: ComponentStateDto): ReportEntities {
    const componentConnectors = this.dataConnectorSelector.getForComponent(componentState.id);
    const componentConnectorViews = this.dataConnectorViewSelector.getManyById(
      componentConnectors.map((connector) => getConnectorViewId(connector.id))
    );
    const accInitValue = {
      componentStates: [componentState],
      dataConnectors: componentConnectors,
      filters:
        componentState.filterId != null
          ? [this.filterSelector.getById(componentState.filterId)]
          : [],
      dataConnectorViews: Object.values(componentConnectorViews)
    } as ReportEntities;
    return componentState.childrenIds.reduce((acc, childComponentId) => {
      const childComponent = this.componentStateSelector.getById(childComponentId);
      if (isDefined(childComponent)) {
        const childContext: ReportEntities = this.getComponentDependentEntites(childComponent);
        acc.componentStates = acc.componentStates.concat(childContext.componentStates);
        acc.dataConnectors = acc.dataConnectors.concat(childContext.dataConnectors);
        acc.filters = acc.filters.concat(childContext.filters);
        acc.dataConnectorViews = acc.dataConnectorViews.concat(childContext.dataConnectorViews);
      }
      return acc;
    }, accInitValue);
  }

  getConnectorDependentEntities(dataConnector: DataConnectorDto): ReportEntities {
    return {
      componentStates: [],
      dataConnectors: [dataConnector],
      filters: [],
      dataConnectorViews: [
        this.dataConnectorViewSelector.getById(getConnectorViewId(dataConnector.id))
      ]
    } as ReportEntities;
  }
}
