<span
  *ngIf="!buttonConfig.pageUrl; else linkButton"
  class="dynamic-button--runtime"
  (click)="onClick()"
  [ngClass]="{
    'element--hidden': !buttonConfig.shouldShow()
  }"
  title="{{ buttonConfig.style.title | translate }}"
>
  <i class="{{ buttonConfig.style.iconClass + ' ' + buttonConfig.style.icon }}"></i>
</span>
<ng-template #linkButton>
  <a
    class="dynamic-button--runtime"
    data-test="link-button"
    (click)="onLinkClick($event)"
    [ngClass]="{
      'element--hidden': !buttonConfig.shouldShow(),
      'linked-element--disabled': !buttonConfig.pageUrl
    }"
    title="{{ buttonConfig.style.title | translate }}"
    [href]="buttonConfig.pageUrl"
  >
    <i class="{{ buttonConfig.style.iconClass + ' ' + buttonConfig.style.icon }}"></i>
  </a>
</ng-template>
<c-custom-button-dropdown
  #menu
  *ngIf="buttonConfig.dropdownOptions.length > 0"
  class="custom-button__dropdown element--hidden"
  [dropdownOptions]="buttonConfig.dropdownOptions"
></c-custom-button-dropdown>
