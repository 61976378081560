import { construct } from "../../../core/services/construct.helper";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { ConfigurableEnum } from "../../../meta/decorators/configurable-enum.decorator";
import { Configurable } from "../../../meta/decorators/configurable.decorator";
import { ConfigurationCategory } from "../../../meta/decorators/configuration-category.decorator";
import { EditableType } from "../../../meta/decorators/editable-type.decorator";
import { Serializable } from "../../../meta/decorators/serializable.decorator";
import { CutOffStrategy } from "../../../meta/models/cut-off-strategy";
import { EditorType } from "../../../meta/models/editor-type";
import { PropertyCategory } from "../../../meta/models/property-category";
import { BaseViewConfigDto } from "../../models/base-view-config";
import { ComponentCssSize } from "../../models/component-size";
import { LimitsDto } from "../../models/limits";

const TYPE_NAME = "HeatmapChartViewConfig";
export enum DisplayStrategies {
  GridHeatMap = "GridHeatMap",
  LargeHeatMap = "LargeHeatMap"
}

@EditableType({ fullName: TYPE_NAME })
export class HeatmapChartViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  @ConfigurableEnum({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Display,
    enumSource: DisplayStrategies
  })
  @Serializable(DisplayStrategies.LargeHeatMap)
  displayStrategies!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.SwapXY,
    editorType: EditorType.CheckBox
  })
  @Serializable(false)
  swapXY!: boolean;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Limits, 2)
  @Configurable({
    editorType: EditorType.LimitsEditor,
    advancedMode: true
  })
  limits!: LimitsDto;

  @ConfigurationCategory(
    PropertyCategory.Data,
    LOCALIZATION_DICTIONARY.propertySheet.DataAggregation,
    11
  )
  @ConfigurableEnum({
    enumSource: CutOffStrategy,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.CutOffStrategy,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.CutOffStrategyTooltip
  })
  @Serializable(CutOffStrategy.Downsample)
  cutOffStrategy!: CutOffStrategy;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.XaxisTitle,
    editorType: EditorType.TextBox,
    advancedMode: true
  })
  @Serializable("")
  xAxisTitle!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.YaxisTitle,
    editorType: EditorType.TextBox,
    advancedMode: true
  })
  @Serializable("")
  yAxisTitle!: string;

  constructor(viewConfigDto: Partial<HeatmapChartViewConfig> = {}) {
    super();
    const defaultOverrides: Partial<HeatmapChartViewConfig> = {
      size: getDefaultSize(),
      title: " "
    };
    construct(this, viewConfigDto, TYPE_NAME, defaultOverrides);
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("550", "200");
}
