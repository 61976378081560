import { InjectionToken } from "@angular/core";
import { Maybe } from "../../ts-utils";
import { Version } from "./version";

export interface UpgradeStep {
  name: string;
  fromVersion: Version;

  perform(oldConfig: any): UpgradeStepResult;
}

export interface UpgradeStepResult {
  modified: boolean;
  result: any;

  // show as snackbar message
  warning: Maybe<string>;
}

export interface UpgradeResult {
  originalVersion: Version;
  isModified: boolean;
  upgradedReportContent: any;

  // if upgrade changed report behavior, we can inform the user here
  warning: string;

  performedSteps: string[];
}

export const UPGRADE_STEP = new InjectionToken<UpgradeStep>("UpgradeStep");
