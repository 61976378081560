import { SignalInfoDto } from "../../../data-connectivity";
import { DataSourceDto } from "../../../data-connectivity/models/data-source/data-source";
import { EquipmentDataSourceDto } from "../../../data-connectivity/models/data-source/equipment-data-source";
import { TypeProvider } from "../../../meta";
import { Dictionary, isDefined, Maybe } from "../../../ts-utils";
import { Equipment } from "../equipment";
import { EquipmentProperty } from "../equipment-property";
import { DataSourceDragInfo } from "./data-source-drag-info";

export class EquipmentDragInfo implements DataSourceDragInfo {
  properties: Dictionary<any>;
  public constructor(public equipment: Equipment, public property: Maybe<EquipmentProperty>) {
    this.properties = isDefined(property) ? property.properties : equipment.properties;
  }

  public getDataSource(): DataSourceDto {
    const path = this.equipment.path ? this.equipment.path : "/";
    const propertyClass = this.property?.className != null ? [this.property.className] : [];
    const propertyName = this.property ? this.property.name : "";
    const signalInfoDefaults = TypeProvider.getDefaultsForType(SignalInfoDto);
    const signalInfoId = isDefined(this.property) ? this.property.logId : signalInfoDefaults.id;
    const signalInfoName = isDefined(this.property) ? this.property.name : signalInfoDefaults.name;
    const equipmentDataSource = new EquipmentDataSourceDto({
      path,
      class: propertyClass,
      property: propertyName,
      signal: new SignalInfoDto(signalInfoId, signalInfoName)
    });
    return equipmentDataSource;
  }
}
