import { Maybe } from "../../ts-utils/models/maybe.type";
import { SelectionOption } from "../models";

export const EMPTY_COMBO_BOX_ENTRY = "(none)";

export function addEmptyEntry(
  entries: SelectionOption[],
  entryName: Maybe<string> = null
): SelectionOption[] {
  return [
    ...entries,
    {
      key: "",
      title: entryName ?? EMPTY_COMBO_BOX_ENTRY
    }
  ];
}
