import { Component, HostBinding, OnDestroy, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DataTransferObject } from "../../../core/models/data-transfer-object";
import { getPropertyByPath } from "../../../meta/helpers/property-extraction.helper";
import {
  EditorType,
  Entity,
  PropertyChangeHandler,
  PropertyInfo,
  TypeDescriptor
} from "../../../meta/models";
import { SnapshotContext } from "../../../meta/models/snapshot-context";
import { createSnapshotContextByEditor } from "../../../shared/helpers/undo-redo.helper";
import { Maybe, isDefined, isNotDefined, isUndefined } from "../../../ts-utils";
import { createChangeObject } from "../../helpers/create-change-object.helper";
import { DecoratorContext } from "../../models/decorator-context.type";
import { EditorWrapperComponent } from "../editor-wrapper/editor-wrapper.component";

@Component({
  selector: "property-sheet-editor-wrapper",
  templateUrl: "../editor-wrapper/editor-wrapper.component.html",
  styleUrls: ["../editor-wrapper/editor-wrapper.component.scss"]
})
export class PropertySheetEditorWrapperComponent
  extends EditorWrapperComponent
  implements OnDestroy, OnInit
{
  get target(): Entity {
    return this.targetInfo.value as Entity;
  }

  @HostBinding(`class.element--hidden`) _isHidden = false;
  public isHiddenSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  ngOnInit(): void {
    super.ngOnInit();
    if (isDefined(this.propertyConfig.descriptor.visibilitySelector)) {
      this.propertyConfig.descriptor
        .visibilitySelector(this.parentInfo)
        .pipe(takeUntil(this.unsubscribeSubject$))
        .subscribe((currentDisplayValue) => {
          const displayValues = this.propertyConfig.descriptor.displayValues;
          this.isHidden = isNotDefined(
            displayValues.find((value) => value === currentDisplayValue)
          );
        });
    }
  }

  set isHidden(value: boolean) {
    if (this._isHidden !== value) {
      this.isHiddenSubject.next(value);
    }
    this._isHidden = value;
  }

  updateTarget(newValue: any): void {
    const updatedParentObject = this.executePropertyObserver({
      ...this.propertyConfig,
      value: newValue
    });
    const isViewModelUpdate = this.propertyConfig.descriptor.editorType === EditorType.EntityArray;
    if (isViewModelUpdate) {
      return;
    }
    const actionContext: SnapshotContext = createSnapshotContextByEditor(
      this.propertyConfig,
      this.targetInfo.value
    );
    this.undoRedoService.createSnapshot(actionContext);
    let changes: any;
    if (isDefined(updatedParentObject)) {
      const truncatedPath: string[] = [...this.propertyConfig.localPath];
      truncatedPath.pop();
      if (truncatedPath.length === 0) {
        changes = updatedParentObject;
      } else {
        changes = createChangeObject(truncatedPath, updatedParentObject);
      } // IP probably dont need whole object but just changed branch
    } else {
      changes = createChangeObject(this.propertyConfig.localPath, newValue);
    }
    this.entityUpdater.update(
      this.target.id,
      this.targetType.name,
      changes,
      this.propertyConfig.parentEntityInfo
    );
  }

  private executePropertyObserver(propertyInfo: PropertyInfo<unknown>): unknown {
    const localPath: string[] = [...propertyInfo.localPath];
    const propertyName: string = localPath.pop();
    const parentObject: DataTransferObject = getPropertyByPath(this.target, localPath);
    const parentType: TypeDescriptor =
      this.typeProvider.tryGetType(parentObject.typeName) ?? this.typeProvider.getType("Object");
    const observerFunction: Maybe<PropertyChangeHandler<DecoratorContext, unknown, unknown>> =
      this.typeProvider.getPropertyObserver(parentType, propertyName);

    if (isDefined(observerFunction)) {
      const delegateContext: DecoratorContext = {
        dataSourceDeserializer: this.dataSourceDeserializer,
        deserializer: this.statusDisplayTypeDeserializer
      };
      const newParentObject = observerFunction(delegateContext, parentObject, propertyInfo);
      if (isUndefined(newParentObject)) {
        throw new Error("Property observer should return a value");
      }
      return newParentObject;
    } else {
      return null;
    }
  }
}
