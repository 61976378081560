import { Injectable } from "@angular/core";
import { Maybe } from "../../ts-utils";

const MAX_LOGS = 100;
const ERROR_KEY = "error-logs";

export interface ErrorInfo {
  timestamp: Date;
  message: string;
  error: Maybe<Error>;
}
// IP: quick implementation - for more features, we should use Log4JS with localStorage appender
@Injectable()
export class ErrorLogger {
  private items: ErrorInfo[] = [];

  constructor() {
    const itemsJson = localStorage.getItem(ERROR_KEY);
    this.items = itemsJson ? JSON.parse(itemsJson) : [];
    if (!Array.isArray(this.items)) {
      console.warn("Invalid stored error logs array", this.items);
      this.items = [];
    }
  }

  public add(message: string, error?: Error): void {
    this.items.push({
      message,
      error,
      timestamp: new Date()
    });
    if (this.items.length > MAX_LOGS) {
      this.items.shift();
    }
    localStorage.setItem(ERROR_KEY, JSON.stringify(this.items));
  }

  public getLatestErrors(numberOfErrors: number): ErrorInfo[] {
    return this.items.length <= numberOfErrors ? this.items : this.items.slice(-numberOfErrors);
  }
}
