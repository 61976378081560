import { Component, Input } from "@angular/core";
import {
  ItemInfo,
  ObjectDescriptor,
  SubcategoryConfig,
  SubcategoryKeyValue
} from "../../../meta/models";

@Component({
  selector: "property-sheet-page",
  templateUrl: "property-sheet-page.component.html",
  styleUrls: ["property-sheet-page.component.scss"]
})
export class PropertySheetPageComponent {
  @Input() targetInfo: ObjectDescriptor<any>;
  @Input() sectionedProps: SubcategoryConfig;

  public sectionCatNames(index: number, item: SubcategoryKeyValue): string {
    return item.value.reduce(
      (acc: string, itemInfo: ItemInfo) => acc.concat(itemInfo.propertyInfo.descriptor.name),
      ""
    );
  }
}
