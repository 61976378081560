import { Injectable } from "@angular/core";
import { IDeserializer } from "../../../meta/services/entity-converter";
import { DeepPartial } from "../../../ts-utils";
import { CriticalError } from "../../../ts-utils/models/critical-error";
import { createDataSource } from "../../helpers/data-source-creation.helper";
import { EmptyDataSourceDto } from "../../models";
import { DataSourceDto } from "../../models/data-source/data-source";

@Injectable()
export class DataSourceDeserializer
  implements IDeserializer<Partial<DataSourceDto>, DataSourceDto> {
  constructor() {}

  public convert(dataSource: DeepPartial<DataSourceDto>): DataSourceDto {
    if (!dataSource) {
      return new EmptyDataSourceDto();
    }

    if (dataSource.typeName != null) {
      const derivedDataSource = createDataSource(dataSource.typeName, dataSource);
      return derivedDataSource;
    } else {
      throw new CriticalError(`Data source type "${dataSource.typeName}" not found`);
    }
  }
}
