import {
  BaseViewConfigDto,
  ComponentCssSize,
  Configurable,
  construct,
  DeepPartial,
  EditableType,
  EditorType,
  Serializable
} from "ui-core";

const TYPE_NAME = "StaticEccentricityViewConfig";

@EditableType({ fullName: TYPE_NAME })
export class StaticEccentricityViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  @Configurable({ displayName: "Rotor Mill Center Color", editorType: EditorType.ColorPicker })
  @Serializable("#000000")
  statorCenterColor!: string;

  @Configurable({ displayName: "Calculated Rotor Shape Color", editorType: EditorType.ColorPicker })
  @Serializable("#FF0000")
  rotorMillCenterColor!: string;

  constructor(viewConfigDto: DeepPartial<StaticEccentricityViewConfig> = {}) {
    super();
    construct(this, viewConfigDto, TYPE_NAME, { size: getDefaultSize() });
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("575", "450");
}
