import {
  BaseViewConfigDto,
  ComponentCssSize,
  Configurable,
  construct,
  DeepPartial,
  EditableType,
  EditorType,
  Serializable
} from "ui-core";

const DEFAULT_LEFT_COLOR = "#2E92FA";
const DEFAULT_RIGHT_COLOR = "#004C97";
const DEFAULT_CATEGORIES = [] as string[];
const DEFAULT_LINKS = [] as string[];
const DEFAULT_LEFT_SERIES_DATA = [] as any[];
const DEFAULT_RIGHT_SERIES_DATA = [] as any[];

const TYPE_NAME = "DowntimeComparisonChartViewConfig";

@EditableType({ fullName: TYPE_NAME })
export class DowntimeComparisonChartViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  @Configurable({ displayName: "Left Bar Color", editorType: EditorType.ColorPicker })
  @Serializable(DEFAULT_LEFT_COLOR)
  leftColor!: string;

  @Configurable({ displayName: "Right Bar Color", editorType: EditorType.ColorPicker })
  @Serializable(DEFAULT_RIGHT_COLOR)
  rightColor!: string;

  categories: string[] = DEFAULT_CATEGORIES;
  links: string[] = DEFAULT_LINKS;
  leftSeriesData: any[] = DEFAULT_LEFT_SERIES_DATA;
  rightSeriesData: any[] = DEFAULT_RIGHT_SERIES_DATA;

  constructor(viewConfigDto: DeepPartial<DowntimeComparisonChartViewConfig> = {}) {
    super();
    construct(this, viewConfigDto, TYPE_NAME, { size: getDefaultSize() });
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("750", "350");
}
