<div class="switch__container">
  <label #switch class="switch">
    <input
      #input
      focusVisualization
      [focusedElement]="switch"
      [isRounded]="true"
      type="checkbox"
      [checked]="value"
      (change)="onValueChange(!value, $event)"
    />
    <span class="slider round"></span>
  </label>
  <label *ngIf="label" class="switch__label" [class.editor--value-configured]="distinctDefault">
    {{ label }}
  </label>
</div>
