import { Action, createReducer, on } from "@ngrx/store";
import { Equipment } from "../../../core/models/equipment";
import { EquipmentProperty } from "../../../core/models/equipment-property";
import { AppStatusActions } from "../../../environment";
import { EntityId } from "../../../meta";
import { Maybe } from "../../../ts-utils";
import {
  EquipmentBrowserState,
  initialEquipmentBrowserState,
  initialSignalBrowserState,
  SignalBrowserState
} from "../../models/item-browser-state";
import { DataExplorerActions } from "./data-explorer.actions";

export interface State {
  isSignalTabSelected: boolean;
  signalBrowserState: SignalBrowserState;
  equipmentBrowserState: EquipmentBrowserState;
}

const initialState: State = {
  isSignalTabSelected: true,
  signalBrowserState: initialSignalBrowserState,
  equipmentBrowserState: initialEquipmentBrowserState
};

export function reducer(state: State, action: Action) {
  return _reducer(state, action);
}

const _reducer = createReducer(
  initialState,
  on(DataExplorerActions.changeSignalTabMode, (state, { isSignalTabSelected }) =>
    changeSignalTabMode(state, isSignalTabSelected)
  ),
  on(DataExplorerActions.setSignalSearchPattern, (state, { searchPattern }) =>
    setSignalSearchPattern(state, searchPattern)
  ),
  on(DataExplorerActions.preserveLastUsedSignal, (state, { signal }) =>
    preserveLastUsedSignal(state, signal)
  ),
  on(DataExplorerActions.selectEquipment, (state, { equipment }) =>
    selectEquipment(state, equipment)
  ),
  on(DataExplorerActions.unselectEquipment, (state) => unselectEquipment(state)),
  on(DataExplorerActions.expandPropertyBrowser, (state) => expandPropertyBrowser(state)),
  on(DataExplorerActions.collapsePropertyBrowser, (state) => collapsePropertyBrowser(state)),
  on(DataExplorerActions.setEquipmentTreeModel, (state, { treeModel }) =>
    setEquipmentTreeModel(state, treeModel)
  ),
  on(DataExplorerActions.preserveEquipmentProperty, (state, { equipmentProperty }) =>
    preserveEquipmentProperty(state, equipmentProperty)
  ),
  on(AppStatusActions.enterPreviewMode, (state) => collapsePropertyBrowser(state))
);

function changeSignalTabMode(state: State, isSignalTabSelected: boolean): State {
  return {
    ...state,
    isSignalTabSelected
  };
}

function setSignalSearchPattern(state: State, searchPattern: Maybe<string>): State {
  return {
    ...state,
    signalBrowserState: { ...state.signalBrowserState, searchPattern }
  };
}

function preserveLastUsedSignal(state: State, lastSelectedSignal: Maybe<EntityId>): State {
  return {
    ...state,
    signalBrowserState: { ...state.signalBrowserState, lastSelectedSignal }
  };
}

function selectEquipment(state: State, equipment: Equipment): State {
  return {
    ...state,
    equipmentBrowserState: {
      ...state.equipmentBrowserState,
      equipment,
      isPropertyBrowserExpanded: true
    }
  };
}

function unselectEquipment(state: State): State {
  return {
    ...state,
    equipmentBrowserState: {
      ...state.equipmentBrowserState,
      equipment: null,
      isPropertyBrowserExpanded: false
    }
  };
}

function expandPropertyBrowser(state: State): State {
  return {
    ...state,
    equipmentBrowserState: {
      ...state.equipmentBrowserState,
      isPropertyBrowserExpanded: true
    }
  };
}

function collapsePropertyBrowser(state: State): State {
  return {
    ...state,
    equipmentBrowserState: {
      ...state.equipmentBrowserState,
      isPropertyBrowserExpanded: false
    }
  };
}

function setEquipmentTreeModel(state: State, treeModel: Maybe<Equipment[]>): State {
  return {
    ...state,
    equipmentBrowserState: {
      ...state.equipmentBrowserState,
      treeModel
    }
  };
}

function preserveEquipmentProperty(
  state: State,
  lastSelectedProperty: Maybe<EquipmentProperty>
): State {
  return {
    ...state,
    equipmentBrowserState: {
      ...state.equipmentBrowserState,
      lastSelectedProperty
    }
  };
}
