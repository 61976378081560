import { Injectable } from "@angular/core";
import { Dispatcher } from "../../dispatcher";
import { EntityId } from "../../meta/models/entity";
import { isDefined, isNotDefined } from "../../ts-utils";
import { ComponentStateDto } from "../models";
import { SizeInPx } from "../models/size-in-px";
import { ComponentStateActions } from "../store/component-state/component-state.actions";
import { RuntimeViewService } from "./runtime-view.service";

export const CARD_ITEM_DRAG_HANDLE = "positioning-service__card-drag-handle";
export const PAGE_ITEM_DRAG_HANDLE = "positioning-service__page-drag-handle";
export const DRAG_OVERLAY_CLASS = "positioning-service__drag-overlay";
const DRAG_OVERLAY_Z_INDEX = "111";

@Injectable()
export class ComponentPositioningService {
  constructor(private dispatcher: Dispatcher, private runtimeViewService: RuntimeViewService) {}

  public createDragOverlay(element: Element, parentIsPage: boolean = false): void {
    let dragOverlay = this.getDragOverlayElement(element);
    if (isNotDefined(dragOverlay)) {
      dragOverlay = document.createElement("div") as HTMLDivElement;
      dragOverlay.classList.add(DRAG_OVERLAY_CLASS);
      dragOverlay.classList.add(parentIsPage ? PAGE_ITEM_DRAG_HANDLE : CARD_ITEM_DRAG_HANDLE);
      element.appendChild(dragOverlay);
    }
  }

  public showDraggableOverlay(element: Element): void {
    const dragOverlay = this.getDragOverlayElement(element);
    if (isDefined(dragOverlay)) {
      dragOverlay.style.display = "block";
    }
  }

  public hideDraggableOverlay(element: Element): void {
    const dragOverlay = this.getDragOverlayElement(element);
    if (isDefined(dragOverlay)) {
      dragOverlay.style.display = "none";
    }
  }

  public updateMovedComponentPosition(componentId: EntityId, draggable: any): void {
    const offsetLeft = draggable.position.x;
    const offsetTop = draggable.position.y;
    this.dispatcher.dispatch(
      ComponentStateActions.updatePosition({
        componentId: componentId,
        offsetLeft: offsetLeft,
        offsetTop: offsetTop
      })
    );
  }

  public setDroppedComponentPosition(
    attachedComponentState: ComponentStateDto,
    offsetLeft: number,
    offsetTop: number,
    parentContentSize: SizeInPx
  ): void {
    const expectedRuntimeSize = this.runtimeViewService.calculateRuntimeSize(
      attachedComponentState.view,
      parentContentSize
    ).runtimeSize;
    const leftOffsetCorrection = expectedRuntimeSize.widthInPx / 2;
    const topOffsetCorrection = expectedRuntimeSize.heightInPx / 2;

    this.dispatcher.dispatch(
      ComponentStateActions.updatePosition({
        componentId: attachedComponentState.id,
        offsetLeft: offsetLeft - leftOffsetCorrection,
        offsetTop: offsetTop - topOffsetCorrection
      })
    );
  }

  setDragOverlayZIndex(element: Element, newZIndex: string): void {
    const dragOverlay = this.getDragOverlayElement(element);
    if (isDefined(dragOverlay)) {
      dragOverlay.style.zIndex = Number(newZIndex) >= 0 ? newZIndex : "0";
    }
  }

  resetDragOverlayZIndex(element: Element): void {
    const dragOverlay = this.getDragOverlayElement(element);
    if (isDefined(dragOverlay)) {
      dragOverlay.style.zIndex = DRAG_OVERLAY_Z_INDEX;
    }
  }

  setDragOverlayPointerCursor(element: Element): void {
    const dragOverlay = this.getDragOverlayElement(element);
    if (isDefined(dragOverlay)) {
      dragOverlay.style.cursor = "pointer";
      dragOverlay.title = "Link a widget";
    }
  }

  setDragOverlayGrabCursor(element: Element): void {
    const dragOverlay = this.getDragOverlayElement(element);
    if (isDefined(dragOverlay)) {
      dragOverlay.style.cursor = "grab";
      dragOverlay.title = "";
    }
  }

  private getDragOverlayElement(element: Element): HTMLElement {
    return element.querySelector("." + DRAG_OVERLAY_CLASS) as HTMLElement;
  }
}
