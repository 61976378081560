import { createSelector } from "@ngrx/store";
import { selectConnectivityFeature } from "../feature.selector";
import { ConnectivityState } from "../state";

export const selectDescriptors = createSelector(
  selectConnectivityFeature,
  (connectivity: ConnectivityState) => {
    return {
      apiDescriptors: connectivity.allApiDataSourceDescriptors,
      genericDescriptors: connectivity.allGenericDatasourceDescriptors
    };
  }
);
