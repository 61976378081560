import * as Draggabilly from "draggabilly";
import { Subscription } from "rxjs";
import { PositionDto } from "../../core/models/position";
import { EntityId } from "../../meta/models/entity";
import { Dictionary } from "../../ts-utils";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { BaseComponent } from "../components";

export class DraggabillyEventContext {
  constructor(
    public draggableComponentId: EntityId,
    public container: BaseComponent,
    public draggable: Draggabilly,
    public draggingIndicator: HTMLElement,
    public initialTopOffset: string = "",
    public initialLeftOffset: string = "",
    public isSelectionHandled: boolean = false,
    public cursorOffset: PositionDto = { top: 0, left: 0 },
    public ctrlDownSubscription: Maybe<Subscription> = null,
    public ctrlUpSubscription: Maybe<Subscription> = null,
    public selectedSiblings: HTMLElement[] = [],
    public draggedElementsOffsets: Dictionary<PositionDto> = {},
    public cachedContainSize: any = {},
    public cachedRelativeStartPosition: any = {},
    public isDraggingStarted: boolean = false
  ) {}
}
