import { Injectable } from "@angular/core";
import { isEqual } from "lodash";
import { BehaviorSubject } from "rxjs";
import { EntityId } from "../../meta";
import { Maybe, isDefined, isEmpty, isNotDefined } from "../../ts-utils";
import { last } from "../../ts-utils/helpers/array.helper";
import { ComponentStateDto } from "../models";
import { isBasicCard } from "../models/component-type.helper";
import { ComponentStateSelector } from "./entity-selectors/component-state.selector";

@Injectable({ providedIn: "root" })
export class ComponentSelectionService {
  private _selectedComponentsIds: EntityId[] = [];
  selectedComponentsChanged: BehaviorSubject<EntityId[]> = new BehaviorSubject<EntityId[]>([]);

  constructor(private componentStateSelector: ComponentStateSelector) {}

  set selectedComponentsIds(value: EntityId[]) {
    if (!isEqual(value, this._selectedComponentsIds)) {
      this._selectedComponentsIds = value;
      this.selectedComponentsChanged.next(value);
      return;
    }
    this._selectedComponentsIds = value;
  }

  get selectedComponentsIds(): EntityId[] {
    return this._selectedComponentsIds;
  }

  clearSelection(): void {
    this.selectedComponentsIds = [];
  }

  tryAddToMultipleSelection(component: ComponentStateDto): void {
    if (this.isSelectionEmpty) {
      this.selectSingle(component);
      return;
    }

    if (this.isComponentSelected(component.id)) {
      this.removeFromSelected([component.id]);
      return;
    }

    const selectionParentCard = this.componentStateSelector.getParent(
      this.selectedComponentsIds[0]
    );
    if (isDefined(selectionParentCard)) {
      if (selectionParentCard.childrenIds.find((id) => id === component.id)) {
        this.selectedComponentsIds = this.selectedComponentsIds.concat(component.id);
      } else {
        this.selectSingle(component);
      }
    }
  }

  selectSingle(component: ComponentStateDto): void {
    if (component.view.selectable) {
      this.selectedComponentsIds = [component.id];
      return;
    }
    const closestSelectableParent = findSelectableParent(component.id, this.componentStateSelector);
    this.selectedComponentsIds = isDefined(closestSelectableParent)
      ? [closestSelectableParent.id]
      : [];
  }

  isBasicCardSelected(): boolean {
    if (this.isSelectionEmpty) {
      return false;
    }
    const componentState = this.componentStateSelector.getById(this.selectedComponentsIds[0]);
    return isDefined(componentState) && isBasicCard(componentState.type);
  }

  get lastSelectedComponent(): Maybe<ComponentStateDto> {
    return this.isSelectionEmpty
      ? null
      : this.componentStateSelector.getById(last(this.selectedComponentsIds));
  }

  getSelectedComponents(): Maybe<ComponentStateDto>[] {
    return this.isSelectionEmpty
      ? []
      : this.componentStateSelector.getManyById(this.selectedComponentsIds);
  }

  get isSelectionEmpty(): boolean {
    return isEmpty(this.selectedComponentsIds);
  }

  isWidgetInMultipleSelection(componentId: EntityId): boolean {
    return this.isMultipleSelectionActive && this.isComponentSelected(componentId);
  }

  get isMultipleSelectionActive(): boolean {
    return this.selectedComponentsIds.length > 1;
  }

  isComponentSelected(componentId: EntityId): boolean {
    return this.selectedComponentsIds.some(
      (selectedComponentId) => selectedComponentId === componentId
    );
  }

  removeFromSelected(componentIds: EntityId[]): void {
    if (isNotDefined(componentIds)) {
      return;
    }
    this.selectedComponentsIds = this.selectedComponentsIds.filter((selectedComponentId) =>
      isNotDefined(componentIds.find((id) => id === selectedComponentId))
    );
  }

  findAncestorOfSelectedComponent(): Maybe<EntityId> {
    return this.findBasicCardAncestor(this.selectedComponentsIds[0]);
  }

  findBasicCardAncestor(componentId: EntityId): Maybe<EntityId> {
    return this.isBasicCardSelected()
      ? componentId
      : findSelectableParent(componentId, this.componentStateSelector)?.id;
  }
}

export function findSelectableParent(
  componentId: EntityId,
  componentStateSelector: ComponentStateSelector
): Maybe<ComponentStateDto> {
  const parent = componentStateSelector.getParent(componentId);
  if (isNotDefined(parent)) {
    return null;
  }
  if (parent.view.selectable) {
    return parent;
  }
  return findSelectableParent(parent.id, componentStateSelector);
}
