import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { Subject, timer } from "rxjs";
import { debounce, distinctUntilChanged } from "rxjs/operators";
import { OfType } from "../../../meta/decorators";
import { EditorType } from "../../../meta/models";
import { AutocompleteInterpolationComponent } from "../../../shared/components/autocomplete-interpolation/autocomplete-interpolation.component";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { focusItemInput } from "../../helpers/input-editor-helper";
import { BaseEditorComponent } from "../base-editor.component";

@Component({
  selector: "text-box-editor",
  templateUrl: "text-box-editor.component.html",
  styleUrls: ["text-box-editor.component.scss"]
})
@OfType(EditorType.TextBox)
export class TextBoxEditorComponent extends BaseEditorComponent implements OnInit, OnDestroy {
  inputChanged$: Subject<string> = new Subject<string>();

  @ViewChild("autocompleteInterpolation")
  autocompleteInterpolationComponent!: AutocompleteInterpolationComponent;
  @ViewChild("editorInput", { read: MatAutocompleteTrigger })
  autocompleteTrigger?: MatAutocompleteTrigger;

  @HostBinding("attr.title")
  public get tooltipText(): string {
    return this.tooltip;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.inputChanged$
      .pipe(
        debounce((_newValue) => {
          return this.propertyInfo.descriptor.allowInterpolation ? timer(300) : timer(1000);
        }),
        distinctUntilChanged()
      )
      .subscribe((newValue) => this.onValueChanged(newValue));
    window.addEventListener("scroll", this.scrollEvent, true);
  }

  ngOnDestroy(): void {
    this.inputChanged$.complete();
    window.removeEventListener("scroll", this.scrollEvent, true);
  }

  scrollEvent = (event) => {
    if (isDefined(this.autocompleteTrigger) && this.autocompleteTrigger.panelOpen) {
      this.autocompleteTrigger.updatePosition();
    }
  };

  onInputChange(newValue: string): void {
    this.inputChanged$.next(newValue);
  }

  focus(): void {
    focusItemInput(this.editorInput?.nativeElement);
  }

  onValueChange(value: string): void {
    if (isDefined(this.editorInput)) {
      this.editorInput.nativeElement.value = value;
    }
    this.onValueChanged(value);
  }
}
