import { isDefined } from "../../ts-utils/helpers/predicates.helper";
export class GeneralSettingsLocalizationDictionary {
  LayoutType: string = "Layout";
  Responsive: string = "Responsive";
  Desktop: string = "Desktop";
  Mobile: string = "Mobile";
  Custom: string = "Custom";
  Landscape: string = "Landscape";
  GeneralSettings: string = "GeneralSettings";
  Language: string = "Language";
  RootPath: string = "RootPath";
  RootClass: string = "RootClass";
  RootPathTooltip: string = "RootPathTooltip";

  constructor() {
    Object.keys(this).forEach((key) => {
      const thisKey = key as keyof GeneralSettingsLocalizationDictionary;
      this[thisKey] = isDefined(thisKey) ? `GeneralSettings.${thisKey}` : "";
    });
  }
}
