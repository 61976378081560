import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FeedbackFormData } from "../../core/models/feedback-form-data";
import { ErrorInfo } from "../../core/services/error-logger";
import { IFeedbackService } from "../../core/services/i-feedback.service";
import { Maybe } from "../../ts-utils";
import { FullStoreState } from "../models/full-store-state";
import { FeatureSelector } from "./feature-selector";

@Injectable({ providedIn: "root" })
export class FeedbackService extends IFeedbackService {
  constructor(protected featureSeelctor: FeatureSelector) {
    super();
  }

  public sendFeedback(
    feedbackFormData: FeedbackFormData,
    fullStoreState: Maybe<FullStoreState> = null,
    errors: ErrorInfo[] = []
  ): Observable<boolean> {
    throw new Error("Not implemented!");
  }

  public getStoreState(): FullStoreState {
    return {
      cache: this.featureSeelctor.getBrowsingState(),
      connectivity: this.featureSeelctor.getConnectivityState(),
      report: this.featureSeelctor.getReportState(),
      environment: this.featureSeelctor.getEnvironmentState()
    };
  }
}
