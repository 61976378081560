import { DataConnectorRoles } from "ui-core";
import { PropertyRoles } from "ui-core";

export const Roles: DataConnectorRoles = {
  CurrentTemp: {
    name: "CurrentTemperature",
    isDefaultRole: true
  },
  AverageTemp: {
    name: "AverageTemperature",
    isDefaultRole: false
  }
};

export const Properties: PropertyRoles = {
  AlarmLimit: {
    name: "highLimit"
  },
  TripLimit: {
    name: "highHighLimit"
  },
  Unit: {
    name: "unit"
  },
  LocationAngle: {
    name: "locationAngle"
  },
  signalName: {
    name: "name"
  },
  plcTag: {
    name: "plcTag"
  }
};
