import { Injectable } from "@angular/core";
import { isBasicCard } from "../../elements/models/component-type.helper";
import { BASIC_CARD_VIEW_CONFIG } from "../../elements/models/view-config-type.constants";
import { isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class RenameBasicCardView implements UpgradeStep {
  name = "RenameBasicCardView";
  fromVersion = new Version(1, 0, 4);

  perform(oldConfig: any): UpgradeStepResult {
    const componentStates: any = oldConfig["componentStates"]?.["entities"];
    if (isNotDefined(componentStates)) {
      return {
        result: oldConfig,
        modified: false,
        warning: null
      };
    }
    let changed = false;
    Object.values(componentStates).forEach((component: any) => {
      const view = component["view"];
      if (isBasicCard(component["type"])) {
        view["typeName"] = BASIC_CARD_VIEW_CONFIG;
        changed = true;
      }
    });
    return {
      result: oldConfig,
      modified: changed,
      warning: null
    };
  }
}
