import { Dictionary, SignalDataSourceDto, SignalDragInfo } from "ui-core";

export class RdsSignalDragInfo extends SignalDragInfo {
  constructor(public dataSource: SignalDataSourceDto, public properties: Dictionary<any>) {
    super(dataSource, properties);
  }

  public getDescription(): string {
    return this.properties.plcTag;
  }
}
