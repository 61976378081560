import {
  ChangeDetectionStrategy,
  Component,
  Input,
  isDevMode,
  NgZone,
  OnChanges,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import * as Highcharts from "highcharts";
import HC_More from "highcharts/highcharts-more";
import HC_Accessibility from "highcharts/modules/accessibility";
import HC_Bullet from "highcharts/modules/bullet";
import HC_exporting from "highcharts/modules/exporting";
import HC_HeatMap from "highcharts/modules/heatmap";
import HC_NoDataToDisplay from "highcharts/modules/no-data-to-display";
import HC_SolidGauge from "highcharts/modules/solid-gauge";
import HC_StockModule from "highcharts/modules/stock";
import HC_Tilemap from "highcharts/modules/tilemap";
import HC_Variwide from "highcharts/modules/variwide";
import { isEqual as _isEqual } from "lodash";
import { ColorListService } from "../../../../environment/services/color-list.service";
import { HighchartsChartExtendedComponent } from "../../../../shared/components/highcharts-chart-extended/highcharts-chart-extended.component";
import { isEmptyOrNotDefined } from "../../../../ts-utils";
import { nameof } from "../../../../ts-utils/helpers/name-of";
import { isDefined, isNotDefined } from "../../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../../ts-utils/models/maybe.type";
import { BaseViewConfigDto } from "../../../models";
import { CommonOptionsConfig } from "../../../models/common-options-configuration";
import { DataStatus, INVALID_DATA } from "../../../models/data-status";
import { IBaseDisplayConfig } from "../../../models/i-view-config/i-base-display-config";
import { SizeInPx } from "../../../models/size-in-px";
import { RuntimeSettingsSelector } from "../../../services/entity-selectors/runtime-settings.selector";
import {
  getCommonOptions,
  mergeChartOptions,
  setTransparentChartBackground
} from "../../../services/highcharts/base-highcharts-options.helper";

@Component({ template: "", changeDetection: ChangeDetectionStrategy.OnPush })
export abstract class SimpleChartComponent implements OnChanges {
  @ViewChild(HighchartsChartExtendedComponent)
  public chartComponent: HighchartsChartExtendedComponent | null = null;

  @Input()
  public viewConfig: Maybe<IBaseDisplayConfig> = null;

  @Input() disableAnimations: boolean = false;

  private activeChartSize: Maybe<SizeInPx> = null;

  protected chartObject: Maybe<Highcharts.Chart> = null;
  public Highcharts = Highcharts;
  private _chartOptions: Maybe<Highcharts.Options> = null;
  public constructorType = "chart";

  constructor(
    private ngZone: NgZone,
    protected colorService: ColorListService,
    private runtimeSettingsService: RuntimeSettingsSelector
  ) {
    HC_More(this.Highcharts);
    HC_Bullet(this.Highcharts);
    HC_HeatMap(this.Highcharts);
    HC_SolidGauge(this.Highcharts);
    HC_StockModule(this.Highcharts);
    HC_Variwide(this.Highcharts);
    HC_NoDataToDisplay(this.Highcharts);
    HC_Tilemap(this.Highcharts);
    HC_exporting(this.Highcharts);
    HC_Accessibility(this.Highcharts);

    this._chartOptions = this.getCommonOptions({
      runtimeSize: null,
      title: undefined,
      titleFormat: this.viewConfig?.titleFormat,
      dataStatus: null,
      foregroundColor: null,
      exportingEnabled: false,
      disableChartAnimations:
        this.disableAnimations || this.runtimeSettingsService.getRuntimeSettings().noAnimation
    });
    setTransparentChartBackground(this._chartOptions);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const viewConfigChange = changes[nameof<SimpleChartComponent>("viewConfig")];
    if (isDefined(viewConfigChange) && isDefined(viewConfigChange.currentValue)) {
      const currentSize = (viewConfigChange.currentValue as IBaseDisplayConfig).runtimeView
        .runtimeSize;
      this.manuallySetChartSize(currentSize);
    }
  }

  private static clickCallback(e: Highcharts.PointerEventObject): void {
    const chart = this as any as Highcharts.Chart;
    if (e.shiftKey) {
      console.log(JSON.stringify(chart.userOptions));
    } else {
      console.log(chart.userOptions);
    }
  }

  private getCommonOptions(config: CommonOptionsConfig): Highcharts.Options {
    const opt = getCommonOptions(config, this.colorService);

    const component = this;
    opt.chart!.events!.load = function () {
      component.chartObject = this; // expose chart object to component in order to properly add and remove series
      if (config.runtimeSize != null) {
        component.manuallySetChartSize(config.runtimeSize);
      }
    };

    if (isDevMode()) {
      opt!.chart!.events!.click = SimpleChartComponent.clickCallback;
    }
    return opt;
  }

  protected manuallySetChartSize(size: SizeInPx): void {
    if (isNotDefined(this.chartObject)) {
      return;
    }

    size = this.getHighchartsSize(size);
    if (isNotDefined(this.activeChartSize) || !_isEqual(this.activeChartSize, size)) {
      this.activeChartSize = size;
      const animation = false;
      this.chartObject.setSize(size.widthInPx - 2, size.heightInPx - 2, animation);
      this.chartObject.reflow();
    }
  }

  // NOTE override in derivatives where chart control is smaller than component itself
  protected getHighchartsSize(componentSize: SizeInPx): SizeInPx {
    return componentSize;
  }

  public get chartOptions(): Maybe<Highcharts.Options> {
    return this._chartOptions;
  }

  public set chartOptions(options: Maybe<Highcharts.Options>) {
    this._chartOptions = options;
  }

  protected forceFullRedraw(): void {
    if (this.chartComponent != null) {
      this.chartObject = null;
      this.activeChartSize = null;
      this.chartComponent.forceFullRedraw();
    }
  }

  public mergeChartOptions(
    options: Highcharts.Options,
    fullRedraw: boolean,
    dataStatus: DataStatus
  ): void {
    const commonOptions = this.getCommonOptions({
      runtimeSize: this.viewConfig?.runtimeView?.runtimeSize,
      title: this.viewConfig?.title,
      titleFormat: this.viewConfig?.titleFormat,
      dataStatus,
      exportingEnabled: (this.viewConfig as BaseViewConfigDto).exporting,
      foregroundColor: (this.viewConfig as BaseViewConfigDto).foregroundColor,
      disableChartAnimations:
        this.disableAnimations || this.runtimeSettingsService.getRuntimeSettings().noAnimation
    });

    if (requireRedraw(dataStatus, this.chartOptions)) {
      fullRedraw = true; // TODO investigate why is noData text not applied without fullRedraw
    }
    options.lang = {
      ...options.lang,
      noData: resolveDataMessage(options.series, dataStatus)
    };

    this.chartOptions = mergeChartOptions(commonOptions, options);

    if (this.isImageDataRequest()) {
      const chartUrl = this.createChartImage(this.chartOptions);
      const urlParams = new URLSearchParams(window.location.search);

      urlParams.set("image", chartUrl);
    }

    setTransparentChartBackground(this.chartOptions);

    if (fullRedraw) {
      this.forceFullRedraw();
    }

    if (this.viewConfig?.runtimeView?.runtimeSize != null && isDefined(this.chartComponent)) {
      this.manuallySetChartSize(this.viewConfig.runtimeView.runtimeSize);
    }
  }

  protected svgToUrl(svg: string): string {
    const svgPath = btoa(
      svg.replace(/[\u00A0-\u2666]/g, (char: string) => {
        return "&#" + char.charCodeAt(0) + ";";
      })
    );

    const headerPath = "data:image/svg+xml;base64,";
    const imagePath = headerPath + svgPath;

    return imagePath;
  }

  protected createChartImage(options: Highcharts.Options): string {
    const div = document.createElement("div");
    const chart = Highcharts.chart(div, options);

    const body = document.getElementsByTagName("body")[0];
    const image = document.createElement("img");
    const chartUrl = this.svgToUrl(chart.getSVG());

    image.style.maxWidth = "100%";
    image.style.maxHeight = "100vh";
    image.style.height = "auto";
    image.src = chartUrl;

    body.innerHTML = "";
    body.appendChild(image);

    return chartUrl;
  }

  private isImageDataRequest(): boolean {
    const urlParams = new URLSearchParams(window.location.search);
    const imageParam = urlParams.get("image");

    if (!imageParam) {
      return false;
    }

    return (
      this.chartOptions!.lang?.noData === DataStatus.DataReceived &&
      window.location.href.includes("__dynamic__") &&
      imageParam.toLowerCase() === "true"
    );
  }
}

function requireRedraw(dataStatus: DataStatus, chartOptions: Maybe<Highcharts.Options>): boolean {
  return (
    isDefined(dataStatus) &&
    isDefined(chartOptions) &&
    !hasAlreadyInvalidData(dataStatus, chartOptions) &&
    chartOptions.lang?.noData !== dataStatus
  );
}

function hasAlreadyInvalidData(dataStatus: DataStatus, chartOptions: Highcharts.Options): boolean {
  return chartOptions.lang?.noData === INVALID_DATA && dataStatus === DataStatus.DataReceived;
}

export function resolveDataMessage(series, componentDataStatus: DataStatus): string {
  return isReceivedDataInvalid(series, componentDataStatus) ? INVALID_DATA : componentDataStatus;
}

function isReceivedDataInvalid(series, componentDataStatus: DataStatus): boolean {
  return isSeriesEmptyOrNotDefined(series) && componentDataStatus === DataStatus.DataReceived;
}

function isSeriesEmptyOrNotDefined(series): boolean {
  if (isNotDefined(series)) {
    return true;
  }
  return series.every((dataSeries) => isEmptyOrNotDefined(dataSeries.data));
}
