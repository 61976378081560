import { Maybe } from "../../ts-utils/models/maybe.type";

export enum TimeUnit {
  Years = "years",
  Months = "months",
  Weeks = "weeks",
  Days = "days",
  Hours = "hours",
  Minutes = "minutes",
  Seconds = "seconds"
}

export function getEntry(valueOfEntry: string): Maybe<TimeUnit> {
  const matchedValue = Object.values(TimeUnit).find((value) => value === valueOfEntry);
  return matchedValue;
}
