import { TimeSeriesDataPoint } from "../../../../../data-connectivity";
import { DataPointDto } from "../../../../../data-connectivity/models/data-point";
import { RowKeyType } from "../../../../models";
import { ConnectorPerColumnStrategy } from "./connector-per-column-strategy";

export class TimeTableStrategy extends ConnectorPerColumnStrategy {
  protected get xColumnTitle(): string {
    return "Timestamp";
  }

  protected getRowKey(dataPoint: DataPointDto, _pointsIndex: number): RowKeyType {
    const seconds: RowKeyType = (dataPoint as TimeSeriesDataPoint).x.getTime() / 1000;
    return Math.round(seconds);
  }
}
