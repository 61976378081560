import { merge as _merge } from "lodash";
import { COMPONENT_STATE_DTO } from "../../elements/models/entity-type.constants";
import { DeepPartial, Maybe, isDefined, mergeDeep } from "../../ts-utils";
import {
  ComponentDataAggregationConfigDto,
  ConnectorDataAggregationConfigDto,
  DataAggregationConfigDto
} from "../models/data-aggregation-config";
import { InheritableAggregationFunction } from "../models/data-aggregation-function";
import { ApiDataSourceDto } from "../models/data-source/api-data-source";
import { DataSourceDto } from "../models/data-source/data-source";
import { DataSourceType } from "../models/data-source/data-source.type";
import { EmptyDataSourceDto } from "../models/data-source/empty-data-source";
import { EquipmentDataSourceDto } from "../models/data-source/equipment-data-source";
import { GroupedDataSourceDto } from "../models/data-source/grouped-data-source";
import { SignalDataSourceDto } from "../models/data-source/signal-data-source";
import { TabularDataSourceDto } from "../models/data-source/tabular-data-source";
import { ValueDataSourceDto } from "../models/data-source/value-data-source";
import { KeyValuePair } from "../models/key-value-pair";
import { switchPartialDataSource } from "./data-source-type.helper";

export function createDataSource(
  dataSourceType: DataSourceType,
  partial: DeepPartial<DataSourceDto>,
  ownerType: Maybe<string> = null
): DataSourceDto {
  const typedPartial = { ...partial };
  typedPartial.typeName = dataSourceType;
  if (isDefined(ownerType)) {
    const aggregationConfigDto = createDataAggregationDto(ownerType);
    typedPartial.aggregationConfig = _merge(aggregationConfigDto, typedPartial.aggregationConfig);
  }
  return switchPartialDataSource(typedPartial, {
    ApiDataSourceDto: (partialApiSource) => createApiDataSource(partialApiSource),
    EmptyDataSourceDto: (partialEmptyDataSource) => createEmptyDataSource(partialEmptyDataSource),
    EquipmentDataSourceDto: (partialEquipmentSource) =>
      createEquipmentDataSource(partialEquipmentSource),
    GroupedDataSourceDto: (partialGroupedSource) => createGroupedDataSource(partialGroupedSource),
    SignalDataSourceDto: (partialSignalSource) => createSignalDataSource(partialSignalSource),
    TabularDataSourceDto: (partialTabularSource) => createTabularDataSource(partialTabularSource),
    ValueDataSourceDto: (partialValueSource) => createValueDataSource(partialValueSource)
  });
}

function createDataAggregationDto(ownerType: string): DataAggregationConfigDto {
  const defaultValue =
    ownerType === COMPONENT_STATE_DTO
      ? InheritableAggregationFunction.None
      : InheritableAggregationFunction.Inherited;
  const partialDataAggregation: DeepPartial<DataAggregationConfigDto> = {
    materialAggregationFunction: defaultValue,
    arrayAggregationFunction: defaultValue,
    locationAggregationFunction: defaultValue,
    timeAggregationFunction: defaultValue
  };
  return ownerType === COMPONENT_STATE_DTO
    ? new ComponentDataAggregationConfigDto(partialDataAggregation)
    : new ConnectorDataAggregationConfigDto(partialDataAggregation);
}

function createApiDataSource(source: DeepPartial<ApiDataSourceDto> = {}): ApiDataSourceDto {
  const params = source.params != null ? source.params.map((dto) => new KeyValuePair(dto)) : [];
  const target = new ApiDataSourceDto(source.url, params);
  const merged = _merge(target, source);
  return merged;
}

function createEmptyDataSource(source: DeepPartial<EmptyDataSourceDto> = {}): DataSourceDto {
  const target = new EmptyDataSourceDto();
  return _merge(target, source);
}

export function createEquipmentDataSource(
  source: DeepPartial<EquipmentDataSourceDto> = {}
): EquipmentDataSourceDto {
  const target = new EquipmentDataSourceDto();
  return mergeDeep(target, source);
}

function createGroupedDataSource(
  source: DeepPartial<GroupedDataSourceDto> = {}
): GroupedDataSourceDto {
  const target = new GroupedDataSourceDto();
  return mergeDeep(target, source);
}

function createSignalDataSource(
  source: DeepPartial<SignalDataSourceDto> = {}
): SignalDataSourceDto {
  const target = new SignalDataSourceDto();
  return mergeDeep(target, source);
}

function createTabularDataSource(
  source: DeepPartial<TabularDataSourceDto> = {}
): TabularDataSourceDto {
  const target = new TabularDataSourceDto();
  return mergeDeep(target, source);
}

function createValueDataSource(source: DeepPartial<ValueDataSourceDto> = {}): ValueDataSourceDto {
  const target = new ValueDataSourceDto(source.value);
  return mergeDeep(target, source);
}
