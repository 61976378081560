<div #editorWrapper class="ngx-editor-wrapper__container" (clickOutside)="unsetFocus()">
  <quill-editor
    [ngModel]="value"
    [modules]="editorConfig"
    [customOptions]="customFontSizeOptions"
    (onEditorCreated)="initializeEditor($event)"
    (onSelectionChanged)="setFocus()"
    (onEditorChanged)="processEditorChange($event)"
  >
  </quill-editor>
</div>
