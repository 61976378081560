import { Action, createReducer, on } from "@ngrx/store";
import { GeneralSettingsDto } from "../../../core/models/general-settings";
import { PageWidth } from "../../../core/models/page-layout";
import { RuntimeSettings } from "../../../core/models/runtime-settings";
import { mergeDeep } from "../../../ts-utils/helpers/assignment.helper";
import { isNotDefined } from "../../../ts-utils/helpers/predicates.helper";
import { DeepPartial } from "../../../ts-utils/models/deep-partial.type";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { CommonActions } from "../common/common.actions";
import { GeneralSettingsActions } from "../general-settings/general-settings.actions";
import { RuntimeSettingsState } from "./runtime-settings-state";
import { RuntimeSettingsActions } from "./runtime-settings.actions";

export const initialState: RuntimeSettingsState = {
  pagePreviewWidth: "",
  currentRootPath: "",
  periodType: "",
  noAnimation: false
};

export function reducer(state: RuntimeSettingsState, action: Action): RuntimeSettingsState {
  return _reducer(state, action);
}

const _reducer = createReducer(
  initialState,
  on(RuntimeSettingsActions.setPagePreviewWidth, (state, { width }) =>
    setPagePreviewWidth(state, width)
  ),
  on(RuntimeSettingsActions.setCurrentRootPath, (state, { currentRootPath }) =>
    setCurrentRootPath(state, currentRootPath)
  ),
  on(GeneralSettingsActions.setRootPath, (state, { rootPath }) =>
    setCurrentRootPath(state, rootPath)
  ),
  on(RuntimeSettingsActions.setPeriodType, (state, { pType }) => setPeriodType(state, pType)),
  on(GeneralSettingsActions.update, (state, { changes: generalSettings }) =>
    update(state, generalSettings)
  ),
  on(CommonActions.upsertEntities, (state, { reportEntities }) =>
    onUpsertEntities(state, reportEntities?.generalSettings)
  ),
  on(CommonActions.resetStore, (state, { reportConfiguration }) =>
    setSettingsOrDefault(reportConfiguration?.runtimeSettings)
  ),
  on(CommonActions.replaceAll, (state, { entities }) => onReplaceAll(entities.runtimeSettings)),
  on(CommonActions.discardRuntimeParameters, (state, { reportSettings }) =>
    discardRuntimeSettings(state, reportSettings)
  ),
  on(RuntimeSettingsActions.setNoAnimation, (state, { noAnimation }) =>
    updateNoAnimation(state, noAnimation)
  )
);

function setPagePreviewWidth(state: RuntimeSettingsState, width: PageWidth): RuntimeSettingsState {
  return {
    ...state,
    pagePreviewWidth: width
  };
}

function setCurrentRootPath(
  state: RuntimeSettingsState,
  newRootPath: string
): RuntimeSettingsState {
  return { ...state, currentRootPath: newRootPath };
}

function setPeriodType(state: RuntimeSettingsState, newPeriodType: string): RuntimeSettingsState {
  return { ...state, periodType: newPeriodType };
}

function updateNoAnimation(
  state: RuntimeSettingsState,
  noAnimation: boolean
): RuntimeSettingsState {
  console.log(`reducer:  ${state.noAnimation} => ${noAnimation}`);
  if (state.noAnimation === noAnimation) {
    return state;
  }
  return {
    ...state,
    noAnimation
  };
}

function update(
  state: RuntimeSettingsState,
  generalSettingsUpdate: DeepPartial<GeneralSettingsDto>
): RuntimeSettingsState {
  return {
    ...state,
    periodType: generalSettingsUpdate.periodType ?? state.periodType,
    currentRootPath: generalSettingsUpdate.rootPath ?? state.currentRootPath
  };
}

function onUpsertEntities(
  state: RuntimeSettingsState,
  generalSettingsUpdate: Maybe<Partial<GeneralSettingsDto>>
): RuntimeSettingsState {
  if (isNotDefined(generalSettingsUpdate)) {
    return state;
  }

  return {
    ...state,
    periodType: generalSettingsUpdate.periodType ?? state.periodType,
    currentRootPath: generalSettingsUpdate.rootPath ?? state.currentRootPath
  };
}

function setSettingsOrDefault(newSettings: Maybe<RuntimeSettingsState>): RuntimeSettingsState {
  return mergeDeep(initialState, newSettings);
}

function onReplaceAll(newRuntimeSettings: RuntimeSettings): RuntimeSettingsState {
  return { ...newRuntimeSettings };
}

function discardRuntimeSettings(
  state: RuntimeSettingsState,
  generalSettingsUpdate: DeepPartial<GeneralSettingsDto>
): RuntimeSettingsState {
  return {
    ...state,
    periodType: generalSettingsUpdate.periodType ?? state.periodType,
    currentRootPath: generalSettingsUpdate.rootPath ?? state.currentRootPath
  };
}
