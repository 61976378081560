import _isEqual from "lodash/isEqual";
import _isObject from "lodash/isObject";
import _transform from "lodash/transform";
import { isDefined } from "./predicates.helper";

export function objectDifference(first: Object, second: Object): Object {
  return _transform(first, (result: Object, value: any, key: string | number) => {
    let secondValue = isDefined(second) ? second[key] : undefined;
    if (!_isEqual(value, secondValue)) {
      result[key] =
        _isObject(value) && _isObject(secondValue) ? objectDifference(value, secondValue) : value;
    }
  });
}
