<div [class.lightTheme]="applyLightTheme">
  <div>
    <div
      (mousedown)="nameClicked()"
      class="selectionNode"
      [class.isFiltering]="isNodeNameFiltering()"
    >
      {{ equipment?.name }}
    </div>
    <ul class="classes">
      <li
        *ngFor="let cls of equipment.classes"
        (mousedown)="classClicked(cls)"
        [class.isFiltering]="isClassFiltering(cls)"
      >
        [{{ cls }}]
      </li>
    </ul>
  </div>
</div>
