import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgZone,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { ColorListService } from "../../../../environment/services/color-list.service";
import { DateFormatterService } from "../../../../environment/services/date-formatter.service";
import { isDefined } from "../../../../ts-utils";
import { Maybe } from "../../../../ts-utils/models/maybe.type";
import { DataStatus } from "../../../models/data-status";
import { ICategoryBarDisplayConfig } from "../../../models/i-view-config/i-base-display-config";
import { DataConnectorDescriptor } from "../../../models/store/data-connector-descriptor";
import { RuntimeSettingsSelector } from "../../../services/entity-selectors/runtime-settings.selector";
import { CategoryBarDisplayService } from "../../../services/highcharts/category-bar-display.service";
import { SimpleChartComponent } from "../simple-chart/simple-chart.component";
@Component({
  selector: "c-category-bar",
  templateUrl: "./category-bar.component.html",
  styleUrls: ["./category-bar.component.scss"],
  host: { class: "simple-component" },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryBarComponent extends SimpleChartComponent implements OnChanges {
  @Input() public values: Maybe<DataConnectorDescriptor[]> = null;
  @Input() viewConfig: Maybe<ICategoryBarDisplayConfig> = null;
  @Input() dataStatus: DataStatus = DataStatus.NoDataDefined;
  @Input() horizontal: boolean = false;

  constructor(
    ngZone: NgZone,
    private dateFormatter: DateFormatterService,
    protected colorService: ColorListService,
    runtimeSettingsService: RuntimeSettingsSelector
  ) {
    super(ngZone, colorService, runtimeSettingsService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (isDefined(this.viewConfig)) {
      const displayService = this.getDisplayService();
      const options = displayService.getChartOptions(this.viewConfig, this.values ?? []);
      this.mergeChartOptions(options, true, this.dataStatus);
    }
  }

  private getDisplayService(): CategoryBarDisplayService {
    return new CategoryBarDisplayService(this.dateFormatter, this.horizontal ? "bar" : "column");
  }
}
