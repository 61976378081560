import { Action, createReducer, on } from "@ngrx/store";
import { IProjectHierarchyWithStatus } from "../../models/motor-response.interface";
import { HierarchyCollectionActions } from "../actions/hierarchy-collection.actions";

export const initialState: IProjectHierarchyWithStatus = {
  continents: [],
  motorStatus: undefined
}

export function hierarchyCollectionReducer(state: IProjectHierarchyWithStatus, action: Action) {
  return _reducer(state, action);
}

const _reducer = createReducer(
  initialState,
  on(HierarchyCollectionActions.getHierarchy, (state) => getHierarchyCollection(state)),
  on(HierarchyCollectionActions.setHierarchy, (state, { motorResponse }) =>
    setHierarchyCollection(state, motorResponse)
  )
);

function setHierarchyCollection(state: IProjectHierarchyWithStatus, motorResponse: IProjectHierarchyWithStatus) {
  if (!motorResponse) {
    return state;
  } else {
    return {
      ...state,
      ...motorResponse
    };
  }
}

function getHierarchyCollection(state: IProjectHierarchyWithStatus) {
  return state;
}
