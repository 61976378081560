import { Component, HostBinding, Input, OnInit } from "@angular/core";
import {
  FIRST_SUBGROUP_INDEX,
  SECOND_SUBGROUP_INDEX
} from "../../../meta/models/groupable-properties";
import { ObjectDescriptor } from "../../../meta/models/object-descriptor";
import { isDefaultValidationFunction } from "../../../meta/models/property-descriptor";
import { PropertyInfo } from "../../../meta/models/property-info";
import { ItemInfo } from "../../../meta/models/property-sheet-config";
import { isNotDefined } from "../../../ts-utils/helpers/predicates.helper";

const VALIDABLE_INPUT_CSS = "validable-input";

@Component({
  selector: "grouped-property-sheet-editors",
  templateUrl: "grouped-property-sheet-editors.component.html",
  styleUrls: ["grouped-property-sheet-editors.component.scss"]
})
export class GroupedPropertySheetEditors implements OnInit {
  @Input() properties: ItemInfo[] = [];
  @Input() targetInfo: ObjectDescriptor<any>;
  subgroupsIndex: number[] = [FIRST_SUBGROUP_INDEX, SECOND_SUBGROUP_INDEX];
  @HostBinding(`class.${VALIDABLE_INPUT_CSS}`) validableInput: boolean = false;

  ngOnInit(): void {
    this.validableInput = this.properties.some(
      (prop) => !isDefaultValidationFunction(prop.propertyInfo.descriptor.validationFunction)
    );
  }

  getSubgroupWidth(subgroupIndex: number): number {
    const property = this.properties.find(
      (prop) => prop.propertyInfo.descriptor.groupInfo.subgroupIndex === subgroupIndex
    );
    return isNotDefined(property)
      ? 0
      : property.propertyInfo.descriptor.groupInfo.subgroupWidthRatio * 100;
  }

  getSubgroupProperties(subgroupIndex: number): ItemInfo[] {
    return this.properties.filter(
      (item) => item.propertyInfo.descriptor.groupInfo.subgroupIndex === subgroupIndex
    );
  }

  sectionPropNames(_index: number, item: ItemInfo): string {
    return item.propertyInfo.descriptor.name;
  }

  getTargetInfo(propInfo: PropertyInfo<any>): ObjectDescriptor<unknown> {
    return propInfo.targetInfo ?? this.targetInfo;
  }
}
