import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap } from "rxjs/operators";
import { PeriodType } from "../../models/period-type";
import { DataService } from "../../services/data.service";
import { PeriodTypeActions } from "./period-type.actions";

@Injectable()
export class PeriodTypeEffects {
  constructor(private actions$: Actions, private dataService: DataService) {}

  loadPeriodTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PeriodTypeActions.load),
      switchMap(() => this.dataService.getPeriodTypes()),
      map((periodTypes: PeriodType[]) => PeriodTypeActions.addMany({ periodTypes }))
    )
  );
}
