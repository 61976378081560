<div
  class="equipment-tree-container"
  [class.lightTheme]="applyLightTheme"
  [class.showInDialog]="true"
>
  <div class="equipment-tree-title">
    {{ "Sidebar.Equipment" | translate }}
  </div>

  <div class="equipment-tree-progress-bar" *ngIf="showProgressBar">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <mat-tree
    #tree
    [dataSource]="dataSource"
    [treeControl]="treeControl"
    class="treeContainer"
    *ngIf="!showProgressBar"
  >
    <mat-tree-node
      *matTreeNodeDef="let node"
      matTreeNodePadding
      [matTreeNodePaddingIndent]="matTreeNodePadding"
    >
      <!-- use a disabled button to provide padding for tree leaf -->
      <button *ngIf="!hasChildren(node)" mat-icon-button disabled class="equipment-arrow"></button>
      <button
        *ngIf="hasChildren(node)"
        [disableRipple]="true"
        mat-icon-button
        matTreeNodeToggle
        [attr.aria-label]="'toggle'"
        class="equipment-arrow"
      >
        <span
          class="toggle-children"
          [style.transform]="isExpanded(node) ? 'rotate(90deg)' : 'rotate(0deg)'"
        ></span>
      </button>
      <equipment-path-node
        [equipment]="node"
        [selectionInfo]="node.selectionPath"
        (selected)="onNodeSelected(node, $event)"
      >
      </equipment-path-node>
    </mat-tree-node>
  </mat-tree>
  <div class="selectedPathText">
    <span class="resultArrow">&#8594;</span>
    <span *ngIf="canHaveOverallIndex()">
      <span>({{ selectedSteps() }})[</span>
      <input
        class="overallIndexInput"
        type="number"
        step="1"
        min="1"
        [ngModel]="overallIndex"
        (ngModelChange)="onOverallIndexChange($event)"
      />
      <span>]</span>
    </span>
    <span *ngIf="!canHaveOverallIndex()">
      {{ selectedSteps() }}
    </span>
  </div>
</div>
