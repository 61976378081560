import { PropertyInfo } from "../../meta/models/property-info";
import { TypeProvider } from "../../meta/services/type-provider";
import { isEmpty, isEmptyOrNotDefined } from "../../ts-utils/helpers/is-empty.helper";
import { isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { Dictionary } from "../../ts-utils/models/dictionary.type";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { StatusIndicatorViewConfig } from "../components/status-indicator/view-config";
import { IndicatorDisplayStrategy } from "../models/status-indicator/indicator-display-strategy";
import { IndicatorShape } from "../models/status-indicator/indicator-shape";
import { IndicatorState } from "../models/status-indicator/indicator-state";
import { IndicatorStateDisplay } from "../models/status-indicator/indicator-state-display";
import { ShapeStateDisplay } from "../models/status-indicator/shape-state-display";
import { StateDisplayType } from "../models/status-indicator/state-display-type";
import {
  BINARY_STATES,
  DEFAULT_BINARY_STATE,
  DEFAULT_MULTIVALUE_STATE,
  DEFAULT_STATUS_VALUE,
  ICON_SCALE_FACTOR_MAX,
  ICON_SCALE_FACTOR_MIN,
  MULTIVALUE_STATES,
  NULL_VALUE,
  NULL_VALUE_SMALL
} from "../models/status-indicator/status-indicator-states.constants";
import { StatusValue } from "../models/status-indicator/status-value";

const TYPE_PROVIDER = TypeProvider.getInstance();

export function onChangeDisplayStrategy(
  _: any,
  owner: StatusIndicatorViewConfig,
  propertyChange: PropertyInfo<IndicatorDisplayStrategy>
): StatusIndicatorViewConfig {
  return {
    ...owner,
    displayStrategy: propertyChange.value,
    states: initStatusIndicatorStates(propertyChange.value, owner.typeName)
  };
}

export function initStatusIndicatorStates(
  indicatorType: Maybe<string>,
  typeName: Maybe<string> = null
): IndicatorState[] {
  return indicatorType === IndicatorDisplayStrategy.Binary
    ? BINARY_STATES
    : getMultivalueStates(typeName);
}

export function getMultivalueStates(typeName: Maybe<string>): IndicatorState[] {
  const defaults: Partial<StatusIndicatorViewConfig> = isDefined(typeName)
    ? TYPE_PROVIDER.getDefaultsByName(typeName)
    : {};
  return !isEmptyOrNotDefined(defaults.states) ? defaults.states : MULTIVALUE_STATES;
}

export function getIndicatorStatus(
  value: StatusValue,
  displayStrategy: IndicatorDisplayStrategy,
  typeName: Maybe<string> = null
): IndicatorState {
  const indicatorStates: IndicatorState[] = initStatusIndicatorStates(displayStrategy, typeName);
  return resolveIndicatorState(value, indicatorStates, displayStrategy);
}

export function resolveIndicatorState(
  value: StatusValue,
  states: IndicatorState[],
  displayStrategy: IndicatorDisplayStrategy
): IndicatorState {
  let matchedState = states.find((state: IndicatorState) => {
    if (doesValueMatchNullState(value, state.value)) {
      return state;
    } else if (isDefined(value) && Number(value) === Number(state.value)) {
      return state;
    }
  });
  if (isNotDefined(matchedState)) {
    matchedState = resolveIfNoStateMatch(states, displayStrategy);
  }
  return matchedState;
}

function doesValueMatchNullState(value: StatusValue, stateValue: Maybe<StatusValue>): boolean {
  return (
    (isNotDefined(value) || value === "" || value === NULL_VALUE || value === NULL_VALUE_SMALL) &&
    (isNotDefined(stateValue) || stateValue === NULL_VALUE || stateValue === NULL_VALUE_SMALL)
  );
}

function resolveIfNoStateMatch(
  states: IndicatorState[],
  displayStrategy: IndicatorDisplayStrategy
): IndicatorState {
  const defaultState = states.find((state: IndicatorState) => state.value === DEFAULT_STATUS_VALUE);
  if (isDefined(defaultState)) {
    return defaultState;
  } else {
    return displayStrategy === IndicatorDisplayStrategy.Binary
      ? DEFAULT_BINARY_STATE
      : DEFAULT_MULTIVALUE_STATE;
  }
}

export function shouldHaveSameHeightAndWidth(displayType: IndicatorStateDisplay): boolean {
  return (
    displayType.typeName === StateDisplayType.Shape &&
    [
      IndicatorShape.Square,
      IndicatorShape.FullCircle,
      IndicatorShape.BorderCircle,
      IndicatorShape.Rhombus
    ].includes((displayType as ShapeStateDisplay).shape)
  );
}

export function getIconSize(height: number, title: string): number {
  return height * (isEmpty(title) ? ICON_SCALE_FACTOR_MAX : ICON_SCALE_FACTOR_MIN);
}

export function createClassDictForShapeType(indicatorShape: IndicatorShape): Dictionary<boolean> {
  const classDictionary: Dictionary<boolean> = {};
  classDictionary["status-indicator--no-shape"] = indicatorShape === IndicatorShape.None;
  classDictionary["status-indicator--circle"] =
    indicatorShape === IndicatorShape.FullCircle || indicatorShape === IndicatorShape.BorderCircle;
  classDictionary["status-indicator--rectangle"] = indicatorShape === IndicatorShape.Rectangle;
  classDictionary["status-indicator--square"] = indicatorShape === IndicatorShape.Square;
  classDictionary["status-indicator--oval"] = indicatorShape === IndicatorShape.Oval;
  classDictionary["status-indicator--rhombus"] = indicatorShape === IndicatorShape.Rhombus;
  return classDictionary;
}
