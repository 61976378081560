import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { CategorizePropertiesPipe } from "./pipes/categorize-properties.pipe";
import { SortByOrderPipe } from "./pipes/sort-by-order.pipe";
import { SortSubcategoryPipe } from "./pipes/sort-subcategory.pipe";
import { TypeProvider, typeProviderFactory } from "./services/type-provider";

@NgModule({
  imports: [CommonModule],
  declarations: [SortByOrderPipe, SortSubcategoryPipe, CategorizePropertiesPipe],
  exports: [SortByOrderPipe, SortSubcategoryPipe, CategorizePropertiesPipe]
})
export class MetaModule {
  static forRoot(): ModuleWithProviders<MetaModule> {
    return {
      ngModule: MetaModule,
      providers: [{ provide: TypeProvider, useFactory: typeProviderFactory }]
    };
  }
}
