import { Injectable } from "@angular/core";
import { AggregationFunction } from "../../data-connectivity/models/data-aggregation-function";
import { isDefined } from "../../ts-utils/helpers/predicates.helper";
import {
  getComponentStateAggregationConfig,
  getDataSourceAggregationConfig
} from "../helpers/v0.helper";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class UpdateDataAggregationConfig implements UpgradeStep {
  name = "UpdateDataAggregationConfig";
  fromVersion = new Version(1, 0, 3);

  perform(oldConfig: any): UpgradeStepResult {
    const connectorsAggregationConfigs = getDataSourceAggregationConfig(oldConfig);
    const componentStateAggregationConfig = getComponentStateAggregationConfig(oldConfig);
    const aggregationConfigs = [
      ...connectorsAggregationConfigs,
      ...componentStateAggregationConfig
    ];
    let modified = false;
    aggregationConfigs.forEach((aggregationConfig) => {
      if (renameArrayAggregationProperty(aggregationConfig)) {
        modified = true;
      }
      if (updateSortAggregationToMaterialAggregationFunction(aggregationConfig)) {
        modified = true;
      }
    });

    return {
      result: oldConfig,
      modified,
      warning: null
    };
  }
}

function renameArrayAggregationProperty(aggregationConfig): boolean {
  let modified = false;
  if (isDefined(aggregationConfig["arrayAggregation"])) {
    aggregationConfig["arrayAggregationFunction"] = aggregationConfig["arrayAggregation"];
    delete aggregationConfig["arrayAggregation"];
    modified = true;
  }
  return modified;
}

function updateSortAggregationToMaterialAggregationFunction(aggregationConfig): boolean {
  let modified = false;
  if (isDefined(aggregationConfig["sortAggregation"])) {
    aggregationConfig["materialAggregationFunction"] = sortAggregationToMaterialAggregationFunction(
      aggregationConfig["sortAggregation"]
    );
    delete aggregationConfig["sortAggregation"];
    modified = true;
  }
  return modified;
}

function sortAggregationToMaterialAggregationFunction(sortAggregation: boolean): any {
  // TODO should we use hardcoded strings here, instead of enum.value? make it safer to refactor enums
  return sortAggregation ? AggregationFunction.Default : AggregationFunction.None;
}
