import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import moment from "moment";
import { TimeRange } from "../../../core/models/time-range";
import { ErrorCatchingActions } from "../../../core/store";
import { Dispatcher } from "../../../dispatcher";
import { ConnectorRoles } from "../../../elements/decorators/connector-roles.decorator";
import { LocalizationService } from "../../../i18n/localization.service";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { EditableType } from "../../../meta/decorators/editable-type.decorator";

const MY_FORMATS = {
  parse: {
    dateInput: "DD/MMM/YYYY"
  },
  display: {
    dateInput: "DD/MMM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@Component({
  selector: "c-basic-time-selector",
  templateUrl: "./basic-time-selector.component.html",
  styleUrls: ["./basic-time-selector.component.scss"],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
@ConnectorRoles()
@EditableType({ fullName: "BasicTimeSelectorComponent", title: "basic-time-selector" })
export class BasicTimeSelectorComponent {
  @Input() public timeRange: TimeRange;
  @Input() public monthly: boolean;

  @Output() timeRangeChanged: EventEmitter<TimeRange> = new EventEmitter<TimeRange>();

  constructor(
    private adapter: DateAdapter<any>,
    private dispatcher: Dispatcher,
    private translationService: LocalizationService
  ) {
    const timeRangeFrom = moment().subtract(4, "hours").toDate();
    const timeRangeTo = moment().toDate();
    this.timeRange = new TimeRange(timeRangeFrom, timeRangeTo);
  }

  onChangeFromDate(value, datepicker: MatDatepicker<any> = null) {
    const newFromDate = new Date(value);
    this.timeRange.from = newFromDate;
    if (!!datepicker) {
      datepicker.close();
    }

    let newTimeRange: TimeRange = null;
    // TODO: is using try/catch okay?

    const invalidTimeRangeErrorMessage = this.translationService.get(
      LOCALIZATION_DICTIONARY.snackBarMessages.InvalidTimeRangeError
    );
    try {
      newTimeRange = {
        from: newFromDate,
        to: this.timeRange.to
      };
      this.timeRange = newTimeRange;
      this.timeRangeChanged.emit(this.timeRange);
    } catch (error) {
      this.dispatcher.dispatch(
        ErrorCatchingActions.catchError({
          messageToDisplay: invalidTimeRangeErrorMessage,
          error: error,
          autoClose: true
        })
      );
    }
  }

  onChangeToDate(value, datepicker: MatDatepicker<any> = null) {
    const invalidTimeRangeErrorMessage = this.translationService.get(
      LOCALIZATION_DICTIONARY.snackBarMessages.InvalidTimeRangeError
    );
    const newToDate = new Date(value);
    this.timeRange.to = newToDate;
    if (!!datepicker) {
      datepicker.close();
    }

    let newTimeRange: TimeRange = null;
    try {
      newTimeRange = {
        from: this.timeRange.from,
        to: newToDate
      };
      this.timeRange = newTimeRange;
      this.timeRangeChanged.emit(this.timeRange);
    } catch (error) {
      this.dispatcher.dispatch(
        ErrorCatchingActions.catchError({
          messageToDisplay: invalidTimeRangeErrorMessage,
          error: error,
          autoClose: true
        })
      );
    }
  }
}
