import { createEntityAdapter, Update } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import { difference as _difference } from "lodash";
import { DataConnectorDto } from "../../../data-connectivity";
import { getConnectorViewId } from "../../../data-connectivity/helpers/connector-view-id.helper";
import { DataConnectorViewDto } from "../../../data-connectivity/models/data-connector-view";
import { EntityId } from "../../../meta";
import {
  assignDeep,
  DeepUpdate,
  Dictionary,
  flattenArrayDict,
  isDefined,
  isNotDefined,
  Maybe
} from "../../../ts-utils";
import { CriticalError } from "../../../ts-utils/models/critical-error";
import { isPseudoConnector } from "../../helpers/connectors.helper";
import { ReportEntities, TableConnectorView } from "../../models";
import { ConnectorsReplaceInfo } from "../../models/store/connectors-replace-info";
import { getDefaultReportContent } from "../../services";
import { CommonActions } from "../common";
import { DataConnectorActions } from "../data-connector/data-connector.actions";
import { DataConnectorViewActions } from "./data-connector-view.actions";
import { DataConnectorViewState } from "./data-connector-view.state";

const adapter = createEntityAdapter<DataConnectorViewDto>();
export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

export const initialState: DataConnectorViewState = adapter.getInitialState();

export function reducer(state: DataConnectorViewState, action: Action): DataConnectorViewState {
  return _reducer(state, action);
}

const _reducer = createReducer(
  initialState,
  on(DataConnectorActions.addOne, (state, { connector, groupId }) =>
    onConnectorAddOne(state, connector.id, groupId)
  ),
  on(DataConnectorActions.addMany, (state, { connectors }) =>
    onConectorAddMany(
      state,
      connectors.map((connector) => connector.id)
    )
  ),
  on(DataConnectorActions.deleteOne, (state, { connector }) =>
    onConnectorDeleteOne(state, connector.id)
  ),
  on(DataConnectorActions.deleteMany, (state, { connectorsByComponent }) =>
    onConnectorDeleteMany(state, flattenArrayDict(connectorsByComponent))
  ),
  on(DataConnectorViewActions.updateOne, (state, { connectorViewUpdate }) =>
    updateOne(state, connectorViewUpdate)
  ),
  on(DataConnectorViewActions.updateMany, (state, { connectorViewUpdates }) =>
    updateMany(state, connectorViewUpdates)
  ),
  on(DataConnectorViewActions.clearConnectorsFromDeletedYAxis, (state, { validYAxes }) =>
    onClearConnectorsFromDeletedYAxis(state, validYAxes)
  ),
  on(DataConnectorActions.updateAllEquipmentSources, (state, { connectorReplacementDict }) =>
    onReplaceConnectors(state, connectorReplacementDict)
  ),
  on(DataConnectorActions.replaceGenericConnectors, (state, { connectorReplacementDict }) =>
    onReplaceConnectors(state, connectorReplacementDict)
  ),
  on(DataConnectorActions.replaceMany, (state, { componentId, connectorsReplaceInfo, groupId }) =>
    onReplaceConnectors(state, { [componentId]: connectorsReplaceInfo }, groupId)
  ),
  on(DataConnectorActions.replaceOne, (state, { oldConnector, newConnector }) =>
    onReplaceOneConnector(state, oldConnector.id, newConnector)
  ),
  on(DataConnectorActions.updateOne, (state, { connectorUpdate }) =>
    onConnectorUpdate(state, connectorUpdate)
  ),
  on(CommonActions.resetStore, () => setDefaultState()),
  on(CommonActions.upsertEntities, (state, { reportEntities }) =>
    onUpsertEntities(state, reportEntities)
  ),
  on(CommonActions.upsertEntitiesOnLoad, (state, { reportEntities }) =>
    onUpsertEntities(state, reportEntities)
  ),
  on(CommonActions.replaceAll, (state, { entities }) =>
    onReplaceAll(state, entities.dataConnectorViews)
  )
);

function onConnectorAddOne(
  state: DataConnectorViewState,
  connectorId: EntityId,
  groupId?: string
): DataConnectorViewState {
  let connectorView = new DataConnectorViewDto({ id: getConnectorViewId(connectorId), groupId });
  if (isPseudoConnector(connectorId)) {
    connectorView = { ...connectorView, order: 0, column: new TableConnectorView() };
  }
  return adapter.addOne(connectorView, state);
}

function onConectorAddMany(
  state: DataConnectorViewState,
  connectorIds: EntityId[]
): DataConnectorViewState {
  const connectorViews = connectorIds.map(
    (connectorId) => new DataConnectorViewDto({ id: getConnectorViewId(connectorId) })
  );
  return adapter.addMany(connectorViews, state);
}

function onConnectorDeleteOne(
  state: DataConnectorViewState,
  connectorId: EntityId
): DataConnectorViewState {
  const connectorViewId = getConnectorViewId(connectorId);
  return adapter.removeOne(connectorViewId.toString(), state);
}

function onConnectorDeleteMany(
  state: DataConnectorViewState,
  connectors: DataConnectorDto[]
): DataConnectorViewState {
  const connectorViewsIds = connectors.map((conn) => getConnectorViewId(conn.id));
  return adapter.removeMany(connectorViewsIds as string[], state);
}

function updateOne(
  state: DataConnectorViewState,
  connectorUpdate: Update<DataConnectorViewDto>
): DataConnectorViewState {
  const { id, changes } = connectorUpdate;
  if (isNotDefined(id)) {
    throw new CriticalError("Undefined id for connector view");
  }
  const targetView: Maybe<DataConnectorViewDto> = state.entities[id];
  if (isNotDefined(targetView)) {
    throw new CriticalError("Undefined connector view");
  }
  const mergedChanges = assignDeep(targetView, changes);
  return adapter.updateOne({ id: id.toString(), changes: mergedChanges }, state);
}

function updateMany(
  state: DataConnectorViewState,
  connectorViewUpdates: DeepUpdate<DataConnectorViewDto>[]
): DataConnectorViewState {
  const mergedUpdates: Update<DataConnectorViewDto>[] = connectorViewUpdates.map(
    (connectorView: DeepUpdate<DataConnectorViewDto>) => {
      const targetConnectorView: DataConnectorViewDto = state.entities[connectorView.id];
      const mergedChanges: DataConnectorViewDto = assignDeep(
        targetConnectorView,
        connectorView.changes
      );
      return { id: connectorView.id.toString(), changes: mergedChanges };
    }
  );
  return adapter.updateMany(mergedUpdates, state);
}

function setDefaultState(): DataConnectorViewState {
  return getDefaultReportContent().dataConnectorViews;
}

function onUpsertEntities(
  state: DataConnectorViewState,
  reportEntities: ReportEntities
): DataConnectorViewState {
  const fullConnectorViews = reportEntities.dataConnectorViews.map(
    (connectorView: DataConnectorViewDto) => new DataConnectorViewDto(connectorView)
  );
  const connectorViewsToUpsert = fullConnectorViews.concat(
    createMissingConnectorViews(reportEntities)
  );
  return adapter.setMany(connectorViewsToUpsert, state);
}

function onClearConnectorsFromDeletedYAxis(
  state: DataConnectorViewState,
  validYAxes: string[]
): DataConnectorViewState {
  const cleanedConnectors: Update<DataConnectorViewDto>[] = cleanConnectorsFromDeletedYAxis(
    state,
    validYAxes
  );
  return adapter.updateMany(cleanedConnectors, state);
}

function cleanConnectorsFromDeletedYAxis(
  state: DataConnectorViewState,
  validYAxes: string[]
): Update<DataConnectorViewDto>[] {
  return Object.values(state.entities)
    .filter(isDefined)
    .reduce((acc: Update<DataConnectorViewDto>[], connectorView: DataConnectorViewDto) => {
      const shouldRemove: boolean = connectorBelongsToDeletedYAxis(connectorView, validYAxes);
      return shouldRemove
        ? acc.concat({
            id: connectorView.id.toString(),
            changes: { axisId: null }
          })
        : acc;
    }, []);
}

function connectorBelongsToDeletedYAxis(
  connectorView: DataConnectorViewDto,
  validYAxes: string[]
): boolean {
  return isDefined(connectorView.axisId) && !validYAxes.some((id) => connectorView.axisId === id);
}

function onReplaceOneConnector(
  state: DataConnectorViewState,
  oldConnectorId: EntityId,
  newConnector: DataConnectorDto
): DataConnectorViewState {
  state = adapter.removeOne(getConnectorViewId(oldConnectorId), state);
  return adapter.addOne(
    new DataConnectorViewDto({ id: getConnectorViewId(newConnector.id) }),
    state
  );
}

function onConnectorUpdate(
  state: DataConnectorViewState,
  connectorUpdate: Update<DataConnectorDto>
): DataConnectorViewState {
  const { id, changes } = connectorUpdate;
  const updatedTitle: Maybe<string> = changes.title;
  if (isNotDefined(updatedTitle)) {
    return state;
  }

  const connectorViewUpdate: Update<DataConnectorViewDto> = {
    id: getConnectorViewId(id),
    changes: { title: updatedTitle }
  };
  return updateOne(state, connectorViewUpdate);
}

function onReplaceConnectors(
  state: DataConnectorViewState,
  connectorReplacementDict: Dictionary<ConnectorsReplaceInfo>,
  groupId?: string
): DataConnectorViewState {
  const replaceInfo: ConnectorsReplaceInfo = Object.keys(connectorReplacementDict).reduce(
    (acc, componentId) => {
      acc.obsoleteConnectors = acc.obsoleteConnectors.concat(
        connectorReplacementDict[componentId].obsoleteConnectors
      );
      acc.newConnectors = acc.newConnectors.concat(
        connectorReplacementDict[componentId].newConnectors
      );
      return acc;
    },
    {
      obsoleteConnectors: [],
      newConnectors: [],
      connectorsToUpdate: []
    } as ConnectorsReplaceInfo
  );
  const viewsToDelete = replaceInfo.obsoleteConnectors.map((conn) => getConnectorViewId(conn.id));
  state = adapter.removeMany(viewsToDelete, state);
  const newViews = replaceInfo.newConnectors.map(
    (connector) => new DataConnectorViewDto({ id: getConnectorViewId(connector.id), groupId })
  );
  return adapter.addMany(newViews, state);
}

function createMissingConnectorViews(reportEntities: ReportEntities): DataConnectorViewDto[] {
  const existingConnectorViewIds = reportEntities.dataConnectorViews.map((dcView) =>
    dcView.id.toString()
  );
  const allConnectorViewIds = reportEntities.dataConnectors.map((connector) =>
    getConnectorViewId(connector.id)
  );
  const missingViews = _difference(allConnectorViewIds, existingConnectorViewIds).map(
    (connectorViewId) => new DataConnectorViewDto({ id: connectorViewId })
  );
  return missingViews;
}

function onReplaceAll(
  state: DataConnectorViewState,
  newDataConnectorViews: DataConnectorViewDto[]
): DataConnectorViewState {
  return adapter.setAll(newDataConnectorViews, state);
}
