import { Injectable } from "@angular/core";

@Injectable()
export class IUserPreferencesService {
  getLanguage(): string {
    throw new Error("Not implemented");
  }

  setLanguage(_language: string): void {
    throw new Error("Not implemented");
  }

  getDateTimeFormat(): string {
    throw new Error("Not implemented");
  }

  setUseServerTime(_useServerTime: boolean): void {
    throw new Error("Not implemented");
  }

  logOut(): void {
    throw new Error("Not implemented");
  }

  getAliasMode(): boolean {
    throw new Error("Not implemented");
  }
}
