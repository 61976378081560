import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Maybe } from "../../../ts-utils/models/maybe.type";

@Component({
  selector: "toggle-switch",
  templateUrl: "toggle-switch.component.html",
  styleUrls: ["./toggle-switch.component.scss"]
})
export class ToggleSwitchComponent {
  @ViewChild("input") input: ElementRef = {} as ElementRef;
  @ViewChild("switch") switch?: ElementRef;
  @Input() label: string = "";
  @Input() value: boolean = false;
  @Input() distinctDefault: boolean = false;
  @Output() changeValue: EventEmitter<any> = new EventEmitter<any>();

  onValueChange(newValue: boolean, event: Event): void {
    this.value = newValue;
    this.changeValue.emit(newValue);
    event.stopPropagation();
  }

  getSwitch(): Maybe<ElementRef> {
    return this.switch;
  }
}
