<div class="single-label__wrapper" [ngStyle]="labelStyle">
  <icon
    class="single-label__icon"
    *ngIf="viewConfig?.icon != null"
    [icon]="viewConfig.icon"
    [iconSize]="iconSize"
  ></icon>
  <div *ngIf="hasLink && isPreviewMode$ | async">
    <i class="abb-icon Right_arrow link-button" (click)="linkIsOpen()"></i>
  </div>
  <span
    label-inline-edit
    [editableText]="viewConfig?.title"
    (click)="linkIsOpen()"
    [ngClass]="{
      'single-label__title--wrap': isTitleFormatWrap(),
      'single-label__title--link link-button': hasLink
    }"
    [style.text-align]="viewConfig?.horizontalAlignment.toLocaleLowerCase()"
    >{{ viewConfig?.title }}</span
  >
</div>
