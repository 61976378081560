import { CommonDataPointPropertyNames } from "../../data-connectivity/models/common-data-properties";
import { DataConnectorRole } from "../../data-connectivity/models/data-connector-role";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import {
  ConfigurationCategory,
  DynamicDefaultFromData,
  DynamicDefaultFromMetadata,
  Serializable
} from "../../meta/decorators";
import { PropertyCategory } from "../../meta/models";
import { LimitProperties } from "../../property-sheet/models/limit-properties";
import { isDefined, Maybe } from "../../ts-utils";
import {
  applyPropertyDecorators,
  PropertyDecoratorFunction
} from "../../ts-utils/helpers/decorator.helper";
import { nameof } from "../../ts-utils/helpers/name-of";
import { LimitsDto } from "./limits";

export interface LimitValueConfigDto {
  target: Maybe<number>;

  extremeLow: Maybe<number>;
  veryLow: Maybe<number>;
  low: Maybe<number>;

  high: Maybe<number>;
  veryHigh: Maybe<number>;
  extremeHigh: Maybe<number>;
}
export interface LimitPropertyNames {
  viewConfigProperty: LimitProperties;
  commonDataPointProperty: CommonDataPointPropertyNames;
  localized: string;
  orderIndex: number;
}

export const LimitsPropertyNames: LimitPropertyNames[] = [
  {
    viewConfigProperty: LimitProperties.target,
    commonDataPointProperty: CommonDataPointPropertyNames.target,
    localized: LOCALIZATION_DICTIONARY.propertySheet.Target,
    orderIndex: 0
  },
  {
    viewConfigProperty: LimitProperties.extremeLow,
    commonDataPointProperty: CommonDataPointPropertyNames.loLoLoLimit,
    localized: LOCALIZATION_DICTIONARY.propertySheet.ExtremeLowValue,
    orderIndex: 1
  },
  {
    viewConfigProperty: LimitProperties.veryLow,
    commonDataPointProperty: CommonDataPointPropertyNames.loLoLimit,
    localized: LOCALIZATION_DICTIONARY.propertySheet.VeryLowValue,
    orderIndex: 2
  },
  {
    viewConfigProperty: LimitProperties.low,
    commonDataPointProperty: CommonDataPointPropertyNames.loLimit,
    localized: LOCALIZATION_DICTIONARY.propertySheet.LowValue,
    orderIndex: 3
  },
  {
    viewConfigProperty: LimitProperties.high,
    commonDataPointProperty: CommonDataPointPropertyNames.hiLimit,
    localized: LOCALIZATION_DICTIONARY.propertySheet.HighValue,
    orderIndex: 4
  },
  {
    viewConfigProperty: LimitProperties.veryHigh,
    commonDataPointProperty: CommonDataPointPropertyNames.hiHiLimit,
    localized: LOCALIZATION_DICTIONARY.propertySheet.VeryHighValue,
    orderIndex: 5
  },
  {
    viewConfigProperty: LimitProperties.extremeHigh,
    commonDataPointProperty: CommonDataPointPropertyNames.hiHiHiLimit,
    localized: LOCALIZATION_DICTIONARY.propertySheet.ExtremeHighValue,
    orderIndex: 6
  }
];

export function configureLimitValues<T extends LimitsDto>(
  viewConfigType: {
    new (...args: any[]): T;
  },
  roleToTakeValuesFrom: DataConnectorRole,
  targetRole: Maybe<DataConnectorRole>,
  lookInLastDataPointProperty: boolean
): void {
  const prototype = viewConfigType.prototype;

  const baseOrderIndex: number = 20;

  LimitsPropertyNames.forEach((limitPropertyNames) => {
    configureLimitValue(
      prototype,
      limitPropertyNames.viewConfigProperty,
      roleToTakeValuesFrom,
      limitPropertyNames.commonDataPointProperty,
      baseOrderIndex + limitPropertyNames.orderIndex,
      lookInLastDataPointProperty
    );
  });

  if (isDefined(targetRole)) {
    applyPropertyDecorators(
      prototype,
      nameof<LimitValueConfigDto>("target"),
      Serializable(null),
      DynamicDefaultFromData(targetRole.name, 1)
    );
  }
}

function configureLimitValue(
  prototype: Object,
  key: string,
  roleToTakeValuesFrom: DataConnectorRole,
  propertyName: string,
  orderIndex: number,
  lookInLastDataPointProperty: boolean
): void {
  applyPropertyDecorators(
    prototype,
    key,
    ...getCommonDecorators(orderIndex),
    DynamicDefaultFromMetadata(
      roleToTakeValuesFrom.name,
      propertyName,
      -5,
      true,
      lookInLastDataPointProperty
    )
  );
}

function getCommonDecorators(orderIndex: number): PropertyDecoratorFunction[] {
  return [
    ConfigurationCategory(
      PropertyCategory.Display,
      LOCALIZATION_DICTIONARY.propertySheet.Limits,
      orderIndex
    ),
    Serializable(null)
  ];
}
