<div
  #container
  class="limits-editor-tooltip__container"
  [class.container--user-configured]="color !== defaultColor"
  [style.left.px]="editorVisualization.absolutePositionX"
>
  <input
    #limitInput
    focusVisualization
    [focusedElement]="container"
    [attr.name]="key"
    class="limits-editor-tooltip__value-input"
    [class.editor--value-configured]="value !== defaultValue"
    [ngClass]="{
      'editor--value-configured': value !== defaultValue && !editorVisualization.isInvalidValue,
      'invalid-value': editorVisualization.isInvalidValue && limitInput.value !== ''
    }"
    type="number"
    [value]="value"
    (input)="valueUpdate.next($event.target.value)"
  />
  <div class="limit-color__indicator">
    <div class="limit-color--selected-background"></div>
    <div
      class="limit-color--selected"
      [style.background-color]="color ?? defaultColor"
      (click)="openColorPicker($event)"
    ></div>
  </div>
</div>
