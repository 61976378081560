<mat-dialog-content class="add-axis-dialog__input-container">
  <div mat-dialog-title>Add axis</div>
  <div class="add-axis-dialog__input-wrapper">
    <div>Axis title:</div>
    <input type="text" #titleInput />
  </div>
  <div class="add-axis-dialog__input-wrapper">
    <div>Axis unit:</div>
    <input type="text" #unitInput />
  </div>
</mat-dialog-content>
<mat-dialog-actions class="add-axis-dialog__button-container">
  <button
    class="c-button c-button--rounded trend__button--rounded c-button--primary"
    [disabled]="inputsEmpty"
    (click)="onDialogClosed()"
  >
    Add
  </button>
  <button class="c-button c-button--rounded trend__button--rounded" mat-dialog-close>Cancel</button>
</mat-dialog-actions>
