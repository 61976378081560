import { Injectable, OnDestroy } from "@angular/core";
import { HttpTransportType, HubConnection, HubConnectionState, LogLevel } from "@microsoft/signalr";
import { environment } from "projects/rds/src/environments/environment";
import { EMPTY, Observable, Subject, combineLatest, defer, from, of } from "rxjs";
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  takeUntil,
  tap
} from "rxjs/operators";
import {
  ComponentStateSelector,
  ConnectorsDictionaryIndexedById,
  DataConnectorActions,
  DataConnectorDto,
  DataConnectorSelector,
  DataPointDto,
  DataStatus,
  Dictionary,
  Dispatcher,
  EntityId,
  FilterSelector,
  GeneralSettingsSelector,
  Maybe,
  RUNTIME_FILTER_ID,
  ReportInfoSelector,
  RuntimeSettingsSelector,
  SignalDataSourceDto,
  WebServicesConfiguration,
  isDefined
} from "ui-core";
import { FLEET_OVERVIEW_REPORT_ID } from "../../models/constants/predefined-reports";
import { SignalRHandshake } from "../../types";
import { AzureQueryStringService } from "./azure-query-string.service";
import { HubConnectionFactoryService } from "./hubConnectionFactory.service";
import { PlantTimeConverter } from "./plant-time.converter";
import { SignalRNotification } from "./signalRNotification.model";

@Injectable()
export class SignalRService implements OnDestroy {
  private signalRConnection!: HubConnection;
  private currentInstance: string = "";
  private destroy$ = new Subject<void>();

  constructor(
    private queryStringService: AzureQueryStringService,
    private apiConfig: WebServicesConfiguration,
    private dispatcher: Dispatcher,
    private connectorSelector: DataConnectorSelector,
    private hubFactory: HubConnectionFactoryService,
    private timeConverter: PlantTimeConverter,
    private generalSettingsSelector: GeneralSettingsSelector,
    private runtimeSettingsSelector: RuntimeSettingsSelector,
    private componentStateSelector: ComponentStateSelector,
    private reportInfoSelector: ReportInfoSelector,
    private filterSelector: FilterSelector
  ) {}

  public init(accessToken: string): void {
    if (!accessToken) {
      throw Error("Access token is null or undefined");
    }

    this.signalRConnection = this.hubFactory
      .getHubConnectionBuilder()
      .withUrl(this.apiConfig.pushDataServiceUrl, {
        accessTokenFactory: () => accessToken,
        transport: HttpTransportType.None
      })
      .withAutomaticReconnect()
      .configureLogging(environment.production ? LogLevel.Error : LogLevel.Debug)
      .build();

    this.subscribeToStore();
  }

  /**
   * It subscribes to the store to get notified about liveMode and it registers or unregisters accordingly from SignalR.
   */
  private subscribeToStore(): void {
    const currentReportId$ = this.reportInfoSelector
      .selectReportId()
      .pipe(filter((reportId) => isDefined(reportId)));

    const runtimeFilter$ = this.filterSelector.selectByIdOrDefault(RUNTIME_FILTER_ID);

    combineLatest([runtimeFilter$, currentReportId$])
      .pipe(
        map(([runtimeFilter, reportId]): SignalRHandshake => {
          const instance = this.resolveInstanceName(reportId!.toString());
          const isLive = runtimeFilter.timeRange.toExpression === "";
          return {
            instance,
            isLive
          };
        }),
        distinctUntilChanged((prev: SignalRHandshake, curr: SignalRHandshake) => {
          const hasInstanceChanged = prev.instance === curr.instance;
          const hasLiveModeChange = prev.isLive === curr.isLive;

          return hasInstanceChanged && hasLiveModeChange;
        }),
        switchMap((handshake: SignalRHandshake) => {
          return this.registerInstance$(handshake.isLive, handshake.instance);
        }),
        tap((newInstance: string) => (this.currentInstance = newInstance)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private resolveInstanceName(reportId: string): string {
    if (reportId === FLEET_OVERVIEW_REPORT_ID) {
      return "*";
    }
    return this.queryStringService.getSignalRInstanceName();
  }

  private registerInstance$(isLive: boolean, newInstance: string): Observable<string> {
    const state = this.signalRConnection.state;
    const sameInstance = this.currentInstance === newInstance;
    const disconnectedNotLive = !isLive && state === HubConnectionState.Disconnected; // no connection and no live mode
    const sameInstanceConnected = isLive && state === HubConnectionState.Connected && sameInstance; // connection opened and same instance
    const noInstance = !this.currentInstance && !newInstance; // there is no instance

    let observableResource$: Observable<string> = of(newInstance);

    /** Ignore when: */
    if (disconnectedNotLive || sameInstanceConnected || noInstance) {
      return EMPTY;
    }

    // We need to have a connection with the right customer
    // no connection yet
    if (isLive && newInstance && state === HubConnectionState.Disconnected) {
      observableResource$ = this.startPushServiceAndRegisterForNewCustomer$(newInstance);
    } else if (
      // connection established but different customer
      isLive &&
      state === HubConnectionState.Connected &&
      newInstance &&
      !sameInstance
    ) {
      observableResource$ = defer(() =>
        from(this.signalRConnection.invoke("Unregister", this.currentInstance)).pipe(
          switchMap(() => from(this.signalRConnection.invoke("Register", newInstance))),
          map(() => newInstance)
        )
      );
      // unregister and disconnect
    } else if (!isLive || !newInstance) {
      observableResource$ = this.stopPushServiceAndUnregisterForCurrentCustomer$();
    }

    return observableResource$.pipe(
      catchError((err) => {
        console.log("Error initializing signalR", err);
        return of(newInstance);
      })
    );
  }

  public stopPushServiceAndUnregisterForCurrentCustomer$(): Observable<string> {
    if (!this.currentInstance) {
      return this.stopPushService$().pipe(map(() => ""));
    }

    return defer(() =>
      from(this.signalRConnection.invoke("Unregister", this.currentInstance))
    ).pipe(
      tap(() => this.signalRConnection.off("SendMessage")),
      switchMap(() => this.stopPushService$()),
      map(() => "")
    );
  }

  public startPushServiceAndRegisterForCurrentCustomer$(): Observable<string> {
    return this.startPushServiceAndRegisterForNewCustomer$(this.currentInstance);
  }

  public startPushServiceAndRegisterForNewCustomer$(newCustomer: string): Observable<string> {
    if (newCustomer == null) {
      return EMPTY;
    }

    return this.startPushService$().pipe(
      switchMap(() => from(this.signalRConnection.invoke("Register", newCustomer))),
      tap(() => this.signalRConnection.on("SendMessage", (msg) => this.eventMessageHandler(msg))),
      map(() => newCustomer)
    );
  }

  /**
   * It starts the signalR connection.
   */
  public startPushService$(): Observable<void> {
    const states = [
      HubConnectionState.Connected,
      HubConnectionState.Connecting,
      HubConnectionState.Reconnecting
    ];

    if (states.includes(this.signalRConnection.state)) {
      return EMPTY;
    }

    this.signalRConnection.onreconnected(() => {
      this.signalRConnection.invoke("Register", this.currentInstance);
    });

    return from(
      this.signalRConnection.start().catch((err) => {
        console.log("Error starting signalR connection", err);
      })
    );
  }

  /**
   * It stops signalR connection.
   */
  public stopPushService$(): Observable<void> {
    const states = [HubConnectionState.Disconnected, HubConnectionState.Disconnecting];

    if (states.includes(this.signalRConnection.state)) {
      return EMPTY;
    }

    return from(
      this.signalRConnection.stop().catch((err) => {
        console.log("Error stopping signalR connection", err);
      })
    );
  }

  /**
   * It handles a message notification received from SignalR.
   * @param type Type of notification
   * @param payload Message
   */
  private eventMessageHandler(payload: string): void {
    if (!payload) {
      console.error("SignalR: Empty notification received");
      return;
    }

    this.dispatcher.dispatch(
      DataConnectorActions.updateData({
        connectorDict: this.parseNotificationToDataConnectorArray(
          JSON.parse(payload) as SignalRNotification
        )
      })
    );
  }

  /**
   * It parses the notification to a array of data connectors
   * @param notification
   * @returns
   */
  private parseNotificationToDataConnectorArray(
    notification: SignalRNotification
  ): ConnectorsDictionaryIndexedById {
    return this.getConnectorsToBeUpdated(notification).reduce(
      (acc: Dictionary<DataConnectorDto>, connector: Maybe<DataConnectorDto>) => {
        if (!connector) {
          return acc;
        }

        const signalId = (connector.dataSource as SignalDataSourceDto).signal.id;

        if (!signalId) {
          return acc;
        }

        acc[connector.id] = { ...connector };

        const newDatapoint = [
          {
            x: this.generalSettingsSelector.getGeneralSettings().useServerTime
              ? new Date(notification.DataTimestamp)
              : new Date(this.timeConverter.addLocalTimeBias(notification.DataTimestamp)),
            y: notification.Signals[signalId]
          }
        ];

        let lastDp = null;

        if (acc[connector.id].dataPoints?.length! > 0) {
          lastDp = acc[connector.id].dataPoints![acc[connector.id].dataPoints?.length! - 1];
        }

        if (
          !lastDp ||
          (connector.numberOfRequestedDataPoints === 1 &&
            isDefined(lastDp.x) &&
            (lastDp.x as Date).getTime() < newDatapoint[0].x.getTime())
        ) {
          acc[connector.id].dataPoints = newDatapoint;
        } else if (connector.numberOfRequestedDataPoints > 1) {
          // check if there is an item for the same date in the collection
          const idx = acc[connector.id].dataPoints?.findIndex(
            (d: DataPointDto) => (d.x as Date).getTime() === newDatapoint[0]?.x?.getTime()
          );

          if (idx! >= 0) {
            const newDps = [...(acc[connector.id].dataPoints as any[])];
            newDps[idx!] = newDatapoint[0];

            acc[connector.id].dataPoints = newDps;
          } else {
            acc[connector.id].dataPoints = acc[connector.id].dataPoints?.concat(newDatapoint);
            acc[connector.id].dataPoints = acc[connector.id].dataPoints?.sort((a, b) =>
              a.x && b.x && a.x < b.x ? -1 : 1
            );
          }
        }

        acc[connector.id].dataStatus = DataStatus.DataReceived;

        return acc;
      },
      {}
    );
  }

  /**
   * Returns all connectors that should be notified by given notification.
   * @param notification
   */
  getConnectorsToBeUpdated(notification: SignalRNotification): Maybe<DataConnectorDto>[] {
    const notificationSignalIds = Object.keys(notification.Signals) as EntityId[];
    const notificationPType = notification.PType;

    const runtimeFiterPeriodType = this.runtimeSettingsSelector.getPeriodType();

    const connectors = this.connectorSelector.getAllSignalBased();

    return connectors.filter((connector) => {
      if (!isDefined(connector)) {
        return false;
      }

      const dataSource = connector.dataSource as SignalDataSourceDto;

      if (!isDefined(dataSource.signal.id)) {
        console.error("Signal id not defined for data source!");
        return false;
      }

      const parentComponenet = this.componentStateSelector.getComponentByConnectorId(connector.id);

      const connectorPType =
        connector.properties["pType"] ||
        connector.dataSource.aggregationConfig.periodType ||
        (parentComponenet?.dataConnectorQuery.aggregationConfig.periodType ?? "") ||
        runtimeFiterPeriodType;

      const correctPType =
        connectorPType === notificationPType ||
        (connectorPType === "" && notificationPType === "PRI");

      if (!correctPType) {
        return false;
      }

      if (!notificationSignalIds.includes(dataSource.signal.id)) {
        return false;
      }

      return true;
    });
  }

  /**
   * It emits from destroy subject and closes the signalR push connection if opened.
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.stopPushService$().subscribe();
  }
}
