import { Injectable } from "@angular/core";
import { v4 as uuid } from "uuid";
import { isCategory, isTimeSeries } from "../../elements/models/component-type.helper";
import { YAxisDescriptor } from "../../elements/models/y-axis-descriptor";
import { isDefined, isNotDefined } from "../../ts-utils";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class RelocateAxisProperties implements UpgradeStep {
  name = "RelocateAxisProperties";
  fromVersion = new Version(4, 0, 0);

  perform(oldConfig: any): UpgradeStepResult {
    const widgets = getWidgets(oldConfig);
    let changed = false;
    widgets.forEach((widget) => {
      if (hasAxisProperties(widget) && isNotDefined(widget.yAxes)) {
        if (isTimeSeries(widget["type"])) {
          widget["view"]["yAxes"] = [
            {
              id: uuid(),
              axisTitle: widget["view"].yAxisTitle,
              min: widget["view"].min,
              max: widget["view"].max
            } as Partial<YAxisDescriptor>
          ];
          delete widget["view"].min;
          delete widget["view"].max;
          delete widget["view"].yAxisTitle;
          changed = true;
        } else {
          widget["view"]["yAxes"] = [
            {
              id: uuid(),
              axisTitle: widget["view"].yAxisTitle
            } as Partial<YAxisDescriptor>
          ];
          delete widget["view"].yAxisTitle;
          changed = true;
        }
      }
    });
    return {
      result: oldConfig,
      modified: changed,
      warning: null
    };
  }
}

function getWidgets(oldConfig: any): any[] {
  const componentStates: any = oldConfig["componentStates"]["entities"];
  const widgets: any[] = Object.values(componentStates).filter(
    (componentState) => isTimeSeries(componentState["type"]) || isCategory(componentState["type"])
  );
  return widgets;
}

function hasAxisProperties(widget: any): boolean {
  return (
    isDefined(widget["view"]) &&
    (isDefined(widget["view"]["min"]) ||
      isDefined(widget["view"]["max"]) ||
      isDefined(widget["view"]["yAxisTitle"]))
  );
}
