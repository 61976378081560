import { Injectable } from "@angular/core";
import { cloneDeep as _cloneDeep, isEqual as _isEqual } from "lodash";
import { DataConnectorViewDto } from "../../data-connectivity/models/data-connector-view";
import { EnvironmentSelector } from "../../environment";
import { EntityId } from "../../meta";
import { TypeProvider } from "../../meta/services/type-provider";
import { Dictionary, isDefined, isEmptyOrNotDefined } from "../../ts-utils";
import { objectDifference } from "../../ts-utils/helpers/object-difference.helper";
import { DeepPartial } from "../../ts-utils/models/deep-partial.type";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { ComponentStateDto } from "../models";
import { isTabContent } from "../models/component-type.helper";
import { ReportContents } from "../store/state";
import {
  ConfigurationService,
  pruneReportContents,
  shouldSaveConnectorViewProps
} from "./configuration.service";
import { ReportInfoSelector } from "./entity-selectors/report-info.selector";
import { FeatureSelector } from "./feature-selector";
import { deserializeReportContent } from "./report-converter.helper";

@Injectable({
  providedIn: "root"
})
export class UnsavedChangesService {
  public isReadOnly: boolean;

  constructor(
    private configurationService: ConfigurationService,
    protected featureSelector: FeatureSelector,
    private typeProvider: TypeProvider,
    private environmentSelector: EnvironmentSelector,
    private reportInfoSelector: ReportInfoSelector
  ) {}

  public shouldShowPrompt(): boolean {
    return !this.reportInfoSelector.getReportInfo().readOnly && this.isReportStateDirty();
  }

  private isReportStateDirty(): boolean {
    const isReportLoading = this.environmentSelector.getReportLoadingState();
    if (isReportLoading) {
      return false;
    }
    const previousPrunedReportState = this.getSavedReportState();
    const currentPrunedReportState = this.getActiveReportState();

    removePropsThatDontAffectSaving(previousPrunedReportState);
    removePropsThatDontAffectSaving(currentPrunedReportState);

    const isStateDirty = !_isEqual(previousPrunedReportState, currentPrunedReportState);
    if (isStateDirty) {
      logReportStateDifference(currentPrunedReportState, previousPrunedReportState);
    }
    return isStateDirty;
  }

  private getSavedReportState(): ReportContents {
    return _cloneDeep(this.configurationService.savedContentForLoadedReport);
  }

  private getActiveReportState(): DeepPartial<ReportContents> {
    const reportContents: ReportContents = this.featureSelector.getReportState();
    const typedReportContents = deserializeReportContent(reportContents);
    const currentPrunedReportState = pruneReportContents(typedReportContents, this.typeProvider);
    return _cloneDeep(currentPrunedReportState);
  }
}

/** used to check what caused prompt for unsaved changes to appear */
function logReportStateDifference(
  currentPrunedReportState: DeepPartial<ReportContents>,
  previousPrunedReportState: ReportContents
): void {
  console.log(
    "Difference active-saved: ",
    objectDifference(currentPrunedReportState, previousPrunedReportState)
  );
  console.log(
    "Difference saved-active: ",
    objectDifference(previousPrunedReportState, currentPrunedReportState)
  );
}

function removePropsThatDontAffectSaving(prunedReportState: DeepPartial<ReportContents>): void {
  if (prunedReportState && isDefined(prunedReportState.componentsCounter)) {
    delete prunedReportState.componentsCounter;
  }
  removeTabConfigCssProps(prunedReportState);
  removeEmptyViewsFromDynamicConnectors(prunedReportState);
}

function removeTabConfigCssProps(prunedReportState: DeepPartial<ReportContents>): void {
  if (
    isDefined(prunedReportState) &&
    isDefined(prunedReportState.componentStates) &&
    isDefined(prunedReportState.componentStates.entities)
  ) {
    Object.keys(prunedReportState.componentStates.entities).forEach((componentId: string) => {
      const component: Maybe<DeepPartial<ComponentStateDto>> =
        prunedReportState.componentStates.entities[componentId];
      if (shouldRemovePropsFromComponentsInsideTabContent(component)) {
        removeCssProps(component?.childrenIds, prunedReportState?.componentStates?.entities);
      }
    });
  }
}

function shouldRemovePropsFromComponentsInsideTabContent(
  component: DeepPartial<Maybe<ComponentStateDto>>
): boolean {
  return (
    isDefined(component) &&
    isDefined(component.type) &&
    isTabContent(component.type) &&
    !isEmptyOrNotDefined(component.childrenIds)
  );
}

function removeCssProps(
  tabContentChildren: EntityId[],
  componentStates: DeepPartial<Dictionary<ComponentStateDto>>
): void {
  {
    tabContentChildren.forEach((componentId) => {
      delete componentStates[componentId]?.view?.css?.left;
      delete componentStates[componentId]?.view?.css?.top;
      delete componentStates[componentId]?.view?.css?.position;
    });
  }
}

function removeEmptyViewsFromDynamicConnectors(
  prunedReportState: DeepPartial<ReportContents>
): void {
  if (
    isDefined(prunedReportState) &&
    isDefined(prunedReportState.dataConnectorViews) &&
    isDefined(prunedReportState.dataConnectorViews.entities)
  ) {
    prunedReportState.dataConnectorViews = Object.values(
      prunedReportState.dataConnectorViews.entities
    ).reduce(
      (
        acc: Dictionary<DeepPartial<DataConnectorViewDto>>,
        connectorView: DeepPartial<DataConnectorViewDto>
      ) => {
        if (isDefined(connectorView) && isDefined(connectorView.title)) {
          delete connectorView.title;
        }

        if (shouldSaveConnectorViewProps(connectorView as DataConnectorViewDto)) {
          acc[connectorView.id] = connectorView;
        }
        return acc;
      },
      {}
    );
  }
}
