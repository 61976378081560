import { AlignValue, Options, YAxisLabelsOptions } from "highcharts";
import { Maybe, first } from "../../../ts-utils";
import { tryConvertToNumber } from "../../../ts-utils/helpers/number.helper";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { getLimitOrPrimaryColor, getTextColorForNoDataStatus } from "../../helpers/color.helper";
import { getSingleValueFromConnector } from "../../helpers/component-data-accessor.helper";
import { DataStatus } from "../../models";
import { YAxisLabelsAlignment } from "../../models/alignment/vertical-gauge-labels-alignment";
import { IVerticalGaugeDisplayConfig } from "../../models/i-view-config/i-gauge-display-config";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import { PRIMARY_Y_AXIS_ID, mergeChartOptions } from "./base-highcharts-options.helper";
import { LinearGaugeDisplayService } from "./linear-gauge-display.service";

export class VerticalGaugeDisplayService extends LinearGaugeDisplayService {
  constructor() {
    super();
    this.chartType = "column";
  }

  protected getSpecificGaugeOptions(
    viewConfig: IVerticalGaugeDisplayConfig,
    dataConnectorDescriptors: Maybe<DataConnectorDescriptor>[],
    dataStatus: DataStatus
  ): Options {
    const value = getSingleValueFromConnector(first(dataConnectorDescriptors)?.connector);
    const min = tryConvertToNumber(viewConfig.min);
    const max = tryConvertToNumber(viewConfig.max);
    const normalizedValue = isDefined(min) ? value - min : value;

    const verticalOptions: Options = {
      chart: {
        spacingLeft: viewConfig.showLimitValues && viewConfig.showLimitsAsBars ? 30 : undefined
      },
      plotOptions: {
        column: {
          pointPadding: 0.1,
          threshold: min
        }
      },
      title: {
        align: "center"
      },
      yAxis: [
        {
          id: PRIMARY_Y_AXIS_ID,
          plotBands: [
            {
              id: PRIMARY_Y_AXIS_ID,
              label: {
                style: {
                  color: getTextColorForNoDataStatus(dataStatus, viewConfig.foregroundColor)
                }
              }
            }
          ]
        }
      ],
      series: [
        {
          type: undefined,
          data: isDefined(max) ? [max - value] : [value],
          enableMouseTracking: false,
          color: viewConfig.neutralColor || "#F0F0F0" // upper part
        },
        {
          type: undefined,
          data: [normalizedValue],
          color: getLimitOrPrimaryColor(normalizedValue, viewConfig.limits, viewConfig.primaryColor)
        }
      ]
    };
    const linearGaugeOptions = super.getSpecificGaugeOptions(
      viewConfig,
      dataConnectorDescriptors,
      dataStatus
    );
    return mergeChartOptions(linearGaugeOptions, verticalOptions);
  }

  protected getYOffset(): number {
    return 5;
  }

  protected getTextAlignStyle(): AlignValue {
    return "center";
  }

  protected getPlotLineLabel(viewConfig: IVerticalGaugeDisplayConfig, value: number) {
    const { showLimitValues, showLimitsAsBars } = viewConfig;
    if (!showLimitValues) {
      return {};
    }
    const x = showLimitValues && showLimitsAsBars ? -10 : 10;
    return { text: value, textAlign: "center", x, y: -1 };
  }

  protected getYAxisLabelOptions(viewConfig: IVerticalGaugeDisplayConfig): YAxisLabelsOptions {
    const { widthInPx } = viewConfig.runtimeView.runtimeSize;
    const xScalingOffset: number = widthInPx * 0.1;
    const options = super.getYAxisLabelOptions(viewConfig);
    return {
      ...options,
      x:
        viewConfig.yAxisLabelsAlignment === YAxisLabelsAlignment.Right
          ? -xScalingOffset
          : xScalingOffset
    };
  }

  protected getYAxisLabelAlignment(viewConfig: IVerticalGaugeDisplayConfig): boolean {
    return viewConfig.yAxisLabelsAlignment === YAxisLabelsAlignment.Right;
  }
}
