import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import {
  DataColumnType,
  DataConnectorDto,
  DataTableResponse,
  Dictionary,
  Filter,
  GenericDataSourceDescriptor,
  GenericDataSourceDto,
  HttpService,
  QueryParamsResolverService,
  WebServicesConfiguration
} from "ui-core";
import { EMPTY_COMBO_BOX_ENTRY } from "../../../../../ui-core/src/lib/meta/helpers/enum-combobox.helper";
import { ApiResponse } from "../../models/api/api-response";
import { QueryDto } from "../../models/api/query";
import { AzureQueryStringService } from "./azure-query-string.service";
import { GenericQueriesStrategy } from "./generic-query-strategies/generic-queries-strategy";
import { PlantTimeConverter } from "./plant-time.converter";

@Injectable()
export class GenericQueriesService {
  constructor(
    private queryStringService: AzureQueryStringService,
    private httpService: HttpService,
    private apiConfig: WebServicesConfiguration,
    private timeConverter: PlantTimeConverter,
    private queryParamsResolver: QueryParamsResolverService
  ) {}

  getData(
    filters: Dictionary<Filter>,
    genericQueries: Dictionary<GenericDataSourceDto>,
    queryType: string,
    mapDataTablesToConnectors: (
      response: DataTableResponse,
      queries: Dictionary<GenericDataSourceDto>
    ) => Dictionary<DataConnectorDto[]>
  ): Observable<Dictionary<DataConnectorDto[]>> {
    const strategy = this.chooseProcessingStrategy(queryType);
    var request = strategy.prepareRequest(filters, genericQueries);

    return this.sendRequest(request, queryType).pipe(
      map((response: ApiResponse[]) => strategy.processResponse(response, genericQueries)),
      map((response: DataTableResponse) => mapDataTablesToConnectors(response, genericQueries)),
      map((dataConnectors: Dictionary<DataConnectorDto[]>) => {
        return strategy.filterConnectorsFromGenericQuery(dataConnectors, genericQueries);
      })
    );
  }
  chooseProcessingStrategy(queryType: string): GenericQueriesStrategy {
    switch (queryType) {
      default:
        return new GenericQueriesStrategy(
          this.queryStringService,
          this.timeConverter,
          this.queryParamsResolver
        );
    }
  }

  sendRequest(query: QueryDto, queryType: string): Observable<ApiResponse[]> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    const msalToken = localStorage.getItem("msal.idtoken") || "";
    headers.append("Authorization", "Bearer " + msalToken);
    const options = { headers };

    return this.httpService.post({
      url: `${this.apiConfig.logDataServiceUrl}/ServingFunction${queryType}`,
      body: query,
      failureDefault: [],
      options
    });
  }

  static getGenericDataSourceDescriptors(): Observable<GenericDataSourceDescriptor[]> {
    const genericDataSourceDescriptors: GenericDataSourceDescriptor[] = [
      {
        entity: EMPTY_COMBO_BOX_ENTRY,
        columns: [],
        isValid: true
      },
      {
        entity: "/Events",
        columns: [
          { name: "Timestamp", type: DataColumnType.Date },
          { name: "Message", type: DataColumnType.String },
          { name: "Source", type: DataColumnType.String },
          { name: "Category", type: DataColumnType.String },
          { name: "CategoryIcon", type: DataColumnType.Unknown },
          { name: "Enabled", type: DataColumnType.Number },
          { name: "EnabledIcon", type: DataColumnType.Unknown },
          { name: "Active", type: DataColumnType.String },
          { name: "ActiveIcon", type: DataColumnType.Unknown },
          { name: "Ack", type: DataColumnType.String },
          { name: "AckIcon", type: DataColumnType.Unknown },
          { name: "EquipmentPath", type: DataColumnType.String },
          { name: "Subsystem", type: DataColumnType.String },
          { name: "CategoryNorm", type: DataColumnType.String },
          { name: "Controller", type: DataColumnType.String },
          { name: "Severity", type: DataColumnType.Number },
          { name: "EventId", type: DataColumnType.String },
          { name: "SeverityGroup", type: DataColumnType.String },
          { name: "ConditionClassName", type: DataColumnType.String },
          { name: "GroupNumber", type: DataColumnType.Number },
          { name: "FailureReason", type: DataColumnType.Unknown },
          { name: "CorrectiveActionsAsList", type: DataColumnType.Unknown },
          { name: "EffectsAsList", type: DataColumnType.Unknown },
          { name: "PossibleCausesAsList", type: DataColumnType.Unknown },
          { name: "RemarksAsList", type: DataColumnType.Unknown }
        ],
        isValid: true
      },
      {
        entity: "/Notifications",
        columns: [
          { name: "Timestamp", type: DataColumnType.Date },
          { name: "Message", type: DataColumnType.String },
          { name: "Description", type: DataColumnType.String },
          { name: "Recommendations", type: DataColumnType.String },
          { name: "AlarmId", type: DataColumnType.String },
          { name: "EquipmentPath", type: DataColumnType.String },
          { name: "Signal", type: DataColumnType.String },
          { name: "Type", type: DataColumnType.Number },
          { name: "Acknowledged", type: DataColumnType.String }
        ],
        isValid: true
      }
    ];

    return of(genericDataSourceDescriptors);
  }
}
