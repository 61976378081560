import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { WebServicesConfiguration } from "../../../core/services/api.config";
import { DataService } from "../../../data-connectivity/services/data.service";
import { LocalizationService } from "../../../i18n/localization.service";
import { TypeProvider } from "../../../meta";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { BaseEditorComponent } from "../base-editor.component";

@Component({
  selector: "file-selection-editor",
  templateUrl: "file-selection-editor.component.html",
  styleUrls: ["./file-selection-editor.component.scss"]
})
export class FileSelectionEditorComponent extends BaseEditorComponent implements OnInit, OnDestroy {
  @ViewChild("fileInput") fileInput: ElementRef<HTMLInputElement>;
  protected unsubscribeSubject$: Subject<any> = new Subject<any>();
  displayValue = new FormControl<string>("");

  constructor(
    protected cdr: ChangeDetectorRef,
    protected webConfigService: WebServicesConfiguration,
    protected dataService: DataService,
    protected typeProvider: TypeProvider,
    protected translationService: LocalizationService
  ) {
    super(cdr, typeProvider, translationService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setDisplayValue(this.value);
    this.subscribeOnValueChange();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  refreshValue(value): void {
    super.refreshValue(value);
    this.editorInput.nativeElement.value = value ?? "";
  }

  private resetFileInput(): void {
    /** File input needs to be reset so the 'change' event can be triggered again */
    this.fileInput.nativeElement.value = "";
  }

  protected subscribeOnValueChange(): void {
    this.displayValue.valueChanges
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((newValue) => {
        this.onValueChanged(newValue);
      });
  }

  onFileInput(event: any): void {
    const files: FileList = event.target.files;
    if (isDefined(files) && files.length > 0) {
      const fileData: File = files[0];
      if (!this.allowedFileType(fileData.type)) {
        return;
      }
      this.processFile(fileData);
    }
    this.resetFileInput();
  }

  onXClick(): void {
    this.setDisplayValue("");
  }

  protected setDisplayValue(newValue: string): void {
    this.displayValue.setValue(newValue);
  }

  protected allowedFileType(fileType: string): boolean {
    throw new Error("Not implemnted");
  }

  protected processFile(file: File): void {
    throw new Error("Not implemnted");
  }
}
