import { PAGE_VIRTUAL_GRID_GAP, PAGE_VIRTUAL_GRID_SIDE } from "../models";

export function getSvgBackgroundImage(dottedBackground: boolean): string {
  return dottedBackground ? dottedImage : gridImage;
}
const gridImage = getSvgGridImage();
const dottedImage = getSvgDottedImage();

function getSvgDottedImage(): string {
  const rectSide = 3;
  const fillOpacity: number = 0.3;
  const fillColor: string = "#555";
  const imageSide: number = 30;
  const rectOffset = imageSide / 2;
  const rawSvg: string = `
  <svg xmlns="http://www.w3.org/2000/svg" width="${imageSide}" height="${imageSide}" >
        <g>
          <rect
            width="${rectSide}"
            height="${rectSide}"
             x="${rectOffset}"
            y="${rectOffset}"
            fill="${fillColor}"
            fill-opacity="${fillOpacity}"
            shape-rendering="crispEdges"
          />
        </g>
      </svg>
    `;
  const encodedSvg: string = btoa(rawSvg.trim());
  return `url('data:image/svg+xml;base64,${encodedSvg}')`;
}

function getSvgGridImage(): string {
  const rectSideCorrection = 2;
  const rectSide = PAGE_VIRTUAL_GRID_SIDE - rectSideCorrection;
  const rectOffset = 1;
  const strokeWidth: number = 1;
  const strokeOpacity: number = 0.2;
  const strokeColor: string = "#555";
  const fillOpacity: number = 0.1;
  const fillColor: string = "#e2e2e2";
  const imageSide: number = PAGE_VIRTUAL_GRID_SIDE + PAGE_VIRTUAL_GRID_GAP;
  const rawSvg: string = `
  <svg xmlns="http://www.w3.org/2000/svg" width="${imageSide}" height="${imageSide}" >
        <g>
          <rect
            width="${rectSide}"
            height="${rectSide}"
            x="${rectOffset}"
            y="${rectOffset}"
            stroke="${strokeColor}"
            stroke-width="${strokeWidth}"
            stroke-opacity="${strokeOpacity}"
            fill="${fillColor}"
            fill-opacity="${fillOpacity}"
            shape-rendering="crispEdges"
          />
        </g>
      </svg>
    `;
  const encodedSvg: string = btoa(rawSvg.trim());
  return `url('data:image/svg+xml;base64,${encodedSvg}')`;
}
