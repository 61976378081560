import { Equipment } from "../../core/models/equipment";
import { EquipmentProperty } from "../../core/models/equipment-property";
import { EntityId } from "../../meta/models/entity";
import { Maybe } from "../../ts-utils/models/maybe.type";

export interface SignalBrowserState {
  searchPattern: Maybe<string>;
  lastSelectedSignal: Maybe<EntityId>;
}

export const initialSignalBrowserState: SignalBrowserState = {
  searchPattern: null,
  lastSelectedSignal: null
};

export interface EquipmentBrowserState {
  equipment: Maybe<Equipment>;
  isPropertyBrowserExpanded: boolean;
  treeModel: Maybe<Equipment[]>;
  lastSelectedProperty: Maybe<EquipmentProperty>;
}

export const initialEquipmentBrowserState: EquipmentBrowserState = {
  equipment: null,
  isPropertyBrowserExpanded: false,
  treeModel: null,
  lastSelectedProperty: null
};
