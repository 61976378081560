import { Injectable } from "@angular/core";
import { ComponentCssSize } from "../../elements";
import {
  FULL_HEIGHT_CSS_SIZE,
  FULL_WIDTH_CSS_SIZE
} from "../../elements/models/component-size.constants";
import { isCard, isPage } from "../../elements/models/component-type.helper";
import { isDefined } from "../../ts-utils";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

const OLD_DEFAULT_BLOCK_SIZE_IN_PX: number = 25;
const FULL_WIDTH_SPAN_SIZE: number = -1;
const FULL_HEIGHT_SPAN_SIZE: number = -1;
@Injectable()
export class UpgradeWidgetSize implements UpgradeStep {
  name = "UpgradeWidgetSize";
  fromVersion = new Version(3, 0, 1);

  perform(oldConfig: any): UpgradeStepResult {
    const widgets = getWidgets(oldConfig);
    let changed = false;
    widgets.forEach((widget) => {
      const view = widget["view"];
      if (isDefined(view)) {
        const widgetSize = view["size"];
        if (isDefined(widgetSize)) {
          view["size"] = upgradeToCssSize(widgetSize.columns, widgetSize.rows);
          changed = true;
        }
      }
    });
    return {
      result: oldConfig,
      modified: changed,
      warning: null
    };
  }
}

function getWidgets(oldConfig: any): any[] {
  const componentStates: any = oldConfig["componentStates"]["entities"];
  const widgets: any[] = Object.values(componentStates).filter(
    (componentState) => !isCard(componentState["type"]) && !isPage(componentState["type"])
  );
  return widgets;
}

function upgradeToCssSize(columns: number, rows: number): Partial<ComponentCssSize> {
  return {
    width: convertToCssWidth(columns),
    height: convertToCssHeight(rows)
  };
}

function convertToCssWidth(columns: number): string {
  if (columns === FULL_WIDTH_SPAN_SIZE) {
    return FULL_WIDTH_CSS_SIZE;
  }
  return (columns * OLD_DEFAULT_BLOCK_SIZE_IN_PX).toString();
}

function convertToCssHeight(rows: number): string {
  if (rows === FULL_HEIGHT_SPAN_SIZE) {
    return FULL_HEIGHT_CSS_SIZE;
  }
  return (rows * OLD_DEFAULT_BLOCK_SIZE_IN_PX).toString();
}
