export class FilterComponentLocalizationDictionary {
  From: string = "From";
  To: string = "EndDate";
  PeriodType: string = "PeriodType";
  LiveModeOn: string = "LiveModeOn";
  Custom: string = "Custom";
  Last: string = "Last";
  DateTimePicker: string = "DateTimePicker";
  Apply: string = "Apply";
  location: string = "location";
  material: string = "material";
  array: string = "array";

  constructor() {
    Object.keys(this).forEach((key) => {
      const thisKey = key as keyof FilterComponentLocalizationDictionary;
      this[thisKey] = `FilterComponent.${thisKey}`;
    });
  }
}
