export class MockComposite<T> {
  link: any;

  children: MockComposite<T>[];
  constructor(public name: T) {
    this.children = [];
  }

  addChild(child: T): MockComposite<T> {
    const newLength = this.children.push(new MockComposite<T>(child));
    return this.children[newLength - 1];
  }
}
