import { Injectable, Injector } from "@angular/core";
import { GlobalErrorHandler } from "../../../../../ui-core/src/lib/core/services/global-error-handler";
import { TelemetryService } from "./telemetry.service";

@Injectable()
export class RdsErrorHandler extends GlobalErrorHandler {
  constructor(injector: Injector, private telemetryService: TelemetryService) {
    super(injector);
  }

  handleError(error: Error): void {
    super.handleError(error);
    this.telemetryService.logException(error);
  }
}
