import { createAction, props } from "@ngrx/store";
import { Maybe } from "../../../ts-utils";

export namespace TimeInfoActions {
  export const updateCurrentTime = createAction(
    "[Environment] Update Current Time",
    props<{ now: Date }>()
  );

  export const getServerTime = createAction("[Environment] Get Server Time");

  export const updateTimeOffset = createAction(
    "[Environment] Update Time Offset",
    props<{ newOffset: number; previousOffset: Maybe<number> }>()
  );
}
