import { isEmpty, isEmptyOrNotDefined, isNotDefined, Maybe } from "../../ts-utils";

export function formatEquipmentPropertyPath(
  equipmentName: Maybe<string>,
  propertyName: Maybe<string>,
  signalName: string
): string {
  if (isEmptyOrNotDefined(equipmentName)) {
    if (isNotDefined(propertyName) || isEmpty(propertyName)) {
      return signalName;
    }
    return propertyName;
  }

  const propertyPart = propertyName ? "[" + propertyName + "]" : "";
  return equipmentName + propertyPart;
}
