<ng-container *ngIf="!isCollapsed; else asDropdown">
  <div class="copy-paste-cut__container">
    <header-button [buttonConfig]="cutButtonConfig" icon="Cut"></header-button>
    <header-button [buttonConfig]="copyButtonConfig" icon="Copy"></header-button>
    <header-button [buttonConfig]="pasteButtonConfig" icon="Paste"></header-button>
  </div>
</ng-container>

<ng-template #asDropdown> 
  <div (clickOutside)="closeMenu()">
    <header-button [buttonConfig]="dropdownButtonConfig" [isSelected]="isMenuOpened" icon="Copy">
    </header-button>
    <ul *ngIf="isMenuOpened" class="copy-paste-cut__menu">
      <header-button [buttonConfig]="cutButtonConfig" icon="Cut" [verticallyOpened]="true" [showLabel]="true">
      </header-button>
      <header-button [buttonConfig]="copyButtonConfig" icon="Copy" [verticallyOpened]="true" [showLabel]="true">
      </header-button>
      <header-button [buttonConfig]="pasteButtonConfig" icon="Paste" [verticallyOpened]="true" [showLabel]="true">
      </header-button>
    </ul>
  </div>
</ng-template>