import { isDefined } from "../../../lib/ts-utils/helpers";

export interface EnableGroups {
  enableGroups: boolean;
}

export function areGroupsEnabled(object: any): object is EnableGroups {
  if (isDefined(object.view)) {
    return (
      object.view.enableGroups &&
      typeof object.view.enableGroups !== "undefined" &&
      isDefined(object.view.enableGroups)
    );
  } else {
    return false;
  }
}
