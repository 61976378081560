import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { distinctUntilChanged, map, take } from "rxjs/operators";
import { isGrouped, isTabular } from "../../data-connectivity/helpers/data-source-type.helper";
import { DataConnectorDto } from "../../data-connectivity/models";
import { DataConnectorViewModel } from "../../data-connectivity/models/data-connector.vm";
import { DataSourceDto } from "../../data-connectivity/models/data-source/data-source";
import { GenericDataSourceDto } from "../../data-connectivity/models/data-source/generic-data-source";
import { getGroupedDataSourceColumns } from "../../data-connectivity/models/data-source/grouped-data-source";
import { getTabularSourceColumns } from "../../data-connectivity/models/data-source/tabular-data-source";
import { IGenericDataSourceService } from "../../data-connectivity/services/i-generic-data-source.service";
import { LocalizationService } from "../../i18n/localization.service";
import { SelectionOption } from "../../meta";
import { ComponentStateDto } from "../models";
import { ComponentStateViewModel } from "../models/component-state.vm";
import { selectComponentStateById } from "../store";

@Injectable()
export class GenericDataSourceService extends IGenericDataSourceService {
  constructor(private store: Store<any>, private localizationService: LocalizationService) {
    super();
  }

  public getColumns(target: any): SelectionOption[] {
    if (target instanceof ComponentStateViewModel) {
      const componentState = target as ComponentStateViewModel;
      const dataSource = componentState.dataConnectorQuery as DataSourceDto;
      if (!dataSource) {
        return [];
      }
      const genericDataSource = dataSource as GenericDataSourceDto;
      if (isGrouped(genericDataSource)) {
        return getGroupedDataSourceColumns();
      } else if (isTabular(genericDataSource)) {
        return getTabularSourceColumns(this.store, genericDataSource, this.localizationService);
      }
    }
    return [];
  }

  public getSelectedEntity(component: any): string {
    if (!(component instanceof ComponentStateViewModel)) {
      throw new Error("Invalid component");
    }
    const componentStateVM: ComponentStateViewModel = component as ComponentStateViewModel;
    let selectedEntityName: string;
    this.store
      .pipe(
        select(selectComponentStateById(componentStateVM.id)), // FIXME: should not know about 'elements' module
        map((state: ComponentStateDto) => {
          const genericDS = state.dataConnectorQuery as GenericDataSourceDto;
          return genericDS.entity;
        }),
        distinctUntilChanged(),
        take(1)
      )
      .subscribe((entity) => {
        selectedEntityName = entity;
      });
    return selectedEntityName;
  }

  //FIXME rename,or split into two functions (is used in data-source-enum.helper)
  public isDataConnector(target: any): boolean {
    return target instanceof DataConnectorDto || target instanceof DataConnectorViewModel;
  }
}
