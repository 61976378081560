export type DataSourceType =
  | "DataSourceDto"
  | SignalDataSourceType
  | ApiDataSourceType
  | GenericDataSourceType
  | ValueDataSourceType
  | EmptyDataSourceType;
export type SignalDataSourceType = "SignalDataSourceDto" | EquipmentDataSourceType;
export type EquipmentDataSourceType = "EquipmentDataSourceDto";
export type ApiDataSourceType = "ApiDataSourceDto";
export type GenericDataSourceType =
  | "GenericDataSourceDto"
  | GroupedDataSourceType
  | TabularDataSourceType
  | EmptyDataSourceType;
export type GroupedDataSourceType = "GroupedDataSourceDto";
export type TabularDataSourceType = "TabularDataSourceDto";
export type EmptyDataSourceType = "EmptyDataSourceDto";
export type ValueDataSourceType = "ValueDataSourceDto";

export const API_DATA_SOURCE = "ApiDataSourceDto";
export const DATA_SOURCE = "DataSourceDto";
export const EMPTY_DATA_SOURCE = "EmptyDataSourceDto";
export const EQUIPMENT_DATA_SOURCE = "EquipmentDataSourceDto";
export const GENERIC_DATA_SOURCE = "GenericDataSourceDto";
export const GROUPED_DATA_SOURCE = "GroupedDataSourceDto";
export const SIGNAL_DATA_SOURCE = "SignalDataSourceDto";
export const TABULAR_DATA_SOURCE = "TabularDataSourceDto";
export const VALUE_DATA_SOURCE = "ValueDataSourceDto";
