import { Component, Input, OnDestroy, OnInit, Type } from "@angular/core";
import { fromEvent, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { Dispatcher } from "../../../dispatcher";
import { BaseComponent } from "../../../elements/components/base/base.component";
import { ContainerComponent } from "../../../elements/components/container/container.component";
import { Clipboard } from "../../../elements/models/clipboard";
import { ComponentStateDto } from "../../../elements/models/component-state";
import {
  ClipboardService,
  getPasteContainerId
} from "../../../elements/services/clipboard.service";
import {
  ComponentSelectionService,
  findSelectableParent
} from "../../../elements/services/component-selection.service";
import { ComponentStateSelector } from "../../../elements/services/entity-selectors/component-state.selector";
import { ComponentStateActions } from "../../../elements/store/component-state/component-state.actions";
import { LocalizationService } from "../../../i18n/localization.service";
import { C_KEY, V_KEY, X_KEY } from "../../../keyboard.constants";
import { EntityId, TypeProvider } from "../../../meta";
import { createUpdatedComponentsInfo } from "../../../meta/helpers/updated-entities-info.helper";
import { isEmptyOrNotDefined } from "../../../ts-utils/helpers/is-empty.helper";
import { isDefined, isNotDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { isInputElement } from "../../helpers/html-element-type.helper";
import { HeaderButtonConfig } from "../../models/button/header-button.config";

@Component({
  selector: "copy-paste-cut",
  templateUrl: "copy-paste-cut.component.html",
  styleUrls: ["./copy-paste-cut.component.scss"]
})
export class CopyPasteCutComponent implements OnInit, OnDestroy {
  copyButtonConfig!: HeaderButtonConfig;
  pasteButtonConfig!: HeaderButtonConfig;
  cutButtonConfig!: HeaderButtonConfig;
  dropdownButtonConfig!: HeaderButtonConfig;
  isMenuOpened: boolean = false;
  private unsubscribeSubject$: Subject<any> = new Subject();
  @Input() isCollapsed: boolean = false;

  constructor(
    private localizer: LocalizationService,
    private componentSelectionService: ComponentSelectionService,
    private dispatcher: Dispatcher,
    private clipboardService: ClipboardService,
    private componentStateSelector: ComponentStateSelector,
    private typeProvider: TypeProvider
  ) {}

  ngOnInit(): void {
    this.copyButtonConfig = this.createCopyButtonConfig();
    this.pasteButtonConfig = this.createPasteButtonConfig();
    this.cutButtonConfig = this.createCutButtonConfig();
    this.dropdownButtonConfig = this.createDropDownButtonConfig();
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  private initSubscriptions(): void {
    this.subscribeToSelectedComponents();
    this.subscribeToClipboardData();
    this.subscribeToKeyboardEvent();
  }

  private subscribeToSelectedComponents(): void {
    this.componentSelectionService.selectedComponentsChanged
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe(() => {
        const isSelectionEmpty = this.componentSelectionService.isSelectionEmpty;
        this.copyButtonConfig.isDisabled = isSelectionEmpty;
        this.cutButtonConfig.isDisabled = isSelectionEmpty;
        this.disableOrEnablePasteButton();
      });
  }

  private subscribeToClipboardData(): void {
    this.clipboardService.clipboardDataChanged$
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe(() => {
        this.disableOrEnablePasteButton();
      });
  }

  private disableOrEnablePasteButton(): void {
    this.pasteButtonConfig.isDisabled =
      isNotDefined(this.clipboardService.clipboardData) ||
      (!this.clipboardService.isBasicCardInClipboard() &&
        this.componentSelectionService.isSelectionEmpty);
  }

  private subscribeToKeyboardEvent(): void {
    fromEvent<KeyboardEvent>(document, "keydown")
      .pipe(
        filter((event) => this.isShortcutPressed(event.ctrlKey, event.key.toLowerCase())),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe((event) => {
        const key: string = event.key.toLowerCase();
        if (key === X_KEY && !this.cutButtonConfig.isDisabled) {
          this.handleCut();
        } else if (key === C_KEY && !this.copyButtonConfig.isDisabled) {
          this.handleCopy();
        } else if (key === V_KEY && !this.pasteButtonConfig.isDisabled) {
          this.handlePaste();
        }
      });
  }

  private isShortcutPressed(isCtrlKeyPressed: boolean, key: string): boolean {
    return (
      isCtrlKeyPressed &&
      (key === X_KEY || key === C_KEY || key === V_KEY) &&
      !isInputElement(document.activeElement) &&
      isEmptyOrNotDefined(window.getSelection()?.toString())
    );
  }

  private createCopyButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.localizer.buttons.Copy,
      isDisabled: true,
      clickFunction: () => {
        return this.handleCopy();
      }
    });
  }

  private createCutButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.localizer.buttons.Cut,
      isDisabled: true,
      clickFunction: () => {
        return this.handleCut();
      }
    });
  }

  private createPasteButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.localizer.buttons.Paste,
      isDisabled: true,
      clickFunction: () => {
        return this.handlePaste();
      }
    });
  }

  private createDropDownButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.localizer.buttons.Clipboard,
      hasDropdown: true,
      clickFunction: () => this.showOrHideMenu()
    });
  }

  handleCopy(): void {
    this.dispatcher.dispatch(
      ComponentStateActions.copyComponents({
        componentIds: this.componentSelectionService.selectedComponentsIds
      })
    );
  }

  handleCut(): void {
    this.dispatcher.dispatch(
      ComponentStateActions.cutComponents({
        componentIds: this.componentSelectionService.selectedComponentsIds
      }),
      {
        withSnapshot: true,
        updatedEntitiesInfo: createUpdatedComponentsInfo(
          this.componentSelectionService.selectedComponentsIds
        )
      }
    );
  }

  handlePaste(): void {
    const clipboardData = this.clipboardService.clipboardData;
    if (isDefined(clipboardData)) {
      const componentFromSelection = this.getSingleSelectedComponent();
      this.dispatchPasteAction(componentFromSelection, clipboardData);
    }
  }

  private dispatchPasteAction(
    singleSelectedComponentState: Maybe<ComponentStateDto>,
    clipboardContent: Clipboard
  ) {
    const areWidgetsPasted = isWidget(clipboardContent.content[0].type, this.typeProvider);
    let pasteIntoId = getPasteContainerId(
      singleSelectedComponentState ?? this.componentStateSelector.getRoot(),
      areWidgetsPasted,
      this.componentStateSelector
    );
    if (isDefined(pasteIntoId)) {
      const pasteTargetId = this.resolvePasteTargetId(
        singleSelectedComponentState,
        areWidgetsPasted
      );
      this.dispatcher.dispatch(
        ComponentStateActions.pasteComponents({
          pasteIntoId,
          areWidgetsPasted,
          pasteTargetId
        }),
        {
          withSnapshot: true,
          updatedEntitiesInfo: createUpdatedComponentsInfo([pasteTargetId])
        }
      );
    }
  }

  private getSingleSelectedComponent(): Maybe<ComponentStateDto> {
    const singleSelectedComponentId: Maybe<EntityId> =
      this.componentSelectionService.selectedComponentsIds[0];
    if (isDefined(singleSelectedComponentId))
      return this.componentStateSelector.getById(singleSelectedComponentId);
  }

  private resolvePasteTargetId(
    singleSelectedComponentState: Maybe<ComponentStateDto>,
    areWidgetsPasted: boolean
  ): Maybe<EntityId> {
    if (
      !areWidgetsPasted &&
      isDefined(singleSelectedComponentState) &&
      isWidget(singleSelectedComponentState.type, this.typeProvider)
    ) {
      return findSelectableParent(singleSelectedComponentState.id, this.componentStateSelector)?.id;
    }
    return singleSelectedComponentState?.id;
  }

  private showOrHideMenu(): void {
    this.isMenuOpened ? this.closeMenu() : this.openMenu();
  }

  closeMenu(): void {
    this.isMenuOpened = false;
  }

  private openMenu(): void {
    this.isMenuOpened = true;
  }
}

function isWidget(componentType: string, typeProvider: TypeProvider): boolean {
  const typeInstance = typeProvider.getType(componentType)
    .constructorFunction as Type<BaseComponent>;
  const isInstanceOfBaseComponent: boolean = typeInstance.prototype instanceof BaseComponent;
  const isContainer: boolean = typeInstance.prototype instanceof ContainerComponent;
  return isInstanceOfBaseComponent && !isContainer;
}
