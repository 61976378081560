import { ChangeDetectorRef, Component, ElementRef, OnInit } from "@angular/core";
import { RDS_LOCALIZATION_DICTIONARY } from "projects/rds/src/assets/i18n/models/rds-localization-dictionary";
import {
  BaseComponent,
  ChartComponent,
  ComponentConstructorParams,
  EditableType,
  LayoutBuilder,
  View,
  WebServicesConfiguration
} from "ui-core";
import { RdsComponentCategory } from "../rds-component-category";
import { GradientChartViewConfig } from "./view-config";

@Component({
  selector: "gradient-chart",
  templateUrl: "./gradient-chart.component.html",
  styleUrls: ["./gradient-chart.component.css"],
  providers: [{ provide: BaseComponent, useExisting: GradientChartComponent }]
})
@LayoutBuilder(
  RdsComponentCategory.RDS,
  "GradientChartComponent",
  "Plugin",
  "abb-icon",
  undefined,
  RDS_LOCALIZATION_DICTIONARY.layoutEditor.GradientChart
)
@EditableType({ fullName: "GradientChartComponent", title: "gradient-chart" })
export class GradientChartComponent extends ChartComponent implements OnInit {
  constructor(
    params: ComponentConstructorParams,
    hostElementRef: ElementRef,
    cdr: ChangeDetectorRef,
    private webConfigService: WebServicesConfiguration
  ) {
    super(params, hostElementRef, cdr);
  }

  public getMaxNumberOfDataConnectors(): number {
    return 100;
  }

  @View(GradientChartViewConfig)
  public get view() {
    return this.currentState.view as GradientChartViewConfig;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  public get imageUrl() {
    const imageIsDefined = this.view && this.view.image;
    return imageIsDefined ? this.webConfigService.imageGetUrl + `/${this.view.image}` : "";
  }

  protected updateDisplay() {
    super.updateDisplay();
    // debugger
  }

  protected updateChartData(): void {}

  protected setChartOptions(): void {
    this.chartOptions = {
      chart: {
        type: "bar",
        height: 100,
        width: 500
      },
      style: {
        bottom: 0
      },
      xAxis: {
        visible: false,
        tickInterval: 20
      },
      title: {
        text: "<pounds head on desk>"
      },
      yAxis: {
        min: 0,
        max: 100,

        labels: {
          overflow: "justify"
        }
      },
      tooltip: {
        valueSuffix: " millions"
      },
      plotOptions: {
        bar: {
          showInLegend: false,
          tooltip: {
            visible: false
          }
        }
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: "Colour",
          data: [100],
          enableMouseTracking: false,

          color: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1
            },
            stops: [
              [0, this.view.lowSeverity],
              [0.5, this.view.mediumSeverity],
              [1, this.view.highSeverity]
            ]
          }
        }
      ]
    };
  }
}
