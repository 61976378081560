export const iconSizeXL = 40;
export const iconSizeL = 30;
export const iconSizeM = 24;
export const iconSizeS = 15;
export const iconSizeXS = 10;

export const fontSize10 = 10;
export const fontSize12 = 12;
export const fontSize14 = 14;
export const fontSize16 = 16;
export const fontSize20 = 20;
export const fontSize24 = 24;
export const fontSize32 = 32;
export const fontSize40 = 40;

export const headlineFontSize = fontSize24;
export const titleFontSize = fontSize20;
export const subcategoryFontSize = fontSize16;
export const subtitleFontSize = fontSize14;
export const sectionTitleFontSize = fontSize14;
export const contentFontSize = fontSize14;
export const subcontentFontSize = fontSize12;
export const inputFontSize = fontSize12;
export const labelFontSize = fontSize12;
export const matCalendarContentSize = fontSize12;

export const cardTitleFontSize = fontSize20;
export const mobileCardTitleFontSize = fontSize16;
export const componentTitleFontSize = fontSize16;
export const tabTitleFontSize = fontSize14;
export const tableFontSize = fontSize14;
