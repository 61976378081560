import { Cardinality } from "../../core/models/cardinality";
import { DecoratorDelegateContext } from "../../core/models/decorator-delegate-context";
import { SelectionOption } from "../../meta/models/selection";
import { Dictionary } from "../../ts-utils";
import { getConnectorIdByViewId } from "../helpers/connector-view-id.helper";

export interface DataConnectorRole {
  name: string;
  cardinality?: Cardinality;
  isDefaultRole?: boolean;
}

export declare type DataConnectorRoles = Dictionary<DataConnectorRole>;

export function getDefaultDataConnectorRole(roles: DataConnectorRoles): DataConnectorRole {
  const roleKeys = Object.keys(roles);
  const defaultRoleKey: string = roleKeys.find((key: string) => roles[key].isDefaultRole);
  const defaultRole = roles[defaultRoleKey];
  return defaultRole;
}

export function getRolesNames(context: DecoratorDelegateContext): SelectionOption[] {
  const connectorId = getConnectorIdByViewId(context.ownerInstance.id);
  const component = context.services.componentStateSelector.getComponentByConnectorId(connectorId);
  const roles = context.services.metadataService.getRoles(component?.type);
  const res: SelectionOption[] = Object.entries(roles).map(([key, value]) => {
    const { name } = value;
    return { key: name, title: name };
  });

  return res;
}
