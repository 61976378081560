import * as Highcharts from "highcharts";
import { DataPoint } from "../../../data-connectivity";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { ITimeSeriesChartDisplayConfig } from "../../models/i-view-config/i-base-display-config";
import { LimitsDto } from "../../models/limits";
import { LimitMarkerHelper, LimitValues } from "../limit.helper";

const INFINITY_TIMESTAMP = 2551441564000;

export function createLimitSeries(
  connectorPoints: DataPoint[],
  viewConfig: ITimeSeriesChartDisplayConfig
): Highcharts.SeriesOptionsType[] {
  const limitArrays: LimitValues = getLimitArrays(connectorPoints, viewConfig);
  const xValues = connectorPoints.map((p) => (p.x as Date).getTime());
  return createAllLimitAreaOptions(limitArrays, xValues, viewConfig.limits);
}

function getLimitArrays(
  connectorPoints: DataPoint[],
  viewConfig: ITimeSeriesChartDisplayConfig
): LimitValues {
  return new LimitMarkerHelper(viewConfig.limits).getLimitArrays(connectorPoints);
}

function createAllLimitAreaOptions(
  limits: LimitValues,
  xValues: number[],
  colors: LimitsDto
): Highcharts.SeriesOptionsType[] {
  return [
    createLimitOption(xValues, null, limits.extremeLow, colors.extremeLowColor, true, "lololo"),
    createLimitOption(
      xValues,
      limits.extremeLow,
      limits.veryLow,
      colors.veryLowColor,
      true,
      "lolo"
    ),
    createLimitOption(
      xValues,
      limits.veryLow || limits.extremeLow,
      limits.low,
      colors.lowColor,
      true,
      "lo"
    ),

    createLimitOption(
      xValues,
      limits.high,
      limits.veryHigh || limits.extremeHigh,
      colors.highColor,
      false,
      "hi"
    ),
    createLimitOption(
      xValues,
      limits.veryHigh,
      limits.extremeHigh,
      colors.veryHighColor,
      false,
      "hihi"
    ),
    createLimitOption(xValues, limits.extremeHigh, null, colors.extremeHighColor, false, "hihihi"),
    createTargetOption(xValues, limits.target, colors.targetColor)
  ].filter(isDefined);
}

function createTargetOption(
  xValues: number[],
  targetValues: number[],
  color: Maybe<string>
): Maybe<Highcharts.SeriesOptionsType> {
  if (targetValues == null) {
    return null;
  }
  const data = xValues.map((t, index) => [t, targetValues[index]]);
  extendXToInfinity(data);
  const lineOptions: Highcharts.SeriesLineOptions = {
    // xAxis: 1, yAxis: 1 are deleted because multiple axis are not allowed, the limit value is now visible on y axis
    type: "line",
    name: "target",
    data: data,
    color: color ?? undefined,
    showInLegend: false,
    enableMouseTracking: false,
    lineWidth: 1,
    marker: { enabled: false },
    zIndex: -1
  };
  return lineOptions;
}

function createLimitOption(
  xValues: number[],
  lower: Maybe<number[]>,
  upper: Maybe<number[]>,
  color: Maybe<string>,
  isLow: boolean,
  name: string
): Maybe<Highcharts.SeriesOptionsType> {
  if (lower == null && upper == null) {
    return null;
  }
  makeLabelsVisible(xValues, lower, upper);
  if (lower != null && upper != null) {
    const data = xValues.map((t, index) => [t, lower[index], upper[index]]);
    extendXToInfinity(data);
    return createLimitSeriesOption("arearange", name, data, color);
  }
  if ((isLow && upper != null) || (!isLow && lower != null)) {
    const yValues = (lower || upper) as number[];
    const data = xValues.map((t, index) => [t, yValues[index]]);
    extendXToInfinity(data);
    const areaOptions = createLimitSeriesOption(
      "area",
      name,
      data,
      color
    ) as Highcharts.SeriesAreaOptions;
    areaOptions.threshold = isLow ? -Infinity : Infinity;
    return areaOptions;
  }
  return null;
}

function makeLabelsVisible(
  xValues: number[],
  lower: Maybe<number[]>,
  upper: Maybe<number[]>
): void {
  if (xValues?.length === 1) {
    xValues.push(xValues[0] + 1);
  }
  if (lower?.length === 1) {
    lower.push(lower[0]);
  }
  if (upper?.length === 1) {
    upper.push(upper[0]);
  }
}

function extendXToInfinity(data: number[][]): void {
  data.unshift([0, ...data[0].slice(1)]);
  data.push([INFINITY_TIMESTAMP, ...data[data.length - 1].slice(1)]);
}

function createLimitSeriesOption(
  type: string,
  name: string,
  data: unknown[],
  color: Maybe<string>
): Highcharts.SeriesOptionsType {
  //Limit value is visible on primary y axis
  return {
    type: type,
    name: name,
    data: data,
    color: color,
    fillOpacity: 1,
    showInLegend: false,
    enableMouseTracking: false,
    lineWidth: 0,
    marker: { enabled: false },
    zIndex: -1
  } as Highcharts.SeriesOptionsType;
}
