import { Injectable } from "@angular/core";
import { REPORT_FILTER_ID, RUNTIME_FILTER_ID } from "../../core/helpers/filter/filter-id.helper";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class ReplaceGlobalIdWithRuntimeIdFilter implements UpgradeStep {
  name = "ReplaceGlobalIdWithRuntimeIdFilter";
  fromVersion = new Version(4, 0, 1);

  perform(oldConfig: any): UpgradeStepResult {
    const filters = getFilters(oldConfig);
    let changed = false;
    filters.forEach((filter) => {
      filter["sourceFilterId"] = RUNTIME_FILTER_ID;
      changed = true;
    });
    return {
      result: oldConfig,
      modified: changed,
      warning: null
    };
  }
}

function getFilters(oldConfig: any): any[] {
  const filters: any = oldConfig["filters"]["entities"];
  const filtersWithGlobalSourceFilterId: any[] = Object.values(filters).filter(
    (filter) => filter["sourceFilterId"] === REPORT_FILTER_ID
  );
  return filtersWithGlobalSourceFilterId;
}
