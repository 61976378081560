export const BASE = "BaseComponent";
export const PAGE = "PageComponent";
export const BASIC_CARD = "BasicCardComponent";
export const TAB_GROUP = "TabGroupComponent";
export const TAB_CONTENT = "TabContentComponent";
export const NAVIGATION_BAR = "NavigationBarComponent";
export const TIME_SERIES = "TimeSeriesComponent";
export const CATEGORY = "CategoryComponent";
export const LABEL = "LabelComponent";
export const SINGLE_VALUE = "SingleValueComponent";
export const STATUS_INDICATOR = "StatusIndicatorComponent";
export const BUTTON = "ButtonComponent";
export const SCATTER_CHART = "ScatterChartComponent";
export const GENERIC_IFRAME = "GenericIframe";
export const WATERFALL_CHART = "WaterfallChartComponent";
