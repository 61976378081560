<div class="signal-browser__container">
  <div class="signal-browser__header">
    <div #searchInput class="browser__search-input">
      <input
        #signalSearch
        focusVisualization
        [focusedElement]="searchInput"
        class="browser__search-input-text"
        placeholder="{{ 'Sidebar.SearchSignals' | translate }}..."
        [value]="searchPattern"
        (keyup)="searchInputChanged$.next($event.target.value)"
        maxlength="100"
      />
      <i class="abb-icon abb-icon--medium signal-browser__header-icon Search"></i>
    </div>
    <i
      class="abb-icon abb-icon--medium signal-browser__header-icon Information_circle_1"
      [class.browser--visible-description]="isSignalDescriptionVisible"
      title="{{
        (isSignalDescriptionVisible
          ? localizer.sidebar.HideSignalsDescription
          : localizer.sidebar.ShowSignalsDescription
        ) | translate
      }}"
      (click)="showOrHideSignalDescription()"
    >
    </i>
  </div>
  <div class="browser__progress-bar-wrapper" *ngIf="showProgressBar">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <div *ngIf="displayTitle" class="signal-browser__title">
    {{ localizer.sidebar.Signals | translate }}
  </div>

  <cdk-virtual-scroll-viewport
    cdk-virtual-scroll-viewport-patch
    itemSize="{{ isSignalDescriptionVisible ? 40 : 30 }}"
    class="signal-browser__list"
  >
    <div
      *cdkVirtualFor="let signal of signalQueryComplete$ | async"
      #signalItem
      class="signal-browser__item"
      draggable="true"
      (dragstart)="dragStart($event, signal)"
      (dragend)="dragEnd()"
      (touchstart)="dragStart($event, signal)"
      (touchmove)="touchMove($event)"
      (touchend)="dropOnTouchDevices($event)"
      (mousedown)="selectSignal(signal)"
      [ngClass]="{
        'signal-browser__item--selected': isSelectedItem(signal),
        'element--hidden': !isSignalTabSelected
      }"
    >
      <span *ngIf="!openInDialog" class="item-drag-dots">
        <i *ngIf="isSelectedItem(signal)" class="dashboard-icon icon-Drag-dots"></i>
      </span>
      <span class="signal-browser__item-info">
        <div>
          {{ getTitleBasedOnAliasMode(signal) }}
        </div>
        <small *ngIf="isSignalDescriptionVisible" class="signal-browser__item-description">
          {{ signal.getDescription() }}
        </small>
      </span>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
