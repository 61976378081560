import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { first, takeUntil } from "rxjs/operators";
import { ReportDescription } from "../../core";
import { SubscribeFunction } from "../../core/models/subscribe-function";
import { BrowsingState } from "../store/browsing.state";
import { CachedReportsActions, State } from "../store/cached-reports";
import { isOpenedReportInState } from "../store/cached-reports/cached-reports.selectors";
import { getCachedFeature } from "../store/feature.selector";
import { ReportIdsActions } from "../store/report-ids/report-ids.actions";
import { areReportIdsInState, getReportIds } from "../store/report-ids/report-ids.selectors";

export interface IReportBrowserSelector {
  subscribe: (
    delegate: SubscribeFunction<State | ReportDescription[]>,
    stateSelector: (x: BrowsingState) => State | ReportDescription[],
    unsubscribeSubject$: Subject<any>
  ) => void;
  subscribeOnSlice: (
    stateSelector: (x: BrowsingState) => State | ReportDescription[],
    delegate: SubscribeFunction<State | ReportDescription[]>,
    unsubscribeSubject$: Subject<any>
  ) => void;

  refreshReportIds: () => void;
  deleteCachedReports: () => void;
  checkIfCachedReport: (reportId: ReportDescription) => void;
  checkIfIdsCached: () => void;
}

@Injectable()
export class ReportBrowserSelector implements IReportBrowserSelector {
  private cachedState$: Observable<BrowsingState>;

  constructor(private store$: Store<any>) {
    this.cachedState$ = this.store$.select(getCachedFeature);
  }

  refreshReportIds(): void {
    this.store$.dispatch(ReportIdsActions.clear());
    this.store$.dispatch(ReportIdsActions.fetchAll());
  }

  deleteCachedReports(): void {
    this.store$.dispatch(CachedReportsActions.deleteCachedReports());
  }

  checkIfCachedReport(reportDesc: ReportDescription): void {
    this.store$
      .select(isOpenedReportInState(reportDesc.reportId))
      .pipe(first())
      .subscribe((isReportTreeNodePresent: boolean) => {
        if (isReportTreeNodePresent) {
          this.store$.dispatch(CachedReportsActions.fetchCachedReportComponentState());
        } else {
          this.store$.dispatch(CachedReportsActions.fetchReport({ reportId: reportDesc.reportId }));
        }
      })
      .unsubscribe();
  }

  checkIfIdsCached(): void {
    this.store$
      .select(areReportIdsInState)
      .pipe(first())
      .subscribe((areReportIdsPresent: boolean) => {
        if (areReportIdsPresent) {
          this.store$.dispatch(ReportIdsActions.fetchCached());
        } else {
          this.store$.dispatch(ReportIdsActions.fetchAll());
        }
      })
      .unsubscribe();
  }

  selectAllReports(): Observable<ReportDescription[]> {
    return this.store$.select(getReportIds);
  }

  subscribe(
    delegate: SubscribeFunction<State | ReportDescription[]>,
    stateSelector: (x: BrowsingState) => State | ReportDescription[],
    unsubscribeSubject$: Subject<any>
  ): void {
    this.subscribeOnSlice(stateSelector, delegate, unsubscribeSubject$);
  }

  subscribeOnSlice(
    stateSelector: (x: BrowsingState) => State | ReportDescription[],
    delegate: SubscribeFunction<any>,
    unsubscribeSubject$: Subject<any>
  ): void {
    this.cachedState$
      .pipe(select(stateSelector), takeUntil(unsubscribeSubject$))
      .subscribe(delegate);
  }
}
