import { SizeInPx } from "../models/size-in-px";

const LEGEND_BOX_SCALING_FACTOR = 0.8;
const COMPONENT_WIDTH_FOR_SMALL_LEGEND_BOX = 300;
const COMPONENT_WIDTH_FOR_LARGE_LEGEND_BOX = 500;
const LEGEND_COLUMN_NUMBER_FOR_MEDIUM_LEGEND_BOX = 2;
const LEGEND_COLUMN_NUMBER_FOR_LARGE_LEGEND_BOX = 3;
const LEGEND_DIVIDER_FACTOR = 4;

export function getLegendColumnWidth(runtimeSize: SizeInPx): number {
  const componentWidth: number = runtimeSize.widthInPx;
  const legendBoxWidth: number = componentWidth * LEGEND_BOX_SCALING_FACTOR;

  if (componentWidth < COMPONENT_WIDTH_FOR_SMALL_LEGEND_BOX) {
    return legendBoxWidth;
  } else if (componentWidth > COMPONENT_WIDTH_FOR_LARGE_LEGEND_BOX) {
    return Number((legendBoxWidth / LEGEND_COLUMN_NUMBER_FOR_LARGE_LEGEND_BOX).toFixed(2));
  } else {
    return Number((legendBoxWidth / LEGEND_COLUMN_NUMBER_FOR_MEDIUM_LEGEND_BOX).toFixed(2));
  }
}

export function getLegendBoxHeight(size: SizeInPx): number {
  return Number((size.heightInPx / LEGEND_DIVIDER_FACTOR).toFixed(2));
}
