import { TypeDescriptor } from "../../meta";
import { TypeProvider } from "../../meta/services/type-provider";
import { DeepPartial } from "../../ts-utils";
import { BaseViewConfigDto } from "../models";
import { ViewConfigProvider } from "../services/view-config-provider";

const TYPE_PROVIDER = TypeProvider.getInstance();
const VIEW_CONFIG_PROVIDER = ViewConfigProvider.getInstance();

export function createViewConfig(
  componentType: string,
  partialView: DeepPartial<BaseViewConfigDto> = {}
): BaseViewConfigDto {
  const componentTypeDescriptor: TypeDescriptor = TYPE_PROVIDER.getType(componentType);
  const targetViewConfigType = VIEW_CONFIG_PROVIDER.getNativeType(componentTypeDescriptor);
  const targetView = new targetViewConfigType(partialView);
  return targetView;
}
