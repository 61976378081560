import { DataConnectorViewDto } from "../../../data-connectivity/models/data-connector-view";
import { SERIES_TYPE_SHAPE } from "../../../data-connectivity/models/series-type.strategies";
import { first } from "../../../ts-utils/helpers/array.helper";
import { exhaustiveTypeCheck } from "../../../ts-utils/helpers/exhaustive-type-check.helper";
import { assertIsDefined, isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { isWhiteSpace } from "../../../ts-utils/helpers/string.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { ISeriesBaseConfig } from "../../models/series-base-config";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import { YAxisDescriptor } from "../../models/y-axis-descriptor";

interface Ellipse {
  shape: "ellipse";
  centerX: number;
  centerY: number;
  radiusX: Maybe<number>;
  radiusY: Maybe<number>;
  color: string;
  fillColor: string;
  lineWidth: number;
}

type IShapeConfig = Ellipse;

function renderShapes(shapes: IShapeConfig[], chart: Highcharts.Chart): void {
  shapes.forEach((shape, index) => renderShape(shape, index, chart));
}

function renderShape(shape: IShapeConfig, shapeIndex: number, chart: Highcharts.Chart): void {
  switch (shape.shape) {
    case "ellipse":
      renderEllipse(shape, shapeIndex, chart);
      break;
    default:
      exhaustiveTypeCheck("unknown shape", shape.shape);
  }
}

function renderEllipse(shape: Ellipse, shapeIndex: number, chart: Highcharts.Chart): void {
  const xAxis = chart.xAxis[0];
  const yAxis = chart.yAxis[0];
  const centerX = xAxis.toPixels(shape.centerX, false);
  const centerY = yAxis.toPixels(shape.centerY, false);
  let radiusX = isDefined(shape.radiusX)
    ? xAxis.toPixels(shape.radiusX, false) - xAxis.toPixels(0, false)
    : null;
  let radiusY = isDefined(shape.radiusY)
    ? yAxis.toPixels(0, false) - yAxis.toPixels(shape.radiusY, false)
    : null;

  radiusX = radiusX ?? radiusY;
  radiusY = radiusY ?? radiusX;
  assertIsDefined(radiusX);
  assertIsDefined(radiusY);

  const p0 = [centerX - radiusX, centerY];
  const rotation = 0;
  const largeArgFlag = 1;
  const sweepFlag = 1;
  const pathData: Highcharts.SVGPathArray = [
    ["M", p0[0], p0[1]],
    ["A", radiusX, radiusY, rotation, largeArgFlag, sweepFlag, p0[0], p0[1] + 0.1],
    ["z"]
  ];
  const key = "ellipse" + shapeIndex;
  const oldPath = getExtraChartData(chart, key) as Maybe<Highcharts.SVGElement>;

  if (isDefined(oldPath)) {
    oldPath.animate({ d: pathData });
    setPathAttributes(oldPath, shape);
  } else {
    const path = chart.renderer.path(pathData);
    setPathAttributes(path, shape);
    setExtraChartData(chart, key, path);
    path.add();
  }
}

function setPathAttributes(path: Highcharts.SVGElement, config: IShapeConfig): void {
  const styleAttrs: Highcharts.SVGAttributes = {
    stroke: config.color,
    "stroke-width": config.lineWidth,
    fill: config.fillColor
  };
  path.attr(styleAttrs);
}

function getExtraChartData(chart: Highcharts.Chart, key: string): unknown {
  const old = (chart as any)[key];
  return old;
}

function setExtraChartData(chart: Highcharts.Chart, key: string, data: unknown): void {
  (chart as any)[key] = data;
}

export class ShapeGenerator {
  toShapeConfig(dataConnectorDescriptor: DataConnectorDescriptor): Maybe<IShapeConfig> {
    const connectorView = dataConnectorDescriptor.connectorView;
    if (!isDefined(connectorView)) {
      return null;
    }
    if (connectorView.scatterSeriesConfig.seriesType === SERIES_TYPE_SHAPE) {
      const seriesConfig = this.getSeriesConfig(connectorView);
      const properties = first(dataConnectorDescriptor.connector?.dataPoints)?.properties;
      if (isDefined(properties)) {
        const asConfig: IShapeConfig = { ...properties };
        const configuredColor = connectorView?.color;
        asConfig.color =
          isDefined(configuredColor) && !isWhiteSpace(configuredColor) ? configuredColor : "black";
        asConfig.lineWidth = isDefined(seriesConfig.lineWidth) ? seriesConfig.lineWidth : 1;
        const configuredFillColor = connectorView.scatterSeriesConfig.backgroundColor;
        asConfig.fillColor =
          isDefined(configuredFillColor) && !isWhiteSpace(configuredFillColor)
            ? configuredFillColor
            : "transparent";
        if (asConfig.shape === "ellipse") {
          if (
            isDefined(asConfig.centerX) &&
            isDefined(asConfig.centerY) &&
            (isDefined(asConfig.radiusX) || isDefined(asConfig.radiusY))
          ) {
            return asConfig;
          }
        }
      }
    }
    return null;
  }

  buildRenderFunction(shapes: IShapeConfig[]): Highcharts.ChartRenderCallbackFunction {
    return function (this: Highcharts.Chart, _event: Event) {
      renderShapes(shapes, this);
    };
  }

  public addShapes(
    opt: Highcharts.Options,
    dataConnectorDescriptors: DataConnectorDescriptor[],
    yAxisDesc: YAxisDescriptor[]
  ): void {
    const shapeConfigs = dataConnectorDescriptors
      .map((dcd) => this.toShapeConfig(dcd))
      .filter(isDefined);

    if (shapeConfigs.length > 0) {
      assertIsDefined(opt.chart);
      const events: Highcharts.ChartEventsOptions = opt.chart.events ?? {};
      if (!isDefined(opt.chart.events)) {
        opt.chart.events = events;
      }
      events.render = this.buildRenderFunction(shapeConfigs);
    }
  }

  getSeriesConfig(connectorView: DataConnectorViewDto): ISeriesBaseConfig {
    return connectorView.scatterSeriesConfig;
  }
}
