import { Injectable } from "@angular/core";
import { PositioningType } from "../../elements/models/positioning-type";
import { isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { getBasicCards } from "../helpers/v0.helper";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class AddCardPositioningType implements UpgradeStep {
  name = "AddCardPositioningType";
  fromVersion = new Version(1, 0, 8);

  perform(oldConfig: any): UpgradeStepResult {
    const cards: any[] = getBasicCards(oldConfig);

    let changed = false;
    cards.forEach((card) => {
      // NOTE relative position is default, hence pruned
      if (isDefined(card["view"]["positioningType"])) {
        return;
      }
      let allChildrenAreRelative = true;
      if (!Array.isArray(card["childrenIds"])) {
        card["view"]["positioningType"] = PositioningType.Absolute;
      } else {
        card["childrenIds"].map((childId) => {
          const childComponentState: any = oldConfig["componentStates"]?.["entities"]?.[childId];
          if (isNotDefined(childComponentState)) {
            return;
          }
          if (childComponentState?.["view"]?.["css"]?.["position"] === "absolute") {
            allChildrenAreRelative = false;
          }
        });
        if (allChildrenAreRelative) {
          card["view"]["positioningType"] = PositioningType.Relative;
        } else {
          card["view"]["positioningType"] = PositioningType.Absolute;
        }
      }
      changed = true;
    });

    return {
      result: oldConfig,
      modified: changed,
      warning: null
    };
  }
}
