import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { first } from "rxjs/operators";
import { BrowsingState } from "../../browsing/store/browsing.state";
import { getCachedFeature } from "../../browsing/store/feature.selector";
import { selectConnectivityFeature } from "../../data-connectivity/store/feature.selector";
import { ConnectivityState } from "../../data-connectivity/store/state";
import { getEnvironmentFeature } from "../../environment/store/feature.selector";
import { EnvironmentState } from "../../environment/store/state";
import { EntityId } from "../../meta/models/entity";
import { getReportFeature } from "../store/feature.selector";
import { ReportState } from "../store/state";
import { ComponentStateViewModelDeserializer } from "./deserializers/component-state-vm.deserializer";
import { ComponentStatePropertySelector } from "./entity-selectors/component-state-property.selector";
import { ComponentStateSelector } from "./entity-selectors/component-state.selector";
import { DataConnectorSelector } from "./entity-selectors/data-connector.selector";
import { RuntimeSettingsSelector } from "./entity-selectors/runtime-settings.selector";

// TODO SRP: Refactor or remove this class
@Injectable()
export class FeatureSelector {
  constructor(
    private store$: Store<any>,
    private componentStateSelector: ComponentStateSelector,
    private dataConnectorSelector: DataConnectorSelector,
    private componentStateViewModelDeserializer: ComponentStateViewModelDeserializer,
    private runtimeSettingsSelector: RuntimeSettingsSelector
  ) {}

  createComponentStatePropertySelector(componentStateId: EntityId): ComponentStatePropertySelector {
    return new ComponentStatePropertySelector(
      this.store$,
      componentStateId,
      this.componentStateSelector,
      this.dataConnectorSelector,
      this.componentStateViewModelDeserializer,
      this.runtimeSettingsSelector
    );
  }

  getReportState(): ReportState {
    let reportState;
    this.store$
      .select(getReportFeature)
      .pipe(first())
      .subscribe((report: ReportState) => (reportState = report));
    return reportState;
  }

  getBrowsingState(): BrowsingState {
    let browsingState;
    this.store$
      .select(getCachedFeature)
      .pipe(first())
      .subscribe((state: BrowsingState) => (browsingState = state));
    return browsingState;
  }

  getConnectivityState(): ConnectivityState {
    let connectivityState;
    this.store$
      .select(selectConnectivityFeature)
      .pipe(first())
      .subscribe((state: ConnectivityState) => (connectivityState = state));
    return connectivityState;
  }

  getEnvironmentState(): EnvironmentState {
    let environmentState;
    this.store$
      .select(getEnvironmentFeature)
      .pipe(first())
      .subscribe((state: EnvironmentState) => (environmentState = state));
    return environmentState;
  }
}
