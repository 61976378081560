import { Injectable, OnDestroy } from "@angular/core";
import moment from "moment";
import { Subject } from "rxjs";
import { TimeService } from "../../../core/services/time.service";
import { Dispatcher } from "../../../dispatcher";
import { LocalizationService } from "../../../i18n/localization.service";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { EntityId } from "../../../meta/models/entity";
import { LiveModeFilter } from "../../../shared/models/live-mode-filter";
import { isDefined, isNotDefined, Maybe } from "../../../ts-utils";
import { isWhiteSpaceOrNotDefined } from "../../../ts-utils/helpers/string.helper";
import { QueryFilter } from "../..//models/filter/query-filter";
import { isEventRuntimeFilter } from "../../helpers/filter/filter-id.helper";
import {
  isCorrectTimeRangeInterval,
  LIVE_MODE_REGEX
} from "../../helpers/filter/filter-validation.helper";
import { Filter } from "../../models/filter/filter";
import { FilterConfigurationDto } from "../../models/filter/filter-configuration";
import { TimeRange } from "../../models/time-range";
import { TimeRangeConfigurationDto } from "../../models/time-range-configuration";
import { TimeUnit } from "../../models/time-unit";
import { HOUR_TO_MS } from "../../models/time.constants";
import { ErrorCatchingActions } from "../../store/error-catching/error-catching.actions";
import { DateExpressionParser } from "./date-expression-parser";
import { IFilterSelector } from "./i-filter.selector";

// TODO: cleanup after incremental query refactoring
@Injectable()
export class FilterFactory implements OnDestroy {
  private unsubscribeSubject$: Subject<any> = new Subject<any>();

  constructor(
    private timeService: TimeService,
    private filterSelector: IFilterSelector,
    private dispatcher: Dispatcher,
    private translationService: LocalizationService
  ) {}

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  isFilterValid(filter: Filter): boolean {
    return isCorrectTimeRangeInterval(filter.timeRange?.from, filter.timeRange?.to);
  }

  createQueryFiltersById(filterIds: EntityId[]): QueryFilter[] {
    const filterConfigs = this.filterSelector.getManyById(filterIds);
    return this.createQueryFilters(filterConfigs);
  }

  createQueryFilters(filterConfigs: FilterConfigurationDto[]): QueryFilter[] {
    return filterConfigs
      .map((filterConfig) => this.createQueryFilter(filterConfig))
      .filter(isDefined);
  }

  createQueryFilterById(filterId: EntityId): Maybe<QueryFilter> {
    const filterConfig = this.filterSelector.getById(filterId);
    return this.createQueryFilter(filterConfig);
  }

  createQueryFilter(
    filterConfig: FilterConfigurationDto,
    sourceFilterConfig?: FilterConfigurationDto
  ): Maybe<QueryFilter> {
    const queryFilter = this.createQueryFilterFromConfiguration(filterConfig, sourceFilterConfig);
    if (!this.isFilterValid(queryFilter)) {
      this.displayInvalidConfigurationWarning();
      return null;
    }

    if (queryFilter.isLiveMode) {
      queryFilter.timeRange.to = null;
    }
    return queryFilter;
  }

  // FIXME Refactor, current code difficult to understand
  // There are many functions in this file with similar names and overlapping logic; it's unclear what should be used
  // This particular function mixes up the levels of abstraction for creating time range in the if and else branches
  createQueryFilterFromConfiguration(
    filterConfig: FilterConfigurationDto,
    sourceFilterConfig?: FilterConfigurationDto
  ): QueryFilter {
    if (isDefined(filterConfig.sourceFilterId)) {
      const sourceConfig =
        sourceFilterConfig || this.filterSelector.getByIdOrDefault(filterConfig.sourceFilterId);
      const sourceFilter = this.createQueryFilterFromConfiguration(sourceConfig);
      const merged = this.inheritFilter(filterConfig, sourceFilter);
      return merged;
    } else {
      const timeRange = this.createStandaloneFilterTimeRange(filterConfig.timeRange);
      return new QueryFilter(
        filterConfig.id,
        timeRange,
        isWhiteSpaceOrNotDefined(filterConfig.timeRange.toExpression),
        filterConfig.customFilters
      );
    }
  }

  private inheritFilter(filterConfig: FilterConfigurationDto, source: QueryFilter): QueryFilter {
    const isEventRuntimeSourceFilter: boolean = isEventRuntimeFilter(filterConfig.sourceFilterId);
    const timeRange = this.createRelativeFilterTimeRange(
      filterConfig.timeRange,
      source.timeRange,
      isEventRuntimeSourceFilter
    );
    const isLive =
      source.isLiveMode && isWhiteSpaceOrNotDefined(filterConfig.timeRange.toExpression);
    const customFilters = this.inheritProperties(filterConfig.customFilters, source.customFilters);
    return new QueryFilter(filterConfig.id, timeRange, isLive, customFilters);
  }

  private inheritProperties<T>(child: T, parent: T): T {
    return Object.getOwnPropertyNames(parent).reduce((merged: T, key: string) => {
      const childValue = merged[key];
      if (
        isNotDefined(childValue) ||
        (typeof childValue === "string" && isWhiteSpaceOrNotDefined(childValue))
      ) {
        merged[key] = parent[key];
      }
      return merged;
    }, Object.assign({}, child));
  }

  isInLiveMode(filterId: EntityId): boolean {
    //FIXME: add subscription on this selector, not to take it each time from store
    const liveModeFilters = this.filterSelector.getAllInLiveMode();
    return liveModeFilters[filterId] != null;
  }

  createFilterFromConfiguration(filterConfig: FilterConfigurationDto): Filter | null {
    const timeRange = this.createTimeRangeFromFilterConfig(filterConfig);
    if (isNotDefined(timeRange)) {
      this.displayInvalidConfigurationWarning();
      return null;
    }
    // TODO: Missing logic for calculating the isLiveMode
    return new Filter(filterConfig.id, timeRange, undefined);
  }

  private displayInvalidConfigurationWarning(): void {
    const invalidFilterMessage = this.translationService.get(
      LOCALIZATION_DICTIONARY.snackBarMessages.InvalidFilter
    );
    this.dispatcher.dispatch(
      ErrorCatchingActions.catchWarning({ messageToDisplay: invalidFilterMessage })
    );
  }

  createTimeRangeFromFilterConfig(filterConfig: FilterConfigurationDto): Maybe<TimeRange> {
    const standaloneFilter = !filterConfig.sourceFilterId;
    if (standaloneFilter) {
      return this.createStandaloneFilterTimeRange(filterConfig.timeRange);
    } else {
      const sourceFilterConfig = this.filterSelector.getByIdOrDefault(filterConfig.sourceFilterId);
      const sourceTimeRange = this.createTimeRangeFromFilterConfig(sourceFilterConfig);
      return this.createRelativeFilterTimeRangeAndCheck(filterConfig.timeRange, sourceTimeRange);
    }
  }

  public createStandaloneFilterTimeRange(
    timeRangeConfig: TimeRangeConfigurationDto
  ): Maybe<TimeRange> {
    const isLiveMode = isWhiteSpaceOrNotDefined(timeRangeConfig.toExpression);
    return isLiveMode
      ? this.createStandaloneLiveModeTimeRange(timeRangeConfig)
      : this.createStandaloneFixedTimeRange(timeRangeConfig);
  }

  private createStandaloneLiveModeTimeRange(
    timeRangeConfig: TimeRangeConfigurationDto
  ): Maybe<TimeRange> {
    const liveModeFilter = this.createStandaloneLiveModeFilter(timeRangeConfig);
    if (isNotDefined(liveModeFilter)) {
      return null;
    }
    return this.resolveStandaloneLiveModeTimeRange(liveModeFilter.amount, liveModeFilter.unit);
  }

  public createStandaloneLiveModeFilter(
    timeRangeConfig: TimeRangeConfigurationDto
  ): Maybe<LiveModeFilter> {
    const fromExpression = (timeRangeConfig.fromExpression || "").replace(/\s/g, "");
    const expressionResult = LIVE_MODE_REGEX.exec(fromExpression);
    if (isNotDefined(expressionResult)) {
      return null;
    }
    const offset = Math.abs(Number(expressionResult[2]));
    const unit = expressionResult[6] as TimeUnit;
    return new LiveModeFilter({ amount: offset, unit });
  }

  public resolveStandaloneLiveModeTimeRange(offset: number, unit: string): TimeRange {
    const toDate = this.timeService.currentTime;
    const duration = moment.duration(-offset, unit as moment.unitOfTime.DurationConstructor);
    const durationInMiliseconds = duration.asMilliseconds();
    const fromDate = moment(toDate).add(durationInMiliseconds, "ms").toDate();
    return new TimeRange(fromDate, toDate);
  }

  public createStandaloneFixedTimeRange(
    timeRangeConfig: TimeRangeConfigurationDto
  ): Maybe<TimeRange> {
    const parser = new DateExpressionParser();
    const fromDate = parser.createDateFromFixedExpression(timeRangeConfig.fromExpression || "");
    const toDate = parser.createDateFromFixedExpression(timeRangeConfig.toExpression || "");
    if (fromDate && toDate) {
      const timeRange: TimeRange = new TimeRange(fromDate, toDate);
      return timeRange;
    }
    return null;
  }

  private createRelativeFilterTimeRange(
    timeRangeConfig: TimeRangeConfigurationDto,
    sourceTimeRange: Maybe<TimeRange>,
    isEventRuntimeSourceFilter?: boolean
  ): Maybe<TimeRange> {
    if (isNotDefined(sourceTimeRange)) {
      return null;
    }
    const parser = new DateExpressionParser();
    const startDate = this.shouldTakeFromSourceTimeRange(
      isEventRuntimeSourceFilter,
      timeRangeConfig.fromExpression
    )
      ? sourceTimeRange.from
      : parser.createDateFromExpression(timeRangeConfig.fromExpression || "", sourceTimeRange);

    const endDate = this.shouldTakeFromSourceTimeRange(
      isEventRuntimeSourceFilter,
      timeRangeConfig.toExpression
    )
      ? sourceTimeRange.to
      : parser.createDateFromExpression(timeRangeConfig.toExpression || "", sourceTimeRange);
    return startDate != null && endDate != null ? new TimeRange(startDate, endDate) : null;
  }

  private shouldTakeFromSourceTimeRange(
    isEventRuntimeSourceFilter: Maybe<boolean>,
    expression: Maybe<string>
  ): boolean {
    return isEventRuntimeSourceFilter || isWhiteSpaceOrNotDefined(expression);
  }

  private createRelativeFilterTimeRangeAndCheck(
    timeRangeConfig: TimeRangeConfigurationDto,
    sourceTimeRange: TimeRange | null | undefined
  ): Maybe<TimeRange> {
    const timeRange = this.createRelativeFilterTimeRange(timeRangeConfig, sourceTimeRange);
    if (isNotDefined(timeRange) || !isCorrectTimeRangeInterval(timeRange.from, timeRange.to)) {
      return null;
    }
    return timeRange;
  }

  public createStandaloneTimeRangeConfiguration(
    timeRange: TimeRange,
    liveMode = false
  ): TimeRangeConfigurationDto {
    const parser = new DateExpressionParser();
    if (liveMode) {
      const spanInHours = calculateSpanInHours(timeRange);
      return parser.createStandaloneFilterLiveModeExpressions(spanInHours);
    } else {
      return this.createTimeRangeConfiguration(timeRange);
    }
  }

  public createTimeRangeConfiguration(timeRange: TimeRange): TimeRangeConfigurationDto {
    const parser = new DateExpressionParser();
    const fromExpression = parser.createExpressionFromDate(timeRange.from);
    const toExpression = parser.createExpressionFromDate(timeRange.to);
    return new TimeRangeConfigurationDto({ fromExpression, toExpression });
  }

  public createTimeRangeFromLiveModeFilter(
    liveModeFilter: Maybe<LiveModeFilter>
  ): TimeRangeConfigurationDto {
    const parser = new DateExpressionParser();
    return parser.createFilterLiveModeExpressions(liveModeFilter);
  }
}

export function calculateSpanInHours(timeRange: TimeRange): number {
  const difference = timeRange.to.getTime() - timeRange.from.getTime();
  return difference / HOUR_TO_MS;
}
