import { Dictionary } from "../../ts-utils/models/dictionary.type";
import { LimitEditorPosition } from "../models/limit-editor-position";
import { LimitEditorVisualization } from "../models/limit-editor-visualization";
import { LimitProperties } from "../models/limit-properties";

export function resolveEditorsInfo(): Dictionary<LimitEditorVisualization> {
  return Object.values(LimitProperties).reduce(
    (acc: Dictionary<LimitEditorVisualization>, limitTitle: LimitProperties) => {
      const pointInChart = getCoordinatesFromChart(limitTitle);
      acc[limitTitle] = new LimitEditorVisualization({
        point: {
          x: pointInChart.x,
          y: pointInChart.y
        }
      });
      return acc;
    },
    {}
  );
}

export function getCoordsToDrawChart(
  editorsInfo: Dictionary<LimitEditorVisualization>,
  chart: Highcharts.Chart
): Dictionary<LimitEditorVisualization> {
  return Object.values(editorsInfo).reduce(
    (acc: Dictionary<LimitEditorVisualization>, limit: LimitEditorVisualization) => {
      limit.absolutePositionX = chart.xAxis[0].toPixels(limit.point.x, false);
      limit.absolutePositionY = chart.yAxis[0].toPixels(limit.point.y, false);
      return acc;
    },
    editorsInfo
  );
}

export function getCoordinatesFromChart(limitPropertyName: LimitProperties): LimitEditorPosition {
  switch (limitPropertyName) {
    case LimitProperties.target:
      return { x: 1, y: 0.5 };
    case LimitProperties.high:
      return { x: 0.4, y: 1.5 };
    case LimitProperties.veryHigh:
      return { x: 1.4, y: 2.5 };
    case LimitProperties.extremeHigh:
      return { x: 1.9, y: 3.5 };
    case LimitProperties.extremeLow:
      return { x: 1.9, y: -2.5 };
    case LimitProperties.veryLow:
      return { x: 1.4, y: -1.5 };
    case LimitProperties.low:
      return { x: 0.4, y: -0.5 };
    default:
      return { x: 0.8, y: 0.5 };
  }
}

export function getLabelStyle(): Highcharts.CSSObject {
  return {
    color: "var(--foreground-primary)"
  };
}

export enum LimitTooltipLabel {
  hiLimit = "hi",
  hiHiLimit = "hihi",
  hiHiHiLimit = "hihihi",
  loLimit = "lo",
  loLoLimit = "lolo",
  loLoLoLimit = "lololo",
  target = "target"
}
