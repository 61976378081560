<mat-dialog-content>
  <div mat-dialog-title>Remove signals</div>

  <div class="remove-signals-dialog__wrapper">
    <div>Select axis:</div>
    <mat-select placeholder="Y Axis" #axisList (selectionChange)="getSignalsForAxis($event.value)">
      <mat-option *ngFor="let axis of axisArray" value="{{ axis.id }}">
        <div [innerHTML]="axis.title.text | oneline"></div>
      </mat-option>
    </mat-select>
  </div>

  <div *ngIf="signalsPresent" class="remove-signals-dialog__wrapper">
    <div>Select signals:</div>
    <div [ngClass]="{ 'remove-signals-dialog__signal-list-wrapper': signalsArray.length > 0 }">
      <mat-selection-list dense #signalsList>
        <mat-list-option *ngFor="let signal of signalsArray" value="{{ signal }}">
          {{ signal }}
        </mat-list-option>
      </mat-selection-list>
    </div>
  </div>

  <div
    #noSignals
    *ngIf="!signalsPresent"
    class="remove-signals-dialog__signal-list-wrapper remove-signals-dialog__no-signals--spaced"
  >
    <div>No signals for selected axis.</div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="remove-signals-dialog__button-container">
  <button
    [disabled]="noValuesSelected"
    class="c-button c-button--rounded trend__button--rounded c-button--primary"
    (click)="onDialogClosed()"
  >
    Remove
  </button>
  <button class="c-button c-button--rounded trend__button--rounded" mat-dialog-close>Cancel</button>
</mat-dialog-actions>
