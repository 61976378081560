<input
  #editorInput
  type="text"
  class="signal-editor__input"
  [class.editor--value-configured]="value !== propertyInfo.descriptor.defaultValue"
  [value]="signalName"
  readonly
/>
<button (click)="browse()" *ngIf="!isReadOnly" class="c-button c-button--rounded">...</button>
<label *ngIf="isReadOnly">{{ signalName }}</label>
