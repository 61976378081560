import { construct } from "../../../core/services/construct.helper";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { Configurable, EditableType, Serializable } from "../../../meta/decorators";
import { EditorType } from "../../../meta/models/editor-type";
import { DeepPartial } from "../../../ts-utils/models/deep-partial.type";
import { BaseViewConfigDto } from "../../models/base-view-config";
import { ComponentCssSize } from "../../models/component-size";

// @dynamic
@EditableType({ fullName: "IframeViewConfig" })
export class IframeViewConfig extends BaseViewConfigDto {
  typeName = "IframeViewConfig";

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Url,
    editorType: EditorType.TextBox
  })
  @Serializable("")
  src!: string;

  constructor(viewConfigDto: DeepPartial<IframeViewConfig> = {}) {
    super();
    const defaultOverrides: Partial<IframeViewConfig> = {
      size: getDefaultSize()
    };

    construct(this, viewConfigDto, "IframeViewConfig", defaultOverrides);
  }
}
function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("500", "300");
}
