<section
  class="root-path__container"
  [ngClass]="{
    'root-path__container--vertical': !canExpandHorizontally
  }"
  (clickOutside)="closeIfDropdown()"
>
  <div [ngClass]="{ 'element--hidden': isFilterBarSelected && canExpandHorizontally }">
    <filter-toolbar-element
      [buttonConfig]="toggleButton"
      selectedValueLabel="{{ localizer.generalSettings.RootPath | translate }}"
      [isDropdown]="!canExpandHorizontally"
      [isOpened]="isFilterBarSelected"
    ></filter-toolbar-element>
  </div>
  <div *ngIf="isFilterBarSelected">
    <div
      class="root-path"
      [ngClass]="{
        'root-path--vertical': !canExpandHorizontally
      }"
    >
      <div
        class="root-path__filter-bar-icon"
        [ngClass]="{ 'element--hidden': !canExpandHorizontally }"
      >
        <i class="abb-icon abb-icon--medium Tree_view"></i>
      </div>
      <div class="root-path__search">
        <span>{{ localizer.generalSettings.RootPath | translate }}</span>
        <div #searchInput class="browser__search-input root-path__search-input">
          <input
            #rootPathInput
            focusVisualization
            [focusedElement]="searchInput"
            type="text"
            class="browser__search-input-text root-path__search-input-text"
            [value]="rootPath == '' ? '/' : rootPath"
            title="{{ localizer.generalSettings.RootPathTooltip | translate }}"
            (input)="rootPathChanged$.next(rootPathInput.value)"
          />
          <i class="abb-icon abb-icon--medium Search search__icon" (click)="editRootPath()"></i>
        </div>
      </div>
      <button
        class="app-filter-button app-filter-button__collapse"
        tabindex="-1"
        [ngClass]="{ 'element--hidden': !canExpandHorizontally, selected: isFilterBarSelected }"
        (click)="closeBar($event)"
      >
        <i class="abb-icon abb-icon--medium Left"></i>
      </button>
    </div>
  </div>
</section>
