import { BaseComponent } from "../components";
import { ComponentFactory, Type } from "@angular/core";

export abstract class IComponentFactoryService {
  getFactory(componentName: string): ComponentFactory<BaseComponent> {
    return null;
  }

  getComponentType(componentTypeName: string): Type<BaseComponent> {
    return null;
  }

  createTypedComponentState(componentTypeName: string): BaseComponent {
    return null;
  }
}
