import { Injectable } from "@angular/core";
import {
  appFooterHeight,
  appHeaderHeight,
  basicCardFooterHeight,
  basicCardHeaderHeight,
  tabGroupHeaderHeight
} from "../../../style-variables";
import { DisplayMode } from "../../core/models/display-mode";
import { PageWidth, RESPONSIVE_LAYOUT_VALUE } from "../../core/models/page-layout";
import { ViewMode } from "../../core/models/view-mode";
import { EnvironmentSelector } from "../../environment/services/environment.selector";
import { CONTENT_WRAPPER_CLASS } from "../components/app-body/app-body.component";
import { BasicCardViewConfig } from "../components/basic-card/view-config";
import { convertCssSizeToPx } from "../helpers/component-size.helper";
import {
  ROOT_PADDING_BOTTOM,
  ROOT_PADDING_LEFT_TOP,
  ROOT_PADDING_LEFT_TOP_MOBILE
} from "../helpers/page-component.helper";
import { BaseViewConfigDto } from "../models/base-view-config";
import { ComponentCssSize } from "../models/component-size";
import {
  CardRuntimeViewProperties,
  NEUTRAL_SCALING_FACTOR,
  PageRuntimeViewProperties,
  RuntimeViewProperties
} from "../models/runtime-view-properties";
import { CardSizeInPx, SizeInPx, isCardSizeInPx } from "../models/size-in-px";
import {
  BASIC_CARD_VIEW_CONFIG,
  NAVIGATION_BAR_VIEW_CONFIG,
  PAGE_VIEW_CONFIG,
  TAB_GROUP_VIEW_CONFIG
} from "../models/view-config-type.constants";
import { RuntimeSettingsSelector } from "./entity-selectors/runtime-settings.selector";

const PAGE_SCROLLBAR_SPACE = 10;
const PINNED_SIDEBAR_CLASS = "sidebar__panels--pinned";
const BASIC_CARD_HEIGHT_TRESHOLD = 160;

// @dynamic
@Injectable({ providedIn: "root" })
export class RuntimeViewService {
  private isInPreviewMode = false;
  private pageLayoutWidth: PageWidth = "";
  private displayMode: string = DisplayMode.Desktop;

  constructor(
    private environmentSelector: EnvironmentSelector,
    private runtimeSettingsSelector: RuntimeSettingsSelector
  ) {
    this.subscribeToViewMode();
    this.subscribeToPageWidth();
    this.subscribeToDisplayMode();
    // TODO fix resizing with footer
    // TODO fix load for smaller screens
  }

  private subscribeToPageWidth(): void {
    this.runtimeSettingsSelector.selectPagePreviewWidth().subscribe((pageWidth: PageWidth) => {
      this.pageLayoutWidth = pageWidth;
    });
  }

  private subscribeToViewMode(): void {
    this.environmentSelector.selectViewMode().subscribe((viewMode: ViewMode) => {
      this.isInPreviewMode = viewMode === ViewMode.PreviewMode;
    });
  }

  private subscribeToDisplayMode(): void {
    this.environmentSelector.selectDisplayMode().subscribe((displayMode: string) => {
      this.displayMode = displayMode;
    });
  }

  public calculateRuntimeSize(
    view: BaseViewConfigDto,
    parentContentSize: SizeInPx
  ): RuntimeViewProperties {
    switch (view.typeName) {
      case PAGE_VIEW_CONFIG: {
        return this.calculateRootRuntimeSize();
      }
      case BASIC_CARD_VIEW_CONFIG: {
        return this.calculateCardRuntimeSize(view as BasicCardViewConfig, parentContentSize);
      }
      case NAVIGATION_BAR_VIEW_CONFIG: {
        return this.calculateNavigationBarRuntimeSize(view.size, parentContentSize);
      }
      default:
        return this.calculateDefaultRuntimeSize(view.size, parentContentSize);
    }
  }

  public calculateContentSize(view: BaseViewConfigDto): SizeInPx {
    switch (view.typeName) {
      case PAGE_VIEW_CONFIG: {
        return this.calculateRootContentSizeInPx(view);
      }
      case BASIC_CARD_VIEW_CONFIG: {
        return this.calculateCardContentSizeInPx(view as BasicCardViewConfig);
      }
      case TAB_GROUP_VIEW_CONFIG: {
        return this.calculateTabGroupContentSizeInPx(view);
      }
      default:
        return view.runtimeView.runtimeSize;
    }
  }

  public calculateRootRuntimeSize(): PageRuntimeViewProperties {
    const widthInPx = this.calculateRootHorizontalSpace(this.pageLayoutWidth);
    const heightInPx = this.calculateRootVerticalSpace();
    const pagePadding = resolvePagePadding(this.displayMode);

    return {
      runtimeSize: { heightInPx, widthInPx },
      paddingLeft: pagePadding,
      paddingTop: pagePadding
    };
  }

  /**
   * @description
   * Works with BasicCardComponent
   * @summary
   * if card size is fullDim, define it in terms of its parentDim
   * if card is bigger than its parent, define it in terms of its parent
   */
  private calculateCardRuntimeSize(
    view: BasicCardViewConfig,
    parentContentSize: SizeInPx
  ): CardRuntimeViewProperties {
    const { canBeScaled, size, showFooter, showHeader } = view;
    const newRuntimeView: CardRuntimeViewProperties = {
      ...this.calculateDefaultRuntimeSize(size, parentContentSize),
      scalingFactor: NEUTRAL_SCALING_FACTOR
    };

    const newWidth = Math.min(newRuntimeView.runtimeSize.widthInPx, parentContentSize.widthInPx);
    const headerHeight = showHeader ? basicCardHeaderHeight : 0;
    const footerHeight = showFooter ? basicCardFooterHeight : 0;
    const cardBodyAspect =
      newRuntimeView.runtimeSize.widthInPx /
      (newRuntimeView.runtimeSize.heightInPx - headerHeight - footerHeight);

    const newHeight = newWidth / cardBodyAspect + headerHeight + footerHeight;

    const scalingFactor: number = newWidth / newRuntimeView.runtimeSize.widthInPx;
    if (shouldScaleCard(this.isInPreviewMode && canBeScaled, scalingFactor)) {
      newRuntimeView.scalingFactor = scalingFactor;
      newRuntimeView.runtimeSize.heightInPx = newHeight;
    }
    newRuntimeView.runtimeSize.widthInPx = newWidth;

    const areHeaderAndFooterShown = showFooter && showHeader;
    if (areHeaderAndFooterShown) {
      newRuntimeView.runtimeSize.heightInPx = Math.max(
        newRuntimeView.runtimeSize.heightInPx,
        BASIC_CARD_HEIGHT_TRESHOLD
      );
    }
    return newRuntimeView;
  }

  private calculateNavigationBarRuntimeSize(
    size: ComponentCssSize,
    parentContentSize: SizeInPx
  ): RuntimeViewProperties {
    const newRuntimeView: RuntimeViewProperties = this.calculateDefaultRuntimeSize(
      size,
      parentContentSize
    );
    newRuntimeView.runtimeSize.widthInPx = Math.min(
      newRuntimeView.runtimeSize.widthInPx,
      parentContentSize.widthInPx
    );

    return newRuntimeView;
  }

  private calculateDefaultRuntimeSize(
    size: ComponentCssSize,
    parentContentSize: SizeInPx
  ): RuntimeViewProperties {
    let parentScalingFactor = NEUTRAL_SCALING_FACTOR;
    if (isCardSizeInPx(parentContentSize)) {
      parentScalingFactor = parentContentSize.scalingFactor;
    }
    const inverseParentScalingFactor = 1 / parentScalingFactor;

    return {
      runtimeSize: convertCssSizeToPx(size, {
        heightInPx: parentContentSize.heightInPx * inverseParentScalingFactor,
        widthInPx: parentContentSize.widthInPx * inverseParentScalingFactor
      })
    };
  }

  private calculateRootContentSizeInPx(view: BaseViewConfigDto): SizeInPx {
    return {
      heightInPx:
        view.runtimeView.runtimeSize.heightInPx -
        (view.runtimeView as PageRuntimeViewProperties).paddingTop -
        ROOT_PADDING_BOTTOM,
      widthInPx:
        view.runtimeView.runtimeSize.widthInPx -
        (view.runtimeView as PageRuntimeViewProperties).paddingLeft
    };
  }

  private calculateCardContentSizeInPx(view: BasicCardViewConfig): CardSizeInPx {
    let { widthInPx, heightInPx } = view.runtimeView.runtimeSize;
    if (view.showFooter) {
      heightInPx -= basicCardFooterHeight;
    }
    if (view.showHeader) {
      heightInPx -= basicCardHeaderHeight;
    }
    return { heightInPx, widthInPx, scalingFactor: view.runtimeView.scalingFactor };
  }

  private calculateTabGroupContentSizeInPx(view: BaseViewConfigDto): SizeInPx {
    let { widthInPx, heightInPx } = view.runtimeView.runtimeSize;
    heightInPx -= tabGroupHeaderHeight;

    return { heightInPx, widthInPx };
  }

  public calculateRootHorizontalSpace(pageLayoutWidth: PageWidth): number {
    if (pageLayoutWidth.toString() === RESPONSIVE_LAYOUT_VALUE) {
      const appBodyContent = document.querySelector(`.${CONTENT_WRAPPER_CLASS}`);
      const pinnedSidebarWidth =
        document.querySelector(`.${PINNED_SIDEBAR_CLASS}`)?.clientWidth ?? 0;
      return appBodyContent.clientWidth - PAGE_SCROLLBAR_SPACE + pinnedSidebarWidth;
    } else {
      const widthInPx = Number.isInteger(<number>pageLayoutWidth)
        ? <number>pageLayoutWidth
        : Number.parseInt(<string>pageLayoutWidth);
      return widthInPx;
    }
  }

  calculateRootVerticalSpace(): number {
    return this.environmentSelector.getTemplateBuilderMode()
      ? window.innerHeight
      : window.innerHeight - appHeaderHeight - appFooterHeight;
  }
}

function shouldScaleCard(isScalingEnabled: boolean, scalingFactor: number): boolean {
  const minScalingFactor = NEUTRAL_SCALING_FACTOR / 4;
  const maxScalingFactor = NEUTRAL_SCALING_FACTOR * 4;
  return isScalingEnabled && scalingFactor > minScalingFactor && scalingFactor < maxScalingFactor;
}

function resolvePagePadding(displayMode: string): number {
  return displayMode === DisplayMode.Mobile ? ROOT_PADDING_LEFT_TOP_MOBILE : ROOT_PADDING_LEFT_TOP;
}
