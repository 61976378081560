import { DataTransferObject } from "../../../core/models/data-transfer-object";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { ConfigurableEnum } from "../../../meta/decorators/configurable-enum.decorator";
import { EditableType } from "../../../meta/decorators/editable-type.decorator";
import { OnPropertyChange } from "../../../meta/decorators/on-property-change.decorator";
import { Serializable } from "../../../meta/decorators/serializable.decorator";
import { PropertyInfo } from "../../../meta/models/property-info";
import { CriticalError } from "../../../ts-utils/models/critical-error";
import { IndicatorStateDecoratorContext } from "./indicator-state-decorator-context";
import { StateDisplayType } from "./state-display-type";

@EditableType({ fullName: "IndicatorStateDisplay", virtual: true })
export abstract class IndicatorStateDisplay implements DataTransferObject {
  @ConfigurableEnum({
    enumSource: StateDisplayType,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.StateDisplayType
  })
  @OnPropertyChange<IndicatorStateDecoratorContext, IndicatorStateDisplay, StateDisplayType>(
    onStateDisplayTypeChange
  )
  @Serializable(StateDisplayType.Shape)
  typeName: StateDisplayType;
}

function onStateDisplayTypeChange(
  context: IndicatorStateDecoratorContext,
  owner: IndicatorStateDisplay,
  propertyChange: PropertyInfo<StateDisplayType>
): IndicatorStateDisplay {
  const targetStateDisplayType = owner;
  if (!targetStateDisplayType) {
    throw new CriticalError("Undefined display type");
  }
  const deserializer = context.deserializer;
  const newDataSource = deserializer.convert({
    ...targetStateDisplayType,
    typeName: propertyChange.value
  });
  return newDataSource;
}
