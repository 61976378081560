import { Directive, Host, OnDestroy, OnInit, Self } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ViewMode } from "../../core/models/view-mode";
import { EnvironmentSelector } from "../../environment/services/environment.selector";
import { ContainerComponent } from "../components/container/container.component";

export const CSS_CONTAINER_EDIT_MODE = "container-component--edit-mode";

@Directive({
  selector: "[editable-layout]"
})
export class EditableLayoutDirective implements OnInit, OnDestroy {
  protected unsubscribeSubject$: Subject<any> = new Subject();

  constructor(
    @Host()
    @Self()
    private containerComponent: ContainerComponent,
    private environmentSelector: EnvironmentSelector
  ) {}

  ngOnInit() {
    this.subscribeToViewMode();
  }

  private subscribeToViewMode() {
    this.environmentSelector
      .selectViewMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((viewMode: ViewMode) => {
        if (viewMode === ViewMode.PreviewMode) {
          // this.containerComponent.hostElement.classList.remove(CSS_CONTAINER_EDIT_MODE);
          this.containerComponent.disableDragging();
        } else {
          // this.containerComponent.hostElement.classList.add(CSS_CONTAINER_EDIT_MODE);
          this.containerComponent.enableDragging();
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }
}
