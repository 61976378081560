import { createAction, props } from "@ngrx/store";
import { Theme } from "../../../theme";

export namespace AppSettingsActions {
  export const updateCurrentLanguage = createAction(
    "[Environment] Update Current Language",
    props<{ language: string }>()
  );

  export const updateDateFormat = createAction(
    "[Environment] Update Date Format",
    props<{ dateFormat: string }>()
  );

  export const updateUserName = createAction(
    "[Environment] Update User Name",
    props<{ userName: string }>()
  );

  export const updateTheme = createAction("[Environment] Update Theme", props<{ theme: Theme }>());

  export const changeAliasMode = createAction(
    "[Environment] Update Alias Mode",
    props<{ aliasMode: boolean }>()
  );
}
