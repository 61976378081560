export class FilterToolbarElementConfig {
  public icon: string = "";
  public class: string = "";
  public clickFunction: Function;

  constructor(params: Partial<FilterToolbarElementConfig> = {}) {
    this.icon = params.icon ?? "";
    this.class = params.class ?? "";
    this.clickFunction = params.clickFunction ?? (() => "");
  }
}
