<section
  *ngIf="!shouldHide"
  class="runtime-custom-filters__container"
  [ngClass]="{ 'runtime-custom-filters__container--vertical': !canExpandHorizontally }"
  (clickOutside)="closeIfDropdown()"
>
  <div [ngClass]="{ 'element--hidden': isFilterBarSelected && canExpandHorizontally }">
    <filter-toolbar-element
      [buttonConfig]="toggleButton"
      selectedValueLabel="{{ localizer.propertySheet.RuntimeFilters | translate }}"
      [isDropdown]="!canExpandHorizontally"
      [isOpened]="isFilterBarSelected"
    ></filter-toolbar-element>
  </div>
  <div *ngIf="isFilterBarSelected">
    <div
      *ngIf="isFilterBarSelected"
      class="runtime-custom-filters__bar"
      [ngClass]="{
        'runtime-custom-filters__bar--vertical': !canExpandHorizontally
      }"
    >
      <div
        class="runtime-custom-filters__icon-item"
        [ngClass]="{ 'element--hidden': !canExpandHorizontally }"
      >
        <i [class]="'abb-icon abb-icon--medium ' + icon"></i>
      </div>
      <div
        class="runtime-custom-filters__items"
        [ngClass]="{
          'runtime-custom-filters__items--vertical': !canExpandHorizontally && isFilterBarSelected
        }"
      >
        <div
          [ngClass]="
            canExpandHorizontally
              ? 'runtime-custom-filters__item'
              : 'runtime-custom-filters__item--vertical'
          "
          *ngFor="let filter of customFilters | filterByVisibility; trackBy: trackByTitle"
        >
          <span
            class="runtime-custom-filters__title"
            [ngClass]="{ 'runtime-custom-filters__title--vertical': !canExpandHorizontally }"
            >{{ filter.descriptor.displayName }}</span
          >
          <input
            class="runtime-custom-filters__input"
            [type]="getFilterType(filter.descriptor.editorType)"
            [readonly]="filter.descriptor.isReadOnly"
            [ngModel]="runtimeFilterConfiguration.customFilters[resolveCustomFilterKey(filter)]"
            (ngModelChange)="
              customFilterUpdate$.next({
                value: $event,
                key: resolveCustomFilterKey(filter),
                editorType: filter.descriptor.editorType
              })
            "
          />
        </div>
        <button
          class="app-filter-button app-filter-button__collapse"
          tabindex="-1"
          [ngClass]="{ 'element--hidden': !canExpandHorizontally, selected: isFilterBarSelected }"
          (click)="closeBar($event)"
        >
          <i class="abb-icon abb-icon--medium Left"></i>
        </button>
      </div>
    </div>
  </div>
</section>
