import { Dictionary } from "@ngrx/entity";
import { isEqual as _isEqual } from "lodash";
import { EntityId } from "../../meta";
import { isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { ComponentStateDto, hasChildren } from "../models/component-state";
import { RuntimeViewUpdate } from "../models/runtime-view-update";
import { SizeInPx } from "../models/size-in-px";
import { RuntimeViewService } from "../services/runtime-view.service";
import { ROOT_COMPONENT_ID } from "../store/component-state/component-state.selectors";

export function setRuntimeViewOnLoad(
  reportEntities: Dictionary<ComponentStateDto>,
  runtimeViewService: RuntimeViewService
): Dictionary<ComponentStateDto> {
  const updates = getRuntimeViewUpdate(reportEntities, runtimeViewService);
  updates.forEach((update) => {
    const component = reportEntities[update.componentId];
    if (isDefined(component)) {
      component.view.runtimeView = update.runtimeViewProps;
    }
  });
  return reportEntities;
}

export function getRuntimeViewUpdate(
  reportEntities: Dictionary<Maybe<ComponentStateDto>>,
  runtimeViewService: RuntimeViewService
): RuntimeViewUpdate[] {
  const root = reportEntities[ROOT_COMPONENT_ID];
  // NOTE when resizing while store is not yet loaded correctly size is undefined
  if (isNotDefined(root) || isNotDefined(root.view.size)) {
    return [];
  }
  const rootRuntimeView = runtimeViewService.calculateRootRuntimeSize();
  const updates: RuntimeViewUpdate[] = [
    { componentId: ROOT_COMPONENT_ID, runtimeViewProps: rootRuntimeView }
  ];
  const rootContentSize = runtimeViewService.calculateContentSize({
    ...root.view,
    runtimeView: rootRuntimeView
  });
  root.childrenIds.forEach((id) => {
    updates.push(
      ...getRuntimeViewPropsUpdate(reportEntities, id, rootContentSize, runtimeViewService)
    );
  });
  return updates;
}

export function getRuntimeViewPropsUpdate(
  reportEntities: Dictionary<Maybe<ComponentStateDto>>,
  id: EntityId,
  parentContentSize: SizeInPx,
  runtimeViewService: RuntimeViewService
): RuntimeViewUpdate[] {
  const componentState = reportEntities[id];
  if (isNotDefined(componentState)) {
    return [];
  }
  const oldRuntimeView = componentState.view.runtimeView;
  const runtimeView = runtimeViewService.calculateRuntimeSize(
    componentState.view,
    parentContentSize
  );
  const updates: RuntimeViewUpdate[] = [];
  if (!_isEqual(runtimeView, oldRuntimeView)) {
    updates.push({
      componentId: componentState.id,
      runtimeViewProps: runtimeView
    });
  }
  if (!hasChildren(componentState)) {
    return updates;
  }
  const contentSize = runtimeViewService.calculateContentSize({
    ...componentState.view,
    runtimeView: runtimeView
  });
  componentState.childrenIds.forEach((childId) => {
    const childState = reportEntities[childId];
    if (isNotDefined(childState)) {
      return;
    }
    updates.push(
      ...getRuntimeViewPropsUpdate(reportEntities, childId, contentSize, runtimeViewService)
    );
  });
  return updates;
}
