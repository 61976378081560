<div (clickOutside)="closeMenu()">
  <header-button [buttonConfig]="dropdownButtonConfig" [isSelected]="isMenuOpened" icon="Align">
  </header-button>
  <ul *ngIf="isMenuOpened" class="align-and-distribute-widgets__menu">
    <li *ngFor="let buttonType of buttonTypes">
      <header-button
        [buttonConfig]="alignAndDistributeButtons[buttonType]"
        [icon]="getButtonTypeIcon(buttonType)"
        [verticallyOpened]="true"
        [showLabel]="true"
      >
      </header-button>
    </li>
  </ul>
</div>
