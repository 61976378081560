import { DataConnectorRoles } from "ui-core";
import { PropertyRoles } from "ui-core";

export const Roles: DataConnectorRoles = {
  ChartAirgap: {
    name: "Chart Airgap",
    isDefaultRole: false
  },
  Sensors: {
    name: "Sensors",
    isDefaultRole: true
  },
  xAxis: {
    name: "X Axis"
  },
  yAxis: {
    name: "Y Axis"
  },
  RotorCenter: {
    name: "Rotor Center"
  },
  AirgapProfileEllipse: {
    name: "Airgap Profile Ellipse"
  }
};

export const Properties: PropertyRoles = {
  AlarmLimit: {
    name: "highLimit"
  },
  TripLimit: {
    name: "highHighLimit"
  },
  Unit: {
    name: "unit"
  },
  LocationAngle: {
    name: "locationAngle"
  },
  signalName: {
    name: "name"
  },
  plcTag: {
    name: "plcTag"
  },
  sensorInternalTags: {
    name: "internalTag"
  }
};
