import { KmTrendConfig, WebServicesConfiguration } from "ui-core";
import { environment } from "./environment";

export function buildRdsKmTrendConfig(): KmTrendConfig {
  const webServicesConfiguration =
    environment.webServicesConfiguration as unknown as WebServicesConfiguration;
  return {
    dataSourceUrl: `${webServicesConfiguration.logDataServiceUrl}/Data`,
    baseHRef: "/"
  };
}
