import { LinkDto } from "../../../core";
import { construct } from "../../../core/services/construct.helper";
import { BLACK_COLOR_HEX } from "../../../elements/models/colors.constants";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { Configurable, ConfigurationCategory, Serializable } from "../../../meta/decorators";
import { EditableType } from "../../../meta/decorators/editable-type.decorator";
import { EditorType, PropertyCategory } from "../../../meta/models";
import { Maybe } from "../../../ts-utils";
import { SimpleViewConfig } from "../../models/simple-view-config";

const TYPE_NAME = "SimpleSingleValueViewConfig";

@EditableType({ fullName: TYPE_NAME })
export class SimpleSingleValueViewConfig extends SimpleViewConfig {
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.TextColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable(BLACK_COLOR_HEX)
  textColor!: string;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General, 5)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Unit,
    editorType: EditorType.TextBox
  })
  @Serializable("")
  unit!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.DisplayFormat,
    editorType: EditorType.TextBox
  })
  @Serializable("")
  displayFormat!: string;

  @Serializable(null)
  link: Maybe<LinkDto>;

  alignment!: string;
  columnWidth: Maybe<number> = null;

  constructor() {
    super();
    construct(this, {}, TYPE_NAME);
  }
}
