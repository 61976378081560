import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService, WebServicesConfiguration } from "ui-core";
import { Query } from "../../models/api/query";
import { TrendResultDto } from "../../types";

@Injectable({ providedIn: "root" })
export class QueryService {
  constructor(private httpService: HttpService, private apiConfig: WebServicesConfiguration) {}

  public sendQuery(query: Query): Observable<TrendResultDto[]> {
    return this.httpService.post({
      url: `${this.apiConfig.itemsServiceUrl}/customers/${query.plant}/motors/${query.rootEquipment}/data`,
      body: JSON.stringify(query.body),
      failureDefault: []
    });
  }
}
