import { Maybe } from "../models";
import { isDefined } from "./predicates.helper";

export function tryParseEnum<T>(enumType: { [s: string]: T }, value: Maybe<string>): Maybe<T> {
  if (!isDefined(value)) {
    return null;
  }
  const matches = (Object.values(enumType) as unknown as string[]).includes(value);
  return matches ? (value as unknown as T) : null;
}
