<!-- <c-image></c-image> -->

<div class="gradient_block">
  <highcharts-chart-extended
    class="chart-container heatmap-alignment-chart__chart-container container"
    [Highcharts]="Highcharts"
    [options]="chartOptions"
  >
  </highcharts-chart-extended>
</div>
<!-- <div class="image-display__wrapper" style="position: relative;top: 100px;">
  <img [src]="imageUrl | explicite | async" class="image-display" />
</div> -->
