<div
  class="legend__container"
  [ngClass]="{
    'legend-direction--row': Orientation.Horizontal === orientation,
    'legend-direction--column': Orientation.Vertical === orientation,

    'legend__container--align-left': HorizontalAlignment.Left === alignment,
    'legend__container--align-center': HorizontalAlignment.Center === alignment,
    'legend__container--align-right': HorizontalAlignment.Right === alignment
  }"
>
  <!-- NOTE: because of the wrapper div we can no longer pass inline styles (e.g. flex wrap) on host of the component -->
  <km-legend-item *ngFor="let legendItem of itemList" [legendItem]="legendItem"></km-legend-item>
</div>
