import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { Equipment } from "../../core/models/equipment";
import { EquipmentProperty } from "../../core/models/equipment-property";
import { EntityId } from "../../meta";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { State as DataExplorerState } from "../store/data-explorer/data-explorer.reducer";
import {
  selectDataExplorerModel,
  selectEquipment,
  selectEquipmentTreeModel,
  selectLastUsedEquipmentProperty,
  selectLastUsedSignal,
  selectPropertyBrowserMode,
  selectSignalSearchPattern,
  selectSignalTabMode
} from "../store/data-explorer/data-explorer.selectors";

@Injectable({ providedIn: "root" })
export class DataExplorerSelector {
  constructor(private store$: Store<any>) {}

  selectDataExplorerModel(): Observable<DataExplorerState> {
    return this.store$.select(selectDataExplorerModel);
  }

  selectSignalTabMode(): Observable<boolean> {
    return this.store$.select(selectSignalTabMode);
  }

  selectSignalSearchPattern(): Observable<Maybe<string>> {
    return this.store$.select(selectSignalSearchPattern);
  }

  selectLastUsedSignal(): Observable<Maybe<EntityId>> {
    return this.store$.select(selectLastUsedSignal);
  }

  selectEquipment(): Observable<Maybe<Equipment>> {
    return this.store$.select(selectEquipment);
  }

  selectPropertyBrowserMode(): Observable<boolean> {
    return this.store$.select(selectPropertyBrowserMode);
  }

  selectEquipmentTreeModel(): Observable<Maybe<Equipment[]>> {
    return this.store$.select(selectEquipmentTreeModel);
  }

  selectLastUsedEquipmentProperty(): Observable<Maybe<EquipmentProperty>> {
    return this.store$.select(selectLastUsedEquipmentProperty);
  }

  getSignalSearchPattern(): Maybe<string> {
    let signalSearchPattern: Maybe<string>;
    this.selectSignalSearchPattern()
      .pipe(take(1))
      .subscribe((searchPattern) => (signalSearchPattern = searchPattern));
    return signalSearchPattern;
  }

  getLastSelectedSignal(): Maybe<EntityId> {
    let lastSelectedSignal: Maybe<EntityId>;
    this.selectLastUsedSignal()
      .pipe(take(1))
      .subscribe((signal) => (lastSelectedSignal = signal));
    return lastSelectedSignal;
  }

  getEquipment(): Maybe<Equipment> {
    let selectedEquipment: Maybe<Equipment>;
    this.selectEquipment()
      .pipe(take(1))
      .subscribe((equipment) => (selectedEquipment = equipment));
    return selectedEquipment;
  }

  getEquipmentTreeModel(): Maybe<Equipment[]> {
    let treeModel: Maybe<Equipment[]>;
    this.selectEquipmentTreeModel()
      .pipe(take(1))
      .subscribe((tree) => (treeModel = tree));
    return treeModel;
  }

  getLastSelectedEquipmentProperty(): Maybe<EquipmentProperty> {
    let equipmentProperty: Maybe<EquipmentProperty>;
    this.selectLastUsedEquipmentProperty()
      .pipe(take(1))
      .subscribe((property) => (equipmentProperty = property));
    return equipmentProperty;
  }
}
