import { Injectable } from "@angular/core";
import { ContainerComponent } from "../components";

@Injectable()
export class ActiveContainersService {
  public currentActiveContainers: ContainerComponent[] = [];
  constructor() {}

  public addContainerComponent(container: ContainerComponent) {
    this.currentActiveContainers.push(container);
  }

  public removeContainerComponent(container: ContainerComponent) {
    const index = this.currentActiveContainers.indexOf(container);
    this.currentActiveContainers.splice(index, 1);
  }
}
