<div class="feedback__wrapper">
  <div class="feedback__header">Give feedback</div>
  <div class="feedback__item">
    <label class="feedback__item-label"> {{ localizer.dialogs.Type | translate }}: </label>
    <select [(ngModel)]="selectedFeedbackType" class="feedback__type-selection editor--discreet-input" >
      <option *ngFor="let feedbackType of feedbackTypeSelectOptions" [value]="feedbackType.key">
        {{ feedbackType.title | translate }}
      </option>
    </select>
  </div>
  <div class="feedback__item">
    <label class="feedback__item-label"> {{ localizer.dialogs.Title | translate }}: </label>
    <input type="text" [(ngModel)]="title" class="editor--discreet-input" />
  </div>
  <div class="feedback__item">
    <label class="feedback__item-label"> {{ localizer.dialogs.Description | translate }}: </label>
    <textarea rows="5" [(ngModel)]="description" class="feedback__description editor--discreet-input"></textarea>
  </div>
  <div class="feedback__item">
    <label class="feedback__item-label"> {{ localizer.dialogs.Attachments | translate }}: </label>
    <input
      #fileInput
      type="file"
      id="file_uploads"
      name="file_uploads"
      class="file-selection__file_input"
      (change)="onFileInput($event)"
    />
    <label for="file_uploads" class="c-button c-button--rounded feedback__attachments-button">
      Attach file
    </label>
    <mat-chip-listbox #chipList>
      <mat-chip-option
        *ngFor="let file of attachments; let fileIndex = index"
        [removable]="true"
        (removed)="remove(fileIndex)"
      >
        {{ file.name }}
        <mat-icon matChipRemove class="abb-icon Close"></mat-icon>
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
  <div
    class="feedback__item feedback__item--info_share"
    *ngIf="selectedFeedbackType === FeedbackType.Bug"
  >
    <mat-slide-toggle #matSlideToggle [(ngModel)]="allowToShareBugInfo"> </mat-slide-toggle>
    <label class="feedback__debug-info-label">{{
      localizer.dialogs.AllowDebugInfo | translate
    }}</label>
    <i class="info-icon abb-icon abb-icon--medium Information_circle_2"></i>
  </div>
  <div mat-dialog-actions class="feedback__action-buttons">
    <button
      class="c-button c-button--rounded c-button--primary send-button"
      (click)="send()"
      [disabled]="isSendButtonDisabled()"
    >
      {{ localizer.dialogs.Send | translate }}
    </button>
    <button class="c-button c-button--rounded" (click)="cancel()">
      {{ localizer.dialogs.Cancel | translate }}
    </button>
  </div>
</div>
