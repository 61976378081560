<div class="clientSearch_block">
  <form class="clientNameForm" [formGroup]="clientNameForm">
    <input
      type="text"
      matInput
      placeholder="Find plant name ..."
      formControlName="clientName"
      [matAutocomplete]="autoGroup"
      class="clientNameForm__input"
    />
    <mat-autocomplete (optionSelected)="getSelectedClient($event)" #autoGroup="matAutocomplete">
      <mat-optgroup *ngFor="let group of countryGroupOptions" [label]="group.country">
        <mat-option *ngFor="let name of group.clientsNames" [value]="name">
          {{ name }}
        </mat-option>
      </mat-optgroup>
    </mat-autocomplete>
  </form>
</div>
