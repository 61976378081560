import { EntityId } from "../../../meta/models/entity";
import { TimeRangeConfigurationDto } from "../time-range-configuration";
import { FilterConfigurationDto } from "./filter-configuration";

export class FilterViewModel {
  id: EntityId;
  timeRange: TimeRangeConfigurationDto;
  sourceFilterConfig: FilterViewModel;

  constructor(filterConfig: FilterConfigurationDto, sourceFilter: FilterViewModel) {
    this.id = filterConfig.id;
    this.timeRange = filterConfig.timeRange;
    this.sourceFilterConfig = sourceFilter;
  }
}

export function createFilterViewModel(
  filterConfig: FilterConfigurationDto,
  sourceFilterConfig: FilterViewModel
): FilterViewModel {
  return new FilterViewModel(filterConfig, sourceFilterConfig);
}
