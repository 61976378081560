import { DataConnectorRoles } from "ui-core";

export const Roles: DataConnectorRoles = {
  PoleValues: {
    name: "Motor Temperatures",
    isDefaultRole: true
  },
  CalculatedRotorShape: {
    name: "Calculated Rotor Shape"
  }
};
