import { construct } from "../../../core";
import { EditableType } from "../../../meta/decorators/editable-type.decorator";
import { DeepPartial } from "../../../ts-utils";
import { ComponentCssSize } from "../../models/component-size";
import { FULL_HEIGHT_CSS_SIZE, FULL_WIDTH_CSS_SIZE } from "../../models/component-size.constants";
import { ContainerComponentViewConfig } from "../container/view-config";

const TYPE_NAME = "TabContentViewConfig";

// @dynamic
@EditableType({ fullName: TYPE_NAME })
export class TabContentViewConfig extends ContainerComponentViewConfig {
  typeName = TYPE_NAME;

  constructor(viewConfigDto: DeepPartial<TabContentViewConfig> = {}) {
    super();
    const defaultOverrides: Partial<TabContentViewConfig> = {
      size: getDefaultSize(),
      selectable: false
    };
    construct(this, viewConfigDto, TYPE_NAME, defaultOverrides);
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize(FULL_WIDTH_CSS_SIZE, FULL_HEIGHT_CSS_SIZE);
}
