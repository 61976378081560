export class Version {
  constructor(public major: number, public minor: number, public patch: number) {}

  public static fromArray(array: number[]): Version {
    if (array.length !== 3) {
      throw new Error("bad length");
    }
    return new Version(array[0], array[1], array[2]);
  }

  public get asArray(): number[] {
    return [this.major, this.minor, this.patch];
  }

  public toString(): string {
    return this.asArray.join(".");
  }

  public static compare(a: Version, b: Version): number {
    return Version.compareArray(a.asArray, b.asArray);
  }

  private static compareArray(a: number[], b: number[]): number {
    for (let index = 0; index < a.length; index++) {
      const diff = a[index] - b[index];
      if (diff !== 0) {
        return diff;
      }
    }
    return 0;
  }
}
