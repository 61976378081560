import { PositionDto } from "../../../core/models/position";

export interface RectangleDto extends PositionDto {
  top: number;
  left: number;
  width: number;
  height: number;
}

export enum RectCorner {
  TopLeft = "TopLeft",
  TopRight = "TopRight",
  BottomLeft = "BottomLeft",
  BottomRight = "BottomRight",
  None = "None"
}

enum HorizontalSide {
  Left = "Left",
  Right = "Right"
}

enum VerticalSide {
  Top = "Top",
  Bottom = "Bottom"
}

enum NoSide {
  None = "None"
}

export const RectSide = {
  ...HorizontalSide,
  ...VerticalSide,
  ...NoSide
};
export type RectSide = HorizontalSide | VerticalSide | NoSide;

export class RectGrabbedPosition {
  constructor(
    public verticalSide: VerticalSide | NoSide,
    public horizontalSide: HorizontalSide | NoSide
  ) {}

  static getNone(): RectGrabbedPosition {
    return new RectGrabbedPosition(RectSide.None, RectSide.None);
  }

  isNone(): boolean {
    return this.verticalSide === RectSide.None && this.horizontalSide === RectSide.None;
  }

  isCorner(): boolean {
    return this.verticalSide !== RectSide.None && this.horizontalSide !== RectSide.None;
  }

  getCorner(): RectCorner {
    if (this.isCorner()) {
      if (this.verticalSide === RectSide.Top) {
        if (this.horizontalSide === RectSide.Left) {
          return RectCorner.TopLeft;
        }
        return RectCorner.TopRight;
      }
      if (this.horizontalSide === RectSide.Left) {
        return RectCorner.BottomLeft;
      }
      return RectCorner.BottomRight;
    }
    return RectCorner.None;
  }

  getSide(): RectSide {
    if (this.isCorner()) {
      return RectSide.None;
    }
    return this.verticalSide !== RectSide.None ? this.verticalSide : this.horizontalSide;
  }
}

export function getRectGrabbedPosition(
  mousePosition: PositionDto,
  componentPlacement: RectangleDto,
  borderSizePx: number
): RectGrabbedPosition {
  let horizontalSide: RectSide = RectSide.None;
  let verticalSide: RectSide = RectSide.None;
  if (isMouseOnLeftRectBorder(componentPlacement.left, mousePosition.left, borderSizePx)) {
    horizontalSide = RectSide.Left;
  } else if (
    isMouseOnRightRectBorder(
      componentPlacement.left,
      mousePosition.left,
      componentPlacement.width,
      borderSizePx
    )
  ) {
    horizontalSide = RectSide.Right;
  }

  if (isMouseOnTopRectBorder(componentPlacement.top, mousePosition.top, borderSizePx)) {
    verticalSide = RectSide.Top;
  } else if (
    isMouseOnBottomRectBorder(
      componentPlacement.top,
      mousePosition.top,
      componentPlacement.height,
      borderSizePx
    )
  ) {
    verticalSide = RectSide.Bottom;
  }
  return new RectGrabbedPosition(verticalSide, horizontalSide);
}

function isMouseOnLeftRectBorder(left: number, currentX: number, border: number): boolean {
  return left <= currentX && currentX <= left + border;
}

function isMouseOnRightRectBorder(
  left: number,
  currentX: number,
  width: number,
  border: number
): boolean {
  return left + width - border <= currentX && currentX < left + width;
}

function isMouseOnTopRectBorder(top: number, currentY: number, border: number): boolean {
  return top <= currentY && currentY < top + border;
}

function isMouseOnBottomRectBorder(
  top: number,
  currentY: number,
  height: number,
  border: number
): boolean {
  return top + height - border <= currentY && currentY < top + height;
}
