<div class="card-item__drag-handle">
  <highcharts-chart-extended
    class="chart-container"
    [Highcharts]="Highcharts"
    [options]="chartOptions"
  >
  </highcharts-chart-extended>
  <span class="static-eccentricity-chart__legend-title"> LEGEND </span>
  <km-legend
    [itemList]="[
      {
        name: 'Rotor Center (Calculated)',
        color: view.rotorMillCenterColor,
        bulletType: BulletType.Circle
      },
      { name: 'Stator Center', color: view.statorCenterColor, bulletType: BulletType.Circle }
    ]"
    [alignment]="HorizontalAlignment.Center"
    [orientation]="Orientation.Horizontal"
  ></km-legend>
</div>
