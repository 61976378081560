import { DataConnectorDto } from "../../data-connectivity";
import { ConnectorContextService } from "../../data-connectivity/services/connector-context.service";
import { Maybe, isDefined } from "../../ts-utils";
import { StrategizedChartViewConfig } from "../components/strategized-chart/view-config";
import { ComponentStateDto, ReportEntities } from "../models";
import { RuntimeViewUpdate } from "../models/runtime-view-update";
import { SizeInPx } from "../models/size-in-px";
import { hasDynamicDataPoints } from "./dynamic-data-points.helper";

export function getReportConnectorsWithContext(
  reportEntities: ReportEntities,
  contextService: ConnectorContextService
): DataConnectorDto[] {
  return reportEntities.componentStates.reduce((acc, componentState) => {
    const componentConnectors: DataConnectorDto[] = componentState.dataConnectorIds
      .map((connectorId) =>
        reportEntities.dataConnectors.find((connector) => connector.id === connectorId)
      )
      .filter(isDefined);
    const connectorsWithContext = getConnectorsWithContext(
      componentConnectors,
      componentState,
      contextService
    );
    acc = acc.concat(connectorsWithContext);
    return acc;
  }, [] as DataConnectorDto[]);
}

export function getConnectorsWithContext(
  connectors: DataConnectorDto[],
  ownerComponent: ComponentStateDto,
  contextService: ConnectorContextService
): DataConnectorDto[] {
  const connectorsWithContext = connectors.map((connector: DataConnectorDto) =>
    getConnectorWithContext(connector, ownerComponent, contextService)
  );
  return connectorsWithContext;
}

export function getConnectorWithContext(
  connector: DataConnectorDto,
  ownerComponent: ComponentStateDto,
  contextService: ConnectorContextService
): DataConnectorDto {
  const strategy: string | undefined = (ownerComponent.view as StrategizedChartViewConfig)
    .displayStrategy;
  const size: SizeInPx = ownerComponent.view.runtimeView.runtimeSize;
  const connectorWithContext: DataConnectorDto = {
    ...connector,
    numberOfRequestedDataPoints: contextService.getNumberOfDataPointsToRequest(
      ownerComponent.type,
      strategy,
      size,
      connector.role
    )
  };
  return connectorWithContext;
}

export function shouldUpdateConnectorsContext(
  component: Maybe<ComponentStateDto>,
  update: RuntimeViewUpdate
): boolean {
  return (
    isDefined(component) &&
    // isEqual fails, because it also compares runtime types, which are different to to issues with deserialization
    JSON.stringify(component.view.runtimeView) !== JSON.stringify(update.runtimeViewProps) &&
    hasDynamicDataPoints(component.type)
  );
}
