import "reflect-metadata";
import { isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { TypeDescriptor } from "../models/type-descriptor";
import { TypeProvider } from "../services/type-provider";

const TYPE_PROVIDER = TypeProvider.getInstance();
export const IS_ATTACHABLE = "attachable";

export class EditableTypeParams {
  fullName: string;
  title?: string;
  virtual?: boolean;
  overridePropertyValues?: boolean;
}

export function EditableType(params: EditableTypeParams) {
  return function (typeConstructor: Function) {
    const existingType: Maybe<TypeDescriptor> = TYPE_PROVIDER.tryGetType(params.fullName);
    if (isDefined(existingType)) {
      throw new Error(`Type ${params.fullName} already exists.`);
    }

    const typeDescriptor: TypeDescriptor = TYPE_PROVIDER.addType({
      constructorFunction: typeConstructor as new () => any,
      name: params.fullName,
      isVirtual: params.virtual,
      overridePropertyValues: params.overridePropertyValues
    });
    if (isNotDefined(params.virtual)) {
      Reflect.defineMetadata(IS_ATTACHABLE, true, typeDescriptor);
    }
    typeDescriptor.titleProperty = params.title ?? params.fullName;
  };
}
