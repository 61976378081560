import { createSelector } from "@ngrx/store";
import { selectDataConnectorEntities } from "../data-connector/data-connector.selectors";
import { selectComponentStateById } from "../component-state";
import { EntityId } from "../../../meta/models";

export const getDataConectorsForComponent = (id: EntityId) =>
  createSelector(
    selectDataConnectorEntities,
    selectComponentStateById(id),
    (entities, componentState) => {
      return Object.keys(entities).filter(
        (entityId) => componentState.dataConnectorIds.indexOf(entityId) !== -1
      );
    }
  );
