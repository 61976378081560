import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { LocalizationService } from "../../../i18n/localization.service";
import { DateTimePickerDialogInfo } from "../../models/date-time-picker-dialog-info";
import { BaseDialogComponent } from "../base-dialog/base-dialog.component";

@Component({
  selector: "date-time-picker-dialog",
  templateUrl: "date-time-picker-dialog.component.html",
  styleUrls: ["./date-time-picker-dialog.component.scss"]
})
export class DateTimePickerDialogComponent extends BaseDialogComponent {
  dateTimeFormat: string = "";
  date: Date;

  constructor(
    public localizer: LocalizationService,
    @Inject(MAT_DIALOG_DATA) public dateTimePickerDialogInfo: DateTimePickerDialogInfo,
    private dialogRef: MatDialogRef<DateTimePickerDialogComponent>
  ) {
    super();
    this.date = this.dateTimePickerDialogInfo.date;
    this.dateTimeFormat = this.dateTimePickerDialogInfo.dateTimeFormat;
  }

  onSelectDateTimePicker(updateStartDateTime: boolean, newDate: Date): void {
    this.date = newDate;
  }

  onReset(): void {
    this.date = this.dateTimePickerDialogInfo.date;
  }

  onSave(): void {
    this.dialogRef.close(this.date);
  }

  onCancel(): void {
    this.dialogRef.close(this.dateTimePickerDialogInfo.date);
  }
}
