<div class="card-item__drag-handle">
  <div class="system_overview__image" [class.linked-element]="view.link" (click)="openLink()">
    <img [src]="view.imageUrl" />
  </div>
  <div class="system_overview__content-container">
    <h2 class="system_overview__title">{{ view.title }}</h2>
    <ul class="system_overview__list_status_indicators">
      <li class="system_overview__item" *ngFor="let connector of dataConnectors">
        <c-simple-status-indicator
          [status]="getStatus()"
          style="width: 12px; height: 12px"
        ></c-simple-status-indicator>
        <span
          class="system_overview__item_title"
          [class.linked-element]="!!view.link"
          (click)="openLink()"
        >
          {{ connector.title }}
        </span>
      </li>
    </ul>
  </div>
  <div class="system_overview__view-more-button">
    <button
      class="c-button c-button--rounded c-button--primary"
      [disabled]="!hasLink"
      [style.cursor]="buttonCursorModifier"
      (click)="openLink()"
    >
      VIEW MORE
    </button>
  </div>
</div>
