import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { PropertySheetLocalizationDictionary } from "../../../i18n/models/property-sheet-localization-dictionary";
import { ConfigurableEnum } from "../../../meta/decorators/configurable-enum.decorator";
import { Configurable } from "../../../meta/decorators/configurable.decorator";
import { Title } from "../../../meta/decorators/title.decorator";
import { EditorType } from "../../../meta/models/editor-type";
import { SelectionOption } from "../../../meta/models/selection";
import { Maybe } from "../../../ts-utils";
import { EquipmentPath } from "../equipment-path";

export enum WidgetEventType {
  SelectTimeRangeEvent = "SelectTimeRangeEvent",
  ResetTimeRangeEvent = "ResetTimeRangeEvent",
  SelectEquipmentPathEvent = "SelectEquipmentPathEvent"
}

export type SelectTimeRangeEvent = {
  type: WidgetEventType.SelectTimeRangeEvent;
  start: Date;
  end: Date;
};

export type ResetTimeRangeEvent = {
  type: WidgetEventType.ResetTimeRangeEvent;
};

export type TimeRangeEvent = SelectTimeRangeEvent | ResetTimeRangeEvent;

export type SelectEquipmentPathEvent = {
  type: WidgetEventType.SelectEquipmentPathEvent;
  path: EquipmentPath;
};

export type WidgetEvent = SelectTimeRangeEvent | ResetTimeRangeEvent | SelectEquipmentPathEvent;

export class EventSubscription {
  @ConfigurableEnum({
    enumSource: getEventTypeEnum,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Type,
    advancedMode: true
  })
  eventType: WidgetEventType = WidgetEventType.SelectTimeRangeEvent;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.SourceWidget,
    editorType: EditorType.TextBox
  })
  @Title()
  sourceWidgetId: Maybe<string>;
}

export function getEventTypeEnum(): SelectionOption[] {
  // ResetTimeRangeEvent is implicitly subscribed when SelectTimeRangeEvent is subscribed
  const allEventTypes: WidgetEventType[] = [WidgetEventType.SelectTimeRangeEvent]; // , WidgetEventType.SelectEquipmentPathEvent];
  return allEventTypes.map((eventType) => ({
    key: eventType,
    title:
      LOCALIZATION_DICTIONARY.propertySheet[eventType as keyof PropertySheetLocalizationDictionary]
  }));
}
