import { COMMA, ENTER } from "@angular/cdk/keycodes";
import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from "@angular/material/autocomplete";
import { cloneDeep as _cloneDeep } from "lodash";
import { Observable, Subject, of, timer } from "rxjs";
import { debounce, map, takeUntil } from "rxjs/operators";
import { forceOpenAutocomplete } from "../../../elements/helpers/mat-autocomplete.helper";
import { LocalizationService } from "../../../i18n/localization.service";
import { SelectionOption, TypeProvider } from "../../../meta";
import { OfType } from "../../../meta/decorators/of-type.decorator";
import { EditorType } from "../../../meta/models/editor-type";
import { isDefined, isEmpty, isEmptyOrNotDefined } from "../../../ts-utils/helpers";
import { focusItemInput } from "../../helpers/input-editor-helper";
import { BaseEditorComponent } from "../base-editor.component";

@Component({
  selector: "editable-select-list-editor",
  templateUrl: "editable-select-list-editor.component.html",
  styleUrls: ["editable-select-list-editor.component.scss"]
})
@OfType(EditorType.EditableSelectList)
export class EditableSelectListEditorComponent
  extends BaseEditorComponent
  implements OnInit, OnDestroy
{
  selectableItems: SelectionOption[] = [];
  selectableItemNames$: Observable<string[]> = of([]);
  inputControl = new FormControl<string>("");

  //multiple selection
  multiSelectionResult: SelectionOption[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];

  //single selection
  inputChanges$: Subject<string> = new Subject<string>();
  unsubscribeSubject$: Subject<any> = new Subject<any>();
  inputValue!: string;

  @HostBinding("attr.title")
  public get tooltipText(): string {
    return this.tooltip;
  }

  @ViewChild("autocomplete")
  matAutocomplete?: MatAutocomplete;

  @ViewChild(MatAutocompleteTrigger)
  autocompleteTrigger?: MatAutocompleteTrigger;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected typeProvider: TypeProvider,
    protected translationService: LocalizationService
  ) {
    super(cdr, typeProvider, translationService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (!this.propertyInfo.descriptor.allowMultipleSelection) {
      this.initSingleSelection();
    }
    if (isDefined(this.itemOwner)) {
      const selectableItems = this.propertyInfo.descriptor.constructorFunction(this.itemOwner);
      this.setSelectableItems(selectableItems);
    } else {
      throw new Error("Undefined owner entity.");
    }

    this.selectableItemNames$ = this.inputControl.valueChanges.pipe(
      map((partialName: string | null) => this._filterItemNames(partialName || ""))
    );
    window.addEventListener("scroll", this.scrollEvent, true);
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
    window.removeEventListener("scroll", this.scrollEvent, true);
  }

  scrollEvent = (event) => {
    if (this.autocompleteTrigger?.panelOpen) {
      this.autocompleteTrigger.updatePosition();
    }
  };

  private initSingleSelection(): void {
    this.inputValue = _cloneDeep(this.value);
    this.inputControl.setValue(this.inputValue);
    this.subscribeToInputChanges();
  }

  subscribeToInputChanges(): void {
    this.inputChanges$
      .pipe(
        debounce((newValue) => {
          return isEmptyOrNotDefined(newValue) ? timer(0) : timer(3000);
        }),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe((newValue) => {
        this.onInputChange(newValue);
      });
  }

  //This method will be called before ngOnInit
  setSelectableItems(newItems: SelectionOption[] | Observable<SelectionOption[]>): void {
    if (Array.isArray(newItems)) {
      this.selectableItems = newItems;
      this.selectPreviouslySelectedItems();
    } else {
      newItems && newItems.subscribe((items) => this.setSelectableItems(items));
    }
  }

  private selectPreviouslySelectedItems(): void {
    this.multiSelectionResult = [...this.multiSelectionResult];
    if (isEmpty(this.multiSelectionResult)) {
      this.multiSelectionResult = this.collectItemsFromEditorInitialValue();
    }
  }

  private _filterItemNames(partialName: string): string[] {
    const filterValue: string = partialName.toLowerCase();
    const selectableItemNames: string[] = this.selectableItems.map(
      (option: SelectionOption) => option.title
    );
    const alreadySelectedItemNames: string[] = this.multiSelectionResult.map(
      (option: SelectionOption) => option.title
    );

    const filteredItemNames = selectableItemNames.filter((itemName: string) => {
      const isNotAlreadySelected: boolean = !alreadySelectedItemNames.includes(itemName);
      const isPartiallyMatching: boolean = itemName.toLocaleLowerCase().includes(filterValue);
      const shouldShow: boolean = isNotAlreadySelected && isPartiallyMatching;
      return shouldShow;
    });
    return filteredItemNames;
  }

  selectAll(): void {
    this.multiSelectionResult = [...this.selectableItems];
    this.onMultipleSelectionChange();
  }

  clearAllSelections(): void {
    this.multiSelectionResult = [];
    this.onMultipleSelectionChange();
  }

  removeSelectedItem(title: string): void {
    this.multiSelectionResult = this.multiSelectionResult.filter((item) => item.title !== title);
    this.onMultipleSelectionChange();
  }

  updateOnSelect(selectedItem: MatAutocompleteSelectedEvent): void {
    const selectedItemValue = selectedItem.option.value;
    if (this.propertyInfo.descriptor.allowMultipleSelection) {
      this.updateOnMultipleSelection(selectedItemValue);
    } else if (this.inputValue !== selectedItemValue) {
      this.updateOnSingleSelection(selectedItemValue);
    }
  }

  updateOnMultipleSelection(selectedItemValue: string): void {
    const newlySelectedItem = this.selectableItems.find(
      (option) => option.title === selectedItemValue
    );
    this.multiSelectionResult =
      newlySelectedItem == null
        ? [...this.multiSelectionResult]
        : [...this.multiSelectionResult, newlySelectedItem];
    this.onMultipleSelectionChange();
    this.inputControl.setValue("");
  }

  onMultipleSelectionChange(): void {
    const selectedKeys = this.multiSelectionResult.map((option) => option.key);
    this.onValueChanged(selectedKeys);
    this.forceOpenAutocomplete();
  }

  forceOpenAutocomplete(): void {
    forceOpenAutocomplete(
      this.inputControl,
      this.editorInput?.nativeElement.value,
      this.autocompleteTrigger
    );
  }

  updateOnSingleSelection(selectedItemValue: string): void {
    this.inputValue = selectedItemValue;
    const valueToUpdate = this.propertyInfo.descriptor.showCustomInputInSelectList
      ? this.inputValue
      : this.getItemKey(selectedItemValue);
    this.onValueChanged(valueToUpdate);
  }

  getItemKey(itemValue: string): string {
    const item = this.selectableItems.find((item: SelectionOption) => item.title === itemValue);
    return item?.key ?? "";
  }

  onCustomInputFocusout(value: string): void {
    if (this.propertyInfo.descriptor.showCustomInputInSelectList) {
      const currentValue = value.trim();
      this.onInputChange(currentValue);
    } else {
      this.inputControl.setValue(this.inputValue, {
        emitEvent: false
      });
    }
  }

  onInputChange(value: string): void {
    if (this.differentFromCurrentAndNotInSelectList(value)) {
      {
        this.inputValue = value;
        this.onValueChanged(value);
      }
    }
    setTimeout(() => {
      this.autocompleteTrigger?.updatePosition();
    }, 1000);
  }

  differentFromCurrentAndNotInSelectList(newValue: string): boolean {
    return (
      (this.inputValue !== newValue &&
        !this.autocompleteTrigger?.panelOpen &&
        this.propertyInfo.descriptor.showCustomInputInSelectList) ||
      (newValue === "" && !isEmptyOrNotDefined(this.inputValue))
    );
  }

  preventOtherEvents(event: Event): void {
    event.stopPropagation();
  }

  refreshValue(value: any): void {
    super.refreshValue(value);
    if (this.propertyInfo.descriptor.allowMultipleSelection) {
      this.multiSelectionResult = this.collectItemsFromEditorInitialValue();
    } else {
      const valueToSet = this.propertyInfo.descriptor.showCustomInputInSelectList
        ? value
        : this.getItemName(value);
      this.editorInput.nativeElement.value = valueToSet ?? "";
    }
  }

  collectItemsFromEditorInitialValue(): SelectionOption[] {
    return this.selectableItems.filter((item) => this.getInitialEditorValue().includes(item.key));
  }

  //We use this method to validate 'this.value' because we might use 'this.value' before potential validation in ngOnInit
  private getInitialEditorValue(): string[] {
    if (typeof this.value === "string" && this.value !== "") {
      return [this.value];
    } else {
      return this.value || [];
    }
  }

  getItemName(key: string): string {
    const item = this.selectableItems.find((item: SelectionOption) => item.key === key);
    return item?.title ?? "";
  }

  focus(): void {
    focusItemInput(this.editorInput?.nativeElement);
    this.autocompleteTrigger?.closePanel();
  }
}
