import {
  BaseViewConfigDto,
  ComponentCssSize,
  Configurable,
  ConfigurationCategory,
  construct,
  DeepPartial,
  EditableType,
  EditorType,
  LOCALIZATION_DICTIONARY,
  PropertyCategory,
  Serializable
} from "ui-core";

const TYPE_NAME = "PolarChartViewConfig";

@EditableType({ fullName: TYPE_NAME })
export class PolarChartViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  @Configurable({ displayName: "Title", editorType: EditorType.TextBox })
  @Serializable("")
  title!: string;

  @Configurable({ displayName: "Variable", editorType: EditorType.TextBox })
  @Serializable("")
  variable!: string;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Color, 1)
  @Configurable({ displayName: "Basic color", editorType: EditorType.ColorPicker })
  @Serializable("#004C97")
  basicColor!: string;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Color, 1)
  @Configurable({ displayName: "Lowest color", editorType: EditorType.ColorPicker })
  @Serializable("#4DB82C")
  lowestColor!: string;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Color, 1)
  @Configurable({ displayName: "Highest color", editorType: EditorType.ColorPicker })
  @Serializable("#FF0000")
  highestColor!: string;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.View, 2)
  @Configurable({ displayName: "Show extremes", editorType: EditorType.CheckBox })
  @Serializable(true)
  showExtremes!: boolean;

  constructor(viewConfigDto: DeepPartial<PolarChartViewConfig> = {}) {
    super();
    construct(this, viewConfigDto, TYPE_NAME, { size: getDefaultSize() });
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("600", "600");
}
