import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  Output,
  SimpleChange,
  SimpleChanges
} from "@angular/core";
import { ChartSelectionContextObject } from "highcharts";
import { TimeRange } from "../../../../core/models/time-range";
import {
  TimeRangeEvent,
  WidgetEventType
} from "../../../../core/models/widget-eventing/widget-event";
import { AppSettingsService } from "../../../../environment/services/app-settings.service";
import { ColorListService } from "../../../../environment/services/color-list.service";
import { DateFormatterService } from "../../../../environment/services/date-formatter.service";
import { isDefined } from "../../../../ts-utils";
import { CriticalError } from "../../../../ts-utils/models/critical-error";
import { Maybe } from "../../../../ts-utils/models/maybe.type";
import { ComponentUserEventCallbacks } from "../../../models/component-event-data";
import { DataStatus } from "../../../models/data-status";
import { DisplayStrategies } from "../../../models/display-strategies/time-series-display-strategies";
import { ITimeSeriesChartDisplayConfig } from "../../../models/i-view-config/i-base-display-config";
import { DataConnectorDescriptor } from "../../../models/store/data-connector-descriptor";
import { RuntimeSettingsSelector } from "../../../services/entity-selectors/runtime-settings.selector";
import { TimeSeriesDisplayService } from "../../../services/highcharts/time-series-display.service";
import { SimpleChartComponent } from "../simple-chart/simple-chart.component";

@Component({
  selector: "c-series",
  templateUrl: "./series.component.html",
  styleUrls: ["./series.component.scss"],
  host: { class: "simple-component" },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SeriesComponent extends SimpleChartComponent implements OnChanges {
  @Input() values: Maybe<DataConnectorDescriptor[]> = null;
  @Input() viewConfig: Maybe<ITimeSeriesChartDisplayConfig> = null;
  @Input() xRange: Partial<TimeRange> = {};
  @Input() displayType: DisplayStrategies = DisplayStrategies.Line;
  @Input() dataStatus: DataStatus = DataStatus.NoDataDefined;
  @Output() userZoom = new EventEmitter<TimeRangeEvent>();

  private previousViewConfig: Maybe<ITimeSeriesChartDisplayConfig> = null;
  isZoomed: boolean = false;
  displayService!: TimeSeriesDisplayService;

  constructor(
    ngZone: NgZone,
    protected dateFormatter: DateFormatterService,
    private appSettings: AppSettingsService,
    protected colorService: ColorListService,
    runtimeSettingsService: RuntimeSettingsSelector
  ) {
    super(ngZone, colorService, runtimeSettingsService);
    this.displayService = this.getDisplayService();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (isDefined(this.viewConfig) && this.displayService) {
      this.refreshDisplayServiceIfNeeded(changes["displayType"]);

      const fullRedraw =
        this.previousViewConfig != null &&
        this.displayService.requiresFullRedraw(this.previousViewConfig, this.viewConfig, changes);
      this.previousViewConfig = this.viewConfig;

      const callbacks: ComponentUserEventCallbacks = {
        zoom: (event) => this.raiseUserZoom(event)
      };

      const options = this.displayService.getChartOptions(
        this.viewConfig,
        this.xRange,
        this.values ?? [],
        this.dataStatus,
        callbacks
      );
      this.mergeChartOptions(options, fullRedraw, this.dataStatus);
    }
  }

  private refreshDisplayServiceIfNeeded(displayTypeChanges: Maybe<SimpleChange>): void {
    if (isDefined(displayTypeChanges)) {
      this.displayService = this.getDisplayService();
    }
  }

  private raiseUserZoom(event: ChartSelectionContextObject): void {
    const { min, max } = event.xAxis[0];
    if (isDefined(min) && isDefined(max)) {
      this.userZoom.emit({
        type: WidgetEventType.SelectTimeRangeEvent,
        start: new Date(min),
        end: new Date(max)
      });
      this.isZoomed = true;
    }
  }

  getDisplayService(): TimeSeriesDisplayService {
    let res: TimeSeriesDisplayService;
    switch (this.displayType) {
      case DisplayStrategies.Line:
        return new TimeSeriesDisplayService(
          "line",
          true,
          this.dateFormatter,
          this.appSettings,
          this.colorService
        );
      case DisplayStrategies.Area:
        return new TimeSeriesDisplayService(
          "area",
          true,
          this.dateFormatter,
          this.appSettings,
          this.colorService
        );
      case DisplayStrategies.Bar:
        return new TimeSeriesDisplayService(
          "bar",
          false,
          this.dateFormatter,
          this.appSettings,
          this.colorService
        );
      case DisplayStrategies.Column:
        return new TimeSeriesDisplayService(
          "column",
          false,
          this.dateFormatter,
          this.appSettings,
          this.colorService
        );

      default:
        throw new CriticalError(`Unknown gaugeType ${this.displayType}`);
    }
  }

  onResetZoom(): void {
    this.userZoom.emit({
      type: WidgetEventType.ResetTimeRangeEvent
    });
    this.isZoomed = false;
  }
}
