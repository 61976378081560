import { ChangeDetectorRef, Component, HostBinding, OnInit } from "@angular/core";
import { Actions, ofType } from "@ngrx/effects";
import { take } from "rxjs/operators";
import { SignalInfoDto } from "../../../data-connectivity/models/signal-info";
import { Dispatcher } from "../../../dispatcher";
import { LocalizationService } from "../../../i18n/localization.service";
import { TypeProvider } from "../../../meta";
import { OfType } from "../../../meta/decorators/of-type.decorator";
import { EditorType } from "../../../meta/models/editor-type";
import { BaseEditorComponent } from "../../../property-sheet/components/base-editor.component";
import { SignalBrowserDialogActions } from "../../../shared/dialogs/actions/signal-browser-dialog.actions";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";

@Component({
  selector: "signal-editor",
  templateUrl: "signal-editor.component.html",
  styleUrls: ["./signal-editor.component.scss"]
})
@OfType(EditorType.SignalBrowser)
export class SignalEditorComponent extends BaseEditorComponent implements OnInit {
  public selectedSignal: SignalInfoDto;
  signalName: string = "";
  @HostBinding("attr.title")
  public get tooltipText(): string {
    return this.tooltip;
  }

  constructor(
    private dispatcher: Dispatcher,
    private actions$: Actions,
    protected cdr: ChangeDetectorRef,
    protected typeProvider: TypeProvider,
    protected translationService: LocalizationService
  ) {
    super(cdr, typeProvider, translationService);
    this.signalName = "";
  }

  ngOnInit() {
    super.ngOnInit();
    this.selectedSignal = this.value;

    if (this.selectedSignal !== null && typeof this.selectedSignal !== "undefined") {
      this.signalName = this.selectedSignal.name.toString();
    } else {
      this.signalName = "";
    }
  }

  refreshValue(value): void {
    super.refreshValue(value);
    this.selectedSignal = value;
    this.signalName = isDefined(value) ? value.name.toString() : "";
  }

  // TODO this common logic can be moved into a superclass
  browse() {
    this.subscribeToDialogClose();
    this.openDialog();
  }

  private subscribeToDialogClose() {
    this.actions$
      .pipe(ofType(SignalBrowserDialogActions.onSignalBrowserDialogClosed), take(1))
      .subscribe(({ result }) => {
        if (result != null) {
          this.selectedSignal = result.dataSource.signal;
          this.signalName = this.selectedSignal.name.toString();
          this.onValueChanged(this.selectedSignal);
        }
      });
  }

  private openDialog() {
    this.dispatcher.dispatch(SignalBrowserDialogActions.openSignalBrowserDialog());
  }
}
