import { Dictionary } from "lodash";
import { QueryFilter } from "../../core";
import { AggregationFunction, AggregationType, DataConnectorDto } from "../../data-connectivity";
import { DataAggregationConfigDto } from "../../data-connectivity/models/data-aggregation-config";
import { EntityId } from "../../meta";
import { ComponentStateDto } from "../models/component-state";
import { QueryParamsResolverService } from "../services/query-params-resolver.service";

// query only every 0.5% of the filter time range
// If a trend has 1000 pixels, we would reload in chunks of 5 pixels.
const LIVE_MODE_QUERY_FILTER_TIME_FRACTION = 0.005;
export const LIVE_MODE_QUERY_INTERVAL_IN_SECONDS = 25;

export function filterHeartbeatConnectors(
  connectorsByComponent: Dictionary<DataConnectorDto[]>,
  queryFilter: QueryFilter,
  heartbeatNumber: number,
  currentTime: Date,
  queryParamsResolverService: QueryParamsResolverService
): Dictionary<DataConnectorDto[]> {
  return Object.keys(connectorsByComponent).reduce(
    (acc: Dictionary<DataConnectorDto[]>, componentId: EntityId) => {
      const componentConnectors = connectorsByComponent[componentId];
      const componentConnectorsForThisHeartbeat = componentConnectors.filter((connector) => {
        const timeAggregation: AggregationType =
          queryParamsResolverService.resolveConnectorAggregation(
            connector.dataSource.aggregationConfig,
            componentId,
            (aggregationConfig: DataAggregationConfigDto) =>
              aggregationConfig.timeAggregationFunction
          );
        return shouldQueryOnHeartbeat(queryFilter, heartbeatNumber, currentTime, timeAggregation);
      });
      if (componentConnectorsForThisHeartbeat.length > 0) {
        acc[componentId] = componentConnectorsForThisHeartbeat;
      }
      return acc;
    },
    {}
  );
}

export function filterHeartbeatComponents(
  componentsWithQuery: ComponentStateDto[],
  queryFilter: QueryFilter,
  heartbeatNumber: number,
  currentTime: Date
): ComponentStateDto[] {
  return componentsWithQuery.filter((component) =>
    shouldQueryOnHeartbeat(
      queryFilter,
      heartbeatNumber,
      currentTime,
      component.dataConnectorQuery.aggregationConfig.timeAggregationFunction
    )
  );
}

function shouldQueryOnHeartbeat(
  filter: QueryFilter,
  heartbeatNumber: number,
  currentTime: Date,
  timeAggregation: AggregationType
): boolean {
  if (
    timeAggregation !== AggregationFunction.None &&
    timeAggregation !== AggregationFunction.First &&
    timeAggregation !== AggregationFunction.Latest
  ) {
    const incrementIntervalInMs =
      getFilterDurationInMs(filter, currentTime) * LIVE_MODE_QUERY_FILTER_TIME_FRACTION;
    const incrementIntervalInHeartbeats =
      incrementIntervalInMs / (LIVE_MODE_QUERY_INTERVAL_IN_SECONDS * 1000);
    const shouldQuery: boolean =
      incrementIntervalInHeartbeats <= 1 ||
      heartbeatNumber % Math.floor(incrementIntervalInHeartbeats) === 0;
    return shouldQuery;
  } else {
    return true;
  }
}

function getFilterDurationInMs(filter: QueryFilter, currentTime: Date): number {
  const range = filter.timeRange;
  const end = range.to != null ? range.to : currentTime;
  return end.getTime() - range.from.getTime();
}
