import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { BaseDialogComponent } from "../../../shared/components/base-dialog/base-dialog.component";
import { IconId } from "../../models/icon-id";

interface IconSet {
  fontClass: string;
  start: number;
  end: number;
}

@Component({
  selector: "icon-browser",
  templateUrl: "icon-browser.component.html",
  styleUrls: ["icon-browser.component.scss"]
})
export class IconBrowserComponent extends BaseDialogComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<IconBrowserComponent>) {
    super();
  }

  private iconSet: IconSet = {
    fontClass: "abb-icon",
    start: 0xe900,
    end: 0xea3e
  };

  public enumeratedIcons: IconId[] = [];
  numRows = 5;

  ngOnInit() {
    this.enumeratedIcons = this.enumerateIcons();

    // cannot access ElementRef's width in ngOnInit.
    // and if we set numRows in ngAfterViewInit, we get ExpressionChangedAfterItHasBeenCheckedError
    const screenWidth = window.innerWidth;
    const iconBrowserWidth = 0.4 * screenWidth;
    const rows = Math.floor(iconBrowserWidth / 50);
    // show at least 3, at most 10 rows
    this.numRows = Math.max(3, Math.min(10, rows));
  }

  private enumerateIcons(): IconId[] {
    const res = this.enumerateIconSet(this.iconSet);
    return res;
  }

  private enumerateIconSet(set: IconSet): IconId[] {
    const numIcons = set.end - set.start + 1;
    return [...Array(numIcons).keys()].map((iconIndex) => {
      return { fontClass: set.fontClass, char: set.start + iconIndex } as IconId;
    });
  }

  public onIconClick(clickedIcon: IconId) {
    this.dialogRef.close(clickedIcon);
  }
}
