import { Component, Input, ViewChild } from "@angular/core";
import { toggleElement } from "../../../elements/helpers/dom-element-visibility.helper";
import { isDefined } from "../../../ts-utils";
import { ButtonConfig } from "../../models/button/button-config";
import { CustomButtonDropdownComponent } from "../custom-button-dropdown/custom-button-dropdown.component";

@Component({
  selector: "c-custom-button",
  template: ""
})
export class CustomButtonComponent {
  @Input() buttonConfig: ButtonConfig = new ButtonConfig();
  @ViewChild("menu") protected menu: CustomButtonDropdownComponent;

  public onClick(): void {
    if (isDefined(this.menu)) {
      toggleElement(this.menu.hostElementRef.nativeElement);
    }
    this.buttonConfig.clickFunction.execute();
  }
}
