import * as unitsCss from "units-css";
import { UNIT_PX } from "../../elements/helpers/column-width-validation.helper";
import { BaseViewConfigDto } from "../../elements/models/base-view-config";
import { ComponentStateDto } from "../../elements/models/component-state";
import { ComponentPositionUpdate } from "../../elements/models/resize/component-position-update";
import { SizeInPx } from "../../elements/models/size-in-px";
import { DomMapper } from "../../elements/services/dom-mapper.service";
import { EntityId } from "../../meta/models/entity";
import { first, last } from "../../ts-utils/helpers/array.helper";
import { isEmpty } from "../../ts-utils/helpers/is-empty.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";

export const LEFT: string = "left";
export const TOP: string = "top";

interface ComponentPositionDescriptor {
  componentId: EntityId;
  left: number;
  top: number;
  width: number;
  height: number;
}

export type PositionsCalculationFunction = (
  components: ComponentStateDto[]
) => ComponentPositionUpdate[];

export enum AlignmentAndDistribution {
  LeftAlign,
  Center,
  RightAlign,
  TopAlign,
  Middle,
  BottomAlign,
  HorizontalDistribute,
  VerticalDistribute
}

export const AlignAndDistributeIconDict: { [key in AlignmentAndDistribution]: string } = {
  [AlignmentAndDistribution.LeftAlign]: "Align_right",
  [AlignmentAndDistribution.Center]: "Align_vertical_center",
  [AlignmentAndDistribution.RightAlign]: "Align_left",
  [AlignmentAndDistribution.TopAlign]: "Align_top",
  [AlignmentAndDistribution.Middle]: "Align_horizontal_center",
  [AlignmentAndDistribution.BottomAlign]: "Align_bottom",
  [AlignmentAndDistribution.HorizontalDistribute]: "Sld_1",
  [AlignmentAndDistribution.VerticalDistribute]: "System"
};

export function getPositionsForLeftAlignment(
  selectedComponents: ComponentStateDto[]
): ComponentPositionUpdate[] {
  const positionDescriptors: ComponentPositionDescriptor[] =
    calculatePositionDescriptors(selectedComponents);
  const minLeft: number = calculateMinLeft(positionDescriptors);
  return positionDescriptors.map(
    (descriptor) =>
      ({
        componentId: descriptor.componentId,
        offsetLeft: minLeft,
        offsetTop: descriptor.top
      } as ComponentPositionUpdate)
  );
}

export function getPositionsForCenterAlignment(
  selectedComponents: ComponentStateDto[]
): ComponentPositionUpdate[] {
  const positionDescriptors: ComponentPositionDescriptor[] =
    calculatePositionDescriptors(selectedComponents);
  const center: number =
    (calculateMinLeft(positionDescriptors) + calculateMaxRight(positionDescriptors)) / 2;
  return positionDescriptors.map(
    (descriptor) =>
      ({
        componentId: descriptor.componentId,
        offsetLeft: center - descriptor.width / 2,
        offsetTop: descriptor.top
      } as ComponentPositionUpdate)
  );
}

function calculateMinLeft(positionDescriptors: ComponentPositionDescriptor[]): number {
  const leftDistances: number[] = positionDescriptors.map((descriptor) => descriptor.left);
  return Math.min(...leftDistances);
}

export function getPositionsForRightAlignment(
  selectedComponents: ComponentStateDto[]
): ComponentPositionUpdate[] {
  const positionDescriptors: ComponentPositionDescriptor[] =
    calculatePositionDescriptors(selectedComponents);
  const maxRight: number = calculateMaxRight(positionDescriptors);
  return positionDescriptors.map(
    (descriptor) =>
      ({
        componentId: descriptor.componentId,
        offsetLeft: maxRight - descriptor.width,
        offsetTop: descriptor.top
      } as ComponentPositionUpdate)
  );
}

function calculateMaxRight(positionDescriptors: ComponentPositionDescriptor[]): number {
  const rightEdgeDistances: number[] = positionDescriptors.map(
    (descriptor) => descriptor.left + descriptor.width
  );
  return Math.max(...rightEdgeDistances);
}

export function getPositionsForTopAlignment(
  selectedComponents: ComponentStateDto[]
): ComponentPositionUpdate[] {
  const positionDescriptors: ComponentPositionDescriptor[] =
    calculatePositionDescriptors(selectedComponents);
  const minTop: number = calculateMinTop(positionDescriptors);
  return positionDescriptors.map(
    (descriptor) =>
      ({
        componentId: descriptor.componentId,
        offsetLeft: descriptor.left,
        offsetTop: minTop
      } as ComponentPositionUpdate)
  );
}

export function getPositionsForMiddleAlignment(
  selectedComponents: ComponentStateDto[]
): ComponentPositionUpdate[] {
  const positionDescriptors: ComponentPositionDescriptor[] =
    calculatePositionDescriptors(selectedComponents);
  const middle: number =
    (calculateMinTop(positionDescriptors) + calculateMaxBottom(positionDescriptors)) / 2;
  return positionDescriptors.map(
    (descriptor) =>
      ({
        componentId: descriptor.componentId,
        offsetLeft: descriptor.left,
        offsetTop: middle - descriptor.height / 2
      } as ComponentPositionUpdate)
  );
}

function calculateMinTop(positionDescriptors: ComponentPositionDescriptor[]): number {
  const topDistances: number[] = positionDescriptors.map((descriptor) => descriptor.top);
  return Math.min(...topDistances);
}

export function getPositionsForBottomAlignment(
  selectedComponents: ComponentStateDto[]
): ComponentPositionUpdate[] {
  const positionDescriptors: ComponentPositionDescriptor[] =
    calculatePositionDescriptors(selectedComponents);
  const maxBottom: number = calculateMaxBottom(positionDescriptors);
  return positionDescriptors.map(
    (descriptor) =>
      ({
        componentId: descriptor.componentId,
        offsetLeft: descriptor.left,
        offsetTop: maxBottom - descriptor.height
      } as ComponentPositionUpdate)
  );
}

function calculateMaxBottom(positionDescriptors: ComponentPositionDescriptor[]): number {
  const bottomEdgeDistances: number[] = positionDescriptors.map(
    (descriptor) => descriptor.top + descriptor.height
  );
  return Math.max(...bottomEdgeDistances);
}

export function getPositionsForHorizontalDistribution(
  selectedComponents: ComponentStateDto[]
): ComponentPositionUpdate[] {
  const positionDescriptors: ComponentPositionDescriptor[] =
    calculatePositionDescriptors(selectedComponents);
  positionDescriptors.sort((a, b) => a.left - b.left);
  if (isEmpty(positionDescriptors)) {
    return [];
  }
  const gapSize: number = calculateHorizontalGapSize(positionDescriptors);
  return positionDescriptors.reduce((acc: ComponentPositionUpdate[], descriptor, index) => {
    if (index === 0) {
      acc.push({
        componentId: descriptor.componentId,
        offsetLeft: descriptor.left,
        offsetTop: descriptor.top
      });
    } else {
      const newLeft: number = last(acc).offsetLeft + positionDescriptors[index - 1].width + gapSize;
      if (newLeft >= 0) {
        acc.push({
          componentId: descriptor.componentId,
          offsetLeft: newLeft,
          offsetTop: descriptor.top
        });
      }
    }
    return acc;
  }, []);
}

function calculateHorizontalGapSize(positionDescriptors: ComponentPositionDescriptor[]): number {
  const totalWidth: number = positionDescriptors.reduce(
    (acc, descriptor) => acc + descriptor.width,
    0
  );
  const widthRange: number =
    last(positionDescriptors).left +
    last(positionDescriptors).width -
    first(positionDescriptors).left;
  return (widthRange - totalWidth) / (positionDescriptors.length - 1);
}

export function getPositionsForVerticalDistribution(
  selectedComponents: ComponentStateDto[]
): ComponentPositionUpdate[] {
  const positionDescriptors: ComponentPositionDescriptor[] =
    calculatePositionDescriptors(selectedComponents);
  positionDescriptors.sort((a, b) => a.top - b.top);
  if (isEmpty(positionDescriptors)) {
    return [];
  }
  const gapSize: number = calculateVerticalGapSize(positionDescriptors);
  return positionDescriptors.reduce((acc: ComponentPositionUpdate[], descriptor, index) => {
    if (index === 0) {
      acc.push({
        componentId: descriptor.componentId,
        offsetLeft: descriptor.left,
        offsetTop: descriptor.top
      });
    } else {
      const newTop: number = last(acc).offsetTop + positionDescriptors[index - 1].height + gapSize;
      if (newTop >= 0) {
        acc.push({
          componentId: descriptor.componentId,
          offsetLeft: descriptor.left,
          offsetTop: newTop
        });
      }
    }
    return acc;
  }, []);
}

function calculateVerticalGapSize(positionDescriptors: ComponentPositionDescriptor[]): number {
  const totalHeight: number = positionDescriptors.reduce(
    (acc, descriptor) => acc + descriptor.height,
    0
  );
  const heightRange: number =
    last(positionDescriptors).top +
    last(positionDescriptors).height -
    first(positionDescriptors).top;
  return (heightRange - totalHeight) / (positionDescriptors.length - 1);
}

function calculatePositionDescriptors(
  components: ComponentStateDto[]
): ComponentPositionDescriptor[] {
  return components.reduce((acc: ComponentPositionDescriptor[], component: ComponentStateDto) => {
    const element: Maybe<HTMLElement> = getComponentHost(component.id);
    const view: BaseViewConfigDto = component.view;
    const size: SizeInPx = view.runtimeView.runtimeSize;
    let descriptor: ComponentPositionDescriptor;
    try {
      descriptor = {
        componentId: component.id,
        left: unitsCss.convert(UNIT_PX, view.css.left, element, LEFT),
        top: unitsCss.convert(UNIT_PX, view.css.top, element, TOP),
        width: size.widthInPx,
        height: size.heightInPx
      } as ComponentPositionDescriptor;
    } catch (exception) {
      return acc;
    }
    return acc.concat([descriptor]);
  }, []);
}

export function getComponentHost(componentId: EntityId): Maybe<HTMLElement> {
  const id: string = DomMapper.getHostId(componentId);
  return document.getElementById(id);
}
