import { Action } from "@ngrx/store";
import * as unitsCss from "units-css";
import { SizeMeasurement } from "../../core/models/size-measurement";
import { EntityId } from "../../meta";
import { DeepPartial } from "../../ts-utils/models/deep-partial.type";
import { RectangleDto } from "../models";
import { ComponentCssSize } from "../models/component-size";
import {
  cssSizeParser,
  FULL_HEIGHT_CSS_SIZE,
  FULL_WIDTH_CSS_SIZE,
  PX_AUTOFILL_KEY
} from "../models/component-size.constants";
import { SizeInPx } from "../models/size-in-px";
import { ComponentStateActions } from "../store/component-state/component-state.actions";
import { UNIT_PERCENTAGE, UNIT_PX } from "./column-width-validation.helper";

export const FULL_HEIGHT_PAGE_CSS = {
  height: "auto",
  minHeight: "100%"
};

export function isFullWidth(size: ComponentCssSize): boolean {
  return size.width === FULL_WIDTH_CSS_SIZE;
}

export function isFullHeight(size: ComponentCssSize): boolean {
  return size.height === FULL_HEIGHT_CSS_SIZE;
}

export function calculateNumberOfPointsByLengthInPix(
  length: number,
  useLowResolution: boolean
): number {
  const pixelsPerDataPoint = getPixelsPerDataPoint(useLowResolution);
  const numPts = calculateNumPointsLimited(length, pixelsPerDataPoint);
  return numPts;
}

function getPixelsPerDataPoint(useLowResolution: boolean): number {
  return useLowResolution ? 20 : 5;
}

function calculateNumPointsLimited(length: number, pixelsPerDataPoint: number): number {
  return Math.min(Math.round(length / pixelsPerDataPoint), 500);
}

export function convertCssSizeToPx(cssSize: ComponentCssSize, availableSpace: SizeInPx): SizeInPx {
  let width: number = 0;
  let height: number = 0;

  const parsedHeight: SizeMeasurement = cssSizeParser(cssSize.height);
  const parsedWidth: SizeMeasurement = cssSizeParser(cssSize.width);

  if (parsedHeight.unit === UNIT_PERCENTAGE) {
    height = (parsedHeight.value / 100) * availableSpace.heightInPx;
  } else {
    height = unitsCss.convert(UNIT_PX, cssSize.height, undefined, PX_AUTOFILL_KEY);
  }

  if (parsedWidth.unit === UNIT_PERCENTAGE) {
    width = (parsedWidth.value / 100) * availableSpace.widthInPx;
  } else {
    width = unitsCss.convert(UNIT_PX, cssSize.width, undefined, PX_AUTOFILL_KEY);
  }
  return { heightInPx: height, widthInPx: width };
}

export function calculateNewComponentSize(
  componentSize: ComponentCssSize,
  newComponentRect: RectangleDto,
  parentContentSize: SizeInPx
): ComponentCssSize {
  const width = Math.floor(newComponentRect.width);
  const height = Math.floor(newComponentRect.height);

  return new ComponentCssSize(
    preserveDimensionUnit(width, componentSize.width, parentContentSize.widthInPx),
    preserveDimensionUnit(height, componentSize.height, parentContentSize.heightInPx)
  );
}

function preserveDimensionUnit(
  newDimension: number,
  oldDimension: string,
  parentDimension: number
): string {
  const originalUnit = cssSizeParser(oldDimension).unit;
  if (originalUnit === UNIT_PERCENTAGE) {
    return ((100 * newDimension) / parentDimension).toFixed(2).toString() + UNIT_PERCENTAGE;
  } else {
    return (
      unitsCss
        .convert(originalUnit, newDimension, undefined, PX_AUTOFILL_KEY)
        .toFixed(2)
        .toString() + originalUnit
    );
  }
}

export function getSizeUpdateAction(
  sizeUpdate: DeepPartial<ComponentCssSize>,
  componentId: EntityId
): Action {
  return ComponentStateActions.updateComponentSize({
    componentId,
    height: sizeUpdate.height,
    width: sizeUpdate.width
  });
}
