import "reflect-metadata";
import {
  ComponentMetadata,
  ComponentMetadataService
} from "../../data-connectivity/services/component-metadata.service";
import { TypeProvider } from "../../meta/services/type-provider";

const TYPE_PROVIDER = TypeProvider.getInstance();
const METADATA_PROVIDER = ComponentMetadataService.getInstance();

export function MaxConnectors(maxNumberOfConnectors: number) {
  return function (typeConstructor: Function) {
    const typeDescriptor = TYPE_PROVIDER.addType({
      constructorFunction: typeConstructor as new () => any
    });
    const componentTypeName = typeDescriptor.name;
    const newMetadata: Partial<ComponentMetadata> = { maxNumberOfConnectors };
    METADATA_PROVIDER.addOrUpdate(componentTypeName, newMetadata);
  };
}
