import { v4 as uuid } from "uuid";
import { construct } from "../../core/services/construct.helper";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import { Configurable } from "../../meta/decorators/configurable.decorator";
import { EditableType } from "../../meta/decorators/editable-type.decorator";
import { AllowInterpolation } from "../../meta/decorators/interpolation.decorator";
import { Key } from "../../meta/decorators/key.decorator";
import { Serializable } from "../../meta/decorators/serializable.decorator";
import { Title } from "../../meta/decorators/title.decorator";
import { EditorType } from "../../meta/models/editor-type";
import { validateNumberOrInterpolatedString } from "../../property-sheet/helpers/number-validation.helper";
import { DeepPartial } from "../../ts-utils/models/deep-partial.type";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { DEFAULT_Y_AXIS_INTERPOLATED_TITLE } from "./property-interpolation.constants";

const TYPE_NAME = "YAxisDescriptor";

@EditableType({ fullName: TYPE_NAME })
export class YAxisDescriptor {
  @Key()
  @Serializable()
  id: string;

  @Title()
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Title,
    editorType: EditorType.TextBox
  })
  @Serializable(DEFAULT_Y_AXIS_INTERPOLATED_TITLE)
  axisTitle!: string;

  @Configurable({
    editorType: EditorType.TextBox,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.MinimumValue,
    validationFunction: validateNumberOrInterpolatedString
  })
  @Serializable(null)
  @AllowInterpolation()
  min!: Maybe<string>;

  @Configurable({
    editorType: EditorType.TextBox,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.MaximumValue,
    validationFunction: validateNumberOrInterpolatedString
  })
  @Serializable(null)
  @AllowInterpolation()
  max!: Maybe<string>;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Color,
    editorType: EditorType.ColorPicker
  })
  @Serializable()
  color!: string;

  isHidden: boolean = false;

  constructor(yAxisDescriptor: DeepPartial<YAxisDescriptor> = {}) {
    construct(this, yAxisDescriptor, TYPE_NAME, { id: uuid() });
  }
}
