import { Update } from "@ngrx/entity";
import { EntityId } from "../../meta";
import { Maybe } from "../../ts-utils";
import { ComponentStateDto } from "../models";

export function createCacheUpdateObject(
  componentId: Maybe<EntityId>,
  cacheMode: Maybe<boolean>,
  timestamp: Maybe<Date>
): Maybe<Update<ComponentStateDto>> {
  if (componentId == null || cacheMode == null || timestamp == null) {
    return;
  }
  return {
    id: componentId.toString(),
    changes: {
      cache: { enabled: cacheMode, currentTimestamp: timestamp }
    }
  } as Update<ComponentStateDto>;
}
