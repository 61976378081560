import { Action, createReducer, on } from "@ngrx/store";
import { ReportId } from "../../../core/models/report-id";
import { isDefined, Maybe } from "../../../ts-utils";
import { isEmpty } from "../../../ts-utils/helpers/is-empty.helper";
import { ReportConfiguration } from "../../models";
import { CommonActions } from "../common";
import { ReportInfoActions } from "./report-info.actions";

export interface ReportInfoState {
  id: ReportId;
  name: string;
  dirty: boolean;
  readOnly: boolean;
  ancestors: string[];
  description: string;
  aliasName: string;
}

export const initialState: ReportInfoState = {
  id: undefined,
  name: "",
  dirty: false,
  readOnly: false, // temp, until RDS provides this info
  ancestors: [],
  description: "",
  aliasName: ""
};

export function reducer(state: ReportInfoState, action: Action): ReportInfoState {
  return _reducer(state, action);
}

const _reducer = createReducer(
  initialState,
  on(ReportInfoActions.updateDirty, (state, { isDirty }) => updateDirty(state, isDirty)),
  on(CommonActions.resetStore, (state, { reportConfiguration }) =>
    setStateOrDefault(state, reportConfiguration)
  )
);

function updateDirty(state: ReportInfoState, newDirtyValue: boolean): ReportInfoState {
  return { ...state, dirty: newDirtyValue };
}

function setStateOrDefault(
  state: ReportInfoState,
  reportConfiguration: ReportConfiguration
): ReportInfoState {
  const isReadOnly = reportConfiguration.readOnly ?? state.readOnly;
  const reportId = reportConfiguration.id ?? state.id;
  const aliasName = reportConfiguration.aliasName ?? state.aliasName;
  const description = reportConfiguration.description ?? state.description;
  const name = getReportName(reportConfiguration.name, reportConfiguration.id);
  return {
    ...initialState,
    readOnly: isReadOnly,
    id: reportId,
    name,
    aliasName,
    description
  };
}

function getReportName(reportName: Maybe<string>, reportId: ReportId): string {
  return isDefined(reportName) && !isEmpty(reportName) ? reportName : reportId.toString();
}
