import { ComponentCssSize } from "../../models/component-size";
import { SingleValueViewConfig } from "./view-config";

export const DEFAULT_OVERRIDES_FOR_HORIZONTAL_GAUGE: Partial<SingleValueViewConfig> = {
  size: new ComponentCssSize("500", "150")
};
export const DEFAULT_OVERRIDES_FOR_RADIAL_GAUGE: Partial<SingleValueViewConfig> = {
  size: new ComponentCssSize("300", "200")
};

export const DEFAULT_OVERRIDES_FOR_CIRCLE_VALUE: Partial<SingleValueViewConfig> = {
  size: new ComponentCssSize("100", "100")
};

export const DEFAULT_OVERRIDES_FOR_VERTICAL_GAUGE: Partial<SingleValueViewConfig> = {
  size: new ComponentCssSize("150", "400")
};
