import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { WebServicesConfiguration } from "ui-core";

@Injectable({
  providedIn: "root"
})
export class RoleService {
  constructor(private http: HttpClient, private apiConfig: WebServicesConfiguration) {}

  getEditorPermission(): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiConfig.itemsServiceUrl}/access-management/me/editor`);
  }
}
