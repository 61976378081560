import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ReportInfo } from "projects/ui-core/src/lib/elements/models/report-info";
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import {
  Dispatcher,
  LocalizationService,
  ReportConfiguration,
  ReportDescription,
  ReportsService,
  WebServicesConfiguration,
  plainTextToDashCase
} from "ui-core";
import { CriticalError } from "../../../../../ui-core/src/lib/ts-utils/models/critical-error";
import { AzureQueryStringService } from "../api/azure-query-string.service";
import { AzureAPIConfigurationService } from "./azure-api-configuration.service";

@Injectable()
export class AzureReportsService extends ReportsService {
  private azureApi: AzureAPIConfigurationService;
  private readonly queryStringService: AzureQueryStringService;

  constructor(
    http: HttpClient,
    api: WebServicesConfiguration,
    dispatcher: Dispatcher,
    translationService: LocalizationService,
    queryStringService: AzureQueryStringService
  ) {
    super(http, api, dispatcher, translationService);
    this.queryStringService = queryStringService;

    if (api instanceof AzureAPIConfigurationService) {
      this.azureApi = api as AzureAPIConfigurationService;
    } else {
      throw new CriticalError("Invalid dependency injected");
    }
  }

  // if using document db, ancestors are already in the report configuration
  setAncestorsForReport(report: ReportConfiguration): Observable<ReportConfiguration> {
    return of(report);
  }

  getAllReports(): Observable<ReportDescription[]> {
    return this.http
      .get<ReportInfo[]>(
        `${this.api.allReportsUrl}/${this.queryStringService.getPlantName()}/reports/info`
      )
      .pipe(
        switchMap((reports) => {
          const reportsIds: ReportDescription[] = reports.map((report) => {
            return {
              reportId: report.id,
              reportName: report.name || report.id.toString()
            };
          });
          return of(reportsIds);
        })
      );
  }

  getUniqueIdentifier(reportName: string): string {
    return plainTextToDashCase(reportName);
  }
}
