import { createAction, props } from "@ngrx/store";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { ApiDataSourceDescriptor } from "../../models/data-source/data-source-descriptors";

export namespace ApiDataSourceDescriptorActions {
  export const loadSuccess = createAction(
    "[API Descriptors] Fetch Success",
    props<{ descriptors: Maybe<ApiDataSourceDescriptor[]> }>()
  );
}
