<div class="live-mode-filter__container">
  <span class="live-mode-filter__label">{{ localizer.filterComponent.Last | translate }}</span>
  <input
    #timeAmountInput
    class="live-mode-filter__input"
    [ngClass]="{ error: isInvalidTimeAmount, 'editor--value-configured': !isDefaultTimeRange }"
    type="number"
    min="0"
    [value]="liveModeFilter.amount"
    (input)="amountUpdate.next($event.target.value)"
  />
  <selector
    class="live-mode-filter__selector"
    [items]="timeUnits"
    [selectedItem]="liveModeFilter.unit"
    (onChangeValue)="onChangeTimeUnit($event)"
    [distinctDefault]="!isDefaultTimeRange"
  ></selector>
</div>
