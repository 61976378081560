import { Update } from "@ngrx/entity";
import { DataPointValue } from "../../data-connectivity/models";
import { EntityId } from "../../meta/models/entity";
import { Dictionary, isDefined, isEmpty, Maybe } from "../../ts-utils";
import { ITextualValueViewConfig } from "../components/simple-components/textual-value/i-textual-value-view-config";
import { BaseViewConfigDto, DataStatus } from "../models";
import {
  BASE16,
  BLACK_COLOR_HEX,
  BLUE_INDEX,
  BLUE_WEIGHT,
  EXTRACTION_START_INDEX,
  FULL_OPACITY,
  GREEN_INDEX,
  GREEN_WEIGHT,
  OPACITY_FACTOR,
  OPACITY_INDEX,
  RED_COLOR_HEX,
  RED_INDEX,
  RED_WEIGHT,
  WHITE_COLOR_HEX,
  WHITE_COLOR_THRESHOLD
} from "../models/colors.constants";
import { ComponentStateDto } from "../models/component-state";
import { isBasicCard, isTabGroup } from "../models/component-type.helper";
import { IGaugeDisplayConfig } from "../models/i-view-config/i-gauge-display-config";
import { LimitsDto } from "../models/limits";
import { LimitMarkerHelper } from "../services/limit.helper";

export const COLOR_PICKER_DIALOG_WIDTH = 228;
export const COLOR_PICKER_DIALOG_HEIGHT = 331;
const RGBA_FORMAT_REGEX = /(.*?)(rgb|rgba)\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)/;
const HEX_COLOR_FORMAT = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

export function getComponentsWithForegroundColor(
  targetComponent: ComponentStateDto,
  componentDict: Dictionary<ComponentStateDto>,
  foregroundColor: string
): Update<ComponentStateDto>[] {
  const updatedTargetComponent: Update<ComponentStateDto> = updateComponentForegroundColor(
    targetComponent.id,
    componentDict[targetComponent.id].view,
    foregroundColor
  );
  if (isBasicCard(targetComponent.type)) {
    return updateComponentsInsideBasicCard(
      targetComponent.id,
      componentDict,
      foregroundColor
    ).concat(updatedTargetComponent);
  }
  if (isTabGroup(targetComponent.type)) {
    return updateComponentsInsideTabGroup(targetComponent.id, componentDict, foregroundColor);
  }
  return [];
}

function updateComponentsInsideBasicCard(
  basicCardId: EntityId,
  componentDict: Dictionary<ComponentStateDto>,
  foregroundColor: string
): Update<ComponentStateDto>[] {
  return componentDict[basicCardId].childrenIds.reduce(
    (acc: Update<ComponentStateDto>[], childId: EntityId) => {
      if (isEmpty(componentDict[childId].view.css.backgroundColor)) {
        if (!isEmpty(componentDict[childId].childrenIds)) {
          acc.push(...updateComponentsInsideTabGroup(childId, componentDict, foregroundColor));
        } else {
          acc.push(
            updateComponentForegroundColor(childId, componentDict[childId].view, foregroundColor)
          );
        }
      }
      return acc;
    },
    []
  );
}

function updateComponentsInsideTabGroup(
  tabGroupId: EntityId,
  componentDict: Dictionary<ComponentStateDto>,
  foregroundColor: string
): Update<ComponentStateDto>[] {
  return componentDict[tabGroupId].childrenIds.reduce(
    (acc: Update<ComponentStateDto>[], tabContentId: EntityId) => {
      if (isEmpty(componentDict[tabContentId].view.css.backgroundColor)) {
        acc.push(
          updateComponentForegroundColor(
            tabContentId,
            componentDict[tabContentId].view,
            foregroundColor
          )
        );
        acc.push(
          ...updateComponentsInsideTabContent(
            componentDict[tabContentId].childrenIds,
            componentDict,
            foregroundColor
          )
        );
      }
      return acc;
    },
    [updateComponentForegroundColor(tabGroupId, componentDict[tabGroupId].view, foregroundColor)]
  );
}

function updateComponentsInsideTabContent(
  childrenIds: EntityId[],
  componentDict: Dictionary<ComponentStateDto>,
  foregroundColor: string
): Update<ComponentStateDto>[] {
  return childrenIds.reduce((acc: Update<ComponentStateDto>[], childId: EntityId) => {
    if (isEmpty(componentDict[childId].view.css.backgroundColor)) {
      acc.push(
        updateComponentForegroundColor(childId, componentDict[childId].view, foregroundColor)
      );
    }
    return acc;
  }, []);
}

export function updateComponentForegroundColor(
  componentId: EntityId,
  componentView: BaseViewConfigDto,
  foregroundColor: string
): Update<ComponentStateDto> {
  return {
    id: componentId.toString(),
    changes: {
      view: {
        ...componentView,
        foregroundColor
      }
    }
  };
}

export function getConfiguredOrForegroundColor(
  configuredColor: string,
  foregroundColor: string
): string {
  return areColorsCompatible(configuredColor, foregroundColor) ? configuredColor : foregroundColor;
}

export function areColorsCompatible(configuredColor: string, foregroundColor: string): boolean {
  return getNeutralColorForText(configuredColor, null) !== foregroundColor;
}

export function getNeutralColorForText(
  color: string,
  parentBackgroundColor: Maybe<string>
): string {
  const backgroundColor: string =
    isEmpty(color) && isDefined(parentBackgroundColor) ? parentBackgroundColor : color;

  if (backgroundColor === "white") {
    return BLACK_COLOR_HEX;
  }

  return backgroundColor.includes("#")
    ? getNeutralColorFromHexColor(backgroundColor)
    : getNeutralColorFromRGBA(backgroundColor);
}

function getNeutralColorFromHexColor(hexColor: string): string {
  const parsedColor = hexColor.replace("#", "");

  const red = parseInt(parsedColor.substr(0, 2), BASE16);
  const green = parseInt(parsedColor.substr(2, 2), BASE16);
  const blue = parseInt(parsedColor.substr(4, 2), BASE16);
  return resolveNeutralColorForText(red, green, blue, FULL_OPACITY);
}

function getNeutralColorFromRGBA(rgbaColor: string): string {
  const valuesFromRGBA = getValuesFromRgba(rgbaColor);

  const red = Number(valuesFromRGBA[RED_INDEX]);
  const green = Number(valuesFromRGBA[GREEN_INDEX]);
  const blue = Number(valuesFromRGBA[BLUE_INDEX]);
  const opacity = Number(valuesFromRGBA[OPACITY_INDEX]);

  return resolveNeutralColorForText(red, green, blue, opacity);
}

export function getValuesFromRgba(rgbaColor: string): string[] {
  const separator: string = rgbaColor.indexOf(",") > -1 ? "," : " ";
  return rgbaColor.substr(EXTRACTION_START_INDEX).split(")")[0].split(separator);
}

function resolveNeutralColorForText(
  red: number,
  green: number,
  blue: number,
  opacity: number
): string {
  const brightness =
    red * RED_WEIGHT + green * GREEN_WEIGHT + blue * BLUE_WEIGHT + (1 - opacity) * OPACITY_FACTOR;
  return brightness >= WHITE_COLOR_THRESHOLD ? BLACK_COLOR_HEX : WHITE_COLOR_HEX;
}

export function getTextColorForSingleValue(
  singleValue: DataPointValue,
  viewConfig: ITextualValueViewConfig | IGaugeDisplayConfig
): string {
  const primaryOrLimitColor: string = getLimitOrPrimaryColor(
    singleValue,
    viewConfig.limits,
    viewConfig.primaryColor
  );
  return getConfiguredOrForegroundColor(primaryOrLimitColor, viewConfig.foregroundColor);
}

export function getLimitOrPrimaryColor(
  singleValue: DataPointValue,
  limits: LimitsDto,
  primaryColor: string
): string {
  const limitColor: Maybe<string> = new LimitMarkerHelper(limits).getValuesLimitColor(
    singleValue,
    limits
  );
  return limitColor ?? primaryColor;
}

export function isValidColorFormat(color: string): boolean {
  return isRgbaFormat(color) || isHexFormat(color);
}

export function isRgbaFormat(color: string): boolean {
  return RGBA_FORMAT_REGEX.test(color);
}

export function isHexFormat(color: string): boolean {
  return HEX_COLOR_FORMAT.test(color);
}

export function getTextColorForNoDataStatus(
  dataStatus: Maybe<DataStatus>,
  textColor: string
): string {
  const statusTextColor: string = getConfiguredOrForegroundColor(RED_COLOR_HEX, textColor);
  return dataStatus === DataStatus.RequestFailed ? statusTextColor : textColor;
}
