import { COMPONENT_STATE_VIEW_MODEL } from "../../elements/models/entity-type.constants";
import { EntityId } from "../models/entity";
import { UpdatedEntitiesInfo } from "../models/updated-entities-info";

export function createUpdatedComponentsInfo(entityIds: EntityId[]): UpdatedEntitiesInfo {
  return {
    entityIds,
    entitiesTypeName: COMPONENT_STATE_VIEW_MODEL
  };
}
