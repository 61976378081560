import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { TimeRange } from "../../../core/models/time-range";
import { HISTORY_VIEW_CONSTANTS as CONSTANTS } from "../../../elements/components/history-view/history-view.constants";
import { DateFormatterService } from "../../../environment/services/date-formatter.service";
import { isDefined, isNotDefined, Maybe } from "../../../ts-utils";
import {
  calculateCurrentDateFormat,
  calculateStartEndDateFormat,
  visualizeSliderProgress
} from "../../helpers/time-scrubber.helper";
@Component({
  selector: "c-time-scrubber",
  templateUrl: "./time-scrubber.component.html",
  styleUrls: ["./time-scrubber.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeScrubberComponent
  implements OnInit, AfterContentChecked, OnChanges, OnDestroy, AfterViewInit
{
  @Input() dateTimeRange: TimeRange | null = null;

  @Output() timestampChanged: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() playbackEnded: EventEmitter<void> = new EventEmitter<void>();

  startDate: Date = new Date();
  endDate: Date = new Date();
  playbackInterval: Maybe<NodeJS.Timeout>;
  currentDate: Date = new Date();
  startEndDateFormat: string = "";
  currentDateFormat: string = "";
  bubbleLeftOffset: number = 0;
  @Input() stepsCount: number = CONSTANTS.TIME_SLIDER_STEPS;
  @ViewChild("sliderInput") sliderInputRef!: ElementRef<HTMLInputElement>;
  @ViewChild("bubble") currentDateBubbleRef!: ElementRef<HTMLOutputElement>;
  private isSliderDragged: boolean = false;
  constructor(
    private cdr: ChangeDetectorRef,
    private dateFormatterService: DateFormatterService,
    private hostElementRef: ElementRef<HTMLElement>
  ) {}

  ngOnInit(): void {
    this.onDateTimeRangeUpdate();
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    this.updateBubbleOffset();
    this.initSliderEvents();
  }

  private initSliderEvents(): void {
    this.sliderInputRef.nativeElement.onmousedown = () => {
      this.isSliderDragged = true;
    };
    this.sliderInputRef.nativeElement.onmouseup = () => {
      this.isSliderDragged = false;
    };
    this.sliderInputRef.nativeElement.oninput = () => {
      this.sliderValueChanged();
    };
  }

  ngOnDestroy(): void {
    this.endPlayback();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["dateTimeRange"] && changes["dateTimeRange"].currentValue) {
      if (isNotDefined(this.sliderInputRef)) {
        return;
      }
      this.onDateTimeRangeUpdate();
      this.endPlayback();
      this.sliderInputRef.nativeElement.value = "0";
      visualizeSliderProgress(this.sliderInputRef.nativeElement);
      this.timestampChanged.emit(this.endDate);
      this.updateBubbleOffset();
    }
  }

  onDateTimeRangeUpdate(): void {
    if (isDefined(this.dateTimeRange)) {
      this.startDate = this.dateTimeRange.from;
      this.endDate = this.dateTimeRange.to;
      this.currentDate = this.startDate;
      this.startEndDateFormat = calculateStartEndDateFormat(
        this.startDate,
        this.endDate,
        this.dateFormatterService
      );
      this.currentDateFormat = calculateCurrentDateFormat(
        this.startDate,
        this.endDate,
        this.dateFormatterService
      );
    }
  }

  sliderValueChanged(): void {
    visualizeSliderProgress(this.sliderInputRef.nativeElement);
    this.currentDate = this.convertSliderValueToDate(
      parseInt(this.sliderInputRef.nativeElement.value)
    );
    this.updateBubbleOffset();
    this.timestampChanged.emit(this.currentDate);
  }

  public updateBubbleOffset(): void {
    this.bubbleLeftOffset = this.calculateBubbleOffset();
    this.cdr.detectChanges();
  }

  startPlayback(): void {
    this.playbackInterval = setInterval(() => {
      if (!this.isSliderDragged) {
        this.stepFoward();
        const sliderElement = this.sliderInputRef.nativeElement;
        if (sliderElement.value === sliderElement.max) {
          this.moveSliderToStart();
        }
      }
    }, 400);
  }

  moveSliderToStart(): void {
    this.endPlayback();
    this.sliderInputRef.nativeElement.value = "0";
    visualizeSliderProgress(this.sliderInputRef.nativeElement);
    this.currentDate = this.startDate;
    this.updateBubbleOffset();
  }

  endPlayback(): void {
    if (isDefined(this.playbackInterval)) {
      clearInterval(this.playbackInterval);
    }
    this.playbackEnded.emit();
  }

  stepBackwards(): void {
    this.sliderInputRef.nativeElement.stepDown();
    this.sliderValueChanged();
  }

  stepFoward(): void {
    this.sliderInputRef.nativeElement.stepUp();
    this.sliderValueChanged();
  }

  convertSliderValueToDate(sliderValue: number): Date {
    const timeRangeDiffInMs = this.endDate.valueOf() - this.startDate.valueOf();
    const stepSizeInMs = timeRangeDiffInMs / (this.stepsCount - 1);
    return new Date(this.startDate.valueOf() + stepSizeInMs * sliderValue);
  }

  calculateBubbleOffset(): number {
    const sliderInput = this.sliderInputRef.nativeElement;
    const currentDateBubble = this.currentDateBubbleRef.nativeElement;
    const sliderInputRect = sliderInput.getBoundingClientRect();
    const hostRect = this.hostElementRef.nativeElement.getBoundingClientRect();

    return (
      sliderInputRect.left -
      hostRect.left -
      currentDateBubble.getBoundingClientRect().width / 2 +
      (sliderInputRect.width / this.stepsCount) * parseInt(sliderInput.value)
    );
  }
}
