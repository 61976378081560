import { ExternalLinkDto, LinkDto, LinkOpenMode } from "../../core";
import { isDefined, Maybe } from "../../ts-utils";
import { tryParseEnum } from "../../ts-utils/helpers/enum.helper";
import { ComponentButtonParams } from "../components/base/component-button-params";
import { LinkProperties } from "../models/link-properties";

export function openLink(component: ComponentButtonParams): void {
  if (!component.isEditable && component.hasLink) {
    component.linkResolver.resolveLink(component.currentState.view.link);
  }
}

export function buildLinkFromProperties(properties: LinkProperties): Maybe<LinkDto> {
  if (isDefined(properties.Url)) {
    const openMode = tryParseEnum(LinkOpenMode, properties.LinkOpenMode);
    const linkDto = new ExternalLinkDto(properties.Url, isDefined(openMode) ? openMode : undefined);
    linkDto.title = properties.LinkTargetTitle;
    return linkDto;
  }
  return null;
}
