import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LocalizationService } from "../../../i18n/localization.service";
import { BaseDialogComponent } from "../base-dialog/base-dialog.component";

export enum ConfirmationDialogResult {
  SAVE = "SAVE",
  DISCARD = "DISCARD",
  CANCEL = "CANCEL"
}

@Component({
  selector: "c-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html",
  styleUrls: ["./confirmation-dialog.component.scss"]
})
export class ConfirmationDialogComponent extends BaseDialogComponent {
  public text = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) displayText: string,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public localizer: LocalizationService
  ) {
    super();
    this.text = displayText;
  }

  onSave() {
    this.closeDialog(ConfirmationDialogResult.SAVE);
  }

  onDiscard() {
    this.closeDialog(ConfirmationDialogResult.DISCARD);
  }

  onCancel() {
    this.closeDialog(ConfirmationDialogResult.CANCEL);
  }

  private closeDialog(dialogResult: ConfirmationDialogResult) {
    this.dialogRef.close(dialogResult);
  }
}
