<div class="border-side-selector-editor__container">
  <div class="border-side-selector-editor__wrapper">
    <div
      #borderSideElement
      *ngFor="let side of sides"
      [class.border-side--focus]="shouldFocusSide(side)"
      (click)="selectSide(side)"
      class="border {{ side | lowercase }}"
    ></div>
  </div>
</div>
