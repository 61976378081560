import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap, tap } from "rxjs/operators";
import { DateTimePickerDialogComponent } from "../../components/date-time-picker-dialog/date-time-picker-dialog.component";
import { DateTimePickerDialogActions } from "../actions/date-time-picker-dialog.actions";
import { PropertySheetEditorDialogStrategy } from "../dialog-config.strategies";
import { OpenDialogEffect } from "./dialog.effects";

@Injectable()
export class DateTimePickerDialogEffects extends OpenDialogEffect {
  constructor(private actions$: Actions, dialog: MatDialog) {
    super(dialog);
  }

  openDateTimePickerDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DateTimePickerDialogActions.openDateTimePickerDialog),
      tap(({ dateTimePickerDialogInfo: reportCreationInfo }) => {
        this.dialogConfigStrategy = new PropertySheetEditorDialogStrategy();
        this.dialogConfig = this.dialogConfigStrategy.createDialogConfig(reportCreationInfo);
        this.dialogRef = this.dialog.open(DateTimePickerDialogComponent, this.dialogConfig);
      }),
      switchMap(() => {
        return this.dialogRef.afterClosed().pipe(
          map((result: Date) => {
            return DateTimePickerDialogActions.onDateTimePickerDialogClosed({
              selectedDate: result ?? this.dialogRef.componentInstance.date
            });
          })
        );
      })
    )
  );
}
