import { animate, state, style, transition, trigger } from "@angular/animations";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { historyViewPlayerHeight } from "projects/ui-core/src/style-variables";
import { Subject, fromEvent } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { isCorrectTimeRangeInterval } from "../../../core/helpers/filter/filter-validation.helper";
import { FilterConfigurationDto } from "../../../core/models/filter/filter-configuration";
import { TimeRange } from "../../../core/models/time-range";
import { FilterFactory } from "../../../core/services/filter/filter-factory.service";
import { QueryStringService } from "../../../core/services/query-string.service";
import { DataConnectorDto } from "../../../data-connectivity/models/data-connector";
import { Dispatcher } from "../../../dispatcher";
import { LocalizationService } from "../../../i18n/localization.service";
import { ESCAPE_KEY, SPACE_KEY } from "../../../keyboard.constants";
import { EntityId } from "../../../meta/models/entity";
import { TimeScrubberComponent } from "../../../shared/components/time-scrubber/time-scrubber.component";
import {
  RuntimeSessionConfig,
  SessionTimeRange
} from "../../../shared/models/copy-runtime-to-clipboard-info";
import { isDefined, isNotDefined } from "../../../ts-utils";
import { Dictionary } from "../../../ts-utils/models/dictionary.type";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { createConnectorsDictionaryWithEmptyDataPoints } from "../../helpers/connectors.helper";
import {
  convertToLocalEntities,
  createTrendEntities,
  fitExpandedCardToDialog,
  getLocalId,
  isShortcutPressed,
  resetViewModeScaling,
  updateCutoffStrategy
} from "../../helpers/history-view.helper";
import { getRuntimeViewPropsUpdate } from "../../helpers/runtime-view.helper";
import { ComponentStateDto, DataStatus, ReportEntities } from "../../models";
import { PasteEntities } from "../../models/paste-entities";
import { SizeInPx } from "../../models/size-in-px";
import { ComponentStateSelector, DataConnectorSelector } from "../../services";
import { createCacheUpdateObject } from "../../services/cache-options.helper";
import { ClipboardService } from "../../services/clipboard.service";
import { CloningService, mergeReportEntities } from "../../services/cloning.service";
import { FilterSelector } from "../../services/entity-selectors/filter.selector";
import { HistoryViewService } from "../../services/history-view.service";
import { RuntimeViewService } from "../../services/runtime-view.service";
import { CommonActions } from "../../store/common/common.actions";
import { ComponentStateActions } from "../../store/component-state/component-state.actions";
import { DataConnectorActions } from "../../store/data-connector/data-connector.actions";
import { HistoryViewDialogActions } from "../../store/dialogs/actions/history-view-dialog.actions";
import { FilterActions } from "../../store/filter/filter.actions";
import { HISTORY_VIEW_CONSTANTS } from "./history-view.constants";

@Component({
  selector: "c-history-view",
  templateUrl: "./history-view.component.html",
  styleUrls: ["./history-view.component.scss"],
  animations: [
    trigger("flip", [
      state("false", style({ transform: "none" })),
      state("true", style({ transform: "rotateY(180deg)" })),
      transition("false => true", animate("750ms ease-out")),
      transition("true => false", animate("750ms ease-out"))
    ])
  ]
})
export class HistoryViewComponent implements OnInit, OnDestroy, AfterViewInit {
  public isDateTimePickerOpened: boolean = false;
  public expandedCardId: Maybe<EntityId>;
  public trendContainerId: Maybe<EntityId>;
  public trendId: Maybe<EntityId>;
  public trendShown: boolean = false;
  public localEntities!: ReportEntities;
  public playModeOn: boolean = false;
  public originalCardSize: SizeInPx;
  public initialDialogSize: SizeInPx;
  public isMaximized: boolean = false;
  currentTimeRange: Maybe<TimeRange>;
  public scalingTransformation: string = `scale(1)`;
  private resizeObserver: ResizeObserver;
  private unsubscribeSubject$: Subject<any> = new Subject<any>();
  @ViewChild("timeScrubber")
  private timeScrubber: TimeScrubberComponent | null = null;
  @ViewChild("dialogBody")
  private dialogBody: ElementRef<HTMLDivElement>;

  constructor(
    private dispatcher: Dispatcher,
    private filterFactory: FilterFactory,
    private historyViewService: HistoryViewService,
    private filterSelector: FilterSelector,
    private clipboardService: ClipboardService,
    private cloningService: CloningService,
    private componentStateSelector: ComponentStateSelector,
    private dataConnectorSelector: DataConnectorSelector,
    private runtimeViewService: RuntimeViewService,
    private cdr: ChangeDetectorRef,
    private queryStringService: QueryStringService,
    public translationService: LocalizationService
  ) {
    const previewedComponent = this.historyViewService.expandedComponent;
    if (isNotDefined(previewedComponent)) {
      return;
    }
    this.currentTimeRange = this.getInitialTimeRange(previewedComponent);
    this.addDialogFilterToStore(this.currentTimeRange);
    this.initializeLocalEntities(this.currentTimeRange, previewedComponent);

    const expandedCard = this.localEntities.componentStates.find(
      (cs) => cs.id === this.expandedCardId
    );
    if (isDefined(expandedCard)) {
      this.originalCardSize = expandedCard.view.runtimeView.runtimeSize;
      this.initialDialogSize = calculateInitialDialogSize(this.originalCardSize);
    }
  }

  ngOnInit(): void {
    this.subscribeToKeyboardEvent();
  }

  ngAfterViewInit(): void {
    this.resizeObserver = new ResizeObserver(() => {
      this.reevaluateScaling();
      this.recalculateTrendRuntimeSize();
      this.timeScrubber?.updateBubbleOffset();
    });
    this.resizeObserver.observe(this.dialogBody.nativeElement);
  }

  ngOnDestroy(): void {
    this.resizeObserver.disconnect();
    const componentsToRemove: ComponentStateDto[] = this.localEntities.componentStates;
    const connectorsByComponent: Dictionary<DataConnectorDto[]> = componentsToRemove.reduce(
      (dict: Dictionary<DataConnectorDto[]>, component: ComponentStateDto) => {
        dict[component.id] = this.dataConnectorSelector.getManyByIdAsArray(
          component.dataConnectorIds
        );
        return dict;
      },
      {}
    );
    this.dispatcher.dispatch(
      DataConnectorActions.deleteMany({
        connectorsByComponent
      })
    );
    this.dispatcher.dispatch(
      ComponentStateActions.deleteMany({ targetComponents: componentsToRemove })
    );

    this.dispatcher.dispatch(
      FilterActions.deleteOne({ filterId: HISTORY_VIEW_CONSTANTS.FILTER_ID })
    );

    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  private initializeLocalEntities(
    initialTimeRange: TimeRange,
    previewedComponent: ComponentStateDto
  ): ReportEntities {
    const { entities: expandedEntities, entityId: expandedCardId } = this.createExpandedEntities(
      initialTimeRange.to,
      previewedComponent
    );
    const trendEntities = createTrendEntities(expandedEntities, this.cloningService);
    const localEntities = mergeReportEntities(trendEntities, expandedEntities);
    localEntities.componentStates = updateCutoffStrategy(localEntities.componentStates);
    this.dispatcher.dispatch(CommonActions.upsertEntities({ reportEntities: localEntities }));
    this.expandedCardId = expandedCardId;
    this.trendContainerId = HISTORY_VIEW_CONSTANTS.TREND_CARD_ID;

    return (this.localEntities = localEntities);
  }

  private createExpandedEntities(
    initialTimeRangeEnd: Date,
    previewedComponent: ComponentStateDto
  ): PasteEntities {
    let {
      entities: expandedEntities,
      entityId: expandedCardId,
      mappings
    } = this.clipboardService.initPaste(this.clipboardService.initCopy([previewedComponent]))[0];
    expandedCardId = getLocalId(expandedCardId);
    expandedEntities = convertToLocalEntities(expandedEntities, initialTimeRangeEnd);
    expandedEntities.componentStates = resetViewModeScaling(expandedEntities.componentStates);
    expandedEntities.componentStates = fitExpandedCardToDialog(
      expandedEntities.componentStates,
      expandedCardId
    );
    return {
      entities: expandedEntities,
      entityId: expandedCardId,
      mappings
    };
  }

  private getInitialTimeRange(previewedComponent: ComponentStateDto): TimeRange {
    const componentFilterConfiguration = this.filterSelector.getByIdOrDefault(
      previewedComponent.filterId
    );
    const componentFilter = this.filterFactory.createFilterFromConfiguration(
      componentFilterConfiguration
    );
    return componentFilter?.timeRange ?? { from: new Date(), to: new Date() };
  }

  private addDialogFilterToStore(initialTimeRange: TimeRange): void {
    const dialogFilter: FilterConfigurationDto = new FilterConfigurationDto({
      id: HISTORY_VIEW_CONSTANTS.FILTER_ID,
      timeRange: this.filterFactory.createTimeRangeConfiguration(initialTimeRange)
    });
    this.dispatcher.dispatch(FilterActions.addOne({ filter: dialogFilter }));
  }

  private subscribeToKeyboardEvent(): void {
    fromEvent<KeyboardEvent>(document.body, "keydown")
      .pipe(
        filter((event) => isShortcutPressed(event.key)),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe((event: KeyboardEvent) => {
        const key: string = event.key;
        if (key === SPACE_KEY) {
          event.preventDefault();
          this.playModeOn ? this.stopHistory() : this.playHistory();
        } else if (key === ESCAPE_KEY) {
          this.closeHistoryView();
          event.stopPropagation();
        }
      });
  }

  onTogglePickerVisibility(event: Event): void {
    this.togglePickerVisibility();
    event.stopPropagation();
  }

  togglePickerVisibility(): void {
    this.isDateTimePickerOpened = !this.isDateTimePickerOpened;
  }

  closeHistoryView(): void {
    this.dispatcher.dispatch(HistoryViewDialogActions.closeHistoryViewDialog());
  }

  toggleTrendVisibility() {
    if (!this.trendShown) {
      this.stopHistory();
    }

    this.trendShown = !this.trendShown;
  }

  toggleDialogMaximize() {
    this.isMaximized = !this.isMaximized;
  }

  playHistory(): void {
    if (this.playModeOn) {
      return;
    }
    if (isDefined(this.timeScrubber)) {
      this.playModeOn = true;
      this.timeScrubber.startPlayback();
    }
  }

  stopHistory(): void {
    if (!this.playModeOn) {
      return;
    }
    if (isDefined(this.timeScrubber)) {
      this.playModeOn = false;
      this.timeScrubber.endPlayback();
    }
  }

  public onTimeRangeChange(newTimeRange: Maybe<TimeRange>): void {
    if (isDefined(newTimeRange) && isCorrectTimeRangeInterval(newTimeRange.from, newTimeRange.to)) {
      this.currentTimeRange = { ...newTimeRange };
      this.updateConnectorsOnTimeRangeChange();
      this.updateFilterOnTimeRangeChange(newTimeRange);
    }
  }

  updateComponentCacheTimestamp(newCacheTimestamp: Date): void {
    const updateObject = createCacheUpdateObject(this.expandedCardId, true, newCacheTimestamp);
    if (isDefined(updateObject)) {
      this.dispatcher.dispatch(
        ComponentStateActions.updateOne({
          componentUpdate: updateObject
        })
      );
    }
  }

  updateFilterOnTimeRangeChange(newTimeRange: TimeRange): void {
    const newTimeRangeConfig = this.filterFactory.createTimeRangeConfiguration(newTimeRange);
    this.dispatcher.dispatch(
      FilterActions.upsertOne({
        filterUpdate: {
          id: HISTORY_VIEW_CONSTANTS.FILTER_ID,
          changes: {
            timeRange: newTimeRangeConfig
          }
        }
      })
    );
  }

  updateConnectorsOnTimeRangeChange(): void {
    if (isDefined(this.expandedCardId)) {
      const expandedCard = this.componentStateSelector.getById(this.expandedCardId);
      if (isNotDefined(expandedCard)) {
        return;
      }
      const childComponentConnectors = expandedCard.childrenIds.reduce(
        (acc: Dictionary<DataConnectorDto[]>, componentId) => {
          acc[componentId.toString()] = this.dataConnectorSelector.getForComponent(componentId);
          return acc;
        },
        {}
      );

      this.dispatcher.dispatch(
        DataConnectorActions.addOrReplaceData({
          connectorDict: createConnectorsDictionaryWithEmptyDataPoints(
            childComponentConnectors,
            DataStatus.WaitingForData
          )
        })
      );
    }
  }

  private reevaluateScaling(): void {
    const dialogBodyRect = this.dialogBody.nativeElement.getBoundingClientRect();
    const horizontalScalingFactor = dialogBodyRect.width / this.originalCardSize.widthInPx;
    const verticalScalingFactor =
      (dialogBodyRect.height - historyViewPlayerHeight) / this.originalCardSize.heightInPx;

    this.scalingTransformation = `scale(${Math.min(
      horizontalScalingFactor,
      verticalScalingFactor
    )})`;

    this.cdr.detectChanges();
  }

  private recalculateTrendRuntimeSize(): void {
    const allComponents = this.componentStateSelector.getAllAsDict();
    const dialogSize = this.dialogBody.nativeElement.getBoundingClientRect();
    const updates = getRuntimeViewPropsUpdate(
      allComponents,
      HISTORY_VIEW_CONSTANTS.TREND_CARD_ID,
      { heightInPx: dialogSize.height, widthInPx: dialogSize.width },
      this.runtimeViewService
    );
    this.dispatcher.dispatch(ComponentStateActions.updateRuntimeViewProps({ updates }));
  }

  copyConfigurationToClipboard(): void {
    if (isDefined(this.currentTimeRange) && isDefined(this.historyViewService.expandedComponent)) {
      const sessionTimeRange: SessionTimeRange = {
        startTime: this.currentTimeRange.from,
        endTime: this.currentTimeRange.to
      };
      this.queryStringService.copyUrlSessionToClipboard({
        timeRange: sessionTimeRange,
        expandedComponentId: this.historyViewService.expandedComponent.id.toString()
      } as RuntimeSessionConfig);
    }
  }
}

function calculateInitialDialogSize(originalCardSize: SizeInPx): SizeInPx {
  return {
    heightInPx: originalCardSize.heightInPx + historyViewPlayerHeight,
    widthInPx: originalCardSize.widthInPx
  };
}
