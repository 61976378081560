import { construct } from "../../../core/services/construct.helper";
import { EditableType } from "../../../meta/decorators/editable-type.decorator";
import { Serializable } from "../../../meta/decorators/serializable.decorator";
import { DeepPartial } from "../../../ts-utils/models/deep-partial.type";
import { BaseViewConfigDto } from "../../models/base-view-config";
import { PositioningType } from "../../models/positioning-type";
import { CONTAINER_COMPONENT_VIEW_CONFIG } from "../../models/view-config-type.constants";

@EditableType({ fullName: CONTAINER_COMPONENT_VIEW_CONFIG })
export class ContainerComponentViewConfig extends BaseViewConfigDto {
  typeName = CONTAINER_COMPONENT_VIEW_CONFIG;

  @Serializable(false)
  snapToGrid!: boolean;

  @Serializable(PositioningType.Absolute)
  positioningType!: PositioningType;

  constructor(viewConfigDto: DeepPartial<ContainerComponentViewConfig> = {}) {
    super();
    construct(this, viewConfigDto, CONTAINER_COMPONENT_VIEW_CONFIG);
  }
}
