import { construct } from "../../core/services/construct.helper";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import { Configurable, EditableType } from "../../meta/decorators";
import { Serializable } from "../../meta/decorators/serializable.decorator";
import { EditorType } from "../../meta/models/editor-type";

const TYPE_NAME = "KmLogConfig";

export interface KmLogConfigDto {
  id: any;
  TimeOffset: number;
  LineColor: string;
  LineType: number;
  LineWidth: number;
  MarkerRadius: number;
  ShowMarker: boolean;
  YAxisLabelPos: number;
  ValueFormat: string;
}

@EditableType({ fullName: TYPE_NAME, title: "id" })
export class KmLogConfig implements KmLogConfigDto {
  id: any;
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.TimeOffset,
    editorType: EditorType.Number
  })
  @Serializable(0)
  TimeOffset!: number;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.LineColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#959595")
  LineColor!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.LineType,
    editorType: EditorType.Number
  })
  @Serializable(0)
  LineType!: number;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.LineWidth,
    editorType: EditorType.Number
  })
  @Serializable(1)
  LineWidth!: number;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.MarkerRadius,
    editorType: EditorType.Number
  })
  @Serializable(1)
  MarkerRadius!: number;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ShowMarker,
    editorType: EditorType.CheckBox
  })
  @Serializable(false)
  ShowMarker!: boolean;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.YAxisLabelPosition,
    editorType: EditorType.Number
  })
  @Serializable(0)
  YAxisLabelPos!: number;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ValueFormat,
    editorType: EditorType.TextBox
  })
  @Serializable("")
  ValueFormat!: string;

  constructor() {
    construct(this, {}, TYPE_NAME);
  }
}
