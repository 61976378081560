import { Injectable } from "@angular/core";
import { CustomFilterValue } from "../../core/models/filter/filter-type-descriptor";
import { OverridableParamKeys } from "../../core/services/query-param-key.converter";
import {
  AggregationType,
  DataConnectorDto,
  DataSourceDto,
  InheritableAggregationFunction
} from "../../data-connectivity";
import { DataAggregationConfigDto } from "../../data-connectivity/models/data-aggregation-config";
import { CutOffStrategy, InheritableCutOffStrategy } from "../../meta/models/cut-off-strategy";
import { EntityId } from "../../meta/models/entity";
import { Dictionary, Maybe, isEmptyOrNotDefined, isNotDefined } from "../../ts-utils";
import { ComponentStateSelector } from "./entity-selectors/component-state.selector";
import { RuntimeSettingsSelector } from "./entity-selectors/runtime-settings.selector";

@Injectable()
export class QueryParamsResolverService {
  constructor(
    private componentStateSelector: ComponentStateSelector,
    private runtimeSettingsSelector: RuntimeSettingsSelector
  ) {}

  inheritParameters(
    parentComponentId: EntityId,
    dataConnector: DataConnectorDto
  ): Record<OverridableParamKeys, string> {
    const periodType = this.resolveConnectorPeriodType(dataConnector.dataSource, parentComponentId);

    const materialAggregation = this.resolveConnectorAggregation(
      dataConnector.dataSource.aggregationConfig,
      parentComponentId,
      (aggregationConfig: DataAggregationConfigDto) => aggregationConfig.materialAggregationFunction
    );
    const locationAggregation = this.resolveConnectorAggregation(
      dataConnector.dataSource.aggregationConfig,
      parentComponentId,
      (aggregationConfig: DataAggregationConfigDto) => aggregationConfig.locationAggregationFunction
    );
    const timeAggregation = this.resolveConnectorAggregation(
      dataConnector.dataSource.aggregationConfig,
      parentComponentId,
      (aggregationConfig: DataAggregationConfigDto) => aggregationConfig.timeAggregationFunction
    );
    const arrayAggregation = this.resolveConnectorAggregation(
      dataConnector.dataSource.aggregationConfig,
      parentComponentId,
      (aggregationConfig: DataAggregationConfigDto) => aggregationConfig.arrayAggregationFunction
    );

    const cutOffStrategy = this.getConnectorCutOffStrategy(parentComponentId, dataConnector);

    return {
      [OverridableParamKeys.ArrayAggregation]: arrayAggregation,
      [OverridableParamKeys.TimeAggregation]: timeAggregation,
      [OverridableParamKeys.LocationAggregation]: locationAggregation,
      [OverridableParamKeys.MaterialAggregation]: materialAggregation,
      [OverridableParamKeys.PeriodType]: periodType,
      [OverridableParamKeys.CutOffStrategy]: cutOffStrategy
    };
  }

  resolveConnectorPeriodType(
    connectorDataSource: DataSourceDto,
    parentComponentId: EntityId
  ): Maybe<string> {
    const connectorPType = connectorDataSource.aggregationConfig.periodType;

    if (!isEmptyOrNotDefined(connectorPType)) {
      return connectorPType;
    }
    const parentComponentDCQ =
      this.componentStateSelector.getById(parentComponentId)?.dataConnectorQuery;
    return this.resolveDCQPeriodType(parentComponentDCQ);
  }

  resolveDCQPeriodType(dataConnectorQuery: Maybe<DataSourceDto>): string {
    let dcqPeriodType = this.getDCQPeriodType(dataConnectorQuery);
    if (isEmptyOrNotDefined(dcqPeriodType)) {
      dcqPeriodType = this.getGlobalPeriodType();
    }
    return dcqPeriodType;
  }

  getDCQPeriodType(dataConnectorQuery: Maybe<DataSourceDto>): Maybe<string> {
    return dataConnectorQuery?.aggregationConfig.periodType;
  }

  getGlobalPeriodType(): string {
    return this.runtimeSettingsSelector.getPeriodType();
  }

  resolveConnectorAggregation(
    connectorAggregationConfig: DataAggregationConfigDto,
    parentComponentId: EntityId,
    selectAggregation: AggregationSelector
  ): AggregationType {
    const componentAggregationConfig = this.getComponentAggregationConfig(parentComponentId);
    const connectorAggregation = selectAggregation(connectorAggregationConfig);

    if (
      connectorAggregation !== InheritableAggregationFunction.Inherited ||
      isNotDefined(componentAggregationConfig)
    ) {
      return connectorAggregation;
    }
    return selectAggregation(componentAggregationConfig);
  }

  getComponentAggregationConfig(componentId: EntityId): Maybe<DataAggregationConfigDto> {
    return this.componentStateSelector.getById(componentId)?.dataConnectorQuery.aggregationConfig;
  }

  resolveConnectorFilter(
    connectorStandardFilters: Dictionary<CustomFilterValue>,
    parentComponentStandardFilters: Dictionary<CustomFilterValue>,
    filterParamSelector: StandardFilterSelector
  ): CustomFilterValue {
    const connectorFilter = filterParamSelector(connectorStandardFilters);
    switch (typeof connectorFilter) {
      case "string": {
        if (connectorFilter !== "") {
          return connectorFilter;
        }
        return filterParamSelector(parentComponentStandardFilters);
      }
      case "number":
        return connectorFilter;
      default:
        return filterParamSelector(parentComponentStandardFilters);
    }
  }

  getConnectorCutOffStrategy(
    parentComponentId: EntityId,
    dataConnector: DataConnectorDto
  ): Maybe<CutOffStrategy> {
    if (dataConnector.cutOffStrategy === InheritableCutOffStrategy.Inherited) {
      const parentComponent = this.componentStateSelector.getById(parentComponentId);
      return parentComponent?.view.cutOffStrategy;
    }
    return CutOffStrategy[dataConnector.cutOffStrategy];
  }
}

type AggregationSelector = (aggregationConfig: DataAggregationConfigDto) => AggregationType;

type StandardFilterSelector = (standardFilters: Dictionary<CustomFilterValue>) => CustomFilterValue;
