<div class="report-browser__container">
  <input
    class="editor--discreet-input report-browser__input"
    type="search"
    placeholder="{{ localizer.propertySheet.Search | translate }}"
    (keyup)="searchInputChanged($event)"
  />
  <cdk-virtual-scroll-viewport
    cdk-virtual-scroll-viewport-patch
    itemSize="20"
    class="report-browser__list"
  >
    <div *cdkVirtualFor="let reportInfo of filteredReportDescriptions$ | async">
      <p
        class="report-browser__report-item"
        [ngClass]="{
          'report-browser__item--selected':
            currentReportSelected && currentReportSelected.reportId === reportInfo.reportId
        }"
        (click)="createReportLink(reportInfo)"
      >
        <span class="report-browser__report-name">
          {{ reportInfo.reportName }}
        </span>
        <span *ngIf="shouldShowReportDescription" class="report-browser__report-description">
          {{ reportInfo.reportDescription }}</span
        >
      </p>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
