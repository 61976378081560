import { isDefined } from "../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { NestedProperty } from "../models/nested-property";

/**
 * @param path in originalObject to place where propertyValue should be
 * @param originalObject is both mutated and returned
 */
export function overridePropertyByPath(
  path: string[],
  originalObject: any,
  propertyValue: any
): any {
  const [pathFragment, ...rest] = path;
  if (rest.length === 0) {
    originalObject[pathFragment] = propertyValue;
    return originalObject;
  } else {
    return overridePropertyByPath(rest, originalObject[pathFragment], propertyValue);
  }
}

// similar to overridePropertyByPath, but creates empty object for missing intermediate nodes (instead of throwing exception)
// TODO: can we always use this and remove above function?
export function overridePropertyByPathCreatingIntermediates(
  path: string[],
  originalObject: any,
  propertyValue: any,
  nestedProperty?: Maybe<NestedProperty>
): any {
  const [pathFragment, ...rest] = path;
  if (rest.length === 0) {
    if (isDefined(nestedProperty)) {
      originalObject[pathFragment][nestedProperty.objectIndex][nestedProperty.propertyName] =
        propertyValue;
    } else {
      originalObject[pathFragment] = propertyValue;
    }
    return originalObject;
  } else {
    if (!isDefined(originalObject[pathFragment])) {
      originalObject[pathFragment] = {};
    }
    return overridePropertyByPathCreatingIntermediates(
      rest,
      originalObject[pathFragment],
      propertyValue
    );
  }
}
