import { Component, Input } from "@angular/core";
import { LinkResolver } from "../../../browsing/services/link-resolver";
import { ReportLinkDto } from "../../../core";
import { ReportConfiguration } from "../../models/report-configuration";

@Component({
  selector: "breadcrumb-nav",
  templateUrl: "./breadcrumb-navigation.component.html",
  styleUrls: ["./breadcrumb-navigation.component.scss"]
})
export class BreadcrumbNavigationComponent {
  @Input() reports: ReportConfiguration[] = [];

  constructor(private linkResolver: LinkResolver) {}

  onLinkClicked(report: ReportConfiguration): void {
    const link: Partial<ReportLinkDto> = {
      info: { reportId: report.id, reportName: report.id.toString() }
    };
    this.linkResolver.resolveLink(new ReportLinkDto(link));
  }
}
