import { createEntityAdapter, Update } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import { cloneDeep as _cloneDeep } from "lodash";
import {
  determineSourceFilterId,
  REPORT_FILTER_ID,
  RUNTIME_FILTER_ID
} from "../../../core/helpers/filter/filter-id.helper";
import { FilterConfigurationDto } from "../../../core/models/filter/filter-configuration";
import { CustomFilterValue } from "../../../core/models/filter/filter-type-descriptor";
import { DataConnectorDto } from "../../../data-connectivity/models/data-connector";
import { EntityId } from "../../../meta/models";
import {
  assignDeep,
  Dictionary,
  isDefined,
  isEmptyOrNotDefined,
  isEmptyOrNotDefined2,
  isNotDefined,
  Maybe
} from "../../../ts-utils";
import { CriticalError } from "../../../ts-utils/models/critical-error";
import { DeepUpdate } from "../../../ts-utils/models/deep-update.type";
import { ComponentStateDto, ReportEntities } from "../../models";
import { getDefaultReportContent } from "../../services";
import { createDefaultFilter } from "../../services/global-filter.helper";
import { CommonActions } from "../common/common.actions";
import { ComponentStateActions } from "../component-state/component-state.actions";
import { DataConnectorActions } from "../data-connector/data-connector.actions";
import { FilterActions } from "./filter.actions";
import { FilterState } from "./filter.state";

const adapter = createEntityAdapter<FilterConfigurationDto>();
export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

export const initialState: FilterState = adapter.getInitialState({
  ids: [REPORT_FILTER_ID, RUNTIME_FILTER_ID],
  entities: {
    [REPORT_FILTER_ID]: createDefaultFilter(REPORT_FILTER_ID),
    [RUNTIME_FILTER_ID]: createDefaultFilter(RUNTIME_FILTER_ID)
  }
});

export function reducer(state: FilterState, action: Action) {
  return _reducer(state, action);
}

const _reducer = createReducer(
  initialState,
  on(FilterActions.addOne, (state, { filter }) => addOne(state, filter)),
  on(FilterActions.addMany, (state, { filters }) => addMany(state, filters)),
  on(FilterActions.deleteOne, (state, { filterId }) => deleteOne(state, filterId)),
  on(FilterActions.deleteMany, (state, { filterIds }) => deleteMany(state, filterIds)),
  on(FilterActions.updateMany, (state, { filterUpdates }) => updateMany(state, filterUpdates)),
  on(FilterActions.upsertOne, FilterActions.reactToSelectTimeRange, (state, { filterUpdate }) =>
    upsert(state, filterUpdate)
  ),
  on(FilterActions.setSourceFilter, (state, { filterId, sourceFilterId }) =>
    upsert(state, { id: filterId.toString(), changes: { sourceFilterId } })
  ),
  on(FilterActions.updateGlobalFilter, (state, { changes: filterUpdate }) =>
    updateGlobalFilter(state, filterUpdate)
  ),
  on(FilterActions.resolveUpdatedCustomFilters, (state, { customFilters }) =>
    resolveUpdatedCustomFilters(state, customFilters)
  ),
  on(ComponentStateActions.deleteOne, (state, { targetComponent }) =>
    deleteOne(
      state,
      targetComponent.filterId === RUNTIME_FILTER_ID ? null : targetComponent.filterId
    )
  ),
  on(ComponentStateActions.deleteMany, (state, { targetComponents }) =>
    onComponentDeleteMany(state, targetComponents)
  ),
  on(DataConnectorActions.deleteOne, (state, { connector }) =>
    deleteOne(state, connector.filterId === RUNTIME_FILTER_ID ? null : connector.filterId)
  ),
  on(DataConnectorActions.deleteMany, (state, { connectorsByComponent }) =>
    onConnectorDeleteMany(state, Object.values(connectorsByComponent).flat())
  ),
  on(DataConnectorActions.replaceOne, (state, { oldConnector }) =>
    deleteOne(state, oldConnector.filterId === RUNTIME_FILTER_ID ? null : oldConnector.filterId)
  ),
  on(DataConnectorActions.replaceMany, (state, { connectorsReplaceInfo }) =>
    onConnectorDeleteMany(state, connectorsReplaceInfo.obsoleteConnectors)
  ),
  on(CommonActions.resetStore, () => setDefaultState()),
  on(CommonActions.upsertEntities, (state, { reportEntities }) =>
    onUpsertEntities(state, _cloneDeep(reportEntities.filters))
  ),
  on(CommonActions.upsertEntitiesOnLoad, (state, { reportEntities }) =>
    upsertEntitiesOnLoad(state, reportEntities)
  ),
  on(CommonActions.replaceAll, (state, { entities }) => onReplaceAll(state, entities.filters)),
  on(CommonActions.discardRuntimeParameters, (state) => discardRuntimeFilter(state))
);

function addOne(state: FilterState, newFilter: FilterConfigurationDto): FilterState {
  if (newFilter == null || newFilter.id == null) {
    throw new CriticalError(`Invalid filter`);
  }
  const fullFilter = new FilterConfigurationDto(newFilter);
  return adapter.addOne(fullFilter, state);
}

function addMany(state: FilterState, newFilters: FilterConfigurationDto[]): FilterState {
  if (newFilters == null || newFilters.length < 1) {
    throw new CriticalError(`No filters specified`);
  }
  const fullFilters = newFilters.map(
    (filter: FilterConfigurationDto) => new FilterConfigurationDto(filter)
  );
  return adapter.addMany(fullFilters, state);
}

function deleteOne(state: FilterState, filterId: Maybe<EntityId>): FilterState {
  if (isNotDefined(filterId)) {
    return state;
  }
  state = adapter.removeOne(filterId.toString(), state);
  const dependentFilters = updateDependentFilters(state, [filterId]);
  state = adapter.updateMany(dependentFilters, state);
  return state;
}

function deleteMany(state: FilterState, filterIds: EntityId[]): FilterState {
  const filterIdsToRemove = filterIds.map((filterId) => filterId.toString());
  state = adapter.removeMany(filterIdsToRemove, state);
  const dependentFilters = updateDependentFilters(state, filterIdsToRemove);
  state = adapter.updateMany(dependentFilters, state);
  return state;
}

function updateDependentFilters(
  state: FilterState,
  sourceFilterIds: EntityId[]
): Update<FilterConfigurationDto>[] {
  return (state.ids as string[])
    .map((filterId: string) => state.entities[filterId])
    .filter((filter) => sourceFilterIds.find((filterId) => filter?.sourceFilterId === filterId))
    .map<Update<FilterConfigurationDto>>((filterConfiguration) => {
      const filterId = filterConfiguration?.id.toString();
      const sourceFilterId = determineSourceFilterId(
        state.entities,
        filterConfiguration?.sourceFilterId
      );
      return {
        id: filterId,
        changes: {
          sourceFilterId
        }
      };
    });
}

function updateMany(
  state: FilterState,
  filterUpdates: Update<FilterConfigurationDto>[]
): FilterState {
  return adapter.updateMany(filterUpdates, state);
}

function upsert(
  state: FilterState,
  filterChanges: DeepUpdate<FilterConfigurationDto>
): FilterState {
  const existingFilterInStore = _cloneDeep(state.entities[filterChanges.id]);
  let fullFilter;
  if (isDefined(existingFilterInStore)) {
    fullFilter = assignDeep(existingFilterInStore, filterChanges.changes);
  } else {
    fullFilter = new FilterConfigurationDto({
      ...filterChanges.changes,
      id: filterChanges.id
    });
  }
  return adapter.upsertOne(fullFilter, state);
}

function updateGlobalFilter(
  state: FilterState,
  filterChanges: Partial<FilterConfigurationDto>
): FilterState {
  const reportFilterInStore = _cloneDeep(state.entities[REPORT_FILTER_ID]);
  const runtimeFilterInStore = _cloneDeep(state.entities[RUNTIME_FILTER_ID]);
  if (isNotDefined(reportFilterInStore) || isNotDefined(runtimeFilterInStore)) {
    return state;
  }

  const reportFilterChanges = assignDeep(reportFilterInStore, filterChanges);
  const runtimeFilterChanges = assignDeep(runtimeFilterInStore, filterChanges);
  const mergedUpdates: Update<FilterConfigurationDto>[] = [
    {
      id: REPORT_FILTER_ID,
      changes: reportFilterChanges
    },
    {
      id: RUNTIME_FILTER_ID,
      changes: runtimeFilterChanges
    }
  ];
  return adapter.updateMany(mergedUpdates, state);
}

function resolveUpdatedCustomFilters(
  state: FilterState,
  customFilters: Dictionary<CustomFilterValue>
): FilterState {
  const reportFilterInStore = _cloneDeep(state.entities[REPORT_FILTER_ID]);
  const runtimeFilterInStore = _cloneDeep(state.entities[RUNTIME_FILTER_ID]);
  if (isNotDefined(reportFilterInStore) || isNotDefined(runtimeFilterInStore)) {
    return state;
  }
  const globalFilterUpdates: Update<FilterConfigurationDto>[] = [
    {
      id: REPORT_FILTER_ID,
      changes: {
        customFilters
      }
    },
    {
      id: RUNTIME_FILTER_ID,
      changes: {
        customFilters: updateRuntimeCustomFilters(
          reportFilterInStore,
          runtimeFilterInStore,
          customFilters
        )
      }
    }
  ];
  const additionalFilterUpdates = removeUnsetCustomFilters(state.entities, customFilters);
  return adapter.updateMany([...globalFilterUpdates, ...additionalFilterUpdates], state);
}

function updateRuntimeCustomFilters(
  reportFilter: FilterConfigurationDto,
  runtimeFilter: FilterConfigurationDto,
  customFilters: Dictionary<CustomFilterValue>
): Dictionary<CustomFilterValue> {
  return Object.keys(customFilters).reduce(
    (acc: Dictionary<CustomFilterValue>, filterKey: string) => {
      if (
        runtimeFilter.customFilters[filterKey] === reportFilter.customFilters[filterKey] ||
        isEmptyOrNotDefined2(runtimeFilter.customFilters[filterKey])
      ) {
        acc[filterKey] = customFilters[filterKey];
      } else {
        acc[filterKey] = runtimeFilter.customFilters[filterKey];
      }
      return acc;
    },
    {}
  );
}

function removeUnsetCustomFilters(
  filters: Dictionary<Maybe<FilterConfigurationDto>>,
  customFilters: Dictionary<CustomFilterValue>
): Update<FilterConfigurationDto>[] {
  return Object.values(filters)
    .filter(isDefined)
    .filter((filter) => filter.id !== RUNTIME_FILTER_ID && filter.id !== REPORT_FILTER_ID)
    .reduce((acc: Update<FilterConfigurationDto>[], filter: FilterConfigurationDto) => {
      const storedCustomFilters: Dictionary<CustomFilterValue> = _cloneDeep(filter.customFilters);
      if (isDefined(storedCustomFilters)) {
        const adjustedCustomFilters: Dictionary<CustomFilterValue> = Object.keys(
          storedCustomFilters
        ).reduce((acc: Dictionary<CustomFilterValue>, filterKey: string) => {
          if (isDefined(customFilters[filterKey])) {
            acc[filterKey] = storedCustomFilters[filterKey];
          }
          return acc;
        }, {});
        acc.push({
          id: filter.id.toString(),
          changes: {
            customFilters: adjustedCustomFilters
          }
        });
      }
      return acc;
    }, []);
}

function onComponentDeleteMany(
  state: FilterState,
  componentStates: ComponentStateDto[]
): FilterState {
  const filtersToDelete = componentStates.reduce((acc: EntityId[], componentState) => {
    const filterId = componentState.filterId;
    if (isDefined(filterId) && filterId !== RUNTIME_FILTER_ID) {
      acc.push(filterId);
    }
    return acc;
  }, []);
  return onDeleteMany(state, filtersToDelete);
}

function onConnectorDeleteMany(
  state: FilterState,
  connectorsToDelete: DataConnectorDto[]
): FilterState {
  const filtersToDelete = connectorsToDelete.reduce(
    (acc: EntityId[], connector: DataConnectorDto) => {
      const filterId = connector.filterId;
      if (isDefined(filterId) && filterId !== RUNTIME_FILTER_ID) {
        acc.push(filterId);
      }
      return acc;
    },
    []
  );
  return onDeleteMany(state, filtersToDelete);
}

function onDeleteMany(state: FilterState, filtersToDelete: EntityId[]): FilterState {
  if (filtersToDelete.length > 0) {
    return adapter.removeMany(
      filtersToDelete.map((filterId) => filterId.toString()),
      state
    );
  }
  const dependentFilters = filtersToDelete.reduce(
    (acc: Update<FilterConfigurationDto>[], removedFilter: EntityId) => {
      const updatedFilters = updateDependentFilters(state, [removedFilter]);
      acc.push(...updatedFilters);
      return acc;
    },
    []
  );
  state = adapter.updateMany(dependentFilters, state);
  return state;
}

function setDefaultState(): FilterState {
  return getDefaultReportContent().filters;
}

function onUpsertEntities(state: FilterState, filters: FilterConfigurationDto[]): FilterState {
  let updatedGlobalFilter: Maybe<FilterConfigurationDto>;
  if (filters != null && filters.length !== 0) {
    const fullFilters = filters.map((filter: FilterConfigurationDto) => {
      if (filter.id === REPORT_FILTER_ID) {
        updatedGlobalFilter = filter;
      }
      return new FilterConfigurationDto(filter);
    });
    if (isDefined(updatedGlobalFilter)) {
      fullFilters.push(
        new FilterConfigurationDto({ ...updatedGlobalFilter, id: RUNTIME_FILTER_ID })
      );
    }
    return adapter.upsertMany(fullFilters, state);
  } else {
    return state;
  }
}

function upsertEntitiesOnLoad(state: FilterState, reportEntities: ReportEntities): FilterState {
  if (isEmptyOrNotDefined(reportEntities.filters)) {
    return state;
  }
  const fullFilters = _cloneDeep(reportEntities.filters).map(
    (filter: FilterConfigurationDto) => new FilterConfigurationDto(filter)
  );
  return adapter.upsertMany(fullFilters, state);
}

function onReplaceAll(state: FilterState, newFilters: FilterConfigurationDto[]): FilterState {
  return adapter.setAll(newFilters, state);
}

function discardRuntimeFilter(state: FilterState): FilterState {
  const reportFilterInStore = _cloneDeep(state.entities[REPORT_FILTER_ID]);
  if (isNotDefined(reportFilterInStore)) {
    return state;
  }

  const mergedUpdates: Update<FilterConfigurationDto>[] = [
    {
      id: RUNTIME_FILTER_ID,
      changes: {
        ...reportFilterInStore,
        id: RUNTIME_FILTER_ID
      }
    }
  ];
  return adapter.updateMany(mergedUpdates, state);
}
