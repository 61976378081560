import { createSelector } from "@ngrx/store";
import { getReportFeature } from "../feature.selector";
import { ReportState } from "../state";
import { RuntimeSettingsState } from "./runtime-settings-state";

export const selectRuntimeSettings = createSelector(
  getReportFeature,
  (state: ReportState) => state.runtimeSettings
);

export const selectPagePreviewWidth = createSelector(
  selectRuntimeSettings,
  (runtimeSettings: RuntimeSettingsState) => runtimeSettings.pagePreviewWidth
);

export const selectCurrentRootPath = createSelector(
  selectRuntimeSettings,
  (runtimeSettings: RuntimeSettingsState) => runtimeSettings.currentRootPath
);

export const selectRuntimePeriodType = createSelector(
  selectRuntimeSettings,
  (runtimeSettings: RuntimeSettingsState) => runtimeSettings.periodType
);
