import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { cloneDeep as _cloneDeep } from "lodash";
import { Subject } from "rxjs";
import { Dispatcher } from "../../dispatcher";
import { LocalizationService } from "../../i18n/localization.service";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import {
  RuntimeSessionConfig,
  SessionTimeRange
} from "../../shared/models/copy-runtime-to-clipboard-info";
import { Dictionary, isDefined, isEmptyOrNotDefined, isNumber } from "../../ts-utils";
import { CustomFilterValue, IName } from "../models";
import { UrlParams } from "../models/url-params";
import { ErrorHandlingActions } from "../store/error-handling/error-handling.actions";

// IP for some reason built in Angular mechanism doesn't work

@Injectable({ providedIn: "root" })
export class QueryStringService {
  public rootPathChanged: Subject<string> = new Subject<string>();
  public filterParamsChanged: Subject<boolean> = new Subject<boolean>();
  private currentQueryParams: Map<string, string> = new Map<string, string>();

  constructor(
    private locationService: Location,
    private dispatcher: Dispatcher,
    private translationService: LocalizationService
  ) {
    this.locationService.onUrlChange((url) => {
      this.onUrlChange(url);
    });
  }

  private onUrlChange(url: string): void {
    const newQueryParams = this.extractQueryParams(url);
    if (this.currentQueryParams.size === 0) {
      this.cacheQueryParams(newQueryParams);
    } else {
      if (queryParamsChanged(this.currentQueryParams, newQueryParams)) {
        const rootPathChanged =
          newQueryParams.get(UrlParams.rootPath) !==
          this.currentQueryParams.get(UrlParams.rootPath);
        if (rootPathChanged) {
          this.rootPathChanged.next(newQueryParams.get(UrlParams.rootPath));
        }
        if (filterParamsChanged(this.currentQueryParams, newQueryParams)) {
          this.filterParamsChanged.next(true);
        }
        this.cacheQueryParams(newQueryParams);
      }
    }
  }

  private extractQueryParams(url: string): Map<string, string> {
    const queryParams: Map<string, string> = new Map<string, string>();
    const params = this.getParams(url);

    params.forEach((value, key) => {
      queryParams.set(key, value);
    });
    return queryParams;
  }

  private cacheQueryParams(queryParams: Map<string, string>): void {
    this.currentQueryParams = queryParams;
  }

  public getParams(url?: string): URLSearchParams {
    return new URLSearchParams(QueryStringService.getQueryString(url));
  }

  static getQueryString(url?: string): string {
    const queryStringWithQuestionMark = url || location.search || location.hash;
    const queryString = queryStringWithQuestionMark.split("?")[1];
    return isDefined(queryString) ? decodeURIComponent(queryString) : "";
  }

  public getRootPath(): string {
    return this.getParams().get(UrlParams.rootPath) || "";
  }

  public resolveUrl(reportId: string): string {
    return `/${reportId}`;
  }

  public getProductName(): IName {
    const params = this.getParams();
    const productName = params.get(UrlParams.productName) ?? "";
    const productNameShort = params.get(UrlParams.productNameShort) ?? "";
    return { fullName: productName, shortName: productNameShort };
  }

  public getParamsWithRootPath(newRootPath: string): URLSearchParams {
    const params: URLSearchParams = this.getParams();
    this.setRootPath(params, newRootPath);
    return params;
  }

  public isLoggingEnabled(): boolean {
    const valueAsString = this.getParams().get(UrlParams.isLoggingEnabled);
    return !isEmptyOrNotDefined(valueAsString) && valueAsString === "true";
  }

  getComponentIdToExpand(): string {
    return this.getParams().get(UrlParams.cardId) || "";
  }

  async copyUrlSessionToClipboard(sessionConfig: RuntimeSessionConfig): Promise<void> {
    const params: URLSearchParams = this.setParamsFromSessionConfig(sessionConfig);
    const url: string = window.location.href.split("?")[0] + "?" + params.toString();

    await navigator.clipboard.writeText(decodeURIComponent(url)).then(() => {
      this.dispatcher.dispatch(
        ErrorHandlingActions.displayInfo({
          messageToDisplay: this.translationService.get(
            LOCALIZATION_DICTIONARY.snackBarMessages.ClipboardCopy
          )
        })
      );
    });
  }

  setParamsFromSessionConfig(sessionConfig: RuntimeSessionConfig): URLSearchParams {
    const params: URLSearchParams = this.getParams();
    this.setTimeRange(sessionConfig.timeRange, params);

    if (isDefined(sessionConfig.customFilters)) {
      this.setCustomFilters(params, sessionConfig.customFilters);
    }
    if (isDefined(sessionConfig.rootPath)) {
      this.setRootPath(params, sessionConfig.rootPath);
    }
    if (isDefined(sessionConfig.periodType)) {
      this.setPeriodType(params, sessionConfig.periodType);
    }
    if (sessionConfig.expandedComponentId) {
      params.set(UrlParams.cardId, sessionConfig.expandedComponentId);
    }
    return params;
  }

  setTimeRange(timeRange: SessionTimeRange, params: URLSearchParams): void {
    const startDateISO: string = timeRange.startTime.toISOString();
    params.set(UrlParams.startDate, startDateISO);

    if (timeRange.endTime) {
      const endDateISO: string = timeRange.endTime.toISOString();
      params.set(UrlParams.endDate, endDateISO);
    }
  }

  setCustomFilters(params: URLSearchParams, customFilters: Dictionary<CustomFilterValue>): void {
    Object.entries(customFilters).map(([key, value]) => {
      if (isDefined(value)) {
        params.set(key, isNumber(value) ? value.toString() : value);
      }
    });
  }

  setRootPath(params: URLSearchParams, rootPath: string): void {
    params.set(UrlParams.rootPath, rootPath);
  }

  setPeriodType(params: URLSearchParams, periodType: string): void {
    params.set(UrlParams.pType, periodType);
  }
}

function filterParamsChanged(
  currentQueryParams: Map<string, string>,
  newQueryParams: Map<string, string>
): boolean {
  const currentFilterQueryParams = removeNonFilterParams(currentQueryParams);
  const newFilterQueryParams = removeNonFilterParams(newQueryParams);
  return queryParamsChanged(currentFilterQueryParams, newFilterQueryParams);
}

function removeNonFilterParams(queryParams: Map<string, string>): Map<string, string> {
  const filterQueryParams = _cloneDeep(queryParams);
  filterQueryParams.delete(UrlParams.rootPath);
  filterQueryParams.delete(UrlParams.productNameShort);
  filterQueryParams.delete(UrlParams.productName);
  filterQueryParams.delete(UrlParams.idType);
  filterQueryParams.delete(UrlParams.isLoggingEnabled);
  filterQueryParams.delete(UrlParams.language);
  return filterQueryParams;
}

function queryParamsChanged(
  currentQueryParams: Map<string, string>,
  newQueryParams: Map<string, string>
): boolean {
  if (currentQueryParams.size !== newQueryParams.size) {
    return true;
  }
  for (const [key, value] of newQueryParams) {
    const paramIsAddedOrChanged =
      !currentQueryParams.has(key) ||
      (currentQueryParams.has(key) && currentQueryParams.get(key) !== value);
    if (paramIsAddedOrChanged) {
      return true;
    }
  }
  return false;
}
