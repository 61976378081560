import { AlignValue, Options } from "highcharts";
import { first, Maybe } from "../../../ts-utils";
import { tryConvertToNumber } from "../../../ts-utils/helpers/number.helper";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { getLimitOrPrimaryColor } from "../../helpers/color.helper";
import { getSingleValueFromConnector } from "../../helpers/component-data-accessor.helper";
import { DataStatus } from "../../models/data-status";
import { ILinearGaugeDisplayConfig } from "../../models/i-view-config/i-gauge-display-config";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import { mergeChartOptions } from "./base-highcharts-options.helper";
import { LinearGaugeDisplayService } from "./linear-gauge-display.service";

export class HorizontalGaugeDisplayService extends LinearGaugeDisplayService {
  constructor() {
    super();
    this.chartType = "bar";
  }

  protected getSpecificGaugeOptions(
    viewConfig: ILinearGaugeDisplayConfig,
    dataConnectorDescriptors: Maybe<DataConnectorDescriptor>[],
    dataStatus: DataStatus
  ): Options {
    const value = getSingleValueFromConnector(first(dataConnectorDescriptors)?.connector);
    const min = tryConvertToNumber(viewConfig.min);
    const max = tryConvertToNumber(viewConfig.max);
    const normalizedValue = isDefined(min) ? value - min : value;

    const horizontalOptions: Options = {
      title: {
        align: "left"
      },
      plotOptions: {
        bar: {
          groupPadding: 0,
          threshold: min
        }
      },
      series: [
        {
          type: undefined,
          data: isDefined(max) ? [max - value] : [value],
          enableMouseTracking: false,
          color: viewConfig.neutralColor || "#F0F0F0",
          dataLabels: {
            enabled: false
          }
        },
        {
          type: undefined,
          data: [normalizedValue],
          color: getLimitOrPrimaryColor(normalizedValue, viewConfig.limits, viewConfig.primaryColor)
        }
      ]
    };
    const linearGaugeOptions = super.getSpecificGaugeOptions(
      viewConfig,
      dataConnectorDescriptors,
      dataStatus
    );
    return mergeChartOptions(linearGaugeOptions, horizontalOptions);
  }

  protected getYOffset(): number {
    return 3;
  }

  protected getTextAlignStyle(): AlignValue {
    return "center";
  }

  protected getPlotLineLabel(viewConfig: ILinearGaugeDisplayConfig, value: number) {
    if (!viewConfig.showLimitValues) {
      return {};
    }
    return {
      text: value,
      textAlign: "center",
      rotation: 0,
      y: -5
    };
  }
}
