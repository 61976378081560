import { Component, Input } from "@angular/core";
import { ButtonConfig } from "../../../shared/models/button/button-config";

@Component({
  selector: "c-button-strip",
  templateUrl: "./button-strip.component.html",
  styleUrls: ["./button-strip.component.scss"]
})
export class ButtonStripComponent {
  @Input()
  public buttons: ButtonConfig[] = [];
}
