import { Type } from "@angular/core";
import { DeepPartial, isDefined, Maybe } from "../../ts-utils";

export function convertDtoArray<T>(
  dtoArray: Maybe<DeepPartial<T>>[],
  constructorFunction: Type<T>
): T[] {
  const convertedArray: T[] = dtoArray.filter(isDefined).map((dto) => new constructorFunction(dto));
  return convertedArray;
}
