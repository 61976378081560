<mat-dialog-content>
  <h4 mat-dialog-title>Remove axis</h4>
  <div class="remove-axis-dialog__wrapper" *ngIf="axisPresent">
    <mat-selection-list class="remove-axis-dialog__axis-list" #axisList>
      <mat-list-option *ngFor="let axis of axisArray" value="{{ axis.id }}">
        <div [innerHTML]="axis.title.text | oneline"></div>
      </mat-list-option>
    </mat-selection-list>
  </div>

  <div
    #noAxisPresent
    class="remove-axis-dialog__wrapper remove-axis-dialog__no-axis--spaced"
    *ngIf="!axisPresent"
  >
    <div>No axis present.</div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="remove-axis-dialog__button-container">
  <button
    class="c-button c-button--rounded trend__button--rounded c-button--primary"
    [disabled]="notSelectedAxis"
    (click)="onDialogClosed()"
  >
    Remove
  </button>
  <button class="c-button c-button--rounded trend__button--rounded" mat-dialog-close>Cancel</button>
</mat-dialog-actions>
