import { LocalizationDictionary } from "ui-core";
import { RdsLayoutEditorLocalizationDictionary } from "./rds-layout-edit-localization-dictionary";
import { RdsPropertySheetLocalizationDictionary } from "./rds-property-sheet-localization-dictionary";

export class RdsLocalizationDictionary extends LocalizationDictionary {
  constructor() {
    super();
    this._propertySheet = new RdsPropertySheetLocalizationDictionary();
    this._layoutEditor = new RdsLayoutEditorLocalizationDictionary();
  }

  get propertySheet(): RdsPropertySheetLocalizationDictionary {
    return this._propertySheet as RdsPropertySheetLocalizationDictionary;
  }

  get layoutEditor(): RdsLayoutEditorLocalizationDictionary {
    return this._layoutEditor as RdsLayoutEditorLocalizationDictionary;
  }
}

export const RDS_LOCALIZATION_DICTIONARY = new RdsLocalizationDictionary();
