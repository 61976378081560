<div class="app-header">
  <div class="app-header__menu-button">
    <header-button
      *ngIf="displayMode == DisplayMode.Mobile || previewMode"
      [buttonConfig]="menuButtonConfig"
      [isSelected]="sidebarOpened"
      icon="Menu"
    ></header-button>
  </div>
  <div class="app-header__logo">
    <span class="app-header__logo--long" *ngIf="standalone">{{ appTitle.fullName }} </span>
    <span class="app-header__logo--short" *ngIf="standalone">{{ appTitle.shortName }} </span>
  </div>
  <div class="app-header__bar">
    <div
      class="layout-settings-wrapper"
      *ngIf="!previewMode && displayMode === DisplayMode.Desktop"
    >
      <layout-settings></layout-settings>
    </div>
    <undo-redo *ngIf="!previewMode"></undo-redo>
    <div class="app-header__first-group">
      <components-header-buttons *ngIf="!previewMode"></components-header-buttons>
    </div>
    <div
      class="app-header__second-group"
      [ngClass]="{ 'app-header__regrouped-buttons': previewMode }"
    >
      <div class="app-header__sub-group-buttons">
        <save-settings
          *ngIf="!previewMode"
          (saveReport)="save()"
          (saveAsReport)="saveAs()"
        ></save-settings>
        <div
          class="app-header__group-wrapper"
          [ngClass]="{ 'app-header__group-wrapper--desktop': displayMode === DisplayMode.Desktop }"
        >
          <header-button
            [buttonConfig]="filterButton"
            [isSelected]="toolbarOpened"
            icon="Filter"
          ></header-button>
          <user-menu></user-menu>
        </div>
      </div>
      <div class="app-header__sub-group-buttons">
        <header-button
          *ngIf="hasAccessToEdit"
          data-test="preview-mode-button"
          [buttonConfig]="previewButtonConfig"
          [isSelected]="previewMode"
          [icon]="previewMode ? 'View' : 'Hide'"
        >
        </header-button>
        <project-info></project-info>
        <div class="app-header__toggle-switch-wrapper">
          <toggle-switch
            label="Dark"
            [value]="isDarkTheme"
            (changeValue)="toggleTheme()"
          ></toggle-switch>
        </div>
      </div>
    </div>
  </div>
</div>
