import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { EditableType } from "../../../meta/decorators/editable-type.decorator";
import { ConnectorRoles } from "../../decorators/connector-roles.decorator";
import { HorizontalAlignment } from "../../models/horizontal-alignment";
import { Orientation } from "../../models/orientation";
import { LegendItemConfig } from "../legend-item/legend-item-config";

@Component({
  selector: "km-legend",
  templateUrl: "./legend.component.html",
  styleUrls: ["./legend.component.scss"]
})
@ConnectorRoles()
@EditableType({ fullName: "LegendComponent", title: "legend" })
export class LegendComponent {
  HorizontalAlignment = HorizontalAlignment;
  Orientation = Orientation;

  @Input()
  public itemList: LegendItemConfig[] = [];

  @Input()
  public alignment: HorizontalAlignment = HorizontalAlignment.Left;

  @Input()
  public orientation: Orientation = Orientation.Horizontal;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  public setHidden(index: number, newValue: boolean): void {
    if (index > this.itemList.length) {
      return;
    } else {
      this.itemList[index].hidden = newValue;
      this.refresh();
    }
  }

  public refresh(): void {
    this.changeDetectorRef.detectChanges();
  }
}
