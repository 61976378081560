import { Routes } from "@angular/router";
import { CanDeactivateReport } from "./can-deactive-report.guard";
import { PageComponent } from "./elements/components/page/page.component";

export const routes: Routes = [
  {
    path: "report/:id",
    component: PageComponent,
    canDeactivate: [CanDeactivateReport]
  },
  {
    path: "",
    redirectTo: "/",
    pathMatch: "full"
  }
];
