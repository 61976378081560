import { DataTableSchema } from "./data-table-schema";

export type DataRow = any[];
export type DataTable = DataRow[];

export class TypedDataTable {
  invalidQueryMessage: string;
  queryId: string;
  schema: DataTableSchema;
  table: DataTable;
}

export type DataTableResponse = TypedDataTable[];
