import { createAction, props } from "@ngrx/store";
import { ReportId } from "../../../../core/models/report-id";
import { ConfirmationDialogResult } from "../../../../shared/components/confirmation-dialog/confirmation-dialog.component";

export type ChosenActionCallback = (chosenAction: boolean | null) => any;

export namespace SaveChangesDialogActions {
  export const projectSpecificSaveChangesDialog = createAction(
    "[Dialog] Project Specific Save Changes Dialog",
    props<{
      displayText: string;
      callback: ChosenActionCallback;
      reportId: ReportId;
    }>()
  );

  export const saveChangesInDialog = createAction(
    "[Dialog] Save Changes Dialog",
    props<{
      displayText: string;
      reportId: ReportId;
    }>()
  );

  export const saveChangesInDialogClosed = createAction(
    "[Dialog] Save Changes Dialog Closed",
    props<{ dialogResult: ConfirmationDialogResult }>()
  );
}
