import { DateFormatterService } from "../../../environment/services/date-formatter.service";
import { ValidationContext } from "../../../meta/models/validation-context";
import {
  Dictionary,
  isEmptyOrNotDefined2,
  isNotDefined,
  isWhiteSpaceOrNotDefined,
  Maybe
} from "../../../ts-utils";
import { FilterConfigurationDto, TimeRange, TimeRangeConfigurationDto } from "../../models";
import { CustomFilterValue } from "../../models/filter/filter-type-descriptor";

export const START = "Start";
export const END = "End";
export const CURRENT = "Current";
export const LIVE_MODE_TIME_UNIT = "hours";
export const DEFAULT_SPAN_IN_HOURS = 4;

const GENERIC_DATE_PARAMETER_STRING = `(${START}|${END})([+|-]\\d+)?`;
export const LIVE_MODE_REGEX = new RegExp(
  `(${CURRENT})(-(\\d+)(\\.(\\d+))?)(seconds|minutes|hours|days|weeks|months|years)`
);
export const FIXED_DATE_REGEX = new RegExp(
  "^Date\\((\\d{4})(,(\\d{1,2}))(,(\\d{1,2}))?(,(\\d{1,2}))?(,(\\d{1,2}))?(,(\\d{1,2}))?\\)$"
);
export const GLOBAL_OFFSET_REGEX = new RegExp(
  `^(s*)(${START}|${END})(s*)(([+|-]s*[0-9]+)(s*)(minutes|hours|days|months|years|m|h|d|M|y))?$`
);
export const GENERIC_DATE_PARAMETER_REGEX = new RegExp(GENERIC_DATE_PARAMETER_STRING);
export const GENERIC_DATE_REGEX = new RegExp(
  `^Date\\((\\d{4}|${GENERIC_DATE_PARAMETER_STRING})` +
    `(,(\\d{1,2}|${GENERIC_DATE_PARAMETER_STRING}))` +
    `(\\,(\\d{1,2}|${GENERIC_DATE_PARAMETER_STRING}))?` +
    `(\\,(\\d{1,2}|${GENERIC_DATE_PARAMETER_STRING}))?` +
    `(\\,(\\d{1,2}|${GENERIC_DATE_PARAMETER_STRING}))?\\)$`
);

export function validateInputForFilterExpression(
  expression: string,
  validationContext: ValidationContext
): boolean {
  return (
    expression === "" ||
    isGlobalOffsetDateExpression(expression) ||
    isGenericDateExpression(expression)
  );
}

export function isRelativeExpression(expression: string): boolean {
  return (
    isGlobalOffsetDateExpression(expression) ||
    (isGenericDateExpression(expression) && !isFixedDateExpression(expression))
  );
}

export function isFixedDateExpression(expression: Maybe<string>): expression is string {
  if (isNotDefined(expression)) {
    return false;
  }
  expression = expression.replace(/\s/g, "");
  return FIXED_DATE_REGEX.test(expression);
}

export function isGlobalOffsetDateExpression(expression: Maybe<string>): expression is string {
  if (isNotDefined(expression)) {
    return false;
  }
  expression = expression.replace(/\s/g, "");
  return GLOBAL_OFFSET_REGEX.test(expression);
}

export function isGenericDateExpression(expression: string): expression is string {
  if (isNotDefined(expression)) {
    return false;
  }
  expression = expression.replace(/\s/g, "");
  return GENERIC_DATE_REGEX.test(expression);
}

export function isEmptyFilterConfig(filterConfig: FilterConfigurationDto): boolean {
  return (
    isWhiteSpaceOrNotDefined(filterConfig.timeRange.fromExpression) &&
    isWhiteSpaceOrNotDefined(filterConfig.timeRange.toExpression) &&
    areCustomFiltersEmpty(filterConfig.customFilters)
  );
}

export function doesFilterExpressionUseSourceFilter(
  timeRangeConfig: TimeRangeConfigurationDto
): boolean {
  return (
    isRelativeExpression(timeRangeConfig.fromExpression) ||
    isRelativeExpression(timeRangeConfig.toExpression)
  );
}

export function areCustomFiltersEmpty(customFilters: Dictionary<CustomFilterValue>): boolean {
  return (
    Object.keys(customFilters).length === 0 ||
    Object.values(customFilters).every((cf) => isEmptyOrNotDefined2(cf))
  );
}

export function validateTimeRangeInputFormat(
  timeRange: string,
  dateFormatter: DateFormatterService
): Maybe<TimeRange> {
  const splittedDates = timeRange.split(" - ");
  if (
    dateFormatter.isDateInvalid(splittedDates[0]) ||
    dateFormatter.isDateInvalid(splittedDates[1])
  ) {
    return null;
  }
  const newTimeRange = convertExpressionToTimeRange(
    splittedDates[0],
    splittedDates[1],
    dateFormatter
  );
  return newTimeRange;
}

export function convertExpressionToTimeRange(
  from: string,
  to: string,
  dateFormatter: DateFormatterService
): TimeRange {
  const fromDate: Date = dateFormatter.createMomentFromString(from).toDate();
  const toDate: Date = dateFormatter.createMomentFromString(to).toDate();
  return new TimeRange(fromDate, toDate);
}

export function isCorrectTimeRangeInterval(startDate: Date, endDate: Date): boolean {
  return startDate && endDate && startDate < endDate;
}

export function isTimeRangeSame(
  oldTimeRangeConfiguration: TimeRangeConfigurationDto,
  newTimeRangeConfiguration: TimeRangeConfigurationDto
): boolean {
  return (
    oldTimeRangeConfiguration.fromExpression === newTimeRangeConfiguration.fromExpression &&
    oldTimeRangeConfiguration.toExpression === newTimeRangeConfiguration.toExpression
  );
}
