<div class="property-sheet-subcategory__title">
  {{ subCategory.key | translate }}
</div>
<div class="property-sheet-subcategory__props">
  <div
    *ngFor="
      let subcategoryProperty of subCategory.value
        | sortByOrder
        | categorizeProperties
        | keyvalue : sortGroups;
      trackBy: trackByIndex
    "
  >
    <ng-container *ngIf="subcategoryProperty.key === ''; else groupedProperties">
      <property-sheet-editor-wrapper
        *ngFor="let itemInfo of subcategoryProperty.value; trackBy: sectionPropNames"
        [targetInfo]="getTargetInfo(itemInfo.propertyInfo)"
        [propertyConfig]="itemInfo.propertyInfo"
        [parentInfo]="itemInfo.parentInfo"
      ></property-sheet-editor-wrapper>
    </ng-container>
    <ng-template #groupedProperties>
      <grouped-property-sheet-editors
        [properties]="subcategoryProperty.value"
        [targetInfo]="targetInfo"
      ></grouped-property-sheet-editors>
    </ng-template>
  </div>
</div>
