<section
  class="filter-element"
  [ngClass]="{
    'filter-element--selected': isOpened && isDropdown
  }"
  (click)="buttonConfig.clickFunction()"
>
  <div class="filter-element__items">
    <i class="{{ 'abb-icon abb-icon--large ' + buttonConfig.icon }}"></i>
    <span [class]="'filter-element__label ' + buttonConfig.class">{{
      selectedValueLabel | translate
    }}</span>
    <i
      class="abb-icon abb-icon--medium filter-element__arrow"
      [ngClass]="isOpened && isDropdown ? 'Up' : isDropdown ? 'Down' : 'Right'"
    ></i>
  </div>
</section>
