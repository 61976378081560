import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map } from "rxjs/operators";
import { EntityId } from "../../../meta/models/entity";
import { ReportBrowserNode } from "../../models/report-browser-node";
import { CachedReportsActions } from "./cached-reports.actions";

const ROOT_ENTITY = "Root";

@Injectable()
export class BrowsingReportEffects {
  constructor(private actions$: Actions) {}

  // TODO Investigate if this should be renamed/redesigned
  fetch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CachedReportsActions.fetchReport),
      map(({ reportId }) => {
        const selectedReport = createReportComponentState(ROOT_ENTITY, reportId);
        return CachedReportsActions.fetchReportSuccess({ cachedReportNode: selectedReport });
      })
    )
  );
}

function createReportComponentState(root: EntityId, reportId: EntityId): ReportBrowserNode {
  const rootOfReportTree: ReportBrowserNode = new ReportBrowserNode(root, [], reportId);
  return rootOfReportTree;
}
