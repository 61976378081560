import { Directive, ElementRef, Input, OnInit, Renderer2 } from "@angular/core";
import { Subscription, fromEvent } from "rxjs";
import { filter, take, takeUntil } from "rxjs/operators";
import { PositionDto } from "../../core/models/position";
import { EntityId } from "../../meta/models/entity";
import { isDefined } from "../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { CSS_COMPONENT_SELECTED } from "../components/base/base.component";
import { HIDDEN_ELEMENT_CLASS } from "../helpers/dom-element-visibility.helper";
import {
  calculateScrollOffsetX,
  calculateScrollOffsetY,
  getSelectedChildrenIds,
  getSelectionBoxRectangle,
  isWidgetInsideSelection,
  setSelectionBoxStyle
} from "../helpers/drag-selection.helper";
import { CLOSEST_COMPONENT_SELECTOR } from "../helpers/widget-dragging.helper";
import { ComponentSelectionService } from "../services/component-selection.service";

@Directive({
  selector: "[widgetsContainer]"
})
export class WidgetsContainerDirective implements OnInit {
  @Input("widgetsContainer") isSelectionEnabled!: boolean;
  @Input() containerId!: EntityId;
  @Input() isSelectable: boolean = true;

  containerElement!: HTMLElement;
  mouseMoveSubscription: Maybe<Subscription>;
  dragStartPoint: Maybe<PositionDto>;
  selectionBoxElement!: HTMLElement;
  containerPositon!: PositionDto;
  isDraggingStarted: boolean = false;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private selectionService: ComponentSelectionService
  ) {
    this.containerElement = this.el.nativeElement as HTMLElement;
  }

  ngOnInit(): void {
    this.createSelectionBox();
    fromEvent(this.containerElement, "pointerdown")
      .pipe(filter(() => this.isSelectionEnabled))
      .subscribe((event: Event) => {
        this.containerPositon = calculateContainerPosition(event);
        this.dragStartPoint = this.getCoordsInsideContainer(event as MouseEvent);
        this.registerDragSelectionEvents();
      });
  }

  registerDragSelectionEvents(): void {
    const mouseup$ = fromEvent(document, "pointerup");
    const mousemove$ = fromEvent(document, "pointermove").pipe(takeUntil(mouseup$));
    mousemove$.pipe(take(1)).subscribe(() => {
      this.onDragStart();
    });
    this.mouseMoveSubscription = mousemove$.subscribe((event: Event) => {
      this.onMouseMove(event);
    });
    mouseup$.pipe(take(1)).subscribe(() => {
      this.onMouseUp();
    });
  }

  createSelectionBox(): void {
    this.selectionBoxElement = this.renderer.createElement("div");
    this.renderer.addClass(this.selectionBoxElement, HIDDEN_ELEMENT_CLASS);
    this.renderer.addClass(this.selectionBoxElement, "selection-box");
    this.renderer.appendChild(this.containerElement, this.selectionBoxElement);
  }

  getCoordsInsideContainer(event: MouseEvent): PositionDto {
    const offsetX = event.pageX - this.containerPositon.left + this.containerElement.scrollLeft;
    const offsetY = event.pageY - this.containerPositon.top + this.containerElement.scrollTop;
    return {
      left: offsetX,
      top: offsetY
    };
  }

  onDragStart(): void {
    this.selectionService.clearSelection();
    this.isDraggingStarted = true;
    this.renderer.removeClass(this.selectionBoxElement, HIDDEN_ELEMENT_CLASS);
  }

  onMouseMove(event: Event) {
    if (isDefined(this.dragStartPoint)) {
      const movePoint = limitMovePoint(
        this.getCoordsInsideContainer(event as MouseEvent),
        this.containerElement
      );
      setSelectionBoxStyle(
        this.selectionBoxElement,
        getSelectionBoxRectangle(movePoint, this.dragStartPoint)
      );
      this.containerElement.scrollBy(
        calculateScrollOffsetX(movePoint, this.containerElement),
        calculateScrollOffsetY(movePoint, this.containerElement)
      );
      document.getSelection()?.empty();
      this.visualizeSelectionOfChilren();
    }
  }

  onMouseUp() {
    this.renderer.addClass(this.selectionBoxElement, HIDDEN_ELEMENT_CLASS);
    const selectedWidgetsIds = getSelectedChildrenIds(this.containerElement, true);
    if (selectedWidgetsIds.length > 0) {
      this.selectionService.selectedComponentsIds = selectedWidgetsIds;
    } else if (this.isDraggingStarted) {
      const selectableContainerId: EntityId = this.isSelectable
        ? this.containerId
        : this.selectionService.findBasicCardAncestor(this.containerId);
      this.selectionService.selectedComponentsIds = [selectableContainerId];
    }
    this.isDraggingStarted = false;
  }

  visualizeSelectionOfChilren(): void {
    this.containerElement.querySelectorAll(CLOSEST_COMPONENT_SELECTOR).forEach((child) => {
      if (isWidgetInsideSelection(child as HTMLElement, this.selectionBoxElement)) {
        child.classList.add(CSS_COMPONENT_SELECTED);
      } else {
        child.classList.remove(CSS_COMPONENT_SELECTED);
      }
    });
  }
}

function calculateContainerPosition(event: Event): PositionDto {
  return {
    left: (event as PointerEvent).pageX - (event as PointerEvent).offsetX,
    top: (event as PointerEvent).pageY - (event as PointerEvent).offsetY
  };
}

function limitMovePoint(movePoint: PositionDto, containerElement: HTMLElement): PositionDto {
  if (movePoint.left < 0) movePoint.left = 0;
  if (movePoint.top < 0) movePoint.top = 0;
  if (movePoint.left > containerElement.scrollWidth) movePoint.left = containerElement.scrollWidth;
  if (movePoint.top > containerElement.scrollHeight) movePoint.top = containerElement.scrollHeight;
  return movePoint;
}
