<div class="load-sharing-chart__wrapper">
  <highcharts-chart-extended
    class="chart-container"
    [Highcharts]="Highcharts"
    [options]="chartOptions"
  >
  </highcharts-chart-extended>
  <div class="load-sharing-chart__legend">
    <km-legend
      [itemList]="[
        { name: 'U-V', color: view.uvColor },
        { name: 'V-W', color: view.vwColor },
        { name: 'W-U', color: view.wuColor }
      ]"
    ></km-legend>
  </div>
</div>
