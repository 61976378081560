import {
  BaseViewConfigDto,
  ComponentCssSize,
  Configurable,
  construct,
  DeepPartial,
  EditableType,
  EditorType,
  Serializable
} from "ui-core";

const TYPE_NAME = "RotorShapeChartViewConfig";

@EditableType({ fullName: TYPE_NAME })
export class RotorShapeChartViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  @Configurable({ displayName: "Smallest Airgap Color", editorType: EditorType.ColorPicker })
  @Serializable("#FF0000")
  smallestAirgapColor!: string;

  @Configurable({ displayName: "Average Airgap Color", editorType: EditorType.ColorPicker })
  @Serializable("#843c9b")
  averageAirgapColor!: string;

  @Configurable({ displayName: "Largest Airgap Color", editorType: EditorType.ColorPicker })
  @Serializable("#4db82c")
  largestAirgapColor!: string;

  @Configurable({ displayName: "Calculated Rotor Shape Color", editorType: EditorType.ColorPicker })
  @Serializable("#4D187F")
  calculatedRotorShapeColor!: string;

  @Configurable({ displayName: "Calculated Rotor Shape Color", editorType: EditorType.ColorPicker })
  @Serializable("black")
  detectedPoleValuesColor!: string;

  constructor(viewConfigDto: DeepPartial<RotorShapeChartViewConfig> = {}) {
    super();
    construct(this, viewConfigDto, TYPE_NAME, { size: getDefaultSize() });
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("550", "400");
}
