import { NestedProperty } from "../../property-sheet/models/nested-property";
import {
  isArray,
  isDefined,
  isEmptyOrNotDefined,
  isNotDefined,
  isString,
  last
} from "../../ts-utils";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { DATA_CONNECTOR_DTO } from "../models/entity-type.constants";
import { InterpolationSourceOptions } from "../models/interpolation-source-options";
import {
  InterpolationParams,
  InterpolationSourceType,
  TargetProperty
} from "../models/property-interpolation-models";
import {
  STRING_INTERPOLATION_INPUT,
  STRING_INTERPOLATION_SEPARATORS
} from "../models/property-interpolation.constants";
import { DataConnectorDescriptor } from "../models/store/data-connector-descriptor";

const KM_PROPERTY_ALIAS_NAME = "aliasName";
const KM_PROPERTY_ORIGINAL_NAME = "realName";

export function findInterpolationSourceInConnectorGroups(
  sourceOptions: InterpolationSourceOptions,
  sourceType: string,
  sourceParams: string[]
): InterpolationSourceType {
  const groupIdentifier = sourceParams[1];
  const connectorIndexInGroup = Number(sourceParams[2]) - 1;
  const descriptorsWithGroupId: Maybe<DataConnectorDescriptor[]> =
    sourceOptions.connectorDescriptors?.filter(
      (connDescriptor: DataConnectorDescriptor) =>
        connDescriptor.connectorView?.groupId === groupIdentifier
    );

  return isInterpolationSourceInvalid(descriptorsWithGroupId, connectorIndexInGroup)
    ? null
    : (descriptorsWithGroupId[connectorIndexInGroup] as any)[sourceType];
}

function isInterpolationSourceInvalid(
  descriptorsInGroup: Maybe<DataConnectorDescriptor[]>,
  realConnectorIndex: number
): boolean {
  return (
    isEmptyOrNotDefined(descriptorsInGroup) || realConnectorIndex + 1 > descriptorsInGroup.length
  );
}

export function isCircularSearching(
  source: InterpolationSourceType,
  propertyName: string,
  targetPropertyInfo: TargetProperty
): boolean {
  return (
    isDefined(source) &&
    targetPropertyInfo.localPath.includes(propertyName) &&
    requiresRecursiveSearchingForActualValue(source, propertyName, targetPropertyInfo)
  );
}

function requiresRecursiveSearchingForActualValue(
  source: InterpolationSourceType,
  propertyName: string,
  targetPropertyInfo: TargetProperty
): boolean {
  const potentialCircularValue = "." + propertyName + "}";
  return (
    targetPropertyInfo.targetType === (source as any).typeName ||
    source[last(targetPropertyInfo.localPath)]?.includes(potentialCircularValue)
  );
}

export function shouldTakeValueFromSignalName(
  interpolationParams: InterpolationParams,
  aliasMode: boolean
): string {
  let propertyNameToTakeValueFrom = interpolationParams.propertyName;
  if (needsConnectorTitle(interpolationParams)) {
    propertyNameToTakeValueFrom = aliasMode ? KM_PROPERTY_ALIAS_NAME : KM_PROPERTY_ORIGINAL_NAME;
  }
  if (propertyNameToTakeValueFrom === KM_PROPERTY_ORIGINAL_NAME) {
    propertyNameToTakeValueFrom = "title";
  }
  return propertyNameToTakeValueFrom;
}

function needsConnectorTitle(interpolationParams: InterpolationParams): boolean {
  return (
    getSourceName(interpolationParams.source) === DATA_CONNECTOR_DTO &&
    interpolationParams.propertyName === "title"
  );
}

export function containsInterpolation(propertyValue: any): boolean {
  return isArray(propertyValue)
    ? containsInterpolatedPropertyInArray(propertyValue)
    : containsInterpolatedString(propertyValue);
}

function containsInterpolatedPropertyInArray(propertyValue: any): boolean {
  return propertyValue.some((item) =>
    Object.values(item).some((value) => containsInterpolatedString(value))
  );
}

export function getInterpolatedNestedProperties(items: any): NestedProperty[] {
  return items.reduce((acc: NestedProperty[], item, index) => {
    Object.entries(item).map(([propertyName, value]) => {
      if (containsInterpolatedString(value)) {
        acc.push({ propertyName, objectIndex: index });
      }
    });
    return acc;
  }, []);
}

function containsInterpolatedString(propertyValue: any): boolean {
  return (
    isDefined(propertyValue) &&
    isString(propertyValue) &&
    isDefined(propertyValue.match(STRING_INTERPOLATION_INPUT))
  );
}

export function getPropertyValueInDeep(target: any, propertyName: string): any {
  if (isNotDefined(target)) {
    return;
  }
  if (isDefined(target[propertyName])) {
    return target[propertyName];
  } else {
    for (const property of Object.keys(target)) {
      if (isDefined(target[property]) && typeof target[property] === "object") {
        const value = getPropertyValueInDeep(target[property], propertyName);
        if (isDefined(value)) {
          return value;
        }
      }
    }
  }
}

export function extractInterpolationParams(interpolationString: string): InterpolationParams {
  const interpolationParams: string[] = interpolationString.split(STRING_INTERPOLATION_SEPARATORS);

  if (interpolationParams.length >= 3) {
    return {
      source: interpolationParams[1],
      propertyName: interpolationParams[2]
    };
  } else {
    throw new Error("Invalid interpolation string ${interpolationString}.");
  }
}

export function getSourceName(source: InterpolationSourceType): string {
  if (isNotDefined(source)) {
    return "";
  }
  if (typeof source === "string") {
    return source;
  }
  if ("typeName" in source) {
    return source.typeName;
  }
  return "";
}
