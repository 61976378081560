import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Type,
  ViewChildren
} from "@angular/core";
import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { merge as _merge } from "lodash";
import { fromEvent, Subject } from "rxjs";
import { filter, first, skip, takeUntil } from "rxjs/operators";
import { EquipmentSelector } from "../../../browsing/services/equipment.selector";
import { getLink, LinkResolver } from "../../../browsing/services/link-resolver";
import { DraggedItem } from "../../../core/models/drag/dragged-item";
import { DraggedItemType } from "../../../core/models/drag/dragged-item-type";
import { FilterConfigurationDto } from "../../../core/models/filter/filter-configuration";
import { isLinkDefined } from "../../../core/models/link";
import { PositionDto } from "../../../core/models/position";
import { ViewMode } from "../../../core/models/view-mode";
import { IFilterSelector } from "../../../core/services/filter/i-filter.selector";
import { IDragDropService } from "../../../core/services/i-drag-drop.service";
import { EquipmentDataSourceDto } from "../../../data-connectivity";
import { DataConnectorDto, isTimeSeries } from "../../../data-connectivity/models/data-connector";
import {
  sortDataPoints,
  TimeSeriesDataPointDto
} from "../../../data-connectivity/models/data-point";
import { IDataConnectorViewSelector } from "../../../data-connectivity/services/i-data-connector-view.selector";
import { Dispatcher } from "../../../dispatcher";
import { AppSettingsService } from "../../../environment/services/app-settings.service";
import { ColorListService } from "../../../environment/services/color-list.service";
import { DateFormatterService } from "../../../environment/services/date-formatter.service";
import { EnvironmentSelector } from "../../../environment/services/environment.selector";
import { LocalizationService } from "../../../i18n/localization.service";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { createUpdatedComponentsInfo } from "../../../meta/helpers/updated-entities-info.helper";
import { EntityId } from "../../../meta/models/entity";
import { TypeDescriptor } from "../../../meta/models/type-descriptor";
import { TypeProvider } from "../../../meta/services/type-provider";
import { PropertySheetService } from "../../../property-sheet/services/property-sheet.service";
import { ButtonConfig } from "../../../shared/models/button/button-config";
import { UndoRedoService } from "../../../shared/services/undo-redo.service";
import {
  CriticalError,
  DeepPartial,
  Dictionary,
  isDefined,
  isEmpty,
  isEmptyOrNotDefined,
  isNotDefined,
  last,
  Maybe,
  mergeDeep
} from "../../../ts-utils";
import { ResizableComponentDirective } from "../../directives/resizable-component.directive";
import {
  calculateNewComponentSize,
  getSizeUpdateAction,
  isFullHeight,
  isFullWidth
} from "../../helpers/component-size.helper";
import {
  getOverlappingWidgets,
  hideContainersConfigButtons,
  reorderOverlappingWidgets,
  resolveConfigButtonsCssPosition,
  revertOverlappingWidgetsZIndex,
  unhideContainersConfigButtons,
  unsetOverlappingWidgetsZIndex
} from "../../helpers/config-buttons.helper";
import { createDynamicButtons } from "../../helpers/create-dynamic-buttons.helper";
import { refreshLoadingIndicator } from "../../helpers/create-loading-indicator.helper";
import {
  HIDDEN_ELEMENT_CLASS,
  hideElement,
  unhideElement
} from "../../helpers/dom-element-visibility.helper";
import {
  getComponentButtonParams,
  LINK_BUTTON_CSS_CLASS
} from "../../helpers/get-dynamic-buttons.helper";
import { shouldDisableChartAnimations } from "../../helpers/history-view.helper";
import { isInlineModeAllowed } from "../../helpers/inline-edit.helper";
import { isAbsolutePositioningType } from "../../helpers/positioning-type.helper";
import { resolveTimeAggregationOverride } from "../../helpers/time-aggregation-default-override.helper";
import {
  findClosestContainerComponent,
  findMaxComponentOrder,
  findParentContainer,
  getParentContainerHTML,
  isDroppedAfter,
  resolveActionToUpdateOrder
} from "../../helpers/widget-dragging.helper";
import { BaseViewConfigDto, RectangleDto } from "../../models";
import { BorderSide, BorderStyleDto } from "../../models/border-style";
import { CacheOptionsDto } from "../../models/cache-options";
import { ComponentStateDto, isValidComponentState } from "../../models/component-state";
import { ComponentStyleDto, convertToCssProperties, CssSize } from "../../models/component-style";
import { ComponentTypeInfo } from "../../models/component-type-info";
import {
  canContainOtherComponents,
  isContainerWidget,
  isWidget
} from "../../models/component-type.helper";
import { DataStatus } from "../../models/data-status";
import { DropOffset } from "../../models/drop-offset";
import { DynamicButtonType } from "../../models/dynamic-button-type";
import { BASE } from "../../models/element-type.constants";
import { EquipmentActionParameters } from "../../models/equipment-action-parameters";
import { IConnectable } from "../../models/i-connectable";
import { SizeInPx } from "../../models/size-in-px";
import { createCacheUpdateObject } from "../../services/cache-options.helper";
import { ComponentDataAccessFactory } from "../../services/component-data-access-factory.service";
import { ComponentPositioningService } from "../../services/component-positioning.service";
import {
  ComponentSelectionService,
  findSelectableParent
} from "../../services/component-selection.service";
import { ComponentStateViewModelDeserializer } from "../../services/deserializers/component-state-vm.deserializer";
import { DomMapper } from "../../services/dom-mapper.service";
import { DropHelper, getDropOffsetInsideContainer } from "../../services/drop.helper";
import { DynamicDefaultsEvaluator } from "../../services/dynamic-defaults-evaluator";
import { ComponentStatePropertySelector } from "../../services/entity-selectors/component-state-property.selector";
import { ComponentStateSelector } from "../../services/entity-selectors/component-state.selector";
import { DataConnectorSelector } from "../../services/entity-selectors/data-connector.selector";
import { RuntimeSettingsSelector } from "../../services/entity-selectors/runtime-settings.selector";
import { FeatureSelector } from "../../services/feature-selector";
import { IComponentDataAccessor } from "../../services/i-component-data-accessor";
import { IComponentFactoryService } from "../../services/i-component-factory.service";
import { InlineEditService } from "../../services/inline-edit.service";
import { LinkingWidgetService } from "../../services/linking-widget.service";
import { PropertyInterpolationService } from "../../services/property-interpolation.service";
import { RuntimeViewService } from "../../services/runtime-view.service";
import { StrategyDefaultsOverrideService } from "../../services/strategy-defaults-override.service";
import { getDefaultOverridesForStrategy } from "../../services/strategy-defaults.helper";
import { ComponentStateActions } from "../../store/component-state/component-state.actions";
import { ContainerComponentViewConfig } from "../container/view-config";
import { TimeSeriesViewConfig } from "../time-series/view-config";
import { ComponentButtonParams } from "./component-button-params";
import { ComponentConstructorParams } from "./component-constructor-params";

export const CSS_BASE_CLASS = "c-base-component";
const CSS_EDIT_MODE_ACTIVE = "edit-mode--active";
export const CSS_COMPONENT_SELECTED = "component--selected";
const CSS_COMPONENT_HOVERED = "component--hovered";
const CSS_COMPONENT_HIGHLIGHTED = "component--highlighted";
const OFFSET_CORRECTION = 15;

@Component({
  selector: "c-base",
  template: "",
  changeDetection: ChangeDetectionStrategy.OnPush
})
@EditableWidget({ fullName: BASE, title: "base-component" })
export class BaseComponent
  implements OnInit, AfterViewInit, OnDestroy, IConnectable, ComponentButtonParams
{
  public dispatcher: Dispatcher;
  public propertySheetService: PropertySheetService;
  public typeProvider: TypeProvider;
  private hostElementRef: ElementRef;
  private readonly _typeDescriptor: TypeDescriptor;

  @HostBinding(`class.${CSS_EDIT_MODE_ACTIVE}`) shouldShowResizeBorder = false;
  @HostBinding(`class.${CSS_COMPONENT_SELECTED}`) isSelected = false;
  @HostBinding(`class.${CSS_COMPONENT_HOVERED}`) private _isHovered = false;
  @HostBinding(`class.${CSS_COMPONENT_HIGHLIGHTED}`) isHighlighted = false;

  @HostBinding("attr.ResizableComponentDirective")
  componentResizeEditor: ResizableComponentDirective;

  @ViewChildren(forwardRef(() => BaseComponent))
  public staticViewChildren: QueryList<BaseComponent>;

  @Input() public id: EntityId;
  @Output() public removed: EventEmitter<BaseComponent> = new EventEmitter();
  @Output() public timestampUpdated: EventEmitter<Date> = new EventEmitter();

  // selectors
  protected dataConnectorSelector: DataConnectorSelector;
  public filterSelector: IFilterSelector;
  protected environmentSelector: EnvironmentSelector;
  public componentStateSelector: ComponentStateSelector;
  public componentStatePropertySelector: ComponentStatePropertySelector;
  protected dataConnectorViewSelector: IDataConnectorViewSelector;
  public runtimeSettingsSelector: RuntimeSettingsSelector;
  public equipmentSelector: EquipmentSelector;

  protected _currentState: ComponentStateDto | null = null;
  protected _featureSelector: FeatureSelector;
  public linkResolver: LinkResolver;
  protected draggedComponentService: IDragDropService;
  protected componentFactoryService: IComponentFactoryService;
  protected filter: FilterConfigurationDto;
  protected unsubscribeSubject$: Subject<any> = new Subject();
  protected colorService: ColorListService;
  public latestTimestamp: Date;

  protected dataAccessFactory: ComponentDataAccessFactory;
  protected dataAccessor: IComponentDataAccessor;
  protected dynamicDefaultsEvaluator: DynamicDefaultsEvaluator;
  protected appSettingsService: AppSettingsService;
  protected componentPositioningService: ComponentPositioningService;
  protected runtimeViewService: RuntimeViewService;
  protected dateFormatter: DateFormatterService;
  public localizer: LocalizationService;
  public componentSelectionService: ComponentSelectionService;
  public propertyInterpolationService: PropertyInterpolationService;
  undoRedoService: UndoRedoService;
  private _isInEditMode: boolean = false;
  private _dataConnectors: DataConnectorDto[] = [];
  public disableChartAnimations: boolean = false;
  private _children: ComponentStateDto[] = [];
  private dropHelper: DropHelper;
  protected loadingSpinner: Maybe<HTMLElement>;
  protected configButtonsContainer: Maybe<HTMLDivElement>;
  private runtimeButtonsContainer: Maybe<HTMLDivElement>;
  private commonButtons: ButtonConfig[] = [];
  private overlappingWidgets: HTMLElement[] = [];
  inlineEditService: InlineEditService;
  componentStateVmDeserializer: ComponentStateViewModelDeserializer;
  private linkingWidgetService: LinkingWidgetService;
  strategyDefaultsOverrideService: StrategyDefaultsOverrideService;

  get currentState(): ComponentStateDto {
    if (!this._currentState) {
      throw new CriticalError(`Undefined current state`);
    }
    return this._currentState;
  }

  get cache(): Maybe<CacheOptionsDto> {
    return this.currentState.cache;
  }

  get runtimeSize(): SizeInPx {
    return this.currentState.view.runtimeView.runtimeSize;
  }

  get hostElement(): HTMLElement {
    return this.hostElementRef.nativeElement;
  }

  get typeDescriptor(): TypeDescriptor {
    return this._typeDescriptor;
  }

  get children(): ComponentStateDto[] {
    return this._children;
  }

  get dataConnectors(): DataConnectorDto[] {
    return this._dataConnectors;
  }

  get isEditable(): boolean {
    return this._isInEditMode;
  }

  get hasLink(): boolean {
    return isLinkDefined(this.currentState.view.link);
  }

  get isHovered(): boolean {
    return this._isHovered;
  }

  set isHovered(value: boolean) {
    this._isHovered = value;
    this.visualizeFocus();
  }

  protected get isResizable(): boolean {
    return this._isInEditMode;
  }

  private get configButtons(): ButtonConfig[] {
    return this.commonButtons.filter(
      (buttonConfig) => buttonConfig.style.buttonType === DynamicButtonType.Config
    );
  }

  private get runtimeButtons(): ButtonConfig[] {
    return this.commonButtons.filter(
      (buttonConfig) => buttonConfig.style.buttonType === DynamicButtonType.Runtime
    );
  }

  constructor(params: ComponentConstructorParams, hostElementRef: ElementRef) {
    this._featureSelector = params.featureSelector;
    this.dispatcher = params.dispatcher;
    this.propertySheetService = params.propertySheetService;
    this.typeProvider = params.typeProvider;
    this.hostElementRef = hostElementRef;
    this.linkResolver = params.linkResolver;
    this.draggedComponentService = params.draggedComponentService;
    this.componentFactoryService = params.componentFactoryService;
    this.dateFormatter = params.dateFormatter;
    this.localizer = params.localizer;
    this.componentSelectionService = params.componentSelectionService;
    this.runtimeSettingsSelector = params.runtimeSettingsSelector;
    this.equipmentSelector = params.equipmentSelector;
    this.undoRedoService = params.undoRedoService;
    this._typeDescriptor = this.typeProvider.getTypeByConstructor(this.constructor);
    if (!this._typeDescriptor) {
      throw new CriticalError(
        `Type descriptor for component ${this.hostElement.tagName} has not been found`
      );
    }
    this.dataConnectorSelector = params.dataConnectorSelector;
    this.environmentSelector = params.environmentSelector;
    this.componentStateSelector = params.componentStateSelector;
    this.filterSelector = params.filterSelector;
    this.dataAccessFactory = params.dataAccessFactory;
    this.dropHelper = params.dropHelper;
    this.dynamicDefaultsEvaluator = params.dynamicDefaultsEvaluator;
    this.appSettingsService = params.appSettingsService;
    this.dataConnectorViewSelector = params.dataConnectorViewSelector;
    this.componentPositioningService = params.componentPositioningService;
    this.colorService = params.colorService;
    this.runtimeViewService = params.runtimeViewService;
    this.propertyInterpolationService = params.propertyInterpolationService;
    this._currentState = null;
    this.inlineEditService = params.inlineEditService;
    this.componentStateVmDeserializer = params.componentStateVmDeserializer;
    this.linkingWidgetService = params.linkingWidgetService;
    this.strategyDefaultsOverrideService = params.strategyDefaultsOverrideService;
  }

  ngOnInit(): void {
    this.initState();
    this.disableChartAnimations = shouldDisableChartAnimations(
      this.id,
      this.typeDescriptor.name,
      this.componentStateSelector
    );
    this.subscribeToInlineEditMode();
    this.subscribeToLinkingWidget();
  }

  private subscribeToInlineEditMode(): void {
    fromEvent<MouseEvent>(this.hostElement, "click")
      .pipe(
        filter((event) => this.canEnterInlineMode(event.detail, event.ctrlKey)),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe(() => this.inlineEditService.enterInlineEditMode(this));
  }

  private canEnterInlineMode(eventDetail: number, isMultipleSelection: boolean): boolean {
    return (
      eventDetail === 2 &&
      !isMultipleSelection &&
      !this.inlineEditService.isComponentInInlineEditMode(this.id) &&
      this.isEditable &&
      isInlineModeAllowed(
        this.currentState.type,
        (this.currentState.view as TimeSeriesViewConfig).displayStrategy
      )
    );
  }

  private subscribeToLinkingWidget(): void {
    this.linkingWidgetService.linkingModeChanged$
      .pipe(
        filter((isActive) => !isActive),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe(() => this.tryRemoveLinkingIndication());
    this.linkingWidgetService.highlightingWidgetChanged$
      .pipe(
        filter((info) => this.id === info.widgetId),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe((info) => {
        this.hostElement.scrollIntoView({ block: "nearest", behavior: "smooth" });
        this.isHighlighted = info.highlight;
      });
  }

  protected initState(): void {
    this.hostElement.id = DomMapper.getHostId(this.id);
    this.hostElement.classList.add(CSS_BASE_CLASS);
    this.hostElementRef.nativeElement.angularComponentRef = this;
    this.componentStatePropertySelector =
      this._featureSelector.createComponentStatePropertySelector(this.id);
    this.initResizeElements();
    this.preInitSubscriptions();
    this.initSubscriptions();
  }

  protected preInitSubscriptions(): void {
    return;
  }

  protected initResizeElements(): void {
    this.componentResizeEditor = new ResizableComponentDirective();
    this.componentResizeEditor.resizableComponent = this.hostElement;
    this.componentResizeEditor.onResizeEndCallback = this.onResizeEnd();
    this.componentResizeEditor.ngOnInit();
  }

  protected initSubscriptions(): void {
    this.componentStatePropertySelector.subscribe((currentState) => {
      const isFirstUpdateFromStore = this._currentState === null;
      this.updateComponentFromStoreState(currentState);
      if (isFirstUpdateFromStore) {
        this.initDynamicButtons();
      }
    });

    this.componentStatePropertySelector.subscribeOnFilter((context: FilterConfigurationDto) => {
      if (isDefined(this.filter)) {
        this.updateDisplay("filter updated");
      }
      this.filter = context;
    });

    this.subscribeToPeriodType();

    this.componentStatePropertySelector.selectChildren().subscribe((children) => {
      this._children = children;
    });

    this.environmentSelector
      .selectViewMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((viewMode: ViewMode) => {
        viewMode === ViewMode.EditMode ? this.enterEditMode() : this.exitEditMode();
      });

    this.componentSelectionService.selectedComponentsChanged
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((selectedComponentsIds: EntityId[]) => {
        this.onSelectedComponentChange(selectedComponentsIds);
      });

    this.componentStateSelector
      .selectComponentCacheOptions(this.id)
      .pipe(skip(1), takeUntil(this.unsubscribeSubject$))
      .subscribe((cacheOptions) => {
        this.onCacheOptionsChange(cacheOptions.enabled, cacheOptions.currentTimestamp);
        this.updateDisplay("cache changed");
      });

    this.componentStateSelector
      .selectComponentView(this.id)
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((view) => {
        this.onViewChange(view);
        this.updateDisplay("view changed");
      });

    this.componentStateSelector
      .selectBackgroundImageData(this.id)
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((imageData) => {
        this.applyBackgroundImage(imageData || "");
      });

    this.componentStateSelector
      .selectComponentDataStatus(this.id)
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((dataStatus) => {
        this.onDataStatusChange(dataStatus);
      });

    this.componentStateSelector
      .selectLink(this.id)
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((link) => {
        this.showOrHideLinkButton();
      });

    this.dataConnectorViewSelector
      .selectForComponent(this.id)
      .pipe(skip(1), takeUntil(this.unsubscribeSubject$))
      .subscribe(() => {
        this.updateDisplay("connnector view changed");
      });

    this.equipmentSelector
      .selectEquipmentTree()
      .pipe(
        first((previousEquipment) => {
          return isLinkDefined(this.currentState.view.link) && isDefined(previousEquipment);
        })
      )
      .subscribe(() => {
        this.showOrHideLinkButton();
      });

    this.undoRedoService.widgetModified$
      .pipe(
        filter((widgetId) => widgetId === this.id),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe(() => {
        this.hostElement.scrollIntoView({
          block: "center",
          behavior: "smooth"
        });
      });

    this.componentStateSelector
      .selectDisplayStrategy(this.id)
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe(() => this.showOrHideInlineButton());
  }

  protected subscribeToPeriodType(): void {
    this.componentStatePropertySelector.subscribeOnPeriodType((periodType) => {
      this.updateDisplay("period type updated");
    }, 1);
  }

  protected onViewChange(view: BaseViewConfigDto): void {
    if (isDefined(view.runtimeView) && isDefined(view.css)) {
      this.applyCssToHostElement(view.css);
      resolveConfigButtonsCssPosition(
        view,
        this.configButtonsContainer,
        this.typeDescriptor.name,
        this.configButtons.length
      );
    }
    if (view.hidden != null) {
      this.setHidden(view.hidden);
    }
  }

  protected onDataStatusChange(dataStatus: DataStatus): void {
    const showSpinner: boolean = dataStatus === DataStatus.WaitingForData;
    this.loadingSpinner = refreshLoadingIndicator(
      showSpinner,
      this.loadingSpinner,
      this.hostElementRef
    );
  }

  protected applyBackgroundImage(base64Image: string): void {
    if (this.shouldApplyBackgroundImageToHost()) {
      const backgroundImageOptions = {
        "background-image": "url('" + base64Image + "')",
        "background-repeat": this.currentState.view.css?.backgroundRepeat ?? "no-repeat"
      };
      Object.assign(this.hostElement.style, backgroundImageOptions);
    }
  }

  protected initDynamicButtons(): void {
    this.commonButtons = getComponentButtonParams(this);
    this.initConfigButtons();
    this.initRuntimeButtons();
  }

  protected initConfigButtons(): void {
    this.configButtonsContainer = createDynamicButtons(this.configButtons, this.localizer);
    hideElement(this.configButtonsContainer);
    this.hostElementRef.nativeElement.appendChild(this.configButtonsContainer);
  }

  protected initRuntimeButtons(): void {
    this.runtimeButtonsContainer = createDynamicButtons(this.runtimeButtons, this.localizer);
    this.showOrHideLinkButton();
    hideElement(this.runtimeButtonsContainer);
    this.hostElementRef.nativeElement.appendChild(this.runtimeButtonsContainer);
  }

  protected showOrHideInlineButton(): void {
    return;
  }

  @HostListener("mouseenter", ["$event"])
  onMouseenter(event: MouseEvent): void {
    if (this.shouldUnhideConfigButtons(event)) {
      unhideElement(this.configButtonsContainer);
      this.makeConfigButtonsFullyVisible();
    }
    this.hideParentsConfigButtons(event);
    this.isHovered = true;
    this.tryAddLinkingIndication();
  }

  shouldUnhideConfigButtons(event: MouseEvent): boolean {
    return (
      this.isEditable &&
      !this.linkingWidgetService.isLinkingModeActive() &&
      isDefined(this.configButtonsContainer) &&
      this.canUnhideConfigButtons()
    );
  }

  private canUnhideConfigButtons(): boolean {
    return (
      this.canUnhideConfigButtonsBasedOnDragging() &&
      this.inlineEditService.canUnhideConfigButtons(this.id)
    );
  }

  private canUnhideConfigButtonsBasedOnDragging(): boolean {
    return isDefined(this.draggedComponentService.target)
      ? this.isSingleComponentSelected()
      : !this.componentSelectionService.isWidgetInMultipleSelection(this.id);
  }

  private isSingleComponentSelected(): boolean {
    return (
      this.componentSelectionService.isComponentSelected(this.id) &&
      !this.componentSelectionService.isMultipleSelectionActive
    );
  }

  protected makeConfigButtonsFullyVisible(): void {
    if (isDefined(this.draggedComponentService.target)) {
      return;
    }
    this.overlappingWidgets = getOverlappingWidgets(this.hostElement, this.configButtonsContainer);
    unsetOverlappingWidgetsZIndex(this.overlappingWidgets, this.componentPositioningService);
    if (!isEmpty(this.overlappingWidgets)) {
      reorderOverlappingWidgets(this.hostElement);
    }
  }

  protected hideParentsConfigButtons(event: MouseEvent): void {
    hideContainersConfigButtons(event);
  }

  private tryAddLinkingIndication(): void {
    if (this.canLinkWidget()) {
      this.isHighlighted = true;
      this.componentPositioningService.setDragOverlayPointerCursor(this.hostElement);
    }
  }

  @HostListener("mouseleave", ["$event"])
  onMouseleave(event: MouseEvent): void {
    if (this.isEditable && isDefined(this.configButtonsContainer)) {
      hideElement(this.configButtonsContainer);
      this.revertOverlappingWidgetsState();
      this.unhideParentsConfigButtons(event);
    }
    this.isHovered = false;
    this.tryRemoveLinkingIndication();
  }

  protected revertOverlappingWidgetsState(): void {
    revertOverlappingWidgetsZIndex(this.overlappingWidgets, this.componentPositioningService);
    this.overlappingWidgets = [];
  }

  protected unhideParentsConfigButtons(event: MouseEvent): void {
    unhideContainersConfigButtons(event);
  }

  hideConfigButtons(): void {
    if (isDefined(this.configButtonsContainer)) {
      hideElement(this.configButtonsContainer);
    }
  }

  unhideConfigButtons(): void {
    if (isDefined(this.configButtonsContainer)) {
      unhideElement(this.configButtonsContainer);
    }
  }

  // TODO: try finding better solution. Currently is needed because of dynamic creation of buttons
  protected showOrHideLinkButton(): void {
    const linkButtonParams = this.runtimeButtons.find(
      (button) => button.style.title === this.localizer.buttons.Link
    );
    const linkButton =
      this.runtimeButtonsContainer?.getElementsByClassName(LINK_BUTTON_CSS_CLASS)[0];
    if (isDefined(linkButton) && isDefined(linkButtonParams)) {
      linkButtonParams.shouldShow()
        ? unhideElement(linkButton as HTMLElement)
        : hideElement(linkButton as HTMLElement);

      const url: Maybe<string> = getLink(this);
      if (isDefined(url)) {
        (linkButton as HTMLAnchorElement).href = url;
      }
    }
  }

  ngAfterViewInit(): void {
    this.dataConnectorSelector
      .selectForComponent(this.id)
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((connectors) => {
        this._dataConnectors = connectors;
        this.updateDisplay("onDataConnectorsChange");
      });
  }

  protected updateComponentFromStoreState(currentState: ComponentStateDto): void {
    this._currentState = currentState;
    this.dataAccessor = this.dataAccessFactory.createAccessor(this._currentState);
    // underlying assumption is that currentState has nested objects with preserved prototypes
    if (!isValidComponentState(this.currentState)) {
      console.error("Component state has no view config", this.currentState);
    }
  }

  private onSelectedComponentChange(selectedComponentsIds: EntityId[]): void {
    const newSelectedState = isDefined(selectedComponentsIds.find((id) => id === this.id));
    if (this.isSelected !== newSelectedState) {
      this.isSelected = newSelectedState;
      this.visualizeFocus();
      this.isSelected ? this.enableResize() : this.disableResize();
    }
  }

  protected visualizeFocus(): void {
    return;
  }

  ngOnDestroy(): void {
    this.componentStatePropertySelector.unsubscribe();
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  protected enableResize(): void {
    if (!this.isResizable) {
      return;
    }
    this.componentResizeEditor.enableResizeElements();
  }

  protected disableResize(): void {
    this.componentResizeEditor.disableResizeElements();
  }

  protected enterEditMode(): void {
    this._isInEditMode = true;
    this.showOrHideRuntimeButtons();
    if (this.isResizable) {
      this.shouldShowResizeBorder = true;
    }
  }

  protected exitEditMode(): void {
    this._isInEditMode = false;
    this.showOrHideRuntimeButtons();
    this.shouldShowResizeBorder = false;
  }

  private showOrHideRuntimeButtons(): void {
    if (isDefined(this.runtimeButtonsContainer)) {
      this.isEditable
        ? hideElement(this.runtimeButtonsContainer)
        : unhideElement(this.runtimeButtonsContainer);
    }
  }

  public getFormattedLatestTimestamp(): string {
    return this.dateFormatter.formatDate(this.latestTimestamp);
  }

  applyCssToHostElement(css: Partial<ComponentStyleDto>): void {
    const adjustedCss = this.adjustCss(css);
    const fullCss: ComponentStyleDto = _merge(new ComponentStyleDto(), adjustedCss);
    this.applyBorderStyleToHostElement(fullCss);
    Object.assign(this.hostElement.style, convertToCssProperties(fullCss));
  }

  protected adjustCss(css: Partial<ComponentStyleDto>): Partial<ComponentStyleDto> {
    return {
      ...css,
      ...this.getCssSize(this.runtimeSize)
    };
  }

  protected getCssSize(runtimeSize: SizeInPx): CssSize {
    const width = { width: `${runtimeSize.widthInPx}px` };
    const height = { height: `${runtimeSize.heightInPx}px`, minHeight: "0" };
    return {
      ...width,
      ...height
    };
  }

  private applyBorderStyleToHostElement(css: Partial<ComponentStyleDto>): void {
    let borderCssStyle = {
      borderLeft: "",
      borderRight: "",
      borderTop: "",
      borderBottom: "",
      borderRadius: ""
    };
    let borderRadius: string = "";

    if (isDefined(css.border)) {
      const { radius, sides, borderWidth, borderColor } = css.border as BorderStyleDto;
      if (isDefined(radius) && (isDefined(radius.radiusInput) || isDefined(radius.radiusSlider))) {
        borderRadius = `${radius.radiusSlider}px`;
        borderCssStyle = { ...borderCssStyle, borderRadius };
      }

      sides.forEach((side: string) => {
        borderCssStyle = {
          ...borderCssStyle,
          [BorderSide[side]]: `${borderWidth}px solid ${borderColor}`
        };
      });

      Object.assign(this.hostElement.style, borderCssStyle);
    }
  }

  protected shouldApplyBackgroundImageToHost(): boolean {
    return true;
  }

  protected setHidden(isHidden: boolean): void {
    if (isHidden) {
      (this.hostElementRef.nativeElement as HTMLElement).classList.add(HIDDEN_ELEMENT_CLASS);
    } else {
      (this.hostElementRef.nativeElement as HTMLElement).classList.remove(HIDDEN_ELEMENT_CLASS);
    }
  }

  protected updateDisplay(_callerInfo?: string): void {
    this.updateLatestTimestamp();
  }

  public onCacheOptionsChange(cacheMode: boolean, timestamp: Date): void {
    const childrenIds = this.children.map((component) => component.id);
    if (childrenIds.length === 0) {
      return;
    }
    const updates: Update<ComponentStateDto>[] = childrenIds.reduce((acc, componentId) => {
      const updateObject = createCacheUpdateObject(componentId, cacheMode, timestamp);
      if (updateObject != null) {
        acc.push(updateObject);
      }
      return acc;
    }, [] as Update<ComponentStateDto>[]);
    if (updates.length > 0) {
      this.dispatch(ComponentStateActions.updateMany({ componentUpdates: updates }));
    }
  }

  protected updateLatestTimestamp(): void {
    const allConnectors = this.dataConnectors;
    const sortedLatestTimestamps: Date[] = Object.values(allConnectors)
      .filter((connector) => isTimeSeries(connector) && Array.isArray(connector.dataPoints))
      .map((connector) => last(connector.dataPoints) || null)
      .filter((dataPoint) => !!dataPoint)
      .sort(sortDataPoints)
      .map((dataPoint: TimeSeriesDataPointDto) => dataPoint.x);
    const newTimestamp =
      sortedLatestTimestamps.length > 0 ? new Date(last(sortedLatestTimestamps)) : undefined;
    this.latestTimestamp = newTimestamp;
    this.timestampUpdated.emit(newTimestamp);
  }

  public connectTo(componentState: ComponentStateDto): void {
    this.id = componentState.id;
    if (this.typeDescriptor.name !== componentState.type) {
      throw new CriticalError(`New component state is of type '${componentState.type}', \
       but hosting component is of type '${this.typeDescriptor.name}'`);
    }
  }

  public dispatch(action: Action): void {
    return this.dispatcher.dispatch(action);
  }

  snapshotDispatch(action: Action): void {
    this.dispatcher.dispatch(action, {
      withSnapshot: true,
      updatedEntitiesInfo: createUpdatedComponentsInfo([this.id])
    });
  }

  getEquipmentActionParameters(
    newDataConnectorQuery: EquipmentDataSourceDto
  ): EquipmentActionParameters {
    const result: EquipmentActionParameters = {
      queries: [newDataConnectorQuery],
      ids: [this.id],
      originatorTreshold: 0
    };
    result.originatorTreshold = result.queries.length;
    return result;
  }

  shouldAllowDrop(): boolean {
    return this.canAcceptDrop();
  }

  canAcceptDrop(): boolean {
    const target = this.draggedComponentService.target;
    const targetType = target?.type;
    return (
      targetType === DraggedItemType.Signal ||
      targetType === DraggedItemType.Equipment ||
      (targetType === DraggedItemType.Component &&
        !isContainerWidget(target?.item?.descriptor?.name))
    );
  }

  selectComponent(handleAsMultiple: boolean): void {
    if (this.isEditable) {
      const isWidgetLinked: boolean = this.tryLinkWidget();
      if (isWidgetLinked) {
        return;
      } else {
        this.linkingWidgetService.deactivateLinkingMode();
      }
      const componentForSelection = this.currentState.view.selectable
        ? this.currentState
        : findSelectableParent(this.currentState.id, this.componentStateSelector);
      if (isDefined(componentForSelection)) {
        this.updatePropertySheetTargetOnSelection(componentForSelection);
        handleAsMultiple
          ? this.tryAddToSelection(componentForSelection)
          : this.selectSingle(componentForSelection);
      } else {
        this.componentSelectionService.clearSelection();
      }
    }
  }

  private tryLinkWidget(): boolean {
    if (this.canLinkWidget()) {
      this.linkingWidgetService.linkWidget(this.id);
      return true;
    }
    return false;
  }

  private canLinkWidget(): boolean {
    return this.linkingWidgetService.isLinkingModeActive() && isWidget(this.currentState.type);
  }

  private tryRemoveLinkingIndication(): void {
    if (this.isHighlighted) {
      this.isHighlighted = false;
      this.componentPositioningService.setDragOverlayGrabCursor(this.hostElement);
    }
  }

  protected updatePropertySheetTargetOnSelection(componentState: ComponentStateDto): void {
    this.propertySheetService.openOrReplaceTarget(componentState, null, true);
  }

  tryAddToSelection(component: ComponentStateDto): void {
    this.componentSelectionService.tryAddToMultipleSelection(component);
    if (
      isDefined(this.configButtonsContainer) &&
      this.componentSelectionService.isMultipleSelectionActive
    ) {
      hideElement(this.configButtonsContainer);
    }
  }

  selectSingle(component: ComponentStateDto): void {
    this.componentSelectionService.selectSingle(component);
    if (isDefined(this.configButtonsContainer)) {
      unhideElement(this.configButtonsContainer);
    }
  }

  @HostListener("dragover", ["$event"])
  onDragOver(event: DragEvent): void {
    if (this.shouldAllowDrop()) {
      event.preventDefault(); // HTML drop disabled by default
    }
    event.stopPropagation();
  }

  @HostListener("drop", ["$event"])
  drop(event: DragEvent | TouchEvent): void {
    this.undoRedoService.createSnapshot({
      updatedEntitiesInfo: createUpdatedComponentsInfo([this.id])
    });
    const target = this.draggedComponentService.target;
    if (isDefined(target) && !this.dropHelper.dropConnectorOnComponent(target, this.currentState)) {
      if (target.type === DraggedItemType.Component) {
        const droppedComponent = this.onComponentDrop(target, event);
        if (isDefined(droppedComponent)) {
          this.componentSelectionService.selectSingle(droppedComponent);
          this.propertySheetService.openOrReplaceTarget(droppedComponent, null, true);
        }
      }
    }

    event.stopPropagation();
    this.draggedComponentService.clear();
  }

  onComponentDrop(
    droppedItem: DraggedItem,
    event: DragEvent | TouchEvent
  ): Maybe<ComponentStateDto> {
    const componentDescriptorAndAlias = droppedItem.item as ComponentTypeInfo;
    const parentComponent = findClosestContainerComponent(event);
    const parentHTMLContainer = getParentContainerHTML(parentComponent);
    const componentId: Maybe<EntityId> = this.tryAddComponent(componentDescriptorAndAlias);

    if (isNotDefined(componentId)) {
      return null;
    }
    const addedComponentState = this.componentStateSelector.getById(componentId);
    if (isNotDefined(parentComponent)) {
      return addedComponentState;
    }
    const isParentInManualMode = isAbsolutePositioningType(
      (parentComponent.currentState.view as ContainerComponentViewConfig).positioningType
    );

    if (isParentInManualMode && this.shouldUpdateDroppedComponentPosition(addedComponentState)) {
      this.updateDroppedComponentPosition(
        event,
        parentHTMLContainer,
        parentComponent,
        addedComponentState
      );
    }
    if (!isParentInManualMode) {
      this.updateDroppedComponentOrder(event, componentId);
    }
    return addedComponentState;
  }

  updateDroppedComponentPosition(
    event: DragEvent | TouchEvent,
    parentHTMLContainer: HTMLElement,
    parentComponent: BaseComponent,
    addedComponentState: ComponentStateDto
  ): void {
    const dropOffset: DropOffset = getDropOffsetInsideContainer(
      event,
      this.currentState,
      parentHTMLContainer
    );
    if (this.id !== parentComponent.id) {
      dropOffset.top += OFFSET_CORRECTION;
      dropOffset.left += OFFSET_CORRECTION;
    }
    this.componentPositioningService.setDroppedComponentPosition(
      addedComponentState,
      dropOffset.left,
      dropOffset.top,
      this.runtimeViewService.calculateContentSize(parentComponent.currentState.view)
    );
  }

  updateDroppedComponentOrder(event: DragEvent | TouchEvent, componentId: EntityId): void {
    if (canContainOtherComponents(this.currentState.type)) {
      this.dispatch(ComponentStateActions.updateOrderSetAtEnd({ componentId }));
    } else {
      this.dispatch(
        resolveActionToUpdateOrder(
          isDroppedAfter(event as DragEvent, this.hostElement.getBoundingClientRect()),
          this.id,
          componentId
        )
      );
    }
  }

  tryAddComponent(componentInfo: ComponentTypeInfo): Maybe<EntityId> {
    return this.canContain(componentInfo.descriptor.constructorFunction)
      ? this.addComponent(componentInfo)
      : null;
  }

  protected canContain(componentForAttaching: Type<BaseComponent>): boolean {
    return componentForAttaching.prototype instanceof BaseComponent;
  }

  private addComponent(componentInfo: ComponentTypeInfo): Maybe<EntityId> {
    const parent = findParentContainer(this.currentState, this.componentStateSelector);
    if (isNotDefined(parent)) {
      return null;
    }
    const initialConfig: DeepPartial<ComponentStateDto> = componentInfo.descriptor.initialConfig;
    const aliasDefaults: DeepPartial<ComponentStateDto> = this.getAliasDefaults(
      componentInfo.alias.name,
      parent
    );
    const componentDefaults: DeepPartial<ComponentStateDto> = isEmptyOrNotDefined(initialConfig)
      ? aliasDefaults
      : mergeDeep(initialConfig, aliasDefaults);

    let newComponent = ComponentStateDto.createByName(
      componentInfo.descriptor.name,
      this.typeProvider,
      this.componentStateSelector,
      componentDefaults
    );

    newComponent = resolveTimeAggregationOverride(newComponent);

    this.dispatch(ComponentStateActions.addOne({ newComponent, parentId: parent.id }));
    return newComponent.id;
  }

  protected getAliasDefaults(
    alias: string,
    parent: ComponentStateDto
  ): DeepPartial<ComponentStateDto> {
    const containerComponents = this.componentStateSelector.getChildrenAsArray(parent.id);
    const maxOrder = findMaxComponentOrder(containerComponents, -1);

    return {
      view: {
        displayStrategy: alias,
        css: {
          order: (maxOrder + 1).toString()
        },
        ...getDefaultOverridesForStrategy(alias, this.strategyDefaultsOverrideService)
      }
    };
  }

  protected shouldUpdateDroppedComponentPosition(
    componentState: Maybe<ComponentStateDto>
  ): boolean {
    return (
      isDefined(componentState) &&
      !isFullHeight(componentState.view.size) &&
      !isFullWidth(componentState.view.size)
    );
  }

  @HostListener("pointerdown", ["$event"])
  protected pointerDownHandler(event: PointerEvent): void {
    event.stopPropagation();
    this.trySelectComponent(event.ctrlKey);
  }

  private trySelectComponent(isMultipleSelection: boolean): void {
    if (!this.inlineEditService.isComponentInInlineEditMode(this.id)) {
      this.selectComponent(isMultipleSelection);
    }
  }

  onResizeEnd() {
    // NOTE "this" context needs to be preserved, hence lambda
    return (resizedComponentsRects: Dictionary<RectangleDto>) => {
      this.undoRedoService.createSnapshot({
        updatedEntitiesInfo: createUpdatedComponentsInfo([this.id])
      });
      const parentComponent = this.componentStateSelector.getParent(this.currentState.id);
      const positionUpdateDict: Dictionary<PositionDto> = {};
      this.componentStateSelector
        .getManyById(Object.keys(resizedComponentsRects))
        .forEach((component) => {
          if (isDefined(resizedComponentsRects[component.id])) {
            const parent: ComponentStateDto = this.componentStateSelector.getParent(component.id);
            const parentContentSize = this.runtimeViewService.calculateContentSize(parent.view);
            let componentRect = resizedComponentsRects[component.id];
            componentRect = fitToParent(componentRect, parentContentSize);
            const newSize = calculateNewComponentSize(
              component.view.size,
              componentRect,
              parentContentSize
            );
            this.dispatch(getSizeUpdateAction(newSize, component.id));
            positionUpdateDict[component.id] = {
              left: componentRect.left,
              top: componentRect.top
            };
          }
        });
      if (isDefined(parentComponent) && this.currentState.view.css.position === "absolute") {
        this.dispatch(
          ComponentStateActions.updateChildrenPositions({
            updateDict: positionUpdateDict,
            parentId: parentComponent.id
          })
        );
      }
    };
  }
}

function fitToParent(componentRect: RectangleDto, parentContentSize: SizeInPx): RectangleDto {
  componentRect.width = Math.min(componentRect.width, parentContentSize.widthInPx);
  componentRect.left = Math.max(0, componentRect.left);
  componentRect.left = Math.min(
    componentRect.left,
    parentContentSize.widthInPx - componentRect.width
  );

  componentRect.height = Math.min(componentRect.height, parentContentSize.heightInPx);
  componentRect.top = Math.max(0, componentRect.top);
  componentRect.top = Math.min(
    componentRect.top,
    parentContentSize.heightInPx - componentRect.height
  );
  return componentRect;
}
