import { RDS_LOCALIZATION_DICTIONARY } from "projects/rds/src/assets/i18n/models/rds-localization-dictionary";
import {
  BaseViewConfigDto,
  ComponentCssSize,
  Configurable,
  ConfigurationCategory,
  construct,
  DeepPartial,
  EditableType,
  EditorType,
  LOCALIZATION_DICTIONARY,
  NotSerializable,
  PropertyCategory,
  Serializable
} from "ui-core";

const TYPE_NAME = "GradientChartViewConfig";

@EditableType({ fullName: TYPE_NAME })
export class GradientChartViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  @Configurable({ displayName: "StatusHeatmap", editorType: EditorType.ImageSelection })
  @Serializable()
  image: string;

  @NotSerializable()
  title: string;

  @NotSerializable()
  displayFormat: string;

  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.LowSeverityColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#00FF00")
  lowSeverity!: string;

  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.MediumSeverityColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#FFDB00")
  mediumSeverity!: string;

  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.HighSeverityColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#D40000")
  highSeverity!: string;

  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.PrimaryColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#004C97")
  primaryColor!: string;

  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.NominalColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#005C27")
  nominalColor!: string;

  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.MinimumValue,
    editorType: EditorType.TextBox
  })
  @Serializable(0)
  min!: number;

  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.MaximumValue,
    editorType: EditorType.TextBox
  })
  @Serializable(100)
  max!: number;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General, 5)
  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.Unit,
    editorType: EditorType.TextBox
  })
  @Serializable("")
  unit!: string;

  value: number;
  nominalValue: number;

  constructor(viewConfigDto: DeepPartial<GradientChartViewConfig> = {}) {
    super();
    construct(this, viewConfigDto, TYPE_NAME, { size: getDefaultSize() });
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("250", "150");
}
