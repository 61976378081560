<a
  [attr.href]="getLinkUrl()"
  (click)="onClick($event)"
  rel="noopener noreferrer"
  [style.color]="config.textColor"
  class="simple-single-value__wrapper"
  [style.justify-content]="config.alignment"
>
  <div class="simple-single-value__indicator-value">
    {{ displayValue }}
  </div>
  <div class="simple-single-value__indicator-unit">{{ config.unit }}</div>
</a>
