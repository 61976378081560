import { createAction, props } from "@ngrx/store";
import { Equipment } from "../../../core/models/equipment";
import { EquipmentProperty } from "../../../core/models/equipment-property";
import { EntityId } from "../../../meta/models/entity";
import { Maybe } from "../../../ts-utils";

export namespace DataExplorerActions {
  export const changeSignalTabMode = createAction(
    "[Data Explorer] Change Signal Tab Mode",
    props<{ isSignalTabSelected: boolean }>()
  );

  export const setSignalSearchPattern = createAction(
    "[Data Explorer] Set Signal Search Pattern",
    props<{ searchPattern: Maybe<string> }>()
  );

  export const preserveLastUsedSignal = createAction(
    "[Data Explorer] Preserve Last Used Signal",
    props<{ signal: Maybe<EntityId> }>()
  );

  export const selectEquipment = createAction(
    "[Data Explorer] Select Equipment",
    props<{
      equipment: Equipment;
    }>()
  );

  export const unselectEquipment = createAction("[Data Explorer] Unselect Equipment");

  export const expandPropertyBrowser = createAction("[Data Explorer] Expand Property Browser");

  export const collapsePropertyBrowser = createAction("[Data Explorer] Collapse Property Browser");

  export const setEquipmentTreeModel = createAction(
    "[Data Explorer] Set Equipment Tree Model",
    props<{ treeModel: Maybe<Equipment[]> }>()
  );

  export const preserveEquipmentProperty = createAction(
    "[Data Explorer] Preserve Equipment Property",
    props<{ equipmentProperty: Maybe<EquipmentProperty> }>()
  );
}
