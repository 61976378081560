<mat-dialog-content>
  <div mat-dialog-title>Add plotline</div>
  <div class="add-plotline-dialog__plotline-input-wrapper">
    <div class="add-plotline-dialog__input-wrapper">
      <div>Plotline name:</div>
      <input type="text" #nameInput />
    </div>
    <div class="add-plotline-dialog__input-wrapper">
      <div>Plotline value:</div>
      <input type="text" #valueInput />
    </div>
    <div class="add-plotline-dialog__input-wrapper">
      <div>Plotline color:</div>
      <input
        #colorInput
        [value]="plotlineColor"
        [(colorPicker)]="plotlineColor"
        [cpOKButton]="true"
        [cpOKButtonClass]="'c-button c-button--rounded c-button--primary'"
        [cpCancelButton]="true"
        [cpCancelButtonClass]="'c-button c-button--rounded'"
      />
    </div>
    <div class="add-plotline-dialog__input-wrapper">
      <div>Assign to axis:</div>
      <mat-select placeholder="Y Axis" #axisList>
        <mat-option *ngFor="let axis of axisArray" value="{{ axis.id }}">
          <div [innerHTML]="axis.title.text | oneline"></div>
        </mat-option>
      </mat-select>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="add-signal-dialog__button-container">
  <button
    [disabled]="inputInvalid"
    class="c-button c-button--rounded trend__button--rounded c-button--primary"
    (click)="onDialogClosed()"
  >
    Add
  </button>
  <button class="c-button c-button--rounded trend__button--rounded" mat-dialog-close>Cancel</button>
</mat-dialog-actions>
