import { IconId } from "../../../browsing/models/icon-id";
import { construct } from "../../../core/services/construct.helper";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { Configurable } from "../../../meta/decorators/configurable.decorator";
import { EditableType } from "../../../meta/decorators/editable-type.decorator";
import { Serializable } from "../../../meta/decorators/serializable.decorator";
import { EditorType } from "../../../meta/models/editor-type";
import { DeepPartial } from "../../../ts-utils/models/deep-partial.type";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { IndicatorStateDisplay } from "./indicator-state-display";
import { StateDisplayType } from "./state-display-type";

@EditableType({ fullName: StateDisplayType.Icon })
export class IconStateDisplay extends IndicatorStateDisplay {
  typeName: StateDisplayType = StateDisplayType.Icon;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Color,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#ccc")
  color!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.IndicatorIcon,
    editorType: EditorType.IconPicker
  })
  @Serializable(null)
  icon!: Maybe<IconId>;

  constructor(params: DeepPartial<IconStateDisplay> = {}) {
    super();
    construct(this, params, StateDisplayType.Icon);
  }
}
