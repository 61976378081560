import { Cardinality, DataConnectorRoles } from "ui-core";

export const Roles: DataConnectorRoles = {
  Value: {
    name: "Value",
    cardinality: Cardinality.One,
    isDefaultRole: true
  },

  PolePrefix: {
    name: "Heatmap Status",
    isDefaultRole: true
  }
};