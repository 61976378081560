import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import _isEqual from "lodash/isEqual";
import { RDS_LOCALIZATION_DICTIONARY } from "projects/rds/src/assets/i18n/models/rds-localization-dictionary";
import {
  BaseComponent,
  ChartComponent,
  ComponentConstructorParams,
  ComponentStateActions,
  ConnectorRoles,
  DataConnectorDto,
  EditableType,
  LayoutBuilder,
  MaxConnectors,
  View
} from "ui-core";
import { RdsComponentCategory } from "../rds-component-category";
import { PositionViewConfig } from "./position-view-config";
// import "fabric";
import { Roles } from "./roles";
import { StatusHeatmapViewConfig } from "./view-config";

declare const fabric: any;

export interface DataPoint {
  x: number;
  y: number;
  value: number;
  id?: string;
}

export interface DroppedDP {
  layerX: number;
  layerY: number;
}

@Component({
  selector: "rds-status-heatmap",
  templateUrl: "./status-heatmap.component.html",
  styleUrls: ["./status-heatmap.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: StatusHeatmapComponent }]
})
@LayoutBuilder(RdsComponentCategory.RDS, "StatusHeatmapComponent",
  "Plugin", "abb-icon", undefined, RDS_LOCALIZATION_DICTIONARY.layoutEditor.StatusHeatMap)    
@ConnectorRoles(Roles)
@MaxConnectors(100)
@EditableType({ fullName: "StatusHeatmapComponent", title: "status-heatmap" })
export class StatusHeatmapComponent extends ChartComponent implements OnInit {
  isDCAdding = false;
  colorStops: any[] = [];
  positionsFromAPI: PositionViewConfig[];
  deletedDC_Id: any;
  mappedPositions: PositionViewConfig[];
  isUpdateConfirmed = true;
  isRecoverDC: boolean;
  changedDC: { value: number; x: number; y: number };

  dialogRef: any;
  step: number;
  interval: NodeJS.Timeout;
  counter: any;
  dataConnector: any;
  showMovingSection: boolean;
  layerBounds: { layerX: any; layerY: any };

  longStep = 2;
  shortStep = 0.1;

  @View(StatusHeatmapViewConfig)
  public get view() {
    return this.currentState.view as StatusHeatmapViewConfig;
  }

  droppedDC: DroppedDP | null;

  activeDataConnector: DataConnectorDto;

  initialDatapoint: DataPoint = {
    x: 5,
    y: 0,
    value: 0
  };

  initialPositions: PositionViewConfig[] = [
    {
      id: "",
      x: 0,
      y: 0,
      value: 0
    }
  ];
  positions: PositionViewConfig[] = [
    {
      id: "",
      x: 0,
      y: 0,
      value: 0
    }
  ];

  newDCpositions: PositionViewConfig[] = [];

  private chartSeries: any[];

  dataConnectorsEntities = 0;

  constructor(
    params: ComponentConstructorParams,
    hostElementRef: ElementRef<any>,
    cdr: ChangeDetectorRef,
    public dialog: MatDialog
  ) {
    super(params, hostElementRef, cdr);
  }

  ngOnInit() {
    super.ngOnInit();
    this.positionsFromAPI = this.view.positions;
    this.chartSeries = [
      {
        name: "",
        data: []
      }
    ];
  }

  protected updateDisplay() {
    super.updateDisplay();
    if (
      !this.dataConnectors.find((item) => !item.dataPoints) &&
      !this.dataConnectors.find((item) => item.dataPoints.length === 0)
    ) {
      this.updateDCDatapoints();

      if (this.dataConnectorsEntities > this.dataConnectors.length) {
        this.deleteDataConnector();
      }
      // Adding New Data Connector
      else if (this.dataConnectors.length > this.dataConnectorsEntities) {
        this.addDataConnectorsToMap();
      }
      // Initiating dataPoints displaying
      else if (
        (this.positions.length > 0 && !this.checkEqual()) ||
        this.dataConnectors.length === 0
      ) {
        this.mapAndUpdateDataPoints();
      }
    } else if (
      !this.dataConnectors.find((item) => !item.dataPoints) &&
      this.dataConnectors.find((item) => item.dataPoints.length === 0)
    ) {
      const messageToDisplay = "No data points for this period";
      console.error(messageToDisplay);
      // this.dispatcher.dispatch(
      //   ErrorCatchingActions.catchError({ messageToDisplay, error: "Error", autoClose: true })
      // );
    }
    // else if (this.dataConnectors.filter((item) => item.dataPoints).length === 0) {
    //   console.log();
    // }
  }

  deleteDataConnector() {
    this.view.positions = this.view.positions.filter((item) => {
      if (this.dataConnectors.find((element) => element.id === item.id)) {
        return item;
      } else {
        this.deletedDC_Id = item;
      }
    });

    // Map After Deleting
    if (this.view.positions.length > 0) {
      this.mapAndUpdateDataPoints();
    } else {
      this.dataConnectorsEntities = 0;
    }
  }

  updateDCDatapoints(newDC?: any) {
    this.positions = this.view.positions;
    this.mappedPositions = [...this.initialPositions];

    // Check if is DC recovering ( cancel button clicked )
    const positionsCollection = newDC ? this.positionsFromAPI : this.positions;

    this.dataConnectors.map((dc) => {
      const value = dc.dataPoints.slice(-1).pop().y;
      positionsCollection.find((item) => {
        if (item.id === dc.id) {
          this.mappedPositions.push({ ...item, value });
        }
      });
    });
  }

  checkEqual() {
    return _isEqual(this.positions, this.mappedPositions);
  }

  addDataConnectorsToMap(selectedDC?: DataConnectorDto) {
    this.activeDataConnector = this.dataConnectors.slice(-1).pop();
    const value = this.activeDataConnector.dataPoints.slice(-1).pop().y;
    const dataPoint = this.defineDatapoint(value);

    if (this.isDCAdding) {
      this.activeDataConnector = selectedDC ? selectedDC : this.activeDataConnector;
      this.openMovingFrame(this.activeDataConnector);
    }

    this.mapAndUpdateDataPoints();
  }

  openMovingFrame(event: any) {
    this.showMovingSection = true;
    const clickedBounds = {
      layerX: event.x,
      layerY: event.y
    };
    this.layerBounds = this.droppedDC ? this.droppedDC : clickedBounds;
  }

  defineDatapoint(value: number): DataPoint {
    const recoveredDC = this.positionsFromAPI.find(
      (item) => item.id === this.activeDataConnector.id
    );
    let dataPoint: DataPoint = this.initialDatapoint;

    if (recoveredDC) {
      this.isRecoverDC = true;
      dataPoint = {
        x: recoveredDC.x,
        y: recoveredDC.y,
        value
      };
    } else {
      this.isRecoverDC = false;
    }

    return dataPoint;
  }

  mapAndUpdateDataPoints() {
    if (this.dataConnectors.length > 0) {
      this.positions = this.mappedPositions;
    }

    let DC_Collection = this.dataConnectors;

    this.dataConnectorsEntities = this.dataConnectors.length;

    if (this.deletedDC_Id) {
      if (this.dataConnectors.length === 0) {
        const data = {
          x: this.deletedDC_Id.x,
          y: this.deletedDC_Id.y,
          value: this.deletedDC_Id.value
        };
        this.updateDataPoints(this.deletedDC_Id, data);
      } else {
        DC_Collection = this.dataConnectors.filter((item) => this.deletedDC_Id.id !== item.id);
      }
    }
    // else {
    DC_Collection.map((connector) => {
      const currentViewPos = this.mappedPositions.find((item) => item.id === connector.id);
      const value = connector.dataPoints.slice(-1).pop().y;

      let DP = this.initialDatapoint;

      if (currentViewPos) {
        DP = currentViewPos;
      } else {
        const position = this.positions.find((item: any) => item.id === connector.id);
        if (position) {
          DP = {
            x: position.x,
            y: position.y,
            value: position.value || value
          };
        } else {
          const x = this.droppedDC?.layerX ? this.droppedDC.layerX : 5;
          const y = this.droppedDC?.layerY ? this.droppedDC.layerY : 0;
          DP = {
            x,
            y,
            value
          };
        }
      }

      this.updateDataPoints(connector, DP);
    });
  }

  @HostListener("click", ["$event"])
  onPropertyOpen(event: any) {
    console.log("event: ", event);
  }

  @HostListener("drop", ["$event"])
  onDrop(event: any) {
    if (this.isDCAdding) {
      this.finishPointMoving();
    }

    this.droppedDC = {
      layerX: event.layerX / 10,
      layerY: (event.currentTarget.clientHeight - event.layerY) / 10
    };
    this.isDCAdding = true;
    this.isUpdateConfirmed = false;

    super.updateDisplay();
  }

  updateDataPoints(connector: any, dataPoint?: DataPoint) {
    if (this.deletedDC_Id) {
      this.chartSeries[0].data = this.chartSeries[0].data.filter(
        (item: any) => item.id !== this.deletedDC_Id.id
      );
      this.mappedPositions = this.mappedPositions.filter(
        (item) => item.id !== this.deletedDC_Id.id
      );
      this.deletedDC_Id = null;
    } else {
      const { x, y } = dataPoint;
      const id = connector.id;
      const dataConnector: any = {
        ...dataPoint,
        id,
        properties: connector.properties,
        dataPoints: connector.dataPoints,
        DC: connector.properties.name
      };
      const value = dataConnector.value;
      const positionDP = {
        id,
        x,
        y,
        value
      };

      // Add Points to ChartSeries
      this.chartSeries[0].data.push(dataConnector);
      if (!this.mappedPositions.some((item) => item.id === id)) {
        // Merge with new Datapoint position
        this.mappedPositions = [...this.mappedPositions, positionDP];
      } else {
        this.mappedPositions = this.mappedPositions.map((item) => {
          if (item.id === id) {
            item = positionDP;
            return item;
          }
          return item;
        });
      }
      this.deletedDC_Id = null;
    }

    if (this.dataConnectors.length > 0) {
      this.view.positions = this.mappedPositions;

      if (!this.view.unit || this.view.unit === "") {
        const unit = connector.properties.unit;
        this.view.unit = unit ? unit : this.view.unit;
      }
      // if (this.chartSeries[0].data.length <= this.dataConnectors.length) {
      super.updateDisplay();
      // }
    }
  }

  confirmUpdate() {
    this.dispatch(
      ComponentStateActions.updateOne({
        componentUpdate: { id: this.id.toString(), changes: { view: this.view } }
      })
    );
  }

  protected updateChartData(): void {}

  protected setChartOptions(): void {
    const chartWidth = 100;
    const chartHeight = 100;
    this.chartOptions = {
      chart: {
        type: "heatmap",
        inverted: false
      },

      title: {
        text: null
      },
      xAxis: {
        visible: false,
        max: chartWidth,
        min: 0
      },

      yAxis: {
        visible: false,
        max: chartHeight,
        min: 0
      },
      colorAxis: {
        min: this.view.min,
        max: this.view.max,
        stops: this.colorStopsCalc(),
        labels: {
          step: 1,
          enabled: true,
          formatter: (event: any) => `${event.value} ${this.view.unit}`
        }
      },
      legend: {
        align: "center",
        layout: "horizontal",
        verticalAlign: "bottom",
        y: this.view.colorAxisPosition,
        symbolHeight: this.view.colorAxisHeight,
        symbolWidth: this.view.colorAxisWidth
      },
      tooltip: {
        formatter: (event: any) => {
          const point = event.chart.hoverPoint;
          return `<div>
              <p> Status of: <b>${point.properties.name}</b></p>
              <br>
              <p>Is: <b>${point.value.toFixed(2)} ${point.properties.unit}</b></p>
              <br>
              <p>Data Connector Tag: <b> ${point.properties.internalTag}</b></p>
            </div>`;
        },
        style: {
          pointerEvents: "auto"
        },
        headerFormat: "",
        pointFormat: `Status of <b> <pre>{point}</pre></b> is <b>{point.value}</b>`
      },

      plotOptions: {
        cursor: "pointer",
        showInLegend: true,
        series: {
          cursor: "pointer",
          point: {
            events: {
              click: (event: Event) => {
                this.onPointClick(event);
              }
            }
          }
        }
      },
      // series: this.chartSeries

      series: [
        {
          boostThreshold: 1,
          borderWidth: 1,
          nullColor: "#EFEFEF",
          colsize: this.view.dataPointWidth,
          rowsize: this.view.dataPointHeight,
          data: this.chartSeries[0]["data"]
        }
      ]
    };

    this.mergeChartOptions(this.chartOptions);
  }

  onPointClick(event: any) {
    this.isDCAdding = true;
    const movingDC = event.point.options;
    this.addDataConnectorsToMap(movingDC);
  }

  colorStopsCalc() {
    const colorsCollection = [
      this.view.extremeLowColor,
      this.view.veryLowColor,
      this.view.lowColor,
      this.view.highColor,
      this.view.veryHighColor,
      this.view.extremeHighColor
    ];
    const colorsInds = Number(
      (1 / (colorsCollection.filter((color) => color !== "").length - 1)).toFixed(1)
    );
    const mappedColors = colorsCollection
      .filter((color) => color !== "")
      .map((color, index) => {
        const colInd = index === 0 ? 0 : Number((colorsInds * index).toFixed(1));
        return { value: color, index: colInd };
      });

    return mappedColors.map((color) => [...[color.index], color.value]);
  }

  // Point Moving

  finishPointMoving(): void {
    this.showMovingSection = false;
    this.isDCAdding = false;
    this.isUpdateConfirmed = true;
    this.droppedDC = null;
    this.confirmUpdate();
  }

  on_X_PointMove(operation: string, step: number) {
    this.step = step;
    this.interval = setInterval(() => {
      this.counter++;

      if (this.counter % 2) {
        this.step++;
      }

      this.movePoint(this.calcPositionX(operation), this.layerBounds.layerY);
    }, 500);
  }

  on_X_OneClick(operation: string, step: number) {
    this.step = step;
    const pos_X = this.calcPositionX(operation);
    this.counter = 0;
    this.movePoint(pos_X, this.layerBounds.layerY);
  }

  on_Y_PointMove(operation: string, step: number) {
    this.step = step;
    this.interval = setInterval(() => {
      this.counter++;

      if (this.counter % 2) {
        this.step++;
      }

      this.movePoint(this.layerBounds.layerX, this.calcPositionY(operation));
    }, 500);
  }

  on_Y_OneClick(operation: string, step: number) {
    this.step = step;
    const pos_Y = this.calcPositionY(operation);
    this.counter = 0;
    this.movePoint(this.layerBounds.layerX, pos_Y);
  }

  calcPositionX(operation: string) {
    if (operation === "+") {
      return this.layerBounds.layerX + this.step;
    } else {
      return this.layerBounds.layerX - this.step;
    }
  }

  calcPositionY(operation: string) {
    if (operation === "+") {
      return this.layerBounds.layerY + this.step;
    } else {
      return this.layerBounds.layerY - this.step;
    }
  }

  stopInc() {
    clearInterval(this.interval);
    this.counter = 0;
    this.step = 1;
  }

  movePoint(x: number, y: number) {
    this.layerBounds.layerX = x;
    this.layerBounds.layerY = y;
    const value = this.activeDataConnector.dataPoints.slice(-1).pop().y;
    const dataPoint = {
      value,
      x,
      y
    };

    this.changedDC = dataPoint;
    this.updateDataPoints(this.activeDataConnector, dataPoint);
  }
}
