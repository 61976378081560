import moment from "moment";
import { TimeRange } from "../../core/models/time-range";
import { DataPointDto } from "../models/data-point";
import { ComplexValueDataModel, ValueDataModel } from "../models/value-data-model";

function makeDateRange(numPoints: number, from: Date, to: Date): Date[] {
  const start = moment(from);
  const end = to != null ? moment(to) : moment();

  if (numPoints === 1) {
    return [end.toDate()];
  }

  const duration = end.diff(start);
  const step = duration / (numPoints - 1);

  const res: Date[] = [];
  const current = start;
  for (let index = 0; index < numPoints; index++) {
    res.push(current.toDate());
    current.add(step);
  }
  return res;
}

export function convertValueDataModel(
  value: ValueDataModel,
  numPoints: number,
  timeRange: TimeRange
): DataPointDto[] {
  if (value instanceof Array || !(value instanceof Object)) {
    const dates = makeDateRange(numPoints, timeRange.from, timeRange.to);
    return dates.map((date) => {
      return { x: date, y: value } as DataPointDto;
    });
  } else {
    const history = (value as ComplexValueDataModel).history;
    const pointProperties = (value as ComplexValueDataModel).pointProperties;

    if (history != null) {
      const dates = makeDateRange(numPoints, timeRange.from, timeRange.to);

      return dates.map((date, index: number) => {
        const historyIndex = index + (history.length - dates.length);
        const pt: DataPointDto = {
          x: date,
          y: history[historyIndex < 0 ? 0 : historyIndex]
        };
        if (pointProperties != null) {
          pt.properties = pointProperties;
        }
        return pt;
      });
    } else {
      const pt: DataPointDto = {
        x: timeRange.to != null ? timeRange.to : new Date(),
        y: (value as ComplexValueDataModel).data
      };
      if (pointProperties != null) {
        pt.properties = pointProperties;
      }
      return [pt];
    }
  }
}
