import { Injectable } from "@angular/core";
import { Observable, Observer, of } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { EquipmentClassNames, EquipmentProperty, ErrorCatchingActions } from "../../core";
import { SignalDragInfo } from "../../core/models/drag/signal-drag-info";
import { Equipment } from "../../core/models/equipment";
import { Filter } from "../../core/models/filter/filter";
import { QueryFilter } from "../../core/models/filter/query-filter";
import { WebServicesConfiguration } from "../../core/services/api.config";
import { HttpService } from "../../core/services/http.service";
import { QueryStringService } from "../../core/services/query-string.service";
// IP FIXME: should be in core
import { Dispatcher } from "../../dispatcher";
import { setDataStatusFromPoints } from "../../elements/helpers/connectors.helper";
import { ComponentStateDto } from "../../elements/models/component-state";
import { RequestScope } from "../../elements/models/request-scope";
import { LocalizationService } from "../../i18n/localization.service";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import { Dictionary, first, isString, Maybe } from "../../ts-utils";
import { DataPoint, GROUPED_DATA_SOURCE, PeriodType } from "../models";
import { ConnectorsDictionaryIndexedById } from "../models/connectors-dictionary-indexed-by-id";
import { DataConnectorDto } from "../models/data-connector";
import { DataSourceDto } from "../models/data-source/data-source";
import {
  ApiDataSourceDescriptor,
  GenericDataSourceDescriptor
} from "../models/data-source/data-source-descriptors";
import { EquipmentDataSourceDto } from "../models/data-source/equipment-data-source";
import { GenericDataSourceDto } from "../models/data-source/generic-data-source";
import { DataTableResponse, TypedDataTable } from "../models/data-table-response";
import {
  ConnectorPerColumn,
  ConnectorPerColumnValue,
  DataTableToConnectorStrategy
} from "./data-table-to-connector-strategy";
import { DataConnectorFactory } from "./deserializers/data-connector-factory.service";

@Injectable()
export class DataService {
  constructor(
    protected _queryStringService: QueryStringService,
    protected apiConfig: WebServicesConfiguration,
    protected httpService: HttpService,
    protected dispatcher: Dispatcher,
    protected dataConnectorFactory: DataConnectorFactory,
    protected localizationService: LocalizationService
  ) {}

  protected get queryStringService(): QueryStringService {
    return this._queryStringService;
  }

  get(
    filter: QueryFilter,
    requestedConnectors: Dictionary<DataConnectorDto[]>,
    requestScope: RequestScope
  ): Observable<ConnectorsDictionaryIndexedById> {
    throw new Error("Not implemented");
  }

  getFullEquipmentTree(): Observable<Equipment> {
    return of(null);
  }

  getEquipmentTree(path: string): Observable<Equipment> {
    return of(null);
  }

  getAllSignalNames(): Observable<string[]> {
    return of(null);
  }

  // todo: rename (gets list of signals?)
  getFilteredSignalData(search: string, caseSensitive: boolean): Observable<SignalDragInfo[]> {
    return of(null);
  }

  getAllEquipmentClasses(): Observable<EquipmentClassNames[]> {
    return of(null);
  }

  getEquipmentProperties(rootPath?: string, recursive?: boolean): Observable<EquipmentProperty[]> {
    return of(null);
  }

  getPeriodTypes(): Observable<PeriodType[]> {
    return of(null);
  }

  // TODO return observable should be a dict indexed by owner component id
  protected getManyEquipment(
    equipmentQueries: EquipmentDataSourceDto[],
    rootPath: string
  ): Observable<DataConnectorDto[][]> {
    return of([]);
  }

  public resolveComponentEquipmentQueries(
    equipmentQueries: Dictionary<EquipmentDataSourceDto>,
    pageRootPath: string,
    componentStates: ComponentStateDto[]
  ): Observable<Dictionary<DataConnectorDto[]>> {
    return of({});
  }

  // process EquipmentDataSources, look up its signalID (or set its value?)
  public resolveEquipmentConnectors(
    equipmentConnectors: DataConnectorDto[],
    rootPath: string
  ): Observable<DataConnectorDto[]> {
    const equipmentDataSources: EquipmentDataSourceDto[] = equipmentConnectors.map(
      (dc) => dc.dataSource as EquipmentDataSourceDto
    );
    return this.getManyEquipment(equipmentDataSources, rootPath).pipe(
      filter((connectorMatrix: DataConnectorDto[][]) => connectorMatrix.length > 0),
      // take only first connector, and fix id
      map((connectorMatrix: DataConnectorDto[][]) => {
        const singleConnectors: DataConnectorDto[] = [];
        for (let index = 0; index < Object.keys(equipmentConnectors).length; index++) {
          const newConnector = first(connectorMatrix[index]);
          const mergedConnector = this.mergeEquipmentConnectorProperties(
            newConnector,
            equipmentConnectors[index]
          );
          singleConnectors[index] = mergedConnector;
        }
        return singleConnectors;
      })
    );
  }

  protected mergeEquipmentConnectorProperties(
    resolvedConnector: DataConnectorDto,
    old: DataConnectorDto
  ): DataConnectorDto {
    throw new Error("Not implemented");
  }

  public getGenericDataSourceDescriptors(): Observable<GenericDataSourceDescriptor[]> {
    return of([]);
  }

  public getContinuousLookupConnectors(
    queries: Dictionary<DataSourceDto>,
    filters: Dictionary<Filter>,
    requestScope: RequestScope
  ): Observable<Dictionary<DataConnectorDto[]>> {
    return of({});
  }

  public getApiDataSourceDescriptors(): Observable<ApiDataSourceDescriptor[]> {
    return of([]);
  }

  protected mapDataTablesToConnectors(
    response: DataTableResponse,
    queries: Dictionary<GenericDataSourceDto>
  ): Dictionary<DataConnectorDto[]> {
    if (response == null) {
      return {};
    }
    return response.reduce(
      (acc: Dictionary<DataConnectorDto[]>, typedTable: TypedDataTable, index: number) => {
        const query = queries[typedTable.queryId];
        acc[typedTable.queryId] = this.mapSingleDataTableToConnectors(typedTable, query);
        return acc;
      },
      {}
    );
  }

  protected mapSingleDataTableToConnectors(
    typedTable: TypedDataTable,
    query: GenericDataSourceDto
  ): DataConnectorDto[] {
    if (typedTable.invalidQueryMessage != null) {
      console.warn("Invalid Query: " + typedTable.invalidQueryMessage, query);
      this.dispatcher.dispatch(
        ErrorCatchingActions.catchWarning({ messageToDisplay: typedTable.invalidQueryMessage })
      );
      return [];
    }

    let strategy: DataTableToConnectorStrategy;
    if (query.typeName === GROUPED_DATA_SOURCE) {
      strategy = new ConnectorPerColumnValue(this.localizationService);
    } else {
      strategy = new ConnectorPerColumn(this.localizationService);
    }
    const connectors: DataConnectorDto[] = strategy.map(typedTable, query.typeName);
    return connectors.map((connector: DataConnectorDto) => {
      setDataStatusFromPoints(connector);
      return connector;
    });
  }

  protected createDataConnector(
    template: DataConnectorDto,
    dataPoints: DataPoint[],
    properties: any
  ): DataConnectorDto {
    return this.dataConnectorFactory.create({
      id: template.id,
      dataSource: template.dataSource,
      dataPoints,
      properties: properties || template.properties || {}
    });
  }

  public saveImage(image: File): Observable<string> {
    const formData: FormData = new FormData();
    formData.append("file", image, image.name);
    return this.httpService.postWithAutoOptions({
      url: this.apiConfig.imageUploadUrl,
      body: formData
    });
  }

  public getImage(imageUrl: Maybe<string>): Observable<string> {
    if (imageUrl == null || imageUrl === "") {
      return of("");
    }
    return this.httpService.get({ url: imageUrl, options: { responseType: "blob" } }).pipe(
      switchMap((blob) => {
        return new Observable<string>((observer: Observer<string>) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            const base64Result = reader.result;
            if (isString(base64Result)) {
              observer.next(base64Result);
            } else {
              observer.next("");
            }
            observer.complete();
          };
        });
      }),
      catchError((error) => {
        this.dispatcher.dispatch(
          ErrorCatchingActions.catchError({
            messageToDisplay: this.localizationService.get(
              LOCALIZATION_DICTIONARY.snackBarMessages.GettingImageError
            ),
            error: error,
            autoClose: true
          })
        );
        return of("");
      })
    );
  }
}
