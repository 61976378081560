<div *ngIf="selectedLayout" (clickOutside)="closeMenu()">
  <header-button
    [buttonConfig]="dropDownButton"
    [isSelected]="isMenuOpened"
    [icon]="getLayoutTypeIcon(selectedLayout)"
  >
  </header-button>
  <ul *ngIf="isMenuOpened" class="layout-settings__menu" #layoutSettings>
    <li *ngFor="let layoutType of layoutTypeKeys">
      <header-button
        [buttonConfig]="layoutButtonItems[layoutType]"
        [showLabel]="true"
        [icon]="getLayoutTypeIcon(layoutType)"
        [verticallyOpened]="true"
      ></header-button>
    </li>
  </ul>
</div>
