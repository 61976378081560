import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService
} from "@azure/msal-angular";
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  RedirectRequest
} from "@azure/msal-browser";
import { Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { catchError, filter, map, switchMap, take, takeUntil, tap } from "rxjs/operators";
import { AppSettingsActions } from "ui-core";
import { FLEET_VIEW_REPORT_ID } from "./models/constants/predefined-reports";
import { msalScopes } from "./msal";
import { SignalRService } from "./services/api/signalR.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {
  loggedIn = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private signalRSvc: SignalRService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) {}

  ngOnInit(): void {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get("no-msal")?.toLowerCase() === "true") {
      this.loggedIn = true;
      return;
    }

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
        switchMap(() => this.login()),
        takeUntil(this._destroying$)
      )
      .subscribe();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        switchMap(() => this.initalize()),
        takeUntil(this._destroying$)
      )
      .subscribe();

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.Startup),
        tap(() => this.setAccount()),
        takeUntil(this._destroying$)
      )
      .subscribe();

    this.authService
      .handleRedirectObservable()
      .pipe(
        switchMap(() => this.initalize()),
        take(1)
      )
      .subscribe();
  }

  private setAccount(): void {
    const activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  private initalize(): Observable<void> {
    if (!this.authService.instance.getActiveAccount()) {
      return this.authService.loginRedirect();
    } else {
      return this.authService
        .acquireTokenSilent({
          scopes: msalScopes,
          account: this.authService.instance.getActiveAccount() as AccountInfo
        })
        .pipe(
          tap((authResult: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(authResult.account);
            this.dispatchUserName();
            // FIXME: km trend which runs in an iframe expects msal.idtoken
            localStorage["msal.idtoken"] = authResult.accessToken;
            // TODO Stop signalR for the time being
            this.signalRSvc.init(authResult.accessToken);
            this.loggedIn = true;
            if (this.router.url.toLowerCase() === "/") {
              this.router.navigateByUrl(`/report/${FLEET_VIEW_REPORT_ID}`);
            }
          }),
          map(() => void 0),
          catchError((error) => {
            console.log("Acquire token silent error", error);
            return this.login();
          })
        );
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  public login(): Observable<void> {
    if (this.msalGuardConfig.authRequest) {
      return this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest
      } as RedirectRequest);
    } else {
      return this.authService.loginRedirect();
    }
  }

  private dispatchUserName(): void {
    this.store.dispatch(
      AppSettingsActions.updateUserName({
        userName: `${this.authService.instance.getActiveAccount()?.name}`
      })
    );
  }
}
