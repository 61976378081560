import { cloneDeep as _cloneDeep } from "lodash";
import { isEmpty } from "../../ts-utils/helpers/is-empty.helper";
import { isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { Equipment } from "../models/equipment";
import { EquipmentProperty } from "../models/equipment-property";
import { getEquipmentWithPath } from "./equipment-data-source.helper";

export function onRootPathChange(rootPath: string, fullEquipmentTree: Equipment): Equipment {
  const equipment = getEquipmentOfCurrentPath(rootPath, fullEquipmentTree);
  return equipment ?? new Equipment();
}

export function getEquipmentOfCurrentPath(
  currentRootPath: string,
  fullEquipmentTree: Equipment
): Maybe<Equipment> {
  return currentRootPath === ""
    ? fullEquipmentTree
    : getEquipmentWithPath(fullEquipmentTree, currentRootPath);
}

export function filterByRootClass(
  rootNode: Maybe<Equipment>,
  rootClass: string,
  aliasMode: boolean
): Maybe<Equipment> {
  if (isNotDefined(rootNode)) {
    return null;
  }
  if (isEmpty(rootNode.children)) {
    return {
      ...rootNode,
      disabled: !checkIfEquipmentHasClass(rootNode, rootClass, aliasMode)
    };
  }
  let children: Equipment[] = _cloneDeep(rootNode.children);
  const disabled = !checkIfEquipmentHasClass(rootNode, rootClass, aliasMode);
  children = children.map(
    (child: Equipment) => filterByRootClass(child, rootClass, aliasMode) ?? child
  );
  return {
    ...rootNode,
    disabled,
    children
  };
}

export function checkIfEquipmentHasClass(
  equipment: Equipment,
  rootClass: string,
  aliasMode: boolean
): boolean {
  return equipment.properties.some((property: EquipmentProperty) =>
    aliasMode && isDefined(property.aliasClassName)
      ? property.aliasClassName.includes(rootClass)
      : property.className?.includes(rootClass)
  );
}
