<div class="category__wrapper card-item__drag-handle" [ngSwitch]="view.displayStrategy">
  <c-pie
    *ngSwitchCase="strategyEnum.Pie"
    [viewConfig]="simpleView"
    [values]="valueConnectorDescriptors"
    [asDonut]="false"
    [dataStatus]="currentState.componentDataStatus"
    [disableAnimations]="disableChartAnimations"
  >
  </c-pie>
  <c-pie
    *ngSwitchCase="strategyEnum.Donut"
    [viewConfig]="simpleView"
    [values]="valueConnectorDescriptors"
    [asDonut]="true"
    [dataStatus]="currentState.componentDataStatus"
    [disableAnimations]="disableChartAnimations"
  >
  </c-pie>
  <c-category-bar
    *ngSwitchCase="strategyEnum.CategoryHorizontalBar"
    [viewConfig]="simpleView"
    [values]="valueConnectorDescriptors"
    [horizontal]="true"
    [dataStatus]="currentState.componentDataStatus"
  >
  </c-category-bar>
  <c-category-bar
    *ngSwitchCase="strategyEnum.CategoryVerticalBar"
    [viewConfig]="simpleView"
    [values]="valueConnectorDescriptors"
    [horizontal]="false"
    [dataStatus]="currentState.componentDataStatus"
  >
  </c-category-bar>

  <div *ngSwitchDefault>
    <div>strategy {{ view.displayStrategy }}</div>
  </div>
</div>
