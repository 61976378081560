<div class="c-status-indicator__wrapper">
  <div
    class="c-status-indicator__title"
    [style.color]="viewConfig.foregroundColor"
    [ngClass]="{
      'c-status-indicator__title--wrap': isTitleFormatWrap()
    }"
  >
    {{ viewConfig.title }}
  </div>
  <c-simple-status-indicator
    [ngStyle]="additionalStyle"
    [status]="status"
    [iconSize]="iconSize"
  ></c-simple-status-indicator>
</div>
