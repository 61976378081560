import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MsalInterceptor } from "@azure/msal-angular";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class AzureAuthInterceptor implements HttpInterceptor {
  constructor(
    private msalInterceptor: MsalInterceptor
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let idx = 1;
    let msalToken = '';
    let tmpMsalToken = '';

    //FIX ME: remove once both android and ios have been release
    msalToken = this.getCookie(`msal-token`);

    do {
      tmpMsalToken = this.getCookie(`msal-token-${idx}`);

      if (tmpMsalToken != '') {
        msalToken = msalToken.concat(tmpMsalToken);  
      }      

      idx = idx + 1;
    } while (tmpMsalToken != '')

    const headers = {} as any;

    if (msalToken != '') {
      headers["Authorization"] = msalToken;
    }

    const newRequest = request.clone({
      setHeaders: headers
    });

    let obs$ = this.msalInterceptor.intercept(newRequest, next);

    if (msalToken != '') {
      obs$ = next.handle(newRequest);
    }

    return obs$.pipe(
      catchError((error) => {
        // FIXME: We need to handle this errors nicely
        return throwError(error);
      })
    );
  }

  private getCookie(name: string): string {
    const ca: Array<string> = document.cookie.split(";");
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, "");
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }

    return '';
  }
}
