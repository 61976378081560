import { DEFAULT_TITLE } from "../../elements/models/default-property-value.constants";
import { DataConnectorViewSelector } from "../../elements/services/entity-selectors/data-connector-view.selector";
import { isDefined } from "../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { DataConnectorDto } from "../models/data-connector";
import { DataConnectorViewDto } from "../models/data-connector-view";
import { getConnectorViewId } from "./connector-view-id.helper";

const MAX_ORDER_VALUE = 10000;

export function getConnectorDtoViewId(connector: DataConnectorDto): string {
  return getConnectorViewId(connector.id);
}

export function getConnectorGroupId(
  connectorId: string,
  connectorViewSelector: DataConnectorViewSelector
): string {
  const connectorViewId: string = getConnectorViewId(connectorId);
  return connectorViewSelector.getById(connectorViewId)?.groupId;
}

export function sortConnectorViews(connectorViews: DataConnectorViewDto[]): void {
  connectorViews.sort((a, b) => {
    const firstOrderValue = a.order ?? MAX_ORDER_VALUE;
    const secondOrderValue = b.order ?? MAX_ORDER_VALUE;
    return firstOrderValue - secondOrderValue;
  });
}

export function shouldUpdateConnectorViewTitle(
  connectorView: Maybe<DataConnectorViewDto>
): boolean {
  return (
    isDefined(connectorView) &&
    isDefined(connectorView.title) &&
    connectorView.title !== DEFAULT_TITLE
  );
}
