import { Injectable } from "@angular/core";
import { AggregationFunction } from "../../data-connectivity/models/data-aggregation-function";
import {
  getComponentStateAggregationConfig,
  getDataSourceAggregationConfig
} from "../helpers/v0.helper";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class RenameAggregationFunctions implements UpgradeStep {
  name = "RenameAggregationFunctions";
  fromVersion = new Version(0, 0, 4);

  perform(oldConfig: any): UpgradeStepResult {
    const connectorsAggregationConfigs = getDataSourceAggregationConfig(oldConfig);
    const componentStateAggregationConfig = getComponentStateAggregationConfig(oldConfig);
    const aggregationConfigs = [
      ...connectorsAggregationConfigs,
      ...componentStateAggregationConfig
    ];
    let modified = false;
    aggregationConfigs.forEach((aggregationConfig) => {
      const newName = this.renameAggregation(aggregationConfig["timeAggregationFunction"]);
      if (newName != null) {
        modified = true;
        aggregationConfig["timeAggregationFunction"] = newName;
      }
    });

    return {
      result: oldConfig,
      modified,
      warning: null
    };
  }

  private renameAggregation(oldName: string): string {
    // 31721	edit	Ivan Petkovic	18 Dec 2019 14:01:29	$/Knowledge Manager/Main/WebGUI/projects/ui-core/src/lib/data-connectivity/models/data-aggregation-function.ts	changed aggregation functions enum mapping
    switch (oldName) {
      case "NONE":
        // warning
        return AggregationFunction.Default;
      case "AVG":
        return AggregationFunction.Average;
      case "MAX":
        return AggregationFunction.Maximum;
      case "MIN":
        return AggregationFunction.Minimum;
      case "SUM":
        return AggregationFunction.Sum;
      case "FIRST":
        return AggregationFunction.First;
      case "LATEST":
        return AggregationFunction.Latest;
    }
  }
}
