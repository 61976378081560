import { construct } from "../../../core";
import { EditableType } from "../../../meta";
import { DeepPartial } from "../../../ts-utils";
import { BaseViewConfigDto, ComponentCssSize } from "../../models";

const TYPE_NAME = "HistoryViewPlaceholderViewConfig";

const DEFAULT_WIDTH = "450";
const DEFAULT_HEIGHT = "225";
// @dynamic
@EditableType({ fullName: TYPE_NAME })
export class HistoryViewPlaceholderViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  constructor(viewConfigDto: DeepPartial<HistoryViewPlaceholderViewConfig> = {}) {
    super();
    construct(this, viewConfigDto, TYPE_NAME, { size: getDefaultSize() });
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize(DEFAULT_WIDTH, DEFAULT_HEIGHT);
}
