import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class ChartRedrawInitiatorService {
  redrawCharts$: Subject<any> = new Subject();

  triggerChartsRedraw() {
    this.redrawCharts$.next();
  }
}
