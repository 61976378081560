import { createAction, props } from "@ngrx/store";
import { Maybe } from "../../../ts-utils";
import { GenericDataSourceDescriptor } from "../../models/data-source/data-source-descriptors";

export namespace GenericDataSourceDescriptorActions {
  export const load = createAction("[GDS Descriptors] Fetch");

  export const loadSuccess = createAction(
    "[GDS Descriptors] Fetch Success",
    props<{ descriptors: Maybe<GenericDataSourceDescriptor[]> }>()
  );
}
