import { Update } from "@ngrx/entity";
import { createAction, props } from "@ngrx/store";
import { FilterConfigurationDto } from "../../../core/models/filter/filter-configuration";
import { CustomFilterValue } from "../../../core/models/filter/filter-type-descriptor";
import { EntityId } from "../../../meta/models";
import { ParentEntityInfo } from "../../../meta/models/parent-entity-info";
import { DeepUpdate } from "../../../ts-utils/models/deep-update.type";
import { Dictionary } from "../../../ts-utils/models/dictionary.type";
import { Maybe } from "../../../ts-utils/models/maybe.type";

export namespace FilterActions {
  export const addOne = createAction(
    "[Filter] Add One",
    props<{ filter: FilterConfigurationDto }>()
  );

  export const addMany = createAction(
    "[Filter] Add Many",
    props<{ filters: FilterConfigurationDto[] }>()
  );

  export const deleteOne = createAction(
    "[Filter] Delete One",
    props<{ filterId: EntityId; parentEntityInfo?: Maybe<ParentEntityInfo> }>()
  );

  export const deleteMany = createAction(
    "[Filter] Delete Many",
    props<{ filterIds: EntityId[] }>()
  );

  export const updateMany = createAction(
    "[Filter] Update Many",
    props<{ filterUpdates: Update<FilterConfigurationDto>[] }>()
  );

  export const upsertOne = createAction(
    "[Filter] Upsert One",
    props<{
      filterUpdate: DeepUpdate<FilterConfigurationDto>;
      parentEntityInfo?: Maybe<ParentEntityInfo>;
    }>()
  );

  export const queryFullDataForFilters = createAction(
    "[Filter] Query Full Data For Filters",
    props<{
      filterIds: EntityId[];
    }>()
  );

  export const setSourceFilter = createAction(
    "[Filter] Set Source Filter",
    props<{
      filterId: EntityId;
      sourceFilterId: EntityId;
    }>()
  );

  export const reactToSelectTimeRange = createAction(
    "[CrossWidgetEvent] React to SelectTimeRange",
    props<{
      componentId: EntityId;
      filterUpdate: DeepUpdate<FilterConfigurationDto>;
    }>()
  );

  export const modifiedComponentFilter = createAction(
    "[PropertySheet] Modified Component Filter",
    props<{
      componentId: EntityId;
      filterUpdate: DeepUpdate<FilterConfigurationDto>;
    }>()
  );

  export const updateGlobalFilter = createAction(
    "[Filter] Update Global Filter",
    props<{
      changes: Partial<FilterConfigurationDto>;
    }>()
  );

  export const resolveUpdatedCustomFilters = createAction(
    "[Filter] Update Global Custom Filters",
    props<{
      customFilters: Dictionary<CustomFilterValue>;
    }>()
  );

  export const updateManyWithTimeOffset = createAction(
    "[Filter] Update Many With Time Offset",
    props<{ offset: number }>()
  );
}
