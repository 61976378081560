import { Actions, ofType, createEffect } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { switchMap, map, tap } from "rxjs/operators";

import { MatDialog } from "@angular/material/dialog";
import {
  AddPlotlineDialogComponent,
  RemovePlotlineDialogComponent
} from "../../../components/trend/dialogs";
import { PlotlineData } from "../../../models/dialogs/plotline-data.model";
import { RemovePlotlineData } from "../../../models/dialogs/remove-plotline-data.model";
import { OpenDialogEffect } from "../../../../shared/dialogs/effects/dialog.effects";
import {
  AddPlotlineConfigStrategy,
  RemovePlotlineConfigStrategy
} from "../dialog-config.strategies";
import { PlotlineDialogActions, AxisDialogActions } from "../actions";

@Injectable()
export class PlotlineDialogEffects extends OpenDialogEffect {
  openAddPlotlineDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlotlineDialogActions.addPlotlineDialog),
      tap(({ chartYAxes }) => {
        this.dialogConfigStrategy = new AddPlotlineConfigStrategy();
        this.dialogConfig = this.dialogConfigStrategy.createDialogConfig(chartYAxes);
        this.dialogRef = this.dialog.open(AddPlotlineDialogComponent, this.dialogConfig);
      }),
      switchMap(() => {
        return this.dialogRef.afterClosed().pipe(
          map((plotlineData: PlotlineData) => {
            if (!plotlineData) {
              return AxisDialogActions.closeDialog({ dialogRef: this.dialogRef });
            }
            return PlotlineDialogActions.addPlotlineDialogSuccess({ result: plotlineData });
          })
        );
      })
    )
  );

  openRemovePlotlineDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlotlineDialogActions.removePlotlineDialog),
      tap(({ chartYAxes }) => {
        this.dialogConfigStrategy = new RemovePlotlineConfigStrategy();
        this.dialogConfig = this.dialogConfigStrategy.createDialogConfig(chartYAxes);
        this.dialogRef = this.dialog.open(RemovePlotlineDialogComponent, this.dialogConfig);
      }),
      switchMap(() => {
        return this.dialogRef.afterClosed().pipe(
          map((axisPlotlinesRemoved: RemovePlotlineData) => {
            if (!axisPlotlinesRemoved) {
              return AxisDialogActions.closeDialog({ dialogRef: this.dialogRef });
            }
            return PlotlineDialogActions.removePlotlineDialogSuccess({
              plotlinesToRemove: axisPlotlinesRemoved
            });
          })
        );
      })
    )
  );

  constructor(private actions$: Actions, dialog: MatDialog) {
    super(dialog);
  }
}
