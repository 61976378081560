import { isDefined } from "../../ts-utils/helpers/predicates.helper";

export function getPropertyByPath(target: any, path: string[]): any {
  return path.reduce((acc, pathFragment: string) => acc[pathFragment], target);
}

export function tryGetPropertyByPath(target: any, path: string[]): any {
  return path.reduce(
    (acc, pathFragment: string) => (isDefined(acc) ? acc[pathFragment] : null),
    target
  );
}
