export const WHITE_COLOR_HEX = "#ffffff";
export const BLACK_COLOR_HEX = "#000000";
export const DEFAULT_CHART_TEXT_COLOR_HEX = "#828282";
export const DEFAULT_PRIMARY_COLOR_HEX = "#004C97";
export const DEFAULT_BULLET_PLOT_BAND_COLOR = "#e6e9ff";
export const RED_COLOR_HEX = "#ff000f";
export const BLACK_COLOR_WITH_OPACITY = "RGB(0, 0, 0, 0.3)";
export const WHITE_COLOR_WITH_OPACITY = "RGB(255, 255, 255, 0.5)";
export const RED_WEIGHT = 0.299;
export const GREEN_WEIGHT = 0.587;
export const BLUE_WEIGHT = 0.114;
export const WHITE_COLOR_THRESHOLD = 150;
export const RED_INDEX = 0;
export const GREEN_INDEX = 1;
export const BLUE_INDEX = 2;
export const OPACITY_INDEX = 3;
export const EXTRACTION_START_INDEX = 5;
export const OPACITY_FACTOR = 255;
export const BASE16 = 16;
export const FULL_OPACITY = 1;
