import { construct } from "../../core";
import { LineTypeStrategies } from "../../data-connectivity/models/line-type-strategies";
import { DisplayStrategies } from "../../data-connectivity/models/series-marker-display-strategies";
import {
  SeriesType,
  SeriesTypeForTimeSeriesArray,
  SERIES_TYPE_DEFAULT
} from "../../data-connectivity/models/series-type.strategies";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import { ConfigurableEnum } from "../../meta/decorators/configurable-enum.decorator";
import { Configurable } from "../../meta/decorators/configurable.decorator";
import { ConfigurationCategory } from "../../meta/decorators/configuration-category.decorator";
import { EditableType } from "../../meta/decorators/editable-type.decorator";
import { Serializable } from "../../meta/decorators/serializable.decorator";
import { EditorType } from "../../meta/models/editor-type";
import { PropertyCategory } from "../../meta/models/property-category";
import { ISeriesBaseConfig } from "./series-base-config";

const TYPE_NAME = "TimeSeriesConnectorView";

@EditableType({ fullName: TYPE_NAME })
export class TimeSeriesConnectorView implements ISeriesBaseConfig {
  typeName = TYPE_NAME;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.SeriesConfiguration
  )
  @ConfigurableEnum({
    enumSource: SeriesTypeForTimeSeriesArray,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.SeriesType
  })
  @Serializable(SERIES_TYPE_DEFAULT)
  seriesType!: SeriesType;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.SeriesConfiguration
  )
  @ConfigurableEnum({
    enumSource: LineTypeStrategies,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.LineType
  })
  @Serializable(LineTypeStrategies.Solid)
  lineStyle!: string;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.SeriesConfiguration
  )
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.LineWidth,
    editorType: EditorType.Number
  })
  @Serializable(2)
  lineWidth!: number;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.SeriesConfiguration
  )
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ShowMarker,
    editorType: EditorType.CheckBox
  })
  @Serializable(false)
  showMarker!: boolean;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.SeriesConfiguration
  )
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.MarkerRadius,
    editorType: EditorType.Number
  })
  @Serializable(5)
  markerRadius!: number;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.SeriesConfiguration
  )
  @ConfigurableEnum({
    enumSource: DisplayStrategies,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Marker_Style
  })
  @Serializable(DisplayStrategies.Circle)
  markerStyle!: string;

  constructor(timeSeriesConnectorView: Partial<TimeSeriesConnectorView> = {}) {
    construct(this, timeSeriesConnectorView, TYPE_NAME);
  }
}
