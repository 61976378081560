import { Cardinality } from "../../../core/models/cardinality";
import { DataConnectorRoles } from "../../../data-connectivity/models/data-connector-role";

export const SUM_ROLE: string = "Sum";

export const Roles: DataConnectorRoles = {
  Value: {
    name: "Value",
    cardinality: Cardinality.One,
    isDefaultRole: true
  },
  Sum: {
    name: SUM_ROLE,
    cardinality: Cardinality.One,
    isDefaultRole: false
  }
};
