import { Equipment } from "../../core/models/equipment";
import {
  EquipmentPath,
  EquipmentPathStep,
  PathAxis,
  PathPredicate,
  PredicateType
} from "../../core/models/equipment-path";
import { EquipmentProperty } from "../../core/models/equipment-property";
import { isEmptyOrNotDefined } from "../../ts-utils";
import { isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { CriticalError } from "../../ts-utils/models/critical-error";
import { Maybe } from "../../ts-utils/models/maybe.type";

export interface SelectableEquipment {
  equipment: Equipment;

  name: string;
  children: SelectableEquipment[];
  parent: Maybe<SelectableEquipment>;
  classes: string[];
}

export function resolveEquipmentPathToEquipment(
  equipmentPath: EquipmentPath,
  baseEquipment: Maybe<Equipment>,
  currentRootPath: string
): Maybe<Equipment> {
  const fullSelectableEquipmentTree = getSelectableEquipmentTree(baseEquipment);
  const selectableEquipmentFromRootPath = findSelectableEquipmentWithPath(
    fullSelectableEquipmentTree,
    currentRootPath
  );
  if (isNotDefined(selectableEquipmentFromRootPath)) {
    return null;
  }
  const resolvedEquipments = ePathSelect(selectableEquipmentFromRootPath, equipmentPath);
  const index = isDefined(equipmentPath.overallIndex) ? equipmentPath.overallIndex - 1 : 0;
  return resolvedEquipments[index]?.equipment;
}

export function getSelectableEquipmentTree(
  rootEquipment: Maybe<Equipment>
): Maybe<SelectableEquipment> {
  if (isNotDefined(rootEquipment)) {
    return null;
  }
  const selectableEquipmentTree = toSelectableEquipment(null, rootEquipment);
  return selectableEquipmentTree;
}

function findSelectableEquipmentWithPath(
  rootEquipment: Maybe<SelectableEquipment>,
  path: string
): Maybe<SelectableEquipment> {
  if (isNotDefined(rootEquipment) || isNotDefined(path)) {
    return null;
  }
  if (rootEquipment.equipment.path === path) {
    return rootEquipment;
  }
  let parentEquipment: Maybe<SelectableEquipment> = null;
  for (let equipmentChild of rootEquipment.children) {
    parentEquipment = findSelectableEquipmentWithPath(equipmentChild, path);
    if (isDefined(parentEquipment)) {
      return parentEquipment;
    }
  }
  return null;
}

export function toSelectableEquipment(
  parent: Maybe<SelectableEquipment>,
  equipment: Equipment
): SelectableEquipment {
  if (isNotDefined(equipment.name)) {
    throw new CriticalError("no name");
  }
  const selectable: SelectableEquipment = {
    equipment: equipment,
    name: equipment.name,
    children: [],
    parent: parent,
    classes: getClassesOfEquipment(equipment)
  };
  selectable.children = equipment.children.map((child) => toSelectableEquipment(selectable, child));
  return selectable;
}

export function getClassesOfEquipment(equipment: Equipment): string[] {
  return equipment.properties.reduce((acc: string[], prop: EquipmentProperty) => {
    if (!isEmptyOrNotDefined(prop.className) && !acc.includes(prop.className!)) {
      acc.push(prop.className!);
    }
    return acc;
  }, []);
}

export function ePathSelect(
  current: SelectableEquipment,
  path: EquipmentPath,
  matchedNodeCallback?: (
    matchedNode: SelectableEquipment,
    lastStepToThisNode: EquipmentPathStep,
    remainder: EquipmentPathStep[]
  ) => void
): SelectableEquipment[] {
  return ePathSelectSteps(current, path.steps, matchedNodeCallback);
}

export function ePathSelectSteps(
  current: SelectableEquipment,
  steps: EquipmentPathStep[],
  matchedNodeCallback?: (
    matchedNode: SelectableEquipment,
    lastStepToThisNode: EquipmentPathStep,
    remainder: EquipmentPathStep[]
  ) => void
): SelectableEquipment[] {
  if (steps.length === 0) {
    throw new CriticalError("no steps");
  }
  const step = steps[0];
  const remainder = steps.slice(1);

  switch (step.axis) {
    case PathAxis.Parent:
      if (isDefined(current.parent)) {
        if (isDefined(matchedNodeCallback)) {
          matchedNodeCallback(current.parent, step, remainder);
        }
        if (remainder.length > 0) {
          return ePathSelectSteps(current.parent, remainder, matchedNodeCallback);
        } else {
          return [current.parent];
        }
      }
      return [];
    case PathAxis.Child:
      if (matchesAll(current, step.predicates)) {
        if (isDefined(matchedNodeCallback)) {
          matchedNodeCallback(current, step, remainder);
        }
        if (remainder.length > 0) {
          return current.children
            .map((child) => ePathSelectSteps(child, remainder, matchedNodeCallback))
            .flat();
        } else {
          return [current];
        }
      }
      return [];

    default:
      throw new CriticalError(`Path Axis ${step.axis} not supported.`);
  }
}

function matchesAll(equipment: SelectableEquipment, predicates: PathPredicate[]): boolean {
  return predicates.every((predicate) => matches(equipment, predicate));
}

function matches(equipment: SelectableEquipment, predicate: PathPredicate): boolean {
  switch (predicate.type) {
    case PredicateType.NodeName:
      return equipment.name === predicate.expected;
    case PredicateType.EquipmentClass:
      return equipment.classes.includes(predicate.expected as string);
  }
  return false;
}
