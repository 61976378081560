import { Inject, Injectable } from "@angular/core";
import { Action, Store } from "@ngrx/store";
import { DispatchOptions } from "./dispatch-options";
import { UndoRedo } from "./shared/models/undo-redo";
import { IUndoRedoService } from "./shared/services/i-undo-redo.service";

@Injectable()
export class Dispatcher {
  constructor(
    private store$: Store<any>,
    @Inject(UndoRedo) private undoRedoService: IUndoRedoService
  ) {}

  dispatch(action: Action, options?: DispatchOptions): void {
    if (options?.withSnapshot) {
      this.undoRedoService.createSnapshot({
        updatedEntitiesInfo: options.updatedEntitiesInfo
      });
    }
    this.store$.dispatch(action);
  }
}
