import { Injectable } from "@angular/core";
import { ComponentStateDto } from "../../elements";
import { isDefined } from "../../ts-utils/helpers/predicates.helper";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class RenameClusterProperty implements UpgradeStep {
  name = "RenameClusterProperty";
  fromVersion = new Version(4, 0, 4);

  perform(oldConfig: any): UpgradeStepResult {
    const widgets = getClusterCharts(oldConfig);
    let changed = false;
    widgets.forEach((widget) => {
      const view = widget["view"];
      if (isDefined(view)) {
        const displayStrategy = view["displayStrategies"];
        if (isDefined(displayStrategy)) {
          delete view["displayStrategies"];
          view["displayStrategy"] = displayStrategy;
          changed = true;
        }
      }
    });
    return {
      result: oldConfig,
      modified: changed,
      warning: null
    };
  }
}

function getClusterCharts(oldConfig: any): any[] {
  const componentStates: any = oldConfig["componentStates"]["entities"];
  const widgets: any[] = Object.values(componentStates).filter(
    (componentState) => (componentState as ComponentStateDto).type === "ClusterChartComponent"
  );
  return widgets;
}
