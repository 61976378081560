<div class="report-browser-dialog__header">
  <div mat-dialog-title>
    {{ localizer.propertySheet.CreateLink | translate }}
  </div>
  <div class="report-browser-dialog__header-buttons">
    <i
      title="Reload reports"
      class="abb-icon Refresh report-browser-dialog__reload-icon"
      (click)="refreshReports$.next(true)"
    >
    </i>
    <i
      class="abb-icon Information_circle_1 report-browser-dialog__reload-icon"
      [ngClass]="{ 'browser--visible-description': shouldShowReportDescription }"
      title="{{
        shouldShowReportDescription ? 'Hide reports description' : 'Show reports description'
      }}"
      (click)="showOrHideReportDescription()"
    >
    </i>
  </div>
</div>

<div class="report-browser-dialog__content-container">
  <div class="report-browser-dialog__report-title">
    <i class="report-browser-dialog__report-icon abb-icon abb-icon--medium Reports"></i>
    <span class="report-browser-dialog__report-title--text">{{
      localizer.propertySheet.SelectAReport | translate
    }}</span>
  </div>

  <div class="report-browser-dialog__reports">
    <report-browser
      [refreshReports$]="refreshReports$"
      (clickedReport)="createReportLink($event)"
      [shouldShowReportDescription]="shouldShowReportDescription"
    ></report-browser>
  </div>
</div>

<div mat-dialog-actions>
  <button class="c-button c-button--rounded c-button--primary" (click)="confirm()">
    {{ localizer.dialogs.Confirm | translate }}
  </button>
  <button class="c-button c-button--rounded" (click)="close()">
    {{ localizer.dialogs.Cancel | translate }}
  </button>
</div>
