import { AfterViewInit, Component, ElementRef, Input, OnDestroy } from "@angular/core";
import { v4 as uuid } from "uuid";
import { EntityId } from "../../../meta";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { View } from "../../decorators/view.decorator";
import { getLocalId } from "../../helpers/history-view.helper";
import { ComponentStateDto } from "../../models";
import { ActiveContainersService } from "../../services/active-containers.service";
import { ComponentPositioningService } from "../../services/component-positioning.service";
import { ComponentStateActions } from "../../store/component-state/component-state.actions";
import { ComponentConstructorParams } from "../base/component-constructor-params";
import { ContainerComponent } from "../container/container.component";
import { HistoryViewPlaceholderViewConfig } from "./view-config";

const TYPE_NAME = "HistoryViewPlaceholderComponent";
@Component({
  selector: "c-hv-placeholder",
  templateUrl: "./history-view-placeholder.component.html",
  styleUrls: ["./history-view-placeholder.component.scss"],
  providers: [{ provide: ContainerComponent, useExisting: HistoryViewPlaceholderComponent }]
})
@EditableWidget({ fullName: TYPE_NAME, virtual: true })
export class HistoryViewPlaceholderComponent
  extends ContainerComponent
  implements AfterViewInit, OnDestroy
{
  @Input() displayedComponentId: EntityId | null = null;
  constructor(
    params: ComponentConstructorParams,
    hostElementRef: ElementRef,
    activeContainersService: ActiveContainersService,
    componentPositioningService: ComponentPositioningService
  ) {
    super(params, hostElementRef, activeContainersService, componentPositioningService);
    this.id = getLocalId(uuid());
    this.addPlaceholderToStore();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.addDisplayedComponentAsChild();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.dispatch(ComponentStateActions.deleteOne({ targetComponent: this.currentState }));
  }

  private addDisplayedComponentAsChild(): void {
    if (isDefined(this.displayedComponentId)) {
      this.dispatch(
        ComponentStateActions.updateChildren({
          childrenIdsToAdd: [this.displayedComponentId],
          parentId: this.id
        })
      );
    }
  }

  private addPlaceholderToStore(): void {
    this.dispatch(
      ComponentStateActions.addOne({
        newComponent: this.createPlaceholderComponent(),
        parentId: null
      })
    );
  }

  private createPlaceholderComponent(): ComponentStateDto {
    return new ComponentStateDto({
      id: this.id,
      type: TYPE_NAME
    });
  }

  @View(HistoryViewPlaceholderViewConfig)
  public get view(): HistoryViewPlaceholderViewConfig {
    return this.currentState.view as HistoryViewPlaceholderViewConfig;
  }
}
