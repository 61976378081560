export enum EditorType {
  Hidden,
  TextBox,
  Number,
  CheckBox,
  ComboBox,
  ColorPicker,
  NestedObjectEditor,
  SignalBrowser,
  EquipmentBrowser,
  EquipmentPathBrowser,
  NestedSheet, // composite object which will be opened in a separate property sheet
  Array,
  EntityArray,
  LinkEditor,
  ImageSelection,
  IconPicker,
  LimitsEditor,
  TextArea,
  TimeRangeEditor,
  EditableSelectList,
  BorderSideSelector,
  SliderEditor,
  LinkWidget
}
