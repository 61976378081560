import { Component } from "@angular/core";
import { Dictionary } from "lodash";
import { RDS_LOCALIZATION_DICTIONARY } from "projects/rds/src/assets/i18n/models/rds-localization-dictionary";
import {
  BaseComponent,
  ChartComponent,
  ConnectorRoles,
  EditableType,
  LayoutBuilder,
  MaxConnectors,
  View
} from "ui-core";
import { RdsComponentCategory } from "../../rds-component-category";
import { Properties, Roles } from "./roles";
import { AirgapProfileChartViewConfig } from "./view-config";

const CALCULATION_PRECISION = 1; // unit is degree
const pointsForDrawing = 360 / CALCULATION_PRECISION;
const DEGREES_PER_RAD = 180 / Math.PI;
const RADS_PER_DEGREE = 1 / DEGREES_PER_RAD;
const highchartsQuadrantAngles: number[] = [+90, +270, +270, +90];

@Component({
  selector: "rds-airgap-profile-chart",
  templateUrl: "./airgap-profile-chart.component.html",
  styleUrls: ["./airgap-profile-chart.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: AirgapProfileChartComponent }]
})
@LayoutBuilder(
  RdsComponentCategory.RDS,
  "AirgapProfileChartComponent",
  "Plugin",
  "abb-icon",
  undefined,
  RDS_LOCALIZATION_DICTIONARY.layoutEditor.AirgapProfileChart
)
@ConnectorRoles(Roles)
@MaxConnectors(100)
@EditableType({ fullName: "AirgapProfileChartComponent", title: "airgap-profile-chart" })
export class AirgapProfileChartComponent extends ChartComponent {
  updateChartFlag = false;
  updateOneToOneFlag = false;

  minorAxis: number;
  majorAxis: number;
  rotorCenter: { x: number; y: number };
  locationAngleArray: string[] = [];
  signalNameArray: string[] = [];
  plcTag: string[] = [];
  sensors: number[] = [];

  @View(AirgapProfileChartViewConfig)
  public get view(): AirgapProfileChartViewConfig {
    return this.currentState.view as AirgapProfileChartViewConfig;
  }

  // TODO: Investigate if this hook can be safely removed
  protected updateDisplay() {
    super.updateDisplay();
  }

  // FIXME 901 when real data about sensors is ready refactor this function
  private getSensorPosition(sensorIndex: number) {
    const sensorIsOnMajorAxis: boolean =
      sensorIndex === 0 ||
      sensorIndex === 1 ||
      sensorIndex === 2 ||
      sensorIndex === 6 ||
      sensorIndex === 7 ||
      sensorIndex === 8;
    if (sensorIsOnMajorAxis) {
      return this.majorAxis;
    }
    return this.minorAxis;
  }
  private populateAirgapValues() {
    const airgapProfileValues: number[] = this.dataAccessor.getValueForRole(
      Roles.AirgapProfileEllipse.name
    );

    if (!airgapProfileValues) {
      return;
    }

    const airgapProfile = {
      majorAxis: airgapProfileValues[0], //Math.max(...airgapProfileValues),
      minorAxis: airgapProfileValues[1] //Math.min(...airgapProfileValues)
    };

    this.majorAxis = airgapProfile.majorAxis;
    this.minorAxis = airgapProfile.minorAxis;
  }

  private populateRotorCenterValues() {
    const rotorCenterValues: number[] = this.dataAccessor.getValueForRole(Roles.RotorCenter.name);

    if (!rotorCenterValues) {
      return;
    }

    let rotorCenter = {
      x: rotorCenterValues[0],
      y: rotorCenterValues[1]
    };

    const angle = Math.atan(rotorCenter.y / rotorCenter.x) * DEGREES_PER_RAD;
    const rotorCenterAngle = this.getHighchartsQuadrantAngleSummand(rotorCenter) - angle;
    const rotorCenterLength = Math.sqrt(
      rotorCenter.x * rotorCenter.x + rotorCenter.y * rotorCenter.y
    );

    rotorCenter = {
      x: rotorCenterAngle,
      y: rotorCenterLength
    };
    this.rotorCenter = rotorCenter;
  }

  private getHighchartsQuadrantAngleSummand(millRotorCenter: { x: number; y: number }) {
    const xValueIsPositive: boolean = millRotorCenter.x > 0;
    const yValueIsPositive: boolean = millRotorCenter.y > 0;

    const isInQuadrant: Dictionary<boolean> = {
      1: xValueIsPositive && yValueIsPositive,
      2: !xValueIsPositive && yValueIsPositive,
      3: !xValueIsPositive && !yValueIsPositive,
      4: xValueIsPositive && !yValueIsPositive
    };
    return highchartsQuadrantAngles.reduce((prev: number, current: number, index: number) => {
      if (isInQuadrant[index + 1]) {
        return current;
      }
      return prev;
    }, null);
  }

  protected updateChartData(): void {
    // FIXME 902 to be changed to real sensor degree.

    this.locationAngleArray = [];
    this.signalNameArray = [];
    this.locationAngleArray = this.dataAccessor
      .getConnectorsByRole(Roles.Sensors.name)
      .map((dc) => dc.properties[Properties.LocationAngle.name].toString());
    this.signalNameArray = this.dataAccessor
      .getConnectorsByRole(Roles.Sensors.name)
      .map((dc) => dc.properties[Properties.signalName.name].toString());
    this.plcTag = this.dataAccessor
      .getConnectorsByRole(Roles.Sensors.name)
      .map((dc) => dc.properties[Properties.plcTag.name].toString());

    this.populateAirgapValues();
    this.populateRotorCenterValues();
  }

  private getSeries() {
    const series = [];
    //this.populateRotorCenterValues();
    let rotorCenter;
    if (!!this.rotorCenter) {
      rotorCenter = [this.rotorCenter.x, this.rotorCenter.y];
    } else {
      rotorCenter = [0, 0];
    }

    const STATOR_CENTER = {
      name: "Stator Center",
      color: this.view.statorCenterColor,
      lineWidth: 0,
      data: [{ x: 0, y: 0 }],
      marker: {
        enabled: true,
        symbol: "circle",
        lineWidth: 1,
        lineColor: this.view.statorCenterColor,
        fillColor: this.view.statorCenterColor,
        radius: 3
      }
    };

    const ROTOR_CENTER = {
      name: "Rotor Center",
      color: this.view.rotorCenterColor,
      data: [{ x: rotorCenter[0], y: rotorCenter[1] }],
      lineWidth: 0,
      marker: {
        enabled: true,
        symbol: "circle",
        lineWidth: 1,
        lineColor: this.view.rotorCenterColor,
        fillColor: this.view.rotorCenterColor,
        radius: 3
      }
    };

    const AIRGAP_PROFILE = {
      enableMouseTracking: true,
      type: "line",
      name: "Airgap Profile",
      //dashStyle: "shortdash",
      lineWidth: 2,
      color: this.view.airgapProfileColor,
      data: this.createDotsOfEllipse()
    };

    const NOMINAL_AIRGAP_PLOTLINE = {
      enableMouseTracking: true,
      type: "line",
      name: "Reference Circle",
      //dashStyle: "shortdash",
      lineWidth: 1,
      color: this.view.nominalAirgapColor,
      data: Array(pointsForDrawing).fill(Math.min(this.minorAxis, this.majorAxis))
    };
    const rotorRadius = Math.min(this.minorAxis - 5, this.majorAxis - 5, 7);
    const ScaledDownMill = {
      enableMouseTracking: true,
      type: "line",
      name: "Scaled Down Rotor",
      //dashStyle: "shortdash",
      lineWidth: 1,
      color: this.view.scaledDownRotorColor,

      data: this.createDotsOfMill(rotorCenter, rotorRadius)
      // data: Array(37).fill(Math.min(this.view.minorAxis, this.view.majorAxis) - 5)
    };

    series.push(NOMINAL_AIRGAP_PLOTLINE);
    series.push(AIRGAP_PROFILE);
    series.push(ScaledDownMill);
    series.push(STATOR_CENTER);
    series.push(ROTOR_CENTER);

    if (!!this.locationAngleArray) {
      let sensorMarker = this.buildSensorMarkers();
      series.push(sensorMarker);
    }
    return series;
  }

  private createDotsOfMill(rotorCenter, radius) {
    let millDots = [];
    const phi = rotorCenter[0] * RADS_PER_DEGREE;
    const r0 = rotorCenter[1];
    for (let i = 0; i < pointsForDrawing - 1; i++) {
      let theta = (i * RADS_PER_DEGREE * 360) / pointsForDrawing;
      millDots.push(
        r0 * Math.cos(theta - phi) +
          Math.sqrt(radius * radius - r0 * r0 * Math.sin(theta - phi) * Math.sin(theta - phi))
      );
    }
    millDots.push(millDots[0]);
    return millDots;
  }
  private createDotsOfEllipse() {
    const b = this.minorAxis;
    const a = this.majorAxis;
    let EllipsDots = [];
    for (let i = 0; i < pointsForDrawing - 1; i++) {
      let theta = Math.PI / 2 + (i * RADS_PER_DEGREE * 360) / pointsForDrawing;
      EllipsDots.push(
        (a * b) /
          Math.sqrt(
            b * Math.cos(theta) * (b * Math.cos(theta)) +
              a * Math.sin(theta) * (a * Math.sin(theta))
          )
      );
    }
    EllipsDots.push(EllipsDots[0]);
    return EllipsDots;
  }

  private buildSensorMarkers() {
    let sensors = [];
    for (let i = 0; i < this.locationAngleArray.length; i++) {
      sensors.push([+this.locationAngleArray[i] + 90, Math.min(this.minorAxis, this.majorAxis)]);
    }
    const sensorMarker = {
      name: "Static Airgap Sensors",
      data: sensors,
      lineWidth: 0,
      color: "green",
      marker: {
        enabled: true,
        symbol: "square",
        lineWidth: 0,
        radius: 3
      },
      states: {
        hover: {
          lineWidthPlus: 0
        }
      }
    };
    //
    return sensorMarker;
  }

  protected setChartOptions(): void {
    this.updateChartFlag = true;
    this.updateOneToOneFlag = true;
    const component = this;
    // FIXME 905 add nominal airgap value with signal name..

    this.chartOptions = {
      lang: {
        noData: "No Data Available"
      },
      chart: {
        polar: true
      },
      title: { text: null },
      credits: { enabled: false },

      plotOptions: {
        series: {
          pointStart: 0,
          pointInterval: CALCULATION_PRECISION,
          connectEnds: true,
          connectNulls: false
        },
        line: { marker: { enabled: false, states: { hover: { enabled: false } } } }
      },
      tooltip: {
        borderColor: "black",
        shared: false,
        formatter: function () {
          let text = "";
          if (this.series.name == "Static Airgap Sensors") {
            var nameIndex = component.locationAngleArray.indexOf((this.x - 90).toString());
            text = component.signalNameArray[nameIndex] + "<br>" + component.plcTag[nameIndex];
          } else {
            text = this.series.name;
          }
          return text;
        }
      },
      xAxis: {
        tickInterval: 90,
        min: 0,
        max: 360,
        lineWidth: 0,
        gridLineColor: "#000000",
        labels: {
          formatter: function () {
            return "";
          }
        }
      },
      yAxis: {
        labels: {
          enabled: false
        },
        id: "y-axis-1",
        min: 0,
        tickAmount: 0,
        gridLineWidth: 0,
        plotLines: [] // [NOMINAL_AIRGAP_PLOTLINE]
      },
      series: this.getSeries(),
      legend: {
        enabled: true,
        align: "center",
        verticalAlign: "bottom",
        layout: "horizontal"
      }
    };
  }
}
