import { animate, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { isEqual as _isEqual } from "lodash";
import { first } from "rxjs/operators";
import { ItemBrowserComponent } from "../../../browsing/components/item-browser/item-browser.component";
import { DataExplorerSelector } from "../../../browsing/services/data-explorer.selector";
import { DataExplorerActions } from "../../../browsing/store/data-explorer";
import { DraggedItemType } from "../../../core/models/drag/dragged-item-type";
import { DroppableElement } from "../../../core/models/droppable-element";
import { Equipment } from "../../../core/models/equipment";
import { EquipmentProperty } from "../../../core/models/equipment-property";
import { EquipmentPropertyDataType } from "../../../core/models/equipment-property-data-type";
import { IDragDropService } from "../../../core/services/i-drag-drop.service";
import { DataService } from "../../../data-connectivity/services/data.service";
import { Dispatcher } from "../../../dispatcher";
import { EnvironmentSelector } from "../../../environment/services/environment.selector";
import { LocalizationService } from "../../../i18n/localization.service";
import { isEmpty } from "../../../ts-utils/helpers/is-empty.helper";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { RuntimeSettingsSelector } from "../../services/entity-selectors/runtime-settings.selector";

@Component({
  selector: "equipment-property-browser",
  templateUrl: "equipment-property-browser.component.html",
  styleUrls: ["./equipment-property-browser.component.scss"],
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [style({ opacity: 0 }), animate("200ms", style({ opacity: 1 }))]),
      transition(":leave", [style({ opacity: 1 }), animate("200ms", style({ opacity: 0 }))])
    ])
  ]
})
export class EquipmentPropertyBrowserComponent
  extends ItemBrowserComponent
  implements OnInit, OnDestroy
{
  selectedEquipment: Maybe<Equipment>;
  @Output() collapse: EventEmitter<any> = new EventEmitter();
  @Output() collapseSidebar: EventEmitter<any> = new EventEmitter();

  public showProperties: boolean = false;
  public equipmentProperties: Maybe<EquipmentProperty[]> = null;
  public recursiveChecked: boolean = true;
  EquipmentPropertyDataType = EquipmentPropertyDataType;
  public showProgressBar: boolean = true;
  private lastSelectedProperty: Maybe<EquipmentProperty>;

  constructor(
    protected dataService: DataService,
    public localizer: LocalizationService,
    protected dragDropService: IDragDropService,
    protected dataExplorerSelector: DataExplorerSelector,
    protected dispatcher: Dispatcher,
    private runtimeSettingsSelector: RuntimeSettingsSelector,
    protected environmentSelector: EnvironmentSelector
  ) {
    super(dragDropService, environmentSelector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscribeToSelectedEquipment();
    this.lastSelectedProperty = this.dataExplorerSelector.getLastSelectedEquipmentProperty();
    this.setSelectedItem(this.lastSelectedProperty);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (!_isEqual(this.selectedItem, this.lastSelectedProperty)) {
      this.dispatcher.dispatch(
        DataExplorerActions.preserveEquipmentProperty({
          equipmentProperty: this.selectedItem as EquipmentProperty
        })
      );
    }
  }

  private subscribeToSelectedEquipment(): void {
    this.dataExplorerSelector.selectEquipment().subscribe((equipment) => {
      this.selectedEquipment = equipment;
      this.setSelectedItem(null);
      this.getEquipmentProperties(this.recursiveChecked);
    });
  }

  recursiveCheckBoxChanged(checkedRecursive: boolean): void {
    this.recursiveChecked = checkedRecursive;
    this.getEquipmentProperties(this.recursiveChecked);
  }

  getEquipmentProperties(recursiveSearch: boolean): void {
    if (isDefined(this.selectedEquipment)) {
      this.dataService
        .getEquipmentProperties(this.selectedEquipment.path, recursiveSearch)
        .pipe(first())
        .subscribe((equipmentProperties: EquipmentProperty[]) => {
          this.equipmentProperties = equipmentProperties;
          this.showProperties = !isEmpty(equipmentProperties);
          this.showProgressBar = false;
        });
    }
  }

  dragStart(event: Event, property: EquipmentProperty): void {
    this.collapseSidebar.emit();
    super.dragStart(event, property, this.runtimeSettingsSelector.getCurrentRootPath());
  }

  getDragTarget(property: EquipmentProperty) {
    return {
      type: DraggedItemType.Equipment,
      item: {
        equipment: this.selectedEquipment,
        property
      }
    };
  }

  selectedEquipmentPropertyChanged(property: EquipmentProperty): void {
    this.setSelectedItem(property);
  }

  close(event: Event): void {
    this.setSelectedItem(null);
    this.collapse.emit();
    this.dispatcher.dispatch(DataExplorerActions.unselectEquipment());
    event.stopPropagation();
  }

  isSelectedItem(item: DroppableElement): boolean {
    return _isEqual(this.selectedItem, item);
  }

  getPropertyNameBasedOnAliasMode(property: EquipmentProperty): string {
    return this.aliasMode && isDefined(property.aliasName) ? property.aliasName : property.name;
  }

  resolveClassName(property: EquipmentProperty): string {
    const className = this.getClassNameBasedOnAliasMode(property);
    return className !== "" ? "(" + className + ")" : "";
  }

  getClassNameBasedOnAliasMode(property: EquipmentProperty): string {
    return this.aliasMode && isDefined(property.aliasClassName)
      ? property.aliasClassName
      : property.className ?? "";
  }
}
