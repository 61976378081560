import { Update } from "@ngrx/entity";
import { createAction, props } from "@ngrx/store";
import { DataConnectorViewDto } from "../../../data-connectivity/models/data-connector-view";
import { EntityId } from "../../../meta";
import { DeepUpdate } from "../../../ts-utils/models/deep-update.type";

export namespace DataConnectorViewActions {
  export const updateOne = createAction(
    "[DataConnectorView] Update One",
    props<{ connectorViewUpdate: Update<DataConnectorViewDto> }>()
  );

  export const toggleExpandedState = createAction(
    "[DataConnectorView] Toggle Connector Expanded State",
    props<{ connectorViewId: EntityId }>()
  );

  export const updateMany = createAction(
    "[DataConnectorView] Update Many",
    props<{ connectorViewUpdates: DeepUpdate<DataConnectorViewDto>[] }>()
  );

  export const deletePseudoOne = createAction(
    "[DataConnectorView] Delete Pseudo One",
    props<{ pseudoConnectorViewId: EntityId }>()
  );

  export const clearConnectorsFromDeletedYAxis = createAction(
    "[DataConnectorView] Clear Connectors From Deleted Y Axis",
    props<{ validYAxes: string[] }>()
  );
}
