import { RectangleDto } from "../models/resize/rectangle";

export type CalcNewRectFunction = (
  originalRect: RectangleDto,
  dx: number,
  dy: number
) => RectangleDto;

const MIN_SIZE = 20;

export function calcNewRectFactory(
  topGrabbed: boolean,
  rightGrabbed: boolean,
  bottomGrabbed: boolean,
  leftGrabbed: boolean
): CalcNewRectFunction {
  return (originalRect: RectangleDto, dx: number, dy: number) => {
    const horizontal = calcNewSize(
      leftGrabbed,
      rightGrabbed,
      originalRect.left,
      originalRect.width,
      dx
    );
    const vertical = calcNewSize(
      topGrabbed,
      bottomGrabbed,
      originalRect.top,
      originalRect.height,
      dy
    );
    return {
      left: horizontal.start,
      width: horizontal.size,
      top: vertical.start,
      height: vertical.size
    };
  };
}

function calcNewSize(
  startGrabbed: boolean,
  endGrabbed: boolean,
  originalStart: number,
  originalSize: number,
  delta: number
): { start: number; size: number } {
  const startMult = startGrabbed ? 1 : 0;
  const endMult = endGrabbed ? 1 : 0;

  if (startGrabbed && delta > originalSize - MIN_SIZE) {
    delta = originalSize - MIN_SIZE;
  }
  if (endGrabbed && delta < -(originalSize - MIN_SIZE)) {
    delta = -(originalSize - MIN_SIZE);
  }

  return {
    start: originalStart + startMult * delta,
    size: originalSize - startMult * delta + endMult * delta
  };
}
