import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ColorPickerDirective } from "ngx-color-picker";
import { take } from "rxjs/operators";
import { getValuesFromRgba, isRgbaFormat } from "../../../elements/helpers/color.helper";
import {
  BLUE_INDEX,
  GREEN_INDEX,
  OPACITY_INDEX,
  RED_INDEX
} from "../../../elements/models/colors.constants";
import { LocalizationService } from "../../../i18n/localization.service";
import { BaseDialogComponent } from "../../../shared/components/base-dialog/base-dialog.component";
import { ColorPickerDialogInfo } from "../../../shared/models/color-picker-dialog-info";
import { Maybe, isDefined, isEmptyOrNotDefined } from "../../../ts-utils";

export const DEFAULT_COLOR = "rgba(255,255,255,0)";
const COLOR_INPUT = "colorInput";

@Component({
  selector: "color-picker-dialog",
  templateUrl: "color-picker-dialog.component.html",
  styleUrls: ["color-picker-dialog.component.scss"]
})
export class ColorPickerDialogComponent
  extends BaseDialogComponent
  implements OnInit, AfterViewInit
{
  @ViewChild(ColorPickerDirective) colorPicker: Maybe<ColorPickerDirective>;
  @ViewChild(COLOR_INPUT) colorInput: ElementRef<HTMLInputElement>;
  public color: string;
  public defaultColor: string;
  private isFirstTimeOpened: boolean = true;

  constructor(
    public localizer: LocalizationService,
    @Inject(MAT_DIALOG_DATA) public colorPickerDialogInfo: ColorPickerDialogInfo,
    private dialogRef: MatDialogRef<ColorPickerDialogComponent>
  ) {
    super();
  }

  ngOnInit(): void {
    this.color = this.colorPickerDialogInfo.color;
    this.defaultColor = this.colorPickerDialogInfo.defaultColor;
  }

  ngAfterViewInit(): void {
    this.subscribeToFirstColorChange();
  }

  subscribeToFirstColorChange(): void {
    if (isDefined(this.colorPicker)) {
      this.colorPicker.colorPickerChange.pipe(take(1)).subscribe((color: string) => {
        if (this.shouldSetOpacityToNewColor(color)) {
          const valuesFromRGBA = getValuesFromRgba(color);
          if (Number(valuesFromRGBA[OPACITY_INDEX]) === 0 && isDefined(this.colorInput)) {
            const color = `rgba(${valuesFromRGBA[RED_INDEX]},${valuesFromRGBA[GREEN_INDEX]},${valuesFromRGBA[BLUE_INDEX]},1)`;
            this.colorPicker.cpInputChange.emit({
              input: COLOR_INPUT,
              value: color,
              color: color
            });
          }
        }
      });
    }
  }

  private shouldSetOpacityToNewColor(color: string): boolean {
    return (
      isEmptyOrNotDefined(this.colorPickerDialogInfo.color) &&
      this.isFirstTimeOpened &&
      isRgbaFormat(color)
    );
  }

  onInputChange(event): void {
    if (isDefined(this.colorPicker)) {
      this.colorPicker.colorPickerChange.emit(event.color);
      this.colorPicker.colorSelected(event.color);
    }
  }

  onReset(): void {
    this.color = this.defaultColor;
    this.dialogRef.close(this.color);
  }

  onSave(): void {
    this.dialogRef.close(this.color);
  }

  onCancel(): void {
    this.color = this.colorPickerDialogInfo.color;
    this.dialogRef.close(this.color);
  }
}
