import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ParamMap } from "@angular/router";
import { Update, createEntityAdapter, Dictionary as ngDictionary } from "@ngrx/entity";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { REPORT_FILTER_ID, RUNTIME_FILTER_ID } from "../../core/helpers/filter/filter-id.helper";
import { FilterConfigurationDto } from "../../core/models/filter/filter-configuration";
import { GeneralSettingsDto } from "../../core/models/general-settings";
import { ReportId, ReportTag } from "../../core/models/report-id";
import { UrlParams } from "../../core/models/url-params";
import { WebServicesConfiguration } from "../../core/services/api.config";
import { DynamicReportServiceFactory } from "../../core/services/dynamic-report/dynamic-report-service.factory";
import { QueryStringService } from "../../core/services/query-string.service";
import { ErrorCatchingActions } from "../../core/store/error-catching/error-catching.actions";
import { ErrorHandlingActions } from "../../core/store/error-handling/error-handling.actions";
import { getConnectorIdByViewId } from "../../data-connectivity/helpers/connector-view-id.helper";
import { DataConnectorDto } from "../../data-connectivity/models/data-connector";
import { DataConnectorViewDto } from "../../data-connectivity/models/data-connector-view";
import { Dispatcher } from "../../dispatcher";
import { LocalizationService } from "../../i18n/localization.service";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import { removeDefaultValues } from "../../meta/helpers/remove-default-values.helper";
import { Entity, EntityId } from "../../meta/models";
import { TypeProvider } from "../../meta/services/type-provider";
import { ReportCreationParams } from "../../shared/models/report-creation-params";
import { ReportCreationType } from "../../shared/models/report-creation-type";
import {
  CriticalError,
  Dictionary,
  isDefined,
  isEmpty,
  isEmptyOrNotDefined,
  isNotDefined,
  mergeDeep
} from "../../ts-utils";
import { Maybe } from "../../ts-utils/models/maybe.type";
import {
  CURRENT_VERSION,
  ReportUpgradeService
} from "../../upgrade/service/report-upgrade.service";
import { HISTORY_VIEW_CONSTANTS } from "../components/history-view/history-view.constants";
import { ComponentStateDto } from "../models/component-state";
import { PAGE } from "../models/element-type.constants";
import {
  COMPONENT_STATE_DTO,
  DATA_CONNECTOR_DTO,
  DATA_CONNECTOR_VIEW_DTO,
  FILTER_CONFIGURATION_DTO
} from "../models/entity-type.constants";
import { ReportConfiguration } from "../models/report-configuration";
import { CommonActions } from "../store/common/common.actions";
import { ComponentStateState } from "../store/component-state/component-state.state";
import { DataConnectorState } from "../store/data-connector";
import { DataConnectorViewState } from "../store/data-connector-view/data-connector-view.state";
import { getAllDynamicConnectorsAsDictionary } from "../store/data-connector/data-connector.selectors";
import { FilterState } from "../store/filter/filter.state";
import { ReportContents } from "../store/state";
import { FeatureSelector } from "./feature-selector";
import { createDefaultFilter } from "./global-filter.helper";
import { deserializeReportContentOnLoad } from "./report-converter.helper";
import { ReportsService } from "./reports.service";

@Injectable({ providedIn: "root" })
export class ConfigurationService {
  private url: string;
  private _savedContentForLoadedReport: Maybe<ReportContents> = null;
  private _loadedReportId: Maybe<ReportId> = null;
  protected saveAsUrl: string;

  //FIXME: use HttpService instead of HttpClient
  constructor(
    protected http: HttpClient,
    api: WebServicesConfiguration,
    protected reportsService: ReportsService,
    protected dispatcher: Dispatcher,
    protected typeProvider: TypeProvider,
    protected translationService: LocalizationService,
    protected featureSelector: FeatureSelector,
    protected reportUpgradeService: ReportUpgradeService,
    protected queryStringService: QueryStringService,
    protected dynamicReportSericeFactory: DynamicReportServiceFactory
  ) {
    this.url = api.reportsUrl;
    this.saveAsUrl = api.saveAsUrl;
  }

  public set savedContentForLoadedReport(savedContentForLoadedReport: Maybe<ReportContents>) {
    this._savedContentForLoadedReport = savedContentForLoadedReport;
  }

  public get savedContentForLoadedReport(): Maybe<ReportContents> {
    return this._savedContentForLoadedReport;
  }

  private createReportUrl(reportTag: ReportTag | ReportId): string {
    if (this.queryStringService.getParams().has(UrlParams.idType)) {
      return (
        this.url +
        this.queryStringService.resolveUrl(reportTag.toString()) +
        "?idType=" +
        this.queryStringService.getParams().get(UrlParams.idType)
      );
    }
    return this.url + this.queryStringService.resolveUrl(reportTag.toString());
  }

  load(reportTag: ReportTag, queryParams?: ParamMap): Observable<ReportConfiguration> {
    if (typeof reportTag === "undefined") {
      throw new CriticalError("Undefined report ID");
    }

    const reportTagString = reportTag.toString();
    let reportToFetch$: Observable<ReportConfiguration>;

    if (this.dynamicReportSericeFactory.isDynamicReport(reportTagString) && queryParams) {
      const dynamicReportService = this.dynamicReportSericeFactory.getService(reportTagString);
      const report = dynamicReportService.generateReport(queryParams);
      reportToFetch$ = of(report);
    } else {
      const uri = this.createReportUrl(reportTag);
      reportToFetch$ = this.http.get<ReportConfiguration>(uri);
    }

    return reportToFetch$.pipe(
      switchMap(
        // FIX ME: how do we allow fetchedReport to be string or reportConfiguration and afterwards we access content property?
        (fetchedReportConfig: string | ReportConfiguration): Observable<ReportConfiguration> => {
          // NOTE expect that fetchedReportConfig has id which is valid
          const defaultReportConfig: ReportConfiguration = getDefaultReportConfiguration(
            reportTag as unknown as ReportId
          );

          if (isNotDefined(fetchedReportConfig)) {
            fetchedReportConfig = defaultReportConfig;
          } else if (typeof fetchedReportConfig === "string") {
            fetchedReportConfig = !isEmptyOrNotDefined(fetchedReportConfig)
              ? (JSON.parse(fetchedReportConfig) as ReportConfiguration)
              : defaultReportConfig;
          }
          if (
            typeof fetchedReportConfig.content === "string" &&
            isEmpty(fetchedReportConfig.content)
          ) {
            delete fetchedReportConfig.content;
          }

          const upgradeResult = this.reportUpgradeService.upgrade(fetchedReportConfig.content);
          fetchedReportConfig.content = upgradeResult.upgradedReportContent;
          if (upgradeResult.isModified) {
            console.log(
              `Report configuration upgraded from ${upgradeResult.originalVersion} to ${CURRENT_VERSION}: ` +
                upgradeResult.performedSteps.join(", ")
            );
          }
          if (!isEmpty(upgradeResult.warning)) {
            this.dispatcher.dispatch(
              ErrorHandlingActions.displayInfo({
                messageToDisplay: this.translationService.get(upgradeResult.warning)
              })
            );
          }

          const untypedReport = mergeDeep(defaultReportConfig, fetchedReportConfig);
          const typedReport: ReportConfiguration = {
            ...untypedReport,
            content: deserializeReportContentOnLoad(untypedReport.content)
          } as ReportConfiguration;
          if (!typedReport.ancestors) {
            return of(typedReport);
          }
          try {
            return this.reportsService.setAncestorsForReport(typedReport);
          } catch (err) {
            console.error(`Ancestors not found for report ${reportTag}`);
            return of(typedReport);
          }
        }
      ),
      map((report: ReportConfiguration) => {
        // FIXME 13 another side effect; add into store instead
        this.reportsService.current = report;
        this._loadedReportId = reportTag as unknown as ReportId;

        if (isDefined(report.content) && !isEmpty(report.content)) {
          const prunedReportContent = pruneReportContents(report.content, this.typeProvider);
          this.savedContentForLoadedReport = prunedReportContent;
        }

        return report;
      }),
      catchError((error) => {
        let errorMessage: string = this.translationService.get(
          LOCALIZATION_DICTIONARY.snackBarMessages.LoadingReportConfigError
        );
        if (error?.error?.Message) {
          // BE error messages are not translated
          errorMessage = error.error.Message.toString();
        }

        this.dispatcher.dispatch(
          ErrorCatchingActions.catchError({
            messageToDisplay: errorMessage,
            error: error,
            autoClose: false
          })
        );
        return of(this.getDeserializedDefaultReportConfig(reportTag as unknown as ReportId));
      })
    );
  }

  private getDeserializedDefaultReportConfig(reportId: ReportId): ReportConfiguration {
    const defaultReportConfig: ReportConfiguration = getDefaultReportConfiguration(reportId);

    const report: ReportConfiguration = {
      ...defaultReportConfig,
      content: deserializeReportContentOnLoad(defaultReportConfig.content)
    } as ReportConfiguration;
    return report;
  }

  getContentForNewReport(reportCreationParams: ReportCreationParams): ReportContents {
    if (!isDefined(reportCreationParams) || typeof reportCreationParams.id === "undefined") {
      throw new CriticalError("Undefined report ID");
    }

    if (reportCreationParams.creationType === ReportCreationType.New) {
      return getDefaultReportContent();
    } else {
      const existingReportContents = this.featureSelector.getReportState();
      if (isDefined(existingReportContents)) {
        return existingReportContents;
      } else {
        const duplicationWarning = this.translationService.get(
          LOCALIZATION_DICTIONARY.snackBarMessages.ReportDuplicationWarning
        );
        this.dispatcher.dispatch(
          ErrorCatchingActions.catchWarning({ messageToDisplay: duplicationWarning })
        );
        return getDefaultReportContent();
      }
    }
  }

  //FIXME: do not return full report configuration
  save(
    reportId: ReportId,
    state: ReportContents,
    hideConfirmation: boolean,
    type: ReportCreationType = ReportCreationType.Duplicate
  ): Observable<Maybe<ReportConfiguration>> {
    if (typeof reportId === "undefined") {
      throw new CriticalError("Undefined report ID");
    }
    const prunedReportContent = pruneReportContents(state, this.typeProvider);
    const headers: HttpHeaders = new HttpHeaders({ "Content-type": "application/json" });
    const reportConfigForSave: Maybe<ReportConfiguration> = {
      id: reportId,
      content: prunedReportContent
    };

    const savedMessage = this.translationService.get(LOCALIZATION_DICTIONARY.snackBarMessages.Save);
    const errorMessage = this.translationService.get(
      LOCALIZATION_DICTIONARY.snackBarMessages.SavingError
    );

    let result: Observable<ReportConfiguration>;

    if (type === ReportCreationType.New) {
      result = this.http
        .post<ReportConfiguration>(
          this.createReportUrl(reportId),
          JSON.stringify(reportConfigForSave),
          { headers }
        )
        .pipe(
          map((createdReport: ReportConfiguration) => {
            this.dispatcher.dispatch(
              CommonActions.addReportToSidebar({
                data: createdReport
              })
            );
            if (createdReport?.id) {
              reportConfigForSave.id = createdReport.id;
            }

            return createdReport;
          })
        );
    } else {
      result = this.http.put<ReportConfiguration>(
        this.createReportUrl(reportId),
        JSON.stringify(reportConfigForSave),
        { headers }
      );
    }

    return result.pipe(
      map(() => {
        if (!hideConfirmation) {
          this.dispatcher.dispatch(
            ErrorCatchingActions.provideInfo({ messageToDisplay: savedMessage })
          );
        }
        if (this._loadedReportId === reportId) {
          this.savedContentForLoadedReport = prunedReportContent;
        }
        return reportConfigForSave;
      }),
      tap((createdReport) => {
        this.dispatcher.dispatch(
          CommonActions.reportCreated({
            reportName: reportId.toString(),
            reportId: createdReport.id
          })
        );
      }),
      catchError((error: HttpErrorResponse) => {
        this.dispatcher.dispatch(
          ErrorCatchingActions.catchError({
            messageToDisplay: errorMessage,
            error: error,
            autoClose: true
          })
        );
        return of(null);
      })
    );
  }

  create(
    reportCreationParams: ReportCreationParams,
    state: ReportContents,
    currentReportId: ReportId
  ): Observable<Maybe<ReportConfiguration>> {
    return this.save(reportCreationParams.id, state, false, ReportCreationType.New);
  }
}

export function getDefaultReportConfiguration(reportId: ReportId): ReportConfiguration {
  return {
    content: getDefaultReportContent(),
    id: reportId,
    name: ""
  };
}

export function getDefaultReportContent(): ReportContents {
  return {
    componentStates: {
      ids: ["Root"],
      entities: {
        Root: {
          dataConnectorIds: [],
          view: {},
          childrenIds: [],
          filterId: RUNTIME_FILTER_ID,
          id: "Root",
          type: PAGE,
          cache: {
            enabled: false,
            currentTimestamp: null
          },
          dataConnectorQuery: null
        } as ComponentStateDto
      } as Dictionary<ComponentStateDto>
    },
    dataConnectors: {
      ids: [],
      entities: {}
    },
    filters: {
      ids: [REPORT_FILTER_ID, RUNTIME_FILTER_ID],
      entities: {
        [REPORT_FILTER_ID]: createDefaultFilter(REPORT_FILTER_ID),
        [RUNTIME_FILTER_ID]: createDefaultFilter(RUNTIME_FILTER_ID)
      }
    },
    dataConnectorViews: {
      ids: [],
      entities: {}
    },
    componentsCounter: 0,
    generalSettings: TypeProvider.getDefaultsForType(GeneralSettingsDto),
    version: CURRENT_VERSION.asArray
  };
}

export function pruneReportContents(
  report: ReportContents,
  typeProvider: TypeProvider
): ReportContents {
  report = removeDynamicDataConnectors(report);
  report = removeDataConnectorsThatAreNotInTheComponentStates(report);
  report = removeRuntimeFilters(report);

  const prunedReport: ReportContents = {
    componentStates: transformComponentStates(report.componentStates, typeProvider),
    dataConnectors: transformDataConnectors(report.dataConnectors, typeProvider),
    filters: transformFilters(report.filters, typeProvider),
    dataConnectorViews: transformConnectorViews(report.dataConnectorViews, typeProvider),
    componentsCounter: report.componentsCounter,
    generalSettings: transformGeneralSettings(report.generalSettings, typeProvider),
    version: CURRENT_VERSION.asArray
  };
  return prunedReport;
}

function findNonRuntimeOnlyFilter(
  filter: FilterConfigurationDto,
  allFilters: ngDictionary<FilterConfigurationDto>
): Maybe<FilterConfigurationDto> {
  let f: Maybe<FilterConfigurationDto> = filter;
  while (isDefined(f) && f.isRuntimeOnly && isDefined(f.sourceFilterId)) {
    f = allFilters[f.sourceFilterId];
  }
  return f;
}

function removeRuntimeFilters(state: ReportContents): ReportContents {
  const filterAdapter = createEntityAdapter<FilterConfigurationDto>();
  const runtimeFilters = Object.values(state.filters.entities)
    .filter(isDefined)
    .filter((filter) => filter.isRuntimeOnly);

  Object.values(state.componentStates.entities)
    .filter(isDefined)
    .forEach((component) => {
      if (isDefined(component.filterId)) {
        const filter = state.filters.entities[component.filterId];
        if (filter?.isRuntimeOnly) {
          component.filterId = findNonRuntimeOnlyFilter(filter, state.filters.entities)?.id;
        }
      }
    });

  const dependentFilters: Update<FilterConfigurationDto>[] = [];

  Object.values(state.filters.entities)
    .filter(isDefined)
    .forEach((filter: FilterConfigurationDto) => {
      if (isDefined(filter.sourceFilterId)) {
        const eventRuntimeFilter = runtimeFilters.find((f) => f.id === filter.sourceFilterId);
        if (isDefined(eventRuntimeFilter)) {
          dependentFilters.push({
            id: filter.id,
            changes: {
              sourceFilterId: findNonRuntimeOnlyFilter(eventRuntimeFilter, state.filters.entities)
                ?.id
            }
          } as Update<FilterConfigurationDto>);
        }
      }
    });

  state.filters = filterAdapter.updateMany(dependentFilters, state.filters);
  state.filters = filterAdapter.removeMany(
    runtimeFilters.map((filter) => filter.id as string),
    state.filters
  );
  return state;
}

function removeDataConnectorsThatAreNotInTheComponentStates(state: ReportContents): ReportContents {
  const componentStateIds: string[] = Object.keys(state.componentStates.entities);
  const presentDataConnectorIds: EntityId[] = componentStateIds.reduce(
    (acc: EntityId[], componentStateId: string) => [
      ...acc,
      ...state.componentStates.entities[componentStateId].dataConnectorIds
    ],
    []
  );
  const filteredDataConnectorEntities: Dictionary<DataConnectorDto> =
    presentDataConnectorIds.reduce(
      (acc: Dictionary<DataConnectorDto>, presentDataConnectorId: EntityId) => {
        acc[presentDataConnectorId] = state.dataConnectors.entities[presentDataConnectorId];
        return acc;
      },
      {} as Dictionary<DataConnectorDto>
    );
  const dataConnectorsState: DataConnectorState = {
    ids: presentDataConnectorIds as string[],
    entities: filteredDataConnectorEntities
  };
  return {
    ...state,
    dataConnectors: dataConnectorsState
  };
}

function removeDynamicDataConnectors(state: ReportContents): ReportContents {
  const dynamicConnectorsDict: Dictionary<DataConnectorDto> = getAllDynamicConnectorsAsDictionary(
    state.dataConnectors
  );

  const filteredComponentStatesDict = updateComponentsWithDynamicConnectors(
    state?.componentStates.entities,
    dynamicConnectorsDict
  );
  const updatedDataConnectorViewState = saveDynamicConnectorTitleInReport(
    state.dataConnectorViews.entities,
    dynamicConnectorsDict
  );

  const nonDynamicConnectors = Object.values(state.dataConnectors.entities).filter(
    (connector) => !connector?.isDynamicallyCreated
  );
  const filteredDataConnectorState: DataConnectorState = {
    ids: nonDynamicConnectors.map((connector) => connector?.id.toString()),
    entities: nonDynamicConnectors.reduce((acc, connector) => {
      acc[connector?.id] = connector;
      return acc;
    }, {})
  };

  return {
    componentStates: {
      ids: state.componentStates.ids,
      entities: filteredComponentStatesDict
    },
    dataConnectors: filteredDataConnectorState,
    filters: state.filters,
    dataConnectorViews: {
      ids: state.dataConnectorViews.ids,
      entities: updatedDataConnectorViewState
    },
    componentsCounter: state.componentsCounter,
    generalSettings: state.generalSettings,
    version: state.version
  };
}

function updateComponentsWithDynamicConnectors(
  components: Dictionary<ComponentStateDto>,
  connectorsToRemove: Dictionary<DataConnectorDto>
): Dictionary<ComponentStateDto> {
  return Object.values(components)
    .map((componentState: ComponentStateDto) => ({
      ...componentState,
      dataConnectorIds: componentState.dataConnectorIds.filter(
        (connectorId) => !connectorsToRemove[connectorId]
      )
    }))
    .reduce((acc, componentState: ComponentStateDto) => {
      acc[componentState.id] = componentState;
      return acc;
    }, {});
}

function saveDynamicConnectorTitleInReport(
  connectorView: Dictionary<DataConnectorViewDto>,
  dynamicConnectors: Dictionary<DataConnectorDto>
): Dictionary<DataConnectorViewDto> {
  return Object.values(connectorView)
    .map((connectorView: DataConnectorViewDto) => ({
      ...connectorView,
      title: dynamicConnectors[getConnectorIdByViewId(connectorView.id)]?.title
    }))
    .reduce((acc, connectorView: DataConnectorViewDto) => {
      acc[connectorView.id] = connectorView;
      return acc;
    }, {});
}

function transformComponentStates(
  componentStates: ComponentStateState,
  typeProvider: TypeProvider
): ComponentStateState {
  const componentStateType = typeProvider.getType(COMPONENT_STATE_DTO);
  const componentStatesArray: ComponentStateDto[] = filterOutHistoryViewElements(
    Object.values(componentStates.entities)
  );

  const minimalComponentStatesArray: ComponentStateDto[] = componentStatesArray.map(
    (componentState: ComponentStateDto) => {
      const minimalComponentState = removeDefaultValues(
        componentState,
        componentStateType,
        typeProvider
      ) as ComponentStateDto;
      return minimalComponentState;
    }
  );
  const entities: Dictionary<ComponentStateDto> = entitiesArrayToDictionary(
    minimalComponentStatesArray
  );

  return {
    ids: Object.keys(entities),
    entities
  } as ComponentStateState;
}

function transformDataConnectors(
  dataConnectors: DataConnectorState,
  typeProvider: TypeProvider
): DataConnectorState {
  const filteredDataConnectorArray = filterOutHistoryViewElements(
    Object.values(dataConnectors.entities)
  ).map(clearDataPoints);
  const dataConnectorType = typeProvider.getType(DATA_CONNECTOR_DTO);

  const minimalDataConnectorsArray: DataConnectorDto[] = filteredDataConnectorArray.map(
    (dataConnector: DataConnectorDto) => {
      const minimalDataConnector = removeDefaultValues(
        dataConnector,
        dataConnectorType,
        typeProvider
      ) as DataConnectorDto;
      return minimalDataConnector;
    }
  );
  const entities: Dictionary<DataConnectorDto> = entitiesArrayToDictionary(
    minimalDataConnectorsArray
  );
  return {
    ids: Object.keys(entities),
    entities: entities
  };
}

function clearDataPoints(dataConnector: DataConnectorDto): DataConnectorDto {
  return { ...dataConnector, dataPoints: [] };
}

function transformFilters(filters: FilterState, typeProvider: TypeProvider): FilterState {
  const filtersArray: FilterConfigurationDto[] = filterOutHistoryViewElements(
    Object.values(filters.entities)
  );
  const filterConfigurationType = typeProvider.getType(FILTER_CONFIGURATION_DTO);

  const minimalFiltersArray: FilterConfigurationDto[] = filtersArray
    .filter((filter) => filter.id.toString() !== RUNTIME_FILTER_ID)
    .map((filter: FilterConfigurationDto) => {
      const minimalDataConnector = removeDefaultValues(
        filter,
        filterConfigurationType,
        typeProvider
      ) as FilterConfigurationDto;
      return minimalDataConnector;
    });

  const entities: Dictionary<FilterConfigurationDto> =
    entitiesArrayToDictionary(minimalFiltersArray);
  return {
    ids: Object.keys(entities),
    entities: entities
  };
}

function transformConnectorViews(
  connectorViews: DataConnectorViewState,
  typeProvider: TypeProvider
): DataConnectorViewState {
  const connectorViewsArray: DataConnectorViewDto[] = filterOutHistoryViewElements(
    Object.values(connectorViews.entities)
  );
  const connectorViewType = typeProvider.getType(DATA_CONNECTOR_VIEW_DTO);
  const minimalConnectorViewsArray: DataConnectorViewDto[] = connectorViewsArray.reduce(
    (acc: DataConnectorViewDto[], connectorView: DataConnectorViewDto) => {
      const minimalDataConnectorView = removeDefaultValues(
        connectorView,
        connectorViewType,
        typeProvider
      ) as DataConnectorViewDto;

      if (
        shouldSaveConnectorViewProps(minimalDataConnectorView) ||
        isDefined(minimalDataConnectorView.column) ||
        isDefined(minimalDataConnectorView.timeSeriesConfig)
      ) {
        acc.push(minimalDataConnectorView);
      }

      return acc;
    },
    []
  );
  const entities: Dictionary<DataConnectorViewDto> = entitiesArrayToDictionary(
    minimalConnectorViewsArray
  );
  return {
    ids: Object.keys(entities),
    entities: entities
  };
}

export function shouldSaveConnectorViewProps(
  minimalDataConnectorView: DataConnectorViewDto
): boolean {
  const propertyNames = Object.getOwnPropertyNames(minimalDataConnectorView).filter(
    (propertyName) => propertyName !== "id"
  );

  return !isEmpty(propertyNames);
}

function transformGeneralSettings(generalSettings: GeneralSettingsDto, typeProvider: TypeProvider) {
  return removeDefaultValues(
    generalSettings,
    typeProvider.getType("GeneralSettingsDto"),
    typeProvider
  ) as GeneralSettingsDto;
}

function filterOutHistoryViewElements<T extends { id: EntityId }>(ent: T[]): T[] {
  return ent.filter((entity) => !entity.id.toString().includes(HISTORY_VIEW_CONSTANTS.ID_PREFIX));
}

// FIXME move to core
function entitiesArrayToDictionary<T extends Entity>(array: T[]): Dictionary<T> {
  const entitiesDictionary: Dictionary<T> = array.reduce((acc: Dictionary<T>, state: T) => {
    acc[state.id] = state;
    return acc;
  }, {} as Dictionary<T>);
  return entitiesDictionary;
}
