import { Injectable } from "@angular/core";
import { DataConnectorDto } from "../..";
import { getOrCreateEntityFilter } from "../../../core/helpers/filter/filter-id.helper";
import { IFilterSelector } from "../../../core/services/filter/i-filter.selector";
import { IEntityConverter } from "../../../meta/services/entity-converter";
import { isDefined } from "../../../ts-utils";
import { CriticalError } from "../../../ts-utils/models/critical-error";
import { getConnectorViewId } from "../../helpers/connector-view-id.helper";
import { DataConnectorViewModel } from "../../models/data-connector.vm";
import { IDataConnectorViewSelector } from "../i-data-connector-view.selector";
import { DataSourceDeserializer } from "./data-source.deserializer";

@Injectable()
export class DataConnectorViewModelDeserializer
  implements IEntityConverter<DataConnectorDto, DataConnectorViewModel>
{
  constructor(
    private dataConnectorViewSelector: IDataConnectorViewSelector,
    private dataSourceDeserializer: DataSourceDeserializer,
    private filterSelector: IFilterSelector
  ) {}

  convert(connectorDto: DataConnectorDto): DataConnectorViewModel {
    const connectorVM: DataConnectorViewModel = new DataConnectorViewModel(connectorDto);
    const connectorView = this.dataConnectorViewSelector.getById(
      getConnectorViewId(connectorDto.id)
    );
    if (isDefined(connectorView)) {
      connectorVM.view = connectorView;
    } else {
      throw new CriticalError("Connector view is not defined");
    }
    if (isDefined(connectorDto.dataSource)) {
      connectorVM.dataSource = this.dataSourceDeserializer.convert(connectorDto.dataSource);
    }
    connectorVM.filterConfig = getOrCreateEntityFilter(connectorVM.id, this.filterSelector);
    return connectorVM;
  }
}
