import { createAction, props } from "@ngrx/store";
import { PageWidth } from "../../../core/models/page-layout";

export namespace RuntimeSettingsActions {
  export const setPagePreviewWidth = createAction(
    "[Runtime Settings] Set Page Preview Width",
    (width: PageWidth) => {
      return { width };
    }
  );

  export const setPeriodType = createAction(
    "[Runtime Settings] Set Period Type",
    props<{ pType: string }>()
  );

  export const setCurrentRootPath = createAction(
    "[Runtime Settings] Set Current Root Path",
    props<{ currentRootPath: string }>()
  );

  export const setNoAnimation = createAction(
    "[Runtime Settings] Set NoAnimation",
    props<{ noAnimation: boolean }>()
  );
}
