import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { ReportDescription } from "../../../core/models/report-description";
import { LocalizationService } from "../../../i18n/localization.service";
import { BaseDialogComponent } from "../../../shared/components/base-dialog/base-dialog.component";
import { Maybe } from "../../../ts-utils/models/maybe.type";

@Component({
  selector: "report-browser-dialog",
  templateUrl: "./report-browser-dialog.component.html",
  styleUrls: ["./report-browser-dialog.component.scss"]
})
export class ReportBrowserDialogComponent extends BaseDialogComponent {
  refreshReports$: Subject<boolean> = new Subject<boolean>();
  shouldShowReportDescription: boolean = false;
  private reportLinkObject: Maybe<ReportDescription>;

  constructor(
    public dialogRef: MatDialogRef<ReportBrowserDialogComponent>,
    public localizer: LocalizationService
  ) {
    super();
  }

  createReportLink(reportLinkObject: ReportDescription): void {
    this.reportLinkObject = reportLinkObject;
  }

  confirm(): void {
    this.dialogRef.close(this.reportLinkObject);
  }

  close(): void {
    this.dialogRef.close();
  }

  showOrHideReportDescription(): void {
    this.shouldShowReportDescription = !this.shouldShowReportDescription;
  }
}
