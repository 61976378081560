import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AppStatusActions } from "projects/ui-core/src/lib/environment";
import { map } from "rxjs/operators";
import { HistoryViewService } from "../../../services/history-view.service";
import { HistoryViewDialogActions } from "../actions/history-view-dialog.actions";

@Injectable()
export class HistoryViewDialogEffects {
  constructor(private actions$: Actions, private historyViewService: HistoryViewService) {}

  openHistoryViewDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(HistoryViewDialogActions.openHistoryViewDialog),
      map(({ component }) => {
        this.historyViewService.expandedComponent = component;
        return AppStatusActions.toggleHistoryViewMode();
      })
    )
  );

  closeHistoryViewDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(HistoryViewDialogActions.closeHistoryViewDialog),
      map(({}) => {
        this.historyViewService.clearExpandedComponent();
        return AppStatusActions.toggleHistoryViewMode();
      })
    )
  );
}
