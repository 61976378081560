<select
  #editorInput
  class="abb-input editor--discreet-input"
  [class.editor--value-configured]="value !== propertyInfo.descriptor.defaultValue"
  (change)="selectValueChanged(editorInput.value)"
  [value]="value"
>
  <option
    *ngFor="let item of items"
    [value]="item.key"
    [selected]="isSelected(item)"
    [class.item--selected]="item.key === value"
  >
    {{ item.title | translate }}
  </option>
</select>
<span class="abb-input__icon abb-input__icon--pull-left abb-icon Down"></span>
