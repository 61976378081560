import { createAction, props } from "@ngrx/store";
import { IName } from "../../../core";

export namespace ProjectEnvironmentActions {
  export const updateEnvironmentName = createAction(
    "[Environment] Update Environment Name",
    props<{ environmentName: string }>()
  );

  export const setProjectName = createAction("[Environment] Set Project Name", props<IName>());

  export const setProductName = createAction("[Environment] Set Product Name", props<IName>());

  export const updateStandalone = createAction(
    "[Environment] Update Standalone",
    props<{ standalone: boolean }>()
  );

  export const setPushDataMode = createAction(
    "[Environment] Set Push Data Mode",
    props<{ pushModeActive: boolean }>()
  );
}
