import { Options } from "highcharts";
import { Maybe, first } from "../../../ts-utils";
import { isEmpty } from "../../../ts-utils/helpers/is-empty.helper";
import {
  getConfiguredOrForegroundColor,
  getNeutralColorForText,
  getTextColorForNoDataStatus
} from "../../helpers/color.helper";
import { getSingleValueFromConnector } from "../../helpers/component-data-accessor.helper";
import {
  DEFAULT_BULLET_PLOT_BAND_COLOR,
  DEFAULT_CHART_TEXT_COLOR_HEX
} from "../../models/colors.constants";
import { DataStatus } from "../../models/data-status";
import { IGaugeDisplayConfig } from "../../models/i-view-config/i-gauge-display-config";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import { PRIMARY_X_AXIS_ID, PRIMARY_Y_AXIS_ID } from "./base-highcharts-options.helper";
import { GaugeDisplayService } from "./gauge-display.service";

export class BulletChartDisplayService extends GaugeDisplayService {
  constructor() {
    super();
    this.chartType = "bullet";
    this.isTargetAsAxis = false;
  }

  protected getSpecificGaugeOptions(
    viewConfig: IGaugeDisplayConfig,
    dataConnectorDescriptors: Maybe<DataConnectorDescriptor>[],
    dataStatus: DataStatus
  ): Options {
    const value = getSingleValueFromConnector(first(dataConnectorDescriptors)?.connector);
    const component = this;
    const plotBandColor = getNeutralColorForText(
      isEmpty(viewConfig.neutralColor) ? DEFAULT_BULLET_PLOT_BAND_COLOR : viewConfig.neutralColor,
      null
    );
    return {
      chart: {
        inverted: true,
        events: {
          render: function () {
            const chart = this;
            chart.series.forEach(function (s) {
              s.points.forEach(function (point: Highcharts.Point) {
                // @ts-ignore
                if (point.dataLabel) {
                  component.setDataLabelColor(chart.plotWidth, point, viewConfig.primaryColor);
                }
              });
            });
          }
        }
      },
      title: {
        text: viewConfig.showTitle ? viewConfig?.title : ""
      },
      noData: {
        style: {
          color: getTextColorForNoDataStatus(dataStatus, DEFAULT_CHART_TEXT_COLOR_HEX)
        }
      },
      plotOptions: {
        bullet: {
          color: viewConfig.primaryColor,
          targetOptions: {
            width: "200%",
            color: viewConfig.limits.targetColor
          }
        },
        series: {
          dataLabels: {
            style: {
              color: getConfiguredOrForegroundColor(viewConfig.primaryColor, plotBandColor)
            }
          }
        }
      },
      xAxis: [
        {
          id: PRIMARY_X_AXIS_ID,
          labels: { enabled: false },
          tickLength: 0
        }
      ],
      yAxis: [
        {
          id: PRIMARY_Y_AXIS_ID,
          gridLineWidth: 0,
          title: { text: null },

          tickWidth: 1,
          tickLength: 7,
          tickColor: "#E6E6E6",
          startOnTick: false,
          endOnTick: false,
          labels: {
            enabled: viewConfig.showYAxisLabels
          },
          plotBands: this.getYAxisPlotBands(viewConfig)
        }
      ],
      series: [
        {
          id: "value_bullet",
          type: undefined,
          data:
            value != null
              ? [
                  {
                    y: value,
                    target: viewConfig.limits.target
                  }
                ]
              : []
        }
      ]
    };
  }

  private setDataLabelColor(
    plotBandWidth: number,
    point: Highcharts.Point,
    primaryColor: string
  ): void {
    // @ts-ignore
    const invertedBarWidth = Number(point.shapeArgs.height);
    // @ts-ignore
    const dataLabelWidth = Number(point.dataLabel.width);
    if (plotBandWidth - invertedBarWidth < dataLabelWidth) {
      // @ts-ignore
      point.dataLabel.css({
        color: getNeutralColorForText(primaryColor, null)
      });
    }
  }

  protected getDataLabelFormat(value: number, viewConfig: IGaugeDisplayConfig): string {
    return super.getDataLabelFormat(value, viewConfig).replace("<br />", "").replace("color", "");
  }
}
