import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { Configurable } from "../../../meta/decorators/configurable.decorator";
import { EditableType } from "../../../meta/decorators/editable-type.decorator";
import { Serializable } from "../../../meta/decorators/serializable.decorator";
import { EditorType } from "../../../meta/models/editor-type";
import {
  CustomFilterValueType,
  DEFAULT_TEXT_VALUE
} from "../../../shared/models/custom-filter-value-type";
import { DeepPartial } from "../../../ts-utils/models/deep-partial.type";
import { construct } from "../../services/construct.helper";
import { CustomFilterValueDescriptor } from "./custom-filter-value-descriptor";

@EditableType({ fullName: CustomFilterValueType.Text })
export class CustomFilterTextValueDescriptor extends CustomFilterValueDescriptor {
  typeName: CustomFilterValueType = CustomFilterValueType.Text;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Filter_InitialValue,
    editorType: EditorType.TextBox
  })
  @Serializable(DEFAULT_TEXT_VALUE)
  initialValue!: string;

  constructor(params: DeepPartial<CustomFilterTextValueDescriptor> = {}) {
    super();
    construct(this, params, CustomFilterValueType.Text);
  }
}
