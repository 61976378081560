/* eslint-disable @typescript-eslint/no-namespace */
import { Update } from "@ngrx/entity";
import { createAction, props } from "@ngrx/store";
import { ReportTag } from "../../../core";
import { QueryFilter } from "../../../core/models/filter/query-filter";
import { ConnectorsDictionaryIndexedById } from "../../../data-connectivity/models/connectors-dictionary-indexed-by-id";
import { DataConnectorDto } from "../../../data-connectivity/models/data-connector";
import { EntityId } from "../../../meta/models/entity";
import { DeepUpdate, Dictionary } from "../../../ts-utils";
import { DataStatus, ReportEntities } from "../../models";
import { ComponentStateDto } from "../../models/component-state";
import { ConnectorsReplaceInfo } from "../../models/store/connectors-replace-info";

export namespace DataConnectorActions {
  export const addOne = createAction(
    "[DataConnector] Add One",
    ({
      componentId,
      connector,
      groupId
    }: {
      componentId: EntityId;
      connector: DataConnectorDto;
      groupId?: string;
    }) => {
      if (componentId == null) {
        throw new Error("Undefined component ID");
      }
      return { componentId, connector, groupId };
    }
  );

  export const addMany = createAction(
    "[DataConnector] Add Many",
    ({ componentId, connectors }: { componentId: EntityId; connectors: DataConnectorDto[] }) => {
      if (componentId == null) {
        throw new Error("Undefined component ID");
      }
      return { componentId, connectors };
    }
  );

  export const deleteOne = createAction(
    "[DataConnector] Delete One",
    ({ componentId, connector }: { componentId: EntityId; connector: DataConnectorDto }) => {
      if (componentId == null) {
        throw new Error("Undefined component ID");
      }
      return { componentId, connector };
    }
  );

  export const deleteMany = createAction(
    "[DataConnector] Delete Many",
    props<{
      connectorsByComponent: Dictionary<DataConnectorDto[]>;
    }>()
  );

  export const updateOne = createAction(
    "[DataConnector] Update One",
    props<{ connectorUpdate: Update<DataConnectorDto> }>()
  );

  export const updateMany = createAction(
    "[DataConnector] Update Many",
    props<{ connectorUpdates: Update<DataConnectorDto>[] }>()
  );

  export const updateDataStatusMany = createAction(
    "[DataConnector] Update Data Status Many",
    props<{
      dataConnectorStatusDic: Dictionary<DataStatus>;
      dataConnectorQueryStatusDic: Dictionary<DataStatus>;
    }>()
  );

  export const replaceOne = createAction(
    "[DataConnector] Replace One",
    props<{
      componentId: EntityId;
      oldConnector: DataConnectorDto;
      newConnector: DataConnectorDto;
    }>()
  );

  export const replaceMany = createAction(
    "[DataConnector] Replace Many",
    props<{
      componentId: EntityId;
      connectorsReplaceInfo: ConnectorsReplaceInfo;
      groupId?: string;
    }>()
  );

  export const resolveEquipmentConnector = createAction(
    "[DataConnector] Resolve Equipment Connector",
    props<{
      componentId: EntityId;
      connector: DataConnectorDto;
      replace: boolean;
      groupId?: string;
    }>()
  );

  export const resolveUpdatedEquipmentConnector = createAction(
    "[DataConnector] Resolve Updated Equipment Connector",
    props<{ connector: DataConnectorDto }>()
  );

  export const resolveEquipmentQuery = createAction(
    "[DataConnector] Resolve Equipment Query",
    props<{ component: ComponentStateDto; groupId?: string }>()
  );

  export const setContext = createAction(
    "[DataConnector] Set Context",
    props<{
      componentId: EntityId;
      connector: DataConnectorDto;
      replace: boolean;
      groupId?: string;
    }>()
  );

  export const setContextMany = createAction(
    "[DataConnector] Set Context Many",
    props<{ componentId: EntityId; connectors: DataConnectorDto[] }>()
  );

  export const setContextManyDynamic = createAction(
    "[DataConnector] Set Context Many Dynamic",
    props<{ componentId: EntityId; connectors: DataConnectorDto[]; groupId?: string }>()
  );

  export const setContextOnLoad = createAction(
    "[DataConnector] Set Context On Load",
    props<{ reportTag: ReportTag; reportEntities: ReportEntities }>()
  );

  export const addOrReplaceData = createAction(
    "[DataConnector] Add or Replace Data",
    props<{
      connectorDict: ConnectorsDictionaryIndexedById;
    }>()
  );

  export const updateData = createAction(
    "[DataConnector] Update Data",
    props<{
      connectorDict: ConnectorsDictionaryIndexedById;
      startCutoffDate?: Date;
    }>()
  );

  export const clearDataPoints = createAction(
    "[DataConnector] Clear Data Points",
    props<{ connectorIds: EntityId[] }>()
  );

  export const resolveFullGenericQueries = createAction(
    "[DataConnector] Resolve Full Generic Queries",
    props<{ queryFilter: QueryFilter; componentsWithQuery: ComponentStateDto[] }>()
  );

  export const replaceGenericConnectors = createAction(
    "[DataConnector] Replace Generic Connectors",
    props<{ connectorReplacementDict: Dictionary<ConnectorsReplaceInfo> }>()
  );

  export const resolveIncrementalGenericQueries = createAction(
    "[DataConnector] Resolve Incremental Generic Queries",
    props<{ queryFilter: QueryFilter; componentsWithQuery: ComponentStateDto[] }>()
  );

  export const updateAllEquipmentSources = createAction(
    "[DataConnector] Update All Equipment Sources",
    props<{
      connectorReplacementDict: Dictionary<ConnectorsReplaceInfo>;
      equipmentConnectorUpdates: Update<DataConnectorDto>[];
    }>()
  );

  export const updateRootPath = createAction(
    "[DataConnector] Update root path",
    props<{
      rootPath: string;
      resolvedEquipmentQueries: Dictionary<DataConnectorDto[]>;
      resolvedEquipmentConnectors: DataConnectorDto[];
    }>()
  );

  export const resolveDraggedEquipmentToGroup = createAction(
    "[Data Connector] Resolve Dragged Equipment To Group",
    props<{ componentUpdate: DeepUpdate<ComponentStateDto>; groupId: string }>()
  );

  export const appendAdditionalDataPoints = createAction(
    "[DataConnector] Append Additional Data Points",
    props<{
      connectorDict: ConnectorsDictionaryIndexedById;
    }>()
  );
}
