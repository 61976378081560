import { Injectable } from "@angular/core";
import { EntityId } from "../../meta/models/entity";
import { isEmptyOrNotDefined } from "../../ts-utils";
import { isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class RelocatePeriodType implements UpgradeStep {
  name = "RelocatePeriodType";
  fromVersion = new Version(1, 0, 5);

  perform(oldConfig: any): UpgradeStepResult {
    const filterEntities = oldConfig["filters"]?.["entities"];
    const componentStates = oldConfig["componentStates"]?.["entities"];
    const connectorEntites = oldConfig["dataConnectors"]?.["entities"];
    let modified = false;
    modified = movePTypeFromFiltersToComponents(filterEntities, componentStates);
    modified = movePTypeFromConnectorDataSourcesToAggregationConfig(connectorEntites);

    return {
      result: oldConfig,
      modified,
      warning: null
    };
  }
}

function movePTypeFromFiltersToComponents(filterEntities: any, componentStates: any): boolean {
  if (isNotDefined(filterEntities) || isNotDefined(componentStates)) {
    return false;
  }
  const filtersWithPTypes: any[] = findFiltersWithDefinedPeriodType(Object.values(filterEntities));
  let modified = false;
  filtersWithPTypes.forEach((filter) => {
    const parentComponentId = legacy_getComponentIdByFilterId(filter["id"]);
    if (isNotDefined(parentComponentId)) {
      return;
    }
    const filterParentComponent = componentStates[parentComponentId];
    if (isNotDefined(filterParentComponent)) {
      return;
    }
    movePTypeFromFilterToComponentDCQ(filter, filterParentComponent);
    modified = true;
  });
  return modified;
}

export function legacy_getComponentIdByFilterId(filterId: EntityId): EntityId | null {
  const RUNTIME_FILTER_ID: string = "Runtime";
  const REPORT_FILTER_ID: string = "Global";
  const FILTER_ID_SUFFIX = "-filter";
  if (filterId == null || filterId === RUNTIME_FILTER_ID || filterId === REPORT_FILTER_ID) {
    return null;
  }
  return filterId.toString().slice(0, filterId.toString().length - FILTER_ID_SUFFIX.length);
}

function findFiltersWithDefinedPeriodType(filters: any[]): any[] {
  return filters.filter((filter) => !isEmptyOrNotDefined(filter["periodType"]));
}

function movePTypeFromFilterToComponentDCQ(filter: any, filterParentComponent: any): void {
  if (isNotDefined(filterParentComponent["dataConnectorQuery"])) {
    filterParentComponent["dataConnectorQuery"] = {};
  }
  if (isNotDefined(filterParentComponent["dataConnectorQuery"]["aggregationConfig"])) {
    filterParentComponent["dataConnectorQuery"]["aggregationConfig"] = {};
  }
  filterParentComponent["dataConnectorQuery"]["aggregationConfig"]["periodType"] =
    filter["periodType"];
  delete filter["periodType"];
  // TODO: delete filters which stay empty after this change
}

function movePTypeFromConnectorDataSourcesToAggregationConfig(connectorEntites: any): boolean {
  if (isNotDefined(connectorEntites)) {
    return false;
  }
  const connectorsWithPtype = findConnectorsWithDefinedPeriodType(Object.values(connectorEntites));
  let modified = false;
  connectorsWithPtype.forEach((connector) => {
    if (isNotDefined(connector["dataSource"]["aggregationConfig"])) {
      connector["dataSource"]["aggregationConfig"] = {};
    }
    connector["dataSource"]["aggregationConfig"]["periodType"] = connector["dataSource"]["pType"];
    delete connector["dataSource"]["pType"];
    modified = true;
  });
  return modified;
}

function findConnectorsWithDefinedPeriodType(connectors: any[]): any[] {
  return connectors.filter(
    (connector) =>
      isDefined(connector["dataSource"]) && !isEmptyOrNotDefined(connector["dataSource"]["pType"])
  );
}
