import { Maybe } from "../../../ts-utils/models/maybe.type";
import { FunctionWithParams } from "../function-with-params";
import { ButtonStyle } from "./button-style";

// @dynamic
export class ButtonConfig {
  public clickFunction: FunctionWithParams<any>;
  public style: ButtonStyle;
  public dropdownOptions: ButtonConfig[];
  public shouldShow: () => boolean;
  public pageUrl: Maybe<string>;

  constructor(params: Partial<ButtonConfig> = {}) {
    this.clickFunction = params.clickFunction ?? new FunctionWithParams(() => {}, null);
    this.style = params.style ?? new ButtonStyle();
    this.dropdownOptions = params.dropdownOptions ?? [];
    this.shouldShow = params.shouldShow ?? (() => true);
    this.pageUrl = params.pageUrl ?? null;
  }
}
