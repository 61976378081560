import { SimpleChanges } from "@angular/core";
import * as Highcharts from "highcharts";
import { ChartSelectionContextObject, Options } from "highcharts";
import { TimeRange } from "../../../core/models/time-range";
import { MIN_TO_MS } from "../../../core/models/time.constants";
import { formatValueByStringFormat } from "../../../core/services/value-formatter.service";
import {
  AnalyticTypes,
  DataConnectorDto,
  DataPoint,
  DataPointDto,
  ICommonDataProperties,
  TimeSeriesDataPoint
} from "../../../data-connectivity";
import {
  SERIES_TYPE_DEFAULT,
  SERIES_TYPE_SCATTER
} from "../../../data-connectivity/models/series-type.strategies";
import { AppSettingsService } from "../../../environment/services/app-settings.service";
import { ColorListService } from "../../../environment/services/color-list.service";
import { DateFormatterService } from "../../../environment/services/date-formatter.service";
import { getEntityTitle } from "../../../meta/helpers/get-title.helper";
import {
  assertIsDefined,
  isDefined,
  isEmptyOrNotDefined,
  isNotDefined,
  isNumber,
  toStringOrUndefined
} from "../../../ts-utils";
import { getNumber, tryConvertToNumber } from "../../../ts-utils/helpers/number.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import {
  MinMaxAggregator,
  prepareTrendSeries,
  simpleLinearRegression
} from "../../helpers/regression-line.helper";
import { ComponentUserEventCallbacks } from "../../models/component-event-data";
import { DataStatus } from "../../models/data-status";
import { ITimeSeriesChartDisplayConfig } from "../../models/i-view-config/i-base-display-config";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import * as TimeSeriesChartHelper from "../time-series-chart-helper";
import { TooltipData, getTooltipText } from "../tooltip.helper";
import {
  PRIMARY_X_AXIS_ID,
  PRIMARY_Y_AXIS_ID,
  Y_AXIS_PREFIX,
  getCommonGridLineColor,
  getCommonTextColor,
  getSeriesAxisOptions
} from "./base-highcharts-options.helper";
import { PlotBandGenerator } from "./plot-band-generator.service";
import { PlotLineGenerator, PlotLineGeneratorTimeSeries } from "./plot-line-generator.service";
import {
  areConnectorsReordered,
  chartSizeChanged,
  switchedOnOff,
  xOr
} from "./requires-full-redraw.helper";
import { hideSeries, makeSeriesInvisible, showSeries } from "./series-visibility.helper";
import { createLimitSeries } from "./time-series-display-limits.helper";

type TrendFixupFunc = (xRange: number[]) => void;

type Analytics = {
  forecasts: Maybe<DataPointDto[][]>;
  anomalies: Maybe<DataPointDto[][]>;
};

export class TimeSeriesDisplayService {
  private plotLineGenerator: PlotLineGenerator = new PlotLineGeneratorTimeSeries();
  private plotBandGenerator = new PlotBandGenerator();
  private invisibleSeriesIds: Set<string> = new Set<string>();

  constructor(
    private chartType: string,
    private isContinuous: boolean,
    protected dateFormatter: DateFormatterService,
    private appSettings: AppSettingsService,
    private colorService: ColorListService
  ) {}

  requiresFullRedraw(
    previousViewConfig: ITimeSeriesChartDisplayConfig,
    currentViewConfig: ITimeSeriesChartDisplayConfig,
    changes: SimpleChanges
  ): boolean {
    const didChartSizeChange = chartSizeChanged(previousViewConfig, currentViewConfig);
    const previousMin = tryConvertToNumber(previousViewConfig.min);
    const previousMax = tryConvertToNumber(previousViewConfig.max);
    const currentMin = tryConvertToNumber(currentViewConfig.min);
    const currentMax = tryConvertToNumber(currentViewConfig.max);
    return (
      didChartSizeChange ||
      xOr(previousViewConfig.stacked, currentViewConfig.stacked) ||
      xOr(previousViewConfig.showTrendLine, currentViewConfig.showTrendLine) ||
      switchedOnOff(previousMax, currentMax) ||
      switchedOnOff(previousMin, currentMin) ||
      (isDefined(changes["values"]) &&
        (changes["values"].previousValue.length > changes["values"].currentValue.length ||
          areConnectorsReordered(changes["values"].previousValue, changes["values"].currentValue)))
    );
  }

  getChartOptions(
    viewConfig: ITimeSeriesChartDisplayConfig,
    xRange: Partial<TimeRange>,
    valueConnectors: DataConnectorDescriptor[],
    dataStatus: DataStatus,
    callbacks: ComponentUserEventCallbacks
  ): Options {
    const seriesType = this.chartType;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const component = this;
    const opt: Options = {
      time: {
        useUTC: false
      },
      chart: {
        type: seriesType,
        zooming: { type: "x" },
        events: {
          selection: (event) => {
            if (this.shouldZoom(event)) {
              callbacks.zoom(event);
              return true;
            } else {
              return false;
            }
          }
        }
      },
      tooltip: {
        headerFormat: "",
        pointFormatter: function () {
          return component.getChartTooltip(this, valueConnectors, viewConfig);
        },
        backgroundColor: "rgb(255, 255, 255, 1)"
      },
      xAxis: [
        {
          id: PRIMARY_X_AXIS_ID,
          type: "datetime",
          title: {
            text: viewConfig.xAxisTitle
          },
          min: xRange.from?.getTime(),
          max: xRange.to?.getTime(),
          labels: {
            enabled: this.shouldEnableLabels(dataStatus)
          }
        }
      ],
      yAxis: getYAxes(viewConfig, valueConnectors),
      legend: {
        enabled: viewConfig.showLegend,
        maxHeight: 100
      },
      plotOptions: {
        column: {
          groupPadding: 0.1,
          pointPadding: 0.1,
          dataLabels: {
            enabled: viewConfig.showColumnDataLabels,
            crop: viewConfig.stacked,
            overflow: viewConfig.stacked ? "justify" : "allow",
            formatter: function () {
              return formatNumber(this.y, viewConfig);
            },
            y: -5
          }
        },
        bar: {
          groupPadding: 0.1,
          pointPadding: 0.1
        },
        series: {
          events: {
            hide: function (this: Highcharts.Series) {
              hideSeries(this.options.id, component.invisibleSeriesIds);
            },
            show: function (this: Highcharts.Series) {
              showSeries(this.options.id, component.invisibleSeriesIds);
            }
          }
        }
      }
    };

    this.plotLineGenerator.addPlotLines(opt, valueConnectors, viewConfig.yAxes);
    this.plotBandGenerator.addPlotBands(opt, valueConnectors);

    const trendFixups: TrendFixupFunc[] = [];
    const minMaxAggregator = new MinMaxAggregator();

    const series: Highcharts.SeriesOptionsType[] = valueConnectors
      .filter(
        (dcd) => !this.plotLineGenerator.isPlotLine(dcd) && !this.plotBandGenerator.isBand(dcd)
      )
      .reduce((acc, connectorDesc, index): Highcharts.SeriesOptionsType[] => {
        const dataPoints = this.getDataPoints(
          connectorDesc.connector as DataConnectorDto,
          this.isContinuous
        );
        const master = this.createMasterSeries(
          connectorDesc,
          index,
          dataPoints,
          viewConfig,
          xRange,
          minMaxAggregator
        );
        acc.push(master);

        acc.push(...this.createAnalyticsSeries(master, connectorDesc, minMaxAggregator));

        if (viewConfig.showTrendLine && dataPoints.length > 0) {
          acc.push(this.createRegressionSeries(master, trendFixups));
        }

        // remove condition once legend is implemented with radio-buttons
        if (index === valueConnectors.length - 1) {
          acc.push(...createLimitSeries(dataPoints, viewConfig));
        }
        return acc;
      }, [] as Highcharts.SeriesOptionsType[]);

    trendFixups.forEach((fixup) => fixup(minMaxAggregator.range));
    makeSeriesInvisible(series, this.invisibleSeriesIds);
    opt.series = series;
    return opt;
  }

  private shouldZoom(event: ChartSelectionContextObject): boolean {
    const { min, max } = event.xAxis[0];
    return max - min > MIN_TO_MS;
  }

  private createRegressionSeries(
    masterSeries: Highcharts.SeriesLineOptions,
    trendFixups: TrendFixupFunc[]
  ): Highcharts.SeriesOptionsType {
    const trendSeries = prepareTrendSeries(masterSeries);
    const originalPoints = masterSeries.data as [number, Maybe<number>][];
    const filteredPoints = originalPoints.filter(isDefinedDatapoint);
    const regression = simpleLinearRegression(filteredPoints);
    trendFixups.push((xRange: number[]) => {
      trendSeries.data = xRange.map((x) => [x, regression(x)]);
    });
    return trendSeries;
  }

  private shouldEnableLabels(dataStatus: DataStatus): boolean {
    return (
      dataStatus === DataStatus.DataReceived || dataStatus === DataStatus.WaitingForMorePreciseData
    );
  }

  private createMasterSeries(
    connectorDesc: DataConnectorDescriptor,
    seriesIndex: number,
    dataPoints: DataPoint[],
    viewConfig: ITimeSeriesChartDisplayConfig,
    xRange: Partial<TimeRange>,
    minMaxAggregator: MinMaxAggregator
  ): Highcharts.SeriesLineOptions {
    const timeSeriesConfig = connectorDesc.connectorView?.timeSeriesConfig;
    const connector = connectorDesc.connector;
    assertIsDefined(connector);

    const areUnconnectedPoints = timeSeriesConfig?.seriesType === SERIES_TYPE_SCATTER;
    const highchartsSeriesType =
      timeSeriesConfig?.seriesType !== SERIES_TYPE_DEFAULT
        ? timeSeriesConfig?.seriesType?.toLowerCase()
        : undefined;
    const markerSymbol = timeSeriesConfig?.markerStyle?.toLowerCase()?.replace(/_/g, "-");
    const connectorViewColor = connectorDesc.connectorView?.color;
    const data = toHighChartPoints(dataPoints);
    minMaxAggregator.process(data);

    return {
      name: getEntityTitle(connector),
      type: highchartsSeriesType,
      stacking: viewConfig.stacked ? "normal" : false,
      data: data,
      id: connector.id,
      color: !isEmptyOrNotDefined(connectorViewColor)
        ? connectorViewColor
        : this.colorService.getSeriesColorAtIndex(seriesIndex),
      dashStyle: timeSeriesConfig?.lineStyle,
      lineWidth: areUnconnectedPoints ? 0 : timeSeriesConfig?.lineWidth,
      marker: {
        enabled: areUnconnectedPoints ? true : timeSeriesConfig?.showMarker,
        radius: timeSeriesConfig?.markerRadius,
        symbol: markerSymbol
      },
      pointRange: getPointRange(xRange, connector.numberOfRequestedDataPoints),
      ...getSeriesAxisOptions(connectorDesc, viewConfig),
      zIndex: 0
    } as Highcharts.SeriesLineOptions;
  }

  private createAnalyticsSeries(
    masterSeries: Highcharts.SeriesLineOptions,
    connectorDesc: DataConnectorDescriptor,
    minMaxAggregator: MinMaxAggregator
  ): Highcharts.SeriesLineOptions[] {
    const res: Highcharts.SeriesLineOptions[] = [];
    const { forecasts, anomalies } = this.extractAnalytics(connectorDesc.connector);
    if (forecasts) {
      forecasts.forEach((fc: DataPoint[], idx: number) => {
        const series = this.createOneAnalyticSeries(masterSeries, fc, "Forecast", minMaxAggregator);
        res.push(series);
      });
    }
    if (anomalies) {
      anomalies.forEach((an: DataPoint[], idx: number) => {
        const series = this.createOneAnalyticSeries(
          masterSeries,
          an,
          `Anomaly ${++idx}`,
          minMaxAggregator,
          {
            marker: {
              symbol: "circle",
              radius: 2.5
            },
            dashStyle: "Solid",
            color: "#F94449"
          }
        );

        res.push(series);
      });
    }
    return res;
  }

  private createOneAnalyticSeries(
    master: Highcharts.SeriesLineOptions,
    dataPoints: DataPoint[],
    type: string,
    minMaxAggregator: MinMaxAggregator,
    customOptions?: Partial<Highcharts.SeriesLineOptions>
  ): Highcharts.SeriesLineOptions {
    const data = toHighChartPoints(dataPoints);
    minMaxAggregator.process(data);
    const series = {
      name: `[${type}] ${master.name}`,
      data: data,
      id: `${type}-${master.id}`,
      dashStyle: "Dot",
      color: master.color,
      yAxis: master.yAxis,
      type: undefined as unknown,
      custom: {
        nature: type
      },
      zIndex: 1,
      ...customOptions
    } as Highcharts.SeriesLineOptions;

    return series;
  }

  private extractAnalytics(connector: Maybe<DataConnectorDto>): Analytics {
    if (!connector || !connector.analytics) {
      return {
        forecasts: [],
        anomalies: []
      };
    } else {
      return {
        forecasts: connector.analytics[AnalyticTypes.Forecast],
        anomalies: connector.analytics[AnalyticTypes.Anomaly]
      };
    }
  }

  private getDataPoints(connector: DataConnectorDto, isContinuousDisplay: boolean): DataPoint[] {
    if (connector != null && connector.dataPoints != null) {
      let data = connector.dataPoints as TimeSeriesDataPoint[];
      if (isContinuousDisplay) {
        data = TimeSeriesChartHelper.padGaps(data, this.appSettings.Settings.useStartTime);
      }
      return data;
    } else {
      return [];
    }
  }

  private getChartTooltip(
    chartPoint: Highcharts.Point,
    connectorDescriptors: DataConnectorDescriptor[],
    viewConfig: ITimeSeriesChartDisplayConfig
  ): string {
    const seriesName: string = chartPoint.series.name;
    const options = chartPoint.series.options as any;
    const seriesNature: string = options.custom != null ? options.custom.nature : null;
    const dataConnector: Maybe<DataConnectorDto> = connectorDescriptors.find(
      (descriptor) =>
        isDefined(descriptor.connector) && getEntityTitle(descriptor.connector) === seriesName
    )?.connector;
    const description: string =
      seriesNature != null
        ? seriesNature
        : dataConnector != null
        ? (dataConnector.properties as ICommonDataProperties).description ?? ""
        : "";

    const tooltipData: TooltipData = {
      title: seriesName,
      description,
      additionalInfo: this.dateFormatter.formatDate(new Date(chartPoint.x)),
      value: isNumber(chartPoint.y) ? `${chartPoint.y}` : "",
      unit: dataConnector ? toStringOrUndefined(dataConnector.properties.unit) : "",
      axisName: chartPoint.series.yAxis.options.title?.text ?? undefined
    };
    return getTooltipText(tooltipData, viewConfig.displayFormat);
  }
}

export function toHighChartPoints(points: DataPoint[]): [number, Maybe<number>][] {
  return points.map((dataPoint: DataPoint) => [
    (dataPoint.x as Date).getTime(),
    getNumber(dataPoint.evaluatedValue ?? dataPoint.y)
  ]);
}

function getPointRange(xRange: Partial<TimeRange>, numberOfDataPoints: number): Maybe<number> {
  if (isNotDefined(xRange.from) || isNotDefined(xRange.to)) {
    return undefined;
  }
  const timeRangeInMs = xRange.to.getTime() - xRange.from.getTime();
  return timeRangeInMs / numberOfDataPoints;
}

function formatNumber(value: any, viewConfig: ITimeSeriesChartDisplayConfig): string {
  return formatValueByStringFormat(value, viewConfig.displayFormat) ?? "";
}

function getYAxes(
  displayConfig: ITimeSeriesChartDisplayConfig,
  dataConnectorDescriptors: DataConnectorDescriptor[]
): Highcharts.YAxisOptions[] {
  const textColor: string = getCommonTextColor(displayConfig.foregroundColor);
  const gridLineColor = getCommonGridLineColor(displayConfig.foregroundColor);
  const shouldIgnoreDynamicRange: boolean =
    dataConnectorDescriptors.filter(
      (connectorDescriptor: DataConnectorDescriptor) => !connectorDescriptor.connectorView?.axisId
    ).length > 1 || displayConfig.stacked;

  return displayConfig.yAxes.map<Highcharts.YAxisOptions>((axisConfig, index) => ({
    id: index === 0 ? PRIMARY_Y_AXIS_ID : Y_AXIS_PREFIX + index.toString(),
    title: {
      text: axisConfig.axisTitle,
      style: { color: axisConfig.color ?? textColor }
    },
    labels: {
      formatter: function () {
        return formatNumber(this.value, displayConfig);
      },
      style: { color: axisConfig.color ?? textColor }
    },
    min:
      tryConvertToNumber(axisConfig.min) ??
      (index === 0 && !shouldIgnoreDynamicRange
        ? tryConvertToNumber(displayConfig.min)
        : undefined),
    max:
      tryConvertToNumber(axisConfig.max) ??
      (index === 0 && !shouldIgnoreDynamicRange
        ? tryConvertToNumber(displayConfig.max)
        : undefined),
    endOnTick: false,
    startOnTick: false,
    opposite: index % 2 === 1,
    visible: !axisConfig.isHidden,
    lineColor: gridLineColor,
    tickColor: gridLineColor,
    gridLineColor
  }));
}

function isDefinedDatapoint(dp: [number, Maybe<number>]): dp is [number, number] {
  return isDefined(dp[1]);
}
