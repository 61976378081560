import { Nominal } from "projects/ui-core/src/lib/ts-utils/models/nominal.type";
import { MotorModel } from "./motor.model";

export class MotorMarker {
  motors: MotorModel[];
  iconUrl: string;
  description: string;
  latitude: number;
  longitude: number;
  motorsByCustomer: Map<string, MotorModel[]>;
  totalMotorsCount: number;

  productionArea?: string;
  systemOverviewReport?: Nominal<string | number, "ReportId">;
  projectOverviewReport?: string;
  markerLabel?: google.maps.MarkerLabel;
}
