import { Injectable } from "@angular/core";
import { ComponentStateDto } from "../models";
import { ComponentDataAccessor } from "./component-data-accessor";
import { DataConnectorViewSelector } from "./entity-selectors/data-connector-view.selector";
import { DataConnectorSelector } from "./entity-selectors/data-connector.selector";
import { IComponentDataAccessor } from "./i-component-data-accessor";

@Injectable()
export class ComponentDataAccessFactory {
  constructor(
    private dataConnectorSelector: DataConnectorSelector,
    private dataConnectorViewSelector: DataConnectorViewSelector
  ) {}

  createAccessor(component: ComponentStateDto): IComponentDataAccessor {
    return new ComponentDataAccessor(
      component,
      this.dataConnectorSelector,
      this.dataConnectorViewSelector
    );
  }
}
