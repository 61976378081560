// NOTE when sent to trend, make sure that number is actually sent
export enum KmGridLineStyle { // FIXME 4 why is this in elements models?
  Solid = 0,
  Step = 3,
  Dash = 4,
  Dot = 5
}

export enum KmGridInterval {
  auto,
  a50,
  a100,
  a150,
  a200,
  a250
}
