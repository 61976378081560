import "reflect-metadata";
import { DEFAULT_WIDTH_RATIO, GroupPropertiesInfo } from "../models/groupable-properties";
import { PropertyDescriptor } from "../models/property-descriptor";
import { TypeProvider } from "../services/type-provider";

//adds one property to the corresponding group

const TYPE_PROVIDER = TypeProvider.getInstance();
export function GroupIndividualProperties(groupInfo: GroupPropertiesInfo) {
  return function (proto: Object, key: string) {
    const partialPropertyDescriptor: Partial<PropertyDescriptor> = {
      groupInfo: {
        groupId: groupInfo.groupId,
        subgroupIndex: groupInfo.subgroupIndex,
        subgroupWidthRatio: groupInfo.subgroupWidthRatio ?? DEFAULT_WIDTH_RATIO
      }
    };
    TYPE_PROVIDER.upsertProperty(proto, key, partialPropertyDescriptor);
  };
}
