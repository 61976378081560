import { Injectable } from "@angular/core";
import { LimitsDto } from "../../elements/models/limits";
import { isDefined, isNotDefined } from "../../ts-utils";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class RelocateLimits implements UpgradeStep {
  name = "RelocateLimits";
  fromVersion = new Version(1, 0, 10);

  perform(oldConfig: any): UpgradeStepResult {
    const componentStates = oldConfig?.["componentStates"]?.["entities"];
    if (isNotDefined(componentStates)) {
      return {
        result: oldConfig,
        modified: false,
        warning: null
      };
    }
    let changed = false;
    Object.values(componentStates)
      .map((componentState: any) => componentState.view)
      .map((view: any) => {
        changed = true;
        if (!isDefined(view.limits)) {
          view.limits = {};
        }
        migrateLimits(view, view.limits as LimitsDto);
      });
    return {
      result: oldConfig,
      modified: changed,
      warning: null
    };
  }
}

function migrateLimits(oldView: any, newLimitsDto: LimitsDto): any {
  const keys: (keyof LimitsDto)[] = [
    "extremeHigh",
    "extremeHighColor",
    "extremeLow",
    "extremeLowColor",
    "high",
    "highColor",
    "low",
    "lowColor",
    "target",
    "targetColor",
    "veryHigh",
    "veryHighColor",
    "veryLow",
    "veryLowColor"
  ];

  keys.forEach((k) => migrateLimitsProperty(oldView, newLimitsDto, k));
}

function migrateLimitsProperty(
  oldView: any,
  newLimitsDto: LimitsDto,
  property: keyof LimitsDto
): any {
  if (isDefined(oldView[property])) {
    (newLimitsDto[property] as any) = oldView[property];
  }
}
