import { Maybe } from "../../ts-utils/models/maybe.type";

const INPUT_TYPE_TEXT = "text";
const INPUT_TYPE_NUMBER = "number";

export function focusItemInput(element: Maybe<HTMLInputElement>): void {
  element?.focus();
  trySetCursorAtTheEnd(element);
}

function trySetCursorAtTheEnd(element: Maybe<HTMLInputElement>): void {
  const type: Maybe<string> = element?.type;
  if (!shouldSetCursorAtTheEnd(type)) {
    return;
  }
  const shouldRevert: boolean = type === INPUT_TYPE_NUMBER;
  if (shouldRevert) {
    element.type = INPUT_TYPE_TEXT;
  }
  const length = element.value.length;
  element.setSelectionRange(length, length);
  if (shouldRevert) {
    element.type = INPUT_TYPE_NUMBER;
  }
}

function shouldSetCursorAtTheEnd(elementType: Maybe<string>): boolean {
  return elementType === INPUT_TYPE_TEXT || elementType === INPUT_TYPE_NUMBER;
}
