import { createSelector } from "@ngrx/store";
import { selectConnectivityFeature } from "../feature.selector";
import { ConnectivityState } from "../state";

export const selectAllGenericDatasourceDescriptors = createSelector(
  selectConnectivityFeature,
  (connectivity: ConnectivityState) => connectivity.allGenericDatasourceDescriptors
);

export const selectAllGenericDatasourceNames = createSelector(
  selectConnectivityFeature,
  (connectivity: ConnectivityState) =>
    connectivity.allGenericDatasourceDescriptors != null
      ? connectivity.allGenericDatasourceDescriptors.map((desc) => desc.entity)
      : []
);
