import { Directive, OnInit } from "@angular/core";
import { getPositionFromPointerEvent, PositionDto } from "../../core/models/position";
import { Dictionary } from "../../ts-utils/models/dictionary.type";
import { CSS_COMPONENT_SELECTED } from "../components/base/base.component";
import { RectangleDto } from "../models/resize/rectangle";
import { DomMapper } from "../services/dom-mapper.service";
import { fromPx } from "../services/drop.helper";
import { calcNewRectFactory, CalcNewRectFunction } from "../services/resize-helper";

type OnResizeEndCallback = (resizedComponentsRects: Dictionary<RectangleDto>) => void;

export const RESIZE_ELEMENT_WIDTH = 10;

@Directive({
  selector: "[resizable-component]"
})
export class ResizableComponentDirective implements OnInit {
  private resizeElements: HTMLElement[];
  public resizableComponent: HTMLElement;
  public onResizeEndCallback: OnResizeEndCallback;

  constructor() {}

  ngOnInit(): void {
    this.initResizeElements(this.resizableComponent, this.onResizeEndCallback);
    this.disableResizeElements();
  }

  private initResizeElements(
    resizableComponent: HTMLElement,
    onResizeEndCallback: OnResizeEndCallback
  ): void {
    this.resizeElements = createResizeElements(resizableComponent, onResizeEndCallback);
    this.resizeElements.forEach((resizeElement: HTMLElement) =>
      resizableComponent.appendChild(resizeElement)
    );
  }

  public disableResizeElements(): void {
    this.resizeElements.forEach(hideElement);
  }

  public enableResizeElements(): void {
    this.resizeElements.forEach(showElement);
  }
}

function createResizeElements(
  resizableComponent: HTMLElement,
  onResizeEndCallback: OnResizeEndCallback
): HTMLDivElement[] {
  const SIDE_ELEMENT = "resizable-component__side";
  const CORNER_ELEMENT = "resizable-component__corner";

  const SPECIFIC_SIDE_NAME = {
    topSide: "top-side",
    bottomSide: "bottom-side",
    leftSide: "left-side",
    rightSide: "right-side"
  };

  const SPECIFIC_CORNER_NAME = {
    topLeft: "top-left",
    topRight: "top-right",
    bottomLeft: "bottom-left",
    bottomRight: "bottom-right"
  };

  const resizeElementTypeFactory = createResizeElementTypeFactory(
    resizableComponent,
    onResizeEndCallback
  );
  const createSideElement = resizeElementTypeFactory(SIDE_ELEMENT);
  const topSide = createSideElement(
    SPECIFIC_SIDE_NAME.topSide,
    calcNewRectFactory(true, false, false, false)
  );
  const bottomSide = createSideElement(
    SPECIFIC_SIDE_NAME.bottomSide,
    calcNewRectFactory(false, false, true, false)
  );
  const leftSide = createSideElement(
    SPECIFIC_SIDE_NAME.leftSide,
    calcNewRectFactory(false, false, false, true)
  );
  const rightSide = createSideElement(
    SPECIFIC_SIDE_NAME.rightSide,
    calcNewRectFactory(false, true, false, false)
  );
  const createCornerElement = resizeElementTypeFactory(CORNER_ELEMENT);
  const topLeft = createCornerElement(
    SPECIFIC_CORNER_NAME.topLeft,
    calcNewRectFactory(true, false, false, true)
  );
  const topRight = createCornerElement(
    SPECIFIC_CORNER_NAME.topRight,
    calcNewRectFactory(true, true, false, false)
  );
  const bottomLeft = createCornerElement(
    SPECIFIC_CORNER_NAME.bottomLeft,
    calcNewRectFactory(false, false, true, true)
  );
  const bottomRight = createCornerElement(
    SPECIFIC_CORNER_NAME.bottomRight,
    calcNewRectFactory(false, true, true, false)
  );
  const resizeElements = [
    topSide,
    bottomSide,
    leftSide,
    rightSide,
    topLeft,
    topRight,
    bottomLeft,
    bottomRight
  ];
  return resizeElements;
}

function createResizeElementTypeFactory(
  resizableComponent: HTMLElement,
  onResizeEndCallback: OnResizeEndCallback
) {
  return (resizeElementTypeCssClass: string) => {
    return (specificElementClassName: string, calcNewRectFn: CalcNewRectFunction) => {
      const RESIZABLE_COMPONENT_CSS_CLASS_NAME = "resizable-component";
      const resizeElement: HTMLDivElement = document.createElement("div");
      resizeElement.draggable = false;
      resizeElement.classList.add(
        RESIZABLE_COMPONENT_CSS_CLASS_NAME,
        resizeElementTypeCssClass,
        specificElementClassName
      );

      resizeElement.addEventListener(
        "pointerdown",
        startResize(resizableComponent, calcNewRectFn, onResizeEndCallback)
      );

      return resizeElement;
    };
  };
}

function startResize(
  resizableComponent: HTMLElement,
  calcNewRectFn: CalcNewRectFunction,
  onResizeEndCallback: OnResizeEndCallback
) {
  return (event: PointerEvent) => {
    event.stopPropagation();
    const rectsBeforeResize: RectangleDto[] = [];
    const resizableElements: HTMLElement[] = [];
    resizableComponent.parentElement
      ?.querySelectorAll("." + CSS_COMPONENT_SELECTED)
      .forEach((element) => {
        rectsBeforeResize.push(calculateCurrentRect(element as HTMLElement));
        resizableElements.push(element as HTMLElement);
      });

    const resizeStartPosition: PositionDto = getPositionFromPointerEvent(event);

    const onResize = updateComponentRectOnResize(
      resizableElements,
      rectsBeforeResize,
      resizeStartPosition,
      calcNewRectFn
    );

    window.addEventListener("pointermove", onResize);
    window.addEventListener(
      "pointerup",
      updateComponentSizeOnResizeEnd(
        rectsBeforeResize,
        resizeStartPosition,
        calcNewRectFn,
        onResizeEndCallback,
        onResize,
        resizableElements
      )
    );
  };
}

function calcMoveOffset(resizeStartPosition: PositionDto, event: PointerEvent): PositionDto {
  const currentPointerPosition = getPositionFromPointerEvent(event);
  return {
    left: currentPointerPosition.left - resizeStartPosition.left,
    top: currentPointerPosition.top - resizeStartPosition.top
  };
}

function updateComponentRectOnResize(
  resizableComponents: HTMLElement[],
  rectsBeforeResize: RectangleDto[],
  resizeStartPosition: PositionDto,
  calcNewRectFn: CalcNewRectFunction
) {
  const onResize = (event: PointerEvent) => {
    const moveOffset: PositionDto = calcMoveOffset(resizeStartPosition, event);
    resizableComponents.forEach((component, index) => {
      setHTMLRect(
        component,
        calcNewRectFn(rectsBeforeResize[index], moveOffset.left, moveOffset.top)
      );
    });
  };
  return onResize;
}

function updateComponentSizeOnResizeEnd(
  rectsBeforeResize: RectangleDto[],
  resizeStartPosition: PositionDto,
  calcNewRectFn: CalcNewRectFunction,
  onResizeEndCallback: OnResizeEndCallback,
  onResizeCallback: (event: PointerEvent) => void,
  resizableComponents: HTMLElement[]
) {
  const onResizeEnd = (event: PointerEvent) => {
    const moveOffset: PositionDto = calcMoveOffset(resizeStartPosition, event);
    const resizedComponentsRects: Dictionary<RectangleDto> = {};
    resizableComponents.map((component, index) => {
      resizedComponentsRects[DomMapper.getComponentId(component.id)] = calcNewRectFn(
        rectsBeforeResize[index],
        moveOffset.left,
        moveOffset.top
      );
      setHTMLRect(component, rectsBeforeResize[index]);
    });

    // NOTE unset changes in case that new size is the same as the old one

    onResizeEndCallback(resizedComponentsRects);
    window.removeEventListener("pointermove", onResizeCallback);
    window.removeEventListener("pointerup", onResizeEnd);
  };
  return onResizeEnd;
}

function setHTMLRect(element: HTMLElement, rect: RectangleDto): void {
  const { top, left, width, height } = rect;
  const style = element.style;
  const toPx = (value: number) => `${value}px`;
  style.top = toPx(top);
  style.left = toPx(left);
  style.width = toPx(width);
  style.height = toPx(height);
}

function hideElement(element: HTMLElement): void {
  element.style.display = "none";
}

function showElement(element: HTMLElement): void {
  element.style.display = "block";
}

function calculateCurrentRect(element: HTMLElement): RectangleDto {
  const style: CSSStyleDeclaration = element.style;

  return {
    top: fromPx(style.top),
    left: fromPx(style.left),
    width: element.offsetWidth,
    height: element.offsetHeight
  };
}
