<input
  #editorInput
  type="number"
  class="editor--discreet-input"
  [class.editor--value-configured]="value !== propertyInfo.descriptor.defaultValue"
  step="any"
  *ngIf="!isReadOnly"
  [class.invalid-input]="!isValid"
  [ngModel]="value"
  (ngModelChange)="onInputChange($event)"
  [placeholder]="generatePlaceholderText() | translate"
/>
<label *ngIf="isReadOnly">{{ value }}</label>
