import { Action } from "@ngrx/store";
import { DataConnectorDto } from "../../data-connectivity/models/data-connector";
import {
  Dictionary,
  groupByNestedKey,
  isDefined,
  isEmpty,
  isEmptyDict,
  isEmptyOrNotDefined
} from "../../ts-utils";
import { ComponentStateDto } from "../models/component-state";
import {
  ConnectorRequestParams,
  DataRequestParams,
  GenericRequestParams,
  isGenericRequest,
  isSignalRequest
} from "../models/data-request-params";
import { QueryParamsResolverService } from "../services/query-params-resolver.service";
import { CommonActions } from "../store/common/common.actions";
import { DataConnectorActions } from "../store/data-connector/data-connector.actions";
import { filterHeartbeatComponents, filterHeartbeatConnectors } from "./heartbeat.helper";

export function getIncrementalRequestActions(
  requestParams: DataRequestParams[],
  heartbeatNumber: number,
  currentTime: Date,
  queryParamsResolverService: QueryParamsResolverService
): Action[] {
  const requestsGroupedByFilter: Dictionary<DataRequestParams[]> = groupByNestedKey(
    requestParams,
    (params) => params.queryFilter.id
  );
  const allActions: Action[] = Object.values(requestsGroupedByFilter)
    .reduce((acc: Action[], params: DataRequestParams[]) => {
      const connectorsByComponent = prepareHeartbeatConnectors(
        params,
        heartbeatNumber,
        currentTime,
        queryParamsResolverService
      );
      if (!isEmptyDict(connectorsByComponent)) {
        acc.push(
          CommonActions.getIncrementalFilterData({
            connectorsByComponent,
            queryFilter: params[0].queryFilter
          })
        );
      }

      const heartbeatComponents: ComponentStateDto[] = getHeartbeatComponentsWithQuery(
        params,
        heartbeatNumber,
        currentTime
      );
      if (!isEmptyOrNotDefined(heartbeatComponents)) {
        acc.push(
          DataConnectorActions.resolveIncrementalGenericQueries({
            queryFilter: params[0].queryFilter,
            componentsWithQuery: heartbeatComponents
          })
        );
      }
      return acc;
    }, [])
    .filter(isDefined);

  return !isEmpty(allActions) ? allActions : [CommonActions.doNothing()];
}

function prepareHeartbeatConnectors(
  params: DataRequestParams[],
  heartbeatNumber: number,
  currentTime: Date,
  queryParamsResolverService: QueryParamsResolverService
): Dictionary<DataConnectorDto[]> {
  return params
    .filter((param) => isSignalRequest(param))
    .reduce((acc: Dictionary<DataConnectorDto[]>, param: DataRequestParams) => {
      const heartbeatConnectors: Dictionary<DataConnectorDto[]> = filterHeartbeatConnectors(
        (param as ConnectorRequestParams).connectorsByComponent,
        (param as ConnectorRequestParams).queryFilter,
        heartbeatNumber,
        currentTime,
        queryParamsResolverService
      );
      Object.keys(heartbeatConnectors).map(
        (component: string) => (acc[component] = heartbeatConnectors[component])
      );
      return acc;
    }, {});
}

function getHeartbeatComponentsWithQuery(
  params: DataRequestParams[],
  heartbeatNumber: number,
  currentTime: Date
): ComponentStateDto[] {
  return params
    .filter((param) => isGenericRequest(param))
    .flatMap((params) =>
      filterHeartbeatComponents(
        (params as GenericRequestParams).componentsWithQuery,
        (params as GenericRequestParams).queryFilter,
        heartbeatNumber,
        currentTime
      )
    );
}
