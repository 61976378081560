import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PageWidth } from "../models/page-layout";
import { RuntimeSettings } from "../models/runtime-settings";

@Injectable()
export class IRuntimeSettingsSelector {
  public select(): Observable<RuntimeSettings> {
    throw new Error("Not implemented");
  }

  public selectPagePreviewWidth(): Observable<PageWidth> {
    throw new Error("Not implemented");
  }

  public selectCurrentRootPath(): Observable<string> {
    throw new Error("Not implemented");
  }

  public selectPeriodType(): Observable<string> {
    throw new Error("Not implemented");
  }

  public getRuntimeSettings(): RuntimeSettings {
    throw new Error("Not implemented");
  }

  public getCurrentRootPath(): string {
    throw new Error("Not implemented");
  }

  public getPeriodType(): string {
    throw new Error("Not implemented");
  }

  public getPagePreviewWidth(): PageWidth {
    throw new Error("Not implemented");
  }
}
