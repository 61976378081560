import { BasicCardViewConfig } from "../components/basic-card/view-config";
import { PageViewConfig } from "../components/page/view-config";
import { BaseViewConfigDto } from "../models/base-view-config";
import { BASIC_CARD_VIEW_CONFIG, PAGE_VIEW_CONFIG } from "../models/view-config-type.constants";

export function isBasicCardViewConfig(x: BaseViewConfigDto): x is BasicCardViewConfig {
  return x.typeName === BASIC_CARD_VIEW_CONFIG;
}

export function isPageViewConfig(x: BaseViewConfigDto): x is PageViewConfig {
  return x.typeName === PAGE_VIEW_CONFIG;
}
