import { construct } from "../../core/services/construct.helper";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import { Configurable, ConfigurationCategory, EditableType } from "../../meta/decorators";
import { Serializable } from "../../meta/decorators/serializable.decorator";
import { EditorType, PropertyCategory } from "../../meta/models";

const TYPE_NAME = "KmColumns";

export interface KmColumnsDto {
  Descr: boolean;
  Value: boolean;
  Limits: boolean;
  editableLayout: boolean;
  PType: boolean;
  RulerTime: false;
  Unit: boolean;
  TimeOffset: boolean;
  Location: boolean;
  SortName: boolean;
  Reduction: boolean;
  TrendLineAVG: boolean;
  TrendLineMIN: boolean;
  TrendLineMAX: boolean;
  TrendLineSUM: boolean;
  TrendLineSTD: boolean;
  TrendLineCNT: boolean;
  TrendLineVAR: boolean;
  TrendLineCVR: boolean;
}
@EditableType({ fullName: TYPE_NAME, title: "km-columns" })
export class KmColumns implements KmColumnsDto {
  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Columns)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Description,
    editorType: EditorType.CheckBox,
    advancedMode: true
  })
  @Serializable(true)
  Descr!: boolean;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Columns)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Value,
    editorType: EditorType.CheckBox,
    advancedMode: true
  })
  @Serializable(true)
  Value!: boolean;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Columns)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Limits,
    editorType: EditorType.CheckBox,
    advancedMode: true
  })
  @Serializable(true)
  Limits!: boolean;

  editableLayout: boolean;
  PType: boolean;
  RulerTime: false;
  Unit: boolean;
  TimeOffset: boolean;
  Location: boolean;
  SortName: boolean;
  Reduction: boolean;
  TrendLineAVG: boolean;
  TrendLineMIN: boolean;
  TrendLineMAX: boolean;
  TrendLineSUM: boolean;
  TrendLineSTD: boolean;
  TrendLineCNT: boolean;
  TrendLineVAR: boolean;
  TrendLineCVR: boolean;

  constructor() {
    construct(this, {}, TYPE_NAME);
  }
}
