import { Injectable } from "@angular/core";
import { Update } from "@ngrx/entity";
import { differenceBy as _differenceBy, intersectionBy as _intersectionBy } from "lodash";
import { ComponentMetadataService, DataConnectorDto } from "../../data-connectivity";
import { getConnectorViewId } from "../../data-connectivity/helpers/connector-view-id.helper";
import { shouldUpdateConnectorViewTitle } from "../../data-connectivity/helpers/data-connector-view.helper";
import { DataConnectorViewDto } from "../../data-connectivity/models/data-connector-view";
import { ConnectorContextService } from "../../data-connectivity/services/connector-context.service";
import { EntityId } from "../../meta";
import { Dictionary, Maybe, isNotDefined } from "../../ts-utils";
import { isPseudoConnector } from "../helpers/connectors.helper";
import { DataStatus } from "../models";
import { ConnectorsReplaceInfo } from "../models/store/connectors-replace-info";
import { getConnectorsWithContext } from "./connector-context.helper";
import { ComponentStateSelector } from "./entity-selectors/component-state.selector";
import { DataConnectorViewSelector } from "./entity-selectors/data-connector-view.selector";
import { DataConnectorSelector } from "./entity-selectors/data-connector.selector";

@Injectable()
export class DataConnectorReplacementService {
  constructor(
    private componentStateSelector: ComponentStateSelector,
    private dataConnectorSelector: DataConnectorSelector,
    private metadataProvider: ComponentMetadataService,
    private dataConnectorViewSelector: DataConnectorViewSelector
  ) {}

  createDynamicConnectorReplacementInfo(
    newDynamicConnectors: Dictionary<DataConnectorDto[]>,
    contextService: ConnectorContextService
  ): Dictionary<ConnectorsReplaceInfo> {
    if (isNotDefined(newDynamicConnectors) || isNotDefined(contextService)) {
      return null;
    }
    return Object.keys(newDynamicConnectors).reduce((acc, componentId) => {
      acc[componentId] = this.createComponentDynamicConnectorReplacementInfo(
        componentId,
        newDynamicConnectors[componentId],
        contextService
      );

      return acc;
    }, {} as Dictionary<ConnectorsReplaceInfo>);
  }

  createComponentDynamicConnectorReplacementInfo(
    componentId: EntityId,
    newDynamicConnectors: DataConnectorDto[],
    contextService: ConnectorContextService
  ): ConnectorsReplaceInfo {
    if (
      isNotDefined(componentId) ||
      isNotDefined(newDynamicConnectors) ||
      isNotDefined(contextService)
    ) {
      return getEmptyReplacementInfo();
    }
    const ownerComponent = this.componentStateSelector.getById(componentId);
    if (isNotDefined(ownerComponent)) {
      return getEmptyReplacementInfo();
    }
    const componentOldConnectors = this.dataConnectorSelector.getForComponent(componentId);

    let connectorsReplacementInfo: ConnectorsReplaceInfo;
    if (this.componentHasMaxConnectors(ownerComponent.type, componentOldConnectors)) {
      connectorsReplacementInfo = this.getReplaceAllConnectorsUpdateInfo(
        componentOldConnectors,
        newDynamicConnectors
      );
    } else {
      connectorsReplacementInfo = this.createDynamicConnectorsUpdateInfo(
        componentOldConnectors,
        newDynamicConnectors
      );
    }

    connectorsReplacementInfo.newConnectors = getConnectorsWithContext(
      connectorsReplacementInfo.newConnectors,
      ownerComponent,
      contextService
    );
    connectorsReplacementInfo.dcqDataStatus =
      newDynamicConnectors.length > 0 ? DataStatus.DataReceived : DataStatus.NoDataReceived;
    return connectorsReplacementInfo;
  }

  componentHasMaxConnectors(componentType: string, oldConnectors: DataConnectorDto[]): boolean {
    const ownerMaxConnectors: number = this.metadataProvider.getMaxConnectors(componentType);
    return oldConnectors.length >= ownerMaxConnectors;
  }

  private getReplaceAllConnectorsUpdateInfo(
    oldConnectors: DataConnectorDto[],
    newConnectors: DataConnectorDto[]
  ): ConnectorsReplaceInfo {
    return {
      obsoleteConnectors: oldConnectors,
      newConnectors: newConnectors,
      connectorsToUpdate: []
    } as ConnectorsReplaceInfo;
  }

  private createDynamicConnectorsUpdateInfo(
    oldConnectors: DataConnectorDto[],
    newDynamicConnectors: DataConnectorDto[]
  ): ConnectorsReplaceInfo {
    const oldDynamicConnectors = getDynamicConnectors(oldConnectors);
    const oldDynamicConnectorsToRemove = _differenceBy(
      oldDynamicConnectors,
      newDynamicConnectors,
      "id"
    );
    const dynamicConnectorsToAdd = _differenceBy(newDynamicConnectors, oldDynamicConnectors, "id");
    const oldConnectorsToUpdate = _intersectionBy(
      newDynamicConnectors,
      oldDynamicConnectors,
      "id"
    ).map((dynamicConnector) => {
      const connectorView: Maybe<DataConnectorViewDto> = this.dataConnectorViewSelector.getById(
        getConnectorViewId(dynamicConnector.id)
      );
      if (shouldUpdateConnectorViewTitle(connectorView)) {
        dynamicConnector = { ...dynamicConnector, title: connectorView.title };
      }
      // TODO: check if this data poitns are actually defined
      return {
        id: dynamicConnector.id.toString(),
        changes: dynamicConnector
      } as Update<DataConnectorDto>;
    });
    return {
      obsoleteConnectors: oldDynamicConnectorsToRemove,
      newConnectors: dynamicConnectorsToAdd,
      connectorsToUpdate: oldConnectorsToUpdate
    } as ConnectorsReplaceInfo;
  }
}

function getDynamicConnectors(connectors: DataConnectorDto[]): DataConnectorDto[] {
  const dynamicConnectors: DataConnectorDto[] = connectors.filter(
    (connector) => connector.isDynamicallyCreated && !isPseudoConnector(connector.id)
  );
  return dynamicConnectors;
}

function getEmptyReplacementInfo(): ConnectorsReplaceInfo {
  return {
    obsoleteConnectors: [],
    newConnectors: [],
    connectorsToUpdate: []
  };
}
