import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { QueryFilter } from "../../core/models/filter/query-filter";
import { IFilterSelector } from "../../core/services/filter/i-filter.selector";
import { QueryParamKeyConverter } from "../../core/services/query-param-key.converter";
import { TimeService } from "../../core/services/time.service";
import {
  ConnectorsDictionaryIndexedById,
  DataConnectorDto,
  DataPoint,
  EquipmentDataSourceDto,
  SIGNAL_DATA_SOURCE,
  SignalInfoDto,
  ValueDataSourceDto
} from "../../data-connectivity";
import {
  isEquipment,
  isSignalBased,
  isValue
} from "../../data-connectivity/helpers/data-source-type.helper";
import { convertValueDataModel } from "../../data-connectivity/helpers/value-data-conversion.helper";
import { DataService } from "../../data-connectivity/services/data.service";
import { EquipmentToDataConnectorsConverter } from "../../data-connectivity/services/equipment-to-data-connectors.converter";
import { EntityId } from "../../meta/models/entity";
import {
  Dictionary,
  Maybe,
  isDefined,
  isEmptyOrNotDefined,
  isEmptyOrNotDefined2,
  isNotDefined
} from "../../ts-utils";
import { debugLog } from "../../ts-utils/helpers/conditional-logging";
import { CriticalError } from "../../ts-utils/models/critical-error";
import { setDataStatusFromPoints } from "../helpers/connectors.helper";
import { ComponentStateDto } from "../models/component-state";
import { DataServiceParams } from "../models/data-service-params";
import { DataStatus } from "../models/data-status";
import { RequestScope } from "../models/request-scope";
import { DataConnectorSelector } from "./entity-selectors/data-connector.selector";
import { RuntimeSettingsSelector } from "./entity-selectors/runtime-settings.selector";
import { QueryParamsResolverService } from "./query-params-resolver.service";

@Injectable()
export class ElementsDataService extends DataService {
  protected connectorSelector: DataConnectorSelector;
  protected equipmentToDataConnectorsConverter: EquipmentToDataConnectorsConverter;
  protected timeService: TimeService;
  protected queryParamsResolver: QueryParamsResolverService;
  protected runtimeSettingsSelector: RuntimeSettingsSelector;
  protected filterSelector: IFilterSelector;
  protected queryParamKeyConverter: QueryParamKeyConverter;

  constructor(params: DataServiceParams) {
    super(
      params.queryStringService,
      params.apiConfig,
      params.httpService,
      params.dispatcher,
      params.dataConnectorFactory,
      params.localizationService
    );
    this.connectorSelector = params.connectorSelector;
    this.equipmentToDataConnectorsConverter = params.equipmentToDataConnectorsConverter;
    this.timeService = params.timeService;
    this.queryParamsResolver = params.queryParamsResolver;
    this.runtimeSettingsSelector = params.runtimeSettingsSelector;
    this.filterSelector = params.filterSelector;
    this.queryParamKeyConverter = params.queryParamKeyConverter;
  }

  protected getData(
    filter: QueryFilter,
    requestedConnectors: Dictionary<DataConnectorDto[]>,
    requestScope: RequestScope
  ): Observable<ConnectorsDictionaryIndexedById> {
    return null;
  }

  get(
    filter: QueryFilter,
    requestedConnectors: Dictionary<DataConnectorDto[]>,
    requestScope: RequestScope
  ): Observable<ConnectorsDictionaryIndexedById> {
    if (!filter) {
      throw new CriticalError(`Undefined filter for request`);
    }

    const { resolvedConstantConnectors, otherConnectors } = this.processConstants(
      filter,
      requestedConnectors
    );

    const { valid, invalid } = this.removeInvalidSignalConnectors(otherConnectors);

    return this.getData(filter, valid, requestScope).pipe(
      map((remainingResult: ConnectorsDictionaryIndexedById) => {
        const validResults = resolvedConstantConnectors.reduce(
          (acc: ConnectorsDictionaryIndexedById, conn: DataConnectorDto) => {
            if (conn.dataStatus !== DataStatus.RequestFailed) {
              setDataStatusFromPoints(conn);
            }
            acc[conn.id] = conn;
            return acc;
          },
          remainingResult
        );
        const allResults = invalid.reduce(
          (acc: ConnectorsDictionaryIndexedById, invalidConn: DataConnectorDto) => {
            acc[invalidConn.id] = invalidConn;
            return acc;
          },
          validResults
        );
        debugLog("ElementsDataService.getData", {
          validResults,
          invalid,
          requestedConnectors,
          allResults
        });
        return allResults;
      })
    );
  }

  private splitInvalidSignalConectors(connectors: DataConnectorDto[]): {
    valid: DataConnectorDto[];
    invalid: DataConnectorDto[];
  } {
    const valid: DataConnectorDto[] = [];
    const invalid: DataConnectorDto[] = [];
    connectors.forEach((connector) => {
      const connectorIsInvalid =
        isSignalBased(connector.dataSource) &&
        isEmptyOrNotDefined(connector.dataSource.signal.id?.toString());

      if (connectorIsInvalid) {
        const invalidConnnectorUpdate: Partial<DataConnectorDto> = {
          id: connector.id,
          dataStatus:
            connector.dataSource.typeName === SIGNAL_DATA_SOURCE
              ? DataStatus.NoDataDefined
              : DataStatus.NoDataReceived,
          dataPoints: []
        };
        invalid.push(invalidConnnectorUpdate as DataConnectorDto);
      } else {
        valid.push(connector);
      }
    });
    return { valid, invalid };
  }

  private removeInvalidSignalConnectors(connectors: Dictionary<DataConnectorDto[]>): {
    valid: Dictionary<DataConnectorDto[]>;
    invalid: DataConnectorDto[];
  } {
    const allValid: Dictionary<DataConnectorDto[]> = {};
    const allInvalid: DataConnectorDto[] = [];

    Object.keys(connectors).forEach((componentId) => {
      const { valid, invalid } = this.splitInvalidSignalConectors(connectors[componentId]);
      allValid[componentId] = valid;
      allInvalid.push(...invalid);
    });
    return { valid: allValid, invalid: allInvalid };
  }

  private processConstants(
    filter: QueryFilter,
    requestedConnectors: Dictionary<DataConnectorDto[]>
  ): {
    resolvedConstantConnectors: DataConnectorDto[];
    otherConnectors: Dictionary<DataConnectorDto[]>;
  } {
    const connectors = Object.keys(requestedConnectors).reduce(
      (acc, componentId) => {
        requestedConnectors[componentId].forEach((connector) => {
          const resolvedConstantConnector = this.resolveConstantConnector(connector, filter);
          if (isDefined(resolvedConstantConnector)) {
            acc.evaluated.push(resolvedConstantConnector);
          } else {
            isDefined(acc.remaining[componentId])
              ? acc.remaining[componentId].push(connector)
              : (acc.remaining[componentId] = [connector]);
          }
        });
        return acc;
      },
      {
        evaluated: [] as DataConnectorDto[],
        remaining: {} as Dictionary<DataConnectorDto[]>
      }
    );
    return {
      resolvedConstantConnectors: connectors.evaluated,
      otherConnectors: connectors.remaining
    };
  }

  private resolveConstantConnector(
    connector: DataConnectorDto,
    filter: QueryFilter
  ): Maybe<DataConnectorDto> {
    let resolvedConstantConnector: Maybe<DataConnectorDto> = null;
    if (isValue(connector.dataSource)) {
      resolvedConstantConnector = this.createConstantValueConnector(connector, filter);
    } else if (isEquipment(connector.dataSource)) {
      const equipmentDataSource = connector.dataSource;
      if (isDefined(equipmentDataSource.value)) {
        const dataPoints = convertValueDataModel(
          equipmentDataSource.value,
          connector.numberOfRequestedDataPoints,
          filter.timeRange
        );
        resolvedConstantConnector = this.createDataConnector(connector, dataPoints, null);
        resolvedConstantConnector.isIncrementalUpdate = false;
      }
    }
    return resolvedConstantConnector;
  }

  private createConstantValueConnector(
    connector: DataConnectorDto,
    filter: QueryFilter
  ): DataConnectorDto {
    const dataSource = connector.dataSource as ValueDataSourceDto;
    let parsedValue = null;
    if (!isEmptyOrNotDefined2(dataSource.value)) {
      try {
        parsedValue = JSON.parse(dataSource.value);
      } catch (error) {
        console.error("Error Parsing '" + dataSource.value + "': ", error);
      }
    }
    let dataPoints: DataPoint[] = [];
    let props: any;
    if (!isEmptyOrNotDefined2(dataSource.value)) {
      dataPoints = convertValueDataModel(
        parsedValue,
        connector.numberOfRequestedDataPoints,
        filter.timeRange
      );
      props = parsedValue.properties;
    }
    return this.createDataConnector(connector, dataPoints, props);
  }

  public resolveComponentEquipmentQueries(
    equipmentQueries: Dictionary<EquipmentDataSourceDto>,
    rootPath: string,
    componentStates: ComponentStateDto[]
  ): Observable<Dictionary<DataConnectorDto[]>> {
    if (Object.values(equipmentQueries).length > 0) {
      return this.getManyEquipment(Object.values(equipmentQueries), rootPath).pipe(
        map((equipmentPropertyConnectors: DataConnectorDto[][]) => {
          this.equipmentToDataConnectorsConverter.setConnectorIds(
            equipmentPropertyConnectors,
            Object.keys(equipmentQueries)
          );
          this.equipmentToDataConnectorsConverter.setDefaultRole(
            equipmentPropertyConnectors,
            Object.keys(equipmentQueries),
            componentStates
          );
          const equipmentPropertyConnectorsDic = this.matrixToDictionary(
            equipmentPropertyConnectors,
            Object.keys(equipmentQueries)
          );
          return equipmentPropertyConnectorsDic;
        })
      );
    } else {
      return of({});
    }
  }

  private matrixToDictionary(
    matrix: DataConnectorDto[][],
    keys: EntityId[]
  ): Dictionary<DataConnectorDto[]> {
    return keys.reduce((acc: Dictionary<DataConnectorDto[]>, id: EntityId, index: number) => {
      acc[id] = matrix[index] || [];
      return acc;
    }, {});
  }

  protected mergeEquipmentConnectorProperties(
    resolvedConnector: DataConnectorDto,
    existingConnector: DataConnectorDto
  ): DataConnectorDto {
    const updatedConnector = new DataConnectorDto(existingConnector);
    if (!isEquipment(updatedConnector.dataSource)) {
      throw Error("Data source is not equipment!");
    }
    if (isNotDefined(resolvedConnector)) {
      updatedConnector.dataPoints = [];
      updatedConnector.dataSource.signal = new SignalInfoDto(null, "");
      updatedConnector.dataSource.value = null;
      updatedConnector.dataStatus = DataStatus.NoDataReceived;
    } else {
      updatedConnector.properties = resolvedConnector.properties;
      updatedConnector.dataSource = { ...resolvedConnector.dataSource };
      updatedConnector.isTimeSeries = resolvedConnector.isTimeSeries;
    }
    return updatedConnector;
  }
}
