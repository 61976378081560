import { Component, HostBinding, ViewChild } from "@angular/core";
import { OfType } from "../../../meta/decorators";
import { EditorType } from "../../../meta/models";
import { ToggleSwitchComponent } from "../../../shared/components/toggle-switch/toggle-switch.component";
import { BaseEditorComponent } from "../base-editor.component";

@Component({
  selector: "check-box-editor",
  templateUrl: "check-box-editor.component.html",
  styleUrls: ["./check-box-editor.component.scss"]
})
@OfType(EditorType.CheckBox)
export class CheckBoxEditorComponent extends BaseEditorComponent {
  @ViewChild(ToggleSwitchComponent)
  toggleSwitch?: ToggleSwitchComponent;
  @HostBinding("attr.title")
  public get tooltipText(): string {
    return this.tooltip;
  }

  onValueChanged(value: boolean): void {
    super.onValueChanged(value);
  }

  focus(): void {
    this.toggleSwitch?.getSwitch()?.nativeElement.focus();
  }
}
