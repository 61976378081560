<div class="limits-editor__container" *ngIf="editorsInfo">
  <highcharts-chart-extended [Highcharts]="Highcharts" [options]="chartOptions" [oneToOne]="true">
  </highcharts-chart-extended>
  <limit-value-color-editor
    *ngFor="let editorInfo of editorsInfo | keyvalue"
    [style.top.px]="editorInfo.value.absolutePositionY"
    [key]="editorInfo.key"
    [editorVisualization]="editorInfo.value"
    [value]="limitsConfiguration[editorInfo.key]"
    [color]="limitsConfiguration[editorInfo.key + limitColorSufix]"
    (onChangeColor)="onLimitColorChange(editorInfo.key, $event)"
    (onChangeValue)="onLimitValueChange(editorInfo.key)"
  ></limit-value-color-editor>
</div>
