import { Injectable } from "@angular/core";
import { EntityId } from "../../meta/models/entity";
import { isDefined, Maybe } from "../../ts-utils";

const HTML_BODY_ELEMENT_NAME = "body";
const CSS_BASE_CLASS = "c-base-component";
export const COMPONENT_HOST_ID_PREFIX = "__COMPONENT__";

@Injectable()
export class DomMapper {
  static getHostId(componentId: EntityId) {
    return `${COMPONENT_HOST_ID_PREFIX}${componentId}`;
  }

  static getComponentId(hostId: string) {
    return hostId.slice(COMPONENT_HOST_ID_PREFIX.length);
  }

  static findComponentHostElement(element: HTMLElement): HTMLElement | null {
    if (element.nodeName.toLowerCase() === window.document.children[0].nodeName.toLowerCase()) {
      return null;
    }

    const elementClasses: string[] = [].slice.call(element.classList);
    if (elementClasses.indexOf(CSS_BASE_CLASS) !== -1) {
      return element;
    } else {
      if (element.parentElement.nodeName.toLowerCase() !== HTML_BODY_ELEMENT_NAME) {
        return DomMapper.findComponentHostElement(element.parentElement);
      } else {
        console.error(`[DomMapper] Component host element not found`);
        return null;
      }
    }
  }

  static getSiblings(element: HTMLElement): HTMLElement[] {
    const siblings: Maybe<HTMLCollection> = element.parentNode?.children;
    return isDefined(siblings)
      ? (Array.from(siblings) as HTMLElement[]).filter((child) =>
          child.id.startsWith(COMPONENT_HOST_ID_PREFIX)
        )
      : [];
  }
}
