/* eslint-disable @typescript-eslint/no-namespace */
import { createAction, props, union } from "@ngrx/store";
import { FilterConfigurationDto } from "../../../core/models/filter/filter-configuration";
import { QueryFilter } from "../../../core/models/filter/query-filter";
import { GeneralSettingsDto } from "../../../core/models/general-settings";
import { ReportId, ReportTag } from "../../../core/models/report-id";
import { TimeRange } from "../../../core/models/time-range";
import {
  ResetTimeRangeEvent,
  SelectEquipmentPathEvent,
  SelectTimeRangeEvent
} from "../../../core/models/widget-eventing/widget-event";
import { DataConnectorDto } from "../../../data-connectivity/models/data-connector";
import { EntityId } from "../../../meta/models/entity";
import { ReportSnapshot } from "../../../meta/models/report-snapshot";
import { ReportCreationParams } from "../../../shared/models/report-creation-params";
import { isNotDefined } from "../../../ts-utils/helpers/predicates.helper";
import { CriticalError } from "../../../ts-utils/models/critical-error";
import { Dictionary } from "../../../ts-utils/models/dictionary.type";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { ReportConfiguration } from "../../models/report-configuration";
import { ReportEntities } from "../../models/report-entities";
import { ReportContents } from "../state";

export namespace CommonActions {
  //#region INITIAL LOAD

  export const loadLanguages = createAction("[Common] Load Languages");

  export const resetStore = createAction(
    "[Common] Reset Store",
    props<{
      reportTag: ReportTag;
      reportConfiguration: ReportConfiguration;
    }>()
  );

  export const initDynamicConnectors = createAction(
    "[Common] Initialize Dynamic Connectors",
    props<{ reportTag: ReportTag; reportEntities: ReportEntities }>()
  );

  export const resolveBackgroundImages = createAction(
    "[Common] Resolve Background Images",
    props<{ reportTag: ReportTag; reportEntities: ReportEntities }>()
  );

  export const upsertEntitiesOnLoad = createAction(
    "[Common] Upsert Entities On Load",
    props<{ reportEntities: ReportEntities }>()
  );

  export const upsertEntities = createAction(
    "[Common] Upsert Entities",
    props<{ reportEntities: ReportEntities }>()
  );
  //#endregion

  //#region RUNTIME WORKFLOW

  export const saveReport = createAction(
    "[Common] Save Report",
    props<{
      reportId: ReportId;
      hideConfirmation?: boolean;
    }>()
  );

  export const startSavingReport = createAction(
    "[Common] Start Saving Report",
    ({
      reportId,
      state,
      hideConfirmation
    }: {
      reportId: ReportId;
      state: ReportContents;
      hideConfirmation: boolean;
    }) => {
      if (isNotDefined(state)) {
        throw new CriticalError("Undefined report contents");
      }
      return { reportId, state, hideConfirmation };
    }
  );

  export const finishSavingReport = createAction("[Common] Finish Saving Report");

  export const saveReportSuccess = createAction("[Common] Save Report Success");

  export const reportCreated = createAction(
    "[Common] Report Created",
    props<{ reportName: string; reportId: ReportId }>()
  );

  export const addReportToSidebar = createAction(
    "[Common] Add Report To Sidebar",
    props<{ data: Maybe<ReportConfiguration> }>()
  );

  export const getFullRangeSignalData = createAction(
    "[Common] Get Full Range Data",
    props<{
      connectors: DataConnectorDto[];
      timeRange?: Partial<TimeRange>;
    }>()
  );

  export const getFullRangeFilterData = createAction(
    "[Common] Get Full Range Filter data",
    props<{
      connectorsByComponent: Dictionary<DataConnectorDto[]>;
      queryFilter: QueryFilter;
      isAdditionalDataRequest?: boolean;
    }>()
  );

  export const getIncrementalFilterData = createAction(
    "[Common] Get Incremental Filter data",
    props<{
      connectorsByComponent: Dictionary<DataConnectorDto[]>;
      queryFilter: QueryFilter;
    }>()
  );

  export const liveModeUpdate = createAction(
    "[Common] Live Mode Update",
    props<{ liveModeFilters: FilterConfigurationDto[]; heartbeatNumber: number }>()
  );

  export const doNothing = createAction("[Common] Do Nothing");

  export const startCreatingReport = createAction(
    "[Common] Start Creating Report",
    props<{ reportCreationInfo: ReportCreationParams; state: ReportContents }>()
  );

  export const upsertFromSnapshot = createAction(
    "[Common] Upsert From Snapshot",
    props<{ reportSnapshot: ReportSnapshot }>()
  );

  export const replaceAll = createAction(
    "[Common] Replace All",
    props<{ entities: ReportEntities }>()
  );
  //#endregion

  export const raiseWidgetSelectTimeRangeEvent = createAction(
    "[Widget] Raise SelectTimeRange Event",
    props<{ raisedEvent: SelectTimeRangeEvent; source: EntityId }>()
  );
  export const raiseWidgetResetTimeRangeEvent = createAction(
    "[Widget] Raise ResetTimeRange Event",
    props<{ raisedEvent: ResetTimeRangeEvent; source: EntityId }>()
  );

  export const raiseWidgetSelectEquipmentPathEvent = createAction(
    "[Widget] Raise SelectEquipmentPath Event",
    props<{ raisedEvent: SelectEquipmentPathEvent; source: EntityId }>()
  );

  export const discardRuntimeParameters = createAction(
    "[Environment] Discard Runtime Parameters",
    props<{ reportSettings: Partial<GeneralSettingsDto> }>()
  );

  const raiseWidgetEvents = union({
    raiseWidgetSelectTimeRangeEvent,
    raiseWidgetSelectEquipmentPathEvent
  });
  export type RaiseWidgetEventActions = typeof raiseWidgetEvents;
}
