import { Injectable } from "@angular/core";
import { IconStateDisplay } from "../../../elements/models/status-indicator/icon-state-display";
import { ImageStateDisplay } from "../../../elements/models/status-indicator/image-state-display";
import { IndicatorStateDisplay } from "../../../elements/models/status-indicator/indicator-state-display";
import { ShapeStateDisplay } from "../../../elements/models/status-indicator/shape-state-display";
import { StateDisplayType } from "../../../elements/models/status-indicator/state-display-type";
import { IDeserializer } from "../../../meta/services/entity-converter";
import { CriticalError, DeepPartial, isDefined } from "../../../ts-utils";

@Injectable()
export class StatusDisplayTypeDeserializer
  implements IDeserializer<Partial<IndicatorStateDisplay>, IndicatorStateDisplay>
{
  constructor() {}

  public convert(stateDisplay: DeepPartial<IndicatorStateDisplay>): IndicatorStateDisplay {
    if (!stateDisplay) {
      return new ShapeStateDisplay();
    }
    if (isDefined(stateDisplay.typeName)) {
      return createStatusDisplay(stateDisplay);
    } else {
      throw new CriticalError(`Status Display type "${stateDisplay.typeName}" not found`);
    }
  }
}

export function createStatusDisplay(
  partial: DeepPartial<IndicatorStateDisplay>
): IndicatorStateDisplay {
  switch (partial.typeName) {
    case StateDisplayType.Shape:
      return new ShapeStateDisplay();
    case StateDisplayType.Icon:
      return new IconStateDisplay();
    case StateDisplayType.Image:
      return new ImageStateDisplay();
    default:
      return new ShapeStateDisplay();
  }
}
