import { createAction, props } from "@ngrx/store";
import { ApiResponse } from "../../models/api/api-response";
import { IMotorStatus } from "../../models/motor-status.interface";

export namespace ClientDataActions {

  export const getClientDataAction = createAction(
    "[CLIENT DATA] Get Client Data",
    props<{ motorStatus: IMotorStatus }>()
  );

  export const setClientData = createAction(
    "[CLIENT DATA] Set Client Data",
    props<{ clientData: ApiResponse[] }>()
  );
}
