import { createAction, props } from "@ngrx/store";
import { EquipmentDialogInfo } from "../../../property-sheet/models/equipment-dialog-info";

export namespace EquipmentBrowserDialogActions {
  export const openEquipmentBrowserDialog = createAction(
    "[Dialog] Open Equipment Browser Dialog",
    props<{ equipmentDialogInfo: EquipmentDialogInfo }>()
  );

  export const onEquipmentBrowserDialogClosed = createAction(
    "[Dialog] On Equipment Browser Dialog Closed",
    props<{ result: any }>()
  );
}
