export enum DataStatus {
  WaitingForData = "Waiting for data",
  WaitingForMorePreciseData = "Waiting for more precise data",
  NoDataReceived = "No data received",
  RequestFailed = "Request failed",
  DataReceived = "Data received",
  NoDataDefined = "No data defined"
}

export const INVALID_DATA = "Invalid data";
