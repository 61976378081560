import { Pipe, PipeTransform } from "@angular/core";
import { of } from "rxjs";
import { DataService } from "../../data-connectivity/services/data.service";

@Pipe({ name: "explicite" })
export class ExpliciteRequestPipe implements PipeTransform {
  constructor(private dataService: DataService) {}

  transform(url: string) {
    if (url == null || url === "") {
      return of("");
    }
    return this.dataService.getImage(url);
  }
}
