import { DataTransferObject } from "../../core/models/data-transfer-object";
import { construct } from "../../core/services/construct.helper";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import { Configurable } from "../../meta/decorators/configurable.decorator";
import { ConfigurationCategory } from "../../meta/decorators/configuration-category.decorator";
import { EditableType } from "../../meta/decorators/editable-type.decorator";
import { GroupNestedProperties } from "../../meta/decorators/group-nested-properties.decorator";
import { Serializable } from "../../meta/decorators/serializable.decorator";
import { EditorType } from "../../meta/models/editor-type";
import { PropertyCategory } from "../../meta/models/property-category";
import { DeepPartial } from "../../ts-utils/models/deep-partial.type";
import { BorderRadiusDto } from "./border-radius";

export enum BorderSide {
  Top = "borderTop",
  Right = "borderRight",
  Bottom = "borderBottom",
  Left = "borderLeft",
  All = "border"
}

const TYPE_NAME = "BorderStyleDto";
@EditableType({ fullName: TYPE_NAME, title: "border-style-dto" })
@GroupNestedProperties({
  subgroupsWidthRatio: [0.3, 0.7], //add a value between 0 and 1
  layout: [["sides"], ["borderWidth", "borderColor"]] //use the same property name from the DTO and add it to the appropriate subgroup
})
export class BorderStyleDto implements DataTransferObject {
  typeName: string = TYPE_NAME;

  @Serializable(new BorderRadiusDto())
  radius!: BorderRadiusDto;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Style)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Border,
    editorType: EditorType.BorderSideSelector,
    advancedMode: true
  })
  @Serializable([], String)
  sides!: string[];

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Style)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Width,
    editorType: EditorType.Number,
    advancedMode: true
  })
  @Serializable(2)
  borderWidth!: number;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Style)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Color,
    editorType: EditorType.ColorPicker,
    advancedMode: true
  })
  @Serializable("")
  borderColor!: string;

  constructor(borderStyle: DeepPartial<BorderStyleDto> = {}) {
    construct(this, borderStyle, TYPE_NAME, {});
  }
}
