import { EditableType } from "../../../meta/decorators";
import { BaseViewConfigDto } from "../../models/base-view-config";

const TYPE_NAME = "StrategizedChartViewConfig";

@EditableType({ fullName: TYPE_NAME })
export abstract class StrategizedChartViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  abstract get displayStrategy(): string;
  abstract set displayStrategy(value: string);
}
