import { Component } from "@angular/core";
import { map as _map } from "lodash";
import { Cardinality } from "../../../core/models/cardinality";
import { DataConnectorDto } from "../../../data-connectivity/models/data-connector";
import { DataConnectorRoles } from "../../../data-connectivity/models/data-connector-role";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { ConnectorRoles } from "../../decorators/connector-roles.decorator";
import { MaxConnectors } from "../../decorators/max-connectors.decorator";
import { View } from "../../decorators/view.decorator";
import { BaseComponent } from "../base/base.component";
import { MetaDataInspectorViewConfig } from "./view-config";

const Roles: DataConnectorRoles = {
  Value: {
    name: "Value",
    cardinality: Cardinality.One,
    isDefaultRole: true
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function valueToString(value: any): string {
  return value === null ? "(null)" : value === undefined ? "(undefined)" : value;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function valueKeyPairToArray(value: any, key: string): string[] {
  return ['"' + key + '"', valueToString(value)];
}

@Component({
  selector: "c-meta-data-inspector",
  templateUrl: "./meta-data-inspector.component.html",
  styleUrls: ["./meta-data-inspector.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: MetaDataInspectorComponent }]
})
// @LayoutBuilder(
//   ComponentCategory.OtherComponents,
//   "MetaDataInspectorComponent",
//   "Analyze_document",
//   "abb-icon"
// )
@ConnectorRoles(Roles)
@MaxConnectors(100)
@EditableWidget({ fullName: "MetaDataInspectorComponent", title: "meta-data-inspector" })
export class MetaDataInspectorComponent extends BaseComponent {
  @View(MetaDataInspectorViewConfig)
  public get view(): MetaDataInspectorViewConfig {
    return this.currentState.view as MetaDataInspectorViewConfig;
  }

  private prevNumPoints: number;

  protected initSubscriptions() {
    super.initSubscriptions();

    // TODO: setting number of datapoints currently not supported.

    // this.componentStatePropertySelector.subscribeOnSlice(
    //   (x) => (x.view as MetaDataInspectorViewConfig).numPoints,
    //   (v) => this.onNumPointsUpdated(v)
    // );
  }

  // private onNumPointsUpdated(numPoints: number): void {
  //   if (this.prevNumPoints == null) {
  //     this.prevNumPoints = numPoints;
  //   } else if (this.prevNumPoints !== numPoints) {
  //     this.prevNumPoints = numPoints;
  //     this.dispatch(
  //       ComponentStateActions.updateOne({
  //         componentUpdate: { id: this.id.toString(), changes: { maxNumberOfDataPoints: numPoints } }
  //       })
  //     );
  //     const dc = this.dataAccessor.getConnectorByRole(Roles.Value.name);
  //     if (dc != null) {
  //       this.dispatch(
  //         DataConnectorActions.updateOne({
  //           connectorUpdate: {
  //             id: dc.id.toString(),
  //             changes: {
  //               numberOfRequestedDataPoints: numPoints
  //             }
  //           }
  //         })
  //       );
  //     }
  //   }
  // }

  // protected calculateNumberOfDataPointsToRequest(): number {
  //   return this.view.numPoints;
  // }

  protected updateDisplay(): void {
    super.updateDisplay();

    const allDcs = this.dataAccessor.getAllConnectors();

    this.connectorInfos = allDcs.map((dc: DataConnectorDto) => {
      const info = new ConnectorInfo();
      info.title = valueToString(dc.title);
      info.connectorProperties = [
        ["Role", valueToString(dc.role)],
        ["isTimeSeries", dc.isTimeSeries.toString()]
      ].concat(_map(dc.properties, valueKeyPairToArray));
      if (dc.dataPoints != null && dc.dataPoints.length > 0) {
        info.dataPointProperties = dc.dataPoints.map((point) =>
          [
            ["x", valueToString(point.x)],
            ["y", valueToString(point.y)]
          ].concat(_map(point.properties, valueKeyPairToArray))
        );
      } else {
        info.dataPointProperties = null;
      }
      return info;
    });
  }

  public connectorInfos: ConnectorInfo[];
  public connectorProperties: string[][];

  public dataPointProperties: string[][][];
}

export class ConnectorInfo {
  public title: string;
  public connectorProperties: string[][];

  public dataPointProperties: string[][][];
}
