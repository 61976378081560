import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LocalizationService } from "../../../i18n/localization.service";
import { BaseDialogComponent } from "../base-dialog/base-dialog.component";

@Component({
  selector: "report-dialog",
  templateUrl: "./report-dialog.component.html",
  styleUrls: ["./report-dialog.component.scss"]
})
export class ReportDialogComponent extends BaseDialogComponent {
  public reportPath: string = "";
  public reportName: string = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<ReportDialogComponent>,
    public localizer: LocalizationService
  ) {
    super();
    this.reportName = data.reportName;
    this.reportPath = data.reportPath;
  }
}
