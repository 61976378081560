import { FooterFunctions } from "../models/table/table-footer-functions";

export function resolveFooterValue(footerFunction: FooterFunctions, values: number[]): number {
  switch (footerFunction) {
    case FooterFunctions.Min:
      return getMinimum(values);
    case FooterFunctions.Max:
      return getMaximum(values);
    case FooterFunctions.Cnt:
      return getLength(values);
    case FooterFunctions.Avg:
      return getAverageValue(values);
    case FooterFunctions.Var:
      return calculateSampleVarience(values);
    case FooterFunctions.StDev:
      return calculateSampleStandardDeviation(values);
    case FooterFunctions.CoefficientOfVariation:
      return calculateCoefficientOfVariation(values);
    default:
      return 0;
  }
}

export function getMinimum(values: number[]): number {
  return Math.min(...values);
}

export function getMaximum(values: number[]): number {
  return Math.max(...values);
}

export function getLength(values: number[]): number {
  return values.length;
}

export function getAverageValue(values: number[]): number {
  const sum = values.reduce((acc, value) => {
    return (acc += value);
  }, 0);

  return sum / getLength(values);
}

export function calculateSampleVarience(values: number[]): number {
  const length = values.length;
  if (length < 2) {
    return 0;
  }
  const mean = getAverageValue(values);
  return values.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) / (length - 1);
}

export function calculateSampleStandardDeviation(values: number[]): number {
  if (getLength(values) < 2) {
    return 0;
  }

  return Math.sqrt(calculateSampleVarience(values));
}

export function calculateCoefficientOfVariation(values: number[]): number {
  const mean = getAverageValue(values);
  if (mean === 0) {
    return 0;
  }
  const standardDeviation = calculateSampleStandardDeviation(values);
  return (standardDeviation / mean) * 100;
}
