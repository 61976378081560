import { Actions, ofType, createEffect } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { switchMap, map, tap } from "rxjs/operators";

import { MatDialog } from "@angular/material/dialog";
import {
  AddSignalDialogComponent,
  RemoveSignalsDialogComponent
} from "../../../components/trend/dialogs";
import { SignalData } from "../../../models/dialogs/signal-data.model";
import { OpenDialogEffect } from "../../../../shared/dialogs/effects/dialog.effects";
import { AddSignalConfigStrategy, RemoveSignalConfigStrategy } from "../dialog-config.strategies";
import { SignalDialogActions, AxisDialogActions } from "../actions";

@Injectable()
export class SignalDialogEffects extends OpenDialogEffect {
  openAddSignalDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalDialogActions.addSignalDialog),
      tap(({ chartYAxes }) => {
        this.dialogConfigStrategy = new AddSignalConfigStrategy();
        this.dialogConfig = this.dialogConfigStrategy.createDialogConfig(chartYAxes);
        this.dialogRef = this.dialog.open(AddSignalDialogComponent, this.dialogConfig);
      }),
      switchMap(() => {
        return this.dialogRef.afterClosed().pipe(
          map((signalData: SignalData) => {
            if (!signalData) {
              return AxisDialogActions.closeDialog({ dialogRef: this.dialogRef });
            }
            return SignalDialogActions.addSignalDialogSuccess({ result: signalData });
          })
        );
      })
    )
  );

  openRemoveSignalDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalDialogActions.removeSignalDialog),
      tap(({ chartYAxes }) => {
        this.dialogConfigStrategy = new RemoveSignalConfigStrategy();
        this.dialogConfig = this.dialogConfigStrategy.createDialogConfig(chartYAxes);
        this.dialogRef = this.dialog.open(RemoveSignalsDialogComponent, this.dialogConfig);
      }),
      switchMap(() => {
        return this.dialogRef.afterClosed().pipe(
          map((removeSignals: SignalData) => {
            if (!removeSignals) {
              return AxisDialogActions.closeDialog({ dialogRef: this.dialogRef });
            }
            return SignalDialogActions.removeSignalDialogSuccess({
              signalsToRemove: removeSignals
            });
          })
        );
      })
    )
  );

  constructor(private actions$: Actions, dialog: MatDialog) {
    super(dialog);
  }
}
