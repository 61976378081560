import { Component, ElementRef, Input } from "@angular/core";
import { ButtonConfig } from "../../models/button/button-config";

@Component({
  selector: "c-custom-button-dropdown",
  templateUrl: "./custom-button-dropdown.component.html",
  styleUrls: ["./custom-button-dropdown.component.scss"]
})
export class CustomButtonDropdownComponent {
  @Input() dropdownOptions: ButtonConfig[] = [];

  constructor(public hostElementRef: ElementRef) {}
}
