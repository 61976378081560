import { Injectable } from "@angular/core";
import moment, { Moment } from "moment";
import { from } from "rxjs";
import { map, reduce, skipWhile, takeWhile } from "rxjs/operators";
import { ValidationContext } from "../../meta/models/validation-context";
import { Maybe, isDefined, isEmpty, isEmptyOrNotDefined, isNotDefined } from "../../ts-utils";
import {
  AM_PM_FORMAT,
  DATE_FORMAT,
  DAY_FORMAT,
  DAY_OF_WEEK_FORMAT,
  DEFAULT_DATE_FORMAT,
  EXPANDED_DEFAULT_DATE_FORMAT_WITH_SECONDS,
  HOUR_FORMAT,
  MILISECOND_FORMAT,
  MINUTE_FORMAT,
  MINUTE_WITHOUT_LEADING_ZERO_FORMAT,
  MOMENT_AM_PM_FORMAT,
  MOMENT_MINUTE_WITHOUT_LEADING_ZERO_FORMAT,
  MOMENT_MINUTE_WITH_LEADING_ZERO_FORMAT,
  MONTH_FORMAT,
  SECOND_FORMAT,
  SEPARATORS
} from "../helpers/date-formatter.helper";
import { EnvironmentSelector } from "./environment.selector";

@Injectable()
export class DateFormatterService {
  private language: string = "";
  private format: string = "";

  constructor(private environmentSelector: EnvironmentSelector) {
    this.environmentSelector.selectCurrentLanguage().subscribe((language) => {
      this.language = language;
    });
    this.environmentSelector.selectDateFormat().subscribe((dateFormat) => {
      this.format = dateFormat;
    });
  }

  public formatDate(timestamp: Date | string, dateFormat?: Maybe<string>): string {
    return timestamp
      ? moment(new Date(timestamp))
          .locale(this.language)
          .format(this.getDateFormat(dateFormat ?? this.format))
      : "";
  }

  public getDateFormat(dateFormat: string): string {
    if (isEmptyOrNotDefined(dateFormat) || dateFormat === this.format) {
      return this.convertDateFormat(this.format);
    }
    return this.convertDateFormat(dateFormat);
  }

  public convertDateFormat(dateFormat: string): string {
    const splitDateFormat: Maybe<RegExpMatchArray> = splitCustomDateFormat(dateFormat);
    if (isNotDefined(splitDateFormat)) {
      return this.format;
    }
    const convertedDateFormat = splitDateFormat.map((format: string) =>
      this.convertOneFormat(format)
    );
    return joinSeparateFormatIntoMomentFormat(convertedDateFormat);
  }

  public convertOneFormat(format: string): string {
    if (
      format.match(SEPARATORS) ||
      format.match(SECOND_FORMAT) ||
      format.match(MILISECOND_FORMAT) ||
      format.match(HOUR_FORMAT)
    ) {
      return format;
    } else if (format.match(MONTH_FORMAT)) {
      return this.convertMonthFormat(format);
    } else if (format.match(DAY_FORMAT)) {
      return this.convertDayFormat(format);
    } else if (format.match(MINUTE_FORMAT)) {
      return this.convertMinuteFormat(format);
    } else if (format.match(AM_PM_FORMAT)) {
      return MOMENT_AM_PM_FORMAT;
    } else {
      return format;
    }
  }

  public convertMonthFormat(format: string): string {
    return format.toUpperCase();
  }

  public convertDayFormat(format: string): string {
    if (format.match(DAY_OF_WEEK_FORMAT)) {
      return format.toLowerCase();
    }
    return format.toUpperCase();
  }

  public convertMinuteFormat(format: string): string {
    if (format.match(MINUTE_WITHOUT_LEADING_ZERO_FORMAT)) {
      return MOMENT_MINUTE_WITHOUT_LEADING_ZERO_FORMAT;
    }
    return MOMENT_MINUTE_WITH_LEADING_ZERO_FORMAT;
  }

  formatMonthAndDay(timestamp: Date): string {
    const selectedMoment = moment(timestamp);
    return `${selectedMoment.date()}/${selectedMoment.month() + 1}`;
  }

  public getDateFormatString(): string {
    const longDateFormat = moment
      .localeData(this.language)
      .longDateFormat(this.format as moment.LongDateFormatKey);
    // if this.format is not a valid LongDateFormat, the method returns undefined
    return longDateFormat || this.convertDateFormat(this.format);
  }

  public isDateInvalid(date: string): boolean {
    return !this.createMomentFromString(date).isValid();
  }

  public createMomentFromString(date: string): Moment {
    const momentFormat = this.getDateFormat(this.format);
    return moment(date, momentFormat, true);
  }

  public getDatePartOfDateFormat(): string {
    const expandedDefaultFormat = moment
      .localeData(this.language)
      .longDateFormat(this.format as moment.LongDateFormatKey);
    const fullFormat = expandedDefaultFormat || this.format;
    const splitDateFormat: Maybe<RegExpMatchArray> = splitCustomDateFormat(fullFormat);
    if (isNotDefined(splitDateFormat)) {
      return this.format;
    }
    let returnedFormat = "";
    from(splitDateFormat)
      .pipe(
        takeWhile((format) => !format.match(HOUR_FORMAT)),
        map((format) =>
          isDefined(expandedDefaultFormat) ? format : this.convertOneFormat(format)
        ),
        reduce((acc: Array<string>, format: string) => {
          acc.push(format);
          return acc;
        }, [])
      )
      .subscribe((formatParts) => {
        returnedFormat = joinSeparateFormatIntoMomentFormat(formatParts);
      });
    return returnedFormat;
  }

  public getTimePartOfDateFormat(): string {
    const fullFormat =
      this.format === DEFAULT_DATE_FORMAT ? EXPANDED_DEFAULT_DATE_FORMAT_WITH_SECONDS : this.format;
    const splitDateFormat: Maybe<RegExpMatchArray> = splitCustomDateFormat(fullFormat);
    if (isNotDefined(splitDateFormat)) {
      return this.format;
    }
    let returnedFormat = "";
    from(splitDateFormat)
      .pipe(
        skipWhile((format) => !format.match(HOUR_FORMAT)),
        map((format) =>
          this.format === DEFAULT_DATE_FORMAT ? format : this.convertOneFormat(format)
        ),
        reduce((acc: Array<string>, format: string) => {
          acc.push(format);
          return acc;
        }, [])
      )
      .subscribe((formatParts) => {
        returnedFormat = joinSeparateFormatIntoMomentFormat(formatParts);
      });
    return returnedFormat;
  }

  public getTimePartOfDateFormatWithoutSeconds(): string {
    const fullFormat = this.getTimePartOfDateFormat();
    const splitDateFormat: Maybe<RegExpMatchArray> = splitCustomDateFormat(fullFormat);
    if (isNotDefined(splitDateFormat)) {
      return this.format;
    }
    const secondsFormatIndex = splitDateFormat.findIndex((formatPart) =>
      formatPart.match(SECOND_FORMAT)
    );
    if (secondsFormatIndex > -1) {
      splitDateFormat[secondsFormatIndex] = "";
      splitDateFormat[secondsFormatIndex - 1] = "";
    }
    return joinSeparateFormatIntoMomentFormat(splitDateFormat);
  }
}

export function validateDateFormat(format: string, _validationContext: ValidationContext): boolean {
  return validateFormat(format);
}

export function validateFormat(format: string): boolean {
  if (isEmpty(format)) {
    return true;
  }
  const splitDateFormat: Maybe<RegExpMatchArray> = splitCustomDateFormat(format);
  if (isNotDefined(splitDateFormat)) {
    return false;
  }
  const match = splitDateFormat.every((format) => isFormatValid(format));
  return match;
}

export function splitCustomDateFormat(dateFormat: string): Maybe<RegExpMatchArray> {
  if (isEmptyOrNotDefined(dateFormat)) {
    return null;
  }
  return dateFormat.split(SEPARATORS) as RegExpMatchArray;
}

export function isFormatValid(format: string): boolean {
  const isValidFormat = DATE_FORMAT.exec(format);
  if (isDefined(isValidFormat)) {
    return true;
  }
  return isSeparator(format);
}

export function isSeparator(format: string): boolean {
  return (
    format === "." ||
    format === "-" ||
    format === "/" ||
    format === " " ||
    format === "" ||
    format === ":"
  );
}

export function joinSeparateFormatIntoMomentFormat(formats: string[]): string {
  let dateFormat: string = "";
  formats.forEach((format) => (dateFormat += format));
  return dateFormat;
}
