import { Action, createReducer, on } from "@ngrx/store";
import { cloneDeep as _cloneDeep } from "lodash";
import { CustomFilterDescriptorDto } from "../../../core/models/filter/custom-filter-descriptor";
import { GeneralSettingsDto } from "../../../core/models/general-settings";
import { TypeProvider } from "../../../meta";
import { DeepPartial, Maybe, assignDeep, isNotDefined, mergeDeep } from "../../../ts-utils";
import { CommonActions } from "../common";
import { GeneralSettingsActions } from "./general-settings.actions";
import { GeneralSettingsState } from "./general-settings.state";

export const initialState: GeneralSettingsState =
  TypeProvider.getDefaultsForType(GeneralSettingsDto);

export function reducer(state: GeneralSettingsState, action: Action) {
  return _reducer(state, action);
}

const _reducer = createReducer(
  initialState,
  on(GeneralSettingsActions.update, (state, { changes: generalSettings }) =>
    update(state, generalSettings)
  ),
  on(GeneralSettingsActions.setRootPath, (state, { rootPath }) => setRootPath(state, rootPath)),
  on(GeneralSettingsActions.setRootClass, (state, { rootClass }) => setRootClass(state, rootClass)),
  on(CommonActions.resetStore, (state, { reportConfiguration }) =>
    setSettingsOrDefault(_cloneDeep(reportConfiguration.content?.generalSettings ?? {}))
  ),
  on(CommonActions.upsertEntities, (state, { reportEntities }) =>
    onUpsertEntities(state, _cloneDeep(reportEntities.generalSettings))
  ),
  on(CommonActions.upsertEntitiesOnLoad, (state, { reportEntities }) =>
    updateCustomFilterDeclarations(state, reportEntities.generalSettings)
  ),
  on(CommonActions.replaceAll, (state, { entities }) =>
    onReplaceAll(entities.generalSettings as GeneralSettingsDto)
  )
);

function update(
  state: GeneralSettingsState,
  generalSettingsUpdate: DeepPartial<GeneralSettingsDto>
): GeneralSettingsState {
  return assignDeep(state, generalSettingsUpdate);
}

function setRootPath(state: GeneralSettingsState, newRootPath: string): GeneralSettingsState {
  return { ...state, rootPath: newRootPath };
}

function onUpsertEntities(
  state: GeneralSettingsState,
  generalSettingsUpdate: DeepPartial<GeneralSettingsDto>
): GeneralSettingsState {
  return assignDeep(state, {
    ...generalSettingsUpdate,
    rootClass: state.rootClass,
    rootPath: state.rootPath
  });
}

function updateCustomFilterDeclarations(
  state: GeneralSettingsState,
  generalSettings: Maybe<Partial<GeneralSettingsDto>>
): GeneralSettingsState {
  if (isNotDefined(generalSettings)) {
    return state;
  }
  const customFilterDeclarations = _cloneDeep(generalSettings.customFilterDeclarations);
  if (isNotDefined(customFilterDeclarations)) {
    return state;
  }
  const merged = assignDeep(state.customFilterDeclarations, customFilterDeclarations);
  return { ...state, customFilterDeclarations: merged };
}

function setRootClass(state: GeneralSettingsState, newRootClass: string): GeneralSettingsState {
  return { ...state, rootClass: newRootClass };
}

function setSettingsOrDefault(newSettings: Partial<GeneralSettingsState>): GeneralSettingsState {
  let customFilters: CustomFilterDescriptorDto[] = newSettings?.customFilterDeclarations ?? [];
  customFilters =
    customFilters.map(
      (customFilterPartial) => new CustomFilterDescriptorDto(customFilterPartial)
    ) ?? [];
  const mergedSettings = mergeDeep(initialState, newSettings);
  return mergedSettings;
}

function onReplaceAll(newGeneralSettings: GeneralSettingsDto): GeneralSettingsState {
  return { ...newGeneralSettings };
}
