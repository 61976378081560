<div
  class="runtime-range__container"
  [class.runtime-range__container--vertical]="!canExpandHorizontally"
  (clickOutside)="closeIfDropdown()"
>
  <div [class.element--hidden]="isFilterBarSelected && canExpandHorizontally">
    <filter-toolbar-element
      [buttonConfig]="toggleButton"
      [selectedValueLabel]="filterElementTitle"
      [isDropdown]="!canExpandHorizontally"
      [isOpened]="isFilterBarSelected"
    ></filter-toolbar-element>
  </div>
  <div
    class="runtime-range--expanded"
    [class.runtime-range--expanded-horizontal]="canExpandHorizontally"
    *ngIf="isFilterBarSelected"
  >
    <div
      [ngClass]="
        canExpandHorizontally ? 'runtime-range__filter-bar' : 'runtime-range__filter-bar--vertical'
      "
    >
      <div class="runtime-range__filter-bar-icon" [class.element--hidden]="!canExpandHorizontally">
        <i [class]="'abb-icon abb-icon--medium ' + icon"></i>
      </div>
      <div
        [ngClass]="
          canExpandHorizontally ? 'runtime-range__filters' : 'runtime-range__filters--vertical'
        "
      >
        <div
          [ngClass]="
            canExpandHorizontally
              ? 'runtime-range__live-mode-wrapper'
              : 'runtime-range__live-mode-wrapper--vertical'
          "
        >
          <div class="runtime-range__live-mode">
            <toggle-switch
              [value]="isLiveMode"
              label="{{ localizer.filterComponent.LiveModeOn | translate }}"
              (changeValue)="onChangeLiveMode($event)"
            ></toggle-switch>
          </div>
        </div>
        <div
          [ngClass]="
            canExpandHorizontally
              ? 'runtime-date-time-picker'
              : 'runtime-date-time-picker--vertical'
          "
          [class.runtime-date-time-picker--live-mode-on]="isLiveMode"
        >
          <div class="runtime-range__date-time-picker-item">
            <div class="runtime-range__picker-item" *ngIf="isLiveMode; else liveModeOff">
              <div
                class="runtime-range__date-time-picker-icon"
                [class.element--hidden]="!canExpandHorizontally"
              >
                <i class="abb-icon abb-icon--large Time"></i>
              </div>
              <live-mode-filter
                [liveModeFilter]="liveModeFilter"
                (onChangeValue)="updateLiveModeFilter($event)"
              ></live-mode-filter>
            </div>
            <ng-template #liveModeOff>
              <div
                [ngClass]="
                  canExpandHorizontally
                    ? 'runtime-range__picker-item--live-mode-off'
                    : 'runtime-range__picker-item--vertical'
                "
              >
                <div
                  class="runtime-range__input"
                  *ngIf="canExpandHorizontally; else expandVertically"
                >
                  <button
                    class="app-filter-button date-time-picker__button"
                    [class.date-time-picker__button--selected]="isDateTimePickerOpened"
                    (click)="showOrHideDateTimePicker(!isDateTimePickerOpened, $event)"
                    title="{{ localizer.filterComponent.DateTimePicker | translate }}"
                  >
                    <i class="abb-icon abb-icon--large Time_and_date"></i>
                  </button>
                  <input
                    #timeRangeInput
                    class="filter__date-time"
                    [ngClass]="{ 'filter__date-time--error': !isTimeRangeValid }"
                    [(ngModel)]="historicTimeRangeInput"
                    (ngModelChange)="timeRangeUpdate.next($event)"
                    (click)="showOrHideDateTimePicker(true, $event)"
                    title="{{ dateTimeFormat }}"
                  />
                </div>
                <ng-template #expandVertically>
                  <div class="runtime-range__editor">
                    <historic-range
                      [timeRange]="historicTimeRange"
                      [horizontalLayout]="false"
                      (onChangeTimeRange)="onSelectTimeRange($event)"
                    >
                    </historic-range>
                  </div>
                </ng-template>
                <button
                  *ngIf="canApplyFilterChanges"
                  class="abb-icon Check_mark app-filter-blue-button"
                  (click)="applyChanges()"
                  title="{{ localizer.filterComponent.Apply | translate }}"
                ></button>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <button
        class="app-filter-button app-filter-button__collapse"
        tabindex="-1"
        [ngClass]="{ 'element--hidden': !canExpandHorizontally, selected: isFilterBarSelected }"
        (click)="closeBar($event)"
      >
        <i class="abb-icon abb-icon--medium Left"></i>
      </button>
    </div>
    <div *ngIf="canExpandHorizontally && !isLiveMode">
      <date-time-range-picker
        [isOpened]="isDateTimePickerOpened"
        [dateTimeRange]="historicTimeRange"
        (onChangeDisplay)="hideDateTimePicker()"
        (onDateTimeChange)="onSelectDateTimePicker($event)"
      ></date-time-range-picker>
    </div>
  </div>
</div>
