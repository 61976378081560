import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap, tap } from "rxjs/operators";
import { CreateReportComponent } from "../../components/create-report/create-report.component";
import { ReportCreationParams } from "../../models/report-creation-params";
import { CreateReportDialogActions } from "../actions/create-report-dialog.actions";
import { CreateReportConfigStrategy } from "../dialog-config.strategies";
import { OpenDialogEffect } from "./dialog.effects";

@Injectable()
export class CreateReportDialogEffects extends OpenDialogEffect {
  constructor(private actions$: Actions, dialog: MatDialog) {
    super(dialog);
  }

  openCreateReportDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateReportDialogActions.openCreateReportDialog),
      tap(({ reportDialogInfo: reportCreationInfo }) => {
        this.dialogConfigStrategy = new CreateReportConfigStrategy();
        this.dialogConfig = this.dialogConfigStrategy.createDialogConfig(reportCreationInfo);
        this.dialogRef = this.dialog.open(CreateReportComponent, this.dialogConfig);
      }),
      switchMap(() => {
        return this.dialogRef.afterClosed().pipe(
          map((result: ReportCreationParams) => {
            return CreateReportDialogActions.onCreateReportDialogClosed({
              reportCreationInfo: result
            });
          })
        );
      })
    )
  );
}
