import { DynamicButtonType } from "../../../elements/models/dynamic-button-type";
import { Maybe } from "../../../ts-utils";

// @dynamic
export class ButtonStyle {
  public buttonType: DynamicButtonType;
  public buttonClass: string;
  public iconClass: string;
  public icon: string;
  public position: Maybe<number>;
  public title: string;

  constructor(params: Partial<ButtonStyle> = {}) {
    this.buttonType = params.buttonType ?? DynamicButtonType.Config;
    this.buttonClass = params.buttonClass ?? "";
    this.iconClass = params.iconClass ?? "";
    this.icon = params.icon ?? "";
    this.position = params.position ?? null;
    this.title = params.title ?? "";
  }
}
