import {
  BaseViewConfigDto,
  ComponentCssSize,
  Configurable,
  construct,
  DeepPartial,
  EditableType,
  EditorType,
  Serializable
} from "ui-core";

const TYPE_NAME = "PoleAlignmentChartViewConfig";

@EditableType({ fullName: TYPE_NAME })
export class PoleAlignmentChartViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  @Configurable({ displayName: "Smallest Airgap Color", editorType: EditorType.ColorPicker })
  @Serializable("#4db82c")
  smallestAirgapColor!: string; // smallest airgap kolor itd

  @Configurable({ displayName: "Average Airgap Color", editorType: EditorType.ColorPicker })
  @Serializable("#843c9b")
  averageAirgapColor!: string;

  @Configurable({ displayName: "Largest Airgap Color", editorType: EditorType.ColorPicker })
  @Serializable("#FF0000")
  largestAirgapColor!: string;

  @Configurable({ displayName: "Poles Color", editorType: EditorType.ColorPicker })
  @Serializable("#3FB6CC")
  polesColor!: string;

  @Configurable({ displayName: "Subtitle", editorType: EditorType.TextBox })
  @Serializable("Measurements based on # sensor")
  subtitle!: string;

  constructor(viewConfigDto: DeepPartial<PoleAlignmentChartViewConfig> = {}) {
    super();
    construct(this, viewConfigDto, TYPE_NAME, { size: getDefaultSize() });
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("475", "375");
}
