import { createAction, props } from "@ngrx/store";
import { DialogData } from "../../../models/dialogs/dialog-data.model";
import { MatDialogRef } from "@angular/material/dialog";

export namespace AxisDialogActions {
  export const addAxisDialog = createAction("[Dialog] Add Axis Dialog");

  export const addAxisDialogSuccess = createAction(
    "[Dialog] Add Axis Dialog Success",
    props<{ result: DialogData }>() // FIXME Rename param and class, both names are too generic
  );

  export const removeAxisDialog = createAction(
    "[Dialog] Remove Axis Dialog",
    props<{ chartYAxes: any[] }>()
  );

  export const removeAxisDialogSuccess = createAction(
    "[Dialog] Remove Axis Dialog Success",
    props<{ axisIds: string[] }>()
  );

  // FIXME This is a general dialog action, and doesn't belong here
  export const closeDialog = createAction(
    "[Dialog] Close Dialog",
    props<{ dialogRef: MatDialogRef<any> }>()
  );
}
