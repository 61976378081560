import { IconSource } from "../icon-source";

export class HeaderButtonConfig {
  public title: string = "";
  public isDisabled: boolean = false;
  public hasDropdown: boolean = false;
  public iconSource: IconSource = IconSource.Abb;
  public clickFunction: Function;
  public clickEventFunction: Function;

  constructor(params: Partial<HeaderButtonConfig> = {}) {
    this.title = params.title ?? "";
    this.isDisabled = params.isDisabled ?? false;
    this.hasDropdown = params.hasDropdown ?? false;
    this.iconSource = params.iconSource ?? IconSource.Abb;
    this.clickFunction = params.clickFunction ?? (() => "");
    this.clickEventFunction = params.clickEventFunction ?? (() => "");
  }
}
