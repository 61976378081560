import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { catchError, filter, switchMap, takeUntil, tap } from "rxjs/operators";
import {
  BaseComponent,
  ComponentConstructorParams,
  ConnectorRoles,
  CriticalError,
  EditableType,
  LayoutBuilder,
  View
} from "ui-core";
import { ApiResponse } from "../../../models/api/api-response";
import { Continent } from "../../../models/api/hierarchy";
import { IProjectHierarchyWithStatus } from "../../../models/motor-response.interface";
import { MotorModel } from "../../../models/side-nav/motor.model";
import { FleetViewDataService } from "../../../services/sidebar/fleet-view.service";
import {
  clientDataSelector,
  hierarchySelector
} from "../../../store/selectors/hierarchy-collection.selector";
import { RdsComponentCategory } from "../../rds-component-category";
import { FleetListViewConfig } from "./view-config";

@Component({
  selector: "rds-fleet-list",
  templateUrl: "./fleet-list.component.html",
  styleUrls: ["./fleet-list.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: FleetListComponent }]
})
@LayoutBuilder(RdsComponentCategory.Fleet, "FleetListComponent", "Dashboard_2")
@ConnectorRoles()
@EditableType({ fullName: "FleetListComponent", title: "fleet-list" })
export class FleetListComponent extends BaseComponent implements OnInit {
  @ViewChild(MatPaginator)
  paginator?: MatPaginator;

  dataSource = new MatTableDataSource([] as MotorModel[]);

  filtersSelectorsMap = new Map<string, string>();

  motors: MotorModel[] = [];

  displayedColumns: string[] = [
    "country",
    "projectName",
    "systemName",
    "status",
    "lastUpdateTime",
    "orYear",
    "icYear",
    "mineral",
    "millSupplier"
  ];

  displayedFilterColumns: string[] = [];

  columnsMap = new Map<string, string[]>();
  columnsMapClone = new Map<string, string[]>();
  filteredMotors: MotorModel[] = [];
  unsubscribeMotors$ = new Subject();
  continents: Continent[] = [];

  constructor(
    params: ComponentConstructorParams,
    hostElementRef: ElementRef,
    private fleetViewDataService: FleetViewDataService,
    private store$: Store<any>
  ) {
    super(params, hostElementRef);
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.createFilterColumns();
    this.getMotorsData$().subscribe();
  }

  @View(FleetListViewConfig)
  public get view(): FleetListViewConfig {
    return this.currentState.view as FleetListViewConfig;
  }

  createFilterColumns(): void {
    this.displayedFilterColumns = this.displayedColumns.map((columnName: string) => {
      return columnName.concat("-filter");
    });
  }

  getMotorsData$(): Observable<ApiResponse[]> {
    return this.store$.select(hierarchySelector).pipe(
      filter((response: IProjectHierarchyWithStatus) => response.continents.length > 0),
      switchMap((response: IProjectHierarchyWithStatus) => {
        this.continents = response.continents;
        return this.store$.select(clientDataSelector);
      }),
      filter((response: ApiResponse[]) => response.length > 1),
      tap((response: ApiResponse[]) => {
        this.motors = this.fleetViewDataService.flatMotors(this.continents, response);
        this.dataSource = new MatTableDataSource(this.motors);
        this.displayedColumns.forEach((column) => {
          this.columnsMap.set(column, [
            "All",
            ...new Set(
              this.motors.map(
                (motor) => motor[column as keyof MotorModel] as MotorModel["systemName"]
              )
            )
          ]);
        });
        this.columnsMapClone = new Map(this.columnsMap);
      }),
      catchError((error) => {
        throw new CriticalError(error.message);
      }),
      takeUntil(this.unsubscribeMotors$)
    );
  }

  getKeys(key: string): string[] | undefined {
    return this.columnsMap.get(key);
  }

  updateFilters(value: string, columnName: string): void {
    if (value === "All") {
      this.resetFilteredMotors();
    }

    this.filtersSelectorsMap.set(columnName, value);
    this.dataSource.filter = value;
  }

  resetFilteredMotors(): void {
    this.filteredMotors = [];
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.unsubscribeMotors$.next();
    this.unsubscribeMotors$.complete();
  }
}
