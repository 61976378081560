import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { Dispatcher } from "../../dispatcher";
import { CriticalError } from "../../ts-utils/models/critical-error";
import { ErrorCatchingActions } from "../store/error-catching";
import { ErrorLogger } from "./error-logger";

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  // ErrorHandler is created before the providers. Adding Dispatcher as constructor parameter gives a circular dependency.
  constructor(private injector: Injector) {
    super();
  }

  // TODO error object can be circular which means it cannot be stringified.
  // Find a way to cut the circularity so that error can be sent to store. Will be used when user can send error logs.
  handleError(error: Error): void {
    super.handleError(error); // writes to console
    const errorLogger: ErrorLogger = this.injector.get(ErrorLogger);

    if ((error as CriticalError).isCritical) {
      const dispatcher: Dispatcher = this.injector.get(Dispatcher);

      dispatcher.dispatch(
        ErrorCatchingActions.catchError({
          messageToDisplay: "An error has occurred. Please reload the page.",
          error: error,
          autoClose: false
        })
      );
    } else {
      //Critical errors are logged in catch and handle error effects
      errorLogger.add("An error has occurred", error);
    }
  }
}
