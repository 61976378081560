import { createSelector } from "@ngrx/store";
import { BrowsingState } from "../browsing.state";
import { getCachedFeature } from "../feature.selector";

export const areReportIdsInState = createSelector(getCachedFeature, (state: BrowsingState) => {
  const idsOfReports = state.allReportDescriptions;
  const stateContainsReportIds: boolean = idsOfReports.length !== 0;
  return stateContainsReportIds;
});

export const getReportIds = createSelector(getCachedFeature, (state: BrowsingState) => {
  return state.allReportDescriptions;
});
