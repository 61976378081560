import { Injectable } from "@angular/core";
import { ComponentCssSize } from "../../elements";
import {
  FULL_HEIGHT_CSS_SIZE,
  FULL_WIDTH_CSS_SIZE
} from "../../elements/models/component-size.constants";
import { isCard, isPage } from "../../elements/models/component-type.helper";
import { isDefined } from "../../ts-utils";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

const OLD_PAGE_GRID_SQUARE_SIDE: number = 75;

const FULL_WIDTH_SPAN_SIZE: number = -1;
const FULL_HEIGHT_SPAN_SIZE: number = -1;
@Injectable()
export class UpgradeComponentSize implements UpgradeStep {
  name = "UpgradeComponentSize";
  fromVersion = new Version(4, 0, 5);

  perform(oldConfig: any): UpgradeStepResult {
    const components = getComponents(oldConfig);
    let changed = false;
    components.forEach((component) => {
      const view = component["view"];
      if (isDefined(view)) {
        const size = view["size"];
        if (isDefined(size)) {
          view["size"] = upgradeToCssSize(size.columns, size.rows, size.gapSize);
          changed = true;
        }
        const expandedSize = view["expandedSize"];
        if (isDefined(expandedSize)) {
          view["expandedSize"] = upgradeToCssSize(
            expandedSize.columns,
            expandedSize.rows,
            expandedSize.gapSize
          );
          changed = true;
        }
        const collapsedSize = view["collapsedSize"];
        if (isDefined(collapsedSize)) {
          view["collapsedSize"] = upgradeToCssSize(
            collapsedSize.columns,
            collapsedSize.rows,
            collapsedSize.gapSize
          );
          changed = true;
        }
      }
    });
    return {
      result: oldConfig,
      modified: changed,
      warning: null
    };
  }
}

function getComponents(oldConfig: any): any[] {
  const componentStates: any = oldConfig["componentStates"]["entities"];
  const components: any[] = Object.values(componentStates).filter(
    (componentState) => isCard(componentState["type"]) || isPage(componentState["type"])
  );
  return components;
}

function upgradeToCssSize(
  columns: number,
  rows: number,
  gapSize: number
): Partial<ComponentCssSize> {
  return {
    width: convertToCssWidth(columns, gapSize),
    height: convertToCssHeight(rows, gapSize)
  };
}

function convertToCssWidth(columns: number, gapSize: number): string {
  if (columns === FULL_WIDTH_SPAN_SIZE) {
    return FULL_WIDTH_CSS_SIZE;
  }
  return `${columns * (OLD_PAGE_GRID_SQUARE_SIDE + gapSize) - gapSize}`;
}

function convertToCssHeight(rows: number, gapSize: number): string {
  if (rows === FULL_HEIGHT_SPAN_SIZE) {
    return FULL_HEIGHT_CSS_SIZE;
  }
  return `${rows * (OLD_PAGE_GRID_SQUARE_SIDE + gapSize) - gapSize}`;
}
