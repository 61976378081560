import { Injectable } from "@angular/core";
import { SelectionOption } from "../../meta";

@Injectable()
export class IGenericDataSourceService {
  public getColumns(target: any): SelectionOption[] {
    throw new Error("Not implemented");
  }

  public isDataConnector(target: any): boolean {
    throw new Error("Not implemented");
  }

  public getSelectedEntity(component: any): string {
    throw new Error("Not implemented");
  }
}
