import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Maybe } from "../../../ts-utils/models/maybe.type";

@Component({
  selector: "selector",
  templateUrl: "selector.component.html",
  styleUrls: ["./selector.component.scss"]
})
export class SelectorComponent {
  @Input() items: string[] = [];
  @Input() selectedItem: string;
  @Input() distinctDefault: boolean = false;
  @Output() onChangeValue: EventEmitter<any> = new EventEmitter();
  @ViewChild("selectElement")
  selectElement?: ElementRef<HTMLInputElement>;

  public onValueChange(item: string): void {
    this.selectedItem = item;
    this.onChangeValue.emit(this.selectedItem);
  }

  getSelectElement(): Maybe<ElementRef<HTMLInputElement>> {
    return this.selectElement;
  }
}
