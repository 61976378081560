export class FilterItem {
  isExpanded: boolean;
  expandedWidth: number;
  collapsedWidth: number;
  canExpandHorizontally: boolean;

  constructor(params: Partial<FilterItem> = {}) {
    this.isExpanded = params.isExpanded ?? false;
    this.expandedWidth = params.expandedWidth ?? 0;
    this.collapsedWidth = params.collapsedWidth ?? 0;
    this.canExpandHorizontally = params.canExpandHorizontally ?? false;
  }
}
