<div class="equipment-property-browser__container" [@enterAnimation]>
  <div class="equipment-property-browser__header-wrapper">
    <div class="equipment-property-browser__header">
      <div class="equipment-property-browser__path">
        <span>{{ selectedEquipment?.path }}</span>
      </div>
      <i
        class="sidebar__header-button abb-icon abb-icon--small Close"
        (click)="close($event)"
        tabIndex="0"
      ></i>
    </div>
    <span class="equipment-property-browser__title">({{ selectedEquipment?.name }})</span>
  </div>
  <div class="equipment-property-browser__label">
    <span>
      {{ localizer.sidebar.Properties | translate }}
    </span>
    <div class="equipment-property-browser__label-recursive">
      <toggle-switch
        label="{{ localizer.sidebar.Recursive | translate }}"
        [value]="recursiveChecked"
        (changeValue)="recursiveCheckBoxChanged($event)"
      ></toggle-switch>
    </div>
  </div>
  <div *ngIf="showProgressBar">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <div class="equipment-property-browser__content" *ngIf="showProperties">
    <div
      class="equipment-property-browser__item"
      *ngFor="let property of equipmentProperties"
      draggable="true"
      (mousedown)="selectedEquipmentPropertyChanged(property)"
      [class.equipment-property-browser__item--active]="isSelectedItem(property)"
      (touchmove)="touchMove($event)"
      (touchend)="dropOnTouchDevices($event)"
      (drag)="dragStart($event, property)"
      (touchstart)="dragStart($event, property)"
      (dragend)="dragEnd()"
    >
      <div class="equipment-property-browser__item-name">
        <span *ngIf="property.name">{{ getPropertyNameBasedOnAliasMode(property) }}</span>
        <span class="equipment-property-class">
          {{ resolveClassName(property) }}
        </span>
      </div>
      <div class="equipment-property-browser__item-unit">
        <span *ngIf="property.unit">{{ property.unit }}</span>
      </div>
    </div>
  </div>
</div>
