import { Store } from "@ngrx/store";
import { LocalizationService } from "../../../i18n/localization.service";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import {
  ConfigurableEnum,
  ConfigurationCategory,
  EditableType,
  Serializable
} from "../../../meta/decorators";
import { EditorType, PropertyCategory, SelectionOption } from "../../../meta/models";
import { getColumns, getEntityColumns } from "../../helpers/generic-data-source-entities.helper";
import { TABULAR_DATA_SOURCE, TabularDataSourceType } from "./data-source.type";
import { GenericDataSourceDto, isEmptyGenericSource } from "./generic-data-source";

@EditableType({ fullName: TABULAR_DATA_SOURCE, title: "tabular-data-source-dto" })
export class TabularDataSourceDto extends GenericDataSourceDto implements GenericDataSourceDto {
  typeName: TabularDataSourceType = TABULAR_DATA_SOURCE;

  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.DataSource)
  @ConfigurableEnum({
    enumSource: getColumns,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Columns ?? "",
    editorType: EditorType.EditableSelectList,
    allowMultipleSelection: true
  })
  @Serializable()
  public columns: string[];
}

export function isEmptyTabularSource(dataSource: TabularDataSourceDto): boolean {
  return (
    isEmptyGenericSource(dataSource) ||
    dataSource.columns == null ||
    dataSource.columns.length === 0
  );
}

export function getTabularSourceColumns(
  store: Store,
  dataSource: TabularDataSourceDto,
  localizationService: LocalizationService
): SelectionOption[] {
  return getEntityColumns(store, dataSource.entity, () => true, localizationService);
}
