<ng-container *ngIf="!isCollapsed; else asDropdown">
  <div class="undo-redo__container">
    <header-button [buttonConfig]="undoButtonConfig" icon="Undo"></header-button>
    <header-button [buttonConfig]="redoButtonConfig" icon="Redo"></header-button>
  </div>
</ng-container>

<ng-template #asDropdown>
  <div (clickOutside)="closeMenu()">
    <header-button [buttonConfig]="dropdownButtonConfig" [isSelected]="isMenuOpened" icon="Undo">
    </header-button>
    <ul *ngIf="isMenuOpened" class="undo-redo__menu">
      <header-button
        [buttonConfig]="undoButtonConfig"
        icon="Undo"
        [verticallyOpened]="true"
        [showLabel]="true"
      >
      </header-button>
      <header-button
        [buttonConfig]="redoButtonConfig"
        icon="Redo"
        [verticallyOpened]="true"
        [showLabel]="true"
      >
      </header-button>
    </ul>
  </div>
</ng-template>
