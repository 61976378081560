import { DataConnectorRoles } from "ui-core";

export const Roles: DataConnectorRoles = {
  GridA: {
    name: "Grid-A"
  },
  GridB: {
    name: "Grid-B"
  },
  SwitchgearA: {
    name: "Switchgear-A"
  },
  SwitchgearB: {
    name: "Switchgear-B"
  },
  TransformersA: {
    name: "Transformers-A"
  },
  TransformersB: {
    name: "Transformers-B"
  },
  EHouseA: {
    name: "EHouse-A"
  },
  EHouseB: {
    name: "EHouse-B"
  },
  MotorA: {
    name: "Motor-A"
  },
  MotorB: {
    name: "Motor-B"
  }
};
