import { AfterViewInit, Component, Inject, OnDestroy, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatListOption, MatSelectionList, MatSelectionListChange } from "@angular/material/list";
import { from, of, Subject } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import { BaseDialogComponent } from "../../../../../shared/components/base-dialog/base-dialog.component";

@Component({
  selector: "c-remove-axis-dialog",
  templateUrl: "./remove-axis-dialog.component.html",
  styleUrls: ["./remove-axis-dialog.component.scss"]
})
export class RemoveAxisDialogComponent
  extends BaseDialogComponent
  implements AfterViewInit, OnDestroy
{
  @ViewChild("axisList") axisList: MatSelectionList;
  public axisArray: any[] = [];
  public notSelectedAxis: boolean;
  unsubscribeSubject$: Subject<any> = new Subject<any>();
  axisPresent: boolean;
  ngAfterViewInit(): void {
    // changes disabled status of submit button on selection of axis
    if (!!this.axisList) {
      from(this.axisList.selectionChange)
        .pipe(
          switchMap((value: MatSelectionListChange) => {
            const hasAxisSelected: boolean =
              value.source.selectedOptions.selected.length > 0 ? true : false;
            return of(hasAxisSelected);
          }),
          takeUntil(this.unsubscribeSubject$)
        )
        .subscribe((selectedAxis: boolean) => (this.notSelectedAxis = !selectedAxis));
    }
  }
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<RemoveAxisDialogComponent>
  ) {
    super();
    this.axisArray = data.axisArray;
    this.axisPresent = this.axisArray.length !== 0 ? true : false;
    this.notSelectedAxis = true;
  }
  onDialogClosed(): void {
    if (!!this.axisList) {
      const axisListToRemove: string[] = this.getValuesFromListOptions(
        this.axisList.selectedOptions.selected
      );

      this.dialogRef.close(axisListToRemove);
    }
  }
  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }
  public getValuesFromListOptions(listOptions: MatListOption[]): string[] {
    return listOptions.map((listOption) => listOption.value);
  }
}
