import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { MatCalendar } from "@angular/material/datepicker";
import { isEqual as _isEqual } from "lodash";
import moment from "moment";
import {
  MOMENT_AM_PM_FORMAT,
  MOMENT_SECOND_TOKEN
} from "../../../environment/helpers/date-formatter.helper";
import { LocalizationService } from "../../../i18n/localization.service";
import { isDefined } from "../../../ts-utils/helpers";
import { updateDate, updateTime } from "../../helpers/date-time-picker.helper";

@Component({
  selector: "date-time-picker",
  templateUrl: "date-time-picker.component.html",
  styleUrls: ["./date-time-picker.component.scss"]
})
export class DateTimePickerComponent implements OnInit {
  @Input() dateTimeFormat: string = "";
  @Input() set date(value: Date) {
    this._dateTime = value;
    this.time = moment(this.date);
    this.updateCalendar(this.date);
  }
  get date(): Date {
    return this._dateTime;
  }
  @Output() onDateTimeChange: EventEmitter<any> = new EventEmitter();
  @Output() onChangeDisplay: EventEmitter<Event> = new EventEmitter();
  @ViewChild("calendar", { static: false }) calendar!: MatCalendar<Date>;

  private _dateTime!: Date;
  time!: moment.Moment;
  formatIncludesMeridiem: boolean = false;
  formatIncludesSecond: boolean = false;

  @HostListener("click", ["$event"])
  onClick(event): void {
    event.stopPropagation();
  }

  constructor(public localizer: LocalizationService) {}

  ngOnInit(): void {
    this.formatIncludesMeridiem = this.dateTimeFormat.includes(MOMENT_AM_PM_FORMAT);
    this.formatIncludesSecond = this.dateTimeFormat.includes(MOMENT_SECOND_TOKEN);
  }

  updateCalendar(date: Date): void {
    if (isDefined(this.calendar)) {
      this.calendar.activeDate = date;
      this.calendar.updateTodaysDate();
    }
  }

  changeDate(newDate: Date): void {
    this.date = updateDate(this.date, newDate);
    this.onChange();
  }

  resetTime(): void {
    this.changeTime(moment(this.date).startOf("date"));
  }

  changeTime(newTime: moment.Moment): void {
    if (!_isEqual(newTime.toDate(), this.date)) {
      const timeToUpdate = newTime.toDate().toTimeString();
      this.date = updateTime(this.date, timeToUpdate);
      this.onChange();
    }
  }

  onChange(): void {
    this.onDateTimeChange.emit(this.date);
  }

  closeDateTimePicker(): void {
    this.onChangeDisplay.emit();
  }
}
