import { Component, HostBinding, Input, OnInit } from "@angular/core";
import { takeRight as _takeRight } from "lodash";
import { EMPTY_VALUE } from "../../../core/models/time-range";
import { DataPointDto, TimeSeriesDataPointDto } from "../../../data-connectivity/models/data-point";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { first, last } from "../../../ts-utils";
import { BaseComponent } from "../../components/base/base.component";
import { ConnectorRoles } from "../../decorators/connector-roles.decorator";
import { View } from "../../decorators/view.decorator";
import { getSingleValueTooltipTextFromComponent } from "../../services/tooltip.helper";
import { Roles } from "./roles";
import { StaticNumericValueViewConfig } from "./view-config";

const EQUAL_INDICATOR_NAME = "Minus";
const GREATER_INDICATOR_NAME = "Up_arrow";
const LESSER_INDICATOR_NAME = "Down_arrow";

@Component({
  selector: "c-static-numeric-value",
  templateUrl: "./static-numeric-value.component.html",
  styleUrls: ["./static-numeric-value.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: StaticNumericValueComponent }]
})
@ConnectorRoles(Roles)
@EditableWidget({ fullName: "StaticNumericValueComponent", title: "static-numeric-value" })
export class StaticNumericValueComponent extends BaseComponent implements OnInit {
  @HostBinding("attr.title")
  public get tooltipText(): string {
    return getSingleValueTooltipTextFromComponent(this);
  }
  @Input() public title = "";
  @Input() public unit = "";
  @Input() public defaultValue = EMPTY_VALUE;

  public indicatorName: string;
  public indicatorColor: string;

  ngOnInit() {
    super.ngOnInit();
    this.initTitle();
    this.initUnit();
    this.initValue();
    this.initIndicator();
  }

  @View(StaticNumericValueViewConfig)
  public get view(): StaticNumericValueViewConfig {
    return this.currentState.view as StaticNumericValueViewConfig;
  }

  private initTitle() {
    if (!this.view.title && this.title) {
      this.view.title = this.title;
    }
  }

  private initUnit(): void {
    if (!this.view.unit && this.unit) {
      this.view.unit = this.unit;
    }
  }

  private initValue() {
    this.view.value = this.defaultValue;
  }

  private initIndicator() {
    this.indicatorName = EQUAL_INDICATOR_NAME;
    this.indicatorColor = this.view.color;
  }

  protected updateDisplay(callerInfo?: string) {
    super.updateDisplay(callerInfo);
    const lastTwoDataPoints: DataPointDto[] = _takeRight(
      this.dataAccessor.getAllDataPointsForRole(Roles.Value.name),
      2
    );
    if (lastTwoDataPoints.length > 0) {
      const lastDataPoint: TimeSeriesDataPointDto = last(
        lastTwoDataPoints
      ) as TimeSeriesDataPointDto;

      const currentValue: number = parseFloat(lastDataPoint.y);
      this.view.value =
        typeof currentValue !== "undefined" && currentValue !== null && isNaN(currentValue)
          ? currentValue.toString()
          : this.defaultValue;

      const timestamp: Date = lastDataPoint.x;
      this.view.timestamp = this.dateFormatter.formatDate(timestamp);

      const penultimateDataPoint: TimeSeriesDataPointDto =
        lastTwoDataPoints.length > 1
          ? (first(lastTwoDataPoints) as TimeSeriesDataPointDto)
          : { x: null, y: NaN };

      const previousValue: number = parseFloat(penultimateDataPoint.y);
      this.updateIndicator(previousValue, currentValue);
    }
  }

  updateIndicator(oldValue: number, newValue: number): void {
    if (!oldValue || isNaN(oldValue) || isNaN(newValue) || oldValue === newValue) {
      this.indicatorName = EQUAL_INDICATOR_NAME;
      this.indicatorColor = this.view.color;
    } else if (newValue > oldValue) {
      this.indicatorName = GREATER_INDICATOR_NAME;
      this.indicatorColor = this.view.greaterThanBeforeColor;
    } else {
      this.indicatorName = LESSER_INDICATOR_NAME;
      this.indicatorColor = this.view.lesserThanBeforeColor;
    }
  }
}
