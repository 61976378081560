import { EditableType, Serializable, Title } from "../../meta/decorators";
import { Key } from "../../meta/decorators/key.decorator";
import { EntityId } from "../../meta/models/entity";
import { Maybe } from "../../ts-utils";

@EditableType({ fullName: "SignalInfoDto", title: "signal-info-dto" })
export class SignalInfoDto {
  @Title()
  @Serializable("")
  name: string;

  @Key()
  @Serializable()
  id: Maybe<EntityId>;

  constructor(id: Maybe<EntityId>, name: string) {
    this.id = id;
    this.name = name;
  }
}
