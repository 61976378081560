import {
  ChangeDetectorRef,
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewContainerRef
} from "@angular/core";
import { Subject, fromEvent } from "rxjs";
import { debounceTime, filter, map, takeUntil } from "rxjs/operators";
import { ViewMode } from "../../core/models/view-mode";
import { Dispatcher } from "../../dispatcher";
import { DynamicTemplateDirective } from "../../dynamics/dynamic-template.directive";
import { EnvironmentSelector } from "../../environment/services/environment.selector";
import { createUpdatedComponentsInfo } from "../../meta/helpers/updated-entities-info.helper";
import { EntityId } from "../../meta/models/entity";
import { UndoRedoService } from "../../shared/services/undo-redo.service";
import { Maybe, isDefined, isNotDefined } from "../../ts-utils";
import { isClickedOnUndoRedo, isElementInHost } from "../helpers/inline-edit.helper";
import { CSS_INLINE_EDIT_INPUT, InlineComponentParams } from "../models/inline-mode-params";
import { InlineEditService } from "../services/inline-edit.service";

@Directive({ selector: "[inlineEdit]" })
export class InlineEditDirective extends DynamicTemplateDirective implements OnInit, OnDestroy {
  protected inlineComponentParams: Maybe<InlineComponentParams> = null;
  protected elementSiblings: HTMLElement[] = [];
  private unsubscribeSubject$: Subject<any> = new Subject();

  constructor(
    protected cdr: ChangeDetectorRef,
    protected element: ElementRef,
    protected inlineEditService: InlineEditService,
    protected renderer: Renderer2,
    protected dispatcher: Dispatcher,
    protected undoRedoService: UndoRedoService,
    protected environmentSelector: EnvironmentSelector,
    public viewContainerRef: ViewContainerRef,
    protected componentFactoryResolver: ComponentFactoryResolver
  ) {
    super(viewContainerRef, componentFactoryResolver);
  }

  ngOnInit(): void {
    this.inlineEditService.onInlineComponentUpdate
      .pipe(
        filter((componentParams: Maybe<InlineComponentParams>) =>
          isElementInHost(componentParams?.hostElement, this.element.nativeElement)
        ),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe((componentParams: Maybe<InlineComponentParams>) => {
        this.inlineComponentParams = componentParams;
        this.initializeInlineEditing();
      });

    this.subscribeToViewMode();
    this.subscribeToClickOutside();
  }

  ngOnDestroy(): void {
    if (isNotDefined(this.inlineComponentParams)) {
      this.unsubscribeSubject$.next();
      this.unsubscribeSubject$.complete();
    }
  }

  private subscribeToViewMode(): void {
    this.environmentSelector
      .selectViewMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((viewMode: ViewMode) => {
        if (viewMode === ViewMode.PreviewMode && isDefined(this.inlineComponentParams)) {
          this.exitInlineMode(this.inlineComponentParams.id, false);
        }
      });
  }

  private subscribeToClickOutside(): void {
    fromEvent<PointerEvent>(document, "pointerdown", { capture: true })
      .pipe(
        filter(
          (event: PointerEvent) =>
            isDefined(this.inlineComponentParams) &&
            !this.inlineComponentParams.hostElement.contains(event.target as HTMLElement) &&
            !isClickedOnUndoRedo(event.target as HTMLElement)
        ),
        debounceTime(100),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe((event) => {
        if (this.shouldLeaveInlineMode(event.target as HTMLElement)) {
          this.exitInlineMode(this.inlineComponentParams?.id);
        }
      });
  }

  protected shouldLeaveInlineMode(target: HTMLElement): boolean {
    return isElementInHost(this.inlineComponentParams?.hostElement, this.element.nativeElement);
  }

  protected exitInlineMode(
    componentId: EntityId,
    shouldShowDraggableOverlay: boolean = true
  ): void {
    this.inlineEditService.exitInlineEditMode(componentId, shouldShowDraggableOverlay);
    this.inlineComponentParams = null;
  }

  protected initializeInlineEditing(): void {
    this.elementSiblings = (
      Array.from(this.element.nativeElement.parentElement.children) as HTMLElement[]
    ).map((elementSibling: HTMLElement) => elementSibling);
  }

  prepareInlineSimpleTextEditing(editableText: string, componentId: EntityId): void {
    const parentElement: HTMLElement = this.element.nativeElement.parentElement;
    const inputElement = this.insertTextInput(editableText);
    this.ignoreSideEventsWhileEditing(inputElement);
    this.subscribeToInputChange(componentId, inputElement);

    this.renderer.listen(inputElement, "focusout", (event: FocusEvent) => {
      this.processInputFocusOut(
        componentId,
        parentElement,
        inputElement,
        isClickedOnUndoRedo(event.relatedTarget as HTMLElement)
      );
    });
  }

  private insertTextInput(editableText: string): HTMLInputElement {
    const parentElement: HTMLElement = this.element.nativeElement.parentElement;
    const inputElement: HTMLInputElement = this.renderer.createElement("input");
    this.renderer.setAttribute(inputElement, "value", editableText);
    this.applyInputElementStyle(inputElement);
    this.renderer.appendChild(parentElement, inputElement);
    this.renderer.removeChild(parentElement, this.element.nativeElement);
    this.moveCursorToTextEnd(editableText, inputElement);

    return inputElement;
  }

  private applyInputElementStyle(inputElement: HTMLElement): void {
    const elementRect: DOMRect = this.element.nativeElement.getBoundingClientRect();
    const elementHeight = elementRect.bottom - elementRect.top;
    const elementFontSize = window
      .getComputedStyle(this.element.nativeElement)
      .fontSize.split("px");

    this.renderer.setStyle(inputElement, "top", elementRect.top);
    this.renderer.setStyle(inputElement, "left", elementRect.left);
    this.renderer.setStyle(inputElement, "height", `${elementHeight}px`);

    this.inheritStylesFromElement(inputElement);

    this.renderer.setStyle(inputElement, "font-size", `${Number(elementFontSize[0])}px`);
    this.renderer.addClass(inputElement, CSS_INLINE_EDIT_INPUT);
  }

  private inheritStylesFromElement(inputElement: HTMLElement): void {
    const elementStyle = window.getComputedStyle(this.element.nativeElement);
    for (let i = 0; i < elementStyle.length; i++) {
      const property = elementStyle[i];
      const value = elementStyle.getPropertyValue(property);
      inputElement.style[property] = value;
    }
  }

  private moveCursorToTextEnd(editableText: string, inputElement: HTMLInputElement): void {
    const cursorEndPosition: number = editableText.length;
    inputElement.setSelectionRange(cursorEndPosition, cursorEndPosition);
    inputElement.focus();
  }

  private ignoreSideEventsWhileEditing(inputElement: HTMLInputElement): void {
    this.renderer.listen(inputElement, "click", (event) => {
      event.stopPropagation();
    });
    this.renderer.listen(inputElement, "keydown", (event) => {
      event.stopPropagation();
    });
  }

  private subscribeToInputChange(componentId: EntityId, inputElement: HTMLInputElement): void {
    fromEvent(inputElement, "keyup")
      .pipe(
        debounceTime(300),
        map((event: any) => event.target.value),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe((newValue: string) => this.updateTargetTitle(componentId, newValue));
  }

  protected processInputFocusOut(
    componentId: EntityId,
    parentElement: HTMLElement,
    inputElement: HTMLInputElement,
    preserveInputOnUndoRedo: boolean
  ): void {
    this.updateTargetTitle(componentId, inputElement.value);
    if (preserveInputOnUndoRedo) {
      this.moveCursorToTextEnd(inputElement.value, inputElement);
    } else {
      this.renderer.removeChild(parentElement, inputElement);
      this.renderer.appendChild(parentElement, this.element.nativeElement);
    }
  }

  protected updateTargetTitle(componentId: EntityId, newValue: string): void {
    if (isDefined(this.inlineComponentParams)) {
      this.undoRedoService.createSnapshot({
        updatedEntitiesInfo: createUpdatedComponentsInfo([componentId])
      });
    }
  }

  addOrRemoveElementSiblings(shouldAdd: boolean): void {
    this.elementSiblings.forEach((elementSibling: HTMLElement) => {
      if (elementSibling.className !== CSS_INLINE_EDIT_INPUT) {
        shouldAdd
          ? this.renderer.appendChild(this.element.nativeElement.parentElement, elementSibling)
          : this.renderer.removeChild(this.element.nativeElement.parentElement, elementSibling);
      }
    });
  }
}
