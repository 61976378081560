import { STRING_INTERPOLATION_REGEX } from "../../elements/models/property-interpolation.constants";
import { ValidationContext } from "../../meta/models/validation-context";
import { NUMBER_REGEX } from "../../ts-utils/helpers/number.helper";

export function validateNumberOrInterpolatedString(
  value: string,
  _validationContext?: ValidationContext
): boolean {
  return value === "" || NUMBER_REGEX.test(value) || STRING_INTERPOLATION_REGEX.test(value);
}
