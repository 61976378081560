import { construct } from "../../../../core";
import { LOCALIZATION_DICTIONARY } from "../../../../i18n/models/localization-dictionary";
import {
  Configurable,
  EditableType,
  NotSerializable,
  Serializable
} from "../../../../meta/decorators";
import { EditorType } from "../../../../meta/models/editor-type";
import { DeepPartial, Maybe } from "../../../../ts-utils";
import { BaseViewConfigDto } from "../../../models/base-view-config";
import { ComponentCssSize } from "../../../models/component-size";
import { TitleFormat } from "../../../models/title-format";

const TYPE_NAME = "HorizontalSeparatorViewConfig";

// @dynamic
@EditableType({ fullName: TYPE_NAME })
export class HorizontalSeparatorViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Color,
    editorType: EditorType.ColorPicker
  })
  @Serializable(null)
  color!: Maybe<string>;

  @NotSerializable()
  title: string;

  @NotSerializable()
  titleFormat: TitleFormat;

  @NotSerializable()
  displayFormat: string;

  constructor(viewConfigDto: DeepPartial<HorizontalSeparatorViewConfig> = {}) {
    super();
    const defaultOverrides: Partial<HorizontalSeparatorViewConfig> = {
      size: getDefaultSize(),
      hasRuntimeData: false
    };
    construct(this, viewConfigDto, TYPE_NAME, defaultOverrides);
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("175", "50");
}
