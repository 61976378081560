import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { RUNTIME_FILTER_ID } from "../../core/helpers/filter/filter-id.helper";
import { FilterConfigurationDto } from "../../core/models/filter/filter-configuration";
import { QueryFilter } from "../../core/models/filter/query-filter";
import { FilterFactory } from "../../core/services/filter/filter-factory.service";
import {
  ComponentMetadataService,
  DataConnectorDto,
  isEmptyEquipmentSource
} from "../../data-connectivity";
import { isEquipment } from "../../data-connectivity/helpers/data-source-type.helper";
import { DataService } from "../../data-connectivity/services/data.service";
import { EntityId } from "../../meta";
import { Dictionary, isDefined, Maybe } from "../../ts-utils";
import { ComponentStateDto, DataStatus } from "../models";
import { RequestScope } from "../models/request-scope";
import { DataConnectorQueryService } from "./data-connector-query.service";

export interface ConnectorResolutionDetail {
  beingQueried: DataConnectorDto[];
  queries$: Observable<DataConnectorDto[]>;
}

export interface DataConnectorQueryResolutionDetail {
  beingQueried: EntityId[];
  queries$: Observable<Dictionary<DataConnectorDto[]>>;
}

@Injectable({ providedIn: "root" })
export class ConnectorResolverService {
  constructor(
    private dataService: DataService,
    private dcqService: DataConnectorQueryService,
    private filterFactory: FilterFactory,
    private metadataProvider: ComponentMetadataService
  ) {}

  public resolveEquipmentConnectors(
    connectorDtos: DataConnectorDto[],
    pageRootPath: string
  ): ConnectorResolutionDetail {
    const equipmentConnectors = connectorDtos.filter(
      (connector) =>
        isEquipment(connector.dataSource) &&
        !isEmptyEquipmentSource(connector.dataSource) &&
        !connector.isDynamicallyCreated
    );

    let queries$: Observable<DataConnectorDto[]>;
    if (equipmentConnectors.length > 0) {
      queries$ = this.dataService
        .resolveEquipmentConnectors(equipmentConnectors, pageRootPath)
        .pipe(
          map((connectors) => {
            connectors.forEach((connector) => {
              this.assignInitialDataStatus(connector);
            });
            return connectors;
          })
        );
    } else {
      queries$ = of([]);
    }
    return {
      beingQueried: equipmentConnectors,
      queries$
    };
  }

  private assignInitialDataStatus(connector: DataConnectorDto): void {
    connector.dataStatus = isDefined(connector.dataPoints)
      ? connector.dataPoints.length > 0
        ? DataStatus.DataReceived
        : DataStatus.NoDataReceived
      : DataStatus.WaitingForData;
  }

  public resolveEquipmentQueries(
    componentStates: ComponentStateDto[],
    reportLevelPath: string
  ): DataConnectorQueryResolutionDetail {
    const equipmentQueryDict = this.dcqService.getEquipmentQueryDict(componentStates);
    let queries$: Observable<Dictionary<DataConnectorDto[]>>;
    if (Object.keys(equipmentQueryDict).length > 0) {
      queries$ = this.dataService
        .resolveComponentEquipmentQueries(equipmentQueryDict, reportLevelPath, componentStates)
        .pipe(
          map((connectorsDict) => {
            Object.values(connectorsDict).forEach((conns) =>
              conns.forEach((conn) => {
                this.assignInitialDataStatus(conn);
              })
            );
            return truncateConnectorsByMaxNumberOfConnectors(
              connectorsDict,
              componentStates,
              this.metadataProvider
            );
          })
        );
    } else {
      queries$ = of({});
    }
    return {
      beingQueried: Object.keys(equipmentQueryDict),
      queries$
    };
  }

  public resolveGenericQueries(
    componentStates: ComponentStateDto[],
    filterConfigs: FilterConfigurationDto[],
    requestScope: RequestScope
  ): DataConnectorQueryResolutionDetail {
    const genericQueryDict = this.dcqService.getGenericQueryDict(componentStates);
    const componentsWithQuery = componentStates.filter((component) =>
      Object.keys(genericQueryDict).includes(component.id.toString())
    );
    let queries$: Observable<Dictionary<DataConnectorDto[]>>;
    if (componentsWithQuery.length > 0) {
      const queryFilterDict = this.getFiltersForManyComponents(componentsWithQuery, filterConfigs);
      const dynamicConnectorsDict$ = this.dataService.getContinuousLookupConnectors(
        genericQueryDict,
        queryFilterDict,
        requestScope
      );
      queries$ = dynamicConnectorsDict$.pipe(
        map((connectorsDict) => {
          Object.values(connectorsDict).forEach((conns) =>
            conns.forEach((conn) => {
              this.assignInitialDataStatus(conn);
            })
          );
          return truncateConnectorsByMaxNumberOfConnectors(
            connectorsDict,
            componentStates,
            this.metadataProvider
          );
        })
      );
    } else {
      queries$ = of({});
    }
    return {
      beingQueried: componentsWithQuery.map((component) => component.id),
      queries$
    };
  }

  private getFiltersForManyComponents(
    componentsWithQuery: ComponentStateDto[],
    filterConfigs: FilterConfigurationDto[]
  ): Dictionary<QueryFilter> {
    let filtersById: Dictionary<QueryFilter>;
    if (filterConfigs) {
      filtersById = filterConfigs.reduce(
        (acc: Dictionary<QueryFilter>, filterConfig: FilterConfigurationDto) => {
          const sourceFilterConfig: Maybe<FilterConfigurationDto> = filterConfigs.find(
            (potentialSource) => potentialSource.id === filterConfig.sourceFilterId
          );

          const queryFilter = this.filterFactory.createQueryFilter(
            filterConfig,
            sourceFilterConfig
          );

          if (isDefined(queryFilter)) {
            acc[filterConfig.id] = queryFilter;
          }
          return acc;
        },
        {}
      );
    }
    const filtersByComponent: Dictionary<QueryFilter> = componentsWithQuery.reduce(
      (acc: Dictionary<QueryFilter>, componentState: ComponentStateDto) => {
        const filter = filtersById[componentState.filterId ?? RUNTIME_FILTER_ID];
        acc[componentState.id] = filter;
        return acc;
      },
      {}
    );
    return filtersByComponent;
  }
}

function truncateConnectorsByMaxNumberOfConnectors(
  connectorsByComponentId: Dictionary<DataConnectorDto[]>,
  componentStates: ComponentStateDto[],
  metadataProvider: ComponentMetadataService
): Dictionary<DataConnectorDto[]> {
  const maxConnectorsDict: Dictionary<number> = getMaxConnectorsByComponent(
    componentStates,
    metadataProvider
  );
  return truncateConnectorDict(connectorsByComponentId, maxConnectorsDict);
}

function getMaxConnectorsByComponent(
  componentStates: ComponentStateDto[],
  metadataProvider: ComponentMetadataService
): Dictionary<number> {
  return componentStates.reduce((acc: Dictionary<number>, componentState) => {
    acc[componentState.id] = metadataProvider.getMaxConnectors(componentState.type);
    return acc;
  }, {});
}

function truncateConnectorDict(
  connectorsByComponentId: Dictionary<DataConnectorDto[]>,
  maxConnectorsByComponentId: Dictionary<number>
): Dictionary<DataConnectorDto[]> {
  const prunedConnectorDict = Object.keys(connectorsByComponentId).reduce(
    (acc: Dictionary<DataConnectorDto[]>, componentId: EntityId) => {
      acc[componentId] = [...connectorsByComponentId[componentId]];
      const componentMaxConnectors = maxConnectorsByComponentId[componentId];
      if (connectorsByComponentId[componentId].length >= componentMaxConnectors) {
        acc[componentId].length = componentMaxConnectors;
      }
      return acc;
    },
    {}
  );
  return prunedConnectorDict;
}
