<div
  mat-dialog-title
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  class="report-dialog__header"
  cdkDragHandle
>
  <span>{{ reportName }}</span>
  <button class="report-dialog__button" mat-icon-button mat-dialog-close disableRipple="true">
    <mat-icon class="abb-icon abb-icon--medium Close" disableRipple="true"></mat-icon>
  </button>
</div>
<mat-dialog-content class="report-dialog__content">
  <iframe [src]="reportPath | safe: 'resourceUrl'"> </iframe>
</mat-dialog-content>
