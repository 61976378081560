import { Component, QueryList, ViewChildren } from "@angular/core";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Dictionary } from "../../../ts-utils/models/dictionary.type";
import { ConnectorRoles } from "../../decorators/connector-roles.decorator";
import {
  calculateNewComponentSize,
  getSizeUpdateAction
} from "../../helpers/component-size.helper";
import {
  ComponentCssSize,
  CssSize,
  PAGE_VIRTUAL_GRID_GAP,
  PAGE_VIRTUAL_GRID_SIDE,
  RectangleDto
} from "../../models";
import { SizeInPx } from "../../models/size-in-px";
import { BaseComponent } from "../base/base.component";
import { ContainerComponent } from "../container/container.component";
import { ContainerComponentViewConfig } from "../container/view-config";

@Component({
  selector: "c-card",
  template: ""
})
@ConnectorRoles()
@EditableWidget({ fullName: "CardComponent", virtual: true })
export class CardComponent extends ContainerComponent {
  @ViewChildren(BaseComponent) protected _viewChildren: QueryList<BaseComponent>;

  onResizeEnd() {
    // NOTE "this" context needs to be preserved, hence lambda
    return (resizedComponentsRects: Dictionary<RectangleDto>) => {
      const newCardRect = resizedComponentsRects[this.id];
      if (isDefined(newCardRect)) {
        this.snapshotDispatch(getSizeUpdateAction(this.calculateCardSize(newCardRect), this.id));
      }
    };
  }

  private calculateCardSize(rect: RectangleDto): ComponentCssSize {
    const rootView = this.componentStateSelector.getRoot().view as ContainerComponentViewConfig;

    const rootContentSize = this.runtimeViewService.calculateContentSize(rootView);
    rect.width = Math.min(rootContentSize.widthInPx, rect.width);

    if (rootView.snapToGrid) {
      const sideWithGap = PAGE_VIRTUAL_GRID_GAP + PAGE_VIRTUAL_GRID_SIDE;
      const maxColumns = Math.floor(
        (rootContentSize.widthInPx + PAGE_VIRTUAL_GRID_GAP) / sideWithGap
      );
      let columns = Math.round((rect.width + PAGE_VIRTUAL_GRID_GAP) / sideWithGap);
      columns = Math.min(maxColumns, columns);
      const rows = Math.round((rect.height + PAGE_VIRTUAL_GRID_GAP) / sideWithGap);
      rect.width = columns * sideWithGap - PAGE_VIRTUAL_GRID_GAP;
      rect.height = rows * sideWithGap - PAGE_VIRTUAL_GRID_GAP;
    }
    return calculateNewComponentSize(this.currentState.view.size, rect, rootContentSize);
  }

  protected getCssSize(runtimeSize: SizeInPx): CssSize {
    return {
      width: `${runtimeSize.widthInPx}px`,
      height: `${runtimeSize.heightInPx}px`
    };
  }
}
