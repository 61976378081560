import { RUNTIME_FILTER_ID } from "../../core/helpers/filter/filter-id.helper";
import {
  createDateByIsoDateTimeStrings,
  createValidTimeRange
} from "../../core/helpers/filter/time-range.helper";
import { CustomFilterDescriptorDto } from "../../core/models/filter/custom-filter-descriptor";
import { FilterConfigurationDto } from "../../core/models/filter/filter-configuration";
import {
  CUSTOM_FILTER_TYPES,
  CustomFilterValue
} from "../../core/models/filter/filter-type-descriptor";
import { TimeRange } from "../../core/models/time-range";
import { TimeRangeConfigurationDto } from "../../core/models/time-range-configuration";
import { UrlParams } from "../../core/models/url-params";
import { FilterFactory } from "../../core/services/filter/filter-factory.service";
import { Dictionary, Maybe, isDefined, isEmptyOrNotDefined, isNotDefined } from "../../ts-utils";

interface TimeRangeConfigParams {
  startDate: Date;
  endDate: Date;
  isInLiveMode: boolean;
}

export function getTimeRangeConfigBasedOnUrl(
  queryParams: URLSearchParams,
  globalFilterConfig: FilterConfigurationDto,
  filterFactory: FilterFactory
): Maybe<TimeRangeConfigurationDto> {
  const startDateIsDefined = urlHasDefinedTimeRangeParam(UrlParams.startDate, queryParams);
  const endDateIsDefined = urlHasDefinedTimeRangeParam(UrlParams.endDate, queryParams);
  if (!startDateIsDefined && !endDateIsDefined) {
    return;
  }
  let timeRangeConfigParams: TimeRangeConfigParams;
  if (startDateIsDefined && endDateIsDefined) {
    timeRangeConfigParams = getTimeRangeParamsCompletelyFromUrl(queryParams);
  } else {
    timeRangeConfigParams = getTimeRangeParamsPartiallyFromUrl(
      queryParams,
      filterFactory.createTimeRangeFromFilterConfig(globalFilterConfig),
      filterFactory.isInLiveMode(RUNTIME_FILTER_ID)
    );
  }
  return createGlobalTimeRangeConfig(timeRangeConfigParams, filterFactory);
}

function getTimeRangeParamsCompletelyFromUrl(queryParams: URLSearchParams): TimeRangeConfigParams {
  const startDateParam: Maybe<Date> = createDateByIsoDateTimeStrings(
    queryParams.get(UrlParams.startDate)
  );
  const endDateParam: Maybe<Date> = createDateByIsoDateTimeStrings(
    queryParams.get(UrlParams.endDate)
  );
  return {
    startDate: startDateParam,
    endDate: endDateParam,
    isInLiveMode: false
  } as TimeRangeConfigParams;
}

function getTimeRangeParamsPartiallyFromUrl(
  queryParams: URLSearchParams,
  globalTimeRange: Maybe<TimeRange>,
  globalFilterIsInLiveMode: boolean
): TimeRangeConfigParams {
  const isInLiveMode = urlHasDefinedTimeRangeParam(UrlParams.endDate, queryParams)
    ? false
    : globalFilterIsInLiveMode;

  let startDateParam: Maybe<Date> =
    createDateByIsoDateTimeStrings(queryParams.get(UrlParams.startDate)) ?? globalTimeRange?.from;
  let endDateParam: Maybe<Date> =
    createDateByIsoDateTimeStrings(queryParams.get(UrlParams.endDate)) ?? globalTimeRange?.to;
  return {
    startDate: startDateParam,
    endDate: endDateParam,
    isInLiveMode: isInLiveMode
  } as TimeRangeConfigParams;
}

function createGlobalTimeRangeConfig(
  timeRangeConfigParams: TimeRangeConfigParams,
  filterFactory: FilterFactory
): Maybe<TimeRangeConfigurationDto> {
  const newGlobalTimeRange = createValidTimeRange(
    timeRangeConfigParams.startDate,
    timeRangeConfigParams.endDate
  );
  if (isNotDefined(newGlobalTimeRange)) {
    return;
  }
  return filterFactory.createStandaloneTimeRangeConfiguration(
    newGlobalTimeRange,
    timeRangeConfigParams.isInLiveMode
  );
}

export function getPeriodTypeFromUrl(
  queryParams: URLSearchParams,
  allowedPeriodTypes: string[]
): Maybe<string> {
  if (!queryParams.has(UrlParams.pType)) {
    return null;
  }
  const pTypeFromUrl = queryParams.get(UrlParams.pType);
  return isDefined(pTypeFromUrl) && allowedPeriodTypes.includes(pTypeFromUrl) ? pTypeFromUrl : null;
}

export function getCustomFiltersFromUrl(
  customFiltersFromConfig: Maybe<CustomFilterDescriptorDto[]>,
  queryParams: URLSearchParams
): Dictionary<CustomFilterValue> {
  if (isNotDefined(customFiltersFromConfig)) {
    return {};
  }
  return customFiltersFromConfig.reduce((acc: Dictionary<CustomFilterValue>, customFilter) => {
    if (queryParams.has(customFilter.key)) {
      const paramValue = queryParams.get(customFilter.key);
      const convertedValue = convertCustomFilterValueFromurl(
        paramValue,
        customFilter.valueDescriptor.typeName
      );
      if (isDefined(convertedValue)) {
        acc[customFilter.key] = convertedValue;
      }
    }
    return acc;
  }, {});
}

export function getNoAnimationFromUrl(queryParams: URLSearchParams): Maybe<boolean> {
  const noAnimationFromUrl = queryParams.get(UrlParams.noAnimation);
  return isDefined(noAnimationFromUrl);
}

export function convertCustomFilterValueFromurl(
  valueInQueryString: Maybe<string>,
  customFilterType: string
): Maybe<CustomFilterValue> {
  const customFilterDesc = CUSTOM_FILTER_TYPES.find((t) => t.type === customFilterType);
  if (isNotDefined(customFilterDesc) || isNotDefined(valueInQueryString)) {
    return null;
  }
  if (customFilterDesc.typeConstructorFunction === String) {
    return valueInQueryString;
  }
  if (customFilterDesc.typeConstructorFunction === Number) {
    const parsedValue = parseFloat(valueInQueryString);
    return isNaN(parsedValue) ? null : parsedValue;
  }
  return null;
}

function urlHasDefinedTimeRangeParam(
  timeRangeParam: UrlParams.startDate | UrlParams.endDate,
  queryParams: URLSearchParams
): boolean {
  return queryParams.has(timeRangeParam) && !isEmptyOrNotDefined(queryParams.get(timeRangeParam));
}
