import { Injectable } from "@angular/core";
import { clone as _clone } from "lodash";
import { DraggedItem } from "../../core/models/drag/dragged-item";
import { DraggedItemType } from "../../core/models/drag/dragged-item-type";
import { EquipmentDragInfo } from "../../core/models/drag/equipment-drag-info";
import { Equipment } from "../../core/models/equipment";
import { EquipmentProperty } from "../../core/models/equipment-property";
import { IDragDropService } from "../../core/services/i-drag-drop.service";
import { DomMapper } from "./dom-mapper.service";
import { BaseComponent } from "../components";
import { Maybe } from "../../ts-utils/models/maybe.type";

@Injectable()
export class DragDropService implements IDragDropService {
  public _target: DraggedItem | null = null;

  private _lastElementPosition; // FIXME: use types
  private _touchIndicator: HTMLElement;

  public enableDrag: boolean;

  public setDragTarget(item: DraggedItem, rootPath: string = ""): void {
    this._target = this.validate(item, rootPath);
  }

  get target(): Maybe<DraggedItem> {
    return this._target;
  }

  public clear(): void {
    this._target = null;
    this._lastElementPosition = null;
  }

  private validate(draggedItem: DraggedItem, rootPath: string): DraggedItem {
    switch (draggedItem.type) {
      case DraggedItemType.Equipment: {
        const equipment: Equipment = draggedItem.item.equipment;
        const property: EquipmentProperty = draggedItem.item.property;
        // const propertyName: string = property.name || "";
        const equipmentPath = equipment && equipment.path ? equipment.path : "";
        const splitPath: string[] = equipmentPath.split("/").filter((slice) => slice !== "");

        if (rootPath !== "") {
          const rootPathLength = rootPath.split("/").filter((slice) => slice !== "").length;
          splitPath.splice(0, rootPathLength);

          if (splitPath.length === 0) {
            splitPath.push("");
          }
        }
        const relativePath: string = splitPath.join("/");

        const equipmentWithModifiedPath = _clone(equipment);
        equipmentWithModifiedPath.path = relativePath;
        const item: EquipmentDragInfo = new EquipmentDragInfo(equipmentWithModifiedPath, property);
        return {
          type: DraggedItemType.Equipment,
          item
        };
      }
    }
    return draggedItem;
  }

  public touchMove(event: Event): void {
    if (!this.enableDrag || this._target == null) {
      return;
    }
    event.preventDefault();
    this._lastElementPosition = event;
    if (this._touchIndicator == null) {
      this._touchIndicator = document.createElement("div");
      document.body.appendChild(this._touchIndicator);
      this._touchIndicator.classList.add("draged-element");
      this._touchIndicator.textContent = (<HTMLElement>event.currentTarget).textContent;
    } else {
      const xPosition = this._lastElementPosition.touches[0].pageX;
      const yPosition = this._lastElementPosition.touches[0].pageY;

      this._touchIndicator.style.left = xPosition + 10 + "px";
      this._touchIndicator.style.top = yPosition - 10 + "px";
    }
  }

  public touchEnd(event): void {
    if (this._lastElementPosition != null) {
      const xPosition = this._lastElementPosition.touches[0].pageX;
      const yPosition = this._lastElementPosition.touches[0].pageY;
      const realTargetElement = document.elementFromPoint(xPosition, yPosition);
      const component: HTMLElement | null = DomMapper.findComponentHostElement(
        realTargetElement as HTMLElement
      );
      if (component == null) {
        this._target = null;
        return;
      }
      const componentRef = (component as any).angularComponentRef as BaseComponent;
      if (componentRef != null && componentRef.shouldAllowDrop()) {
        componentRef.drop(event);
      }

      this._lastElementPosition = undefined;
      this._target = null;
    }
    if (this._touchIndicator != null) {
      document.body.removeChild(this._touchIndicator);
      delete this._touchIndicator;
    }
  }

  public dragEnd(): void {
    this.clear();
  }
}
