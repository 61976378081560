import { construct, LinkDto } from "../../../core";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { AllowInterpolation, PropertyCategory } from "../../../meta";
import {
  Configurable,
  ConfigurableArray,
  ConfigurationCategory,
  NotSerializable
} from "../../../meta/decorators";
import { EditableType } from "../../../meta/decorators/editable-type.decorator";
import { HideChildProperties } from "../../../meta/decorators/hide-child-properties.decorator";
import { Serializable } from "../../../meta/decorators/serializable.decorator";
import { EditorType } from "../../../meta/models/editor-type";
import { DeepPartial, isDefined } from "../../../ts-utils";
import { convertDtoArray } from "../../helpers/convert-dto-array.helper";
import { BaseViewConfigDto } from "../../models/base-view-config";
import { ComponentCssSize } from "../../models/component-size";
import { ContainerComponentStyleDto } from "../../models/container-component-style";
import { NavLinkInfo } from "../../models/nav-link-info";
import { NAVIGATION_BAR_VIEW_CONFIG } from "../../models/view-config-type.constants";

const DEFAULT_WIDTH = "160";
const DEFAULT_HEIGHT = "75";
// @dynamic
@EditableType({ fullName: NAVIGATION_BAR_VIEW_CONFIG })
export class NavigationBarViewConfig extends BaseViewConfigDto {
  typeName = NAVIGATION_BAR_VIEW_CONFIG;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.DisplayStatusIcons,
    editorType: EditorType.CheckBox
  })
  @Serializable(true)
  displayStatusIcons!: boolean;

  @ConfigurationCategory(
    PropertyCategory.Interaction,
    LOCALIZATION_DICTIONARY.propertySheet.Navigation
  )
  @ConfigurableArray({
    editorType: EditorType.Array,
    typeConstructor: NavLinkInfo,
    arrayItemEditorType: EditorType.NestedObjectEditor,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.NavLinksTooltip
  })
  @AllowInterpolation()
  @Serializable([new NavLinkInfo()], NavLinkInfo)
  links!: NavLinkInfo[];

  @NotSerializable()
  title: string;

  @NotSerializable()
  displayFormat: string;

  @HideChildProperties()
  @NotSerializable()
  link: LinkDto;

  @Serializable(new ContainerComponentStyleDto())
  css!: ContainerComponentStyleDto;

  @NotSerializable()
  exporting!: boolean;

  constructor(viewConfigDto: DeepPartial<NavigationBarViewConfig> = {}) {
    super();
    if (isDefined(viewConfigDto.links)) {
      viewConfigDto = {
        ...viewConfigDto,
        links: convertDtoArray(viewConfigDto.links, NavLinkInfo)
      };
    }
    const defaultOverrides: Partial<NavigationBarViewConfig> = {
      size: getDefaultSize(),
      title: ""
    };
    construct(this, viewConfigDto, NAVIGATION_BAR_VIEW_CONFIG, defaultOverrides);
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize(DEFAULT_WIDTH, DEFAULT_HEIGHT);
}
