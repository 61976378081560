import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, of, throwError } from "rxjs";
import { catchError, map, take } from "rxjs/operators";
import { AppStatusActions } from "../../environment/store/app-status/app-status.actions";
import { selectConnectionStatus } from "../../environment/store/feature.selector";
import { LocalizationService } from "../../i18n/localization.service";
import { isNotDefined, isUndefined } from "../../ts-utils";
import { CriticalError } from "../../ts-utils/models/critical-error";
import { StrictPartial } from "../../ts-utils/models/strict-partial.type";
import { HttpGetParams, HttpPostParams } from "../models/http-params";
@Injectable()
export class HttpService {
  constructor(
    public http: HttpClient,
    public store$: Store<any>,
    private translationService: LocalizationService
  ) {
    window.addEventListener("online", () => {
      // should set conn status to true? because it will not fire reloading of data, that will wait for heartbeat
      this.store$.dispatch(AppStatusActions.updateConnectionStatus({ isOnline: true }));
    });

    window.addEventListener("offline", () => {
      this.store$.dispatch(AppStatusActions.updateConnectionStatus({ isOnline: false }));
    });
  }

  post(params: StrictPartial<HttpPostParams, "url" | "body">): Observable<any> {
    const httpPostParams = new HttpPostParams(params);
    const response$: Observable<any> = this.http
      .post(httpPostParams.url, httpPostParams.body, httpPostParams.options)
      .pipe(
        map((response) => {
          this.store$.dispatch(AppStatusActions.updateConnectionStatus({ isOnline: true }));
          return response;
        }),
        catchError((error) => {
          if (this.isOnline()) {
            if (navigator.onLine === false || error.status === 0 || error.status >= 500) {
              this.store$.dispatch(AppStatusActions.updateConnectionStatus({ isOnline: false }));
            }
          }
          return isUndefined(httpPostParams.failureDefault)
            ? throwError(error)
            : of(httpPostParams.failureDefault);
        })
      );
    return response$;
  }

  postWithAutoOptions(params: StrictPartial<HttpPostParams, "url" | "body">): Observable<any> {
    const httpPostParams = new HttpPostParams(params);
    const autoOptions = undefined;
    const response$: Observable<any> = this.http
      .post(httpPostParams.url, httpPostParams.body, autoOptions)
      .pipe(
        map((response) => {
          this.store$.dispatch(AppStatusActions.updateConnectionStatus({ isOnline: true }));
          return response;
        }),
        catchError((error) => {
          if (this.isOnline()) {
            if (navigator.onLine === false || error.status === 0 || error.status >= 500) {
              this.store$.dispatch(AppStatusActions.updateConnectionStatus({ isOnline: false }));
            }
          }
          return isUndefined(httpPostParams.failureDefault)
            ? throwError(error)
            : of(httpPostParams.failureDefault);
        })
      );
    return response$;
  }

  get(params: StrictPartial<HttpGetParams, "url">): Observable<any> {
    const httpGetParams = new HttpGetParams(params);
    if (isNotDefined(httpGetParams.url)) {
      throw new CriticalError("Url is null.");
    }
    return this.http.get(httpGetParams.url, httpGetParams.options).pipe(
      catchError((error) => {
        return isUndefined(httpGetParams.failureDefault)
          ? throwError(error)
          : of(httpGetParams.failureDefault);
      })
    );
  }

  private isOnline(): boolean {
    let isOnline: boolean;
    this.store$
      .select(selectConnectionStatus)
      .pipe(take(1))
      .subscribe((status) => {
        isOnline = status;
      });
    return isOnline;
  }
}
