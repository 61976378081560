import { Component, Input } from "@angular/core";
import { SimpleBoolIndicatorViewConfig } from "./view-config";
import { TableComponentCell } from "../../models/table-component-cell";

@Component({
  selector: "c-simple-bool-indicator",
  templateUrl: "./simple-bool-indicator.component.html",
  styleUrls: ["./simple-bool-indicator.component.scss"]
})
export class SimpleBoolIndicatorComponent implements TableComponentCell {
  @Input() public value: boolean;
  @Input() public config: SimpleBoolIndicatorViewConfig;
}
