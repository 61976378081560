import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DynamicTemplateDirective } from "./dynamic-template.directive";

@NgModule({
  imports: [CommonModule],
  declarations: [DynamicTemplateDirective],
  exports: [DynamicTemplateDirective]
})
export class DynamicsModule {}
