import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { ComponentCategory } from "../../meta";
import { ComponentCategoryInfo } from "../models/component-category-info";

@Injectable()
export class CoreComponentCategoryProvider {
  public selectAllCategories(): Observable<ComponentCategoryInfo[]> {
    return of([
      new ComponentCategoryInfo(
        ComponentCategory.Container,
        "dashboard-icon " + "icon-Container",
        100
      ),
      new ComponentCategoryInfo(
        ComponentCategory.SingleValue,
        "dashboard-icon " + "icon-Dial-Gauge",
        200
      ),
      new ComponentCategoryInfo(
        ComponentCategory.TimeSeries,
        "dashboard-icon " + "icon-KM-Trend",
        300
      ),
      new ComponentCategoryInfo(
        ComponentCategory.Category,
        "dashboard-icon " + "icon-Pie-Chart",
        400
      ),
      new ComponentCategoryInfo(
        ComponentCategory.Glitter,
        "dashboard-icon " + "icon-Other-Components",
        500
      )
    ]);
  }
}
