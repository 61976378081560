import { PositioningType } from "../models/positioning-type";

export function togglePositionType(positioningType: PositioningType): PositioningType {
  return switchPositioningType(positioningType, {
    Absolute: () => PositioningType.Relative,
    Relative: () => PositioningType.Absolute
  });
}

export function switchPositioningType<Out>(
  positioningType: PositioningType,
  callbacks: {
    Absolute: (positioningType: PositioningType.Absolute) => Out;
    Relative: (positioningType: PositioningType.Relative) => Out;
  }
): Out {
  switch (positioningType) {
    case PositioningType.Absolute:
      return callbacks[PositioningType.Absolute](PositioningType.Absolute);
    case PositioningType.Relative:
      return callbacks[PositioningType.Relative](PositioningType.Relative);
    default: {
      throw Error(`Unknown positioning type: ${positioningType}`);
    }
  }
}

export function isAbsolutePositioningType(positioningType: PositioningType): boolean {
  return positioningType === PositioningType.Absolute;
}

export function isRelativePositioningType(positioningType: PositioningType): boolean {
  return positioningType === PositioningType.Relative;
}
