import {
  BaseViewConfigDto,
  ComponentCssSize,
  Configurable,
  construct,
  DeepPartial,
  EditableType,
  EditorType,
  Serializable
} from "ui-core";

const TYPE_NAME = "LoadSharingChartViewConfig";

@EditableType({ fullName: TYPE_NAME })
export class LoadSharingChartViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  @Configurable({ displayName: "Alarm Color", editorType: EditorType.ColorPicker })
  @Serializable("#FFBB00")
  alarmColor!: string;

  @Configurable({ displayName: "Trip Color", editorType: EditorType.ColorPicker })
  @Serializable("#FF0000")
  tripColor!: string;

  @Configurable({ displayName: "Phase U Color", editorType: EditorType.ColorPicker })
  @Serializable("#ABD4FD")
  uvColor!: string;

  @Configurable({ displayName: "Phase V Color", editorType: EditorType.ColorPicker })
  @Serializable("#2E92FA")
  vwColor!: string;

  @Configurable({ displayName: "Phase W Color", editorType: EditorType.ColorPicker })
  @Serializable("#004C97")
  wuColor!: string;

  constructor(viewConfigDto: DeepPartial<LoadSharingChartViewConfig> = {}) {
    super();
    construct(this, viewConfigDto, TYPE_NAME, { size: getDefaultSize() });
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("550", "400");
}
