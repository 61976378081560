import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "separateWords"
})
export class SeparateWordsPipe implements PipeTransform {
  /**
   * Separates words from provided string
   * @param value in PascalCase format
   * @returns Separated words from string
   */
  transform(value: string): string {
    return value.replace(/([A-Z][a-z])/g, " $1").replace(/(\d)/g, " $1");
  }
}
