import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ComponentStateDto } from "../../../elements";
import { EntityId } from "../../../meta";
import { Dictionary, Maybe } from "../../../ts-utils";
import { FilterConfigurationDto } from "../../models/filter/filter-configuration";

@Injectable()
export class IFilterSelector {
  selectAll(): Observable<Dictionary<Maybe<FilterConfigurationDto>>> {
    throw new Error("Not implemented");
  }

  selectById(filterId: EntityId): Observable<FilterConfigurationDto> {
    throw new Error("Not implemented");
  }

  selectByIdOrDefault(filterId: EntityId): Observable<FilterConfigurationDto> {
    throw new Error("Not implemented");
  }

  selectAllInLiveMode(): Observable<Dictionary<FilterConfigurationDto>> {
    throw new Error("Not implemented");
  }

  getById(id: EntityId): FilterConfigurationDto {
    throw new Error("Not implemented");
  }

  getByComponent(component: ComponentStateDto): FilterConfigurationDto {
    throw new Error("Not implemented");
  }

  getManyById(ids: EntityId[]): FilterConfigurationDto[] {
    throw new Error("Not implemented");
  }

  getManyByComponents(components: ComponentStateDto[]): FilterConfigurationDto[] {
    throw new Error("Not implemented");
  }

  getAll(): Dictionary<FilterConfigurationDto> {
    throw new Error("Not implemented");
  }

  getAllAsArray(): FilterConfigurationDto[] {
    throw new Error("Not implemented");
  }

  getGlobal(): FilterConfigurationDto {
    throw new Error("Not implemented");
  }

  getRuntime(): FilterConfigurationDto {
    throw new Error("Not implemented");
  }

  getByIdOrDefault(filterId: EntityId): FilterConfigurationDto {
    throw new Error("Not implemented");
  }

  getAllInLiveMode(): Dictionary<FilterConfigurationDto> {
    throw new Error("Not implemented");
  }

  getDependentFilters(sourceFilterId: EntityId): FilterConfigurationDto[] {
    throw new Error("Not implemented");
  }

  getRelativeFilters(sourceFilterId: EntityId): FilterConfigurationDto[] {
    throw new Error("Not implemented");
  }

  getManyWithDependentFilters(filterIds: EntityId[]): FilterConfigurationDto[] {
    throw new Error("Not implemented");
  }
}
