import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { ConfigurableEnum } from "../../../meta/decorators/configurable-enum.decorator";
import { EditableType } from "../../../meta/decorators/editable-type.decorator";
import { OnPropertyChange } from "../../../meta/decorators/on-property-change.decorator";
import { Serializable } from "../../../meta/decorators/serializable.decorator";
import { PropertyInfo } from "../../../meta/models/property-info";
import { SelectionOption } from "../../../meta/models/selection";
import { CustomFilterValueType } from "../../../shared/models/custom-filter-value-type";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { CriticalError } from "../../../ts-utils/models/critical-error";
import { createCustomFilterValueDescriptor } from "../../helpers/filter/custom-filter-value-descriptor-creation.helper";
import { DataTransferObject } from "../data-transfer-object";
import { CUSTOM_FILTER_TYPES } from "./filter-type-descriptor";

@EditableType({ fullName: "CustomFilterValueDescriptor", virtual: true })
export abstract class CustomFilterValueDescriptor implements DataTransferObject {
  @ConfigurableEnum({
    enumSource: getTypes,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Filter_ValueType
  })
  @OnPropertyChange<any, CustomFilterValueDescriptor, CustomFilterValueType>(onTypeChange)
  @Serializable(CustomFilterValueType.Text)
  typeName: CustomFilterValueType;
}

function getTypes(): SelectionOption[] {
  // do not remove res variable (https://github.com/ng-packagr/ng-packagr/issues/696)
  const res = CUSTOM_FILTER_TYPES.map((filterType) => {
    return {
      key: filterType.type,
      title: filterType.title
    };
  });
  return res;
}

function onTypeChange(
  _context: any,
  _owner: CustomFilterValueDescriptor,
  propertyChange: PropertyInfo<CustomFilterValueType>
): CustomFilterValueDescriptor {
  const newTypeForValue: CustomFilterValueType = propertyChange.value;
  if (isDefined(newTypeForValue)) {
    return createCustomFilterValueDescriptor(newTypeForValue, {});
  } else {
    throw new CriticalError(`Value type "${newTypeForValue}" not found`);
  }
}
