import { Injectable } from "@angular/core";
import { WebServicesConfiguration } from "ui-core";

@Injectable()
export class AzureAPIConfigurationService extends WebServicesConfiguration {
  constructor() {
    super();
  }

  generateRemainingItems(): void {
    this.logDataServiceUrl = this.logDataServiceUrl || `${this.itemsServiceUrl}`;
    this.configurationServiceUrl = this.configurationServiceUrl || this.itemsServiceUrl;
    this.reportsUrl = this.reportsUrl || `${this.itemsServiceUrl}/customers`;
    this.allReportsUrl = this.allReportsUrl || `${this.itemsServiceUrl}/customers`;
    this.logItemsServiceUrl =
      this.logItemsServiceUrl || `${this.itemsServiceUrl}/customers/{customer}/root-equipment/{rootEquipment}/signals`;
    this.equipmentUrl = this.equipmentUrl || `${this.itemsServiceUrl}/customers/{customer}/root-equipment/{rootEquipment}`;
    this.equipmentPropertiesUrl =
      this.equipmentPropertiesUrl || `${this.itemsServiceUrl}/customers/{customer}/root-equipment/{rootEquipment}/properties`;
    this.imageUploadUrl = `${this.itemsServiceUrl}/customers/{customer}/images`;
    this.imageGetUrl = `${this.itemsServiceUrl}/customers/{customer}/images`;
    this.saveAsUrl = this.configurationServiceUrl;
  }
}
