import _cloneDeep from "lodash/cloneDeep";
import { FilterConfigurationDto } from "../../core/models/filter/filter-configuration";
import { GeneralSettingsDto } from "../../core/models/general-settings";
import { DataConnectorDto } from "../../data-connectivity/models/data-connector";
import { Entity } from "../../meta/models/entity";
import { ConstructorFunction, DeepPartial, Dictionary, isNotDefined, Maybe } from "../../ts-utils";
import { ComponentStateDto } from "../models/component-state";
import { ComponentStateState, FilterState } from "../store";
import { DataConnectorState } from "../store/data-connector/data-connector.state";
import { ReportContents } from "../store/state";
import { initializeRuntimeFilter } from "./global-filter.helper";
import { updateComponentZIndexesOnReportLoad } from "./layer-order.helper";

export function deserializeReportContentOnLoad(reportState: ReportContents): ReportContents {
  const typedReportContent = deserializeReportContent(reportState);
  typedReportContent.componentStates.entities = updateComponentZIndexesOnReportLoad(
    typedReportContent.componentStates.entities as Dictionary<ComponentStateDto>
  );
  typedReportContent.filters = initializeRuntimeFilter(typedReportContent.filters);
  return typedReportContent;
}

export function deserializeReportContent(reportState: ReportContents): ReportContents {
  const deserializedReportState: ReportContents = _cloneDeep(reportState) as ReportContents;
  deserializedReportState.componentStates.entities = getValidatedComponentStates(
    reportState.componentStates
  );
  deserializedReportState.dataConnectors.entities = getTypedDataSources(reportState.dataConnectors);
  deserializedReportState.filters.entities = getTypedFilters(reportState.filters);
  deserializedReportState.generalSettings = getTypedGeneralSettings(reportState.generalSettings);
  return deserializedReportState;
}

function getValidatedComponentStates(
  componentStates: ComponentStateState
): Dictionary<ComponentStateDto> {
  if (isNotDefined(componentStates)) {
    return {};
  }
  const { entities } = componentStates;
  return convertPartialDtoDict<ComponentStateDto>(
    entities,
    ComponentStateDto.prototype.constructor as ConstructorFunction<ComponentStateDto>
  );
}

function getTypedDataSources(dataConnectors: DataConnectorState): Dictionary<DataConnectorDto> {
  if (isNotDefined(dataConnectors)) {
    return {};
  }
  return convertPartialDtoDict<DataConnectorDto>(
    dataConnectors.entities,
    DataConnectorDto.prototype.constructor as ConstructorFunction<DataConnectorDto>
  );
}

function getTypedFilters(filters: FilterState): Dictionary<FilterConfigurationDto> {
  if (isNotDefined(filters)) {
    return {};
  }
  return convertPartialDtoDict<FilterConfigurationDto>(
    filters.entities,
    FilterConfigurationDto.prototype.constructor as ConstructorFunction<FilterConfigurationDto>
  );
}

function getTypedGeneralSettings(generalSettings: Maybe<GeneralSettingsDto>): GeneralSettingsDto {
  return new GeneralSettingsDto(generalSettings ?? {});
}

function convertPartialDtoDict<T extends Entity>(
  dict: Dictionary<Maybe<DeepPartial<T>>>,
  entityConstructor: ConstructorFunction<T>
): Dictionary<T> {
  if (isNotDefined(dict)) {
    return {};
  }
  return Object.keys(dict).reduce((acc: Dictionary<T>, key) => {
    acc[key] = new entityConstructor(dict[key]);
    return acc;
  }, {});
}
