import { DefaultValue } from "../../../../lib/elements/decorators/default-value.decorator";
import { construct } from "../../../core/services/construct.helper";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import {
  AllowInterpolation,
  Configurable,
  ConfigurableArray,
  ConfigurableEnum,
  ConfigurationCategory,
  EditableType,
  Serializable
} from "../../../meta/decorators";
import { CutOffStrategy, EditorType, PropertyCategory } from "../../../meta/models";
import { EditorSize } from "../../../meta/models/editor-size";
import { validateNumberOrInterpolatedString } from "../../../property-sheet/helpers/number-validation.helper";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { convertDtoArray } from "../../helpers/convert-dto-array.helper";
import { BaseViewConfigDto, ComponentCssSize } from "../../models";
import { SpiderSeriesType } from "../../models/spider-series-type";

const TYPE_NAME = "SpiderChartViewConfig";
const GROUP_NAME = "Series";
export enum DisplayStrategies {
  SpiderChart = "SpiderChart",
  PolarChart = "PolarChart"
}
// @dynamic
@EditableType({ fullName: TYPE_NAME })
export class SpiderChartViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  @ConfigurableArray({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.AxisNames,
    arrayEditorSize: EditorSize.Medium,
    typeConstructor: String,
    editorType: EditorType.Array,
    arrayItemEditorType: EditorType.TextBox,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.AxisTooltip
  })
  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.AxisNames,
    12
  )
  @Serializable([], String)
  axisNames!: string[];

  @ConfigurableArray({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.SeriesType,
    editorType: EditorType.Array,
    typeConstructor: SpiderSeriesType,
    arrayItemEditorType: EditorType.NestedObjectEditor,
    arrayEditorSize: EditorSize.Medium,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.SpiderSeriesTypeTooltip
  })
  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.SeriesType,
    13
  )
  @Serializable([], SpiderSeriesType)
  seriesConfigs!: SpiderSeriesType[];

  @ConfigurableEnum({
    enumSource: DisplayStrategies,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Display
  })
  @Serializable(DisplayStrategies.SpiderChart)
  displayStrategies!: string;

  @ConfigurationCategory(
    PropertyCategory.Data,
    LOCALIZATION_DICTIONARY.propertySheet.DataAggregation,
    11
  )
  @ConfigurableEnum({
    enumSource: CutOffStrategy,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.CutOffStrategy,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.CutOffStrategyTooltip
  })
  @Serializable(CutOffStrategy.Downsample)
  cutOffStrategy!: CutOffStrategy;

  @DefaultValue(true)
  enableGroups: boolean = true;

  @Configurable({
    editorType: EditorType.TextBox,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.MinimumValue,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.MinimumValue,
    validationFunction: validateNumberOrInterpolatedString
  })
  @Serializable()
  @AllowInterpolation()
  min: string;

  @Configurable({
    editorType: EditorType.TextBox,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.MaximumValue,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.MaximumValue,
    validationFunction: validateNumberOrInterpolatedString
  })
  @Serializable()
  @AllowInterpolation()
  max: string;

  constructor(viewConfigDto: Partial<SpiderChartViewConfig> = {}) {
    super();
    if (isDefined(viewConfigDto.seriesConfigs)) {
      viewConfigDto = {
        ...viewConfigDto,
        seriesConfigs: convertDtoArray(viewConfigDto.seriesConfigs, SpiderSeriesType)
      };
    }
    const defaultOverrides: Partial<SpiderChartViewConfig> = {
      size: getDefaultSize(),
      groupName: GROUP_NAME
    };
    construct(this, viewConfigDto, TYPE_NAME, defaultOverrides);
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("550", "200");
}
