export type PageWidth = number | string;
// previewWidth:
// -1 => responsive
// no custom, but # of pixels. If 1900 it will be recognized
// as desktop

export enum LayoutType {
  Desktop = 1,
  Responsive = 2,
  Mobile = 3
}

export const DESKTOP_LAYOUT_WIDTH_IN_PX = 1900;
export const MOBILE_LAYOUT_WIDTH_IN_PX = 768;
export const RESPONSIVE_LAYOUT_VALUE = "";
export const DEFAULT_PAGE_WIDTH = DESKTOP_LAYOUT_WIDTH_IN_PX;

export function getLayoutTypeWidth(layoutType: LayoutType): PageWidth {
  switch (layoutType) {
    case LayoutType.Responsive:
      return RESPONSIVE_LAYOUT_VALUE;
    case LayoutType.Desktop:
      return DESKTOP_LAYOUT_WIDTH_IN_PX;
    case LayoutType.Mobile:
      return MOBILE_LAYOUT_WIDTH_IN_PX;
    default:
      return RESPONSIVE_LAYOUT_VALUE;
  }
}

export function determineLayoutType(width: PageWidth): string {
  if (!width || width === RESPONSIVE_LAYOUT_VALUE) {
    return LayoutType[LayoutType.Responsive];
  }
  switch (parseInt(<string>width)) {
    case DESKTOP_LAYOUT_WIDTH_IN_PX:
      return LayoutType[LayoutType.Desktop];
    case MOBILE_LAYOUT_WIDTH_IN_PX:
      return LayoutType[LayoutType.Mobile];
    //TODO add logic for layout
    default:
      return LayoutType[LayoutType.Desktop];
  }
}
