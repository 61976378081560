export interface PositionDto {
  top: number;
  left: number;
}

export const getPositionFromPointerEvent = (event: PointerEvent): PositionDto => {
  return {
    left: event.clientX,
    top: event.clientY
  } as PositionDto;
};
