import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from "@angular/core";
import Highcharts from "highcharts";
import { cloneDeep as _cloneDeep } from "lodash";
import { Subject } from "rxjs";
import { LIMIT_COLOR_SUFIX } from "../../../elements/models/limit-color-config";
import { LimitValueConfigDto } from "../../../elements/models/limit-value-config";
import { LimitsDto } from "../../../elements/models/limits";
import { LocalizationService } from "../../../i18n/localization.service";
import { OfType } from "../../../meta/decorators";
import { EditorType } from "../../../meta/models/editor-type";
import { TypeProvider } from "../../../meta/services/type-provider";
import { Dictionary, first, isDefined, Maybe } from "../../../ts-utils";
import { focusItemInput } from "../../helpers/input-editor-helper";
import { getLimitValuesFromInputs, validateLimits } from "../../helpers/limit-validation.helper";
import {
  getCoordsToDrawChart,
  getLabelStyle,
  LimitTooltipLabel,
  resolveEditorsInfo
} from "../../helpers/limits-editor.helper";
import { LimitEditorVisualization } from "../../models/limit-editor-visualization";
import { BaseEditorComponent } from "../base-editor.component";
import { LimitValueColorEditorComponent } from "../limit-value-color-editor/limit-value-color-editor.component";

@Component({
  selector: "limits-editor",
  templateUrl: "limits-editor.component.html",
  styleUrls: ["limits-editor.component.scss"]
})
@OfType(EditorType.LimitsEditor)
export class LimitsEditorComponent extends BaseEditorComponent implements OnInit, OnDestroy {
  public limitColorSufix = LIMIT_COLOR_SUFIX;
  chartOptions: Highcharts.Options;
  public Highcharts = Highcharts;
  chartObject: Highcharts.Chart | null = null;
  public editorsInfo: Dictionary<LimitEditorVisualization>;
  public limitsConfiguration: LimitsDto;
  private lastChangedLimitEditor: Maybe<LimitValueColorEditorComponent>;
  protected unsubscribeSubject$: Subject<any> = new Subject<any>();
  @ViewChildren(LimitValueColorEditorComponent)
  limitValueColorEditors!: QueryList<LimitValueColorEditorComponent>;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected typeProvider: TypeProvider,
    protected localizer: LocalizationService,
    private elementRef: ElementRef
  ) {
    super(cdr, typeProvider, localizer);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.limitsConfiguration = _cloneDeep(this.value);
    this.initializeEditors();
    this.chartOptions = this.getChartOptions();
    const component = this;
    this.chartOptions.chart = {
      ...this.chartOptions.chart,
      events: {
        load: function () {
          component.chartObject = this;
          component.updateEditorsInfo();
        }
      }
    };
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  public initializeEditors(): void {
    this.editorsInfo = resolveEditorsInfo();
  }

  public updateEditorsInfo(): void {
    if (isDefined(this.chartObject)) {
      this.editorsInfo = getCoordsToDrawChart(this.editorsInfo, this.chartObject);
    }
  }

  refreshValue(value): void {
    super.refreshValue(value);
    const changedLimit = Object.entries(this.limitsConfiguration).find(
      ([key, val]) => val !== value[key]
    );
    if (isDefined(changedLimit)) {
      const key = first(changedLimit).replace(LIMIT_COLOR_SUFIX, "");
      this.lastChangedLimitEditor = this.limitValueColorEditors.find((limit) => limit.key === key);
    }
    this.limitsConfiguration = value;
  }

  focus(): void {
    const limitInput: Maybe<ElementRef> = this.lastChangedLimitEditor?.getLimitInput();
    focusItemInput(limitInput?.nativeElement);
  }

  public onLimitColorChange(limitPropertyName: string, color: string): void {
    const updatedProperty: string = limitPropertyName + LIMIT_COLOR_SUFIX;
    this.limitsConfiguration = {
      ...this.limitsConfiguration,
      [updatedProperty]: color
    };
    this.onValueChanged(this.limitsConfiguration);
  }

  public onLimitValueChange(limitPropertyName: string): void {
    const visibleValues = getLimitValuesFromInputs(this.elementRef);
    this.verifyProvidedLimits(limitPropertyName, visibleValues);
  }

  public verifyProvidedLimits(limitPropertyName: string, visibleValues: LimitValueConfigDto): void {
    this.limitsConfiguration = validateLimits(
      this.limitsConfiguration,
      limitPropertyName,
      visibleValues,
      this.editorsInfo
    );
    this.onValueChanged({
      ...this.limitsConfiguration,
      [limitPropertyName]: this.editorsInfo[limitPropertyName].isInvalidValue
        ? null
        : visibleValues[limitPropertyName]
    });
  }

  private getChartOptions(): any {
    const opt = {
      chart: {
        type: "line",
        backgroundColor: "var(--background-secondary)",
        height: 250,
        width: 240
      },
      navigation: {
        buttonOptions: {
          enabled: false
        }
      },
      title: {
        text: ""
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          enableMouseTracking: false,
          marker: {
            enabled: false
          }
        }
      },
      tooltip: {
        enabled: false
      },
      lang: {
        noData: ""
      },
      xAxis: [
        {
          min: 0,
          max: 3,
          labels: {
            enabled: false
          },
          tickLength: 0,
          lineColor: "#1F1F1F",
          lineWidth: 1.2
        }
      ],
      yAxis: [
        {
          title: {
            text: ""
          },
          labels: {
            enabled: false
          },
          min: -3,
          max: 3,
          gridLineWidth: 0,
          lineColor: "#1F1F1F",
          lineWidth: 1.2,
          plotLines: [
            {
              color: "#C30B0B",
              width: 2,
              value: 3,
              dashStyle: "shortdot",
              label: {
                text: LimitTooltipLabel.hiHiHiLimit,
                style: getLabelStyle()
              }
            },
            {
              width: 2,
              value: 2,
              dashStyle: "shortdot",
              color: "#F38927",
              label: {
                text: LimitTooltipLabel.hiHiLimit,
                style: getLabelStyle()
              }
            },
            {
              width: 2,
              value: 1,
              dashStyle: "shortdot",
              color: "#B95005",
              label: {
                text: LimitTooltipLabel.hiLimit,
                style: getLabelStyle()
              }
            },
            {
              width: 2,
              value: 0,
              dashStyle: "shortdot",
              color: "#203CCE",
              label: {
                text: LimitTooltipLabel.target,
                style: getLabelStyle()
              }
            },
            {
              width: 2,
              value: -1,
              dashStyle: "shortdot",
              color: "#B95005",
              label: {
                text: LimitTooltipLabel.loLimit,
                style: getLabelStyle()
              }
            },
            {
              width: 2,
              value: -2,
              dashStyle: "shortdot",
              color: "#F38927",
              label: {
                text: LimitTooltipLabel.loLoLimit,
                style: getLabelStyle()
              }
            },
            {
              width: 2,
              value: -3,
              dashStyle: "shortdot",
              color: "#C30B0B",
              label: {
                text: LimitTooltipLabel.loLoLoLimit,
                style: getLabelStyle()
              }
            }
          ]
        }
      ],
      series: Object.values(this.editorsInfo).reduce(
        (acc: any, editorInfo: LimitEditorVisualization) => {
          acc.push({
            data: editorInfo.point
          });
          return acc;
        },
        []
      )
    };

    return opt;
  }
}
