import { Entity } from "../../meta";
import { switchEntity } from "../../meta/helpers/switch-entity.helper";

export function hasDataProps(target: Entity): boolean {
  return switchEntity<boolean>(target, {
    ComponentStateDto: (target) => target.view.hasRuntimeData,
    ComponentStateViewModel: (target) => target.view.hasRuntimeData,
    DataConnectorDto: (target) => !target.isDynamicallyCreated,
    DataConnectorViewModel: (target) => !target.isDynamicallyCreated,
    DataConnectorViewDto: () => true,
    FilterConfigurationDto: () => true,
    GlobalFilterViewModel: () => true
  });
}
