import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import moment from "moment";
import { takeUntil, tap } from "rxjs/operators";
import { EnvironmentSelector, GeneralSettingsSelector, TimeInfoActions } from "ui-core";
import { Continent, Project } from "../../models/api/hierarchy";
import { FleetViewDataService } from "../sidebar/fleet-view.service";
import { AzureQueryStringService } from "./azure-query-string.service";

@Injectable()
export class PlantTimeConverter {
  private timezoneOffset = 0;

  private projects: Project[] = [];

  constructor(
    private queryStringService: AzureQueryStringService,
    private fleetViewService: FleetViewDataService,
    private store$: Store<any>,
    private generalSettingsSelector: GeneralSettingsSelector,
    private environmentSelector: EnvironmentSelector
  ) {
    this.fleetViewService.continentsCollection$
      .pipe(
        tap((response: Continent[]) => {
          this.projects = response
            .map((continent: Continent) => {
              return continent.countries.map((country) => {
                return country.projects;
              });
            })
            .reduce((acc, val) => acc.concat(val), [])
            .reduce((acc, val) => acc.concat(val), []);

          this.updateCurrentTime();
        }),
        takeUntil(this.fleetViewService.unsubscribeHierarchy$)
      )
      .subscribe();
  }

  private get offsetBasedOnUseServerTime(): number {
    const useServerTime = this.generalSettingsSelector.getUseServerTime();
    return useServerTime ? this.timezoneOffset : 0;
  }

  updateCurrentTime(): void {
    if (this.projects.length > 0) {
      const project = this.projects.find(
        (project) => project.name === this.queryStringService.getPlantName()
      );
      this.timezoneOffset =
        project != null ? this.getUtcOffset(project!.tzName) - moment().utcOffset() : 0;
    } else {
      this.timezoneOffset = 0;
    }

    this.store$.dispatch(
      TimeInfoActions.updateTimeOffset({
        newOffset: this.timezoneOffset * 1000 * 60,
        previousOffset: this.environmentSelector.getServerOffset()
      })
    );
  }

  getUtcOffset(timeZoneInfo: string): number {
    const utcNow = new Date();

    const localTime = `${utcNow
      .toLocaleString("en-CA", { timeZone: timeZoneInfo, hour12: false })
      .replace(", ", "T")}.`;

    const utcSuffix = `${utcNow.getMilliseconds().toString().padStart(3, "0")}Z`;

    const fakeShiftedUTC = new Date(localTime + utcSuffix);

    const offset = (fakeShiftedUTC.getTime() - utcNow.getTime()) / 60000;
    return offset;
  }

  addLocalTimeBias(utcTime: string | Date): string {
    return this.applyOffset(utcTime, this.offsetBasedOnUseServerTime);
  }

  removeLocalTimeBias(utcTime: string | Date): string {
    return this.applyOffset(utcTime, -this.offsetBasedOnUseServerTime);
  }

  private applyOffset(utcTime: string | Date, offset: number): string {
    if (offset === 0 || utcTime === null) {
      return utcTime instanceof Date ? utcTime.toISOString() : utcTime;
    }

    return moment(utcTime).add(offset, "minutes").utc().toISOString();
  }
}
