export function cloneDeepWithProto<T>(source: T): T {
  if (source instanceof Object) {
    const clonedObject = Object.assign(
      Object.create(source as Object),
      Object.keys(source).reduce((acc, propName) => {
        try {
          if (source[propName] instanceof Object) {
            const clone = cloneDeepWithProto(source[propName]);
            acc[propName] = clone;
          } else {
            acc[propName] = source[propName];
          }
          return acc;
        } catch (e) {
          console.error(`Problem cloning ${propName} `, e);
        }
      }, {})
    );
    return clonedObject;
  } else {
    return source;
  }
}
