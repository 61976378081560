import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { map, switchMap, tap } from "rxjs/operators";
import {
  ConfirmationDialogComponent,
  ConfirmationDialogResult
} from "../../../../shared/components/confirmation-dialog/confirmation-dialog.component";
import { OpenDialogEffect } from "../../../../shared/dialogs/effects/dialog.effects";
import { isDefined } from "../../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../../ts-utils/models/maybe.type";
import { CommonActions } from "../../common";
import {
  ChosenActionCallback,
  SaveChangesDialogActions
} from "../actions/save-changes-dialog.actions";

@Injectable()
export class SaveChangesDialogEffects extends OpenDialogEffect {
  private clickedOnSave = false;
  private callback: Maybe<ChosenActionCallback> = null;

  constructor(private actions$: Actions, dialog: MatDialog, private store$: Store<any>) {
    super(dialog);
  }

  callCallbackAfterReportSave$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CommonActions.saveReportSuccess),
        map(() => {
          const isProjectSpecificDialog = this.clickedOnSave && isDefined(this.callback);
          if (isProjectSpecificDialog) {
            this.callback(true);
          } else {
            this.store$.dispatch(
              SaveChangesDialogActions.saveChangesInDialogClosed({
                dialogResult: ConfirmationDialogResult.SAVE
              })
            );
          }
        })
      ),
    { dispatch: false }
  );

  projectSpecificSavingUnsavedReportChanges = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SaveChangesDialogActions.projectSpecificSaveChangesDialog),
        tap(({ displayText }) => {
          const dialogConfig = new MatDialogConfig<any>();
          dialogConfig.data = displayText;
          dialogConfig.autoFocus = true;
          dialogConfig.hasBackdrop = false;
          this.dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
        }),
        switchMap(({ callback, reportId }) => {
          return this.dialogRef.afterClosed().pipe(
            map((dialogResult: ConfirmationDialogResult) => {
              switch (dialogResult) {
                case ConfirmationDialogResult.SAVE: {
                  this.clickedOnSave = true;
                  this.callback = callback;
                  this.store$.dispatch(
                    CommonActions.saveReport({
                      reportId,
                      hideConfirmation: true
                    })
                  );
                  break;
                }
                case ConfirmationDialogResult.DISCARD: {
                  callback(false);
                  break;
                }
                case ConfirmationDialogResult.CANCEL: {
                  callback(null);
                  break;
                }
                default: {
                  // click on backdrop
                  callback(null);
                }
              }
            })
          );
        })
      ),
    { dispatch: false }
  );

  savingUnsavedReportChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SaveChangesDialogActions.saveChangesInDialog),
      tap(({ displayText }) => {
        const dialogConfig = new MatDialogConfig<any>();
        dialogConfig.data = displayText;
        dialogConfig.autoFocus = true;
        dialogConfig.hasBackdrop = false;
        this.dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
      }),
      switchMap(({ reportId }) => {
        return this.dialogRef.afterClosed().pipe(
          map((dialogResult: ConfirmationDialogResult) => {
            if (dialogResult === ConfirmationDialogResult.SAVE) {
              this.clickedOnSave = true;
              this.callback = null;
              return CommonActions.saveReport({
                reportId
              });
            } else {
              return SaveChangesDialogActions.saveChangesInDialogClosed({ dialogResult });
            }
          })
        );
      })
    )
  );
}
