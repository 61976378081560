import { createAction, props } from "@ngrx/store";

export namespace ReportDialogActions {
  export const openReportAsPopup = createAction(
    "[Dialog] View Report Dialog",
    props<{ reportName: string; reportPath: string }>()
  );

  export const onCloseReportPopup = createAction("[Dialog] On Close Report Popup");
}
