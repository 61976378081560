import { Injectable } from "@angular/core";
import { EquipmentSelector } from "../../../browsing/services/equipment.selector";
import { LinkResolver } from "../../../browsing/services/link-resolver";
import { IFilterSelector } from "../../../core/services/filter/i-filter.selector";
import { IDragDropService } from "../../../core/services/i-drag-drop.service";
import { IDataConnectorViewSelector } from "../../../data-connectivity/services/i-data-connector-view.selector";
import { Dispatcher } from "../../../dispatcher";
import { AppSettingsService } from "../../../environment/services/app-settings.service";
import { ColorListService } from "../../../environment/services/color-list.service";
import { DateFormatterService } from "../../../environment/services/date-formatter.service";
import { EnvironmentSelector } from "../../../environment/services/environment.selector";
import { LocalizationService } from "../../../i18n/localization.service";
import { TypeProvider } from "../../../meta/services/type-provider";
import { PropertySheetService } from "../../../property-sheet/services/property-sheet.service";
import { UndoRedoService } from "../../../shared/services/undo-redo.service";
import { ComponentDataAccessFactory } from "../../services/component-data-access-factory.service";
import { ComponentPositioningService } from "../../services/component-positioning.service";
import { ComponentSelectionService } from "../../services/component-selection.service";
import { ComponentStateViewModelDeserializer } from "../../services/deserializers/component-state-vm.deserializer";
import { DropHelper } from "../../services/drop.helper";
import { DynamicDefaultsEvaluator } from "../../services/dynamic-defaults-evaluator";
import { ComponentStateSelector } from "../../services/entity-selectors/component-state.selector";
import { DataConnectorSelector } from "../../services/entity-selectors/data-connector.selector";
import { RuntimeSettingsSelector } from "../../services/entity-selectors/runtime-settings.selector";
import { FeatureSelector } from "../../services/feature-selector";
import { IComponentFactoryService } from "../../services/i-component-factory.service";
import { InlineEditService } from "../../services/inline-edit.service";
import { LinkingWidgetService } from "../../services/linking-widget.service";
import { PropertyInterpolationService } from "../../services/property-interpolation.service";
import { RuntimeViewService } from "../../services/runtime-view.service";
import { StrategyDefaultsOverrideService } from "../../services/strategy-defaults-override.service";

@Injectable()
export class ComponentConstructorParams {
  constructor(
    public featureSelector: FeatureSelector,
    public dispatcher: Dispatcher,
    public propertySheetService: PropertySheetService,
    public typeProvider: TypeProvider,
    public linkResolver: LinkResolver,
    public draggedComponentService: IDragDropService,
    public componentFactoryService: IComponentFactoryService,
    public dataAccessFactory: ComponentDataAccessFactory,
    public dateFormatter: DateFormatterService,
    public localizer: LocalizationService,
    public componentSelectionService: ComponentSelectionService,
    public filterSelector: IFilterSelector,
    public componentStateSelector: ComponentStateSelector,
    public dataConnectorSelector: DataConnectorSelector,
    public environmentSelector: EnvironmentSelector,
    public dropHelper: DropHelper,
    public dynamicDefaultsEvaluator: DynamicDefaultsEvaluator,
    public appSettingsService: AppSettingsService,
    public dataConnectorViewSelector: IDataConnectorViewSelector,
    public runtimeSettingsSelector: RuntimeSettingsSelector,
    public componentPositioningService: ComponentPositioningService,
    public equipmentSelector: EquipmentSelector,
    public colorService: ColorListService,
    public runtimeViewService: RuntimeViewService,
    public propertyInterpolationService: PropertyInterpolationService,
    public undoRedoService: UndoRedoService,
    public inlineEditService: InlineEditService,
    public componentStateVmDeserializer: ComponentStateViewModelDeserializer,
    public linkingWidgetService: LinkingWidgetService,
    public strategyDefaultsOverrideService: StrategyDefaultsOverrideService
  ) {}
}

export class EmptyMockComponentConstructorParams {}
