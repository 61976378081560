import {
  BaseViewConfigDto,
  ComponentCssSize,
  construct,
  DeepPartial,
  EditableType,
  FULL_HEIGHT_CSS_SIZE,
  FULL_WIDTH_CSS_SIZE
} from "ui-core";

const TYPE_NAME = "FleetMapViewConfig";

@EditableType({ fullName: TYPE_NAME })
export class FleetMapViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  constructor(viewConfigDto: DeepPartial<FleetMapViewConfig> = {}) {
    super();
    construct(this, viewConfigDto, TYPE_NAME, { size: getDefaultSize() });
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize(FULL_WIDTH_CSS_SIZE, FULL_HEIGHT_CSS_SIZE);
}
