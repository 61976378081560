import { createEntityAdapter, Update } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import {
  cloneDeep as _cloneDeep,
  isEqual as _isEqual,
  max as _max,
  pickBy as _pickBy
} from "lodash";
import { linkCreate, LinkDto, REPORT_LINK, ReportLinkDto } from "../../../core/models/link";
import { PositionDto } from "../../../core/models/position";
import {
  ApiDataSourceDto,
  DataConnectorDto,
  DataSourceDto,
  EmptyDataSourceDto
} from "../../../data-connectivity";
import { ComponentDataAggregationConfigDto } from "../../../data-connectivity/models/data-aggregation-config";
import { EntityId } from "../../../meta/models/entity";
import {
  assignDeep,
  DeepPartial,
  Dictionary,
  isDefined,
  isEmpty,
  isNotDefined,
  isNumericValue,
  Maybe,
  roundToTwoDecimals
} from "../../../ts-utils";
import { debugLog } from "../../../ts-utils/helpers/conditional-logging";
import { CriticalError } from "../../../ts-utils/models/critical-error";
import { DeepUpdate } from "../../../ts-utils/models/deep-update.type";
import { BasicCardViewConfig } from "../../components/basic-card/view-config";
import { ContainerComponentViewConfig } from "../../components/container/view-config";
import { NavigationBarViewConfig } from "../../components/navigation-bar/view-config";
import {
  getComponentsWithForegroundColor,
  getNeutralColorForText
} from "../../helpers/color.helper";
import { UNIT_PX } from "../../helpers/column-width-validation.helper";
import { togglePositionType } from "../../helpers/positioning-type.helper";
import { ensureDataStatusOnLoad } from "../../helpers/resolve-data-status.helper";
import { isBasicCardViewConfig, isPageViewConfig } from "../../helpers/view-config-type-helper";
import { findMaxComponentOrder } from "../../helpers/widget-dragging.helper";
import { BaseViewConfigDto, ReportEntities } from "../../models";
import { WHITE_COLOR_HEX } from "../../models/colors.constants";
import { ComponentCssSize } from "../../models/component-size";
import { ComponentStateDto } from "../../models/component-state";
import { ComponentStyleDto } from "../../models/component-style";
import { isBasicCard, isTabGroup } from "../../models/component-type.helper";
import { DataStatus } from "../../models/data-status";
import { Direction } from "../../models/direction";
import { BASIC_CARD } from "../../models/element-type.constants";
import { NavLinkInfo } from "../../models/nav-link-info";
import { PositioningType } from "../../models/positioning-type";
import { ComponentPositionUpdate } from "../../models/resize/component-position-update";
import { RuntimeViewUpdate } from "../../models/runtime-view-update";
import { ConnectorsReplaceInfo } from "../../models/store/connectors-replace-info";
import { getDefaultReportContent } from "../../services/configuration.service";
import {
  getComponentZIndex,
  getUpdatesForMoveToEdgeLayerAction,
  getUpdatesForMoveToNextLayerAction,
  LayerChange
} from "../../services/layer-order.helper";
import { CommonActions } from "../common/common.actions";
import { DataConnectorActions } from "../data-connector/data-connector.actions";
import { FilterActions } from "../filter/filter.actions";
import { ComponentStateActions } from "./component-state.actions";
import { findSiblings } from "./component-state.selectors";
import { ComponentStateState } from "./component-state.state";

const GRID_FIELD_DIMENSION = 25;

const adapter = createEntityAdapter<ComponentStateDto>();
export const INITIAL_STATE: ComponentStateState = adapter.getInitialState();

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

export function reducer(state: ComponentStateState, action: Action): ComponentStateState {
  return _reducer(state, action);
}

const _reducer = createReducer(
  INITIAL_STATE,
  on(ComponentStateActions.addOne, (state, { newComponent, parentId }) =>
    addOne(state, newComponent, parentId)
  ),
  on(ComponentStateActions.addMany, (state, { newComponents }) => addMany(state, newComponents)),
  on(ComponentStateActions.deleteOne, (state, { targetComponent }) =>
    deleteOne(state, targetComponent)
  ),
  on(ComponentStateActions.deleteMany, (state, { targetComponents }) =>
    deleteMany(state, targetComponents)
  ),
  on(ComponentStateActions.updateOne, (state, { componentUpdate }) =>
    updateOne(state, componentUpdate)
  ),
  on(ComponentStateActions.updateMany, (state, { componentUpdates }) =>
    updateMany(state, componentUpdates)
  ),
  on(ComponentStateActions.replace, (state, { newComponent }) => replace(state, newComponent)),
  on(ComponentStateActions.updateWithoutChanges, (state, { componentId }) =>
    updateWithoutChanges(state, componentId)
  ),
  on(ComponentStateActions.updateComponentSize, (state, { componentId, width, height }) =>
    updateComponentSize(state, componentId, { width, height })
  ),
  on(ComponentStateActions.updateExpanded, (state, { componentId, expanded }) =>
    updateExpanded(state, componentId, expanded)
  ),
  on(ComponentStateActions.updatePosition, (state, { componentId, offsetLeft, offsetTop }) =>
    updatePosition(state, componentId, offsetLeft, offsetTop)
  ),
  on(ComponentStateActions.updatePositions, (state, { componentPositions }) =>
    updatePositions(state, componentPositions)
  ),
  on(ComponentStateActions.updateChildrenPositions, (state, { updateDict, parentId }) =>
    updateChildrenPositions(state, updateDict, parentId)
  ),
  on(ComponentStateActions.updateAbsolutePositions, (state, { containerId, updates }) =>
    updateAbsolutePositions(state, containerId, updates)
  ),
  on(ComponentStateActions.updateRelativePositions, (state, { containerId }) =>
    updateRelativePositions(state, containerId)
  ),
  on(ComponentStateActions.updateChildren, (state, { parentId, childrenIdsToAdd: childrenIds }) =>
    updateChildren(state, parentId, childrenIds)
  ),
  on(ComponentStateActions.removeFromChildren, (state, { childrenIds, componentId }) =>
    removeFromChildren(state, componentId, childrenIds)
  ),
  on(ComponentStateActions.bringToFront, (state, { componentId }) =>
    moveToEdgeLayer(state, componentId, LayerChange.Front)
  ),
  on(ComponentStateActions.sendToBack, (state, { componentId }) =>
    moveToEdgeLayer(state, componentId, LayerChange.Back)
  ),
  on(ComponentStateActions.bringToFrontMany, (state, { componentId, incrementalUpdateSiblings }) =>
    moveToEdgeLayerMany(state, componentId, incrementalUpdateSiblings, LayerChange.Front)
  ),
  on(ComponentStateActions.sendToBackMany, (state, { componentId, incrementalUpdateSiblings }) =>
    moveToEdgeLayerMany(state, componentId, incrementalUpdateSiblings, LayerChange.Back)
  ),
  on(ComponentStateActions.bringForward, (state, { componentId }) =>
    moveToNextLayer(state, componentId, LayerChange.Front)
  ),
  on(ComponentStateActions.sendBackward, (state, { componentId }) =>
    moveToNextLayer(state, componentId, LayerChange.Back)
  ),
  on(ComponentStateActions.updateRuntimeViewProps, (state, { updates }) =>
    onUpdateRuntimeViewProps(state, updates)
  ),
  on(ComponentStateActions.updateLink, (state, { componentId, link }) =>
    onUpdateLink(state, componentId, link)
  ),
  on(ComponentStateActions.updateNavBarLinks, (state, { componentId, links }) =>
    onUpdateNavBarLinks(state, componentId, links)
  ),
  on(ComponentStateActions.toggleCardPositioning, (state, { componentId }) =>
    toggleCardPositioning(state, componentId)
  ),
  on(ComponentStateActions.toggleSnapToGrid, (state, { componentId }) =>
    toggleSnapToGrid(state, componentId)
  ),
  on(ComponentStateActions.updateOrderSetBefore, (state, { componentId, targetSiblingId }) =>
    updateOrderSetNextTo(state, componentId, targetSiblingId, Direction.LEFT)
  ),
  on(ComponentStateActions.updateOrderSetAfter, (state, { componentId, targetSiblingId }) =>
    updateOrderSetNextTo(state, componentId, targetSiblingId, Direction.RIGHT)
  ),
  on(ComponentStateActions.updateOrderSetAtEnd, (state, { componentId }) =>
    updateOrderSetAtEnd(state, componentId)
  ),
  on(ComponentStateActions.updateComponentStatusMany, (state, { componentStates }) =>
    updateComponentsState(state, componentStates)
  ),
  on(ComponentStateActions.updateApiQueryParams, (state, { componentId, params }) =>
    onApiQueryParamsUpdate(state, componentId, params)
  ),
  on(DataConnectorActions.addOne, (state, { componentId, connector }) =>
    onConnectorAdd(state, componentId, connector)
  ),
  on(DataConnectorActions.addMany, (state, { componentId, connectors }) =>
    onConnectorAddMany(state, componentId, connectors)
  ),
  on(DataConnectorActions.deleteOne, (state, { componentId, connector }) =>
    onConnectorDelete(state, componentId, connector.id)
  ),
  on(DataConnectorActions.deleteMany, (state, { connectorsByComponent }) =>
    onConnectorDeleteMany(state, connectorsByComponent)
  ),
  on(DataConnectorActions.replaceOne, (state, { componentId, oldConnector, newConnector }) =>
    onConnectorReplaceOne(state, componentId, oldConnector.id, newConnector)
  ),
  on(DataConnectorActions.replaceMany, (state, { componentId, connectorsReplaceInfo }) =>
    onConnectorReplaceMany(state, componentId, connectorsReplaceInfo)
  ),
  on(DataConnectorActions.replaceGenericConnectors, (state, { connectorReplacementDict }) =>
    replaceConnectors(state, connectorReplacementDict)
  ),
  on(DataConnectorActions.updateAllEquipmentSources, (state, { connectorReplacementDict }) =>
    replaceConnectors(state, connectorReplacementDict)
  ),
  on(FilterActions.reactToSelectTimeRange, (state, { componentId, filterUpdate }) =>
    updateFilterId(state, componentId, filterUpdate.id)
  ),
  on(FilterActions.deleteOne, (state, { filterId }) => onFilterDelete(state, filterId)),
  on(CommonActions.resetStore, () => setDefaultState()),
  on(CommonActions.upsertEntities, (state, { reportEntities }) =>
    onUpsertEntities(state, reportEntities)
  ),
  on(CommonActions.upsertEntitiesOnLoad, (state, { reportEntities }) =>
    onUpsertEntitiesOnLoad(state, reportEntities)
  ),
  on(DataConnectorActions.resolveDraggedEquipmentToGroup, (state, { componentUpdate }) =>
    onDraggingEquipmentToGroup(state, componentUpdate)
  ),
  on(DataConnectorActions.updateDataStatusMany, (state, action) => {
    return updateMany(
      state,
      Object.entries(action.dataConnectorQueryStatusDic).map<Update<ComponentStateDto>>(
        ([id, status]) => ({ id, changes: { dataConnectorQueryDataStatus: status } })
      )
    );
  }),
  on(CommonActions.replaceAll, (state, { entities }) =>
    onReplaceAll(state, entities.componentStates)
  )
);

function onUpdateNavBarLinks(
  state: ComponentStateState,
  componentId: EntityId,
  navLinkInfos: DeepPartial<NavLinkInfo[]>
): ComponentStateState {
  const view = state.entities[componentId]?.view as NavigationBarViewConfig;
  if (isNotDefined(view)) {
    return state;
  }
  const newLinks: DeepPartial<NavLinkInfo[]> = _cloneDeep(navLinkInfos);

  newLinks.forEach((navLinkInfo) => {
    if (navLinkInfo?.link?.typeName === REPORT_LINK) {
      const reportLink = navLinkInfo?.link as DeepPartial<ReportLinkDto>;
      if (isNotDefined(reportLink.info)) {
        navLinkInfo.link = linkCreate(navLinkInfo.link as LinkDto);
      }
    }
  });
  const changes: Update<ComponentStateDto> = {
    id: componentId.toString(),
    changes: {
      view: {
        ...view,
        links: newLinks
      } as BaseViewConfigDto
    }
  };
  return adapter.updateOne(changes, state);
}

function onUpdateLink(
  state: ComponentStateState,
  componentId: EntityId,
  link: DeepPartial<LinkDto>
): ComponentStateState {
  const view = state.entities[componentId]?.view;
  if (isNotDefined(view)) {
    return state;
  }
  let newLink: LinkDto;
  if (isDefined(link.typeName)) {
    // NOTE when link type is changed, we do not merge with old link as different link types have different properties
    newLink = linkCreate(link as LinkDto);
  } else {
    newLink = linkCreate({ ...view.link, ...link });
  }
  // NOTE keep proto until metadata can be accessed via type property
  // newLink = mergeDeep(newLink, link);
  const changes: Update<ComponentStateDto> = {
    id: componentId.toString(),
    changes: {
      view: {
        ...view,
        link: newLink
      } as BaseViewConfigDto
    }
  };
  return adapter.updateOne(changes, state);
}

function onUpdateRuntimeViewProps(
  state: ComponentStateState,
  updates: RuntimeViewUpdate[]
): ComponentStateState {
  const changes: Update<ComponentStateDto>[] = updates.map(({ componentId, runtimeViewProps }) => {
    const view = state.entities[componentId].view;

    return {
      id: componentId.toString(),
      changes: {
        view: {
          ...view,
          runtimeView: {
            ...view.runtimeView,
            ...runtimeViewProps
          }
        } as BaseViewConfigDto
      }
    };
  });
  return adapter.updateMany(changes, state);
}

function addOne(
  state: ComponentStateState,
  newComponentState: ComponentStateDto,
  parentId: EntityId
): ComponentStateState {
  if (componentStateExistsInStore(state, newComponentState)) {
    return state;
  }
  state = addComponentToParent(parentId, newComponentState.id, state);

  let fullComponent: ComponentStateDto = updateComponentWithZIndex(
    state.entities,
    newComponentState,
    parentId
  );
  fullComponent = isBasicCard(fullComponent.type)
    ? updateBasicCardWithSize(fullComponent)
    : updateComponentWithSize(fullComponent);
  if (doesParentHaveForegroundColor(state.entities, parentId)) {
    fullComponent = updateComponentWithForegroundColor(state.entities, fullComponent, parentId);
  }

  return adapter.addOne(fullComponent, state);
}

function addComponentToParent(
  parentId: EntityId,
  newChildId: EntityId,
  state: ComponentStateState
): ComponentStateState {
  const parentComponent = state.entities[parentId];
  if (isNotDefined(parentComponent)) {
    return state;
  }
  return adapter.updateOne(
    {
      id: parentId.toString(),
      changes: {
        childrenIds: [...parentComponent.childrenIds, newChildId]
      }
    },
    state
  );
}

function componentStateExistsInStore(
  state: ComponentStateState,
  newComponentState: ComponentStateDto
): boolean {
  Object.keys(state).forEach((key) => {
    if (_isEqual(state[key], newComponentState)) {
      return true;
    }
  });
  return false;
}

//#region Z-INDEX CALCULATION
function updateComponentWithZIndex(
  componentSatesDict: Dictionary<ComponentStateDto>,
  newComponentState: ComponentStateDto,
  parentId: EntityId
): ComponentStateDto {
  if (parentId == null) {
    return newComponentState;
  } else {
    const zIndex = determineZIndex(newComponentState, parentId, componentSatesDict);
    return setComponentStateZIndex(newComponentState, zIndex);
  }
}

function determineZIndex(
  componentState: ComponentStateDto,
  parentId: EntityId,
  componentStatesDict: Dictionary<ComponentStateDto>
): number {
  const currentZIndex = getComponentZIndex(componentState);
  if (currentZIndex != null) {
    return currentZIndex;
  }
  const parentComponent = componentStatesDict[parentId];
  const siblings = findSiblings(componentState.id, parentComponent, componentStatesDict);
  if (siblings && siblings.length === 0) {
    return 1;
  }
  return calculateZIndex(siblings);
}

function calculateZIndex(siblings: ComponentStateDto[]): number {
  const siblingZIndexes = siblings.reduce((acc, sibling) => {
    const parsedZIndex = getComponentZIndex(sibling);
    if (parsedZIndex != null) {
      acc.push(parsedZIndex);
    }
    return acc;
  }, []);

  return siblingZIndexes.length > 0 ? _max(siblingZIndexes) + 1 : 1;
}

function setComponentStateZIndex(
  componentState: ComponentStateDto,
  zIndex: number
): ComponentStateDto {
  return {
    ...componentState,
    view: {
      ...componentState.view,
      css: {
        ...componentState.view.css,
        zIndex: zIndex.toString()
      }
    }
  };
}

//#endregion

function doesParentHaveForegroundColor(
  componentSatesDict: Dictionary<ComponentStateDto>,
  parentId: EntityId
): Maybe<boolean> {
  if (componentSatesDict[parentId] && componentSatesDict[parentId].view) {
    return componentSatesDict[parentId].view.foregroundColor !== "";
  }
}

function updateComponentWithForegroundColor(
  componentStates: Dictionary<ComponentStateDto>,
  newComponentState: ComponentStateDto,
  parentId: EntityId
): ComponentStateDto {
  return {
    ...newComponentState,
    view: {
      ...newComponentState.view,
      foregroundColor:
        componentStates[parentId].type === BASIC_CARD
          ? getNeutralColorForText(componentStates[parentId].view.css.backgroundColor, null)
          : componentStates[parentId].view.foregroundColor
    }
  };
}

function updateBasicCardWithSize(newComponentState: ComponentStateDto): ComponentStateDto {
  const { size, expandedSize, collapsedSize } = newComponentState.view as BasicCardViewConfig;
  return {
    ...newComponentState,
    view: {
      ...newComponentState.view,
      size: {
        ...size,
        width: determineComponentSize(size.width),
        height: determineComponentSize(size.height)
      },
      expandedSize: {
        ...expandedSize,
        width: determineComponentSize(expandedSize.width),
        height: determineComponentSize(expandedSize.height)
      },
      collapsedSize: {
        ...collapsedSize,
        width: determineComponentSize(collapsedSize.width),
        height: determineComponentSize(collapsedSize.height)
      }
    } as BasicCardViewConfig
  };
}

function updateComponentWithSize(newComponentState: ComponentStateDto): ComponentStateDto {
  const { width, height } = newComponentState.view.size;
  return {
    ...newComponentState,
    view: {
      ...newComponentState.view,
      size: {
        ...newComponentState.view.size,
        width: determineComponentSize(width),
        height: determineComponentSize(height)
      }
    }
  };
}

function determineComponentSize(size: string): string {
  return isNumericValue(size) ? roundToTwoDecimals(parseInt(size)) + "px" : size;
}

function addMany(
  state: ComponentStateState,
  newComponentStates: ComponentStateDto[]
): ComponentStateState {
  const fullComponents = newComponentStates.map(
    (component: ComponentStateDto) => new ComponentStateDto(component)
  );
  return adapter.addMany(fullComponents, state);
}

function deleteOne(
  state: ComponentStateState,
  targetComponentState: ComponentStateDto
): ComponentStateState {
  state = adapter.removeOne(targetComponentState.id.toString(), state);
  const parentUpdates: Update<ComponentStateDto>[] = (state.ids as string[])
    .map((componentStateId: string) => state.entities[componentStateId])
    .map<Update<ComponentStateDto>>((singleComponentState) => {
      const singleComponentStateId = singleComponentState.id.toString();
      return {
        id: singleComponentStateId,
        changes: {
          childrenIds: singleComponentState.childrenIds.filter(
            (childId) => childId != null && childId.toString() !== singleComponentStateId.toString()
          )
        }
      };
    });
  state = adapter.updateMany(parentUpdates, state);
  return state;
}

function deleteMany(
  state: ComponentStateState,
  targetComponentStates: ComponentStateDto[]
): ComponentStateState {
  const componentStateIdsForDelete: string[] = targetComponentStates.map(
    (componentState: ComponentStateDto) => componentState.id.toString()
  );
  state = adapter.removeMany(componentStateIdsForDelete, state);

  const parentUpdates: Update<ComponentStateDto>[] = (state.ids as string[])
    .map((componentStateId: string) => state.entities[componentStateId])
    .map<Update<ComponentStateDto>>((singleComponentState) => {
      const singleComponentStateId = singleComponentState.id.toString();
      return {
        id: singleComponentStateId,
        changes: {
          childrenIds: singleComponentState.childrenIds
            .filter((childId) => childId != null)
            .filter((childId) => !componentStateIdsForDelete.includes(childId.toString()))
        }
      };
    });
  state = adapter.updateMany(parentUpdates, state);
  return state;
}

function updateOne(
  state: ComponentStateState,
  componentUpdate: DeepUpdate<ComponentStateDto>
): ComponentStateState {
  const { id, changes } = componentUpdate;
  if (isNotDefined(id)) {
    throw new CriticalError("Undefined component id.");
  }
  const targetComponent: ComponentStateDto = state.entities[id];
  const prunedChanges = removePropertiesHandledByOtherReducers(changes);

  const backgroundColor: Maybe<string> = prunedChanges.view?.css?.backgroundColor;
  if (isDefined(backgroundColor)) {
    const parentBackgroundColor: Maybe<string> = getParentComponent(state, id)?.view.css
      ?.backgroundColor;
    (prunedChanges.view as BaseViewConfigDto).foregroundColor = getNeutralColorForText(
      backgroundColor,
      parentBackgroundColor
    );
  }

  const mergedChanges = assignDeep(targetComponent, prunedChanges);
  if (_isEqual(targetComponent, mergedChanges)) {
    return state;
  }
  return adapter.updateOne(
    {
      id: id.toString(),
      changes: mergedChanges
    },
    state
  );
}

function updateMany(
  state: ComponentStateState,
  componentUpdates: DeepUpdate<ComponentStateDto>[]
): ComponentStateState {
  const mergedUpdates: Update<ComponentStateDto>[] = componentUpdates.map((update) => {
    const targetComponent: ComponentStateDto = state.entities[update.id];
    const mergedChanges = assignDeep(targetComponent, update.changes);
    return { id: update.id.toString(), changes: mergedChanges };
  });
  return adapter.updateMany(mergedUpdates, state);
}

function replace(state: ComponentStateState, newComponent: ComponentStateDto): ComponentStateState {
  const targetId = newComponent.id;
  if (targetId == null) {
    throw new CriticalError("Undefined component id.");
  }
  return adapter.updateOne(
    {
      id: targetId.toString(),
      changes: newComponent
    },
    state
  );
}

function updateWithoutChanges(
  state: ComponentStateState,
  componentId: EntityId
): ComponentStateState {
  if (componentId == null) {
    return state;
  }
  return adapter.updateOne(
    {
      id: componentId.toString(),
      changes: state.entities[componentId]
    },
    state
  );
}

function updateComponentSize(
  state: ComponentStateState,
  componentId: EntityId,
  newSize: Partial<ComponentCssSize>
): ComponentStateState {
  const componentState: ComponentStateDto = state.entities[componentId];
  const sizeWithoutUndefinedValues = removeUndefinedProps(newSize);
  state = adapter.updateOne(
    {
      id: componentId.toString(),
      changes: {
        view: {
          ...componentState.view,
          size: {
            ...componentState.view.size,
            ...sizeWithoutUndefinedValues
          } as ComponentCssSize
        }
      }
    },
    state
  );
  if (isBasicCard(componentState.type)) {
    const { expanded } = componentState.view as BasicCardViewConfig;
    if (expanded) {
      state = updateExpandedSize(state, componentId, sizeWithoutUndefinedValues);
    } else {
      state = updateCollapsedSize(state, componentId, sizeWithoutUndefinedValues);
    }
  }
  return state;
}

function updateCollapsedSize(
  state: ComponentStateState,
  componentId: EntityId,
  newCollapsedSize: Partial<ComponentCssSize>
): ComponentStateState {
  const componentState = state.entities[componentId];
  if (componentState == null) {
    return state;
  }
  const view = componentState.view as BasicCardViewConfig;
  const { collapsedSize } = view;
  return adapter.updateOne(
    {
      id: componentId.toString(),
      changes: {
        view: {
          ...view,
          collapsedSize: {
            ...collapsedSize,
            ...newCollapsedSize
          } as ComponentCssSize
        } as BasicCardViewConfig
      }
    },
    state
  );
}

function updateExpandedSize(
  state: ComponentStateState,
  componentId: EntityId,
  newExpandedSize: Partial<ComponentCssSize>
): ComponentStateState {
  const componentState = state.entities[componentId];
  if (componentState == null) {
    return state;
  }
  const view = componentState.view as BasicCardViewConfig;
  const { expandedSize } = view;
  return adapter.updateOne(
    {
      id: componentId.toString(),
      changes: {
        view: {
          ...view,
          expandedSize: {
            ...expandedSize,
            ...newExpandedSize
          } as ComponentCssSize
        } as BasicCardViewConfig
      }
    },
    state
  );
}

function removeUndefinedProps<T extends Object>(object: T): Partial<T> {
  return _pickBy(object, (propertyValue) => typeof propertyValue !== "undefined") as Partial<T>;
}

function setInitialExpandedState(state: ComponentStateState): ComponentStateState {
  let updatedState = state;
  (updatedState.ids as string[]).forEach((id: string) => {
    const component = updatedState.entities[id];
    if (component != null && isBasicCard(component.type)) {
      const card = component;
      updatedState = updateExpanded(
        updatedState,
        card.id,
        (card.view as BasicCardViewConfig).expanded
      );
    }
  });
  return updatedState;
}

function updateExpanded(
  state: ComponentStateState,
  componentId: EntityId,
  isExpanded: boolean
): ComponentStateState {
  const componentState = state.entities[componentId];
  if (componentState != null && isBasicCard(componentState.type)) {
    const view = componentState.view as BasicCardViewConfig;
    state = adapter.updateOne(
      {
        id: componentId.toString(),
        changes: {
          view: {
            ...view,
            expanded: isExpanded
          } as BasicCardViewConfig
        }
      },
      state
    );
    const cardSize: ComponentCssSize = isExpanded ? view.expandedSize : view.collapsedSize;
    state = updateComponentSize(state, componentId, cardSize);
    const shouldBeHidden = !isExpanded;
    componentState.childrenIds.forEach((childId: EntityId) => {
      const childView = state.entities[childId].view;
      const isHidable = childView.hidable;
      if (isHidable) {
        state = adapter.updateOne(
          {
            id: childId.toString(),
            changes: {
              view: {
                ...childView,
                hidden: shouldBeHidden
              } as BasicCardViewConfig
            }
          },
          state
        );
      }
    });
    return state;
  } else {
    return state;
  }
}

function updatePosition(
  state: ComponentStateState,
  componentId: EntityId,
  offsetLeft: number,
  offsetTop: number
): ComponentStateState {
  const parentComponent: Maybe<ComponentStateDto> = getParentComponent(state, componentId);
  const targetComponent: Maybe<ComponentStateDto> = state.entities[componentId];
  if (isNotDefined(parentComponent) || isNotDefined(targetComponent)) {
    throw new CriticalError(`Component with id ${componentId} not found. Cannot update.`);
  }
  const parentView = parentComponent.view;
  const cssUpdate = getCssAbsolutePositionUpdates(
    (parentView as ContainerComponentViewConfig).snapToGrid,
    offsetLeft,
    offsetTop,
    targetComponent
  );
  const updates: Update<ComponentStateDto>[] = [
    {
      id: componentId.toString(),
      changes: {
        view: {
          ...targetComponent.view,
          css: cssUpdate
        }
      }
    }
  ];
  if (isBasicCardViewConfig(parentView)) {
    const updatesToParent: Partial<ComponentStateDto> = {
      view: { ...parentView, positioningType: PositioningType.Absolute } as BasicCardViewConfig
    };
    updates.push({ id: parentComponent.id.toString(), changes: updatesToParent });
  }
  return adapter.updateMany(updates, state);
}

function updatePositions(
  state: ComponentStateState,
  componentPositions: ComponentPositionUpdate[]
): ComponentStateState {
  const componentsUpdates: Update<ComponentStateDto>[] = componentPositions.map(
    (componentPosition) => {
      const { id, view } = state.entities[componentPosition.componentId];
      return {
        id: id.toString(),
        changes: {
          view: {
            ...view,
            css: {
              ...view.css,
              left: roundToTwoDecimals(componentPosition.offsetLeft) + UNIT_PX,
              top: roundToTwoDecimals(componentPosition.offsetTop) + UNIT_PX
            }
          }
        }
      };
    }
  );
  return adapter.updateMany(componentsUpdates, state);
}

function updateChildrenPositions(
  state: ComponentStateState,
  updateDict: Dictionary<PositionDto>,
  parentId: EntityId
): ComponentStateState {
  const parentComponent: Maybe<ComponentStateDto> = state.entities[parentId];

  if (isNotDefined(parentComponent)) {
    throw new CriticalError(`Component with id ${parentId} not found. Cannot update.`);
  }
  const parentView = parentComponent.view;
  const updates = Object.keys(updateDict).reduce(
    (updatesAcc: Update<ComponentStateDto>[], childId) => {
      const childComponent: Maybe<ComponentStateDto> = state.entities[childId];
      if (isNotDefined(childComponent)) {
        return updatesAcc;
      }
      const cssUpdate = getCssAbsolutePositionUpdates(
        (parentView as ContainerComponentViewConfig).snapToGrid,
        updateDict[childId].left,
        updateDict[childId].top,
        childComponent
      );

      return updatesAcc.concat(getCssUpdateObject(childComponent, cssUpdate));
    },
    []
  );

  if (isBasicCardViewConfig(parentView)) {
    const updatesToParent: Partial<ComponentStateDto> = {
      view: { ...parentView, positioningType: PositioningType.Absolute } as BasicCardViewConfig
    };
    updates.push({ id: parentComponent.id.toString(), changes: updatesToParent });
  }
  return adapter.updateMany(updates, state);
}

function updateAbsolutePositions(
  state: ComponentStateState,
  cardId: EntityId,
  positionUpdates: ComponentPositionUpdate[]
): ComponentStateState {
  const parentView = state.entities[cardId].view;
  if (!isBasicCardViewConfig(parentView) && parentView.typeName !== "TabContentViewConfig") {
    console.log(
      "Expected BasicCardViewConfig or TabContentViewConfig, but got view type: " +
        parentView.typeName
    );
    return state;
  }
  const updates: Update<ComponentStateDto>[] = positionUpdates.map(
    ({ offsetLeft, offsetTop, componentId }) => {
      const componentState = state.entities[componentId];
      const cssUpdate = getCssAbsolutePositionUpdates(
        (parentView as ContainerComponentViewConfig).snapToGrid,
        offsetLeft,
        offsetTop,
        componentState
      );
      return {
        id: componentId.toString(),
        changes: {
          view: {
            ...componentState.view,
            css: cssUpdate
          }
        } as Partial<ComponentStateDto>
      } as Update<ComponentStateDto>;
    }
  );
  return adapter.updateMany(updates, state);
}

function updateRelativePositions(
  state: ComponentStateState,
  cardId: EntityId
): ComponentStateState {
  const componentState = state.entities[cardId];
  const childrenUpdates = componentState.childrenIds.map((childId) => {
    const { id, view } = state.entities[childId];
    return {
      id: id.toString(),
      changes: {
        view: {
          ...view,
          css: { ...view.css, left: "", top: "", position: "relative" }
        }
      }
    };
  });
  return adapter.updateMany(childrenUpdates, state);
}

function getCssAbsolutePositionUpdates(
  snapToGrid: Maybe<boolean>,
  offsetLeft: number,
  offsetTop: number,
  targetComponent: ComponentStateDto
): ComponentStyleDto {
  const absolutePositionLeft: string = calculateComponentPosition(snapToGrid, offsetLeft);
  const absolutePositionTop: string = calculateComponentPosition(snapToGrid, offsetTop);
  return {
    ...targetComponent.view.css,
    left: absolutePositionLeft,
    top: absolutePositionTop,
    position: "absolute"
  };
}

function calculateComponentPosition(snapToGrid: Maybe<boolean>, offsetInParent: number): string {
  let newOffsetInParent;
  if (snapToGrid) {
    const offsetInGridField = offsetInParent % GRID_FIELD_DIMENSION;
    if (offsetInGridField <= GRID_FIELD_DIMENSION / 2) {
      newOffsetInParent = offsetInParent - offsetInGridField;
    } else {
      newOffsetInParent = offsetInParent - offsetInGridField + GRID_FIELD_DIMENSION;
    }
  } else {
    newOffsetInParent = offsetInParent;
  }
  newOffsetInParent = newOffsetInParent < 0 ? 0 : newOffsetInParent;
  return roundToTwoDecimals(newOffsetInParent) + "px";
}

function updateChildren(
  state: ComponentStateState,
  parentId: EntityId,
  childrenIds: EntityId[]
): ComponentStateState {
  let updatedChildrenIds: EntityId[] = state.entities[parentId].childrenIds;
  updatedChildrenIds = updatedChildrenIds.concat(childrenIds);

  return adapter.updateOne(
    {
      id: parentId.toString(),
      changes: {
        childrenIds: updatedChildrenIds
      }
    },
    state
  );
}

function removeFromChildren(
  state: ComponentStateState,
  componentId: EntityId,
  childrenForRemoving: EntityId[]
): ComponentStateState {
  const component = state.entities[componentId];
  if (isNotDefined(component)) {
    return state;
  }
  return adapter.updateOne(
    {
      id: component.id.toString(),
      changes: {
        childrenIds: component.childrenIds.filter((childId) =>
          isNotDefined(childrenForRemoving.find((childForRemoving) => childForRemoving === childId))
        )
      }
    },
    state
  );
}

function moveToEdgeLayer(
  state: ComponentStateState,
  componentId: EntityId,
  layerChange: LayerChange
): ComponentStateState {
  if (componentId == null) {
    return state;
  }
  const updates = getUpdatesForMoveToEdgeLayerAction(componentId, layerChange, state.entities);
  return adapter.updateMany(updates, state);
}

function moveToEdgeLayerMany(
  state: ComponentStateState,
  componentId: EntityId,
  incrementalUpdateSiblings: EntityId[],
  layerChange: LayerChange
): ComponentStateState {
  const updates = getUpdatesForMoveToEdgeLayerAction(
    componentId,
    layerChange,
    state.entities,
    incrementalUpdateSiblings
  );
  return adapter.updateMany(updates, state);
}

function moveToNextLayer(
  state: ComponentStateState,
  componentId: EntityId,
  layerChange: LayerChange
): ComponentStateState {
  if (componentId == null) {
    return state;
  }
  const updates = getUpdatesForMoveToNextLayerAction(componentId, layerChange, state.entities);
  return adapter.updateMany(updates, state);
}

function onConnectorAdd(
  state: ComponentStateState,
  componentId: EntityId,
  dataConnector: DataConnectorDto
): ComponentStateState {
  const targetState: ComponentStateDto = state.entities[componentId]; // TODO 12 use selectors
  const connectorIds: EntityId[] = targetState.dataConnectorIds.concat(dataConnector.id);
  const changes: Partial<ComponentStateDto> = { dataConnectorIds: connectorIds };

  return adapter.updateOne(
    {
      id: componentId.toString(),
      changes: changes
    },
    state
  );
}

function onConnectorAddMany(
  state: ComponentStateState,
  componentId: EntityId,
  dataConnectors: DataConnectorDto[]
): ComponentStateState {
  const targetState = state.entities[componentId]; // TODO 12 use selectors
  const connectors = targetState.dataConnectorIds.concat(
    dataConnectors.map((dataConnector) => dataConnector.id)
  );
  return adapter.updateOne(
    {
      id: componentId.toString(),
      changes: {
        dataConnectorIds: connectors
      }
    },
    state
  );
}

function onConnectorDelete(
  state: ComponentStateState,
  componentId: EntityId,
  connectorId: EntityId
): ComponentStateState {
  const targetState = state.entities[componentId]; // TODO 12 use selectors
  if (isNotDefined(targetState)) {
    return state;
  }
  const dataConnectorIds: EntityId[] = targetState.dataConnectorIds.filter(
    (id) => id !== connectorId
  );

  return adapter.updateOne(
    {
      id: componentId.toString(),
      changes: {
        dataConnectorIds: dataConnectorIds
      }
    },
    state
  );
}

function onConnectorDeleteMany(
  state: ComponentStateState,
  connectorsByComponent: Dictionary<DataConnectorDto[]>
): ComponentStateState {
  const updates: Update<ComponentStateDto>[] = Object.keys(connectorsByComponent).reduce(
    (acc: Update<ComponentStateDto>[], componentId) => {
      const updateObject = getUpdateForComponentConnectors(
        state,
        componentId,
        connectorsByComponent[componentId].map((conn) => conn.id)
      );
      if (isDefined(updateObject)) {
        acc.push(updateObject);
      }
      return acc;
    },
    []
  );
  return adapter.updateMany(updates, state);
}

function getUpdateForComponentConnectors(
  state: ComponentStateState,
  componentId: EntityId,
  connectorsToRemove: EntityId[]
): Maybe<Update<ComponentStateDto>> {
  const targetState = state.entities[componentId];
  if (isNotDefined(targetState)) {
    return null;
  }
  return {
    id: componentId.toString(),
    changes: {
      dataConnectorIds: targetState.dataConnectorIds.filter(
        (id) => connectorsToRemove.indexOf(id.toString()) < 0
      )
    }
  };
}

function onConnectorReplaceOne(
  state: ComponentStateState,
  componentId: EntityId,
  oldConnectorId: EntityId,
  newConnector: DataConnectorDto
): ComponentStateState {
  const targetComponent: ComponentStateDto = state.entities[componentId];
  const updatedConnectorIds: EntityId[] = _cloneDeep(targetComponent.dataConnectorIds);
  const index: number = updatedConnectorIds.findIndex((currentId) => currentId === oldConnectorId);
  updatedConnectorIds[index] = newConnector.id;
  return adapter.updateOne(
    {
      id: componentId.toString(),
      changes: {
        dataConnectorIds: updatedConnectorIds,
        dataConnectorQuery: {
          ...new EmptyDataSourceDto(),
          aggregationConfig: new ComponentDataAggregationConfigDto(
            targetComponent.dataConnectorQuery.aggregationConfig
          )
        } // specific to single value use case
      }
    },
    state
  );
}

function onConnectorReplaceMany(
  state: ComponentStateState,
  componentId: EntityId,
  connectorsReplaceInfo: ConnectorsReplaceInfo
): ComponentStateState {
  const targetComponent: Maybe<ComponentStateDto> = state.entities[componentId];
  const connectorUpdate = createConnectorReplacementUpdate(targetComponent, connectorsReplaceInfo);
  if (isNotDefined(connectorUpdate)) {
    return state;
  }
  return adapter.updateOne(connectorUpdate, state);
}

function onFilterDelete(state: ComponentStateState, filterId: EntityId): ComponentStateState {
  const componentsWithFilterUpdates: Update<ComponentStateDto>[] = getComponentFilterUpdates(
    state,
    filterId
  );

  if (!!componentsWithFilterUpdates && componentsWithFilterUpdates.length > 0) {
    return adapter.updateMany(componentsWithFilterUpdates, state);
  } else {
    return state;
  }
}

function updateFilterId(
  state: ComponentStateState,
  componentId: EntityId,
  filterId: EntityId
): ComponentStateState {
  return adapter.updateOne(
    {
      id: componentId.toString(),
      changes: {
        filterId: filterId
      }
    },
    state
  );
}

function getComponentFilterUpdates(
  state: ComponentStateState,
  filterId: EntityId
): Update<ComponentStateDto>[] {
  return Object.values(state.entities)
    .filter(isDefined)
    .filter((component: ComponentStateDto) => component.filterId === filterId)
    .map((stateWithFilter: ComponentStateDto) => ({
      id: stateWithFilter.id.toString(),
      changes: { filterId: null }
    }));
}

function setDefaultState(): ComponentStateState {
  return getDefaultReportContent().componentStates;
}

function onUpsertEntitiesOnLoad(
  state: ComponentStateState,
  reportEntities: ReportEntities
): ComponentStateState {
  debugLog("upsert entities on load REDUCER", reportEntities);
  const updatedState: ComponentStateState = onUpsertEntities(state, reportEntities);
  const updatedComponents: DeepUpdate<ComponentStateDto>[] = resolveComponentForegroundColorOnLoad(
    reportEntities.componentStates,
    updatedState
  );
  return !isEmpty(updatedComponents) ? updateMany(updatedState, updatedComponents) : updatedState;
}

function onUpsertEntities(
  state: ComponentStateState,
  reportEntities: ReportEntities
): ComponentStateState {
  if (isDefined(reportEntities.componentStates)) {
    const fullStates: ComponentStateDto[] = reportEntities.componentStates.map(
      (component: ComponentStateDto) =>
        getComponentWithStatus(component, reportEntities.dataConnectors)
    );
    const upsertedState = adapter.upsertMany(fullStates, state);
    const updatedState = setInitialExpandedState(upsertedState);
    return updatedState;
  } else {
    return state;
  }
}

function getComponentWithStatus(
  component: ComponentStateDto,
  connectors: DataConnectorDto[]
): ComponentStateDto {
  if (isDefined(connectors)) {
    const componentConnectors: DataConnectorDto[] = component.dataConnectorIds
      .map((connectorId) => connectors.find((connector) => connector.id === connectorId))
      .filter(isDefined);
    return new ComponentStateDto(ensureDataStatusOnLoad(component, componentConnectors));
  }
  return new ComponentStateDto(component);
}

function resolveComponentForegroundColorOnLoad(
  components: ComponentStateDto[],
  state: ComponentStateState
): DeepUpdate<ComponentStateDto>[] {
  return components.reduce((acc: DeepUpdate<ComponentStateDto>[], component: ComponentStateDto) => {
    if (
      !isEmpty(component.view.css.backgroundColor) &&
      component.view.css.backgroundColor !== WHITE_COLOR_HEX
    ) {
      const foregroundColor: string = getNeutralColorForText(
        component.view.css.backgroundColor,
        null
      );
      if (isBasicCard(component.type) || isTabGroup(component.type)) {
        acc.push(...getComponentsWithForegroundColor(component, state.entities, foregroundColor));
      } else {
        acc.push({
          id: component.id.toString(),
          changes: { view: { ...component.view, foregroundColor } }
        });
      }
    }
    return acc;
  }, []);
}

function replaceConnectors(
  state: ComponentStateState,
  connectorReplaceDict: Dictionary<ConnectorsReplaceInfo>
): ComponentStateState {
  const updates: Update<ComponentStateDto>[] = Object.entries(connectorReplaceDict).reduce(
    (acc, [componentId, connectorReplace]) => {
      const updatedComponent = createConnectorReplacementUpdate(
        state.entities[componentId],
        connectorReplace
      );
      if (isDefined(updatedComponent)) {
        acc.push(updatedComponent);
      }
      return acc;
    },
    [] as Update<ComponentStateDto>[]
  );
  return adapter.updateMany(updates, state);
}

function createConnectorReplacementUpdate(
  componentState: Maybe<ComponentStateDto>,
  connectorsReplaceInfo: ConnectorsReplaceInfo
): Maybe<Update<ComponentStateDto>> {
  if (isNotDefined(componentState)) {
    return null;
  }
  const oldConnectorIdsToUpdate: EntityId[] = excludeObsoleteConnectors(
    componentState,
    connectorsReplaceInfo.obsoleteConnectors
  );
  const mandatoryConnectorIdsOrder = connectorsReplaceInfo.connectorsToUpdate.map(
    (connector) => connector.id
  );
  const newConnectorIds: EntityId[] = connectorsReplaceInfo.newConnectors.map(
    (newConnector) => newConnector.id
  );

  sortFollowingConnectorToUpdateIds(oldConnectorIdsToUpdate, mandatoryConnectorIdsOrder);
  const update = {
    id: componentState.id.toString(),
    changes: {
      dataConnectorIds: oldConnectorIdsToUpdate.concat(newConnectorIds)
    }
  } as Update<ComponentStateDto>;
  if (isDefined(connectorsReplaceInfo.dcqDataStatus)) {
    update.changes.dataConnectorQueryDataStatus = connectorsReplaceInfo.dcqDataStatus;
  }
  return update;
}

function excludeObsoleteConnectors(
  targetComponent: ComponentStateDto,
  obsoleteConnectors: DataConnectorDto[]
): EntityId[] {
  const componentConnectorIds: EntityId[] = targetComponent.dataConnectorIds;
  return componentConnectorIds.filter(
    (connectorId) => !obsoleteConnectors.find((conn) => conn.id === connectorId)
  );
}

function sortFollowingConnectorToUpdateIds(
  connectorIdsToReorder: EntityId[],
  mandatoryConnectorIdsOrder: EntityId[]
): void {
  connectorIdsToReorder.sort((a, b) => {
    return mandatoryConnectorIdsOrder.indexOf(a) - mandatoryConnectorIdsOrder.indexOf(b);
  });
}

/** @param changes is mutated. */
function removePropertiesHandledByOtherReducers(
  changes: DeepPartial<ComponentStateDto>
): DeepPartial<ComponentStateDto> {
  const prunedChanges = _cloneDeep(changes);
  if (isNotDefined(prunedChanges.view)) {
    return prunedChanges;
  }

  if (isDefined(prunedChanges.view.size)) {
    delete prunedChanges.view.size;
  }
  if (isDefined(prunedChanges.view.link)) {
    delete prunedChanges.view.link;
  }
  if (isDefined((prunedChanges.view as Partial<NavigationBarViewConfig>).links)) {
    delete (prunedChanges.view as Partial<NavigationBarViewConfig>).links;
  }
  return prunedChanges;
}

function toggleCardPositioning(
  state: ComponentStateState,
  componentId: EntityId
): ComponentStateState {
  const componentState = state.entities[componentId];
  if (isNotDefined(componentState)) {
    console.warn(`Component state with id: ${componentId} does not exist!`);
    return state;
  }
  if (!isBasicCardViewConfig(componentState.view)) {
    console.warn(
      `Toggling of card positioning only available for BasicCardComponent. Current view config type: ${componentState.view.typeName}`
    );
    return state;
  }
  const cardView: BasicCardViewConfig = {
    ...componentState.view,
    positioningType: togglePositionType(componentState.view.positioningType)
  };

  const update: Update<ComponentStateDto> = {
    id: componentId.toString(),
    changes: { view: cardView }
  };

  return adapter.updateOne(update, state);
}

function toggleSnapToGrid(state: ComponentStateState, componentId: EntityId): ComponentStateState {
  const componentState = state.entities[componentId];
  if (isNotDefined(componentState)) {
    console.warn(`Component state with id: ${componentId} does not exist!`);
    return state;
  }
  if (!isBasicCardViewConfig(componentState.view) && !isPageViewConfig(componentState.view)) {
    console.warn(
      `Toggling of snap to grid only available for BasicCardComponent and PageComponent. Current view config type: ${componentState.view.typeName}`
    );
    return state;
  }
  const view = {
    ...componentState.view,
    snapToGrid: !componentState.view.snapToGrid
  };
  const update: Update<ComponentStateDto> = {
    id: componentId.toString(),
    changes: { view }
  };
  return adapter.updateOne(update, state);
}

function updateComponentsState(
  state: ComponentStateState,
  componentsStates: Dictionary<DataStatus>
): ComponentStateState {
  const componentUpdates = Object.entries(componentsStates).map<Update<ComponentStateDto>>(
    ([id, status]) => ({
      id,
      changes: {
        componentDataStatus: status
      }
    })
  );
  return adapter.updateMany(componentUpdates, state);
}

function onApiQueryParamsUpdate(
  state: ComponentStateState,
  componentId: EntityId,
  params: DeepPartial<DataSourceDto>
): ComponentStateState {
  const target = state.entities[componentId];
  return adapter.updateOne(
    {
      id: componentId.toString(),
      changes: {
        dataConnectorQuery: {
          ...target?.dataConnectorQuery,
          params
        } as ApiDataSourceDto
      }
    },
    state
  );
}

function updateOrderSetNextTo(
  state: ComponentStateState,
  componentId: EntityId,
  targetSiblingId: EntityId,
  direction: Direction
): ComponentStateState {
  const component = state.entities[componentId];
  const targetSibling = state.entities[targetSiblingId];
  const componentParent = getParentComponent(state, componentId);

  if (isNotDefined(componentParent) || isNotDefined(targetSibling) || isNotDefined(component)) {
    return state;
  }
  return updateStateForSetNextTo(state, componentParent, component, targetSibling, direction);
}

function updateOrderSetAtEnd(
  state: ComponentStateState,
  componentId: EntityId
): ComponentStateState {
  const componentParent: Maybe<ComponentStateDto> = getParentComponent(state, componentId);
  const component = state.entities[componentId];
  if (isDefined(componentParent) && isDefined(component)) {
    const siblingComponents = componentParent.childrenIds
      .map((siblingId) => state.entities[siblingId])
      .filter(isDefined);
    const maxOrder = findMaxComponentOrder(siblingComponents, -1);
    return getUpdatedOrderState((maxOrder + 1).toString(), component.id, state);
  }
  return state;
}

function updateStateForSetNextTo(
  state: ComponentStateState,
  componentParent: ComponentStateDto,
  component: ComponentStateDto,
  targetSibling: ComponentStateDto,
  direction: Direction
): ComponentStateState {
  const sortedSiblings = componentParent.childrenIds
    .map((id) => state.entities[id])
    .filter(isDefined)
    .filter((child) => child.id !== component.id)
    .sort((a, b) => {
      return compareOrders(a.view.css.order, b.view.css.order) ?? 0;
    });
  const targetComponentIndex = sortedSiblings.findIndex((cs) => cs.id === targetSibling.id);
  const targetComponentOrder = getOrderAsNumber(targetSibling.view.css.order);
  if (Number.isNaN(targetComponentOrder)) {
    return updateOrderSetAtEnd(state, component.id);
  }
  state = sortedSiblings
    .slice(targetComponentIndex + (direction === Direction.LEFT ? 0 : 1))
    .reduce((stateAcc, component) => increaseOrder(component, stateAcc, 2), state);

  return getUpdatedOrderState((targetComponentOrder + 1).toString(), component.id, state);
}

function compareOrders(order1: string, order2: string): Maybe<number> {
  const order1Number = getOrderAsNumber(order1);
  const order2Number = getOrderAsNumber(order2);
  if (Number.isNaN(order1Number) || Number.isNaN(order2Number)) {
    return null;
  }
  if (order1Number > order2Number) {
    return 1;
  }
  if (order1Number < order2Number) {
    return -1;
  }
  return 0;
}

function increaseOrder(
  component: ComponentStateDto,
  state: ComponentStateState,
  increaseBy: number
): ComponentStateState {
  const orderNumber = getOrderAsNumber(component.view.css.order);
  if (Number.isNaN(orderNumber)) {
    return state;
  }
  return getUpdatedOrderState((orderNumber + increaseBy).toString(), component.id, state);
}

function getUpdatedOrderState(
  newOrder: string,
  componentId: EntityId,
  state: ComponentStateState
): ComponentStateState {
  return updateOne(state, {
    id: componentId.toString(),
    changes: {
      view: {
        css: {
          order: newOrder
        }
      }
    }
  });
}

function getOrderAsNumber(order: string): number {
  return order === "" ? 0 : parseInt(order);
}

function getCssUpdateObject(
  component: ComponentStateDto,
  cssObject: ComponentStyleDto
): Update<ComponentStateDto> {
  return {
    id: component.id.toString(),
    changes: {
      view: {
        ...component.view,
        css: cssObject
      }
    }
  };
}

function getParentComponent(
  state: ComponentStateState,
  componentId: EntityId
): Maybe<ComponentStateDto> {
  return Object.values(state.entities)
    .filter(isDefined)
    .find((potentialParent: ComponentStateDto) =>
      potentialParent.childrenIds.includes(componentId)
    );
}

function onDraggingEquipmentToGroup(
  state: ComponentStateState,
  componentUpdate: DeepUpdate<ComponentStateDto>
): ComponentStateState {
  const { id, changes } = componentUpdate;
  const targetComponent = state.entities[id];

  return adapter.updateOne(
    {
      id: id.toString(),
      changes: {
        view: { ...targetComponent?.view, title: changes.view?.title } as BaseViewConfigDto,
        dataConnectorQuery: {
          ...targetComponent?.dataConnectorQuery,
          ...changes.dataConnectorQuery
        } as DataSourceDto
      }
    },
    state
  );
}

function onReplaceAll(
  state: ComponentStateState,
  newComponentStates: ComponentStateDto[]
): ComponentStateState {
  return adapter.setAll(newComponentStates, state);
}
