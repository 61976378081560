<div class="table__container">
  <mat-form-field *ngIf="isFilterEnabled" subscriptSizing="dynamic">
    <input matInput (keyup)="applyFilter($event)" placeholder="Filter" />
  </mat-form-field>
  <div class="time-table__table-wrapper">
    <table
      mat-table
      [dataSource]="tableRowsWithPaginator"
      matSort
      cdkDropList
      cdkDropListOrientation="horizontal"
      (cdkDropListDropped)="drop($event)"
      [cdkDropListData]="tableRowsWithPaginator.data"
      matSortActive="xColumn"
      matSortDirection="asc"
      matSortDisableClear="true"
      [class.alternateRowColors]="alternateRowColors"
    >
      <ng-container
        *ngFor="let col of tableColumns; trackBy: trackItemsByIndex; let i = index"
        [matColumnDef]="col.id"
        ><th
          mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          [disabled]="isColumnSortingDisabled(col.id)"
          [style.backgroundColor]="headerBackgroundColor"
          [ngClass]="{
            'table__column--sorted': sort?.active === col.id && sort?.direction !== '',
            'table__column--label': col.id === labelColumn
          }"
          [style.width.px]="col.cellConfig.columnWidth"
          [style.min-width.px]="col.cellConfig.columnWidth"
          [style.max-width.px]="col.cellConfig.columnWidth"
          (click)="sortDirectionChanged()"
        >
          <div
            cdkDrag
            table-inline-edit
            [editableText]="col.title"
            [connectorId]="getConnectorId(col.id)"
          >
            <span class="table__column-title">{{ col.title }}</span>
            <span *cdkDragPreview>{{ col.title }}</span>
            <i
              class="abb-icon"
              [ngClass]="{
                'table__row--sorted': sort?.active != col.id,
                'table__sorting-arrow': sort?.active == col.id,
                Triangle_down: sort?.active == col.id && sort?.direction == 'desc',
                Triangle_up:
                  (sort?.active == col.id && sort?.direction == 'asc') || sort?.active != col.id
              }"
            ></i>
          </div>
          <div class="table__resize-wrapper" (mousedown)="onResizeStart($event, i)">
            <div class="table__resize-border"></div>
          </div>
        </th>
        <td mat-cell *matCellDef="let row" [style.text-align]="col.cellConfig.alignment">
          <div
            *ngIf="
              row.cells[col.id] && row.cells[col.id].cellType !== TableCellType.HTML;
              else htmlInCell
            "
          >
            <ng-template
              dynamicCell
              [cellType]="row.cells[col.id].cellType"
              [cellConfig]="mergeConfigs(col, row)"
              [cellData]="getValue(col, row)"
            >
            </ng-template>
          </div>
          <ng-template #htmlInCell
            ><div class="table__cell--html" [innerHTML]="getValue(col, row) | safe"></div
          ></ng-template>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="tableColumnIds; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: tableColumnIds"></tr>

      <ng-container *ngFor="let footerId of footerIds; trackBy: trackItemsByIndex">
        <ng-container
          *ngFor="let columnId of footerRowDict[footerId]?.columnIds"
          [matColumnDef]="columnId"
        >
          <td
            mat-footer-cell
            *matFooterCellDef
            [style.text-align]="getFooterColumnConfig(columnId)?.alignment"
          >
            {{ processFooterValue(footerId, columnId) }}
          </td>
        </ng-container>

        <tr
          mat-footer-row
          *matFooterRowDef="footerRowDict[footerId]?.columnIds; sticky: true"
          class="table__footer-row"
          [class.table__footer--hidden]="shouldHideFooter()"
        ></tr>
      </ng-container>
    </table>
  </div>

  <div [hidden]="hidePaginator">
    <mat-paginator
      class="table__paginator"
      #paginator
      [showFirstLastButtons]="true"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      (page)="pageChanged($event)"
    ></mat-paginator>
  </div>
</div>
