import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap, tap } from "rxjs/operators";
import { ReportDescription } from "../../../core/models/report-description";
import { ReportBrowserDialogComponent } from "../../../property-sheet/components/report-browser-dialog/report-browser-dialog.component";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { ReportBrowserDialogActions } from "../actions/report-browser-dialog.actions";
import { ReportBrowserDialogStrategy } from "../dialog-config.strategies";
import { OpenDialogEffect } from "./dialog.effects";

@Injectable()
export class ReportBrowserDialogEffects extends OpenDialogEffect {
  constructor(private actions$: Actions, dialog: MatDialog) {
    super(dialog);
  }

  openReportBrowserStrategy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportBrowserDialogActions.openReportBrowserDialog),
      tap(() => {
        this.dialogConfigStrategy = new ReportBrowserDialogStrategy();
        this.dialogConfig = this.dialogConfigStrategy.createDialogConfig();
        this.dialogRef = this.dialog.open(ReportBrowserDialogComponent, this.dialogConfig);
      }),
      switchMap(() => {
        return this.dialogRef.afterClosed().pipe(
          map((result: Maybe<ReportDescription>) => {
            return ReportBrowserDialogActions.onReportBrowserDialogClosed({ result });
          })
        );
      })
    )
  );
}
