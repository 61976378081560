import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ExpliciteRequestPipe } from "../../../shared";
import { Dictionary, isDefined, Maybe } from "../../../ts-utils";
import { createClassDictForShapeType } from "../../helpers/status-indicator.helper";
import { IconStateDisplay } from "../../models/status-indicator/icon-state-display";
import { ImageStateDisplay } from "../../models/status-indicator/image-state-display";
import { IndicatorShape } from "../../models/status-indicator/indicator-shape";
import { IndicatorState } from "../../models/status-indicator/indicator-state";
import { ShapeStateDisplay } from "../../models/status-indicator/shape-state-display";
import { StateDisplayType } from "../../models/status-indicator/state-display-type";

@Component({
  selector: "c-simple-status-indicator",
  styleUrls: ["./simple-status-indicator.component.scss"],
  templateUrl: "./simple-status-indicator.component.html"
})
export class SimpleStatusIndicatorComponent implements OnInit, OnChanges {
  @Input() public status: IndicatorState;
  @Input() public iconSize: Maybe<number> = null;
  public classDictionary: Dictionary<boolean> = {};
  public StateDisplayType = StateDisplayType;
  public imgSrc: string = "";
  constructor(private imgLoadingPipe: ExpliciteRequestPipe) {}

  ngOnInit(): void {
    this.resetConfiguration();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.resetConfiguration();
    this.resetImageSource(changes);
  }

  resetConfiguration(): void {
    if (this.status.displayType.typeName === StateDisplayType.Shape) {
      this.classDictionary = createClassDictForShapeType(
        (this.status.displayType as ShapeStateDisplay).shape
      );
    }
  }

  resetImageSource(changes: SimpleChanges): void {
    if (shouldUpdateImageSrc(this.status, changes)) {
      this.imgLoadingPipe.transform(this.imageUrl).subscribe((base64Image: string) => {
        this.imgSrc = base64Image;
      });
    }
  }

  public get imageUrl() {
    return (this.status.displayType as ImageStateDisplay).image ?? "";
  }

  public get icon() {
    return (this.status.displayType as IconStateDisplay).icon ?? null;
  }

  public get color() {
    switch (this.status.displayType.typeName) {
      case StateDisplayType.Shape:
        return (this.status.displayType as ShapeStateDisplay).color;
      case StateDisplayType.Icon:
        return (this.status.displayType as IconStateDisplay).color;
      default:
        return "";
    }
  }

  public isBorderCircle(): boolean {
    if (this.status.displayType.typeName === StateDisplayType.Shape) {
      return (this.status.displayType as ShapeStateDisplay).shape === IndicatorShape.BorderCircle;
    }
    return false;
  }
}

function shouldUpdateImageSrc(currentStatus: IndicatorState, changes: SimpleChanges): boolean {
  return (
    currentStatus.displayType.typeName === StateDisplayType.Image &&
    isDefined(changes["status"]) &&
    ((changes["status"].currentValue as IndicatorState).displayType as ImageStateDisplay).image !==
      ((changes["status"].previousValue as IndicatorState)?.displayType as ImageStateDisplay)?.image
  );
}
