<div class="card-item__drag-handle" [ngSwitch]="view.displayStrategy">
  <single-line-label
    *ngSwitchCase="strategyEnum.Label"
    [viewConfig]="interpolatedViewConfig"
    [hasLink]="hasLink"
    (openLink)="resolveLink()"
  >
  </single-line-label>
  <div *ngSwitchCase="strategyEnum.MultilineLabel" class="multiline-label__container">
    <ng-template label-inline-edit [editableText]="interpolatedViewConfig.htmlText"></ng-template>
    <div
      [style.word-wrap]="'break-word'"
      [style.color]="interpolatedViewConfig.foregroundColor"
      [innerHTML]="interpolatedViewConfig.htmlText | safe"
    ></div>
  </div>
</div>
