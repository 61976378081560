import { Injectable } from "@angular/core";
import { DefaultPropertyConfiguration } from "../../core/models/default-property-configuration";
import { DATA_AGGREGATION_CONFIG_DTO } from "../../core/models/dto-type.constants";
import { Dictionary } from "../../ts-utils";
import { BASIC_CARD_VIEW_CONFIG } from "../models/view-config-type.constants";

@Injectable()
export class ProjectDefaults {
  typeDescriptorDefaults: Dictionary<Dictionary<DefaultPropertyConfiguration>> = {
    [BASIC_CARD_VIEW_CONFIG]: {
      ["showFooter"]: {
        defaultValue: true
      }
    },
    [DATA_AGGREGATION_CONFIG_DTO]: {
      ["materialAggregationFunction"]: {
        isHidden: false
      }
    }
  };
}
