<div class="card-item__drag-handle" [ngSwitch]="view.displayStrategy">
  <table-for-connectors 
    *ngSwitchCase="strategyEnum.Table"
    [viewConfig]="simpleView"
    [dataConnectors]="valueConnectorDescriptors"
    [dataStatus]="currentState.componentDataStatus"
    [tableComponentId]="id"
    (pageSizeChange)="updatePageSize($event)"
    (loadMoreData)="loadMoreData()"
  ></table-for-connectors>
  <histogram
    *ngSwitchCase="strategyEnum.Histogram"
    [viewConfig]="simpleView"
    [value]="valueConnectorDescriptors"
    [dataStatus]="currentState.componentDataStatus"
    [disableAnimations]="disableChartAnimations"
  >
  </histogram>
  <c-series
    *ngSwitchDefault
    [viewConfig]="simpleView"
    [xRange]="filterRange"
    [values]="valueConnectorDescriptors"
    [displayType]="view.displayStrategy"
    [dataStatus]="currentState.componentDataStatus"
    (userZoom)="onUserZoom($event)"
  ></c-series>
</div>
