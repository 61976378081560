import { construct } from "../../../core";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { Configurable, ConfigurableEnum } from "../../../meta/decorators";
import { EditableType } from "../../../meta/decorators/editable-type.decorator";
import { Serializable } from "../../../meta/decorators/serializable.decorator";
import { EditorType } from "../../../meta/models/editor-type";
import { DeepPartial } from "../../../ts-utils";
import { BaseViewConfigDto } from "../../models/base-view-config";
import { ComponentCssSize } from "../../models/component-size";
import { KmColumns } from "../../models/km-columns";
import { KmGridLineStyle } from "../../models/km-grid";
import { KmTheme } from "../../models/km-themes";

const TYPE_NAME = "KmTrendViewConfig";
const KM_DOT_GRID_VALUE = 5;

// @dynamic
@EditableType({ fullName: TYPE_NAME })
export class KmTrendViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  @ConfigurableEnum({
    enumSource: KmTheme,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Theme
  })
  @Serializable(KmTheme.White)
  theme!: KmTheme;

  @Serializable(new KmColumns())
  columns!: KmColumns;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ShowGrid,
    editorType: EditorType.CheckBox,
    advancedMode: true
  })
  @Serializable(true)
  gridShow!: boolean;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.GridLineWidth,
    editorType: EditorType.Number,
    allowEmpty: false,
    advancedMode: true
  })
  @Serializable(1)
  gridLineWidth!: number;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.GridLineColor,
    editorType: EditorType.ColorPicker,
    advancedMode: true
  })
  @Serializable("#959595")
  gridLineColor!: string;

  @ConfigurableEnum({
    enumSource: KmGridLineStyle,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.GridLineStyle,
    advancedMode: true
  })
  @Serializable(KmGridLineStyle[KM_DOT_GRID_VALUE])
  gridLineStyle!: KmGridLineStyle;

  constructor(viewConfigDto: DeepPartial<KmTrendViewConfig> = {}) {
    super();
    construct(this, viewConfigDto, TYPE_NAME, { size: getDefaultSize() });
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("700", "375");
}
