import { construct } from "../../../core/services/construct.helper";
import { CommonDataPropertyNames } from "../../../data-connectivity/models";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import {
  Configurable,
  ConfigurationCategory,
  DynamicDefaultFromMetadata,
  EditableType,
  EditorType,
  PropertyCategory,
  Serializable
} from "../../../meta";
import { ComponentCssSize } from "../../models";
import { BaseViewConfigDto } from "../../models/base-view-config";
import { Roles } from "./roles";

const TYPE_NAME = "WaterfallChartViewConfig";

@EditableType({ fullName: TYPE_NAME })
export class WaterfallChartViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.DisplayLegend,
    editorType: EditorType.CheckBox
  })
  @Serializable(true)
  showLegend!: boolean;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General, 5)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Unit,
    editorType: EditorType.TextBox
  })
  @Serializable("")
  @DynamicDefaultFromMetadata(Roles.Value.name, CommonDataPropertyNames.unit, -1, true, false)
  unit!: string;

  constructor(viewConfigDto: Partial<WaterfallChartViewConfig> = {}) {
    super();
    const defaultOverrides: Partial<WaterfallChartViewConfig> = {
      size: getDefaultSize()
    };
    construct(this, viewConfigDto, TYPE_NAME, defaultOverrides);
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("550", "200");
}
