import { Directive, ElementRef, Host, OnDestroy, OnInit, Self } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ViewMode } from "../../core/models/view-mode";
import { selectViewMode } from "../../environment/store/feature.selector";

@Directive({
  selector: "[editor-mode]"
})
export class EditorModeDirective implements OnInit, OnDestroy {
  protected unsubscribeSubject$: Subject<any> = new Subject();
  constructor(
    @Host()
    @Self()
    private holderElement: ElementRef,
    private store$: Store<any>
  ) {}

  ngOnInit(): void {
    this.getViewMode();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  private getViewMode(): void {
    this.store$
      .select(selectViewMode)
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((viewMode: ViewMode) => {
        this.holderElement.nativeElement.style.display =
          viewMode === ViewMode.EditMode ? "inline-block" : "none";
      });
  }
}
