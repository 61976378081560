export enum SingleValueDisplayStrategy {
  Value = "Value",
  CircleValue = "CircleValue",
  TrendValue = "TrendValue",
  DialGauge = "DialGauge",
  MultiDialGauge = "MultiDialGauge",
  SolidGauge = "SolidGauge",
  HorizontalGauge = "HorizontalGauge",
  VerticalGauge = "VerticalGauge",
  BulletChart = "BulletChart"
}

export const TextualSingleValueDisplayStrategies = [
  SingleValueDisplayStrategy.Value,
  SingleValueDisplayStrategy.CircleValue,
  SingleValueDisplayStrategy.TrendValue
];

export const GaugeSingleValueDisplayStrategies = [
  SingleValueDisplayStrategy.DialGauge,
  SingleValueDisplayStrategy.MultiDialGauge,
  SingleValueDisplayStrategy.SolidGauge,
  SingleValueDisplayStrategy.HorizontalGauge,
  SingleValueDisplayStrategy.VerticalGauge,
  SingleValueDisplayStrategy.BulletChart
];
