import { createAction, props } from "@ngrx/store";
import { IProjectHierarchyWithStatus } from "../../models/motor-response.interface";

export namespace HierarchyCollectionActions {
  export const getHierarchy = createAction("[HIERARCHY] Get Hierarchy");

  export const setHierarchy = createAction(
    "[HIERARCHY] Set Hierarchy",
    props<{ motorResponse: IProjectHierarchyWithStatus }>()
  );
}
