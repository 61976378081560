import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { DataConnectorDto } from "../../../../data-connectivity";
import { Maybe } from "../../../../ts-utils/models/maybe.type";
import { DataStatus } from "../../../models/data-status";
import { ITextualTrendViewConfig } from "./i-textual-trend-view-config";

const EQUAL_INDICATOR_NAME = "Minus";
const GREATER_INDICATOR_NAME = "Up_arrow";
const LESSER_INDICATOR_NAME = "Down_arrow";

@Component({
  selector: "textual-trend",
  templateUrl: "./textual-trend.component.html",
  styleUrls: ["./textual-trend.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextualTrendComponent implements OnChanges {
  @Input()
  public value: Maybe<DataConnectorDto> = null;

  @Input() viewConfig: Maybe<ITextualTrendViewConfig> = null;

  @Input() fontSizePx: number = 12;

  @Input() dataStatus: DataStatus = DataStatus.NoDataDefined;

  public trendIndicatorName: Maybe<string> = null;
  public trendIndicatorColor: Maybe<string> = null;

  ngOnChanges(_changes: SimpleChanges): void {
    if (this.viewConfig != null) {
      const trend = this.calculateTrend();
      if (trend != null) {
        if (trend > 0) {
          this.trendIndicatorName = GREATER_INDICATOR_NAME;
          this.trendIndicatorColor = this.viewConfig.highColor;
        } else if (trend < 0) {
          this.trendIndicatorName = LESSER_INDICATOR_NAME;
          this.trendIndicatorColor = this.viewConfig.lowColor;
        } else {
          this.trendIndicatorName = EQUAL_INDICATOR_NAME;
          this.trendIndicatorColor = this.viewConfig.primaryColor;
        }
      } else {
        this.trendIndicatorName = null;
      }
    }
  }

  private calculateTrend(): number | null {
    if (this.value != null && this.value.dataPoints != null && this.value.dataPoints.length > 1) {
      const pts = this.value.dataPoints;
      const last = pts[pts.length - 1].y;
      const prev = pts[pts.length - 2].y;
      return last - prev;
    }
    return null;
  }
}
