import { DataConnectorRoles } from "ui-core";

export const Roles: DataConnectorRoles = {
  AlarmLimit: {
    name: "Alarm Limit",
    isDefaultRole: true
  },
  TripLimit: {
    name: "Trip Limit"
  },
  TemperaturePrefix: {
    name: "Temperature-"
  },
  RmsPrefix: {
    name: "Rms-"
  }
};
