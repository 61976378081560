import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import {
  Configurable,
  ConfigurationCategory,
  EditableType,
  Serializable
} from "../../meta/decorators";
import { Placeholder } from "../../meta/decorators/placeholder.decorator";
import { EditorType, PropertyCategory } from "../../meta/models";
import { validateInputForFilterExpression } from "../helpers/filter/filter-validation.helper";
import { construct } from "../services/construct.helper";
import { DataTransferObject } from "./data-transfer-object";

const TYPE_NAME = "TimeRangeConfigurationDto";

// @dynamic
@EditableType({ fullName: TYPE_NAME })
export class TimeRangeConfigurationDto implements DataTransferObject {
  typeName = TYPE_NAME;

  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.Filter)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.From,
    editorType: EditorType.TextBox,
    validationFunction: validateInputForFilterExpression,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.FilterConfigTooltip
  })
  @Serializable("")
  @Placeholder({
    text: LOCALIZATION_DICTIONARY.propertySheet.Inherited,
    combineWithRuntimeValue: false
  })
  fromExpression?: string;

  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.Filter)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.To,
    editorType: EditorType.TextBox,
    validationFunction: validateInputForFilterExpression,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.FilterConfigTooltip
  })
  @Serializable("")
  @Placeholder({
    text: LOCALIZATION_DICTIONARY.propertySheet.Inherited,
    combineWithRuntimeValue: false
  })
  toExpression?: string;

  constructor(timeRange: Partial<TimeRangeConfigurationDto> = {}) {
    construct(this, timeRange, TYPE_NAME);
  }
}
