import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { fromEvent, of, Subject } from "rxjs";
import { debounceTime, map, switchMap, takeUntil } from "rxjs/operators";
import { BaseDialogComponent } from "../../../../../shared/components/base-dialog/base-dialog.component";
import { AxisData } from "../../../../models/dialogs/axis-data.model";

@Component({
  selector: "c-add-axis-dialog",
  templateUrl: "./add-axis-dialog.component.html",
  styleUrls: ["./add-axis-dialog.component.scss"]
})
export class AddAxisDialogComponent
  extends BaseDialogComponent
  implements AfterViewInit, OnDestroy
{
  @ViewChild("titleInput", { static: true }) titleInput: ElementRef;
  @ViewChild("unitInput", { static: true }) unitInput: ElementRef;
  unsubscribeSubject$: Subject<any> = new Subject<any>();
  public inputsEmpty: boolean;

  private titleEmpty: boolean;
  private unitEmpty: boolean;

  ngOnDestroy() {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  constructor(public dialogRef: MatDialogRef<AddAxisDialogComponent>) {
    super();
    this.inputsEmpty = true;
    this.titleEmpty = true;
    this.unitEmpty = true;
  }

  onDialogClosed(): void {
    const axisData: AxisData = {
      title: this.titleInput.nativeElement.value,
      unit: this.unitInput.nativeElement.value
    };
    this.dialogRef.close(axisData);
  }

  ngAfterViewInit() {
    const DEBOUNCE_TIME = 50;
    // checking if inputs are empty
    fromEvent(this.titleInput.nativeElement, "keyup")
      .pipe(
        debounceTime(DEBOUNCE_TIME),
        map((event: any) => event.target.value),
        switchMap((value: string) => {
          if (value) {
            return of(true);
          } else {
            return of(false);
          }
        }),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe((titleInputFull: boolean) => {
        this.titleEmpty = !titleInputFull;
        if (!this.unitEmpty) {
          this.inputsEmpty = !titleInputFull;
        } else {
          this.inputsEmpty = true;
        }
      });

    fromEvent(this.unitInput.nativeElement, "keyup")
      .pipe(
        debounceTime(DEBOUNCE_TIME),
        map((event: any) => event.target.value),
        switchMap((value: string) => {
          if (value) {
            return of(true);
          } else {
            return of(false);
          }
        }),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe((unitInputFull: boolean) => {
        this.unitEmpty = !unitInputFull;

        if (!this.titleEmpty) {
          this.inputsEmpty = !unitInputFull;
        } else {
          this.inputsEmpty = true;
        }
      });
  }
}
