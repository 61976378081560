import {
  EquipmentPath,
  EquipmentPathStep,
  PathAxis,
  PathPredicate,
  PredicateType
} from "../../core/models/equipment-path";
import { isDefined } from "../../ts-utils/helpers/predicates.helper";
import { CriticalError } from "../../ts-utils/models/critical-error";

export function ePathToString(path: EquipmentPath): string {
  const stepsString = ePathStepsToString(path.steps);
  if (isDefined(path.overallIndex)) {
    return `(${stepsString})[${path.overallIndex}]`;
  } else {
    return stepsString;
  }
}

export function ePathStepsToString(steps: EquipmentPathStep[]): string {
  return steps.reduce((toThisStep: string, step: EquipmentPathStep) => {
    const axisString = "/" + axisToString(step.axis);
    if (step.predicates.length === 0) {
      return toThisStep + (step.axis === PathAxis.Parent ? axisString : axisString + "*");
    }
    const predicatesString = step.predicates.reduce(
      (acc, predicate) => acc + predicateToString(predicate),
      ""
    );
    return toThisStep + axisString + predicatesString;
  }, "");
}

function axisToString(axis: PathAxis): string {
  switch (axis) {
    case PathAxis.Parent:
      return "..";
    case PathAxis.Child:
      return "";
    case PathAxis.DescendantOrSelf:
      return "/";
    default:
      throw new CriticalError(`Unknown axis type ${axis}`);
  }
}

function predicateToString(predicate: PathPredicate): string {
  switch (predicate.type) {
    case PredicateType.NodeName:
      return predicate.expected as string;
    case PredicateType.EquipmentClass:
      return "[" + predicate.expected + "]";
    default:
      throw new CriticalError(`Unknown predicate type ${predicate.type}`);
  }
}
