import { Component, Input, OnChanges } from "@angular/core";
import * as Highcharts from "highcharts";

import { TableComponentCell } from "../../models/table-component-cell";
import { SimpleTrendViewConfig } from "./view-config";

@Component({
  selector: "c-simple-trend",
  templateUrl: "./simple-trend.component.html",
  styleUrls: ["./simple-trend.component.scss"]
})
export class SimpleTrendComponent implements TableComponentCell, OnChanges {
  @Input() public value: number[][];
  @Input() public config: SimpleTrendViewConfig;

  public Highcharts = Highcharts;
  public chartOptions: any;
  public constructorType = "chart";

  ngOnChanges() {
    this.setChartOptions();
  }

  protected setChartOptions(): void {
    this.chartOptions = {
      lang: {
        noData: "No Data Available"
      },
      chart: {
        type: "line",
        backgroundColor: "rgba(255, 255, 255, 0.0)"
      },
      title: {
        text: null
      },
      credits: {
        enabled: false
      },
      xAxis: {
        type: "datetime",
        tickAmount: 0,
        title: {
          text: null
        },
        labels: {
          format: ""
        }
      },
      yAxis: {
        min: 0,
        tickAmount: 0,
        showFirstLabel: false,
        showLastLabel: false,
        title: {
          text: null
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        line: {
          marker: {
            enabled: false
          }
        }
      },
      series: [
        {
          name: "Values",
          data: this.value,
          color: this.config.plotColor,
          enableMouseTracking: false
        }
      ]
    };
  }
}
