import { Injectable } from "@angular/core";
import { cloneDeep as _cloneDeep } from "lodash";
import { getBasicCards } from "../helpers/v0.helper";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class UpgradeCardSize implements UpgradeStep {
  name = "UpgradeCardSize";
  fromVersion = new Version(0, 0, 5);

  perform(oldConfig: any): UpgradeStepResult {
    const cards = getBasicCards(oldConfig);
    let changed = false;
    cards.forEach((card) => {
      const view = card["view"];
      const collapsed = view["collapsedSize"];
      if (collapsed == null) {
        const size = view["size"];
        view["collapsedSize"] = _cloneDeep(size);
        view["expandedSize"] = _cloneDeep(size);
        changed = true;
      }
    });
    return {
      result: oldConfig,
      modified: changed,
      warning: null
    };
  }
}
