import { Injectable } from "@angular/core";
import { isDefined } from "../../ts-utils/helpers/predicates.helper";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class RemoveColumnIdInConnectors implements UpgradeStep {
  name = "RemoveColumnIdInConnectors";
  fromVersion = new Version(4, 0, 9);

  perform(oldConfig: any): UpgradeStepResult {
    const dataConnectorViews = oldConfig.dataConnectorViews;
    let modified = false;
    if (isDefined(dataConnectorViews)) {
      for (const dataConnectorView of Object.values(dataConnectorViews.entities)) {
        if (
          isDefined((dataConnectorView as any).column) &&
          isDefined((dataConnectorView as any).column.columnId)
        ) {
          delete (dataConnectorView as any).column.columnId;
          modified = true;
        }
      }
    }
    return {
      result: oldConfig,
      modified: modified,
      warning: null
    };
  }
}
