import { Injectable } from "@angular/core";
import { cloneDeep as _cloneDeep } from "lodash";
import { DataTransferObject } from "../../core/models/data-transfer-object";
import { toDictionary } from "../../ts-utils/helpers/dictionary.helper";
import { isEmpty } from "../../ts-utils/helpers/is-empty.helper";
import { isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { ConstructorFunction } from "../../ts-utils/models/constructor-function";
import { Dictionary } from "../../ts-utils/models/dictionary.type";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { StrictPartial } from "../../ts-utils/models/strict-partial.type";
import { endMeasurement, startMeasurement } from "../helpers/performance-measurement.helper";
import { isKey, isTitle } from "../helpers/property-descriptor-predicates.helper";
import { Entity } from "../models/entity";
import { ObjectDescriptor } from "../models/object-descriptor";
import { ParentEntityInfo, ParentEntityType } from "../models/parent-entity-info";
import { PropertyCategory } from "../models/property-category";
import { PropertyChangeHandler } from "../models/property-change-handler";
import { PropertyDescriptor } from "../models/property-descriptor";
import { PropertyInfo } from "../models/property-info";
import { PropertyPaths } from "../models/property-paths";
import { TypeDescriptor } from "../models/type-descriptor";

@Injectable()
export class TypeProvider {
  private static _instance: TypeProvider;

  private _typesByName: Dictionary<TypeDescriptor>;
  private _typesByConstructor: Map<Function, TypeDescriptor>;

  protected constructor() {
    this._typesByName = {};
    this._typesByConstructor = new Map<Function, TypeDescriptor>();
    this.createInitialTypesDictionary();
  }

  static getInstance(): TypeProvider {
    return TypeProvider._instance ?? (TypeProvider._instance = new TypeProvider());
  }

  static getDefaultsForType<T extends Object>(constructorFunction: ConstructorFunction<T>): T {
    const type: TypeDescriptor =
      TypeProvider.getInstance().getTypeByConstructor(constructorFunction);
    const defaultObject: T = type.properties.reduce((acc: T, prop: PropertyDescriptor) => {
      acc[prop.name] = _cloneDeep(prop.defaultValue);
      return acc;
    }, {} as T);
    return defaultObject;
  }

  getTypes(): TypeDescriptor[] {
    return Array.from(this._typesByConstructor.values());
  }

  private createInitialTypesDictionary(): void {
    const primitiveTypes: TypeDescriptor[] = [];
    primitiveTypes.push(
      new TypeDescriptor({
        constructorFunction: Object.getPrototypeOf("").constructor,
        name: "string",
        isPrimitive: true
      })
    );
    primitiveTypes.push(
      new TypeDescriptor({
        constructorFunction: Object.getPrototypeOf("").constructor,
        name: "String",
        isPrimitive: true
      })
    );
    primitiveTypes.push(
      new TypeDescriptor({
        constructorFunction: Object.getPrototypeOf(true).constructor,
        name: "boolean",
        isPrimitive: true
      })
    );
    primitiveTypes.push(
      new TypeDescriptor({
        constructorFunction: Object.getPrototypeOf(true).constructor,
        name: "Boolean",
        isPrimitive: true
      })
    );
    primitiveTypes.push(
      new TypeDescriptor({
        constructorFunction: Object.getPrototypeOf(0).constructor,
        name: "number",
        isPrimitive: true
      })
    );
    primitiveTypes.push(
      new TypeDescriptor({
        constructorFunction: Object.getPrototypeOf(0).constructor,
        name: "Number",
        isPrimitive: true
      })
    );
    primitiveTypes.push(
      new TypeDescriptor({
        constructorFunction: Object.getPrototypeOf({}).constructor,
        name: "object",
        isPrimitive: true
      })
    );
    primitiveTypes.push(
      new TypeDescriptor({
        constructorFunction: Object.getPrototypeOf({}).constructor,
        name: "Object",
        isPrimitive: true
      })
    );
    primitiveTypes.forEach((type) => this.addTypeImp(type));
  }

  isCompositeType(typeConstructor: Function): boolean {
    const typeDescriptor: TypeDescriptor = this.getTypeByConstructor(typeConstructor);
    return typeDescriptor.isCompositeType;
  }

  getType(typeName: string): TypeDescriptor {
    // NOTE: added type doesnt have to have name defined at first.
    const found = this.tryGetType(typeName);
    if (!found) {
      throw new Error(`Type '${typeName}' not found`);
    }
    return found;

    // const matches: TypeDescriptor[] = this.getTypes().filter(
    //   (typeDescriptor) => typeDescriptor.name === typeName
    // );
    // if (!matches.length) {
    //   throw new Error(`Type '${typeName}' not found`);
    // } else if (matches.length > 1 && !matches[0].isPrimitive) {
    //   throw new Error(`Found multiple type descriptors with type '${typeName}`);
    // }
    // return matches[0];
  }

  tryGetType(typeName: string): Maybe<TypeDescriptor> {
    const found = this._typesByName[typeName];
    if (!found) {
      return null;
    }
    return found;
  }

  getTypeByConstructor(constructorFunc: Function): TypeDescriptor {
    const typeDescriptor = this.tryGetTypeByConstructor(constructorFunc);
    if (!typeDescriptor) {
      throw new Error(`Type for constructor name ${constructorFunc.name} not found.`);
    }
    return typeDescriptor;
  }

  tryGetTypeByConstructor(constructorFunc: Function): Maybe<TypeDescriptor> {
    const typeDescriptor = this._typesByConstructor.get(constructorFunc);
    if (!typeDescriptor) {
      return null;
    }
    return typeDescriptor;
  }

  addType(params: StrictPartial<TypeDescriptor, "constructorFunction">): TypeDescriptor {
    let typeDescriptor = this.tryGetTypeByConstructor(params.constructorFunction);
    if (!typeDescriptor) {
      typeDescriptor = new TypeDescriptor(params);
      this.addTypeImp(typeDescriptor);
    }
    if (isDefined(params.name)) {
      typeDescriptor.name = params.name;
      this._typesByName[params.name] = typeDescriptor;
    }
    if (isDefined(params.isVirtual)) {
      typeDescriptor.isVirtual = params.isVirtual;
    }
    if (isDefined(params.initialConfig)) {
      typeDescriptor.initialConfig = params.initialConfig;
    }

    if (isDefined(params.overridePropertyValues)) {
      typeDescriptor.overridePropertyValues = params.overridePropertyValues;
    }

    return typeDescriptor;
  }

  private addTypeImp(typeDescriptor: TypeDescriptor): void {
    this._typesByConstructor.set(typeDescriptor.constructorFunction, typeDescriptor);
    if (typeDescriptor.name) {
      this._typesByName[typeDescriptor.name] = typeDescriptor;
    }
  }

  upsertProperty(
    proto: Object,
    name: string,
    partialPropertyDescriptor: Partial<PropertyDescriptor>
  ): void {
    const typeDescriptor = this.addType({
      constructorFunction: proto.constructor as new () => any
    });
    const propConstructor = Reflect.getMetadata("design:type", proto, name);
    if (propConstructor == null) {
      throw new Error("propConstructor is null for " + name);
    }
    typeDescriptor.upsertProperty(name, propConstructor, partialPropertyDescriptor);
  }

  getAllProperties(type: TypeDescriptor): PropertyDescriptor[] {
    // TODO: what if prop decorator is overriden or even doesn't exist in inherited class?
    const ancestorProps: PropertyDescriptor[] = this.getAncestorTypesFor(type.constructorFunction)
      .reduce((acc: PropertyDescriptor[], ancestorType) => {
        const ancestorProperties = type.overridePropertyValues
          ? this.overrideAncestorDescriptors(ancestorType.properties, type.name)
          : [...ancestorType.properties];
        return [...acc, ...ancestorProperties];
      }, [])
      .filter((ancestorProp: PropertyDescriptor) => ancestorProp.isInheritable)
      .filter((ancestorProp: PropertyDescriptor) => !typeOverridesProperty(type, ancestorProp));

    return [...ancestorProps, ...type.properties];
  }

  private overrideAncestorDescriptors(
    ancestorProps: PropertyDescriptor[],
    typeName: string
  ): PropertyDescriptor[] {
    return ancestorProps.map((ancestorProp: PropertyDescriptor) => {
      return isDefined(ancestorProp.overrideValueFunction)
        ? ancestorProp.overrideValueFunction(ancestorProp.name, typeName, ancestorProp.isHidden)
        : ancestorProp;
    });
  }

  getTitlePropertyItemsDeep(type: TypeDescriptor, instance: any): PropertyInfo<string>[] {
    const titleProps: PropertyInfo<string>[] = this.getAllPropertyItemsDeep(type, instance).filter(
      isTitle
    );
    return titleProps;
  }

  getKeyPropertyItemDeep(type: TypeDescriptor, instance: any): Maybe<PropertyInfo<any>> {
    return this.getAllPropertyItemsDeep(type, instance).find(isKey);
  }

  getAllPropertyItemsDeep(
    type: TypeDescriptor,
    instance: DataTransferObject,
    evaluatedInstance: Maybe<DataTransferObject> = null,
    propertyPaths: PropertyPaths = new PropertyPaths(),
    targetInfo: Maybe<ObjectDescriptor<unknown>> = null,
    parentEntityInfo: Maybe<ParentEntityInfo> = null
  ): PropertyInfo<unknown>[] {
    startMeasurement("getAllPropertyItemsDeep");
    const res = this.getAllPropertyItemsDeep2(
      type,
      instance,
      { localPath: propertyPaths.localPath, originalPath: propertyPaths.originalPath },
      targetInfo,
      parentEntityInfo,
      evaluatedInstance
    );
    endMeasurement("getAllPropertyItemsDeep");
    return res;
  }

  // TODO Find way to make instance type-safe
  getAllPropertyItemsDeep2(
    type: TypeDescriptor,
    instance: DataTransferObject,
    propertyPaths: PropertyPaths,
    targetInfo: Maybe<ObjectDescriptor<unknown>> = null,
    parentEntityInfo: Maybe<ParentEntityInfo> = null,
    evaluatedInstance: Maybe<DataTransferObject> = null
  ): PropertyInfo<unknown>[] {
    let shallowMembers: PropertyDescriptor[];
    if (type.isVirtual) {
      const specializedType: TypeDescriptor = this.getType(instance.typeName);
      shallowMembers = this.getAllProperties(specializedType);
    } else {
      shallowMembers = this.getAllProperties(type);
    }

    const shallowMemberItems: PropertyInfo<unknown>[] = shallowMembers.map(
      (shallowProp: PropertyDescriptor) => {
        const propIsEntity: boolean = this.isPropertyEntity(shallowProp);
        const memberItem: PropertyInfo<unknown> = {
          value: instance[shallowProp.name],
          descriptor: shallowProp,
          localPath: propIsEntity ? [] : propertyPaths.localPath.concat([shallowProp.name]),
          targetInfo: null,
          originalPath: propertyPaths.originalPath.concat([shallowProp.name]),
          parentEntityInfo: parentEntityInfo,
          placeholder: shallowProp.placeholderConfig
        };
        if (isDefined(targetInfo)) {
          memberItem.targetInfo = targetInfo;
        }

        if (
          isDefined(evaluatedInstance) &&
          (shallowProp.allowInterpolation || !isEmpty(shallowProp.dynamicDefaults))
        ) {
          memberItem.runtimeValue = evaluatedInstance[shallowProp.name];
        }

        return memberItem;
      }
    );

    const deepMemberItems: PropertyInfo<unknown>[] = shallowMembers.reduce(
      (acc: PropertyInfo<unknown>[], shallowProp: PropertyDescriptor) => {
        if (!shallowProp.isArray && !shouldHidePropertyAndChildren(shallowProp)) {
          const expanded = this.expandProperty(
            shallowProp,
            instance,
            {
              localPath: propertyPaths.localPath,
              originalPath: propertyPaths.originalPath.concat([shallowProp.name])
            } as PropertyPaths,
            targetInfo,
            parentEntityInfo,
            evaluatedInstance
          );
          acc = acc.concat(expanded);
        }
        return acc;
      },
      []
    );
    return [...shallowMemberItems, ...deepMemberItems];
  }

  private expandProperty(
    shallowProp: PropertyDescriptor,
    instance: DataTransferObject,
    propertyPaths: PropertyPaths,
    targetInfo: ObjectDescriptor<unknown>,
    parentEntityInfo: Maybe<ParentEntityInfo> = null,
    evaluatedInstance: Maybe<DataTransferObject> = null
  ): PropertyInfo<unknown>[] {
    const shallowPropType: Maybe<TypeDescriptor> = this.tryGetTypeByConstructor(
      shallowProp.constructorFunction
    );
    if (shallowPropType == null) {
      // IP fails on id for the ComponentStateViewModel as constr. func is Object
      console.warn(
        `unknown type of property ${shallowProp.name} with constructor ${shallowProp.constructorFunction}`
      );
      return [];
    }
    let newTargetInfo: ObjectDescriptor<unknown> = targetInfo;
    let newLocalPropPath: string[] = propertyPaths.localPath.concat([shallowProp.name]);
    if (shallowProp.customPropertyExpander != null) {
      const customPropertyDescriptors = shallowProp.customPropertyExpander(instance);
      const res = customPropertyDescriptors.map((propDesc: PropertyDescriptor) => {
        return {
          descriptor: propDesc,
          value: instance[shallowProp.name][propDesc.name],
          localPath: [...newLocalPropPath, propDesc.name],
          targetInfo,
          originalPath: [...propertyPaths.originalPath, propDesc.name],
          parentEntityInfo: parentEntityInfo,
          placeholder: propDesc.placeholderConfig
        } as PropertyInfo<unknown>;
      });
      return res;
    }
    const propIsEntity: boolean = this.isPropertyEntity(shallowProp);
    if (propIsEntity) {
      parentEntityInfo = {
        parentId: (instance as Entity).id,
        entityType: (instance as Entity).typeName as ParentEntityType
      };
      newTargetInfo = {
        value: instance[shallowProp.name],
        type: shallowPropType
      };
      propertyPaths.originalPath = [...newLocalPropPath];
      newLocalPropPath = [];
    }

    const allProperties = this.getAllPropertyItemsDeep(
      shallowPropType,
      instance[shallowProp.name],
      isDefined(evaluatedInstance) ? evaluatedInstance[shallowProp.name] : null,
      { localPath: newLocalPropPath, originalPath: propertyPaths.originalPath },
      newTargetInfo,
      parentEntityInfo
    );

    if (isDefined(shallowProp.displayValues)) {
      allProperties.forEach((item) => {
        item.descriptor.displayValues = shallowProp.displayValues;
      });
    }

    if (isDefined(shallowProp.visibilitySelector)) {
      allProperties.forEach((item) => {
        item.descriptor.visibilitySelector = shallowProp.visibilitySelector;
      });
    }
    return allProperties;
  }

  private _allInheritedFromEntityByName: Dictionary<TypeDescriptor> | null = null;

  private getAllInheritedFromEntityByName(): Dictionary<TypeDescriptor> {
    if (this._allInheritedFromEntityByName === null) {
      this._allInheritedFromEntityByName = toDictionary(
        this.getAllInheritedFrom(this.getType("Entity")),
        (x) => x.name,
        (x) => x
      );
    }
    return this._allInheritedFromEntityByName;
  }

  private isPropertyEntity(property: PropertyDescriptor): boolean {
    const allEntityTypes = this.getAllInheritedFromEntityByName();
    const propertyType: TypeDescriptor = this.getTypeByConstructor(property.constructorFunction);
    return allEntityTypes[propertyType.name] !== undefined;
  }

  filterPropertyItems(
    items: PropertyInfo<unknown>[],
    category: PropertyCategory,
    subCategory?: string
  ): PropertyInfo<unknown>[] {
    return items
      .filter(
        (propertyItem: PropertyInfo<unknown>) => propertyItem.descriptor.category === category
      )
      .filter((propertyItem: PropertyInfo<unknown>) => {
        if (typeof subCategory !== "undefined") {
          return propertyItem.descriptor.subCategory === subCategory;
        } else {
          return true;
        }
      });
  }

  getAllInheritedFrom(baseType: TypeDescriptor): TypeDescriptor[] {
    return this.getTypes().filter((currentType: TypeDescriptor) =>
      currentType.isInheritedFrom(baseType)
    );
  }

  private getAncestorTypesFor(constructorFunction: Function): TypeDescriptor[] {
    const prototypeList: Function[] = []; // [constructorFunction];
    while (constructorFunction && constructorFunction !== Object.prototype) {
      constructorFunction = Object.getPrototypeOf(constructorFunction);
      prototypeList.push(constructorFunction);
    }
    return prototypeList
      .map((constructorFunc) => this.tryGetTypeByConstructor(constructorFunc))
      .filter(isDefined);
  }

  public getActualPropertyDescriptor(
    propertyDescriptor: PropertyDescriptor,
    propertyValue: any
  ): PropertyDescriptor {
    const shouldTakeConstructorFunctionFromPropertyDescriptor =
      propertyDescriptor.isArray || !propertyValue || propertyDescriptor.isEnum;
    const constructorFunction = shouldTakeConstructorFunctionFromPropertyDescriptor
      ? propertyDescriptor.constructorFunction
      : propertyValue.constructor;
    let actualPropertyDescriptor: PropertyDescriptor = propertyDescriptor;
    if (propertyValue && propertyDescriptor.isVirtual) {
      const actualPropertyType = this.getTypeByConstructor(constructorFunction);
      actualPropertyDescriptor = PropertyDescriptor.createFrom(propertyDescriptor, {
        constructorFunction: actualPropertyType.constructorFunction,
        isVirtual: false
      });
    }
    return actualPropertyDescriptor;
  }

  getPropertyObserver(
    type: TypeDescriptor,
    propertyName: string
  ): Maybe<PropertyChangeHandler<unknown, unknown, unknown>> {
    return [type, ...this.getAncestorTypesFor(type.constructorFunction)]
      .map((currentType: TypeDescriptor) => currentType.getPropertyObserver(propertyName))
      .find((observer: Maybe<PropertyChangeHandler<unknown, unknown, unknown>>) =>
        isDefined(observer)
      );
  }

  /** gets property observer for last element in path */
  getPropertyObserverByPath(
    type: TypeDescriptor,
    path: string[]
  ): Maybe<PropertyChangeHandler<unknown, unknown, unknown>> {
    if (path.length === 1) {
      return this.getPropertyObserver(type, path[0]);
    } else {
      const [pathFragment, ...rest] = path;
      const propertyDescriptor = type.getPropertyByName(pathFragment);
      if (isDefined(propertyDescriptor)) {
        const typeDescriptor = this.getTypeByConstructor(propertyDescriptor.constructorFunction);
        return this.getPropertyObserverByPath(typeDescriptor, rest);
      } else {
        console.warn("No child property on defined path");
        return null;
      }
    }
  }

  areEqualTypes(firstConstructorFunc: Function, secondConstructorFunc: Function): boolean {
    return (
      this.getTypeByConstructor(firstConstructorFunc).name ===
      this.getTypeByConstructor(secondConstructorFunc).name
    );
  }

  getDefaultsByName<T>(typeName: string): Partial<T> {
    const typeDescriptor: TypeDescriptor = this.getType(typeName);
    return this.getDefaults<T>(typeDescriptor);
  }

  getDefaults<T>(typeDescriptor: TypeDescriptor): Partial<T> {
    const propDescriptors: PropertyDescriptor[] = this.getAllProperties(typeDescriptor);
    const dto: Partial<T> = {};
    propDescriptors.forEach((propDesc: PropertyDescriptor) => {
      const { name: propName, defaultValue } = propDesc;
      if (defaultValue !== undefined) {
        dto[propName] = defaultValue;
      } else {
        const propTypeDescriptor = this.getTypeByConstructor(propDesc.constructorFunction);
        if (!propTypeDescriptor.isPrimitive) {
          const fallbackDefault = this.getDefaults<unknown>(propTypeDescriptor);
          dto[propName] = fallbackDefault;
        }
      }
    });
    return dto;
  }

  getDerivedType(itemValue: any, propertyDescriptor: PropertyDescriptor): TypeDescriptor {
    let type = this.getTypeByConstructor(propertyDescriptor.constructorFunction);
    if (isNotDefined(type)) {
      console.error(
        `Type descriptor not found`,
        propertyDescriptor.displayName,
        propertyDescriptor.constructorFunction
      );
    }
    if (type.isVirtual && typeof itemValue !== "undefined") {
      type = this.getType((itemValue as DataTransferObject).typeName);
      if (isNotDefined(type)) {
        console.log(`Type descriptor not found`, itemValue);
      }
    }
    return type;
  }

  getPropertyNames(typeName: string, instance: any): string[] {
    const typeDescriptor: TypeDescriptor = this.getType(typeName);
    const allProperties = this.getAllPropertyItemsDeep(typeDescriptor, instance);

    return allProperties.reduce((acc: string[], prop: PropertyInfo<unknown>) => {
      const isPrimitiveType = this.getTypeByConstructor(
        prop.descriptor.constructorFunction
      ).isPrimitive;
      if (isPrimitiveType && !prop.descriptor.isArray) {
        acc.push(prop.descriptor.name);
      }
      return acc;
    }, []);
  }
}

function typeOverridesProperty(type: TypeDescriptor, ancestorProp: PropertyDescriptor): boolean {
  return type.properties.map((prop) => prop.name).includes(ancestorProp.name);
}
function shouldHidePropertyAndChildren(property: PropertyDescriptor): boolean {
  return property.isHidden && property.hideChildren;
}

export const typeProviderFactory = (): TypeProvider => {
  return TypeProvider.getInstance();
};
