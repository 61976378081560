import { FILTER_ID_SUFFIX } from "../../core/helpers/filter/filter-id.helper";
import { COMPONENT_STATE_VIEW_MODEL } from "../../elements/models/entity-type.constants";
import { Y_KEY, Z_KEY } from "../../keyboard.constants";
import { resolveSnapshotAction } from "../../meta/helpers/action-type.helper";
import { EntityId } from "../../meta/models/entity";
import { PropertyInfo } from "../../meta/models/property-info";
import { PropertySheetSnapshotState } from "../../meta/models/property-sheet-snapshot-state";
import { SnapshotContext } from "../../meta/models/snapshot-context";
import { UpdatedEntitiesInfo } from "../../meta/models/updated-entities-info";
import { isDefined } from "../../ts-utils";
import { isInputElement } from "./html-element-type.helper";

export function isShortcutPressed(isCtrlKeyPressed: boolean, key: string): boolean {
  return (
    isCtrlKeyPressed && (key === Z_KEY || key === Y_KEY) && !isInputElement(document.activeElement)
  );
}

export function createSnapshotContextByEditor(
  propertyConfig: PropertyInfo<unknown>,
  target: any
): SnapshotContext {
  const propertyEditorPath: string[] = propertyConfig.originalPath;
  let entityId: EntityId;
  let typeName: string;
  if (target.id?.includes(FILTER_ID_SUFFIX)) {
    entityId = target.id.replace(FILTER_ID_SUFFIX, "");
    typeName = COMPONENT_STATE_VIEW_MODEL;
  }
  const updatedEntitiesInfo: UpdatedEntitiesInfo = {
    entityIds: isDefined(entityId) ? [entityId] : [target.id],
    entitiesTypeName: typeName ?? target.typeName
  };
  const propertySheetSnapshotState: PropertySheetSnapshotState = {
    category: propertyConfig.descriptor.category,
    advancedMode: propertyConfig.descriptor.advancedMode
  };
  return {
    actionType: resolveSnapshotAction(propertyEditorPath),
    updatedEntitiesInfo,
    propertySheetSnapshotState,
    propertyEditorPath
  };
}
