import { Component, Input } from "@angular/core";
import { HeaderButtonConfig } from "../../models/button/header-button.config";

@Component({
  selector: "header-button",
  templateUrl: "./header-button.component.html",
  styleUrls: ["./header-button.component.scss"]
})
export class HeaderButtonComponent {
  @Input() buttonConfig: HeaderButtonConfig = new HeaderButtonConfig();
  @Input() isSelected: boolean = false;
  @Input() showLabel: boolean = false;
  @Input() hasProgressBar: boolean = false;
  @Input() icon: string = "";
  @Input() verticallyOpened: boolean = false;

  onClick(event: Event): void {
    this.buttonConfig.clickEventFunction(event);
    this.buttonConfig.clickFunction();
  }
}
