import { ActionReducerMap } from "@ngrx/store/src/models";
import { BrowsingState } from "./browsing.state";
import * as fromCachedReports from "./cached-reports/cached-reports.reducer";
import * as fromDataExplorer from "./data-explorer/data-explorer.reducer";
import * as fromEquipment from "./equipment/equipment.reducer";
import { reducer } from "./report-ids/report-ids.reducer";

export const reducers: ActionReducerMap<BrowsingState> = {
  cachedReports: fromCachedReports.reducer,
  allReportDescriptions: reducer, // FIXME: Not good
  equipment: fromEquipment.reducer,
  dataExplorer: fromDataExplorer.reducer
};
