import { Injectable } from "@angular/core";
import { EntityState } from "@ngrx/entity";
import { DataConnectorDto } from "../../data-connectivity";
import { getConnectorViewId } from "../../data-connectivity/helpers/connector-view-id.helper";
import { DataConnectorViewDto } from "../../data-connectivity/models/data-connector-view";
import { SeriesType } from "../../data-connectivity/models/series-type.strategies";
import { ComponentStateDto } from "../../elements";
import { X_COLUMN_ID } from "../../elements/components/simple-components/table-for-connectors/table-rendering-strategies/connector-per-column-strategy";
import { getPseudoConnectorId } from "../../elements/helpers/connectors.helper";
import { TableConnectorView } from "../../elements/models/table/table-connector-view";
import { TimeSeriesConnectorView } from "../../elements/models/timeseries-connector-view";
import { EntityId } from "../../meta";
import { isDefined, isNotDefined } from "../../ts-utils";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class RelocateDataConnectorViewProperties implements UpgradeStep {
  name = "RelocateDataConnectorViewProperties";
  fromVersion = new Version(3, 0, 0);

  perform(oldConfig: any): UpgradeStepResult {
    const dataConnectorViewsState = oldConfig.dataConnectorViews;
    const dataConnectorsState = oldConfig.dataConnectors;
    const componentStates = oldConfig.componentStates;
    let modified = false;
    if (isDefined(dataConnectorViewsState)) {
      for (const dataConnectorView of Object.values(dataConnectorViewsState.entities)) {
        modified =
          this.relocateTimeSeriesConfiguration(dataConnectorViewsState, dataConnectorView) ||
          modified;
      }
    }
    if (isDefined(componentStates)) {
      for (const componentState of Object.values(componentStates.entities)) {
        const columnsConfiguration = (componentState as any).view?.columnsConfiguration;
        if (isDefined(columnsConfiguration)) {
          modified =
            this.relocateColumnsConfiguration(
              componentStates,
              dataConnectorViewsState,
              dataConnectorsState,
              (componentState as any).id
            ) || modified;
        }
      }
    }
    return {
      result: oldConfig,
      modified: modified,
      warning: null
    };
  }

  private relocateTimeSeriesConfiguration(
    dataConnectorViewsState: EntityState<DataConnectorViewDto>,
    dataConnectorView: any
  ): boolean {
    let modified = false;

    const seriesType: SeriesType = (dataConnectorView as any).seriesType;
    const lineStyle: string = (dataConnectorView as any).lineStyle;
    const lineWidth: number = (dataConnectorView as any).lineWidth;
    const showMarker: boolean = (dataConnectorView as any).showMarker;
    const markerRadius: number = (dataConnectorView as any).markerRadius;
    const markerStyle: string = (dataConnectorView as any).markerStyle;

    dataConnectorView = {
      ...dataConnectorView,
      timeSeriesConfig: new TimeSeriesConnectorView(),
      column: new TableConnectorView()
    };
    if (isDefined(seriesType)) {
      delete (dataConnectorView as any).seriesType;
      dataConnectorView = {
        ...dataConnectorView,
        timeSeriesConfig: { ...dataConnectorView.timeSeriesConfig, seriesType }
      };
      modified = true;
    }
    if (isDefined(lineStyle)) {
      delete (dataConnectorView as any).lineStyle;
      dataConnectorView = {
        ...dataConnectorView,
        timeSeriesConfig: { ...dataConnectorView.timeSeriesConfig, lineStyle }
      };
      modified = true;
    }
    if (isDefined(lineWidth)) {
      delete (dataConnectorView as any).lineWidth;
      dataConnectorView = {
        ...dataConnectorView,
        timeSeriesConfig: { ...dataConnectorView.timeSeriesConfig, lineWidth }
      };
      modified = true;
    }
    if (isDefined(showMarker)) {
      delete (dataConnectorView as any).showMarker;
      dataConnectorView = {
        ...dataConnectorView,
        timeSeriesConfig: { ...dataConnectorView.timeSeriesConfig, showMarker }
      };
      modified = true;
    }
    if (isDefined(markerRadius)) {
      delete (dataConnectorView as any).markerRadius;
      dataConnectorView = {
        ...dataConnectorView,
        timeSeriesConfig: { ...dataConnectorView.timeSeriesConfig, markerRadius }
      };
      modified = true;
    }
    if (isDefined(markerStyle)) {
      delete (dataConnectorView as any).markerStyle;
      dataConnectorView = {
        ...dataConnectorView,
        timeSeriesConfig: { ...dataConnectorView.timeSeriesConfig, markerStyle }
      };
      modified = true;
    }
    dataConnectorViewsState.entities[dataConnectorView.id] = dataConnectorView;

    return modified;
  }

  private relocateColumnsConfiguration(
    componentStates: EntityState<ComponentStateDto>,
    dataConnectorViewsState: EntityState<DataConnectorViewDto>,
    dataConnectorsState: EntityState<DataConnectorDto>,
    componentId: EntityId
  ): boolean {
    let component = componentStates.entities[componentId];
    if (isNotDefined(component)) {
      return false;
    }
    const columns = Object.values((component as any).view?.columnsConfiguration) as any;
    columns.forEach((column, index) => {
      const dataConnectorId = column.columnId;
      let dataConnectorViewId = getConnectorViewId(dataConnectorId);
      if (dataConnectorId === X_COLUMN_ID) {
        const pseudoConnectorId = getPseudoConnectorId(componentId);
        const pseudoConnector = getOrCreateEntity<DataConnectorDto>(
          dataConnectorsState,
          pseudoConnectorId,
          () =>
            new DataConnectorDto({
              id: pseudoConnectorId
            })
        );
        if (
          isDefined(component?.dataConnectorIds) &&
          !component?.dataConnectorIds.includes(pseudoConnectorId)
        ) {
          component?.dataConnectorIds.push(pseudoConnectorId);
        }
        dataConnectorViewId = getConnectorViewId(pseudoConnector.id);
      } else {
        const connector = dataConnectorsState.entities[dataConnectorId];
        if (isDefined(connector)) {
          connector.title = (column as any).title;
        }
      }
      const dataConnectorView = getOrCreateEntity<DataConnectorViewDto>(
        dataConnectorViewsState,
        dataConnectorViewId,
        () =>
          new DataConnectorViewDto({
            id: dataConnectorViewId,
            order: index
          })
      );
      delete (column as any).title;
      dataConnectorView.column = { ...column };
    });
    delete (component?.view as any).columnsConfiguration;
    componentStates.entities[componentId] = component;
    return true;
  }
}

function getOrCreateEntity<T>(state: EntityState<T>, id: EntityId, creator: () => T): T {
  const existing = state.entities[id];
  if (isDefined(existing)) {
    return existing;
  }
  const newEntity = creator();
  (state.ids as string[]).push(id.toString());
  state.entities[id] = newEntity;
  return newEntity;
}
