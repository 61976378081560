import { take } from "rxjs/operators";
import { DecoratorDelegateContext } from "../../core/models/decorator-delegate-context";
import { GLOBAL_FILTER_VIEW_MODEL } from "../../elements/models/entity-type.constants";
import { addEmptyEntry } from "../../meta/helpers/enum-combobox.helper";
import { SelectionOption } from "../../meta/models/selection";
import { selectPeriodTypes } from "../store/feature.selector";

export function getPeriodTypes(context: DecoratorDelegateContext): SelectionOption[] {
  let periodTypes: SelectionOption[];
  context.store
    .select(selectPeriodTypes)
    .pipe(take(1))
    .subscribe((newPeriodTypes) => {
      periodTypes = Object.keys(newPeriodTypes).map((periodTypeKey) => {
        return {
          key: periodTypeKey,
          title: newPeriodTypes[periodTypeKey]
        };
      });
    });

  const emptyEntryName =
    context.ownerInstance.typeName === GLOBAL_FILTER_VIEW_MODEL
      ? context.services.localizationService.propertySheet.NonePeriodType
      : context.services.localizationService.propertySheet.Inherited;
  return addEmptyEntry(periodTypes, emptyEntryName);
}
