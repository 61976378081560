import { Action, createReducer, on } from "@ngrx/store";
import { ReportDescription } from "../../../core";
import { ReportIdsActions } from "./report-ids.actions";

const initialState: ReportDescription[] = [];

export function reducer(state: ReportDescription[], action: Action): ReportDescription[] {
  return _reducer(state, action);
}

const _reducer = createReducer(
  initialState,
  on(ReportIdsActions.fetchCached, (state) => fetchCached(state)),
  on(ReportIdsActions.fetchAllSuccess, (state, { reportIds }) => fetchAllSuccess(reportIds)),
  on(ReportIdsActions.clear, () => clear())
);

function fetchCached(state: ReportDescription[]): ReportDescription[] {
  const copy = [...state];
  return copy;
}

function fetchAllSuccess(reportIds: ReportDescription[]): ReportDescription[] {
  return reportIds;
}

function clear(): ReportDescription[] {
  return [];
}
