<div class="card-item__drag-handle">
  <div class="vibration-chart-container chart-container">
    <highcharts-chart-extended [Highcharts]="Highcharts" [options]="chartOptions">
    </highcharts-chart-extended>
    <div class="cell-data-container">
      <div #spinnerContainer class="spinner-container" style="width: 150px; height: 150px"></div>
      <div class="no-cells-selected-message">
        <div *ngIf="shouldShowClickMessage">
          Please click on a cell to see additional information.
        </div>
      </div>
      <div class="histogram-container" [ngClass]="{ shown: shouldShowHistograms }">
        <highcharts-chart-extended
          *ngFor="let histogramChartOp of histogramChartOptions"
          [Highcharts]="Highcharts"
          [options]="histogramChartOp"
        >
        </highcharts-chart-extended>
      </div>
    </div>
  </div>
</div>

