import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { combineLatest, EMPTY } from "rxjs";
import { concatMap, mergeMap, throttleTime, withLatestFrom } from "rxjs/operators";
import { isEmptyOrNotDefined } from "../../../ts-utils";
import { GenericDataSourceDescriptor } from "../../models/data-source/data-source-descriptors";
import { DataService } from "../../services/data.service";
import { ApiDataSourceDescriptorActions } from "../api-data-source-descriptor";
import { GenericDataSourceDescriptorActions } from "../generic-data-source-descriptor";
import { DataSourceDescriptorActions } from "./data-source-descriptor.actions";
import { selectDescriptors } from "./data-source-descriptor.selectors";

export const THROTTLE_TIME = 5 * 60 * 1000;

@Injectable()
export class DataSourceDescriptorEffects {
  constructor(
    private dataService: DataService,
    private actions$: Actions,
    private store$: Store<any>
  ) {}

  fetchDescriptors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataSourceDescriptorActions.loadDataSourceDescriptors),
      throttleTime(THROTTLE_TIME),
      withLatestFrom(this.store$.select(selectDescriptors)),
      concatMap(([action, cache]) => {
        if (
          isEmptyOrNotDefined(cache.apiDescriptors) ||
          isEmptyOrNotDefined(cache.genericDescriptors)
        ) {
          return combineLatest([
            this.dataService.getApiDataSourceDescriptors(),
            this.dataService.getGenericDataSourceDescriptors()
          ]);
        } else {
          return EMPTY; // use cached version
        }
      }),
      mergeMap(([apiDescriptors, genericDescriptors]) => {
        if (!isEmptyOrNotDefined(genericDescriptors)) {
          genericDescriptors = genericDescriptors.map(patchIfInvalid);
        }
        return [
          ApiDataSourceDescriptorActions.loadSuccess({
            descriptors: apiDescriptors
          }),
          GenericDataSourceDescriptorActions.loadSuccess({
            descriptors: genericDescriptors
          })
        ];
      })
    )
  );
}

function patchIfInvalid(descriptor: GenericDataSourceDescriptor): GenericDataSourceDescriptor {
  if (!descriptor.isValid) {
    descriptor.entity = descriptor.entity + " (invalid)";
    descriptor.columns = [];
  }
  return descriptor;
}
