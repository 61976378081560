import { Injectable } from "@angular/core";
import { IPTypeCheckService } from "../../../../../ui-core/src/lib/core";
import {
  DAY_PTYPE,
  HALF_HOUR_PTYPE,
  HOUR_PTYPE,
  MINUTE_PTYPE,
  PRI_PTYPE,
  QUARTER_HOUR_PTYPE,
  WEEK_PTYPE
} from "../../models/period-types";

@Injectable()
export class PTypeCheckService implements IPTypeCheckService {
  public isPri(periodType: string): boolean {
    return periodType === PRI_PTYPE;
  }

  public isMinute(periodType: string): boolean {
    return periodType === MINUTE_PTYPE;
  }

  public is15Minute(periodType: string): boolean {
    return periodType === QUARTER_HOUR_PTYPE;
  }

  public is30Minute(periodType: string): boolean {
    return periodType === HALF_HOUR_PTYPE;
  }

  public isHour(periodType: string): boolean {
    return periodType === HOUR_PTYPE;
  }

  public isShift(periodType: string): boolean {
    //PType not supported
    return false;
  }

  public isDay(periodType: string): boolean {
    return periodType === DAY_PTYPE;
  }

  public isWeek(periodType: string): boolean {
    return periodType === WEEK_PTYPE;
  }

  public isMonth(periodType: string): boolean {
    //PType not supported
    return false;
  }

  public isYear(periodType: string): boolean {
    //PType not supported
    return false;
  }
}
