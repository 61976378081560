import { createEntityAdapter, Update } from "@ngrx/entity";
import { Action, createReducer, createSelector, on } from "@ngrx/store";
import { isEqual as _isEqual } from "lodash";
import { COMPONENT_DATA_AGGREGATION_DTO } from "../../../core/models/dto-type.constants";
import * as DataExtractor from "../../../data-connectivity/helpers/data-extraction.helper";
import {
  isEmptySource,
  isSignalBased
} from "../../../data-connectivity/helpers/data-source-type.helper";
import { DataSourceDto } from "../../../data-connectivity/models";
import { ConnectorDataAggregationConfigDto } from "../../../data-connectivity/models/data-aggregation-config";
import { DataConnectorDto } from "../../../data-connectivity/models/data-connector";
import { getDataConnectorTitle } from "../../../meta/helpers/get-title.helper";
import { EntityId } from "../../../meta/models";
import { TypeProvider } from "../../../meta/services/type-provider";
import {
  assignDeep,
  CriticalError,
  Dictionary,
  flattenArrayDict,
  isDefined,
  isEmptyDict,
  isNotDefined,
  Maybe
} from "../../../ts-utils";
import { ConnectorsReplaceInfo } from "../../models/store/connectors-replace-info";
import { getDefaultReportContent } from "../../services/configuration.service";
import { CommonActions } from "../common/common.actions";
import { FilterActions } from "../filter/filter.actions";
import { DataConnectorActions } from "./data-connector.actions";
import { DataConnectorState } from "./data-connector.state";

const adapter = createEntityAdapter<DataConnectorDto>();
export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

const DATA_CONNECTOR_DEFAULTS = TypeProvider.getDefaultsForType(DataConnectorDto);

export const selectAllConnectors = createSelector(selectEntities, (entities) => {
  return Object.keys(entities).map((id) => entities[id]);
});

export const initialState: DataConnectorState = adapter.getInitialState();

export function reducer(state: DataConnectorState, action: Action): DataConnectorState {
  return _reducer(state, action);
}

const _reducer = createReducer(
  initialState,
  on(DataConnectorActions.addOne, (state, { connector }) => addOne(state, connector)),
  on(DataConnectorActions.addMany, (state, { connectors }) => addMany(state, connectors)),
  on(DataConnectorActions.deleteOne, (state, { connector }) => deleteOne(state, connector)),
  on(DataConnectorActions.deleteMany, (state, { connectorsByComponent }) =>
    deleteMany(state, flattenArrayDict(connectorsByComponent))
  ),
  on(DataConnectorActions.updateOne, (state, { connectorUpdate }) =>
    updateOne(state, connectorUpdate)
  ),
  on(DataConnectorActions.updateMany, (state, { connectorUpdates }) =>
    updateMany(state, connectorUpdates)
  ),
  on(DataConnectorActions.updateDataStatusMany, (state, action) => {
    return updateMany(
      state,
      Object.entries(action.dataConnectorStatusDic).map<Update<DataConnectorDto>>(
        ([id, status]) => ({ id, changes: { dataStatus: status } })
      )
    );
  }),
  on(DataConnectorActions.replaceOne, (state, { oldConnector, newConnector }) =>
    replaceOne(state, oldConnector.id, newConnector)
  ),
  on(DataConnectorActions.replaceMany, (state, { connectorsReplaceInfo }) =>
    replaceMany(state, connectorsReplaceInfo)
  ),
  on(DataConnectorActions.addOrReplaceData, (state, { connectorDict }) =>
    addOrReplaceData(state, connectorDict)
  ),
  on(DataConnectorActions.updateData, (state, { connectorDict, startCutoffDate }) =>
    updateData(state, connectorDict, startCutoffDate)
  ),
  on(DataConnectorActions.appendAdditionalDataPoints, (state, { connectorDict }) =>
    appendAdditionalDataPoints(state, connectorDict)
  ),
  on(DataConnectorActions.clearDataPoints, (state, { connectorIds }) =>
    clearDataPoints(state, connectorIds)
  ),
  on(DataConnectorActions.replaceGenericConnectors, (state, { connectorReplacementDict }) =>
    onReplaceGenericConnectors(state, connectorReplacementDict)
  ),
  on(
    DataConnectorActions.updateAllEquipmentSources,
    (state, { connectorReplacementDict, equipmentConnectorUpdates }) =>
      onUpdateAllEquipmentSources(state, connectorReplacementDict, equipmentConnectorUpdates)
  ),
  on(CommonActions.resetStore, () => setDefaultState()),
  on(CommonActions.upsertEntities, (state, { reportEntities }) =>
    onUpsertEntities(state, reportEntities.dataConnectors)
  ),
  on(CommonActions.upsertEntitiesOnLoad, (state, { reportEntities }) =>
    onUpsertEntities(state, reportEntities.dataConnectors)
  ),
  on(CommonActions.replaceAll, (state, { entities }) =>
    onReplaceAll(state, entities.dataConnectors)
  ),
  on(FilterActions.deleteOne, (state, { filterId }) => onFilterDelete(state, filterId))
);

function addOne(state: DataConnectorState, newConnector: DataConnectorDto): DataConnectorState {
  const fullConnector = new DataConnectorDto(newConnector);
  return adapter.addOne(fullConnector, state);
}

function addMany(state: DataConnectorState, newConnectors: DataConnectorDto[]): DataConnectorState {
  const fullConnectors = newConnectors.map(
    (connector: DataConnectorDto) => new DataConnectorDto(connector)
  );
  return adapter.addMany(fullConnectors, state);
}

function deleteOne(
  state: DataConnectorState,
  connector: Maybe<DataConnectorDto>
): DataConnectorState {
  if (isNotDefined(connector)) {
    return state;
  }
  return adapter.removeOne(connector.id.toString(), state);
}

function deleteMany(state: DataConnectorState, connectors: DataConnectorDto[]): DataConnectorState {
  const connectorIds: EntityId[] = connectors.map((conn) => conn.id);
  return connectorIds.length > 0 ? adapter.removeMany(connectorIds as string[], state) : state;
}

function updateOne(
  state: DataConnectorState,
  connectorUpdate: Update<DataConnectorDto>
): DataConnectorState {
  if (isNotDefined(connectorUpdate.id)) {
    throw new CriticalError("Undefined connector id.");
  }
  const mergedChanges = mergeConnectorChanges(connectorUpdate, state.entities[connectorUpdate.id]);
  if (isNotDefined(mergedChanges)) {
    return state;
  } else {
    if (isDefined(connectorUpdate.changes.dataSource)) {
      mergedChanges.changes.title = getDataConnectorTitle(mergedChanges.changes.dataSource);
      const dataSourceChangedToEmpty = isEmptySource(connectorUpdate.changes.dataSource);
      if (dataSourceChangedToEmpty) {
        mergedChanges.changes.dataPoints = null;
      }
    }
    return adapter.updateOne(mergedChanges, state);
  }
}

function updateMany(
  state: DataConnectorState,
  connectorUpdates: Update<DataConnectorDto>[]
): DataConnectorState {
  const mergedUpdates: Update<DataConnectorDto>[] = connectorUpdates
    .map((update) => {
      const mergedChanges = mergeConnectorChanges(update, state.entities[update.id]);
      if (isNotDefined(mergedChanges)) {
        return update;
      }
      if (
        shouldUpdateConnectorTitle(state.entities[update.id]?.dataSource, update.changes.dataSource)
      ) {
        mergedChanges.changes.title = getDataConnectorTitle(update.changes.dataSource);
      }

      if (shouldUpdateConnectorAggregation(state.entities[update.id]?.dataSource)) {
        mergedChanges.changes.dataSource = {
          ...mergedChanges.changes.dataSource,
          aggregationConfig: new ConnectorDataAggregationConfigDto()
        };
      }

      return mergedChanges;
    })
    .filter(isDefined);
  return adapter.updateMany(mergedUpdates, state);
}

function shouldUpdateConnectorTitle(
  oldDataSource: Maybe<DataSourceDto>,
  newDataSource: Maybe<DataSourceDto>
): boolean {
  if (isNotDefined(newDataSource)) {
    return false;
  }
  if (!isSignalBased(newDataSource)) {
    return false;
  }
  return !_isEqual(oldDataSource, newDataSource);
}

function shouldUpdateConnectorAggregation(oldDataSource: Maybe<DataSourceDto>): boolean {
  return (
    isDefined(oldDataSource) &&
    oldDataSource.aggregationConfig.typeName === COMPONENT_DATA_AGGREGATION_DTO
  );
}

function replaceOne(
  state: DataConnectorState,
  oldConnectorId: EntityId,
  newConnector: DataConnectorDto
): DataConnectorState {
  const connectorUpdate: Update<DataConnectorDto> = {
    id: oldConnectorId.toString(),
    changes: { ...newConnector }
  };
  return adapter.updateOne(connectorUpdate, state);
}

function replaceMany(
  state: DataConnectorState,
  connectorsReplaceInfo: ConnectorsReplaceInfo
): DataConnectorState {
  const obsoleteConnectorIds: string[] = connectorsReplaceInfo.obsoleteConnectors.map((conn) =>
    conn.id.toString()
  );
  state = adapter.removeMany(obsoleteConnectorIds, state);
  return addMany(state, connectorsReplaceInfo.newConnectors);
}

function addOrReplaceData(
  state: DataConnectorState,
  newConnectorsDict: Dictionary<DataConnectorDto>
): DataConnectorState {
  if (newConnectorsDict == null || isEmptyDict(newConnectorsDict)) {
    return state;
  }
  const dataConnectorsChanges = DataExtractor.getFullRangeConnectorUpdates(
    newConnectorsDict,
    state.entities
  );
  return adapter.updateMany(dataConnectorsChanges, state);
}

function updateData(
  state: DataConnectorState,
  newConnectorsDict: Dictionary<DataConnectorDto>,
  startCutoffDate: Maybe<Date>
): DataConnectorState {
  if (isNotDefined(newConnectorsDict) || isEmptyDict(newConnectorsDict)) {
    return state;
  }
  const dataConnectorUpdates = DataExtractor.getIncrementalConnectorUpdates(
    newConnectorsDict,
    state.entities,
    startCutoffDate
  );
  return adapter.updateMany(dataConnectorUpdates, state);
}

function appendAdditionalDataPoints(
  state: DataConnectorState,
  newConnectorsDict: Dictionary<DataConnectorDto>
): DataConnectorState {
  if (isNotDefined(newConnectorsDict) || isEmptyDict(newConnectorsDict)) {
    return state;
  }
  const dataConnectorUpdates = DataExtractor.getAdditionalDataConnectorUpdates(
    newConnectorsDict,
    state.entities
  );
  return adapter.updateMany(dataConnectorUpdates, state);
}

function clearDataPoints(state: DataConnectorState, connectorIds: EntityId[]): DataConnectorState {
  const updateArray: Update<DataConnectorDto>[] = connectorIds.reduce((acc, id) => {
    acc.push({
      id: id,
      changes: { dataPoints: [] }
    });
    return acc;
  }, []);

  return adapter.updateMany(updateArray, state);
}

function setDefaultState(): DataConnectorState {
  return getDefaultReportContent().dataConnectors;
}

function onUpsertEntities(
  state: DataConnectorState,
  dataConnectors: DataConnectorDto[]
): DataConnectorState {
  if (dataConnectors != null) {
    const fullConnectors = dataConnectors.map(
      (connector: DataConnectorDto) => new DataConnectorDto(connector)
    );
    return adapter.upsertMany(fullConnectors, state);
  } else {
    return state;
  }
}

function onReplaceGenericConnectors(
  state: DataConnectorState,
  genericConnectorReplacementInfo: Dictionary<ConnectorsReplaceInfo>
): DataConnectorState {
  return replaceConnectors(state, genericConnectorReplacementInfo);
}

function onUpdateAllEquipmentSources(
  state: DataConnectorState,
  dynamicConnectorReplacementInfo: Dictionary<ConnectorsReplaceInfo>,
  equipmentPropertyConnectors: Update<DataConnectorDto>[]
): DataConnectorState {
  state = replaceConnectors(state, dynamicConnectorReplacementInfo);
  const updates = mergeManyConnectorChanges(state, equipmentPropertyConnectors);
  return adapter.updateMany(updates, state);
}

function mergeManyConnectorChanges(
  state: DataConnectorState,
  equipmentPropertyConnectors: Update<DataConnectorDto>[]
): Update<DataConnectorDto>[] {
  return equipmentPropertyConnectors.reduce(
    (acc: Update<DataConnectorDto>[], equipmentConnectorUpdate) => {
      const update = mergeConnectorChanges(
        equipmentConnectorUpdate,
        state.entities[equipmentConnectorUpdate.id]
      );
      if (isDefined(update)) {
        acc.push(update);
      }
      return acc;
    },
    []
  );
}

function mergeConnectorChanges(
  connectorUpdate: Update<DataConnectorDto>,
  connectorInStore: Maybe<DataConnectorDto>
): Maybe<Update<DataConnectorDto>> {
  if (isNotDefined(connectorInStore)) {
    return null;
  }
  const mergedChanges = assignDeep(connectorInStore, connectorUpdate.changes);
  return { id: connectorUpdate.id.toString(), changes: mergedChanges };
}

function replaceConnectors(
  state: DataConnectorState,
  connectorReplacementDict: Dictionary<ConnectorsReplaceInfo>
): DataConnectorState {
  const replaceInfo: ConnectorsReplaceInfo = Object.keys(connectorReplacementDict).reduce(
    (acc, componentId) => {
      acc.obsoleteConnectors = acc.obsoleteConnectors.concat(
        connectorReplacementDict[componentId].obsoleteConnectors
      );
      acc.newConnectors = acc.newConnectors.concat(
        connectorReplacementDict[componentId].newConnectors
      );
      acc.connectorsToUpdate = acc.connectorsToUpdate.concat(
        connectorReplacementDict[componentId].connectorsToUpdate
      );

      return acc;
    },
    {
      obsoleteConnectors: [],
      newConnectors: [],
      connectorsToUpdate: []
    } as ConnectorsReplaceInfo
  );
  state = adapter.removeMany(
    replaceInfo.obsoleteConnectors.map((conn) => conn.id.toString()),
    state
  );
  state = updateMany(state, replaceInfo.connectorsToUpdate);
  return addMany(state, replaceInfo.newConnectors);
}

function onReplaceAll(
  state: DataConnectorState,
  newDataConnectors: DataConnectorDto[]
): DataConnectorState {
  return adapter.setAll(newDataConnectors, state);
}

function onFilterDelete(state: DataConnectorState, filterId: EntityId): DataConnectorState {
  const connectorsWithFilterUpdates: Update<DataConnectorDto>[] = getConnectorFilterUnsetUpdates(
    state,
    filterId
  );

  if (!!connectorsWithFilterUpdates && connectorsWithFilterUpdates.length > 0) {
    return adapter.updateMany(connectorsWithFilterUpdates, state);
  } else {
    return state;
  }
}

function getConnectorFilterUnsetUpdates(
  state: DataConnectorState,
  filterId: EntityId
): Update<DataConnectorDto>[] {
  return Object.values(state.entities)
    .filter(isDefined)
    .filter((connector: DataConnectorDto) => connector.filterId === filterId)
    .map((stateWithFilter: DataConnectorDto) => ({
      id: stateWithFilter.id.toString(),
      changes: { filterId: null }
    }));
}
