import { isDefined, Maybe } from "../../ts-utils";

export type DataPointValue = any; // number | string | boolean;
export type DataPointCategory = string;
export type XAxisPoint = Date | number | DataPointCategory;
export type YAxisPoint = any;

export const X_DATA_POINT = "x";
export const Y_DATA_POINT = "y";

export interface DataPointDto {
  x?: XAxisPoint;
  y: DataPointValue;
  evaluatedValue?: DataPointValue;
  properties?: any;
}

export interface TimeSeriesDataPointDto extends DataPointDto {
  x: Date;
  startTime?: Date;
  endTime?: Date;
}

export function hasValue(dataPoint: Maybe<DataPointDto>): dataPoint is DataPointDto {
  return isDefined(dataPoint?.y);
}

export function getKey(dataPoint: DataPointDto): Maybe<string | number> {
  const key = dataPoint.x;
  if (key instanceof Date) {
    return key.getTime();
  }
  return key;
}

export function sortDataPoints(a: DataPointDto, b: DataPointDto): number {
  const firstKey = getKey(a);
  const secondKey = getKey(b);
  if (firstKey < secondKey) {
    return -1;
  }
  if (firstKey > secondKey) {
    return 1;
  }
  return 0;
}
