import {
  BaseViewConfigDto,
  ComponentCssSize,
  Configurable,
  ConfigurationCategory,
  construct,
  DeepPartial,
  EditableType,
  EditorType,
  LOCALIZATION_DICTIONARY,
  Maybe,
  PropertyCategory,
  Serializable
} from "ui-core";

const TYPE_NAME = "StatorWindingTemperatureChartViewConfig";

@EditableType({ fullName: TYPE_NAME })
export class StatorWindingTemperatureChartViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  @Configurable({ displayName: "Alarm Color", editorType: EditorType.ColorPicker })
  @Serializable("#FFBB00")
  alarmColor!: string;

  @Configurable({ displayName: "Current Color", editorType: EditorType.ColorPicker })
  @Serializable("#004C97")
  currentColor!: string;

  @Configurable({ displayName: "Max Color", editorType: EditorType.ColorPicker })
  @Serializable("#2E92FA")
  maxColor!: string;

  @Configurable({ displayName: "Trip Color", editorType: EditorType.ColorPicker })
  @Serializable("#FF0000")
  tripColor!: string;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General, 5)
  @Configurable({ displayName: "Unit", editorType: EditorType.TextBox })
  @Serializable("")
  unit!: string;

  @Configurable({ displayName: "Alarm Limit Fallback", editorType: EditorType.Number })
  @Serializable(null)
  alarmLimit: Maybe<number>;

  @Configurable({ displayName: "Trip Limit Fallback", editorType: EditorType.Number })
  @Serializable(null)
  tripLimit: Maybe<number>;

  constructor(viewConfigDto: DeepPartial<StatorWindingTemperatureChartViewConfig> = {}) {
    super();
    construct(this, viewConfigDto, TYPE_NAME, { size: getDefaultSize() });
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("550", "550");
}
