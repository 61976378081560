import { createEntityAdapter } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import { ReportBrowserNode } from "../../models/report-browser-node";
import { CachedReportsActions } from "./cached-reports.actions";
import { State } from "./cached-reports.state";

const reportBrowserNodeSelector = (reportBrowserNode: ReportBrowserNode) => {
  return reportBrowserNode.reportId.toString();
};

const adapter = createEntityAdapter<ReportBrowserNode>({
  selectId: reportBrowserNodeSelector
});

export const initialState: State = adapter.getInitialState();
export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

export function reducer(state: State, action: Action) {
  return _reducer(state, action);
}

const _reducer = createReducer(
  initialState,
  on(CachedReportsActions.addReport, (state, { newReportNode }) => add(state, newReportNode)),
  on(CachedReportsActions.fetchReportSuccess, (state, { cachedReportNode }) =>
    onFetchReportSuccess(state, cachedReportNode)
  ),
  on(CachedReportsActions.fetchCachedReportComponentState, (state) =>
    fetchCachedReportComponentState(state)
  ),
  on(CachedReportsActions.deleteCachedReports, (state) => deleteAll(state))
);

function add(state: State, newReportNode: ReportBrowserNode) {
  return adapter.addOne(newReportNode, state);
}

function onFetchReportSuccess(state: State, cachedReportNode: ReportBrowserNode): State {
  return adapter.addOne(cachedReportNode, state);
}

function fetchCachedReportComponentState(state: State): State {
  const copy = { ...state };
  return copy;
}

function deleteAll(state: State): State {
  return adapter.removeAll(state);
}
