import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { HelpService } from "../../../core";
import { Dispatcher } from "../../../dispatcher";
import { EnvironmentSelector } from "../../../environment/services/environment.selector";
import { LocalizationService } from "../../../i18n/localization.service";
import { HelpDialogActions } from "../../dialogs/actions/help-actions";
import { HeaderButtonConfig } from "../../models/button/header-button.config";

@Component({
  selector: "project-info",
  templateUrl: "project-info.component.html",
  styleUrls: ["./project-info.component.scss"]
})
export class ProjectInfoComponent implements OnInit, OnDestroy {
  moreButton: HeaderButtonConfig;
  aboutButton: HeaderButtonConfig;
  helpButton: HeaderButtonConfig;
  helpLandingButton: HeaderButtonConfig;
  releaseNotesButton: HeaderButtonConfig;
  creditsButton: HeaderButtonConfig;

  isMenuOpened: boolean = false;
  private unsubscribeSubject$: Subject<any> = new Subject<any>();

  constructor(
    public localizer: LocalizationService,
    public helpService: HelpService,
    private dispatcher: Dispatcher,
    private environmentSelector: EnvironmentSelector
  ) {}

  ngOnInit(): void {
    this.subscribeToFilterToolbarMode();
    this.initializeButtons();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  private subscribeToFilterToolbarMode(): void {
    this.environmentSelector
      .selectFilterToolbarVisibilityMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((filterToolbarOpened: boolean) => {
        if (filterToolbarOpened) {
          this.closeMenu();
        }
      });
  }

  initializeButtons(): void {
    this.moreButton = this.createMoreButtonConfig();
    this.aboutButton = this.createAboutButtonConfig();
    this.helpButton = this.createHelpButtonConfig();
    this.releaseNotesButton = this.createReleaseNotesButtonConfig();
    this.helpLandingButton = this.createHelpLandingButtonConfig();
    this.creditsButton = this.createCreditsButtonConfig();
  }

  private createMoreButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.localizer.buttons.More,
      hasDropdown: true,
      clickFunction: () => {
        return this.showOrHideMenu();
      }
    });
  }

  showOrHideMenu(): void {
    this.isMenuOpened ? this.closeMenu() : this.openMenu();
  }

  closeMenu(): void {
    if (this.isMenuOpened) {
      this.isMenuOpened = false;
    }
  }

  openMenu(): void {
    this.isMenuOpened = true;
  }

  private createAboutButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.localizer.buttons.About,
      clickFunction: () => {
        return this.openAboutPage();
      }
    });
  }

  openAboutPage(): void {
    this.dispatcher.dispatch(HelpDialogActions.openAboutDialog());
  }

  get hasHelpPage(): boolean {
    return false; // helpService.hasHelpDocument
  }

  private createHelpButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.localizer.buttons.Help,
      clickFunction: () => {
        return this.openHelpPage();
      }
    });
  }

  openHelpPage(): void {
    this.helpService.open();
  }

  get hasReleaseNotes(): boolean {
    return this.helpService.hasHelpDocument;
  }

  private createReleaseNotesButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.localizer.buttons.ReleaseNotes,
      clickFunction: () => {
        return this.navigateTo(
          "assets/ui-core/documentation/release-notes/current/release-notes.html"
        );
      }
    });
  }

  private navigateTo(url: string): void {
    window.top?.open(url);
  }

  get hasHelpLanding(): boolean {
    return this.helpService.hasHelpDocument;
  }

  private createHelpLandingButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.localizer.buttons.Help,
      clickFunction: () => {
        return this.navigateTo("assets/ui-core/documentation/user-guide/help-landing-page.html");
      }
    });
  }

  private createCreditsButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.localizer.buttons.Credits,
      clickFunction: () => {
        return this.openCreditsPage();
      }
    });
  }

  openCreditsPage(): void {
    this.dispatcher.dispatch(HelpDialogActions.openCreditsDialog());
  }
}
