export interface SizeInPx {
  widthInPx: number;
  heightInPx: number;
}

export interface CardSizeInPx extends SizeInPx {
  scalingFactor: number;
}

export function isCardSizeInPx(x: SizeInPx): x is CardSizeInPx {
  return "scalingFactor" in x;
}
