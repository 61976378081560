import { construct } from "../../../core/services/construct.helper";
import { validateValueFormat } from "../../../core/services/value-formatter.service";
import { validateDateFormat } from "../../../environment/services/date-formatter.service";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { EditorType, PropertyCategory } from "../../../meta";
import {
  Configurable,
  ConfigurableEnum,
  ConfigurationCategory,
  EditableType,
  Serializable
} from "../../../meta/decorators";
import { ValidationContext } from "../../../meta/models/validation-context";
import { validateInputForColumnWidth } from "../../helpers/column-width-validation.helper";
import { HorizontalAlignment } from "../alignment/horizontal-alignment";

export const TYPE_NAME = "TableConnectorView";

@EditableType({ fullName: TYPE_NAME })
export class TableConnectorView {
  typeName = TYPE_NAME;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.TableColumn
  )
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Width,
    editorType: EditorType.TextBox,
    validationFunction: validateInputForColumnWidth,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.ColumnWidthConfigTooltip
  })
  @Serializable("")
  public width!: string;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.TableColumn
  )
  @ConfigurableEnum({
    enumSource: HorizontalAlignment,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.HorizontalAlignment
  })
  @Serializable()
  horizontalAlignment?: string;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.TableColumn
  )
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.DisplayFormat,
    editorType: EditorType.TextBox,
    validationFunction: validateDisplayOrDateFormat,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.DisplayOrDateFormatTooltip
  })
  @Serializable("")
  displayFormat!: string;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.TableColumn
  )
  @Configurable({
    editorType: EditorType.CheckBox,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.HideColumn
  })
  @Serializable(false)
  isHidden!: boolean;

  constructor(columnConfig: Partial<TableConnectorView> = {}) {
    construct(this, columnConfig, TYPE_NAME);
  }
}

function validateDisplayOrDateFormat(
  format: string,
  _validationContext: ValidationContext
): boolean {
  return (
    validateValueFormat(format, _validationContext) ||
    validateDateFormat(format, _validationContext)
  );
}
