import {
  Directive,
  EventEmitter,
  Host,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Self
} from "@angular/core";
import { Subject } from "rxjs";

import { BaseComponent } from "../components/base/base.component";
import { ComponentDataAccessor } from "../services/component-data-accessor";
import { DataConnectorViewSelector } from "../services/entity-selectors/data-connector-view.selector";
import { DataConnectorSelector } from "../services/entity-selectors/data-connector.selector";

@Directive({
  selector: "[condition]"
})
export class ConditionValueDirective implements OnInit, OnDestroy {
  @Input() role = "";
  @Input() delegate: (value: any) => boolean = null;

  @Output() result: EventEmitter<boolean> = new EventEmitter();

  private unsubscribeSubject$: Subject<any> = new Subject();

  constructor(
    @Host()
    @Self()
    private holderComponent: BaseComponent,
    private dataConnectorSelector: DataConnectorSelector,
    private dataConnectorViewSelector: DataConnectorViewSelector
  ) {}

  ngOnInit() {
    this.holderComponent.componentStatePropertySelector.subscribeOnDataConnectors({
      componentId: this.holderComponent.id,
      delegate: () => {
        const dataAccessor = new ComponentDataAccessor(
          this.holderComponent.currentState,
          this.dataConnectorSelector,
          this.dataConnectorViewSelector
        );
        const value: any = dataAccessor.getValueForRole(this.role);
        const resultToEmit = this.delegate(value);
        this.result.emit(resultToEmit);
      },
      takeUntil: this.unsubscribeSubject$
    });
  }

  ngOnDestroy() {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }
}
