<mat-autocomplete #autoRef="matAutocomplete" (optionSelected)="onSelectValue($event)">
  <mat-option
    *ngFor="let option of suggestedOptions | async | sortAlphabetically"
    [value]="option"
    title="{{ option }}"
    (click)="preventOtherEvents($event)"
  >
    {{ option }}
  </mat-option>
</mat-autocomplete>
