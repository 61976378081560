import { PropertySheetLocalizationDictionary } from "ui-core";

export class RdsPropertySheetLocalizationDictionary extends PropertySheetLocalizationDictionary {
  AirgapProfileColor?: string = "AirgapProfileColor";
  ScaledDownRotorColor?: string = "ScaledDownRotorColor";
  RotorCenterColor?: string = "RotorCenterColor";
  Subtitle?: string = "Subtitle";
  Categories?: string = "Categories";
  YAxisTitle?: string = "YAxisTitle";
  ColumnTitle?: string = "ColumnTitle";
  ChartLabel?: string = "ChartLabel";
  XAxisLabel?: string = "XAxisLabel";
  BarColor?: string = "BarColor";
  ShowNominal?: string = "ShowNominal";
  NominalValue?: string = "NominalValue";

  TooltipTitle?: string = "Tooltip Title";
  AirgapColor?: string = "Airgap Color";
  CalculatedRotorShapeColor?: string = "Calculated Rotor Shape Color";
  RotorMillCenterColor?: string = "Rotor Mill Center Color";
  AlarmColor?: string = "Alarm Color";
  TripColor?: string = "Trip Color";
  PhaseUColor?: string = "Phase U Color";
  PhaseVColor?: string = "Phase V Color";
  PhaseWColor?: string = "Phase W Color";
  RunningColor?: string = "Running Color";
  StoppedColor?: string = "Stopped Color";
  SmallestAirgapColor?: string = "Smallest Airgap Color";
  AverageAirgapColor?: string = "Average Airgap Color";
  LargestAirgapColor?: string = "Largest Airgap Color";
  MinAirgapColor?: string = "Min Airgap Color";
  MaxAirgapColor?: string = "Max Airgap Color";
  MaxFluxDensityColor?: string = "Max Flux Density Color";
  MinFluxDensityColor?: string = "Min Flux Density Color";
  MaxColor?: string = "Max Color";
  FluxDensityColor?: string = "Flux Density Color";
  LowSeverityColor?: string = "Low Severity Color";
  MediumSeverityColor?: string = "Medium Severity Color";
  HighSeverityColor?: string = "High Severity Color";
  Positions?: string = "Positons";
  HighestColor?: string = "Highest Color";
  HigherColor?: string = "Higher Color";
  MediumColor?: string = "Medium Color";
  LowerColor?: string = "Lower Color";
  LowestColor?: string = "Lowest Color";
  MaximumPoints?: string = "Maximum Points";
  NumberOfPoles?: string = "Number of Poles";
  XPrecision?: string = "X Precision";
  YPrecision?: string = "Y Precision";
  PolePass?: string = "Fundamental Pole Passing";
  PolePass3?: string = "3*Fundamental Pole Passing";
  PolePass6?: string = "6*Fundamental Pole Passing";
  PolePass9?: string = "9*Fundamental Pole Passing";
  PolePass12?: string = "12*Fundamental Pole Passing";
  PolePass15?: string = "15*Fundamental Pole Passing";
  PolePassThickness?: string = "Pole Pass Thickness";
  AverageValues?: string = "Show Average Value";

  ChartWidth?: string = "Chart Width";
  ChartHeight?: string = "Chart Height";

  ColorAxisWidth?: string = "Color Axis Width";
  ColorAxisHeight?: string = "Color Axis Height";
  ColorAxisPosition?: string = "Color Axis Position To Top";

  DataPointWidth?: string = "Datapoint Width";
  DataPointHeight?: string = "Datapoint Height";

  constructor() {
    super();
    Object.keys(this).forEach((key) => {
      const thisKey = key as keyof RdsPropertySheetLocalizationDictionary;
      this[thisKey] = `PropertySheet.${thisKey}`;
    });
  }
}
