import { Action, createReducer, on } from "@ngrx/store";
import { Dictionary } from "../../../ts-utils";
import { PeriodType } from "../../models";
import { PeriodTypeActions } from "./period-type.actions";

// format of the dictionary is {key : title}; title needs to be localized
const initialState = {
  // CUSTOM: "CUSTOM",
  // WHOLE: "WHOLE"
};

export function reducer(state: Dictionary<string>, action: Action) {
  return _reducer(state, action);
}

const _reducer = createReducer(
  initialState,
  on(PeriodTypeActions.addMany, (state, { periodTypes }) => addMany(state, periodTypes))
);

function addMany(state: Dictionary<string>, periodTypes: PeriodType[] = []): Dictionary<string> {
  if (periodTypes != null) {
    const periodTypeDict: Dictionary<string> = periodTypes.reduce((acc, periodType) => {
      acc[periodType.key] = periodType.title;
      return acc;
    }, {});
    return { ...state, ...periodTypeDict };
  } else {
    return state;
  }
}
