import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import {
  DEFAULT_SPAN_IN_HOURS,
  LIVE_MODE_TIME_UNIT
} from "../../../core/helpers/filter/filter-validation.helper";
import { TimeUnit } from "../../../core/models/time-unit";
import { LocalizationService } from "../../../i18n/localization.service";
import { isDefined, isEmpty, Maybe } from "../../../ts-utils";
import { formatAmountToTwoDecimals } from "../../helpers/live-mode-time-unit.helper";
import { LiveModeFilter } from "../../models/live-mode-filter";
import { SelectorComponent } from "../selector/selector.component";

@Component({
  selector: "live-mode-filter",
  templateUrl: "live-mode-filter.component.html",
  styleUrls: ["./live-mode-filter.component.scss"]
})
export class LiveModeFilterComponent implements OnInit {
  @Input() liveModeFilter: LiveModeFilter;
  @Input() distinctDefault: boolean = false;
  public timeUnits: string[] = [];
  public isInvalidTimeAmount: boolean = false;
  amountUpdate: Subject<string> = new Subject<string>();
  @Output() onChangeValue: EventEmitter<any> = new EventEmitter();
  @ViewChild("timeAmountInput")
  timeAmountInput?: ElementRef<HTMLInputElement>;
  @ViewChild(SelectorComponent)
  selectorComponent?: SelectorComponent;

  constructor(public localizer: LocalizationService) {}

  ngOnInit(): void {
    this.timeUnits = Object.values(TimeUnit);
    this.subscribeToAmountUpdate();
    this.setInitialDisplayAmount();
  }

  public subscribeToAmountUpdate(): void {
    this.amountUpdate
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((amount: string) => {
        this.onChangeTimeAmount(amount);
      });
  }

  public onChangeTimeAmount(newAmount: string): void {
    if (isEmpty(newAmount)) {
      this.isInvalidTimeAmount = true;
      return;
    }

    this.isInvalidTimeAmount = false;
    const amount: number = formatAmountToTwoDecimals(Number(newAmount));
    if (amount !== this.liveModeFilter.amount) {
      this.onChangeValue.emit({ ...this.liveModeFilter, amount });
    }
  }

  setInitialDisplayAmount(): void {
    this.liveModeFilter.amount = formatAmountToTwoDecimals(this.liveModeFilter.amount);
  }

  public onChangeTimeUnit(unit: TimeUnit): void {
    if (isDefined(unit)) {
      this.onChangeValue.emit({ ...this.liveModeFilter, unit });
    }
  }

  public get isDefaultTimeRange(): boolean {
    return (
      this.liveModeFilter.amount === DEFAULT_SPAN_IN_HOURS &&
      this.liveModeFilter.unit === LIVE_MODE_TIME_UNIT
    );
  }

  getTimeAmountInput(): Maybe<ElementRef<HTMLInputElement>> {
    return this.timeAmountInput;
  }

  getSelectElement(): Maybe<ElementRef<HTMLInputElement>> {
    return this.selectorComponent?.getSelectElement();
  }
}
