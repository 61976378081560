import { Injectable } from "@angular/core";
import { IDynamicReportService } from "../../interfaces/dynamic-report-service.interface";
import {
  DYNAMIC_REPORT,
  DYNAMIC_REPORT_CANVAS,
  DYNAMIC_REPORT_TABLE
} from "../../models/dynamic-report/dynamic-report.constants";
import { DynamicReportCanvasService } from "./dynamic-report-canvas.service";
import { DynamicReportLineChartService } from "./dynamic-report-line-chart.service";
import { DynamicReportTableService } from "./dynamic-report-table.service";

@Injectable({
  providedIn: "root"
})
export class DynamicReportServiceFactory {
  getService(reportName: string): IDynamicReportService {
    switch (reportName) {
      case DYNAMIC_REPORT:
        return new DynamicReportLineChartService();
      case DYNAMIC_REPORT_TABLE:
        return new DynamicReportTableService();
      case DYNAMIC_REPORT_CANVAS:
        return new DynamicReportCanvasService();
      default:
        throw new Error(`Service with name ${reportName} not found.`);
    }
  }

  isDynamicReport(reportName: string): boolean {
    return reportName?.includes(DYNAMIC_REPORT);
  }
}
