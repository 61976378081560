import { Injectable } from "@angular/core";
import { DataConnectorDto } from "../../data-connectivity";
import { isSingleValue } from "../../elements/models/component-type.helper";
import {
  DEFAULT_TITLE_INTERPOLATION,
  DEFAULT_UNIT_INTERPOLATION
} from "../../elements/models/property-interpolation.constants";
import { isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class UpgradeWidgetInterpolablePropertiesDefaults implements UpgradeStep {
  name = "UpgradeWidgetInterpolablePropertiesDefaults";
  fromVersion = new Version(4, 0, 3);

  perform(oldConfig: any): UpgradeStepResult {
    const componentStates = oldConfig?.["componentStates"]?.["entities"];
    const dataConnectors = oldConfig?.["dataConnectors"]?.["entities"];
    if (isNotDefined(componentStates) || isNotDefined(dataConnectors)) {
      return {
        result: oldConfig,
        modified: false,
        warning: null
      };
    }
    let changed = false;
    Object.values(componentStates).map((component: any) => {
      const view = component.view;
      if (isNotDefined(view.title)) {
        view.title = "";
        changed = true;
      }
      if (isSingleValue(component.type) && isNotDefined(view.unit)) {
        view.unit = "";
        changed = true;
      }
      if (isTitleCopiedFromConnector(view.title, Object.values(dataConnectors))) {
        view.title = DEFAULT_TITLE_INTERPOLATION;
        changed = true;
      }
      if (isUnitCopiedFromConnector(view.unit, Object.values(dataConnectors))) {
        view.unit = DEFAULT_UNIT_INTERPOLATION;
        changed = true;
      }
    });
    return {
      result: oldConfig,
      modified: changed,
      warning: null
    };
  }
}

function isTitleCopiedFromConnector(
  componentTitle: string,
  dataConnectors: DataConnectorDto[]
): boolean {
  return dataConnectors.some((dataConnector) => dataConnector.title === componentTitle);
}

function isUnitCopiedFromConnector(
  componentUnit: any,
  dataConnectors: DataConnectorDto[]
): boolean {
  return dataConnectors.some(
    (dataConnector) => dataConnector["properties"]?.["unit"] === componentUnit
  );
}
