import { construct } from "../../../core";
import { BaseViewConfigDto } from "../../../elements/models/base-view-config";
import { EditableType } from "../../../meta/decorators";
import { ComponentStyleDto, FULL_WIDTH_CSS_SIZE } from "../../models";
import { ComponentCssSize } from "../../models/component-size";

const TYPE_NAME = "ReportHeaderViewConfig";
const DEFAULT_WIDTH = FULL_WIDTH_CSS_SIZE;
const DEFAULT_HEIGHT = "330";

// @dynamic
@EditableType({ fullName: TYPE_NAME })
export class ReportHeaderViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  constructor(viewConfigDto: Partial<ReportHeaderViewConfig> = {}) {
    super();
    const defaultOverrides: Partial<ReportHeaderViewConfig> = {
      size: getDefaultSize(),
      css: new ComponentStyleDto("white")
    };
    construct(this, viewConfigDto, TYPE_NAME, defaultOverrides);
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize(DEFAULT_WIDTH, DEFAULT_HEIGHT);
}
