import { Injectable } from "@angular/core";
import { Equipment, EquipmentProperty, EquipmentPropertyDataType } from "ui-core";
import { RdsEquipment } from "../../models/equipment/rds-equipment";
import { RdsEquipmentProperties } from "../../models/equipment/rds-equipment-properties";
import { RdsEquipmentProperty } from "../../models/equipment/rds-equipment-property";
import { RdsEquipmentPropertyResponse } from "../../models/equipment/rds-equipment-property-response";

@Injectable()
export class AzureEquipmentConverter {
  /**
   * This converter is used when we do a post query for equipment
   * @param rawEquipmentProperites 
   * @returns 
   */
  public toEquipments2(rawEquipmentProperites: RdsEquipmentProperties[]): Equipment[] {
    if (!rawEquipmentProperites) {
      return [];
    }
    return rawEquipmentProperites.map((equipmentProperty: RdsEquipmentProperties) => {
      const equipment: Equipment = {
        properties: [],
        children: new Array<Equipment>()
      };
      equipment.properties = equipmentProperty.map((azureProperty: RdsEquipmentPropertyResponse) =>
        this.toEquipmentProperty2(azureProperty)
      );
      return equipment;
    });
  }

  public toEquipmentProperty2(azureProperty: RdsEquipmentPropertyResponse): EquipmentProperty {
    let property: EquipmentProperty;
    // FIX ME: for RDS this is always false, since we don't return the value of the signal
    if (this.isConstantValueProperty(azureProperty)) {
      property = {
        name: azureProperty.id,
        value: azureProperty.value
      };
    } else {
      property = {
        logId: azureProperty.id,
        name: azureProperty.properties.name || "",
        unit: azureProperty.properties.unit || "",
        properties: azureProperty.properties
      };
    }
    return property;
  }

  private isConstantValueProperty(azureProperty: RdsEquipmentPropertyResponse): boolean {
    return typeof azureProperty.value !== "undefined";
  }

  /**
   * This converter is used when we do a get equipment for a whole branch
   * @param rawEquipments 
   * @returns 
   */
  public toEquipments(rawEquipments: RdsEquipment[]): Equipment[] {
    if (!rawEquipments) {
      return [];
    }

    return rawEquipments.map((e) => this.toEquipment(e));
  }

  public toEquipment(rawEquipment: RdsEquipment): Equipment {
    return {
      id: rawEquipment.Id,
      description: rawEquipment.Description,
      path: rawEquipment.Path,
      disabled: false,
      name: rawEquipment.NodeName,
      properties: rawEquipment.Properties?.map((p: RdsEquipmentProperty) =>
        this.toEquipmentProperty(p)
      ),
      children: rawEquipment.Children?.map((c) => this.toEquipment(c))
    } as Equipment;
  }

  public toEquipmentProperties(rawProperties: RdsEquipmentProperty[]): EquipmentProperty[]{
    if (!rawProperties) {
      return [];
    }

    return rawProperties.map((p) => this.toEquipmentProperty(p));
  }

  public toEquipmentProperty(rawProperty: RdsEquipmentProperty): EquipmentProperty {
    return {
      name: rawProperty.name,
      aliasName: rawProperty.plcTag,
      logId: rawProperty.internalTag,
      dataType: EquipmentPropertyDataType.Real,
      description: rawProperty.description,
      unit: rawProperty.unit,
      // TODO use fileType?
      // className: "Property",
      properties: rawProperty
    } as EquipmentProperty;
  }
}
