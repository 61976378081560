export enum DynamicDefaultType {
  Value,
  Function,
  DataLastValue,
  LastDataPointProperty,
  ConnectorMetadataProperty,
  ConnectorViewProperty
}

export function isConnectorDefault(type: DynamicDefaultType): boolean {
  return (
    type === DynamicDefaultType.DataLastValue ||
    type === DynamicDefaultType.LastDataPointProperty ||
    type === DynamicDefaultType.ConnectorMetadataProperty ||
    type === DynamicDefaultType.ConnectorViewProperty
  );
}
