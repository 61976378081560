import { Directive, HostListener, Input, OnInit } from "@angular/core";

export const EDITOR_FOCUSED = "editor--focused";
const EDITOR_FOCUSED_NESTED = "editor--focused-nested";
const EDITOR_FOCUSED_ROUNDED = "editor--focused-rounded";

@Directive({
  selector: "[focusVisualization]"
})
export class FocusVisualizationDirective implements OnInit {
  @Input() focusedElement!: HTMLElement;
  @Input() isRounded: boolean = false;
  @Input() isNested: boolean = false;
  private focusedClass!: string;
  constructor() {}

  ngOnInit(): void {
    this.focusedClass = this.determineFocusedClass();
  }

  private determineFocusedClass(): string {
    if (this.isRounded) {
      return EDITOR_FOCUSED_ROUNDED;
    }

    if (this.isNested) {
      return EDITOR_FOCUSED_NESTED;
    }

    return EDITOR_FOCUSED;
  }

  @HostListener("focus") onFocus(): void {
    this.focusedElement?.classList.add(this.focusedClass);
  }

  @HostListener("focusout") onFocusout(): void {
    this.focusedElement?.classList.remove(this.focusedClass);
  }
}
