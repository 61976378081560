import { Injectable } from "@angular/core";
import { DATA_AGGREGATION_CONFIG_DTO } from "../../core/models/dto-type.constants";
import { FilterConfigurationDto } from "../../core/models/filter/filter-configuration";
import { TimeRange } from "../../core/models/time-range";
import { getConnectorIdByViewId } from "../../data-connectivity/helpers/connector-view-id.helper";
import { sortConnectorViews } from "../../data-connectivity/helpers/data-connector-view.helper";
import { DataConnectorDto } from "../../data-connectivity/models/data-connector";
import { DataConnectorViewDto } from "../../data-connectivity/models/data-connector-view";
import { DataPointDto } from "../../data-connectivity/models/data-point";
import { getConnectorForComponentTarget } from "../../elements/helpers/connectors.helper";
import { ComponentStateDto } from "../../elements/models/component-state";
import { ComponentStateViewModel } from "../../elements/models/component-state.vm";
import {
  COMPONENT_STATE_VIEW_MODEL,
  DATA_CONNECTOR_DTO,
  DATA_CONNECTOR_VIEW_DTO,
  DATA_CONNECTOR_VIEW_MODEL,
  FILTER_CONFIGURATION_DTO
} from "../../elements/models/entity-type.constants";
import { PERIOD_TYPE_WIDGET_QUERY_FILTER } from "../../elements/models/property-interpolation-models";
import {
  CONNECTOR_GROUP_INPUT,
  CONNECTOR_SOURCE,
  CONNECTOR_VIEW_SOURCE,
  DATA_POINT_SOURCE,
  FILTER_SOURCE,
  SOURCE_INDEX_SEPARATORS,
  WIDGET_SOURCE
} from "../../elements/models/property-interpolation.constants";
import { resolveCustomFilterKey } from "../../elements/services/custom-filter.helper";
import { ComponentStateViewModelDeserializer } from "../../elements/services/deserializers/component-state-vm.deserializer";
import { ComponentStateSelector } from "../../elements/services/entity-selectors/component-state.selector";
import { DataConnectorViewSelector } from "../../elements/services/entity-selectors/data-connector-view.selector";
import { DataConnectorSelector } from "../../elements/services/entity-selectors/data-connector.selector";
import { GeneralSettingsSelector } from "../../elements/services/entity-selectors/general-settings.selector";
import { Entity, EntityId } from "../../meta/models/entity";
import { TypeProvider } from "../../meta/services/type-provider";
import { last, removeDuplicates } from "../../ts-utils/helpers/array.helper";
import { isEmptyOrNotDefined } from "../../ts-utils/helpers/is-empty.helper";
import { isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { COMMON_DATA_POINT_PROPERTY_NAMES } from "../models/autocomplete-interpolation.constants";

@Injectable({ providedIn: "root" })
export class AutocompleteInterpolationService {
  constructor(
    private typeProvider: TypeProvider,
    private componentSelector: ComponentStateSelector,
    private componentStateVmDeserializer: ComponentStateViewModelDeserializer,
    private dataConnectorSelector: DataConnectorSelector,
    private dataConnectorViewSelector: DataConnectorViewSelector,
    private generalSettingsSelector: GeneralSettingsSelector
  ) {}

  getPropertyNamesForAutocomplete(
    interpolationSource: string,
    propertySheetTarget: Entity,
    connectorSourceIdentifier: Maybe<string> = null
  ): string[] {
    switch (interpolationSource) {
      case WIDGET_SOURCE:
        return this.getPropertyNamesForComponent(propertySheetTarget);
      case CONNECTOR_SOURCE:
        return this.getConnectorPropertyNames(connectorSourceIdentifier, propertySheetTarget);
      case CONNECTOR_VIEW_SOURCE:
        return this.typeProvider.getPropertyNames(
          DATA_CONNECTOR_VIEW_DTO,
          new DataConnectorViewDto({ id: "" })
        );
      case FILTER_SOURCE:
        return this.getFilterPropertyNames();
      case DATA_POINT_SOURCE:
        return this.getDataPointPropertyNames(propertySheetTarget);
      default:
        return [];
    }
  }

  getPropertyNamesForComponent(propertySheetTarget: Entity): string[] {
    let componentViewModel: Maybe<ComponentStateViewModel> = null;
    if (propertySheetTarget.typeName === DATA_CONNECTOR_VIEW_MODEL) {
      const component: ComponentStateDto = this.componentSelector.getComponentByConnectorId(
        propertySheetTarget.id
      );
      componentViewModel = this.componentStateVmDeserializer.convert(component);
    } else {
      componentViewModel = propertySheetTarget as ComponentStateViewModel;
    }

    return this.typeProvider
      .getPropertyNames(componentViewModel.view.typeName, componentViewModel.view)
      .concat(this.getDataFilteringPropertyNames(componentViewModel.filterConfig));
  }

  getDataFilteringPropertyNames(filterConfig: FilterConfigurationDto): string[] {
    return this.typeProvider
      .getType(DATA_AGGREGATION_CONFIG_DTO)
      .properties.map((propertyDescriptor) => propertyDescriptor.name)
      .concat(this.typeProvider.getPropertyNames(FILTER_CONFIGURATION_DTO, filterConfig));
  }

  getConnectorPropertyNames(
    connectorSourceIdentifier: Maybe<string> = null,
    propertySheetTarget: Entity
  ): string[] {
    return this.typeProvider
      .getPropertyNames(DATA_CONNECTOR_DTO, new DataConnectorDto())
      .concat(
        this.getMetadataPropertyNamesForConnector(connectorSourceIdentifier, propertySheetTarget)
      );
  }

  getMetadataPropertyNamesForConnector(
    connectorSourceIdentifier: Maybe<string> = null,
    propertySheetTarget: Entity
  ): string[] {
    if (isDefined(connectorSourceIdentifier)) {
      return this.extractMetadataPropertyNames(connectorSourceIdentifier, propertySheetTarget);
    } else if (propertySheetTarget.typeName === COMPONENT_STATE_VIEW_MODEL) {
      const connector = getConnectorForComponentTarget(
        propertySheetTarget.id,
        this.dataConnectorSelector,
        this.dataConnectorViewSelector
      );
      return isDefined(connector) ? Object.keys(connector.properties) : [];
    } else {
      return Object.keys(this.dataConnectorSelector.getById(propertySheetTarget.id).properties);
    }
  }

  extractMetadataPropertyNames(
    connectorSourceIdentifier: string,
    propertySheetTarget: Entity
  ): string[] {
    if (propertySheetTarget.typeName === COMPONENT_STATE_VIEW_MODEL) {
      if (isDefined(connectorSourceIdentifier.match(new RegExp(CONNECTOR_GROUP_INPUT)))) {
        return this.getMetadataPropertyNamesFromGroupedConnector(
          connectorSourceIdentifier,
          propertySheetTarget as ComponentStateViewModel
        );
      } else {
        return this.getMetadataPropertyNamesByConnectorIndex(
          connectorSourceIdentifier,
          propertySheetTarget as ComponentStateViewModel
        );
      }
    }
    return [];
  }

  getMetadataPropertyNamesFromGroupedConnector(
    groupIdParam: string,
    propertySheetTarget: ComponentStateViewModel
  ): string[] {
    const paramsSeparatedByConnectorIdentifier = groupIdParam.split(SOURCE_INDEX_SEPARATORS);
    const groupId = paramsSeparatedByConnectorIdentifier[1];
    const connectorIndexInGroup =
      Number(
        paramsSeparatedByConnectorIdentifier[paramsSeparatedByConnectorIdentifier.length - 2]
      ) - 1;

    const componentConnectorViews = this.dataConnectorViewSelector
      .getForComponent(propertySheetTarget.id)
      .filter((connectorView) => connectorView.groupId === groupId);
    sortConnectorViews(componentConnectorViews);
    const conectorView = componentConnectorViews[connectorIndexInGroup];
    if (isNotDefined(conectorView)) {
      return [];
    }

    const connector = this.dataConnectorSelector.getById(getConnectorIdByViewId(conectorView.id));
    if (isNotDefined(connector) || isEmptyOrNotDefined(connector.properties)) {
      return [];
    }
    return Object.keys(connector.properties);
  }

  getMetadataPropertyNamesByConnectorIndex(
    connectorIndex: string,
    propertySheetTarget: ComponentStateViewModel
  ): string[] {
    const indexSeparatedParams = connectorIndex.split(SOURCE_INDEX_SEPARATORS);
    const index = Number(indexSeparatedParams[indexSeparatedParams.length - 2]) - 1;

    const connector: Maybe<DataConnectorDto> = getConnectorForComponentTarget(
      propertySheetTarget.id,
      this.dataConnectorSelector,
      this.dataConnectorViewSelector,
      index
    );
    if (isNotDefined(connector) || isEmptyOrNotDefined(connector.properties)) {
      return [];
    }

    return Object.keys(connector.properties);
  }

  getFilterPropertyNames(): string[] {
    const customFilterNames = this.generalSettingsSelector
      .getCustomFilterDescriptors()
      .map((customFilterDescriptor) => resolveCustomFilterKey(customFilterDescriptor))
      .filter(isDefined);
    return [
      ...Object.keys(new TimeRange(new Date(), new Date())),
      PERIOD_TYPE_WIDGET_QUERY_FILTER
    ].concat(customFilterNames);
  }

  getDataPointPropertyNames(propertySheetTarget: Entity): string[] {
    if (propertySheetTarget.typeName === DATA_CONNECTOR_VIEW_MODEL) {
      const connector: Maybe<DataConnectorDto> = this.dataConnectorSelector.getById(
        propertySheetTarget.id
      );
      return this.collectPropertyNamesFromLastDataPoint(connector);
    }
    return this.getDataPointPropertyNamesForComponentTarget(propertySheetTarget.id);
  }

  getDataPointPropertyNamesForComponentTarget(propertySheetTargetId: EntityId): string[] {
    const firstConnector: Maybe<DataConnectorDto> = getConnectorForComponentTarget(
      propertySheetTargetId,
      this.dataConnectorSelector,
      this.dataConnectorViewSelector
    );

    if (isNotDefined(firstConnector)) {
      return COMMON_DATA_POINT_PROPERTY_NAMES;
    }
    return this.collectPropertyNamesFromLastDataPoint(firstConnector);
  }

  collectPropertyNamesFromLastDataPoint(connector: Maybe<DataConnectorDto>): string[] {
    if (isNotDefined(connector) || isEmptyOrNotDefined(connector.dataPoints)) {
      return COMMON_DATA_POINT_PROPERTY_NAMES;
    }

    const lastDataPoint: Maybe<DataPointDto> = last(connector.dataPoints);
    if (isNotDefined(lastDataPoint) || isNotDefined(lastDataPoint.properties)) {
      return COMMON_DATA_POINT_PROPERTY_NAMES;
    }

    return removeDuplicates(
      COMMON_DATA_POINT_PROPERTY_NAMES.concat(Object.keys(lastDataPoint.properties))
    );
  }
}
