import { Observable, of } from "rxjs";
import { filter, map } from "rxjs/operators";
import { DecoratorDelegateContext } from "../../core/models/decorator-delegate-context";
import { getConnectorIdByViewId } from "../../data-connectivity/helpers/connector-view-id.helper";
import { isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { selectDataConnectorById } from "../store/data-connector";
import { selectDataConnectorViewById } from "../store/data-connector-view/data-connector-view.selectors";

export function selectDisplayStrategyByComponentId(
  context: DecoratorDelegateContext
): Observable<string> {
  return context.services.componentStateSelector.selectDisplayStrategy(context.ownerInstance.id);
}

export function selectDisplayStrategyByConnectorViewId(
  context: DecoratorDelegateContext
): Observable<string> {
  const connectorId = getConnectorIdByViewId(context.ownerInstance.id);
  const component = context.services.componentStateSelector.getComponentByConnectorId(connectorId);
  if (isNotDefined(component)) {
    return of("");
  }
  return context.services.componentStateSelector.selectDisplayStrategy(component.id);
}

export function selectComponentTypeByConnectorViewId(
  context: DecoratorDelegateContext
): Observable<string> {
  const connectorId = getConnectorIdByViewId(context.ownerInstance.id);
  const component = context.services.componentStateSelector.getComponentByConnectorId(connectorId);
  if (isNotDefined(component)) {
    return of("");
  }
  return of(component.type);
}

export function selectScatterSeriesTypeByConnectorViewId(
  context: DecoratorDelegateContext
): Observable<string> {
  return context.store.select(selectDataConnectorViewById(context.ownerInstance.id)).pipe(
    filter((x) => isDefined(x)),
    map((connectorView) => connectorView?.scatterSeriesConfig.seriesType ?? "Default")
  );
}

export function filterOutEnumValues(enumObject: any, valuesToExclude: any[]): any[] {
  return Object.values(enumObject).filter((value) => !valuesToExclude.includes(value));
}

export function selectConnectorComputability(
  context: DecoratorDelegateContext
): Observable<boolean> {
  const connectorId = getConnectorIdByViewId(context.ownerInstance.id);
  return context.store.select(selectDataConnectorById(connectorId)).pipe(
    filter((x) => isDefined(x)),
    map((connector) => connector?.isComputing ?? false)
  );
}
