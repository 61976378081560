import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { MatAutocomplete, MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { Observable, of } from "rxjs";
import {
  END_INTERPOLATION_INDICATOR,
  PROPERTY_AUTOCOMPLETE_INDICATOR,
  SOURCES_REGEX,
  SOURCE_AUTOCOMPLETE_INDICATOR,
  SOURCE_INDEX_SEPARATORS
} from "../../../elements/models/property-interpolation.constants";
import { first, last, removeDuplicates } from "../../../ts-utils/helpers/array.helper";
import { isDefined, isNotDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { getSourcesForAutocomplete } from "../../services/autocomplete-interpolation.helper";
import { AutocompleteInterpolationService } from "../../services/autocomplete-interpolation.service";

@Component({
  selector: "autocomplete-interpolation",
  templateUrl: "autocomplete-interpolation.component.html",
  styleUrls: ["autocomplete-interpolation.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutocompleteInterpolationComponent implements OnInit, OnChanges {
  @Input() ownerInfo: any = "";
  @Input() value: Maybe<string>;
  @Output() onValueChange: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild(MatAutocomplete) autocomplete!: MatAutocomplete;

  suggestedOptions: Observable<string[]> = of([]);
  nonAutocompleteValue: string = "";

  constructor(private autocompleteInterpolationService: AutocompleteInterpolationService) {}

  ngOnInit(): void {
    this.suggestedOptions = of(this.filterValue(this.value));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isInputValueChanged(changes)) {
      this.suggestedOptions = of(this.filterValue(this.value));
    }
  }

  private isInputValueChanged(changes: SimpleChanges): boolean {
    return (
      isDefined(changes["value"]) &&
      isDefined(changes["value"].previousValue) &&
      isDefined(changes["value"].currentValue) &&
      changes["value"].currentValue !== changes["value"].previousValue
    );
  }

  private filterValue(value: Maybe<string>): string[] {
    if (isNotDefined(value)) {
      return [];
    }
    const allInterpolationParams: string[] = value.split(SOURCE_AUTOCOMPLETE_INDICATOR);
    const interpolationValueWithoutSourceIndicator: string = last(allInterpolationParams);
    if (this.showAutocompleteForInterpolationSource(allInterpolationParams)) {
      return this.getInterpolationSources(value, interpolationValueWithoutSourceIndicator);
    }

    const propertyAutocompleteParams: string[] = interpolationValueWithoutSourceIndicator.split(
      PROPERTY_AUTOCOMPLETE_INDICATOR
    );
    if (this.showAutocompleteForInterpolationProperties(propertyAutocompleteParams)) {
      return this.getInterpolationPropertyNames(value, propertyAutocompleteParams);
    }

    return [];
  }

  private showAutocompleteForInterpolationSource(allInterpolationParams: string[]): boolean {
    const sourceAutocompleteIndicator: string =
      allInterpolationParams[allInterpolationParams.length - 2];
    const interpolationSource: string = allInterpolationParams[allInterpolationParams.length - 1];
    return (
      isDefined(sourceAutocompleteIndicator) &&
      isNotDefined(interpolationSource.match(SOURCE_INDEX_SEPARATORS)) &&
      !interpolationSource.includes(".")
    );
  }

  private getInterpolationSources(
    value: string,
    interpolationValueWithoutSourceIndicator: string
  ): string[] {
    this.nonAutocompleteValue = value.slice(
      0,
      value.length - interpolationValueWithoutSourceIndicator.length
    );

    return getSourcesForAutocomplete().filter((source) =>
      source.toLowerCase().includes(interpolationValueWithoutSourceIndicator.toLowerCase())
    );
  }

  private showAutocompleteForInterpolationProperties(
    propertyAutocompleteParams: string[]
  ): boolean {
    const interpolationSource: string = first(propertyAutocompleteParams);
    const interpolationProperty: string = last(propertyAutocompleteParams);
    return (
      isDefined(interpolationSource.match(SOURCES_REGEX)) &&
      !interpolationProperty.includes(END_INTERPOLATION_INDICATOR)
    );
  }

  private getInterpolationPropertyNames(
    value: string,
    propertyAutocompleteParams: string[]
  ): string[] {
    const interpolationSource: string = first(propertyAutocompleteParams);
    const interpolationProperty: string = last(propertyAutocompleteParams);
    const connectorSourceIdentifier: Maybe<string> = propertyAutocompleteParams[1];

    if (interpolationSource !== interpolationProperty) {
      this.nonAutocompleteValue = value.slice(0, value.length - interpolationProperty.length);
    }
    const properties = this.autocompleteInterpolationService.getPropertyNamesForAutocomplete(
      interpolationSource,
      this.ownerInfo,
      connectorSourceIdentifier
    );

    return removeDuplicates(properties).filter((property) =>
      property.toLowerCase().includes(interpolationProperty.toLowerCase())
    );
  }

  onSelectValue(selectedItem: MatAutocompleteSelectedEvent): void {
    const selectedItemValue = selectedItem.option.value;
    const updatedValue = this.nonAutocompleteValue + selectedItemValue;
    this.onValueChange.emit(updatedValue);
  }

  preventOtherEvents(event: Event): void {
    event.stopPropagation();
  }
}
