import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import cloneDeep from "lodash/cloneDeep";
import { map, switchMap, tap } from "rxjs/operators";
import { EquipmentModelBrowserDialogComponent } from "../../../elements/components/equipment-model-browser-dialog/equipment-model-browser-dialog.component";
import { EquipmentBrowserDialogActions } from "../actions";
import { OpenEquipmentEditorStrategy } from "../dialog-config.strategies";
import { OpenDialogEffect } from "./dialog.effects";

@Injectable()
export class EquipmentBrowserDialogEffects extends OpenDialogEffect {
  constructor(private actions$: Actions, dialog: MatDialog) {
    super(dialog);
  }

  openEquipmentBrowserStrategy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EquipmentBrowserDialogActions.openEquipmentBrowserDialog),
      tap(({ equipmentDialogInfo: equipmentDialogInfo }) => {
        this.dialogConfigStrategy = new OpenEquipmentEditorStrategy();
        this.dialogConfig = this.dialogConfigStrategy.createDialogConfig(
          cloneDeep(equipmentDialogInfo)
        );
        this.dialogRef = this.dialog.open(EquipmentModelBrowserDialogComponent, this.dialogConfig);
      }),
      switchMap(() => {
        return this.dialogRef.afterClosed().pipe(
          map((result) => {
            return EquipmentBrowserDialogActions.onEquipmentBrowserDialogClosed({ result });
          })
        );
      })
    )
  );
}
