import { Injectable } from "@angular/core";
import { DraggedItem } from "../models";
import { Maybe } from "../../ts-utils/models/maybe.type";

@Injectable()
export class IDragDropService {
  public enableDrag: boolean = false;
  public setDragTarget(item: DraggedItem, rootPath: string = ""): void {}
  get target(): Maybe<DraggedItem> {
    throw new Error("Not implemented");
  }
  public clear(): void {}
  public touchMove(event: Event): void {}
  public touchEnd(event): void {}
  public dragEnd(): void {}
}
