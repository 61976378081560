import { Component } from "@angular/core";
import { isUndefined } from "lodash";
import { RDS_LOCALIZATION_DICTIONARY } from "projects/rds/src/assets/i18n/models/rds-localization-dictionary";
import {
  BaseComponent,
  ChartComponent,
  ConnectorRoles,
  EditableType,
  formatValueByStringFormat,
  isDefined,
  isNotDefined,
  LayoutBuilder,
  View
} from "ui-core";
import { DataPointDto } from "../../../../../../ui-core/src/lib/data-connectivity/models/data-point";
import { MaxConnectors } from "../../../../../../ui-core/src/lib/elements/decorators/max-connectors.decorator";
import { RdsComponentCategory } from "../../rds-component-category";
import { Roles } from "./roles";
import { LoadSharingChartViewConfig } from "./view-config";

@Component({
  selector: "rds-load-sharing-chart",
  templateUrl: "./load-sharing-chart.component.html",
  styleUrls: ["./load-sharing-chart.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: LoadSharingChartComponent }]
})
@LayoutBuilder(RdsComponentCategory.RDS, "LoadSharingChartComponent",
  "Plugin", "abb-icon", undefined, RDS_LOCALIZATION_DICTIONARY.layoutEditor.LoadSharingChart)  
@MaxConnectors(20)
@ConnectorRoles(Roles)
@EditableType({ fullName: "LoadSharingChartComponent", title: "load-sharing-chart" })
export class LoadSharingChartComponent extends ChartComponent {
  // TODO If these limits need to be configurable from the prop sheet, move them into view config and decorate
  alarmLimit: number = 7;
  tripLimit: number = 10;

  uvArray: number[][] = [];
  vwArray: number[][] = [];
  wuArray: number[][] = [];

  @View(LoadSharingChartViewConfig)
  public get view(): LoadSharingChartViewConfig {
    return this.currentState.view as LoadSharingChartViewConfig;
  }

  protected updateChartData(): void {
    if (this.dataConnectors.length === 0) {
      console.error("[LoadSharingComponent] no dataconnectors");
      return;
    }

    const uArray = this.makePairedArray(0);
    const vArray = this.makePairedArray(1);
    const wArray = this.makePairedArray(2);

    if (uArray && vArray && wArray) {
      this.uvArray = this.subtractPairedArrays(uArray, vArray);
      this.vwArray = this.subtractPairedArrays(vArray, wArray);
      this.wuArray = this.subtractPairedArrays(wArray, uArray);
    }
  }

  private makePairedArray(arrayIndex: number): number[][] {    
    const rmsArray = this.dataConnectors
      .filter((d) => d.role === Roles.RmsPrefix.name)
      .map((c) => c.dataPoints)[arrayIndex]; //.getValueForRole(Roles.RmsPrefix.name);
    
    const temperatureArray = this.dataConnectors
      .filter((d) => d.role === Roles.TemperaturePrefix.name)
      .map((c) => c.dataPoints)[arrayIndex];
    
    if (isNotDefined(rmsArray) || isNotDefined(temperatureArray)) {
      return [];
    }

    const pairedArray: number[][] = rmsArray.reduce(
      (acc: number[][], rmsPoint: DataPointDto, index: number) => {
        const temperaturePoint: DataPointDto = temperatureArray[index];
        if (isDefined(temperaturePoint)) {
          acc.push([rmsPoint.y, temperaturePoint.y]);
        }
        return acc;
      },
      []
    );
    return pairedArray;
  }

  private subtractPairedArrays(array1: number[][], array2: number[][]): number[][] {
    return array1.map((arr1PairedValue, index) => {
      const arr2PairedValue = array2[index];
      if (isUndefined(arr2PairedValue)) {
        return [0, 0];
      }

      return [arr1PairedValue[0] - arr2PairedValue[0], arr1PairedValue[1] - arr2PairedValue[1]];
    });
  }

  protected setChartOptions(): void {
    const component = this;
    this.chartOptions = {
      lang: {
        noData: "No Data Available"
      },
      chart: {
        type: "scatter",
        zoomType: "xy",
        backgroundColor: "rgba(255, 255, 255, 0.0)"
      },
      title: {
        text: null
      },
      credits: {
        enabled: false
      },
      xAxis: {
        title: {
          enabled: true,
          text: "RMS of Cycloconverter input current (A)"
        },
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true
      },
      yAxis: {
        title: {
          text: "Cycloconverter Transformers Winding Temperature (°C)"
        },
        softMax: this.tripLimit,
        plotLines: [
          {
            id: "trip limit",
            color: this.view.tripColor,
            width: 1,
            value: this.tripLimit
          },
          {
            id: "alarm limit",
            color: this.view.alarmColor,
            width: 1,
            value: this.alarmLimit
          }
        ]
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        scatter: {
          tooltip: {
            headerFormat: "<i>{series.name}</i><br/>",
            pointFormatter: function () {
              const x = formatValueByStringFormat(this.options.x, component.view.displayFormat);
              const y = formatValueByStringFormat(this.options.y, component.view.displayFormat);
              return "<b>" + x + "</b> amps<br><b>" + y + "</b> °C";
            }
          }
        }
      },
      series: [
        {
          name: "Phase U",
          data: this.uvArray,
          color: this.view.uvColor
        },
        {
          name: "Phase V",
          data: this.vwArray,
          color: this.view.vwColor
        },
        {
          name: "Phase W",
          data: this.wuArray,
          color: this.view.wuColor
        }
      ]
    };
  }
}
