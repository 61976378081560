import "reflect-metadata";

import { EditorType } from "../models/editor-type";

// const editorsList = (<any>window).__editors;

export function OfType(type: EditorType) {
  return function (typeConstructor: Function) {
    if (typeof (<any>window).__editors === "undefined") {
      (<any>window).__editors = [];
    }

    (<any>window).__editors.push({
      key: type,
      editor: typeConstructor
    });
  };
}
