import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, UrlSegment } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DisplayMode } from "../../../core/models/display-mode";
import { EnvironmentSelector } from "../../../environment/services/environment.selector";
import { SidemenuComponents } from "../../../shared/models/tool-bar-item";

export const CONTENT_WRAPPER_CLASS = "app-body__content-wrapper";
@Component({
  selector: "c-app-body",
  templateUrl: "./app-body.component.html",
  styleUrls: ["./app-body.component.scss"]
})
export class AppBodyComponent implements OnInit {
  isPropertySheetOpened: boolean = false;
  isFilterToolbarOpened: boolean = false;
  public displayMode: string;
  public DisplayMode = DisplayMode;
  public templateBuilderMode: boolean = false;
  public SidemenuComponents = SidemenuComponents;
  public contentWrapperClass = CONTENT_WRAPPER_CLASS;
  private unsubscribeSubject$: Subject<any> = new Subject<any>();
  @Input() hideFooter = false;
  @Output() toggleReportBrowser: EventEmitter<any> = new EventEmitter<any>();

  constructor(private environmentSelector: EnvironmentSelector, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.subscribeToPropertySheetMode();
    this.subscribeToDisplayMode();
    this.subscribeToFilterToolbarMode();
    this.subscribeToTemplateBuilderMode();
  }

  private subscribeToDisplayMode(): void {
    this.environmentSelector
      .selectDisplayMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((displayMode: string) => {
        this.displayMode = displayMode;
        this.cdr.detectChanges();
      });
  }

  private subscribeToPropertySheetMode(): void {
    this.environmentSelector
      .selectPropertySheetVisibilityMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((propertySheetMode) => {
        this.isPropertySheetOpened = propertySheetMode;
        this.cdr.detectChanges();
      });
  }

  private subscribeToFilterToolbarMode(): void {
    this.environmentSelector
      .selectFilterToolbarVisibilityMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((isFilterToolbarOpened) => {
        this.isFilterToolbarOpened = isFilterToolbarOpened;
      });
  }

  private subscribeToTemplateBuilderMode(): void {
    this.environmentSelector
      .selectTemplateBuilderMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((templateBuilderMode: boolean) => {
        this.templateBuilderMode = templateBuilderMode;
        this.cdr.detectChanges();
      });
  }

  public toggleReportBrowserSidebar(): void {
    this.toggleReportBrowser.emit();
  }

  onRouterActivated(event: { activatedRoute: ActivatedRoute }): void {
    const urlObservable: Observable<UrlSegment[]> = event.activatedRoute.url;
  }
}
