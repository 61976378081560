import { construct } from "../../../core/services/construct.helper";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { ConfigurableEnum } from "../../../meta/decorators/configurable-enum.decorator";
import { Configurable } from "../../../meta/decorators/configurable.decorator";
import { EditableType } from "../../../meta/decorators/editable-type.decorator";
import { Serializable } from "../../../meta/decorators/serializable.decorator";
import { EditorType } from "../../../meta/models/editor-type";
import { DeepPartial } from "../../../ts-utils/models/deep-partial.type";
import { IndicatorShape } from "./indicator-shape";
import { IndicatorStateDisplay } from "./indicator-state-display";
import { StateDisplayType } from "./state-display-type";

@EditableType({ fullName: StateDisplayType.Shape })
export class ShapeStateDisplay extends IndicatorStateDisplay {
  typeName: StateDisplayType = StateDisplayType.Shape;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Color,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#ccc")
  color!: string;

  @ConfigurableEnum({
    enumSource: IndicatorShape,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.IndicatorShape
  })
  @Serializable(IndicatorShape.None)
  shape!: IndicatorShape;

  constructor(params: DeepPartial<ShapeStateDisplay> = {}) {
    super();
    construct(this, params, StateDisplayType.Shape);
  }
}
