import { DataConnectorRoles } from "ui-core";

export const Roles: DataConnectorRoles = {
  PoleAirgapValues: {
    name: "poles_airgap",
    isDefaultRole: true
  },
  PoleDensityValues: {
    name: "poles_density"
  }
};
