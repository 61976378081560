import "reflect-metadata";
import { NumberOfDataPointsToRequestFunction } from "../models/number-of-data-points-to-request-function";
import { TypeProvider } from "../services/type-provider";

const TYPE_PROVIDER = TypeProvider.getInstance();

export function NumberOfDataPointsToRequest(
  numberOfDataPointsToRequestFunc: NumberOfDataPointsToRequestFunction
): any {
  return function (typeConstructor: Function) {
    const typeDescriptor = TYPE_PROVIDER.addType({
      constructorFunction: typeConstructor as new () => any
    });
    typeDescriptor.numberOfDataPointsToRequest = numberOfDataPointsToRequestFunc;
  };
}
