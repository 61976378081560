import { IconId } from "../../browsing/models/icon-id";
import { construct, linkCreate, LinkDto, ReportId, ReportLinkDto } from "../../core";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import {
  AllowInterpolation,
  Configurable,
  EditableType,
  EditorType,
  Serializable,
  Title,
  Virtual
} from "../../meta";
import { isDefined, Maybe } from "../../ts-utils";
import { DEFAULT_TITLE } from "./default-property-value.constants";

const TYPE_NAME = "NavLinkInfo";

// @dynamic
@EditableType({ fullName: TYPE_NAME })
export class NavLinkInfo {
  typeName = TYPE_NAME;

  @AllowInterpolation()
  @Title()
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Title,
    editorType: EditorType.TextBox
  })
  @Serializable(DEFAULT_TITLE)
  title!: string;

  @Virtual()
  @Serializable(new ReportLinkDto({ info: { reportId: "" as ReportId, reportName: "" } }))
  link!: LinkDto;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Color,
    editorType: EditorType.ColorPicker
  })
  @Serializable("")
  color: Maybe<string>;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.IndicatorIcon,
    editorType: EditorType.IconPicker
  })
  @Serializable(null)
  icon!: Maybe<IconId>;

  // FIXME should be Partial<NavLinkInfo> (ng-packagr issue#1185)
  constructor(navLinkInfoDto: Partial<NavLinkInfo> = {}) {
    if (isDefined(navLinkInfoDto.link)) {
      navLinkInfoDto = { ...navLinkInfoDto, link: linkCreate(navLinkInfoDto.link) };
    }
    construct(this, navLinkInfoDto, TYPE_NAME);
  }
}
