import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  NgZone,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { ColorListService } from "../../../../environment/services/color-list.service";
import { DateFormatterService } from "../../../../environment/services/date-formatter.service";
import { isDefined } from "../../../../ts-utils";
import { CriticalError } from "../../../../ts-utils/models/critical-error";
import { Maybe } from "../../../../ts-utils/models/maybe.type";
import { DataStatus } from "../../../models/data-status";
import { SingleValueDisplayStrategy } from "../../../models/display-strategies/single-value-display-strategies";
import { IGaugeDisplayConfig } from "../../../models/i-view-config/i-gauge-display-config";
import { SizeInPx } from "../../../models/size-in-px";
import { DataConnectorDescriptor } from "../../../models/store/data-connector-descriptor";
import { RuntimeSettingsSelector } from "../../../services/entity-selectors/runtime-settings.selector";
import { BulletChartDisplayService } from "../../../services/highcharts/bullet-chart-display.service";
import { DialGaugeDisplayService } from "../../../services/highcharts/dial-gauge-display.service";
import { GaugeDisplayService } from "../../../services/highcharts/gauge-display.service";
import { HorizontalGaugeDisplayService } from "../../../services/highcharts/horizontal-gauge-display.service";
import { MultiDialGaugeDisplayService } from "../../../services/highcharts/multi-dial-gauge-display.service";
import {
  SolidGaugeDisplayService,
  solidGaugeInnerRadiusInPercent
} from "../../../services/highcharts/solid-gauge-display.service";
import { VerticalGaugeDisplayService } from "../../../services/highcharts/vertical-gauge-display.service";
import { getMultiValueTooltip } from "../../../services/tooltip.helper";
import { SimpleChartComponent } from "../simple-chart/simple-chart.component";

@Component({
  selector: "gauge",
  templateUrl: "./gauge.component.html",
  styleUrls: ["./gauge.component.scss"],
  host: { class: "simple-component" },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GaugeComponent extends SimpleChartComponent implements OnChanges {
  @HostBinding("attr.title")
  tooltipText: string = "";

  @Input() dataConnectorDescriptors: Maybe<DataConnectorDescriptor>[] = [];
  @Input() viewConfig: Maybe<IGaugeDisplayConfig> = null;
  @Input() dataStatus: DataStatus = DataStatus.NoDataDefined;
  @Input() gaugeType: SingleValueDisplayStrategy = SingleValueDisplayStrategy.SolidGauge;

  private previousViewConfig: Maybe<IGaugeDisplayConfig> = null;

  constructor(
    ngZone: NgZone,
    protected dateFormatter: DateFormatterService,
    protected colorService: ColorListService,
    runtimeSettingsService: RuntimeSettingsSelector
  ) {
    super(ngZone, colorService, runtimeSettingsService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (isDefined(this.viewConfig)) {
      const displayService = this.getDisplayService();
      const newChartOptions = displayService.getChartOptions(
        this.viewConfig,
        this.dataConnectorDescriptors,
        this.dataStatus
      );
      const fullRedraw =
        isDefined(this.previousViewConfig) &&
        displayService.shouldRedraw(
          this.previousViewConfig,
          this.viewConfig,
          this.chartOptions,
          newChartOptions
        );
      this.previousViewConfig = this.viewConfig;
      this.mergeChartOptions(newChartOptions, fullRedraw, this.dataStatus);

      this.tooltipText = getMultiValueTooltip(
        this.dataConnectorDescriptors,
        {
          displayFormat: this.viewConfig.displayFormat,
          unit: this.viewConfig.unit
        },
        this.dateFormatter
      );
    } else {
      this.tooltipText = "";
    }
  }

  getDisplayService(): GaugeDisplayService {
    switch (this.gaugeType) {
      case SingleValueDisplayStrategy.SolidGauge:
        return new SolidGaugeDisplayService();
      case SingleValueDisplayStrategy.DialGauge:
        return new DialGaugeDisplayService();
      case SingleValueDisplayStrategy.MultiDialGauge:
        return new MultiDialGaugeDisplayService(this.colorService);
      case SingleValueDisplayStrategy.VerticalGauge:
        return new VerticalGaugeDisplayService();
      case SingleValueDisplayStrategy.HorizontalGauge:
        return new HorizontalGaugeDisplayService();
      case SingleValueDisplayStrategy.BulletChart:
        return new BulletChartDisplayService();
      default:
        throw new CriticalError(`Unknown gaugeType ${this.gaugeType}`);
    }
  }

  protected getHighchartsSize(componentSize: SizeInPx): SizeInPx {
    const size = super.getHighchartsSize(componentSize);
    if (this.gaugeType === SingleValueDisplayStrategy.SolidGauge) {
      const factor = solidGaugeInnerRadiusInPercent / 100 - 0.1;
      if (size.heightInPx > size.widthInPx * factor) {
        size.heightInPx = size.heightInPx * factor;
      }
    }
    return size;
  }
}
