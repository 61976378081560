import { Component, HostBinding, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subject, combineLatest } from "rxjs";
import { filter, switchMap, takeUntil, tap } from "rxjs/operators";
import { DisplayMode } from "../../../core/models/display-mode";
import { Dispatcher } from "../../../dispatcher";
import { isRelativePositioningType } from "../../../elements/helpers/positioning-type.helper";
import { ComponentStateDto } from "../../../elements/models/component-state";
import { isContainerWidget, isNavigationBar } from "../../../elements/models/component-type.helper";
import { PositioningType } from "../../../elements/models/positioning-type";
import { ComponentSelectionService } from "../../../elements/services/component-selection.service";
import { ComponentStateSelector } from "../../../elements/services/entity-selectors/component-state.selector";
import { ComponentStateActions } from "../../../elements/store/component-state/component-state.actions";
import { ROOT_COMPONENT_ID } from "../../../elements/store/component-state/component-state.selectors";
import { EnvironmentSelector } from "../../../environment";
import { LocalizationService } from "../../../i18n/localization.service";
import { createUpdatedComponentsInfo } from "../../../meta/helpers/updated-entities-info.helper";
import { EntityId } from "../../../meta/models/entity";
import { PropertySheetService } from "../../../property-sheet/services/property-sheet.service";
import { isEmpty } from "../../../ts-utils/helpers/is-empty.helper";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { HeaderButtonConfig } from "../../models/button/header-button.config";
import { IconSource } from "../../models/icon-source";

@Component({
  selector: "components-header-buttons",
  templateUrl: "components-header-buttons.component.html",
  styleUrls: ["./components-header-buttons.component.scss"]
})
export class ComponentsHeaderButtons implements OnInit, OnDestroy {
  autoPositionButtonConfig!: HeaderButtonConfig;
  editButtonConfig!: HeaderButtonConfig;
  snapToGridButtonConfig!: HeaderButtonConfig;
  deleteButtonConfig!: HeaderButtonConfig;
  sendToBackButtonConfig!: HeaderButtonConfig;
  bringToFrontButtonConfig!: HeaderButtonConfig;
  dropdownButtonConfig!: HeaderButtonConfig;
  isMenuOpened: boolean = false;
  DisplayMode = DisplayMode;
  displayMode: string;
  public isSnapToGridActive: boolean = false;
  public isAutoPositionActive: boolean = false;
  private unsubscribeSubject$: Subject<any> = new Subject();
  @HostBinding("class.collapsed") collapseButtons: boolean = false;

  constructor(
    private componentStateSelector: ComponentStateSelector,
    private propertySheetService: PropertySheetService,
    private localizer: LocalizationService,
    private dispatcher: Dispatcher,
    private componentSelectionService: ComponentSelectionService,
    private environmentSelector: EnvironmentSelector
  ) {}

  ngOnInit(): void {
    this.autoPositionButtonConfig = this.createAutoPositionButtonConfig();
    this.snapToGridButtonConfig = this.createSnapToGridButtonConfig();
    this.editButtonConfig = this.createEditButtonConfig();
    this.deleteButtonConfig = this.createDeleteButtonConfig();
    this.bringToFrontButtonConfig = this.createBringToFrontButtonConfig();
    this.sendToBackButtonConfig = this.createSendToBackButtonConfig();
    this.dropdownButtonConfig = this.createDropDownButtonConfig();
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  initSubscriptions(): void {
    this.subscribeToSelectedComponentProperties();
    this.subscribeToDisplayMode();
  }

  private subscribeToSelectedComponentProperties(): void {
    this.componentSelectionService.selectedComponentsChanged
      .pipe(
        tap(() => this.disableOrEnableButtons()),
        switchMap(() => this.getAncestorProperties$()),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe(([snapToGrid, positioningType]) => {
        this.isSnapToGridActive = isDefined(snapToGrid) ? snapToGrid : false;
        this.isAutoPositionActive = isDefined(positioningType)
          ? isRelativePositioningType(positioningType)
          : false;
      });
  }

  private subscribeToDisplayMode(): void {
    this.environmentSelector
      .selectDisplayMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((displayMode: string) => {
        this.collapseButtons = displayMode !== DisplayMode.Desktop;
        this.displayMode = displayMode;
      });
  }

  private createAutoPositionButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.localizer.buttons.AutoPosition,
      isDisabled: true,
      iconSource: IconSource.Dashboard,
      clickFunction: () => this.onChangeAutoPosition()
    });
  }

  private createSnapToGridButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.localizer.buttons.SnapToGrid,
      isDisabled: true,
      iconSource: IconSource.Dashboard,
      clickFunction: () => this.onChangeSnapToGrid()
    });
  }

  private createEditButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.localizer.buttons.Edit,
      isDisabled: true,
      clickFunction: () => this.editSelectedComponent()
    });
  }

  private createDeleteButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.localizer.buttons.Delete,
      isDisabled: true,
      clickFunction: () => {
        return this.handleDelete();
      }
    });
  }

  private createBringToFrontButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.localizer.buttons.BringToFront,
      isDisabled: true,
      clickFunction: () => {
        return this.handleBringToFront();
      }
    });
  }

  private createSendToBackButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.localizer.buttons.SendToBack,
      isDisabled: true,
      clickFunction: () => {
        return this.handleSendToBack();
      }
    });
  }

  private createDropDownButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.localizer.buttons.Miscellaneous,
      hasDropdown: true,
      clickFunction: () => this.showOrHideMenu(!this.isMenuOpened)
    });
  }

  private onChangeAutoPosition(): void {
    const componentId = this.componentSelectionService.findAncestorOfSelectedComponent();
    this.dispatcher.dispatch(
      ComponentStateActions.toggleCardPositioning({
        componentId
      }),
      {
        withSnapshot: true,
        updatedEntitiesInfo: createUpdatedComponentsInfo([componentId])
      }
    );
  }

  private onChangeSnapToGrid(): void {
    const componentId =
      this.componentSelectionService.findAncestorOfSelectedComponent() ?? ROOT_COMPONENT_ID;
    this.dispatcher.dispatch(
      ComponentStateActions.toggleSnapToGrid({
        componentId
      }),
      {
        withSnapshot: true,
        updatedEntitiesInfo: createUpdatedComponentsInfo([componentId])
      }
    );
  }

  private editSelectedComponent(): void {
    const selectedComponentId: EntityId = this.componentSelectionService.selectedComponentsIds[0];
    const selectedComponent = this.componentStateSelector.getById(selectedComponentId);
    if (isDefined(selectedComponent)) {
      this.propertySheetService.openOrReplaceTarget(selectedComponent);
    }
  }

  private handleDelete(): void {
    const selectedComponentsIds = this.componentSelectionService.selectedComponentsIds;
    this.dispatcher.dispatch(
      ComponentStateActions.deleteManyWithChildren({
        targetComponents: this.componentStateSelector.getManyById(selectedComponentsIds)
      }),
      {
        withSnapshot: true,
        updatedEntitiesInfo: createUpdatedComponentsInfo(selectedComponentsIds)
      }
    );
  }

  private handleSendToBack(): void {
    const selectedComponentsIds = this.componentSelectionService.selectedComponentsIds;
    if (!isEmpty(selectedComponentsIds)) {
      this.dispatcher.dispatch(
        ComponentStateActions.sendToBackMany({
          componentId: selectedComponentsIds[0],
          incrementalUpdateSiblings: selectedComponentsIds.slice(1)
        }),
        {
          withSnapshot: true,
          updatedEntitiesInfo: createUpdatedComponentsInfo(selectedComponentsIds)
        }
      );
    }
  }

  private handleBringToFront(): void {
    const selectedComponentsIds = this.componentSelectionService.selectedComponentsIds;
    if (!isEmpty(selectedComponentsIds)) {
      this.dispatcher.dispatch(
        ComponentStateActions.bringToFrontMany({
          componentId: selectedComponentsIds[0],
          incrementalUpdateSiblings: selectedComponentsIds.slice(1)
        }),
        {
          withSnapshot: true,
          updatedEntitiesInfo: createUpdatedComponentsInfo(selectedComponentsIds)
        }
      );
    }
  }

  private disableOrEnableButtons(): void {
    const lastSelectedComponent: Maybe<ComponentStateDto> =
      this.componentSelectionService.lastSelectedComponent;
    const isSelectionEmpty = this.componentSelectionService.isSelectionEmpty;

    const isMultipleSelectionActive = this.componentSelectionService.isMultipleSelectionActive;
    const isContainer = isContainerWidget(lastSelectedComponent?.type ?? "");
    const isNavBar = isNavigationBar(lastSelectedComponent?.type ?? "");

    this.deleteButtonConfig.isDisabled = isSelectionEmpty;
    this.bringToFrontButtonConfig.isDisabled = isSelectionEmpty || isContainer;
    this.sendToBackButtonConfig.isDisabled = isSelectionEmpty || isContainer;
    this.autoPositionButtonConfig.isDisabled = isSelectionEmpty || isNavBar;
    this.snapToGridButtonConfig.isDisabled = isNavBar;
    this.editButtonConfig.isDisabled = isSelectionEmpty || isMultipleSelectionActive;
  }

  public showOrHideMenu(isMenuOpened: boolean): void {
    this.isMenuOpened = isMenuOpened;
  }

  private getAncestorProperties$(): Observable<[boolean, PositioningType]> {
    const componentId: EntityId =
      this.componentSelectionService.findAncestorOfSelectedComponent() ?? ROOT_COMPONENT_ID;
    const componentStateUnavailable$ = this.componentStateSelector
      .selectComponentStateAvailability(componentId)
      .pipe(filter((available) => available === false));

    return combineLatest([
      this.componentStateSelector
        .selectSnapToGrid(componentId)
        .pipe(takeUntil(componentStateUnavailable$)),
      this.componentStateSelector
        .selectPositioningType(componentId)
        .pipe(takeUntil(componentStateUnavailable$))
    ]);
  }
}
