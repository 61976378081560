import { createAction, props } from "@ngrx/store";
import { ComponentStateDto } from "../../../../elements/models/component-state";

export namespace HistoryViewDialogActions {
  export const openHistoryViewDialog = createAction(
    "[Dialog] Open History View Dialog",
    props<{ component: ComponentStateDto }>()
  );

  export const closeHistoryViewDialog = createAction("[Dialog] Close History View Dialog");
}
