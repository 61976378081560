import { DroppableElement } from "./droppable-element";
import { EquipmentProperty } from "./equipment-property";

export class Equipment implements DroppableElement {
  // TODO Equipment browser should use new Equipment Node which extends this class and move disabled there
  public id?: string;
  public name?: string;
  public description?: string;
  public path?: string;
  public properties: EquipmentProperty[] = [];
  public children: Equipment[] = [];
  public disabled?: boolean;
}
