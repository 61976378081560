<div class="card-item__drag-handle">
  <highcharts-chart-extended
    class="chart-container"
    [Highcharts]="Highcharts"
    [options]="chartOptions"
  >
  </highcharts-chart-extended>
  <span class="rotor-shape-chart__legend-title"> LEGEND </span>
  <km-legend
    [itemList]="[
      { name: 'Smallest Airgap', color: view.smallestAirgapColor },
      {
        name: 'Largest Airgap',
        color: view.largestAirgapColor
      },
      { name: 'Calculated Rotor Shape', color: view.calculatedRotorShapeColor },
      { name: 'Detected Pole Values', color: view.detectedPoleValuesColor },
      { name: 'Average Airgap', color: view.averageAirgapColor }
    ]"
    [alignment]="HorizontalAlignment.Center"
  ></km-legend>
</div>
