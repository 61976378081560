import { isDevMode } from "@angular/core";
import { Dispatcher } from "../../dispatcher";
import { TypeProvider } from "../../meta/services/type-provider";
import { isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { DeepPartial } from "../../ts-utils/models/deep-partial.type";
import { BasicCardViewConfig } from "../components/basic-card/view-config";
import { IframeViewConfig } from "../components/generic-iframe/view-config";
import { ComponentCssSize } from "../models/component-size";
import { cssSizeParser } from "../models/component-size.constants";
import { ComponentStateDto } from "../models/component-state";
import { GENERIC_IFRAME } from "../models/element-type.constants";
import { ComponentStateSelector } from "../services/entity-selectors/component-state.selector";
import { ComponentStateActions, ROOT_COMPONENT_ID } from "../store";
import { isRelativePositioningType } from "./positioning-type.helper";

export function convertReportToTemplate(componentStateSelector: ComponentStateSelector): string {
  const card = componentStateSelector.getChildrenAsArray(ROOT_COMPONENT_ID)[0];
  const isAutoPosition = isRelativePositioningType(
    (card.view as BasicCardViewConfig).positioningType
  );
  const iframeWidgets = componentStateSelector
    .getManyById(card.childrenIds)
    .filter((component) => component.type === GENERIC_IFRAME);

  const iframeElements = iframeWidgets.map((iframeWidget, index) => {
    const view = iframeWidget.view as IframeViewConfig;
    return createIframeElement(view, index, isAutoPosition);
  });

  return generateTemplateHtml(iframeElements);
}

export function refreshReportFromTemplate(
  templateText: string,
  componentSelector: ComponentStateSelector,
  dispatcher: Dispatcher
): void {
  const cardId = componentSelector.getRoot().childrenIds[0];
  dispatcher.dispatch(
    ComponentStateActions.deleteMany({
      targetComponents: componentSelector.getChildrenAsArray(cardId)
    })
  );

  const templateHtml = new DOMParser().parseFromString(templateText, "text/html");
  const iframes = templateHtml.querySelectorAll("iframe");
  const componentsCount = componentSelector.getComponentCount();
  iframes.forEach((iframe, index) => {
    iframe.src = createDashboardsSrc(iframe.src);
    const newComponent = createIframeComponent(iframe, componentsCount + index);
    dispatcher.dispatch(ComponentStateActions.addOne({ newComponent, parentId: cardId }));
  });
}

function createIframeElement(
  view: IframeViewConfig,
  index: number,
  isAutoPosition: boolean
): HTMLIFrameElement {
  const convertedWidth = convertStyleProp(view.size.width);
  const convertedHeight = convertStyleProp(view.size.height);
  const convertedTop = convertStyleProp(view.css.top);
  const convertedLeft = convertStyleProp(view.css.left);

  const iframe = document.createElement("iframe");
  iframe.id = `IFRAME_${index + 1}`;
  iframe.src = createTemplateSrc(view.src);
  iframe.frameBorder = "0";
  iframe.style.width = convertedWidth;
  iframe.style.height = convertedHeight;
  iframe.style.order = view.css.order;
  if (!isAutoPosition) {
    iframe.style.left = convertedLeft;
    iframe.style.top = convertedTop;
    iframe.style.position = "absolute";
  }
  return iframe;
}

function convertStyleProp(styleProp: string): string {
  const parsedProp = cssSizeParser(styleProp);
  // KBST doesn't suppot floating values
  return Math.floor(parsedProp.value) + parsedProp.unit;
}

function generateTemplateHtml(iframeElements: HTMLIFrameElement[]) {
  return `<!DOCTYPE html>
          <!-- #include file="../scripts/TemplateMenus.inc" -->
          <html>
            <head>
              <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
              <link href="../css/CIMSstyles.css" rel="stylesheet" type="text/css" media="screen" />
              <link href="../css/CIMSPrintStyles.css" rel="stylesheet" type="text/css" media="print" />
              <!-- #include file="../Scripts/KBSHeaderInclude.inc" -->
              <%=GetPopUpScripts%>
            </head>
            <body bmi_id_max="${iframeElements.length}">
              ${iframeElements.reduce((acc, element) => acc.concat(element.outerHTML + "\n"), "")}
              <%=GetPopupDiv%>
            </body>
          </html>`;
}

function createIframeComponent(iframe: HTMLIFrameElement, counter: number): ComponentStateDto {
  return ComponentStateDto.createWithCounter(GENERIC_IFRAME, counter, TypeProvider.getInstance(), {
    view: {
      css: { top: iframe.style.top, left: iframe.style.left, order: iframe.style.order },
      size: new ComponentCssSize(iframe.style.width, iframe.style.height),
      src: iframe.src
    } as DeepPartial<IframeViewConfig>
  });
}

export function extractIframeConfig(htmlText: string): Partial<IframeViewConfig> {
  const htmlElement = new DOMParser().parseFromString(htmlText, "text/html");
  const iframeElement = htmlElement.querySelector("iframe");
  if (isNotDefined(iframeElement)) {
    return {};
  }

  return {
    size: new ComponentCssSize(iframeElement.style.width, iframeElement.style.height),
    src: createDashboardsSrc(iframeElement.src)
  };
}

function createDashboardsSrc(originalSrc: string): string {
  if (!isDevMode()) {
    return originalSrc;
  }

  return originalSrc.replace(
    location.protocol + "//" + location.host,
    location.protocol + "//" + location.hostname + "/km"
  );
}

function createTemplateSrc(originalSrc: string): string {
  return isDevMode()
    ? originalSrc.replace(location.protocol + "//" + location.hostname + "/km", "..")
    : originalSrc.replace(location.protocol + "//" + location.host, "..").replace("/km", "");
}
