import { Injectable } from "@angular/core";
import { EntityState } from "@ngrx/entity";
import { BorderSide, BorderStyleDto } from "../../elements/models/border-style";
import { ComponentStateDto } from "../../elements/models/component-state";
import { ComponentStyleDto } from "../../elements/models/component-style";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import { EntityId } from "../../meta/models/entity";
import { isDefined } from "../../ts-utils/helpers/predicates.helper";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class UpgradeCssBorder implements UpgradeStep {
  name = "UpgradeBorderStyle";
  fromVersion = new Version(4, 0, 8);

  perform(oldConfig: any): UpgradeStepResult {
    const componentStates = oldConfig.componentStates;
    let modified = false;
    if (isDefined(componentStates)) {
      for (const componentState of Object.values(componentStates.entities)) {
        if (
          isDefined((componentState as any).view) &&
          isDefined((componentState as any).view.css)
        ) {
          modified = this.updateCssBorder(componentStates, (componentState as any).id) || modified;
        }
      }
    }
    return {
      result: oldConfig,
      modified: modified,
      warning: modified ? LOCALIZATION_DICTIONARY.snackBarMessages.BorderUpgrade : null
    };
  }

  updateCssBorder(componentStates: EntityState<ComponentStateDto>, componentId: EntityId): boolean {
    const component = componentStates.entities[componentId];
    const cssBorderDto: BorderStyleDto = new BorderStyleDto();
    let modified = false;

    if (isDefined(component) && isDefined(component.view.css)) {
      if (this.areAllSidesConfigured((component as any)?.view.css)) {
        delete (component.view.css as any).borderLeft;
        delete (component.view.css as any).borderRight;
        delete (component.view.css as any).borderTop;
        delete (component.view.css as any).borderBottom;

        cssBorderDto.sides = [...cssBorderDto.sides, this.getBorderSideKey(BorderSide.All)];
        modified = true;
      }

      modified = this.checkSideConfiguration(component.view.css, cssBorderDto);
      this.updateComponentState(componentStates, componentId, cssBorderDto);
    }
    return modified;
  }

  private areAllSidesConfigured(css: ComponentStyleDto): boolean {
    return (
      isDefined((css as any).borderLeft) &&
      isDefined((css as any).borderTop) &&
      isDefined((css as any).borderRight) &&
      isDefined((css as any).borderBottom)
    );
  }

  private checkSideConfiguration(css: ComponentStyleDto, cssBorderDto: BorderStyleDto): boolean {
    let modified: boolean = false;
    if (isDefined((css as any).borderLeft)) {
      delete (css as any).borderLeft;
      cssBorderDto.sides = [...cssBorderDto.sides, this.getBorderSideKey(BorderSide.Left)];
      modified = true;
    }
    if (isDefined((css as any).borderRight)) {
      delete (css as any).borderRight;
      cssBorderDto.sides = [...cssBorderDto.sides, this.getBorderSideKey(BorderSide.Right)];
      modified = true;
    }
    if (isDefined((css as any).borderTop)) {
      delete (css as any).borderTop;
      cssBorderDto.sides = [...cssBorderDto.sides, this.getBorderSideKey(BorderSide.Top)];
      modified = true;
    }
    if (isDefined((css as any).borderBottom)) {
      delete (css as any).borderBottom;
      cssBorderDto.sides = [...cssBorderDto.sides, this.getBorderSideKey(BorderSide.Bottom)];
      modified = true;
    }

    return modified;
  }

  private getBorderSideKey(borderSideValue: BorderSide): string {
    return Object.keys(BorderSide)[Object.values(BorderSide).indexOf(borderSideValue)];
  }

  private updateComponentState(
    componentStates: EntityState<ComponentStateDto>,
    componentId: EntityId,
    cssBorderDto: BorderStyleDto
  ): void {
    const component = componentStates.entities[componentId];
    if (isDefined(component)) {
      component.view.css = { ...component.view.css, border: cssBorderDto };
      componentStates.entities[componentId] = component;
    }
  }
}
