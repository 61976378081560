import "reflect-metadata";
import { isDefined } from "../../ts-utils";
import { PropertyDescriptor } from "../models/property-descriptor";
import { TypeProvider } from "../services/type-provider";

const TYPE_PROVIDER = TypeProvider.getInstance();

export function Serializable(defaultValue?: any, arrayTypeConstructorFunction?: Function) {
  return function (proto: Object, key: string) {
    const typeDescriptor = TYPE_PROVIDER.addType({
      constructorFunction: proto.constructor as new () => any
    });

    const isArrayType = isDefined(arrayTypeConstructorFunction);
    const typeConstructorFunction = isArrayType
      ? arrayTypeConstructorFunction
      : Reflect.getMetadata("design:type", proto, key);

    const partialPropertyDescriptor: Partial<PropertyDescriptor> = {
      defaultValue: defaultValue,
      isArray: isArrayType,
      constructorFunction: typeConstructorFunction,
      isSerializable: true
    };

    typeDescriptor.upsertProperty(key, typeConstructorFunction, partialPropertyDescriptor);
  };
}
