import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MAT_SELECT_CONFIG } from "@angular/material/select";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { isEqual as _isEqual } from "lodash";
import { ValueFormatterService } from "projects/ui-core/src/lib/core/services/value-formatter.service";
import { ViewMode } from "../../../../core/models/view-mode";
import { getConnectorIdByViewId } from "../../../../data-connectivity/helpers/connector-view-id.helper";
import { EntityId } from "../../../../meta/models/entity";
import { SimpleSingleValueViewConfig } from "../../../../shared/components/simple-single-value/view-config";
import { SimpleViewConfig } from "../../../../shared/models/simple-view-config";
import { Dictionary, Maybe, isDefined, isEmpty, isNotDefined } from "../../../../ts-utils";
import { extractColumnIdFromFooterId } from "../../../helpers/column.helper";
import { resolveFooterValue } from "../../../helpers/footer-function.helper";
import { TableCellType } from "../../../models/table/table-cell-type";
import { ColumnType, TableColumnConfig } from "../../../models/table/table-column-config";
import { FooterFunctions } from "../../../models/table/table-footer-functions";
import { FooterRow, TableRow } from "../../../models/table/table-row";
import {
  LABEL_COLUMN,
  X_COLUMN_ID
} from "../table-for-connectors/table-rendering-strategies/connector-per-column-strategy";

const CSS_SORT_HEADER_CONTAINER = "mat-sort-header-container";
export const CSS_TABLE_PAGINATOR_CONTAINER = "table__paginator-container";
const MIN_COLUMN_WIDTH = 70;
const OFFSET_TO_HEADER = 2;

@Component({
  selector: "c-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: {
        overlayPanelClass: CSS_TABLE_PAGINATOR_CONTAINER
      }
    }
  ]
})
export class TableComponent implements AfterViewInit, OnChanges {
  @Input() tableColumns: TableColumnConfig[] = [];
  @Input() tableColumnIds: string[] = [];
  @Input() pageSizeOptions: number[] = [];
  @Input() pageSize: number = 0;
  @Input() headerBackgroundColor: string = "#ccc";
  @Input() viewMode: ViewMode = ViewMode.EditMode;
  @Input() isFilterEnabled: boolean = false;
  @Input() alternateRowColors: boolean = false;
  @Input() tableRowsWithPaginator: MatTableDataSource<TableRow[]> = new MatTableDataSource<
    TableRow[]
  >([]);
  @Input() tableComponentId!: EntityId;
  @Input() numberOfRequestedDataPoints: number = 0;
  @Input() showFooter: boolean = false;
  @Input() footerRowDict: Dictionary<FooterRow> = {};

  @Output() pageSizeChange: EventEmitter<number> = new EventEmitter();
  @Output() sortDirectionChange: EventEmitter<any> = new EventEmitter();
  @Output() storePreviewAndInlineChanges: EventEmitter<TableColumnConfig[]> = new EventEmitter();
  @Output() loadMoreData: EventEmitter<any> = new EventEmitter();

  @ViewChild("paginator", { static: true }) paginator: Maybe<MatPaginator> = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  public hidePaginator: boolean = false;
  public resizeInProgress = false;
  public resizeStartPoint: number = 0;
  public initialColumnWidth: number = 0;
  public headerRow!: HTMLTableRowElement;
  public resizedColumnIndex: number = -1;
  public columnToResize: Maybe<Element> = null;
  public TableCellType = TableCellType;
  public isSortDisabled: boolean = false;
  public footerIds: string[] = [""];
  public labelColumn: string = LABEL_COLUMN;

  constructor(
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    protected valueFormatter: ValueFormatterService
  ) {}

  ngAfterViewInit(): void {
    this.tableRowsWithPaginator.sort = this.sort;
    if (isDefined(this.paginator)) {
      this.tableRowsWithPaginator.paginator = this.paginator;
    }
    this.tableRowsWithPaginator.sortingDataAccessor = (row: any, headerId: string): string => {
      return isDefined(row.cells[headerId]) &&
        isDefined(row.cells[headerId].rowValue) &&
        isDefined(row.cells[headerId].cellType) &&
        row.cells[headerId].cellType !== this.TableCellType.HTML
        ? (row.cells[headerId].rowValue as string)
        : "";
    };
    this.filterTableData();
    this.determinePaginatorRangeLabel();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isDefined(changes["tableColumns"])) {
      this.showOrHidePaginator();
    }

    if (!this.isFilterEnabled && this.viewMode === ViewMode.EditMode) {
      this.checkForAllDataWhenFilterIsDisabled();
    }

    if (this.checkForFooterIdsUpdate(changes)) {
      this.footerIds = Object.keys(this.footerRowDict);
    }
  }

  public checkForAllDataWhenFilterIsDisabled(): void {
    if (!_isEqual(this.tableRowsWithPaginator.data, this.tableRowsWithPaginator.filteredData)) {
      this.tableRowsWithPaginator.filter = "";
      this.showOrHidePaginator();
    }
  }

  public filterTableData(): void {
    this.tableRowsWithPaginator.filterPredicate = (data: any, filter: string): boolean => {
      let isMatching: boolean = false;
      let isFilterIncluded: boolean = false;
      this.tableColumns.forEach((column: TableColumnConfig) => {
        if (isDefined(data.cells[column.id]) && isDefined(data.cells[column.id].value)) {
          const value: string = this.getValue(column, data).toString();
          if (value.trim().toLocaleLowerCase().includes(filter)) {
            isFilterIncluded = true;
          }
        }
        isMatching = isFilterIncluded;
      });
      return isMatching;
    };
  }

  private determinePaginatorRangeLabel(): void {
    if (isDefined(this.paginator)) {
      this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
        const start: number = page * pageSize + 1;
        const end: number = Math.min((page + 1) * pageSize, length);
        const totalCount: string = length < this.numberOfRequestedDataPoints ? `of ${length}` : "";
        return `${start} - ${end} ${totalCount}`;
      };
    }
  }

  private checkForFooterIdsUpdate(changes: SimpleChanges): boolean {
    return (
      isDefined(changes["footerRowDict"]) &&
      isDefined(changes["footerRowDict"].previousValue) &&
      isDefined(changes["footerRowDict"].currentValue) &&
      !_isEqual(changes["footerRowDict"].previousValue, changes["footerRowDict"].currentValue)
    );
  }

  public mergeConfigs(column: TableColumnConfig, row: TableRow): Partial<SimpleViewConfig> {
    let columnConfig;
    let cellConfig;

    if (isNotDefined(column) || isNotDefined(column.cellConfig)) {
      columnConfig = {};
    } else {
      columnConfig = column.cellConfig;
    }

    if (
      isNotDefined(row) ||
      isNotDefined(row.cells[column.id]) ||
      isNotDefined(row.cells[column.id].config)
    ) {
      cellConfig = {};
    } else {
      cellConfig = row.cells[column.id].config;
    }
    return { ...columnConfig, ...cellConfig };
  }

  public getValue(column: TableColumnConfig, row: TableRow): any {
    if (isNotDefined(column) || isNotDefined(row) || isNotDefined(row.cells[column.id])) {
      return "";
    } else {
      const value = row.cells[column.id].value;
      const format: string = (column.cellConfig as SimpleSingleValueViewConfig).displayFormat;
      return this.valueFormatter.formatValue(value, format);
    }
  }

  pageChanged({ pageSize }: PageEvent): void {
    this.pageSizeChange.emit(pageSize);
    if (this.isLastPage()) {
      this.loadMoreData.emit();
    }
  }

  private isLastPage(): boolean {
    return !this.paginator?.hasNextPage();
  }

  public sortDirectionChanged(): void {
    this.sortDirectionChange.emit();
    this.isSortDisabled = false;
  }

  public drop(event: CdkDragDrop<TableRow[][]>): void {
    moveItemInArray(this.tableColumns, event.previousIndex, event.currentIndex);
    moveItemInArray(this.tableColumnIds, event.previousIndex, event.currentIndex);
    this.storePreviewAndInlineChanges.emit(this.tableColumns);
  }

  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableRowsWithPaginator.filter = filterValue.trim().toLocaleLowerCase();
    if (this.tableRowsWithPaginator.paginator) {
      this.tableRowsWithPaginator.paginator.firstPage();
    }
    this.showOrHidePaginator();
  }

  public showOrHidePaginator(): void {
    const items: number = this.isFilterEnabled
      ? this.tableRowsWithPaginator.filteredData.length
      : this.tableRowsWithPaginator.data.length;
    this.hidePaginator = items / this.pageSize <= 1;
  }

  public onResizeStart(event: MouseEvent, index: number): void {
    event.preventDefault();
    const resizeHandler: any = event.target;
    this.columnToResize = resizeHandler.closest("th");
    this.resizedColumnIndex = index;
    this.resizeInProgress = true;
    this.resizeStartPoint = event.pageX;

    if (isDefined(this.columnToResize)) {
      this.headerRow = this.columnToResize.closest("tr");
      this.initialColumnWidth = this.columnToResize.clientWidth;

      this.headerRow.addEventListener("mousemove", this.onMouseMove);
      this.headerRow.addEventListener("mouseup", this.onMouseUp);
    }
  }

  onMouseMove = (event: MouseEvent) => {
    if (isDefined(this.columnToResize) && this.resizeInProgress && event.buttons) {
      const widthResizeOffset: number = event.pageX - this.resizeStartPoint;
      const newWidth: number = this.initialColumnWidth + widthResizeOffset;
      this.setAutoWidthForLastColumn();

      if (newWidth >= MIN_COLUMN_WIDTH) {
        const sortHeaderContainer = this.columnToResize.querySelector(
          "." + CSS_SORT_HEADER_CONTAINER
        );
        this.renderer.setStyle(
          sortHeaderContainer,
          "max-width",
          `${newWidth - OFFSET_TO_HEADER}px`
        );

        this.adjustColumnWidth(this.resizedColumnIndex, newWidth);
        this.cdr.detectChanges();
      }
    }
  };

  private setAutoWidthForLastColumn(): void {
    const allColumnsResized = this.tableColumns.every((column: TableColumnConfig) =>
      isDefined((column.cellConfig as SimpleSingleValueViewConfig).columnWidth)
    );
    if (allColumnsResized) {
      this.adjustColumnWidth(this.tableColumns.length - 1);
    }

    const lastColumn = this.headerRow.lastElementChild;
    if (isDefined(lastColumn)) {
      this.renderer.removeStyle(lastColumn, "width");
    }
  }

  private adjustColumnWidth(index: number, width: Maybe<number> = null): void {
    (this.tableColumns[index].cellConfig as SimpleSingleValueViewConfig).columnWidth = width;
  }

  onMouseUp = (event) => {
    if (this.resizeInProgress) {
      this.resizeInProgress = false;
      this.headerRow.removeEventListener("mousemove", this.onMouseMove);
      this.headerRow.removeEventListener("mouseup", this.onMouseUp);
      this.columnToResize = null;
      this.isSortDisabled = true;
      this.storePreviewAndInlineChanges.emit(this.tableColumns);
    }
  };

  getConnectorId(columnId: string): Maybe<string> {
    return columnId === X_COLUMN_ID ? null : getConnectorIdByViewId(columnId);
  }

  processFooterValue(footerId: string, footerColumnId: string): number | string {
    const footerRow: Maybe<FooterRow> = this.footerRowDict[footerId];

    if (
      isNotDefined(footerRow) ||
      (isDefined(footerRow) && footerRow.function === FooterFunctions.None)
    ) {
      return "";
    }

    if (footerColumnId.includes(LABEL_COLUMN)) {
      return footerRow.label;
    }

    const columnId = extractColumnIdFromFooterId(footerColumnId);
    const columnType: Maybe<ColumnType> = this.tableColumns.find(
      (column) => column.id === columnId
    )?.columnType;
    const isNumericData: boolean = isDefined(columnType) && columnType === ColumnType.Number;

    if (!isNumericData) {
      return "";
    }

    const values = this.extractCurrentPageData()
      .map((tableRow: any) => {
        return tableRow.cells[columnId]?.value ?? null;
      })
      .filter(isDefined);

    if (isEmpty(values)) {
      return "";
    }

    return Number(resolveFooterValue(footerRow.function, values).toFixed(2));
  }

  extractCurrentPageData(): TableRow[][] {
    if (isNotDefined(this.paginator)) {
      return this.tableRowsWithPaginator.data;
    }

    const startIndex: number = this.paginator.pageSize * this.paginator.pageIndex;
    const endIndex: number = this.paginator.pageSize + startIndex;

    let tableRows: TableRow[][] = [];
    if (this.tableRowsWithPaginator.filteredData.length !== 0) {
      tableRows = this.tableRowsWithPaginator.filteredData;
    } else {
      tableRows = this.tableRowsWithPaginator.data;
    }
    return tableRows.slice(startIndex, endIndex);
  }

  getFooterColumnConfig(columnId: string): SimpleSingleValueViewConfig {
    const column = this.tableColumns.find(
      (column) => column.id === extractColumnIdFromFooterId(columnId)
    );
    return column?.cellConfig as SimpleSingleValueViewConfig;
  }

  trackItemsByIndex(i: number): number {
    return i;
  }

  isColumnSortingDisabled(columnId: string): boolean {
    return this.isSortDisabled || columnId.includes(LABEL_COLUMN);
  }

  shouldHideFooter(): boolean {
    return !this.showFooter || this.tableRowsWithPaginator.data.length === 0;
  }
}
