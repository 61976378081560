import { Component, HostListener } from "@angular/core";

@Component({
  selector: "base-dialog",
  template: ""
})
export class BaseDialogComponent {
  constructor() {}

  @HostListener("body:click", ["$event"])
  onClick(event: Event) {
    event.stopPropagation();
  }

  @HostListener("body:keydown", ["$event"])
  onKeydown(event: KeyboardEvent): void {
    event.stopPropagation();
  }
}
