import { DeepPartial, isDefined } from "../../ts-utils";
import { exhaustiveTypeCheck } from "../../ts-utils/helpers/exhaustive-type-check.helper";
import { CriticalError } from "../../ts-utils/models/critical-error";
import { ApiDataSourceDto } from "../models/data-source/api-data-source";
import { DataSourceDto } from "../models/data-source/data-source";
import {
  API_DATA_SOURCE,
  EMPTY_DATA_SOURCE,
  EQUIPMENT_DATA_SOURCE,
  GROUPED_DATA_SOURCE,
  SIGNAL_DATA_SOURCE,
  TABULAR_DATA_SOURCE,
  VALUE_DATA_SOURCE
} from "../models/data-source/data-source.type";
import { EmptyDataSourceDto } from "../models/data-source/empty-data-source";
import { EquipmentDataSourceDto } from "../models/data-source/equipment-data-source";
import { GenericDataSourceDto } from "../models/data-source/generic-data-source";
import { GroupedDataSourceDto } from "../models/data-source/grouped-data-source";
import { SignalDataSourceDto } from "../models/data-source/signal-data-source";
import { TabularDataSourceDto } from "../models/data-source/tabular-data-source";
import { ValueDataSourceDto } from "../models/data-source/value-data-source";

export function switchDataSource<Out>(
  dataSource: DataSourceDto,
  callbacks: {
    ApiDataSourceDto: (dataSource: ApiDataSourceDto) => Out;
    EmptyDataSourceDto: (dataSource: EmptyDataSourceDto) => Out;
    EquipmentDataSourceDto: (dataSource: EquipmentDataSourceDto) => Out;
    GroupedDataSourceDto: (dataSource: GroupedDataSourceDto) => Out;
    SignalDataSourceDto: (dataSource: SignalDataSourceDto) => Out;
    TabularDataSourceDto: (dataSource: TabularDataSourceDto) => Out;
    ValueDataSourceDto: (dataSource: ValueDataSourceDto) => Out;
  }
): Out {
  switch (dataSource.typeName) {
    case "ApiDataSourceDto":
      return callbacks[dataSource.typeName](dataSource as ApiDataSourceDto);
    case "EmptyDataSourceDto":
      return callbacks[dataSource.typeName](dataSource as EmptyDataSourceDto);
    case "EquipmentDataSourceDto":
      return callbacks[dataSource.typeName](dataSource as EquipmentDataSourceDto);
    case "GroupedDataSourceDto":
      return callbacks[dataSource.typeName](dataSource as GroupedDataSourceDto);
    case "SignalDataSourceDto":
      return callbacks[dataSource.typeName](dataSource as SignalDataSourceDto);
    case "TabularDataSourceDto":
      return callbacks[dataSource.typeName](dataSource as TabularDataSourceDto);
    case "ValueDataSourceDto":
      return callbacks[dataSource.typeName](dataSource as ValueDataSourceDto);
    case "DataSourceDto":
    case "GenericDataSourceDto":
      throw new CriticalError(
        `Instance of abstract data source type ${dataSource.typeName} not allowed.`
      );
    default:
      exhaustiveTypeCheck("Unknown data source type: ", dataSource.typeName);
  }
}

export function switchPartialDataSource<Out>(
  dataSource: DeepPartial<DataSourceDto>,
  callbacks: {
    ApiDataSourceDto: (dataSource: DeepPartial<ApiDataSourceDto>) => Out;
    EmptyDataSourceDto: (dataSource: DeepPartial<EmptyDataSourceDto>) => Out;
    EquipmentDataSourceDto: (dataSource: DeepPartial<EquipmentDataSourceDto>) => Out;
    GroupedDataSourceDto: (dataSource: DeepPartial<GroupedDataSourceDto>) => Out;
    SignalDataSourceDto: (dataSource: DeepPartial<SignalDataSourceDto>) => Out;
    TabularDataSourceDto: (dataSource: DeepPartial<TabularDataSourceDto>) => Out;
    ValueDataSourceDto: (dataSource: DeepPartial<ValueDataSourceDto>) => Out;
  }
): Out {
  switch (dataSource.typeName) {
    case "ApiDataSourceDto":
      return callbacks[dataSource.typeName](dataSource as DeepPartial<ApiDataSourceDto>);
    case "EmptyDataSourceDto":
      return callbacks[dataSource.typeName](dataSource as DeepPartial<EmptyDataSourceDto>);
    case "EquipmentDataSourceDto":
      return callbacks[dataSource.typeName](dataSource as DeepPartial<EquipmentDataSourceDto>);
    case "GroupedDataSourceDto":
      return callbacks[dataSource.typeName](dataSource as DeepPartial<GroupedDataSourceDto>);
    case "SignalDataSourceDto":
      return callbacks[dataSource.typeName](dataSource as DeepPartial<SignalDataSourceDto>);
    case "TabularDataSourceDto":
      return callbacks[dataSource.typeName](dataSource as DeepPartial<TabularDataSourceDto>);
    case "ValueDataSourceDto":
      return callbacks[dataSource.typeName](dataSource as DeepPartial<ValueDataSourceDto>);
    case "DataSourceDto":
    case "GenericDataSourceDto":
      throw new CriticalError(
        `Instance of abstract data source type ${dataSource.typeName} not allowed.`
      );
    default:
      exhaustiveTypeCheck("Unknown data source type: ", dataSource.typeName);
  }
}

export function isSignalBased(dataSource: DataSourceDto): dataSource is SignalDataSourceDto {
  return isSignal(dataSource) || isEquipment(dataSource);
}

export function isSignal(dataSource: DataSourceDto): dataSource is SignalDataSourceDto {
  return isDefined(dataSource) && dataSource.typeName === SIGNAL_DATA_SOURCE;
}

export function isEquipment(dataSource: DataSourceDto): dataSource is EquipmentDataSourceDto {
  return isDefined(dataSource) && dataSource.typeName === EQUIPMENT_DATA_SOURCE;
}

export function isEmptySource(dataSource: DataSourceDto): dataSource is EmptyDataSourceDto {
  return isDefined(dataSource) && dataSource.typeName === EMPTY_DATA_SOURCE;
}

export function isGeneric(dataSource: DataSourceDto): dataSource is GenericDataSourceDto {
  return isGrouped(dataSource) || isTabular(dataSource);
}

export function isGrouped(dataSource: DataSourceDto): dataSource is GroupedDataSourceDto {
  return isDefined(dataSource) && dataSource.typeName === GROUPED_DATA_SOURCE;
}

export function isTabular(dataSource: DataSourceDto): dataSource is TabularDataSourceDto {
  return isDefined(dataSource) && dataSource.typeName === TABULAR_DATA_SOURCE;
}

export function isValue(dataSource: DataSourceDto): dataSource is ValueDataSourceDto {
  return isDefined(dataSource) && dataSource.typeName === VALUE_DATA_SOURCE;
}

export function isApi(dataSource: DataSourceDto): dataSource is ApiDataSourceDto {
  return isDefined(dataSource) && dataSource.typeName === API_DATA_SOURCE;
}

export function isGenericOrApiPartial(dataSource: DeepPartial<DataSourceDto>): boolean {
  return isGenericPartial(dataSource) || isApiPartial(dataSource);
}

export function isApiPartial(
  dataSource: DeepPartial<DataSourceDto>
): dataSource is DeepPartial<ApiDataSourceDto> {
  return isDefined(dataSource) && dataSource.typeName === API_DATA_SOURCE;
}

export function isEmptyPartial(
  dataSource: DeepPartial<DataSourceDto>
): dataSource is DeepPartial<EmptyDataSourceDto> {
  return isDefined(dataSource) && dataSource.typeName === EMPTY_DATA_SOURCE;
}

export function isEquipmentPartial(
  dataSource: DeepPartial<DataSourceDto>
): dataSource is DeepPartial<EquipmentDataSourceDto> {
  return isDefined(dataSource) && dataSource.typeName === EQUIPMENT_DATA_SOURCE;
}

export function isGenericPartial(
  dataSource: DeepPartial<DataSourceDto>
): dataSource is DeepPartial<GenericDataSourceDto> {
  return (
    isDefined(dataSource) &&
    (dataSource.typeName === GROUPED_DATA_SOURCE || dataSource.typeName === TABULAR_DATA_SOURCE)
  );
}

export function isGroupedPartial(
  dataSource: DeepPartial<DataSourceDto>
): dataSource is DeepPartial<GroupedDataSourceDto> {
  return isDefined(dataSource) && dataSource.typeName === GROUPED_DATA_SOURCE;
}

export function isTabularPartial(
  dataSource: DeepPartial<DataSourceDto>
): dataSource is DeepPartial<TabularDataSourceDto> {
  return isDefined(dataSource) && dataSource.typeName === TABULAR_DATA_SOURCE;
}
