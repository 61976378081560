import { DataTransferObject } from "../../core/models/data-transfer-object";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import { Configurable, ConfigurationCategory } from "../../meta/decorators";
import { EditableType } from "../../meta/decorators/editable-type.decorator";
import { Serializable } from "../../meta/decorators/serializable.decorator";
import { EditorType } from "../../meta/models";
import { PropertyCategory } from "../../meta/models/property-category";
import { ValidationContext } from "../../meta/models/validation-context";
import { CSS_SIZE, CSS_SIZE_REGEX, cssSizeParser } from "./component-size.constants";

export const DEFAULT_COMPONENT_WIDTH = 250;
export const DEFAULT_COMPONENT_HEIGHT = 150;

@EditableType({ fullName: CSS_SIZE, title: "component-css-size" })
export class ComponentCssSize implements DataTransferObject {
  typeName: string = CSS_SIZE;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.SizeAndPosition,
    1
  )
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Width,
    editorType: EditorType.TextBox,
    advancedMode: true,
    validationFunction: validateWidth,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.CssWidthTooltip,
    inlineAlignment: true
  })
  @Serializable()
  public width: string;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.SizeAndPosition,
    2
  )
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Height,
    editorType: EditorType.TextBox,
    advancedMode: true,
    validationFunction: validateHeight,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.CssHeightTooltip,
    inlineAlignment: true
  })
  @Serializable()
  public height: string;

  constructor(
    width: string = DEFAULT_COMPONENT_WIDTH.toString(),
    height: string = DEFAULT_COMPONENT_HEIGHT.toString()
  ) {
    this.width = width;
    this.height = height;
  }
}

export function validateHeight(height: string, _validationContext?: ValidationContext): boolean {
  return CSS_SIZE_REGEX.test(height) && cssSizeParser(height).value > 0;
}

export function validateWidth(width: string, _validationContext?: ValidationContext): boolean {
  return CSS_SIZE_REGEX.test(width) && cssSizeParser(width).value > 0;
}
