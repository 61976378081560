import { Actions, ofType, createEffect } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { switchMap, map, tap } from "rxjs/operators";

import { MatDialog } from "@angular/material/dialog";
import {
  AddAxisDialogComponent,
  RemoveAxisDialogComponent
} from "../../../components/trend/dialogs";
import { AxisData } from "../../../models/dialogs/axis-data.model";
import { OpenDialogEffect } from "../../../../shared/dialogs/effects/dialog.effects";
import { AddAxisConfigStrategy, RemoveAxisConfigStrategy } from "../dialog-config.strategies";
import { AxisDialogActions } from "../actions";

@Injectable()
export class AxisDialogEffects extends OpenDialogEffect {
  openAddAxisDialog$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AxisDialogActions.addAxisDialog),
      tap(() => {
        this.dialogConfigStrategy = new AddAxisConfigStrategy();
        this.dialogConfig = this.dialogConfigStrategy.createDialogConfig();
        this.dialogRef = this.dialog.open(AddAxisDialogComponent, this.dialogConfig);
      }),
      switchMap(() => {
        return this.dialogRef.afterClosed().pipe(
          map((axisData: AxisData) => {
            if (!axisData) {
              return AxisDialogActions.closeDialog({ dialogRef: this.dialogRef });
            }
            return AxisDialogActions.addAxisDialogSuccess({ result: axisData });
          })
        );
      })
    )
  );

  openRemoveAxisDialog$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AxisDialogActions.removeAxisDialog),
      tap(({ chartYAxes }) => {
        this.dialogConfigStrategy = new RemoveAxisConfigStrategy();
        this.dialogConfig = this.dialogConfigStrategy.createDialogConfig(chartYAxes);
        this.dialogRef = this.dialog.open(RemoveAxisDialogComponent, this.dialogConfig);
      }),
      switchMap(() => {
        return this.dialogRef.afterClosed().pipe(
          map((removeAxisIds: string[]) => {
            if (!removeAxisIds) {
              return AxisDialogActions.closeDialog({ dialogRef: this.dialogRef });
            }
            return AxisDialogActions.removeAxisDialogSuccess({ axisIds: removeAxisIds });
          })
        );
      })
    )
  );

  constructor(private actions$: Actions, dialog: MatDialog) {
    super(dialog);
  }
}
