import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ComponentCategoryInfo } from "../../core/models/component-category-info";
import { CoreComponentCategoryProvider } from "../../core/services/core-component-category-provider";
import { EnvironmentSelector } from "../../environment";
import { SidemenuComponents, ToolbarItem } from "../models/tool-bar-item";

@Injectable()
export class ToolbarItemsGenerator {
  constructor(
    protected componentCategoryInfoProvider: CoreComponentCategoryProvider,
    protected environmentSelector: EnvironmentSelector
  ) {}

  //TODO: rename
  generateConfigToolbarItems$(): Observable<ToolbarItem[]> {
    return this.generateLayoutEditorToolbarItems$();
  }

  private generateLayoutEditorToolbarItems$(): Observable<ToolbarItem[]> {
    const layoutBuiledItems$: Observable<ToolbarItem[]> = this.componentCategoryInfoProvider
      .selectAllCategories()
      .pipe(
        map((componentCategories) => componentCategories.sort((a, b) => a.orderNo - b.orderNo)),
        map((componentCategories) =>
          componentCategories.map(
            (categoryInfo: ComponentCategoryInfo) =>
              new ToolbarItem(
                categoryInfo.icon,
                SidemenuComponents.LayoutBuilderTabContent,
                categoryInfo.id
              )
          )
        )
      );

    return layoutBuiledItems$;
  }

  generateExplorerToolbarItems$(): Observable<ToolbarItem[]> {
    return this.environmentSelector
      .selectTemplateBuilderMode()
      .pipe(
        map((templateBuilderMode: boolean) =>
          templateBuilderMode
            ? []
            : [new ToolbarItem("abb-icon Database", SidemenuComponents.DataExplorer, "")]
        )
      );
  }
}
