import {
  customFiltersCollapsedWidth,
  customFilterWidth,
  filterButtonCollapse,
  filterIconItemWidth,
  periodTypeCollapsedWidth,
  periodTypeFullFilterWidth,
  rootPathCollapsedWidth,
  timeRangeCollapsedWidth
} from "../../../style-variables";
import { DisplayMode } from "../../core/models/display-mode";
import { Dictionary, Maybe } from "../../ts-utils";
import { FilterItem } from "../models/filter-item";
import { FilterType } from "../models/filter-type";

const DEFAULT_FILLED_SPACE = 79;

export function resolveExpansionConfiguration(
  filterItems: Dictionary<FilterItem>,
  displayMode: string,
  filterToolbarWidth: number
): Dictionary<FilterItem> {
  const filterBars = filterItems;
  Object.values(FilterType).map((filter: FilterType) => {
    filterBars[filter] = {
      ...filterItems[filter],
      canExpandHorizontally: canExpandHorizontally(
        filter,
        displayMode,
        filterBars,
        filterToolbarWidth
      )
    };
  });
  return filterBars;
}

export function canExpandHorizontally(
  filterType: FilterType,
  displayMode: string,
  filterItems: Dictionary<FilterItem>,
  filterToolbarWidth: number
): boolean {
  if (displayMode === DisplayMode.Mobile) {
    return false;
  }
  return (
    calculateRemainingWidth(filterToolbarWidth, filterType, filterItems) >
    filterItems[filterType].expandedWidth
  );
}

export function calculateRemainingWidth(
  filterWidth: number,
  filterType: FilterType,
  filterBars: Dictionary<FilterItem>
): number {
  return filterWidth - calculateFilledSpaceWidth(filterType, filterBars);
}

export function calculateFilledSpaceWidth(
  filterType: string,
  filterBars: Dictionary<FilterItem>
): number {
  let ocuppiedWidth: number = DEFAULT_FILLED_SPACE;
  Object.keys(filterBars).map((filter) => {
    if (filter !== filterType) {
      ocuppiedWidth += getFilterCurrentWidth(filterBars[filter]);
    }
  });
  return ocuppiedWidth;
}

export function getFilterCurrentWidth(filter: FilterItem): number {
  if (!filter.isExpanded || (filter.isExpanded && !filter.canExpandHorizontally)) {
    return filter.collapsedWidth;
  }
  return filter.expandedWidth;
}

export function resolveCustomFilterWidth(customFiltersLength: number): number {
  return (
    filterIconItemWidth + filterButtonCollapse + calculateCustomFiltersWidth(customFiltersLength)
  );
}

export function calculateCustomFiltersWidth(customFiltersLength: number): number {
  return customFiltersLength * customFilterWidth;
}

export function calculatePTypeFilterWidth(periodTypes: Dictionary<string>): number {
  return (
    filterIconItemWidth +
    filterButtonCollapse +
    periodTypeFullFilterWidth * Object.keys(periodTypes).length
  );
}

export function getCollapsedWidth(filterType: FilterType): number {
  switch (filterType) {
    case FilterType.CustomFilters: {
      return customFiltersCollapsedWidth;
    }
    case FilterType.PeriodType: {
      return periodTypeCollapsedWidth;
    }
    case FilterType.TimeRange: {
      return timeRangeCollapsedWidth;
    }
    case FilterType.RootPath: {
      return rootPathCollapsedWidth;
    }
    default: {
      return 0;
    }
  }
}

export function getFirstOpened(filterItems: Dictionary<FilterItem>): Maybe<string> {
  return Object.keys(filterItems).find((filter) => filterItems[filter].isExpanded);
}

export function findOpenedItems(filterItems: Dictionary<FilterItem>): string[] {
  return Object.keys(filterItems).filter((filter: string) => filterItems[filter].isExpanded);
}

export function findOpenedItemsVertically(filterItems: Dictionary<FilterItem>): string[] {
  return Object.keys(filterItems).filter(
    (filter: string) => filterItems[filter].isExpanded && !filterItems[filter].canExpandHorizontally
  );
}
