import { EventEmitter, Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { EntityId } from "../../meta/models/entity";
import { Maybe, isDefined, isNotDefined } from "../../ts-utils";
import { ComponentButtonParams } from "../components/base/component-button-params";
import {
  CSS_INLINE_EDIT_COMPONENT,
  InlineComponentParams as ComponentInlineInfo
} from "../models/inline-mode-params";
import { ComponentPositioningService } from "./component-positioning.service";

@Injectable()
export class InlineEditService {
  private renderer: Renderer2;
  private _componentInlineInfo: Maybe<ComponentInlineInfo> = null;

  onInlineComponentUpdate: EventEmitter<Maybe<ComponentInlineInfo>> = new EventEmitter<
    Maybe<ComponentInlineInfo>
  >();

  get componentInlineInfo(): Maybe<ComponentInlineInfo> {
    return this._componentInlineInfo;
  }

  set componentInlineInfo(componentInlineInfo: Maybe<ComponentInlineInfo>) {
    this._componentInlineInfo = componentInlineInfo;
    this.onInlineComponentUpdate.emit(componentInlineInfo);
  }

  constructor(
    private rendererFactory: RendererFactory2,
    private componentPositioningService: ComponentPositioningService
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  enterInlineEditMode(component: ComponentButtonParams): void {
    if (isDefined(this.componentInlineInfo)) {
      this.exitInlineEditMode(this.componentInlineInfo.id);
    }

    component.hideConfigButtons();
    this.componentPositioningService.hideDraggableOverlay(component.hostElement);
    component.componentSelectionService.clearSelection();
    this.renderer.addClass(component.hostElement, CSS_INLINE_EDIT_COMPONENT);

    this.componentInlineInfo = {
      id: component.currentState.id,
      viewConfig: component.currentState.view,
      hostElement: component.hostElement
    };
  }

  exitInlineEditMode(componentId: EntityId, shouldShowDraggableOverlay: boolean = true): void {
    if (this.isComponentInInlineEditMode(componentId)) {
      this.renderer.removeClass(this.componentInlineInfo?.hostElement, CSS_INLINE_EDIT_COMPONENT);
      shouldShowDraggableOverlay
        ? this.componentPositioningService.showDraggableOverlay(
            this.componentInlineInfo?.hostElement
          )
        : this.componentPositioningService.hideDraggableOverlay(
            this.componentInlineInfo?.hostElement
          );
      this.componentInlineInfo = null;
    }
  }

  canUnhideConfigButtons(hoveredComponentId: EntityId): boolean {
    return (
      isNotDefined(this.componentInlineInfo) || hoveredComponentId !== this.componentInlineInfo.id
    );
  }

  isComponentInInlineEditMode(componentId: EntityId): boolean {
    return isDefined(this.componentInlineInfo) && componentId === this.componentInlineInfo.id;
  }
}
