import moment from "moment";
import { KeyValue } from "../../core";
import { RUNTIME_FILTER_ID } from "../../core/helpers/filter/filter-id.helper";
import { Filter } from "../../core/models/filter/filter";
import { FilterConfigurationDto } from "../../core/models/filter/filter-configuration";
import { ConnectorMetadataProperty, DataConnectorDto, DataPoint } from "../../data-connectivity";
import { Dictionary, isDefined, isEmptyOrNotDefined, isNotDefined, Maybe } from "../../ts-utils";
import { CardButtonParams } from "../components/basic-card/card-button-params";
import { ComponentStateViewModel } from "../models";
import { ExportFormat } from "../models/export-format";

export interface ExportParams {
  cardComponent: CardButtonParams;
  outputFormat: ExportFormat;
}

interface ComponentExportInfo {
  title: string;
  dataSeries: ConnectorExportInfo[];
}

interface ConnectorExportInfo {
  title: string;
  metadata: Dictionary<ConnectorMetadataProperty>;
  data: DataPoint[];
}

enum DataType {
  Meta = "Meta",
  Series = "Series"
}

export function exportData(params: ExportParams): void {
  const cardViewModel: ComponentStateViewModel = params.cardComponent.vmConverter.convert(
    params.cardComponent.currentState
  );
  const fileName: string = getFileName(params.cardComponent);
  const outputData: ComponentExportInfo[] = getChildrenData(cardViewModel);
  switch (params.outputFormat) {
    case ExportFormat.JSON:
      exportToJson(outputData, fileName);
      break;
    case ExportFormat.CSV:
      exportToCsv(outputData, fileName);
      break;
    case ExportFormat.XLSX:
      exportToXlsx(outputData, fileName);
      break;
  }
}

function getFileName(cardComponent: CardButtonParams): string {
  const globalFilterConfig: FilterConfigurationDto =
    cardComponent.filterSelector.getById(RUNTIME_FILTER_ID);
  const globalFilter: Maybe<Filter> =
    cardComponent.filterFactory.createFilterFromConfiguration(globalFilterConfig);
  if (isNotDefined(globalFilter)) {
    throw new Error("Global filter not found.");
  }
  const globalPeriodType = cardComponent.runtimeSettingsSelector.getPeriodType();

  const separator: string = "_";
  const cardName: string = cardComponent.currentState.view.title ?? cardComponent.id.toString();
  const pType: Maybe<string> = isEmptyOrNotDefined(globalPeriodType) ? null : globalPeriodType;
  const startDate: string = moment(globalFilter.timeRange.from).format("YYMMDDHHmmss");
  const endDate: string = moment(globalFilter.timeRange.to).format("YYMMDDHHmmss");

  let fileName: string = cardName;
  if (isDefined(pType)) {
    fileName = fileName.concat(separator, pType);
  }
  fileName = fileName.concat(separator, startDate, separator, endDate);
  return fileName;
}

function getChildrenData(card: ComponentStateViewModel): ComponentExportInfo[] {
  const childrenData: ComponentExportInfo[] = card.allDescendants.map(
    (childComponent: ComponentStateViewModel) => ({
      title: childComponent.view.title ?? "",
      dataSeries: getConnectorsData(childComponent)
    })
  );
  return childrenData;
}

function getConnectorsData(component: ComponentStateViewModel): ConnectorExportInfo[] {
  const connectorsData: ConnectorExportInfo[] = component.dataConnectors.map(
    (connector: DataConnectorDto) => ({
      title: connector.title,
      metadata: connector.properties ?? {},
      data: connector.dataPoints ?? []
    })
  );
  return connectorsData;
}

function exportToJson(outputData: ComponentExportInfo[], outputFileName: string): void {
  const jsonContent: string = createJsonContent(outputData);
  createFile(jsonContent, "text/json", outputFileName + ".json");
}

function createJsonContent(componentData: ComponentExportInfo[]): string {
  return JSON.stringify(componentData);
}

function exportToCsv(outputData: ComponentExportInfo[], outputFileName: string): void {
  const csvContent: string = createCsvContent(outputData);
  createFile(csvContent, "text/csv", outputFileName + ".csv");
}

function createCsvContent(componentData: ComponentExportInfo[]): string {
  const dataMatrix: string[][] = createDataMatrix(componentData);
  const csvString: string = createCsvString(dataMatrix);
  return csvString;
}

function createDataMatrix(componentData: ComponentExportInfo[]): string[][] {
  const connectorData: ConnectorExportInfo[] = extractConnectorData(componentData);

  const initialMatrix: string[][] = initMatrix(connectorData);
  const metadataMatrix: string[][] = addDataToMatrix(DataType.Meta, connectorData, initialMatrix);
  const fullMatrix: string[][] = addDataToMatrix(DataType.Series, connectorData, metadataMatrix);
  return fullMatrix;
}

function extractConnectorData(componentData: ComponentExportInfo[]): ConnectorExportInfo[] {
  const connectorData = componentData.reduce(
    (acc: ConnectorExportInfo[], componentInfo: ComponentExportInfo) => {
      acc = acc.concat(componentInfo.dataSeries);
      return acc;
    },
    []
  );
  return connectorData;
}

function initMatrix(connectorData: ConnectorExportInfo[]): string[][] {
  const headerLabels: string[] = connectorData.map((connectorInfo) => connectorInfo.title);
  const headerRow: string[] = [""].concat(headerLabels);
  const matrix: string[][] = [headerRow];
  return matrix;
}

function addDataToMatrix(
  dataType: DataType,
  connectorData: ConnectorExportInfo[],
  oldMatrix: string[][]
): string[][] {
  const newMatrix: string[][] = connectorData.reduce(
    (acc: string[][], connectorInfo: ConnectorExportInfo, connectorIndex: number) => {
      const dataEntries: KeyValue<string, ConnectorMetadataProperty>[] = getDataEntries(
        dataType,
        connectorInfo
      );
      const columnIndex: number = connectorIndex + 1;
      const numberOfColumns: number = acc[0].length;
      populateColumn(dataEntries, acc, columnIndex, numberOfColumns);
      return acc;
    },
    oldMatrix
  );
  return newMatrix;
}

function getDataEntries(
  dataType: DataType,
  connectorInfo: ConnectorExportInfo
): KeyValue<string, ConnectorMetadataProperty>[] {
  if (dataType === DataType.Meta) {
    return getMetadataEntries(connectorInfo);
  } else {
    return getSeriesDataEntries(connectorInfo);
  }
}

function getMetadataEntries(
  connectorInfo: ConnectorExportInfo
): KeyValue<string, ConnectorMetadataProperty>[] {
  return Object.entries(connectorInfo.metadata).map(([key, value]) => ({ key, value }));
}

function getSeriesDataEntries(connectorInfo: ConnectorExportInfo): KeyValue<string, string>[] {
  return connectorInfo.data.map((dataPoint: DataPoint) => {
    const key = dataPoint.x instanceof Date ? moment(dataPoint.x).format() : "";
    const value = dataPoint.y;
    return { key, value };
  });
}

function populateColumn(
  dataEntries: KeyValue<string, ConnectorMetadataProperty>[],
  oldMatrix: ConnectorMetadataProperty[][],
  columnIndex: number,
  numberOfColumns: number
): ConnectorMetadataProperty[][] {
  const newMatrix = dataEntries.reduce(
    (acc: ConnectorMetadataProperty[][], entry: KeyValue<string, ConnectorMetadataProperty>) => {
      const rowIndex: number =
        entry.key !== ""
          ? acc.findIndex((row: ConnectorMetadataProperty[]) => row[0] === entry.key)
          : -1;
      if (rowExists(rowIndex)) {
        acc[rowIndex][columnIndex] = entry.value;
      } else {
        const newRow: ConnectorMetadataProperty[] = new Array(numberOfColumns).fill("");
        newRow[0] = entry.key;
        newRow[columnIndex] = entry.value;
        acc.push(newRow);
      }
      return acc;
    },
    oldMatrix
  );
  return newMatrix;
}

function rowExists(rowIndex: number): boolean {
  return rowIndex > -1;
}

function createCsvString(dataMatrix: string[][]): string {
  return dataMatrix.map((row: string[]) => row.join(",")).join("\r\n");
}

function exportToXlsx(outputData: ComponentExportInfo[], outputFileName: string): void {
  // TODO Implement logic using library
}

function createFile(fileContent: any, contentType: string, fileName: string): void {
  const anchor = document.createElement("a");
  const file = new Blob([fileContent], { type: contentType });
  anchor.href = URL.createObjectURL(file);
  anchor.download = fileName;
  anchor.click();
}
