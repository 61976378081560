import { Injectable } from "@angular/core";
import { DateFormatterService } from "../../environment";
import { ValidationContext } from "../../meta/models/validation-context";
import { isDate, isDefined, isNumber, isString, isWhiteSpace, Maybe } from "../../ts-utils";
import {
  formatNumberByFormatString,
  formatNumberDefault,
  isValidNumberFormatString
} from "./number-formatter";

const HTML_REGEX = new RegExp(/<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/);

@Injectable({
  providedIn: "root"
})
export class ValueFormatterService {
  constructor(protected dateFormatter: DateFormatterService) {}

  public formatValue(value: number | Date | string, format: Maybe<string>): string {
    if (isNumber(value)) {
      return formatValueByStringFormat(value, format);
    } else if (isDate(value) || !this.dateFormatter.isDateInvalid(value)) {
      return this.dateFormatter.formatDate(value, format);
    } else {
      return value;
    }
  }
}

// todo: rename formatNumberByStringFormat
export function formatValueByStringFormat(value: number, format: Maybe<string>): string {
  if (isDefined(format) && !isWhiteSpace(format)) {
    return formatNumberByFormatString(value, format);
  }
  const res = formatNumberDefault(value);
  return res;
}

export function validateValueFormat(
  format: unknown,
  _validationContext?: ValidationContext
): boolean {
  if (isString(format)) {
    return isValidNumberFormatString(format);
  }
  return false;
}

export function isValueHTML(value: string): boolean {
  return HTML_REGEX.test(value);
}
