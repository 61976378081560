import { Injectable } from "@angular/core";
import { getBasicCards } from "../helpers/v0.helper";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class RemovePositionStatic implements UpgradeStep {
  name = "RemovePositionStatic";
  fromVersion = new Version(0, 0, 3);

  perform(oldConfig: any): UpgradeStepResult {
    const cards: any[] = getBasicCards(oldConfig);
    let changed = false;
    cards.forEach((card) => {
      const css = card["view"]["css"];
      const position = css["position"];
      if (position === "static") {
        css.position = undefined;
        changed = true;
      }
    });

    return {
      result: oldConfig,
      modified: changed,
      warning: null
    };
  }
}
