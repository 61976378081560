import { createSelector } from "@ngrx/store";
import { isEmpty, isEmptyOrNotDefined, isNotDefined, Maybe } from "../../../ts-utils";
import { ApiDataSourceDescriptor } from "../../models";
import { selectConnectivityFeature } from "../feature.selector";
import { ConnectivityState } from "../state";

export const selectApiDescriptors = createSelector(
  selectConnectivityFeature,
  (connectivity: ConnectivityState) => connectivity.allApiDataSourceDescriptors
);

export const selectUrls = createSelector(
  selectApiDescriptors,
  (descriptors: ApiDataSourceDescriptor[]) => {
    if (!isEmptyOrNotDefined(descriptors)) {
      return descriptors.map((descriptor) => descriptor.url);
    }
  }
);

export const selectApiDescriptorByUrl = (url: string) =>
  createSelector(selectApiDescriptors, (descriptors: ApiDataSourceDescriptor[]) => {
    if (!isEmptyOrNotDefined(descriptors) && !isEmpty(url)) {
      const apiDescriptor: Maybe<ApiDataSourceDescriptor> = descriptors.find(
        (descriptor: ApiDataSourceDescriptor) => descriptor.url === url
      );
      if (isNotDefined(apiDescriptor)) {
        console.warn(`API data source with url ${url} not found`);
      } else {
        return apiDescriptor;
      }
    }
  });

export const selectUrlParameters = (url: string) =>
  createSelector(
    selectApiDescriptorByUrl(url),
    (descriptor: ApiDataSourceDescriptor) => descriptor?.queryParams
  );
