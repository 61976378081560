import { EntityId } from "../../meta/models/entity";
import { BaseViewConfigDto } from "./base-view-config";

export const CSS_INLINE_EDIT_COMPONENT = "component--inline-mode";
export const CSS_INLINE_EDIT_INPUT = "text-input--inline-mode";
export const CSS_INLINE_EDIT_ICON = "icon--inline-mode";
export const DELETE_ICON = "Trash";
export const EDIT_ICON = "Edit";

export interface InlineComponentParams {
  id: EntityId;
  viewConfig: BaseViewConfigDto;
  hostElement: HTMLElement;
}
