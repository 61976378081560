import {
  ApiDataSourceDto,
  DataConnectorDto,
  DataSourceDto,
  EquipmentDataSourceDto,
  GenericDataSourceDto,
  GroupedDataSourceDto,
  TabularDataSourceDto
} from "../../data-connectivity";
import {
  isApi,
  isEquipment,
  isEquipmentPartial,
  isGeneric,
  isGenericPartial,
  isGrouped,
  isTabular
} from "../../data-connectivity/helpers/data-source-type.helper";
import { DeepPartial, isEmpty, isEmptyOrNotDefined } from "../../ts-utils";

export function equipmentDcqNeedsUpdate(
  dataSourceUpdate: DeepPartial<DataSourceDto>,
  updatedDataSource: DataSourceDto
): boolean {
  return (
    isEquipmentPartial(dataSourceUpdate) &&
    isEquipment(updatedDataSource) &&
    equipmentDcqNeedsResolution(dataSourceUpdate, updatedDataSource)
  );
}

export function equipmentDcqNeedsResolution(
  dataSourceUpdate: DeepPartial<EquipmentDataSourceDto>,
  updatedDataSource: EquipmentDataSourceDto
): boolean {
  return !isEmpty(dataSourceUpdate) && isValidEquipmentQuery(updatedDataSource);
}

export function isValidEquipmentQuery(updatedDataSource: EquipmentDataSourceDto): boolean {
  const pathExists = !isEmptyOrNotDefined(updatedDataSource.path);
  return pathExists;
}

export function genericOrApiDcqNeedsUpdate(
  dataSourceUpdate: DeepPartial<DataSourceDto>,
  updatedDataSource: DataSourceDto
): boolean {
  return (
    genericDcqNeedsUpdate(dataSourceUpdate, updatedDataSource) ||
    apiDcqNeedsUpdate(dataSourceUpdate, updatedDataSource)
  );
}

function genericDcqNeedsUpdate(
  dataSourceUpdate: DeepPartial<DataSourceDto>,
  updatedDataSource: DataSourceDto
): boolean {
  return (
    isGenericPartial(dataSourceUpdate) &&
    isGeneric(updatedDataSource) &&
    genericDcqNeedsResolution(dataSourceUpdate, updatedDataSource)
  );
}

export function apiDcqNeedsUpdate(
  dataSourceUpdate: DeepPartial<DataSourceDto>,
  updatedDataSource: DataSourceDto
): boolean {
  return isApi(updatedDataSource) && apiDcqNeedsResolution(dataSourceUpdate, updatedDataSource);
}

export function genericDcqNeedsResolution(
  dataSourceUpdate: DeepPartial<GenericDataSourceDto>,
  updatedDataSource: GenericDataSourceDto
): boolean {
  return !isEmpty(dataSourceUpdate) && isValidGenericQuery(updatedDataSource);
}

export function isValidGenericQuery(updatedDataSource: GenericDataSourceDto): boolean {
  if (isTabular(updatedDataSource)) {
    return isValidTabularQuery(updatedDataSource);
  }
  if (isGrouped(updatedDataSource)) {
    return isValidGroupedQuery(updatedDataSource);
  }
  return true;
}

function isValidTabularQuery(updatedDataSource: TabularDataSourceDto): boolean {
  const tableOrViewExists = !isEmptyOrNotDefined(updatedDataSource.entity);
  const columnsExist = !isEmptyOrNotDefined(updatedDataSource.columns);

  return tableOrViewExists && columnsExist;
}

function isValidGroupedQuery(updatedDataSource: GroupedDataSourceDto): boolean {
  const tableOrViewExists = !isEmptyOrNotDefined(updatedDataSource.entity);
  const groupingExists = !isEmptyOrNotDefined(updatedDataSource.groupBy1);

  return tableOrViewExists && groupingExists;
}

export function apiDcqNeedsResolution(
  dataSourceUpdate: DeepPartial<DataSourceDto>,
  updatedDataSource: ApiDataSourceDto
): boolean {
  return !isEmpty(dataSourceUpdate) && isValidApiQuery(updatedDataSource);
}

export function isValidApiQuery(updatedDataSource: ApiDataSourceDto): boolean {
  return !isEmptyOrNotDefined(updatedDataSource.url);
}

export function dynamicConnectorsNeedCleanup(dynamicConnectors: DataConnectorDto[]): boolean {
  return dynamicConnectors.length > 0;
}
