import { clone as _clone } from "lodash";
import { mergeByKey } from "./array.helper";
import { isEmptyOrNotDefined } from "./is-empty.helper";
import { isArray, isDefined, isFunction, isNotDefined, isUndefined } from "./predicates.helper";

// FIXME assignDeep returns T1 but it actually merges T1 and T2
export function assignDeep<T1, T2>(target: T1, source: T2): T1 {
  return assignOrMergeDeep(
    target,
    source,
    (sourceValue, _targetValue) => isUndefined(sourceValue) || isFunction(sourceValue),
    (sourceValue, _targetValue) => sourceValue
  );
}

/** Does not merge function properties */
export function mergeDeep<T1, T2>(target: T1, source: T2, mergeArrayProps: boolean = true): T1 {
  return assignOrMergeDeep(
    target,
    source,
    (sourceValue, targetValue) =>
      isNotDefined(sourceValue) ||
      isFunction(sourceValue) ||
      sourceArrayIsEmptyWhileTargetIsDefined(sourceValue, targetValue),
    (sourceValue, targetValue) =>
      mergeArrayProps ? mergeByKey(targetValue, sourceValue) : sourceValue
  );
}

function assignOrMergeDeep<T1, T2>(
  target: T1,
  source: T2,
  shouldNotMerge: (sourceValue: unknown, targetValue: unknown) => boolean,
  mergeArrays: (sourceValue: unknown[], targetValue: unknown[]) => any[]
): T1 {
  target = _clone(target);
  source = _clone(source);
  if (isNotDefined(target) || isNotDefined(source)) {
    return target;
  }
  Object.entries(source).forEach(([key, sourceValue]) => {
    const targetValue = target[key];
    if (shouldNotMerge(sourceValue, targetValue)) {
      return;
    }
    if (propIsObjectAndTargetIsDefined(sourceValue, targetValue)) {
      target[key] = assignOrMergeDeep(targetValue, sourceValue, shouldNotMerge, mergeArrays);
    } else if (isArray(sourceValue) && isArray(targetValue)) {
      target[key] = mergeArrays(sourceValue, targetValue);
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
}

// Difference between assignDeep and mergeDeep:
// if source has a null, empty array or empty string property, assign will overwrite it from target, while merge will not

function propIsObjectAndTargetIsDefined(sourceProp: any, targetProp: any): boolean {
  return (
    sourceProp instanceof Object &&
    !(sourceProp instanceof Date) &&
    !isArray(sourceProp) &&
    isDefined(targetProp)
  );
}

function sourceArrayIsEmptyWhileTargetIsDefined(sourceProp: any, targetProp: any): boolean {
  return isArray(sourceProp) && isEmptyOrNotDefined(sourceProp) && isDefined(targetProp);
}

// IP create mergeDeep version in meta module using type descriptor and checking all props
