import { animate, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { Actions, ofType } from "@ngrx/effects";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { EquipmentSelector } from "../../../browsing/services/equipment.selector";
import { DataExplorerActions } from "../../../browsing/store/data-explorer";
import { EquipmentClassNames, GeneralSettingsDto } from "../../../core";
import {
  checkIfEquipmentHasClass,
  onRootPathChange
} from "../../../core/helpers/root-class.helper";
import { Equipment } from "../../../core/models/equipment";
import { DataService } from "../../../data-connectivity/services/data.service";
import { Dispatcher } from "../../../dispatcher";
import { EnvironmentSelector } from "../../../environment/services/environment.selector";
import { LocalizationService } from "../../../i18n/localization.service";
import { EquipmentDialogInfo } from "../../../property-sheet/models/equipment-dialog-info";
import { EquipmentBrowserDialogActions } from "../../../shared/dialogs/actions/equipment-browser-dialog.actions";
import { isEmpty } from "../../../ts-utils/helpers/is-empty.helper";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { GeneralSettingsSelector } from "../../services/entity-selectors/general-settings.selector";
import { GeneralSettingsActions } from "../../store/general-settings/general-settings.actions";

const DEFAULT_ROOT_CLASS = "Any";

@Component({
  selector: "equipment-data-explorer",
  templateUrl: "equipment-data-explorer.component.html",
  styleUrls: ["./equipment-data-explorer.component.scss"],
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [style({ opacity: 0 }), animate("300ms", style({ opacity: 1 }))]),
      transition(":leave", [style({ opacity: 1 }), animate("400ms", style({ opacity: 0 }))])
    ])
  ]
})
export class EquipmentDataExplorerComponent implements OnInit, OnDestroy {
  public rootPath: string = "";
  public isRootPathInvalid: boolean = false;
  public equipmentClasses: string[];
  public rootClass: string;
  public fullEquipmentTree: Equipment;
  public currentRootNode: Equipment;
  public rootClassChanged$: Subject<string> = new Subject<string>();
  private unsubscribeSubject$: Subject<any> = new Subject<any>();
  aliasMode: boolean = false;
  @Output() collapseSidebar: EventEmitter<any> = new EventEmitter();

  constructor(
    private actions$: Actions,
    private dispatcher: Dispatcher,
    private dataService: DataService,
    public localizer: LocalizationService,
    protected equipmentSelector: EquipmentSelector,
    private generalSettingsSelector: GeneralSettingsSelector,
    private environmentSelector: EnvironmentSelector
  ) {}

  ngOnInit(): void {
    this.getRootPathAndClass();
    this.getAliasMode();
    this.subscribeToEquipmentClasses();
    this.subscribeToEquipmentTree();
    this.subscribeToRootClass();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  getRootPathAndClass(): void {
    const generalSettings: GeneralSettingsDto = this.generalSettingsSelector.getGeneralSettings();
    this.rootPath = generalSettings.rootPath;
    this.rootClass = isEmpty(generalSettings.rootClass)
      ? DEFAULT_ROOT_CLASS
      : generalSettings.rootClass;
  }

  private getAliasMode(): void {
    this.environmentSelector
      .selectAliasMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((isAliasMode: boolean) => (this.aliasMode = isAliasMode));
  }

  private subscribeToEquipmentClasses(): void {
    this.dataService
      .getAllEquipmentClasses()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((newEquipmentClasses: EquipmentClassNames[]) => {
        const equipmentClasses: string[] = newEquipmentClasses.map((equipmentClass) =>
          this.aliasMode && isDefined(equipmentClass.aliasClassName)
            ? equipmentClass.aliasClassName
            : equipmentClass.className
        );
        this.equipmentClasses = [DEFAULT_ROOT_CLASS, ...equipmentClasses];
      });
  }

  private subscribeToEquipmentTree(): void {
    this.equipmentSelector
      .selectEquipmentTree()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((fullEquipmentTree) => {
        if (isDefined(fullEquipmentTree)) {
          this.fullEquipmentTree = fullEquipmentTree;
          this.currentRootNode = onRootPathChange(this.rootPath, this.fullEquipmentTree);
        }
      });
  }

  private subscribeToRootClass(): void {
    this.rootClassChanged$
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((rootClass: string) => {
        this.rootClass = rootClass === "" ? DEFAULT_ROOT_CLASS : rootClass;
        this.isRootPathInvalid = checkIfInvalidRootPath(
          this.currentRootNode,
          rootClass,
          this.aliasMode
        );

        if (!this.isRootPathInvalid) {
          this.dispatcher.dispatch(
            GeneralSettingsActions.setRootClass({
              rootClass: this.rootClass
            })
          );
        }

        this.dispatcher.dispatch(DataExplorerActions.unselectEquipment());
      });
  }

  editRootPath(): void {
    this.subscribeToEquipmentDialogClose();
    const equipmentDialogInfo: EquipmentDialogInfo = {
      currentPath: "",
      usePathBrowser: false,
      rootClass: this.rootClass,
      openFullTree: true
    };
    this.dispatcher.dispatch(
      EquipmentBrowserDialogActions.openEquipmentBrowserDialog({
        equipmentDialogInfo
      })
    );
  }

  private subscribeToEquipmentDialogClose(): void {
    this.actions$
      .pipe(ofType(EquipmentBrowserDialogActions.onEquipmentBrowserDialogClosed), take(1))
      .subscribe(({ result: selectedRootPath }) => {
        if (isDefined(selectedRootPath) && this.rootPath !== selectedRootPath) {
          this.rootPath = selectedRootPath === this.fullEquipmentTree.path ? "/" : selectedRootPath;
          this.currentRootNode = onRootPathChange(this.rootPath, this.fullEquipmentTree);
          this.updateRootPath();
          this.isRootPathInvalid = false;
          this.dispatcher.dispatch(DataExplorerActions.unselectEquipment());
        }
      });
  }

  collapseUnpinnedSidebarOnDragging(): void {
    this.collapseSidebar.emit();
  }

  public resetRootPath(): void {
    this.dispatcher.dispatch(DataExplorerActions.collapsePropertyBrowser());
    this.rootPath = "";
    this.currentRootNode = onRootPathChange(this.rootPath, this.fullEquipmentTree);
    this.isRootPathInvalid = checkIfInvalidRootPath(
      this.currentRootNode,
      this.rootClass,
      this.aliasMode
    );
    if (!this.isRootPathInvalid) {
      this.updateRootPath();
    }
  }

  updateRootPath(): void {
    this.dispatcher.dispatch(
      GeneralSettingsActions.setRootPath({
        rootPath: this.rootPath
      })
    );
  }

  selectedEquipmentChanged(equipment: Equipment): void {
    this.dispatcher.dispatch(DataExplorerActions.selectEquipment({ equipment }));
  }
}

function checkIfInvalidRootPath(
  rootNode: Equipment,
  rootClass: string,
  aliasMode: boolean = false
): boolean {
  return rootClass === DEFAULT_ROOT_CLASS || !isDefined(rootNode)
    ? false
    : !checkIfEquipmentHasClass(rootNode, rootClass, aliasMode);
}
