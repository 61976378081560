import { Injectable } from "@angular/core";
import { isNullOrEmpty } from "projects/localization-util/src/util";
import { QueryStringService, UrlParams } from "ui-core";
import {
  FLEET_OVERVIEW_REPORT_ID,
  FLEET_VIEW_REPORT_ID
} from "../../models/constants/predefined-reports";
import { PmgUrlParams } from "../../models/pmg-url-params";

@Injectable({
  providedIn: "root"
})
export class AzureQueryStringService extends QueryStringService {
  public getPlantName(): string {
    const pathChunks = this.getRootPathChunks();
    return pathChunks[0] || "";
  }

  public getProductionAreaName(): string {
    const pathChunks = this.getRootPathChunks();
    if (pathChunks.length > 1) {
      return pathChunks[1];
    }
    return this.getParams().get(PmgUrlParams.productionArea) || "";
  }

  public getMotorName(): string {
    const pathChunks = this.getRootPathChunks();
    return pathChunks[2] || "";
  }

  public getSignalRInstanceName(): string {
    const plant = this.getPlantName();
    const motor = this.getMotorName();
    return `${plant}.${motor}`;
  }

  public resolveUrl(reportId: string): string {
    const isPredifinedReport =
      reportId === FLEET_VIEW_REPORT_ID || reportId === FLEET_OVERVIEW_REPORT_ID;

    const customer = isPredifinedReport
      ? "all"
      : this.getParams().get(UrlParams.rootPath)?.split("/")[0];

    let reportUrl = customer ? `/${customer}/reports/${reportId}` : `/${reportId}`;

    const queryParams = new URLSearchParams();

    this.appendRootPath(queryParams);

    if (isPredifinedReport) {
      this.appendAdditionalParameters(queryParams);
    }

    const query = queryParams.toString();
    if (!isNullOrEmpty(query)) {
      reportUrl = `${reportUrl}?${query}`;
    }

    return reportUrl;
  }

  private appendRootPath(queryParams: URLSearchParams): void {
    const rootPath = this.getRootPath();
    if (rootPath !== "") {
      queryParams.append(UrlParams.rootPath, rootPath);
    }
  }

  private appendAdditionalParameters(queryParams: URLSearchParams): void {
    const productionArea = this.getProductionAreaName();
    if (productionArea !== "") {
      queryParams.append(PmgUrlParams.productionArea, productionArea);
    }
  }

  private getRootPathChunks(): string[] {
    return this.getRootPath().split("/");
  }
}
