import { Injectable } from "@angular/core";
import { ComponentCssSize, ComponentStateDto, ComponentStyleDto } from "../../../elements";
import { BasicCardViewConfig } from "../../../elements/components/basic-card/view-config";
import { PageViewConfig } from "../../../elements/components/page/view-config";
import { PositioningType } from "../../../elements/models/positioning-type";
import { ReportConfiguration } from "../../../elements/models/report-configuration";
import { Dictionary } from "../../../ts-utils/models/dictionary.type";
import { IDynamicReportService } from "../../interfaces/dynamic-report-service.interface";
import {
  FilterConfigurationDto,
  GeneralSettingsDto,
  ReportId,
  TimeRangeConfigurationDto
} from "../../models";
import { DYNAMIC_REPORT_CANVAS } from "../../models/dynamic-report/dynamic-report.constants";

@Injectable({
  providedIn: "root"
})
export class DynamicReportCanvasService implements IDynamicReportService {
  reportName: string = DYNAMIC_REPORT_CANVAS;

  generateReport(): ReportConfiguration {
    return this.getQueryReportTemplate();
  }

  public getQueryReportTemplate(): ReportConfiguration {
    return {
      id: DYNAMIC_REPORT_CANVAS as ReportId,
      content: {
        componentStates: {
          ids: ["Root", "basic-card-736"],
          entities: {
            Root: {
              id: "Root",
              view: {
                size: {
                  width: "100%",
                  height: "100%"
                } as Partial<ComponentCssSize>,
                snapToGrid: false,
                hideBackground: true
              } as PageViewConfig,
              type: "PageComponent",
              childrenIds: ["basic-card-736"],
              filterId: "Global"
            } as unknown as ComponentStateDto,
            "basic-card-736": {
              id: "basic-card-736",
              view: {
                title: "Basic Card",
                size: {
                  width: "99.73%",
                  height: "99.83%"
                } as Partial<ComponentCssSize>,
                expandedSize: {
                  width: "99.73%",
                  height: "99.83%"
                },
                showHeader: false,
                css: {
                  order: "0",
                  zIndex: "1"
                } as Partial<ComponentStyleDto>,
                titleFormat: "Truncate",
                positioningType: PositioningType.Relative,
                noBoxShadow: true,
                resizable: false
              } as BasicCardViewConfig,
              type: "BasicCardComponent"
            } as unknown as ComponentStateDto
          }
        },
        dataConnectors: {
          ids: [],
          entities: {}
        },
        filters: {
          ids: ["Global"],
          entities: {
            Global: {
              id: "Global",
              timeRange: {
                fromExpression: "Current-2hours"
              } as TimeRangeConfigurationDto,
              customFilters: {
                material: "",
                location: ""
              }
            } as Partial<FilterConfigurationDto>
          } as Dictionary<FilterConfigurationDto>
        },
        dataConnectorViews: {
          ids: [],
          entities: {}
        },
        componentsCounter: 756,
        generalSettings: {
          rootPath: "/Cement/Company 2/Europe/Untervaz/RM1",
          rootClass: "Any"
        } as GeneralSettingsDto,
        version: [4, 0, 12]
      }
    };
  }
}
