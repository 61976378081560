<div class="fleet-list__container">
  <div class="fleet-list__column-container">
    <mat-table
      *ngIf="dataSource"
      [dataSource]="dataSource"
      class="fleet-mat-table"
    >
      <ng-container
        *ngFor="let column of displayedColumns"
        matColumnDef="{{ column }}"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          class="column-header-title"
        >
          {{ column | capitalize | separateWords }}
        </th>
        <td mat-cell *matCellDef="let row" class="mat-column-position">
          <div [ngSwitch]="column">
            <div
              *ngSwitchCase="'status'"
              class="status_block"
            >
              <div class="text_block">
                <div class="circle status_indicator" [ngClass]="row.markerColor"></div>
                <div>{{ row.status }}</div>
              </div>
            </div>
            <div *ngSwitchCase="'lastUpdateTime'">
              {{ row.lastUpdateTime | timeStampFormatter }}
            </div>
            <div *ngSwitchDefault>
              {{ row[column] }}
            </div> 
          </div>
        </td>
      </ng-container>

      <!-- Header with inputs -->
      <ng-container
        *ngFor="let column of displayedColumns"
        matColumnDef="{{ column }}-filter"
      >
        <th mat-header-cell *matHeaderCellDef class="mat-header-filter">
          <select
            name="{{ column }}"
            (change)="updateFilters($event.target.value, $event.target.name)"
          >
            <option *ngFor="let c of getKeys(column)">{{ c }}</option>
          </select>
        </th>
      </ng-container>
      <!-- -->

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"
      ></tr>
      <tr
        mat-header-row
        *matHeaderRowDef="displayedFilterColumns"
        class="example-second-header-row"
      ></tr>

      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </mat-table>
  </div>
</div>
