import { Injectable } from "@angular/core";

export enum OverridableParamKeys {
  PeriodType = "periodType",
  TimeAggregation = "timeAggregation",
  MaterialAggregation = "materialAggregation",
  LocationAggregation = "locationAggregation",
  ArrayAggregation = "arrayAggregation",
  CutOffStrategy = "cutOffStrategy"
}

@Injectable()
export class QueryParamKeyConverter {
  convert(_param: string): string {
    return "";
  }
}
