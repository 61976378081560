<div class="downtime-analysis__chart-container chart-container">
  <rds-downtime-comparison-chart [id]="children[0].id"> </rds-downtime-comparison-chart>
</div>
<div class="downtime-analysis__pie-charts-container">
  <div class="downtime-analysis__column-container">
    <div class="downtime-analysis__column">
      <c-category [id]="children[1].id"></c-category>
    </div>
    <div class="downtime-analysis__column">
      <c-category [id]="children[2].id"></c-category>
    </div>
  </div>
</div>
