import { Injectable } from "@angular/core";
import { isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { getBasicCards } from "../helpers/v0.helper";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class UpgradeFullyCardSize implements UpgradeStep {
  name = "UpgradeFullyCardSize";
  fromVersion = new Version(4, 0, 11);

  perform(oldConfig: any): UpgradeStepResult {
    const cards = getBasicCards(oldConfig);
    let changed = false;
    cards.forEach((card) => {
      const view = card["view"];
      const { size, expandedSize, collapsedSize, expanded } = view;
      if (isDefined(size) && isNotDefined(expandedSize) && isNotDefined(collapsedSize)) {
        if (isNotDefined(expanded) || expanded) {
          view["expandedSize"] = view["size"];
        } else {
          view["collapsedSize"] = view["size"];
        }
        changed = true;
      }
    });
    return {
      result: oldConfig,
      modified: changed,
      warning: null
    };
  }
}
