import { construct } from "../../../core/services/construct.helper";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { ConfigurableArray } from "../../../meta/decorators/configurable-array.decorator";
import { ConfigurableEnum } from "../../../meta/decorators/configurable-enum.decorator";
import { Configurable } from "../../../meta/decorators/configurable.decorator";
import { ConfigurationCategory } from "../../../meta/decorators/configuration-category.decorator";
import { EditableType } from "../../../meta/decorators/editable-type.decorator";
import { NotSerializable } from "../../../meta/decorators/not-serializable.decorator";
import { OnPropertyChange } from "../../../meta/decorators/on-property-change.decorator";
import { Serializable } from "../../../meta/decorators/serializable.decorator";
import { CutOffStrategy } from "../../../meta/models/cut-off-strategy";
import { EditorSize } from "../../../meta/models/editor-size";
import { EditorType } from "../../../meta/models/editor-type";
import { PropertyCategory } from "../../../meta/models/property-category";
import { PropertyInfo } from "../../../meta/models/property-info";
import { DefaultValue } from "../../decorators/default-value.decorator";
import { BaseViewConfigDto } from "../../models/base-view-config";
import { ComponentCssSize } from "../../models/component-size";
import { ClusterDisplayStrategies } from "./cluster-display-strategies";

const TYPE_NAME = "ClusterChartViewConfig";
const GROUP_NAME = "Category";

// @dynamic
@EditableType({ fullName: TYPE_NAME })
export class ClusterChartViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  @ConfigurableArray({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.GroupNames,
    arrayEditorSize: EditorSize.Medium,
    typeConstructor: String,
    editorType: EditorType.Array,
    arrayItemEditorType: EditorType.TextBox,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.GroupNamesTooltip
  })
  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.GroupNames,
    12
  )
  @Serializable([], String)
  groupNames!: string[];

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.XaxisTitle,
    editorType: EditorType.TextBox
  })
  @Serializable()
  xAxisTitle!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.YaxisTitle,
    editorType: EditorType.TextBox
  })
  @Serializable()
  yAxisTitle!: string;

  @OnPropertyChange<any, Partial<ClusterChartViewConfig>, ClusterDisplayStrategies>(
    onChangeDisplayStrategy
  )
  @ConfigurableEnum({
    enumSource: ClusterDisplayStrategies,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Display
  })
  @Serializable(ClusterDisplayStrategies.ClusteredBarChart)
  displayStrategy!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Stack,
    editorType: EditorType.CheckBox
  })
  @Serializable(false)
  stacked!: boolean;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.FontColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("")
  textColor!: string;

  @ConfigurationCategory(
    PropertyCategory.Data,
    LOCALIZATION_DICTIONARY.propertySheet.DataAggregation,
    11
  )
  @ConfigurableEnum({
    enumSource: CutOffStrategy,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.CutOffStrategy,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.CutOffStrategyTooltip
  })
  @Serializable(CutOffStrategy.Downsample)
  cutOffStrategy!: CutOffStrategy;

  @NotSerializable()
  @DefaultValue(true)
  enableGroups: boolean = true;

  constructor(viewConfigDto: Partial<ClusterChartViewConfig> = {}) {
    super();
    const defaultOverrides: Partial<ClusterChartViewConfig> = {
      size: getDefaultSize(),
      groupName: GROUP_NAME,
      title: " ",
      enableGroups:
        viewConfigDto.displayStrategy !== ClusterDisplayStrategies.ClusteredMultiPointBarChart
    };
    construct(this, viewConfigDto, TYPE_NAME, defaultOverrides);
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("550", "200");
}

function onChangeDisplayStrategy(
  context: any,
  owner: Partial<ClusterChartViewConfig>,
  propertyChange: PropertyInfo<ClusterDisplayStrategies>
): Partial<ClusterChartViewConfig> {
  return {
    enableGroups: propertyChange.value !== ClusterDisplayStrategies.ClusteredMultiPointBarChart,
    displayStrategy: propertyChange.value
  };
}
