import { EntityId } from "../../meta/models/entity";

export class ReportTreeNode {
  componentId: EntityId;
  name: string | number;
  children: ReportTreeNode[];
  reportId: number | string;

  constructor(componentId: EntityId, children: ReportTreeNode[], reportId: number | string) {
    this.componentId = componentId;
    this.name = componentId;
    this.children = children;
    this.reportId = reportId;
  }

  public addChild(child: ReportTreeNode): ReportTreeNode {
    this.children.push(child);
    return child;
  }
}
