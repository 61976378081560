import { construct } from "../../../core/services/construct.helper";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { Configurable, Serializable } from "../../../meta/decorators";
import { EditableType } from "../../../meta/decorators/editable-type.decorator";
import { EditorType } from "../../../meta/models";
import { SimpleViewConfig } from "../../models/simple-view-config";

const TYPE_NAME = "SimpleBoolIndicatorViewConfig";

@EditableType({ fullName: TYPE_NAME })
export class SimpleBoolIndicatorViewConfig extends SimpleViewConfig {
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.TrueColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#005c27")
  trueColor!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.FalseColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#ff0000")
  falseColor!: string;

  constructor() {
    super();
    construct(this, {}, TYPE_NAME);
  }
}
