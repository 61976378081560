import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { merge, Observable } from "rxjs";
import { initServerTime } from "../environment/store/time-info/time-info.effects";
import { IUserPreferencesService } from "./services/i-user-preferences.service";

@Injectable({ providedIn: "root" })
export class AppInitializationServices {
  constructor(public userPreferences: IUserPreferencesService) {}
}

export type OnInitBlocking = (
  store: Store<any>,
  services: AppInitializationServices
) => Observable<any>;

// based on https://medium.com/@remohy1/initialize-angular-app-with-ngrx-app-initializer-6556b819e0e3
// but it seems to work also without ngrxOnRunEffects
export function initApplication(store: Store<any>, services: AppInitializationServices): Function {
  const tasks: OnInitBlocking[] = [initServerTime];
  return () =>
    new Promise((resolve, reject) => {
      const waitFor$s: Observable<any>[] = tasks.map((initAction) => initAction(store, services));

      const combinedWaiter$ = merge(...waitFor$s);
      combinedWaiter$.subscribe(
        (x) => {
          // console.log(x);
        },
        (error) => reject(error),
        () => {
          // console.log("init complete");
          // store.dispatch(createAction("[Comment]: init complete")());
          resolve(true);
        }
      );
    });
}
