import { map, take } from "rxjs/operators";
import { FILTER_CONFIGURATION_DTO } from "../../../elements/models/entity-type.constants";
import { Entity } from "../../../meta/models/entity";
import { PropertyDescriptor } from "../../../meta/models/property-descriptor";
import { DecoratorDelegateContext } from "../../models/decorator-delegate-context";
import {
  CustomFilterDescriptorDto,
  createPropertyDescriptor
} from "../../models/filter/custom-filter-descriptor";
import { FilterConfigurationDto } from "../../models/filter/filter-configuration";
import { RUNTIME_FILTER_ID } from "./filter-id.helper";

export const CUSTOM_FILTER_PROPERTY_NAME = "customFilters";

export function expandCustomFilters(
  context: DecoratorDelegateContext,
  instance: Entity
): PropertyDescriptor[] {
  const customFilters$ = context.services.generalSettingsSelector
    .selectCustomFilterDescriptors()
    .pipe(map((customFilters: CustomFilterDescriptorDto[]) => customFilters));
  let customFilters: CustomFilterDescriptorDto[];
  customFilters$.pipe(take(1)).subscribe((cf: CustomFilterDescriptorDto[]) => (customFilters = cf));
  return customFilters.map((filterDesc: CustomFilterDescriptorDto) => {
    const customFilterDescriptor = resolveFilterAccessConfiguration(context, filterDesc);
    return createPropertyDescriptor(customFilterDescriptor, CUSTOM_FILTER_PROPERTY_NAME);
  });
}

function resolveFilterAccessConfiguration(
  context: DecoratorDelegateContext,
  filterDesc: CustomFilterDescriptorDto
): CustomFilterDescriptorDto {
  if (isTargetRuntimeFilter(context.ownerInstance)) {
    return filterDesc;
  }
  return {
    ...filterDesc,
    isHidden: false,
    isReadOnly: false
  };
}

function isTargetRuntimeFilter(ownerInstance: Entity): boolean {
  return (
    ownerInstance.typeName === FILTER_CONFIGURATION_DTO &&
    (ownerInstance as FilterConfigurationDto).id === RUNTIME_FILTER_ID
  );
}
