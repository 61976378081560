import { createAction, props } from "@ngrx/store";
import { ReportDescription } from "../../../core";

export namespace ReportIdsActions {
  export const fetchAll = createAction("[Report Ids] Fetch All");

  export const fetchAllSuccess = createAction(
    "[Report Ids] Fetch All Success",
    props<{ reportIds: ReportDescription[] }>()
  );

  export const fetchCached = createAction("[Report Ids] Fetch Cached");

  export const clear = createAction("[Report Ids] Clear");
}
