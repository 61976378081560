import { Injectable, Injector } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { REPORT_FILTER_ID } from "../../core/helpers/filter/filter-id.helper";
import { GeneralSettingsDto } from "../../core/models/general-settings";
import { COMPONENT_STATE_VIEW_MODEL } from "../../elements/models/entity-type.constants";
import { ReportEntities } from "../../elements/models/report-entities";
import { ComponentSelectionService } from "../../elements/services/component-selection.service";
import { ComponentStateSelector } from "../../elements/services/entity-selectors/component-state.selector";
import { DataConnectorViewSelector } from "../../elements/services/entity-selectors/data-connector-view.selector";
import { DataConnectorSelector } from "../../elements/services/entity-selectors/data-connector.selector";
import { FilterSelector } from "../../elements/services/entity-selectors/filter.selector";
import { GeneralSettingsSelector } from "../../elements/services/entity-selectors/general-settings.selector";
import { InlineEditService } from "../../elements/services/inline-edit.service";
import { CommonActions } from "../../elements/store/common/common.actions";
import { Entity, EntityId } from "../../meta/models/entity";
import { PropertySheetSnapshotState } from "../../meta/models/property-sheet-snapshot-state";
import { ReportSnapshot } from "../../meta/models/report-snapshot";
import { SnapshotContext } from "../../meta/models/snapshot-context";
import { UpdatedEntitiesInfo } from "../../meta/models/updated-entities-info";
import { PropertySheetService } from "../../property-sheet/services/property-sheet.service";
import { first } from "../../ts-utils/helpers/array.helper";
import { isEmptyOrNotDefined } from "../../ts-utils/helpers/is-empty.helper";
import { isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { IUndoRedoService } from "./i-undo-redo.service";

@Injectable({ providedIn: "root" })
export class UndoRedoService extends IUndoRedoService {
  private propertySheetService!: PropertySheetService;
  private inlineEditService!: InlineEditService;
  private snapshots: ReportSnapshot[] = [];
  private index: number = -1;
  isLocked: boolean = false;
  snapshotPointerChanged$: Subject<any> = new Subject<any>();
  widgetModified$: Subject<EntityId> = new Subject<EntityId>();
  propertySheetSnapshotStateChanged$: Subject<PropertySheetSnapshotState> =
    new Subject<PropertySheetSnapshotState>();
  editorPathChanged$: Subject<string[]> = new Subject<string[]>();

  constructor(
    private store$: Store<any>,
    private injector: Injector,
    private componentStateSelector: ComponentStateSelector,
    private dataConnectorSelector: DataConnectorSelector,
    private dataConnectorViewSelector: DataConnectorViewSelector,
    private filterSelector: FilterSelector,
    private generalSettingsSelector: GeneralSettingsSelector,
    private componentSelectionService: ComponentSelectionService
  ) {
    super();
  }

  createSnapshot(actionContext: SnapshotContext): void {
    this.index++;
    this.snapshots[this.index] = {
      context: actionContext,
      entities: this.createSnapshotFromCurrentState()
    };
    this.removeExcessActions();
    this.snapshotPointerChanged$.next();
  }

  private removeExcessActions(): void {
    if (this.snapshots.length > this.index + 1) {
      this.snapshots.length = this.index + 1;
    }
  }

  undo(): void {
    this.isLocked = true;
    this.captureLastStateIfNeeded();
    this.store$.dispatch(
      CommonActions.upsertFromSnapshot({ reportSnapshot: this.snapshots[this.index] })
    );
    this.tryScrollIntoModifiedWidgets();
    this.visualizeModification();
    this.index--;
    this.snapshotPointerChanged$.next();
    this.isLocked = false;
  }

  private captureLastStateIfNeeded(): void {
    if (this.index === this.snapshots.length - 1) {
      this.snapshots[this.index + 1] = {
        context: {
          isLastRedoableState: true
        },
        entities: this.createSnapshotFromCurrentState()
      };
    }
  }

  private createSnapshotFromCurrentState(): ReportEntities {
    return {
      componentStates: this.componentStateSelector.getAllAsArray(),
      dataConnectors: this.dataConnectorSelector.getAllAsArray(),
      dataConnectorViews: this.dataConnectorViewSelector.getAllAsArray(),
      filters: this.filterSelector.getAllAsArray(),
      generalSettings: this.getPartialGeneralSettings()
    };
  }

  private getPartialGeneralSettings(): Partial<GeneralSettingsDto> {
    const generalSettings: GeneralSettingsDto = this.generalSettingsSelector.getGeneralSettings();
    return {
      periodType: generalSettings.periodType,
      useServerTime: generalSettings.useServerTime,
      customFilterDeclarations: generalSettings.customFilterDeclarations
    };
  }

  redo(): void {
    this.isLocked = true;
    this.index++;
    this.tryScrollIntoModifiedWidgets();
    this.store$.dispatch(
      CommonActions.upsertFromSnapshot({
        reportSnapshot: {
          context: this.snapshots[this.index].context,
          entities: this.snapshots[this.index + 1].entities
        }
      })
    );
    this.visualizeModification();
    this.snapshotPointerChanged$.next();
    this.isLocked = false;
  }

  private tryScrollIntoModifiedWidgets(): void {
    if (this.shouldScrollIntoModifiedWidgets()) {
      const entityId = first(this.snapshots[this.index].context.updatedEntitiesInfo?.entityIds);
      this.widgetModified$.next(entityId);
    }
  }

  private shouldScrollIntoModifiedWidgets(): boolean {
    const { updatedEntitiesInfo } = this.snapshots[this.index].context;
    return (
      isDefined(updatedEntitiesInfo) &&
      updatedEntitiesInfo.entitiesTypeName === COMPONENT_STATE_VIEW_MODEL &&
      !isEmptyOrNotDefined(updatedEntitiesInfo.entityIds)
    );
  }

  private visualizeModification(): void {
    this.trySelectWidgets();
    this.tryOpenPropertySheet();
    this.tryFocusChangedProperty();
  }

  private trySelectWidgets(): void {
    if (this.shouldClearSelection()) {
      this.componentSelectionService.clearSelection();
      return;
    }
    const selectedComponentIds = this.filterOutInlineComponents(
      this.snapshots[this.index].context.updatedEntitiesInfo?.entityIds ?? []
    );
    this.componentSelectionService.selectedComponentsIds = selectedComponentIds;
  }

  private shouldClearSelection(): boolean {
    const { updatedEntitiesInfo } = this.snapshots[this.index].context;
    if (isNotDefined(updatedEntitiesInfo)) {
      return true;
    }
    const isGlobalFilterChanged = updatedEntitiesInfo.entityIds?.some(
      (id) => id === REPORT_FILTER_ID
    );
    return isGlobalFilterChanged || !this.isWidgetChanged(updatedEntitiesInfo);
  }

  private isWidgetChanged(updatedEntitiesInfo: UpdatedEntitiesInfo): boolean {
    const entityId = first(updatedEntitiesInfo.entityIds);
    return !!entityId && !!this.componentStateSelector.getById(entityId);
  }

  private filterOutInlineComponents(selectedComponentIds: EntityId[]): EntityId[] {
    this.initializeInlineEditService();
    if (isDefined(this.inlineEditService.componentInlineInfo)) {
      selectedComponentIds = selectedComponentIds.filter(
        (selectedComponentId) =>
          selectedComponentId !== this.inlineEditService.componentInlineInfo.id
      );
    }
    return selectedComponentIds;
  }

  private initializeInlineEditService(): void {
    if (isNotDefined(this.inlineEditService)) {
      this.inlineEditService = this.injector.get(InlineEditService);
    }
  }

  private tryOpenPropertySheet(): void {
    this.initializePropertySheetService();
    const { updatedEntitiesInfo } = this.snapshots[this.index].context;
    if (this.shouldOpenPropertySheet(updatedEntitiesInfo)) {
      const targetId = first(updatedEntitiesInfo.entityIds);
      const target =
        targetId === REPORT_FILTER_ID
          ? this.filterSelector.getGlobal()
          : this.componentStateSelector.getById(targetId);
      if (isDefined(target)) {
        this.propertySheetService.openOrReplaceTarget(target as Entity);
      }
    }
  }

  private initializePropertySheetService(): void {
    if (isNotDefined(this.propertySheetService)) {
      this.propertySheetService = this.injector.get(PropertySheetService);
    }
  }

  private shouldOpenPropertySheet(updatedEntitiesInfo: Maybe<UpdatedEntitiesInfo>): boolean {
    const { propertySheetSnapshotState } = this.snapshots[this.index].context;
    return (
      isDefined(updatedEntitiesInfo) &&
      updatedEntitiesInfo.entityIds.length === 1 &&
      (isDefined(propertySheetSnapshotState) || this.propertySheetService.isPropertySheetOpened)
    );
  }

  private tryFocusChangedProperty(): void {
    const { propertySheetSnapshotState, propertyEditorPath } = this.snapshots[this.index].context;
    if (isNotDefined(propertySheetSnapshotState)) {
      return;
    }
    const { category, advancedMode } = propertySheetSnapshotState;
    this.propertySheetSnapshotStateChanged$.next({
      category,
      advancedMode
    });
    setTimeout(() => {
      this.editorPathChanged$.next(propertyEditorPath);
    }, 0);
  }

  canUndo(): boolean {
    return isDefined(this.snapshots[this.index]);
  }

  canRedo(): boolean {
    return (
      isDefined(this.snapshots[this.index + 1]) &&
      !this.snapshots[this.index + 1].context.isLastRedoableState
    );
  }

  reset(): void {
    this.index = -1;
    this.snapshots = [];
    this.snapshotPointerChanged$.next();
  }
}
