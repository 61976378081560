import { RDS_LOCALIZATION_DICTIONARY } from "projects/rds/src/assets/i18n/models/rds-localization-dictionary";
import { getCommonChartSize } from "projects/ui-core/src/lib/elements/components/base/common-view-config-defaults";
import { EditorSize } from "projects/ui-core/src/lib/meta/models/editor-size";
import {
  AllowInterpolation,
  BaseViewConfigDto,
  Configurable,
  ConfigurableArray,
  ConfigurableEnum,
  ConfigurationCategory,
  construct,
  DeepPartial,
  EditableType,
  EditorType,
  isDefined,
  LOCALIZATION_DICTIONARY,
  PropertyCategory,
  Serializable,
  YAxisDescriptor
} from "ui-core";
import { DisplayStrategies } from "../../models/display-strategies/array-display-strategies";

const TYPE_NAME = "ArrayChartViewConfig";

@EditableType({ fullName: TYPE_NAME })
export class ArrayChartViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General, 1)
  @ConfigurableEnum({
    enumSource: DisplayStrategies,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Display
  })
  @Serializable(DisplayStrategies.Line)
  chartDisplayStrategy!: string;

  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.AverageValues,
    editorType: EditorType.CheckBox
  })
  @Serializable(false)
  showAverage!: boolean;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.DisplayLegend,
    editorType: EditorType.CheckBox
  })
  @Serializable(true)
  showLegend!: boolean;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.XaxisTitle,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.XaxisTitleTooltip,
    editorType: EditorType.TextBox,
    advancedMode: true
  })
  @Serializable("")
  xAxisTitle!: string;

  @ConfigurableArray({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.YAxes,
    editorType: EditorType.Array,
    typeConstructor: YAxisDescriptor,
    arrayItemEditorType: EditorType.NestedObjectEditor,
    arrayEditorSize: EditorSize.Medium,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.YAxesTooltip,
    canBeHidden: true
  })
  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.YAxes)
  @Serializable([new YAxisDescriptor()], YAxisDescriptor)
  @AllowInterpolation()
  yAxes!: YAxisDescriptor[];

  constructor(viewConfigDto: DeepPartial<ArrayChartViewConfig> = {}) {
    super();

    viewConfigDto = {
      ...viewConfigDto,
      yAxes: isDefined(viewConfigDto.yAxes)
        ? viewConfigDto.yAxes.map((yAxis) => new YAxisDescriptor(yAxis))
        : [new YAxisDescriptor()]
    };

    const defaultOverrides: Partial<ArrayChartViewConfig> = {
      size: getCommonChartSize()
    };

    construct(this, viewConfigDto, TYPE_NAME, defaultOverrides);
  }
}
