export enum UrlParams {
  rootPath = "rootPath",
  language = "lang",
  isLoggingEnabled = "logging",
  productName = "productName",
  productNameShort = "productNameShort",
  /** idType: if id parameter in URL is "id" or "name" - in KM case "name" is not unique */
  idType = "idType",
  /** startDate and endDate are expected to be in ISO format */
  startDate = "startDate",
  endDate = "endDate",
  pType = "pType",
  material = "material",
  location = "location",
  array = "array",
  cardId = "cardId",
  noAnimation = "noAnimation"
}
