import { Injectable, OnDestroy } from "@angular/core";
import { cloneDeep as _cloneDeep } from "lodash";
import { initialPropertySheetWidth } from "projects/ui-core/src/style-variables";
import { BehaviorSubject } from "rxjs";
import { Dispatcher } from "../../dispatcher";
import { ComponentStateDto } from "../../elements/models/component-state";
import { COMPONENT_STATE_VIEW_MODEL } from "../../elements/models/entity-type.constants";
import { ReportEntities } from "../../elements/models/report-entities";
import { EntityInfoService } from "../../elements/services/entity-info.service";
import { ComponentStateActions } from "../../elements/store/component-state/component-state.actions";
import { AppStatusActions } from "../../environment";
import { Entity, EntityId } from "../../meta";
import { TypeProvider } from "../../meta/services/type-provider";
import {
  convertDashCaseToTitleText,
  CriticalError,
  first,
  isDefined,
  isNotDefined,
  last
} from "../../ts-utils";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { ModifiedObject } from "../models/modified-object";
import { PropertySheetEntity } from "../models/property-sheet-entity";
import { PropertySheetDependencyService } from "./property-sheet-dependency.service";

@Injectable()
export class PropertySheetService implements OnDestroy {
  private entitiesInPropertySheet: PropertySheetEntity[] = [];
  public onWidthChange$: BehaviorSubject<number> = new BehaviorSubject(initialPropertySheetWidth);
  public activeTabIndex: number = 0;

  constructor(
    protected typeProvider: TypeProvider,
    private dispatcher: Dispatcher,
    private entityInfoProvider: EntityInfoService,
    private propertySheetDependencyService: PropertySheetDependencyService
  ) {}

  ngOnDestroy(): void {
    this.onWidthChange$.complete();
  }

  get isPropertySheetOpened(): boolean {
    return this.entitiesInPropertySheet.length > 0;
  }

  get isPropertySheetClosed(): boolean {
    return this.entitiesInPropertySheet.length === 0;
  }

  get isNestedPropertySheetOpened(): boolean {
    return this.entitiesInPropertySheet.length > 1;
  }

  get currentTarget(): Maybe<ModifiedObject<Entity>> {
    return last(this.entitiesInPropertySheet)?.target;
  }

  get targetWithDependencies(): Maybe<PropertySheetEntity> {
    return last(this.entitiesInPropertySheet);
  }

  openOrReplaceTarget(
    target: Entity,
    parentEntityId: Maybe<EntityId> = null,
    newSelection: boolean = false
  ): void {
    if ((newSelection && this.isPropertySheetClosed) || this.isAlreadyOpenedForTarget(target.id)) {
      return;
    }
    this.updateActiveTabInPreviousTarget();
    const targetInfo: ModifiedObject<Entity> = this.entityInfoProvider.getEntityInfo(
      target,
      parentEntityId
    );
    validateEntity(targetInfo);
    const dependentEntities: ReportEntities =
      this.propertySheetDependencyService.getTargetDependentEntities(
        targetInfo.value.id,
        targetInfo.type
      );
    this.preserveEntityDependencies(targetInfo, dependentEntities);
    this.dispatcher.dispatch(
      AppStatusActions.openPropertySheet({
        targetInfo: _cloneDeep(targetInfo)
      })
    );
  }

  preserveEntityDependencies(
    entityInfo: ModifiedObject<Entity>,
    dependentEntities: ReportEntities
  ): void {
    if (isNotDefined(entityInfo.parentEntityId)) {
      this.entitiesInPropertySheet = [];
    }
    const entitiesToPreserve = {
      target: _cloneDeep(entityInfo),
      dependentEntities
    };
    this.entitiesInPropertySheet.push(entitiesToPreserve);
  }

  closeOrRemoveCurrentTarget(keepOpenForPreviousTarget: boolean = false): void {
    if (isNotDefined(this.currentTarget)) {
      return;
    }
    this.updateActiveTabInPreviousTarget();
    this.entitiesInPropertySheet.pop();
    if (keepOpenForPreviousTarget && isDefined(this.currentTarget)) {
      this.dispatcher.dispatch(
        AppStatusActions.openPropertySheet({
          targetInfo: _cloneDeep(this.currentTarget)
        })
      );
    } else {
      this.entitiesInPropertySheet = [];
      this.dispatcher.dispatch(AppStatusActions.closePropertySheet());
    }
  }

  isAlreadyOpenedForTarget(targetId: EntityId): boolean {
    return this.currentTarget?.value.id === targetId;
  }

  updateActiveTabInPreviousTarget(): void {
    const previousTarget: Maybe<ModifiedObject<Entity>> = this.currentTarget;
    const activeTabIndex: number = this.activeTabIndex;
    if (
      isDefined(previousTarget) &&
      previousTarget.type.name === COMPONENT_STATE_VIEW_MODEL &&
      (previousTarget.value as ComponentStateDto).activeTabIndex !== activeTabIndex
    ) {
      this.dispatcher.dispatch(
        ComponentStateActions.updateOne({
          componentUpdate: {
            id: previousTarget.value.id.toString(),
            changes: { activeTabIndex }
          }
        })
      );
    }
  }

  updateCurrentEntity(target: Entity): Entity {
    const targetDescriptor: Maybe<ModifiedObject<Entity>> = this.currentTarget;
    if (isDefined(targetDescriptor)) {
      targetDescriptor.value = target;
    }
    return target;
  }

  getParentIndicatorPath(): string {
    if (!this.isNestedPropertySheetOpened) {
      return "";
    }
    const componentStateParent = first(this.entitiesInPropertySheet)?.target;
    if (isNotDefined(componentStateParent)) {
      return "";
    }
    return convertDashCaseToTitleText(componentStateParent.value.id.toString()) + " > ";
  }
}

function validateEntity(targetInfo: ModifiedObject<Entity>): void {
  if (isNotDefined(targetInfo) || typeof targetInfo.value === "undefined") {
    throw new CriticalError(`Undefined target object`);
  }
}
