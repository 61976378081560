import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { map, take } from "rxjs/operators";
import { getEquipmentWithPath } from "../../core/helpers/equipment-data-source.helper";
import { Equipment } from "../../core/models/equipment";
import { GeneralSettingsSelector } from "../../elements/services/entity-selectors/general-settings.selector";
import { Maybe } from "../../ts-utils";
import { State as EquipmentState } from "../store/equipment/equipment.reducer";
import {
  selectEquipmentModel,
  selectEquipmentTree,
  selectLoadingStatus
} from "../store/equipment/equipment.selectors";

@Injectable({ providedIn: "root" })
export class EquipmentSelector {
  unsubscribeSubject$: Subject<any> = new Subject<any>();

  constructor(
    private store$: Store<any>,
    private generalSettingsSelector: GeneralSettingsSelector
  ) {}

  selectEquipmentModel(): Observable<EquipmentState> {
    return this.store$.select(selectEquipmentModel);
  }

  selectLoadingStatus(): Observable<boolean> {
    return this.store$.select(selectLoadingStatus);
  }

  //TODO: this selector should react od root path change too. Currently it depends only on selectEquipmentTree()
  selectEquipmentTree(): Observable<Equipment> {
    return this.store$.select(selectEquipmentTree);
  }

  selectEquipmentTreeFromRootPath(
    shouldOpenFullEquipmentTree: boolean
  ): Observable<Maybe<Equipment>> {
    return this.selectEquipmentTree().pipe(
      map((rootEquipment) => {
        return getEquipmentWithPath(
          rootEquipment,
          shouldOpenFullEquipmentTree
            ? rootEquipment?.path || ""
            : this.generalSettingsSelector.getRootPath()
        );
      })
    );
  }

  getEquipmentTree(): Maybe<Equipment> {
    let equipmentTree: Maybe<Equipment> = null;
    this.selectEquipmentModel()
      .pipe(take(1))
      .subscribe((equipmentState: EquipmentState) => (equipmentTree = equipmentState.root));
    return equipmentTree;
  }
}
