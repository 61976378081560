import { Dictionary } from "../../../ts-utils/models/dictionary.type";

export interface DataTableColumn {
  name: string;
  type: DataColumnType;
}

export enum DataColumnType {
  Unknown = "Unknown",
  Date = "Date",
  Number = "Number",
  String = "String"
}

export interface GenericDataSourceDescriptor {
  entity: string;
  columns: DataTableColumn[];
  isValid: boolean;
}

export interface ApiParameter {
  description: string;
  dataType: DataColumnType;
  queryValues?: string[]; //supported values per key
}

export interface ApiDataSourceDescriptor {
  url: string;
  description?: string;
  queryParams: Dictionary<ApiParameter>;
}
