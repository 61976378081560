
<div *ngIf="showMovingSection" class="moving_buttons_block">
  <div class="horizontal_buttons_section" >
    <div class="horizontal_arrow_block">
      <p class="point_arrow" (click)="on_X_OneClick('-', longStep)" (mousedown)="on_X_PointMove('-', longStep)" (mouseup)="stopInc()" >
        <i class="icon abb-icon Double_left"></i>
      </p>

      <p class="point_arrow" (click)="on_X_OneClick('-', shortStep)" (mousedown)="on_X_PointMove('-', shortStep)" (mouseup)="stopInc()" >
        <i class="icon abb-icon Left"></i>
      </p>

      <p class="point_arrow" (click)="on_X_OneClick('+', shortStep)" (mousedown)="on_X_PointMove('+', shortStep)" (mouseup)="stopInc()" >
        <i class="icon abb-icon Right"></i>
      </p>

      <p class="point_arrow" (click)="on_X_OneClick('+', longStep)" (mousedown)="on_X_PointMove('+', longStep)" (mouseup)="stopInc()" >
        <i class="icon abb-icon Double_right"></i>
      </p>

    </div>
    <div class="button_block">
      <button
        class="c-button c-button--rounded c-button--primary"
        (click)="finishPointMoving()"
        cdkFocusInitial
      >
        Confirm
      </button>
    </div>
  </div>
  <div class="vertical_arrow_block">
    <p class="point_arrow" (click)="on_Y_OneClick('+', longStep)" (mousedown)="on_Y_PointMove('+', longStep)" (mouseup)="stopInc()" >
      <i class="icon abb-icon Double_up"></i>
    </p>

    <p class="point_arrow" (click)="on_Y_OneClick('+', shortStep)" (mousedown)="on_Y_PointMove('+', shortStep)" (mouseup)="stopInc()" >
      <i class="icon abb-icon Up"></i>
    </p>

    <p class="point_arrow" (click)="on_Y_OneClick('-', shortStep)" (mousedown)="on_Y_PointMove('-', shortStep)" (mouseup)="stopInc()" >
      <i class="icon abb-icon Down"></i>
    </p>

    <p class="point_arrow" (click)="on_Y_OneClick('-', longStep)" (mousedown)="on_Y_PointMove('-', longStep)" (mouseup)="stopInc()" >
      <i class="icon abb-icon Double_down"></i>
    </p>
  </div>
</div>

<div class="card-item__drag-handle heatmap-alignment-chart__wrapper">
  <highcharts-chart-extended
    class="chart-container heatmap-alignment-chart__chart-container container"
    [Highcharts]="Highcharts"
    [options]="chartOptions"
  >
  </highcharts-chart-extended>
</div>
