import { construct } from "../../../core";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { Configurable, EditableType, Serializable } from "../../../meta/decorators";
import { EditorType } from "../../../meta/models";
import { DeepPartial } from "../../../ts-utils";
import { BaseViewConfigDto } from "../../models/base-view-config";
import { ComponentCssSize } from "../../models/component-size";

const TYPE_NAME = "ClassifiedHeatmapChartViewConfig";

// @dynamic
@EditableType({ fullName: TYPE_NAME })
export class ClassifiedHeatmapChartViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.UnknownColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#e6e6e6")
  unknownColor!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.FaultyColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#FF0000")
  faultyColor!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ForcedColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#FFBB00")
  forcedColor!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.HealthyColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#005C27")
  healthyColor!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.NoisyColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#2E92FA")
  noisyColor!: string;

  constructor(viewConfigDto: DeepPartial<ClassifiedHeatmapChartViewConfig>) {
    super();
    construct(this, viewConfigDto, TYPE_NAME, { size: getDefaultSize() });
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("1100", "400");
}
