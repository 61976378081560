import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output } from "@angular/core";
import { Subject, combineLatest } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DisplayMode } from "../../../core/models/display-mode";
import { EnvironmentSelector } from "../../../environment/services/environment.selector";
import { LocalizationService } from "../../../i18n/localization.service";
import { HeaderButtonConfig } from "../../models/button/header-button.config";

@Component({
  selector: "save-settings",
  templateUrl: "save-settings.component.html",
  styleUrls: ["./save-settings.component.scss"]
})
export class SaveSettingsComponent implements OnInit, OnDestroy {
  @Output() saveReport: EventEmitter<never> = new EventEmitter<never>();
  @Output() saveAsReport: EventEmitter<never> = new EventEmitter<never>();

  private unsubscribeSubject$: Subject<never> = new Subject<never>();
  
  public saveButtonConfig: HeaderButtonConfig = {} as HeaderButtonConfig;
  public saveAsButtonConfig: HeaderButtonConfig = {} as HeaderButtonConfig;
  public dropDownButton: HeaderButtonConfig = {} as HeaderButtonConfig;
  public displayMode: string = "";
  public DisplayMode = DisplayMode;
  public isMenuOpened: boolean = false;
  public savingInProgress: boolean = false;

  @HostListener('document:keydown.control.s', ['$event']) saveShortcut(event: KeyboardEvent): void {
    event.preventDefault();
    this.saveReport.emit();
  }

  @HostListener('document:keydown.control.shift.s', ['$event']) saveAsShortcut(event: KeyboardEvent): void {
    event.preventDefault();
    this.saveAsReport.emit();
  }

  constructor(
    public localizer: LocalizationService,
    private environmentSelector: EnvironmentSelector
  ) {}

  ngOnInit(): void {
    this.initializeButtons();
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  initializeButtons(): void {
    this.dropDownButton = this.createDropdownButtonConfig();
    this.saveButtonConfig = this.createSaveButtonConfig();
    this.saveAsButtonConfig = this.createSaveAsButtonConfig();
  }

  initSubscriptions(): void {
    // TODO find better solution for closing filter dropdown when sidebar is opened on mobile devices
    this.subscribeToDisplayAndSidebarMode();
    this.subscribeToFilterToolbarMode();
    this.subscribeToSavingProgress();
  }

  private subscribeToSavingProgress(): void {
    this.environmentSelector
      .selectReportSavingState()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((reportSavingState: boolean) => {
        this.savingInProgress = reportSavingState;
      });
  }

  private subscribeToDisplayAndSidebarMode(): void {
    combineLatest([
      this.environmentSelector.selectDisplayMode(),
      this.environmentSelector.selectSidebarVisibilityMode()
    ])
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe(([displayMode, isSidebarOpened]) => {
        this.displayMode = displayMode;
        if (shouldCloseMenu(isSidebarOpened, displayMode, this.isMenuOpened)) {
          this.closeMenu();
        }
      });
  }

  private subscribeToFilterToolbarMode(): void {
    this.environmentSelector
      .selectFilterToolbarVisibilityMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((filterToolbarOpened: boolean) => {
        if (filterToolbarOpened) {
          this.closeMenu();
        }
      });
  }

  private createDropdownButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.localizer.buttons.Save,
      clickFunction: () => {
        return this.showOrHideMenu();
      },
      hasDropdown: true
    });
  }

  private createSaveButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.localizer.buttons.Save,
      clickFunction: () => {
        return this.saveReport.emit();
      }
    });
  }

  private createSaveAsButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.localizer.buttons.SaveAs,
      clickFunction: () => {
        return this.saveAsReport.emit();
      }
    });
  }

  showOrHideMenu(): void {
    this.isMenuOpened ? this.closeMenu() : this.openMenu();
  }

  openMenu(): void {
    this.isMenuOpened = true;
  }

  closeMenu(): void {
    this.isMenuOpened = false;
  }
}

function shouldCloseMenu(
  isSidebarOpened: boolean,
  displayMode: string,
  isDropdownOpened: boolean
): boolean {
  return (
    (isSidebarOpened && displayMode === DisplayMode.Mobile && isDropdownOpened) ||
    (displayMode !== DisplayMode.Mobile && isDropdownOpened)
  );
}
