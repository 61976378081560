import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { Dictionary } from "lodash";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Dispatcher } from "../../../dispatcher";
import { ComponentStateDto } from "../../../elements/models/component-state";
import { ComponentPositionUpdate } from "../../../elements/models/resize/component-position-update";
import { ComponentSelectionService } from "../../../elements/services/component-selection.service";
import { ComponentStateActions } from "../../../elements/store/component-state/component-state.actions";
import { LocalizationService } from "../../../i18n/localization.service";
import { ButtonsLocalizationDictionary } from "../../../i18n/models/buttons-localization-dictionary";
import { createUpdatedComponentsInfo } from "../../../meta/helpers/updated-entities-info.helper";
import { EntityId } from "../../../meta/models/entity";
import { CriticalError } from "../../../ts-utils/models/critical-error";
import {
  AlignAndDistributeIconDict,
  AlignmentAndDistribution,
  getPositionsForBottomAlignment,
  getPositionsForCenterAlignment,
  getPositionsForHorizontalDistribution,
  getPositionsForLeftAlignment,
  getPositionsForMiddleAlignment,
  getPositionsForRightAlignment,
  getPositionsForTopAlignment,
  getPositionsForVerticalDistribution,
  PositionsCalculationFunction
} from "../../helpers/align-and-distribute-widgets.helper";
import { HeaderButtonConfig } from "../../models/button/header-button.config";

@Component({
  selector: "align-and-distribute-widgets",
  templateUrl: "align-and-distribute-widgets.component.html",
  styleUrls: ["./align-and-distribute-widgets.component.scss"]
})
export class AlignAndDistributeWidgetsComponent implements OnInit, OnChanges, OnDestroy {
  buttonTypes: AlignmentAndDistribution[] = [];
  dropdownButtonConfig!: HeaderButtonConfig;
  alignAndDistributeButtons!: Dictionary<HeaderButtonConfig>;
  @Input() isAutoPositionActive: boolean = false;
  isMenuOpened: boolean = false;
  private unsubscribeSubject$: Subject<any> = new Subject();

  constructor(
    private localizer: LocalizationService,
    private componentSelectionService: ComponentSelectionService,
    private dispatcher: Dispatcher
  ) {}

  ngOnInit(): void {
    this.buttonTypes = this.getButtonTypes();
    this.dropdownButtonConfig = this.createDropDownButtonConfig();
    this.alignAndDistributeButtons = this.createButtonsDictionary();
    this.initSubscriptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes["isAutoPositionActive"].isFirstChange()) {
      this.disableOrEnableButtons();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  private getButtonTypes(): AlignmentAndDistribution[] {
    return Object.keys(AlignmentAndDistribution)
      .filter((key) => parseInt(key).toString() !== key)
      .map((key) => AlignmentAndDistribution[key as keyof typeof AlignmentAndDistribution]);
  }

  private initSubscriptions(): void {
    this.subscribeToSelectedComponents();
  }

  private subscribeToSelectedComponents(): void {
    this.componentSelectionService.selectedComponentsChanged
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe(() => this.disableOrEnableButtons());
  }

  private disableOrEnableButtons(): void {
    const isDisabled: boolean = this.shouldDisableButtons();
    Object.keys(this.alignAndDistributeButtons).forEach(
      (key) => (this.alignAndDistributeButtons[key].isDisabled = isDisabled)
    );
  }

  private shouldDisableButtons(): boolean {
    const isMultipleSelectionActive: boolean =
      this.componentSelectionService.isMultipleSelectionActive;
    return !isMultipleSelectionActive || (isMultipleSelectionActive && this.isAutoPositionActive);
  }

  private createDropDownButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.localizer.buttons.AlignAndDistribute,
      hasDropdown: true,
      clickFunction: () => this.showOrHideMenu()
    });
  }

  private showOrHideMenu(): void {
    this.isMenuOpened ? this.closeMenu() : this.openMenu();
  }

  closeMenu(): void {
    this.isMenuOpened = false;
  }

  private openMenu(): void {
    this.isMenuOpened = true;
  }

  private createButtonsDictionary(): Dictionary<HeaderButtonConfig> {
    return this.buttonTypes.reduce(
      (acc: Dictionary<HeaderButtonConfig>, buttonType: AlignmentAndDistribution) => {
        acc[buttonType] = this.createButtonConfig(buttonType);
        return acc;
      },
      {}
    );
  }

  private createButtonConfig(buttonType: AlignmentAndDistribution): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.getButtonTitle(buttonType),
      isDisabled: true,
      clickFunction: this.getButtonFunction(buttonType)
    });
  }

  private getButtonTitle(buttonType: AlignmentAndDistribution): string {
    return this.localizer.buttons[
      AlignmentAndDistribution[buttonType] as keyof ButtonsLocalizationDictionary
    ];
  }

  private getButtonFunction(buttonType: AlignmentAndDistribution): () => void {
    switch (buttonType) {
      case AlignmentAndDistribution.LeftAlign:
        return () => this.updateComponentPositions(getPositionsForLeftAlignment);
      case AlignmentAndDistribution.Center:
        return () => this.updateComponentPositions(getPositionsForCenterAlignment);
      case AlignmentAndDistribution.RightAlign:
        return () => this.updateComponentPositions(getPositionsForRightAlignment);
      case AlignmentAndDistribution.TopAlign:
        return () => this.updateComponentPositions(getPositionsForTopAlignment);
      case AlignmentAndDistribution.Middle:
        return () => this.updateComponentPositions(getPositionsForMiddleAlignment);
      case AlignmentAndDistribution.BottomAlign:
        return () => this.updateComponentPositions(getPositionsForBottomAlignment);
      case AlignmentAndDistribution.HorizontalDistribute:
        return () => this.updateComponentPositions(getPositionsForHorizontalDistribution);
      case AlignmentAndDistribution.VerticalDistribute:
        return () => this.updateComponentPositions(getPositionsForVerticalDistribution);
      default:
        throw new CriticalError(
          `Alignment or distribution ${AlignmentAndDistribution[buttonType]} not supported.`
        );
    }
  }

  private updateComponentPositions(alignOrDistributeFunction: PositionsCalculationFunction): void {
    const selectedComponents: ComponentStateDto[] = this.componentSelectionService
      .getSelectedComponents()
      .filter(Boolean);
    const componentPositions: ComponentPositionUpdate[] =
      alignOrDistributeFunction(selectedComponents);
    const targetIds: EntityId[] = selectedComponents.map((component) => component.id);
    this.dispatcher.dispatch(
      ComponentStateActions.updatePositions({
        componentPositions
      }),
      {
        withSnapshot: true,
        updatedEntitiesInfo: createUpdatedComponentsInfo(targetIds)
      }
    );
  }

  getButtonTypeIcon(buttonType: AlignmentAndDistribution): string {
    return AlignAndDistributeIconDict[buttonType];
  }
}
