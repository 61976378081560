import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import moment from "moment";
import { map, switchMap } from "rxjs/operators";
import { Hierarchy } from "../../models/api/hierarchy";
import { IProjectHierarchyWithStatus } from "../../models/motor-response.interface";
import { IClient, IMotorStatus } from "../../models/motor-status.interface";
import { HierarchyService } from "../../services/hierarchy.service";
import { FleetViewDataService } from "../../services/sidebar/fleet-view.service";
import { HierarchyCollectionActions } from "../actions/hierarchy-collection.actions";

@Injectable()
export class HierarchyEffects {
  hierarchyCollection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HierarchyCollectionActions.getHierarchy),
      switchMap(() => {
        return this.hierarhyService.getHierarchyObservable().pipe(
          map((hierarchy) => {
            const motorResponse = this.mapMotorsGroups(hierarchy);
            this.fleetViewService.continentsCollection$.next(motorResponse.continents);
            return HierarchyCollectionActions.setHierarchy({ motorResponse });
          })
        );
      })
    )
  );

  fromDateHours = -3;

  constructor(
    private actions$: Actions,
    private hierarhyService: HierarchyService,
    private fleetViewService: FleetViewDataService
  ) {}

  mapMotorsGroups(res: Hierarchy): IProjectHierarchyWithStatus {
    const continents = Object.values(res.continents);
    const From = moment(new Date()).add(this.fromDateHours, "h").format();
    const To = moment(new Date()).format();
    const motorStatus: IMotorStatus = {
      From,
      To,
      LastValue: false,
      Clients: []
    };
    continents.forEach((continent) => {
      continent.countries.forEach((country) => {
        country.projects.forEach((project) => {
          project.systems.forEach((motor) => {
            const clientBody: IClient = {
              Customer: project.name,
              ClientId: `${project.name}.${motor.name}`,
              Motor: `${motor.name}`
            };
            motorStatus.Clients.push(clientBody);
          });
        });
      });
    });

    const responseBody: IProjectHierarchyWithStatus = {
      continents,
      motorStatus
    };

    return responseBody;
  }
}
