import { Injectable } from "@angular/core";
import moment from "moment";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { isDefined, Maybe } from "../../ts-utils";
import { WebServicesConfiguration } from "./api.config";
import { HttpService } from "./http.service";

@Injectable({
  providedIn: "root"
})
export class ServerTimeOffsetMeasurerService {
  constructor(private apiConfig: WebServicesConfiguration, private httpService: HttpService) {}

  public getServerTimeOffset$(): Observable<Maybe<number>> {
    if (!this.apiConfig.serverTimeUrl) {
      console.log("no serverTimeUrl");
      return of(0);
    } else {
      return this.measureOffsetMs$();
    }
  }

  // see https://en.wikipedia.org/wiki/Network_Time_Protocol#Clock_synchronization_algorithm
  // we assume t1 = t2
  private measureOffsetMs$(): Observable<Maybe<number>> {
    const t0 = moment();
    return this.httpService.get({ url: this.apiConfig.serverTimeUrl, failureDefault: null }).pipe(
      map((resp) => {
        if (isDefined(resp)) {
          const t3 = moment();
          const t1t2 = this.getCurrentDateFromResponse(resp);
          const res = (t1t2.diff(t0) + t1t2.diff(t3)) / 2;
          // console.log("got server time", t1t2, "offset: " + res / 60 / 1000);
          return res;
        } else {
          console.log("no response from server");
          return null;
        }
      })
    );
  }

  protected getCurrentDateFromResponse(response: any): moment.Moment {
    return moment(response.CurrentDate);
  }
}
