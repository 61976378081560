import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { GlobalTimeRangeConfigurationDto } from "../../../core/models/filter/global-time-range-configuration";
import { TimeRange } from "../../../core/models/time-range";
import { TimeRangeConfigurationDto } from "../../../core/models/time-range-configuration";
import { FilterFactory } from "../../../core/services/filter/filter-factory.service";
import { Dispatcher } from "../../../dispatcher";
import { TimeInfoActions } from "../../../environment/store/time-info/time-info.actions";
import { LocalizationService } from "../../../i18n/localization.service";
import { EditorType, OfType, TypeProvider } from "../../../meta";
import { BaseEditorComponent } from "../../../property-sheet/components/base-editor.component";
import { focusItemInput } from "../../../property-sheet/helpers/input-editor-helper";
import { isEmptyOrNotDefined } from "../../../ts-utils/helpers/is-empty.helper";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { resolveLiveModeFilter } from "../../helpers/live-mode-time-unit.helper";
import {
  isEndDateTimeChanged,
  isStartDateTimeChanged,
  isTimeAmountChanged,
  isTimeUnitChanged
} from "../../helpers/time-range-editor.helper";
import { LiveModeFilter } from "../../models/live-mode-filter";
import { HistoricRangeComponent } from "../historic-range/historic-range.component";
import { LiveModeFilterComponent } from "../live-mode-filter/live-mode-filter.component";
import { ToggleSwitchComponent } from "../toggle-switch/toggle-switch.component";

@Component({
  selector: "time-range-editor",
  templateUrl: "time-range-editor.component.html",
  styleUrls: ["./time-range-editor.component.scss"]
})
@OfType(EditorType.TimeRangeEditor)
export class TimeRangeEditorComponent extends BaseEditorComponent implements OnInit {
  @Output() applyFilterChanges: EventEmitter<any> = new EventEmitter();
  public isLiveMode: boolean = true;
  public isCustomTimeRangeInvalid: boolean = false;
  public originalTimeRange: TimeRange;
  public liveModeFilter: LiveModeFilter = new LiveModeFilter();
  private lastChangedElement: Maybe<ElementRef>;
  @ViewChild(ToggleSwitchComponent)
  toggleSwitch?: ToggleSwitchComponent;
  @ViewChild(LiveModeFilterComponent)
  liveModeFilterComponent?: LiveModeFilterComponent;
  @ViewChild(HistoricRangeComponent)
  historicRangeComponent?: HistoricRangeComponent;

  constructor(
    public localizer: LocalizationService,
    private filterFactory: FilterFactory,
    private dispatcher: Dispatcher,
    protected typeProvider: TypeProvider,
    protected changeDetectorRef: ChangeDetectorRef
  ) {
    super(changeDetectorRef, typeProvider, localizer);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.isLiveMode = isEmptyOrNotDefined(this.value.toExpression);
    this.initCustomTimeRange(this.value);
    this.initLiveModeFilter(this.value);
  }

  private initCustomTimeRange(timeRange: GlobalTimeRangeConfigurationDto): void {
    const newTimeRange = this.filterFactory.createStandaloneFilterTimeRange(timeRange);
    if (isDefined(newTimeRange)) {
      this.originalTimeRange = newTimeRange;
    }
  }

  private initLiveModeFilter(filterConfiguration: GlobalTimeRangeConfigurationDto): void {
    if (isDefined(filterConfiguration)) {
      this.liveModeFilter =
        this.filterFactory.createStandaloneLiveModeFilter(filterConfiguration) ??
        new LiveModeFilter();
    }
  }

  refreshValue(value): void {
    super.refreshValue(value);
    const isLiveMode: boolean = isEmptyOrNotDefined(value.toExpression);
    const liveModeFilter = this.filterFactory.createStandaloneLiveModeFilter(value);
    const originalTimeRange = this.filterFactory.createStandaloneFilterTimeRange(value);
    this.lastChangedElement = this.resolveLastChangedElement(
      isLiveMode,
      liveModeFilter,
      originalTimeRange
    );
    this.isLiveMode = isLiveMode;
    this.liveModeFilter = liveModeFilter;
    this.originalTimeRange = originalTimeRange;
  }

  private resolveLastChangedElement(
    isLiveMode: boolean,
    liveModeFilter: Maybe<LiveModeFilter>,
    originalTimeRange: Maybe<TimeRange>
  ): Maybe<ElementRef> {
    if (isLiveMode !== this.isLiveMode) {
      return this.toggleSwitch?.getSwitch();
    }
    if (isTimeAmountChanged(this.liveModeFilter, liveModeFilter)) {
      return this.liveModeFilterComponent?.getTimeAmountInput();
    }
    if (isTimeUnitChanged(this.liveModeFilter, liveModeFilter)) {
      return this.liveModeFilterComponent?.getSelectElement();
    }
    if (isStartDateTimeChanged(this.originalTimeRange, originalTimeRange)) {
      return this.historicRangeComponent?.getStartDateTimeInput();
    }
    if (isEndDateTimeChanged(this.originalTimeRange, originalTimeRange)) {
      return this.historicRangeComponent?.getEndDateTimeInput();
    }
    return null;
  }

  onChangeLiveMode(liveMode: boolean): void {
    this.isLiveMode = liveMode;
    this.isCustomTimeRangeInvalid = false;
    if (liveMode) {
      this.liveModeFilter = resolveLiveModeFilter(this.originalTimeRange);
      this.dispatcher.dispatch(TimeInfoActions.updateCurrentTime({ now: new Date() }));
    } else {
      this.originalTimeRange = this.filterFactory.createStandaloneFilterTimeRange(this.value);
    }
    this.applyTimeRangeChanges();
  }

  public onChangeLiveModeFilter(liveModeFilter: LiveModeFilter): void {
    this.liveModeFilter = liveModeFilter;
    this.applyTimeRangeChanges();
  }

  public onChangeCustomTimeRange(newTimeRange: Maybe<TimeRange>): void {
    if (isDefined(newTimeRange)) {
      this.isCustomTimeRangeInvalid = false;
      this.originalTimeRange = newTimeRange;
      this.applyTimeRangeChanges();
    } else {
      this.isCustomTimeRangeInvalid = true;
    }
  }

  public applyTimeRangeChanges(): void {
    const newTimeRangeConfiguration: GlobalTimeRangeConfigurationDto =
      this.resolveTimeRangeConfiguration();
    this.onValueChanged(newTimeRangeConfiguration);
  }

  private resolveTimeRangeConfiguration(): TimeRangeConfigurationDto {
    return this.isLiveMode
      ? this.filterFactory.createTimeRangeFromLiveModeFilter(this.liveModeFilter)
      : this.filterFactory.createTimeRangeConfiguration(this.originalTimeRange);
  }

  focus(): void {
    focusItemInput(this.lastChangedElement?.nativeElement);
  }
}
