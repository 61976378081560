import { Component } from "@angular/core";
import {
  BaseComponent,
  ChartComponent,
  ConnectorRoles,
  EditableType,
  EMPTY_VALUE,
  formatValueByStringFormat,
  isDefined,
  LayoutBuilder,
  Maybe,
  View
} from "ui-core";
import { RDS_LOCALIZATION_DICTIONARY } from "../../../assets/i18n/models/rds-localization-dictionary";
import { RdsComponentCategory } from "../rds-component-category";
import { Properties, Roles } from "./roles";
import { WaterConductivityChartViewConfig } from "./view-config";

@Component({
  selector: "rds-water-conductivity-chart",
  templateUrl: "./water-conductivity-chart.component.html",
  styleUrls: ["./water-conductivity-chart.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: WaterConductivityChartComponent }]
})
@LayoutBuilder(
  RdsComponentCategory.RDS,
  "WaterConductivityChartComponent",
  "Plugin",
  "abb-icon",
  undefined,
  RDS_LOCALIZATION_DICTIONARY.layoutEditor.WaterConductivityChart
)
@ConnectorRoles(Roles, Properties)
@EditableType({ fullName: "WaterConductivityChartComponent", title: "water-conductivity-chart" })
export class WaterConductivityChartComponent extends ChartComponent {
  EMPTY_VALUE = EMPTY_VALUE;
  unit: string;

  currentValue: Maybe<number> = null;
  alarmLimit: Maybe<string> = null;
  tripLimit: Maybe<string> = null;

  @View(WaterConductivityChartViewConfig)
  public get view() {
    return this.currentState.view as WaterConductivityChartViewConfig;
  }

  protected updateDisplay() {
    super.updateDisplay();
    if (isDefined(this.currentValue)) {
      this.updateLegendItem("Current Value", this.currentValue.toString() + " " + this.unit);
    } else {
      this.updateLegendItem("Current Value", EMPTY_VALUE);
    }
    this.updateLegendItem("Trip Limit", this.tripLimit + " " + this.unit);
    this.updateLegendItem("Alarm Limit", this.alarmLimit + " " + this.unit);
  }

  protected updateChartData(): void {
    this.unit =
      this.dataAccessor.getPropertyValueForRole(Roles.Value.name, Properties.Unit.name) ||
      this.view.unit;
    this.alarmLimit =
      this.dataAccessor.getPropertyValueForRole(Roles.Value.name, Properties.AlarmLimit.name) ||
      this.alarmLimit;
    this.tripLimit =
      this.dataAccessor.getPropertyValueForRole(Roles.Value.name, Properties.TripLimit.name) ||
      this.tripLimit;
    this.currentValue = this.dataAccessor.getValueForRole(Roles.Value.name);
  }

  protected setChartOptions(): void {
    const component = this;
    const bandSize = +component.tripLimit - +component.alarmLimit;
    const dataPadding = +component.tripLimit + bandSize - component.currentValue;
    const referenceBarWidth = 5;
    const valueBarWidth = 15;
    this.chartOptions = {
      lang: {
        noData: "No Data Available"
      },
      chart: {
        type: "bar"
      },
      title: { text: null },
      credits: { enabled: false },
      plotOptions: {
        series: {
          stacking: "normal",
          pointPadding: 0,
          groupPadding: 0
        }
      },
      tooltip: {
        enabled: false,
        formatter: function () {
          return (
            "Current Value:</br><b>" +
            formatValueByStringFormat(this.y, component.view.displayFormat) +
            "</b>"
          );
        },
        positioner: function (labelWidth, labelHeight, point) {
          return {
            x: point.plotX - labelWidth / 2,
            y: point.plotY - labelHeight - 5
          };
        }
      },
      xAxis: {
        labels: { enabled: false },
        tickLength: 0
      },
      yAxis: {
        gridLineWidth: 0,
        max: +this.tripLimit + bandSize,
        title: { text: null },

        minorTickInterval: 0.01,
        minorTickWidth: 1,
        minorTickLength: 3,
        minorTickColor: "#E6E6E6",

        tickInterval: 0.1,
        tickWidth: 1,
        tickLength: 7,
        tickColor: "#E6E6E6"
      },
      series: [
        {
          pointWidth: referenceBarWidth,
          name: "trip range",
          data: [0, bandSize],
          color: this.view.tripColor,
          enableMouseTracking: false
        },
        {
          pointWidth: referenceBarWidth,
          name: "alarm range",
          data: [0, bandSize],
          color: this.view.alarmColor,
          enableMouseTracking: false
        },
        {
          pointWidth: referenceBarWidth,
          name: "expected range",
          data: [0, +this.alarmLimit],
          color: this.view.expectedColor,
          enableMouseTracking: false
        },
        {
          pointWidth: valueBarWidth,
          name: "data padding",
          data: [dataPadding, 0],
          color: "#E6E6E6",
          enableMouseTracking: false
        },
        {
          pointWidth: valueBarWidth,
          name: "Water Conductivity",
          data: [this.currentValue, 0],
          color: this.view.currentColor
        }
      ],
      legend: { enabled: false }
    };
  }
}
