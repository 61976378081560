import { FilterConfigurationDto } from "../../core/models/filter/filter-configuration";
import { construct } from "../../core/services/construct.helper";
import { SUM_ROLE } from "../../elements/components/waterfall-chart/roles";
import { selectConnectorComputability } from "../../elements/helpers/dynamically-visible-properties.helper";
import { DEFAULT_TITLE } from "../../elements/models/default-property-value.constants";
import { DATA_CONNECTOR_VIEW_MODEL } from "../../elements/models/entity-type.constants";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import {
  AllowInterpolation,
  ConfigurableEnum,
  ConfigurationCategory,
  OnPropertyChange,
  Title
} from "../../meta/decorators";
import { Configurable, EditableType, Serializable } from "../../meta/decorators/";
import { DynamicallyVisible } from "../../meta/decorators/dynamically-visible.decorator";
import { Virtual } from "../../meta/decorators/virtual.decorator";
import { getDataConnectorTitle } from "../../meta/helpers/get-title.helper";
import {
  CutOffStrategyType,
  InheritableCutOffStrategy,
  PropertyCategory,
  PropertyInfo
} from "../../meta/models";
import { EditorType } from "../../meta/models/editor-type";
import { Entity } from "../../meta/models/entity";
import { DataConnectorDto } from "./data-connector";
import { getRolesNames } from "./data-connector-role";
import { DataConnectorViewDto } from "./data-connector-view";
import { DataSourceDto } from "./data-source/data-source";

const TYPE_NAME = DATA_CONNECTOR_VIEW_MODEL;

@EditableType({ fullName: TYPE_NAME, title: "data-connector-view-model" })
export class DataConnectorViewModel extends Entity {
  typeName = TYPE_NAME;

  @OnPropertyChange<any, Partial<DataConnectorViewModel>, any>(onChangeRole)
  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.General, 2)
  @ConfigurableEnum({
    enumSource: getRolesNames,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Role,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.RoleTooltip
  })
  @Serializable("")
  public role!: string;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General, 1)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Title,
    editorType: EditorType.TextBox,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.PropertyInterpolationTooltip
  })
  @Serializable(DEFAULT_TITLE)
  @AllowInterpolation()
  @Title()
  public title!: string;

  @Serializable()
  @Virtual()
  @DynamicallyVisible(selectConnectorComputability, [false])
  public dataSource!: DataSourceDto;

  @Serializable(null)
  @Virtual()
  @DynamicallyVisible(selectConnectorComputability, [false])
  filterConfig: FilterConfigurationDto;

  @Serializable(null)
  @Virtual()
  view!: DataConnectorViewDto;

  @ConfigurationCategory(
    PropertyCategory.Data,
    LOCALIZATION_DICTIONARY.propertySheet.DataAggregation,
    11
  )
  @ConfigurableEnum({
    enumSource: InheritableCutOffStrategy,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.CutOffStrategy,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.CutOffStrategyTooltip
  })
  @Serializable(InheritableCutOffStrategy.Inherited)
  @DynamicallyVisible(selectConnectorComputability, [false])
  cutOffStrategy!: CutOffStrategyType;

  isDynamicallyCreated: boolean = false;
  isComputing: boolean = false;

  constructor(connector: DataConnectorDto) {
    super();
    const params: Partial<DataConnectorViewModel> = {
      id: connector.id,
      role: connector.role,
      title: connector.title,
      dataSource: connector.dataSource,
      isDynamicallyCreated: connector.isDynamicallyCreated,
      cutOffStrategy: connector.cutOffStrategy,
      isComputing: connector.isComputing
    };
    construct(this, params, TYPE_NAME);
  }
}

function onChangeRole(
  context: any,
  owner: Partial<DataConnectorViewModel>,
  roleChange: PropertyInfo<any>
): Partial<DataConnectorViewModel> {
  return {
    role: roleChange.value,
    title: roleChange.value === SUM_ROLE ? SUM_ROLE : getDataConnectorTitle(owner.dataSource),
    isComputing: roleChange.value === SUM_ROLE
  };
}
