import { ReportId } from "../../core";
import { Maybe } from "../../ts-utils";

export class NavigationNodeInfo {
  link: Maybe<{ reportId: ReportId }>;
  productionArea?: string;
  systemOverviewReport?: string;
  projectOverviewReport?: string;
  description: string;
  queryString: string = "";
  constructor(
    link?: { reportId: ReportId } | null,
    productionArea?: string,
    systemOverviewReport?: string,
    projectOverviewReport?: string,
    description?: string
  ) {
    this.link = !!link ? link : null;
    this.description = !!description ? description : "";
    this.productionArea = productionArea;
    this.systemOverviewReport = systemOverviewReport;
    this.projectOverviewReport = projectOverviewReport;
  }

  get linkToReport(): string {
    if (this.link) {
      return this.link.reportId.toString();
    }
    return "";
  }

  get linkToReportWithQueryString(): string {
    if (this.link) {
      return this.link.reportId.toString().concat("?").concat(this.queryString);
    }
    return "";
  }
}
