import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { DialogConfigStrategy } from "../dialog-config.strategies";

@Injectable()
export class OpenDialogEffect {
  protected dialogRef: MatDialogRef<any>; // TODO Make type safe base dialog class
  protected dialogConfig: MatDialogConfig;
  protected dialogConfigStrategy: DialogConfigStrategy;

  constructor(protected dialog: MatDialog) {}
}
