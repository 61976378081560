import { Injectable } from "@angular/core";
import { OverridableParamKeys, QueryParamKeyConverter } from "ui-core";

export enum RdsOverridableParamKeys {
  PeriodType = "Frequency",
  TimeAggregation = "Aggregation",
  ArrayAggregation = "ArrayAggregation"
}

@Injectable()
export class RdsQueryParamKeyConverter implements QueryParamKeyConverter {
  convert(param: string): string {
    switch (param) {
      case OverridableParamKeys.PeriodType:
        return RdsOverridableParamKeys.PeriodType;
      case OverridableParamKeys.TimeAggregation:
        return RdsOverridableParamKeys.TimeAggregation;
      case OverridableParamKeys.ArrayAggregation:
        return RdsOverridableParamKeys.ArrayAggregation;
      default:
        return "";
    }
  }
}
