import _isEqual from "lodash/isEqual";
import { isEmptyOrNotDefined } from "../../../ts-utils/helpers/is-empty.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { IBaseDisplayConfig } from "../../models/i-view-config/i-base-display-config";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";

export function chartSizeChanged(
  previousProcessedViewConfig: IBaseDisplayConfig,
  currentProcessedViewConfig: IBaseDisplayConfig
): boolean {
  return !_isEqual(
    previousProcessedViewConfig.runtimeView.runtimeSize,
    currentProcessedViewConfig.runtimeView.runtimeSize
  );
}

export function switchedOnOff(prev: any, cur: any): boolean {
  return (prev == null && cur != null) || (prev != null && cur == null);
}

export function xOr(a: boolean, b: boolean): boolean {
  return (a && !b) || (!a && b);
}

export function areConnectorsReordered(
  previousDataConnectorDescriptors: Maybe<DataConnectorDescriptor[]>,
  currentDataConnectorDescriptors: Maybe<DataConnectorDescriptor[]>
): boolean {
  if (
    isEmptyOrNotDefined(previousDataConnectorDescriptors) ||
    isEmptyOrNotDefined(currentDataConnectorDescriptors)
  ) {
    return false;
  }

  return currentDataConnectorDescriptors.some((currentDescriptor: DataConnectorDescriptor) =>
    previousDataConnectorDescriptors.find(
      (previousDescriptor: DataConnectorDescriptor) =>
        currentDescriptor.connectorView?.id === previousDescriptor.connectorView?.id &&
        currentDescriptor.connectorView?.order !== previousDescriptor.connectorView?.order
    )
  );
}
