import { Injectable } from "@angular/core";
import { isDefined } from "../../ts-utils";
import { getBasicCards } from "../helpers/v0.helper";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class RemoveObsoleteCardProps implements UpgradeStep {
  name = "RemoveObsoleteCardProps";
  fromVersion = new Version(1, 0, 9);

  perform(oldConfig: any): UpgradeStepResult {
    const cards: any[] = getBasicCards(oldConfig);
    let changed = false;
    cards.forEach((card) => {
      const view = card["view"];
      if (
        isDefined(view["isEditableLayout"]) ||
        isDefined(view["disableMargin"]) ||
        isDefined(view["displayStatusIcon"])
      ) {
        delete view["isEditableLayout"];
        delete view["disableMargin"];
        delete view["displayStatusIcon"];
        changed = true;
      }
    });

    return {
      result: oldConfig,
      modified: changed,
      warning: null
    };
  }
}
