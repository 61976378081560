import { Action, createReducer, on } from "@ngrx/store";
import { TimeInfo } from "../../models/time-info";
import { TimeInfoActions } from "./time-info.actions";

const initialState: TimeInfo = {
  clientNow: new Date(),
  serverOffset: 0
};

export function reducer(state: TimeInfo, action: Action): TimeInfo {
  return _reducer(state, action);
}

const _reducer = createReducer(
  initialState,
  on(TimeInfoActions.updateTimeOffset, (state, { newOffset }) =>
    updateTimeOffset(state, newOffset)
  ),
  on(TimeInfoActions.updateCurrentTime, (state, { now }) => updateCurrentTime(state, now))
);

function updateTimeOffset(state: TimeInfo, newOffset: number): TimeInfo {
  return {
    ...state,
    serverOffset: newOffset
  };
}

function updateCurrentTime(state: TimeInfo, now: Date): TimeInfo {
  return {
    ...state,
    clientNow: now
  };
}
