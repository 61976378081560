import {
  CONNECTOR_SOURCE,
  CONNECTOR_VIEW_SOURCE,
  DATA_POINT_SOURCE,
  FILTER_SOURCE,
  WIDGET_SOURCE
} from "../../elements/models/property-interpolation.constants";

export function getSourcesForAutocomplete(): string[] {
  return [WIDGET_SOURCE, CONNECTOR_SOURCE, CONNECTOR_VIEW_SOURCE, FILTER_SOURCE, DATA_POINT_SOURCE];
}
