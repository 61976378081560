import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { filter, first, map, switchMap, timeout } from "rxjs/operators";
import { ServerTimeOffsetMeasurerService } from "../../../core/services/server-time-offset-measurer.service";
import { TimeService } from "../../../core/services/time.service";
import { CommonActions } from "../../../elements";
import { FilterActions } from "../../../elements/store/filter";
import { isDefined, isNotDefined } from "../../../ts-utils";
import { selectServerOffset } from "../feature.selector";
import { TimeInfoActions } from "./time-info.actions";

//FIXME: there are references to elements and core module
@Injectable()
export class TimeInfoEffects {
  constructor(
    private actions$: Actions,
    private serverTimeService: ServerTimeOffsetMeasurerService,
    private timeService: TimeService
  ) {}

  gettingServerTime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TimeInfoActions.getServerTime),
      switchMap(() => this.callGetServerTimeOffset())
    )
  );

  private callGetServerTimeOffset(): Observable<Action> {
    return this.serverTimeService.getServerTimeOffset$().pipe(
      map((measuredOffset) =>
        TimeInfoActions.updateTimeOffset({
          newOffset: measuredOffset,
          previousOffset: null
        })
      )
    );
  }

  updateTimeOffset$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TimeInfoActions.updateTimeOffset),
      map(({ newOffset, previousOffset }) => {
        if (
          isNotDefined(previousOffset) ||
          !this.timeService.useServerTime ||
          newOffset === previousOffset
        ) {
          return CommonActions.doNothing();
        }
        const offsetDiff = newOffset - previousOffset;
        return FilterActions.updateManyWithTimeOffset({ offset: offsetDiff });
      })
    )
  );
}

export function initServerTime(store: Store<any>): Observable<any> {
  store.dispatch(TimeInfoActions.getServerTime());
  return store.pipe(
    select(selectServerOffset),
    filter((data) => isDefined(data)),
    first(),
    timeout(30 * 1000)
  );
}
