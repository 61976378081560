import { Injectable } from "@angular/core";
import { Cardinality } from "../../core/models/cardinality";
import {
  DataConnectorRole,
  DataConnectorRoles,
  getDefaultDataConnectorRole
} from "../../data-connectivity/models/data-connector-role";
import { PropertyRoles } from "../../data-connectivity/models/property-role";
import { Dictionary } from "../../ts-utils";

export interface ComponentMetadata {
  roles: DataConnectorRoles;
  properties: PropertyRoles;
  maxNumberOfConnectors: number;
}

export const DEFAULT_METADATA: ComponentMetadata = {
  roles: {
    Value: {
      name: "Value",
      cardinality: Cardinality.One,
      isDefaultRole: true
    }
  },
  properties: {},
  maxNumberOfConnectors: 1
};

@Injectable()
export class ComponentMetadataService {
  private static _instance: ComponentMetadataService;

  constructor() {
    if (!(<any>window).__componentMetadataProvider) {
      (<any>window).__componentMetadataProvider = {} as Dictionary<ComponentMetadata>;
      (<any>window).__componentMetadataProvider = (<any>window).__componentMetadataProvider;
    }
  }

  static getInstance(): ComponentMetadataService {
    return (
      ComponentMetadataService._instance ??
      (ComponentMetadataService._instance = new ComponentMetadataService())
    );
  }

  getAll(): Dictionary<ComponentMetadata> {
    return (<any>window).__componentMetadataProvider;
  }

  addOrUpdate(componentTypeName: string, componentMetadata: Partial<ComponentMetadata>) {
    if (metadataExists(componentTypeName)) {
      const mergedMetadata: ComponentMetadata = mergeMetadata(componentTypeName, componentMetadata);
      (<any>window).__componentMetadataProvider[componentTypeName] = mergedMetadata;
    } else {
      const validatedMetadata: ComponentMetadata = validateMetadata(componentMetadata);
      (<any>window).__componentMetadataProvider[componentTypeName] = validatedMetadata;
    }
  }

  getRoles(componentTypeName: string): Dictionary<DataConnectorRole> {
    if (!metadataExists(componentTypeName)) {
      return DEFAULT_METADATA.roles;
    }
    const componentMetadata: ComponentMetadata = (<any>window).__componentMetadataProvider[
      componentTypeName
    ];
    const roles = componentMetadata.roles;
    return roles;
  }

  getDefaultRole(componentTypeName: string): DataConnectorRole {
    const roles: DataConnectorRoles = this.getRoles(componentTypeName);
    const defaultRole = getDefaultDataConnectorRole(roles);
    return defaultRole;
  }

  getMaxConnectors(componentTypeName: string): number {
    if (!metadataExists(componentTypeName)) {
      return DEFAULT_METADATA.maxNumberOfConnectors;
    }
    const componentMetadata: ComponentMetadata = (<any>window).__componentMetadataProvider[
      componentTypeName
    ];
    const maxConnectors = componentMetadata.maxNumberOfConnectors;
    return maxConnectors;
  }
}

function metadataExists(componentTypeName: string): boolean {
  return (<any>window).__componentMetadataProvider[componentTypeName] != null;
}

function mergeMetadata(
  componentTypeName: string,
  newMetadata: Partial<ComponentMetadata>
): ComponentMetadata {
  return {
    ...(<any>window).__componentMetadataProvider[componentTypeName],
    ...newMetadata
  };
}

function validateMetadata(componentMetadata: Partial<ComponentMetadata>): ComponentMetadata {
  const validatedMetadata: ComponentMetadata = {
    roles: componentMetadata.roles ?? DEFAULT_METADATA.roles,
    properties: componentMetadata.properties ?? DEFAULT_METADATA.properties,
    maxNumberOfConnectors:
      componentMetadata.maxNumberOfConnectors ?? DEFAULT_METADATA.maxNumberOfConnectors
  };
  return validatedMetadata;
}
