import { EntityId } from "../../../meta/models/entity";
import { Dictionary } from "../../../ts-utils";
import { TimeRange } from "../time-range";
import { CustomFilterValue } from "./filter-type-descriptor";

export class Filter {
  id: EntityId;
  timeRange: TimeRange;
  customFilters: Dictionary<CustomFilterValue>;
  isLiveMode: boolean;

  constructor(
    id: EntityId,
    timeRange: TimeRange,
    isLiveMode = false,
    customFilters: Dictionary<CustomFilterValue> = {}
  ) {
    this.id = id;
    this.timeRange = timeRange;
    this.customFilters = customFilters;
    this.isLiveMode = isLiveMode;
  }
}
