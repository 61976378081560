<div class="simple-status-indicator__wrapper" [ngSwitch]="status?.displayType?.typeName">
  <div class="display-type__wrapper">
    <img
      *ngSwitchCase="StateDisplayType.Image"
      class="image-display"
      [src]="imgSrc"
    />
    <icon
      *ngSwitchCase="StateDisplayType.Icon"
      [icon]="icon"
      [iconSize]="iconSize"
      [color]="color"
    ></icon>
    <i
      *ngSwitchCase="StateDisplayType.Shape"
      [ngClass]="classDictionary"
      [style.backgroundColor]="isBorderCircle() ? '#ccc' : color"
      [style.border]="'1px solid ' + color"
    ></i>
  </div>
</div>
