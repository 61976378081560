import { ValidationContext } from "../../meta/models/validation-context";

export const UNIT_PERCENTAGE = "%";
export const UNIT_PX = "px";
const WIDTH_REGEX = new RegExp(`^([0-9]+)((.[0-9]+)?)(${UNIT_PERCENTAGE}|${UNIT_PX})?$`);
const EMPTY_REGEX = new RegExp(`^$`);

export function validateInputForColumnWidth(
  expression: string,
  validationContext: ValidationContext
): boolean {
  return WIDTH_REGEX.test(expression) || EMPTY_REGEX.test(expression);
}
