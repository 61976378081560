import { LocalizationService } from "../../i18n/localization.service";
import { PropertyCategory } from "../../meta/models/property-category";
import {
  CategoryConfig,
  ItemInfo,
  SubcategoryConfig
} from "../../meta/models/property-sheet-config";
import { Maybe, isDefined, isEmpty, isNotDefined } from "../../ts-utils";

export function searchPropertyByPattern(
  categories: CategoryConfig,
  pattern: string,
  localizer: LocalizationService
): CategoryConfig {
  return Object.values(PropertyCategory).reduce(
    (acc: CategoryConfig, category: PropertyCategory) => {
      const subcategories: Maybe<SubcategoryConfig> = categories.get(category);
      const foundProperties = searchProperty(subcategories, pattern, localizer);
      if (foundProperties.size > 0) {
        acc.set(category, foundProperties);
      }
      return acc;
    },
    new Map<PropertyCategory, SubcategoryConfig>()
  );
}

export function searchProperty(
  subcategories: Maybe<SubcategoryConfig>,
  searchPattern: string,
  localizer: LocalizationService
): SubcategoryConfig {
  if (isNotDefined(subcategories)) {
    return new Map<string, ItemInfo[]>();
  }
  const subcategoryNames: string[] = Array.from(subcategories.keys());
  return subcategoryNames.reduce((acc: SubcategoryConfig, subcategory) => {
    const properties: Maybe<ItemInfo[]> = subcategories.get(subcategory);
    if (isDefined(properties)) {
      if (titleMatchPattern(subcategory, searchPattern, localizer)) {
        acc.set(subcategory, properties);
        return acc;
      }
      const matchedProperties: Maybe<ItemInfo[]> = properties.filter((itemInfo: ItemInfo) =>
        titleMatchPattern(itemInfo.propertyInfo.descriptor.displayName, searchPattern, localizer)
      );
      if (isDefined(matchedProperties) && !isEmpty(matchedProperties)) {
        acc.set(subcategory, matchedProperties);
      }
    }
    return acc;
  }, new Map<string, ItemInfo[]>());
}

export function titleMatchPattern(
  title: string,
  pattern: string,
  localizer: LocalizationService
): boolean {
  return localizer.get(title).toLowerCase().search(pattern.toLowerCase()) > -1;
}

export function filterByAdvancedMode(
  properties: CategoryConfig,
  advancedMode: boolean
): CategoryConfig {
  if (advancedMode) {
    return properties;
  }
  const categories: PropertyCategory[] = Object.values(PropertyCategory);
  return categories.reduce(
    (acc: Map<PropertyCategory, Map<string, ItemInfo[]>>, category: PropertyCategory) => {
      const subcategoryProps = properties.get(category);
      const foundProperties = getPropertiesInBasicMode(subcategoryProps);
      acc.set(category, foundProperties);
      return acc;
    },
    new Map<PropertyCategory, SubcategoryConfig>()
  );
}

export function getPropertiesInBasicMode(
  subcategories: Maybe<SubcategoryConfig>
): SubcategoryConfig {
  if (isNotDefined(subcategories)) {
    return new Map<string, ItemInfo[]>();
  }
  return Array.from(subcategories).reduce((acc: SubcategoryConfig, [subcategoryKey, items]) => {
    const filteredProperties: ItemInfo[] = items.filter(
      (itemInfo: ItemInfo) => !itemInfo.propertyInfo.descriptor.advancedMode
    );
    if (!isEmpty(filteredProperties)) {
      acc.set(subcategoryKey, filteredProperties);
    }
    return acc;
  }, new Map<string, ItemInfo[]>());
}
