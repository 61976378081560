import { DataConnectorRoles } from "ui-core";

export const Roles: DataConnectorRoles = {
  HeatmapValue: {
    name: "Heatmap Value",
    isDefaultRole: true
  },
  HistogramValue: {
    name: "Histogram Value",
    isDefaultRole: false
  }
};

