import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";

import { MatDialog } from "@angular/material/dialog";
import { ReportDialogComponent } from "../../components/report-dialog/report-dialog.component";
import { ReportDialogActions } from "../actions";
import { ViewReportConfigStrategy } from "../dialog-config.strategies";
import { OpenDialogEffect } from "./dialog.effects";

@Injectable()
export class ReportDialogEffects extends OpenDialogEffect {
  openAddAxisDialog$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportDialogActions.openReportAsPopup),
      tap((reportPath) => {
        this.dialogConfigStrategy = new ViewReportConfigStrategy();
        this.dialogConfig = this.dialogConfigStrategy.createDialogConfig(reportPath);
        this.dialogRef = this.dialog.open(ReportDialogComponent, this.dialogConfig);
      }),
      switchMap(() => {
        return this.dialogRef.afterClosed().pipe(
          map(() => {
            return ReportDialogActions.onCloseReportPopup();
          })
        );
      })
    )
  );

  constructor(private actions$: Actions, dialog: MatDialog) {
    super(dialog);
  }
}
