<select
  #selectElement
  (change)="onValueChange(selectElement.value)"
  [value]="selectedItem"
  [class.editor--value-configured]="distinctDefault"
>
  <option
    *ngFor="let item of items"
    [class.item--selected]="item == selectedItem"
    [value]="item"
    [selected]="item == selectedItem"
  >
    {{ item | translate }}
  </option>
</select>