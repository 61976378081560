import {
  BaseViewConfigDto,
  ComponentCssSize,
  Configurable,
  construct,
  DeepPartial,
  EditableType,
  EditorType,
  Serializable
} from "ui-core";

const TYPE_NAME = "AirgapProfileChartViewConfig";

@EditableType({ fullName: TYPE_NAME })
export class AirgapProfileChartViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  @Configurable({ displayName: "Nominal Airgap Color", editorType: EditorType.ColorPicker })
  @Serializable("pink")
  nominalAirgapColor!: string;

  @Configurable({ displayName: "Airgap Profile Color", editorType: EditorType.ColorPicker })
  @Serializable("#004C97")
  airgapProfileColor!: string;

  @Configurable({ displayName: "Scaled Down Rotor Color", editorType: EditorType.ColorPicker })
  @Serializable("#2ecafa")
  scaledDownRotorColor!: string;

  @Configurable({ displayName: "Rotor Center Color", editorType: EditorType.ColorPicker })
  @Serializable("red")
  rotorCenterColor!: string;

  @Configurable({ displayName: "Stator Center Color", editorType: EditorType.ColorPicker })
  @Serializable("#000000")
  statorCenterColor!: string;

  @Configurable({ displayName: "Subtitle", editorType: EditorType.TextBox })
  @Serializable("View from feed side")
  subtitle!: string;

  constructor(viewConfigDto: DeepPartial<AirgapProfileChartViewConfig> = {}) {
    super();
    construct(this, viewConfigDto, TYPE_NAME, { size: getDefaultSize() });
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("550", "550");
}
