<input
  #colorInput
  class="element--hidden"
  [(colorPicker)]="color"
  [cpOutputFormat]="'auto'"
  [cpFallbackColor]="'rgba(255,255,255,0)'"
  [cpExtraTemplate]="customTemplate"
  [cpToggle]="true"
  [cpDialogDisplay]="'inline'"
  (cpInputChange)="onInputChange($event)"
/>
<ng-template #customTemplate>
  <div class="dialog__custom-buttons">
    <button class="c-button c-button--primary" (click)="onSave()">
      <span>{{ localizer.propertySheet.Ok | translate }}</span>
    </button>
    <button class="c-button c-button--primary" (click)="onReset()">
      <span class="cancel-btn">{{ localizer.propertySheet.Reset | translate }}</span>
    </button>
    <button class="c-button" (click)="onCancel()">
      <span class="cancel-btn">{{ localizer.dialogs.Cancel | translate }}</span>
    </button>
  </div>
</ng-template>
