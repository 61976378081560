import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Actions, ofType } from "@ngrx/effects";
import { Subject } from "rxjs";
import { debounceTime, take, takeUntil, tap } from "rxjs/operators";
import { QueryStringService } from "../../../core";
import { Dispatcher } from "../../../dispatcher";
import { RuntimeSettingsSelector } from "../../../elements/services/entity-selectors/runtime-settings.selector";
import { RuntimeSettingsActions } from "../../../elements/store/runtime-settings/runtime-settings.actions";
import { LocalizationService } from "../../../i18n/localization.service";
import { EquipmentDialogInfo } from "../../../property-sheet/models/equipment-dialog-info";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { EquipmentBrowserDialogActions } from "../../dialogs/actions/equipment-browser-dialog.actions";
import { FilterToolbarElementConfig } from "../../models/button/filter-toolbar-element.config";
import { FilterType } from "../../models/filter-type";
@Component({
  selector: "root-path",
  templateUrl: "root-path.component.html",
  styleUrls: ["./root-path.component.scss"]
})
export class RootPathComponent implements OnInit, OnDestroy {
  @Input() isFilterBarSelected: boolean = false;
  @Input() canExpandHorizontally: boolean = false;
  @Output() showFilterBar: EventEmitter<any> = new EventEmitter();
  public toggleButton: FilterToolbarElementConfig;
  public rootPath: string;
  public rootClass: string;
  public rootPathChanged$: Subject<string> = new Subject<string>();
  private unsubscribeSubject$: Subject<any> = new Subject<any>();

  constructor(
    private actions$: Actions,
    private dispatcher: Dispatcher,
    public localizer: LocalizationService,
    public queryStringService: QueryStringService,
    private runtimeSettingsSelector: RuntimeSettingsSelector
  ) {}

  ngOnInit(): void {
    this.toggleButton = this.createToggleButtonConfig();
    this.initSubscriptions();
  }

  private createToggleButtonConfig(): FilterToolbarElementConfig {
    return new FilterToolbarElementConfig({
      icon: "Tree_view",
      class: "medium-label root-path__label--responsive",
      clickFunction: () => {
        return this.toggleFilterBar();
      }
    });
  }

  private initSubscriptions(): void {
    this.runtimeSettingsSelector
      .selectCurrentRootPath()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((rootPath: string) => {
        this.rootPath = rootPath;
      });

    this.rootPathChanged$
      .pipe(
        debounceTime(200),
        tap((rootPathValue: string) => {
          if (this.rootPath !== rootPathValue) {
            this.dispatcher.dispatch(
              RuntimeSettingsActions.setCurrentRootPath({
                currentRootPath: rootPathValue
              })
            );
          }
        }),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  closeBar(event: Event): void {
    this.toggleFilterBar();
    event.stopPropagation();
  }

  public toggleFilterBar(): void {
    this.showFilterBar.emit(FilterType.RootPath);
  }

  closeIfDropdown(): void {
    if (!this.canExpandHorizontally && this.isFilterBarSelected) {
      this.toggleFilterBar();
    }
  }

  editRootPath(): void {
    this.subscribeToEquipmentDialogClose();
    const equipmentDialogInfo: EquipmentDialogInfo = {
      currentPath: "",
      usePathBrowser: false,
      rootClass: this.rootClass,
      openFullTree: true
    };
    this.dispatcher.dispatch(
      EquipmentBrowserDialogActions.openEquipmentBrowserDialog({
        equipmentDialogInfo
      })
    );
  }

  private subscribeToEquipmentDialogClose(): void {
    this.actions$
      .pipe(ofType(EquipmentBrowserDialogActions.onEquipmentBrowserDialogClosed), take(1))
      .subscribe(({ result }) => {
        if (isDefined(result)) {
          this.dispatcher.dispatch(
            RuntimeSettingsActions.setCurrentRootPath({ currentRootPath: result })
          );
        }
      });
  }
}
