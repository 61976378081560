<div class="fleet-map-container">
  <div class="fleet-summary-container">
    <div id="summary-chart"></div>
    <div
      *ngIf="!tableViewLoading"
      class="fleet-view-wrapper"
    >
      <table
        mat-table
        multiTemplateDataRows
        [dataSource]="dataSource"
        class="fleet-view-table"
        aria-label="Summary table"
      >
        <ng-container
          *ngFor="let column of columnsToDisplay"
          matColumnDef="{{ column }}"
        >
          <th
            *matHeaderCellDef
            mat-header-cell
            class="table-row-header"
          >
            {{ column | capitalize }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="expand-detail"
            [ngClass]="{'flex-row ': column === 'mills'}"
          >
            <div
              *ngIf="column === 'status'"
              matListItemTitle
            >
              <div
                class="dot"
                [ngClass]="getColorByStatus(element.status)"
              >
              </div>
              [ {{ element.mills }} ] {{ element[column] }}
            </div>
            <i
              *ngIf="column === 'mills' && element.mills > 0"
              class="icon abb-icon"
              [ngClass]="setExpandIcon(element)"
            ></i>
            <span></span>
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td
            mat-cell
            *matCellDef="let element"
            [attr.colspan]="columnsToDisplay.length"
          >
            <div
              class="element-detail"
              [@detailExpand]="element == expandedElement && element.mills > 0 ? 'expanded' : 'collapsed'"
            >
              <div class="element-description">
                <div class="description-card">
                  <table
                    #detailsTable
                    mat-table
                    [dataSource]="detailsSource"
                    class="mat-elevation-z8 details-table"
                    aria-label="Summary details"
                  >
                    <ng-container matColumnDef="plantName">
                      <th
                        *matHeaderCellDef
                        mat-header-cell
                        class="table-row-header"
                      >
                        Plant Name
                      </th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.plantName }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="mill">
                      <th
                        *matHeaderCellDef
                        mat-header-cell
                        class="table-row-header"
                      >
                        Mill
                      </th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.motor }}
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="detailsColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: detailsColumns;"></tr>
                  </table>
                </div>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr
          mat-row
          *matRowDef="let element; columns: columnsToDisplay;"
          class="element-row"
          [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element; setDetailsSource(element.details)"
        >
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
      </table>
    </div>
  </div>
  <div class="google-map-container">
    <google-map
      [center]="center"
      [options]="mapOptions"
    >
      <map-marker
        #marker
        *ngFor="let group of groupMarkers; let i = index"
        [options]="markerOptions"
        [position]="group.markerOptions.position"
        [icon]="group.markerOptions.icon"
        [label]="group.markerOptions.label"
        (mapMouseover)="onMarkerHover(group, i)"
      >
        <map-info-window>
          <div *ngIf="activeGroupMarker?.plantsMarker">
            <ng-container *ngFor="let plant of activeGroupMarker.plantsMarker">
              <div mat-subheader>
                <a
                  class="customer-link"
                  (click)="onCustomerClick(plant)"
                >
                  {{ plant.plantData.projectShortName }}
                </a>
                <span [className]="'fi fi-' + plant.plantData.countryCode"></span>
              </div>
              <mat-list role="list">
                <mat-list-item *ngFor="let motor of plant.motors">
                  <div matListItemTitle>
                    <div
                      class="dot"
                      [ngClass]="getColorByStatus(motor.systemStatus)"
                    >
                    </div>
                    <a (click)="onMotorClick(motor)">
                      {{ motor.systemName }}
                    </a>
                  </div>
                </mat-list-item>
              </mat-list>
            </ng-container>
          </div>
        </map-info-window>
      </map-marker>
    </google-map>
  </div>
</div>
