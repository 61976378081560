<div (clickOutside)="closeMenu()">
  <div *ngIf="helpService.hasHelpDocument">
    <header-button
      [buttonConfig]="moreButton"
      [isSelected]="isMenuOpened"
      icon="Help_circle_1"
    ></header-button>
  </div>
  <ul
    class="project-info__list-style"
    *ngIf="isMenuOpened || !helpService.hasHelpDocument"
    [ngClass]="{ 'project-info__menu': helpService.hasHelpDocument }"
  >
    <li>
      <header-button
        [verticallyOpened]="true"
        [buttonConfig]="aboutButton"
        [showLabel]="isMenuOpened"
        icon="Information_circle_1"
      ></header-button>
    </li>
    <li *ngIf="hasHelpPage">
      <header-button
        [verticallyOpened]="true"
        [buttonConfig]="helpButton"
        [showLabel]="isMenuOpened"
        icon="Help_circle_1"
      >
      </header-button>
    </li>
    <li *ngIf="hasHelpLanding">
      <header-button
        [verticallyOpened]="true"
        [buttonConfig]="helpLandingButton"
        [showLabel]="isMenuOpened"
        icon="Help_circle_1"
      ></header-button>
    </li>
    <li *ngIf="hasReleaseNotes">
      <header-button
        [verticallyOpened]="true"
        [buttonConfig]="releaseNotesButton"
        [showLabel]="isMenuOpened"
        icon="Information_circle_1"
      ></header-button>
    </li>
    <li *ngIf="hasReleaseNotes">
      <header-button
        [verticallyOpened]="true"
        [buttonConfig]="creditsButton"
        [showLabel]="isMenuOpened"
        icon="Group"
      ></header-button>
    </li>
  </ul>
</div>
