<table class="credits_table">
  <tr>
    <th class="credits_table__header-title">CREDITS</th>
  </tr>
  <br />
  <tr *ngFor="let credit of credits; let i = index" class="credits_table__credit">
    <td>
      <div class="credit__header">
        <b> {{ credit.dep }}</b>
        <a href="{{ credit.homepage }}" target="_blank" class="credit__header-homepage">homepage</a>
        <u (click)="flags[i] = !flags[i]" class="credit__header-license">
          {{ flags[i] ? "hide license" : "show license" }}</u
        >
      </div>
      <div *ngIf="flags[i]" class="credit__license-info">
        {{ credit.license }}
      </div>
    </td>
  </tr>
</table>
