import { createSelector } from "@ngrx/store";
import { BrowsingState } from "../browsing.state";
import { getCachedFeature } from "../feature.selector";

export const selectDataExplorerModel = createSelector(
  getCachedFeature,
  (state: BrowsingState) => state.dataExplorer
);

export const selectSignalTabMode = createSelector(
  selectDataExplorerModel,
  (dataExplorerModel) => dataExplorerModel.isSignalTabSelected
);

export const selectSignalSearchPattern = createSelector(
  selectDataExplorerModel,
  (dataExplorerModel) => dataExplorerModel.signalBrowserState.searchPattern
);

export const selectLastUsedSignal = createSelector(
  selectDataExplorerModel,
  (dataExplorerModel) => dataExplorerModel.signalBrowserState.lastSelectedSignal
);

export const selectEquipment = createSelector(
  selectDataExplorerModel,
  (dataExplorerModel) => dataExplorerModel.equipmentBrowserState.equipment
);

export const selectPropertyBrowserMode = createSelector(
  selectDataExplorerModel,
  (dataExplorerModel) => dataExplorerModel.equipmentBrowserState.isPropertyBrowserExpanded
);

export const selectEquipmentTreeModel = createSelector(
  selectDataExplorerModel,
  (dataExplorerModel) => dataExplorerModel.equipmentBrowserState.treeModel
);

export const selectLastUsedEquipmentProperty = createSelector(
  selectDataExplorerModel,
  (dataExplorerModel) => dataExplorerModel.equipmentBrowserState.lastSelectedProperty
);
