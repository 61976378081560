export class SnackBarMessagesLocalizationDictionary {
  Close: string = "Close";
  Save: string = "Save";
  Copy: string = "Copy";
  Cut: string = "Cut";
  SavingError: string = "SavingError";
  SaveAs: string = "SaveAs";
  SaveAsError: string = "SaveAsError";
  AncestorsError: string = "AncestorsError";
  HttpError: string = "HttpError";
  InvalidUrl: string = "InvalidUrl";
  GetManyEquipmentError: string = "GetManyEquipmentError";
  SendingLogsRequestsError: string = "SendingLogsRequestsError";
  InvalidDataQuery: string = "InvalidDataQuery";
  GettingGenericDataSourcesError: string = "GettingGenericDataSourcesError";
  GettingApiDataSourcesError: string = "GettingApiDataSourcesError";
  InvalidTimeRangeError: string = "InvalidTimeRangeError";
  NotSupportedWarning: string = "NotSupportedWarning";
  GettingEquipmentSignalsError: string = "GettingEquipmentSignalsError";
  GettingDynamicConnectorsError: string = "GettingDynamicConnectorsError";
  GettingPeriodTypesError: string = "GettingPeriodTypesError";
  LoadingReportConfigError: string = "LoadingReportConfigError";
  InvalidFilter: string = "InvalidFilter";
  InvalidReportName: string = "InvalidReportName";
  ReportDuplicationWarning: string = "ReportDuplicationWarning";
  GettingImageError: string = "GettingImageError";
  SendFeedbackSuccess: string = "SendFeedbackSuccess";
  SendFeedbackFail: string = "SendFeedbackFail";
  ClipboardCopy: string = "ClipboardCopy";
  BorderUpgrade: string = "BorderUpgrade";
  LinkingWidgetInstruction: string = "LinkingWidgetInstruction";

  constructor() {
    Object.keys(this).forEach((key) => {
      const thisKey = key as keyof SnackBarMessagesLocalizationDictionary;
      this[thisKey] = `SnackBarMessages.${thisKey}`;
    });
  }
}
