import { DataTransferObject } from "../../core/models/data-transfer-object";
import { DeepPartial } from "../../ts-utils/models/deep-partial.type";
import { EditableType } from "../decorators/editable-type.decorator";
import { Key } from "../decorators/key.decorator";
import { Serializable } from "../decorators/serializable.decorator";

// IP move to core

export type EntityId = string | number;

const TYPE_NAME = "Entity";

@EditableType({ fullName: TYPE_NAME })
export class Entity implements DataTransferObject {
  typeName = TYPE_NAME;

  @Key()
  @Serializable()
  public readonly id: EntityId;
}

export type PartialEntity<T extends Entity> = DeepPartial<T> & Entity;

export function isEntity(object: any): object is Entity {
  return object && typeof object.id !== "undefined" && object.id !== null;
}
