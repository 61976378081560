import { createAction, props } from "@ngrx/store";
import { PeriodType } from "../../models";

export namespace PeriodTypeActions {
  export const addMany = createAction(
    "[Period Type] Add Many",
    props<{ periodTypes: PeriodType[] }>()
  );

  export const load = createAction("[Period Type] Load");
}
