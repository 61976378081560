import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { DateFormatterService } from "./services/date-formatter.service";
import { EnvironmentSelector } from "./services/environment.selector";
import { ENVIRONMENT_FEATURE } from "./store/feature.selector";
import { reducers } from "./store/index.reducer";
import { TimeInfoEffects } from "./store/time-info/time-info.effects";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(ENVIRONMENT_FEATURE, reducers),
    EffectsModule.forFeature([TimeInfoEffects])
  ],
  declarations: []
})
export class EnvironmentModule {
  static forRoot(): ModuleWithProviders<EnvironmentModule> {
    return {
      ngModule: EnvironmentModule,
      providers: [EnvironmentSelector, DateFormatterService]
    };
  }
}
