import { Injectable } from "@angular/core";
import { DisplayStrategies as CategoryDisplayStrategies } from "../../elements/models/display-strategies/category-display-strategies";
import { DisplayStrategies as TimeSeriesDisplayStrategies } from "../../elements/models/display-strategies/time-series-display-strategies";
import { TIME_SERIES } from "../../elements/models/element-type.constants";
import { isDefined } from "../../ts-utils/helpers/predicates.helper";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class RenameDisplayStrategies implements UpgradeStep {
  name = "RenameDisplayStrategies";
  fromVersion = new Version(0, 0, 2);

  perform(oldConfig: any): UpgradeStepResult {
    const componentStates: any = oldConfig["componentStates"]["entities"];
    let changed = false;
    Object.values(componentStates).forEach((component: any) => {
      const view = component["view"];
      const strategy = isDefined(view) ? view["displayStrategy"] : null;
      switch (component["type"]) {
        case TIME_SERIES: {
          if (strategy === "TimeSeriesVerticalBar") {
            view.displayStrategy = TimeSeriesDisplayStrategies.Column;
            changed = true;
          } else if (strategy === "TimeSeriesHorizontalBar") {
            view.displayStrategy = TimeSeriesDisplayStrategies.Bar;
            changed = true;
          }
          break;
        }
        case "CategoryComponent": {
          if (strategy === "StackedColumn") {
            view.displayStrategy = CategoryDisplayStrategies.CategoryVerticalBar;
            changed = true;
          }
          break;
        }
      }
    });
    return {
      result: oldConfig,
      modified: changed,
      warning: null
    };
  }
}
