import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap } from "rxjs/operators";
import { FleetViewDataService } from "../../services/sidebar/fleet-view.service";
import { ClientDataActions } from "../actions/client-data.actions";

@Injectable()
export class ClientDataEffects {

  clientDataCollection$ = createEffect(() => this.actions$.pipe(
    ofType(ClientDataActions.getClientDataAction),
    switchMap((action) => {
      return this.fleetViewDataService.getClientData(action.motorStatus).pipe(
        map(clientData => ClientDataActions.setClientData({ clientData }))
      )
    })
  ))

  constructor(
    private actions$: Actions,
    private fleetViewDataService: FleetViewDataService
  ) { }
}