import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DraggedItem } from "../../../core";
import { DroppableElement } from "../../../core/models/droppable-element";
import { IDragDropService } from "../../../core/services/i-drag-drop.service";
import { EnvironmentSelector } from "../../../environment";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";

@Component({
  selector: "c-item-browser",
  template: ""
})
export class ItemBrowserComponent implements OnInit, OnDestroy {
  selectedItem: DroppableElement;
  aliasMode: boolean = false;
  protected unsubscribeSubject$: Subject<any> = new Subject();

  constructor(
    protected dragDropService: IDragDropService,
    protected environmentSelector: EnvironmentSelector
  ) {}

  ngOnInit(): void {
    this.getAliasMode();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  private getAliasMode(): void {
    this.environmentSelector
      .selectAliasMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((isAliasMode: boolean) => (this.aliasMode = isAliasMode));
  }

  dragStart(event, draggableItem: DroppableElement, rootPath: string = "") {
    const dragTarget: Maybe<DraggedItem> = this.getDragTarget(draggableItem);
    if (this.isSelectedItem(draggableItem) && isDefined(dragTarget)) {
      this.dragDropService.enableDrag = true;
      this.dragDropService.setDragTarget(dragTarget, rootPath);
    } else {
      this.dragDropService.enableDrag = false;
    }
  }

  dragEnd() {
    this.dragDropService.dragEnd();
  }

  protected getDragTarget(draggableItem: DroppableElement): Maybe<DraggedItem> {
    return null;
  }

  dropOnTouchDevices(event) {
    this.dragDropService.touchEnd(event);
  }

  touchMove(event) {
    this.dragDropService.touchMove(event);
  }

  setSelectedItem(draggableItem: DroppableElement) {
    this.selectedItem = draggableItem;
  }

  isSelectedItem(draggableItem: DroppableElement): boolean {
    return this.selectedItem === draggableItem;
  }
}
