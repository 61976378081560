import { Injectable } from "@angular/core";
import { ConfigurationService, EnvironmentName } from "ui-core";
import { AzureService } from "../app/services/azure.service";
import { AzureAPIConfigurationService } from "../app/services/core/azure-api-configuration.service";
import { AzureStatusConverter } from "../app/services/core/azure-status.converter";
import { RdsDefaultConfigurationService } from "../app/services/core/rds-default-configuration.service";
import { RdsFeedbackService } from "../app/services/core/rds-feedback.service";

@Injectable()
export class RdsProdApiConfiguration extends AzureAPIConfigurationService {
  constructor() {
    super();

    this.pushDataServiceUrl = environment.signalRUri;
    this.itemsServiceUrl = environment.apiUri;

    super.generateRemainingItems();
  }
}

export const environment = {
  signalRUri: "/signalr/hubs/telemetry",
  apiUri: "/api/serving",
  name: EnvironmentName.Rds,
  production: false,
  appTitle: "Predictive Maintenance for Grinding",
  dataService: AzureService,
  liveMode: "push",
  authUri: "https://dev.condition-monitoring.mining.ability.abb.com/",
  statusConverter: AzureStatusConverter,
  webServicesConfiguration: RdsProdApiConfiguration,
  defaultConfiguration: RdsDefaultConfigurationService,
  configurationService: ConfigurationService,
  telemetryInstrumentationKey: "0ed6407d-db47-44ac-8843-d88ab4da5571",
  googleApiKey: "AIzaSyBRybc6xXYpXPvYgDhkny26Er_I_TciuhQ",
  feedbackService: RdsFeedbackService,
  AADAppClientId: "2bdf68c0-e1ab-4dbe-8959-ebf1629dfcd2"
};

export const buildSpecificModules = [];
