<div class="button__wrapper">
  <button
    (click)="resolveLink()"
    [ngStyle]="buttonStyle"
    [disabled]="!hasLink"
    [class.button--hovered]="!isEditable && hasLink"
  >
    {{ view.title }}
  </button>
</div>
