import { AfterViewInit, Component, Inject, OnDestroy, ViewChild } from "@angular/core";
import { MatOption } from "@angular/material/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatListOption, MatSelectionList, MatSelectionListChange } from "@angular/material/list";
import { MatSelect, MatSelectChange } from "@angular/material/select";
import { from, of, Subject } from "rxjs";
import { debounceTime, map, switchMap, takeUntil } from "rxjs/operators";
import { BaseDialogComponent } from "../../../../../shared/components/base-dialog/base-dialog.component";
import { RemovePlotlineData } from "../../../../models/dialogs/remove-plotline-data.model";

@Component({
  selector: "c-remove-plotline-dialog",
  templateUrl: "./remove-plotline-dialog.component.html",
  styleUrls: ["./remove-plotline-dialog.component.scss"]
})
export class RemovePlotlineDialogComponent
  extends BaseDialogComponent
  implements AfterViewInit, OnDestroy
{
  @ViewChild("axisList", { static: true }) axisList: MatSelect;
  @ViewChild("plotlineList") plotlineList: MatSelectionList;

  public axisArray: any[] = [];
  public plotlineArray: any[] = [];
  public plotlinesPresent = false;
  public noValuesSelected: boolean;

  unsubscribeSubject$: Subject<any> = new Subject<any>();

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<RemovePlotlineDialogComponent>
  ) {
    super();
    this.axisArray = data.axisArray;
    this.noValuesSelected = true;
  }
  ngAfterViewInit(): void {
    const DEBOUNCE_TIME = 50;
    from(this.axisList.selectionChange)
      .pipe(
        debounceTime(DEBOUNCE_TIME),
        switchMap((selectedAxis: MatSelectChange) => {
          if (!this.plotlineList) {
            return of(false);
          }
          return from(this.plotlineList.selectionChange).pipe(
            map((plotlines: MatSelectionListChange) => {
              if (selectedAxis) {
                const hasPlotlinesSelected: boolean =
                  plotlines.source.selectedOptions.selected.length > 0 ? true : false;
                return hasPlotlinesSelected;
              }
              return false;
            })
          );
        }),

        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe((enabled: boolean) => {
        this.noValuesSelected = !enabled;
      });
  }

  onDialogClosed(): void {
    if (!this.axisList || !this.plotlineList) {
      return;
    }
    const plotlineToRemove: RemovePlotlineData = {
      axisId: this.getValueFromSelect(this.axisList),
      plotlineIds: this.getValuesFromListOptions(this.plotlineList.selectedOptions.selected)
    };

    this.dialogRef.close(plotlineToRemove);
  }

  public getValuesFromListOptions(listOptions: MatListOption[]) {
    return listOptions.map((listOption) => listOption.value);
  }

  public getPlotlinesForAxis(axisId: string) {
    const selectedAxis = this.axisArray.find((axis) => axis.id === axisId);
    if (selectedAxis) {
      this.plotlineArray = selectedAxis.plotLines;
    } else {
      this.plotlineArray = [];
    }
    this.plotlinesPresent = this.plotlineArray.length ? true : false;
  }

  public getValueFromSelect(comboBox: MatSelect) {
    if (comboBox.selected) {
      return (comboBox.selected as MatOption).value;
    } else {
      return null;
    }
  }
}
