import { Component, HostBinding } from "@angular/core";
import { OfType } from "../../../meta/decorators/of-type.decorator";
import { EditorType } from "../../../meta/models/editor-type";
import { isEmptyOrNotDefined2 } from "../../../ts-utils/helpers/is-empty.helper";
import { NUMBER_REGEX } from "../../../ts-utils/helpers/number.helper";
import { TextBoxEditorComponent } from "../text-box-editor/text-box-editor.component";

@Component({
  selector: "number-editor",
  templateUrl: "number-editor.component.html",
  styleUrls: ["number-editor.component.scss"]
})
@OfType(EditorType.Number)
export class NumberEditorComponent extends TextBoxEditorComponent {
  @HostBinding("attr.title")
  public get tooltipText(): string {
    return this.tooltip;
  }

  onValueChanged(value: string): void {
    if (isEmptyOrNotDefined2(value)) {
      super.onValueChanged(null);
    } else if (this.isValidNumber(value)) {
      super.onValueChanged(Number(value));
    }
  }

  private isValidNumber(value: string): boolean {
    return NUMBER_REGEX.test(value);
  }
}
