import { Type } from "@angular/core";
import "reflect-metadata";
import { BaseComponent } from "..";
import { TypeProvider } from "../../meta/services/type-provider";
import { ConstructorFunction } from "../../ts-utils";
import { BaseViewConfigDto } from "../models";
import { ViewConfigProvider } from "../services/view-config-provider";

const TYPE_PROVIDER: TypeProvider = TypeProvider.getInstance();
const VIEW_CONFIG_PROVIDER = ViewConfigProvider.getInstance();

// IMPORTANT: temporary solution becuase of accessors, switch back to <T extends BaseViewConfig>
export function View<T extends BaseViewConfigDto>(type: Type<T>) {
  return function (proto: Object, key: string) {
    const componentTypeDescriptor = TYPE_PROVIDER.addType({
      constructorFunction: proto.constructor as ConstructorFunction<BaseComponent>
    });
    const viewTypeDescriptor = TYPE_PROVIDER.addType({
      constructorFunction: type as ConstructorFunction<BaseViewConfigDto>
    });
    VIEW_CONFIG_PROVIDER.add({
      viewConfigNativeType: type,
      viewConfigType: viewTypeDescriptor,
      componentType: componentTypeDescriptor
    });
  };
}
