import { Injectable } from "@angular/core";
import { DataConnectorDto } from "../../data-connectivity";
import { getConnectorDtoViewId } from "../../data-connectivity/helpers/data-connector-view.helper";
import { DataConnectorViewDto } from "../../data-connectivity/models/data-connector-view";
import {
  endMeasurement,
  startMeasurement
} from "../../meta/helpers/performance-measurement.helper";
import { BaseViewConfigDto } from "../models/base-view-config";
import { IComponentConfigDescriptor } from "../models/component-config-descriptor";
import { DataConnectorDescriptor } from "../models/store/data-connector-descriptor";
import { DynamicDefaultsEvaluatorCore } from "./dynamic-defaults-evaluator-core";
import { DataConnectorViewSelector } from "./entity-selectors/data-connector-view.selector";
import { IComponentDataAccessor } from "./i-component-data-accessor";

@Injectable()
export class DynamicDefaultsEvaluator {
  constructor(
    private evaluator: DynamicDefaultsEvaluatorCore,
    private dataConnectorViewSelector: DataConnectorViewSelector
  ) {}

  public collectAndEvaluate<TView extends BaseViewConfigDto>(
    viewConfig: TView,
    dataAccessor: IComponentDataAccessor
  ): IComponentConfigDescriptor<TView> {
    startMeasurement("collectAndEvaluate");
    const allConnectors = dataAccessor.getAllConnectors();
    const allConnectorViews = this.dataConnectorViewSelector.getManyById(
      allConnectors.map((dc) => getConnectorDtoViewId(dc))
    );
    const allConnectorDescriptors = allConnectors.map((dc) => {
      const dcView = allConnectorViews[getConnectorDtoViewId(dc)];
      return {
        connector: dc,
        connectorView: dcView
      };
    });

    const evaluated = this.evaluateAll({
      viewConfig,
      connectorDescriptors: allConnectorDescriptors
    });
    endMeasurement("collectAndEvaluate");
    return evaluated;
  }

  private evaluateAll<TView extends BaseViewConfigDto>(
    componentConfigDescriptor: IComponentConfigDescriptor<TView>
  ): IComponentConfigDescriptor<TView> {
    const evaluatedConnectorDescriptors: DataConnectorDescriptor[] =
      componentConfigDescriptor.connectorDescriptors.map(
        (connectorDesc): DataConnectorDescriptor =>
          this.evaluateDataConnectorDescriptor(connectorDesc)
      );

    const evaluatedViewConfig = this.evaluateViewConfig(
      componentConfigDescriptor.viewConfig,
      componentConfigDescriptor.connectorDescriptors
    );

    return {
      viewConfig: evaluatedViewConfig,
      connectorDescriptors: evaluatedConnectorDescriptors
    };
  }

  evaluateDataConnectorDescriptor(
    connectorDescriptor: DataConnectorDescriptor
  ): DataConnectorDescriptor {
    return {
      connector: this.evaluateDataConnector(connectorDescriptor),
      connectorView: this.evaluateConnectorView(connectorDescriptor)
    };
  }

  evaluateDataConnector(connectorDescriptor: DataConnectorDescriptor): DataConnectorDto {
    return this.evaluator.evaluate(connectorDescriptor.connector!, [
      connectorDescriptor
    ]) as DataConnectorDto;
  }

  evaluateConnectorView(connectorDescriptor: DataConnectorDescriptor): DataConnectorViewDto {
    return this.evaluator.evaluate(connectorDescriptor.connectorView!, [
      connectorDescriptor
    ]) as DataConnectorViewDto;
  }

  public evaluateViewConfig<T extends BaseViewConfigDto>(
    viewConfig: T,
    connectorDescriptors: DataConnectorDescriptor[]
  ): T {
    return this.evaluator.evaluate(viewConfig, connectorDescriptors) as T;
  }
}
