import { Injectable } from "@angular/core";
import { EmptyLinkDto, ExternalLinkDto, ReportLinkDto } from "../../core";
import { isDefined, isNotDefined } from "../../ts-utils";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class UpdateLinks implements UpgradeStep {
  name = "UpdateLinks";
  fromVersion = new Version(1, 0, 0);

  perform(oldConfig: any): UpgradeStepResult {
    const componentStates = oldConfig?.["componentStates"]?.["entities"];
    if (isNotDefined(componentStates)) {
      return {
        result: oldConfig,
        modified: false,
        warning: null
      };
    }
    let changed = false;
    Object.values(componentStates)
      .map((componentState: any) => componentState.view)
      .map((view: any) => {
        if (isDefined(view.link)) {
          changed = true;
          view.link = createValidLink(view.link);
        }
        // navbar
        if (isDefined(view.links)) {
          view.links.map(
            (navLinkInfo: any) => (navLinkInfo.link = createValidLink(navLinkInfo.link))
          );
        }
        // system-overview
        if (isDefined(view.detailsLink)) {
          view.detailsLink = createValidLink(view.detailsLink);
        }
      });
    return {
      result: oldConfig,
      modified: changed,
      warning: null
    };
  }
}

function createValidLink(link: any): any {
  if (isNotDefined(link)) {
    return { typeName: "EmptyLinkDto" } as EmptyLinkDto;
  } else if (typeof link.reportId !== "undefined") {
    return {
      typeName: "ReportLinkDto",
      info: { reportId: link.reportId, reportName: link.reportName },
      rootPath: link.rootPath
    } as ReportLinkDto;
  } else if (typeof link.info !== "undefined") {
    return {
      typeName: "ReportLinkDto",
      info: link.info,
      rootPath: link.rootPath
    } as ReportLinkDto;
  } else if (typeof link.url !== "undefined") {
    return { typeName: "ExternalLinkDto", url: link.url } as ExternalLinkDto;
  } else {
    return { typeName: "EmptyLinkDto" } as EmptyLinkDto;
  }
}
