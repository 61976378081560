import { Component, Inject } from "@angular/core";
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { REPORT_NAME_REGEX } from "projects/ui-core/src/lib/shared/services/report-creation.helper";

export function validateReportName(reportName: string): boolean {
  return reportName.trim() !== "" && REPORT_NAME_REGEX.test(reportName);
}

@Component({
  selector: "dialog-content",
  templateUrl: "dialog.component.html",
  styleUrls: ["dialog.component.scss"]
})
export class DialogComponent {
  reportForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {

    let validators: ValidatorFn[] = [];

    if (data.type === "rename") {
      validators = [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30),
        this.reportNameValidator()
      ];
    }

    this.reportForm = new UntypedFormGroup({
      reportInput: new UntypedFormControl(data.name, validators)
    });
  }

  get formControls() {
    return this.reportForm.controls["reportInput"];
  }

  reportNameValidator(): ValidatorFn {
    return (symbols: AbstractControl): { [key: string]: boolean } | null => {
      return validateReportName(symbols.value) ? null : { reportName: true };
    };
  }

  invalidExpression() {
    return !this.reportForm.valid;
  }

  onSubmit(action) {
    if (!this.invalidExpression()) {
      this.onNoClick(action);
    }
  }

  onNoClick(action?): void {
    let body;
    if (action) {
      body = {
        action,
        reportName: this.reportForm.value.reportInput
      };
    }

    this.dialogRef.close(body);
  }
}
