import { Component } from "@angular/core";
import { take } from "rxjs/operators";
import { OfType } from "../../../meta/decorators";
import { EditorType } from "../../../meta/models";
import { isDefined, isNotDefined } from "../../../ts-utils/helpers/predicates.helper";
import { FileSelectionEditorComponent } from "../file-selection-editor/file-selection-editor.component";

@Component({
  selector: "image-selection-editor",
  templateUrl: "../file-selection-editor/file-selection-editor.component.html",
  styleUrls: ["../file-selection-editor/file-selection-editor.component.scss"]
})
@OfType(EditorType.ImageSelection)
export class ImageSelectionEditorComponent extends FileSelectionEditorComponent {
  protected allowedFileType(fileType: string): boolean {
    return fileType.match(/image\/*/) != null;
  }

  protected processFile(file: File): void {
    if (isNotDefined(file)) {
      return;
    }
    this.dataService
      .saveImage(file)
      .pipe(take(1))
      .subscribe((savedImage) => {
        if (isDefined(savedImage)) {
          const imageUrl = this.webConfigService.imageGetUrl + `/${savedImage}`;
          this.setDisplayValue(imageUrl);
        }
      });
  }
}
