import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Maybe } from "../../ts-utils";
import { ReportId } from "../models";

@Injectable()
export class IReportInfoSelector {
  public selectReportId(): Observable<Maybe<ReportId>> {
    throw new Error("Not implemented");
  }

  public getReportId(): ReportId {
    throw new Error("Not implemented");
  }
}
