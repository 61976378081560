<h1 mat-dialog-title>Icon Picker</h1>
<div mat-dialog-content>
  <mat-grid-list [cols]="numRows" gutterSize="1" class="icon-browser__icons-grid">
    <ng-template ngFor let-icon [ngForOf]="enumeratedIcons">
      <mat-grid-tile class="zoom">
        <icon [icon]="icon" (click)="onIconClick(icon)"></icon>
      </mat-grid-tile>
    </ng-template>
  </mat-grid-list>
</div>
