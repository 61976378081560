<div class="link-editor__wrapper">
  <input
    #editorInput
    class="editor--discreet-input"
    [class.editor--value-configured]="value !== propertyInfo.descriptor.defaultValue"
    (click)="openDialog()"
    [value]="reportName"
    [readonly]="true"
  />
  <i class="abb-icon Close clear-button abb-icon--tiny" (click)="clearLink()"></i>
</div>
