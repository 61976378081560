import { Injectable } from "@angular/core";
import {
  getDefaultFilterIdForEntity,
  RUNTIME_FILTER_ID
} from "../../../core/helpers/filter/filter-id.helper";
import { FilterConfigurationDto } from "../../../core/models/filter/filter-configuration";
import { IFilterSelector } from "../../../core/services/filter/i-filter.selector";
import { DataSourceDeserializer } from "../../../data-connectivity/services/deserializers/data-source.deserializer";
import { EntityId } from "../../../meta/models/entity";
import { IEntityConverter } from "../../../meta/services/entity-converter";
import { isDefined, Maybe } from "../../../ts-utils";
import { createViewConfig } from "../../helpers/view-config-creation.helper";
import { ComponentStateDto } from "../../models/component-state";
import { ComponentStateViewModel } from "../../models/component-state.vm";
import { ComponentStateSelector } from "../entity-selectors/component-state.selector";
import { DataConnectorSelector } from "../entity-selectors/data-connector.selector";

@Injectable()
export class ComponentStateViewModelDeserializer
  implements IEntityConverter<ComponentStateDto, ComponentStateViewModel>
{
  constructor(
    private dataSourceDeserializer: DataSourceDeserializer,
    private componentStateSelector: ComponentStateSelector,
    private dataConnectorSelector: DataConnectorSelector,
    private filterSelector: IFilterSelector
  ) {}

  convert(componentStateDto: ComponentStateDto): ComponentStateViewModel {
    const componentVM: ComponentStateViewModel = new ComponentStateViewModel(componentStateDto);
    componentVM.dataConnectors = this.dataConnectorSelector.getForComponent(componentStateDto.id);
    const children: ComponentStateDto[] = componentStateDto.childrenIds
      .map((childId) => this.componentStateSelector.getById(childId))
      .filter(isDefined);
    componentVM.children = children.map((child: ComponentStateDto) => this.convert(child));

    if (isDefined(componentVM.dataConnectorQuery)) {
      componentVM.dataConnectorQuery = this.dataSourceDeserializer.convert(
        componentStateDto.dataConnectorQuery
      );
    }

    componentVM.filterConfig = this.getOrCreateComponentFilter(
      componentStateDto.id,
      componentStateDto.filterId,
      this.filterSelector
    );
    componentVM.view = createViewConfig(componentStateDto.type, componentStateDto.view);
    return componentVM;
  }

  private getOrCreateComponentFilter(
    componentStateId: EntityId,
    filterId: Maybe<EntityId>,
    filterSelector: IFilterSelector
  ): FilterConfigurationDto {
    if (isDefined(filterId)) {
      return filterSelector.getById(filterId);
    } else {
      return new FilterConfigurationDto({
        id: getDefaultFilterIdForEntity(componentStateId),
        sourceFilterId: RUNTIME_FILTER_ID
      });
    }
  }
}
