import { Injectable } from "@angular/core";
import { Equipment } from "../../core/models/equipment";
import { EquipmentProperty } from "../../core/models/equipment-property";
import { formatEquipmentPropertyPath } from "../../core/services/equipment-path-formatter";
import { createEquipmentDataSource } from "../../data-connectivity/helpers/data-source-creation.helper";
import { CommonDataPropertyNames } from "../../data-connectivity/models/common-data-properties";
import { DataConnectorDto } from "../../data-connectivity/models/data-connector";
import { EquipmentDataSourceDto } from "../../data-connectivity/models/data-source/equipment-data-source";
import { SignalInfoDto } from "../../data-connectivity/models/signal-info";
import { ComponentMetadataService } from "../../data-connectivity/services/component-metadata.service";
import { EquipmentToDataConnectorsConverter } from "../../data-connectivity/services/equipment-to-data-connectors.converter";
import { EntityId } from "../../meta/models/entity";
import { isNotDefined } from "../../ts-utils/helpers";
import { setDataStatusFromPoints } from "../helpers/connectors.helper";
import { ComponentStateDto } from "../models/component-state";

@Injectable()
export class ComponentEquipmentToDataConnectorsConverter extends EquipmentToDataConnectorsConverter {
  constructor(private metadataProvider: ComponentMetadataService) {
    super();
  }

  public convert(
    equipmentLists: Equipment[][],
    equipmentQueryDict: EquipmentDataSourceDto[]
  ): DataConnectorDto[][] {
    return equipmentLists.map((equipments, index) => {
      return equipments.reduce((acc: DataConnectorDto[], equipment: Equipment) => {
        acc.push(...this.convertEquipmentToDataConnectors(equipment, equipmentQueryDict[index]));
        return acc;
      }, []);
    });
  }

  private convertEquipmentToDataConnectors(
    equipment: Equipment,
    equipmentQuery: EquipmentDataSourceDto
  ): DataConnectorDto[] {
    const ownProperties = equipment.properties.map((equipmentProperty: EquipmentProperty) =>
      ComponentEquipmentToDataConnectorsConverter.mapProperty(
        equipmentQuery,
        equipment,
        equipmentProperty,
        null
      )
    );

    if (equipment.children != null) {
      const childProperties = equipment.children
        .map((child: Equipment) => this.convertEquipmentToDataConnectors(child, equipmentQuery))
        .flat();
      return ownProperties.concat(childProperties);
    } else {
      return ownProperties;
    }
  }

  private static mapProperty(
    queriedDataSource: EquipmentDataSourceDto,
    owner: Equipment,
    equipmentProperty: EquipmentProperty,
    dataConnectorRoleName: string
  ): DataConnectorDto {
    const dataSource = createEquipmentDataSource(queriedDataSource);
    if (equipmentProperty.value != null) {
      dataSource.value = equipmentProperty.value;
    } else {
      dataSource.signal = new SignalInfoDto(equipmentProperty.logId, equipmentProperty.name);
    }
    const connectorId = owner.path ? owner.path + equipmentProperty.name : undefined;
    const connector = new DataConnectorDto({
      id: connectorId,
      dataSource,
      role: dataConnectorRoleName,
      isDynamicallyCreated: true,
      isTimeSeries: isNotDefined(equipmentProperty.value),
      title: formatEquipmentPropertyPath(
        owner.name,
        equipmentProperty.name,
        queriedDataSource.signal.name
      )
    });

    setDataStatusFromPoints(connector);

    if (equipmentProperty.properties != null) {
      connector.properties = equipmentProperty.properties;
    }

    connector.properties[CommonDataPropertyNames.equipmentName] = owner.name;
    connector.properties[CommonDataPropertyNames.equipmentPath] = owner.path;
    connector.properties[CommonDataPropertyNames.equipmentDescription] = owner.description;
    connector.properties[CommonDataPropertyNames.equipmentId] = owner.id;

    return connector;
  }

  public setDefaultRole(
    dataConnectorMatrix: DataConnectorDto[][],
    originatorIds: EntityId[],
    componentStates: ComponentStateDto[]
  ): void {
    originatorIds.forEach((id: EntityId, index: number) => {
      // try getting default role (works only for dataConnectorQuery)
      const originatorComponentState = componentStates.find((cs) => cs.id === id);
      if (originatorComponentState != null) {
        const originatorComponentType: string = originatorComponentState.type;
        const defaultRoleName: string =
          this.metadataProvider.getDefaultRole(originatorComponentType).name;
        (dataConnectorMatrix[index] || []).forEach((componentDataConnector: DataConnectorDto) => {
          componentDataConnector.role = defaultRoleName;
        });
      }
    });
  }

  public setConnectorIds(
    dataConnectorMatrix: DataConnectorDto[][],
    originatorIds: EntityId[]
  ): void {
    originatorIds.forEach((componentId: EntityId, componentIdIndex: number) => {
      (dataConnectorMatrix[componentIdIndex] || []).forEach(
        (componentDataConnector: DataConnectorDto, connectorIndex: number) => {
          const modifiedId = componentId + componentDataConnector.id.toString();
          dataConnectorMatrix[componentIdIndex][connectorIndex] = new DataConnectorDto({
            ...componentDataConnector,
            id: modifiedId
          });
        }
      );
    });
  }
}
