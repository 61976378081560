import { animate, style, transition, trigger } from "@angular/animations";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { DataExplorerSelector } from "../../../browsing/services/data-explorer.selector";
import { DataExplorerActions } from "../../../browsing/store/data-explorer/data-explorer.actions";
import { DisplayMode } from "../../../core/models/display-mode";
import { ViewMode } from "../../../core/models/view-mode";
import { HelpService } from "../../../core/services/help.service";
import { Dispatcher } from "../../../dispatcher";
import { EnvironmentSelector } from "../../../environment/services/environment.selector";
import { AppStatusActions } from "../../../environment/store/app-status/app-status.actions";
import { LocalizationService } from "../../../i18n/localization.service";
import { SidemenuComponents, ToolbarItem } from "../../../shared/models/tool-bar-item";
import { ToolbarItemsGenerator } from "../../../shared/services/toolbar-items-generator.service";
import { isDefined, isNotDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";

@Component({
  selector: "sidebar",
  templateUrl: "sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [style({ opacity: 0 }), animate("200ms", style({ opacity: 1 }))]),
      transition(":leave", [style({ opacity: 1 }), animate("200ms", style({ opacity: 0 }))])
    ])
  ]
})
export class SidebarComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("sidebar") sidebar: ElementRef;
  public previewMode: boolean;
  public isSidebarOpened: boolean;
  public isPinned: boolean = false;
  public isSidebarExpanded: boolean;
  public displayMode: string;
  public DisplayMode = DisplayMode;
  public layoutToolbarItems: ToolbarItem[];
  explorerToolbarItems$: Observable<ToolbarItem[]>;
  selectedToolbarItem: Maybe<ToolbarItem>;
  public SidemenuComponents = SidemenuComponents;
  isNavigationActive = false;
  isPropertyBrowserExpanded!: boolean;
  isSignalTabSelected!: boolean;
  private unsubscribeSubject$: Subject<any> = new Subject();
  @Output() toggleReportBrowser: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private dispatcher: Dispatcher,
    public helpService: HelpService,
    public localizer: LocalizationService,
    private environmentSelector: EnvironmentSelector,
    private dataExplorerSelector: DataExplorerSelector,
    private toolbarItemsGenerator: ToolbarItemsGenerator,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initSubscriptions();
    this.toolbarItemsGenerator.generateConfigToolbarItems$().subscribe((layoutToolbarItems) => {
      this.layoutToolbarItems = layoutToolbarItems;
    });
    this.explorerToolbarItems$ = this.toolbarItemsGenerator.generateExplorerToolbarItems$();
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.explorerToolbarItems$ = this.toolbarItemsGenerator.generateExplorerToolbarItems$();
      });
  }

  ngAfterViewInit(): void {
    this.subscribeToPropertyBrowserMode();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  initSubscriptions(): void {
    this.subscribeToViewMode();
    this.subscribeToVisibilityMode();
    this.subscribeToDisplayMode();
    this.subscribeToPinMode();
    this.registerHotkeys();
    this.subscribeToExpandedMode();
    this.subscribeToSignalTabMode();
  }

  private subscribeToPropertyBrowserMode(): void {
    this.dataExplorerSelector
      .selectPropertyBrowserMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((isExpanded) => {
        this.isPropertyBrowserExpanded = isExpanded;
        this.cdr.detectChanges();
      });
  }

  private subscribeToViewMode(): void {
    this.environmentSelector
      .selectViewMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((viewMode: ViewMode) => {
        this.previewMode = viewMode === ViewMode.PreviewMode;
      });
  }

  private subscribeToVisibilityMode(): void {
    this.environmentSelector
      .selectSidebarVisibilityMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((isSidebarOpened: boolean) => {
        this.isSidebarOpened = isSidebarOpened;
        if (!this.isSidebarOpened) {
          this.selectedToolbarItem = undefined;
        }
        this.shouldDisplay(this.isSidebarOpened, this.displayMode);
        this.cdr.detectChanges();
      });
  }

  private subscribeToDisplayMode(): void {
    this.environmentSelector
      .selectDisplayMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((displayMode: string) => {
        this.displayMode = displayMode;
        this.shouldDisplay(this.isSidebarOpened, this.displayMode);
      });
  }

  private subscribeToPinMode(): void {
    this.environmentSelector
      .selectSidebarPinMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((pinMode) => {
        this.isPinned = pinMode;
      });
  }

  private subscribeToExpandedMode(): void {
    this.environmentSelector
      .selectSidebarExpandedMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((expandedMode) => {
        this.isSidebarExpanded = expandedMode;
        if (!this.isSidebarExpanded) {
          this.selectedToolbarItem = null;
        }
      });
  }

  private subscribeToSignalTabMode(): void {
    this.dataExplorerSelector
      .selectSignalTabMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((isSignalTabSelected: boolean) => {
        this.isSignalTabSelected = isSignalTabSelected;
      });
  }

  private registerHotkeys(): void {
    window.addEventListener("keydown", (ev) => {
      switch (ev.key) {
        case "F1": {
          this.helpService.open();
          return false;
        }
        case "F2": {
          this.selectedTabChanged(this.layoutToolbarItems[0]);
          return false;
        }
        default:
          return true;
      }
    });
  }

  public toggleReportBrowserSidebar(): void {
    this.isNavigationActive = !this.isNavigationActive;
    this.toggleReportBrowser.emit();
  }

  selectedTabChanged(selectedItem: ToolbarItem): void {
    if (selectedItem.singleAction) {
      selectedItem.singleAction();
      return;
    }

    if (isNotDefined(this.selectedToolbarItem)) {
      this.dispatcher.dispatch(AppStatusActions.expandSidebar());
    }
    this.selectedToolbarItem = selectedItem;
    if (this.isPropertyBrowserExpanded) {
      this.collapseEquipmentPropertyBrowser();
    }
  }

  closeIfNotPinned(): void {
    if (isDefined(this.selectedToolbarItem) && !this.isPinned) {
      this.collapseSidebar();
    }
    if (this.isSidebarOpened && this.displayMode === DisplayMode.Mobile) {
      this.dispatcher.dispatch(AppStatusActions.closeSidebar());
    }
  }

  shouldDisplay(showSidebar: boolean, displayMode: string): void {
    if (isNotDefined(this.sidebar)) {
      return;
    }
    displayMode === DisplayMode.Mobile && showSidebar
      ? this.sidebar.nativeElement.classList.toggle("responsive")
      : this.sidebar.nativeElement.classList.remove("responsive");
  }

  onChangePinMode(): void {
    this.dispatcher.dispatch(AppStatusActions.changeSidebarPinMode({ pinMode: !this.isPinned }));
  }

  collapseEquipmentPropertyBrowser(): void {
    this.dispatcher.dispatch(DataExplorerActions.collapsePropertyBrowser());
  }

  checkIfSidebarShouldCollapse(): void {
    if (!this.isPinned && this.isSidebarExpanded) {
      this.collapseSidebar();
    }
  }

  collapseSidebar(): void {
    if (this.isPropertyBrowserExpanded) {
      this.collapseEquipmentPropertyBrowser();
    }
    this.dispatcher.dispatch(AppStatusActions.collapseSidebar());
    this.selectedToolbarItem = undefined;
  }
}
