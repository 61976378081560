import { Injectable } from "@angular/core";
import { createSelector, Store } from "@ngrx/store";
import { combineLatest, Observable, Subject } from "rxjs";
import { distinctUntilChanged, first, takeUntil } from "rxjs/operators";
import { isSignalBased } from "../../../data-connectivity/helpers/data-source-type.helper";
import { DataConnectorDto } from "../../../data-connectivity/models/data-connector";
import { DataConnectorSubscriptionContext } from "../../../data-connectivity/models/data-connector-subscription-context";
import { EQUIPMENT_DATA_SOURCE } from "../../../data-connectivity/models/data-source/data-source.type";
import { ComponentStateDto } from "../../../elements/models/component-state";
import { getReportFeature } from "../../../elements/store/feature.selector";
import { ReportContents } from "../../../elements/store/state";
import { EntityId } from "../../../meta/models/entity";
import { areArraysEqualByElementsReference } from "../../../ts-utils/helpers/array.helper";
import { isDefined, isNotDefined } from "../../../ts-utils/helpers/predicates.helper";
import { CriticalError } from "../../../ts-utils/models/critical-error";
import { Dictionary } from "../../../ts-utils/models/dictionary.type";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import { selectAllComponentsForFilter } from "../../store/component-state/component-state.selectors";
import {
  selectComponentConnectorsById,
  selectConnectorByStandaloneFilterId,
  selectDataConnectorById,
  selectDataConnectorDescriptor,
  selectDataConnectorDescriptors,
  selectDataConnectorEntities,
  selectDataConnectorsById,
  selectEquipmentPathByConnector
} from "../../store/data-connector/data-connector.selectors";

@Injectable()
export class DataConnectorSelector {
  constructor(private store$: Store<any>) {}

  // used by Elements
  subscribeToComponentDataConnectors(context: DataConnectorSubscriptionContext): void {
    context.selector =
      context.selector ||
      function (connector: DataConnectorDto) {
        return true;
      };
    context.takeUntil = context.takeUntil || new Subject();
    const dataConnectors = this.getForComponent(context.componentId);
    const dataConnectors$ = dataConnectors
      .filter((connector) => context.selector(connector))
      .map((connector) =>
        this.store$.select(
          createSelector(
            getReportFeature,
            (state: ReportContents) => state.dataConnectors.entities[connector.id]
          )
        )
      );

    combineLatest(dataConnectors$)
      .pipe(takeUntil(context.takeUntil)) // very important, otherwise subsciption will continue after component has been removed
      .subscribe(context.delegate);
  }

  // used by Property sheet
  selectDataConnectorDescriptor(connectorId: EntityId): Observable<DataConnectorDescriptor> {
    return this.store$.select(selectDataConnectorDescriptor(connectorId));
  }

  selectDataConnectorDescriptors(
    connectors: DataConnectorDto[]
  ): Observable<DataConnectorDescriptor[]> {
    return this.store$.select(selectDataConnectorDescriptors(connectors));
  }

  selectManyById(connectorIds: EntityId[]): Observable<Maybe<DataConnectorDto>[]> {
    return this.store$.select(selectDataConnectorsById(connectorIds));
  }

  selectForComponent(componentId: EntityId): Observable<Maybe<DataConnectorDto>[]> {
    return this.store$
      .select(selectComponentConnectorsById(componentId))
      .pipe(distinctUntilChanged(areArraysEqualByElementsReference));
  }

  // used by Elements only
  selectByRole(
    role: string,
    componentId: EntityId,
    exactMatch: boolean = true
  ): Observable<Maybe<DataConnectorDto>[]> {
    return this.store$.select(
      createSelector(getReportFeature, (state: ReportContents) => {
        const componentState: Maybe<ComponentStateDto> =
          state.componentStates.entities[componentId];
        if (isNotDefined(componentState)) {
          throw new CriticalError(`Component state with id=${componentId} not found`);
        }
        const connectors = state.dataConnectors.entities;
        const connectorCopies = componentState.dataConnectorIds
          .map((connectorId) => connectors[connectorId])
          .filter((connector) => {
            return (
              isDefined(connector) &&
              (exactMatch ? connector.role === role : connector.role.includes(role))
            );
          });
        if (connectorCopies.length === 0) {
          return [];
        }
        return connectorCopies;
      })
    );
  }

  selectEquipmentPathByConnector(id: EntityId): Observable<string> {
    return this.store$.select(selectEquipmentPathByConnector(id));
  }

  // used by Elements
  getByRole(role: string, componentId: EntityId, exactMatch: boolean = true): DataConnectorDto[] {
    let targetConnectors: DataConnectorDto[];
    this.selectByRole(role, componentId)
      .pipe(first())
      .subscribe((connector) => (targetConnectors = connector));
    return targetConnectors;
  }

  // used by azure
  getAllSignalBased(): Maybe<DataConnectorDto>[] {
    return Object.values(this.getAll()).filter(
      (connector: Maybe<DataConnectorDto>) => connector && isSignalBased(connector.dataSource)
    );
  }

  // used by this module
  getByStandaloneFilterId(filterId: EntityId): Maybe<DataConnectorDto> {
    let conenctorWithStandaloneFilter: Maybe<DataConnectorDto>;
    this.store$
      .select(selectConnectorByStandaloneFilterId(filterId))
      .pipe(first())
      .subscribe((connector: Maybe<DataConnectorDto>) => {
        conenctorWithStandaloneFilter = connector;
      });

    return conenctorWithStandaloneFilter;
  }

  getAllForStandaloneFilters(filterIds: EntityId[]): DataConnectorDto[] {
    return filterIds.reduce((acc: DataConnectorDto[], filterId: EntityId) => {
      const connector = this.getByStandaloneFilterId(filterId);
      if (isDefined(connector)) {
        acc.push(connector);
      }
      return acc;
    }, []);
  }

  getAllByInheritFilterIds(filterIds: EntityId[]): DataConnectorDto[] {
    return filterIds.reduce((acc: DataConnectorDto[], filterId: EntityId) => {
      const connector = this.getAllByInheritFilterId(filterId);
      if (isDefined(connector)) {
        acc = acc.concat(connector);
      }
      return acc;
    }, []);
  }

  // used by Elements
  getAllByInheritFilterId(filterId: EntityId): DataConnectorDto[] {
    let conectors: DataConnectorDto[] = [];
    this.store$
      .select(selectAllComponentsForFilter(filterId))
      .pipe(first())
      .subscribe((components: ComponentStateDto[]) => {
        conectors = components.reduce((acc, component: ComponentStateDto) => {
          const componentConnectors = this.getForComponent(component.id);
          acc = acc.concat(componentConnectors);
          return acc;
        }, []);
      });

    return conectors;
  }

  // used by Azure and mock data service
  getAll(): Dictionary<Maybe<DataConnectorDto>> {
    let connectors: Dictionary<Maybe<DataConnectorDto>>;
    this.store$
      .select(selectDataConnectorEntities)
      .pipe(first())
      .subscribe((connectorEntities) => {
        connectors = connectorEntities;
      });
    return connectors;
  }

  // used by Elements and Azure
  getAllAsArray(): DataConnectorDto[] {
    const logsDictionary = this.getAll();
    return Object.keys(logsDictionary)
      .map((key) => logsDictionary[key] as DataConnectorDto)
      .reduce((acc: DataConnectorDto[], connector: DataConnectorDto) => {
        acc.push(connector);
        return acc;
      }, []);
  }

  getForComponents(componentIds: EntityId[]): DataConnectorDto[] {
    return componentIds.reduce((acc, componentId) => {
      acc = acc.concat(this.getForComponent(componentId));
      return acc;
    }, []);
  }

  // used by Property sheet and Elements
  getForComponent(componentId: EntityId): DataConnectorDto[] {
    let componentConnectors: DataConnectorDto[];
    this.selectForComponent(componentId)
      .pipe(first())
      .subscribe((connectors) => (componentConnectors = connectors));
    if (!componentConnectors) {
      console.warn("no connectors for ", componentId);
    }
    return componentConnectors;
  }

  getById(connectorId: EntityId): DataConnectorDto {
    let result: DataConnectorDto = null;
    this.store$
      .select(selectDataConnectorById(connectorId))
      .pipe(first())
      .subscribe((connector) => (result = connector));
    return result;
  }

  // used by Elements
  getManyById(connectorIds: EntityId[]): Dictionary<DataConnectorDto> {
    if (!Array.isArray(connectorIds)) {
      return {};
    }
    const connectors: Dictionary<DataConnectorDto> = this.getAll();
    return connectorIds.reduce((acc, connectorId) => {
      if (connectors[connectorId]) {
        acc[connectorId] = connectors[connectorId];
      }
      return acc;
    }, {});
  }

  getManyByIdAsArray(connectorIds: EntityId[]): DataConnectorDto[] {
    if (!Array.isArray(connectorIds)) {
      return [];
    }
    const connectors: DataConnectorDto[] = this.getAllAsArray();
    return connectors.filter((connector) => connectorIds.includes(connector.id));
  }

  getEquipmentDataSourceConnectors(): DataConnectorDto[] {
    return this.getAllAsArray().filter(
      (connector) => connector.dataSource.typeName === EQUIPMENT_DATA_SOURCE
    );
  }

  getEquipmentPropertyConnectors(): DataConnectorDto[] {
    return this.getAllAsArray().filter(
      (connector) =>
        connector.dataSource.typeName === EQUIPMENT_DATA_SOURCE && !connector.isDynamicallyCreated
    );
  }

  getDataConnectorDescriptorByConnectorId(connectorId: EntityId): Maybe<DataConnectorDescriptor> {
    let result: DataConnectorDescriptor = null;
    this.store$
      .select(selectDataConnectorDescriptor(connectorId))
      .pipe(first())
      .subscribe((descriptor) => (result = descriptor));
    return result;
  }

  getDataConnectorDescriptorsByConnectors(
    connectors: DataConnectorDto[]
  ): DataConnectorDescriptor[] {
    let result: DataConnectorDescriptor[] = [];
    this.store$
      .select(selectDataConnectorDescriptors(connectors))
      .pipe(first())
      .subscribe((descriptors) => (result = descriptors));
    return result;
  }
}
