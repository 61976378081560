import { QueryFilter } from "../../core/models/filter/query-filter";
import { DataPointDto } from "../../data-connectivity";
import { DataConnectorDto } from "../../data-connectivity/models/data-connector";
import { DataConnectorViewDto } from "../../data-connectivity/models/data-connector-view";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { ComponentStateViewModel } from "./component-state.vm";

export const PERIOD_TYPE_WIDGET_QUERY_FILTER: string = "periodType";
export const DATA_POINT_VALUE_PROPERTY_NAME = "dataValue";

export interface WidgetQueryFilter {
  queryFilter: Omit<QueryFilter, "timeRange"> & { timeRange: { from: string; to: string } };
  periodType: string;
  typeName: string;
}

export type InterpolationSourceType =
  | Maybe<DataConnectorDto | DataConnectorViewDto | DataPointDto>
  | WidgetQueryFilter
  | ComponentStateViewModel
  | string;

export interface InterpolationParams {
  source: InterpolationSourceType;
  propertyName: any;
}

export interface TargetProperty {
  targetType: string;
  localPath: string[];
}
