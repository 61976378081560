<div class="equipment-browser__container" [class.showInDialog]="showInDialog">
  <div *ngIf="showTitle" class="equipment-browser__title">
    {{ "Sidebar.Equipment" | translate }}
  </div>

  <div class="equipment-browser__progress-bar" *ngIf="showProgressBar">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div class="equipment-browser__tree" [class.showInDialog]="showInDialog">
    <mat-tree
      #tree
      [dataSource]="dataSource"
      [treeControl]="treeControl"
      *ngIf="!showProgressBar"
      class="tree-container"
    >
      <!-- template for a leaf node -->
      <mat-tree-node
        *matTreeNodeDef="let node"
        matTreeNodePadding
        [matTreeNodePaddingIndent]="matTreeNodePadding"
        [disabled]="node.path"
      >
        <li
          class="mat-tree-node"
          [ngClass]="{
            'mat-tree-node--active': isSelectedItem(node),
            'mat-tree-node--disabled': node.disabled
          }"
          draggable="true"
          (drag)="dragStart($event, node)"
          (dragend)="dragEnd()"
          (touchstart)="dragStart($event, node)"
          (touchmove)="touchMove($event)"
          (touchend)="dropOnTouchDevices($event)"
        >
          <div
            (mousedown)="setSelectedItem(node)"
            class="selectionNode"
            [class.mat-tree-node--disabled]="node.disabled"
          >
            {{ node.name }}
          </div>
        </li>
      </mat-tree-node>

      <!-- template for expandable nodes -->
      <mat-tree-node
        *matTreeNodeDef="let node; when: hasChildren"
        matTreeNodePadding
        [matTreeNodePaddingIndent]="matTreeNodePadding"
        [disabled]="node.path"
      >
        <li>
          <div
            class="mat-tree-node"
            draggable="true"
            (drag)="dragStart($event, node)"
            [class.mat-tree-node--active]="isSelectedItem(node)"
            (touchstart)="dragStart($event, node)"
            (touchmove)="touchMove($event)"
            (touchend)="dropOnTouchDevices($event)"
          >
            <button
              [disableRipple]="true"
              mat-icon-button
              matTreeNodeToggle
              [attr.aria-label]="'toggle'"
              class="equipment-arrow"
              [matTreeNodeToggleRecursive]="recursive"
            >
              <span
                class="toggle-children"
                bind-style.transform="
                  treeControl.isExpanded(node) ? 'rotate(90deg)' : 'rotate(0deg)'
                "
              ></span>
            </button>
            <div
              (mousedown)="setSelectedItem(node)"
              class="selectionNode"
              [class.mat-tree-node--disabled]="node.disabled"
            >
              {{ node.name }}
            </div>
          </div>
        </li>
      </mat-tree-node>
    </mat-tree>
  </div>
</div>
