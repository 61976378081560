import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { ViewMode } from "../../../core/models/view-mode";
import { EnvironmentSelector } from "../../../environment/services/environment.selector";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { isDefined } from "../../../ts-utils/helpers";
import { Maybe } from "../../../ts-utils/models";
import { getConfiguredOrForegroundColor } from "../../helpers/color.helper";
import { VerticalAlignment } from "../../models/alignment/vertical-alignment";
import { isWrap } from "../../services/scalable-font-size.helper";
import { LabelViewConfig } from "../label/view-config";

const FLEX_START = "flex-start";
const CENTER = "center";
const FLEX_END = "flex-end";
const ICON_SCALE_FACTOR = 1.418;

@Component({
  selector: "single-line-label",
  templateUrl: "./single-line-label.component.html",
  styleUrls: ["./single-line-label.component.scss"],
  host: { class: "c-label" },
  changeDetection: ChangeDetectionStrategy.OnPush
})
@EditableWidget({ fullName: "MultilineLabelComponent", title: "multiline-label" })
export class SingleLineLabelComponent implements OnChanges, OnInit {
  @Input() viewConfig: Maybe<LabelViewConfig> = null;
  @Input() hasLink: boolean = false;
  @Output() openLink: EventEmitter<any> = new EventEmitter();
  iconSize: Maybe<number> = null;
  labelStyle: Partial<CSSStyleDeclaration> = {};
  isPreviewMode$: Observable<boolean> = of(false);

  constructor(private environmentSelector: EnvironmentSelector) {}

  ngOnInit(): void {
    this.resolveViewMode();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isDefined(this.viewConfig) && isDefined(changes["viewConfig"].currentValue)) {
      this.iconSize = this.viewConfig.fontSize * ICON_SCALE_FACTOR;
      this.labelStyle = this.getLabelStyle();
    }
  }

  private resolveViewMode(): void {
    this.isPreviewMode$ = this.environmentSelector
      .selectViewMode()
      .pipe(map((viewMode) => viewMode === ViewMode.PreviewMode));
  }

  public isTitleFormatWrap(): boolean {
    return isWrap(this.viewConfig?.titleFormat);
  }

  private getLabelStyle(): Partial<CSSStyleDeclaration> {
    const style: Partial<CSSStyleDeclaration> = {};
    if (isDefined(this.viewConfig)) {
      const { bold, primaryColor, foregroundColor, fontSize } = this.viewConfig;
      style["fontWeight"] = bold ? "bold" : "normal";
      style["fontSize"] = fontSize + "px";
      style["color"] = getConfiguredOrForegroundColor(primaryColor, foregroundColor);
      style["justifyContent"] = this.viewConfig.horizontalAlignment.toLocaleLowerCase();
      style["alignItems"] = resolveVerticalAlignment(this.viewConfig.verticalAlignment);
    }
    return style;
  }

  public linkIsOpen(): void {
    this.openLink.emit();
  }
}

function resolveVerticalAlignment(verticalAlignment: string): string {
  switch (verticalAlignment) {
    case VerticalAlignment.Top:
      return FLEX_START;
    case VerticalAlignment.Middle:
      return CENTER;
    case VerticalAlignment.Bottom:
      return FLEX_END;
  }
}
