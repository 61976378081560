import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { cloneDeep as _cloneDeep } from "lodash";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { RUNTIME_FILTER_ID } from "../../../core/helpers/filter/filter-id.helper";
import {
  isCorrectTimeRangeInterval,
  isTimeRangeSame,
  validateTimeRangeInputFormat
} from "../../../core/helpers/filter/filter-validation.helper";
import { FilterConfigurationDto } from "../../../core/models/filter/filter-configuration";
import { TimeRange } from "../../../core/models/time-range";
import { TimeRangeConfigurationDto } from "../../../core/models/time-range-configuration";
import { FilterFactory } from "../../../core/services/filter/filter-factory.service";
import { IFilterSelector } from "../../../core/services/filter/i-filter.selector";
import { Dispatcher } from "../../../dispatcher";
import { FilterActions } from "../../../elements/store/filter/filter.actions";
import { DateFormatterService } from "../../../environment/services/date-formatter.service";
import { EnvironmentSelector } from "../../../environment/services/environment.selector";
import { TimeInfoActions } from "../../../environment/store/time-info/time-info.actions";
import { LocalizationService } from "../../../i18n/localization.service";
import { isEmptyOrNotDefined } from "../../../ts-utils";
import { isDefined, isNotDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import {
  formatAmountToTwoDecimals,
  resolveLiveModeFilter
} from "../../helpers/live-mode-time-unit.helper";
import { FilterToolbarElementConfig } from "../../models/button/filter-toolbar-element.config";
import { FilterType } from "../../models/filter-type";
import { LiveModeFilter } from "../../models/live-mode-filter";

@Component({
  selector: "runtime-date-time-range",
  templateUrl: "runtime-date-time-range.component.html",
  styleUrls: ["./runtime-date-time-range.component.scss"]
})
export class RuntimeDateTimeRangeComponent implements OnInit, OnDestroy {
  @ViewChild("timeRangeInput") timeRangeInput: ElementRef;
  @Input() isFilterBarSelected: boolean = false;
  @Input() canExpandHorizontally: boolean = false;
  @Output() showFilterBar: EventEmitter<any> = new EventEmitter();
  public icon = "Calendar";
  public toggleButton: FilterToolbarElementConfig;
  public isDateTimePickerOpened: boolean = false;
  public historicTimeRangeInput: string = "";
  public filterElementTitle: string = "";
  public isTimeRangeValid: boolean = true;
  public canApplyFilterChanges: boolean = false;
  public isLiveMode: boolean = true;
  localFilterConfiguration: FilterConfigurationDto;
  public historicTimeRange: TimeRange;
  public liveModeFilter: Maybe<LiveModeFilter> = null;
  timeRangeUpdate = new Subject<string>();
  public dateTimeFormat: string = "";
  private unsubscribeSubject$: Subject<any> = new Subject<any>();

  constructor(
    private dispatcher: Dispatcher,
    private filterFactory: FilterFactory,
    public localizer: LocalizationService,
    private filterSelector: IFilterSelector,
    private dateFormatter: DateFormatterService,
    private environmentSelector: EnvironmentSelector
  ) {}

  ngOnInit(): void {
    this.toggleButton = this.createToggleButtonConfig();
    this.subscribeToGlobalFilter();
    this.timeRangeUpdate.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value) => {
      const newTimeRange = validateTimeRangeInputFormat(value, this.dateFormatter);
      this.setTimeRangeIfValid(newTimeRange);
    });
    this.environmentSelector.selectDateFormat().subscribe((format) => {
      this.dateTimeFormat = `Date format: ${format} - ${format}`;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  private createToggleButtonConfig(): FilterToolbarElementConfig {
    return new FilterToolbarElementConfig({
      icon: this.icon,
      clickFunction: () => {
        return this.showOrHideTimeRangeBar();
      }
    });
  }

  closeBar(event: Event): void {
    this.showOrHideTimeRangeBar();
    this.resetFilterConfiguration(this.localFilterConfiguration);
    event.stopPropagation();
  }

  closeIfDropdown(): void {
    if (!this.canExpandHorizontally && this.isFilterBarSelected) {
      this.showOrHideTimeRangeBar();
    }
  }

  public showOrHideTimeRangeBar(): void {
    this.showFilterBar.emit(FilterType.TimeRange);
  }

  private subscribeToGlobalFilter(): void {
    this.filterSelector
      .selectById(RUNTIME_FILTER_ID)
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((filterConfig) => {
        this.initFilterConfiguration(filterConfig);
      });
  }

  initFilterConfiguration(filterConfiguration: FilterConfigurationDto): void {
    this.localFilterConfiguration = _cloneDeep(filterConfiguration);
    this.isLiveMode = isEmptyOrNotDefined(this.localFilterConfiguration.timeRange.toExpression);
    this.resetFilterConfiguration(this.localFilterConfiguration);
    this.filterElementTitle = this.resolveFilterBarLabel(this.isLiveMode);
  }

  private resetFilterConfiguration(filterConfiguration: FilterConfigurationDto): void {
    this.isLiveMode
      ? this.initLiveModeFilter(filterConfiguration)
      : this.initHistoricTimeRange(filterConfiguration);
  }

  private resolveFilterBarLabel(liveMode: boolean): string {
    return liveMode
      ? resolveLiveModeText(this.liveModeFilter)
      : this.localizer.filterComponent.Custom;
  }

  private initLiveModeFilter(filterConfiguration: FilterConfigurationDto): void {
    this.liveModeFilter = this.filterFactory.createStandaloneLiveModeFilter(
      filterConfiguration.timeRange
    );
  }

  private initHistoricTimeRange(filterConfiguration: FilterConfigurationDto): void {
    const timeRange = this.filterFactory.createStandaloneFilterTimeRange(
      filterConfiguration.timeRange
    );
    if (isDefined(timeRange)) {
      this.historicTimeRange = timeRange;
      this.canApplyFilterChanges = false;
      this.isTimeRangeValid = true;
      this.historicTimeRangeInput = resolveTimeRangeInputValue(timeRange, this.dateFormatter);
    }
  }

  onChangeLiveMode(liveMode: boolean): void {
    this.isLiveMode = liveMode;
    this.isDateTimePickerOpened = false;
    this.isTimeRangeValid = true;
    if (liveMode) {
      this.dispatcher.dispatch(TimeInfoActions.updateCurrentTime({ now: new Date() }));
    }
    const updatedTimeRangeConfig: TimeRangeConfigurationDto = this.resolveTimeRangeConfiguration();
    this.saveChanges(updatedTimeRangeConfig);
  }

  private resolveTimeRangeConfiguration(): TimeRangeConfigurationDto {
    if (this.isLiveMode) {
      const timeRange = this.filterFactory.createStandaloneFilterTimeRange(
        this.localFilterConfiguration.timeRange
      );
      this.liveModeFilter = resolveLiveModeFilter(timeRange);
      return this.filterFactory.createTimeRangeFromLiveModeFilter(this.liveModeFilter);
    } else {
      this.historicTimeRange = this.filterFactory.resolveStandaloneLiveModeTimeRange(
        this.liveModeFilter?.amount,
        this.liveModeFilter?.unit
      );
      return this.filterFactory.createTimeRangeConfiguration(this.historicTimeRange);
    }
  }

  public updateLiveModeFilter(liveModeFilter: LiveModeFilter): void {
    this.liveModeFilter = liveModeFilter;
    const timeRange = this.filterFactory.createTimeRangeFromLiveModeFilter(this.liveModeFilter);
    this.saveChanges(timeRange);
  }

  applyChanges(): void {
    const timeRange = this.filterFactory.createTimeRangeConfiguration(this.historicTimeRange);
    this.saveChanges(timeRange);
  }

  public saveChanges(newTimeRangeConfiguration: TimeRangeConfigurationDto): void {
    if (!this.isTimeRangeValid && !this.isLiveMode) {
      console.warn("Invalid filter data");
      return;
    }
    if (isTimeRangeSame(this.localFilterConfiguration.timeRange, newTimeRangeConfiguration)) {
      return;
    }
    this.localFilterConfiguration.timeRange = newTimeRangeConfiguration;
    this.updateFilterInStore(this.localFilterConfiguration);
  }

  private updateFilterInStore(newFilterConfiguration: FilterConfigurationDto): void {
    const filterForStore = _cloneDeep(newFilterConfiguration);
    this.dispatcher.dispatch(
      FilterActions.upsertOne({
        filterUpdate: { id: RUNTIME_FILTER_ID, changes: filterForStore }
      })
    );
  }

  public onSelectDateTimePicker(newTimeRange: TimeRange): void {
    this.historicTimeRangeInput = resolveTimeRangeInputValue(newTimeRange, this.dateFormatter);
    this.setTimeRangeIfValid(newTimeRange);
  }

  public onSelectTimeRange(newTimeRange: TimeRange): void {
    if (this.setTimeRangeIfValid(newTimeRange)) {
      this.historicTimeRangeInput = resolveTimeRangeInputValue(newTimeRange, this.dateFormatter);
    } else {
      const timeRange = this.filterFactory.createStandaloneFilterTimeRange(
        this.localFilterConfiguration.timeRange
      );
      if (isDefined(timeRange)) {
        this.historicTimeRangeInput = resolveTimeRangeInputValue(timeRange, this.dateFormatter);
        this.isTimeRangeValid = true;
      }
    }
  }

  public setTimeRangeIfValid(newTimeRange: Maybe<TimeRange>): boolean {
    if (
      isNotDefined(newTimeRange) ||
      !isCorrectTimeRangeInterval(newTimeRange.from, newTimeRange.to)
    ) {
      this.isTimeRangeValid = false;
      this.canApplyFilterChanges = false;
      return false;
    }
    const newTimeRangeConfiguration: TimeRangeConfigurationDto =
      this.filterFactory.createTimeRangeConfiguration(newTimeRange);
    if (isTimeRangeSame(this.localFilterConfiguration.timeRange, newTimeRangeConfiguration)) {
      this.isTimeRangeValid = true;
      this.canApplyFilterChanges = false;
      return false;
    }
    this.isTimeRangeValid = true;
    this.canApplyFilterChanges = true;
    this.historicTimeRange = newTimeRange;
    return true;
  }

  public showOrHideDateTimePicker(showDateTimePicker: boolean, event: Event): void {
    this.isDateTimePickerOpened = showDateTimePicker;
    event.stopPropagation();
  }

  public hideDateTimePicker(): void {
    this.isDateTimePickerOpened = false;
  }
}

function resolveLiveModeText(liveModeFilter: Maybe<LiveModeFilter>): string {
  const amount = formatAmountToTwoDecimals(liveModeFilter?.amount ?? 0);
  return `Last ${amount} ${liveModeFilter?.unit.charAt(0)}`;
}

function resolveTimeRangeInputValue(
  timeRange: TimeRange,
  dateFormatter: DateFormatterService
): string {
  const fromExpression: string = dateFormatter.formatDate(timeRange.from);
  const toExpression: string = dateFormatter.formatDate(timeRange.to);
  return fromExpression + " - " + toExpression;
}
