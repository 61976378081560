import {
  BaseViewConfigDto,
  ComponentCssSize,
  Configurable,
  ConfigurationCategory,
  construct,
  DeepPartial,
  EditableType,
  EditorType,
  LOCALIZATION_DICTIONARY,
  PropertyCategory,
  Serializable
} from "ui-core";

const TYPE_NAME = "WaterConductivityChartViewConfig";

@EditableType({ fullName: TYPE_NAME })
export class WaterConductivityChartViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General, 5)
  @Configurable({ displayName: "Unit", editorType: EditorType.TextBox })
  @Serializable("")
  unit!: string;

  @Configurable({ displayName: "Expected Range Color", editorType: EditorType.ColorPicker })
  @Serializable("#55BF3B")
  expectedColor!: string;

  @Configurable({ displayName: "Alarm Color", editorType: EditorType.ColorPicker })
  @Serializable("#FFBB00")
  alarmColor!: string;

  @Configurable({ displayName: "Current Color", editorType: EditorType.ColorPicker })
  @Serializable("#004C97")
  currentColor!: string;

  @Configurable({ displayName: "Trip Color", editorType: EditorType.ColorPicker })
  @Serializable("#FF0000")
  tripColor!: string;

  constructor(viewConfigDto: DeepPartial<WaterConductivityChartViewConfig> = {}) {
    super();
    construct(this, viewConfigDto, TYPE_NAME, { size: getDefaultSize() });
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("625", "125");
}
