import { Pipe, PipeTransform } from "@angular/core";
import { Group } from "../../property-sheet/components/array-editor/models";
import { Maybe, isEmptyOrNotDefined } from "../../ts-utils";

@Pipe({ name: "sortAlphabetically" })
export class SortAlphabeticallyPipe implements PipeTransform {
  public transform(items: Maybe<string[]> | Group[], sortBy: "items" | "groups" = "items") {
    if (isEmptyOrNotDefined(items)) {
      return [];
    }

    if (sortBy === "items") {
      return this.sortItems(items as string[]);
    } else {
      return this.sortGroups(items as Group[]);
    }
  }

  private sortItems(items: string[]): string[] {
    const res = items.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    return res;
  }

  private sortGroups(groups: Group[]): Group[] {
    const res = groups.sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });
    return res;
  }
}
