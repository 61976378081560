import { Injectable } from "@angular/core";
import { getFilterActions } from "../../core/services/filter/global-filter-vm.helper";
import { IEntityUpdater } from "../../core/services/i-entity-updater";
import { Dispatcher } from "../../dispatcher";
import { EntityId } from "../../meta";
import { ParentEntityInfo } from "../../meta/models/parent-entity-info";
import { CriticalError } from "../../ts-utils/models/critical-error";
import { Maybe } from "../../ts-utils/models/maybe.type";
import {
  COMPONENT_STATE_VIEW_MODEL,
  DATA_CONNECTOR_VIEW_DTO,
  DATA_CONNECTOR_VIEW_MODEL,
  FILTER_CONFIGURATION_DTO,
  GLOBAL_FILTER_VIEW_MODEL
} from "../models/entity-type.constants";
import { ComponentStateActions } from "../store/component-state/component-state.actions";
import { DataConnectorViewActions } from "../store/data-connector-view/data-connector-view.actions";
import { DataConnectorActions } from "../store/data-connector/data-connector.actions";
import { FilterActions } from "../store/filter/filter.actions";

@Injectable()
export class EntityUpdater extends IEntityUpdater {
  constructor(private dispatcher: Dispatcher) {
    super();
  }

  public update(
    entityId: EntityId,
    entityTypeName: string,
    changes: any,
    parentEntityInfo: Maybe<ParentEntityInfo>
  ): void {
    switch (entityTypeName) {
      case COMPONENT_STATE_VIEW_MODEL:
        this.dispatcher.dispatch(
          ComponentStateActions.updateOne({
            componentUpdate: { id: entityId.toString(), changes }
          })
        );
        break;
      case DATA_CONNECTOR_VIEW_MODEL:
        this.dispatcher.dispatch(
          DataConnectorActions.updateOne({
            connectorUpdate: { id: entityId.toString(), changes }
          })
        );
        break;
      case FILTER_CONFIGURATION_DTO:
        this.dispatcher.dispatch(
          FilterActions.upsertOne({
            filterUpdate: { id: entityId.toString(), changes },
            parentEntityInfo: parentEntityInfo
          })
        );
        break;
      case GLOBAL_FILTER_VIEW_MODEL:
        getFilterActions(this.dispatcher, changes);
        break;
      case DATA_CONNECTOR_VIEW_DTO:
        this.dispatcher.dispatch(
          DataConnectorViewActions.updateOne({
            connectorViewUpdate: { id: entityId.toString(), changes }
          })
        );
        break;
      default:
        throw new CriticalError(`Entity type ${entityTypeName} not found`);
    }
  }

  public shallowUpdate(entityId: EntityId, entityTypeName: string, changes: any): void {
    switch (entityTypeName) {
      case COMPONENT_STATE_VIEW_MODEL:
        this.dispatcher.dispatch(ComponentStateActions.replace({ newComponent: changes }));
        break;
      default:
        throw new CriticalError(`Entity type ${entityTypeName} not found`);
    }
  }
}
