import { EnvironmentSelector } from "../../environment/services/environment.selector";

export function getComponentName(componentDisplayName: string): string {
  return componentDisplayName.replace(/Component/g, "").replace(/([a-z0-9])([A-Z])/g, "$1 $2");
}

export function resolveComponentClassName(
  componentDisplayName: string,
  environmentSelector: EnvironmentSelector
): string {
  if (environmentSelector.getTemplateBuilderMode()) {
    return componentDisplayName;
  }
  return getComponentName(componentDisplayName).replace(/ /g, "");
}
