import {
  BASIC_CARD,
  BUTTON,
  CATEGORY,
  LABEL,
  NAVIGATION_BAR,
  PAGE,
  SINGLE_VALUE,
  TAB_CONTENT,
  TAB_GROUP,
  TIME_SERIES,
  WATERFALL_CHART
} from "./element-type.constants";

export function isWidget(typeName: string): boolean {
  return !canContainOtherComponents(typeName) && !isNavigationBar(typeName);
}

export function canContainOtherComponents(typeName: string): boolean {
  return canBeParent(typeName) || isTabGroup(typeName);
}

export function canBeParent(typeName: string): boolean {
  return isBasicCard(typeName) || isTabContent(typeName) || isPage(typeName);
}

export function isContainerWidget(typeName: string): boolean {
  return isBasicCard(typeName) || isTabGroup(typeName) || isNavigationBar(typeName);
}

export function isBasicCard(typeName: string): boolean {
  return typeName === BASIC_CARD;
}

export function isCard(typeName: string): boolean {
  return isBasicCard(typeName) || isNavigationBar(typeName);
}

export function isTabGroup(typeName: string): boolean {
  return typeName === TAB_GROUP;
}

export function isNavigationBar(typeName: string): boolean {
  return typeName === NAVIGATION_BAR;
}

export function isTabContent(typeName: string): boolean {
  return typeName === TAB_CONTENT;
}

export function isPage(typeName: string): boolean {
  return typeName === PAGE;
}

export function isTimeSeries(typeName: string): boolean {
  return typeName === TIME_SERIES;
}

export function isCategory(typeName: string): boolean {
  return typeName === CATEGORY;
}

export function isLabel(typeName: string): boolean {
  return typeName === LABEL;
}

export function isSingleValue(typeName: string): boolean {
  return typeName === SINGLE_VALUE;
}

export function isButton(typeName: string): boolean {
  return typeName === BUTTON;
}

export function isWaterfallWidget(typeName: string): boolean {
  return typeName === WATERFALL_CHART;
}
