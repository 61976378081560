import { BaseViewConfigDto, ComponentCssSize, construct, DeepPartial, EditableType } from "ui-core";

const TYPE_NAME = "DowntimeAnalysisViewConfig";

@EditableType({ fullName: TYPE_NAME })
export class DowntimeAnalysisViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  constructor(viewConfigDto: DeepPartial<DowntimeAnalysisViewConfig> = {}) {
    super({ link: viewConfigDto.link });
    construct(this, viewConfigDto, TYPE_NAME, { size: getDefaultSize() });
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("600", "1000");
}
