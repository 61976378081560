<h3 mat-dialog-title class="any-class">
  {{ data.dialogTitle }} report: <strong>{{ data.name }}</strong>
</h3>

<form (ngSubmit)="onSubmit(data.type)">
  <mat-form-field [formGroup]="reportForm" *ngIf="data.showInput">
    <mat-label>New report name</mat-label>
    <input formControlName="reportInput" cdkFocusInitial matInput />
  </mat-form-field>
  <!-- Report Name Validation requirements messages -->
  <mat-error class="report_rename_error" *ngIf="formControls.hasError('required')">
    Report name is required
  </mat-error>
  <mat-error class="report_rename_error" *ngIf="formControls.hasError('minlength')">
    Report name is to short
  </mat-error>
  <mat-error class="report_rename_error" *ngIf="formControls.hasError('maxlength')">
    Report name is too long
  </mat-error>
  <mat-error
    class="report_rename_error"
    *ngIf="
      formControls.hasError('reportName') &&
      !formControls.hasError('required') &&
      !formControls.hasError('minlength') &&
      !formControls.hasError('maxlength')
    "
  >
    Report name includes not allowed symbols
  </mat-error>  
  <!-- End -->
</form>

<div class="dialog_actions_block" style="justify-content: center;" mat-dialog-actions>
  <button class="c-button c-button--rounded" (click)="onNoClick()">Cancel</button>
  <button
    [disabled]="!reportForm.valid"
    class="c-button c-button--rounded c-button--primary"
    (click)="onNoClick(data.type)"
    cdkFocusInitial
  >
    Confirm
  </button>
</div>
