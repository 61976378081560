import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { Configurable } from "../../../meta/decorators/configurable.decorator";
import { EditableType } from "../../../meta/decorators/editable-type.decorator";
import { Serializable } from "../../../meta/decorators/serializable.decorator";
import { EditorType } from "../../../meta/models/editor-type";
import {
  CustomFilterValueType,
  DEFAULT_NUMBER_VALUE
} from "../../../shared/models/custom-filter-value-type";
import { DeepPartial } from "../../../ts-utils/models/deep-partial.type";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { construct } from "../../services/construct.helper";
import { CustomFilterValueDescriptor } from "./custom-filter-value-descriptor";

@EditableType({ fullName: CustomFilterValueType.Number })
export class CustomFilterNumberValueDescriptor extends CustomFilterValueDescriptor {
  typeName: CustomFilterValueType = CustomFilterValueType.Number;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Filter_InitialValue,
    editorType: EditorType.Number
  })
  @Serializable(DEFAULT_NUMBER_VALUE)
  initialValue!: Maybe<number>;

  constructor(params: DeepPartial<CustomFilterNumberValueDescriptor> = {}) {
    super();
    construct(this, params, CustomFilterValueType.Number);
  }
}
