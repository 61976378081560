import { sortedIndexBy as _sortedIndexBy } from "lodash";
import { isApi, isGeneric } from "../../data-connectivity/helpers/data-source-type.helper";
import {
  DataConnectorDto,
  DataPoint,
  DataPointDto,
  DataPointValue
} from "../../data-connectivity/models";
import { CriticalError, Maybe, isArray, isDefined, last } from "../../ts-utils";

export function getSingleValueFromConnector(
  connector: Maybe<DataConnectorDto>
): Maybe<DataPointValue> {
  const dataPoint: Maybe<DataPointDto> = getSingleValueDataPoint(connector);
  return getSingleValue(dataPoint);
}

export function getSingleValueDataPoint(connector: Maybe<DataConnectorDto>): Maybe<DataPoint> {
  const dataPoints = connector?.dataPoints;
  if (isDefined(dataPoints) && dataPoints.length > 0) {
    return last(dataPoints);
  } else {
    return null;
  }
}

export function extractValue(dataPoint: DataPointDto): DataPointValue {
  const dataPointValue = dataPoint.evaluatedValue ?? dataPoint.y;
  return isArray(dataPointValue) ? dataPointValue[0] : dataPointValue;
}

export function getSingleValue(dataPoint: Maybe<DataPointDto>): Maybe<DataPointValue> {
  return isDefined(dataPoint) ? extractValue(dataPoint) : null;
}

export function truncateDataPoints(
  connectors: DataConnectorDto[],
  timestamp: Maybe<Date>
): DataConnectorDto[] {
  if (timestamp == null) {
    throw new CriticalError("Cache timestamp undefined.");
  }
  return connectors.reduce((acc: DataConnectorDto[], connector: DataConnectorDto) => {
    if (
      isGeneric(connector.dataSource) ||
      (isApi(connector.dataSource) && !connector.isTimeSeries)
    ) {
      acc.push(connector);
      return acc;
    }
    const truncatedDataPoints: DataPoint[] = getDataPointsUpToTimestamp(
      connector.dataPoints,
      timestamp
    );
    const truncatedConnector = new DataConnectorDto({
      ...connector,
      dataPoints: truncatedDataPoints
    });
    acc.push(truncatedConnector);
    return acc;
  }, []);
}

export function getDataPointsUpToTimestamp(
  sortedDataPoints: DataPoint[],
  timestamp: Date
): DataPoint[] {
  if (sortedDataPoints == null) {
    return [];
  }
  const timestampDataPointIndex = getTimestampDataPointInsertIndex(sortedDataPoints, timestamp);
  if (timestampIndexIsInvalid(timestampDataPointIndex, sortedDataPoints.length)) {
    return sortedDataPoints;
  } else {
    return sortedDataPoints.slice(0, timestampDataPointIndex);
  }
}

export function getTimestampDataPointInsertIndex(
  sortedDataPoints: DataPoint[],
  timestamp: Date
): number {
  const timestampDataPoints = sortedDataPoints.filter((dataPoint) => dataPoint.x != null);
  if (timestampDataPoints.length === 0) {
    return null;
  }
  const pointToInsert = { x: timestamp } as DataPoint;
  return _sortedIndexBy(timestampDataPoints, pointToInsert, (point) => (point.x as Date).getTime());
}

export function timestampIndexIsInvalid(timestampIndex: number, arrayLength: number): boolean {
  return timestampIndex == null || timestampIndex >= arrayLength;
}
