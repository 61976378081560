<div class="wrapper">
  <div #editorInput class="icon-box" tabindex="0" (click)="open()">
    <icon *ngIf="selectedIcon != null" [icon]="selectedIcon"></icon>
  </div>
  <i
    *ngIf="selectedIcon != null"
    class="abb-icon abb-icon--tiny Close clear-button"
    (click)="clear()"
  ></i>
</div>
