import { AfterViewInit, Component, EventEmitter, OnDestroy, Output } from "@angular/core";
import { cloneDeep as _cloneDeep } from "lodash";
import { EditorType, PropertyInfo } from "../../../meta";
import { EditorWrapperComponent } from "../editor-wrapper/editor-wrapper.component";

@Component({
  selector: "nested-object-editor-wrapper",
  templateUrl: "../editor-wrapper/editor-wrapper.component.html",
  styleUrls: ["../editor-wrapper/editor-wrapper.component.scss"]
})
export class NestedObjectEditorWrapperComponent
  extends EditorWrapperComponent
  implements AfterViewInit, OnDestroy
{
  @Output() public objectPropertyChanged: EventEmitter<any> = new EventEmitter<any>();

  insertEditor(itemConfig: PropertyInfo<unknown>, itemOwner: any, parentInfo: any): void {
    if (!itemConfig) {
      return;
    }
    if (itemConfig.descriptor.editorType === EditorType.EntityArray) {
      return;
    } else {
      super.insertEditor(itemConfig, itemOwner, parentInfo);
    }
  }

  updateTarget(newValue: any): void {
    const newPropInfo = _cloneDeep(this.propertyConfig);
    newPropInfo.value = _cloneDeep(newValue);
    this.objectPropertyChanged.emit(newPropInfo);
  }
}
