import { FilterConfigurationDto } from "../../core/models/filter/filter-configuration";
import { GlobalFilterViewModel } from "../../core/models/filter/global-filter-view-model";
import { DataConnectorDto } from "../../data-connectivity";
import { DataConnectorViewDto } from "../../data-connectivity/models/data-connector-view";
import { DataConnectorViewModel } from "../../data-connectivity/models/data-connector.vm";
import { ComponentStateDto, ComponentStateViewModel } from "../../elements";
import {
  COMPONENT_STATE_DTO,
  COMPONENT_STATE_VIEW_MODEL,
  DATA_CONNECTOR_DTO,
  DATA_CONNECTOR_VIEW_DTO,
  DATA_CONNECTOR_VIEW_MODEL,
  FILTER_CONFIGURATION_DTO,
  GLOBAL_FILTER_VIEW_MODEL
} from "../../elements/models/entity-type.constants";
import { CriticalError } from "../../ts-utils/models/critical-error";
import { Entity } from "../models";

export function switchEntity<Out>(
  entity: Entity,
  callbacks: {
    ComponentStateDto: (entity: ComponentStateDto) => Out;
    ComponentStateViewModel: (entity: ComponentStateViewModel) => Out;
    DataConnectorDto: (entity: DataConnectorDto) => Out;
    DataConnectorViewModel: (entity: DataConnectorViewModel) => Out;
    DataConnectorViewDto: (entity: DataConnectorViewDto) => Out;
    FilterConfigurationDto: (entity: FilterConfigurationDto) => Out;
    GlobalFilterViewModel: (entity: GlobalFilterViewModel) => Out;
  }
): Out {
  switch (entity.typeName) {
    case COMPONENT_STATE_DTO: {
      return callbacks[entity.typeName](entity as ComponentStateDto);
    }
    case COMPONENT_STATE_VIEW_MODEL: {
      return callbacks[entity.typeName](entity as ComponentStateViewModel);
    }
    case DATA_CONNECTOR_DTO: {
      return callbacks[entity.typeName](entity as DataConnectorDto);
    }
    case DATA_CONNECTOR_VIEW_MODEL: {
      return callbacks[entity.typeName](entity as DataConnectorViewModel);
    }
    case DATA_CONNECTOR_VIEW_DTO: {
      return callbacks[entity.typeName](entity as DataConnectorViewDto);
    }
    case FILTER_CONFIGURATION_DTO: {
      return callbacks[entity.typeName](entity as FilterConfigurationDto);
    }
    case GLOBAL_FILTER_VIEW_MODEL: {
      return callbacks[entity.typeName](entity as GlobalFilterViewModel);
    }
    default: {
      throw new CriticalError(`Unknown entity type: ${entity.typeName}`);
    }
  }
}
