import "reflect-metadata";
import { combineLocalizationKey } from "../../i18n/models/localization-dictionary";
import { Maybe } from "../../ts-utils";
import { ConstructorFunction } from "../../ts-utils/models/constructor-function";
import { EditorType, SelectionOption } from "../models";
import { Access } from "../models/access";
import { DraftFunction } from "../models/draft-function";
import { PropertyDescriptor, defaultValidationFunction } from "../models/property-descriptor";
import { TypeProvider } from "../services/type-provider";
import { ConfigurableDecoratorParams } from "./configurable.decorator";

const TYPE_PROVIDER = TypeProvider.getInstance();

type EnumItemsFactory = () => SelectionOption[];

export class ConfigurableEnumParams extends ConfigurableDecoratorParams {
  enumSource: object | EnumItemsFactory;
  localizationPrefix?: Maybe<string> = null;
  showCustomInputInSelectList?: boolean = false;
  allowMultipleSelection?: boolean = false;
}

export function ConfigurableEnum(params: ConfigurableEnumParams) {
  return function (proto: Object, key: string) {
    if (params.displayName == null) {
      console.log(params);
    }
    const parentTypeDescriptor = TYPE_PROVIDER.addType({
      constructorFunction: proto.constructor as ConstructorFunction<any>
    });
    let typeConstructorFunction;

    if (typeof params.enumSource === "function") {
      const enumDelegate = params.enumSource as DraftFunction<any>;
      enumDelegate.isDraft = true;
      typeConstructorFunction = enumDelegate;
    } else {
      typeConstructorFunction = function Enum(ownerInstance: any) {
        return getEnumValues(
          params.enumSource,
          params.localizationPrefix ?? params.displayName ?? "NO_NAME_DEFINED"
        );
      };
      TYPE_PROVIDER.addType({
        constructorFunction: typeConstructorFunction as unknown as new () => any,
        name: `Enum_${params.displayName}`,
        isPrimitive: true
      });
    }

    const partialPropertyDescriptor: Partial<PropertyDescriptor> = {
      displayName: params.displayName,
      tooltipKey: params.tooltipKey ?? params.displayName,
      editorType: params.editorType ?? EditorType.ComboBox,
      access: Access.Write,
      isEnum: true,
      isArray: false,
      isVirtual: false,
      isHidden: false,
      advancedMode: params.advancedMode ?? false,
      validationFunction: params.validationFunction ?? defaultValidationFunction,
      constructorFunction: typeConstructorFunction,
      showCustomInputInSelectList: params.showCustomInputInSelectList ?? false,
      allowMultipleSelection: params.allowMultipleSelection ?? false
    };

    parentTypeDescriptor.upsertProperty(key, typeConstructorFunction, partialPropertyDescriptor);
  };
}

export function getEnumValues(enumSource: object, displayName: string): SelectionOption[] {
  let enumeratedValues: SelectionOption[] = [];

  if (Array.isArray(enumSource)) {
    enumeratedValues = (enumSource as Array<string>).map((item) => {
      return {
        key: item,
        title: item
      };
    });
  } else if (typeof enumSource === "object") {
    // NOTE: When enumeration has numbers for values the enumeration obj
    // has additional keys, where keys are those numbers
    enumeratedValues = Object.keys(enumSource)
      .filter((key: string) => isNaN(Number(key)))
      .map((key: string) => {
        return {
          key: key,
          title: combineLocalizationKey(displayName, key) // enumSource[key]
        };
      });
  } else {
    throw new Error(`Undefined enum source`);
  }
  return enumeratedValues;
}
