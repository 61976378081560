import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { EquipmentSelector } from "../../browsing/services/equipment.selector";
import { prefixWithHttp } from "../../core";
import { UrlParams } from "../../core/models";
import {
  ExternalLinkDto,
  getNavigableUrl,
  LinkDto,
  ReportLinkDto,
  switchLink
} from "../../core/models/link";
import { LinkOpenMode } from "../../core/models/link-open-mode";
import { resolveEquipmentPathToEquipment } from "../../core/services/equipment-path-select.helper";
import { ePathStepsToString } from "../../core/services/equipment-path-to-string.helper";
import { QueryStringService } from "../../core/services/query-string.service";
import { Dispatcher } from "../../dispatcher";
import { ComponentButtonParams } from "../../elements/components/base/component-button-params";
import { RuntimeSettingsSelector } from "../../elements/services/entity-selectors/runtime-settings.selector";
import { ReportDialogActions } from "../../shared/dialogs/actions";
import {
  isDefined,
  isEmptyOrNotDefined,
  isEmptyOrNotDefined2,
  isNotDefined,
  Maybe
} from "../../ts-utils";

@Injectable()
export class LinkResolver {
  constructor(
    public router: Router,
    private queryStringService: QueryStringService,
    private equipmentSelector: EquipmentSelector, // create IEquipmentSelector,
    private dispatcher: Dispatcher,
    private runtimeSettingsSelector: RuntimeSettingsSelector
  ) {}

  public resolveLink(link: LinkDto): void {
    switchLink(link, {
      EmptyLinkDto: () => {},
      ExternalLinkDto: (typedLink) => this.openExternalLink(typedLink),
      ReportLinkDto: (typedLink) => {
        this.openReportLink(typedLink);
      }
    });
  }

  private openExternalLink(link: ExternalLinkDto): void {
    if (isEmptyOrNotDefined2(link.url)) {
      return;
    }
    const externalUrl = prefixWithHttp(link.url);
    switch (link.openMode) {
      case LinkOpenMode.NewBrowserTab: {
        window.open(externalUrl);
        break;
      }
      case LinkOpenMode.NewWindow: {
        window.open(externalUrl, "_blank", "any");
        break;
      }
      case LinkOpenMode.SameWindow: {
        this.openExternalLinkInSameWindow(externalUrl);
        break;
      }
      case LinkOpenMode.PopUp: {
        this.dispatcher.dispatch(
          ReportDialogActions.openReportAsPopup({
            reportName: link.title ?? externalUrl,
            reportPath: externalUrl
          })
        );
        break;
      }
      default: {
        window.open(externalUrl);
        break;
      }
    }
  }

  protected openExternalLinkInSameWindow(url: string): void {
    window.location.href = url;
  }

  public openReportLink(link: ReportLinkDto): void {
    if (isEmptyOrNotDefined2(link.info.reportId)) {
      return;
    }

    const encodedString: string = this.getQueryString(link);
    const fullUrl: string = this.getFullUrl(encodedString, link);

    switch (link.openMode) {
      case LinkOpenMode.NewBrowserTab: {
        window.open(fullUrl);
        break;
      }
      case LinkOpenMode.NewWindow: {
        window.open(fullUrl, "_blank", "any");
        break;
      }
      case LinkOpenMode.SameWindow: {
        this.openReportInSameWindow(link, encodedString);
        break;
      }
      case LinkOpenMode.PopUp: {
        this.dispatcher.dispatch(
          ReportDialogActions.openReportAsPopup({
            reportName: link.info.reportName,
            reportPath: fullUrl
          })
        );
        break;
      }
      default: {
        this.openReportInSameWindow(link, encodedString);
        break;
      }
    }
  }

  public getQueryString(link: ReportLinkDto): string {
    let params: URLSearchParams;

    if (isDefined(link.rootPath)) {
      const resolvedEquipment = resolveEquipmentPathToEquipment(
        link.rootPath,
        this.equipmentSelector.getEquipmentTree(),
        this.runtimeSettingsSelector.getCurrentRootPath()
      );
      const rootPathString = resolvedEquipment?.path ?? ePathStepsToString(link.rootPath.steps);
      params = this.queryStringService.getParamsWithRootPath(rootPathString);
    } else {
      params = this.queryStringService.getParams();
    }
    if (params.has(UrlParams.idType)) {
      params.set(UrlParams.idType, "id");
    }

    const queryString = params.toString();

    const encodedString: string = encodeQueryString(queryString);
    return encodedString;
  }

  public getFullUrl(encodedString: string, link: ReportLinkDto): string {
    let namedRoute: string = isEmptyOrNotDefined(encodedString)
      ? getNavigableUrl(link)
      : `${getNavigableUrl(link)}?${encodedString}`;
    const baseUrl: string = window.location.href.replace(this.router.url, "/");
    return baseUrl + namedRoute;
  }

  public openReportInSameWindow(link: ReportLinkDto, queryString: string): void {
    if (isDefined(queryString)) {
      this.router.navigateByUrl(`${getNavigableUrl(link)}?${queryString}`);
    } else {
      this.router.navigateByUrl(getNavigableUrl(link));
    }
  }

  navigateToNewReport(link: LinkDto): void {
    this.resolveLink(link);
  }
}

export function encodeQueryString(queryString: string): string {
  return queryString.replace("/", "%2F");
}

export function getLink(component: ComponentButtonParams): Maybe<string> {
  return switchLink(component.currentState.view.link, {
    EmptyLinkDto: () => null,
    ExternalLinkDto: (typedLink) =>
      isDefined(typedLink.url) ? prefixWithHttp(typedLink.url) : null,
    ReportLinkDto: (typedLink) => {
      if (
        isNotDefined(typedLink.info) ||
        isNotDefined(typedLink.info.reportId) ||
        isNotDefined(typedLink.info.reportName)
      ) {
        return null;
      }
      const encodedString: string = component.linkResolver.getQueryString(typedLink);
      return component.linkResolver.getFullUrl(encodedString, typedLink);
    }
  });
}
