import { Injectable } from "@angular/core";
import {
  RUNTIME_FILTER_ID,
  getDefaultFilterIdForEntity
} from "../../core/helpers/filter/filter-id.helper";
import { isEmptyOrNotDefined } from "../../ts-utils";
import { isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class UpdateArrayAggregationParam implements UpgradeStep {
  name = "UpdateArrayIndexParam";
  fromVersion = new Version(1, 0, 6);

  perform(oldConfig: any): UpgradeStepResult {
    const filterEntities = oldConfig["filters"]?.["entities"];
    const filterIds = oldConfig["filters"]?.["ids"];
    const componentStateEntities = oldConfig["componentStates"]?.["entities"];
    const connectorEntites = oldConfig["dataConnectors"]?.["entities"];
    let modified = false;
    modified = moveArrayAggregationParamInConnectors(connectorEntites);
    modified = moveArrayAggregationParamInComponents(
      componentStateEntities,
      filterEntities,
      filterIds
    );
    return {
      result: oldConfig,
      modified,
      warning: null
    };
  }
}

function moveArrayAggregationParamInConnectors(dataConnectorEntities: any): boolean {
  if (isNotDefined(dataConnectorEntities)) {
    return false;
  }
  let modified = false;
  Object.values(dataConnectorEntities).forEach((connector: any) => {
    const connectorAggregationParameter =
      connector["dataSource"]?.["aggregationConfig"]?.["arrayAggregationParameter"];
    if (!isEmptyOrNotDefined(connectorAggregationParameter)) {
      const parsedArrayValue = Number(connectorAggregationParameter);
      if (!isNaN(parsedArrayValue)) {
        if (isNotDefined(connector["standardFilters"])) {
          connector["standardFilters"] = {};
        }
        connector["standardFilters"]["array"] = parsedArrayValue;
        delete connector["dataSource"]["aggregationConfig"]["arrayAggregationParameter"];
        modified = true;
      }
    }
  });
  return modified;
}

function moveArrayAggregationParamInComponents(
  componentStateEntities: any,
  filterEntities: any,
  filterIds: any[]
): boolean {
  if (
    isNotDefined(componentStateEntities) ||
    isNotDefined(filterEntities) ||
    isNotDefined(filterIds)
  ) {
    return false;
  }
  let modified = false;
  Object.values(componentStateEntities).forEach((componentState: any) => {
    const componentAggregationParameter =
      componentState["dataConnectorQuery"]?.["aggregationConfig"]?.["arrayAggregationParameter"];
    if (!isEmptyOrNotDefined(componentAggregationParameter)) {
      const parsedArrayValue = Number(componentAggregationParameter);
      if (!isNaN(parsedArrayValue)) {
        const componentFilterId =
          componentState.filterId ?? getDefaultFilterIdForEntity(componentState["id"]);
        if (isNotDefined(filterEntities[componentFilterId])) {
          filterEntities[componentFilterId] = {
            id: componentFilterId,
            sourceFilterId: RUNTIME_FILTER_ID
          };
          filterEntities[componentFilterId]["standardFilters"] = {};
          filterIds.push(componentFilterId);
        }
        filterEntities[componentFilterId]["standardFilters"]["array"] = parsedArrayValue;
        delete componentState["dataConnectorQuery"]["aggregationConfig"][
          "arrayAggregationParameter"
        ];
        modified = true;
      }
    }
  });
  return modified;
}
