import { Injectable } from "@angular/core";
import { REPORT_FILTER_ID } from "../../core/helpers/filter/filter-id.helper";
import { CustomFilterDescriptorDto } from "../../core/models/filter/custom-filter-descriptor";
import { CustomFilterNumberValueDescriptor } from "../../core/models/filter/custom-filter-number-value-descriptor";
import { CustomFilterTextValueDescriptor } from "../../core/models/filter/custom-filter-text-value-descriptor";
import { CustomFilterValueDescriptor } from "../../core/models/filter/custom-filter-value-descriptor";
import { GeneralSettingsDto } from "../../core/models/general-settings";
import { isDefined, isNotDefined, isString } from "../../ts-utils/helpers/predicates.helper";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class RelocateStandardFilters implements UpgradeStep {
  name = "RelocateStandardFilters";
  fromVersion = new Version(4, 0, 7);

  perform(oldConfig: any): UpgradeStepResult {
    const filterEntities = oldConfig["filters"]?.["entities"];
    const generalSettings = oldConfig.generalSettings;
    let modified = false;
    const defaultCustomFilters = new GeneralSettingsDto().customFilterDeclarations;

    modified =
      relocateCustomFiltersInGeneralSettings(generalSettings, defaultCustomFilters) || modified;
    modified = relocateStandardFilters(filterEntities, generalSettings) || modified;
    modified = assignValueTypeInOldCustomFilters(generalSettings) || modified;

    return {
      result: oldConfig,
      modified,
      warning: null
    };
  }
}

function relocateCustomFiltersInGeneralSettings(
  generalSettings: any,
  defaultCustomFilters: any
): boolean {
  if (isNotDefined(generalSettings)) {
    return false;
  }
  let modified = false;
  if (isNotDefined(generalSettings["customFilterDeclarations"])) {
    if (isDefined(generalSettings["filterDeclaration"])) {
      generalSettings["customFilterDeclarations"] = defaultCustomFilters.concat(
        generalSettings["filterDeclaration"]["customFilters"] ?? []
      );
      delete generalSettings["filterDeclaration"];
    } else {
      generalSettings["customFilterDeclarations"] = defaultCustomFilters;
    }
    modified = true;
  }
  return modified;
}

function relocateStandardFilters(
  filterEntities: any,
  generalSettings: GeneralSettingsDto
): boolean {
  if (isNotDefined(filterEntities)) {
    return false;
  }
  let modified = false;
  Object.values(filterEntities).forEach((filter) => {
    const standardFilters = filter["standardFilters"];
    if (isDefined(standardFilters)) {
      filter["customFilters"] = filter["customFilters"] ?? {};
      mergeStandardIntoCustomFilters(filter, generalSettings);
      delete filter["standardFilters"];
      modified = true;
    }
  });
  return modified;
}

function mergeStandardIntoCustomFilters(filter: any, generalSettings: any): void {
  if (isNotDefined(generalSettings) || isNotDefined(generalSettings.customFilterDeclarations)) {
    return;
  }
  generalSettings["customFilterDeclarations"].forEach((filterDeclaration) => {
    let oldStandardFilterValue = filter["standardFilters"][filterDeclaration.key];
    if (isDefined(oldStandardFilterValue)) {
      const standardFilterType = isString(oldStandardFilterValue) ? "text" : "number";
      filter["customFilters"][filterDeclaration.key] = oldStandardFilterValue;
      if (filter["id"] === REPORT_FILTER_ID) {
        filterDeclaration.valueDescriptor = updateCustomFilterInitialValue(
          standardFilterType,
          oldStandardFilterValue
        );
      }
    }
  });
}

function assignValueTypeInOldCustomFilters(generalSettings: any): boolean {
  let modified: boolean = false;
  if (isNotDefined(generalSettings) || isNotDefined(generalSettings.customFilterDeclarations)) {
    return modified;
  }
  generalSettings["customFilterDeclarations"] = generalSettings["customFilterDeclarations"].map(
    (customFilter) => {
      if (isDefined(customFilter["type"]) || isNotDefined(customFilter["valueDescriptor"])) {
        modified = true;
        return new CustomFilterDescriptorDto({
          key: customFilter?.key,
          label: customFilter?.label,
          valueDescriptor: updateCustomFilterInitialValue(customFilter.type)
        });
      }
      return customFilter;
    }
  );
  return modified;
}

function updateCustomFilterInitialValue(
  oldCustomFilterType: any,
  initialValue?: any
): CustomFilterValueDescriptor {
  switch (oldCustomFilterType) {
    case "text":
      return new CustomFilterTextValueDescriptor({ initialValue: initialValue ?? "" });
    case "number":
      return new CustomFilterNumberValueDescriptor({ initialValue: initialValue ?? null });
    default:
      return new CustomFilterTextValueDescriptor({ initialValue: initialValue ?? "" });
  }
}
