import { construct } from "../../../core";
import { LinkDto } from "../../../core/models/link";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { Configurable, EditableType, Serializable } from "../../../meta/decorators";
import { EditorType } from "../../../meta/models/editor-type";
import { DeepPartial } from "../../../ts-utils";
import { BaseViewConfigDto } from "../../models/base-view-config";
import { ComponentCssSize } from "../../models/component-size";

const TYPE_NAME = "SystemOverviewViewConfig";

// @dynamic
@EditableType({ fullName: TYPE_NAME })
export class SystemOverviewViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ImageURL,
    editorType: EditorType.TextBox
  })
  @Serializable("")
  imageUrl!: string;

  @Serializable()
  detailsLink: LinkDto;

  constructor(viewConfigDto: DeepPartial<SystemOverviewViewConfig> = {}) {
    super();
    construct(this, viewConfigDto, TYPE_NAME, { size: getDefaultSize() });
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("1250", "500");
}
