import { cloneDeep as _cloneDeep } from "lodash";
import { DataConnectorDto } from "../../data-connectivity";
import { EntityId } from "../../meta";
import { Dictionary, isDefined, Maybe } from "../../ts-utils";
import { ComponentStateDto, DataStatus, ReportEntities } from "../models";

export function mergeConnectors(
  reportEntities: ReportEntities,
  equipmentConnectors: DataConnectorDto[],
  dynamicEquipmentConnectorsDict: Dictionary<DataConnectorDto[]>,
  dynamicGenericConnectorsDict: Dictionary<DataConnectorDto[]>
): ReportEntities {
  const clonedReportEntities = _cloneDeep(reportEntities);
  replaceConnectors(clonedReportEntities, equipmentConnectors);
  addDcqConnectorsToEntities(clonedReportEntities, dynamicEquipmentConnectorsDict);
  addDcqConnectorsToEntities(clonedReportEntities, dynamicGenericConnectorsDict);
  return clonedReportEntities;
}

function replaceConnectors(
  reportEntities: ReportEntities,
  connectorsToReplace: DataConnectorDto[]
): void {
  connectorsToReplace.forEach((newConnector: DataConnectorDto) => {
    const oldConnectorIndex: number = reportEntities.dataConnectors.findIndex(
      (oldConnector) => oldConnector.id === newConnector.id
    );
    reportEntities.dataConnectors[oldConnectorIndex] = _cloneDeep(newConnector);
  });
}

function addDcqConnectorsToEntities(
  reportEntities: ReportEntities,
  dynamicConnectorsDict: Dictionary<DataConnectorDto[]>
): void {
  Object.keys(dynamicConnectorsDict).forEach((componentId: EntityId) => {
    const component: Maybe<ComponentStateDto> = reportEntities.componentStates.find(
      (component: ComponentStateDto) => component.id === componentId
    );
    if (isDefined(component)) {
      const componentDynamicConnectors: DataConnectorDto[] = dynamicConnectorsDict[componentId];
      const componentDynamicConnectorIds: EntityId[] = componentDynamicConnectors.map(
        (connector: DataConnectorDto) => connector.id
      );
      reportEntities.dataConnectors = reportEntities.dataConnectors.concat(
        componentDynamicConnectors
      );
      component.dataConnectorIds = component.dataConnectorIds.concat(componentDynamicConnectorIds);
      component.dataConnectorQueryDataStatus =
        componentDynamicConnectorIds.length > 0
          ? DataStatus.DataReceived
          : DataStatus.NoDataReceived;
    }
  });
}
