import * as Highcharts from "highcharts";
import { Maybe } from "../../ts-utils/models/maybe.type";

export function simpleLinearRegression(data: number[][]): (x: number) => number {
  let sumX = 0,
    sumY = 0,
    sumXY = 0,
    sumX2 = 0;
  data.forEach(([x, y]) => {
    sumX += x;
    sumY += y;
    sumXY += x * y;
    sumX2 += x ** 2;
  });

  const n = data.length;
  const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX ** 2);
  const intercept = (sumY - slope * sumX) / n;

  return (x) => slope * x + intercept;
}

export function prepareTrendSeries(
  masterSeries: Highcharts.SeriesLineOptions | Highcharts.SeriesScatterOptions
): Highcharts.SeriesLineOptions {
  const trendSeries: Highcharts.SeriesLineOptions = {
    name: masterSeries.name + " - trend",
    type: "line",
    color: masterSeries.color,
    yAxis: masterSeries.yAxis,
    visible: masterSeries.visible,
    lineWidth: 1,
    marker: {
      enabled: false
    },
    states: {
      hover: {
        lineWidth: 0
      }
    },
    enableMouseTracking: false,
    linkedTo: masterSeries.id, // ":previous",
    id: `trend-${masterSeries.id}`
  };
  return trendSeries;
}

export class MinMaxAggregator {
  public range = [Number.MAX_VALUE, Number.MIN_VALUE];

  public process(data: [number, Maybe<number>][] | number[][]): void {
    const xPts = data.map((dp) => dp[0]);
    this.range = [Math.min(this.range[0], ...xPts), Math.max(this.range[1], ...xPts)];
  }
}
