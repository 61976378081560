import { QueryFilter } from "../../core/models/filter/query-filter";
import { DataConnectorDto } from "../../data-connectivity";
import { Dictionary } from "../../ts-utils";
import { ComponentStateDto } from "./component-state";

export type DataRequestType = ConnectorRequestType | GenericRequestType;
type ConnectorRequestType = "connector";
type GenericRequestType = "generic";

export const CONNECTOR_REQUEST_TYPE = "connector";
export const GENERIC_REQUEST_TYPE = "generic";

export interface DataRequestParams {
  type: DataRequestType;
  queryFilter: QueryFilter;
}

export interface ConnectorRequestParams extends DataRequestParams {
  type: ConnectorRequestType;
  connectorsByComponent: Dictionary<DataConnectorDto[]>;
}

export interface GenericRequestParams extends DataRequestParams {
  type: GenericRequestType;
  componentsWithQuery: ComponentStateDto[];
}

export function isSignalRequest(params: DataRequestParams): params is ConnectorRequestParams {
  return params.type === CONNECTOR_REQUEST_TYPE;
}

export function isGenericRequest(params: DataRequestParams): params is GenericRequestParams {
  return params.type === GENERIC_REQUEST_TYPE;
}
