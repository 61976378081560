import { Injectable } from "@angular/core";
import { EMPTY_LINK, EXTERNAL_LINK, LinkType, REPORT_LINK } from "../../core";
import {
  API_DATA_SOURCE,
  DataSourceType,
  EMPTY_DATA_SOURCE,
  EQUIPMENT_DATA_SOURCE,
  GROUPED_DATA_SOURCE,
  SIGNAL_DATA_SOURCE,
  TABULAR_DATA_SOURCE,
  VALUE_DATA_SOURCE
} from "../../data-connectivity";
import { DATA_CONNECTOR_VIEW_DTO } from "../../elements/models/entity-type.constants";
import { ViewConfigProvider } from "../../elements/services/view-config-provider";
import { TypeProvider } from "../../meta/services/type-provider";
import { isDefined, isNotDefined } from "../../ts-utils";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class UpdateTypeProps implements UpgradeStep {
  name = "UpdateTypeProps";
  fromVersion = new Version(1, 0, 1);

  constructor(private typeProvider: TypeProvider, private viewConfigProvider: ViewConfigProvider) {}

  perform(oldConfig: any): UpgradeStepResult {
    const componentStates = oldConfig?.["componentStates"]?.["entities"];
    const dataConnectors = oldConfig?.["dataConnectors"]?.["entities"];
    const dataConnectorViews = oldConfig?.["dataConnectorViews"]?.["entities"];
    const customFilters = oldConfig?.["generalSettings"]?.["filterDeclaration"]?.["customFilters"];
    if (
      isNotDefined(componentStates) &&
      isNotDefined(dataConnectors) &&
      isNotDefined(dataConnectorViews) &&
      isNotDefined(customFilters)
    ) {
      return {
        result: oldConfig,
        modified: false,
        warning: null
      };
    }
    Object.values(componentStates).forEach((componentState: any) =>
      this.setViewTypeName(componentState)
    );
    Object.values(componentStates)
      .map((componentState: any) => componentState.view.link)
      .filter(isDefined)
      .map((link: any) => createValidLink(link));
    Object.values(componentStates)
      .map((componentState: any) => componentState.dataConnectorQuery)
      .filter((dcq: any) => isDefined(dcq))
      .map((dcq: any) => createValidDataSource(dcq));
    Object.values(dataConnectors)
      .map((dataConnector: any) => dataConnector.dataSource)
      .filter((dataSource: any) => isDefined(dataSource))
      .map((dataSource: any) => createValidDataSource(dataSource));
    if (isDefined(dataConnectorViews)) {
      Object.values(dataConnectorViews).forEach((dcView: any) => setDcViewTypeName(dcView));
    }
    if (isDefined(customFilters)) {
      customFilters.forEach((customFilterDescriptor: any) =>
        setCustomFilterTypeName(customFilterDescriptor)
      );
    }
    return {
      result: oldConfig,
      modified: true,
      warning: null
    };
  }

  private setViewTypeName(componentState: any): void {
    if (isDefined(componentState.type)) {
      componentState.view.typeName = this.getViewConfigTypeName(componentState.type);
    }
  }

  private getViewConfigTypeName(componentTypeName: string): string {
    const componentTypeDescriptor = this.typeProvider.getType(componentTypeName);
    const viewConfigType = this.viewConfigProvider.getType(componentTypeDescriptor);

    return viewConfigType.name;
  }
}

function createValidLink(link: any): any {
  if (isDefined(link.type)) {
    link.typeName = transformLinkTypeIntoTypeName(link.type);
    delete link.type;
  }
  return link;
}

function transformLinkTypeIntoTypeName(type: any): LinkType {
  switch (type) {
    case "ReportLink":
      return REPORT_LINK;
    case "ExternalLink":
      return EXTERNAL_LINK;
    default:
      return EMPTY_LINK;
  }
}

function createValidDataSource(dataSource: any): any {
  if (isDefined(dataSource.type)) {
    dataSource.typeName = transformSourceTypeIntoTypeName(dataSource.type);
    delete dataSource.type;
  }
  return dataSource;
}

function transformSourceTypeIntoTypeName(type: any): DataSourceType {
  switch (type) {
    case "api":
      return API_DATA_SOURCE;
    case "equipment":
      return EQUIPMENT_DATA_SOURCE;
    case "grouped":
      return GROUPED_DATA_SOURCE;
    case "signal":
      return SIGNAL_DATA_SOURCE;
    case "tabular":
      return TABULAR_DATA_SOURCE;
    case "value":
      return VALUE_DATA_SOURCE;
    default:
      return EMPTY_DATA_SOURCE;
  }
}

function setDcViewTypeName(dcView: any): void {
  dcView.typeName = DATA_CONNECTOR_VIEW_DTO;
}

function setCustomFilterTypeName(customFilterDescriptor: any): void {
  customFilterDescriptor.typeName = "CustomFilterDescriptorDto";
}
