import { Component } from "@angular/core";
import { CustomButtonComponent } from "../../../shared/components/custom-button/custom-button.component";

@Component({
  selector: "c-card-header-button",
  templateUrl: "./card-header-button.component.html",
  styleUrls: [
    "./card-header-button.component.scss",
    "../../../shared/components/custom-button/custom-button.component.scss"
  ]
})
export class CardHeaderButtonComponent extends CustomButtonComponent {
  onLinkClick(event: Event): void {
    event.preventDefault();
    this.onClick();
  }
}
