<div class="card-item__drag-handle pole-flux-monitoring-chart__wrapper">
  <highcharts-chart-extended
    class="chart-container pole-flux-monitoring-chart__chart-container"
    [Highcharts]="Highcharts"
    [options]="chartOptions"
  >
  </highcharts-chart-extended>
  <div class="pole-flux-monitoring-chart__legend-container">
    <div class="pole-flux-chart__column-title">POLAR VIEW</div>

    <km-legend
      [itemList]="[
        { name: 'Flux Density (T)', color: view.fluxDensityColor },
        { name: 'Airgap (mm)', color: view.airgapColor },
        { name: 'Min. Flux Density', color: view.minFluxDensityColor },
        { name: 'Max. Flux Density', color: view.maxFluxDensityColor },
        { name: 'Min. Airgap', color: view.minAirgapColor },
        { name: 'Max. Airgap', color: view.maxAirgapColor }
      ]"
    ></km-legend>
  </div>
</div>
