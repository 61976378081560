<mat-tab-group
  animationDuration="0ms"
  (selectedIndexChange)="onTabChange($event)"
  disableRipple="true"
>
  <mat-tab *ngIf="shouldShowReportsTab()">
    <ng-template mat-tab-label>
      <span class="tab-label">Reports</span>
    </ng-template>
    <ngx-skeleton-loader
      *ngIf="!isReportsDataReady; else reportLoaded"
      count="3"
      appearance="line"
      animation="pulse"
      [theme]="skeletonTheme"
    >
    </ngx-skeleton-loader>
    <ng-template #reportLoaded>
      <div
        *ngIf="selectedTab === REPORTS_TAB_IDX"
        class="tab-content"
        [ngClass]="{ 'editor': isEditor }"
      >
        <div>
          <div class="sidebar__report-info">
            <div class="sidebar__motor-name_wrapper">
              <span>{{ orgFullName }} / {{ currentMotor }}</span>
            </div>
            <div class="search-bar">
              <input
                #searchInput
                placeholder="Search report..."
                (input)="filterReportsTree(searchInput.value)"
              />
            </div>
          </div>
          <mat-tree
            #reportsTree
            class="reports-tree"
            [dataSource]="reports"
            [treeControl]="reportsTreeControl"
          >
            <mat-tree-node
              *matTreeNodeDef="let currentDashboard"
              matTreeNodeToggle
            >
              <report-element
                [draggable]="isDragModeOn"
                [style]="getDragStyle(currentDashboard)"
                [isDeleteModeOn]="isDeleteModeOn"
                [isDragModeOn]="isDragModeOn"
                [isActive]="isActive(currentDashboard)"
                [dashboard]="currentDashboard"
                [dashboards]="dashboards"
                [isRenamingMode]="isCustomizeMode"
                [filterText]="filterText"
                (dragstart)="handleDragStart($event, currentDashboard)"
                (dragover)="handleDragOver($event, currentDashboard)"
                (drop)="handleDrop($event, currentDashboard)"
                (dragend)="handleDragEnd()"
              >
              </report-element>
            </mat-tree-node>

            <mat-tree-node *matTreeNodeDef="let currentDashboardChild; when: hasNestedChild">
              <button
                matTreeNodeToggle
                class="mat-tree__button"
                [ngStyle]="{ 'margin-left.px': currentDashboardChild.level * 20 }"
              >
                <em
                  class="toggle-children"
                  bind-style.transform="
                    reportsTreeControl.isExpanded(currentDashboardChild) ? 'rotate(90deg)' : 'rotate(0deg)'
                  "
                >
                </em>
              </button>
              <report-element
                [draggable]="isDragModeOn"
                [style]="getDragStyle(currentDashboardChild)"
                [isDeleteModeOn]="isDeleteModeOn"
                [isDragModeOn]="isDragModeOn"
                [isRenameModeOn]="isRenameModeOn"
                [isActive]="isActive(currentDashboardChild)"
                [dashboard]="currentDashboardChild"
                [dashboards]="dashboards"
                [filterText]="filterText"
                (dragstart)="handleDragStart($event, currentDashboardChild)"
                (dragover)="handleDragOver($event, currentDashboardChild)"
                (drop)="handleDrop($event, currentDashboardChild)"
                (dragend)="handleDragEnd()"
              >
              </report-element>
            </mat-tree-node>
          </mat-tree>
        </div>
        <div
          *ngIf="isEditor"
          class="sidebar__action-block"
        >
          <button
            (click)="toggleModes(0)"
            class="sidebar__action-button"
          >
            <em class="icon icon--medium abb-icon Plus"></em>
            New
          </button>
          <button
            (click)="toggleModes(1)"
            [ngClass]="{ 'mode__delete': isDeleteModeOn }"
            class="sidebar__action-button"
          >
            <em class="icon icon--medium abb-icon Minus"></em>
            Delete
          </button>
          <button
            (click)="toggleModes(2)"
            [ngClass]="{ 'mode__rearrange': isDragModeOn }"
            class="sidebar__action-button"
          >
            <em class="icon icon--medium abb-icon Rearrange"></em>
            Regroup
          </button>
          <button
            (click)="toggleModes(3)"
            [ngClass]="{ 'mode__rename': isRenameModeOn }"
            class="sidebar__action-button"
          >
            <em class="icon icon--medium abb-icon Edit"></em>
            Rename
          </button>
        </div>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span class="tab-label">Projects</span>
    </ng-template>
    <ngx-skeleton-loader
      *ngIf="isProjectsLoading; else projectsLoaded"
      count="3"
      appearance="line"
      animation="progress-dark"
      [theme]="skeletonTheme"
    >
    </ngx-skeleton-loader>
    <ng-template #projectsLoaded>
      <div
        *ngIf="selectedTab === PROJECT_TAB_IDX || !shouldShowReportsTab()"
        class="tab-content"
      >
        <div>
          <div class="search-bar">
            <input
              #searchInput2
              (input)="filterProjectsTree(searchInput2.value)"
              placeholder="Search customer or motor..."
            />
          </div>
          <mat-tree
            #projectsTree
            [dataSource]="projects"
            [treeControl]="projectsTreeControl"
          >
            <!-- This is the tree node template for leaf nodes -->
            <mat-tree-node
              *matTreeNodeDef="let currentChild"
              matTreeNodeToggle
            >
              <report-element             
                (click)="onProjectNodeClick(currentChild)"
                [isActive]="isNodeActive(currentChild)" 
                [dashboard]="currentChild"
                [dashboards]="projects"
                [filterText]="filterText"
              ></report-element>
            </mat-tree-node>
            <!-- This is the tree node template for nodes with children -->
            <mat-tree-node *matTreeNodeDef="let currentProject; when: projectHasMotors">
              <button
                [ngStyle]="{ 'margin-left.px': currentProject.level * 20 }"
                class="mat-tree__button"
                matTreeNodeToggle
              >
                <em
                  class="toggle-children"
                  bind-style.transform="projectsTreeControl.isExpanded(currentProject) ? 'rotate(90deg)' : 'rotate(0deg)'"
                >
                </em>
              </button>
              <report-element
                (click)="onProjectNodeClick(currentProject)"
                [dashboard]="currentProject"
                [dashboards]="projects"
                [filterText]="filterText"
              ></report-element>
            </mat-tree-node>
          </mat-tree>
        </div>
      </div>
    </ng-template> 
  </mat-tab>
</mat-tab-group>
