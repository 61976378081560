import { groupBy as _groupBy, partition } from "lodash";
import { FilterConfigurationDto, FilterFactory, QueryFilter } from "../../core";
import { RUNTIME_FILTER_ID } from "../../core/helpers/filter/filter-id.helper";
import { DataConnectorDto, DataSourceDto, isGeneric, isSignalBased } from "../../data-connectivity";
import { isApi, isValue } from "../../data-connectivity/helpers/data-source-type.helper";
import { EntityId } from "../../meta";
import {
  Dictionary,
  isDefined,
  isEmpty,
  isNotDefined,
  isString,
  removeDuplicates
} from "../../ts-utils";
import { ComponentStateDto } from "../models";
import {
  CONNECTOR_REQUEST_TYPE,
  ConnectorRequestParams,
  DataRequestParams,
  GENERIC_REQUEST_TYPE,
  GenericRequestParams
} from "../models/data-request-params";
import { ComponentStateSelector } from "../services/entity-selectors/component-state.selector";
import { DataConnectorSelector } from "../services/entity-selectors/data-connector.selector";

interface ConnectorsByComponentQueryFilter {
  connectors: DataConnectorDto[];
  queryFilter: QueryFilter;
}

export function sortComponentsByFilter(
  components: ComponentStateDto[]
): Dictionary<ComponentStateDto[]> {
  return _groupBy(components, (componentState) => componentState.filterId ?? RUNTIME_FILTER_ID);
}

export function sortConnectorsByComponent(
  dataConnectors: DataConnectorDto[],
  componentStates: ComponentStateDto[]
): Dictionary<DataConnectorDto[]> {
  return dataConnectors.reduce((acc: Dictionary<DataConnectorDto[]>, connector) => {
    const ownerComponent = componentStates.find((componentState) =>
      componentState.dataConnectorIds.includes(connector.id)
    );
    if (isDefined(ownerComponent)) {
      isDefined(acc[ownerComponent.id])
        ? acc[ownerComponent.id].push(connector)
        : (acc[ownerComponent.id] = [connector]);
    }
    return acc;
  }, {});
}

export function getAllRequestParamsByFilters(
  filters: FilterConfigurationDto[],
  filterFactory: FilterFactory,
  componentStateSelector: ComponentStateSelector,
  dataConnectorSelector: DataConnectorSelector
): DataRequestParams[] {
  const filterIds: EntityId[] = filters.map((filterConfig) => filterConfig.id);
  const componentsByFilter: ComponentStateDto[] =
    componentStateSelector.getAllForFiltersAsArray(filterIds);
  const connectorsByComponents: DataConnectorDto[] =
    dataConnectorSelector.getAllByInheritFilterIds(filterIds);
  const connectorsByFilter: DataConnectorDto[] =
    dataConnectorSelector.getAllForStandaloneFilters(filterIds);
  let uniqueConnectors: DataConnectorDto[] = [];
  if (!isEmpty(connectorsByFilter) && !isEmpty(connectorsByComponents)) {
    uniqueConnectors = removeDuplicates(
      [...connectorsByComponents, ...connectorsByFilter],
      (connector) => (isString(connector.id) ? connector.id : connector.id.toString())
    );
  } else if (!isEmpty(connectorsByFilter)) {
    uniqueConnectors = connectorsByFilter;
  } else if (!isEmpty(connectorsByComponents)) {
    uniqueConnectors = connectorsByComponents;
  }
  const requestParams: DataRequestParams[] = getAllRequestParams(
    componentsByFilter,
    uniqueConnectors,
    componentStateSelector,
    filterFactory
  );
  return requestParams;
}

export function getAllRequestParams(
  components: ComponentStateDto[],
  connectors: DataConnectorDto[],
  componentStateSelector: ComponentStateSelector,
  filterFactory: FilterFactory
): DataRequestParams[] {
  const signalBasedConnectors = connectors.filter((connector) =>
    validForQuery(connector.dataSource)
  );
  const signalParams: ConnectorRequestParams[] = getRequestParamsForConnectors(
    signalBasedConnectors,
    componentStateSelector,
    filterFactory
  );
  const genericParams: GenericRequestParams[] = getGenericRequestParams(components, filterFactory);
  return (signalParams as DataRequestParams[]).concat(genericParams);
}

export function getRequestParamsForConnectors(
  connectorsToRequest: DataConnectorDto[],
  componentStateSelector: ComponentStateSelector,
  filterFactory: FilterFactory
): ConnectorRequestParams[] {
  const [connectorsWithOwnFilter, connectorsWithComponentFilter] = partition(
    connectorsToRequest,
    (connector) => isDefined(connector.filterId)
  );
  const inheritQueryParams: ConnectorRequestParams[] = resolveConnectorsWithInheritFilters(
    connectorsWithComponentFilter,
    componentStateSelector,
    filterFactory
  );

  inheritQueryParams.push(
    ...getConnectorsOwnFilterParams(connectorsWithOwnFilter, componentStateSelector, filterFactory)
  );
  return inheritQueryParams;
}

function getConnectorsOwnFilterParams(
  ownFilterConnectors: DataConnectorDto[],
  componentStateSelector: ComponentStateSelector,
  filterFactory: FilterFactory
): ConnectorRequestParams[] {
  return ownFilterConnectors
    .map((connector) => {
      const connectorQueryFilter = filterFactory.createQueryFilterById(
        connector.filterId as EntityId
      );
      const component = componentStateSelector.getComponentByConnectorId(connector.id);
      if (isDefined(connectorQueryFilter) && isDefined(component)) {
        return {
          type: CONNECTOR_REQUEST_TYPE,
          connectorsByComponent: {
            [component.id]: [connector]
          },
          queryFilter: connectorQueryFilter
        } as ConnectorRequestParams;
      }
    })
    .filter(isDefined);
}

function resolveConnectorsWithInheritFilters(
  connectors: DataConnectorDto[],
  componentStateSelector: ComponentStateSelector,
  filterFactory: FilterFactory
): ConnectorRequestParams[] {
  const components: Dictionary<ConnectorsByComponentQueryFilter> = connectors.reduce(
    (acc: Dictionary<ConnectorsByComponentQueryFilter>, connector: DataConnectorDto) => {
      const component = componentStateSelector.getComponentByConnectorId(connector.id);
      if (isDefined(component)) {
        if (isNotDefined(acc[component.id])) {
          const componentFilterId = component.filterId ?? RUNTIME_FILTER_ID;
          const queryFilter = filterFactory.createQueryFilterById(componentFilterId);
          if (isDefined(queryFilter)) {
            acc[component.id] = {
              queryFilter,
              connectors: []
            };
          }
        }
        acc[component.id].connectors.push(connector);
      }
      return acc;
    },
    {}
  );

  return Object.keys(components).reduce((acc: ConnectorRequestParams[], componentId: string) => {
    const queryParams: ConnectorRequestParams = {
      type: CONNECTOR_REQUEST_TYPE,
      queryFilter: components[componentId].queryFilter,
      connectorsByComponent: {
        [componentId]: components[componentId].connectors
      }
    };
    acc.push(queryParams);
    return acc;
  }, []);
}

function validForQuery(dataSource: DataSourceDto): boolean {
  return isSignalBased(dataSource) || isApi(dataSource) || isValue(dataSource);
}

export function getGenericRequestParams(
  components: ComponentStateDto[],
  filterFactory: FilterFactory
): GenericRequestParams[] {
  const genericComponents: ComponentStateDto[] = components.filter(
    (component) => isGeneric(component.dataConnectorQuery) || isApi(component.dataConnectorQuery)
  );
  const componentsByFilter: Dictionary<ComponentStateDto[]> =
    sortComponentsByFilter(genericComponents);

  const genericParams = Object.keys(componentsByFilter).reduce(
    (acc: GenericRequestParams[], filterId: string) => {
      const queryFilter = filterFactory.createQueryFilterById(filterId);
      const componentsWithQuery = componentsByFilter[filterId];
      if (isDefined(queryFilter) && componentsWithQuery.length > 0) {
        const queryParams: GenericRequestParams = {
          type: GENERIC_REQUEST_TYPE,
          queryFilter: queryFilter,
          componentsWithQuery
        };
        acc.push(queryParams);
      }
      return acc;
    },
    []
  );
  return genericParams;
}
