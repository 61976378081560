// NOTE CSS variables for use in typescript files.
// IF CHANGED UPDATE SCSS FILE

export const appHeaderHeight = 52;
export const appFooterHeight = 30;
export const appCollapsedSidebarWidth = 48;
export const appSidebarContentWidth = 320;
export const appSidebarEquipmentPropertyWidth = 320;
export const initialPropertySheetWidth = 280;
export const minPropertySheetWidth = 280;
export const maxPropertySheetWidthInVw = 50;

export const basicCardHeaderHeight = 50;
export const basicCardFooterHeight = 50;

export const tabGroupHeaderHeight = 49.5;

export const iconDefaultSize = 32;
export const iconMediumSize = 20;
export const iconLargeSize = 30;

export const outlineWidth = 1;
export const outlineWidthSelected = 3;

export const appFilterHeight = 60;
export const timeRangeExpandedWidth = 660;
export const timeRangeCollapsedWidth = 195;
export const customFiltersCollapsedWidth = 193.5;
export const periodTypeCollapsedWidth = 155;
export const rootPathExpandedWidth = 310;
export const rootPathCollapsedWidth = 165;
export const customFilterWidth = 150;
export const periodTypeFilterWidth = 40;
export const periodTypeFullFilterWidth = 70;
export const filterIconItemWidth = 60;
export const initialFilterWidth = 180;
export const filterButtonCollapse = 44;
export const filterPinButtonWidth = 35;

export const mobileBreakpoint = 480;
export const tabletBreakpoint = 768;
export const desktopBreakpoint = 1366;

export const historyViewPlayerHeight = 75;
export const historyViewHeaderHeight = 52;

export const pagePaddingLeft = 10;

export const dateTimePickerHeight = 340;
export const dateTimePickerWidth = 210;

export const pageVirtualGridGap = 10;
