import { Dispatcher } from "../../../dispatcher";
import { FilterActions } from "../../../elements/store/filter";
import { GeneralSettingsActions } from "../../../elements/store/general-settings";
import { isDefined } from "../../../ts-utils";
import { GlobalFilterViewModel } from "../../models/filter/global-filter-view-model";

export function getFilterActions(
  dispatcher: Dispatcher,
  changes: Partial<GlobalFilterViewModel>
): void {
  if (isDefined(changes.generalSettings)) {
    dispatcher.dispatch(GeneralSettingsActions.update({ changes: changes.generalSettings }));
  }
  if (isDefined(changes.timeRange)) {
    dispatcher.dispatch(
      FilterActions.updateGlobalFilter({
        changes
      })
    );
  }
}
