import { DataConnectorDto, DataPoint } from "../../data-connectivity/models";
import { CriticalError, Maybe, first, isDefined, isNotDefined, last } from "../../ts-utils";
import {
  getTimestampDataPointInsertIndex,
  timestampIndexIsInvalid,
  truncateDataPoints
} from "../helpers/component-data-accessor.helper";
import { sortConnectors } from "../helpers/connectors.helper";
import { ComponentStateDto } from "../models";
import { DataConnectorViewSelector } from "./entity-selectors/data-connector-view.selector";
import { DataConnectorSelector } from "./entity-selectors/data-connector.selector";
import { IComponentDataAccessor } from "./i-component-data-accessor";

export class ComponentDataAccessor implements IComponentDataAccessor {
  constructor(
    private componentState: ComponentStateDto,
    private dataConnectorSelector: DataConnectorSelector,
    private dataConnectorViewSelector: DataConnectorViewSelector
  ) {}

  private get cacheEnabled(): boolean {
    return this.componentState.cache != null && this.componentState.cache.enabled;
  }

  private get cacheTimestamp(): Maybe<Date> {
    return this.componentState.cache != null && this.componentState.cache.enabled
      ? this.componentState.cache.currentTimestamp
      : null;
  }

  public getAllConnectors(): DataConnectorDto[] {
    let allConnectors = this.dataConnectorSelector.getForComponent(this.componentState.id);
    sortConnectors(allConnectors, this.dataConnectorViewSelector);
    if (this.cacheEnabled) {
      allConnectors = truncateDataPoints(allConnectors, this.cacheTimestamp);
    }
    return Object.values(allConnectors);
  }

  public getConnectorByRole(role: string): Maybe<DataConnectorDto> {
    return first(this.getConnectorsByRole(role));
  }

  public getConnectorsBySimilarRole(role: string): DataConnectorDto[] {
    const connectors = this.dataConnectorSelector.getByRole(role, this.componentState.id, false);
    return this.cacheEnabled ? truncateDataPoints(connectors, this.cacheTimestamp) : connectors;
  }

  public getConnectorsByRole(role: string): DataConnectorDto[] {
    const connectors = this.dataConnectorSelector.getByRole(role, this.componentState.id, true);
    return this.cacheEnabled ? truncateDataPoints(connectors, this.cacheTimestamp) : connectors;
  }

  public getAllValuesForRole(role: string): any[] {
    const connector = this.getConnectorByRole(role);
    return this.getAllValuesForConnector(connector);
  }

  public getAllValuesForSimilarRoles(partialRole: string): any[][] {
    const connectors = this.getConnectorsByRole(partialRole);
    return connectors.map((connector) => this.getAllValuesForConnector(connector));
  }

  public getAllValuesForConnector(connector: Maybe<DataConnectorDto>): any[] {
    if (!connector) {
      throw new CriticalError("Invalid data connector.");
    }

    const points = this.getAllDataPointsForConnector(connector);
    return points != null ? points.map((dataPoint) => dataPoint.y) : null;
  }

  public getAllDataPointsForRole(role: string): DataPoint[] {
    const connector = this.getConnectorByRole(role);
    return this.getAllDataPointsForConnector(connector);
  }

  public getAllDataPointsForConnector(connector: Maybe<DataConnectorDto>): DataPoint[] {
    return connector != null ? connector.dataPoints : null;
  }

  public getValueForRole(role: string): any {
    const connector = this.getConnectorByRole(role);
    return this.getValueForConnector(connector);
  }

  public getValuesForSimilarRoles(partialRole: string): any[] {
    const connectors = this.getConnectorsByRole(partialRole);
    return connectors.map((connector) => this.getValueForConnector(connector));
  }

  public getValueForConnector(connector: Maybe<DataConnectorDto>): any {
    const point = this.getDataPointForConnector(connector);
    return isDefined(point) ? point.y : null;
  }

  public getDataPointForRole(role: string): DataPoint {
    const connector = this.getConnectorByRole(role);
    return this.getDataPointForConnector(connector);
  }

  public getDataPointForConnector(connector: Maybe<DataConnectorDto>): DataPoint {
    const dataPoints = this.getAllDataPointsForConnector(connector);
    return this.getDataPoint(dataPoints);
  }

  private getDataPoint(sortedDataPoints: DataPoint[]): DataPoint {
    if (sortedDataPoints == null || sortedDataPoints.length === 0) {
      return null;
    }

    if (this.cacheEnabled) {
      return this.getTimestampDataPoint(sortedDataPoints);
    } else {
      return this.getLatestDataPoint(sortedDataPoints);
    }
  }

  private getTimestampDataPoint(sortedDataPoints: DataPoint[]): DataPoint {
    if (isNotDefined(this.cacheTimestamp)) {
      throw new CriticalError("Cache timestamp undefined.");
    }
    const timestampDataPointIndex = getTimestampDataPointInsertIndex(
      sortedDataPoints,
      this.cacheTimestamp
    );
    if (timestampIndexIsInvalid(timestampDataPointIndex, sortedDataPoints.length)) {
      return sortedDataPoints[sortedDataPoints.length - 1];
    } else {
      return sortedDataPoints[timestampDataPointIndex];
    }
  }

  private getLatestDataPoint(sortedDataPoints: DataPoint[]): DataPoint {
    return last(sortedDataPoints);
  }

  public getPropertyValueForRole(role: string, propertyName: string): any {
    const connector = this.getConnectorByRole(role);
    if (!!connector && !!connector.properties && !!connector.properties[propertyName]) {
      return connector.properties[propertyName];
    } else {
      return null;
    }
  }
}
