import { construct } from "../../../core/services/construct.helper";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { Configurable, Serializable } from "../../../meta/decorators";
import { EditableType } from "../../../meta/decorators/editable-type.decorator";
import { EditorType } from "../../../meta/models";
import { SimpleViewConfig } from "../../models/simple-view-config";

const TYPE_NAME = "SimpleTrendViewConfig";

@EditableType({ fullName: TYPE_NAME })
export class SimpleTrendViewConfig extends SimpleViewConfig {
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.PlotColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#004C97")
  plotColor!: string;

  constructor() {
    super();
    construct(this, {}, TYPE_NAME);
  }
}
