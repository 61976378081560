import { Injectable } from "@angular/core";
import { HelpService } from "ui-core";

@Injectable()
export class RdsHelpService extends HelpService {
  constructor() {
    super();
    this._hasHelpDocument = false;
  }

  public open(): void {
    console.debug("RDS Help Service - Help document not defined");
  }
}
