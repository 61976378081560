import { Injectable } from "@angular/core";
import { SizeInPx } from "../../elements/models/size-in-px";
import { TypeProvider } from "../../meta/services/type-provider";

@Injectable({ providedIn: "root" })
export class ConnectorContextService {
  constructor(private typeProvider: TypeProvider) {}

  public getNumberOfDataPointsToRequest(
    componentTypeName: string,
    strategy: string | undefined,
    componentSize: SizeInPx,
    connectorRole: string
  ): number {
    const componentType = this.typeProvider.getType(componentTypeName);
    if (componentType.numberOfDataPointsToRequest != null) {
      return componentType.numberOfDataPointsToRequest(strategy, componentSize, connectorRole);
    } else {
      return 1;
    }
  }
}
