import { DataConnectorDto, isSignalBased } from "../../data-connectivity";
import { isEmptyOrNotDefined } from "../../ts-utils";
import { ComponentStateDto } from "../models/component-state";
import { DataConnectorSelector } from "../services";

export function getComponentsWithGlobalPtype(
  componentStates: ComponentStateDto[]
): ComponentStateDto[] {
  return componentStates.filter((componentState) =>
    isEmptyOrNotDefined(componentState.dataConnectorQuery.aggregationConfig.periodType)
  );
}

export function getConnectorsWithGlobalPType(
  componentsWithGlobalPType: ComponentStateDto[],
  dataConnectorSelector: DataConnectorSelector
): DataConnectorDto[] {
  return componentsWithGlobalPType.reduce((acc: DataConnectorDto[], componentState) => {
    const connectorsToQuery: DataConnectorDto[] = Object.values(
      dataConnectorSelector.getManyById(componentState.dataConnectorIds)
    ).filter((connector) => !isStaticSignalConnectorWithDefinedPType(connector));

    acc.push(...connectorsToQuery);
    return acc;
  }, []);
}

function isStaticSignalConnectorWithDefinedPType(connector: DataConnectorDto): boolean {
  return (
    !connector.isDynamicallyCreated &&
    isSignalBased(connector.dataSource) &&
    !isEmptyOrNotDefined(connector.dataSource.aggregationConfig.periodType)
  );
}
