import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Equipment } from "../../../core/models/equipment";
import { EquipmentPath } from "../../../core/models/equipment-path";
import { EquipmentDialogInfo } from "../../../property-sheet/models/equipment-dialog-info";
import { BaseDialogComponent } from "../../../shared/components/base-dialog/base-dialog.component";
import { isEmptyOrNotDefined } from "../../../ts-utils/helpers/is-empty.helper";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { GeneralSettingsSelector } from "../../services/entity-selectors/general-settings.selector";

@Component({
  selector: "equipment-model-browser-dialog",
  templateUrl: "./equipment-model-browser-dialog.component.html",
  styleUrls: ["./equipment-model-browser-dialog.component.scss"]
})
export class EquipmentModelBrowserDialogComponent extends BaseDialogComponent {
  private selectedEquipment: Maybe<Equipment> = null;
  public selectedPath: Maybe<EquipmentPath> = null;
  public rootClass: string = "";
  public openFullTree: boolean = false;

  public get usePathBrowser(): boolean {
    return this.equipmentDialogInfo.usePathBrowser;
  }

  constructor(
    public dialogRef: MatDialogRef<EquipmentModelBrowserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public equipmentDialogInfo: EquipmentDialogInfo,
    public generalSettingsSelector: GeneralSettingsSelector
  ) {
    super();
    this.selectedPath = equipmentDialogInfo.currentPath;
    this.rootClass = equipmentDialogInfo.rootClass;
    this.openFullTree = this.equipmentDialogInfo.openFullTree;
  }

  confirm(): void {
    if (this.usePathBrowser && isDefined(this.selectedPath)) {
      this.dialogRef.close(this.selectedPath);
    } else if (!this.usePathBrowser && isDefined(this.selectedEquipment)) {
      const path = this.openFullTree
        ? this.selectedEquipment.path
        : this.getEquipmentRelativePath();
      this.dialogRef.close(path);
    } else {
      this.dialogRef.close();
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  private getEquipmentRelativePath(): string {
    const equipmentPath =
      this.selectedEquipment && this.selectedEquipment.path ? this.selectedEquipment.path : "";
    const splitPath: string[] = equipmentPath.split("/").filter((slice) => slice !== "");
    const configuredRootPath = this.generalSettingsSelector.getRootPath();
    if (!isEmptyOrNotDefined(configuredRootPath)) {
      const rootPathLength = configuredRootPath.split("/").filter((slice) => slice !== "").length;
      splitPath.splice(0, rootPathLength);
      if (splitPath.length === 0) {
        splitPath.push("");
      }
    }
    const relativePath: string = splitPath.join("/");
    return relativePath;
  }

  equipmentSelectionChanged(newEquipment: Equipment): void {
    this.selectedEquipment = newEquipment;
  }

  equipmentPathSelectionChanged(newPath: EquipmentPath): void {
    this.selectedPath = newPath;
  }
}
