import { EntityId } from "../../meta/models/entity";

export class ReportBrowserNode {
  componentId: EntityId;
  name: EntityId;
  children: ReportBrowserNode[];
  reportId: EntityId;

  constructor(componentId: EntityId, children: ReportBrowserNode[], reportId: EntityId) {
    this.componentId = componentId;
    this.name = componentId;
    this.children = children;
    this.reportId = reportId;
  }

  public addChild(child: ReportBrowserNode): ReportBrowserNode {
    this.children.push(child);
    return child;
  }
}
