import { createAction, props } from "@ngrx/store";
import { DateTimePickerDialogInfo } from "../../models/date-time-picker-dialog-info";

export namespace DateTimePickerDialogActions {
  export const openDateTimePickerDialog = createAction(
    "[Dialog] Open DateTime Picker Dialog",
    props<{ dateTimePickerDialogInfo: DateTimePickerDialogInfo }>()
  );

  export const onDateTimePickerDialogClosed = createAction(
    "[Dialog] On DateTime Picker Dialog Closed",
    props<{ selectedDate: Date }>()
  );
}
