import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { SignalDragInfo } from "../../core/models/drag/signal-drag-info";
import { Equipment } from "../../core/models/equipment";
import { EquipmentProperty } from "../../core/models/equipment-property";
import { Filter } from "../../core/models/filter/filter";
import { QueryFilter } from "../../core/models/filter/query-filter";
import {
  SignalDataSourceDto,
  SIGNAL_DATA_SOURCE as SIGNAL_DATA_SOURCE_TYPE
} from "../../data-connectivity";
import { DataConnectorDto } from "../../data-connectivity/models/data-connector";
import { DataPointDto } from "../../data-connectivity/models/data-point";
import { EquipmentDataSourceDto } from "../../data-connectivity/models/data-source/equipment-data-source";
import { Dictionary } from "../../ts-utils";
import { DataServiceParams } from "../models/data-service-params";
import { ElementsDataService } from "./elements-data.service";

const NUMBER_OF_RESULTS = 5;

@Injectable()
export class MockService extends ElementsDataService {
  constructor(params: DataServiceParams) {
    super(params);
  }

  private signals$ = [
    "Signal1",
    "signal2",
    "ABCsignal",
    "123signal",
    "Signal777",
    "xyzSIGNAL88",
    "signalSIGNAL"
  ];

  get(
    filters: QueryFilter,
    connectors?: Dictionary<DataConnectorDto[]>
  ): Observable<Dictionary<DataConnectorDto>> {
    return of(this.query(filters, Object.values(connectors).flat()));
  }

  private query(
    filters: Filter,
    selectedConnectors?: DataConnectorDto[]
  ): Dictionary<DataConnectorDto> {
    const timeOffset = this._calculatetimeOffset(filters.timeRange.from, filters.timeRange.to);
    let currentTimestamp = filters.timeRange.from.valueOf();
    const end = filters.timeRange.to.valueOf();
    let result: Dictionary<DataConnectorDto> = {};

    const allConnectors = this.connectorSelector.getAll();

    result = Object.keys(allConnectors)
      .map((id) => allConnectors[id])
      .reduce((acc: Dictionary<DataConnectorDto>, connector) => {
        if (connector.dataSource.typeName === SIGNAL_DATA_SOURCE_TYPE) {
          const dataSource = connector.dataSource as SignalDataSourceDto;
          acc[connector.id] = new DataConnectorDto({
            dataSource: dataSource
          });
          currentTimestamp = filters.timeRange.from.valueOf();
          while (currentTimestamp < end) {
            const logDataPoint: DataPointDto = {
              x: new Date(currentTimestamp),
              y: Math.random() * 100
            };
            acc[connector.id].dataPoints.push(logDataPoint);
            currentTimestamp += timeOffset;
          }
        } else {
          console.warn(`Not supported`, connector.dataSource);
        }
        return acc;
      }, {});
    console.debug("Result from mock query", result);

    // AzureMockData.addFakePoleAlignmentData(result);
    // AzureMockData.addFakePoleFluxMonitoringData(result);
    // AzureMockData.addFakeStaticAirgapMeasurementsData(result);
    // AzureMockData.addFakeStaticEccentricityData(result);
    // AzureMockData.addFakeRotorShapeData(result);
    // AzureMockData.addFakeAirgapProfileData(result);
    return result;
  }

  private _calculatetimeOffset(start: Date, end: Date): number {
    const numberOfPointsToDisplay = 10;
    const timeSpan = (end.valueOf() - start.valueOf()) / numberOfPointsToDisplay;
    return timeSpan;
  }

  public getEquipmentTree(path: string): Observable<Equipment> {
    return of(null); // FIXME 18 Return mock equipment tree structure
  }

  protected getManyEquipment(
    equipmentQueries: EquipmentDataSourceDto[],
    rootPath: string
  ): Observable<DataConnectorDto[][]> {
    // const equipmentList: Equipment[] = equipmentQueries.map((equipmentQuery) => this.createMockEquipment(equipmentQuery));
    // const dataConnectors: DataConnector[][] = this.equipmentConverter.convertEquipmentToDataConnectors(equipmentList);
    // return of(dataConnectors);
    return of([]);
  }

  private createMockEquipment(equipmentQuery: EquipmentDataSourceDto): Equipment {
    if (!equipmentQuery || !equipmentQuery.path) {
      return null;
    }

    if (!equipmentQuery.class) {
      const result = new Equipment();
      result.path = equipmentQuery.path;

      for (let i = 0; i < NUMBER_OF_RESULTS; i++) {
        const property = new EquipmentProperty();
        property.logId = "Signals.MOCK_SIGNAL";
        property.name = "Prop" + i;
        result.properties.push(property);
      }

      return result;
    }

    // FIXME 18 handle class, properties and depth

    return null;
  }

  getAllSignalNames(): Observable<string[]> {
    return of(this.signals$);
  }

  getFilteredSignalData(search: string, caseSensitive: boolean): Observable<SignalDragInfo[]> {
    if (caseSensitive === true) {
      return of(
        this.signals$
          .filter((signal) => signal.indexOf(search) !== -1)
          .map(
            (item) =>
              new SignalDragInfo(new SignalDataSourceDto({ signal: { id: item, name: item } }), {})
          )
      );
    } else {
      search = search.toLowerCase();
      return of(
        this.signals$
          .filter((signal) => signal.toLowerCase().indexOf(search) !== -1)
          .map(
            (item) =>
              new SignalDragInfo(new SignalDataSourceDto({ signal: { id: item, name: item } }), {})
          )
      );
    }
  }
}
