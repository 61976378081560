import { Maybe } from "./maybe.type";

export class CriticalError extends Error {
  private _critical: Maybe<boolean> = null;

  constructor(errorMessage?: string) {
    super(errorMessage);
    this._critical = true;
  }

  get isCritical(): Maybe<boolean> {
    return this._critical;
  }
}
