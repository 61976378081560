<div class="card-item__drag-handle static-numeric-value__wrapper">
  <div
    class="static-numeric-value__title"
    [ngClass]="{ 'static-numeric-value__text-container--centered': view.centered }"
  >
    {{ view.title }}
  </div>
  <div
    *ngIf="view.showTimestamp"
    class="static-numeric-value__timestamp"
    [ngClass]="{ 'static-numeric-value__text-container--centered': view.centered }"
  >
    {{ view.timestamp }}
  </div>
  <div
    [style.color]="view.color"
    [style.borderColor]="view.color"
    class="static-numeric-value__indicator-wrapper"
    [ngClass]="{
      'static-numeric-value__indicator-wrapper--centered': view.centered,
      'static-numeric-value__indicator-wrapper--circled': view.showCircle
    }"
  >
    <i
      *ngIf="view.showGrowth"
      class="abb-icon {{ indicatorName }}"
      [style.color]="indicatorColor"
    ></i>
    <div class="static-numeric-value__indicator-value">{{ view.displayValue }}</div>
    <div class="static-numeric-value__indicator-unit">{{ view.unit }}</div>
  </div>
</div>
