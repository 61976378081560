import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule
} from "@angular-material-components/datetime-picker";
import { NgxMatMomentModule } from "@angular-material-components/moment-adapter";
import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatToolbarModule } from "@angular/material/toolbar";
import { EffectsModule } from "@ngrx/effects";
import { HighchartsChartModule } from "highcharts-angular";
import { QuillModule } from "ngx-quill";
import { CoreModule } from "../core/core.module";
import { I18NModule } from "../i18n/i18n.module";
import { MaterialModule } from "../material/material.module";
import { AboutComponent } from "./components/about/about.component";
import { AlignAndDistributeWidgetsComponent } from "./components/align-and-distribute-widgets/align-and-distribute-widgets.component";
import { AppFooterComponent } from "./components/app-footer/app-footer.component";
import { AppHeaderComponent } from "./components/app-header/app-header.component";
import { AutocompleteInterpolationComponent } from "./components/autocomplete-interpolation/autocomplete-interpolation.component";
import { BaseDialogComponent } from "./components/base-dialog/base-dialog.component";
import { BasicTimeSelectorComponent } from "./components/basic-time-selector/basic-time-selector.component";
import { ComponentsHeaderButtons } from "./components/components-header-buttons/components-header-buttons.component";
import { ConfirmationDialogComponent } from "./components/confirmation-dialog/confirmation-dialog.component";
import { CopyPasteCutComponent } from "./components/copy-paste-cut/copy-paste-cut.component";
import { CreateReportComponent } from "./components/create-report/create-report.component";
import { CreditsComponent } from "./components/credits/credits.component";
import { CustomButtonDropdownComponent } from "./components/custom-button-dropdown/custom-button-dropdown.component";
import { CustomButtonComponent } from "./components/custom-button/custom-button.component";
import { DateTimePickerDialogComponent } from "./components/date-time-picker-dialog/date-time-picker-dialog.component";
import { DateTimePickerComponent } from "./components/date-time-picker/date-time-picker.component";
import { DateTimeRangePickerComponent } from "./components/date-time-range-picker/date-time-range-picker.component";
import { FeedbackComponent } from "./components/feedback/feedback.component";
import { FilterToolbarElementComponent } from "./components/filter-toolbar-element/filter-toolbar-element.component";
import { HeaderButtonComponent } from "./components/header-button/header-button.component";
import { HighchartsChartExtendedComponent } from "./components/highcharts-chart-extended/highcharts-chart-extended.component";
import { HistoricRangeComponent } from "./components/historic-range/historic-range.component";
import { LayoutSettingsComponent } from "./components/layout-settings/layout-settings.component";
import { LiveModeFilterComponent } from "./components/live-mode-filter/live-mode-filter.component";
import { NgxQuillEditorWrapperComponent } from "./components/ngx-quill-editor-wrapper/ngx-quill-editor-wrapper.component";
import { RuntimePeriodTypeComponent } from "./components/period-type/runtime-period-type.component";
import { ProjectInfoComponent } from "./components/project-info/project-info.component";
import { ReportDialogComponent } from "./components/report-dialog/report-dialog.component";
import { RootPathComponent } from "./components/root-path/root-path.component";
import { RuntimeCustomFiltersComponent } from "./components/runtime-custom-filters/runtime-custom-filters.component";
import { RuntimeDateTimeRangeComponent } from "./components/runtime-date-time-range/runtime-date-time-range.component";
import { RuntimeFilterToolbarComponent } from "./components/runtime-filter-toolbar/runtime-filter-toolbar.component";
import { SaveSettingsComponent } from "./components/save-settings/save-settings.component";
import { SelectorComponent } from "./components/selector/selector.component";
import { SimpleBoolIndicatorComponent } from "./components/simple-bool-indicator/simple-bool-indicator.component";
import { SimpleSingleValueComponent } from "./components/simple-single-value/simple-single-value.component";
import { SimpleTrendComponent } from "./components/simple-trend/simple-trend.component";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { TimeRangeEditorComponent } from "./components/time-range-editor/time-range-editor.component";
import { MomentPipe } from "./components/time-scrubber/moment.pipe";
import { TimeScrubberComponent } from "./components/time-scrubber/time-scrubber.component";
import { ToggleSwitchComponent } from "./components/toggle-switch/toggle-switch.component";
import { UndoRedoComponent } from "./components/undo-redo/undo-redo.component";
import { UserMenuComponent } from "./components/user-menu/user-menu.component";
import { ReportDialogEffects } from "./dialogs/effects";
import { ColorPickerDialogEffects } from "./dialogs/effects/color-picker-dialog.effects";
import { CreateReportDialogEffects } from "./dialogs/effects/create-report-dialog.effects";
import { DateTimePickerDialogEffects } from "./dialogs/effects/date-time-picker-dialog.effects";
import { OpenDialogEffect } from "./dialogs/effects/dialog.effects";
import { EquipmentBrowserDialogEffects } from "./dialogs/effects/equipment-browser-dialog.effects";
import { FeedbackDialogEffects } from "./dialogs/effects/feedback-dialog.effects";
import { HelpDialogEffects } from "./dialogs/effects/help-dialog.effects";
import { IconPickerDialogEffects } from "./dialogs/effects/icon-picker-dialog.effects";
import { ReportBrowserDialogEffects } from "./dialogs/effects/report-browser-dialog.effects";
import { SignalBrowserDialogEffects } from "./dialogs/effects/signal-browser-dialog.effects";
import { ClickOutsideDirectiveModule } from "./directives/click-outside.directive";
import { EditorModeDirective } from "./directives/editor-mode.directive";
import { FocusVisualizationDirective } from "./directives/focus-visualization.directive";
import { NormalModeDirective } from "./directives/normal-mode.directive";
import { ExpliciteRequestPipe } from "./pipes/explicite-request.pipe";
import { FilterByVisibilityPipe } from "./pipes/filter-by-visibility.pipe";
import { SortAlphabeticallyPipe } from "./pipes/sort-alphabetically.pipe";
import { ToolbarItemsGenerator } from "./services/toolbar-items-generator.service";

const COMPONENTS = [
  AboutComponent,
  AlignAndDistributeWidgetsComponent,
  AppFooterComponent,
  AppHeaderComponent,
  HistoricRangeComponent,
  SimpleBoolIndicatorComponent,
  SimpleSingleValueComponent,
  SimpleTrendComponent,
  HighchartsChartExtendedComponent,
  ConfirmationDialogComponent,
  BaseDialogComponent,
  CreateReportComponent,
  SpinnerComponent,
  BasicTimeSelectorComponent,
  CustomButtonDropdownComponent,
  CustomButtonComponent,
  FeedbackComponent,
  LayoutSettingsComponent,
  HeaderButtonComponent,
  SaveSettingsComponent,
  UserMenuComponent,
  RuntimeFilterToolbarComponent,
  RuntimeCustomFiltersComponent,
  UndoRedoComponent,
  CopyPasteCutComponent,
  RuntimePeriodTypeComponent,
  RuntimeDateTimeRangeComponent,
  DateTimeRangePickerComponent,
  DateTimePickerComponent,
  SelectorComponent,
  CustomButtonComponent,
  FilterToolbarElementComponent,
  LiveModeFilterComponent,
  ToggleSwitchComponent,
  RootPathComponent,
  TimeRangeEditorComponent,
  TimeScrubberComponent,
  ComponentsHeaderButtons,
  ProjectInfoComponent,
  ReportDialogComponent,
  NgxQuillEditorWrapperComponent,
  DateTimePickerDialogComponent,
  CreditsComponent,
  AutocompleteInterpolationComponent
];
const allDirectives = [EditorModeDirective, NormalModeDirective, FocusVisualizationDirective];
@NgModule({
  imports: [
    MaterialModule,
    MatToolbarModule,
    CommonModule,
    HighchartsChartModule,
    I18NModule,
    FormsModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    NgxMatMomentModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    MatDatepickerModule,
    ClickOutsideDirectiveModule,
    CoreModule.forRoot(),
    EffectsModule.forFeature([
      OpenDialogEffect,
      EquipmentBrowserDialogEffects,
      HelpDialogEffects,
      IconPickerDialogEffects,
      SignalBrowserDialogEffects,
      CreateReportDialogEffects,
      FeedbackDialogEffects,
      ReportBrowserDialogEffects,
      ReportDialogEffects,
      ColorPickerDialogEffects,
      DateTimePickerDialogEffects
    ]),
    QuillModule.forRoot({})
  ],
  declarations: [
    COMPONENTS,
    allDirectives,
    MomentPipe,
    ExpliciteRequestPipe,
    FilterByVisibilityPipe,
    SortAlphabeticallyPipe
  ],
  exports: [
    COMPONENTS,
    HighchartsChartModule,
    allDirectives,
    ExpliciteRequestPipe,
    SortAlphabeticallyPipe
  ],
  providers: [ToolbarItemsGenerator, ExpliciteRequestPipe]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule
    };
  }
}
