import { DataConnectorRoles } from "ui-core";
import { PropertyRoles } from "ui-core";

export const Roles: DataConnectorRoles = {
  Value: {
    name: "Value",
    isDefaultRole: true
  }
};

export const Properties: PropertyRoles = {
  AlarmLimit: {
    name: "highLimit"
  },
  TripLimit: {
    name: "highHighLimit"
  },
  Unit: {
    name: "unit"
  }
};
