import { Pipe, PipeTransform } from "@angular/core";
import { isDefined } from "../../ts-utils";
import { ItemInfo } from "../models/property-sheet-config";

@Pipe({ name: "sortByOrder" })
export class SortByOrderPipe implements PipeTransform {
  public transform(properties: ItemInfo[]) {
    const res = properties
      .filter((prop) => isDefined(prop?.propertyInfo?.descriptor))
      .sort(
        (prop1, prop2) => prop1.propertyInfo.descriptor.order - prop2.propertyInfo.descriptor.order
      );
    return res;
  }
}
