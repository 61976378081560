import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { Dictionary } from "../../ts-utils/models/dictionary.type";
import { selectPeriodTypes } from "../store/feature.selector";

export interface IConnectivitySelector {
  selectPeriodTypes(): Observable<Dictionary<string>>;
}

@Injectable()
export class ConnectivitySelector implements IConnectivitySelector {
  constructor(private store$: Store<any>) {}

  selectPeriodTypes(): Observable<Dictionary<string>> {
    return this.store$.select(selectPeriodTypes);
  }
}
