import { ComponentFactory, ComponentFactoryResolver, Injectable, Type } from "@angular/core";
import { Store } from "@ngrx/store";
import { TypeProvider } from "../../meta/services/type-provider";
import { BaseComponent } from "../components/base/base.component";
import { IComponentFactoryService } from "./i-component-factory.service";

@Injectable()
export class ComponentFactoryService extends IComponentFactoryService {
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private typeProvider: TypeProvider,
    private store$: Store<any>
  ) {
    super();
  }

  getFactory(componentName: string): ComponentFactory<BaseComponent> {
    const typeDescriptor = this.typeProvider.getType(componentName);
    return this.componentFactoryResolver.resolveComponentFactory(
      typeDescriptor.constructorFunction as Type<BaseComponent>
    );
  }

  getComponentType(componentTypeName: string): Type<BaseComponent> {
    // const designatedFactory = this.getFactory(componentName);
    // if (!designatedFactory) {
    //   console.error(`Factory for ${componentName} not found`);
    //   return null;
    // }
    // designatedFactory.create()
    return this.typeProvider.getType(componentTypeName).constructorFunction as Type<BaseComponent>;
  }
}
