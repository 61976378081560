import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FeedbackFormData, FeedbackType } from "../../../core/models/feedback-form-data";
import { ErrorLogger } from "../../../core/services/error-logger";
import { IFeedbackService } from "../../../core/services/i-feedback.service";
import { ErrorHandlingActions } from "../../../core/store/error-handling/error-handling.actions";
import { Dispatcher } from "../../../dispatcher";
import { LocalizationService } from "../../../i18n/localization.service";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { SelectionOption } from "../../../meta/models/selection";
import { isDefined } from "../../../ts-utils";
import { BaseDialogComponent } from "../base-dialog/base-dialog.component";

const LATEST_ERRORS_LIMIT = 3;

@Component({
  selector: "c-feedback",
  templateUrl: "./feedback.component.html",
  styleUrls: ["./feedback.component.scss"]
})
export class FeedbackComponent extends BaseDialogComponent implements OnInit, OnDestroy {
  title: string = "";
  description: string = "";
  selectedFeedbackType: FeedbackType = FeedbackType.Other;
  attachments: File[] = [];
  allowToShareBugInfo: boolean = false;

  FeedbackType = FeedbackType;
  public feedbackTypeSelectOptions: SelectionOption[] = [];
  private unsubscribeSubject$: Subject<any> = new Subject<any>();
  @ViewChild("fileInput", { static: true }) fileInput: ElementRef<HTMLInputElement>;

  constructor(
    public dialogRef: MatDialogRef<FeedbackComponent>,
    public localizer: LocalizationService,
    private feedbackService: IFeedbackService,
    private dispatcher: Dispatcher,
    private localizationService: LocalizationService,
    private errorLogger: ErrorLogger
  ) {
    super();
  }

  ngOnInit() {
    this.feedbackTypeSelectOptions = Object.keys(FeedbackType).map(
      (key) => ({ key: key, title: LOCALIZATION_DICTIONARY.dialogs[key] } as SelectionOption)
    );
  }

  ngOnDestroy() {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  send(): void {
    const feedbackFormData: FeedbackFormData = {
      type: this.selectedFeedbackType,
      title: this.title,
      description: this.description,
      attachments: this.attachments
    };
    const feedbackTypeIsBug = this.selectedFeedbackType === FeedbackType.Bug;
    const storeState = feedbackTypeIsBug ? this.feedbackService.getStoreState() : null;
    const errors = feedbackTypeIsBug ? this.errorLogger.getLatestErrors(LATEST_ERRORS_LIMIT) : [];
    this.feedbackService
      .sendFeedback(feedbackFormData, storeState, errors)
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((success) => {
        this.displayFeedbackSuccessMessage(success);
      });
    this.dialogRef.close();
  }

  private displayFeedbackSuccessMessage(success: boolean): void {
    success
      ? this.dispatcher.dispatch(
          ErrorHandlingActions.displayInfo({
            messageToDisplay: this.localizationService.get(
              LOCALIZATION_DICTIONARY.snackBarMessages.SendFeedbackSuccess
            )
          })
        )
      : this.dispatcher.dispatch(
          ErrorHandlingActions.handleError({
            messageToDisplay: this.localizationService.get(
              LOCALIZATION_DICTIONARY.snackBarMessages.SendFeedbackFail
            ),
            autoClose: true
          })
        );
  }

  cancel(): void {
    this.dialogRef.close();
  }

  isSendButtonDisabled(): boolean {
    return this.selectedFeedbackType === FeedbackType.Bug && !this.allowToShareBugInfo;
  }

  onFileInput(event: any) {
    const files: FileList = event.target.files;
    if (isDefined(files) && files.length > 0) {
      const fileData: File = files[0];
      this.attachments.push(fileData);
    }
    this.resetFileInput();
  }

  private resetFileInput(): void {
    /** File input needs to be reset so the 'change' event can be triggered again */
    this.fileInput.nativeElement.value = "";
  }

  remove(fileIndex: number): void {
    this.attachments.splice(fileIndex, 1);
  }
}
