export type PropertyDecoratorFunction = (prototype: Object, key: string) => void;

export function applyPropertyDecorators(
  prototype: Object,
  key: string,
  ...decorators: PropertyDecoratorFunction[]
): void {
  decorators.forEach((decorator) => decorator(prototype, key));
}

// properties without decorators don't get design:type Metadata
// reading the comments in https://github.com/microsoft/TypeScript/issues/14971
// we should probably not use design:type at all
export function DynamicallyDecorated(proto: Object, key: string): void {}
