import { Observable } from "rxjs";
import { bufferTime, filter, map } from "rxjs/operators";
import { groupBy } from "../../ts-utils/helpers/array.helper";

const LOG_PERFORMANCE_EVERY = 10; // seconds
const PERFORMANCE_PREFIX = "Dashboard:";

export function startMeasurement(name: string): void {
  performance.mark(PERFORMANCE_PREFIX + +name + "Start");
}

export function endMeasurement(name: string): void {
  performance.measure(PERFORMANCE_PREFIX + name, PERFORMANCE_PREFIX + +name + "Start");
}

export function startMonitoring(): void {
  const performanceObservable = new Observable<PerformanceEntry[]>((subscriber) => {
    const observer = new PerformanceObserver((list, _observer) => {
      const performanceEntries = list
        .getEntries()
        .filter((e) => e.name.startsWith(PERFORMANCE_PREFIX));
      if (performanceEntries.length > 0) {
        subscriber.next(performanceEntries);
      }
    });
    observer.observe({ entryTypes: ["measure"] });
    return observer.disconnect;
  });

  performanceObservable
    .pipe(
      bufferTime(LOG_PERFORMANCE_EVERY * 1000),
      filter((arrays) => arrays.length > 0),
      map((arrays) => arrays.flat())
    )
    .subscribe(logEntries);
}

function logEntries(dashboardMeasures: PerformanceEntry[]): void {
  if (dashboardMeasures.length === 0) {
    console.log("Dashboard performance: no measures");
  } else {
    const measuresByName = groupBy(dashboardMeasures, "name");
    const statsPerName = Object.entries(measuresByName)
      .map<[string, number, number]>(([name, measures]) => {
        const shortName = name.slice(PERFORMANCE_PREFIX.length);
        const totalDuration = measures.reduce((acc, measure) => acc + measure.duration, 0);
        return [shortName, measures.length, totalDuration];
      })
      .sort((a, b) => a[2] - b[2]);
    const msg = statsPerName
      .map(
        ([name, count, duration]) => `${name}: count=${count}, duration: ${duration.toPrecision(3)}`
      )
      .join("\n");
    console.log("Dashboard performance: \n" + msg);
  }
}
