import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ConnectivityState } from "./state";

// FIXME: rename
export const CONNECTIVITY_FEATURE = "connectivity";

export const selectConnectivityFeature =
  createFeatureSelector<ConnectivityState>(CONNECTIVITY_FEATURE);

export const selectPeriodTypes = createSelector(
  selectConnectivityFeature,
  (state) => state.periodTypes
);
