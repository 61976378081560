<div
  class="single-value__title"
  [style.color]="viewConfig?.foregroundColor"
  [ngClass]="isTitleFormatWrap() ? 'single-value__title--wrap' : 'single-value__title--truncate'"
  [style.text-align]="viewConfig?.horizontalAlignment"
>
  {{ viewConfig != null ? viewConfig.title : null }}
</div>
<div
  *ngIf="dataStatus === DataStatus.DataReceived; else noData"
  class="single-value__value-wrapper"
  [ngClass]="{
    'single-value__value-wrapper--circled': withCircle
  }"
  [style.color]="color"
  [style.borderColor]="color"
  [style.justify-content]="viewConfig.horizontalAlignment"
>
  <span
    [class.single-value__link]="hasLink"
    class="single-value__value-and-unit"
    [style.justify-content]="viewConfig.horizontalAlignment"
    [style.width]="!withTrend ? '100%' : 'unset'"
  >
    <ng-container
      *ngIf="
        hasLink;
        then linkTemplate;
        else isHtmlFormattedValue(valueString) ? htmlTemplate : plainTemplate
      "
    >
    </ng-container>

    <ng-template #linkTemplate>
      <a
        [attr.href]="getLink()"
        (click)="onOpenLink()"
        rel="noopener noreferrer"
        class="link-button"
        [style.font-size.px]="viewConfig.fontSize"
        >{{ valueString }}</a
      >
    </ng-template>

    <ng-template #htmlTemplate>
      <span
        class="single-value__value-and-unit--html"
        [style.font-size.px]="viewConfig.fontSize"
        [style.text-align]="viewConfig.horizontalAlignment"
        [innerHTML]="getValueString() | safe"
      ></span>
    </ng-template>

    <ng-template #plainTemplate>
      <span [style.font-size.px]="viewConfig.fontSize">{{ valueString }}</span>
    </ng-template>

    <span
      [style.font-size.px]="unitFontSizePx"
      [ngClass]="hasLink && 'link-button'"
      (click)="onOpenLink()"
      >{{ unitWithSpace }}</span
    >
  </span>

  <textual-trend
    *ngIf="withTrend"
    [value]="value"
    [viewConfig]="viewConfig"
    [fontSizePx]="viewConfig.fontSize"
  ></textual-trend>
</div>
<ng-template #noData>
  <div
    class="single-value__data-status"
    *ngIf="dataStatus != DataStatus.WaitingForData"
    [style.color]="noDataColor"
  >
    {{ dataStatus }}
  </div>
</ng-template>
