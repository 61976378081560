import { ElementRef } from "@angular/core";
import { outlineWidth, outlineWidthSelected } from "../../../style-variables";
import { isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";

const Z_INDEX = "110";

export function createLoadingIndicator(
  hostContainerWidth: number,
  hostContainerHeight: number
): HTMLElement {
  const loadingIndicatorContainer: HTMLElement = document.createElement("i");
  loadingIndicatorContainer.style.height = `${hostContainerHeight - 2 * outlineWidthSelected}px`;
  loadingIndicatorContainer.style.width = `${hostContainerWidth - 2 * outlineWidthSelected}px`;
  loadingIndicatorContainer.style.display = "flex";
  loadingIndicatorContainer.style.justifyContent = "center";
  loadingIndicatorContainer.style.alignItems = "center";
  loadingIndicatorContainer.style.position = "absolute";
  loadingIndicatorContainer.style.backgroundColor = "white";
  loadingIndicatorContainer.style.top = `${outlineWidth + 1}px`;
  loadingIndicatorContainer.style.left = `${outlineWidth + 1}px`;
  loadingIndicatorContainer.style.zIndex = Z_INDEX;

  const loadingIndicator: HTMLElement = document.createElement("div");
  loadingIndicator.classList.add("loading-indicator");
  const dimension = calculateLoadingIndicatorDimensions(
    parseInt(loadingIndicatorContainer.style.width),
    parseInt(loadingIndicatorContainer.style.height)
  );
  loadingIndicator.style.width = `${dimension}px`;
  loadingIndicator.style.height = `${dimension}px`;
  loadingIndicatorContainer.appendChild(loadingIndicator);
  return loadingIndicatorContainer;
}

function calculateLoadingIndicatorDimensions(
  hostContainerWidth: number,
  hostContainerHeight: number
): number {
  const loadingIndicatorDimension = Math.min(hostContainerWidth, hostContainerHeight);
  return (loadingIndicatorDimension - 2 * outlineWidthSelected) / 2;
}

export function refreshLoadingIndicator(
  shouldShowSpinner: boolean,
  loadingSpinner: Maybe<HTMLElement>,
  hostElementRef: ElementRef
): Maybe<HTMLElement> {
  if (shouldShowSpinner && isNotDefined(loadingSpinner)) {
    return showLoadingIndicator(hostElementRef);
  } else if (!shouldShowSpinner && isDefined(loadingSpinner)) {
    return hideLoadingIndicator(hostElementRef, loadingSpinner);
  }
  return loadingSpinner;
}

export function showLoadingIndicator(hostElementRef: ElementRef): HTMLElement {
  const loadingIndicator: HTMLElement = createLoadingIndicator(
    parseInt((hostElementRef.nativeElement as HTMLElement).style.width),
    parseInt((hostElementRef.nativeElement as HTMLElement).style.height)
  );
  (hostElementRef.nativeElement as HTMLElement).appendChild(loadingIndicator);
  return loadingIndicator;
}

export function hideLoadingIndicator(
  hostElementRef: ElementRef,
  loadingSpinner: HTMLElement
): Maybe<HTMLElement> {
  (hostElementRef.nativeElement as HTMLElement).removeChild(loadingSpinner);
  return null;
}
