import { Injectable } from "@angular/core";
import {
  BASE_VIEW_CONFIG,
  DATA_AGGREGATION_CONFIG_DTO
} from "projects/ui-core/src/lib/core/models/dto-type.constants";
import {
  CustomFilterDescriptorDto,
  CustomFilterValueType,
  EQUIPMENT_DATA_SOURCE,
  GENERAL_SETTINGS_TYPE_NAME,
  ProjectDefaults
} from "ui-core";
import { DATA_CONNECTOR_VIEW_DTO } from "../../../../../ui-core/src/lib/elements/models/entity-type.constants";
import {
  DEFAULT_ARRAY_CUSTOM_FILTER_KEY,
  DEFAULT_ARRAY_CUSTOM_FILTER_LABEL
} from "../../models/default-custom-filters";

@Injectable()
export class RdsDefaultConfigurationService extends ProjectDefaults {
  constructor() {
    super();
    this.typeDescriptorDefaults = {
      [DATA_AGGREGATION_CONFIG_DTO]: {
        ["materialAggregationFunction"]: {
          isHidden: true
        }
      },
      [EQUIPMENT_DATA_SOURCE]: {
        ["class"]: {
          isHidden: true
        },
        ["searchDepth"]: {
          isHidden: true
        }
      },
      [GENERAL_SETTINGS_TYPE_NAME]: {
        ["customFilterDeclarations"]: {
          defaultValue: [
            new CustomFilterDescriptorDto({
              key: DEFAULT_ARRAY_CUSTOM_FILTER_KEY,
              label: DEFAULT_ARRAY_CUSTOM_FILTER_LABEL,
              valueDescriptor: {
                typeName: CustomFilterValueType.Number
              }
            })
          ]
        }
      },
      [BASE_VIEW_CONFIG]: {
        ["exporting"]: {
          defaultValue: true
        }
      },
      [DATA_CONNECTOR_VIEW_DTO]: {
        ["timeSeriesConfig"]: {
          isHidden: false,
          displayValues: ["ArrayChartComponent"]
        }
      }
    };
  }
}
