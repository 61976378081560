import { MatDialogConfig } from "@angular/material/dialog";
import { BasicConfig } from "../../../shared/dialogs/dialog-config.strategies";

export class AddAxisConfigStrategy extends BasicConfig {}

export class RemoveAxisConfigStrategy extends BasicConfig {
  createDialogConfig(yAxes: any): MatDialogConfig<any> {
    const dialogConfig = super.createDialogConfig();
    dialogConfig.data = { axisArray: yAxes };
    return dialogConfig;
  }
}

export class AddSignalConfigStrategy extends BasicConfig {
  createDialogConfig(data: any) {
    const dialogConfig = super.createDialogConfig();
    dialogConfig.width = "30vw";
    dialogConfig.data = { axisArray: data };
    return dialogConfig;
  }
}
export class RemoveSignalConfigStrategy extends AddSignalConfigStrategy {}

export class AddPlotlineConfigStrategy extends BasicConfig {
  createDialogConfig(yAxes: any): MatDialogConfig<any> {
    const dialogConfig = super.createDialogConfig();
    dialogConfig.width = "18vw";
    dialogConfig.data = { axisArray: yAxes };
    return dialogConfig;
  }
}
export class RemovePlotlineConfigStrategy extends AddPlotlineConfigStrategy {}
