import { Injectable } from "@angular/core";
import { EntityState } from "@ngrx/entity";
import { DataConnectorViewDto } from "../../data-connectivity/models/data-connector-view";
import { isDefined } from "../../ts-utils/helpers/predicates.helper";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class RelocateOrderFromTableConnectorView implements UpgradeStep {
  name = "RelocateOrderFromTableConnectorView";
  fromVersion = new Version(4, 0, 10);

  perform(oldConfig: any): UpgradeStepResult {
    const dataConnectorViewsState = oldConfig.dataConnectorViews;
    let modified = false;
    if (isDefined(dataConnectorViewsState)) {
      for (const dataConnectorView of Object.values(dataConnectorViewsState.entities)) {
        modified =
          this.relocateOrderFromTableConnectorView(dataConnectorViewsState, dataConnectorView) ||
          modified;
      }
    }

    return {
      result: oldConfig,
      modified: modified,
      warning: null
    };
  }

  private relocateOrderFromTableConnectorView(
    dataConnectorViewsState: EntityState<DataConnectorViewDto>,
    dataConnectorView: any
  ): boolean {
    let modified = false;
    if (isDefined(dataConnectorView.column)) {
      const order: number = dataConnectorView.column.order;
      delete dataConnectorView.column.order;
      dataConnectorView = { ...dataConnectorView, order };
      dataConnectorViewsState.entities[dataConnectorView.id] = dataConnectorView;
      modified = true;
    }

    return modified;
  }
}
