export const FIRST_SUBGROUP_INDEX = 0;
export const SECOND_SUBGROUP_INDEX = 1;
export const DEFAULT_WIDTH_RATIO = 0.5;

export class GroupablePropertiesParams {
  subgroupsWidthRatio: number[] = [];
  layout: string[][] = [];
}

export interface GroupPropertiesInfo {
  groupId: string;
  subgroupIndex: number;
  subgroupWidthRatio?: number;
}
