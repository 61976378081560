import { PropertyDescriptor } from "../models/property-descriptor";
import { TypeProvider } from "../services/type-provider";

const TYPE_PROVIDER = TypeProvider.getInstance();

export function CustomExpander(expander: Function) {
  return function (proto: Object, key: string) {
    const partialPropertyDescriptor: Partial<PropertyDescriptor> = {
      customPropertyExpander: expander,
      isHidden: true
    };

    TYPE_PROVIDER.upsertProperty(proto, key, partialPropertyDescriptor);
  };
}
