import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef } from "@angular/core";
import { DraggedItemType } from "../../../core";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { LayoutBuilder } from "../../../meta/decorators";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { ComponentCategory } from "../../../meta/models/component-category";
import { isNotDefined, Maybe, tryConvertToNumber } from "../../../ts-utils";
import { ConnectorRoles } from "../../decorators/connector-roles.decorator";
import { MaxConnectors } from "../../decorators/max-connectors.decorator";
import { View } from "../../decorators/view.decorator";
import { createDefaultConnector, sumDataPointValues } from "../../helpers/connectors.helper";
import { isContainerWidget } from "../../models/component-type.helper";
import { DataStatus } from "../../models/data-status";
import { DisplayStrategies } from "../../models/display-strategies/category-display-strategies";
import { CATEGORY } from "../../models/element-type.constants";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import { BaseComponent } from "../base/base.component";
import { ComponentConstructorParams } from "../base/component-constructor-params";
import { Roles } from "./roles";
import { CategoryViewConfig } from "./view-config";

@Component({
  selector: "c-category",
  templateUrl: "./category.component.html",
  styleUrls: ["./category.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: CategoryComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@LayoutBuilder(
  ComponentCategory.Category,
  CATEGORY,
  "icon-Pie-Chart",
  "dashboard-icon",
  DisplayStrategies.Pie,
  LOCALIZATION_DICTIONARY.propertySheet.Display_Pie
)
@LayoutBuilder(
  ComponentCategory.Category,
  CATEGORY,
  "icon-Pie-Chart",
  "dashboard-icon",
  DisplayStrategies.Donut,
  LOCALIZATION_DICTIONARY.propertySheet.Display_Donut
)
@LayoutBuilder(
  ComponentCategory.Category,
  CATEGORY,
  "Bar_chart",
  "abb-icon",
  DisplayStrategies.CategoryVerticalBar,
  LOCALIZATION_DICTIONARY.propertySheet.Display_CategoryVerticalBar
)
@LayoutBuilder(
  ComponentCategory.Category,
  CATEGORY,
  "icon-Horizontal-bar-chart",
  "dashboard-icon",
  DisplayStrategies.CategoryHorizontalBar,
  LOCALIZATION_DICTIONARY.propertySheet.Display_CategoryHorizontalBar
)
@ConnectorRoles(Roles)
@MaxConnectors(100)
@EditableWidget({ fullName: CATEGORY, title: "category-component" })
export class CategoryComponent extends BaseComponent {
  @View(CategoryViewConfig)
  public get view(): CategoryViewConfig {
    return this.currentState.view as CategoryViewConfig;
  }

  public simpleView: CategoryViewConfig;
  public valueConnectorDescriptors: DataConnectorDescriptor[];
  public strategyEnum = DisplayStrategies;

  constructor(
    params: ComponentConstructorParams,
    hostElementRef: ElementRef<any>,
    public cdr: ChangeDetectorRef
  ) {
    super(params, hostElementRef);
  }

  protected updateDisplay(callerInfo?: string): void {
    super.updateDisplay(callerInfo);

    const evaluated = this.dynamicDefaultsEvaluator.collectAndEvaluate(
      this.view,
      this.dataAccessor
    );
    this.valueConnectorDescriptors = evaluated.connectorDescriptors.filter(
      (connectorDesc) => connectorDesc.connector.role === Roles.Value.name
    );

    const interpolatedProperties =
      this.propertyInterpolationService.collectInterpolatedProperties<CategoryViewConfig>(
        { ...this.currentState, view: evaluated.viewConfig },
        this.valueConnectorDescriptors
      );
    this.simpleView = interpolatedProperties.viewConfig;
    this.valueConnectorDescriptors = this.abbreviateConnectors(
      interpolatedProperties.connectorDescriptors,
      tryConvertToNumber(this.simpleView.maxChartItems)
    );

    this.cdr.markForCheck();
  }

  protected onDataStatusChange(dataStatus: DataStatus): void {
    super.onDataStatusChange(dataStatus);
    this.cdr.markForCheck();
  }

  canAcceptDrop(): boolean {
    if (
      this.view.displayStrategy === DisplayStrategies.CategoryHorizontalBar ||
      this.view.displayStrategy === DisplayStrategies.CategoryVerticalBar
    ) {
      const target = this.draggedComponentService.target;
      return (
        target?.type === DraggedItemType.Component &&
        !isContainerWidget(target?.item?.descriptor?.name)
      );
    }
    return super.canAcceptDrop();
  }

  abbreviateConnectors(
    connectors: DataConnectorDescriptor[],
    maxConnectors: Maybe<number>
  ): DataConnectorDescriptor[] {
    if (
      !Array.isArray(connectors) ||
      isNotDefined(maxConnectors) ||
      connectors.length <= maxConnectors
    ) {
      return connectors;
    }
    const abbreviatedConnectors = connectors.slice(0, maxConnectors);
    const sumOfRedundantDataPointValues = sumDataPointValues(connectors, maxConnectors);
    const consolidatedConnector: DataConnectorDescriptor = createDefaultConnector();
    consolidatedConnector.connector?.dataPoints?.push({ y: sumOfRedundantDataPointValues });
    abbreviatedConnectors.push(consolidatedConnector);
    return abbreviatedConnectors;
  }
}
