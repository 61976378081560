import { Injectable } from "@angular/core";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class CreateComponentStateView implements UpgradeStep {
  name = "CreateComponentStateView";
  fromVersion = new Version(0, 0, 1);

  perform(oldConfig: any): UpgradeStepResult {
    let componentStatesCollection: any = oldConfig["componentStates"]["entities"];

    let changed = false;
    Object.values(componentStatesCollection)
      .forEach((componentState: any) => {
        if (!componentState.view) {
          changed = true;

          componentState.view = {};
        }
      });

    return {
      result: oldConfig,
      modified: changed,
      warning: null
    };
  }
}
