import { Injectable } from "@angular/core";
import { SnapshotContext } from "../../meta/models/snapshot-context";

@Injectable()
export class IUndoRedoService {
  createSnapshot(actionContext: SnapshotContext): void {
    throw new Error("Not implemented");
  }

  undo(): void {
    throw new Error("Not implemented");
  }

  redo(): void {
    throw new Error("Not implemented");
  }

  canUndo(): boolean {
    throw new Error("Not implemented");
  }

  canRedo(): boolean {
    throw new Error("Not implemented");
  }

  reset(): void {
    throw new Error("Not implemented");
  }
}
