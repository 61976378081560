export function prefixWithHttp(url: string): string {
  const startsWithHttp = url.startsWith("http://") || url.startsWith("https://");
  const startsWithDot = url.startsWith(".");
  const startsWithSlash = url.startsWith("/");

  if (startsWithSlash) {
    return createAbsoluteUrl(url);
  }
  // NOTE webserver which uses https should have redirect rule defined (redirect http to https)
  const externalUrl = startsWithHttp || startsWithDot ? url : `http://${url}`;
  return externalUrl;
}

function createAbsoluteUrl(url: string): string {
  const hostUrl = window.location.origin;
  return `${hostUrl}${url}`;
}
