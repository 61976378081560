<copy-paste-cut [isCollapsed]="collapseButtons"></copy-paste-cut>
<align-and-distribute-widgets
  [isAutoPositionActive]="isAutoPositionActive"
></align-and-distribute-widgets>
<ng-container *ngIf="!collapseButtons; else collapsedGroup">
  <div class="components-toolbar-buttons__buttons-group">
    <header-button [buttonConfig]="sendToBackButtonConfig" icon="Send_to_back"></header-button>
    <header-button [buttonConfig]="bringToFrontButtonConfig" icon="Bring_to_front"></header-button>
    <header-button
      [buttonConfig]="autoPositionButtonConfig"
      icon="icon-Auto-Position-2"
      [isSelected]="isAutoPositionActive"
    ></header-button>
    <header-button
      [buttonConfig]="snapToGridButtonConfig"
      icon="icon-Snap-to-grid"
      [isSelected]="isSnapToGridActive"
    ></header-button>
  </div>
</ng-container>
<header-button
  *ngIf="displayMode !== DisplayMode.Mobile"
  [buttonConfig]="deleteButtonConfig"
  icon="Trash"
></header-button>
<header-button [buttonConfig]="editButtonConfig" icon="Settings"></header-button>
<ng-template #collapsedGroup>
  <div (clickOutside)="showOrHideMenu(false)">
    <header-button [buttonConfig]="dropdownButtonConfig" [isSelected]="isMenuOpened" icon="More">
    </header-button>
    <ul *ngIf="isMenuOpened" class="buttons-group__menu">
      <header-button
        [buttonConfig]="sendToBackButtonConfig"
        icon="Send_to_back"
        [verticallyOpened]="true"
        [showLabel]="true"
      ></header-button>
      <header-button
        [buttonConfig]="bringToFrontButtonConfig"
        icon="Bring_to_front"
        [verticallyOpened]="true"
        [showLabel]="true"
      ></header-button>
      <header-button
        [buttonConfig]="autoPositionButtonConfig"
        icon="icon-Auto-Position-2"
        [verticallyOpened]="true"
        [showLabel]="true"
        [isSelected]="isAutoPositionActive"
      ></header-button>
      <header-button
        [buttonConfig]="snapToGridButtonConfig"
        icon="icon-Snap-to-grid"
        [verticallyOpened]="true"
        [showLabel]="true"
        [isSelected]="isSnapToGridActive"
      ></header-button>
      <header-button
        *ngIf="displayMode === DisplayMode.Mobile"
        [buttonConfig]="deleteButtonConfig"
        icon="Trash"
        [verticallyOpened]="true"
        [showLabel]="true"
      ></header-button>
    </ul>
  </div>
</ng-template>
